import { WidgetName, WidgetConfig } from "../types";
import { CefiOrderFormWidgetConfig } from "./cefi-order-form-widget/cefi-order-form-widget.config";
import { CefiSymbolChartWidgetConfig } from "./cefi-symbol-chart-widget/cefi-symbol-chart-widget.config";
import { CefiPriceImpactWidgetConfig } from "./cefi-price-impact-widget/cefi-price-impact-widget.config";
import { CefiOrdersTableFullWidgetConfig } from "./cefi-orders-table-full-widget/cefi-orders-table-full-widget.config";
import { CefiOrderbookWidgetConfig } from "./cefi-orderbook-widget/cefi-orderbook-widget.config";
import { OtcOrderFormWidgetConfig } from "./otc-order-form-widget/otc-order-form-widget.config";
import { OtcOrdersTableFullWidgetConfig } from "./otc-orders-table-full-widget/otc-orders-table-full-widget.config";
import { OtcSymbolChartWidgetConfig } from "./otc-symbol-chart-widget/otc-symbol-chart-widget.config";

export const CONFIGS: Record<WidgetName, WidgetConfig> = {
  [WidgetName.CefiOrderForm]: CefiOrderFormWidgetConfig,
  [WidgetName.CefiSymbolChart]: CefiSymbolChartWidgetConfig,
  [WidgetName.CefiPriceImpact]: CefiPriceImpactWidgetConfig,
  [WidgetName.CefiOrdersTableFull]: CefiOrdersTableFullWidgetConfig,
  [WidgetName.CefiOrderbook]: CefiOrderbookWidgetConfig,

  [WidgetName.DefiOrderForm]: CefiOrderFormWidgetConfig,
  [WidgetName.DefiSymbolChart]: CefiSymbolChartWidgetConfig,
  [WidgetName.DefiOrdersTableFull]: CefiPriceImpactWidgetConfig,

  [WidgetName.OtcOrderForm]: OtcOrderFormWidgetConfig,
  [WidgetName.OtcOrdersTableFull]: OtcOrdersTableFullWidgetConfig,
  [WidgetName.OtcSymbolChart]: OtcSymbolChartWidgetConfig,
};
