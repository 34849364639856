import React from "react";
import { Stack } from "@mui/material";
import { FeeWalletHelp } from "./fee-wallet-help";

export const FeeManagerHelp = () => {
  return (
    <Stack maxWidth={900} ml="auto" mr="auto">
      <FeeWalletHelp />
      <Stack mt={2}>
        <video
          controls
          width={900}
          height={477}
          src="https://drive.google.com/uc?id=1KwHWjY4HnFi7_W45RdEc5Sfm_RJZ_2AV"
        ></video>
      </Stack>
    </Stack>
  );
};
