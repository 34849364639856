import React from "react";
import { Box } from "@mui/material";
import { formatValue, getDirectionPreffix } from "./get-columns";
import { useAppSelector } from "@src/store/hooks";

export const TotalPnl = () => {
  const positions = useAppSelector((state) => state.portfolio.stream.positions);

  const totalPnl = Object.values(positions || {}).reduce<number>(
    (acc, { unrealizedPnl }) => (acc += unrealizedPnl || 0),
    0
  );
  const sum = Object.values(positions || {}).reduce<number>(
    (acc, { balance, entryPrice }) => (acc += (balance || 0) * (entryPrice || 1)),
    0
  );
  const roe = (totalPnl * 100) / (sum || 1);

  return totalPnl ? (
    <Box sx={{ color: (theme) => (totalPnl < 0 ? theme.palette.error.main : theme.custom.colors.secondary) }}>
      {getDirectionPreffix(totalPnl) + "$" + formatValue(Math.abs(totalPnl))} ({roe.toFixed(1)}%)
    </Box>
  ) : null;
};
