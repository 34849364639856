import React from "react";
import { Link, List, ListItem, Typography, useTheme } from "@mui/material";

export const TermsAndConditions = () => {
  const theme = useTheme();
  return (
    <>
      <Typography
        color={theme.palette.text.secondary}
        fontSize={16}
        fontWeight={500}
        variant="h4"
        component="h1"
        gutterBottom
      >
        Terms and Conditions
      </Typography>
      <Typography fontSize={14} color={theme.palette.text.secondary}>
        All participants of the campaign accept that:
      </Typography>
      <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary, fontSize: 14 }}>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          He/She abides by the Gate{" "}
          <Link
            color={theme.palette.text.secondary}
            href="https://www.google.com/url?q=https://www.gate.io/user-agreement&sa=D&source=docs&ust=1699887854857797&usg=AOvVaw1RHVQMG9kHGFR87-bAgtLV"
          >
            Terms of Service
          </Link>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          He/she acknowledges and agrees to these Terms & Conditions, Campaign Rules, and any subsequent amendments,
          once he/she joins the campaign. Additionally, his/her relevant information including but not limited to
          nickname and UID on Gate.io, all the individual trades and trade amounts made during the trading fee rebate
          validity period, and any rankings as required by the campaign will be calculated and stored for the duration
          of the entire campaign, as well as may also be made publicly available. The aforementioned information cannot
          be removed or revoked during the campaign. Furthermore, he/she acknowledges and agrees that Gate.io has the
          right to access, read, and disclose his/her relevant aforementioned information.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Gate.io reserves the right to disqualify any participant if he/she engages in any inappropriate, dishonest, or
          abusive activities during the campaign. The participant of the campaign acknowledges and agrees that the
          decision regarding disqualification is at the sole discretion of the Gate.io and Gate.iois not obliged to
          provide any explicit reason(s) for doing so.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Gate.io reserves the right to modify and the final interpretation of the Terms & Conditions of this event
          without notifying users in advance. If you have any questions, please contact our customer service.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Gate.io will not be responsible or liable for any losses (including, without limitation, any direct or
          indirect damages for loss of profits) resulting or arising directly or indirectly from your participation in
          this campaign.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Gate.io reserves the right to disqualify trades that are wash trades, involve illegal bulk account
          registrations, self-dealing, or display signs of market manipulation or suspicious behavior, etc. The
          participant of the campaign acknowledges and agrees that the decision regarding disqualification is at the
          sole discretion of the Gate.io and Gate.iois not obliged to provide any explicit reason(s) for doing so.
        </ListItem>
      </List>
    </>
  );
};
