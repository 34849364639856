import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  initialDataLoading?: boolean;
}

const initialState: AppState = {
  initialDataLoading: false,
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setInitialDataLoading: (state, action: PayloadAction<boolean>) => {
      state.initialDataLoading = action.payload;
    },
  },
});

export const { setInitialDataLoading } = appStateSlice.actions;

export default appStateSlice.reducer;
