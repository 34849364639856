import React, { useCallback, useState } from "react";
import _get from "lodash/get";
import { ROW_HEIGHT } from "../constants";
import { calculateGroups } from "../helpers";
import { OrdersType, GroupedOrderbookOrders, OrderbookOrders } from "../types";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { cefiSymbolsApi } from "@src/store/apis/algoliaApi";
import useAnbotoOrderbookSubscription from "@src/subscriptions/hooks/use-anboto-orderbook-subscription";

type AggregatedInfo = {
  coin1?: string;
  coin2?: string;
  amountCoin1: number;
  amountCoin2: number;
};

type OrderbookContextState = {
  asks: GroupedOrderbookOrders;
  bids: GroupedOrderbookOrders;
  totalAmount: number;
  grouping: string;
  midPrice: number;
  spread: string;
  decimals: number;
  groups: string[];
  updateAggregatedInfo: (index: number, type: OrdersType) => void;
  aggregatedInfo: AggregatedInfo;
  onRowClick?: (price: string) => void;
};

interface OrderbookProviderProps {
  symbol: OrderSymbol;
  freeSpace: number;
  grouping: string;
  onRowClick?: (price: string) => void;
}

export const OrderbookContext = React.createContext<OrderbookContextState>({} as OrderbookContextState);

const groupPrice = (
  data: OrderbookOrders,
  grouping: string,
  type: OrdersType
): { data: GroupedOrderbookOrders; levelNumberOfZeros: number } => {
  const level = +grouping;

  if (!level) {
    const levelNumberOfZeros = 0;
    const resultData: GroupedOrderbookOrders = [];

    let accAmount = 0;
    let accTotal = 0;

    data.forEach(([price, amount]) => {
      accAmount += amount;
      accTotal += price * amount;

      resultData.push({
        price,
        amount,
        total: price * amount,
        accAmount,
        accTotal,
      });
    });

    return {
      levelNumberOfZeros,
      data: resultData,
    };
  }

  const groupedData = new Map<
    string,
    {
      amount: number;
      total: number;
    }
  >();

  let levelNumberOfZeros = -Math.log10(level);
  levelNumberOfZeros = levelNumberOfZeros < 0 ? 0 : levelNumberOfZeros;

  let amountNumberOfZeros = 0;

  data.forEach(([price, amount]) => {
    const fn = type === "ask" ? Math.ceil : Math.floor;
    const priceGroup = (fn(price / level) * level).toFixed(levelNumberOfZeros);
    let amountZeros = (amount.toString().split(".")[1]?.length || 0) as number;

    amountZeros = amount > 0 && amountZeros > 3 ? 3 : amountZeros;

    if (amountNumberOfZeros < amountZeros) amountNumberOfZeros = amountZeros;

    const current = groupedData.get(priceGroup);

    if (current) {
      current.amount = (current.amount || 0) + amount;
      current.total = (current.total || 0) + amount * price;
    } else {
      groupedData.set(priceGroup, {
        amount,
        total: amount * price,
      });
    }
  });

  let accAmount = 0;
  let accTotal = 0;

  return {
    levelNumberOfZeros,
    data: Array.from(groupedData, ([grouppedPrice, { amount, total }]) => {
      accAmount += amount;
      accTotal += total;

      return {
        price: parseFloat(grouppedPrice),
        amount,
        total: parseFloat(grouppedPrice) * amount,
        accAmount,
        accTotal,
      };
    }),
  };
};

export const OrderbookProvider = ({
  children,
  symbol,
  freeSpace,
  grouping = "",
  onRowClick,
}: React.PropsWithChildren<OrderbookProviderProps>) => {
  if (!symbol) throw Error("symbol prop is required");

  const _onRowClick = useCallback((price: string) => onRowClick && onRowClick(price), []);

  const getObjectsQuery = cefiSymbolsApi.useGetObjectsQuery([symbol]);
  const { coin1, coin2 } = (getObjectsQuery.data && getObjectsQuery.data[0]) || {};

  const { asks, bids } = useAnbotoOrderbookSubscription(symbol);
  const [area, setArea] = useState<{ index: number | null; type: OrdersType | null }>({
    index: null,
    type: null,
  });

  const askFirst = _get(asks, "0.0", 0);
  const bidFirst = _get(bids, "0.0", 0);

  const asksLength = asks?.length || 0;
  const bidsLength = bids?.length || 0;
  const maxOrders = asksLength > bidsLength ? asksLength : bidsLength;

  let contextAsks: OrderbookContextState["asks"] = [];
  let contextBids: OrderbookContextState["bids"] = [];
  let decimals = 0;

  const groups = calculateGroups([...asks, ...bids], 4);

  const maxOrdersToShow = freeSpace < 0 ? 0 : Math.floor(freeSpace / ROW_HEIGHT);
  const ordersNumber = maxOrders * 2;
  const itemsNumber = ordersNumber <= maxOrdersToShow ? ordersNumber : maxOrdersToShow;

  const { data: contextAsksData, levelNumberOfZeros: asksLevelNumberOfZeros } = groupPrice(asks, grouping, "ask");

  const { data: contextBidsData, levelNumberOfZeros: bidsLevelNumberOfZeros } = groupPrice(bids, grouping, "bid");

  contextAsks = contextAsksData.slice(0, itemsNumber / 2);
  contextBids = contextBidsData.slice(0, itemsNumber / 2);

  decimals = asksLevelNumberOfZeros > bidsLevelNumberOfZeros ? bidsLevelNumberOfZeros : asksLevelNumberOfZeros;

  const midPrice =
    !askFirst && !bidFirst ? 0 : !askFirst ? bidFirst : !bidFirst ? askFirst : (askFirst + bidFirst) / 2 || 0;

  const totalAmount =
    (contextAsks[contextAsks.length - 1]?.accAmount || 0) + (contextBids[contextBids.length - 1]?.accAmount || 0);

  const contextAskFirst = contextAsks[0]?.price || 0;
  const contextBidFirst = contextBids[0]?.price || 0;

  const spread = !contextAskFirst || !contextBidFirst ? "" : (contextAskFirst - contextBidFirst).toFixed(decimals);

  const aggregatedInfo: AggregatedInfo = {
    coin1,
    coin2,
    amountCoin1: 0,
    amountCoin2: 0,
  };

  const data = area.type === "ask" ? contextAsks : contextBids;

  if (area.index !== null && area.type !== null && data.length) {
    aggregatedInfo.amountCoin1 = data[area.index]?.accAmount;
    aggregatedInfo.amountCoin2 = data[area.index]?.accTotal;
  }

  const updateAggregatedInfo = (index: number, type: OrdersType) => {
    setArea({ index, type });
  };

  // const lengthDiff = contextAsks.length - contextBids.length;
  // console.log(lengthDiff);
  // if (lengthDiff) {
  //   const smallest = lengthDiff > 0 ? contextBids : contextAsks;

  //   smallest.push(
  //     ...(new Array(Math.abs(lengthDiff)).fill({
  //       price: 0,
  //       amount: 0,
  //       total: 0,
  //       accAmount: 0,
  //       accTotal: 0,
  //     }) as GroupedOrderbookOrders)
  //   );
  // }

  return (
    <OrderbookContext.Provider
      value={{
        asks: contextAsks,
        bids: contextBids,
        totalAmount,
        grouping,
        midPrice,
        spread,
        decimals,
        groups,
        updateAggregatedInfo,
        aggregatedInfo,
        onRowClick: _onRowClick,
      }}
    >
      {!asks?.length && !bids?.length ? null : children}
    </OrderbookContext.Provider>
  );
};
