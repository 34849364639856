import React, { useRef, useState } from "react";
import { CircularProgress, Paper, Skeleton, Stack, styled, Typography } from "@mui/material";
import PortfolioItem from "./components/portfolio-item";
import PortfolioHowTo from "./components/potfolio-how-to";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { PortfolioType, SELECT_ALL } from "@src/store/slices/portfolioSlice";
import { TableDataError } from "@src/components/table-data-error";
import { PortfolioItemType } from "@src/store/apis/anbotoApi/types";
import { CurrencyValue, usePortfolioCefiAllErrors } from "@src/pages/portfolio";
import { useSidebarStick } from "@src/pages/portfolio/hooks/use-sidebar-stick";
import { EmptyPortfolioList } from "@src/pages/portfolio/components/sidebar/components/empty-portfolio-list";

type PortfolioSidebarTestProps = {
  overviewTotalBalance?: number;
  selectedItem: string;
  linkButton: React.ReactNode;
  portfolioType: PortfolioType;
  onSelect: (item: string) => void;
  isLoading?: boolean;
  isError?: boolean;
  refetch: () => void;
  updateAddedItemList?: (items: string) => void;
  loadingExchangeName?: string;
  isFetching?: boolean;
  portfolioList: PortfolioItemType[];
};

const PortfolioSidebar = ({
  linkButton,
  portfolioType,
  selectedItem,
  onSelect,
  isLoading,
  isFetching,
  isError,
  refetch,
  updateAddedItemList,
  overviewTotalBalance,
  portfolioList,
}: PortfolioSidebarTestProps) => {
  const [showHowto, setShowHowto] = useState(true);
  const isOverviewAvailable = portfolioList?.length || !showHowto;
  const isPortfolioEmpty = portfolioList?.length === 0;
  const { errors } = usePortfolioCefiAllErrors();

  const containertRef = useRef(null);
  const contentRef = useRef(null);
  const isStickBottom = useSidebarStick(containertRef, contentRef);

  return (
    <Root>
      <Stack height="100%" ref={containertRef}>
        {isError ? (
          <Stack flexGrow={1} display="flex" justifyContent="center" alignItems="center">
            <TableDataError onRefresh={refetch} />
          </Stack>
        ) : isLoading ? (
          <Stack flexGrow={1} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <>
            {isStickBottom && <Stack height="100%"></Stack>}
            <Stack
              position="sticky"
              sx={{
                ...(isStickBottom ? { bottom: 0 } : { top: 0 }),
              }}
            >
              <Stack ref={contentRef} display="flex" flexDirection="column" gap={1} flexGrow={1} marginBottom={14}>
                <Stack direction="row">
                  <Typography lineHeight={1.8} fontSize={18} variant="h6">
                    {portfolioType === PortfolioType.CEFI ? "Exchange" : "My wallet"}
                  </Typography>
                </Stack>

                {isOverviewAvailable ? (
                  <PortfolioOverview
                    onClick={() => onSelect(SELECT_ALL)}
                    color="secondary"
                    variant="outlined"
                    className={selectedItem === SELECT_ALL ? "active" : ""}
                  >
                    <Typography display="flex" alignItems="center" fontSize={18}>
                      {portfolioType === PortfolioType.CEFI ? "CEFI" : "DEFI"} Portfolio
                    </Typography>
                    <Typography marginTop={2} fontWeight={400} fontSize={14} color="#8A9296">
                      Est. total balance
                    </Typography>
                    <Typography minWidth={100} textAlign="left" fontWeight={600} fontSize={20}>
                      {isFetching || isLoading ? (
                        <Skeleton animation="wave" />
                      ) : overviewTotalBalance ? (
                        <CurrencyValue value={overviewTotalBalance} />
                      ) : (
                        <CurrencyValue value={0} />
                      )}
                    </Typography>
                  </PortfolioOverview>
                ) : (
                  <PortfolioHowTo setShowHowto={setShowHowto} portfolioType={portfolioType} />
                )}

                {isPortfolioEmpty && (
                  <Typography lineHeight={1.8} fontSize={17} variant="h6">
                    Add {portfolioType === PortfolioType.CEFI ? "exchanges" : "wallets"} to the Portfolio
                  </Typography>
                )}

                {portfolioList?.map((x) => (
                  <PortfolioItem
                    apiErrors={typeof x.item === "string" ? errors[x.item] || [] : []}
                    key={typeof x.item === "string" ? x.item : x.item.name}
                    isActive={selectedItem === (typeof x.item === "string" ? x.item : x.item.name)}
                    setIsActive={onSelect}
                    isAddedToPortfolio={x.isAdded}
                    itemId={typeof x.item === "string" ? x.item : x.item.name}
                    updateAddedItemList={updateAddedItemList}
                    portfolioType={portfolioType}
                  />
                ))}

                {isPortfolioEmpty && <EmptyPortfolioList portfolioType={portfolioType} />}
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
      <SidebarButtonContainer>{linkButton}</SidebarButtonContainer>
    </Root>
  );
};

const Root = styled(Paper)({
  padding: 30,
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});

const SidebarButtonContainer = styled(Stack)({
  position: "sticky",
  bottom: 40,
  padding: "8px 0",
});

const PortfolioOverview = styled(AnbotoButton)(() => ({
  flexDirection: "column",
  alignItems: "flex-start",
  padding: 14,
  ["&.active"]: {
    background: "#0A1C39",
    borderColor: "#2C72E3",
  },
}));

export default PortfolioSidebar;
