import { useEffect } from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import { useLocation } from "react-router-dom";
import {
  GetDefiTokenTableParams,
  getFirstAvailableBuckets,
  getPortfolioTypeValue,
  usePortfolioDefiTokenTableQuery,
  useSelectedWallets,
} from "@src/pages/portfolio";
import { DefiAllocation } from "@src/store/apis/anbotoApi/types";
import {
  PortfolioType,
  setAccountDefiAllocation,
  setDataUpdatedDefi,
  setDefiTotalBalance,
  setTokenDefiAllocation,
  setWalletAllocation,
} from "@src/store/slices/portfolioSlice";

export interface UseDefiTokenTableResult {
  data?: DefiAllocation[];
  isError: boolean;
  isFetching: boolean;
  refetch: () => void;
}

export const useDefiTokenTable = (): UseDefiTokenTableResult => {
  const location = useLocation();
  const portfolioType = getPortfolioTypeValue(location.pathname);
  const { selectedWalletsNames } = useSelectedWallets();
  const wallet_labels = selectedWalletsNames?.join(",");

  const dispatch = useDispatch();

  const params: GetDefiTokenTableParams = {
    currency: "usd",
    wallet_labels,
  };

  const { data, isError, isFetching, refetch } = usePortfolioDefiTokenTableQuery(params, {
    skip: !wallet_labels || portfolioType === PortfolioType.CEFI,
  });

  const filteredData = getFirstAvailableBuckets(wallet_labels ? data : null);

  useEffect(() => {
    /*** set data updated timestamp ***/
    const defiLatestUpdate = get(data, "data.latest_update", "");

    dispatch(setDataUpdatedDefi(defiLatestUpdate));
  }, [data, dispatch, setDataUpdatedDefi]);

  useEffect(() => {
    // calculating and dispatching balances
    const totalBalance = filteredData.reduce((n, { balance, price }) => n + balance * price, 0);

    dispatch(setDefiTotalBalance(totalBalance));

    // allocation data dispatching
    const allocationData = Array.from(
      filteredData.reduce(
        (m, { chain_name, balance, price }) => m.set(chain_name, (m.get(chain_name) || 0) + balance * price),
        new Map()
      ),
      ([chain_name, value]) => ({
        subaccount: chain_name,
        value,
        percent: value / totalBalance,
      })
    ).sort((a, b) => b.value - a.value);

    dispatch(setAccountDefiAllocation(allocationData));
  }, [filteredData]);

  useEffect(() => {
    // wallet allocation dispatching for chart
    const walletAllocation = Array.from(
      filteredData.reduce(
        (m, { wallet_address, value }) => m.set(wallet_address, (m.get(wallet_address) || 0) + value),
        new Map()
      ),
      ([wallet_address, value]) => ({
        walletAddress: wallet_address,
        value,
      })
    ).sort((a, b) => b.value - a.value);

    dispatch(setWalletAllocation(walletAllocation));
  }, [filteredData]);

  useEffect(() => {
    // tokenAllocationDatadispatching for chart
    const tokenAllocationData = Array.from(
      filteredData.reduce(
        (m, { token_symbol, balance, price }) => m.set(token_symbol, (m.get(token_symbol) || 0) + balance * price),
        new Map()
      ),
      ([tokenName, value]) => ({
        tokenName,
        value,
      })
    ).sort((a, b) => b.value - a.value);

    dispatch(setTokenDefiAllocation(tokenAllocationData));
  }, [filteredData]);

  return {
    data: filteredData,
    isError,
    isFetching,
    refetch,
  };
};
