import React, { useRef, useState } from "react";
import { ClickAwayListener, Paper, Popper, Stack, Tooltip, Typography, styled, useTheme } from "@mui/material";
import { Position } from "./types";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { useMenu } from "@src/hooks/useMenu";
import { AnbotoSlider } from "@src/components/anboto-slider/anboto-slider";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";

interface ClosePositionButtonProps {
  position: Position;
  onClosePosition: (position: Position, amount: number) => void;
  onModalOpen?: () => void;
  onModalClose?: () => void;
}

const steps = [0, 25, 50, 75, 100];

const ClosePosition = ({ position, onClosePosition }: ClosePositionButtonProps) => {
  const modalTarget = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const menu = useMenu();
  const [value, setValue] = useState(steps[steps.length - 1]);

  const handleApplyClick = () => {
    menu.hide();
    onClosePosition(position, Math.abs((position.balance || 0) * (value / 100)));
  };

  return (
    !!position.balance && (
      <Stack position="relative">
        <Stack>
          <Tooltip title={`${position.symbol} - ${CEFI_EXCHANGE_NAME[position.exchange]}`} placement="right">
            <Stack ref={modalTarget}>
              <AnbotoButton
                sx={{ padding: 0, fontSize: 12, lineHeight: 1.5 }}
                onClick={() => menu.show(modalTarget.current!)}
                variant="outlined"
              >
                Close
              </AnbotoButton>
            </Stack>
          </Tooltip>
        </Stack>

        <Popper
          open={menu.isOpen}
          anchorEl={menu.anchor}
          placement="bottom-end"
          sx={{
            zIndex: 99,
            userSelect: "none",
            top: 0,
            left: 0,
          }}
        >
          <ClickAwayListener onClickAway={() => menu.hide()} touchEvent="onTouchStart" mouseEvent="onMouseDown">
            <Paper sx={{ border: `1px solid ${theme.palette.primary.main}` }}>
              <Stack pl={6} pt={1} pr={1} pb={1} position="relative">
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    width: 50,
                    position: "absolute",
                    left: theme.spacing(1),
                    top: theme.spacing(1.7),
                  }}
                >
                  {value}%
                </Typography>
                <Stack ml={0.25} mr={0.75}>
                  <AnbotoSlider
                    value={value}
                    onChange={(e: any) => {
                      e.target && setValue(e.target.value);
                    }}
                    marks={steps.map((value) => ({ value }))}
                  />
                </Stack>
                <Stack direction="row" gap={2}>
                  {steps.map((num) => (
                    <PctButton
                      size="small"
                      variant="outlined"
                      onClick={() => setValue(num)}
                      sx={{
                        color: num <= value ? theme.palette.text.primary : theme.custom.text.dim,
                        borderColor: num <= value ? theme.palette.grey[500] : undefined,
                      }}
                    >
                      {num === 100 ? "Max" : `${num}%`}
                    </PctButton>
                  ))}
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mt={1.5}>
                  <Typography sx={{ fontSize: 11, color: (theme) => theme.custom.text.dim }} variant="body2">
                    Close your position via the order form
                  </Typography>
                  {/* <</Stack>Typography fontSize="small" fontWeight="bold">
                    {position.info.symbol.split("_")[1]}{" "}
                    {formatTokenAmount(Math.abs((position.info?.holding || 0) * (value / 100)))}
                  </Typography> */}
                  <AnbotoButton
                    variant="contained"
                    size="small"
                    onClick={handleApplyClick}
                    disabled={!value}
                    sx={{ border: `1px solid ${theme.palette.background.paper}`, padding: 0, lineHeight: 2 }}
                  >
                    Apply
                  </AnbotoButton>
                </Stack>
              </Stack>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Stack>
    )
  );
};

export const ClosePositionButton = React.memo(ClosePosition, () => false);

const PctButton = styled(AnbotoButton)(() => ({
  padding: 0,
  minWidth: 40,
  justifyContent: "center",
  alignItems: "center",
  fontSize: 12,
}));
