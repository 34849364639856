import { ActionsTypes } from "@src/store/actions/actions-types";
import { SetUserPreferencesInterface } from "@src/store/actions/actions-interface";
import { UserNotificationSettings } from "@src/store/apis/anbotoApi/types";
import { Auth } from "@src/features/auth";

export const setUser = (user) => {
  return { type: ActionsTypes.SET_USER, payload: user };
};

export const logOutUser = () => {
  Auth.signOut();

  return { type: ActionsTypes.LOG_OUT_USER, payload: false };
};

export const setUserPreferences = (x: UserNotificationSettings): SetUserPreferencesInterface => ({
  type: ActionsTypes.SET_USER_PREFERENCES,
  payload: x,
});

export const setUserTokenWatchlist = (x) => ({
  type: ActionsTypes.PUT_TOKEN_PAIR_WATCHLIST,
  payload: x,
});

export const enableUserOTPAction = (otpState) => {
  return { type: ActionsTypes.ENABLE_USER_OTP, payload: otpState };
};

export const disableUserOTPAction = (otpState) => {
  return { type: ActionsTypes.DISABLE_USER_OTP, payload: otpState };
};

export const changeUserTeamAction = (newTeam) => {
  return {
    type: ActionsTypes.CHANGE_USER_TEAM,
    payload: { team_name: newTeam.team_name, team_uuid: newTeam.team_uuid },
  };
};
export const setIsReferrer = (isReferrer) => {
  return {
    type: ActionsTypes.SET_USER_IS_REFERRER,
    payload: { is_referrer: isReferrer },
  };
};
export const setIsReferrerRequestSent = (isSent) => {
  return {
    type: ActionsTypes.SET_IS_REFERRAL_REQUEST_SENT,
    payload: { referrer_request_sent: isSent },
  };
};
export const setMevProtection = (isMevProtection) => {
  return {
    type: ActionsTypes.ENABLE_MEV_PROTECTION,
    payload: { mev_protection: isMevProtection },
  };
};

export const setLiquiditySources = (liquiditySources) => {
  return {
    type: ActionsTypes.SET_LIQUIDITY_SOURCES,
    payload: { liquiditySources: { ...liquiditySources } },
  };
};
export const setOrderPreferences = (orderPreferences) => {
  return {
    type: ActionsTypes.SET_ORDER_PREFERENCES,
    payload: { order_preference: { ...orderPreferences } },
  };
};
export const setIsDefiIntroShown = (isShown) => {
  return {
    type: ActionsTypes.SET_IS_DEFI_INTRO_SHOWN,
    payload: { is_defi_intro_shown: isShown },
  };
};
export const setIsCefiOrDefiModalShown = (isShown) => {
  return {
    type: ActionsTypes.SET_IS_CEFI_OR_DEFI_MODAL_SHOWN,
    payload: { is_cefi_or_defi_modal_shown: isShown },
  };
};
export const setIsCefiIntroShown = (isShown) => {
  return {
    type: ActionsTypes.SET_IS_CEFI_INTRO_SHOWN,
    payload: { is_cefi_intro_shown: isShown },
  };
};
export const setIsWidgetsIntroShown = (isShown) => {
  return {
    type: ActionsTypes.SET_IS_WIDGETS_INTRO_SHOWN,
    payload: { is_widgets_intro_shown: isShown },
  };
};

export const setIsIntroShowing = (isIntroShowing) => {
  return {
    type: ActionsTypes.SET_IS_INTRO_SHOWING,
    payload: { isIntroShowing },
  };
};
