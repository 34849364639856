import React, { useEffect } from "react";
import { Button, Stack, Popper, Paper, ClickAwayListener, MenuList, Grow, MenuItem, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { createPortal } from "react-dom";
import { ORDER_FORM_PORTAL_ID } from "../constants";
import { CEFI_ORDER_STRATEGIES, ORDER_EXECUTION_STRATEGY_NAME } from "./StrategySelect";
import { StrategyDialog } from "./strategy-dialog";
import { OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";
import { styledOptions } from "@src/utils/styled-options";
import { useDialog } from "@src/hooks/useDialog";

const algoStrategies: OrderExecutionStrategy[] = [
  OrderExecutionStrategy.TWAP,
  OrderExecutionStrategy.VWAP,
  OrderExecutionStrategy.ICEBERG,
  OrderExecutionStrategy.POV,
  OrderExecutionStrategy.IS,
];

export const StrategyButtonGroup = ({
  onChange,
  value,
}: {
  onChange: (value: OrderExecutionStrategy, disabled?: boolean) => void;
  value: OrderExecutionStrategy;
}) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [strategy, setStrategy] = React.useState<OrderExecutionStrategy>(value || OrderExecutionStrategy.ORDER);
  const [open, setOpen] = React.useState(false);
  const dialog = useDialog();

  const onDialogListItemClick = (x: OrderExecutionStrategy) => {
    dialog.hide();
    handleStrategyChange(x);
  };

  const handleStrategyChange = (strategy: OrderExecutionStrategy) => {
    setStrategy(strategy);
    setOpen(false);
    onChange(strategy);
  };

  const handleClose = (event?: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const helpIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dialog.show();
  };

  useEffect(() => {
    if (value !== strategy) {
      setStrategy(value);
    }
  }, [value]);

  return (
    <>
      <Stack
        direction="row"
        flexWrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          background: (theme) => theme.palette.background.default,
          borderRadius: 1,
        }}
      >
        {[OrderExecutionStrategy.ORDER, OrderExecutionStrategy.LIMIT].map((id: OrderExecutionStrategy) => (
          <Stack flex={1}>
            <StyledButton
              key={id}
              disableRipple
              size="small"
              fullWidth
              onClick={() => handleStrategyChange(id)}
              active={strategy === id}
            >
              {ORDER_EXECUTION_STRATEGY_NAME[id]}
            </StyledButton>
          </Stack>
        ))}

        <Stack ref={anchorRef} flex={1}>
          <StyledButton
            size="small"
            fullWidth
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
            active={algoStrategies.includes(strategy)}
            disableRipple
            endIcon={<ExpandMoreIcon sx={{ color: "#8A9296" }} />}
          >
            <Stack alignItems="center">
              {[OrderExecutionStrategy.LIMIT, OrderExecutionStrategy.ORDER].includes(strategy)
                ? "Algos"
                : ORDER_EXECUTION_STRATEGY_NAME[strategy]}
            </Stack>
          </StyledButton>
        </Stack>
        <InfoOutlinedIcon
          sx={{ fontSize: 13, color: "#8A9296", opacity: 0.6, marginRight: 1, cursor: "pointer" }}
          onClick={helpIconClick}
        />
      </Stack>

      <Popper
        sx={{
          zIndex: 99,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <StyledPaper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {algoStrategies.map((id) => (
                    <MenuItem
                      disableRipple
                      key={id}
                      selected={strategy === id}
                      onClick={() => handleStrategyChange(id)}
                    >
                      {ORDER_EXECUTION_STRATEGY_NAME[id]}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </StyledPaper>
          </Grow>
        )}
      </Popper>

      {dialog.isOpen &&
        createPortal(
          <StrategyDialog
            open
            hide={dialog.hide}
            value={value}
            onSelect={onDialogListItemClick}
            strategies={CEFI_ORDER_STRATEGIES}
          />,
          document.getElementById(ORDER_FORM_PORTAL_ID) as HTMLElement
        )}
    </>
  );
};

const StyledButton = styled(
  Button,
  styledOptions
)<{ active?: boolean }>(({ theme, active }) => ({
  background: theme.palette.background.default,
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  textTransform: "none",
  fontSize: 14,
  "&:hover": {
    background: theme.palette.background.default,
  },
}));

const StyledPaper = styled(
  Paper,
  styledOptions
)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1px solid #3B4043",
  "& .MuiList-root.MuiList-padding": {
    padding: 2,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.default,
  },
  "& .MuiMenuItem-root": {
    padding: theme.spacing(1.5, 2.5),
    borderRadius: theme.spacing(0.25),
    fontSize: 14,
    "&: hover": {
      backgroundColor: theme.custom.background.secondary,
    },
  },
  "& .Mui-selected": {
    fontWeight: 600,
    backgroundColor: `${theme.custom.background.secondary} !important`,
  },
}));
