import React from "react";
import { NavLink } from "react-router-dom";

type LinkProps = {
  to: string;
  ownerState: unknown;
  children: React.ReactNode;
  external?: boolean;
};

export const Link = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ to, children, ownerState, external, ...props }: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
    if (external) {
      return (
        <a href={to} target="_blank" rel="noreferrer" style={{ display: "block" }} {...props}>
          {children}
        </a>
      );
    }

    return (
      <NavLink {...props} ref={ref} to={to}>
        {children}
      </NavLink>
    );
  }
);

Link.displayName = "Link";
