import BigNumber from "bignumber.js";
import { ClipSizeType, DefiOrderExecutionStrategy, OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";
import { isMultiSliceOrderExecutionStrategy } from "@src/store/apis/anbotoApi/utils";
import { PAY_TOKEN_MIN_AMOUNT_IN_USD } from "@src/pages/defi/constants";
import { calculateNumberOfSlices } from "@src/pages/defi/utils";

export const isTokenAmountLessThanMinAmountInUSD = (
  clipSizeType: ClipSizeType,
  clipSizeValue: string,
  tokenAmount: string,
  tokenPrice: string,
  minAmount: string,
  strategy: DefiOrderExecutionStrategy
) => {
  const isMultiStrategy = isMultiSliceOrderExecutionStrategy(strategy) || strategy === OrderExecutionStrategy.DCA;

  if (!isMultiStrategy) {
    return new BigNumber(tokenAmount).multipliedBy(tokenPrice).lt(minAmount);
  }

  if (clipSizeType === ClipSizeType.PERCENTAGE) {
    return new BigNumber(tokenAmount)
      .multipliedBy(new BigNumber(clipSizeValue).dividedBy(100))
      .multipliedBy(tokenPrice)
      .lt(minAmount);
  }

  if (clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS) {
    return new BigNumber(tokenAmount).dividedBy(new BigNumber(clipSizeValue)).multipliedBy(tokenPrice).lt(minAmount);
  }

  if (clipSizeType === ClipSizeType.ABSOLUTE || clipSizeType === ClipSizeType.AUTOMATIC) {
    return (
      new BigNumber(clipSizeValue).multipliedBy(tokenPrice).lt(minAmount) ||
      (new BigNumber(tokenAmount).gte(new BigNumber(clipSizeValue)) &&
        new BigNumber(tokenAmount).minus(new BigNumber(clipSizeValue)).multipliedBy(tokenPrice).gt(0) &&
        new BigNumber(tokenAmount).minus(new BigNumber(clipSizeValue)).multipliedBy(tokenPrice).lt(minAmount))
    );
  }

  return new BigNumber(tokenAmount).dividedBy(clipSizeValue).multipliedBy(tokenPrice).lt(minAmount);
};

interface ValidateAmountProps {
  maxFeeAbsoluteUsd: string;
  clipSizeType: ClipSizeType;
  clipSizeValue: string;
  nativeCoinPrice: string;
  strategy: DefiOrderExecutionStrategy;
  tokenEthRate: string;
  value?: string;
}

export const validateAmount = ({
  maxFeeAbsoluteUsd,
  clipSizeType,
  clipSizeValue,
  nativeCoinPrice,
  strategy,
  tokenEthRate,
  value,
}: ValidateAmountProps): string | null => {
  const tokenUsdPrice =
    !!tokenEthRate && !!nativeCoinPrice
      ? new BigNumber(nativeCoinPrice).div(new BigNumber(tokenEthRate))
      : new BigNumber(0);

  if (!value || !tokenUsdPrice) {
    return null;
  }

  const numberOfSlices = calculateNumberOfSlices({
    clipSizeType,
    clipSizeValue,
    quantity: value,
    strategy,
  });

  //next we evaluate token slices with token slice Fee (not with all order fee)
  const minAmount = new BigNumber(maxFeeAbsoluteUsd).dividedBy(numberOfSlices).toString();

  if (
    maxFeeAbsoluteUsd &&
    isTokenAmountLessThanMinAmountInUSD(
      clipSizeType,
      clipSizeValue,
      value.toString(),
      tokenUsdPrice.toString(),
      minAmount,
      strategy
    )
  ) {
    return "Fees exceed the amount per slice!";
  }

  if (
    maxFeeAbsoluteUsd &&
    isTokenAmountLessThanMinAmountInUSD(
      clipSizeType,
      clipSizeValue,
      value.toString(),
      tokenUsdPrice.toString(),
      new BigNumber(minAmount).dividedBy(0.3).toString(),
      strategy
    )
  ) {
    return "Fees exceed 30% of the amount per slice!";
  }

  if (
    isTokenAmountLessThanMinAmountInUSD(
      clipSizeType,
      clipSizeValue,
      value.toString(),
      tokenUsdPrice.toString(),
      PAY_TOKEN_MIN_AMOUNT_IN_USD.toString(),
      strategy
    )
  ) {
    return `Min amount is $${PAY_TOKEN_MIN_AMOUNT_IN_USD}`;
  }

  return null;
};
