import React, { useEffect, useState, useRef } from "react";
import {
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";
import { ExternalProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { useAccount, usePublicClient } from "wagmi";
import { PropertiesCard, ReceiveCard } from "../components";
import { usePostTradeCreditsDefi } from "../hooks/use-post-trade-credits-defi";
import { CheckAllowanceDialog } from "./check-allowance-dialog";
import {
  useApproveAnbotoSmartContractMutation,
  useLazyAllowanceQuery,
  useLazyCheckIsValidSignatureV2Query,
  useSignOrderMutation,
} from "@src/store/apis/blockchainApi/anboto-contract-v2";
import { CardDialog } from "@src/components/CardDialog";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { createAnbotoContractOrderV3, getAmountWithSlippage, getSalt } from "@src/pages/defi/utils";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { ClipSizeType, OrderExecutionStrategy, OrderSide } from "@src/store/apis/anbotoApi/types";
import { useLazyGetTokenAllowanceQuery, useSubmitParentDefiOrderMutation } from "@src/store/apis/anbotoApi/defi";
import { RootState } from "@src/store/types";
import { fromDefiFormToDefiOrder } from "@src/pages/defi/order-dto-mappers";
import { useDefiOrderFormContext, useTargetNativeToken, useTokenInfo } from "@src/pages/defi/hooks";
import { MaxGasPrice } from "@src/pages/defi/order-form-card-no-gas/components/max-gas-price";
import { useAppSelector } from "@src/store/hooks";
import { ChainId, EVMBasedAddress } from "@src/pages/defi/types";
import {
  DEFAULT_DEFI_SLIPPAGE,
  MAX_GAS_PRICE_MULTIPLY_RATIO,
  PRICE_PROTECTION_OPTIONS,
  USDT_ETH_ADDRESS,
} from "@src/pages/defi/constants";
import { MissingCredits } from "@src/components/missing-credits";
import { isMultiSliceOrderExecutionStrategy } from "@src/store/apis/anbotoApi/utils";
import { useDcaStrategyQuantity } from "@src/pages/defi/hooks/use-dca-strategy-quantity";

type ReviewDialogProps = DialogProps & {
  onSubmitSuccess(): void;
  sellAmount?: string;
};

export const MAX_UNIT_256 = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

export const ReviewDialog = ({ sellAmount, onSubmitSuccess, ...props }: ReviewDialogProps) => {
  const orderForm = useDefiOrderFormContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAllowanceDialog, setShowAllowanceDialog] = React.useState(false);
  const team_uuid = useAppSelector((state) => state.user.team_uuid);
  const feeManagerEnabled = useAppSelector((state) => state.user.fee_manager_enabled);
  const dialog = useRef<HTMLDivElement>(null);
  const snackbar = useSnackbar();
  const account = useAccount();
  const [signOrder] = useSignOrderMutation();
  const [validateOrder] = useLazyCheckIsValidSignatureV2Query();
  const walletProvider = useSelector((state: RootState) => state.blockchain.walletProvider) as ExternalProvider;
  const provider = walletProvider ? new ethers.providers.Web3Provider(walletProvider, "any") : null;
  const liquiditySources = useSelector((state: RootState) => state.user.liquiditySources);
  const isMevProtection = useAppSelector((state: RootState) => state.user.mev_protection);

  const {
    chainId,
    fromTokenAddress,
    toTokenAddress,
    fromTokenAmount,
    side,
    slippage,
    expiration,
    maxGasPrice,
    gasPriceOption,
    maxFeeAbsolute,
    isFeeTakenInInput,
  } = orderForm.getValues();

  const { targetToken } = useTargetNativeToken({ chainId, fromTokenAddress, toTokenAddress });

  const fromToken = useTokenInfo({ chainId, address: fromTokenAddress as EVMBasedAddress });
  const toToken = useTokenInfo({ chainId, address: toTokenAddress as EVMBasedAddress });

  const tokenDecimals = side === OrderSide.BUY ? toToken?.decimals : fromToken?.decimals;
  const toTokenAmount =
    side === OrderSide.BUY && !!toToken
      ? getAmountWithSlippage(orderForm.getValues("toTokenAmount"), slippage, toToken.decimals)
      : orderForm.getValues("toTokenAmount");

  const [approve] = useApproveAnbotoSmartContractMutation();

  // queries
  const getGasOracleQuery = anbotoApi.useGetGasOracleQuery({ chainId });
  const [submitOrder] = useSubmitParentDefiOrderMutation();
  const publicClient = usePublicClient();
  const tokenBalanceAddress = (side === OrderSide.BUY ? toTokenAddress : fromTokenAddress).toLowerCase();
  // get ongoing_allowance the backend
  const [getTokenAllowance, tokenAllowanceResult] = useLazyGetTokenAllowanceQuery();
  // get current allowance from smart-contract
  const [getContractAllowance, getContractAllowanceResult] = useLazyAllowanceQuery();

  const onClose = () => {
    const networkMaxGasPrice = (getGasOracleQuery?.data?.data[gasPriceOption] || 0) * MAX_GAS_PRICE_MULTIPLY_RATIO;
    orderForm.setValue("maxGasPrice", networkMaxGasPrice.toString());
    props.onClose!({}, "backdropClick");
  };

  const currentPriceProtectionOption = orderForm.getValues("priceProtection");

  const postOrder = async () => {
    if (!account || !sellAmount) {
      return;
    }

    if (!account || !fromToken || !toToken) {
      return;
    }

    try {
      setIsSubmitting(true);

      const slippage = orderForm.getValues("slippage") || DEFAULT_DEFI_SLIPPAGE;
      const strategy = orderForm.getValues("strategy");

      const salt = getSalt();
      const block = (await publicClient!.getBlock()) as { timestamp: bigint };
      const deadline = Number(block.timestamp) + expiration;
      const expirationTimestamp = deadline * 1000;
      const isMarketPriceProtection =
        currentPriceProtectionOption === PRICE_PROTECTION_OPTIONS[0] && isMultiSliceOrderExecutionStrategy(strategy);

      const anbotoContractOrder = createAnbotoContractOrderV3({
        salt,
        slippage,
        inputTokenAddress: side === OrderSide.BUY ? toTokenAddress : fromTokenAddress,
        inputTokenAmount: isDcaStrategy
          ? dcaStrategyQuantity
          : side === OrderSide.BUY
          ? toTokenAmount
          : fromTokenAmount,
        inputTokenDecimals: side === OrderSide.BUY ? toToken.decimals : fromToken.decimals,
        outputTokenAddress: side === OrderSide.BUY ? fromTokenAddress : toTokenAddress,
        outputTokenAmount: side === OrderSide.BUY ? fromTokenAmount : toTokenAmount,
        outputTokenDecimals: side === OrderSide.BUY ? fromToken.decimals : toToken.decimals,
        deadline: expirationTimestamp,
        maxGasPrice,
        maxFeeAbsolute,
        isFeeTakenInInput,
        isMarketPriceProtection,
      });

      const anbotoContractOrderSignature = await signOrder({ anbotoContractOrder, chainId }).unwrap();

      // check if order is invalid
      const validationOrderResult = await validateOrder({
        accountFrom: account.address!,
        chainId: chainId,
        order: anbotoContractOrder,
        signature: anbotoContractOrderSignature,
      });

      if (!validationOrderResult?.data) {
        snackbar.enqueueSnackbar("Order sign validation failed.", { variant: "error" });
        setIsSubmitting(false);
        onClose();

        return;
      }

      snackbar.enqueueSnackbar("Order successfully signed", { variant: "success" });

      const selected_aggregators = Object.keys(liquiditySources).reduce((acc: string[], key) => {
        if (liquiditySources[key].value) acc.push(key);
        return acc;
      }, []);
      const formClipSyzeType = orderForm.getValues("clipSizeType");
      const clipSizeType =
        strategy === OrderExecutionStrategy.ORDER || strategy === OrderExecutionStrategy.LIMIT
          ? ClipSizeType.AUTOMATIC
          : formClipSyzeType === ClipSizeType.AUTOMATIC
          ? ClipSizeType.ABSOLUTE
          : formClipSyzeType;

      const orderToSubmit = fromDefiFormToDefiOrder({
        orderForm: {
          ...orderForm.getValues(),
          clipSizeType,
          expiration: expirationTimestamp,
        },
        anbotoContractOrder,
        anbotoContractOrderSignature,
        chainId: chainId,
        account: account.address!,
        accountUid: team_uuid,
        provider,
        selected_aggregators,
        ...(chainId === ChainId.ETHEREUM && { is_private: isMevProtection || false }),
      });

      await submitOrder(orderToSubmit).unwrap();

      snackbar.enqueueSnackbar("Order submitted", { variant: "success" });
      onSubmitSuccess();
    } catch (e) {
      snackbar.enqueueSnackbar(e?.message || "Some error occurred", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };
  const strategy = orderForm.watch("strategy");

  const isDcaStrategy = strategy === OrderExecutionStrategy.DCA;
  const dcaStrategyQuantity = useDcaStrategyQuantity();

  const getRawAmount = () => {
    const tokenAmount = isDcaStrategy ? dcaStrategyQuantity : side === OrderSide.BUY ? toTokenAmount : fromTokenAmount;

    if (!tokenAmount || !tokenDecimals) {
      return "0";
    }

    return new BigNumber(tokenAmount).multipliedBy(10 ** tokenDecimals).toFixed(0);
  };

  const askForAllowanceAndPostOrder = async (permanently?: boolean) => {
    try {
      const tokenAmount = permanently ? new BigNumber(MAX_UNIT_256).toFixed() : getRawAmount();

      if (tokenBalanceAddress === USDT_ETH_ADDRESS && chainId === ChainId.ETHEREUM) {
        const approveResult = await approve({
          chainId,
          tokenAddress: tokenBalanceAddress,
          tokenAmount: "0",
        }).unwrap();
        if (!approveResult) throw new Error("Something wrong with approve transaction, please try again");
      }

      const approveResult = await approve({ chainId, tokenAddress: tokenBalanceAddress, tokenAmount }).unwrap();
      if (!approveResult) throw new Error("Something wrong with approve transaction, please try again");

      setShowAllowanceDialog(false);

      await postOrder();
    } catch (e) {
      snackbar.enqueueSnackbar("Something wrong with approve transaction, please try again", { variant: "error" });
      setShowAllowanceDialog(false);
    }
  };

  const checkAllowanceAndSubmit = async () => {
    const rawAmount = getRawAmount();
    let ongoingAllowance = "0";

    const { data: allowanceValue } = await getContractAllowance({
      chainId,
      tokenAddress: tokenBalanceAddress,
      account: account.address!,
    });

    const currentAllowance = new BigNumber(allowanceValue || 0).toString();

    try {
      const { allowance_data } = await getTokenAllowance({
        chainId,
        fromTokenAddress: tokenBalanceAddress,
        account: account.address!,
      }).unwrap();

      ongoingAllowance = allowance_data?.ongoing_allowance ?? "0";
    } catch (e) {
      console.log("Ongoing allowance error, we will ask to approve full amount");
    }

    const needAllowance = new BigNumber(rawAmount || 0).isGreaterThan(
      new BigNumber(currentAllowance).minus(ongoingAllowance)
    );

    if (needAllowance) {
      setShowAllowanceDialog(true);
    } else {
      await postOrder();
    }
  };

  /* Uncomment when native support approved
  const switchToWrapped = (path: "fromTokenAddress" | "toTokenAddress", value: string) => {
    orderForm.setValue(path, value);
  };*/

  useEffect(() => {
    if (dialog.current && props.open) {
      dialog.current.scrollTop = 0;
    }
  }, [props.open]);

  const fixedAmount = isDcaStrategy
    ? new BigNumber(dcaStrategyQuantity)
    : new BigNumber(side === OrderSide.BUY ? toTokenAmount : fromTokenAmount);
  const amountToApprove = `${fixedAmount} ${(side === OrderSide.BUY ? toToken?.symbol : fromToken?.symbol) || ""}${
    side === OrderSide.BUY ? " (including slippage)" : ""
  }`;

  const postTradeCredits = usePostTradeCreditsDefi({ chainId, fromTokenAddress, quantity: fromTokenAmount });

  const hasMaxGasError = !!orderForm.formState.errors?.maxGasPrice?.message;
  const confirmButtonDisabled =
    getContractAllowanceResult.isFetching ||
    !getGasOracleQuery.data ||
    isSubmitting ||
    tokenAllowanceResult.isFetching ||
    !!targetToken ||
    hasMaxGasError ||
    postTradeCredits < 0;
  const confirmButtonLoading = isSubmitting || tokenAllowanceResult.isFetching;

  return (
    <>
      <CardDialog {...props}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Order summary</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent ref={dialog}>
          <Stack gap={2}>
            {/* Uncomment when native support approved
            <ReviewNativeCard
              account={account.address!}
              chainId={chainId}
              fromToken={fromToken}
              side={side}
              switchToWrapped={switchToWrapped}
              fromTokenAmount={fromTokenAmount}
              toTokenAmount={toTokenAmount}
              toToken={toToken}
            />*/}
            <PropertiesCard />
            {feeManagerEnabled && postTradeCredits < 0 && <MissingCredits creditsPrediction={postTradeCredits} />}
            <MaxGasPrice />
            <ReceiveCard fromToken={fromToken} toToken={toToken} />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Stack direction="row" justifyContent="space-between" width="100%">
            <AnbotoButton variant="outlined" color="secondary" size="small" onClick={onClose}>
              Edit order
            </AnbotoButton>
            <AnbotoButton
              variant="contained"
              color="primary"
              size="small"
              disabled={confirmButtonDisabled}
              onClick={orderForm.handleSubmit(checkAllowanceAndSubmit)}
              loading={confirmButtonLoading}
            >
              Confirm order
            </AnbotoButton>
          </Stack>
        </DialogActions>
      </CardDialog>
      {showAllowanceDialog && (
        <CheckAllowanceDialog
          token={(side === OrderSide.BUY ? toToken?.symbol : fromToken?.symbol) || ""}
          onClose={() => setShowAllowanceDialog(false)}
          handleUnlock={askForAllowanceAndPostOrder}
          amountToApprove={amountToApprove}
        />
      )}
    </>
  );
};
