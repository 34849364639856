import React from "react";
import { updateGroupDataWithModal, removeWidget } from "../../store/widgets.slice";
import { WidgetBase, WidgetBaseProps } from "../widget-base";
import { WidgetHeaderButton } from "../../components/widget-header-button";
import { DragArea } from "../../components/drag-area";
import { useAppDispatch } from "@src/store/hooks";
import { CefiExchangeMarketType, ParentOrder } from "@src/store/apis/anbotoApi/types";
import { useSupportedExchanges } from "@src/pages/cefi/hooks/use-supported-exchanges";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";
import { getOrderFormDataFromParentOrder } from "@src/pages/cefi/helpers/get-order-form-data-from-parent-order";
import { useCefiOrderFormDefaultValues } from "@src/pages/cefi/order-form/hooks/use-cefi-order-form-default-values";
import { setIsOrderCopy } from "@src/store/slices/singleExchangePageSlice";
import { OTC_EXCHANGES } from "@src/features/otc/constants";
import { OtcOrdersTableCard } from "@src/features/otc/components/otc-orders-table";

const OrderTable = React.memo(OtcOrdersTableCard);
OrderTable.displayName = "OrderTable";

export const OtcOrdersTableFullWidget = React.forwardRef(
  (props: React.PropsWithChildren<WidgetBaseProps>, ref: React.Ref<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const supportedExchanges = useSupportedExchanges({ isDefi: false });
    const defaultValues = useCefiOrderFormDefaultValues();

    const handleSymbolClick = async (x: ParentOrder) => {
      dispatch(setIsOrderCopy(true));

      if (!supportedExchanges.getExchange(x.exchange)) return false;

      const fieldValues = getOrderFormDataFromParentOrder(x, supportedExchanges.getExchanges(), defaultValues);

      const symbol = {
        symbol: x.symbol!,
        exchange: CEFI_EXCHANGE_NAME[x.exchange],
        market_type: x.asset_class!.toLowerCase() as CefiExchangeMarketType,
      };

      dispatch(
        updateGroupDataWithModal({
          data: { ...defaultValues, ...fieldValues, symbol },
          title: getOrderSymbolLabel(symbol),
        })
      );
    };

    return (
      <WidgetBase
        {...props}
        ref={ref}
        component={
          <OrderTable
            onSymbolClick={handleSymbolClick}
            exchangeIds={OTC_EXCHANGES}
            topBarPostfix={
              !props.lock ? (
                <>
                  <DragArea sx={{ position: "absolute", left: 0, top: 0, height: "100%", width: "100%" }} />
                  <WidgetHeaderButton icon="close" onClick={() => dispatch(removeWidget(props.id))} sx={{ ml: 1 }} />
                </>
              ) : null
            }
          />
        }
      />
    );
  }
);

OtcOrdersTableFullWidget.displayName = "OtcOrdersTableFullWidget";
