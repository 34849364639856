import React, { useEffect } from "react";
// import { Grid, InputAdornment, MenuItem, Stack, StackProps, Tooltip, Typography, useTheme } from "@mui/material";
import { Grid, InputAdornment, MenuItem, Stack, StackProps, Tooltip, Typography, useTheme } from "@mui/material";
import { Controller } from "react-hook-form";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import _omit from "lodash/omit";
import { useSnackbar } from "notistack";
import { ADVANCED_OPTIONS_DIALOG_HEIGHT, ADVANCED_OPTIONS_DIALOG_WIDTH } from "../../constants";
import { OtcOrderPropertiesCard } from "../review-dialog/otc-order-properties-card";
import { ReviewDialog } from "../review-dialog";
import { OtcOrderFormFieldValues } from "./hooks/use-otc-order-form";
import { useOtcOrderFormContext } from "./hooks/use-otc-order-form-context";
import { SellBuyControlls } from "./sell-buy-controlls";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { onlyNumbers } from "@src/utils/only-numbers";
import {
  CefiExchangeMarketType,
  ClipSizeType,
  OrderExecutionStrategy,
  OrdersCurrentTab,
  OrderSymbol,
  ParentOrderFormParams,
} from "@src/store/apis/anbotoApi/types";
import { ORDER_EXECUTION_STRATEGY_NAME } from "@src/pages/cefi/order-form/fields/StrategySelect";
import { TradingDurationField } from "@src/pages/cefi/order-form/fields/TradingDurationField";
import { withFlatStyle } from "@src/components/with-flat-style";
import { TradingDurationUnitField } from "@src/pages/cefi/order-form/fields/TradingDurationUnitField";
import { AdvancedSettingsButton } from "@src/pages/cefi/order-form/advanced-settings-button";
import { useDialog } from "@src/hooks/useDialog";
import { ParentOrderAdvancedOptionsDialog } from "@src/pages/cefi/order-form/dialog/parent-order-advanced-options-dialog";
import { MAIN_ACCOUNT_VALUE } from "@src/pages/cefi/constants";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useHideFields } from "@src/pages/cefi/order-form/hooks/use-hide-fields";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { useCefiOrderFormDefaultValues } from "@src/pages/cefi/order-form/hooks/use-cefi-order-form-default-values";
import { getTradingStyle, ParentOrderFormFieldValues } from "@src/pages/cefi/order-form/hooks/use-parent-order-form";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { setParams } from "@src/pages/cefi/orders-table/orders-table.slice";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";
import { usePermissions } from "@src/hooks/use-permissions";
import { VIEW_ONLY_MODE_DESCRIPTION } from "@src/constants/common";
// import { ClipSizeTypeSelect } from "@src/pages/defi/order-form-card-no-gas/fields";
// import { ClipSizeValueField } from "@src/pages/cefi/order-form/fields/ClipSizeValueField";

export interface OtcOrderFormProps extends StackProps {
  onFormReset?: (fields: Partial<OtcOrderFormFieldValues>) => void;
  onAdvancedSettingsClick?: (containerRef: React.RefObject<HTMLDivElement>) => void;
  advancedSettingsDraggable?: { x: number; y: number; id: string; group: string } | null;
  advancedSettingsDraggableOpened?: boolean;
  onAdvancedSettingsClose?: () => void;
}

export const OtcOrderForm = ({
  onAdvancedSettingsClick,
  advancedSettingsDraggable,
  advancedSettingsDraggableOpened,
  onAdvancedSettingsClose,
  ...props
}: OtcOrderFormProps) => {
  const theme = useTheme();
  const form = useOtcOrderFormContext();
  const dispatch = useAppDispatch();
  const advancedSettingsDialog = useDialog();
  const reviewDialog = useDialog();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const permissions = usePermissions();

  const strategy = form.watch("strategy");
  const symbol = form.watch("symbol");
  const quantity = form.watch("quantity");
  const account = form.watch("account");
  const side = form.watch("side");
  const clipSizeType = form.watch("clipSizeType");
  const slippage = form.watch("slippage");

  const minAmount = useAppSelector((state) => state.subscriptions.otcOrderbook[getLivePriceId(symbol)]?.minAmount);
  const maxAmount = useAppSelector((state) => state.subscriptions.otcOrderbook[getLivePriceId(symbol)]?.maxAmount);

  const hideFields = useHideFields(form);
  const [submitOrder, submitOrderMutation] = anbotoApi.useSubmitParentCefiOrderMutation();
  const team_uuid = useAppSelector((state) => state.user.team_uuid)!;
  const triggerPrice = form.watch("triggerPrice");
  const defaultValues = useCefiOrderFormDefaultValues({
    symbol,
    account,
    side,
    strategy,
  });
  const snackbar = useSnackbar();

  const onQuantityChange = (value: string) => {
    // const maxPrecision = exchangeSymbolErrors?.validationData?.amount_precision;
    const quantity = onlyNumbers(value);
    // if (modifyPrecision) quantity = modifyWithMaxAmountPrecision(quantity, maxPrecision);

    form.setValue("quantity", quantity || "");
  };

  const handleAdvancedSettingsClose = () => {
    if (onAdvancedSettingsClose && advancedSettingsDraggable) {
      onAdvancedSettingsClose();
    }

    advancedSettingsDialog.hide();
  };

  const getHiddenFieldKeys = () =>
    Object.keys(hideFields).reduce<string[]>((res, key) => (hideFields[key] ? [...res, key] : res), []);

  const resetForm = (symbol?: OrderSymbol | null) => {
    const strategy = form.getValues("strategy");

    const params = {
      ...defaultValues,
      account,
      side,
      quantity: "",
      toQuantity: "",
      strategy,
      tradingStyle: getTradingStyle(strategy, defaultValues.tradingStyle),
    } as Partial<ParentOrderFormFieldValues>;

    if (symbol) params.symbol = symbol;

    form.reset(params);
  };

  const handleSubmit = async () => {
    try {
      const values = { ...form.getValues(), accountId: team_uuid };
      const body = _omit(values, getHiddenFieldKeys()) as ParentOrderFormParams;

      if (values.symbol) {
        body.account = ![CefiExchangeMarketType.FUTURE, CefiExchangeMarketType.SPOT].includes(values.symbol.market_type)
          ? "CFD"
          : "SPOT";
      }

      await submitOrder(body).unwrap();

      const currentTab =
        triggerPrice || strategy === OrderExecutionStrategy.LIMIT ? OrdersCurrentTab.Trigger : OrdersCurrentTab.Regular;

      dispatch(setParams({ currentTab }));
      resetForm(values.symbol);

      snackbar.enqueueSnackbar("Order submitted", { variant: "success" });
    } catch (error) {
      console.log({ error });
      const errorMessage = error.data ? parseAnbotoRequestError(error.data) : "Order submission failed";

      snackbar.enqueueSnackbar(errorMessage, { variant: "error" });
    }
    reviewDialog.hide();
  };

  const reviewForm = () => {
    const quantity = +form.getValues("quantity");

    if (quantity < minAmount || quantity > maxAmount) return;

    reviewDialog.show();
  };

  const onInvalid = (errors) => {
    console.log(errors, form.getValues());
  };

  const sellBuyClick = () => {
    if (!permissions.trade) return;

    form.setValue("account", MAIN_ACCOUNT_VALUE);

    form.handleSubmit(reviewForm, onInvalid)();
  };

  useEffect(() => {
    if (clipSizeType === ClipSizeType.AUTOMATIC) {
      form.setValue("clipSizeType", ClipSizeType.NB_OF_CHILD_ORDERS);
    }
  }, [clipSizeType]);

  return (
    <Stack
      {...props}
      px={2}
      pt={1}
      pb={2}
      position="relative"
      ref={containerRef}
      overflow={reviewDialog.isOpen ? "hidden" : "auto"}
    >
      <Stack flex={1}>
        <Tooltip title={permissions.trade ? "" : VIEW_ONLY_MODE_DESCRIPTION}>
          <div>
            <SellBuyControlls
              symbol={symbol!}
              onSubmit={sellBuyClick}
              strategy={strategy}
              slippage={slippage}
              amount={quantity}
            />
          </div>
        </Tooltip>
        <Grid container spacing={3} pt={1}>
          <Grid item xs={12} mt={2}>
            <Controller
              name="quantity"
              control={form.control}
              render={({ field, formState }) => {
                // const minError = isQuantityMinSliceErrorExists
                //   ? { message: `Min amount per slice is ${getExchangeSymbolMinAmountDataQuery.data!.min_amount}` }
                //   : null;

                const errorMessage = formState.errors.quantity?.message;
                const minMaxError =
                  +quantity < minAmount
                    ? `Min amount is ${minAmount}`
                    : +quantity > maxAmount
                    ? `Max amount is ${maxAmount}`
                    : "";
                return (
                  <QuantityField
                    {...field}
                    size="small"
                    error={!!errorMessage || !!(+quantity && minMaxError)}
                    helperText={errorMessage || (!!+quantity && minMaxError)}
                    onChange={(e) => onQuantityChange(e.target.value)}
                    label="Quantity"
                    variant="outlined"
                    fullWidth
                    labelStyle="dynamic"
                    placeholder={minAmount ? `${minAmount} - ${maxAmount}` : ""}
                    sx={{
                      position: "relative",
                      "& .MuiFormHelperText-root": {
                        position: "absolute",
                        bottom: -19,
                        whiteSpace: "nowrap",
                      },
                      "& input::placeholder": { fontSize: 12, opacity: 0.35 },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ cursor: "default" }}>
                          <Stack direction="row" gap={0.25} alignItems="center">
                            <Typography variant="body2" fontSize={12} color={theme.palette.text.secondary}>
                              {symbol?.coin1}
                            </Typography>
                            {/* <Tooltip
                              title={
                                <Stack>
                                  <Stack>{exchangeSymbolData?.data ? `Min: ${minAmount} Max: ${maxAmount}` : ""}</Stack>
                                  <Stack>
                                    {getSymbolAccountBalanceQuery.isFetching
                                      ? "Loading balance..."
                                      : balanceCoin1 !== undefined
                                      ? `${symbol?.coin1}: ` + balanceCoin1
                                      : ""}
                                  </Stack>
                                  <Stack>
                                    {getSymbolAccountBalanceQuery.isFetching
                                      ? ""
                                      : balanceCoin2 !== undefined
                                      ? `${symbol?.coin2}: ` + balanceCoin2
                                      : ""}
                                  </Stack>
                                </Stack>
                              }
                            >
                              <InfoOutlinedIcon sx={{ fontSize: 12, color: theme.palette.text.disabled }} />
                            </Tooltip> */}
                          </Stack>
                        </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="strategy"
              control={form.control}
              render={({ field }) => {
                return (
                  <StrategyField
                    {...field}
                    select
                    labelStyle="dynamic"
                    label={
                      <Stack direction="row" gap={1} alignItems="center">
                        <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
                          Strategy
                        </Typography>
                      </Stack>
                    }
                    size="small"
                    fullWidth
                    sx={{ "& .MuiSelect-select": { paddingRight: 22 } }}
                  >
                    {[OrderExecutionStrategy.ORDER, OrderExecutionStrategy.TWAP].map((x) => (
                      <MenuItem key={x} value={x}>
                        {[ORDER_EXECUTION_STRATEGY_NAME[x]]}
                      </MenuItem>
                    ))}
                  </StrategyField>
                );
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {strategy === OrderExecutionStrategy.TWAP && (
              <Controller
                name="tradingDuration"
                control={form.control}
                render={({ field, formState }) => (
                  <StyledTradingDurationField
                    {...field}
                    onChange={(e) => field.onChange(e.target.value.replace(/[^\d]/g, ""))}
                    error={!!formState.errors.tradingDuration}
                    helperText={formState.errors.tradingDuration?.message}
                    labelStyle="dynamic"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ "& .MuiInputBase-input": { paddingRight: 0 } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Controller
                            name="tradingDurationUnit"
                            control={form.control}
                            render={({ field }) => (
                              <TradingDurationUnitField
                                {...field}
                                sx={{
                                  "& .MuiSelect-select": { padding: "0 30px 0 0 !important" },
                                }}
                              />
                            )}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
            {strategy === OrderExecutionStrategy.FOK && (
              <Controller
                name="slippage"
                control={form.control}
                render={({ field, formState }) => (
                  <SlippageField
                    {...field}
                    size="small"
                    onChange={(e) => field.onChange(e.target.value.replace(/[^\d]/g, ""))}
                    error={!!formState.errors.slippage}
                    helperText={formState.errors.slippage?.message}
                    label="Slippage"
                    labelStyle="dynamic"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{ "& .MuiInputBase-input": { paddingRight: 0 } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="body2" fontSize={12} color={(theme) => theme.palette.text.secondary}>
                            bps
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
          </Grid>
          {/* {strategy === OrderExecutionStrategy.TWAP && (
            <Grid item xs={6}>
              <Controller
                name="clipSizeType"
                control={form.control}
                render={({ field, formState }) => {
                  console.log({ field });
                  return (
                    <ClipSizeTypeSelectField
                      {...field}
                      excludeTypes={[ClipSizeType.AUTOMATIC]}
                      error={!!formState.errors.clipSizeType}
                      helperText={formState.errors.clipSizeType?.message}
                      labelStyle="dynamic"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  );
                }}
              />
            </Grid>
          )}

          {strategy === OrderExecutionStrategy.TWAP && (
            <Grid item xs={6}>
              <Controller
                name="clipSizeValue"
                control={form.control}
                render={({ field, formState }) => {
                  const clipSizeError = exchangeSymbolErrors.errors.clipSize;

                  return (
                    <StyledClipSizeValueField
                      {...field}
                      error={!!formState.errors.clipSizeValue || !!clipSizeError}
                      helperText={formState.errors.clipSizeValue?.message || clipSizeError}
                      labelStyle="dynamic"
                      label={clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS ? "Number of childs" : "Clip size value"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  );
                }}
              />
            </Grid>
          )} */}
        </Grid>
      </Stack>
      {strategy === OrderExecutionStrategy.TWAP && (
        <AdvancedSettingsButton
          onClickPopup={() => onAdvancedSettingsClick && onAdvancedSettingsClick(containerRef)}
          draggable={!!advancedSettingsDraggable}
          opened={advancedSettingsDraggableOpened || advancedSettingsDialog.isOpen}
          errors={""}
        />
      )}
      <ParentOrderAdvancedOptionsDialog
        openInPopupClick={() => onAdvancedSettingsClick && onAdvancedSettingsClick(containerRef)}
        draggable={advancedSettingsDraggable}
        draggableOpened={advancedSettingsDraggableOpened}
        open={advancedSettingsDialog.isOpen}
        onClose={handleAdvancedSettingsClose}
        hiddenFields={["wouldStyle", "placementMode", "clipSizeType", "clipSizeValue"]}
        width={ADVANCED_OPTIONS_DIALOG_WIDTH}
        height={ADVANCED_OPTIONS_DIALOG_HEIGHT}
      />
      {!!symbol && !!quantity && reviewDialog.isOpen && (
        <ReviewDialog
          open={reviewDialog.isOpen}
          onClose={reviewDialog.hide}
          onSubmit={handleSubmit}
          isOrderConfirmLoading={submitOrderMutation.isLoading}
          sx={{ zIndex: 11, background: (theme) => theme.palette.background.paper }}
        >
          <OtcOrderPropertiesCard />
        </ReviewDialog>
      )}
    </Stack>
  );
};

const StyledTradingDurationField = withFlatStyle(TradingDurationField);
const StrategyField = withFlatStyle(AnbotoTextField);
const QuantityField = withFlatStyle(AnbotoTextField);
const SlippageField = withFlatStyle(AnbotoTextField);
// const ClipSizeTypeSelectField = withFlatStyle(ClipSizeTypeSelect);
// const StyledClipSizeValueField = withFlatStyle(ClipSizeValueField);
