import React from "react";
import { TooltipProps } from "recharts";
import { format } from "date-fns";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

const HistoricalDataTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const dateFormatter = (date) => {
    return format(new Date(date * 1000), "Pp");
  };

  const theme = useTheme();

  if (active && payload && payload.length) {
    const payloadData = payload[0]?.payload;
    const min = payloadData["min"];
    const lowerQuartile = payloadData["bottomWhisker"] + min;
    const median = payloadData["bottomBox"] + lowerQuartile;
    const upperQuartile = payloadData["topBox"] + median;
    const max = payloadData["topWhisker"] + upperQuartile;
    const time = dateFormatter(payloadData["time"]);

    return (
      <Box sx={{ background: theme.custom.colors.gray, padding: 1, borderRadius: 1 }}>
        <Stack direction="column" alignItems="left">
          <Typography>
            <Typography textTransform="uppercase" variant="caption" color={theme.palette.text.secondary}>
              Max:
            </Typography>{" "}
            {parseFloat((max * 100).toFixed(4))}%
          </Typography>
          <Typography>
            <Typography textTransform="uppercase" variant="caption" color={theme.palette.text.secondary}>
              Upper quartile:
            </Typography>{" "}
            {parseFloat((upperQuartile * 100).toFixed(4))}%
          </Typography>
          <Typography>
            <Typography textTransform="uppercase" variant="caption" color={theme.palette.text.secondary}>
              Median:
            </Typography>{" "}
            {parseFloat((median * 100).toFixed(4))}%
          </Typography>
          <Typography>
            <Typography textTransform="uppercase" variant="caption" color={theme.palette.text.secondary}>
              Lower quartile:
            </Typography>{" "}
            {parseFloat((lowerQuartile * 100).toFixed(4))}%
          </Typography>
          <Typography>
            <Typography textTransform="uppercase" variant="caption" color={theme.palette.text.secondary}>
              Min:
            </Typography>{" "}
            {parseFloat((min * 100).toFixed(4))}%
          </Typography>
        </Stack>

        <Typography>{time}</Typography>
      </Box>
    );
  }

  return null;
};

export default HistoricalDataTooltip;
