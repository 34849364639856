import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type TradingDurationFieldProps = ControllerRenderProps & AnbotoTextFieldProps & { noLabel?: boolean };

export const TradingDurationField = forwardRef((props: TradingDurationFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    inputRef={ref}
    size="small"
    fullWidth
    label={props.noLabel ? "" : props.label ? props.label : "Duration"}
    InputProps={{ ...props.InputProps, sx: { pr: 0 } }}
  />
));
TradingDurationField.displayName = "TradingDurationField";
