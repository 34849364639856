import { CompetitionConfig } from "@src/pages/competitions";

export const CONFIG: CompetitionConfig = {
  COMPETITION_ID: "Bybit December",
  REWARDS_HEADER: "Minimum volume",
  IS_REWARDS_COLUMN: true,
  REWARDS: [
    { header: "Top 3", headerColor: "#ECAC27", minVolume: "50K" },
    { header: "Top 4 - Top 10", headerColor: "#8A9296", minVolume: "20K" },
    { header: "Top 10 - Top 12", headerColor: "#2C72E3", minVolume: "10K" },
  ],
  PRIZE_POOL: [
    { volume: "5M", prizePool: "$1K" },
    {
      volume: "10M",
      prizePool: "$5K",
    },
    {
      volume: "20M",
      prizePool: "$10K",
    },
    {
      volume: "50M",
      prizePool: "$15K",
    },
    {
      volume: "80M",
      prizePool: "$20K",
    },
  ],
  START_TIME: "Jan 10th 2024 00:00 UTC",
  END_TIME: "Jan 31th 2024 23:59 UTC",
  PRIZE_POOL_SIZE: 20000,
  EXCHANGE_NAME: "Bybit",
};
