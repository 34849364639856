import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Layout } from "./components/layout";
import { TwoFa } from "@src/pages/settings/two-fa";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AccountTypes, OnboardingSteps, setCurrentOnboardingStep } from "@src/pages/auth/onboarding.slice";
import { useAppSelector } from "@src/store/hooks";
import { useAuth } from "@src/features/auth/hooks/use-auth";

const TwoFactorAuth = () => {
  const accountType = useAppSelector((state) => state.onboarding.accountType);
  const selectedAccountType = useAppSelector((state) => state.onboarding.selectedAccountType);
  const { mfa } = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentOnboardingStep(OnboardingSteps.ENABLE_2FA));
  }, []);

  const navigate = useNavigate();
  const redirectUrl =
    accountType === AccountTypes.INDIVIDUAL && selectedAccountType === AccountTypes.INDIVIDUAL
      ? "/settings/profile"
      : "/settings/account";

  return (
    <Layout>
      <Stack alignItems="center" paddingTop="10%">
        <Typography paddingBottom={5.5} textAlign="center" fontWeight={500} fontSize={32}>
          Enable 2FA
        </Typography>

        {mfa.enabled ? (
          <>
            <TwoFa headerSx={{ display: "none" }} containerSX={{ border: "none" }} disableTurnOff={true} />
            <AnbotoButton
              onClick={() => navigate(redirectUrl)}
              tabIndex={1}
              sx={{ alignSelf: "right", "&:focus": { outline: 1 }, width: 160, top: -150 }}
              size="medium"
              variant="contained"
            >
              Next
            </AnbotoButton>
          </>
        ) : (
          <Stack borderRadius={1} padding={4} paddingTop={0} width={955} sx={{ backgroundColor: "#031116" }}>
            <TwoFa headerSx={{ display: "none" }} containerSX={{ border: "none" }} disableTurnOff={true} />
            <Stack flexDirection="row" justifyContent="flex-end">
              <AnbotoButton
                onClick={() => navigate(redirectUrl)}
                tabIndex={1}
                sx={{ alignSelf: "right", "&:focus": { outline: 1 }, width: 160 }}
                size="medium"
                variant="outlined"
              >
                Skip for now
              </AnbotoButton>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Layout>
  );
};

export default TwoFactorAuth;
