import { Checkbox, FormControlLabel, styled } from "@mui/material";

export const CheckboxForm = styled(FormControlLabel)(({ theme }) => ({
  "&.MuiFormControlLabel-root": {
    marginRight: 0,
    gap: theme.spacing(1),
  },
  "&.MuiFormControlLabel-root .MuiFormControlLabel-label": {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "18px",
    color: "#8A9296",
  },
}));

export const CheckboxControl = styled(Checkbox)(() => ({
  "&.MuiButtonBase-root.MuiCheckbox-root": {
    padding: 0,
  },
}));
