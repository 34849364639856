import React, { useEffect } from "react";
import { ClickAwayListener, Box, styled, TextField, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { withLightFlatStyle } from "../with-flat-style";
import { useDebounce } from "@src/hooks/use-debounce";
import { styledOptions } from "@src/utils/styled-options";

type TableSearchProps = {
  onSearch?: (value: string) => void;
  loading?: boolean;
  placeholder: string;
};

export const TableSearch = ({ onSearch, placeholder }: TableSearchProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [opened, setOpened] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const debouncedValue = useDebounce(searchText, 500);

  useEffect(() => {
    onSearch && onSearch(debouncedValue);
  }, [debouncedValue]);

  const toggleInput = () => {
    if (opened) {
      setSearchText("");
      onSearch && onSearch("");
    }

    setOpened(!opened);
    if (!opened) inputRef?.current?.focus({ preventScroll: true });
  };

  const handleClickAway = () => {
    if (!searchText) {
      setOpened(false);
      onSearch && onSearch("");
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Root opened={opened}>
        <Input
          value={searchText}
          size="small"
          inputRef={inputRef}
          placeholder={placeholder}
          onChange={(e) => setSearchText(e.target.value)}
          inputProps={{ sx: { paddingRight: 0 } }}
          sx={{ width: opened ? 160 : 0, transition: "width .2s ease" }}
          onKeyDown={(e) => e.key === "Enter" && onSearch && onSearch(searchText)}
        />
        <IconButton disableRipple size="small" component="span" onClick={toggleInput} sx={{ width: 32, height: 32 }}>
          {opened ? (
            <ClearIcon sx={{ width: 17, height: 17, color: (theme) => theme.palette.text.primary }} />
          ) : (
            <SearchIcon sx={{ width: 17, height: 17, color: (theme) => theme.palette.text.primary }} />
          )}
        </IconButton>
      </Root>
    </ClickAwayListener>
  );
};

const Root = styled(
  Box,
  styledOptions
)<{ opened: boolean }>(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  flexDirection: "row",
  minWidth: 32,
  position: "relative",
  flexShrink: 0,
  background: theme.custom.background.secondary,
}));

const Input = withLightFlatStyle(
  styled(TextField)({
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      outline: "none",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      height: 32,
      lineHeight: 32,
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 14,
    },
    "& .MuiOutlinedInput-root": {
      padding: 0,
      lineHeight: 0,
    },
  })
);
