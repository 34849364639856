import { useEffect } from "react";
import { useTokenAccountBalanceQuery } from "@src/store/apis/blockchainApi";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";

export const useOrderFormFromTokenBalance = (): void => {
  const orderForm = useDefiOrderFormContext();
  const account = orderForm.watch("account");
  const chainId = orderForm.watch("chainId");
  const formFromTokenBalance = orderForm.watch("fromTokenBalance");
  const fromTokenAddress = orderForm.watch("fromTokenAddress");

  const { data: fromTokenBalance } = useTokenAccountBalanceQuery(
    { account: account!, chainId: chainId!, tokenAddress: fromTokenAddress! },
    { skip: !account || !chainId || !fromTokenAddress }
  );

  useEffect(() => {
    if (formFromTokenBalance === fromTokenBalance) {
      return;
    }

    orderForm.setValue("fromTokenBalance", fromTokenBalance?.toString() ?? "");
  }, [fromTokenBalance, formFromTokenBalance]);
};
