import React, { forwardRef } from "react";
import { MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { PlacementMode } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const PLACEMENT_MODE: PlacementMode[] = [PlacementMode.DEFAULT, PlacementMode.TIGHT, PlacementMode.CUSTOM];

export const PLACEMENT_MODE_LABEL = {
  [PlacementMode.DEFAULT]: "Default",
  [PlacementMode.TIGHT]: "Tight",
  [PlacementMode.CUSTOM]: "Custom",
};

export const PlacementModeSelect = forwardRef((props: AnbotoTextFieldProps, ref) => (
  <AnbotoTextField
    inputRef={ref}
    select
    label={
      <Stack direction="row" gap={1} alignItems="center">
        <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
          {props.label || "Maker Placement"}
        </Typography>
        <Tooltip title="Allows you to choose the placement level in the order book. Default maker placement places your order on top of the order book. You can manually control your maker placement by choosing “Custom”.">
          <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
        </Tooltip>
      </Stack>
    }
    size="small"
    fullWidth
    {...props}
  >
    {PLACEMENT_MODE.map((x) => (
      <MenuItem key={x} value={x}>
        {PLACEMENT_MODE_LABEL[x]}
      </MenuItem>
    ))}
  </AnbotoTextField>
));

PlacementModeSelect.displayName = "PlacementModeSelect";
