import React, { useEffect } from "react";
import { Box, Grid, IconButton, InputAdornment, Stack, Tooltip, Typography } from "@mui/material";
import { CompareArrows } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { TokenBalance } from "@src/pages/defi/order-form-card-no-gas/token-balance";
import { TokenPrice } from "@src/pages/defi/order-form-card-no-gas/token-price";
import {
  ClipSizeType,
  GetBaseCoinPriceResult,
  GetSwapQuoteWithPreTradeAnalysisDefiAwareData,
  GetSwapQuoteWithPreTradeAnalysisDefiAwareParams,
  GetSwapQuoteWithPreTradeAnalysisDefiAwareResult,
  OrderExecutionStrategy,
  OrderSide,
} from "@src/store/apis/anbotoApi/types";
import { TokenAmountControl } from "@src/pages/defi/order-form-card-no-gas/components/token-amount-control";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AdvancedSettingsError } from "@src/components";
import PreTradeAnalysis from "@src/pages/defi/order-form-card-no-gas/components/pre-trade-analysis/pre-trade-analysis";
import { useDefiOrderFormContext, useTokenInfo } from "@src/pages/defi/hooks";
import { EVMBasedAddress } from "@src/pages/defi/types";
import { withFlatStyle } from "@src/components/with-flat-style";
import { ClipSizeTypeSelect } from "@src/pages/defi/order-form-card-no-gas/fields";
import { getOppositeAmount } from "@src/pages/defi/utils";
import { AdvancedOptionsDialog } from "@src/pages/defi/order-form-card-no-gas/dialog";
import { usePreviousPersisted } from "@src/hooks/use-previous-persisted";
import { ClipSizeValueField } from "@src/pages/cefi/order-form/fields/ClipSizeValueField";
import { TradingDurationUnitField } from "@src/pages/cefi/order-form/fields/TradingDurationUnitField";
import { getTradingDurationSec } from "@src/pages/cefi/order-form/utils";
import { TradingDurationField } from "@src/pages/cefi/order-form/fields/TradingDurationField";
import LimitPriceControl from "@src/pages/defi/order-form-card-no-gas/components/limit-price-control/limit-price-control";

export const DEFI_ADVANCED_SETTINGS_DIALOG_FIELDS = ["triggerCondition", "triggerPrice", "extendDuration"];

type MainStrategiesFormProps = {
  nativeCoinPrice: GetBaseCoinPriceResult | undefined;
  quoteData: GetSwapQuoteWithPreTradeAnalysisDefiAwareData | undefined;
  isFetchingQuoteData: boolean;
  isNativeCoinPriceFetching: boolean;
  advancedSettingsDialog: { hide: () => void; isOpen: boolean; show: () => void };
  getQuoteData: (
    arg: GetSwapQuoteWithPreTradeAnalysisDefiAwareParams,
    preferCacheValue?: boolean | undefined
  ) => QueryActionCreatorResult<
    QueryDefinition<
      GetSwapQuoteWithPreTradeAnalysisDefiAwareParams,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
      never,
      GetSwapQuoteWithPreTradeAnalysisDefiAwareResult,
      "anbotoApi"
    >
  >;
};

const MainStrategiesForm = ({
  isFetchingQuoteData,
  getQuoteData,
  quoteData,
  nativeCoinPrice,
  isNativeCoinPriceFetching,
  advancedSettingsDialog,
}: MainStrategiesFormProps) => {
  const orderForm = useDefiOrderFormContext();

  const chainId = orderForm.watch("chainId");
  const fromTokenAddress = orderForm.watch("fromTokenAddress");
  const slippage = orderForm.watch("slippage");
  const childSlippage = orderForm.watch("childSlippage");
  const toTokenAmount = orderForm.watch("toTokenAmount");
  const toTokenAddress = orderForm.watch("toTokenAddress");
  const strategy = orderForm.watch("strategy");
  const side = orderForm.watch("side");
  const clipSizeType = orderForm.watch("clipSizeType");
  const clipSizeValue = orderForm.watch("clipSizeValue");
  const tradingDuration = orderForm.watch("tradingDuration");
  const tradingDurationUnit = orderForm.watch("tradingDurationUnit");
  const fromTokenAmount = orderForm.watch("fromTokenAmount");
  const limitPrice = orderForm.watch("limitPrice");

  const prevFromTokenAmount = usePreviousPersisted(fromTokenAmount, (prev, next) => !next || prev === next);

  const durationSeconds = tradingDuration
    ? getTradingDurationSec(Number(tradingDuration), tradingDurationUnit)
    : undefined;
  const fromToken = useTokenInfo({ chainId, address: fromTokenAddress as EVMBasedAddress });
  const toToken = useTokenInfo({ chainId, address: toTokenAddress as EVMBasedAddress });

  const isSameTokens = fromToken?.address === toToken?.address;
  const isMultiSliceStrategy = [OrderExecutionStrategy.TWAP, OrderExecutionStrategy.ICEBERG].includes(strategy);

  const advancedSettingsErrors = Object.keys(orderForm.formState.errors).filter((key) =>
    DEFI_ADVANCED_SETTINGS_DIALOG_FIELDS.includes(key)
  );
  const hasAdvancedSettingsErrors = advancedSettingsErrors.length > 0;

  const handleTokenPairSwapClick = async () => {
    if (toTokenAddress === fromTokenAddress) return;

    orderForm.setValue("fromTokenAddress", toTokenAddress);
    orderForm.setValue("toTokenAddress", fromTokenAddress);
    orderForm.setValue("fromTokenAmount", toTokenAmount);
    const baseTokenAddress = orderForm.getValues("baseTokenAddress");

    const sideAmount = await getOppositeAmount({
      chainId,
      getQuoteData,
      side,
      slippage:
        strategy === OrderExecutionStrategy.ORDER || strategy === OrderExecutionStrategy.LIMIT
          ? slippage
          : childSlippage || "0",
      fromToken: toToken,
      toToken: fromToken,
      value: toTokenAmount,
      clipSizeType,
      clipSizeVal: clipSizeValue,
      durationSeconds,
      strategy,
      limitPrice,
      baseTokenAddress,
    });

    orderForm.setValue("toTokenAmount", sideAmount);

    await orderForm.trigger();
  };

  const handleClipSizeTypeChange = (e) => {
    const newClipSizeType: ClipSizeType = e.target.value;

    if (newClipSizeType !== ClipSizeType.AUTOMATIC) {
      orderForm.setValue("clipSizeValue", "");
    }

    orderForm.setValue("clipSizeType", newClipSizeType);
  };

  useEffect(() => {
    orderForm.setValue("clipSizeType", ClipSizeType.AUTOMATIC);
  }, [fromTokenAddress?.toLowerCase()]);

  useEffect(() => {
    if (
      fromTokenAmount &&
      prevFromTokenAmount &&
      fromTokenAmount !== prevFromTokenAmount &&
      clipSizeValue &&
      clipSizeType === ClipSizeType.ABSOLUTE
    ) {
      const clipSize = (+clipSizeValue / +prevFromTokenAmount) * +fromTokenAmount;

      orderForm.setValue("clipSizeValue", clipSize.toString());
    }
  }, [prevFromTokenAmount]);

  // todo: reset fields based on new selected strategy
  useEffect(() => {
    if (strategy === OrderExecutionStrategy.ORDER || strategy === OrderExecutionStrategy.LIMIT) {
      orderForm.setValue("triggerPrice", "");
    }
  }, [strategy]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: (theme) => theme.custom.background.default,
          borderRadius: (theme) => theme.spacing(0.5),
          padding: (theme) => `${theme.spacing(1)} ${theme.spacing(2)}`,
          margin: (theme) => `${theme.spacing(2)} 0`,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: "6px", justifyContent: "space-between" }}>
          <TokenBalance chainId={chainId} tokenAddress={fromTokenAddress} />
          <TokenPrice
            isFetching={isNativeCoinPriceFetching || isFetchingQuoteData}
            tokenSymbol={fromToken?.symbol}
            nativeCoinPrice={nativeCoinPrice?.usd}
            tokenEthRate={side === OrderSide.BUY ? quoteData?.buyTokenToEthRate : quoteData?.sellTokenToEthRate}
          />
        </Box>

        <TokenAmountControl
          addressFieldName="fromTokenAddress"
          amountFieldName="fromTokenAmount"
          key="fromToken"
          isFetchingQuoteData={isFetchingQuoteData}
          getQuoteData={getQuoteData}
          fromToken={fromToken}
          toToken={toToken}
          disabled={isSameTokens}
        />
        {strategy === OrderExecutionStrategy.LIMIT && (
          <LimitPriceControl quoteData={quoteData} quoteDataFetching={isFetchingQuoteData} />
        )}
        <Box textAlign="center" mt={0.5} mb={0.5}>
          <IconButton
            disabled={isFetchingQuoteData}
            onClick={handleTokenPairSwapClick}
            size="small"
            color="secondary"
            sx={{ transform: "rotate(90deg)", color: (theme) => theme.palette.text.secondary }}
          >
            <CompareArrows />
          </IconButton>
        </Box>
        <TokenAmountControl
          addressFieldName="toTokenAddress"
          amountFieldName="toTokenAmount"
          key="toToken"
          isFetchingQuoteData={isFetchingQuoteData}
          getQuoteData={getQuoteData}
          fromToken={fromToken}
          toToken={toToken}
          disabled={isSameTokens}
        />

        <Box sx={{ display: "flex", alignItems: "center", mt: "6px", justifyContent: "space-between" }}>
          <TokenBalance chainId={chainId} tokenAddress={toTokenAddress} />
          <TokenPrice
            isFetching={isNativeCoinPriceFetching || isFetchingQuoteData}
            tokenSymbol={toToken?.symbol}
            nativeCoinPrice={nativeCoinPrice?.usd}
            tokenEthRate={side === OrderSide.BUY ? quoteData?.sellTokenToEthRate : quoteData?.buyTokenToEthRate}
          />
        </Box>
      </Box>
      <Grid container spacing={2} mb={2}>
        {strategy === OrderExecutionStrategy.TWAP && (
          <Grid item xs={6}>
            <Controller
              name="tradingDuration"
              control={orderForm.control}
              render={({ field, formState }) => (
                <StyledTradingDurationField
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value.replace(/[^\d]/g, ""));
                    orderForm.clearErrors("tradingDuration");
                  }}
                  error={!!formState.errors.tradingDuration}
                  helperText={formState.errors.tradingDuration?.message}
                  labelStyle="dynamic"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Controller
                          name="tradingDurationUnit"
                          control={orderForm.control}
                          render={({ field }) => <TradingDurationUnitField {...field} />}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />{" "}
          </Grid>
        )}

        {isMultiSliceStrategy && (
          <Grid item xs={6}>
            <Controller
              name="clipSizeType"
              control={orderForm.control}
              render={({ field, formState }) => (
                <StyledClipSizeTypeSelect
                  {...field}
                  className="clipsize-type-select"
                  error={!!formState.errors.clipSizeType}
                  helperText={formState.errors.clipSizeType?.message}
                  onChange={handleClipSizeTypeChange}
                  labelStyle="dynamic"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        )}
        {isMultiSliceStrategy && clipSizeType !== ClipSizeType.AUTOMATIC && (
          <Grid item xs={6}>
            <Controller
              name="clipSizeValue"
              control={orderForm.control}
              render={({ field, formState }) => (
                <StyledClipSizeValueField
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    orderForm.clearErrors("clipSizeValue");
                  }}
                  error={!!formState.errors.clipSizeValue}
                  helperText={formState.errors.clipSizeValue?.message}
                  labelStyle="dynamic"
                  label={clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS ? "Number of childs" : "Clip size value"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        )}
        {/* <Grid item xs={6}> */}
        {/* Hide limit price for the first time https://anbotolabs.atlassian.net/browse/FE-868 */}
        {/* {strategy === OrderExecutionStrategy.ORDER && (
                <Controller
                  name="type"
                  control={orderForm.control}
                  render={({ field }) => <TypeField {...field} labelStyle="dynamic" disabled />}
                />
              )}

              {strategy === OrderExecutionStrategy.ORDER && type === OrderType.LIMIT && (
                <Controller
                  name="limitPrice"
                  control={orderForm.control}
                  render={({ field, formState }) => (
                    <LimitPriceField
                      {...field}
                      error={!!formState.errors.limitPrice}
                      helperText={formState.errors.limitPrice?.message}
                      labelStyle="dynamic"
                    />
                  )}
                />
              )} */}
        {/* </Grid> */}
        {clipSizeType === ClipSizeType.AUTOMATIC && <Grid item xs={6}></Grid>}
        <Grid item xs={6}>
          <Stack sx={{ float: "right" }}>
            <AnbotoButton
              sx={{
                padding: 0.5,
                borderRadius: 0.4,
                height: 32,
                width: 120,
                fontSize: 12,
                background: "#232c2f",
                color: "#899297",
                textAlign: "right",
              }}
              onClick={advancedSettingsDialog.show}
              fullWidth
            >
              <TuneOutlinedIcon sx={{ height: 14, position: "absolute", left: 3 }} />
              <Typography paddingLeft={1} variant="caption">
                Slippage: {isMultiSliceStrategy ? childSlippage : slippage}%
              </Typography>
              {hasAdvancedSettingsErrors && (
                <Tooltip title={advancedSettingsErrors.join("\r\n")}>
                  <AdvancedSettingsError />
                </Tooltip>
              )}
            </AnbotoButton>
          </Stack>
        </Grid>
      </Grid>
      <PreTradeAnalysis />
      <Box p={2} pt={0} display="flex" flexDirection="column" flex={1} overflow="hidden">
        <AdvancedOptionsDialog open={advancedSettingsDialog.isOpen} onClose={advancedSettingsDialog.hide} />
      </Box>
    </>
  );
};

export default MainStrategiesForm;

const StyledClipSizeTypeSelect = withFlatStyle(ClipSizeTypeSelect);
const StyledClipSizeValueField = withFlatStyle(ClipSizeValueField);
const StyledTradingDurationField = withFlatStyle(TradingDurationField);
