import _cloneDeep from "lodash/cloneDeep";
import GridLayout from "react-grid-layout";
import { BREAKPOINTS } from "../constants";
import { WidgetsLayout } from "../types";
import { compactBpLayout } from "./compact-bp-layout";

export const compactPageLayout = (pageLayout: WidgetsLayout) => {
  const _pageLayout = _cloneDeep(pageLayout);

  Object.keys(BREAKPOINTS).forEach((bp) => {
    const _layout = Object.values(pageLayout)
      .map(({ layout }) => {
        return layout[bp];
      })
      .filter(Boolean);

    const compacted = compactBpLayout(_layout);

    compacted.forEach((l: GridLayout.Layout) => {
      _pageLayout[l.i].layout[bp] = l;
    });
  });

  return _pageLayout;
};
