import React from "react";
import { Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import {
  ClipSizeTypeSelect,
  ExpirationField,
  SlippageField,
  StrategyField,
} from "@src/pages/defi/order-form-card-no-gas/fields";
import { ClipSizeType } from "@src/store/apis/anbotoApi/types";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import { TradingDurationField } from "@src/pages/cefi/order-form/fields/TradingDurationField";
import { TradingDurationUnitField } from "@src/pages/cefi/order-form/fields/TradingDurationUnitField";

const DefiPreferencesForm = () => {
  const defiPreferencesForm = useDefiOrderFormContext();

  return (
    <Stack width={750} alignSelf="center">
      <Typography my={3} variant="h6" fontWeight="bold">
        DEFI
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Controller
            name="strategy"
            control={defiPreferencesForm.control}
            render={({ field }) => <StrategyField noInfo {...field} />}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="tradingDuration"
            control={defiPreferencesForm.control}
            render={({ field, formState }) => (
              <TradingDurationField
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value.replace(/[^\d]/g, ""));
                  defiPreferencesForm.clearErrors("tradingDuration");
                }}
                error={!!formState.errors.tradingDuration}
                helperText={formState.errors.tradingDuration?.message}
                labelStyle="dynamic"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Controller
                        name="tradingDurationUnit"
                        control={defiPreferencesForm.control}
                        render={({ field }) => <TradingDurationUnitField {...field} />}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="slippage"
            control={defiPreferencesForm.control}
            render={({ field, formState }) => (
              <SlippageField
                {...field}
                error={!!formState.errors.slippage}
                helperText={formState.errors.slippage?.message}
                labelStyle="dynamic"
                label="Parent slippage"
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="childSlippage"
            control={defiPreferencesForm.control}
            render={({ field, formState }) => (
              <SlippageField
                {...field}
                error={!!formState.errors.childSlippage}
                helperText={formState.errors.childSlippage?.message}
                labelStyle="dynamic"
                label="Child slippage"
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="clipSizeType"
            control={defiPreferencesForm.control}
            render={({ field, formState }) => (
              <ClipSizeTypeSelect
                {...field}
                excludeTypes={[ClipSizeType.NB_OF_CHILD_ORDERS]}
                error={!!formState.errors.clipSizeType}
                helperText={formState.errors.clipSizeType?.message}
                labelStyle="dynamic"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="expiration"
            control={defiPreferencesForm.control}
            render={({ field, formState }) => (
              <ExpirationField
                error={!!formState.errors.expiration}
                helperText={formState.errors.expiration?.message}
                {...field}
                labelStyle="dynamic"
              />
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DefiPreferencesForm;
