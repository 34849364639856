/* eslint-disable react/display-name */

import React from "react";
import format from "date-fns/format";
import { createColumnHelper, Row } from "@tanstack/table-core";
import { AllParentOrder, CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";
import { CELL_FONT_SIZE } from "@src/components/anboto-table";
import { END_TIME_FORMAT } from "@src/pages/cefi/constants";
import { truncateAccount } from "@src/pages/defi/utils";
import { OrderTableSide, ParentOrderStatus } from "@src/components";
import { CHAIN_NAME } from "@src/pages/defi/constants";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { OrdersTableAccount } from "@src/components/orders-table-account";
import { formatUsd } from "@src/utils/format";
import ExportOrderButtons from "@src/components/export-order-button/exportOrderButtons";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";
import { MemoizedExpandRow } from "@src/pages/cefi/orders-table/components/expand-row";

export const columnHelper = createColumnHelper<AllParentOrder>();

export const getColumns = ({ wallets }) => [
  columnHelper.display({
    header: "",
    size: 35,
    id: "sort",
    cell: ({ row }) => <MemoizedExpandRow expanded={row.getIsExpanded()} onClick={() => row.toggleExpanded()} />,
  }),
  columnHelper.accessor("order_id", {
    id: "order_id",
    header: "Order id",
    size: 150,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => <>{row.original.order_id}</>),
  }),
  columnHelper.accessor("symbol", {
    id: "symbol",
    header: "Symbol",
    size: 220,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => {
      if (row.original.order_type === "defi") {
        const network = row.original.network_id ? CHAIN_NAME[row.original.network_id] : "";

        return <>{network ? `${row.original.symbol} - ${network} - ${row.original.exchange}` : row.original.symbol}</>;
      } else {
        return (
          <>
            {row.original.asset_class
              ? getOrderSymbolLabel({
                  symbol: row.original.symbol,
                  exchange: CEFI_EXCHANGE_NAME[row.original.exchange],
                  market_type: row.original.asset_class as CefiExchangeMarketType,
                })
              : "-"}
          </>
        );
      }
    }),
  }),
  columnHelper.accessor("order_type", {
    id: "order_type",
    header: "Type",
    size: 60,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => <>{row.original.order_type.toUpperCase()}</>),
  }),
  columnHelper.accessor("defi_data", {
    id: "defi_data",
    header: "Subaccount / wallet",
    size: 120,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => (
      <OrdersTableAccount
        label={
          row.original.defi_data?.maker_address
            ? wallets[row.original.defi_data.maker_address] || truncateAccount(row.original.defi_data.maker_address)
            : row.original?.subaccount || "Main"
        }
        fontSize={CELL_FONT_SIZE}
      />
    )),
  }),

  columnHelper.accessor("source", {
    id: "source",
    header: "Source",
    size: 70,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => <>{row.original.source?.toUpperCase()}</>),
  }),
  columnHelper.accessor("side", {
    id: "side",
    header: "Side",
    size: 70,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => <OrderTableSide side={row.original?.side} />),
  }),
  columnHelper.accessor("strategy", {
    id: "strategy",
    header: "Strategy",
    size: 70,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => <>{row.original.strategy?.toUpperCase()}</>),
  }),
  columnHelper.accessor("quantity", {
    id: "quantity",
    header: "Quantity",
    size: 70,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => <>{row.original.quantity.toFixed(2)}</>),
  }),
  columnHelper.display({
    id: "filled",
    header: "Filled",
    size: 70,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => (
      <>{(row.original.last_status?.filled_quantity || 0).toFixed(2)}</>
    )),
  }),
  columnHelper.display({
    id: "amount",
    header: "Amount",
    size: 120,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => (
      <>
        {formatUsd((row.original.last_status?.average_price || 0) * (row.original.last_status?.filled_quantity || 0))}
      </>
    )),
  }),
  columnHelper.accessor("start_time", {
    id: "start_time",
    header: "Start time",
    size: 100,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => (
      <>{row.original?.start_time && format(new Date(row.original?.start_time), END_TIME_FORMAT)}</>
    )),
  }),
  columnHelper.accessor("end_time", {
    id: "end_time",
    header: "End time",
    size: 100,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => (
      <>{row.original?.end_time && format(new Date(row.original?.end_time), END_TIME_FORMAT)}</>
    )),
  }),
  columnHelper.accessor("last_status", {
    id: "last_status",
    header: "Status",
    size: 115,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => (
      <ParentOrderStatus<AllParentOrder> order={row.original} fontSize={CELL_FONT_SIZE} />
    )),
  }),
  columnHelper.accessor("order_id", {
    id: "export",
    header: "Child / trades",
    size: 115,
    cell: React.memo(({ row }: { row: Row<AllParentOrder> }) => (
      <ExportOrderButtons
        isCefi={row.original.order_type === "cefi"}
        account_uuid={row.original.account_uuid}
        order_id={row.original?.order_id}
      />
    )),
  }),
];
