import React, { createRef, useEffect } from "react";
import {
  Box,
  CardActions,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  StackProps,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import BigNumber from "bignumber.js";
import _omit from "lodash/omit";
import { modifyWithMaxAmountPrecision } from "../utils";
import { useUpdateSymbolBalance } from "../hooks/use-update-symbol-balance";
import { Leverage } from "../../../features/leverage";
import { TradingDurationField } from "./fields/TradingDurationField";
import { LimitPriceField } from "./fields/limit-price-field";
import { AccountSelect } from "./fields/AccountSelect";
import { QuantityInput } from "./fields/QuantityInput";
import { SideSelect } from "./fields/SideSelect";
import { TradingDurationUnitField } from "./fields/TradingDurationUnitField";
import { ParentOrderAdvancedOptionsDialog } from "./dialog/parent-order-advanced-options-dialog";
import { TradingStyleSelect } from "./fields/TradingStyleSelect";
import { useHideFields } from "./hooks/use-hide-fields";
import { useParentOrderFormContext } from "./hooks/use-parent-order-form-context";
import { ReviewDialog } from "./dialog/review-dialog";
import { ParentOrderPropertiesCard } from "./dialog/review-dialog/parent-order-properties-card";
import { useExchangeSymbolErrors } from "./hooks/use-exchange-symbol-errors";
import { PovRatioField } from "./fields/pov-ratio-field";
import { getTradingStyle, ParentOrderFormFieldValues } from "./hooks/use-parent-order-form";
import { StrategyButtonGroup } from "./fields/strategy-button-group";
import { ORDER_FORM_PORTAL_ID } from "./constants";
import { ReduceOnlyCheckbox } from "./fields/reduce-only-checkbox";
import { getSymbolPair } from "./utils";
import { AdvancedSettingsButton } from "./advanced-settings-button";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { SymbolAccountBalance } from "@src/pages/cefi/order-form/symbol-account-balance";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import {
  OrderExecutionStrategy,
  OrdersCurrentTab,
  OrderSide,
  ParentOrderFormParams,
  OrderSymbol,
  TradingStyle,
  CefiExchangeMarketType,
} from "@src/store/apis/anbotoApi/types";
import { formatTokenAmount, getAssetPrecision } from "@src/utils/format";
import { onlyNumbers } from "@src/utils/only-numbers";
import { RootState } from "@src/store/types";
import { useDialog } from "@src/hooks/useDialog";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { setCurrentTab } from "@src/pages/cefi/orders-table/orders-table.slice";
import { setOrderFormReviewButtonDisabled } from "@src/store/slices/singleExchangePageSlice";
import { withFlatStyle } from "@src/components/with-flat-style";
import { SymbolSelect } from "@src/components/symbol-select";
import { useCefiOrderFormDefaultValues } from "@src/pages/cefi/order-form/hooks/use-cefi-order-form-default-values";
import { UrgencySelect } from "@src/pages/cefi/order-form/fields/urgency-select";
import { useTestnetEnabled } from "@src/hooks/use-testnet-enabled";
import { usePermissions } from "@src/hooks/use-permissions";
import { VIEW_ONLY_MODE_DESCRIPTION } from "@src/constants/common";

export const AVAILABLE_STRATEGIES = [
  OrderExecutionStrategy.ORDER,
  OrderExecutionStrategy.LIMIT,
  OrderExecutionStrategy.TWAP,
  OrderExecutionStrategy.VWAP,
  OrderExecutionStrategy.ICEBERG,
  OrderExecutionStrategy.POV,
  OrderExecutionStrategy.IS,
];

const ADVANCED_SETTINGS_DIALOG_FIELDS = [
  "wouldStyle",
  "wouldPrice",
  "wouldPct",
  "limitPrice",
  "triggerCondition",
  "triggerPrice",
  "placementMode",
  "placement",
  "placementCancel",
  "clipSizeType",
  "clipSizeValue",
  "expirationTime",
];

interface ParentOrderFormCardProps {
  onSymbolChange?: (symbol: OrderSymbol | null) => void;
  hideSymbolSelect?: boolean;
  onFormReset?: (fields: Partial<ParentOrderFormFieldValues>) => void;
  onAdvancedSettingsClick?: (containerRef: React.RefObject<HTMLDivElement>) => void;
  advancedSettingsDraggable?: { x: number; y: number; id: string; group: string } | null;
  advancedSettingsDraggableOpened?: boolean;
  onAdvancedSettingsClose?: () => void;
}

export const ParentOrderFormCard = ({
  sx,
  onSymbolChange,
  onAdvancedSettingsClick,
  advancedSettingsDraggable,
  advancedSettingsDraggableOpened,
  hideSymbolSelect,
  onAdvancedSettingsClose,
  ...props
}: StackProps & ParentOrderFormCardProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const snackbar = useSnackbar();
  const team_uuid = useAppSelector((state: RootState) => state.user.team_uuid)!;
  const advancedSettingsDialog = useDialog();
  const reviewDialog = useDialog();
  const orderForm = useParentOrderFormContext();
  const symbol = orderForm.watch("symbol");
  const side = orderForm.watch("side");
  const strategy = orderForm.watch("strategy");
  const tradingDuration = orderForm.watch("tradingDuration");
  const quantity = orderForm.watch("quantity");
  const toQuantity = orderForm.watch("toQuantity");
  const account = orderForm.watch("account");
  const triggerPrice = orderForm.watch("triggerPrice");
  const limitPrice = orderForm.watch("limitPrice");
  const isTestnet = useTestnetEnabled();
  const permissions = usePermissions();
  const hideFields = useHideFields(orderForm);
  const orderFormRef = createRef<HTMLDivElement>();
  const defaultValues = useCefiOrderFormDefaultValues({
    symbol,
    account,
    side,
    strategy,
  });

  const getOrderSymbolPriceQuery = anbotoApi.useGetOrderSymbolPriceQuery(symbol!, {
    skip: !symbol || symbol.exchange === "B2C2",
  });
  const [submitOrder, submitOrderMutation] = anbotoApi.useSubmitParentCefiOrderMutation();
  const getSymbolAccountBalanceQuery = anbotoApi.useGetSymbolAccountBalanceQuery(
    { symbol: symbol!, account: account! },
    { skip: !symbol || !account }
  );
  const [saveOrderForLater, saveOrderForLaterMutation] = anbotoApi.useSaveOrderForLaterMutation();
  const exchangeSymbolErrors = useExchangeSymbolErrors(symbol);

  useUpdateSymbolBalance(symbol, account);

  const advancedSettingsExchangeErrors = Object.values(_omit(exchangeSymbolErrors.errors, "quantity"));

  const advancedSettingsFormErrors = Object.keys(orderForm.formState.errors).reduce<string[]>((errors, x) => {
    if (ADVANCED_SETTINGS_DIALOG_FIELDS.includes(x) && typeof orderForm.formState.errors[x]?.message === "string")
      errors.push(x + orderForm.formState.errors[x].message);

    return errors;
  }, []);

  const advancedSettingsErrors = [...advancedSettingsExchangeErrors, ...advancedSettingsFormErrors].filter(Boolean);

  const isQuantityMaxErrorExists =
    getSymbolAccountBalanceQuery.data &&
    !getSymbolAccountBalanceQuery.isFetching &&
    side === OrderSide.SELL &&
    new BigNumber(quantity).gt(getSymbolAccountBalanceQuery.data.position);

  const isToQuantityMaxErrorExists =
    getSymbolAccountBalanceQuery.data &&
    !getSymbolAccountBalanceQuery.isFetching &&
    side === OrderSide.BUY &&
    new BigNumber(toQuantity).gt(getSymbolAccountBalanceQuery.data.currency_position);

  const tradingDurationRequired =
    !tradingDuration && (strategy === OrderExecutionStrategy.TWAP || strategy === OrderExecutionStrategy.VWAP);

  const orderFormReviewButtonDisabled =
    !permissions.trade ||
    (isTestnet
      ? !+quantity ||
        tradingDurationRequired ||
        !symbol?.symbol ||
        getOrderSymbolPriceQuery.isFetching ||
        exchangeSymbolErrors.hasErrors
      : !account ||
        !+quantity ||
        tradingDurationRequired ||
        !symbol?.symbol ||
        // isQuantityMinSliceErrorExists ||
        getOrderSymbolPriceQuery.isFetching ||
        getSymbolAccountBalanceQuery.isFetching ||
        exchangeSymbolErrors.hasErrors);

  const quantityDisabled = isTestnet
    ? false
    : !getOrderSymbolPriceQuery.data?.data?.price ||
      getOrderSymbolPriceQuery.isFetching ||
      getOrderSymbolPriceQuery.isError;

  const resetForm = (symbol?: OrderSymbol | null) => {
    const strategy = orderForm.getValues("strategy");

    const params = {
      ...defaultValues,
      account,
      side,
      quantity: "",
      toQuantity: "",
      strategy,
      tradingStyle: getTradingStyle(strategy, defaultValues.tradingStyle),
    } as Partial<ParentOrderFormFieldValues>;

    if (symbol) params.symbol = symbol;

    orderForm.reset(params);
  };

  const getHiddenFieldKeys = () =>
    Object.keys(hideFields).reduce<string[]>((res, key) => (hideFields[key] ? [...res, key] : res), []);

  const handleMaxClick = () => {
    if (side === OrderSide.BUY) {
      onToQuantityChange(`${getSymbolAccountBalanceQuery.data?.currency_position || 0}`);
    } else {
      onQuantityChange(`${getSymbolAccountBalanceQuery.data?.position || 0}`, true);
    }
  };

  const onQuantityChange = (value: string, modifyPrecision?: boolean) => {
    const maxPrecision = exchangeSymbolErrors?.validationData?.amount_precision;
    let quantity = onlyNumbers(value);
    if (modifyPrecision) quantity = modifyWithMaxAmountPrecision(quantity, maxPrecision);

    if (quantity) {
      orderForm.setValue("quantity", quantity);

      const price = strategy === OrderExecutionStrategy.LIMIT ? +limitPrice : getOrderSymbolPriceQuery.data!.data.price;
      const toQuantity = formatTokenAmount(parseFloat(quantity) * price, {
        plain: true,
        precision: getAssetPrecision(symbol?.symbol || "").to,
      });

      orderForm.setValue("toQuantity", toQuantity.toString());
    } else {
      orderForm.setValue("quantity", "");
      orderForm.setValue("toQuantity", "");
    }
  };

  const onToQuantityChange = (value: string) => {
    const toQuantity = onlyNumbers(value);

    if (toQuantity) {
      orderForm.setValue("toQuantity", toQuantity);

      const price = strategy === OrderExecutionStrategy.LIMIT ? +limitPrice : getOrderSymbolPriceQuery.data!.data.price;
      const quantity = formatTokenAmount(parseFloat(toQuantity) / price, {
        plain: true,
        precision: getAssetPrecision(symbol?.symbol || "").from,
      });
      const maxPrecision = exchangeSymbolErrors?.validationData?.amount_precision;

      orderForm.setValue("quantity", modifyWithMaxAmountPrecision(quantity, maxPrecision));
    } else {
      orderForm.setValue("quantity", "");
      orderForm.setValue("toQuantity", "");
    }
  };

  const swapQuantity = () => {
    orderForm.setValue("quantity", orderForm.getValues("toQuantity"));
  };

  const handleSubmit = async () => {
    try {
      const values = { ...orderForm.getValues(), accountId: team_uuid };
      const body = _omit(values, getHiddenFieldKeys()) as ParentOrderFormParams;

      await submitOrder(body).unwrap();

      const value =
        triggerPrice || strategy === OrderExecutionStrategy.LIMIT ? OrdersCurrentTab.Trigger : OrdersCurrentTab.Regular;

      dispatch(setCurrentTab({ value }));
      resetForm(values.symbol);
      advancedSettingsDialog.hide();

      snackbar.enqueueSnackbar("Order submitted", { variant: "success" });
    } catch (error) {
      console.log({ error });
      const errorMessage = error.data ? parseAnbotoRequestError(error.data) : "Order submission failed";

      snackbar.enqueueSnackbar(errorMessage, { variant: "error" });
    }
    reviewDialog.hide();
  };

  const handleSaveForLater = async () => {
    try {
      const values = { ...orderForm.getValues(), accountId: team_uuid };
      const body = _omit(values, getHiddenFieldKeys()) as ParentOrderFormParams;

      await saveOrderForLater(body).unwrap();

      dispatch(setCurrentTab({ value: OrdersCurrentTab.SavedOrders }));

      snackbar.enqueueSnackbar("Order saved", { variant: "success" });
    } catch (error) {
      console.log({ error });
      const errorMessage = error.data ? parseAnbotoRequestError(error.data) : "Order submission failed";
      snackbar.enqueueSnackbar(errorMessage, { variant: "error" });
    }
  };

  const reviewForm = () => reviewDialog.show();

  const renderMaxError = (from?: boolean) => (
    <Typography
      sx={{
        color: (theme) => theme.palette.warning.main,
        fontSize: 12,
        textAlign: from ? "left" : "right",
      }}
    >
      Insufficient funds, proceed carefully
    </Typography>
  );

  const handleSymbolChange = (symbol: OrderSymbol) => {
    onSymbolChange && onSymbolChange(symbol);

    resetForm(symbol);
  };

  const handleAdvancedSettingsClose = () => {
    if (onAdvancedSettingsClose && advancedSettingsDraggable) {
      onAdvancedSettingsClose();
    }

    advancedSettingsDialog.hide();
  };

  useEffect(() => {
    dispatch(setOrderFormReviewButtonDisabled(orderFormReviewButtonDisabled));
  }, [orderFormReviewButtonDisabled]);

  useEffect(() => {
    if (!quantity && reviewDialog.isOpen) reviewDialog.hide();
  }, [quantity, reviewDialog.isOpen]);

  useEffect(() => {
    if (strategy) {
      const tradingStyle = getTradingStyle(strategy, defaultValues.tradingStyle);

      orderForm.setValue("tradingStyle", tradingStyle);
    }

    if (strategy !== OrderExecutionStrategy.LIMIT && limitPrice) orderForm.setValue("limitPrice", "");
  }, [strategy]);

  useEffect(() => {
    if (getOrderSymbolPriceQuery.data && quantity && !toQuantity && !getOrderSymbolPriceQuery.isFetching) {
      onQuantityChange(quantity);
    }
  }, [getOrderSymbolPriceQuery.data, quantity, toQuantity]);

  useEffect(() => {
    advancedSettingsDialog.hide();
  }, [advancedSettingsDraggable]);

  return (
    <Stack
      sx={{ minHeight: 600, position: "relative", overflow: "hidden", ...sx }}
      {...props}
      role="order-form"
      overflow="hidden"
      ref={orderFormRef}
    >
      <Stack id={ORDER_FORM_PORTAL_ID} overflow="hidden" flex={1} position="relative">
        <Stack flex={1} overflow="hidden" position="relative" zIndex={1}>
          <CardContent>
            {!hideSymbolSelect && (
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} className="symbol-select">
                  <Controller
                    name="symbol"
                    control={orderForm.control}
                    render={({ field }) => (
                      <SymbolSelect
                        {...field}
                        onChange={(symbol: OrderSymbol) => handleSymbolChange(symbol)}
                        isError={orderForm.formState.isSubmitted && !symbol}
                        variant="input-dark"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Stack gap={1} flexDirection="row">
                  <Box sx={{ width: 120 }}>
                    <Controller
                      name="account"
                      control={orderForm.control}
                      render={({ field }) => (
                        <StyledAccountSelect
                          {...field}
                          error={orderForm.formState.isSubmitted && !account}
                          noLabel
                          placeholder="Account"
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Controller
                      name="side"
                      control={orderForm.control}
                      render={({ field }) => (
                        <SideSelect value={field.value} onChange={field.onChange} variant="dark" />
                      )}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
          <Box p={2} pt={0} display="flex" flexDirection="column" flex={1}>
            <Card p={2}>
              <Controller
                name="strategy"
                control={orderForm.control}
                render={({ field }) => <StrategyButtonGroup {...field} />}
              />
              {[OrderExecutionStrategy.LIMIT, OrderExecutionStrategy.ORDER].includes(strategy) && (
                <Stack mt={2}>
                  <Controller
                    name="limitPrice"
                    control={orderForm.control}
                    render={({ field, formState }) => {
                      const limitStrategy = strategy === OrderExecutionStrategy.LIMIT;
                      const marketStrategy = strategy === OrderExecutionStrategy.ORDER;

                      return (
                        <StyledLimitPriceField
                          {...field}
                          error={
                            limitStrategy && (!!formState.errors.limitPrice || !!exchangeSymbolErrors.errors.limitPrice)
                          }
                          helperText={
                            limitStrategy &&
                            (formState.errors.limitPrice?.message || exchangeSymbolErrors.errors.limitPrice)
                          }
                          coin={!symbol ? "" : symbol?.coin2 || getSymbolPair(symbol.symbol).to}
                          disabled={marketStrategy}
                          InputProps={{
                            endAdornment: marketStrategy ? (
                              <InputAdornment position="end">
                                <Typography variant="body2" sx={{ opacity: 0.6 }}>
                                  Market
                                </Typography>
                              </InputAdornment>
                            ) : null,
                          }}
                        />
                      );
                    }}
                  />
                </Stack>
              )}
              <Stack direction="row" gap={1} mt={2} alignItems="flex-start">
                <Controller
                  name="quantity"
                  control={orderForm.control}
                  render={({ field, formState }) => {
                    // const minError = isQuantityMinSliceErrorExists
                    //   ? { message: `Min amount per slice is ${getExchangeSymbolMinAmountDataQuery.data!.min_amount}` }
                    //   : null;
                    const maxError = isQuantityMaxErrorExists
                      ? { message: "Insufficient funds, proceed carefully" }
                      : null;
                    // const errorMessage = formState.errors.quantity?.message || maxError?.message || minError?.message;

                    const errorMessage =
                      formState.errors.quantity?.message || maxError?.message || exchangeSymbolErrors?.errors?.quantity;

                    const hasError = !!errorMessage;
                    return (
                      <StyledQuantityInput
                        {...field}
                        max={side === OrderSide.SELL}
                        maxProps={{
                          disabled:
                            getSymbolAccountBalanceQuery.isFetching || !symbol || getOrderSymbolPriceQuery.isError,
                          onClick: handleMaxClick,
                        }}
                        error={hasError}
                        errorType={maxError ? "warning" : undefined}
                        helperText={!maxError ? errorMessage : ""}
                        onChange={(e) => onQuantityChange(e.target.value)}
                        label={`Quantity ${symbol ? `(${symbol.coin1 || getSymbolPair(symbol.symbol).from})` : ""}`}
                        disabled={quantityDisabled}
                      />
                    );
                  }}
                />
                <SwapHorizIcon sx={{ color: theme.custom.text.dim, marginTop: 2.7 }} onClick={swapQuantity} />
                <Controller
                  name="toQuantity"
                  control={orderForm.control}
                  render={({ field, formState }) => {
                    const maxError = isToQuantityMaxErrorExists
                      ? { message: "Insufficient funds, proceed carefully" }
                      : null;
                    const errorMessage = formState.errors.quantity?.message || maxError?.message;
                    const hasError = !!errorMessage;
                    return (
                      <StyledQuantityInput
                        sx={{ border: "none" }}
                        {...field}
                        max={side === OrderSide.BUY}
                        maxProps={{
                          disabled:
                            getSymbolAccountBalanceQuery.isFetching || !symbol || getOrderSymbolPriceQuery.isError,
                          onClick: handleMaxClick,
                        }}
                        error={hasError}
                        errorType={maxError ? "warning" : undefined}
                        helperText={!maxError ? errorMessage : ""}
                        onChange={(e) => onToQuantityChange(e.target.value)}
                        label={`Quantity ${symbol ? `(${symbol.coin2 || getSymbolPair(symbol.symbol).to})` : ""}`}
                        labelPosition="right"
                        disabled={quantityDisabled}
                      />
                    );
                  }}
                />
              </Stack>
              {!isTestnet && <SymbolAccountBalance symbol={symbol} account={account} variant="horizontal" />}
              {(isQuantityMaxErrorExists || isToQuantityMaxErrorExists) && renderMaxError(isQuantityMaxErrorExists)}
              {!isTestnet && getOrderSymbolPriceQuery.isError && (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.error.main,
                    fontSize: 12,
                    textAlign: "right",
                  }}
                >
                  Price fetching error. Please try again later.
                </Typography>
              )}
            </Card>
            <Grid container spacing={2} mt={0.5}>
              {!hideFields.tradingStyle && (
                <Grid item xs={6}>
                  <Controller
                    name="tradingStyle"
                    control={orderForm.control}
                    render={({ field }) => (
                      <StyledTradingStyleSelect
                        {...field}
                        disabled={limitPrice !== "" && strategy === OrderExecutionStrategy.ORDER}
                        excludeTradingStyles={strategy === OrderExecutionStrategy.ORDER ? [TradingStyle.HYBRID] : []}
                        labelStyle="dynamic"
                      />
                    )}
                  />
                </Grid>
              )}
              {strategy === OrderExecutionStrategy.IS && (
                <Grid item xs={6}>
                  <Controller
                    name="urgency"
                    control={orderForm.control}
                    render={({ field }) => <StyledUrgencySelect {...field} labelStyle="dynamic" />}
                  />
                </Grid>
              )}
              {!hideFields.tradingDuration && (
                <Grid item xs={6}>
                  <Controller
                    name="tradingDuration"
                    control={orderForm.control}
                    render={({ field, formState }) => (
                      <StyledTradingDurationField
                        {...field}
                        onChange={(e) => field.onChange(e.target.value.replace(/[^\d]/g, ""))}
                        error={!!formState.errors.tradingDuration}
                        helperText={formState.errors.tradingDuration?.message}
                        labelStyle="dynamic"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Controller
                                name="tradingDurationUnit"
                                control={orderForm.control}
                                render={({ field }) => <TradingDurationUnitField {...field} />}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {[OrderExecutionStrategy.ICEBERG, OrderExecutionStrategy.IS].includes(strategy) && <Grid item xs={6} />}
              {!hideFields.povRatio && (
                <Grid item xs={6}>
                  <Controller
                    name="povRatio"
                    control={orderForm.control}
                    render={({ field, formState }) => (
                      <StyledPovRatioField
                        {...field}
                        error={!!formState.errors.povRatio}
                        helperText={formState.errors.povRatio?.message}
                        labelStyle="dynamic"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              {symbol?.market_type === CefiExchangeMarketType.FUTURE && (
                <>
                  <Grid item xs={6}>
                    <Controller
                      control={orderForm.control}
                      name="reduceOnly"
                      render={({ field }) => <ReduceOnlyCheckbox value={field.value} onChange={field.onChange} />}
                    />
                  </Grid>
                  <Grid item xs={6} alignItems="center">
                    <Leverage symbol={symbol} account={account} />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          {strategy !== OrderExecutionStrategy.ORDER && (
            <Stack flex={1} justifyContent="flex-end" pl={2} pb={2} pr={2}>
              <AdvancedSettingsButton
                onClick={advancedSettingsDialog.show}
                onClickPopup={() => onAdvancedSettingsClick && onAdvancedSettingsClick(orderFormRef)}
                draggable={!!advancedSettingsDraggable}
                opened={advancedSettingsDraggableOpened || advancedSettingsDialog.isOpen}
                errors={
                  strategy !== OrderExecutionStrategy.LIMIT && advancedSettingsErrors.length
                    ? advancedSettingsErrors.join("\r\n")
                    : ""
                }
              />
            </Stack>
          )}
        </Stack>

        <ParentOrderAdvancedOptionsDialog
          openInPopupClick={() => onAdvancedSettingsClick && onAdvancedSettingsClick(orderFormRef)}
          draggable={advancedSettingsDraggable}
          draggableOpened={advancedSettingsDraggableOpened}
          open={advancedSettingsDialog.isOpen}
          onClose={handleAdvancedSettingsClose}
        />
      </Stack>
      <Divider sx={{ borderWidth: 1, borderColor: (theme) => theme.palette.background.default }} />
      <CardActions sx={{ px: 2, pb: 2, justifyContent: "space-between" }}>
        <AnbotoButton
          variant="outlined"
          size="small"
          sx={{ color: (theme) => theme.palette.error.main }}
          onClick={() => resetForm(symbol)}
        >
          Clear
        </AnbotoButton>
        {/* {JSON.stringify(orderForm.formState.errors)} */}
        <Tooltip title={permissions.trade ? "" : VIEW_ONLY_MODE_DESCRIPTION}>
          <div>
            <AnbotoButton
              sx={{ minWidth: 160 }}
              size="small"
              disabled={orderFormReviewButtonDisabled}
              loading={submitOrderMutation.isLoading}
              variant="contained"
              onClick={orderForm.handleSubmit(reviewForm)}
            >
              Review order
            </AnbotoButton>
          </div>
        </Tooltip>
      </CardActions>
      {!!account && !!symbol && !!quantity && (
        <ReviewDialog
          open={reviewDialog.isOpen}
          onClose={reviewDialog.hide}
          onSubmit={handleSubmit}
          onSaveForLater={handleSaveForLater}
          isOrderConfirmLoading={submitOrderMutation.isLoading}
          isSaveForLaterLoading={saveOrderForLaterMutation.isLoading}
          sx={{ zIndex: 3 }}
        >
          <ParentOrderPropertiesCard />
        </ReviewDialog>
      )}
    </Stack>
  );
};

const Card = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.custom.background.default,
}));

const StyledPovRatioField = withFlatStyle(PovRatioField);
const StyledAccountSelect = withFlatStyle(AccountSelect);
const StyledLimitPriceField = withFlatStyle(LimitPriceField);
const StyledTradingDurationField = withFlatStyle(TradingDurationField);
const StyledTradingStyleSelect = withFlatStyle(TradingStyleSelect);
const StyledUrgencySelect = withFlatStyle(UrgencySelect);
const StyledQuantityInput = styled(QuantityInput)(({ theme }) => ({
  "& .MuiFormLabel-root": {
    color: `${theme.palette.text.disabled} !important`,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
}));
