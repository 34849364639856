import { Duration } from "luxon";
import { OrderbookOrders } from "@src/components/orderbook/types";
import { OrderSide } from "@src/store/apis/anbotoApi/types";

export const formatDuration = (durationInSeconds: number): string => {
  if (!durationInSeconds || durationInSeconds < 0) return "-";

  const duration = Duration.fromMillis(durationInSeconds * 1000)
    .shiftTo("months", "days", "hours", "minutes", "seconds")
    .toObject();

  const formattedDuration: string[] = [];

  const { months, days, hours, minutes } = duration;

  if (months && months > 0) {
    formattedDuration.push(`${months}month${months > 1 ? "s" : ""}`);
  }
  if (days && days > 0) {
    formattedDuration.push(`${days}d`);
  }
  if (hours && hours > 0) {
    formattedDuration.push(`${hours}h`);
  }
  if (minutes && minutes > 0) {
    formattedDuration.push(`${minutes}m`);
  }

  return formattedDuration.slice(0, 2).join(" ");
};

export const annualized = (value: number) => value * Math.sqrt(365 * 24 * 60);

export const getSweepPriceDelta = (
  orderBook: OrderbookOrders,
  quantity: string,
  contractSize: number,
  side: OrderSide | undefined
): number => {
  let bestPrice: number;
  const amount: number = +quantity / (contractSize || 1);
  let currentQuantity = 0;

  // const l_pq: number[] = [];

  if (orderBook.length) {
    if (side === OrderSide.BUY) {
      bestPrice = Math.min(...orderBook.map((row) => row[0]));
    } else {
      bestPrice = Math.max(...orderBook.map((row) => row[0]));
    }
  } else {
    return NaN;
  }

  let lastPrice = 0;
  orderBook.forEach(([p, q]) => {
    if (currentQuantity >= amount) {
      return;
    }

    // 0.5 is the scaling factor saying that 50% of orderbook quantity is spoofing
    const add_q: number = Math.min(amount - currentQuantity, 0.5 * q);
    currentQuantity += add_q;
    // l_pq.push(p * add_q);
    lastPrice = p;
  });

  // let delta = l_pq.reduce((acc, val) => acc + val, 0) / currentQuantity - bestPrice;
  let delta = lastPrice - bestPrice;

  if (currentQuantity < amount) {
    delta = (delta / currentQuantity) * amount;
  }

  return delta;
};
