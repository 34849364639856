import React from "react";
import { Box, Grid } from "@mui/material";
import { FeeRates } from "./fee-rates";
import { FeeManagerHeader } from "./fee-manager-header";
import { DepositFlowDialog } from "./deposit-flow-dialog/index";
import { Transactions } from "./transactions/transactions";
import { OverviewTokenFees } from "./overview-token-fees";
import { WithdrawalFlowDialog } from "./withdraw-flow-dialog";
import { FeeManagerHelp } from "./fee-manager-help";
import { FeeManagerTabs } from "@src/store/slices/feeManagerSlice";
import { useDepositWithdrawDialog } from "@src/hooks/use-deposit-withdraw-dialog";
import { useRestoreWalletProvider } from "@src/hooks";
import { useAppSelector } from "@src/store/hooks";

export const FeeManager = () => {
  useRestoreWalletProvider();

  const { currentTab } = useAppSelector((state) => state.feeManager);

  const depositFlowDialog = useDepositWithdrawDialog();
  const withdrawFlowDialog = useDepositWithdrawDialog();

  return (
    <>
      <Box>
        <FeeManagerHeader handleDeposit={() => depositFlowDialog.show()} />
        <Grid container direction="column" paddingTop={"21px"}>
          {currentTab === FeeManagerTabs.OVERVIEW && (
            <OverviewTokenFees useDeposit={depositFlowDialog} useWithdraw={withdrawFlowDialog} />
          )}
          {currentTab === FeeManagerTabs.TRANSACTIONS && <Transactions />}
          {currentTab === FeeManagerTabs.FEE_RATES && <FeeRates />}
          {currentTab === FeeManagerTabs.HELP && <FeeManagerHelp />}
        </Grid>
      </Box>

      {depositFlowDialog.isOpen && (
        <DepositFlowDialog open useDeposit={depositFlowDialog} onClose={() => depositFlowDialog.hide()} />
      )}

      {withdrawFlowDialog.isOpen && (
        <WithdrawalFlowDialog open useWithdraw={withdrawFlowDialog} onClose={() => withdrawFlowDialog.hide()} />
      )}
    </>
  );
};
