import { useEffect, useState } from "react";
import { endOfMonth, startOfMonth, subMonths } from "date-fns";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useAppSelector } from "@src/store/hooks";

export type PostTradeTableData = { time: number; arrival: number; twap: number; vwap: number };

const getLastMonthsData = (month: number) => {
  const currentDate = new Date();
  const lastMonthsDates: { start: number; end: number }[] = [];

  for (let i = 0; i < month; i++) {
    const firstDayOfMonth = startOfMonth(subMonths(currentDate, i));
    const lastDayOfMonth = endOfMonth(subMonths(currentDate, i));

    lastMonthsDates.push({
      start: firstDayOfMonth.getTime() / 1000,
      end: lastDayOfMonth.getTime() / 1000,
    });
  }

  return lastMonthsDates;
};

export const useGet12MonthPostTradeData = () => {
  const user_uuid = useAppSelector((state) => state.user.team_uuid);
  const [postTradeData, setPostTradeData] = useState<PostTradeTableData[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const [getPostTradeAnalysisData] = anbotoApi.useLazyGetPostTradeAnalysisMetricsDataQuery();

  const fetchPostTradeData = async () => {
    setIsFetching(true);
    const dates = getLastMonthsData(12);

    for (const date of dates) {
      const result = await getPostTradeAnalysisData({ ...date, account: user_uuid });

      if (result) {
        const currentData = result?.data?.data?.result;
        setPostTradeData((prevState) => [
          ...prevState,
          {
            time: date.start,
            arrival: currentData?.slippages?.arrival_average || 0,
            twap: currentData?.slippages?.twap_average || 0,
            vwap: currentData?.slippages?.vwap_average || 0,
          },
        ]);
      } else {
        setPostTradeData((prevState) => [...prevState, { time: date.start, arrival: 0, twap: 0, vwap: 0 }]);
      }
    }

    setIsFetching(false);
  };

  useEffect(() => {
    fetchPostTradeData();
  }, []);

  return { postTradeData, isFetching };
};
