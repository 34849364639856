import React from "react";
import {
  Dialog,
  Typography,
  Stack,
  Grid,
  DialogContent,
  DialogActions,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import GroupsIcon from "@mui/icons-material/Groups";
import { SettingsCard } from "../../settings-card";
import { SettingsThumb } from "../../settings-thumb";
import { AccountSettingsDialog } from "../account-settings-dialog";
import { AccountForm, AccountFormRef } from "../account-form";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AvatarsList } from "@src/components/avatars-list";
import { UserAccountMember, UserAccountsResult } from "@src/store/apis/anbotoApi/types";
import { RootState } from "@src/store/types";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { changeUserTeamAction } from "@src/store/actions/user.action";
import { useBlockNavigation } from "@src/hooks/use-block-navigation";
import { CreateAccountAlert } from "@src/pages/auth/registration/components/create-account-alert";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import AccountSettingsContainerHeader from "@src/pages/settings/account-settings/acount-settings-container-header";
import AccountSettingsIndividualAccount from "@src/pages/settings/account-settings/account-settings/account-settings-individual-account";
import { setRequestedMoreAccounts } from "@src/store/slices/uiSettingsSlice";

export const AccountSettings = () => {
  const theme = useTheme();
  const getAccountsQuery = anbotoApi.useGetUserAccountsQuery(null);
  const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const snackbar = useSnackbar();
  const [selectedAccount, setSelectedAccount] = React.useState<UserAccountsResult | null | {}>();

  const [deleteUserAccount, deleteUserAccountMutation] = anbotoApi.useDeleteUserAccountMutation();
  const [accountToDelete, setAccountToDelete] = React.useState<UserAccountsResult | null>(null);
  const [showAlert, setShowAlert] = React.useState(false);
  const { email, max_number_teams, user_uuid, team_uuid } = useSelector((state: RootState) => state.user);
  const formRef = React.useRef<AccountFormRef>(null);
  const dispatch = useAppDispatch();
  const accountsTotal = getAccountsQuery?.data?.results?.length || 0;
  const ownedAccountsNumber =
    getAccountsQuery?.data?.results?.reduce((acc, cur) => acc + (cur.admin_uuid === user_uuid ? 1 : 0), 0) || 0;
  const joinedAccountsNumber = accountsTotal - ownedAccountsNumber;
  const noAccounts = accountsTotal === 0;
  const companyName = getUserDetailsQuery?.data?.company_name || "";
  const [requestMoreAccounts] = anbotoApi.useLazySendCreateUserAccountRequestQuery();

  const { requestedMoreAccounts } = useAppSelector((state) => state.uiSettings);

  const teamId = useAppSelector((state) => state.user.team_uuid);
  const currentAccount = (getAccountsQuery?.data?.results || []).find((account) => account.uuid === teamId);
  const loading = getAccountsQuery.isLoading || getUserDetailsQuery.isLoading;

  React.useEffect(() => {
    if (noAccounts && getAccountsQuery.data) {
      setShowAlert(true);
      setSearchParams(searchParams, { replace: true });
    }
  }, [getAccountsQuery.isLoading]);

  const { terms_of_use } = useSelector((state: RootState) => state.user);

  useBlockNavigation(
    () => accountsTotal > 0,
    noAccounts,
    () => setShowAlert(true)
  );

  const handleAccountRemove = async () => {
    if (!accountToDelete) return false;

    try {
      const accountIsDeleted = await deleteUserAccount(accountToDelete.uuid).unwrap();
      setAccountToDelete(null);

      if (!accountIsDeleted) {
        refetchAccounts();
        if (team_uuid === accountToDelete.uuid) {
          window.location.reload();
        }
        snackbar.enqueueSnackbar(`${accountToDelete.name} has been deleted`, { variant: "success" });
      }
    } catch (error) {
      snackbar.enqueueSnackbar(error.data.details || error.data.error, { variant: "error" });
    }
  };

  const firstAccountCreateCallback = async () => {
    await dispatch(anbotoApi.endpoints.getUserDetails.initiate(null, { subscribe: false, forceRefetch: true }));
    const { data } = await refetchAccounts();
    const team = data?.results[0].possible_teams[0];
    if (team) dispatch(changeUserTeamAction({ team_name: team.name, team_uuid: team.uuid }));
  };

  const prepareMembers = (members: UserAccountMember[], pending?: boolean) =>
    members.map(({ first_name, last_name, email }) => ({
      firstName: first_name,
      lastName: last_name,
      email,
      dashed: !!pending,
    }));

  const refetchAccounts = () =>
    dispatch(anbotoApi.endpoints.getPossibleTeams.initiate(null, { subscribe: false, forceRefetch: true }));

  const handleCreateDialogClose = (onAfterSubmit: boolean) => {
    if (onAfterSubmit) refetchAccounts();
    setSelectedAccount("");
  };

  const handleRequestMoreAccounts = async () => {
    try {
      const { is_sent } = await requestMoreAccounts({ user_uuid: String(user_uuid) }).unwrap();
      if (is_sent) {
        dispatch(setRequestedMoreAccounts(max_number_teams));
        snackbar.enqueueSnackbar("Success. Our team will contact you soon", {
          variant: "success",
        });
      }
    } catch (e) {
      snackbar.enqueueSnackbar(parseAnbotoRequestError(e?.data?.detail), { variant: "error" });
    }
  };

  const isRequestMoreAccountsDisabled =
    accountsTotal < max_number_teams ||
    (typeof requestedMoreAccounts === "number" && requestedMoreAccounts >= max_number_teams);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : !currentAccount ? null : currentAccount.is_individual ? (
        <AccountSettingsIndividualAccount />
      ) : (
        <>
          <SettingsCard
            loading={getAccountsQuery.isLoading}
            headerProps={{
              title: "Team account",
              postfix: (
                <AnbotoButton
                  disabled={isRequestMoreAccountsDisabled}
                  onClick={handleRequestMoreAccounts}
                  variant="contained"
                  size="small"
                >
                  Request more accounts
                </AnbotoButton>
              ),
            }}
            contentHeader={
              <AccountSettingsContainerHeader
                joinedAccountsNumber={joinedAccountsNumber}
                ownedAccountsNumber={ownedAccountsNumber}
                max_number_teams={max_number_teams}
              />
            }
            dialog={
              selectedAccount && (
                <AccountSettingsDialog
                  open
                  account={getAccountsQuery?.data?.results.find((acc) => acc.uuid === selectedAccount)}
                  onClose={handleCreateDialogClose}
                />
              )
            }
          >
            {noAccounts ? (
              <Stack alignItems="center" sx={{ mt: 3 }}>
                <GroupsIcon sx={{ fontSize: 88, color: (theme) => theme.custom.colors.gray }} />
                <Typography
                  sx={{ color: (theme) => theme.palette.text.primary, fontWeight: "bold" }}
                >{`You don't have any account yet, let's create one`}</Typography>
                <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
                  To create an account please enter the name of your company, your own name, or any name of your choice
                </Typography>
                {getUserDetailsQuery.data && (
                  <AccountForm ref={formRef} defaultName={companyName} onAfterSubmit={firstAccountCreateCallback} />
                )}
              </Stack>
            ) : (
              <Grid container spacing={2}>
                {getAccountsQuery?.data?.results.map((account) => (
                  <Grid item xs={4} key={account.uuid}>
                    <SettingsThumb
                      showViewButton={account.admin_email !== email}
                      key={account.uuid}
                      name={account.name}
                      onEdit={() => setSelectedAccount(account.uuid)}
                      onRemove={account.admin_email === email ? () => setAccountToDelete(account) : undefined}
                      onRemoveLoading={
                        deleteUserAccountMutation.isLoading && account.uuid === deleteUserAccountMutation.originalArgs
                      }
                      items={[
                        {
                          label: "Admin",
                          value: 1,
                          visible: true,
                        },
                        {
                          label: "Members",
                          value: account.members.length,
                          visible: true,
                        },
                      ]}
                      preName={
                        <Typography fontSize={12} color={theme.palette.text.secondary}>
                          #{account.account_number}
                        </Typography>
                      }
                      postfix={
                        <AvatarsList
                          sx={{ marginTop: 3 }}
                          users={[...prepareMembers(account.members), ...prepareMembers(account.invited_members, true)]}
                        />
                      }
                    />
                  </Grid>
                ))}
                <Grid item xs={4} key="Create_account_account">
                  <Stack
                    minHeight={157}
                    border="1px dashed #ffffff1f"
                    padding={2.5}
                    direction="column"
                    justifyContent="center"
                  >
                    <AnbotoButton
                      variant="contained"
                      loading={formRef?.current?.loading}
                      onClick={() => (noAccounts ? formRef?.current?.submit() : setSelectedAccount("new"))}
                      disabled={accountsTotal >= max_number_teams}
                    >
                      Create account
                    </AnbotoButton>
                  </Stack>
                </Grid>
              </Grid>
            )}
            {!!accountToDelete && (
              <Dialog
                open
                onClose={() => setAccountToDelete(null)}
                sx={{ "& .MuiPaper-root": { background: `#232C2F !important` } }}
              >
                <DialogContent>
                  <Typography variant="body1">
                    Are you sure you want to delete {accountToDelete?.name} account?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <AnbotoButton variant="outlined" size="small" onClick={() => setAccountToDelete(null)}>
                    No
                  </AnbotoButton>
                  <AnbotoButton
                    variant="outlined"
                    size="small"
                    onClick={handleAccountRemove}
                    autoFocus
                    loading={deleteUserAccountMutation.isLoading}
                  >
                    Yes
                  </AnbotoButton>
                </DialogActions>
              </Dialog>
            )}
            {showAlert && terms_of_use && <CreateAccountAlert onOk={() => setShowAlert(false)} />}
          </SettingsCard>
        </>
      )}
    </>
  );
};
