import { OnboardingSteps } from "@src/pages/auth/onboarding.slice";

export enum StatusCode {
  statusCode200 = 200,
  statusCode400 = 400,
  statusCode500 = 500,
}

export const ONBOARDING_STEPS_NAMES: Record<OnboardingSteps, string> = {
  [OnboardingSteps.ACCOUNT_ACTIVATE]: "Account activate",
  [OnboardingSteps.ENABLE_2FA]: "Enable 2FA",
  [OnboardingSteps.PROFILE_INFORMATION]: "Profile information",
};
