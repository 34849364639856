import React from "react";
import { CefiGroup } from "../../store/widgets.slice";
import { WidgetBase, WidgetBaseProps } from "../widget-base";
import { useGroupData } from "../../hooks/use-group-data";
import tickers from "./b2c2-to-tv-ticker.json";
import coins from "./b2c2-to-tv-coin.json";
import TradingViewWidget from "@src/components/trading-view-widget/trading-view-widget";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";

const getTicker = (symbolObj?: OrderSymbol) => {
  if (!symbolObj) return "";

  const { symbol, coin1, coin2 } = symbolObj;

  if (!coin1 || !coin2) return "";

  const predefined = tickers[symbol];

  if (predefined) return predefined;

  const _coin2 = ["ust", "usc"].includes(coin2.toLowerCase()) ? "USD" : coins[coin2] || coin2;

  return `COINBASE:${coins[coin1] || coin1}${_coin2}`;
};

export const OtcSymbolChart = React.forwardRef((props: WidgetBaseProps, ref: React.Ref<HTMLDivElement>) => {
  const { id, group } = props;
  const groupData = useGroupData(group);

  const { symbol: groupSymbol } = (groupData || {}) as CefiGroup;

  const ticker = getTicker(groupSymbol);

  return (
    <WidgetBase
      {...props}
      ref={ref}
      component={ticker ? <TradingViewWidget ticker={getTicker(groupSymbol)} key={id} /> : null}
    />
  );
});

OtcSymbolChart.displayName = "OtcSymbolChart";
