import React from "react";
import { Stack } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";

export const QrCode = ({ uri, size }: { uri: string; size: number }) => {
  return (
    <Stack
      position="relative"
      width={size}
      height={size}
      sx={{ background: "#fff", borderRadius: (theme) => theme.shape.borderRadius }}
      alignItems="center"
      justifyContent="center"
    >
      <QRCodeSVG value={uri} width={size - 30} height={size - 30} />
    </Stack>
  );
};
