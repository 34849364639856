/* eslint-disable react/display-name */
import React from "react";
import { createColumnHelper, Row } from "@tanstack/table-core";
import { IconButton, Link, Tooltip, Typography } from "@mui/material";
import format from "date-fns/format";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { CELL_FONT_SIZE } from "@src/components/anboto-table";
import { DefiTransaction } from "@src/store/apis/geckoterminal-api/types";
import { formatAmount, roundAmount } from "@src/pages/portfolio/utils/helpers";
import { END_TIME_FORMAT } from "@src/pages/cefi/constants";
import { buildDebankLink } from "@src/pages/defi/token-info/helpers";
import { ReactComponent as DebankLogo } from "@src/assets/svg/debank-logo.svg";
import { CHAIN_CONFIG } from "@src/pages/defi/constants";

export const columnHelper = createColumnHelper<DefiTransaction>();

export const getColumns = ({ chainId, symbol }) => {
  return [
    columnHelper.accessor("kind", {
      id: "kind",
      header: "Side",
      size: 40,
      cell: React.memo(
        ({ row }: { row: Row<DefiTransaction> }) => (
          <Typography
            fontSize={12}
            sx={{
              color: row?.original?.kind?.toUpperCase() === "BUY" ? "success.main" : "error.main",
              fontSize: CELL_FONT_SIZE,
            }}
          >
            {row?.original?.kind?.toUpperCase()}
          </Typography>
        ),
        (prev, next) => prev.row.original.kind === next.row.original.kind
      ),
    }),
    columnHelper.accessor("from_token_amount", {
      id: "from_token_amount",
      header: String(symbol),
      size: 70,
      cell: React.memo(
        ({ row }: { row: Row<DefiTransaction> }) => (
          <Tooltip title={formatAmount(roundAmount(row?.original?.from_token_amount, 3))}>
            <Typography noWrap sx={{ fontSize: 12 }}>
              {formatAmount(roundAmount(row?.original?.from_token_amount, 3))}
            </Typography>
          </Tooltip>
        ),
        (prev, next) => prev.row.original.from_token_amount === next.row.original.from_token_amount
      ),
    }),
    columnHelper.accessor("volume_in_usd", {
      id: "volume_in_usd",
      header: "USD",
      size: 70,
      cell: React.memo(
        ({ row }: { row: Row<DefiTransaction> }) => (
          <Tooltip title={"$" + formatAmount(roundAmount(row?.original?.volume_in_usd, 3))}>
            <Typography noWrap sx={{ fontSize: 12 }}>
              {"$" + formatAmount(roundAmount(row?.original?.volume_in_usd, 3))}
            </Typography>
          </Tooltip>
        ),
        (prev, next) => prev.row.original.volume_in_usd === next.row.original.volume_in_usd
      ),
    }),

    columnHelper.accessor("tx_from_address", {
      id: "tx_from_address",
      header: "From",
      size: 20,
      cell: React.memo(
        ({ row }: { row: Row<DefiTransaction> }) => (
          <IconButton sx={{ borderRadius: 10 }} target="_blank" href={buildDebankLink(row?.original?.tx_from_address)}>
            <DebankLogo width={15} height={15} />
          </IconButton>
        ),
        (prev, next) => prev.row.original.tx_from_address === next.row.original.tx_from_address
      ),
    }),
    columnHelper.accessor("tx_from_address", {
      id: "tx_hash",
      header: "  Tx",
      size: 20,
      cell: React.memo(
        ({ row }: { row: Row<DefiTransaction> }) => (
          <Link target="_blank" href={CHAIN_CONFIG[chainId]?.blockExplorerUrls + "/tx/" + row?.original?.tx_hash}>
            <OpenInNewIcon sx={{ color: "#B0BCC2", fontSize: 16 }} />
          </Link>
        ),
        (prev, next) => prev.row.original.tx_hash === next.row.original.tx_hash
      ),
    }),

    columnHelper.accessor("block_timestamp", {
      id: "block_timestamp",
      header: "Time",
      size: 80,
      cell: React.memo(
        ({ row }: { row: Row<DefiTransaction> }) => (
          <Tooltip
            title={row?.original?.block_timestamp && format(new Date(row?.original?.block_timestamp), END_TIME_FORMAT)}
          >
            <Typography noWrap sx={{ fontSize: 12 }}>
              {row?.original?.block_timestamp && format(new Date(row?.original?.block_timestamp), END_TIME_FORMAT)}
            </Typography>
          </Tooltip>
        ),
        (prev, next) => prev.row.original.block_timestamp === next.row.original.block_timestamp
      ),
    }),
  ];
};
