import React from "react";
import { useSelector } from "react-redux";
import { AllocationMenuCefiItems } from "./allocation-menu-cefi-items";
import { RootState } from "@src/store/types";

export const AllocationCefiMenu = () => {
  const allocationCefiData = useSelector((state: RootState) => state.portfolio.accountCefiAllocation);

  return <AllocationMenuCefiItems allocationItems={allocationCefiData} />;
};
