import React from "react";
import { Paper, Stack, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { BalanceHeader } from "../../components/balance-header";
import { PortfolioSidebarCEFI, useCefiTokenTable } from "@src/pages/portfolio";
import { RootState } from "@src/store/types";

export const PortfolioCefiExchanges = () => {
  const { isFetching } = useCefiTokenTable();
  const selectedExchange = useSelector((state: RootState) => state.portfolio.selectedExchange);

  return (
    <Stack direction="row" flexGrow={1}>
      <SidebarBox>
        <PortfolioSidebarCEFI />
      </SidebarBox>
      <Paper sx={{ flexGrow: 1, position: "relative", gap: 0 }}>
        <BalanceHeader selectedItem={selectedExchange} isFetching={isFetching} />
        <Stack display="flex" flexDirection="column" sx={{ padding: "30px" }}>
          <Outlet />
        </Stack>
      </Paper>
    </Stack>
  );
};

const SidebarBox = styled(Stack)(({ theme }) => ({
  marginRight: 4,
  [theme.breakpoints.down("xl")]: {
    minWidth: 300,
    width: 300,
  },
  [theme.breakpoints.up("xl")]: {
    minWidth: 353,
    width: 353,
  },
}));
