import { ApiKeyCreateResponse, GetApiKeysResponse } from "./types";
import { anbotoApi } from "@src/store/apis/anbotoApi/index";

export const anbotoApiKeys = anbotoApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    createApiKey: builder.mutation<ApiKeyCreateResponse, { teamId: string; label: string }>({
      invalidatesTags: ["API_KEYS"],
      query: ({ teamId, label }) => ({
        method: "POST",
        url: `/user_keys/${teamId}/`,
        body: {
          label,
        },
      }),
    }),
    getApiKeys: builder.query<GetApiKeysResponse, { teamId: string }>({
      providesTags: ["API_KEYS"],
      query: ({ teamId }) => ({
        method: "GET",
        url: `/user_keys/${teamId}/`,
      }),
    }),
    deleteApiKey: builder.mutation<ApiKeyCreateResponse, { teamId: string; label: string }>({
      invalidatesTags: ["API_KEYS"],
      query: ({ teamId, label }) => ({
        method: "DELETE",
        url: `/user_keys/${teamId}/`,
        body: {
          label,
        },
      }),
    }),
  }),
});
