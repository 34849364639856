import React, { useMemo } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { medianOfSortedArray, quartileOfSortedArray, removeArrayItem } from "@src/pages/analytics/helpers";
import HistoricalDataChart from "@src/pages/analytics/funding-rates/historical-data-funding-rates/historical-data-chart";
import { BoxPlotData } from "@src/pages/analytics/types";

type HistoricalDataFundingRates = {
  min: number;
  lowerQuartile: number;
  median: number;
  upperQuartile: number;
  max: number;
  average?: number;
  time?: number;
};

const useBoxPlot = (boxPlots: HistoricalDataFundingRates[]): BoxPlotData[] => {
  const data = useMemo(
    () =>
      boxPlots.map((v) => {
        return {
          min: v.min,
          bottomWhisker: v.lowerQuartile - v.min,
          bottomBox: v.median - v.lowerQuartile,
          topBox: v.upperQuartile - v.median,
          topWhisker: v.max - v.upperQuartile,
          average: v.average,
          size: 500,
          time: Number(v.time),
        };
      }),
    [boxPlots]
  );

  return data;
};

type BoxPlotProps = {
  data: { [symbol: string]: number; time: number }[];
  isFetching?: boolean;
  isError: boolean;
};
const BoxPlot = ({ data, isFetching, isError }: BoxPlotProps) => {
  const fundingRatesHistoricalData = data.map((item) => {
    const itemsValues = Object.values(item);
    const currentItemsValues = removeArrayItem(
      itemsValues,
      itemsValues.reduce((a, b) => Math.max(a, b))
    ).sort((a, b) => a - b);

    const min = currentItemsValues.reduce((a, b) => Math.min(a, b));
    const lowerQuartile = quartileOfSortedArray(currentItemsValues, 0.25);
    const median = medianOfSortedArray(currentItemsValues);
    const upperQuartile = quartileOfSortedArray(currentItemsValues, 0.75);
    const max = currentItemsValues.reduce((a, b) => Math.max(a, b));
    const average = currentItemsValues.reduce((a, b) => a + b) / currentItemsValues.length;

    return {
      min,
      lowerQuartile,
      median,
      upperQuartile,
      max,
      average,
      time: item.time,
    };
  });

  const boxPlotData = useBoxPlot(fundingRatesHistoricalData);

  return (
    <>
      {isError ? (
        <Stack height={300} direction="column" justifyContent="center" textAlign="center">
          <Typography>Something went wrong. Please, try again later</Typography>
        </Stack>
      ) : isFetching ? (
        <Stack alignItems="center" height={300} direction="column" justifyContent="center" textAlign="center">
          <CircularProgress />
        </Stack>
      ) : (
        <HistoricalDataChart boxPlotData={boxPlotData} />
      )}
    </>
  );
};

export default BoxPlot;
