import { readContract } from "@wagmi/core";
import BigNumber from "bignumber.js";
import { blockchainApi } from "@src/store/apis/blockchainApi/index";
import { queryFnFactory } from "@src/store/apis/utils";
import { OPTIMISM_GAS_PRICE_ORACLE_ADDRESS } from "@src/pages/defi/constants";
import ABI from "@src/assets/ABIs/optimism_ovm_gas_price_oracle.json";
import { EVMBasedAddress } from "@src/pages/defi/types/common";
import { ChainId } from "@src/pages/defi/types";
import { wagmiConfig } from "@src/pages/defi/wagmi-config";

const optimismGasPriceOracle = blockchainApi.injectEndpoints({
  endpoints: (builder) => ({
    scalar: builder.query<string, { chainId: ChainId }>({
      queryFn: queryFnFactory<{ chainId: ChainId }, string>(async ({ chainId }) => {
        const scalar = (await readContract(wagmiConfig, {
          address: OPTIMISM_GAS_PRICE_ORACLE_ADDRESS as EVMBasedAddress,
          abi: ABI,
          functionName: "scalar",
          args: [],
          chainId,
        })) as string | null;

        const decimals = (await readContract(wagmiConfig, {
          address: OPTIMISM_GAS_PRICE_ORACLE_ADDRESS as EVMBasedAddress,
          abi: ABI,
          functionName: "decimals",
          args: [],
          chainId,
        })) as string | null;

        if (!scalar || !decimals) {
          return "1";
        }

        return new BigNumber(scalar).div(10 ** new BigNumber(decimals).toNumber()).toString();
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useScalarQuery } = optimismGasPriceOracle;
