import React from "react";
import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { SettingsThumb } from "../settings-thumb";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { CopyToClipboard } from "@src/components";
import { anbotoApi } from "@src/store/apis/anbotoApi";

type WalletsSettingsProps = {
  onEdit: (exchange) => void;
  onDelete: (walletIndex: number) => void;
  setShowCreateDialog: (visible: boolean) => void;
};

export const WalletsSettings = ({ onEdit, onDelete, setShowCreateDialog }: WalletsSettingsProps) => {
  const theme = useTheme();
  const getWalletsQuery = anbotoApi.useGetWalletsQuery(null);
  // const [showcreateDailog, setShowCreateDialog] = useState(false);

  const renderAdress = (address: string) => (
    <Box sx={{ display: "grid", gridTemplateColumns: "1fr 0fr", columnGap: "18px" }}>
      <Typography noWrap variant="body2" sx={{ float: "right", pt: 0.3 }}>
        {address.substring(0, 6) + "..." + address.substring(address.length - 4)}
      </Typography>

      <CopyToClipboard textToCopy={address} />
    </Box>
  );

  return !getWalletsQuery.data?.results.length ? (
    <Box display="flex" alignItems="center" justifyContent="center" sx={{ flex: 1 }}>
      <Stack alignItems="center">
        <AccountBalanceWalletOutlinedIcon sx={{ color: "#3B4043", fontSize: 70 }} />
        <Typography mt={1} color={(theme) => theme.palette.text.secondary}>
          Add your address and start tracking your wallets
        </Typography>
        <AnbotoButton
          variant="contained"
          color="primary"
          onClick={() => setShowCreateDialog(true)}
          sx={{ width: 200, marginTop: theme.spacing(2) }}
        >
          Add wallet
        </AnbotoButton>
      </Stack>
    </Box>
  ) : (
    <>
      <Typography my={2} color={(theme) => theme.palette.text.secondary}>
        Add your address and start tracking your wallets
      </Typography>
      <Stack justifyContent="space-between" sx={{ flex: 1 }}>
        <Grid container spacing={2} pb={2}>
          {getWalletsQuery.data?.results.map((wallet, index) => (
            <Grid xs={4} item key={index}>
              <SettingsThumb
                name={wallet.name}
                variant="contained"
                sx={{ minHeight: 120 }}
                onEdit={() => onEdit(index)}
                onRemove={() => {
                  onDelete(index);
                }}
                items={[
                  {
                    label: "Address",
                    component: renderAdress(wallet.address),
                    visible: !!wallet.address,
                  },
                ]}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );
};
