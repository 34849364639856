import React from "react";
import { styled, Typography, Switch, Stack, StackProps } from "@mui/material";

type MenuItemSwitchProps = {
  label: string;
  secondLabel?: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  checked: boolean;
};

export const MenuItemSwitch = ({
  label,
  secondLabel,
  onClick,
  checked,
  ...props
}: MenuItemSwitchProps & StackProps) => {
  return (
    <Root direction="row" alignItems="center" gap={1.25} onClick={onClick} {...props}>
      <Typography
        sx={{ flex: !secondLabel ? 1 : undefined }}
        color={(secondLabel ? !checked : checked) ? "text.primary" : "text.secondary"}
        variant="body2"
      >
        {label}
      </Typography>
      <StyledSwitch checked={checked} />
      {secondLabel && (
        <Typography color={checked ? "text.primary" : "text.secondary"} variant="body2">
          {secondLabel}
        </Typography>
      )}
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  background: "#192022",
  cursor: "pointer",
  userSelect: "none",
  paddingTop: theme.spacing(1.75),
  paddingBottom: theme.spacing(1.75),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 20,
  height: 10,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 6,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    color: "#192022",
    "&.Mui-checked": {
      color: "#192022",
      transform: "translateX(10px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#179B83" : "#179B83",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 6,
    height: 6,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
}));
