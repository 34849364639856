import React, { useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import format from "date-fns/format";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { END_TIME_FORMAT } from "@src/pages/cefi/constants";
import { AccountingLine, TokenDetails } from "@src/pages/settings/fee-manager/api/types";
import { formatBps, formatUsd } from "@src/utils/format";
import {
  getBlockExplorerLinks,
  getChain,
  getToken,
  getTokenQuantity,
  getTokenValue,
} from "@src/pages/settings/fee-manager/transactions/rows-builder";

type TransactionsTableRowProps = {
  accountingLine: AccountingLine;
  isCollection: boolean;
  tokenDetails: TokenDetails[];
};
const TransactionsTableRow = ({ accountingLine, isCollection, tokenDetails }: TransactionsTableRowProps) => {
  const [isExpandedRowOpened, setIsExpandedRowOpened] = useState(false);

  return (
    <>
      <TableRow key={accountingLine.id} sx={{ cursor: "pointer" }}>
        {isCollection && (
          <TableCell padding="none">
            {accountingLine.operation === "Collection" && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setIsExpandedRowOpened(!isExpandedRowOpened)}
              >
                {isExpandedRowOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
        )}
        <TableCell>{accountingLine.operation === "Collection" ? "Fee charge" : accountingLine.operation}</TableCell>
        <TableCell>
          <Stack>{getToken(accountingLine, tokenDetails)}</Stack>
        </TableCell>
        <TableCell>
          <Stack>{getTokenQuantity(accountingLine)}</Stack>
        </TableCell>
        <TableCell>
          <Stack>{getTokenValue(accountingLine)}</Stack>
        </TableCell>
        <TableCell>
          <Stack>{getChain(accountingLine)}</Stack>
        </TableCell>
        <TableCell>{format(new Date(accountingLine.proceed_at), END_TIME_FORMAT)}</TableCell>
        <TableCell>
          <Stack>{getBlockExplorerLinks(accountingLine.transactions_data)}</Stack>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderTop: 0, borderBottom: isExpandedRowOpened ? 1 : 0 }}
          colSpan={8}
        >
          <Collapse in={isExpandedRowOpened} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography fontSize={14} fontWeight={500} gutterBottom>
                Fee charge info
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Cefi volume</TableCell>
                    <TableCell>Cefi fee</TableCell>
                    <TableCell>Defi volume</TableCell>
                    <TableCell>Defi fee</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{formatUsd(accountingLine.cefi_volume || 0)}</TableCell>
                    <TableCell>{formatBps(accountingLine.cefi_fee_rate || 0)}</TableCell>
                    <TableCell>{formatUsd(accountingLine.defi_volume || 0)}</TableCell>
                    <TableCell>{formatBps(accountingLine.defi_fee_rate || 0)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TransactionsTableRow;
