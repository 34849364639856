import { Position } from "./types";
import { CefiExchangeId, CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";
import { useAppSelector } from "@src/store/hooks";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";

export const useFuturePositions = () => {
  const positions = useAppSelector((state) => state.portfolio.stream.positions);
  const ids = Object.keys(positions).filter((id) => positions[id].symbol.includes(":"));
  const selectedPairs = useAppSelector((state) => state.ordersTable.otherPairsHidden.symbols);
  const showOnlySelectedPairs = useAppSelector((state) => state.ordersTable.otherPairsHidden.value);
  const search = useAppSelector((state) => state.ordersTable.search);

  return ids.reduce<Position[]>((res, id) => {
    const position = positions[id] as unknown as Position;

    if (!position.balance) return res;

    const [exchange, subaccount, marketType, symbol] = id.split("~");

    if (
      showOnlySelectedPairs &&
      !selectedPairs.includes(
        getLivePriceId({
          symbol,
          exchange,
          market_type: marketType as CefiExchangeMarketType,
        })
      )
    ) {
      return res;
    }

    if (search && !search.split(" ").every((word) => id.toLowerCase().includes(word.toLowerCase()))) return res;

    return [
      ...res,
      {
        ...position,
        id,
        exchange: exchange as CefiExchangeId,
        subaccount: subaccount,
        symbol,
      },
    ];
  }, []);
};
