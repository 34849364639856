import { format, subDays, subMonths, subWeeks } from "date-fns";
import { GroupBy } from "@src/store/slices/metricsSlice";
import { GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
export const getLast36monthDates = (): { start_time: string; end_time: string } => {
  const today = new Date();
  const thirtySixMonthAgo = new Date();
  today.setDate(today.getDate());
  thirtySixMonthAgo.setMonth(thirtySixMonthAgo.getMonth() - 36);

  return {
    start_time: formatDate(thirtySixMonthAgo),
    end_time: formatDate(today),
  };
};

export const dateFormatter = (date: number, tickerRange: GroupBy) => {
  if (!isNaN(date)) {
    return format(new Date(date), tickerRange === "months" ? "MMM yyyy" : "dd MMM");
  }
};

export const formatUsdBigAmounts = (number) => {
  if (number > 1000000000 || number < -1000000000) {
    return (number / 1000000000).toString() + "b $";
  } else if (number > 1000000 || number < -1000000) {
    return (number / 1000000).toString() + "m $";
  } else if (number > 1000 || number < -1000) {
    return (number / 1000).toString() + "k $";
  } else {
    return number.toString() + " $";
  }
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const trimMetricsTradesGroupedData = (data) => {
  const result = { ...data };
  const keysToRemove: string[] = [];
  const keys = Object.keys(result).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  for (const key of keys) {
    if (isEmptyObject(result[key])) {
      keysToRemove.push(key);
    } else {
      break;
    }
  }

  for (const key of keysToRemove) {
    delete result[key];
  }

  return result;
};

export const getExchangeNameFromServerExchangeName = (exhange: string): string => {
  if (exhange === "DeFi") return "Defi Sor";
  if (exhange === "1INCH") return "1INCH";
  return exhange.replace(/([A-Z])/g, " $1").trim();
};

export const getTimestampForPeriod = (period: GetOrdersPeriod): number => {
  const now = new Date();
  let startTime;

  switch (period) {
    case GetOrdersPeriod.Day:
      startTime = subDays(now, 1).getTime() / 1000;
      break;
    case GetOrdersPeriod.Week:
      startTime = subWeeks(now, 1).getTime() / 1000;
      break;
    case GetOrdersPeriod.Month:
      startTime = subMonths(now, 1).getTime() / 1000;
      break;
    case GetOrdersPeriod.ThreeMonths:
      startTime = subMonths(now, 3).getTime() / 1000;
      break;
    case GetOrdersPeriod.All:
      startTime = subMonths(now, 12).getTime() / 1000;
      break;
    default:
      startTime = now.getTime() / 1000;
  }

  return startTime;
};
