import { styled } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const AdvancedSettingsError = styled(ErrorOutlineIcon)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(3),
  top: "50%",
  transform: "translateY(-50%)",
  color: theme.palette.error.main,
  fontSize: 16,
}));
