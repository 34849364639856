import { useRef, useState, useEffect } from "react";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";

export const useSupportedExchanges = ({ isDefi }: { isDefi: boolean }) => {
  const exchangesRef = useRef<Record<CefiExchangeId, string>>({} as Record<CefiExchangeId, string>);
  const [, forceUpdate] = useState(0);

  const getExchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi });

  useEffect(() => {
    const _exchanges =
      getExchangesQuery.data?.results.reduce((res, { exchange_id, name }) => ({ ...res, [exchange_id]: name }), {}) ||
      {};

    exchangesRef.current = _exchanges as Record<CefiExchangeId, string>;

    forceUpdate(+new Date());
  }, [getExchangesQuery.data, getExchangesQuery.isFetching]);

  const getExchange = (exchangeId: string) => exchangesRef.current[exchangeId];
  const getExchanges = () => exchangesRef.current;

  return { getExchanges, getExchange };
};
