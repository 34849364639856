import React, { useCallback } from "react";
import { styled } from "@mui/material";
import { OrdersType } from "../types";
import { useOrderbookContext } from "../hooks/use-orderbook-context";
import { ROW_HEIGHT } from "../constants";
import { formatPrice } from "../helpers";
import { Indicator } from "./indicator";
import { Price } from "./price";
import { Amount } from "./amount";
import { Total } from "./total";
import { AggregatedInfoTooltip } from "./aggregated-info-tooltip";
import { formatTokenAmount } from "@src/utils/format";

export const OrdersList = ({ type, ...props }: { type: OrdersType; style?: React.CSSProperties }) => {
  const { asks, bids, decimals, updateAggregatedInfo, onRowClick } = useOrderbookContext();
  const arr = type === "ask" ? asks : bids;

  const handleMouseOver = useCallback((event: React.MouseEvent<HTMLElement>) => {
    const root = event.currentTarget;
    const row = (event.target as Element).closest("li");
    if (!row || !root.contains(row)) return;

    const index = Array.prototype.indexOf.call(root.children, row);
    updateAggregatedInfo(index, type);
  }, []);

  return (
    <AggregatedInfoTooltip {...props}>
      <Root type={type} onMouseOver={handleMouseOver} className="orderbook-list">
        {arr.map(({ price, amount, total, accAmount }, index) => (
          <Row
            key={type === "ask" ? length - index : index}
            price={price}
            amount={amount}
            accAmount={accAmount}
            total={total}
            type={type}
            decimals={decimals}
            onClick={onRowClick}
          />
        ))}
      </Root>
    </AggregatedInfoTooltip>
  );
};

const Root = styled("ul")<{ type: OrdersType }>(({ type }) => ({
  display: "flex",
  flexDirection: type === "bid" ? "column" : "column-reverse",
}));

const Row = React.memo(
  ({
    price,
    amount,
    accAmount,
    type,
    total,
    decimals,
    onClick,
  }: {
    price: number;
    amount: number;
    accAmount: number;
    type: OrdersType;
    total?: number;
    decimals: number;
    onClick?: (price: string) => void;
  }) => (
    <StyledRow className="orderbook-row" onClick={() => onClick && onClick(price.toString())}>
      <Price type={type}>{formatPrice(price, decimals)}</Price>
      <Amount>{formatTokenAmount(amount)}</Amount>
      <Total>{formatPrice(total || 0, (total || 0) > 10 ? 2 : decimals)}</Total>
      <Indicator type={type} amount={accAmount} />
    </StyledRow>
  )
);

Row.displayName = "Row";

OrdersList.displayName = "OrdersList";

const StyledRow = styled("li")(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  heigh: ROW_HEIGHT,
  alignItems: "center",
  position: "relative",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  lineHeight: `${ROW_HEIGHT}px`,
  overflow: "hidden",
  ".orderbook-list:hover &": {
    background: "rgb(130 127 161/0.25)",
  },
  "&:hover~&": {
    background: "none",
  },
}));
