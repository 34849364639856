import React from "react";
import { CircularProgress, IconButton, IconButtonProps, styled, Tooltip } from "@mui/material";
import { Pause, PlayArrow, Remove, RefreshOutlined, LocalAtm } from "@mui/icons-material";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export type TableActionButtonType = "cancel" | "pause" | "resume" | "restart" | "withdraw" | "delete" | "analysis";

export type TableActionButtonProps = {
  type: TableActionButtonType;
  loading?: boolean;
  onClick?: () => void;
  tooltip?: string;
  disabled?: boolean;
  sx?: IconButtonProps["sx"];
};

const StyledActionIconButton = styled(IconButton)({
  backgroundColor: "#8A9296",
  fontSize: 14,
  color: "black",
  padding: "2px",
});

const icons: Record<TableActionButtonType, React.ReactNode> = {
  cancel: <Remove fontSize="inherit" />,
  pause: <Pause fontSize="inherit" />,
  resume: <PlayArrow fontSize="inherit" />,
  restart: <RefreshOutlined fontSize="inherit" />,
  withdraw: <LocalAtm fontSize="inherit" />,
  delete: <DeleteOutlineIcon fontSize="inherit" />,
  analysis: <QueryStatsOutlinedIcon fontSize="inherit" />,
};

export const TableActionButton = ({
  type,
  loading,
  onClick,
  tooltip,
  disabled = false,
  sx,
}: TableActionButtonProps) => {
  if (loading) return <CircularProgress size={18} />;

  if (tooltip)
    return (
      <Tooltip title={tooltip}>
        <StyledActionIconButton sx={sx} size="small" onClick={() => onClick && onClick()} disabled={disabled}>
          {icons[type]}
        </StyledActionIconButton>
      </Tooltip>
    );

  return (
    <StyledActionIconButton sx={sx} size="small" onClick={() => onClick && onClick()} disabled={disabled}>
      {icons[type]}
    </StyledActionIconButton>
  );
};
