import React from "react";
import { Divider, Stack, styled, Typography } from "@mui/material";
import { AllParentOrder } from "@src/store/apis/anbotoApi/types";
import { formatUsd } from "@src/utils/format";
import { ExpandedRow as CefiExpandedRow } from "@src/pages/cefi/orders-table/components/expanded-row";
import { ExpandedRow as DefiExpandedRow } from "@src/pages/defi/orders-table-container/expanded-row";

export const ExpandedRow = ({ data }: { data: AllParentOrder }) => {
  const { order_type, order_id, trader_email, is_trigger, last_status, params } = data;
  const { trading_style } = params;

  return (
    <Root>
      <Stack paddingLeft={5} paddingTop={1} gap={2} direction="row" alignItems="center">
        <Stack direction="row" gap={2}>
          <Typography variant="body2">Trader: {trader_email}</Typography>
          <Typography variant="body2">Is trigger: {is_trigger ? "Yes" : "No"}</Typography>
          {trading_style && <Typography variant="body2">Trading style: {trading_style}</Typography>}
          {last_status?.average_price && (
            <Typography variant="body2">Average price: {formatUsd(last_status?.average_price)}</Typography>
          )}
        </Stack>
        <Divider />
      </Stack>

      {order_type === "cefi" ? (
        <CefiExpandedRow isExtraHeader={true} data={data} key={order_id} />
      ) : (
        <DefiExpandedRow isExtraHeader={true} data={data} key={order_id} />
      )}
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  background: theme.custom.background.default,
}));
