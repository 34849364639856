import BigNumber from "bignumber.js";
import { useOrderFormGasAmountEstimation } from "@src/pages/defi/order-form-card-no-gas/hooks/use-order-form-gas-amount-estimation";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { ChainId } from "@src/pages/defi/types";
import { calculateNumberOfSlices } from "@src/pages/defi/utils";
import { useDefiOrderFormContext, useBaseCoinPrice } from "@src/pages/defi/hooks";
import { OrderExecutionStrategy, OrderSide } from "@src/store/apis/anbotoApi/types";
import { useScalarQuery } from "@src/store/apis/blockchainApi/optimism-gas-price-oracle";
import { useDcaStrategyQuantity } from "@src/pages/defi/hooks/use-dca-strategy-quantity";

export const useEstimatedGasCostPerSlice = () => {
  const orderForm = useDefiOrderFormContext();
  const { averageGasCost, averageGasCostL2 } = useOrderFormGasAmountEstimation();
  const chainId = orderForm.watch("chainId");
  const gasPriceOption = orderForm.watch("gasPriceOption");
  const maxGasPrice = orderForm.watch("maxGasPrice");
  const clipSizeType = orderForm.watch("clipSizeType");
  const clipSizeValue = orderForm.watch("clipSizeValue");
  const fromTokenAmount = orderForm.watch("fromTokenAmount");
  const toTokenAmount = orderForm.watch("toTokenAmount");
  const buyTokenToEthRate = orderForm.watch("buyTokenToEthRate");
  const sellTokenToEthRate = orderForm.watch("sellTokenToEthRate");

  const side = orderForm.watch("side");
  const strategy = orderForm.watch("strategy");
  const isFeeTakenInInput = orderForm.watch("isFeeTakenInInput");
  const outputTokenToEthRate = side === OrderSide.BUY ? sellTokenToEthRate : buyTokenToEthRate;
  const inputTokenToEthRate = side === OrderSide.BUY ? buyTokenToEthRate : sellTokenToEthRate;

  // Only for Optimism and Base we need L2, if it is empty, we use default MIN_GAS_UNITS_PER_SLICE
  const { data: gasOracleDataL1 } = anbotoApi.useGetGasOracleQuery({ chainId });
  const { nativeCoinPrice } = useBaseCoinPrice(chainId);
  const { data: scalar = "1" } = useScalarQuery(
    { chainId },
    { skip: chainId !== ChainId.OPTIMISM && chainId !== ChainId.BASE }
  );

  const dcaQuantity = useDcaStrategyQuantity();
  const isDcaStrategy = strategy === OrderExecutionStrategy.DCA;
  const numberOfSlices = calculateNumberOfSlices({
    clipSizeType,
    clipSizeValue,
    quantity: isDcaStrategy ? dcaQuantity : side === OrderSide.SELL ? fromTokenAmount : toTokenAmount,
    strategy,
  });

  const usdChainTokenPrice = nativeCoinPrice?.usd || 0;
  // default data are getting from the cache but for skipping chains the cache is not up to date
  let tokenFeeUsdPrice: BigNumber;
  if (isFeeTakenInInput) {
    tokenFeeUsdPrice = inputTokenToEthRate
      ? new BigNumber(usdChainTokenPrice).div(new BigNumber(inputTokenToEthRate))
      : new BigNumber("0");
  } else {
    tokenFeeUsdPrice = outputTokenToEthRate
      ? new BigNumber(usdChainTokenPrice).div(new BigNumber(outputTokenToEthRate))
      : new BigNumber("0");
  }

  const maxGasPriceInNetworkTokenL1 = new BigNumber(maxGasPrice).div(10 ** 9);
  const gasPriceL1 = gasOracleDataL1 && gasPriceOption ? gasOracleDataL1.data[gasPriceOption] : "0";
  const gasPriceInNetworkTokenL1 = new BigNumber(gasPriceL1).div(10 ** 9);

  // Only for Optimism and Base we need L2, if it is empty, we use default MIN_GAS_UNITS_PER_SLICE
  if (chainId === ChainId.OPTIMISM || chainId === ChainId.BASE) {
    const chainGasCostPerSliceL1 = new BigNumber(gasPriceInNetworkTokenL1)
      .multipliedBy(averageGasCost)
      .multipliedBy(scalar);
    const chainMaxGasCostPerSliceL1 = new BigNumber(maxGasPriceInNetworkTokenL1)
      .multipliedBy(averageGasCost)
      .multipliedBy(scalar);
    const chainGasCostPerSliceL2 = new BigNumber(gasPriceInNetworkTokenL1).multipliedBy(averageGasCostL2);
    const chainMaxGasCostPerSliceL2 = new BigNumber(maxGasPriceInNetworkTokenL1).multipliedBy(averageGasCostL2);

    const chainGasCostPerSliceAmount = chainGasCostPerSliceL1.plus(chainGasCostPerSliceL2);
    const chainMaxGasCostPerSliceAmount = chainMaxGasCostPerSliceL1.plus(chainMaxGasCostPerSliceL2);
    const feeAbsolutePerSlice = chainGasCostPerSliceAmount.multipliedBy(
      isFeeTakenInInput ? inputTokenToEthRate : outputTokenToEthRate
    );
    const maxFeeAbsolutePerSlice = chainMaxGasCostPerSliceAmount.multipliedBy(
      isFeeTakenInInput ? inputTokenToEthRate : outputTokenToEthRate
    );
    const feeAbsoluteTotal = feeAbsolutePerSlice.multipliedBy(numberOfSlices);
    const maxFeeAbsoluteTotal = maxFeeAbsolutePerSlice.multipliedBy(numberOfSlices);
    const feeAbsolutePerSliceUsd = feeAbsolutePerSlice.multipliedBy(tokenFeeUsdPrice);
    const maxFeeAbsolutePerSliceUsd = maxFeeAbsolutePerSlice.multipliedBy(tokenFeeUsdPrice);
    const feeAbsoluteUsd = feeAbsolutePerSliceUsd.multipliedBy(numberOfSlices);
    const maxFeeAbsoluteUsd = maxFeeAbsolutePerSliceUsd.multipliedBy(numberOfSlices);

    // note that ETH USD price got from Eth network
    return {
      feeAbsolute: feeAbsoluteTotal.toString(),
      maxFeeAbsolute: maxFeeAbsoluteTotal.toString(),
      feeAbsoluteUsd: feeAbsoluteUsd.toString(),
      maxFeeAbsoluteUsd: maxFeeAbsoluteUsd.toString(),
    };
  }

  const chainGasCostPerSlice = new BigNumber(gasPriceInNetworkTokenL1).multipliedBy(averageGasCost);
  const chainMaxGasCostPerSlice = new BigNumber(maxGasPriceInNetworkTokenL1).multipliedBy(averageGasCost);

  const feeAbsolutePerSlice = chainGasCostPerSlice.multipliedBy(
    isFeeTakenInInput ? inputTokenToEthRate : outputTokenToEthRate
  );
  const maxFeeAbsolutePerSlice = chainMaxGasCostPerSlice.multipliedBy(
    isFeeTakenInInput ? inputTokenToEthRate : outputTokenToEthRate
  );

  const feeAbsoluteTotal = feeAbsolutePerSlice.multipliedBy(numberOfSlices);
  const maxFeeAbsoluteTotal = maxFeeAbsolutePerSlice.multipliedBy(numberOfSlices);
  const feeAbsolutePerSliceUsd = feeAbsolutePerSlice.multipliedBy(tokenFeeUsdPrice);
  const maxFeeAbsolutePerSliceUsd = maxFeeAbsolutePerSlice.multipliedBy(tokenFeeUsdPrice);
  const feeAbsoluteUsd = feeAbsolutePerSliceUsd.multipliedBy(numberOfSlices);
  const maxFeeAbsoluteUsd = maxFeeAbsolutePerSliceUsd.multipliedBy(numberOfSlices);

  return {
    feeAbsolute: feeAbsoluteTotal.toString(),
    maxFeeAbsolute: maxFeeAbsoluteTotal.toString(),
    feeAbsoluteUsd: feeAbsoluteUsd.toString(),
    maxFeeAbsoluteUsd: maxFeeAbsoluteUsd.toString(),
  };
};
