import React from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import { SelectTokenDialog } from "./select-token-dialog";
import { useDialog } from "@src/hooks/useDialog";
import { ChainId } from "@src/pages/defi/types";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { SelectArrow } from "@src/components/select-arrow";
import { useGetTokenByAddressAndChainQuery } from "@src/store/apis/algoliaApi";
import { TokenAvatar } from "@src/components/token-avatar";

export type TokenSelectProps = {
  account?: string;
  selectedTokenAddress: string;
  onTokenSelect(tokenSymbol: string): void;
  disabled?: boolean;
  chainId: ChainId;
};

export const TokenAddressField = ({
  account,
  selectedTokenAddress,
  onTokenSelect,
  disabled,
  chainId,
}: TokenSelectProps) => {
  const selectTokenDialog = useDialog();

  const { data: selectedToken } = useGetTokenByAddressAndChainQuery(
    { address: selectedTokenAddress!, chainId: chainId! },
    { skip: !selectedTokenAddress || !chainId }
  );

  const _onTokenSelect = (tokenSymbol: string) => {
    selectTokenDialog.hide();
    onTokenSelect(tokenSymbol);
  };

  return (
    <StyledRootBox>
      <StyledAnbotoButton disabled={disabled} variant="outlined" onClick={selectTokenDialog.show}>
        {selectedToken && (
          <Stack direction="row" gap={1} alignItems="center" width="100%">
            <TokenAvatar
              address={selectedToken?.address}
              chainId={chainId}
              symbol={selectedToken?.symbol}
              sx={{
                height: 16,
                width: 16,
                borderRadius: "50%",
                display: "block",
                fontSize: 12,
                lineHeight: "16px",
              }}
            />
            <Typography sx={{ flexGrow: 1, fontSize: 14 }} textAlign="left">
              {selectedToken.symbol}
            </Typography>
            <SelectArrow />
          </Stack>
        )}
      </StyledAnbotoButton>
      {selectTokenDialog.isOpen && (
        <SelectTokenDialog
          account={account}
          chainId={chainId}
          isOpen={selectTokenDialog.isOpen}
          onClose={selectTokenDialog.hide}
          onTokenSelect={_onTokenSelect}
        />
      )}
    </StyledRootBox>
  );
};

const StyledRootBox = styled(Box)(({ theme }) => ({
  maxHeight: 40,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 4,
  padding: "2px",
}));

const StyledAnbotoButton = styled(AnbotoButton)(({ theme }) => ({
  border: "none !important",
  display: "flex",
  px: 1,
  minWidth: 140,
  alignItems: "center",
  backgroundColor: theme.custom.background.secondary,
  padding: "2px 8px",
  height: "100%",
}));
