import React, { useEffect } from "react";
import { Menu, Typography, useTheme } from "@mui/material";
import { GROUPS } from "../constants";
import { GroupList } from "./group-list";
import { WidgetIconButton } from "./widget-icon-button";
import { useWindowSize } from "@src/hooks/use-window-size";

type GroupMenuProps = {
  selected?: string;
  onChange: (id: string) => void;
  noEmptyGroup?: boolean;
};

export const GroupMenu = ({ selected = "", onChange, noEmptyGroup }: GroupMenuProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [cornerPosition, setCornerPosition] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const settingsOpen = Boolean(anchorEl);
  const groupIds = GROUPS.map(({ id }) => id).filter((id) => (noEmptyGroup ? !!id : true));
  const color = GROUPS.find((group) => group.id === selected)?.color || GROUPS[0]?.color;
  const windowSize = useWindowSize();

  const handleGroupMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGroupMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value: string) => {
    onChange && onChange(value);
    handleGroupMenuClose();
  };

  useEffect(() => {
    if (anchorEl) {
      setTimeout(() => {
        const popupRight = document.getElementById("group-select")?.getBoundingClientRect().right || 0;
        const { left, right, top, height } = anchorEl.getBoundingClientRect();

        if (popupRight < right + 10) {
          setCornerPosition((state) => ({ ...state, x: popupRight - 10 }));
        } else {
          setCornerPosition({ x: left + (right - left) / 2, y: top + height });
        }
      });
    }
  }, [anchorEl, windowSize.width]);

  return (
    <>
      <WidgetIconButton
        aria-controls={settingsOpen ? "group-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={settingsOpen ? "true" : undefined}
        sx={{ width: 22, height: 20, border: `1px solid ${color}` }}
        onClick={handleGroupMenuOpen}
      >
        <Typography fontSize={10} color={color} fontWeight="bold">
          {groupIds.includes(selected) ? selected : "-"}
        </Typography>
      </WidgetIconButton>
      <Menu
        anchorEl={anchorEl}
        open={settingsOpen}
        onClose={handleGroupMenuClose}
        transitionDuration={0}
        PaperProps={{
          sx: {
            overflow: "visible",
            backgroundColor: theme.custom.background.secondary,
            mt: 1.5,
            padding: 0,
            "& .MuiMenu-list": {
              padding: 0,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "fixed",
              top: cornerPosition.y,
              left: cornerPosition.x,
              width: 10,
              height: 10,
              backgroundColor: theme.custom.background.secondary,
              transform: "translateX(-50%) translateY(7px) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <GroupList
          id="group-select"
          getSelectedLabel={(id) => (id ? `Group ${id}` : "No group")}
          groupIds={groupIds}
          onGroupClick={(id) => handleChange(id)}
          selected={selected}
          p={1}
        />
      </Menu>
    </>
  );
};
