import React, { FC, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { Box, Stack, styled } from "@mui/material";
import { TokenAddressField } from "./token-address-field";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { useAccountChainTokenBalances } from "@src/pages/portfolio/hooks/use-account-chain-token-balances";
import { onlyNumbers } from "@src/utils/only-numbers";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { LoaderForInput, SubLabel } from "@src/pages/portfolio";
import { useSendCryptoFormContext } from "@src/pages/portfolio/components/send-crypto/hooks";
import { Token } from "@src/pages/defi/types";
import { formatTokenAmount } from "@src/utils/format";

export interface SelectTokenWithAmountProps {
  account: string | undefined;
  selectedToken: Token;
}

// TODO - preselect token with non-zero balance after rainbow kit integration
export const SelectTokenWithAmount: FC<SelectTokenWithAmountProps> = ({ account, selectedToken }) => {
  const sendCryptoForm = useSendCryptoFormContext();
  const chainId = sendCryptoForm.watch("chainId");
  const tokenAddress = sendCryptoForm.watch("tokenAddress");
  const { balances, isFetching } = useAccountChainTokenBalances({ account, chainId });
  const currencyBalance = (!!tokenAddress && !!balances && balances[selectedToken.symbol.toLowerCase()]) || 0;
  const balancePreSet = useRef<boolean>(false);

  const onFromTokenAmountChange = (newTokenAmount: string) => {
    const value = onlyNumbers(newTokenAmount);

    sendCryptoForm.setValue("tokenAmount", value);
  };

  const onTokenAddressChange = (newTokenAddress: string) => {
    sendCryptoForm.setValue("tokenAddress", newTokenAddress);
  };

  const onMaxClick = () => {
    sendCryptoForm.setValue("tokenAmount", currencyBalance?.toString());
  };

  const amountError = sendCryptoForm.formState.errors?.tokenAmount?.message;

  useEffect(() => {
    sendCryptoForm.setValue("tokenBalance", currencyBalance);
  }, [currencyBalance]);

  useEffect(() => {
    if (!currencyBalance && balances && !balancePreSet.current) {
      const positiveBalanceAddress = Object.keys(balances).find((address) => balances[address]);

      if (positiveBalanceAddress) {
        onTokenAddressChange(positiveBalanceAddress);
        balancePreSet.current = true;
      }
    }
  }, [balances, currencyBalance]);

  return (
    <Stack direction="column" gap={0.75}>
      <Stack
        direction="row"
        width="100%"
        overflow="hidden"
        sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
      >
        <Controller
          name="tokenAddress"
          control={sendCryptoForm.control}
          render={() => (
            <TokenAddressField
              account={account}
              chainId={chainId}
              selectedTokenAddress={selectedToken?.address}
              onTokenSelect={onTokenAddressChange}
            />
          )}
        />
        <Controller
          name="tokenAmount"
          control={sendCryptoForm.control}
          render={({ field, fieldState, formState }) => (
            <AnbotoTextField
              {...field}
              fullWidth
              disabled={formState.isSubmitting}
              error={!!fieldState.error}
              onChange={(e) => onFromTokenAmountChange(e.target.value)}
              InputProps={{
                startAdornment: isFetching ? <LoaderForInput /> : undefined,
                sx: {
                  backgroundColor: (theme) => theme.palette.background.paper,
                  px: 0,
                  pr: "2px",
                  borderRadius: 0,
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                },
                type: "number",
              }}
              placeholder="0"
            />
          )}
        />
        <MaxButton onClick={onMaxClick} size="small">
          MAX
        </MaxButton>
      </Stack>
      <Box sx={{ display: "grid", gridTemplateColumns: "144px 1fr 144px" }}>
        <SubLabel>Available</SubLabel>
        <ErrorLabel>{amountError || ""}</ErrorLabel>
        <Amount>{formatTokenAmount(currencyBalance)}</Amount>
      </Box>
    </Stack>
  );
};

const MaxButton = styled(AnbotoButton)(({ theme }) => ({
  maxHeight: "40px",
  backgroundColor: theme.palette.background.paper,
  borderRadius: 0,
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "21px",
}));

const Amount = styled("span")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "21px",
  textAlign: "right",
}));

const ErrorLabel = styled("span")(({ theme }) => ({
  color: theme.palette.error.main,
  fontWight: 400,
  fontSize: "0.75rem",
}));
