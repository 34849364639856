import React from "react";
import { Stack } from "@mui/material";
import { AnbotoLoadingIndicator } from "../anboto-loading-indicator";
import { useAppSelector } from "@src/store/hooks";
import { AuthenticatorContext } from "@src/features/auth";

export const AnbotoGlobalLoader = ({ children }: React.PropsWithChildren<{}>) => {
  const { ready } = React.useContext(AuthenticatorContext);
  const loading = useAppSelector((state) => state.appState.initialDataLoading);

  return (
    <>
      {(!ready || loading) && (
        <Stack
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          position="fixed"
          zIndex={999999}
          left={0}
          bottom={0}
          sx={{ background: (theme) => theme.palette.background.default }}
        >
          <AnbotoLoadingIndicator style={{ height: 90 }} />
        </Stack>
      )}
      {ready && children}
    </>
  );
};
