import { configureStore } from "@reduxjs/toolkit";
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { cryptocompareApi } from "./apis/cryptocompareApi";
import { algoliaApi } from "./apis/algoliaApi";
import { listenerMiddleware } from "./apis/anbotoApi/listener-middleware";
import { persistedReducer } from "@src/store/persistedReducer";
import { debankApi } from "@src/store/apis/debankApi";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { zeroexApi } from "@src/store/apis/zeroexApi";
import { coingeckoApi } from "@src/store/apis/coingeckoApi";
import { geckoTerminalApi } from "@src/store/apis/geckoterminal-api";

export const store = configureStore({
  devTools: true,
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["blockchain"],
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "blockchain/connectWcWallet/fulfilled",
          "blockchain/connectMetamaskWallet/fulfilled",
        ],
      },
    })
      .prepend(listenerMiddleware.middleware)
      .concat(algoliaApi.middleware)
      .concat(anbotoApi.middleware)
      .concat(debankApi.middleware)
      .concat(blockchainApi.middleware)
      .concat(coingeckoApi.middleware)
      .concat(cryptocompareApi.middleware)
      .concat(zeroexApi.middleware)
      .concat(geckoTerminalApi.middleware),
});
