import React from "react";
import { Tabs } from "@mui/material";
import { AnbotoTab } from "@src/components/ui/AnbotoTabs/AnbotoTab";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { ReferralProgramTabs, setCurrentTab } from "@src/store/slices/referralProgramSlice";

const ReferralTabs = () => {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector((state) => state.referralProgram.currentTab);

  const handleTabChange = (e: React.SyntheticEvent, tab: ReferralProgramTabs) => {
    dispatch(setCurrentTab(tab));
  };

  return (
    <Tabs variant="fullWidth" value={currentTab} sx={{ mb: 0, minHeight: 28 }} onChange={handleTabChange}>
      <AnbotoTab sx={{ minHeight: 28, padding: "8px 12px" }} label="Overview" value={ReferralProgramTabs.OVERVIEW} />
      <AnbotoTab
        sx={{ minHeight: 28, padding: "8px 12px" }}
        label="Commissions"
        value={ReferralProgramTabs.COMMISSIONS}
      />
      <AnbotoTab
        sx={{ minHeight: 28, padding: "8px 12px" }}
        label="Tier structure"
        value={ReferralProgramTabs.TIER_STRUCTURE}
      />
    </Tabs>
  );
};

export default ReferralTabs;
