import React from "react";
import ReactDOM from "react-dom/client";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import "core-js/features/promise";
import "intro.js/introjs.css";
import "react-activity/dist/library.css";
import "@src/styles/application.scss";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { FallbackPage } from "@src/pages/fallback";

BigInt.prototype["toJSON"] = function () {
  return this.toString();
};

Sentry.init({
  dsn: "https://3c9e733e68e14f9ea7c3401ba861d50a@o578146.ingest.sentry.io/6619407",
  enabled: process.env.NODE_ENV === "production",
  environment: process.env.REACT_APP_SENTRY_ENV || "dev",
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <Sentry.ErrorBoundary fallback={<FallbackPage />}>
    <App />
  </Sentry.ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
