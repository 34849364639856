import { OrderSymbol, CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";

export const BREAKPOINTS = { xxl: 3200, xl: 2400, lg: 1500, md: 1200, sm: 960, xs: 480 };

export const LAYOUT_VERSION = 1;

export const MAX_LAYOUTS = 6;

export const LAYOUT_COLUMNS = 24;

export const LAYOUT_ROW_HEIGHT = 20;

export const DRAG_HANDLE_CSS_CLASS_NAME = "drag-handle";

export const WIDGET_HEADER_HEIGHT = 36;

export const DEFAULT_SYMBOL: OrderSymbol = {
  symbol: "BTC/USDT",
  exchange: "Binance",
  market_type: CefiExchangeMarketType.SPOT,
  coin1: "BTC",
  coin2: "USDT",
};

export const DEFAULT_OTC_SYMBOL: OrderSymbol = {
  symbol: "BTC/USD",
  exchange: "b2c2",
  market_type: CefiExchangeMarketType.SPOT,
  coin1: "BTC",
  coin2: "USD",
};

export const MAX_GROUPS = 4;
export const OTC_MAX_GROUPS = 10;

export const GROUPS = [
  { id: "1", color: "#179B83" },
  { id: "2", color: "#2C72E3" },
  { id: "3", color: "#ECAC27" },
  { id: "4", color: "#56C3F2" },
  { id: "5", color: "#179B83" },
  { id: "", color: "#51596cbf" },
];
