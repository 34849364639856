import React from "react";
import { Link } from "@mui/material";
import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";

const binance = (
  <ol>
    <li>
      <Link href="https://accounts.binance.com/en/login" target="_blank" rel="noreferrer">
        Log in
      </Link>{" "}
      to your Binance Account
    </li>
    <li>
      <Link href="https://www.binance.com/en/my/settings/api-management" target="_blank" rel="noreferrer">
        API Management
      </Link>
      {" >"} Create API {">"} System Generated
    </li>
    <li>
      Edit Restrictions
      <ul>
        <li>Enable Spot and Future trading</li>
        <li>Copy and paste all the IPs listed below (Separated by spaces)</li>
      </ul>
    </li>
    <li>Input the API Key and Secret Key on the Anboto Platform</li>
  </ol>
);

const woox = (
  <ol>
    <li>
      <Link href="https://x.woo.org/en/trade" target="_blank">
        Log in
      </Link>{" "}
      to your WOO X Account
    </li>
    <li>
      My Account {">"}{" "}
      <Link href="https://x.woo.org/en/account/sub-account" target="_blank">
        Subaccounts and API
      </Link>
    </li>
    <li>Permissions {">"} Enable Trading</li>
    <li>Allowed IPs {">"} Paste all the IPs listed below (Separated by commas)</li>
    <li>Input the Application ID, API Key and Secret code on the Anboto Platform</li>
    <li>
      <Link href="https://x.woo.org/en/account/spot-margin">Margin & Futures</Link> {">"} Trade Spot & Futures
    </li>
  </ol>
);

const bybit = (
  <ol>
    <li>
      <Link href="https://www.bybit.com/en-US/login" target="_blank">
        Log in
      </Link>{" "}
      to your Bybit Account
    </li>
    <li>
      My Profile {">"}{" "}
      <Link target="_blank" href="https://www.bybit.com/app/user/api-management">
        API
      </Link>{" "}
      {">"} Create New Key {">"} System-generated API Keys
    </li>
    <li>API Key Usage {">"} API Transaction</li>
    <li>
      API Key Permissions
      <ul>
        <li>Read-write</li>
        <li>Paste all the IPs listed below (Separated by commas)</li>
        <li>Enable all; remove CopyTrading, Withdrawal and NFT</li>
      </ul>
    </li>
    <li>Input the API Key and Secret Code on the Anboto Platform</li>
  </ol>
);

const gateio = (
  <ol>
    <li>
      <Link href="https://www.gate.io/login" target="_blank">
        Log in
      </Link>{" "}
      to your Gate.io Account
    </li>
    <li>
      My Profile {">"}{" "}
      <Link href="https://www.gate.io/myaccount/api_key_manage" target="_blank">
        API Management
      </Link>{" "}
      {">"} Create API Key
    </li>
    <li>IP Permissions {">"} Paste all the IPs listed below (Separated by commas)</li>
    <li>
      Permissions {">"} Enable Spot/Margin Trade, Perpetual Futures, Delivery Futures, Wallet, Subaccount, Commission
      Details and Account (Read and Write for all)
    </li>
    <li>Input the API Key and Secret Code on the Anboto Platform</li>
  </ol>
);

const kraken = (
  <ol>
    <li>
      <Link href="https://www.kraken.com/en-us/sign-in" target="_blank">
        Log in
      </Link>{" "}
      to your Kraken Account
    </li>

    <li>
      My Profile {">"}{" "}
      <Link href="https://www.kraken.com/c/account-settings/security" target="_blank">
        Security
      </Link>{" "}
      {">"} API Keys {">"} Create API Key {">"} Go to Kraken Pro
    </li>
    <li>
      <Link href="https://pro.kraken.com/app/settings/api" target="_blank">
        Create API Key
      </Link>{" "}
      {">"} Permissions:
      <ul>
        <li>Funds {">"} Query</li>
        <li>Orders & Trades {">"} Select All</li>
        <li>Data {">"} Query Ledger Entries</li>
      </ul>
    </li>
    <li>Enable IP Address Restriction {">"} Paste all the IPs listed below (Separated by commas)</li>
    <li>Input API Key and Private Key on the Anboto Platform</li>
  </ol>
);

const kucoin = (
  <ol>
    <li>
      <Link href="https://www.kucoin.com/ucenter/signin" target="_blank">
        Log in
      </Link>{" "}
      to your Kucoin Account
    </li>
    <li>
      <Link href="https://www.kucoin.com/account/api" target="_blank">
        API Management
      </Link>{" "}
      {">"} Create API
    </li>
    <li>
      API-Based Trading {">"} API Permissions {">"} Enable Spot, Margin and Futures Trading
    </li>
    <li>
      IP Restriction {">"} Yes {">"} Paste all the IPs listed below (Separated by commas)
    </li>
    <li>Input the API Key and Secret Code on the Anboto Platform</li>
  </ol>
);

const okx = (
  <ol>
    <li>
      <Link href="https://www.okx.com/account/login" target="_blank">
        Log in
      </Link>{" "}
      to your OKX Account
    </li>
    <li>
      My Profile {">"}{" "}
      <Link href="www.okx.com/account/my-api" target="_blank">
        API Keys
      </Link>{" "}
      {">"} Create V5 API Key
    </li>
    <li>IP Adress {">"} Paste all the IPs listed below (Separated by Commas)</li>
    <li>Permissions {">"} Enable Read and Trade</li>
    <li>Input API Key and Secret Key on the Anboto Platform</li>
  </ol>
);

const huobi = (
  <ol>
    <li>
      <Link href="https://www.huobi.com/en-us/login/" target="_blank">
        Log in
      </Link>{" "}
      to your Huobi Account
    </li>
    <li>
      My Profile {">"}{" "}
      <Link href="https://www.huobi.com/en-us/apikey/" target="_blank">
        API Management
      </Link>
    </li>
    <li>Permissions {">"} Enable Read-only and Trade</li>
    <li>Link IP address {">"} Paste all the IPs listed below (Separated by Commas)</li>
    <li>Input the Access Key and Secret Key on the Anboto Platform</li>
  </ol>
);
const mexc = (
  <ol>
    <li>
      <Link href="https://www.mexc.com/login?previous=%2F" target="_blank">
        Log in
      </Link>{" "}
      to your MEXC Account
    </li>
    <li>
      My Profile {">"}{" "}
      <Link href="https://www.mexc.com/user/openapi" target="_blank">
        API Management
      </Link>
    </li>
    <li>Spot Permissions {">"} Enable Account, Trade and Transfer Enable Read-only and Trade</li>
    <li>Future permissions {">"} Enable Account and Trade</li>
    <li>Link IP address {">"} Paste all the IPs listed below (Separated by Commas)</li>
    <li>Input the Access Key and Secret Key on the Anboto Platform</li>
  </ol>
);

const bitget = (
  <ol>
    <li>Log in to your Bitget Account</li>
    <li>
      My Profile {">"} <Link href="https://www.bitget.com/account/newapi">API Keys</Link> {">"} System-Generated
    </li>
    <li>Permissions {">"} Read-Write</li>
    <li>Detailed Permissions {">"} Enable Futures, Spot, Margin, Wallet and Sub-accounts</li>
    <li>Bind IP Addresses {">"} Paste all the IPs listed below (Separated by Commas)</li>
    <li>Input the Access Key, Secret Key and Passphrase on the Anboto Platform</li>
  </ol>
);

export const description = {
  [CefiExchangeId.BINANCE]: {
    video: "https://www.youtube.com/embed/zbaefbwoDik?si=KDrihyyhF7ZoKb1s",
    content: binance,
  },
  [CefiExchangeId.WOOX]: {
    video: "https://www.youtube.com/embed/Wq3UNZyWUj0?si=D6hfN8t4hUrM2Q6e",
    content: woox,
  },
  [CefiExchangeId.BYBIT]: {
    video: "https://www.youtube.com/embed/NOF7B-DUQ_A?si=bZHnxJ378yfSP8DY",
    content: bybit,
  },
  [CefiExchangeId.BITGET]: {
    video: "https://www.youtube.com/embed/0Zc1p85l-U8",
    content: bitget,
  },
  [CefiExchangeId.GATEIO]: {
    video: "https://www.youtube.com/embed/g0m7H6yET0I?si=OyIEbFrCfZ9R3TM2",
    content: gateio,
  },
  [CefiExchangeId.KRAKEN]: {
    video: "https://www.youtube.com/embed/CQ9m7zTnJ0w?si=wI8zAIaCwFpqKUT_",
    content: kraken,
  },
  [CefiExchangeId.KUCOIN]: {
    video: "https://www.youtube.com/embed/cd_YeXPaFRA?si=6cR9sI7KRLcehjJQ",
    content: kucoin,
  },
  [CefiExchangeId.OKX]: {
    video: "https://www.youtube.com/embed/nkhephm4f_c?si=iI1dcGH81So87ePM",
    content: okx,
  },
  [CefiExchangeId.HUOBI]: {
    video: "https://www.youtube.com/embed/TYTJv9-090g?si=mIIxqYOIVvmdhrIP",
    content: huobi,
  },
  [CefiExchangeId.MEXC]: {
    video: "https://www.youtube.com/embed/VFlMWQzdL0A?si=XEhKO8QRR4_EWNN6",
    content: mexc,
  },
};
