import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { AllocationMenuItem } from "./allocation-menu-item";
import { RootState } from "@src/store/types";
import { AllocationAccount, setActiveCefiAllocationItem } from "@src/store/slices/portfolioSlice";
import { isMainAccount } from "@src/pages/settings/helpers";

type AllocationMenuProps = {
  allocationItems: AllocationAccount[];
};

export const AllocationMenuCefiItems = ({ allocationItems }: AllocationMenuProps) => {
  const dispatch = useDispatch();
  const selectedAllocationItemName = useSelector((state: RootState) => state.portfolio.activeCefiAllocationItem);

  const selectAllocationItem = (name: string) => {
    dispatch(setActiveCefiAllocationItem(name));
  };

  useEffect(() => {
    const targetAllocationItem = allocationItems?.find((item) => item.subaccount === selectedAllocationItemName);

    if (!targetAllocationItem && allocationItems?.length) {
      selectAllocationItem(allocationItems[0].subaccount);
    }
  }, [allocationItems, selectedAllocationItemName]);

  return allocationItems?.length ? (
    <Stack>
      <Typography mb={2} fontSize={18} lineHeight="30px" variant="h6">
        Allocation
      </Typography>
      <Stack width={222} display="flex" flexDirection="column">
        <Stack display="flex" flexDirection="column" gap={1}>
          {[...allocationItems]
            .sort((x) => (isMainAccount(x.subaccount) ? -1 : 1))
            .map((item) => (
              <AllocationMenuItem
                setActive={selectAllocationItem}
                isActive={selectedAllocationItemName === item.subaccount}
                key={item.subaccount}
                item={item}
              />
            ))}
        </Stack>
      </Stack>
    </Stack>
  ) : null;
};
