import { PeriodHashTimeFn } from "@src/store/apis/coingeckoApi/types";

const getAvg = (data1: number, data2: number) => (data1 + data2) / 2;

export const byHashedTime =
  (hastTimeFn: PeriodHashTimeFn) =>
  (result, [time, data]) => {
    const timeHash = hastTimeFn(time);
    const timeHashData = result[timeHash];
    return { ...result, [timeHash]: timeHashData ? getAvg(timeHashData, data) : data };
  };

export const handleError = (x) => {
  return x?.error ? { market_caps: [], prices: [], total_volumes: [] } : x.data;
};
