import { readContract, writeContract } from "@wagmi/core";
import { WriteContractReturnType } from "viem";
import { queryFnFactory } from "../utils";
import { blockchainApi } from ".";
import feeManagerABI from "@src/assets/ABIs/feeManager.json";
import { FeeManagerContract } from "@src/pages/settings/fee-manager/api/types";
import { EVMBasedAddress } from "@src/pages/defi/types/common";
import { wagmiConfig } from "@src/pages/defi/wagmi-config";

export type ReadBalance = {
  balance: string;
  claimableWithdrawals: string;
  collectableBalance: string;
  requestableBalance: string;
  unclaimableWithdrawals: string;
};

export enum WithdrawError {
  UnauthorizedWallet = "User must be allowed to withdraw",
}

export const feeManagerContractApi = blockchainApi.injectEndpoints({
  endpoints: (builder) => ({
    teams: builder.query<(string | number)[], { contract: FeeManagerContract; team_uuid: string }>({
      queryFn: queryFnFactory<{ contract: FeeManagerContract; team_uuid: string }, (string | number)[]>(
        ({ contract, team_uuid }) =>
          readContract(wagmiConfig, {
            address: contract.contract_address as EVMBasedAddress,
            abi: feeManagerABI,
            functionName: "teams",
            args: [team_uuid],
            chainId: contract.network_id,
          }) as Promise<(string | number)[]>
      ),
    }),
    getBalance: builder.query<ReadBalance, { contract: FeeManagerContract; team_uuid: string; tokenAddress: string }>({
      queryFn: queryFnFactory<{ contract: FeeManagerContract; team_uuid: string; tokenAddress: string }, ReadBalance>(
        async ({ contract, team_uuid, tokenAddress }) => {
          try {
            const balances = (await readContract(wagmiConfig, {
              address: contract.contract_address as EVMBasedAddress,
              abi: feeManagerABI,
              functionName: "getBalance",
              args: [team_uuid, tokenAddress],
              chainId: contract.network_id,
            })) as Promise<ReadBalance>;

            const balancesNumber = Object.fromEntries(
              Object.entries(balances).map(([key, value]) => [key, value.toString()])
            );

            return balancesNumber as ReadBalance;
          } catch (error) {
            console.log("getBalance error", error);

            return {
              balance: "0",
              claimableWithdrawals: "0",
              collectableBalance: "0",
              requestableBalance: "0",
              unclaimableWithdrawals: "0",
            } as ReadBalance;
          }
        }
      ),
    }),
    createTeam: builder.mutation<
      WriteContractReturnType | null,
      { contract: FeeManagerContract; team_uuid: string; walletAdmin: string }
    >({
      queryFn: queryFnFactory<
        { contract: FeeManagerContract; team_uuid: string; walletAdmin: string },
        WriteContractReturnType | null
      >(async ({ contract, team_uuid, walletAdmin }) =>
        writeContract(wagmiConfig, {
          address: contract.contract_address as EVMBasedAddress,
          abi: feeManagerABI,
          functionName: "createTeam",
          args: [team_uuid, walletAdmin, [walletAdmin]],
        }).catch((error) => {
          console.log("createTeam error", error);
          return null;
        })
      ),
    }),
    depositFee: builder.mutation<
      WriteContractReturnType | null,
      { contract: FeeManagerContract; team_uuid: string; amount: string; tokenAddress: string }
    >({
      queryFn: queryFnFactory<
        { contract: FeeManagerContract; team_uuid: string; amount: string; tokenAddress: string },
        EVMBasedAddress | null
      >(async ({ contract, team_uuid, amount, tokenAddress }) =>
        writeContract(wagmiConfig, {
          address: contract.contract_address as EVMBasedAddress,
          abi: feeManagerABI,
          functionName: "depositFee",
          args: [team_uuid, amount, tokenAddress],
        }).catch((error) => {
          console.log("depositFee error", error);
          return null;
        })
      ),
    }),
    requestFeeWithdrawal: builder.mutation<
      WriteContractReturnType | null | WithdrawError,
      { contract: FeeManagerContract; team_uuid: string; amount: string; tokenAddress: string }
    >({
      queryFn: queryFnFactory<
        { contract: FeeManagerContract; team_uuid: string; amount: string; tokenAddress: string },
        EVMBasedAddress | null | WithdrawError
      >(async ({ contract, team_uuid, amount, tokenAddress }) =>
        writeContract(wagmiConfig, {
          address: contract.contract_address as EVMBasedAddress,
          abi: feeManagerABI,
          functionName: "requestFeeWithdrawal",
          args: [team_uuid, amount, tokenAddress],
        }).catch((error) => {
          console.log("requestFeeWithdrawal error", error);
          if (JSON.stringify(error).includes(WithdrawError.UnauthorizedWallet)) {
            return WithdrawError.UnauthorizedWallet;
          }
          return null;
        })
      ),
    }),
    claimFeeWithdrawal: builder.mutation<
      WriteContractReturnType | null | WithdrawError,
      { contract: FeeManagerContract; team_uuid: string; amount: string; tokenAddress: string }
    >({
      queryFn: queryFnFactory<
        { contract: FeeManagerContract; team_uuid: string; amount: string; tokenAddress: string },
        WriteContractReturnType | null | WithdrawError
      >(async ({ contract, team_uuid, amount, tokenAddress }) =>
        writeContract(wagmiConfig, {
          address: contract.contract_address as EVMBasedAddress,
          abi: feeManagerABI,
          functionName: "claimFeeWithdrawal",
          args: [team_uuid, amount, tokenAddress],
        }).catch((error) => {
          console.log("claimFeeWithdrawal error", error);
          if (JSON.stringify(error).includes(WithdrawError.UnauthorizedWallet)) {
            return WithdrawError.UnauthorizedWallet;
          }
          return null;
        })
      ),
    }),
  }),
});
