import React from "react";
import { Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { DateTime } from "luxon";
import { feeManagerApi } from "../api";
import { fileDownload } from "@src/utils/file";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { useAppSelector } from "@src/store/hooks";

export const ExportTransactionsButton = () => {
  const snackbar = useSnackbar();
  const { team_uuid } = useAppSelector((state) => state.user);
  const [exportAccounting, exportAccountingMutation] = feeManagerApi.useExportAccountingCSVMutation();

  const handleExport = async () => {
    try {
      const data = await exportAccounting({ team_uuid: team_uuid }).unwrap();
      fileDownload(data, `Transactions_credits_manager_${DateTime.now().toFormat("MMM_d_yyyy_HH:mm")}.csv`);
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data?.error), { variant: "error" });
    }
  };

  return (
    <AnbotoButton
      size="small"
      variant="outlined"
      color="secondary"
      endIcon={<Logout htmlColor="text.secondary" />}
      loadingPosition="end"
      loading={exportAccountingMutation.isLoading}
      onClick={handleExport}
      sx={{ py: 0.5 }}
    >
      <Typography variant="caption" fontSize={13}>
        Export transactions record
      </Typography>
    </AnbotoButton>
  );
};
