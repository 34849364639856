import { useEffect, useState } from "react";

export const useSidebarStick = (
  containerRef: React.RefObject<HTMLDivElement>,
  sidebarRef: React.RefObject<HTMLDivElement>
) => {
  const [isStickBottom, setIsStickBottom] = useState<boolean>(false);
  useEffect(() => {
    const getStickType = () => {
      if (containerRef.current) {
        const { innerHeight } = window;
        const sidebarAvailibleHeight = innerHeight - containerRef.current["offsetTop"];
        const sidebarHeight = sidebarRef.current ? sidebarRef.current["clientHeight"] : 0;
        return sidebarHeight > sidebarAvailibleHeight;
      }
      return false;
    };

    const handleResize = () => {
      setIsStickBottom(getStickType());
    };

    if (containerRef.current) {
      setIsStickBottom(getStickType());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isStickBottom;
};
