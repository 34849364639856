import React from "react";
import { DialogProps, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { SettingsDialog } from "../settings-dialog";
import { AccountForm, AccountFormRef } from "./account-form";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { UserAccountsResult } from "@src/store/apis/anbotoApi/types";
import { RootState } from "@src/store/types";

type AccountSettingsDialogProps = DialogProps & {
  onClose(onAfterSubmit?: boolean): void;
  account?: UserAccountsResult;
};

export const AccountSettingsDialog = ({ onClose, account, ...props }: AccountSettingsDialogProps) => {
  const { user_uuid } = useSelector((state: RootState) => state.user);

  const formRef = React.useRef<AccountFormRef>(null);

  const isAdmin = user_uuid === account?.admin_uuid;
  const newAccount = !account?.uuid;

  return (
    <SettingsDialog
      title="Account details"
      onClose={onClose}
      {...props}
      actions={
        <Stack direction="row" justifyContent="space-between" width="100%">
          <AnbotoButton variant="outlined" color="secondary" size="small" onClick={() => onClose()}>
            Cancel
          </AnbotoButton>
          {(isAdmin || newAccount) && (
            <AnbotoButton
              sx={{ minWidth: 160 }}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => formRef?.current?.submit()}
              loading={formRef?.current?.loading}
            >
              Save
            </AnbotoButton>
          )}
        </Stack>
      }
    >
      <Stack sx={{ height: "100%" }} alignItems="center">
        <AccountForm showMembersList account={account} onAfterSubmit={() => onClose(true)} ref={formRef} />
      </Stack>
    </SettingsDialog>
  );
};
