import React from "react";
import { styled } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

const StyledButton = styled(IconButton)(({ theme }) => ({
  background: theme.custom.background.secondary,
  borderRadius: 4,
}));

export const WidgetIconButton = (props: IconButtonProps) => <StyledButton {...props} disableRipple />;
