import React, { forwardRef } from "react";
import { FormControlLabel } from "@mui/material";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoSwitch } from "@src/components/ui/anboto-switch";

export const RandomizeField = forwardRef(({ label, ...props }: ControllerRenderProps & { label: string }, ref) => {
  return (
    <FormControlLabel
      control={<AnbotoSwitch {...props} checked={props.value} inputRef={ref} />}
      label={label}
      labelPlacement="start"
      sx={{ ml: 0, mr: 2 }}
    />
  );
});
RandomizeField.displayName = "RandomizeField";
