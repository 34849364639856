import { useMemo } from "react";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export interface UseWalletsResult {
  [key: string]: string;
}

export const useWallets = (isLowerCase?: boolean): UseWalletsResult => {
  const getWalletsQuery = anbotoApi.useGetWalletsQuery(null);

  const wallets =
    useMemo(
      () =>
        getWalletsQuery.data?.results?.reduce(
          (res, wallet) => ({
            ...res,
            [isLowerCase ? wallet.address.toLowerCase() : wallet.address]: wallet.name,
          }),
          {}
        ),
      [getWalletsQuery.isFetching]
    ) || {};

  return wallets;
};
