import React, { useEffect } from "react";
import { Checkbox, Stack, Typography } from "@mui/material";
import { useActiveGroups } from "@src/features/widgets-layout/hooks/use-active-groups";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { setOtherPairsHidden } from "@src/pages/cefi/orders-table/orders-table.slice";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";

export const OtherPairsHidden = () => {
  const activeGroups = useActiveGroups();
  const otherPairsHidden = useAppSelector((state) => state.ordersTable.otherPairsHidden.value);
  const dispatch = useAppDispatch();
  const symbols = Object.values(activeGroups).map((group) => getLivePriceId(group.symbol));

  const updatePairsHiddenState = (toggle?: boolean) => {
    dispatch(
      setOtherPairsHidden({
        value: toggle ? !otherPairsHidden : otherPairsHidden,
        symbols,
      })
    );
  };

  const handleClick = () => updatePairsHiddenState(true);

  useEffect(() => {
    updatePairsHiddenState();
  }, [symbols.join()]);

  return (
    <Stack onClick={handleClick} direction="row" alignItems="center" overflow="hidden" sx={{ cursor: "pointer" }}>
      <Checkbox size="small" checked={otherPairsHidden} />
      <Typography variant="caption" noWrap>
        Hide other pairs
      </Typography>
    </Stack>
  );
};
