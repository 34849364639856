import { IWalletConnectProviderOptions } from "@walletconnect/types";
import { CHAIN_CONFIG } from "@src/pages/defi/constants";
import { ChainId } from "@src/pages/defi/types";

type Rpc = Record<ChainId, string>;

const rpc: Rpc = {
  [ChainId.ETHEREUM]: CHAIN_CONFIG[ChainId.ETHEREUM].rpcUrls[0],
  [ChainId.BSC]: CHAIN_CONFIG[ChainId.BSC].rpcUrls[0],
  [ChainId.POLYGON]: CHAIN_CONFIG[ChainId.POLYGON].rpcUrls[0],
  [ChainId.AVALANCHE]: CHAIN_CONFIG[ChainId.AVALANCHE].rpcUrls[0],
  [ChainId.ARBITRUM]: CHAIN_CONFIG[ChainId.ARBITRUM].rpcUrls[0],
  [ChainId.OPTIMISM]: CHAIN_CONFIG[ChainId.OPTIMISM].rpcUrls[0],
  [ChainId.BASE]: CHAIN_CONFIG[ChainId.BASE].rpcUrls[0],
};

export const WALLET_CONNECT_PROVIDER_OPTIONS: IWalletConnectProviderOptions = {
  pollingInterval: 20000, // 20 secs
  rpc,
  qrcodeModalOptions: {
    // desktopLinks: [], // no desktop for now
    // mobileLinks: ['trust'],
  },
  clientMeta: {
    name: "Anboto",
    description: "Anboto description",
    url: "https://anboto.xyz",
    icons: [require("@src/assets/images/logo.png").default],
  },
};

export const ORDERS_LIMIT = 400;
