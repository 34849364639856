import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDefiTokenTable } from "../../hooks/use-defi-token-table";
import PortfolioSidebar from "./portfolio-sidebar";
import LinkWalletButton from "./components/link-wallet-button";
import { useAppDispatch } from "@src/store/hooks";
import { RootState } from "@src/store/types";
import { PortfolioType, SELECT_ALL, setSelectedWallet } from "@src/store/slices/portfolioSlice";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useSelectedWallets } from "@src/pages/portfolio";

export const PortfolioSidebarDEFI = () => {
  const { user_uuid } = useSelector((state: RootState) => state.user);
  const [updateWallets] = anbotoApi.useUpdateWalletsMutation();
  const overviewTotalBalance = useSelector((state: RootState) => state.portfolio.overviewDefiTotalBalance);
  const { isError, isFetchingWallets, isLoading, refetch, walletsData, portfolioList } = useSelectedWallets();

  const dispatch = useAppDispatch();
  const selectedWallet = useSelector((state: RootState) => state.portfolio.selectedWallet);
  const { isFetching } = useDefiTokenTable();

  const setSelectedWalletToStore = (selectedWallet: string) => {
    dispatch(setSelectedWallet(selectedWallet));
  };

  const updateAddedWalletsList = (wallet: string) => {
    const wallets =
      walletsData?.map((walletItem) => ({
        ...walletItem,
        is_show_in_portfolio:
          walletItem.name === wallet ? !walletItem.is_show_in_portfolio : walletItem.is_show_in_portfolio,
      })) || [];

    updateWallets({ userId: user_uuid, wallets });
    setSelectedWalletToStore(SELECT_ALL);
  };

  useEffect(() => {
    setSelectedWalletToStore(SELECT_ALL);
  }, []);

  const tableOrWalletsLoading = isFetching || isFetchingWallets;
  const recalculating = selectedWallet === SELECT_ALL && tableOrWalletsLoading;

  return (
    <PortfolioSidebar
      portfolioList={portfolioList}
      linkButton={<LinkWalletButton />}
      portfolioType={PortfolioType.DEFI}
      selectedItem={selectedWallet}
      onSelect={setSelectedWalletToStore}
      isError={isError}
      isLoading={isLoading}
      isFetching={recalculating}
      refetch={refetch}
      updateAddedItemList={updateAddedWalletsList}
      overviewTotalBalance={overviewTotalBalance}
    />
  );
};
