import React from "react";
import { Stack, Typography, Box, styled } from "@mui/material";

export type SettingsHeaderProps = {
  title: React.ReactNode;
  titlePostfix?: React.ReactNode;
  description?: React.ReactNode;
  postfix?: React.ReactNode;
};

export const SettingsHeader = ({ title, titlePostfix, description, postfix }: SettingsHeaderProps) => {
  return (
    <Stack gap={1} justifyContent="space-between" direction="row">
      <Box gap={2}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            {title}
          </Typography>
          {titlePostfix}
        </Stack>
        {description && <Description variant="body1">{description}</Description>}
      </Box>
      <Box>{postfix}</Box>
    </Stack>
  );
};

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
