import React from "react";
import { Stack, StackProps, Typography } from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useSnackbar } from "notistack";
import { SettingsDialog } from "@src/pages/settings/settings-dialog";
import OtpForm from "@src/pages/settings/two-fa/otp-form";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { useDialog } from "@src/hooks/useDialog";
import { useAuth } from "@src/features/auth/hooks/use-auth";

type DisableTwoFaProps = {
  disableTurnOff?: boolean;
  headerSx?: StackProps["sx"];
  containerSX?: StackProps["sx"];
};
const DisableTwoFa = ({ disableTurnOff, headerSx, containerSX }: DisableTwoFaProps) => {
  const snackbar = useSnackbar();
  const disableAuthDialog = useDialog();
  const auth = useAuth();
  const [loading, setLoading] = React.useState(false);

  const disable2fa = async () => {
    try {
      setLoading(true);
      await auth.updateMFAPreference({
        totp: "DISABLED",
      });

      await auth.refetchSession();

      snackbar.enqueueSnackbar("Authentication code is disabled", { variant: "success" });
    } catch (e) {
      snackbar.enqueueSnackbar("Failed to disable 2FA", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {disableAuthDialog.isOpen ? (
        <SettingsDialog title="Turn off authenication" open={disableAuthDialog.isOpen} onClose={disableAuthDialog.hide}>
          <Stack height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Stack border="1px solid #4A5053" padding={4} width={500} textAlign="center">
              <Typography color="#8A9296" marginBottom={2} fontSize={14} fontWeight="400">
                Please enter the 6-digit of 2FA code to turn off authenication
              </Typography>
              <OtpForm onSubmit={disable2fa} />
            </Stack>
          </Stack>
        </SettingsDialog>
      ) : (
        <Stack className="two-fa" display="flex" alignItems="center" height="100%" justifyContent="center">
          <Stack width={960} gap={2} marginBottom={7}>
            <Stack sx={headerSx} width="100%" display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="h6">Two-factor authenication</Typography>
            </Stack>
            <Stack
              sx={containerSX}
              display="flex"
              flexDirection="column"
              width="100%"
              height={480}
              border="1px solid #4A5053"
              justifyContent="center"
              alignItems="center"
            >
              <Stack marginBottom={2} position="relative">
                <SecurityIcon sx={{ fontSize: 105, color: "#636B6F" }} />
                <CheckCircleOutlineOutlinedIcon
                  sx={{ fontSize: 45, color: "#179B83", position: "absolute", top: -5, left: 70 }}
                />
              </Stack>
              <Typography fontSize={16} fontWeight={600}>
                Enabled 2FA
              </Typography>
              <Typography textAlign="center" color="#8A9296" fontSize={14} fontWeight={400}>
                You have successfully enabled
                <br />
                two-factor authentication
              </Typography>
              <AnbotoButton
                onClick={disable2fa}
                // onClick={disableAuthDialog.show}
                sx={{ marginTop: 2, display: disableTurnOff ? "none" : "block" }}
                variant="outlined"
                size="small"
                loading={loading}
              >
                Turn off
              </AnbotoButton>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default DisableTwoFa;
