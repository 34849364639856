import React from "react";
import { Stack } from "@mui/material";
import { ParentOrderFormFieldValues } from "@src/pages/cefi/order-form/hooks/use-parent-order-form";
import PriceImpactProvider from "@src/components/price-impact/components/price-impact-provider";
import PriceImpact from "@src/components/price-impact/components/price-impact";

export interface PriceImpactWrapperProps {
  symbol: ParentOrderFormFieldValues["symbol"];
  quantity: ParentOrderFormFieldValues["quantity"];
  clipSizeVal?: ParentOrderFormFieldValues["clipSizeValue"];
  clipSizeType: ParentOrderFormFieldValues["clipSizeType"];
  tradingDuration: ParentOrderFormFieldValues["tradingDuration"];
  tradingDurationUnit?: ParentOrderFormFieldValues["tradingDurationUnit"];
  strategy?: ParentOrderFormFieldValues["strategy"];
  side?: ParentOrderFormFieldValues["side"];
}

export const PriceImpactWrapper = ({
  symbol,
  quantity,
  clipSizeVal,
  clipSizeType,
  tradingDuration,
  tradingDurationUnit,
  strategy,
  side,
}: PriceImpactWrapperProps) => {
  return (
    <Stack direction="column" px={2} pr={0} justifyContent="space-between" flex={1} className="price-impact-container">
      {symbol && (
        <PriceImpactProvider symbol={symbol} quantity={quantity} side={side}>
          <PriceImpact
            symbol={symbol}
            quantity={quantity}
            clipSizeType={clipSizeType}
            clipSizeVal={clipSizeVal}
            tradingDuration={tradingDuration}
            tradingDurationUnit={tradingDurationUnit}
            side={side}
            strategy={strategy}
          />
        </PriceImpactProvider>
      )}
    </Stack>
  );
};
