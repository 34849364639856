import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

export const ReduceOnlyCheckbox = ({
  disabled,
  value,
  onChange,
}: {
  value?: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}) => (
  <FormControlLabel
    sx={{
      "& .MuiFormControlLabel-label": { fontSize: 12 },
      "& .MuiSvgIcon-root": { fontSize: 18 },
      marginLeft: 0,
    }}
    control={<Checkbox size="small" sx={{ padding: 0, marginRight: 1, marginLeft: 0.5 }} />}
    label="Reduce only"
    checked={!!value}
    onChange={(e, checked) => onChange(checked)}
    disabled={disabled}
  />
);
