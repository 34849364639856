import { PARENT_ORDER_STATUS_LABEL } from "../constants";
import { OrderExecutionStrategy, ParentOrderErrorCode, ParentOrderStatuses } from "@src/store/apis/anbotoApi/types";

export const formatErrorCode = (code: ParentOrderErrorCode) => (code || "").split("_").join(" ");

export const getParentOrderStatus = (
  status?: ParentOrderStatuses,
  quantity = 0,
  filled_quantity = 0,
  error_code?: ParentOrderErrorCode | null
): string => {
  const insuffFunds = error_code === ParentOrderErrorCode.INSUFFICIENT_FUNDS;
  const _status = status || "";

  return insuffFunds
    ? PARENT_ORDER_STATUS_LABEL[ParentOrderStatuses.INSUFFICIENT_FUNDS]
    : _status === ParentOrderStatuses.FILLED
    ? PARENT_ORDER_STATUS_LABEL[_status]
    : filled_quantity / quantity > 0.9
    ? "Partly filled"
    : error_code === ParentOrderErrorCode.EXPIRY_REACHED
    ? "Time limit reached"
    : error_code
    ? formatErrorCode(error_code)
    : _status
    ? PARENT_ORDER_STATUS_LABEL[_status]
    : "";
};

export const isCancelable = (status: ParentOrderStatuses) =>
  ![ParentOrderStatuses.CANCELLED, ParentOrderStatuses.FILLED, ParentOrderStatuses.PENDING_CANCEL].includes(status);

export const formatStrategyLabel = (strategy = "") => {
  if (strategy.toLowerCase() === "order") return "Market";
  if (strategy.toLowerCase() === OrderExecutionStrategy.IS) return "IS";

  return strategy;
};
