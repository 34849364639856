import React, { FC } from "react";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { Tooltip } from "@mui/material";

export interface TokenTrustedProps {
  trusted?: boolean;
}

export const TokenTrusted: FC<TokenTrustedProps> = ({ trusted }) => {
  if (trusted) {
    return null;
  }

  return (
    <Tooltip
      title="Non trusted token. Make sure this is the token you want to trade"
      sx={{ fontSize: 16 }}
      placement="right-start"
    >
      <WarningAmber sx={{ color: (theme) => theme.palette.warning.main, width: 18 }} />
    </Tooltip>
  );
};
