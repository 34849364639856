import React from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

type AccountSettingsContainerHeaderProps = {
  max_number_teams: number;
  joinedAccountsNumber: number;
  ownedAccountsNumber: number;
};

const AccountSettingsContainerHeader = ({
  max_number_teams,
  joinedAccountsNumber,
  ownedAccountsNumber,
}: AccountSettingsContainerHeaderProps) => {
  const isAccounts = !!(joinedAccountsNumber + ownedAccountsNumber);
  const accountsLeft = max_number_teams - joinedAccountsNumber - ownedAccountsNumber;
  return (
    <Stack marginBottom={2} flexDirection="row" justifyContent="space-between" color="#8A9296">
      <Stack width="75%" flexDirection="column" gap={1}>
        <Typography fontSize={16} fontWeight={400}>
          You can invite other members to join your team (if you are admin)
          <br />
          If you want to use different sets of API keys (for other purpose like using a personal account for example),
          please request an additional account
        </Typography>
        <Stack alignItems="center" flexDirection="row" gap={0.5}>
          <Tooltip title="You can delegate work by inviting users to your team" placement="top">
            <Typography sx={{ textDecoration: "underline", cursor: "pointer" }} fontSize={12}>
              Why need to create team
            </Typography>
          </Tooltip>
          <Tooltip title="You can delegate work by inviting users to your team" placement="top">
            <HelpIcon sx={{ cursor: "pointer", fontSize: 14 }} />
          </Tooltip>
        </Stack>
      </Stack>
      {isAccounts && (
        <Stack textAlign="right" display="flex" flexDirection="column">
          <Typography>({accountsLeft >= 0 ? accountsLeft : 0} account left)</Typography>
          <Stack flexDirection="row" gap={2}>
            <Typography>
              Joined:{" "}
              <Typography variant="caption" color="white" fontSize={16}>
                {joinedAccountsNumber}
              </Typography>
            </Typography>
            <Typography>|</Typography>
            <Typography>
              Owned:{" "}
              <Typography variant="caption" color="white" fontSize={16}>
                {ownedAccountsNumber}
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default AccountSettingsContainerHeader;
