import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import _debounce from "lodash/debounce";
import SearchIcon from "@mui/icons-material/Search";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { geckoTerminalApi } from "@src/store/apis/geckoterminal-api";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import { formatAmount, isStableCoin } from "@src/pages/portfolio/utils/helpers";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { setTokenInfoPool } from "@src/store/slices/decentralizedExchangePageSlice";
import { constructTokenAddress } from "@src/pages/defi/utils";
import { ChainId, EVMBasedAddress } from "@src/pages/defi/types";
import { withFlatStyle } from "@src/components/with-flat-style";
import { usePoolInfo } from "@src/pages/defi/hooks/use-pool-info";
import { getChainIdFromGeckoTerminalPoolId } from "@src/pages/defi/token-info/helpers";

const PoolSearch = () => {
  const orderForm = useDefiOrderFormContext();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { formPool } = usePoolInfo();

  const chainId = orderForm.getValues("chainId");
  const fromTokenAddress = orderForm.getValues("fromTokenAddress");
  const toTokenAddress = orderForm.getValues("toTokenAddress");

  const tokensMap = useAppSelector((state) => state.tokens.tokensMap);

  const [searchPool, { data, isFetching, isError }] = geckoTerminalApi.useLazySearchPoolQuery();

  const [searchValue, setSearchValue] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !(ref.current as any).contains(event.target)) {
        setShowSearchResult(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const refetchSearchPool = async (query) => {
    await searchPool({ query });
  };

  const debouncedSearchPool = useCallback(
    _debounce((searchValue) => refetchSearchPool(searchValue), 1000),
    []
  );

  const onResultItemClick = (pool) => {
    if (pool === "") {
      setSearchValue("");
      setShowSearchResult(false);
      dispatch(setTokenInfoPool(formPool));
    } else if (pool) {
      setSearchValue(pool?.attributes?.name || "");

      const quoteTokenId = pool?.relationships?.quote_token?.data?.id;
      const splitQuoteTokenId = quoteTokenId.split("_");
      const quoteTokenAddress = splitQuoteTokenId.length > 1 ? splitQuoteTokenId[1] : "";

      const baseTokenId = pool?.relationships?.base_token?.data?.id;
      const splitBaseTokenId = baseTokenId.split("_");
      const baseTokenAddress = splitBaseTokenId.length > 1 ? splitBaseTokenId[1] : "";
      const baseToken = tokensMap[constructTokenAddress(chainId, baseTokenAddress as EVMBasedAddress)]?.token;

      const currentPool = {
        ...pool,
        isNeedToRevert: isStableCoin(baseToken?.symbol),
        baseTokenAddress,
        quoteTokenAddress,
      };

      dispatch(setTokenInfoPool(currentPool));
    }
    setShowSearchResult(false);
  };

  const chainIdsList = useMemo(() => Object.values(ChainId).filter((chainId) => typeof chainId !== "string"), []);
  const poolsList = data?.data?.filter((pool) =>
    chainIdsList.includes(Number(getChainIdFromGeckoTerminalPoolId(pool?.id)) || 0)
  );

  const onSearchValueChange = (searchQuery) => {
    setSearchValue(searchQuery);
    setShowSearchResult(true);
    debouncedSearchPool(searchQuery);
  };

  useEffect(() => {
    setSearchValue("");
  }, [fromTokenAddress, toTokenAddress]);

  return (
    <Stack width="100%">
      <SearchInput
        fullWidth
        value={searchValue}
        onChange={(e) => onSearchValueChange(e.target.value)}
        size="small"
        placeholder="Pool address, token address or symbol"
        onFocus={() => setShowSearchResult(true)}
        sx={{ border: "none" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "#626b70", fontSize: 16 }} />
            </InputAdornment>
          ),
          endAdornment: searchValue.length ? (
            <InputAdornment position="end">
              {isFetching ? (
                <CircularProgress sx={{ color: "#626b70" }} size={16} />
              ) : (
                <IconButton onClick={() => onResultItemClick("")}>
                  <Close sx={{ color: "#626b70", fontSize: 16 }} />
                </IconButton>
              )}
            </InputAdornment>
          ) : null,
        }}
      />
      {showSearchResult && !isError && !!searchValue?.length && !!poolsList?.length && (
        <Stack
          ref={ref}
          position="absolute"
          right={35}
          top={32}
          zIndex={8}
          minWidth={400}
          sx={{
            background: theme.palette.background.default,
            border: "1px solid #3B4043",
            borderRadius: 0.5,
            boxShadow: theme.shadows[10],
            overflowY: "scroll",
          }}
          height={250}
        >
          <List dense>
            {poolsList?.map((pool) => (
              <ListItem key={pool?.attributes?.address} disablePadding onClick={() => onResultItemClick(pool)}>
                <ListItemButton>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="caption">{pool?.attributes?.name}</Typography>
                          <Typography variant="caption">{pool?.relationships?.dex?.data?.id}</Typography>
                        </Stack>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="caption" fontSize={12}>
                            Liq: {formatAmount(pool?.attributes?.reserve_in_usd)}$
                          </Typography>
                          <Typography variant="caption" fontSize={12}>
                            24h Vol: {formatAmount(pool?.attributes?.volume_usd?.h24)}$
                          </Typography>
                        </Stack>
                      </React.Fragment>
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Stack>
      )}
    </Stack>
  );
};

export default PoolSearch;

const SearchInput = withFlatStyle(AnbotoTextField);
