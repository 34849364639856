import React, { useEffect, useState } from "react";
import { IconButton, Paper, Slider, Stack, Typography, styled } from "@mui/material";
import Draggable from "react-draggable";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { closeLeverageModal } from "./leverage.slice";
import { leverageApi } from "./api";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { onlyNumbers } from "@src/utils/only-numbers";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { getExchangeIdByExchangeName } from "@src/store/apis/anbotoApi/utils";

export const MODAL_WIDTH = 330;
export const MODAL_HEIGHT = 250;

const marks = Array(11)
  .fill(1)
  .map((el, index) => {
    const amount = index ? index * 10 : 1;

    return {
      value: amount,
      label: `${amount}x`,
    };
  });

export const LeverageModal = () => {
  const dispatch = useAppDispatch();
  const [update, { isLoading }] = leverageApi.useUpdateLeverageMutation();
  const snackbar = useSnackbar();
  const { open, x, y, symbol, accountId, reset, leverage } = useAppSelector((state) => state.leverage);
  const [position, setPosition] = useState<{ x: number; y: number }>({ x, y });
  const [newValue, setNewValue] = useState<string>(`${leverage || 1}`);

  useEffect(() => {
    setPosition({ x, y });
    setNewValue(leverage.toString());
  }, [reset]);

  const close = () => {
    dispatch(closeLeverageModal());
  };

  const handleSliderChange = (e, val) => {
    setNewValue(val.toString());
  };

  const handleInputChange = (e) => {
    const num = +onlyNumbers(e.target.value, true);
    setNewValue(!num ? "" : num > 100 ? "100" : num.toString());
  };

  const submit = async () => {
    try {
      if (symbol) {
        await update({
          exchangeId: getExchangeIdByExchangeName(symbol?.exchange),
          accountId,
          symbol: symbol.symbol,
          marketType: symbol.market_type || symbol.asset_class,
          leverage: +newValue,
        }).unwrap();
        close();
      }
    } catch (error) {
      snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data?.error), { variant: "error" });
    }
  };

  return open ? (
    <Draggable
      positionOffset={{ x: -35, y: -50 }}
      position={{ x: position.x, y: position.y }}
      onStop={(_, { x, y }) => setPosition({ x, y })}
      handle=".handle"
    >
      <StyledModal elevation={8}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          py={1.5}
          sx={{ cursor: "grab" }}
          className="handle"
        >
          <Typography fontWeight="bold" variant="body2" noWrap>
            Adjust leverage
          </Typography>
          <IconButton size="small" sx={{ background: "none !important" }} onClick={close}>
            <CloseIcon sx={{ fontSize: 14, color: (theme) => theme.palette.text.primary }} />
          </IconButton>
        </Stack>
        <Stack p={2} gap={2} position="relative">
          <Stack>
            <AnbotoTextField
              inputProps={{ sx: { textAlign: "center" } }}
              value={newValue}
              onChange={handleInputChange}
            />

            {(+newValue < 1 || +newValue > 100) && (
              <Stack position="relative" alignItems="center">
                <Typography variant="caption" color="error.main" position="absolute">
                  Should be in range 1-100
                </Typography>
              </Stack>
            )}
          </Stack>

          <StyledSlider marks={marks} value={+newValue} onChange={handleSliderChange} />
        </Stack>

        <Actions>
          <Stack direction="row" justifyContent="center" gap={3} flex={1}>
            <AnbotoButton size="small" variant="outlined" sx={{ width: 120 }} onClick={close}>
              Cancel
            </AnbotoButton>
            <AnbotoButton
              disabled={+newValue < 1 || +newValue > 100}
              size="small"
              variant="contained"
              sx={{ width: 120 }}
              onClick={submit}
              loading={isLoading}
            >
              Confirm
            </AnbotoButton>
          </Stack>
        </Actions>
      </StyledModal>
    </Draggable>
  ) : null;
};

const StyledModal = styled(Paper)(({ theme }) => ({
  width: MODAL_WIDTH,
  height: MODAL_HEIGHT,
  border: `1px solid ${theme.custom.colors.primary}`,
  position: "absolute",
  zIndex: 99,
  borderRadius: theme.shape.borderRadius,
}));

const Actions = styled(Stack)(({ theme }) => ({
  position: "absolute",
  left: 0,
  bottom: 0,
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-markLabel": {
    fontSize: 11,
  },
  "& .MuiSlider-thumb": {
    height: 8,
    width: 8,
    transition: "none",
    backgroundColor: "#fafafa",
    boxShadow: "none !important",
    "&:hover": {},
  },
  "& .MuiSlider-track": {
    border: "none",
    transition: "none",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#3B4043",
  },
  "& .MuiSlider-mark": {
    opacity: 0,
  },
  "& .MuiSlider-markLabelActive": {
    color: theme.palette.text.secondary,
  },
}));
