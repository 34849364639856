import React from "react";
import { styled } from "@mui/material";
import { AMOUNT_OPACITY, amountStyles } from "../constants";

const StyledAmount = styled("span")<{ isHorizontal?: boolean }>({
  ...amountStyles,
  textAlign: "right",
  width: "35%",
  opacity: AMOUNT_OPACITY,
});

export const Amount = React.memo(StyledAmount);
