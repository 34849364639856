import React, { useState } from "react";
import { Stack, DialogContent, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Card } from "../components/card";
import { ApiKeyRow } from "./api-key-row";
import { ApiKeyModal } from "./api-key-modal";
import { ReactComponent as NoDataIcon } from "./empty.svg";
import { AnbotoModal } from "@src/components/anboto-modal";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { anbotoApiKeys } from "@src/store/apis/anbotoApi/api-keys";
import { useAppSelector } from "@src/store/hooks";
import AnbotoLoader from "@src/components/anboto-loader/anboto-loader";
import { ApiKeyEntity } from "@src/store/apis/anbotoApi/api-keys/types";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { usePermissions } from "@src/hooks/use-permissions";

export const ApiSettings = () => {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selected, setSelected] = useState<ApiKeyEntity | null>(null);
  const teamId = useAppSelector((state) => state.user.team_uuid);
  const { data, isLoading } = anbotoApiKeys.useGetApiKeysQuery({ teamId });
  const [deleteApiKey, { isLoading: deleteApiKeyLoading }] = anbotoApiKeys.useDeleteApiKeyMutation();
  const snackbar = useSnackbar();
  const permissions = usePermissions();

  const onCreateButtonClick = () => {
    setOpen(true);
  };

  const handleDeleteApiKey = async () => {
    if (!selected) return;

    try {
      const res = await deleteApiKey({ teamId, label: selected.label }).unwrap();
      console.log({ res });

      setSelected(null);
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar(parseAnbotoRequestError(error), { variant: "error" });
    }
  };

  return (
    <>
      <Card
        title="API Management"
        successButtonLabel="Create"
        dismissButtonLabel="Remove All"
        onSuccessButtonClick={onCreateButtonClick}
        justifyContent="center"
        successButtonProps={{ sx: { visibility: permissions.trade ? "visible" : "hidden" } }}
      >
        {isLoading ? (
          <AnbotoLoader />
        ) : data?.length ? (
          <Stack alignItems="center">
            {data.map((key) => (
              <ApiKeyRow
                key={key.api_key}
                label={key.label}
                apiKey={key.api_key}
                secretKey={key.api_key}
                hideSecretKey
                onDeleteClick={permissions.trade ? () => setSelected(key) : undefined}
                expanded={expanded.includes(key.api_key)}
                onToggleExpand={() =>
                  setExpanded((state) =>
                    expanded.includes(key.api_key) ? expanded.filter((k) => k !== key.api_key) : [...state, key.api_key]
                  )
                }
              />
            ))}
          </Stack>
        ) : (
          <Stack justifyContent="center" alignItems="center" gap={2} flex={1}>
            <NoDataIcon />
            <Typography variant="body2" sx={{ color: "#8A9296" }}>{`You don't have any API created yet`}</Typography>
            {permissions.trade && (
              <AnbotoButton size="small" variant="outlined" onClick={onCreateButtonClick}>
                Create
              </AnbotoButton>
            )}
          </Stack>
        )}
      </Card>
      {open && <ApiKeyModal onClose={() => setOpen(false)} />}

      {!!selected && (
        <AnbotoModal
          open
          dismissButtonLabel="Cancel"
          successButtonLabel="Delete"
          onDismissButtonClick={() => setSelected(null)}
          onSuccessButtonClick={() => handleDeleteApiKey()}
          loading={deleteApiKeyLoading}
        >
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to delete <b>{data?.find(({ label }) => label === selected.label)?.label}</b> key?
            </Typography>
          </DialogContent>
        </AnbotoModal>
      )}
    </>
  );
};
