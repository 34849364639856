import { CefiGroup } from "../store/widgets.slice";
import { WidgetsLayoutItem } from "../types";
import { useAppSelector } from "@src/store/hooks";

export const useActiveGroups = () => {
  const layout = useAppSelector((state) => state.widgets.views[state.widgets.view || ""]?.layout);
  const groupsData = useAppSelector((state) => state.widgets.views[state.widgets.view || ""]?.groups);
  const activeGroups = Object.values(layout || {}).reduce<string[]>((acc, item: WidgetsLayoutItem) => {
    if (item.group && !acc.includes(item.group)) acc.push(item.group);

    return acc;
  }, []);

  return activeGroups.reduce((acc, group) => {
    if (groupsData && groupsData[group]) acc[group] = groupsData[group];

    return acc;
  }, {} as Record<string, CefiGroup>);
};
