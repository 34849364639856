import { useRef, useState, useEffect } from "react";

type Size = {
  width: number;
  height: number;
};

export function useElementSize(): [React.RefObject<HTMLDivElement>, Size] {
  const elementRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  useEffect(() => {
    const element = elementRef.current;
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      // Loop through the entries
      for (const entry of entries) {
        // Update the size state with the new dimensions
        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      }
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return [elementRef, size];
}
