import { Slider, styled } from "@mui/material";

export const AnbotoSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 14,
    width: 14,
    marginLeft: 6,
    backgroundColor: "#2C72E3",
    boxShadow: "none !important",
    "&:hover": {},
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "#3B4043",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#3B4043",
    height: 10,
    width: 10,
    borderRadius: "50%",
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
  "& .MuiSlider-markLabelActive": {
    color: "#2C72E3",
  },
}));
