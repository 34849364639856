/* eslint-disable react/display-name */

import React from "react";
import { createColumnHelper, Row } from "@tanstack/table-core";
import { CoinNameIcon } from "../../coin-name-icon";
import { formatTokenAmount } from "@src/utils/format";
import { CurrencyValue } from "@src/pages/portfolio";
import { MemoizedExpandRow } from "@src/pages/cefi/orders-table/components/expand-row";
import { DefiAllocationWithIcon } from "@src/store/apis/anbotoApi/types";

const columnHelper = createColumnHelper<DefiAllocationWithIcon>();

export const getDefiColumns = (isOverview: boolean, isProtocolAsset: boolean) => [
  ...(isOverview
    ? [
        columnHelper.display({
          header: "",
          size: 35,
          id: "sort",
          cell: ({ row }) => <MemoizedExpandRow expanded={row.getIsExpanded()} onClick={() => row.toggleExpanded()} />,
        }),
      ]
    : []),
  columnHelper.accessor("token_symbol", {
    id: "token_symbol",
    header: "Token",
    size: 200,
    cell: ({ row }: { row: Row<DefiAllocationWithIcon> }) => (
      <CoinNameIcon
        coinName={row.original.coinName}
        symbol={row.original.token_symbol}
        url={row.original.chainIconUrl}
      />
    ),
  }),
  ...(isProtocolAsset
    ? [
        columnHelper.accessor("protocol", {
          id: "protocol",
          header: "Protocol",
          size: 30,
          cell: ({ row }: { row: Row<DefiAllocationWithIcon> }) => (
            <span>{formatTokenAmount(Number(row.original.protocol))}</span>
          ),
        }),
      ]
    : []),
  columnHelper.accessor("balance", {
    id: "balance",
    header: "Balance",
    size: 30,
    cell: ({ row }: { row: Row<DefiAllocationWithIcon> }) => <span>{formatTokenAmount(row.original.balance)}</span>,
  }),
  columnHelper.accessor("price", {
    id: "price",
    header: "Price",
    size: 30,
    cell: ({ row }: { row: Row<DefiAllocationWithIcon> }) => (
      <>
        ~<CurrencyValue value={row.original.price} />
      </>
    ),
  }),
  columnHelper.accessor("value", {
    id: "value",
    header: "Value",
    size: 30,
    cell: ({ row }: { row: Row<DefiAllocationWithIcon> }) => <CurrencyValue value={row.original.value} />,
  }),

  columnHelper.accessor("portion", {
    id: "portion",
    header: "Portion (%)",
    size: 30,
    cell: ({ row }: { row: Row<DefiAllocationWithIcon> }) => <span>{Number(row.original.portion).toFixed(2)}%</span>,
    sortingFn: (a, b) => a.original.portion - b.original.portion,
  }),
];
