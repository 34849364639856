import React, { useEffect, useState } from "react";
import { Paper, Stack } from "@mui/material";
import PostTradeStats from "@src/pages/metrics/components/post-trade-stats/post-trade-stats";
import { GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";
import { getTimestampForPeriod } from "@src/pages/metrics/helpers";
import PostTradeAnalysisFilters from "@src/pages/metrics/components/post-trade-analysis-filters/post-trade-analysis-filters";
import PostTradeStatsTable from "@src/pages/metrics/components/post-trade-table/post-trade-stats-table";

const PostTradeAnalysisMetrics = () => {
  const [period, setPeriod] = useState(GetOrdersPeriod.ThreeMonths);

  const [start, setStart] = useState(getTimestampForPeriod(period));
  const [end, setEnd] = useState(new Date().getTime() / 1000);

  useEffect(() => {
    setStart(getTimestampForPeriod(period));
    setEnd(new Date().getTime() / 1000);
  }, [period]);

  return (
    <Paper sx={{ maxWidth: "99.9%", flexGrow: 1, padding: 3, display: "flex" }}>
      <Stack gap={1}>
        <PostTradeAnalysisFilters
          onPeriodChange={setPeriod}
          startDate={start}
          endDate={end}
          onStartDateChange={setStart}
          onEndDateChange={setEnd}
        />
        <Stack mt={1}>
          <PostTradeStats start={start} end={end} />
        </Stack>
        <PostTradeStatsTable />
      </Stack>
    </Paper>
  );
};

export default PostTradeAnalysisMetrics;
