import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { CHAIN_ID_BASE_URL } from "@src/store/apis/zeroexApi/constants";
import { GetQuoteParams, Quote } from "@src/store/apis/zeroexApi/types";
import { OrderSide } from "@src/store/apis/anbotoApi/types";
import { ChainId } from "@src/pages/defi/types";

export type GetZeroXQuoteParams = {
  buyToken: string;
  sellToken: string;
  buyAmount: string | undefined;
  sellAmount: string | undefined;
  slippage: string;
  chainId: ChainId;
};

const zeroXApiKey = process.env.REACT_APP_ZERO_X_API_KEY;

const baseQuery = fetchBaseQuery({
  prepareHeaders: (headers) => {
    if (zeroXApiKey) {
      headers.set("0x-api-key", zeroXApiKey);
    }

    return headers;
  },
});

export const zeroexApi = createApi({
  reducerPath: "zeroexApi",
  baseQuery,
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    // https://docs.0x.org/0x-api-swap/api-references/get-swap-v1-quote
    getQuote: builder.query<Quote, GetQuoteParams>({
      query: ({ buyTokenAddress, sellTokenAddress, slippage, tokenAmount, chainId, side }) => ({
        url: `${CHAIN_ID_BASE_URL[chainId]}/swap/v1/quote`,
        params: {
          sellToken: sellTokenAddress,
          buyToken: buyTokenAddress,
          sellAmount: side === OrderSide.SELL ? tokenAmount : undefined,
          buyAmount: side === OrderSide.BUY ? tokenAmount : undefined,
          slippagePercentage: slippage.length ? parseFloat(slippage) / 100 : 0,
        },
      }),
    }),
    // The same endpoint without business logic. Let's remove the old one after defiV2 removing
    getZeroXQuote: builder.query<Quote, GetZeroXQuoteParams>({
      query: ({ buyToken, sellToken, slippage, buyAmount, sellAmount, chainId }) => ({
        url: `${CHAIN_ID_BASE_URL[chainId]}/swap/v1/quote`,
        params: {
          sellToken,
          buyToken,
          sellAmount,
          buyAmount,
          slippagePercentage: slippage.length ? parseFloat(slippage) / 100 : 0,
        },
      }),
    }),
  }),
});
