import React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { IconButton, Badge } from "@mui/material";

export const FrillWidget = React.memo(() => {
  const [widget, setWidget] = React.useState<FrillWidget>();
  const [showBadge, setShowBadge] = React.useState(false);

  React.useEffect(() => {
    const config: FrillConfig = {
      key: process.env.REACT_APP_FRILL_WIDGET_ID || "",
      callbacks: {
        onReady: (frillWidget) => {
          setWidget(frillWidget);
        },
        onBadgeCount({ count }) {
          setShowBadge(!!count);
        },
      },
    };

    if ("Frill" in window) {
      setWidget(window.Frill.widget(config));
    }

    if ("Frill_Config" in window && Array.isArray(window.Frill_Config)) {
      window.Frill_Config.push(config);
    } else {
      window.Frill_Config = [];
      window.Frill_Config.push(config);
    }

    return () => {
      if (widget) {
        widget.destroy();
      }
      if (window.Frill_Config) {
        window.Frill_Config = window.Frill_Config.filter((c) => c !== config);
      }
    };
  }, []);

  return (
    <IconButton className="announcements-icon-button" sx={{ color: "#8A9296" }} onClick={() => widget?.open()}>
      <Badge color="error" variant="dot" invisible={!showBadge} overlap="circular">
        <NotificationsIcon sx={{ fontSize: 20 }} />
      </Badge>
    </IconButton>
  );
});

FrillWidget.displayName = "FrillWidget";
