import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { CircularProgress, InputAdornment } from "@mui/material";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type TokenAmountFieldProps = ControllerRenderProps & AnbotoTextFieldProps & { isLoading: boolean };

const LoaderInputAdornment = () => (
  <InputAdornment position="start" sx={{ pl: 1 }}>
    <CircularProgress size={24} />
  </InputAdornment>
);

export const TokenAmountField = forwardRef(({ isLoading, ...props }: TokenAmountFieldProps, ref) => {
  return (
    <AnbotoTextField
      {...props}
      value={isLoading ? "" : props.value}
      inputRef={ref}
      hiddenLabel
      size="small"
      placeholder={isLoading ? "" : "0.0"}
      disabled={isLoading || props?.disabled}
      fullWidth
      sx={{
        "& .MuiFormHelperText-root": {
          marginRight: 0,
        },
      }}
      InputProps={{
        startAdornment: isLoading ? <LoaderInputAdornment /> : undefined,
        sx: {
          backgroundColor: "#031116",
          px: 0,
          pr: 0.25,
          borderRadius: 0,
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          "& .MuiOutlinedInput-input": { fontWeight: 600 },
        },
      }}
    />
  );
});
TokenAmountField.displayName = "TokenAmountField";
