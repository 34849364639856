import React from "react";
import { Stack, styled } from "@mui/material";
import Sidebar from "./sidebar";
import Lines from "@src/pages/auth/assets/auth-lines.svg";

type RegistrationLayoutProps = {
  children: React.ReactNode;
};

export const Layout = ({ children }: RegistrationLayoutProps) => {
  return (
    <PageContainer>
      <SidebarContainer>
        <Sidebar />
      </SidebarContainer>
      <RegistrationLayoutContent>{children}</RegistrationLayoutContent>
    </PageContainer>
  );
};

const PageContainer = styled(Stack)({
  minWidth: 1280,
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "row",
  flexGrow: 1,
});

const SidebarContainer = styled(Stack)(({ theme }) => ({
  width: 359,
  background: theme.palette.background.paper,
  paddingLeft: 100,
  paddingTop: 66,
}));

const RegistrationLayoutContent = styled(Stack)(({ theme }) => ({
  background: theme.custom.background.default,
  flexGrow: 1,
  position: "relative",
  gap: 0,
  backgroundImage: `url(${Lines})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
}));

export default Layout;
