import React from "react";
import { dateFormatter } from "@src/pages/analytics/helpers";
import { AxisTickProps } from "@src/pages/analytics/types";

const AngleAxisTimeTick = ({ x, y, payload }: AxisTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <text fontSize={12} x={0} y={0} dy={16} textAnchor="end" fill="white" transform="rotate(-45)">
      {dateFormatter(payload.value)}
    </text>
  </g>
);

export default AngleAxisTimeTick;
