import { formatWithDelimiter } from "@src/utils/format";

export function getDecimalPlaces(base: number) {
  const exponent = Math.log10(Math.abs(base));
  return exponent < 0 ? Math.abs(Math.ceil(exponent)) : 0;
}

export const formatPrice = (price: number, decimals: number) =>
  Number(price).toLocaleString("en", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });

export function calculateGroups(prices: Array<Array<number>>, maxGroups: number) {
  if (!prices.length) {
    return [];
  }

  let maxDecimalPlaces = 0;
  let maxIntegerPart = 0;

  for (const price of prices) {
    const [integerPart = "", decimalPlaces = ""] = (price[0] + "").split(".");

    if (decimalPlaces.length > maxDecimalPlaces) maxDecimalPlaces = decimalPlaces.length;
    if (integerPart.length > maxIntegerPart) maxIntegerPart = integerPart.length;
  }

  const limit = maxDecimalPlaces + maxIntegerPart < maxGroups ? maxDecimalPlaces + maxIntegerPart : maxGroups;

  const groupSizes: string[] = [];

  const base = 10 ** (limit - (maxDecimalPlaces + 1));

  let decimalPlaces = getDecimalPlaces(base);

  for (let i = 0; i < limit; i++) {
    const num = base * 10 ** -i;
    if (num <= 1 && getDecimalPlaces(num) > decimalPlaces) decimalPlaces += 1;

    groupSizes.push(parseFloat(num.toString()).toFixed(decimalPlaces));
  }

  return groupSizes.sort((a, b) => +a - +b);
}

export const formatTotal = (amount: string) => {
  if (+amount > 10) return formatWithDelimiter((+amount).toFixed(2));

  return formatWithDelimiter(amount);
};
