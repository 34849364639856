import React, { useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import { Layout, Layouts, Responsive } from "react-grid-layout";
import { BREAKPOINTS, DRAG_HANDLE_CSS_CLASS_NAME, LAYOUT_COLUMNS, LAYOUT_ROW_HEIGHT } from "../constants";
import { WidgetLayoutBreakpoints, WidgetsGridView, WidgetsLayoutItem } from "../types";
import { CONFIGS } from "../widgets/configs";
import { COMPONENTS } from "../widgets/components";
import { useWidgetsGridLayout } from "../hooks/use-widgets-grid-layout";
import { changeBreakpoint, changeView } from "../store/widgets.slice";
import { compactBpLayout } from "../utils/compact-bp-layout";
import { ResizeHandler } from "./resize-handler";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { UpdateGroupDataModal } from "./udpate-group-data-modal";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { useWindowSize } from "@src/hooks/use-window-size";
import { styledOptions } from "@src/utils/styled-options";

export type WidgetsGridLayoutProps = {
  view: WidgetsGridView;
  onWidgetsMount?: () => void;
};

export const WidgetsGridLayout = ({ view, onWidgetsMount }: WidgetsGridLayoutProps) => {
  const containterRef = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = React.useState(0);
  const expandedSidebar = useAppSelector((state) => state.uiSettings.expandedSidebar);
  const appLayoutView = useAppSelector((state) => state.uiSettings.layoutView);
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const { layout, updateLayout, error, loading } = useWidgetsGridLayout(view);

  const [animated, setAnimated] = useState(false);
  const [forceUpdateDep, setForceUpdateDep] = useState<number>();
  const isLayoutLocked = useAppSelector((state) => state.uiSettings.isLayoutLocked);
  const groupsNumber = Object.values(layout).reduce<string[]>(
    (groups, widget) =>
      widget.group && groups.includes(widget.group || "") ? groups : [...groups, widget.group || ""],
    []
  ).length;
  const widgetsNumberByType = Object.values(layout).reduce<Record<string, number>>(
    (names, widget) => ({ ...names, [widget.name]: (names[widget.name] || 0) + 1 }),
    {}
  );
  const hideGroup =
    isLayoutLocked && groupsNumber < 2 && !Object.values(widgetsNumberByType).some((number) => number > 1);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const bp = Responsive?.utils?.getBreakpointFromWidth(BREAKPOINTS, width);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      const containerWidth = containterRef.current?.clientWidth;

      if (containerWidth) {
        setWidth(containterRef.current?.clientWidth);
      }
    });
  }, [expandedSidebar, appLayoutView, windowSize?.width]);

  React.useLayoutEffect(() => {
    dispatch(changeView(view));

    if (onWidgetsMount) {
      setTimeout(() => {
        onWidgetsMount();
      });
    }
  }, []);

  const forceUpdate = () => setTimeout(() => setForceUpdateDep(+new Date()));

  // {
  //   id: "c1",
  //   name: WidgetName.CefiOrderForm,
  //   group: 1,
  //   layout: { i: "c1", x: 0, y: 0, w: 10, h: 12, isResizable: true },
  // }[]
  const layouts = React.useMemo(() => {
    const res: Layouts = {};

    Object.values(layout || {}).forEach((widget: WidgetsLayoutItem) => {
      const defaultWidgetLayout = CONFIGS[widget.name]?.layout || {};
      Object.keys(widget.layout).forEach((bp) => {
        if (!res[bp]) res[bp] = [];

        const minW = defaultWidgetLayout[bp]?.minW;
        const minH = defaultWidgetLayout[bp]?.minH;

        res[bp].push({
          ...widget.layout[bp],
          x: widget.layout[bp]?.x === null ? Infinity : widget.layout[bp]?.x,
          y: widget.layout[bp]?.y === null ? Infinity : widget.layout[bp]?.y,
          minH: minH || widget.layout[bp]?.minH,
          minW: minW || widget.layout[bp]?.minW,
        });
      });
    });

    return res;
  }, [layout, forceUpdateDep]);

  // const onLayoutChange = (layout: Layout[]) => {
  //   console.log("layout change");
  //   // updateLayout(layout);
  // };

  const handleResize = (layout: Layout[]) => {
    updateLayout(layout);
    forceUpdate();
  };

  const handleDragStop = (layout: Layout[]) => {
    updateLayout(compactBpLayout(layout));
    // updateLayout(layout);
    forceUpdate();
  };

  // // when responsive breakpoint changed react grid layout sends new layout twice: with old breakpoint and new breakpoint
  // // which leads to replacement of previous bp layout with new one
  // const debouncedLayoutChange = React.useCallback(_debounce(onLayoutChange, 500), []);

  return loading ? null : error ? (
    <Typography>{error}</Typography>
  ) : (
    <Root flex={1} ref={containterRef} animated={animated} onMouseDown={() => !animated && setAnimated(true)}>
      <UpdateGroupDataModal />
      {!!width && (
        <Responsive
          isDraggable={!isLayoutLocked}
          isResizable={!isLayoutLocked}
          width={width}
          draggableHandle={`.${DRAG_HANDLE_CSS_CLASS_NAME}`}
          rowHeight={LAYOUT_ROW_HEIGHT}
          layouts={layouts}
          breakpoints={BREAKPOINTS}
          compactType="vertical"
          cols={{
            xxl: LAYOUT_COLUMNS,
            xl: LAYOUT_COLUMNS,
            lg: LAYOUT_COLUMNS,
            md: LAYOUT_COLUMNS,
            sm: LAYOUT_COLUMNS,
            xs: LAYOUT_COLUMNS,
          }}
          resizeHandles={!isLayoutLocked ? ["se"] : []}
          resizeHandle={<ResizeHandler />}
          containerPadding={[0, 0]}
          margin={[4, 4]}
          onResizeStop={handleResize}
          onBreakpointChange={(bp: WidgetLayoutBreakpoints) => {
            dispatch(changeBreakpoint(bp));
          }}
          onDragStop={handleDragStop}
          useCSSTransforms
        >
          {Object.values(layout).map(({ name, group, id, layout }) => {
            const widgetConfig = CONFIGS[name];

            if (!widgetConfig) return null;

            const { options } = widgetConfig;
            const component = COMPONENTS[name];

            return React.createElement(component, {
              ...options,
              lock: isLayoutLocked,
              name,
              group: group || id,
              id,
              key: id,
              dataGrid: layout[bp],
              hideGroup,
            });
          })}
        </Responsive>
      )}
    </Root>
  );
};

const Root = styled(
  Box,
  styledOptions
)<{ animated?: boolean }>(({ animated }) => ({
  minHeight: "100%",
  "& .react-grid-item.cssTransforms": {
    transitionProperty: animated ? "transform" : "none",
  },
}));
