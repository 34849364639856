import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";

export enum OtcSymbolSelectTab {
  ALL = "all",
  SPOT = "spot",
  CFD = "cfd",
  RECENT = "recent",
  FAVORITES = "favorites",
}

export enum OtcMarketType {
  SPOT = "spot",
  CFD = "cfd",
}

export type Asset = {
  coin: string;
  exchange: CefiExchangeId;
  balance: string;
  price: string;
};
