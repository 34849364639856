import GridLayout, { Layout } from "react-grid-layout";
import _cloneDeep from "lodash/cloneDeep";
import { LAYOUT_COLUMNS } from "../constants";

export const compactBpLayout = (layout: Layout[]) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  GridLayout?.utils?.compact(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    GridLayout?.utils?.correctBounds(_cloneDeep(layout), { cols: LAYOUT_COLUMNS }),
    "vertical",
    LAYOUT_COLUMNS
  );
