import React from "react";
import { Stack, Link, Typography, styled } from "@mui/material";
import { Row, createColumnHelper } from "@tanstack/table-core";
import { MAIN_ACCOUNT_VALUE } from "../constants";
import { Position } from "./types";
import { TotalPnl } from "./total-pnl";
import { CELL_FONT_SIZE, sort } from "@src/components/anboto-table";
import { OrdersTableAccount } from "@src/components/orders-table-account";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { formatAmount, roundAmount } from "@src/pages/portfolio/utils/helpers";

export const columnHelper = createColumnHelper<Position>();

export const formatValue = (value: number) => (value ? formatAmount(roundAmount(String(value), 3)) : "-");

export const getColumns = ({ onPositionClick }: { onPositionClick?: (p: Position) => void }) => [
  columnHelper.display({
    header: "",
    size: 35,
    id: "sort",
  }),
  columnHelper.accessor("symbol", {
    id: "symbol",
    size: 250,
    header: "Symbol",
    cell: ({ row }) => (
      <Stack direction="row" gap={1} alignItems="center">
        <Link
          sx={{ cursor: "pointer", color: "white", textDecoration: "underline" }}
          onClick={() => onPositionClick && onPositionClick(row.original)}
        >
          {row.original.symbol} - {getExchangeNameLabel(CEFI_EXCHANGE_NAME[row.original.exchange])}
        </Link>
        <Leverage>
          <Typography variant="body2" fontSize={12} fontWeight="bold" lineHeight={1}>
            {row.original.leverage}x
          </Typography>
        </Leverage>
      </Stack>
    ),
  }),
  columnHelper.accessor("subaccount", {
    id: "account",
    size: 100,
    header: "Account",
    cell: ({ getValue }) => {
      const value = getValue() === MAIN_ACCOUNT_VALUE ? "Main" : getValue();

      return <OrdersTableAccount label={value} fontSize={CELL_FONT_SIZE} />;
    },
    sortingFn: (rowA: Row<Position>, rowB: Row<Position>) => {
      const a = rowA.original?.subaccount;
      const b = rowB.original?.subaccount;
      return sort(a, b);
    },
  }),
  columnHelper.accessor("balance", {
    id: "quantity",
    header: "Quantity",
    cell: ({ getValue }) => {
      const value = getValue();

      return (
        <Typography
          sx={{
            color: (theme) => (value < 0 ? theme.palette.error.main : theme.custom.colors.secondary),
            fontSize: CELL_FONT_SIZE,
          }}
        >
          {formatValue(value)}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("entryPrice", {
    id: "avg-open",
    header: "Avg. Open",
    size: 100,
    cell: ({ getValue }) => formatValue(getValue()),
  }),
  columnHelper.accessor("price", {
    id: "last-price",
    header: "Last Price",
    size: 100,
    cell: ({ getValue }) => formatValue(getValue()),
  }),
  columnHelper.accessor("liquidationPrice", {
    id: "liq-price",
    header: "Liq. Price",
    size: 100,
    cell: ({ getValue }) => formatValue(getValue()),
  }),
  columnHelper.accessor("unrealizedPnl", {
    id: "unreal-pnl",
    header: () => (
      <div>
        <TotalPnl />
        <div>Unreal. PnL (ROE %)</div>
      </div>
    ),
    size: 120,
    cell: ({ getValue, row }) => {
      const value = getValue();

      if (!row.original.entryPrice || !row.original.balance || !value) return "-";

      const roe = (value * 100) / (row.original.entryPrice * row.original.balance);

      return (
        <Typography
          sx={{
            color: (theme) => (value < 0 ? theme.palette.error.main : theme.custom.colors.secondary),
            fontSize: CELL_FONT_SIZE,
          }}
        >
          {getDirectionPreffix(value) + "$" + formatValue(Math.abs(value))} {"(" + roe.toFixed(1) + "%)"}
        </Typography>
      );
    },
  }),
  columnHelper.accessor("notional", {
    id: "notional",
    header: "Notional",
    size: 100,
    cell: ({ getValue }) => formatValue(getValue()),
  }),
];

export const getDirectionPreffix = (value: number) => (value > 0 ? "+" : value < 0 ? "-" : "");

const Leverage = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(0.65),
  paddingRight: theme.spacing(0.65),
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  background: `rgba(255,255,255, 0.1)`,
  alignItems: "center",
  justifyContent: "center",
}));
