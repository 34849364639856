import { fallback, http } from "wagmi";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { mainnet, bsc, polygon, optimism, arbitrum, avalanche, base } from "wagmi/chains";
import "@rainbow-me/rainbowkit/styles.css";
import {
  metaMaskWallet,
  rabbyWallet,
  rainbowWallet,
  walletConnectWallet,
  ledgerWallet,
  coinbaseWallet,
  phantomWallet,
  safeWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { _chains } from "@rainbow-me/rainbowkit/dist/config/getDefaultConfig";
import { DEFI_FALLBACK_RPCS } from "./constants";

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID!;

// const initializeRainbowKit = (state: any) => {

//   const { chains, publicClient, webSocketPublicClient } = configureChains(
//     //[mainnet],
//     [bsc, mainnet, polygon, optimism, arbitrum, avalanche, base],
//     [
//       jsonRpcProvider({
//         rpc: (chain) => {
//           return { http: DEFI_FALLBACK_RPCS[chain.id] };
//         },
//       }),
//       publicProvider(),
//     ]
//   );

//   const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID!;
//   const appName = "Anboto App";
//   const connectors = connectorsForWallets([
//     {
//       groupName: "Anboto wallets",
//       wallets: [
//         injectedWallet({ chains }),
//         rainbowWallet({ projectId, chains }),
//         metaMaskWallet({ projectId, chains }),
//         rabbyWallet({ chains }),
//         walletConnectWallet({ projectId, chains }),
//         ledgerWallet({ projectId, chains }),
//         coinbaseWallet({ appName, chains }),
//         phantomWallet({ chains }),
//       ],
//     },
//   ]);
//   const wagmiConfig = createConfig({
//     autoConnect: true,
//     connectors,
//     publicClient,
//     webSocketPublicClient,
//   });
//   state.wagmiConfig = wagmiConfig;
//   state.chains = chains;
// }

const chains = [mainnet, bsc, polygon, optimism, arbitrum, avalanche, base] as _chains;

export const wagmiConfig = getDefaultConfig({
  appName: "Anboto",
  projectId,
  chains,
  wallets: [
    {
      groupName: "Anboto wallets",
      wallets: [
        safeWallet,
        rainbowWallet,
        metaMaskWallet,
        rabbyWallet,
        walletConnectWallet,
        ledgerWallet,
        coinbaseWallet,
        phantomWallet,
      ],
    },
  ],
  transports: chains.reduce(
    (acc, chain) => ({
      ...acc,
      [chain.id]: fallback([http(DEFI_FALLBACK_RPCS[chain.id])]),
    }),
    {}
  ),
});
