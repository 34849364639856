import React from "react";
import { Link, List, ListItem, Typography, useTheme } from "@mui/material";

export const TermsAndConditions = () => {
  const theme = useTheme();
  return (
    <>
      <Typography
        color={theme.palette.text.secondary}
        fontSize={16}
        fontWeight={500}
        variant="h4"
        component="h1"
        gutterBottom
      >
        WOO Campaign Terms and Conditions
      </Typography>
      <Typography fontSize={14} color={theme.palette.text.secondary}>
        All participants of the campaign accept that:
      </Typography>
      <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary, fontSize: 14 }}>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          He/She abides by the WOO X{" "}
          <Link
            color={theme.palette.text.secondary}
            href="https://support.woo.org/hc/en-001/articles/4403851854233--Terms-of-Services"
          >
            Terms of Service
          </Link>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          He/she acknowledges and agrees to these Terms & Conditions, Campaign Rules, and any subsequent amendments,
          once he/she joins the campaign. Additionally, his/her relevant information including but not limited to
          nickname and UID on WOO X, all the individual trades and trade amounts made during the trading fee rebate
          validity period, and any rankings as required by the campaign will be calculated and stored for the duration
          of the entire campaign, as well as may also be made publicly available. The aforementioned information cannot
          be removed or revoked during the campaign. Furthermore, he/she acknowledges and agrees that WOO has the right
          to access, read, and disclose his/her relevant aforementioned information.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          WOO reserves the right to disqualify any participant if he/she engages in any inappropriate, dishonest, or
          abusive activities during the campaign. The participant of the campaign acknowledges and agrees that the
          decision regarding disqualification is at the sole discretion of the WOO X and WOO is not obliged to provide
          any explicit reason(s) for doing so.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          WOO reserves the right to modify and the final interpretation of the Terms & Conditions of this without
          notifying users in advance. If you have any questions, please contact our customer service.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          WOO will not be responsible or liable for any losses (including, without limitation, any direct or indirect
          damages for loss of profits) resulting or arising directly or indirectly from your participation in this
          campaign.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          WOO reserves the right to disqualify trades that are wash trades, involve illegal bulk account registrations,
          self-dealing, or display signs of market manipulation or suspicious behavior, etc. The participant of the
          campaign acknowledges and agrees that the decision regarding disqualification is at the sole discretion of the
          WOO X and WOO is not obliged to provide any explicit reason(s) for doing
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Your personal data are processed by WOOTECH Limited, on the grounds of article 6(1)f of the GDPR - legitimate
          interest of WOO, for the following purposes:
          <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>Conducting the competition,</ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>Selecting winners of the competition,</ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
              Analytics assessment of data collected during the competition.
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Typography fontSize={14} color={theme.palette.text.secondary}>
        You can object to that processing at any time. You may contact us via{" "}
        <Link color={theme.palette.text.secondary} href="https://support.woo.org/hc/en-001/requests/new">
          this form
        </Link>
        . Your data are being transferred outside of the European Economic Area to the United States, Japan, and Taiwan.
        For more details and your rights, please visit{" "}
        <Link
          href="https://docs.google.com/document/d/1QBwYYZoEMFFmqbiUprBR5hxt-VdWzdPCYjC-7eowYhA/edit#heading=h.gjdgxs"
          color={theme.palette.text.secondary}
        >
          WOO X Privacy Note
        </Link>
        .
      </Typography>
    </>
  );
};
