import { WidgetConfigLayout, WidgetName, WidgetLayout } from "../types";
import { getWidgetId } from "./get-id";

export const injectIdInWidgetLayout = (id: string, layout: WidgetConfigLayout) =>
  Object.keys(layout).reduce<WidgetLayout>(
    (res, bp) => ({
      ...res,
      [bp]: {
        ...layout[bp],
        i: id,
      },
    }),
    {} as WidgetLayout
  );

export const createWidgetLayoutItem = (name: WidgetName, layout: WidgetConfigLayout, group?: string) => {
  const id = getWidgetId(name);

  return {
    [id]: {
      id,
      name,
      group: group || "1",
      layout: injectIdInWidgetLayout(id, layout),
    },
  };
};
