import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import AnbotoLoader from "@src/components/anboto-loader/anboto-loader";
import { Holder } from "@src/store/apis/anbotoApi/types";
import { AnbotoTable } from "@src/components/anboto-table";
import { getColumns } from "@src/pages/defi/token-info/components/top-holders/get-columns";
import { ankr } from "@src/store/apis/anbotoApi/ankr";
import { SearchPool } from "@src/store/apis/geckoterminal-api/types";
import { Token } from "@src/pages/defi/types";

type TopHoldersProps = {
  baseTokenPrice: number;
  tokenInfoPool: SearchPool;
  pairInfoToToken: Token | undefined;
  pairInfoFromToken: Token | undefined;
};

export type HolderWithUsdValue = Holder & { usdValue: number; id: number };

const TopHolders = ({ baseTokenPrice, pairInfoToToken, pairInfoFromToken, tokenInfoPool }: TopHoldersProps) => {
  const orderForm = useDefiOrderFormContext();
  const chainId = orderForm.getValues("chainId");

  const { data, isFetching, isError, refetch } = ankr.useGetTopTokenHoldersQuery(
    {
      chainId,
      contractAddress: (tokenInfoPool?.isNeedToRevert ? pairInfoToToken?.address : pairInfoFromToken?.address) || "",
    },
    { skip: !pairInfoToToken || !pairInfoFromToken }
  );

  const holdersList = data?.result?.holders || [];
  const sortedHoldersList = useMemo(
    () =>
      [...holdersList]
        .sort((a, b) => +b.balance - +a.balance)
        .map((holder, index) => ({
          ...holder,
          usdValue: baseTokenPrice * +holder?.balance,
          id: index + 1,
        }))
        .slice(0, 500),
    [isFetching]
  );

  const columns = useMemo<Array<ColumnDef<HolderWithUsdValue, any>>>(
    () => getColumns({ symbol: tokenInfoPool?.isNeedToRevert ? pairInfoToToken?.symbol : pairInfoFromToken?.symbol }),
    [isFetching]
  );

  return (
    <>
      {isFetching ? (
        <Stack direction="column" justifyContent="center" minHeight={300}>
          <AnbotoLoader />
        </Stack>
      ) : pairInfoFromToken && pairInfoToToken ? (
        <Stack maxHeight={430} sx={{ overflow: "auto" }}>
          <AnbotoTable<HolderWithUsdValue>
            containerSx={{ minWidth: 435 }}
            columns={columns}
            data={sortedHoldersList}
            loading={isFetching}
            error={!!isError}
            refetch={refetch}
            noDataText="You don't have any records"
            getRowId={(order: HolderWithUsdValue) => order.holderAddress}
          />
        </Stack>
      ) : (
        <Typography>Unable to fetch transactions information</Typography>
      )}
    </>
  );
};

export default TopHolders;
