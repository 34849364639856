import React from "react";
import { Typography, Stack, Grid, styled, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SettingsCard } from "../settings-card";
// import { ConnectGoogleButton } from "../connect-google-button";
import { telegramIdFormater } from "../helpers";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { RootState } from "@src/store/types";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { ReactComponent as LogoIcon } from "@src/assets/svg/logo-dim.svg";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { REG_EX_TELEGRAM } from "@src/pages/auth/registration/constants";
import { profileInfoSchema } from "@src/pages/auth/registration/hooks/use-profile-info-form";

type SettingsForm = { firstName: string; lastName: string; telegramId: string; email: string; phone: string };

export const ProfileSettings = () => {
  const snackbar = useSnackbar();
  const [updateProfile, { isLoading }] = anbotoApi.useUpdateProfileMutation();
  const { email, first_name, last_name, telegram_id, phone_number } = useSelector((state: RootState) => state.user);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SettingsForm>({
    resolver: yupResolver(profileInfoSchema),
    defaultValues: {
      firstName: first_name,
      lastName: last_name,
      telegramId: telegram_id,
      email,
      phone: phone_number,
    },
  });

  const handleSave = () => {
    handleSubmit(async ({ email, firstName, lastName, phone, telegramId }) => {
      // filling @ incase the user forget it and doesn't leave the field blank before sending the request
      telegramId = telegramId?.length ? telegramIdFormater(telegramId) : telegramId;

      if (telegramId && !telegramId.match(REG_EX_TELEGRAM)) {
        return snackbar.enqueueSnackbar("Please input a valid Telegram ID", {
          variant: "error",
        });
      }

      try {
        await updateProfile({
          email,
          first_name: firstName,
          last_name: lastName,
          phone_number: phone,
          telegram_id: telegramId,
        });
        snackbar.enqueueSnackbar("Updated successfully", {
          variant: "success",
        });
      } catch (err) {
        snackbar.enqueueSnackbar(parseAnbotoRequestError(err?.data), {
          variant: "error",
        });
      }
    })();
  };

  return (
    <SettingsCard
      headerProps={{
        title: "My profile",
        description: "Manage your informations",
      }}
      footer={
        <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
          <AnbotoButton variant="contained" onClick={handleSave} loading={isLoading} sx={{ minWidth: 200 }}>
            Save
          </AnbotoButton>
        </Stack>
      }
    >
      <Box sx={{ maxWidth: 500, ml: "auto", mr: "auto" }}>
        <Stack alignItems="center" sx={{ mb: 4, mt: 1 }}>
          <UploadIcon>
            <LogoIcon />
          </UploadIcon>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <>
                  <AnbotoTextField
                    {...field}
                    error={Boolean(errors.firstName)}
                    fullWidth
                    label="First name"
                    labelStyle="dynamic"
                    helperText={errors?.firstName?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <>
                  <AnbotoTextField
                    {...field}
                    error={Boolean(errors.lastName)}
                    fullWidth
                    label="Last name"
                    labelStyle="dynamic"
                    helperText={errors?.lastName?.message || ""}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <AnbotoTextField
                    {...field}
                    error={Boolean(errors.email)}
                    fullWidth
                    label="Email"
                    labelStyle="dynamic"
                    helperText={errors?.email?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="telegramId"
              control={control}
              render={({ field }) => (
                <>
                  <AnbotoTextField
                    {...field}
                    error={Boolean(errors.telegramId)}
                    fullWidth
                    label="Telegram ID: @qwerty"
                    labelStyle="dynamic"
                    helperText={errors?.telegramId?.message}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
              Our Support is done through Telegram groups. Therefore, we require your Telegram ID
            </Typography>
          </Grid>
        </Grid>
        {/* <Divider sx={{ mt: 3, mb: 3 }}>
          <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
            Connect and login with
          </Typography>
        </Divider>
        <ConnectGoogleButton /> */}
      </Box>
    </SettingsCard>
  );
};

const UploadIcon = styled(Box)({
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: "#3B4043",
  borderRadius: 32,
  width: 64,
  height: 64,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
