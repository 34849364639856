import React, { useLayoutEffect, useRef, useState, useCallback } from "react";
import { Skeleton, Stack, StackProps, styled, Typography, useTheme } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import _uniqWith from "lodash/uniqWith";
import { useSnackbar } from "notistack";
import { WatchlistSymbolItem } from "./watchlist-symbol-item";
import { useToplistSymbols } from "./hooks";
import { WatchlistMenu } from "./watchlist-menu";
import { getSymbolsDeps } from "./helpers";
import { TOP_BAR_HEIGHT } from "./constants";
import { OrderSymbol, FavoriteSymbol } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useAppSelector } from "@src/store/hooks";
import { RootState } from "@src/store/types";
import { WatchlistView } from "@src/store/slices/watchlistSlice";
import { useWindowSize } from "@src/hooks/use-window-size";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";
import { useAnbotoTickersToStoreSubscriptions } from "@src/subscriptions/hooks/use-anboto-tickers-to-store-subscription";

export type WatchlistTopBarProps = {
  symbols?: OrderSymbol[];
  onSymbolClick?: (symbol: OrderSymbol) => void;
} & StackProps;

const iconSx = { fontSize: 12, color: "#8A9296", cursor: "pointer" };

export const WatchlistTopBar = React.memo(({ onSymbolClick, sx }: WatchlistTopBarProps) => {
  const theme = useTheme();

  const snackbar = useSnackbar();

  const [ready, setReady] = useState(false);

  const tickersContainer = useRef<HTMLDivElement | null>(null);
  const tickersBlock = useRef<HTMLDivElement>(null);
  const tickersBlockSecond = useRef<HTMLDivElement>(null);

  const handleTickersContainerMount = useCallback((node: HTMLDivElement) => {
    tickersContainer.current = node;
    setReady(true);
  }, []);

  const { width } = useWindowSize();
  const [showArrows, setShowArrows] = useState({ left: false, right: true });

  const exchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi: false });
  const showWatchlist = useAppSelector((state: RootState) => state.uiSettings.watchlist);
  const userWatchlist = useAppSelector((state: RootState) => state.user.token_pair_watchlist);
  const currentWatchlist = useAppSelector((state: RootState) => state.uiSettings.watchlistView);

  const [toplistSymbols, toplistSymbolsLoading] = useToplistSymbols();
  const symbols = _uniqWith(
    currentWatchlist === WatchlistView.FAVOURITES ? userWatchlist : toplistSymbols || [],
    (a: FavoriteSymbol, b: FavoriteSymbol) => getLivePriceId(a) === getLivePriceId(b)
  );

  useAnbotoTickersToStoreSubscriptions(
    _uniqWith(
      [...userWatchlist, ...toplistSymbols],
      (a: FavoriteSymbol, b: FavoriteSymbol) => getLivePriceId(a) === getLivePriceId(b)
    )
  );

  const [animated, setAnimated] = useState(false);
  const autoScrollEnabled = useAppSelector((state) => state.uiSettings.watchlistAutoScroll);

  const supportedExchanges = exchangesQuery.data?.results || [];

  const handleSymbolClick = ({ symbol, exchange, asset_class, market_type }: OrderSymbol) => {
    const selectedExchange = supportedExchanges.find((it) => it.exchange_id === exchange.toLowerCase());

    if (!selectedExchange) {
      snackbar.enqueueSnackbar(`Exchange ${exchange} is not supported`, { variant: "error" });
      return;
    }

    if (onSymbolClick) {
      onSymbolClick({
        symbol,
        exchange: selectedExchange.name,
        market_type: market_type || asset_class,
      });
    }
  };

  const loading = toplistSymbolsLoading || exchangesQuery.isFetching;

  const textColor = theme.palette.text.secondary;

  const symbolsDeps = getSymbolsDeps(symbols);

  const getTransform = () =>
    tickersBlock.current
      ? new DOMMatrixReadOnly(window.getComputedStyle(tickersBlock.current).getPropertyValue("transform")).m41
      : 0;

  useLayoutEffect(() => {
    const container = tickersContainer.current;
    const block = tickersBlock.current;
    if (container && block) {
      const hasOverflow = block.offsetWidth > container.offsetWidth;
      const animated = autoScrollEnabled ? hasOverflow : false;

      setAnimated(animated);
      if (!autoScrollEnabled) {
        slideLeft();
      }
      setTimeout(() => {
        const transformX = getTransform();

        setShowArrows({ left: hasOverflow ? transformX < 0 : false, right: hasOverflow ? transformX === 0 : false });
      });
    }
  }, [width, ready, symbolsDeps, autoScrollEnabled]);

  const slideLeft = (updateArrows?: boolean) => {
    updateArrows && setShowArrows({ left: false, right: true });
    if (tickersBlock.current) {
      tickersBlock.current.style.transform = "translateX(0)";
      if (tickersBlockSecond.current) tickersBlockSecond.current.style.transform = "translateX(0)";
    }
  };

  const slideRight = () => {
    setShowArrows({ left: true, right: false });
    const containerWidth = tickersContainer.current?.offsetWidth || 0;
    const tickersWidth = tickersBlock.current?.offsetWidth || 0;
    const diff = containerWidth - tickersWidth;

    if (tickersBlock.current && diff < 0) {
      tickersBlock.current.style.transform = `translateX(${diff}px)`;
      if (tickersBlockSecond.current) tickersBlockSecond.current.style.transform = `translateX(${diff}px)`;
    }
  };

  const renderSymbols = (animated?: boolean) =>
    _uniqWith<FavoriteSymbol>(
      symbols,
      ({ symbol, exchange, asset_class }, item) =>
        `${symbol}${exchange}${asset_class}` === `${item.symbol}${item.exchange}${item.asset_class}`
    )
      .map((symbol: FavoriteSymbol) => (
        <Stack key={getLivePriceId(symbol)}>
          <WatchlistSymbolItem
            key={`${symbol.exchange}${symbol.symbol}${animated ? "animated" : ""}`}
            symbol={symbol}
            onClick={() => handleSymbolClick(symbol)}
            showExchangeIcon
            showCoin
            showPrice
            showChange24h
            sx={{ cursor: "pointer" }}
          />
        </Stack>
      ))
      .filter(Boolean);

  return showWatchlist ? (
    <Stack direction="row" alignItems="center" sx={{ height: 24, ...sx }}>
      {!loading && <WatchlistMenu />}
      <List>
        {!animated && showArrows.left && (
          <ArrowButton onClick={() => slideLeft(true)} left={0} className="watchlist-arrow">
            <ArrowBackIosNewIcon sx={iconSx} />
          </ArrowButton>
        )}
        {!animated && showArrows.right && (
          <ArrowButton onClick={slideRight} right={0} className="watchlist-arrow">
            <ArrowForwardIosIcon sx={iconSx} />
          </ArrowButton>
        )}
        {loading ? (
          <Skeleton sx={{ flex: 1 }} variant="rectangular" />
        ) : !symbols?.length && currentWatchlist !== WatchlistView.TOP_LIST ? (
          <Stack direction="row" sx={{ flex: 1 }} justifyContent="center">
            <Typography variant="caption" sx={{ color: textColor, fontSize: 12 }}>
              Your list is empty.
            </Typography>
          </Stack>
        ) : !symbols?.length ? null : (
          <AutoScroll ref={handleTickersContainerMount}>
            <TickersBlock animated={animated} ref={tickersBlock} className="tickers-block">
              {renderSymbols()}
            </TickersBlock>
            {animated && (
              <TickersBlock ref={tickersBlockSecond} animated={animated} className="tickers-block">
                {renderSymbols(true)}
              </TickersBlock>
            )}
          </AutoScroll>
        )}
      </List>
    </Stack>
  ) : null;
});

WatchlistTopBar.displayName = "WatchlistTopBar";

const List = styled(Stack)({
  flex: 1,
  height: TOP_BAR_HEIGHT,
  alignItems: "center",
  position: "relative",
  flexDirection: "row",
  overflow: "hidden",
  marginRight: 3,
  "&:hover .watchlist-arrow": {
    visibility: "visible",
    zIndex: 2,
  },
});

const TickersBlock = styled(Stack, {
  shouldForwardProp: (prop: string) => !["animated"].includes(prop),
})<{ animated: boolean }>(({ theme, animated }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(1),
  animation: animated ? "marquee 40s linear infinite" : undefined,
  transition: "transform 0.2s",
}));

const AutoScroll = styled(Stack)(() => ({
  overflow: "hidden",
  flexDirection: "row",
  "&:hover .tickers-block": {
    animationPlayState: "paused",
  },
}));

const ArrowButton = styled(Stack)(({ theme }) => ({
  visibility: "hidden",
  width: 20,
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  top: 0,
  zIndex: 1,
  background: theme.palette.background.default,
}));
