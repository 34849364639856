import React from "react";
import { AvatarGroup, Stack, StackProps, styled } from "@mui/material";
import { ColorizedAvatar } from "./colorized-avatar";

export const MAX_AVATARS = 6;
export const MAX_AVATARS_COMPACT = 10;

export type AvatarUser = {
  email: string;
  firstName: string;
  lastName: string;
  dashed: boolean;
};

export type AvatarsListProps = {
  users: AvatarUser[];
} & StackProps;

export const AvatarsList = ({ users, sx, ...props }: AvatarsListProps) => {
  const renderAvatars = () =>
    users.map(({ firstName, lastName, email, dashed }) => (
      <ColorizedAvatar user={{ firstName, lastName, email }} dashed={dashed} />
    ));

  return (
    <Stack direction="row" gap={1} sx={{ overflow: "hidden", ...sx }} {...props}>
      {users.length > MAX_AVATARS ? (
        <StyledAvatarGroup total={users.length} max={MAX_AVATARS_COMPACT}>
          {renderAvatars()}
        </StyledAvatarGroup>
      ) : (
        renderAvatars()
      )}
    </Stack>
  );
};

const StyledAvatarGroup = styled(AvatarGroup)({
  "& .MuiAvatar-root:not([aria-label])": {
    width: 32,
    height: 32,
    border: "none !important",
    fontSize: 16,
    fontWeight: "bold",
    zIndex: 1,
    position: "relative",
    color: "#fafafa",
  },
});
