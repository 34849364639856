import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { InputLabel } from "@mui/material";
import { useSnackbar } from "notistack";
import { AuthCard } from "../../layout/auth-card";
import { FormRow } from "../../layout/form-row";
import { BackButton } from "../../layout/back";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { referralProgramApi } from "@src/pages/referral-program";

const useReferralCodeForm = () => {
  return useForm<{ referralCode: string }>({
    defaultValues: {
      referralCode: "",
    },
  });
};

export const ReferralSignUpForm = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const referralCodeForm = useReferralCodeForm();
  const [getCodeAvailability] = referralProgramApi.useLazyGetCodeAvailabilityQuery();
  const referralCode = referralCodeForm.watch("referralCode");
  const [loading, setLoading] = useState(false);

  const signUp = async () => {
    setLoading(true);

    try {
      const { code_exists } = await getCodeAvailability({ code: referralCode }).unwrap();
      setLoading(false);
      if (!code_exists) {
        return snackbar.enqueueSnackbar("Invalid referral code", {
          persist: true,
          variant: "error",
        });
      }
    } catch (error) {
      setLoading(false);
    }

    navigate(`/auth/register_via_invite_link?referral_code=${referralCode}`);
  };

  return (
    <AuthCard sx={{ minHeight: 300 }} logo>
      <FormRow>
        <InputLabel>Referral Code</InputLabel>
        <Controller
          control={referralCodeForm.control}
          name="referralCode"
          render={({ field }) => <AnbotoTextField {...field} size="large" placeholder="Enter a valid referral code" />}
        />
      </FormRow>
      <AnbotoButton
        sx={{ minWidth: "max-content", mt: 3 }}
        variant={!referralCode.length ? "outlined" : "contained"}
        disabled={!referralCode.length}
        onClick={signUp}
        loading={loading}
        size="large"
      >
        Sign Up
      </AnbotoButton>
      <BackButton title="Back to login" to="/login" />
    </AuthCard>
  );
};
