import { getTradingDurationFromMs } from "../order-form/utils";
import { getExpirationTimeFromMs } from "../order-form/fields/ExpirationTimeSelect";
import { MAIN_ACCOUNT_VALUE } from "../constants";
import {
  CefiExchangeId,
  CefiExchangeMarketType,
  OrderExecutionStrategy,
  OrderSide,
  OrderTradingDurationUnit,
  ParentOrder,
} from "@src/store/apis/anbotoApi/types";
import { ParentOrderFormFieldValues } from "@src/pages/cefi/order-form/hooks/use-parent-order-form";

export const getOrderFormDataFromParentOrder = (
  order: ParentOrder,
  exchanges: Record<CefiExchangeId, string>,
  defaultValues: Partial<ParentOrderFormFieldValues>
) => {
  let durationUnits = OrderTradingDurationUnit.HOURS;
  let duration = 0;

  if (order.params?.duration_seconds) {
    const preparedDuration = getTradingDurationFromMs(order.params.duration_seconds * 1000);
    duration = preparedDuration.duration;
    durationUnits = preparedDuration.durationUnits;
  }

  const { params, limit_price, expiration_time, start_time } = order;
  const { placement_infos, trading_style, trigger, would, pov_ratio, pov_risk, reduce_only, urgency } = params || {};

  const symbol = {
    symbol: order.symbol!,
    exchange: exchanges[order.exchange] || order.exchange,
    market_type: order.asset_class!.toLowerCase() as CefiExchangeMarketType,
  };

  const fieldValues: Partial<ParentOrderFormFieldValues> = {
    ...defaultValues,
    symbol,
    account: order.subaccount || MAIN_ACCOUNT_VALUE,
    side: order.side!.toLowerCase() as OrderSide,
    strategy: order.strategy!.toLowerCase() as OrderExecutionStrategy,
    quantity: order.quantity!.toString(),
    tradingStyle: trading_style,
    clipSizeType: order.clip_size_type,
    ...(order.clip_size_val ? { clipSizeValue: order.clip_size_val.toString() } : {}),
    ...(expiration_time ? { expirationTime: getExpirationTimeFromMs(start_time, expiration_time) } : {}),
    ...(limit_price ? { limitPrice: limit_price.toString() } : {}),

    ...(would?.would_style ? { wouldStyle: would.would_style } : {}),
    ...(would?.would_price ? { wouldPrice: would.would_price.toString() } : {}),
    ...(would?.would_pct ? { wouldPct: would.would_pct.toString() } : {}),

    ...(placement_infos?.placement_mode ? { placementMode: placement_infos.placement_mode } : {}),
    ...(placement_infos?.placement ? { placement: placement_infos.placement.toString() } : {}),
    ...(placement_infos?.cancel ? { placementCancel: placement_infos.cancel.toString() } : {}),

    ...(trigger?.trigger_condition ? { triggerCondition: trigger.trigger_condition } : {}),
    ...(trigger?.trigger_price ? { triggerPrice: trigger.trigger_price.toString() } : {}),
    povRisk: pov_risk,
    ...(pov_ratio ? { povRatio: pov_ratio.toString() } : {}),
    reduceOnly: reduce_only,
    ...(urgency ? { urgency } : {}),
  };

  if (durationUnits) fieldValues.tradingDurationUnit = durationUnits;
  if (duration) fieldValues.tradingDuration = `${duration}`;

  return fieldValues;
};
