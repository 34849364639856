import React from "react";
import { Stack, StackProps, Typography, styled } from "@mui/material";
import { Copyright } from "./copyright";
import { ReactComponent as Logo } from "@src/pages/auth/assets/auth-logo.svg";

export const AuthCard = ({
  children,
  title,
  logo,
  ...props
}: React.PropsWithChildren<{ title?: string; logo?: boolean } & StackProps>) => {
  return (
    <Root {...props}>
      {logo && (
        <Stack>
          <Logo />
        </Stack>
      )}
      {title && (
        <Typography mt={logo ? 6 : 0} variant="h6">
          {title}
        </Typography>
      )}
      <Stack width="100%" mt={logo || title ? 6 : 0}>
        {children}
      </Stack>

      <Copyright width="100%" mt={5} justifyContent="flex-end" flex={1} />
    </Root>
  );
};

export const Root = styled(Stack)(({ theme }) => ({
  background: "#031116",
  borderRadius: theme.shape.borderRadius,
  width: 470,
  minHeight: 635,
  padding: "60px 70px 40px",
  margin: "auto",
  alignItems: "center",
  position: "relative",
  flexShrink: 0,
}));
