import React from "react";
import { Box, Typography } from "@mui/material";
import { CurrencyValue } from "@src/pages/portfolio";

const ChartTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box sx={{ background: "#3B4043", padding: 1, borderRadius: 1 }}>
        <Typography fontSize={12} fontWeight={400} color="#8A9296">{`${payload[0].name}`}</Typography>
        <Typography fontSize={12} fontWeight={400}>
          Value: <CurrencyValue value={payload[0].value} />
        </Typography>
      </Box>
    );
  }

  return null;
};

export default ChartTooltip;
