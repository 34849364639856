import * as React from "react";
import _isEqual from "lodash/isEqual";
import {
  widget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  IChartingLibraryWidget,
  ResolutionString,
  IBasicDataFeed,
  ThemeName,
  EntityId,
  DrawingEventType,
} from "@src/charting_library";
import { DefiDataFeed } from "@src/pages/defi/defi-chart/defi-data-feed/defi-datafeed";
import { getTimezone } from "@src/pages/cefi/order-symbol-chart/helpers";
import { TvLine, TvLines } from "@src/pages/cefi/order-symbol-chart/TradingViewChart";

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];
  datafeed: IBasicDataFeed;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
  container: ChartingLibraryWidgetOptions["container"];
  theme?: ThemeName;
  onReady?: () => void;
  isLineChart: boolean;
  lines?: TvLines;
  onLineMove?: (key: string, value: number) => void;
  setCurrentTokenPrice?: (currentTokenPrice: number) => void;
  // configuration: Partial<DatafeedConfiguration>;
}

export interface ChartContainerState {
  ready: boolean;
}

const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(location.search);
  return results === null ? null : (decodeURIComponent(results[1].replace(/\+/g, " ")) as LanguageCode);
};

const DEFAULT_TRADINGVIEW_INTERVAL = "15" as ResolutionString;
const DEFAULT_DEFI_PRECISION = 100;

const getDataFeedConfiguration = () => ({
  supported_resolutions: ["1", "5", "15", "1H", "4H", "1D"] as ResolutionString[],
});

export function getDefiResolutions(isLineChart: boolean, defaultResolutions: ResolutionString[]) {
  if (isLineChart) {
    return ["1D"] as ResolutionString[];
  }

  return defaultResolutions;
}

const userTimezone = getTimezone();

export class TradingViewChart extends React.Component<Partial<ChartContainerProps>, ChartContainerState> {
  public static defaultProps: Omit<
    ChartContainerProps,
    "container" | "datafeed" | "symbol" | "configuration" | "isLineChart"
  > = {
    interval: DEFAULT_TRADINGVIEW_INTERVAL as ResolutionString,
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  private chartLines = {};
  private chartOrder: any = null;
  private tvWidget: IChartingLibraryWidget | null = null;
  private ref: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: ChartContainerProps) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  componentDidMount(): void {
    if (!this.ref.current) {
      return;
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this.props.symbol as string,
      datafeed: new DefiDataFeed({
        ...getDataFeedConfiguration(),
      }),
      interval: this.props.interval as ChartingLibraryWidgetOptions["interval"],
      timezone: userTimezone,
      container: this.ref.current,
      library_path: this.props.libraryPath as string,
      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "timeframes_toolbar",
        "header_saveload",
        "header_compare",
        "header_undo_redo",
        "volume_force_overlay",
      ],
      enabled_features: [
        // 'study_templates',
        "hide_left_toolbar_by_default",
      ],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      theme: this.props.theme,
      custom_css_url: "../tv.css",
      loading_screen: {
        backgroundColor: "#131A1C",
      },
      overrides: {
        "scalesProperties.textColor": "#8A9296",
        "paneProperties.vertGridProperties.color": "#192022",
        "paneProperties.horzGridProperties.color": "#192022",
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": "#131A1C",
        "mainSeriesProperties.candleStyle.upColor": "#179B83",
        "mainSeriesProperties.candleStyle.downColor": "#C3462B",
        "mainSeriesProperties.candleStyle.borderUpColor": "#179B83",
        "mainSeriesProperties.candleStyle.borderDownColor": "#C3462B",
        "mainSeriesProperties.candleStyle.wickUpColor": "#179B83",
        "mainSeriesProperties.candleStyle.wickDownColor": "#C3462B",
        "mainSeriesProperties.minTick": `${DEFAULT_DEFI_PRECISION},1,false`,
      },
      studies_overrides: {
        "volume.volume.color.0": "#C3462B",
        "volume.volume.color.1": "#179B83",
        ...this.props.studiesOverrides,
      },
    };

    const tvWidget = new widget(widgetOptions);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // const tvWidget = new window.TradingView.widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.chart().getPanes()[1]?.setHeight(80);
      tvWidget.headerReady().then(() => {
        tvWidget?.subscribe("drawing_event", this.handleShapeEvents);
        this.setState({ ready: true });
        this.props.onReady && this.props.onReady();

        this.updateChart(this.props.symbol!, this.props.interval!, this.props.lines);

        this.tvWidget?.activeChart().dataReady(() => {
          this.tvWidget
            ?.activeChart()
            .exportData()
            .then(
              (x) => this.props.setCurrentTokenPrice && this.props.setCurrentTokenPrice(x.data[x.data.length - 1][4])
            );
        });
      });
    });
  }

  componentWillUnmount(): void {
    if (this.tvWidget !== null) {
      this.tvWidget?.unsubscribe("drawing_event", this.handleShapeEvents);
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  public defaultInterval = this.props.interval;

  UNSAFE_componentWillReceiveProps({ symbol, isLineChart, interval, lines }) {
    if (!symbol || !this.state.ready) return;

    if (this.props.isLineChart !== isLineChart) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this?.tvWidget?.chart().setChartType(isLineChart ? 3 : 1, () => {});
    }

    if (symbol !== this.props.symbol || lines !== this.props.lines) {
      this.updateChart(symbol, interval, lines);
    }

    this.tvWidget?.activeChart().dataReady(() => {
      this.tvWidget
        ?.activeChart()
        .exportData()
        .then((x) => this.props.setCurrentTokenPrice && this.props.setCurrentTokenPrice(x.data[x.data.length - 1][4]));
    });
  }

  updateChart = (symbol: string, interval: ResolutionString, lines) => {
    setTimeout(() => {
      let resolution;

      if (this.defaultInterval !== interval) {
        this.defaultInterval = interval;
        resolution = interval;
      } else {
        resolution = this.tvWidget?.activeChart()?.resolution() || DEFAULT_TRADINGVIEW_INTERVAL;
      }
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.tvWidget?.setSymbol(symbol, resolution, () => {
        this.updateLines(lines, true);
      });
      this.updateLines(lines, true);
    });
  };

  public updateLines = (lines: TvLines = {}, reset?: boolean) => {
    const prevLines = this.props.lines || {};
    // check if order line still exists in props, remove order line if no
    if (this.chartOrder) {
      this.chartOrder?.remove();
      this.chartOrder = null;
    }

    // remove chart lines not existing in new props
    Object.keys(this.chartLines).forEach((key) => {
      try {
        this.chartLines[key] && this.tvWidget?.activeChart().getShapeById(this.chartLines[key]);
      } catch (error) {
        delete this.chartLines[key];
      }

      if (this.chartLines[key] && (!lines[key] || reset)) {
        try {
          this.tvWidget?.activeChart().removeEntity(this.chartLines[key], { disableUndo: true });
        } catch (err) {
          console.log(err);
        }
        delete this.chartLines[key];
      }
    });

    Object.keys(lines).forEach((key) => {
      if (!_isEqual(prevLines[key], lines[key]) || reset) {
        this.updateLine(key, lines[key]);
      }
    });
  };

  public updateLine = (key: string, { value, label, options, linestyle, quantity }: TvLine) => {
    if (key === "limitPrice") {
      if (!this.chartOrder) {
        const line = this.tvWidget
          ?.activeChart()
          .createOrderLine()
          .setEditable(true)
          .setText(label)
          .setBodyBorderColor(options?.linecolor || "")
          .setBodyTextColor(options?.textcolor || "")
          .setQuantity(quantity || "")
          .setQuantityTextColor(options?.textcolor || "")
          .setQuantityBackgroundColor(options?.linecolor || "")
          .setQuantityBorderColor(options?.linecolor || "")
          .setPrice(value)
          .setLineColor(options?.linecolor || "")
          .onMove(() => {
            const price = this.chartOrder._line._points[0].price;
            this.props.onLineMove && this.props.onLineMove("limitPrice", price);
          });
        this.chartOrder = line;
      } else {
        this.chartOrder
          .setPrice(value)
          .setText(label)
          .setBodyBorderColor(options?.linecolor || "")
          .setBodyTextColor(options?.textcolor || "")
          .setQuantity(quantity || "")
          .setQuantityTextColor(options?.textcolor || "")
          .setQuantityBackgroundColor(options?.linecolor || "")
          .setQuantityBorderColor(options?.linecolor || "")
          .setLineColor(options?.linecolor || "");
      }
    } else {
      if (!this.chartLines[key]) {
        const id = this.tvWidget?.activeChart().createShape(
          { time: 0, price: value },
          {
            shape: "horizontal_line",
            disableSelection: true,
            text: label,
            overrides: {
              showLabel: "true",
              horzLabelsAlign: "left",
              ...(linestyle ? { linestyle } : {}),
              ...options,
            },
          }
        );
        this.chartLines[key] = id;
      } else {
        this.tvWidget
          ?.activeChart()
          .getShapeById(this.chartLines[key])
          ?.setPoints([{ time: 0, price: Number(value) }]);
      }
    }
  };

  public handleShapeEvents = (id: EntityId, type: DrawingEventType) => {
    const linesById = {};

    for (const key in this.chartLines) {
      linesById[this.chartLines[key]] = key;
    }

    if (type === "move" && linesById[id]) {
      setTimeout(() => {
        const value = this.tvWidget?.activeChart().getShapeById(id).getPoints()[0]?.price || 0;
        this.props.onLineMove && this.props.onLineMove(linesById[id], value);
      });
    }
  };

  public render(): JSX.Element {
    return <div ref={this.ref} style={{ height: "100%" }} />;
  }
}
