import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";

export interface LeverageState {
  open?: boolean;
  x: number;
  y: number;
  accountId: string;
  symbol: OrderSymbol | null;
  leverage: number;
  reset?: number;
}

const initialState: LeverageState = {
  open: false,
  x: -1000,
  y: -1000,
  accountId: "",
  symbol: null,
  leverage: 1,
};

export const leverageSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    openLeverageModal: (state, action: PayloadAction<Omit<LeverageState, "open">>) => {
      return {
        ...state,
        ...action.payload,
        open: true,
        reset: +new Date(),
      };
    },
    closeLeverageModal: () => {
      return initialState;
    },
  },
});

export const { openLeverageModal, closeLeverageModal } = leverageSlice.actions;

export default leverageSlice.reducer;
