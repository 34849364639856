import React from "react";
import { Stack, StackProps, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { formatUsd } from "@src/utils/format";

type MissingCreditsProps = {
  creditsPrediction: number;
};

export const MissingCredits = ({ creditsPrediction, ...props }: MissingCreditsProps & StackProps) => {
  const navigate = useNavigate();

  const missingCredits = -creditsPrediction > 0.01 ? formatUsd(-creditsPrediction) : "$0.01";

  return (
    <Stack gap={2} direction="row" alignItems="center" {...props}>
      <Typography variant="body2" color={(theme) => theme.palette.error.main} fontWeight="bold">
        {missingCredits} missing in fee wallet balance to post this order
      </Typography>
      <Stack>
        <AnbotoButton sx={{ width: 100 }} size="small" variant="contained" onClick={() => navigate("/settings/fees")}>
          <Typography noWrap sx={{ fontSize: 14 }}>
            Top Up
          </Typography>
        </AnbotoButton>
      </Stack>
    </Stack>
  );
};
