import { useMemo } from "react";
import { useGetParentDefiOrdersQuery } from "@src/store/apis/anbotoApi/defi";
import { DefaultStatusFilter } from "@src/components/orders-table-filters/types";
import { ALL_AGGREGATORS_PARAMETER } from "@src/store/apis/anbotoApi/constants";
import { OrdersCurrentTab, GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";
import { useAppSelector } from "@src/store/hooks";
import { ORDERS_LIMIT } from "@src/store/constants";
import { ankr } from "@src/store/apis/anbotoApi/ankr";

// The purpose of this hook is to monitor any updates or executed quantity coming from the websocket
// in this way we can trigger updates for available quantities displayed in the trading pannel
export const useLiveExecutedQuantity = () => {
  const team_uuid = useAppSelector((state) => state.user.team_uuid);
  const params = {
    accountId: team_uuid,
    limit: ORDERS_LIMIT,
    currentTab: OrdersCurrentTab.Regular,
    period: GetOrdersPeriod.All,
    status: DefaultStatusFilter.All,
    exchange_list_ids: ALL_AGGREGATORS_PARAMETER,
  };

  const [getUserTokensList, { isFetching: tokenBalanceslistIsFetching }] = ankr.useLazyGetUserTokensListQuery();

  const { data, isFetching: execIsFetching, isError } = useGetParentDefiOrdersQuery(params);
  const executedQuantity = useMemo(() => {
    if (!execIsFetching && !isError && data) {
      const executedQuantity = data.reduce((acc, order) => {
        const filledQuantity = order.last_status?.filled_quantity || 0;
        return acc + filledQuantity;
      }, 0);

      return executedQuantity;
    } else {
      return 0;
    }
  }, [data, execIsFetching]);

  return { executedQuantity, execIsFetching, getUserTokensList, tokenBalanceslistIsFetching };
};
