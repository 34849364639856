import { ExpirationTime } from "@src/pages/cefi/order-form/fields/ExpirationTimeSelect";
import { DefiOrderExpiration, OrderPreferencesExpirations } from "@src/store/apis/anbotoApi/types";

export const DEFAULT_ORDER_PREFERENCE_EXPIRATION = "604800";

export const CefiExpirationTimeValues: Record<ExpirationTime, OrderPreferencesExpirations> = {
  ["1week"]: OrderPreferencesExpirations.WEEK,
  ["1month"]: OrderPreferencesExpirations.MONTH,
  ["6months"]: OrderPreferencesExpirations.SIX_MONTH,
};

export const DefiExpirationTimeValues: Record<DefiOrderExpiration, OrderPreferencesExpirations> = {
  [DefiOrderExpiration.GTC]: OrderPreferencesExpirations.GTC,
  [DefiOrderExpiration.DAY]: OrderPreferencesExpirations.DAY,
  [DefiOrderExpiration.WEEK]: OrderPreferencesExpirations.WEEK,
  [DefiOrderExpiration.MONTH]: OrderPreferencesExpirations.MONTH,
  [DefiOrderExpiration.HALF_YEAR]: OrderPreferencesExpirations.SIX_MONTH,
};

export const DEFAULT_DEFI_EXTEND_DURATION = true;
