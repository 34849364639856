import React from "react";
import { DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { CardDialog } from "@src/components/CardDialog";

type ReviewDialogProps = DialogProps & {
  onSubmit(): void;
  onClose(): void;
  isOrderConfirmLoading?: boolean;
};

export const ReviewDialog = ({
  children,
  onSubmit,
  onClose,
  isOrderConfirmLoading,
  ...props
}: React.PropsWithChildren<ReviewDialogProps>) => {
  return (
    <CardDialog {...props}>
      <DialogTitle sx={{ py: 0.5 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold">Order summary</Typography>
          <IconButton onClick={onClose} size="small">
            <Close sx={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flex: 1, position: "relative" }}>
        <Stack gap={2} flex={1} mt={1.5}>
          {children}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 1.5, pt: 0, pb: 1.5 }}>
        <Stack direction="row" justifyContent="space-between" width="100%" gap={0.5}>
          <AnbotoButton variant="outlined" color="secondary" size="small" onClick={onClose}>
            Edit
          </AnbotoButton>

          <AnbotoButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onSubmit()}
            loading={isOrderConfirmLoading}
          >
            Confirm
          </AnbotoButton>
        </Stack>
      </DialogActions>
    </CardDialog>
  );
};
