import React from "react";
import { Grid, Stack, Typography, styled } from "@mui/material";
import { Reward } from "../types";
import DashboardDataCardHeader from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/dashboard-data-card-header";

export const Rewards = ({ column, rewards, title }: { column?: boolean; rewards: Reward[]; title?: string }) => {
  return (
    <>
      <DashboardDataCardHeader fontWeight={500} fontSize={16} header={title || "Rewards"} />
      <Grid container={column ? false : true} spacing={1} columns={3} gridTemplateRows="3">
        {rewards.map((reward) => (
          <Grid item py={column ? 1 : 0}>
            <RewardsCard>
              <Stack
                px={reward.headerColor === "transparent" ? 0 : 0.5}
                py={0.25}
                sx={{
                  background: reward.headerColor,
                  color: reward.headerColor === "transparent" ? "white" : "black",
                  fontWeight: 500,
                }}
              >
                {reward.header}
              </Stack>
              {reward?.prizePoolPercent && (
                <Typography fontSize={14} fontWeight={400} color="#B0BCC2">
                  {reward?.prizePoolPercent}% of Prize pool
                </Typography>
              )}
              {reward.minVolume && (
                <Typography fontSize={14} fontWeight={400} color="#B0BCC2">
                  Min Volume {reward.minVolume}
                </Typography>
              )}
            </RewardsCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const RewardsCard = styled(Stack)(() => ({
  border: "1px solid #3B4043",
  padding: 12,
  borderRadius: 4,
  flexDirection: "column",
  gap: 8,
  minWidth: 140,
}));
