import { useCallback, useEffect, useState } from "react";
import _debounce from "lodash/debounce";
import { useAppSelector } from "@src/store/hooks";
import { GECKOTERMINAL_CHAIN_IDS } from "@src/store/apis/geckoterminal-api/constants";
import { geckoTerminalApi } from "@src/store/apis/geckoterminal-api";
import { ChainId } from "@src/pages/defi/types";
import { feeManagerApi } from "@src/pages/settings/fee-manager/api";

type PostTradeCreditsDefiProps = {
  quantity: string;
  chainId: ChainId;
  fromTokenAddress: string;
};

export const usePostTradeCreditsDefi = ({ quantity, chainId, fromTokenAddress }: PostTradeCreditsDefiProps): number => {
  const team_uuid = useAppSelector((state) => state.user.team_uuid);
  const [postTradeCredits, setPostTradeCredits] = useState<number>(0);
  const accountStats = feeManagerApi.useGetFeeManagerAccountStatsQuery({ team_uuid: team_uuid });
  const tokensAndBalances = feeManagerApi.useGetFeeTokensAndBalancesQuery();
  const [getTop20TokenPools] = geckoTerminalApi.useLazyGetTop20TokenPoolsQuery();
  const feeManagerEnabled = useAppSelector((state) => state.user.fee_manager_enabled);

  const refetchPostCredits = async (tokensAndBalances, quantity, chainId, fromTokenAddress, accountStats) => {
    try {
      // accounts stats
      const feeRate = accountStats?.data?.fee_rates;
      const creditsBalance = tokensAndBalances?.data?.balance || 0;

      let price;
      if (chainId) {
        const terminalResult = await getTop20TokenPools({
          network: GECKOTERMINAL_CHAIN_IDS[chainId],
          token_address: fromTokenAddress,
        }).unwrap();
        price = terminalResult?.data[0]?.attributes.token_price_usd || 0;
      }

      const newCreditsRequired = creditsBalance - parseFloat(quantity) * price * feeRate;
      setPostTradeCredits(newCreditsRequired);
    } catch (error) {
      console.log("refetchPostCredits error", error);
    }
  };

  const debouncedRefetchPostCredits = useCallback(
    _debounce(
      (tokensAndBalances, quantity, chainId, fromTokenAddress, accountStats) =>
        refetchPostCredits(tokensAndBalances, quantity, chainId, fromTokenAddress, accountStats),
      500
    ),
    []
  );

  useEffect(() => {
    if (feeManagerEnabled) {
      void debouncedRefetchPostCredits(tokensAndBalances, quantity, chainId, fromTokenAddress, accountStats);
    }
  }, [tokensAndBalances, quantity, chainId, fromTokenAddress, accountStats]);

  return postTradeCredits;
};
