import { AccountSubsctiption } from "./anboto-account-subscription";

const subscriptions: Record<string, AccountSubsctiption> = {};

export const getAccountSubscription = (accountId: string) => {
  if (!accountId) throw new Error("Account subscription: accountId is not specified");

  if (!subscriptions[accountId]) {
    subscriptions[accountId] = new AccountSubsctiption({
      url: `/stream/account_uuid/${accountId}/`,
      auth: true,
    });
  }

  return subscriptions[accountId];
};
