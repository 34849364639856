import React from "react";
import { Stack, Typography } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BigNumber from "bignumber.js";

type PriceChangeProps = {
  priceChange: string;
};

const PriceChange = ({ priceChange }: PriceChangeProps) => {
  return (
    <Stack direction="row" alignItems="center">
      <Stack
        sx={{ color: (theme) => theme.palette.text.secondary, fontSize: 16, fontWeight: 400 }}
        alignItems="center"
        direction="row"
        gap={0}
      >
        {new BigNumber(priceChange).gt(0) ? (
          <Stack sx={{ color: (theme) => theme.custom.colors.secondary }} alignItems="center" direction="row" gap={0}>
            <ArrowDropUpIcon />
            <Typography fontSize={12}>{priceChange}%</Typography>
          </Stack>
        ) : (
          <Stack sx={{ color: (theme) => theme.palette.error.main }} alignItems="center" direction="row" gap={0}>
            <ArrowDropDownIcon />
            <Typography fontSize={12}>{priceChange}%</Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default PriceChange;
