import { createSlice } from "@reduxjs/toolkit";

export interface SingleExchangePageSlice {
  orderFormReviewButtonDisabled: boolean;
  isOrderCopy: boolean;
}

const initialState: SingleExchangePageSlice = {
  orderFormReviewButtonDisabled: false,
  isOrderCopy: false,
};

export const singleExchangePageSlice = createSlice({
  name: "singleExchangePage",
  initialState,
  reducers: {
    setOrderFormReviewButtonDisabled: (state, action) => {
      state.orderFormReviewButtonDisabled = action.payload;
    },
    setIsOrderCopy: (state, action) => {
      state.isOrderCopy = action.payload;
    },
  },
});

export const { setIsOrderCopy, setOrderFormReviewButtonDisabled } = singleExchangePageSlice.actions;

export default singleExchangePageSlice.reducer;
