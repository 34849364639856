import React, { FC } from "react";
import { Stack, Typography, Skeleton } from "@mui/material";
import { CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";
import { AnbotoButtonGroupOption, ButtonGroup } from "@src/components/ui/anboto-button-group";
import { CurrencyValue } from "@src/pages/portfolio";
import { leverageApi } from "@src/features/leverage/api";
import { useAppSelector } from "@src/store/hooks";
import { digitsAfterZeros, formatUsd } from "@src/utils/format";

export interface MarketTypeSelectorProps {
  futureAmount: number;
  marketType: CefiExchangeMarketType;
  setMarketType: (marketType: CefiExchangeMarketType) => void;
  spotAmount: number;
  onHideSmallBalance: (isHideSmallBalance: boolean) => void;
}

export const MarketTypeSelector: FC<MarketTypeSelectorProps> = ({
  futureAmount,
  marketType,
  setMarketType,
  spotAmount,
  onHideSmallBalance,
}) => {
  const marketTypeFilterOptions: AnbotoButtonGroupOption<CefiExchangeMarketType>[] = [
    { label: `Spot`, value: CefiExchangeMarketType.SPOT },
    { label: `Future`, value: CefiExchangeMarketType.FUTURE },
  ];
  const balance = marketType === CefiExchangeMarketType.SPOT ? spotAmount : futureAmount;
  const accountId = useAppSelector((state) => state.user.team_uuid);
  const subaccount = useAppSelector((state) => state.portfolio.activeCefiAllocationItem);
  const exchangeId = useAppSelector((state) => state.portfolio.selectedExchange);

  const accountMarginInfo = leverageApi.useGetAccountMarginInfosQuery(
    {
      exchangeId,
      marketType,
      subaccount: subaccount === "main" ? undefined : subaccount,
      accountId,
    },
    { skip: !exchangeId }
  );

  const accountEquity = accountMarginInfo.data?.data?.account_equity || 0;
  const maintenanceMargin = accountMarginInfo.data?.data?.maintenance_margin || 0;
  const marginRatio = accountMarginInfo.data?.data?.margin_ratio || 0;

  const onChange = (marketType: CefiExchangeMarketType) => {
    onHideSmallBalance(marketType === CefiExchangeMarketType.SPOT);
    setMarketType(marketType);
  };

  return (
    <Stack display="flex" flexDirection="row" alignItems="center" gap={2}>
      <ButtonGroup<CefiExchangeMarketType>
        size="small"
        options={marketTypeFilterOptions}
        onChange={onChange}
        value={marketType}
      />
      {(marketType === CefiExchangeMarketType.SPOT || exchangeId === "all") && (
        <Stack>
          <Typography variant="subtitle1" color="text.secondary" fontSize={12}>
            {marketType === CefiExchangeMarketType.SPOT ? "Spot" : "Future"} balance:
          </Typography>
          <Typography variant="body2" minWidth={100} textAlign="left" fontWeight={600} fontSize={14}>
            ≈ <CurrencyValue value={balance} />
          </Typography>
        </Stack>
      )}
      {exchangeId && exchangeId !== "all" && marketType === CefiExchangeMarketType.FUTURE && (
        <>
          <Stack>
            <Typography variant="subtitle1" color="text.secondary" fontSize={12}>
              Account Equity
            </Typography>
            <Typography variant="body2" minWidth={100} textAlign="left" fontWeight={600} fontSize={14}>
              {accountMarginInfo.isFetching ? (
                <Skeleton animation="wave" />
              ) : (
                <Stack alignItems="center" direction="row" gap={2}>
                  {!accountMarginInfo.data?.data?.account_equity ? "-" : formatUsd(accountEquity)}
                </Stack>
              )}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1" color="text.secondary" fontSize={12}>
              Maintenance Margin
            </Typography>
            <Typography variant="body2" minWidth={100} textAlign="left" fontWeight={600} fontSize={14}>
              {accountMarginInfo.isFetching ? (
                <Skeleton animation="wave" />
              ) : (
                <Stack alignItems="center" direction="row" gap={2}>
                  {!accountMarginInfo.data?.data?.maintenance_margin ? "-" : formatUsd(maintenanceMargin)}
                </Stack>
              )}
            </Typography>
          </Stack>
          <Stack>
            <Typography variant="subtitle1" color="text.secondary" fontSize={12}>
              Margin Ratio
            </Typography>
            <Typography variant="body2" minWidth={100} textAlign="left" fontWeight={600} fontSize={14}>
              {accountMarginInfo.isFetching ? (
                <Skeleton animation="wave" />
              ) : (
                <Stack alignItems="center" direction="row" gap={2}>
                  {!accountMarginInfo.data?.data?.margin_ratio ? "-" : digitsAfterZeros(marginRatio * 100) + "%"}
                </Stack>
              )}
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
};
