export function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(email: string, name: string, color: string, dashed?: boolean) {
  return {
    sx: {
      width: dashed ? 30 : 32,
      height: dashed ? 30 : 32,
      fontSize: 16,
      fontWeight: 600,
      color: dashed ? "#8A9296" : "#fafafa",
      border: dashed ? `1px dashed #fafafa !important` : `none !important`,
      backgroundColor: dashed ? "#232C2F" : color,
    },
    children: `${(name || email)[0]}`.toUpperCase(),
  };
}
