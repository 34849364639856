import React, { FC } from "react";
import { Modal } from "@mui/material";
import { ModalContent } from "./styled";
import { ModalWithRainbow } from "./modal-with-rainbow";

export interface SendCryptoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SendCryptoModal: FC<SendCryptoModalProps> = ({ onClose, isOpen }) => (
  <Modal open={isOpen} disableRestoreFocus={true} disableAutoFocus={true} disableEscapeKeyDown={true}>
    <ModalContent>
      <ModalWithRainbow onClose={onClose} />
    </ModalContent>
  </Modal>
);
