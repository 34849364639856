import { PlatformOrdersSubsctiption } from "./anboto-platform-orders-subscription";

const platformOrdersSubscription: { current: null | PlatformOrdersSubsctiption } = {
  current: null,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// window.sub = platformOrdersSubscription;

export const getPlatformOrdersSubscription = () => {
  if (!platformOrdersSubscription.current) {
    platformOrdersSubscription.current = new PlatformOrdersSubsctiption({
      url: "/stream/order_admin/",
      auth: true,
    });
  }

  return platformOrdersSubscription.current;
};
