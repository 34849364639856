import { useCallback, useEffect, useMemo, useState } from "react";
import _throttle from "lodash/throttle";
import { getMarketDataSubscription } from "../index";
import { MarketDataSubscriptionType } from "../types";
import { prepareExchange } from "@src/pages/cefi/order-symbol-chart/anboto-datafeed/helpers";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";
import { CefiExchangeId, OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { getExchangeIdByExchangeName } from "@src/store/apis/anbotoApi/utils";

type Msg = Record<string, any>;

export type OrderbookOrders = Array<Array<number>>;

export type OrderbookState = {
  asks: OrderbookOrders;
  bids: OrderbookOrders;
  timestamp: number;
};

const marketDataSubscription = getMarketDataSubscription();

const DEFAULT_ORDERBOOK_DEPTH = 20;
const ORDERBOOK_DEPTH: Record<CefiExchangeId, number> = {
  [CefiExchangeId.BINANCE]: DEFAULT_ORDERBOOK_DEPTH,
  [CefiExchangeId.BYBIT]: 50,
  [CefiExchangeId.COINBASE]: DEFAULT_ORDERBOOK_DEPTH,
  [CefiExchangeId.GATEIO]: DEFAULT_ORDERBOOK_DEPTH,
  [CefiExchangeId.KRAKEN]: 25,
  [CefiExchangeId.KUCOIN]: DEFAULT_ORDERBOOK_DEPTH,
  [CefiExchangeId.OKX]: DEFAULT_ORDERBOOK_DEPTH,
  [CefiExchangeId.ASCENDEX]: DEFAULT_ORDERBOOK_DEPTH,
  [CefiExchangeId.HUOBI]: 150,
  [CefiExchangeId.BITMEX]: DEFAULT_ORDERBOOK_DEPTH,
  [CefiExchangeId.WOOX]: DEFAULT_ORDERBOOK_DEPTH,
  [CefiExchangeId.BITGET]: DEFAULT_ORDERBOOK_DEPTH,
  [CefiExchangeId.MEXC]: 50,
  [CefiExchangeId.B2C2]: DEFAULT_ORDERBOOK_DEPTH,
};

const useAnbotoOrderbookSubscription = (symbol?: OrderSymbol) => {
  const [orderbook, setOrderbook] = useState<OrderbookState>({
    asks: [],
    bids: [],
    timestamp: +new Date(),
  });

  const symbolDep = useMemo(
    () => (symbol ? getLivePriceId(symbol) : ""),
    [symbol?.exchange, symbol?.market_type, symbol?.symbol]
  );

  const onMessage = useCallback(
    _throttle(({ asks = [], bids = [], timestamp }: Msg) => {
      setOrderbook({
        asks,
        bids,
        timestamp,
      });
    }, 200),
    [symbol?.exchange, symbol?.market_type, symbol?.symbol]
  );

  useEffect(() => {
    if (symbol) {
      setOrderbook((state) => ({ ...state, groups: [] }));

      marketDataSubscription.subscribe(prepareData(symbol), onMessage);
    }

    return () => {
      if (symbol) {
        marketDataSubscription.unsubscribe(prepareData(symbol), onMessage);
      }
    };
  }, [symbolDep]);

  const prepareData = ({ symbol, market_type, exchange }: OrderSymbol) => {
    const exchangeId = getExchangeIdByExchangeName(exchange);

    return {
      symbol,
      market_type,
      data_type: MarketDataSubscriptionType.ORDERBOOK,
      exchange: prepareExchange(exchange),
      params: {
        limit: ORDERBOOK_DEPTH[exchangeId],
      },
    };
  };

  return orderbook;
};

export default useAnbotoOrderbookSubscription;
