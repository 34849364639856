import React, { useState } from "react";
import { Link, Stack, StackProps, styled, Typography } from "@mui/material";
import { Dots } from "react-activity";
import { useSnackbar } from "notistack";
import { ReactComponent as AppstoreLogo } from "@src/assets/svg/appstore.svg";
import { ReactComponent as PlaymarketLogo } from "@src/assets/svg/play-market.svg";
import OtpForm from "@src/pages/settings/two-fa/otp-form";
import { useAuth } from "@src/features/auth/hooks/use-auth";
import { FieldValues } from "@src/pages/settings/two-fa/index";
import { QrCode } from "@src/components/qr-code";
import { FormError } from "@src/pages/auth/layout/form-error";
import { useOtpQr } from "@src/features/auth/hooks/use-otp-qr";
import { useAppSelector } from "@src/store/hooks";

export type EnableTwoFaProps = {
  headerSx?: StackProps["sx"];
  containerSX?: StackProps["sx"];
};

const EnableTwoFa = ({ headerSx, containerSX }: EnableTwoFaProps) => {
  const snackbar = useSnackbar();

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const email = useAppSelector((state) => state.user.email);
  const otpQr = useOtpQr();
  const auth = useAuth();

  const enableMFA = async ({ two_fa_code }: FieldValues) => {
    try {
      setLoading(true);

      await auth.verifyTOTPSetup({ code: two_fa_code });
      await auth.updateMFAPreference({ totp: "ENABLED" });
      await auth.refetchSession();

      snackbar.enqueueSnackbar("Two-factor authentication has been enabled", { variant: "success" });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  console.log(email, otpQr);

  return (
    <Stack className="two-fa" display="flex" alignItems="center" flex={1} height="100%" justifyContent="center">
      <Stack width={960} gap={2} mb={7}>
        <Stack sx={headerSx} width="100%" display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h6">Enable two-factor authentication</Typography>
        </Stack>
        <Stack
          sx={containerSX}
          display="flex"
          flexDirection="row"
          gap={2}
          width="100%"
          border="1px solid #4A5053"
          padding={3}
        >
          <TwoFactorBlock>
            <Bage>Step 1</Bage>
            <Typography marginTop={3} color="white" fontSize={16} fontWeight={600}>
              Download & install
            </Typography>
            <Typography marginBottom={3} color="#8A9296" fontSize={14} fontWeight={400}>
              Google authenticator on your mobile device
            </Typography>
            <Link href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
              <AppstoreLogo />
            </Link>
            <Link
              marginTop={3}
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1"
              target="_blank"
            >
              <PlaymarketLogo />
            </Link>
          </TwoFactorBlock>
          <TwoFactorBlock>
            <Bage>Step 2</Bage>
            <Typography marginTop={3} color="white" fontSize={16} fontWeight={600}>
              Scan the QR code
            </Typography>
            <Typography marginBottom={2} color="#8A9296" fontSize={14} fontWeight={400}>
              Use your 2FA app to the scan the image below
            </Typography>
            {otpQr.loading ? (
              <div className="two-fa__loading">
                <Dots animating={true} />
              </div>
            ) : otpQr.error ? (
              <div className="two-fa__loading">
                <Typography display="block" fontWeight="400" color="error">
                  {otpQr.error}
                </Typography>
              </div>
            ) : (
              <QrCode size={190} uri={otpQr.uri} />
            )}
          </TwoFactorBlock>
          <TwoFactorBlock>
            <Bage>Step 3</Bage>
            <Typography marginTop={3} color="white" fontSize={16} fontWeight={600}>
              After scanning the QR code image
            </Typography>
            <Typography marginBottom={3} color="#8A9296" fontSize={14} fontWeight={400}>
              The app will display a 6-digit that you can enter below
            </Typography>
            <OtpForm onSubmit={enableMFA} loading={loading} />
            {error && <FormError>{error}</FormError>}
          </TwoFactorBlock>
        </Stack>
      </Stack>
    </Stack>
  );
};

const TwoFactorBlock = styled(Stack)(() => ({
  display: "flex",
  flexDirection: "column",
  width: 290,
  height: 360,
  padding: 16,
  backgroundColor: "#232C2F",
  borderRadius: 4,
  textAlign: "center",
  alignItems: "center",
}));

const Bage = styled(Stack)(() => ({
  width: 73,
  height: 29,
  backgroundColor: "#3B4043",
  borderRadius: 100,
  padding: "4px 0",
  fontSize: 14,
  fontWeight: 600,
  textAlign: "center",
}));

export default EnableTwoFa;
