import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type WouldPctFieldFieldProps = ControllerRenderProps & AnbotoTextFieldProps;

export const WouldPctFieldField = forwardRef((props: WouldPctFieldFieldProps, ref) => {
  return (
    <AnbotoTextField
      {...props}
      inputRef={ref}
      variant="outlined"
      size="small"
      label={props.label || "Pct"}
      fullWidth
      type="number"
    />
  );
});

WouldPctFieldField.displayName = "WouldPctFieldField";
