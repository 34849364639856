import { CHAIN_NATIVE_TOKENS, ChainNativeTokens } from "@src/constants/chain-native-tokens";
import { ChainId } from "@src/pages/defi/types";

export interface UseTargetNativeTokenProps {
  chainId: ChainId;
  fromTokenAddress: string;
  toTokenAddress: string;
}

export interface UseTargetNativeTokenResult {
  targetTokenFrom?: ChainNativeTokens;
  targetTokenTo?: ChainNativeTokens;
  targetToken?: ChainNativeTokens;
}

export const useTargetNativeToken = ({
  chainId,
  fromTokenAddress,
  toTokenAddress,
}: UseTargetNativeTokenProps): UseTargetNativeTokenResult => {
  const targetTokenFrom = CHAIN_NATIVE_TOKENS.find(
    (token) => token.chainId === chainId && token.tokenId === fromTokenAddress
  );
  const targetTokenTo = CHAIN_NATIVE_TOKENS.find(
    (token) => token.chainId === chainId && token.tokenId === toTokenAddress
  );
  const targetToken = targetTokenFrom || targetTokenTo;

  return {
    targetTokenFrom,
    targetTokenTo,
    targetToken,
  };
};
