import React from "react";
import { Box, CircularProgress } from "@mui/material";

export const FetchState = () => {
  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};
