import { useEffect, useState, useRef } from "react";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { useSnackbar } from "notistack";
import { useGetUserTokensQuery } from "@src/hooks/use-get-user-tokens-query";
import { useLazyGetTokensByAddressesAndChainQuery, useSearchTokenQuery } from "@src/store/apis/algoliaApi";
import { Token, TokenWithBalance } from "@src/pages/defi/types";
import { CHAIN_EMPTY_ADDRESS } from "@src/constants/empty-addresses";
import { useLazyFetchTokenQuery } from "@src/store/apis/blockchainApi";
import { EVMBasedAddress } from "@src/pages/defi/types/common";
import { saveTokens } from "@src/store/slices/tokensSlice";
import { useAppDispatch } from "@src/store/hooks";

export interface UseExternalTokensResult {
  tokens: TokenWithBalance[];
  isLoading: boolean;
}

export const useExternalTokens = ({ account, chainId, searchString, skipNative }): UseExternalTokensResult => {
  const [tokens, setTokens] = useState<TokenWithBalance[]>([]);
  const [tokensBalancesMap, setTokensBalancesMap] = useState<Record<string, TokenWithBalance>>({});
  const { data: tokensWithBalances, isLoading: isTokensWithBalancesRequestLoading } = useGetUserTokensQuery({
    account,
    chainId,
  });
  const { data: indexedTokens, isLoading: isIndexedTokensRequestLoading } = useSearchTokenQuery(
    { searchString, chainId: chainId! },
    { skip: !chainId }
  );
  const dispatch = useAppDispatch();
  const [getTokens] = useLazyGetTokensByAddressesAndChainQuery();
  const snackbar = useSnackbar();
  const [fetchToken, { isLoading: tokenIsLoading }] = useLazyFetchTokenQuery();
  const isLoading = isTokensWithBalancesRequestLoading || isIndexedTokensRequestLoading || tokenIsLoading;
  const invalidAddress = useRef<string>("");

  // if address is valid but search results are empty, this coin is new or not trusted
  const loadTokenByAddress = async (tokenAddress: EVMBasedAddress): Promise<void> => {
    if (!chainId) {
      return;
    }

    const addressLowerCase = tokenAddress.toLowerCase();

    const { data: token } = await fetchToken({ address: addressLowerCase, chainId });

    if (token && token.address && token.name && token.symbol) {
      setTokens([
        {
          ...token,
          balance: tokensBalancesMap[addressLowerCase]?.balance || tokensBalancesMap[token.symbol]?.balance,
          balanceUsd: tokensBalancesMap[addressLowerCase]?.balanceUsd || tokensBalancesMap[token.symbol]?.balanceUsd,
          trusted: false,
        },
      ]);
    } else {
      snackbar.enqueueSnackbar("Invalid address. Cannot load this token.", { variant: "error" });
    }
  };

  const fillTokensBalancesMap = async () => {
    if (!tokensWithBalances) {
      return;
    }

    if (!tokensWithBalances.length) {
      setTokensBalancesMap({});

      return;
    }

    const newTokensBalancesMap = {};

    tokensWithBalances.forEach((token) => {
      if (!token) {
        return;
      }

      const tokenAddress = skipNative
        ? token.contractAddress?.toLowerCase()
        : token.contractAddress?.toLowerCase() || token?.tokenSymbol;

      if (!tokenAddress) {
        return;
      }

      if (!newTokensBalancesMap[tokenAddress]) {
        newTokensBalancesMap[tokenAddress] = {
          name: token.tokenName,
          chainId: chainId,
          symbol: token.tokenSymbol,
          decimals: token.tokenDecimals,
          address: tokenAddress,
          balance: new BigNumber(token.balance).toFixed(),
          balanceUsd: new BigNumber(token.balanceUsd).toFixed(),
        };
      }
    });

    const addresses = Object.keys(newTokensBalancesMap);

    if (addresses?.length) {
      const { data: enrichedTokens } = await getTokens({ addresses, chainId });

      // enrich tokens with trusted flag, logoURI and name from the Algolia index
      enrichedTokens?.forEach((enrichedToken) => {
        const enrichedTokenAddress = enrichedToken?.address?.toLowerCase();

        if (!enrichedTokenAddress || !newTokensBalancesMap[enrichedTokenAddress]) {
          return;
        }

        newTokensBalancesMap[enrichedTokenAddress] = {
          ...newTokensBalancesMap[enrichedTokenAddress],
          logoURI: enrichedToken.logoURI,
          trusted: enrichedToken.trusted,
          name: enrichedToken.name,
          symbol: enrichedToken.symbol,
        };
      });
    }

    setTokensBalancesMap(newTokensBalancesMap);
  };

  const fillTokens = async () => {
    if (!indexedTokens) {
      return;
    }

    if (!indexedTokens.length) {
      setTokens([]);

      return;
    }

    const newTokens: TokenWithBalance[] = [];

    // initial list should be filled with tokens with non-zero balances
    if (!searchString) {
      Object.values(tokensBalancesMap)?.forEach((token) => {
        const tokenAddress = token?.address?.toLowerCase();

        newTokens.push({
          ...token,
          address: tokenAddress,
        });
      });
    }

    indexedTokens.forEach((token: Token) => {
      const tokenAddress = token?.address?.toLowerCase();

      if (skipNative && tokenAddress === CHAIN_EMPTY_ADDRESS) {
        return;
      }

      // if searchString is not empty - all tokens from tokensBalancesMap are already in the array
      if (tokensBalancesMap[tokenAddress] && !searchString) {
        return;
      }

      newTokens.push({
        ...token,
        address: tokenAddress,
        balance: tokensBalancesMap[tokenAddress]?.balance || tokensBalancesMap[token.symbol]?.balance,
        balanceUsd: tokensBalancesMap[tokenAddress]?.balanceUsd || tokensBalancesMap[token.symbol]?.balanceUsd,
      });
    });

    // save tokens to reducer
    dispatch(saveTokens(newTokens));

    setTokens(newTokens);
  };

  useEffect(() => {
    void fillTokens();
  }, [indexedTokens, tokensBalancesMap]);

  useEffect(() => {
    void fillTokensBalancesMap();
  }, [tokensWithBalances]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const isTokenAddressValid = ethers.utils.isAddress(searchString);

    if (!isTokenAddressValid || tokens?.length > 0 || invalidAddress.current === searchString) {
      return;
    }

    invalidAddress.current = searchString;

    void loadTokenByAddress(searchString);
  }, [searchString, tokens, isLoading, invalidAddress]);

  useEffect(() => {
    if (!searchString) {
      invalidAddress.current = "";
    }
  }, [searchString]);

  return {
    tokens,
    isLoading,
  };
};
