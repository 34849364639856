import React, { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { getCefiColumns } from "./get-cefi-columns";
import { CefiAllocation, CefiAllocationWithIcon } from "@src/store/apis/anbotoApi/types";
import { mergeCefiAllocations } from "@src/pages/portfolio/utils";
import TableCefiAllocationExpandedRow from "@src/pages/portfolio/components/allocation-funds-innder/table-cefi-allocation-expanded-row";
import { AnbotoTable } from "@src/components/anboto-table";

interface AllocationFundsTableProps {
  data?: CefiAllocation[];
  expandedRowRender?: (row: CefiAllocation) => React.ReactNode;
  isFetching: boolean;
  isError: boolean;
  refetch: () => void;
  isOverview: boolean;
  isTradingButtonShow?: boolean;
}

export const AllocationCefiFundsTable: FC<AllocationFundsTableProps> = ({
  data,
  isFetching,
  isError,
  isOverview,
  isTradingButtonShow,
  refetch,
}) => {
  const columns = useMemo<Array<ColumnDef<CefiAllocationWithIcon, any>>>(
    () => getCefiColumns(isOverview, isTradingButtonShow),
    [isOverview, data]
  );

  let dataWithExpandedInfo = [...(data || [])].filter((row) => !!row.balance);

  if (isOverview && data) {
    const expandedInfoItems = dataWithExpandedInfo.reduce((acc, cur) => {
      if (acc[cur.token_symbol]) {
        const index = acc[cur.token_symbol].findIndex((x) => x.exchange_id === cur.exchange_id);
        if (index === -1) {
          acc[cur.token_symbol] = [
            ...acc[cur.token_symbol],
            {
              exchange_id: cur.exchange_id,
              balance: cur.balance,
              value: cur.value,
            },
          ];
        } else {
          acc[cur.token_symbol][index].balance += cur.balance;
          acc[cur.token_symbol][index].value += cur.value;
        }
      } else {
        acc[cur.token_symbol] = [
          {
            exchange_id: cur.exchange_id,
            balance: cur.balance,
            value: cur.value,
          },
        ];
      }
      return acc;
    }, {});

    dataWithExpandedInfo = data.map((item) => {
      return {
        ...item,
        expandedInfo: expandedInfoItems[item.token_symbol],
      };
    });

    dataWithExpandedInfo = mergeCefiAllocations(dataWithExpandedInfo);
  }

  return (
    <Box>
      <AnbotoTable<CefiAllocationWithIcon>
        columns={columns}
        data={dataWithExpandedInfo}
        loading={isFetching}
        error={!!isError}
        refetch={refetch}
        expandedRowRender={(data) =>
          data.expandedInfo ? (
            <TableCefiAllocationExpandedRow
              allocations={data.expandedInfo}
              key={data.token_symbol + data.exchange_id}
            />
          ) : (
            <div />
          )
        }
        getRowId={(cefiAllocation: CefiAllocationWithIcon) =>
          cefiAllocation.portion + cefiAllocation.value + cefiAllocation.token_symbol
        }
      />
    </Box>
  );
};
