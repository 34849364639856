import { ChainId } from "@src/pages/defi/types";
import { GetBaseCoinPriceResult } from "@src/store/apis/anbotoApi/types";
import { CHAIN_ID_NATIVE_COIN } from "@src/store/apis/coingeckoApi/constants";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export interface UseBaseCoinPriceResult {
  nativeCoinPrice: GetBaseCoinPriceResult | undefined;
  isNativeCoinPriceFetching: boolean;
}

export const useBaseCoinPrice = (targetChainId: ChainId): UseBaseCoinPriceResult => {
  const { data: nativeCoinPrice, isFetching: isNativeCoinPriceFetching } = anbotoApi.useGetBaseCoinPriceQuery({
    chainId: targetChainId,
    ids: CHAIN_ID_NATIVE_COIN[targetChainId],
    vsCurrencies: "usd",
  });

  return {
    nativeCoinPrice,
    isNativeCoinPriceFetching,
  };
};
