import React, { useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { ExpandedRow } from "./expanded-row";
import { AllParentOrder, OrdersCurrentTab } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { DefaultStatusFilter } from "@src/components/orders-table-filters/types";
import { AnbotoTable } from "@src/components/anboto-table";
import { useWallets } from "@src/pages/portfolio";
import { useAppSelector } from "@src/store/hooks";
import { getColumns } from "@src/pages/settings/transaction-history/get-columns";
import { useWindowSize } from "@src/hooks/use-window-size";

const OrdersHistoryTable = () => {
  const wallets = useWallets();
  const accountId = useAppSelector((state) => state.user.team_uuid);
  const filters = useAppSelector((state) => state.metrics.historyFilters);
  const { width } = useWindowSize();

  const [pagination, setPagination] = useState({ currentPage: 0, rowsPerPage: 20 });
  const { data, isFetching, isError, refetch } = anbotoApi.useGetAllParentOrdersQuery({
    accountId,
    currentTab: OrdersCurrentTab.History,
    period: filters.period,
    status: DefaultStatusFilter.All,
    limit: pagination.rowsPerPage,
    offset: pagination.currentPage * pagination.rowsPerPage,
  });

  const columns = useMemo<Array<ColumnDef<AllParentOrder, any>>>(
    () => getColumns({ wallets }),
    [isFetching, wallets, width]
  );

  return (
    <Stack>
      <AnbotoTable<AllParentOrder>
        columns={columns}
        data={data?.results || []}
        loading={isFetching}
        error={!!isError}
        refetch={refetch}
        expandedRowRender={(data: AllParentOrder) => <ExpandedRow data={data} key={data.order_id} />}
        getRowId={(order: AllParentOrder) => order.order_id || ""}
        paginationWithDataFetching={{
          count: data?.count || 0,
          pagination,
          setPagination,
        }}
        rowSx={{ minWidth: 1485 }}
        initialState={{
          columnPinning: {
            right: ["last_status", "export"],
          },
        }}
      />
    </Stack>
  );
};

export default OrdersHistoryTable;
