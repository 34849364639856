import { anbotoApi } from "@src/store/apis/anbotoApi";

type UseLeaderboardProps = {
  competitionId: string | undefined;
};

export const useLeaderboard = ({ competitionId }: UseLeaderboardProps) => {
  const leaderBoardData = anbotoApi.useGetLeaderBoardQuery(null);

  const leaderBoard = competitionId ? leaderBoardData?.data?.[competitionId] || [] : [];
  let sortedLeaderBoard = [...leaderBoard].sort((a, b) => b.volume - a.volume).slice(0, 10);

  if (!sortedLeaderBoard.some((x) => x.is_trader === true)) {
    const currentUserParticipant = leaderBoard.find((x) => x.is_trader === true);
    if (currentUserParticipant) sortedLeaderBoard = [...sortedLeaderBoard, currentUserParticipant];
  }

  return { sortedLeaderBoard, isFetching: leaderBoardData.isFetching, refetch: leaderBoardData.refetch };
};
