import React from "react";
import { Stack, Typography, Tooltip, useTheme } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export type CopyToClipboardProps = {
  label?: string;
  textToCopy: string;
  size?: number;
};

export const CopyToClipboard = ({ label, textToCopy, size }: CopyToClipboardProps) => {
  const theme = useTheme();
  const [visibleTooltip, setVisibleTooltip] = React.useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy);
    setVisibleTooltip(true);
    setTimeout(() => setVisibleTooltip(false), 400);
  };

  return (
    <Stack direction="row" gap={1} alignItems="center">
      {label && <Typography variant="body1">{label}</Typography>}
      <Tooltip
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
        open={visibleTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Copied!"
      >
        <ContentCopyIcon
          sx={{
            ...(size ? { fontSize: size } : {}),
            cursor: "pointer",
            color: theme.palette.text.secondary,
          }}
          onClick={handleCopy}
        />
      </Tooltip>
    </Stack>
  );
};
