import React, { useEffect, useState } from "react";
import { Logout } from "@mui/icons-material";
import { Stack, Card, CardHeader, Typography, Box, IconButton, Grid, Divider, useTheme } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import BigNumber from "bignumber.js";
import { useAccount, useDisconnect } from "wagmi";
import { ChainFeesField } from "../chain-fee-multiselect";
import { TokenFeesField } from "../token-fee-multiselect";
import { DepositAndWithdrawForm } from "../utils/useDepositAndWithdrawForm";
import { feeManagerApi } from "../api";
import { MIN_DEPOSIT_AMOUNT_IN_USD, PERCENTAGE_TOLERANCE } from "../constants";
import { QuantityInput } from "@src/pages/cefi/order-form/fields/QuantityInput";
import { truncateAccount } from "@src/pages/defi/utils";
import { useWalletName } from "@src/hooks/useWalletName";
import { ChainId } from "@src/pages/defi/types";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { onlyNumbers } from "@src/utils/only-numbers";
import { CopyToClipboard } from "@src/components";
import { coingeckoApi } from "@src/store/apis/coingeckoApi";

type DepositInputsType = {
  depositForm: UseFormReturn<DepositAndWithdrawForm>;
  symbolsReccord: Record<string, Record<string, string>>;
};

export const DepositInputs = ({ depositForm, symbolsReccord }: DepositInputsType) => {
  const theme = useTheme();
  const { disconnect } = useDisconnect();
  const { address, chain } = useAccount();
  const { name } = useWalletName(address || "");

  const [availableBalance, setAvailableBalance] = useState<string>("0");
  const [minQuantity, setMinQuantity] = useState<number>(0);
  const [tokenPrice, setTokenPrice] = useState<number>(0);
  const [tokenAmount, setTokenAmount] = useState<number>(0);

  const chainId = depositForm.watch("chainId");
  const tokenAddress = depositForm.watch("tokenAddress");
  const quantity = depositForm.watch("amount");
  const depositDisabled = depositForm.watch("isDisabled");

  const possibleTokens = feeManagerApi.useGetPossibleTokenFeesQuery();
  const [getTokenDecimals] = blockchainApi.useLazyGetTokenDecimalsQuery();
  const [getTokenWalletBalance] = blockchainApi.useLazyTokenAccountBalanceQuery();
  const [getSimpleTokenPrice] = coingeckoApi.useLazyGetSimplePriceQuery();

  useEffect(() => {
    if (tokenAddress.length > 0 && address) {
      fetchTokenWalletBalance();
      applyMinQuantity(tokenAddress);
    }
  }, [chainId, tokenAddress, possibleTokens.data, address]);

  useEffect(() => {
    if (tokenAddress.length > 0 && tokenPrice > 0) {
      const newAmount = Math.ceil(Number(quantity) * tokenPrice * 10) / 10;
      setTokenAmount(newAmount);
    }
  }, [quantity, tokenPrice]);

  const onChainIdChange = (newChainId: ChainId) => {
    depositForm.setValue("chainId", newChainId);
    depositForm.setValue("amount", "");
    depositForm.setValue("isDisabled", true);
  };

  const onTokenAddressChange = (newTokenAddress: string) => {
    depositForm.setValue("tokenAddress", newTokenAddress);
    depositForm.setValue("tokenSymbol", symbolsReccord[newTokenAddress]["symbol"]);
    depositForm.setValue("amount", "");
    depositForm.setValue("isDisabled", true);
    applyMinQuantity(newTokenAddress);
  };

  const applyMinQuantity = async (newTokenAddress: string) => {
    try {
      const tokenId = symbolsReccord[newTokenAddress]["coinGeckoId"];
      const rawPriceQuery = await getSimpleTokenPrice({ tokenIdsList: tokenId });

      if (rawPriceQuery.data) {
        const tokenPrice = rawPriceQuery.data![tokenId]["usd"];
        setTokenPrice(tokenPrice);
        const newMinQuantity = MIN_DEPOSIT_AMOUNT_IN_USD / tokenPrice;
        setMinQuantity(newMinQuantity);
      } else {
        setMinQuantity(0);
      }
    } catch (error) {
      setMinQuantity(0);
      console.log(error);
    }
  };

  const onMaxClick = () => {
    depositForm.setValue("amount", availableBalance);
    const depositGranted = new BigNumber(availableBalance).gt((minQuantity * (100 - PERCENTAGE_TOLERANCE)) / 100);
    if (depositGranted) {
      depositForm.setValue("isDisabled", false);
    }
  };

  const fetchTokenWalletBalance = async () => {
    try {
      const decimals = await getTokenDecimals({ chainId: chainId, tokenAddress: tokenAddress }).unwrap();
      const balance = await getTokenWalletBalance({
        chainId: chainId,
        tokenAddress: tokenAddress,
        account: address!,
      }).unwrap();
      depositForm.setValue("tokenDecimals", decimals);

      setAvailableBalance(balance);
    } catch (error) {
      throw new Error("Fetching token balance");
    }
  };

  const onQuantityChange = (value: string) => {
    const quantity = onlyNumbers(value);
    if (quantity.length > 0) {
      depositForm.setValue("amount", quantity);
      if (
        Number(quantity) >= (minQuantity * (100 - PERCENTAGE_TOLERANCE)) / 100 &&
        Number(quantity) < parseFloat(availableBalance)
      ) {
        depositForm.setValue("isDisabled", false);
      } else {
        depositForm.setValue("isDisabled", true);
      }
    } else {
      depositForm.setValue("amount", "");
      depositForm.setValue("isDisabled", true);
    }
  };

  const dynamicQuantityLabel = availableBalance ? `Quantity (Available ${availableBalance})` : "Quantity";
  const quantityDisabled = chainId !== chain?.id;

  return (
    <Stack>
      <Card sx={{ bgcolor: "background.default" }}>
        <CardHeader
          titleTypographyProps={{ noWrap: true }}
          sx={{ maxHeight: "55px" }}
          title={
            <Stack direction="row" justifyContent="space-between">
              <Typography color="text.secondary">Deposit from</Typography>
              <Stack direction="row" gap={1}>
                <Typography>{name || truncateAccount(address!)}</Typography>
                <CopyToClipboard textToCopy={address!} />
              </Stack>
            </Stack>
          }
          action={
            <Box sx={{ mt: -0.8, ml: 1 }}>
              <IconButton onClick={() => disconnect()}>
                <Logout />
              </IconButton>
            </Box>
          }
        />
      </Card>
      <Grid container direction="column" alignItems="center" width="100%">
        <Grid item xs={12} width="100%" py={1.5}>
          <Controller
            name="chainId"
            control={depositForm.control}
            render={({ field }) => <ChainFeesField {...field} onChange={onChainIdChange} />}
          />
        </Grid>
        <Grid item width="100%" py={1.5}>
          <Controller
            name="tokenAddress"
            control={depositForm.control}
            rules={{ required: true }}
            render={({ field }) => (
              <TokenFeesField {...field} selectedChainField={chainId} onChange={onTokenAddressChange} />
            )}
          />
        </Grid>
        <Grid item width="100%" py={1.5}>
          <Controller
            name="amount"
            control={depositForm.control}
            render={({ field, fieldState }) => {
              const errorMessage =
                fieldState.error?.message ||
                (parseFloat(field.value) > parseFloat(availableBalance) && "Inputed amount exceed your balance");
              const hasError = depositDisabled && !!quantity;

              return (
                <QuantityInput
                  {...field}
                  size="medium"
                  max={true}
                  disabled={quantityDisabled}
                  maxProps={{ onClick: onMaxClick, disabled: quantityDisabled }}
                  error={hasError}
                  helperText={errorMessage}
                  amount={tokenAmount}
                  onChange={(e) => onQuantityChange(e.target.value)}
                  label={dynamicQuantityLabel}
                />
              );
            }}
          />
        </Grid>

        <Divider sx={{ borderWidth: "1px", width: "100%" }} color={theme.custom.colors.gray}>
          <Typography color="text.secondary">Min amount: {MIN_DEPOSIT_AMOUNT_IN_USD} USD</Typography>
        </Divider>
      </Grid>
    </Stack>
  );
};
