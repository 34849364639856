import React from "react";
import { Skeleton, Typography, Stack } from "@mui/material";
import { useAccount } from "wagmi";
import { formatTokenAmount } from "@src/utils/format";
import { useTokenAccountBalanceQuery } from "@src/store/apis/blockchainApi";
import { ChainId } from "@src/pages/defi/types";

type TokenBalanceProps = {
  chainId: ChainId;
  tokenAddress: string;
};

export const TokenBalance = ({ chainId, tokenAddress }: TokenBalanceProps) => {
  const account = useAccount();

  const { data: accountTokenBalance, isFetching } = useTokenAccountBalanceQuery(
    { account: account.address!, chainId, tokenAddress },
    { skip: !account.address || !tokenAddress || !chainId }
  );

  return (
    <Stack direction="row">
      <Typography sx={{ flexGrow: 1 }} variant="caption" color="text.secondary" fontWeight={400}>
        Avbl. &nbsp;
      </Typography>
      <Typography sx={{ flexGrow: 1 }} variant="caption" color="text.secondary">
        {isFetching ? (
          <Skeleton width={100} />
        ) : account?.address && accountTokenBalance ? (
          <span style={{ color: "white" }}>{formatTokenAmount(accountTokenBalance)}</span>
        ) : (
          "-"
        )}
      </Typography>
    </Stack>
  );
};
