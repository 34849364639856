import React, { useEffect, useRef } from "react";
import { WidgetBase, WidgetBaseProps } from "../widget-base";
import { useGroupData } from "../../hooks/use-group-data";
import { CefiGroup, updateGroupData } from "../../store/widgets.slice";
import { Orderbook } from "@src/components/orderbook";
import { useAppDispatch } from "@src/store/hooks";
import { OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";

export const CefiOrderbookWidget = React.forwardRef((props: WidgetBaseProps, ref: React.Ref<HTMLDivElement>) => {
  const dispatch = useAppDispatch();
  const { group, id } = props;
  const groupData = useGroupData(group || id) as CefiGroup;
  const strategyRef = useRef(groupData?.strategy);

  const { symbol, priceGrouping, strategy } = groupData || {};

  const handleGroupingChange = (priceGrouping: string) => {
    dispatch(updateGroupData({ group, data: { priceGrouping } }));
  };

  const onRowClick = (limitPrice: string) => {
    if (strategyRef.current === OrderExecutionStrategy.LIMIT)
      dispatch(updateGroupData({ group, data: { limitPrice } }));
  };

  useEffect(() => {
    strategyRef.current = strategy;
  }, [strategy]);

  return (
    <WidgetBase
      {...props}
      ref={ref}
      headerProps={{
        spacingX: 1,
      }}
      component={
        symbol ? (
          <Orderbook
            onRowClick={onRowClick}
            symbol={symbol}
            grouping={priceGrouping}
            onGroupingChange={handleGroupingChange}
          />
        ) : null
      }
    />
  );
});

CefiOrderbookWidget.displayName = "CefiOrderbookWidget";
