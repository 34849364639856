import React, { FC } from "react";
import { Chip } from "@mui/material";
import { TokenAvatar } from "@src/components/token-avatar";
import { ChainId, EVMBasedAddress, Token } from "@src/pages/defi/types";
import { useTokenInfo } from "@src/pages/defi/hooks";

export interface TokenChipProps {
  address: string | EVMBasedAddress;
  chainId: ChainId;
  onClick: (token: Token) => void;
}

export const TokenChip: FC<TokenChipProps> = ({ chainId, address, onClick }) => {
  const token = useTokenInfo({ chainId, address });

  if (!token) {
    return null;
  }

  return (
    <Chip
      key={token.address}
      sx={{ mr: 2 }}
      label={token.symbol}
      avatar={<TokenAvatar address={token.address} chainId={chainId} symbol={token.symbol} />}
      onClick={() => onClick(token)}
    />
  );
};
