import React, { useEffect, useState } from "react";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import { FormProvider, useWatch } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import { closeAdvancedSettingsModal, openAdvancedSettingsModal, updateGroupData } from "../../store/widgets.slice";
import { useGroupData } from "../../hooks/use-group-data";
import { WidgetBase, WidgetBaseProps } from "../widget-base";
import { WIDGET_HEADER_HEIGHT } from "../../constants";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { usePrevious } from "@src/hooks/use-previous";
import { useCefiOrderFormDefaultValues } from "@src/pages/cefi/order-form/hooks/use-cefi-order-form-default-values";
import { ADVANCED_OPTIONS_DIALOG_WIDTH } from "@src/features/otc/constants";
import {
  useOtcOrderForm,
  OtcOrderFormFieldValues,
} from "@src/features/otc/components/otc-order-form/hooks/use-otc-order-form";
import { OtcOrderForm } from "@src/features/otc/components/otc-order-form";
import { useOtcOrderFormContext } from "@src/features/otc/components/otc-order-form/hooks/use-otc-order-form-context";
import { OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";

const getDataDiff = (formState1, formState2) =>
  Object.keys(formState1).reduce((res, key) => {
    return _isEqual(formState2[key], formState1[key]) ? res : { ...res, [key]: formState1[key] };
  }, {});

const OrderFormWithGroupState = (props: React.PropsWithChildren<WidgetBaseProps>) => {
  const orderForm = useOtcOrderFormContext();
  const dispatch = useAppDispatch();
  const groupId = props.group || props.id;
  const prevGroup = usePrevious(props.group);
  const groupData = useGroupData(groupId);
  const strategy = orderForm.watch("strategy");
  const defaultValues = useCefiOrderFormDefaultValues({ strategy });
  const formValues = useWatch();
  const [initial, setInitial] = useState(true);
  const advancedSettingsModal = useAppSelector((state) => state.widgets.advancedSettingsModal[groupId]);
  const isAdvancedSettingsDraggableOpened = advancedSettingsModal?.id === props.id;

  // save form values to group state
  React.useEffect(() => {
    if (!initial) {
      const diff = getDataDiff(formValues, groupData);

      if (!_isEmpty(diff)) {
        dispatch(updateGroupData({ group: groupId, data: diff }));
      }
    }
  }, [formValues]);

  // update form data from group state
  React.useEffect(() => {
    const diff = getDataDiff(groupData, formValues);

    if (Object.keys(diff).length > 1 || prevGroup !== props.group) {
      orderForm.reset({ ...defaultValues, ...groupData }, { keepDefaultValues: true });
    } else if (!_isEmpty(diff)) {
      Object.keys(diff).forEach((key) => orderForm.setValue(key as keyof OtcOrderFormFieldValues, diff[key]));
    }
    setInitial(false);
  }, [groupData, prevGroup, props.group]);

  const openAdvancedSettings = (orderFormRef: React.RefObject<HTMLDivElement>) => {
    if (!orderFormRef.current) return;

    const mainLayoutWidth = document.getElementById("main-layout")?.clientWidth || 0;
    const top =
      orderFormRef.current.getBoundingClientRect().top +
      Math.abs(document.body.getBoundingClientRect().top) -
      WIDGET_HEADER_HEIGHT;
    const left =
      orderFormRef.current.getBoundingClientRect().left + Math.abs(document.body.getBoundingClientRect().left);
    const right = Math.abs(orderFormRef.current.getBoundingClientRect().right);
    const formWidth = orderFormRef.current.getBoundingClientRect().width;
    const x =
      mainLayoutWidth - right > ADVANCED_OPTIONS_DIALOG_WIDTH ? left + formWidth : left - ADVANCED_OPTIONS_DIALOG_WIDTH;
    const y = top;

    dispatch(openAdvancedSettingsModal({ group: groupId, id: props.id, x, y }));
  };

  const handleAdvancedSettingsClose = () => {
    dispatch(closeAdvancedSettingsModal(groupId));
  };

  useEffect(() => {
    if (strategy !== OrderExecutionStrategy.TWAP) {
      dispatch(closeAdvancedSettingsModal(groupId));
    }
  }, [strategy]);

  return (
    <OtcOrderForm
      className="parent-order-form"
      sx={{ minHeight: 0, position: "absolute", width: "100%", height: "100%", left: 0, top: 0 }}
      onAdvancedSettingsClick={openAdvancedSettings}
      advancedSettingsDraggable={advancedSettingsModal}
      advancedSettingsDraggableOpened={isAdvancedSettingsDraggableOpened}
      onAdvancedSettingsClose={handleAdvancedSettingsClose}
    />
  );
};

OrderFormWithGroupState.displayName = "OrderFormWithGroupState";

const OrderFormProvider = ({ children, id }: React.PropsWithChildren<{ id: string }>) => {
  const groupData = useGroupData(id);
  const orderForm = useOtcOrderForm(groupData);

  return (
    <FormProvider {...orderForm}>
      {/* <DevTool control={orderForm.control} placement="top-left" /> */}
      {children}
    </FormProvider>
  );
};

export const OtcOrderFormWidget = React.forwardRef(
  (props: React.PropsWithChildren<WidgetBaseProps>, ref: React.Ref<HTMLDivElement>) => {
    return (
      <WidgetBase
        {...props}
        ref={ref}
        otc
        component={
          <OrderFormProvider id={props.group || props.id}>
            <OrderFormWithGroupState {...props} />
          </OrderFormProvider>
        }
      />
    );
  }
);

OtcOrderFormWidget.displayName = "CefiOrderFormWidget";
