import React from "react";
import { Divider, InputAdornment, Stack, styled, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import {
  SlippageField,
  ExpirationField,
  LimitPriceField,
  TriggerPriceMinField,
  TypeField,
} from "../order-form-card-no-gas/fields";
import {
  OrderExecutionStrategy,
  OrderTradingDurationUnit,
  OrderType,
  ParentDefiOrder,
  PlacementMode,
  TradingStyle,
  TriggerCondition,
} from "@src/store/apis/anbotoApi/types";
import { getTradingDurationFromMs } from "@src/pages/cefi/order-form/utils";
import { TriggerModeField } from "@src/components/trigger-mode-field";
import { useDefiOrderForm } from "@src/pages/defi/hooks";
import { ClipSizeTypeSelect } from "@src/pages/cefi/order-form/fields/ClipSizeTypeSelect";
import { ClipSizeValueField } from "@src/pages/cefi/order-form/fields/ClipSizeValueField";
import { TradingDurationField } from "@src/pages/cefi/order-form/fields/TradingDurationField";
import { TradingDurationUnitField } from "@src/pages/cefi/order-form/fields/TradingDurationUnitField";
import { ExtendDurationSelect } from "@src/components/extend-duration-field";

type DefiExpandedRowProps = {
  data: ParentDefiOrder;
  isExtraHeader?: boolean;
};

export const ExpandedRow = ({ data, isExtraHeader }: DefiExpandedRowProps) => {
  const {
    duration_seconds,
    strategy,
    side,
    order_id,
    quantity,
    clip_size_type,
    clip_size_val,
    params,
    start_time,
    expiration_time,
    limit_price,
    trader_email,
  } = data;
  const {
    placement_infos_cancel,
    placement_infos_placement,
    placement_infos_placement_mode,
    trigger_condition,
    trigger_price,
    extend_duration,
  } = params;

  const orderForm = useDefiOrderForm({
    ...(quantity ? { quantity: quantity?.toString() } : {}),
    side,
    strategy,
    ...(clip_size_val ? { clipSizeValue: clip_size_val.toString() } : {}),
    clipSizeType: clip_size_type,
    ...(duration_seconds
      ? { tradingDuration: getTradingDurationFromMs(duration_seconds * 1000).duration.toString() }
      : {}),
    ...(duration_seconds
      ? {
          tradingDurationUnit: getTradingDurationFromMs(duration_seconds * 1000)
            .durationUnits as OrderTradingDurationUnit,
        }
      : {}),
    extendDuration: extend_duration,
    ...(limit_price ? { limitPrice: limit_price.toString(), type: OrderType.LIMIT } : {}),
    startTime: start_time?.toString(),
    // endTime: end_time,
    ...(placement_infos_placement_mode ? { placementMode: placement_infos_placement_mode as PlacementMode } : {}),
    ...(placement_infos_placement ? { placement: placement_infos_placement.toString() } : {}),
    ...(placement_infos_cancel ? { placementCancel: placement_infos_cancel.toString() } : {}),

    ...(trigger_condition ? { triggerCondition: trigger_condition as TriggerCondition } : {}),
    ...(trigger_price ? { triggerPrice: trigger_price.toString() } : {}),

    ...(params.trading_style ? { tradingStyle: params.trading_style as TradingStyle } : {}),
    // ...(expiration_time
    // ? { expirationTime: getExpirationTimeFromMs(start_time, expiration_time) as ExpirationTime }
    // : {}),
  });

  return (
    <Root>
      {!isExtraHeader && (
        <Stack gap={2} direction="row" alignItems="center" sx={{ pb: 2 }}>
          <Stack direction="row" gap={2}>
            <Typography variant="body2">Order no. {order_id}</Typography>
            <Typography variant="body2">Trader: {trader_email}</Typography>
          </Stack>
          <Divider />
        </Stack>
      )}
      <Stack direction="row" gap={1}>
        {strategy === OrderExecutionStrategy.ORDER && (
          <Controller
            name="type"
            control={orderForm.control}
            render={({ field }) => <TypeField disabled {...field} />}
          />
        )}
        {strategy === OrderExecutionStrategy.ORDER && limit_price && (
          <Controller
            name="limitPrice"
            control={orderForm.control}
            render={({ field }) => <LimitPriceField {...field} disabled />}
          />
        )}
        <Controller
          name="slippage"
          control={orderForm.control}
          render={({ field }) => <SlippageField disabled hideButtonGroup {...field} />}
        />
        {strategy === OrderExecutionStrategy.ORDER && expiration_time && (
          <Controller
            name="expiration"
            control={orderForm.control}
            render={({ field }) => <ExpirationField disabled {...field} />}
          />
        )}
        {(strategy === OrderExecutionStrategy.ICEBERG || strategy === OrderExecutionStrategy.TWAP) && (
          <Controller
            name="clipSizeType"
            control={orderForm.control}
            render={({ field }) => <ClipSizeTypeSelect disabled {...field} />}
          />
        )}
        {(strategy === OrderExecutionStrategy.ICEBERG || strategy === OrderExecutionStrategy.TWAP) && (
          <Controller
            name="clipSizeValue"
            control={orderForm.control}
            render={({ field }) => <ClipSizeValueField label="Clip size value" disabled {...field} />}
          />
        )}
        {!!duration_seconds && (
          <Controller
            name="tradingDuration"
            control={orderForm.control}
            render={({ field }) => (
              <TradingDurationField
                {...field}
                sx={{ minWidth: 130 }}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Controller
                        name="tradingDurationUnit"
                        control={orderForm.control}
                        render={({ field }) => <TradingDurationUnitField {...field} disabled />}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}
        {(strategy === OrderExecutionStrategy.TWAP || strategy === OrderExecutionStrategy.ICEBERG) &&
          trigger_price &&
          trigger_condition && (
            <>
              <Controller
                name="triggerPrice"
                control={orderForm.control}
                render={({ field }) => (
                  <TriggerPriceMinField disabled {...field} triggerCondition={trigger_condition} />
                )}
              />
              <Controller
                name="triggerMode"
                control={orderForm.control}
                render={({ field }) => <TriggerModeField select disabled {...field} />}
              />
            </>
          )}
        <Controller
          name="extendDuration"
          control={orderForm.control}
          render={({ field }) => <ExtendDurationSelect select disabled {...field} />}
        />
      </Stack>
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  background: theme.custom.background.default,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(1),
}));
