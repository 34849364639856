import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetOrdersPeriod, OrdersCurrentTab, OrderStatuses, ParentOrderStatuses } from "@src/store/apis/anbotoApi/types";
import { TableActionButtonType } from "@src/components";
import { DefaultStatusFilter } from "@src/components/orders-table-filters/types";

export interface OrdersTableSlice {
  params: {
    currentTab: OrdersCurrentTab;
    period: GetOrdersPeriod;
    status: OrderStatuses | ParentOrderStatuses | DefaultStatusFilter;
  };
  loading: {
    order: Record<string, boolean>;
    actionType: TableActionButtonType | null;
  };
  search: string;
  otherPairsHidden: {
    value: boolean;
    symbols: string[];
  };
}

const initialState: OrdersTableSlice = {
  params: {
    currentTab: OrdersCurrentTab.Regular,
    period: GetOrdersPeriod.All,
    status: DefaultStatusFilter.All,
  },
  loading: {
    order: {},
    actionType: null,
  },
  search: "",
  otherPairsHidden: {
    value: false,
    symbols: [],
  },
};

export const ordersTableSlice = createSlice({
  name: "singleExchangePage",
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<{ value: OrdersCurrentTab }>) => {
      state.params.currentTab = action.payload.value;
    },
    setParams: (state, action: PayloadAction<Partial<OrdersTableSlice["params"]>>) => {
      state.params = { ...state.params, ...action.payload };
    },
    setOrderLoading: (
      state,
      action: PayloadAction<{ id: string; value: boolean; actionType: TableActionButtonType }>
    ) => {
      const { id, value, actionType } = action.payload;

      state.loading.order[id] = value;
      state.loading.actionType = value ? actionType : null;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setOtherPairsHidden: (state, action: PayloadAction<OrdersTableSlice["otherPairsHidden"]>) => {
      state.otherPairsHidden = action.payload;
    },
  },
});

export const { setCurrentTab, setParams, setOrderLoading, setSearch, setOtherPairsHidden } = ordersTableSlice.actions;

export default ordersTableSlice.reducer;
