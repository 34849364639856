import { ChainId } from "@src/pages/defi/types";
import { Period, PeriodHashTimeFn } from "@src/store/apis/coingeckoApi/types";

// native tokens ids
export const CHAIN_ID_COIN_ID: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: "ethereum",
  [ChainId.BSC]: "binance-smart-chain",
  [ChainId.POLYGON]: "polygon-pos",
  [ChainId.AVALANCHE]: "avalanche",
  [ChainId.ARBITRUM]: "arbitrum-one",
  [ChainId.OPTIMISM]: "optimistic-ethereum",
  [ChainId.BASE]: "base",
};

// blockchain ids
// https://www.coingecko.com/en/api/documentation#operations-tag-asset_platforms
export const CHAIN_ID_ASSET_PLATFORM: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: "ethereum",
  [ChainId.BSC]: "binancecoin",
  [ChainId.POLYGON]: "matic-network",
  [ChainId.AVALANCHE]: "avalanche-2",
  // we always use eth as a native chain coin for Arbitrum, Optimism and Base
  [ChainId.ARBITRUM]: "ethereum", // arbitrum
  [ChainId.OPTIMISM]: "ethereum", // optimism
  [ChainId.BASE]: "ethereum", // base
};

export const CHAIN_ID_NATIVE_COIN: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: "ethereum",
  [ChainId.BSC]: "binancecoin",
  [ChainId.POLYGON]: "matic-network",
  [ChainId.AVALANCHE]: "avalanche-2",
  // we always use eth as a native chain coin for Arbitrum, Optimism, Base
  [ChainId.ARBITRUM]: "ethereum", // arbitrum
  [ChainId.OPTIMISM]: "ethereum", // optimism
  [ChainId.BASE]: "ethereum", // base
};

export const PERIOD_DAYS: Record<Period, number> = {
  DAY: 1,
  WEEK: 7,
  MONTH: 30,
  YEAR: 365,
};

export const PERIOD_HASH_TIME_FN: Record<Period, PeriodHashTimeFn> = {
  DAY: (time) => {
    const date = new Date(time);
    date.setMilliseconds(0);
    date.setSeconds(0);
    return date.toISOString();
  },
  WEEK: (time) => {
    const date = new Date(time);
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    return date.toISOString();
  },
  MONTH: (time) => {
    const date = new Date(time);
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    return date.toISOString();
  },
  YEAR: (time) => {
    const date = new Date(time);
    date.setMilliseconds(0);
    date.setSeconds(0);
    date.setMinutes(0);
    date.setHours(0);
    return date.toISOString();
  },
};
