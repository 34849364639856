import React from "react";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { AnbotoButton } from "../ui/AnbotoButton/AnbotoButton";
import { feeManagerApi } from "@src/pages/settings/fee-manager/api";
import { formatUsd } from "@src/utils/format";

export const CreditsBalance = () => {
  const creditsBalance = feeManagerApi.useGetFeeTokensAndBalancesQuery();
  const navigate = useNavigate();

  return creditsBalance.isFetching ? (
    <LoadingButton loading variant="outlined" fullWidth sx={{ height: 32 }}>
      &nbsp;
    </LoadingButton>
  ) : (
    <AnbotoButton
      tooltip="Fee Wallet Balance"
      sx={{ height: 32, fontSize: "14px", color: (theme) => theme.custom.text.dim }}
      onClick={() => navigate("/settings/fees")}
    >
      <AccountBalanceWalletIcon sx={{ mr: 1, color: "#8A9296", fontSize: 22 }} />
      {formatUsd(creditsBalance?.data?.balance || 0)}
    </AnbotoButton>
  );
};
