import { useCallback, useEffect } from "react";
import _debounce from "lodash/throttle";
import { MAIN_ACCOUNT_VALUE } from "../constants";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { getAccountSubscription } from "@src/subscriptions";
import { CefiAccountMessage } from "@src/subscriptions/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";

export const useUpdateSymbolBalance = (symbol: OrderSymbol | null, account: string) => {
  const accountId = useAppSelector((state) => state.user.team_uuid);
  const dispatch = useAppDispatch();

  const updateBalance = useCallback(
    _debounce((symbol, account) => {
      dispatch(
        anbotoApi.endpoints.getSymbolAccountBalance.initiate(
          {
            symbol,
            account,
          },
          { subscribe: false, forceRefetch: true }
        )
      );
    }, 1000),
    []
  );

  const onMessage = useCallback(
    ({ data }: CefiAccountMessage["message"]) => {
      if (!symbol || !account) {
        return false;
      }

      const msgAccount = data.subaccount || MAIN_ACCOUNT_VALUE;
      const { coin1, coin2, market_type, exchange } = symbol || {};

      if (
        symbol &&
        account &&
        msgAccount === account &&
        data.asset_class === market_type &&
        data.exchange === exchange &&
        ((coin1 && data.symbol.includes(coin1)) || (coin2 && data.symbol.includes(coin2)))
      ) {
        updateBalance(symbol, account);
      }
    },
    [getLivePriceId(symbol), account]
  );

  useEffect(() => {
    let subscription;

    if (accountId && account && symbol) {
      const subscription = getAccountSubscription(accountId);
      subscription?.listenAll(onMessage);
    }

    return () => {
      subscription?.stopListenAll(onMessage);
    };
  }, [getLivePriceId(symbol), account]);
};
