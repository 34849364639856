import React from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { PieChartCard } from "./pie-chart-card/pie-chart-card";
import { SELECT_ALL } from "@src/store/slices/portfolioSlice";
import { RootState } from "@src/store/types";
import {
  ChartNames,
  MAX_NUMBER_TOKEN_ALLOCATION_CHARTS,
  MAX_NUMBER_TOKEN_EXCHANGE_CHARTS,
} from "@src/pages/portfolio/constant";
import { useUserExchangesList } from "@src/pages/portfolio";

type PieChartsCEFIProps = {
  isFetching: boolean;
};

export const PieChartsCEFI = ({ isFetching }: PieChartsCEFIProps) => {
  const selectedExchange = useSelector((state: RootState) => state.portfolio.selectedExchange);
  const exchangesAllocation = useSelector((state: RootState) => state.portfolio.accountCefiAllocation);

  const { data } = useUserExchangesList();
  const isEmptyExchangesList = (data?.available || []).length === 0;

  let exchangesAllocationChart = exchangesAllocation
    ?.map((item) => {
      return { name: item.subaccount, value: item.value };
    })
    .slice(0, MAX_NUMBER_TOKEN_EXCHANGE_CHARTS);
  if (exchangesAllocation.length > MAX_NUMBER_TOKEN_EXCHANGE_CHARTS) {
    const othersAllocationChartBalance = exchangesAllocation
      .slice(MAX_NUMBER_TOKEN_EXCHANGE_CHARTS, exchangesAllocation.length)
      .reduce((n, { value }) => n + value, 0);
    exchangesAllocationChart = [...exchangesAllocationChart, { name: "Others", value: othersAllocationChartBalance }];
  }
  const tokensAllocation = useSelector((state: RootState) => state.portfolio.tokenCefiAllocation);
  let tokensAllocationChart = tokensAllocation
    ?.map((item) => {
      return { name: item.tokenName, value: item.value };
    })
    .slice(0, MAX_NUMBER_TOKEN_ALLOCATION_CHARTS);
  if (tokensAllocation.length > MAX_NUMBER_TOKEN_ALLOCATION_CHARTS) {
    const othersTokenAllocationChartBalance = tokensAllocation
      .slice(MAX_NUMBER_TOKEN_EXCHANGE_CHARTS, tokensAllocation.length)
      .reduce((n, { value }) => n + value, 0);
    tokensAllocationChart = [...tokensAllocationChart, { name: "Others", value: othersTokenAllocationChartBalance }];
  }
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Stack
      direction="row"
      justifyContent="center"
      paddingBottom={3}
      sx={{
        gap: isBigScreen ? 10 : 2,
      }}
    >
      <PieChartCard isFetching={isFetching} chartName={ChartNames.TokenAllocation} chartData={tokensAllocationChart} />
      <PieChartCard
        linkButton={isEmptyExchangesList}
        isFetching={isFetching}
        chartName={selectedExchange === SELECT_ALL ? ChartNames.ExchangesAllocation : selectedExchange}
        chartData={exchangesAllocationChart}
      />
    </Stack>
  );
};
