import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum OnboardingSteps {
  ACCOUNT_ACTIVATE = 0,
  ENABLE_2FA = 1,
  PROFILE_INFORMATION = 2,
}

export enum AccountTypes {
  INDIVIDUAL = "individual",
  TEAM = "team",
}

export enum AccountChoiceTypes {
  ENABLED = 1,
  DISABLED = 0,
}

export interface Onboarding {
  currentStep: OnboardingSteps;
  accountType: AccountTypes;
  selectedAccountType?: AccountTypes;
  accountTypeChoice?: AccountChoiceTypes;
}

const initialState: Onboarding = {
  currentStep: OnboardingSteps.ACCOUNT_ACTIVATE,
  accountType: AccountTypes.TEAM,
};

export const onboardingSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setCurrentOnboardingStep: (state, action: PayloadAction<OnboardingSteps>) => {
      state.currentStep = action.payload;
    },
    setInitialAccountTypeToStore: (state, action: PayloadAction<AccountTypes>) => {
      state.accountType = action.payload;
    },
    setSelectedAccountType: (state, action: PayloadAction<AccountTypes>) => {
      state.selectedAccountType = action.payload;
    },
    setIsAccountTypeChoiceAvailable: (state, action: PayloadAction<AccountChoiceTypes>) => {
      state.accountTypeChoice = action.payload;
    },
  },
});

export const {
  setCurrentOnboardingStep,
  setInitialAccountTypeToStore,
  setSelectedAccountType,
  setIsAccountTypeChoiceAvailable,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
