import _isObject from "lodash/isObject";

type Error = Record<string, string | string[]>;

export const parseAnbotoRequestError = (err: Error, deafultErrorMessage?: string) => {
  const defaultError = deafultErrorMessage || "Some error occurred";
  let result = "";

  if (_isObject(err)) {
    Object.keys(err).forEach((key) => {
      const val = err[key];

      if (result.length) result += "\n";

      if (typeof val === "string") {
        result += `${key}: ${val}`;
      }

      if (Array.isArray(val)) {
        if (typeof val[0] === "string") {
          result += `${key}:\n`;
          result += val.join("\n");
        }
      } else if (_isObject(val)) {
        result += parseAnbotoRequestError(val);
      }
    });

    return result || defaultError;
  }

  return defaultError;
};
