import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import formValidator from "@src/utils/formValidator";

export type CreatePasswordFormFieldValues = {
  password: string;
  confirmPassword: string;
  email?: string;
  isEmail: boolean;
};

const passwordSchema = formValidator
  .object({
    isEmail: formValidator.boolean(),
    email: formValidator.string().email().label("Email").when("isEmail", {
      is: true,
      then: formValidator.string().required(),
    }),
    password: formValidator
      .string()
      .matches(/[A-Z]/, "One Upper case is required")
      .matches(/[a-z]/, "One Lower case is required")
      .matches(/[0-9]/, "One number is required")
      .matches(/\W/, "One special character is required")
      .min(10, "Your password is too short.")
      .label("Password"),
    confirmPassword: formValidator
      .string()
      .required()
      .oneOf([formValidator.ref("password")], "Your passwords do not match.")
      .label("Confirm password"),
  })
  .required();

export const useCreatePasswordForm = (
  isEmail: boolean,
  defaultValues: { password?: string; confirmPassword?: string; email?: string }
) =>
  useForm<CreatePasswordFormFieldValues>({
    resolver: yupResolver(passwordSchema),
    defaultValues: {
      isEmail,
      ...(defaultValues || { password: "", confirmPassword: "", email: "" }),
    },
  });
