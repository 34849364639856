import { useEffect, useState } from "react";

export const useComponentWidth = (ref: React.MutableRefObject<undefined>) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const getWidth = () => (ref?.current ? ref?.current["offsetWidth"] : 0);

    const handleResize = () => {
      setWidth(getWidth());
    };

    if (ref.current) {
      setWidth(getWidth());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  return width;
};
