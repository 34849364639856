import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack, MenuItem, styled, Typography } from "@mui/material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { ReactComponent as KeyIcon } from "@src/assets/svg/key-icon.svg";

export const SetupApiKeyMenuItem = React.forwardRef((_, ref: React.Ref<HTMLLIElement>) => {
  const navigate = useNavigate();

  return (
    <SetupApiKey onClick={(e) => e.stopPropagation()} disableRipple ref={ref}>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1.5}>
          <Typography variant="body2" color="#8A9296" sx={{ fontSize: 12 }}>
            Please connect with
            <br />
            your exchange account
          </Typography>
          <KeyIcon />
        </Stack>
        <AnbotoButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => navigate(`/settings/linked/exchanges`)}
          size="small"
        >
          Setup API key
        </AnbotoButton>
      </Stack>
    </SetupApiKey>
  );
});

SetupApiKeyMenuItem.displayName = "SetupApiKeyMenuItem";

const SetupApiKey = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(2.5),
  background: "#031116",
  margin: 0,
  "&:hover": {
    background: "#031116",
    cursor: "default",
  },
}));
