import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "@src/store/hooks";

export const AnalyticsPage = () => {
  const isAnalyticsVisible = useAppSelector((state) => state.user.is_analytics_visible);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAnalyticsVisible) navigate("/");
  }, [isAnalyticsVisible]);

  return isAnalyticsVisible ? <Outlet /> : null;
};
