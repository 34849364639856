import * as React from "react";
import _isEqual from "lodash/isEqual";
import { AnbotoDataFeed } from "./anboto-datafeed";
import { getTimezone } from "./helpers";
import {
  widget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  IChartingLibraryWidget,
  ResolutionString,
  IBasicDataFeed,
  ThemeName,
  DrawingEventType,
  EntityId,
  DatafeedConfiguration,
} from "@src/charting_library";

export type TvLine = {
  value: number;
  quantity?: string;
  label: string;
  linestyle?: number;
  options?: {
    linecolor?: string;
    showLabel?: string;
    textcolor?: string;
    linewidth?: string;
    horzLabelsAlign?: string;
    vertLabelsAlign?: string;
  };
};

export type TvLines = Record<string, TvLine>;

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];
  datafeed: IBasicDataFeed;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
  container: ChartingLibraryWidgetOptions["container"];
  theme?: ThemeName;
  lines?: TvLines;
  precision?: number;
  onLineMove?: (key: string, value: number) => void;
  onReady?: () => void;
  configuration: Partial<DatafeedConfiguration>;
}

export interface ChartContainerState {
  ready: boolean;
}

function getLanguageFromURL(): LanguageCode | null {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(location.search);
  return results === null ? null : (decodeURIComponent(results[1].replace(/\+/g, " ")) as LanguageCode);
}

const DEFAULT_TRADINGVIEW_INTERVAL = "15" as ResolutionString;

const getDataFeedConfiguration = (config?: Partial<DatafeedConfiguration>) => ({
  supported_resolutions: ["1", "5", "15", "30", "60", "1D", "1W", "1M"] as ResolutionString[],
  ...(config || {}),
});

const userTimezone = getTimezone();

export class TradingViewChart extends React.Component<Partial<ChartContainerProps>, ChartContainerState> {
  public static defaultProps: Omit<ChartContainerProps, "container" | "datafeed" | "symbol" | "configuration"> = {
    interval: DEFAULT_TRADINGVIEW_INTERVAL as ResolutionString,
    libraryPath: "/charting_library/",
    chartsStorageUrl: "https://saveload.tradingview.com",
    chartsStorageApiVersion: "1.1",
    clientId: "tradingview.com",
    userId: "public_user_id",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };
  private chartLines = {};
  private chartOrder: any = null;
  private tvWidget: IChartingLibraryWidget | null = null;
  private ref: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: ChartContainerProps) {
    super(props);
    this.state = {
      ready: false,
    };
  }

  public componentDidMount(): void {
    if (!this.ref.current) {
      return;
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this.props.symbol as string,
      datafeed: new AnbotoDataFeed({
        ...getDataFeedConfiguration(this.props.configuration),
        resetData: this.resetData,
      }),
      interval: this.props.interval as ChartingLibraryWidgetOptions["interval"],
      timezone: userTimezone,
      container: this.ref.current,
      library_path: this.props.libraryPath as string,
      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "timeframes_toolbar",
        "header_saveload",
        "header_compare",
        "header_undo_redo",
        "volume_force_overlay",
      ],
      enabled_features: [
        // 'study_templates',
        "hide_left_toolbar_by_default",
      ],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      theme: this.props.theme,
      custom_css_url: "../tv.css",
      loading_screen: {
        backgroundColor: "#131A1C",
      },
      overrides: {
        "scalesProperties.textColor": "#8A9296",
        "paneProperties.vertGridProperties.color": "#192022",
        "paneProperties.horzGridProperties.color": "#192022",
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": "#131A1C",
        "mainSeriesProperties.candleStyle.upColor": "#179B83",
        "mainSeriesProperties.candleStyle.downColor": "#C3462B",
        "mainSeriesProperties.candleStyle.borderUpColor": "#179B83",
        "mainSeriesProperties.candleStyle.borderDownColor": "#C3462B",
        "mainSeriesProperties.candleStyle.wickUpColor": "#179B83",
        "mainSeriesProperties.candleStyle.wickDownColor": "#C3462B",
        "mainSeriesProperties.minTick": `${this.props.precision},1,false`,
      },
      studies_overrides: {
        "volume.volume.color.0": "#C3462B",
        "volume.volume.color.1": "#179B83",
        ...this.props.studiesOverrides,
      },
    };

    const tvWidget = new widget(widgetOptions);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // const tvWidget = new window.TradingView.widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.chart().getPanes()[1]?.setHeight(80);
      tvWidget.headerReady().then(() => {
        this.setState({ ready: true });
        tvWidget?.subscribe("drawing_event", this.handleShapeEvents);
        // draw horizontal lines
        if (this.props.lines) this.updateLines(this.props.lines, true);
        if (this.props.precision) this.updatePrecision(this.props.precision);
        this.props.onReady && this.props.onReady();
      });
    });
  }

  public componentWillUnmount(): void {
    if (this.tvWidget !== null) {
      this.tvWidget?.unsubscribe("drawing_event", this.handleShapeEvents);
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }

  public defaultInterval = this.props.interval;

  public UNSAFE_componentWillReceiveProps({ symbol, lines, precision, interval }) {
    if (!symbol || !this.state.ready) return;

    if (symbol !== this.props.symbol) {
      let resolution;

      if (this.defaultInterval !== interval) {
        this.defaultInterval = interval;
        resolution = interval;
      } else {
        resolution = this.tvWidget?.activeChart()?.resolution() || DEFAULT_TRADINGVIEW_INTERVAL;
      }

      setTimeout(() => {
        this.tvWidget?.setSymbol(symbol, resolution, () => {
          // cb is required param...
          this.updateLines(lines, true);
        });
      });
    }

    if (precision && precision !== this.props.precision) {
      this.updatePrecision(precision);
    }

    if (lines) this.updateLines(lines);
  }

  updatePrecision = (precision: number) => {
    this.tvWidget?.applyOverrides({ "mainSeriesProperties.minTick": `${precision},1,false` });
  };

  public updateLines = (lines: TvLines = {}, reset?: boolean) => {
    const prevLines = this.props.lines || {};

    // check if order line still exists in props, remove order line if no
    if (this.chartOrder && (!lines.limitPrice || reset)) {
      this.chartOrder?.remove();
      this.chartOrder = null;
    }

    // remove chart lines not existing in new props
    Object.keys(this.chartLines).forEach((key) => {
      try {
        this.chartLines[key] && this.tvWidget?.activeChart().getShapeById(this.chartLines[key]);
      } catch (error) {
        delete this.chartLines[key];
      }

      if (this.chartLines[key] && (!lines[key] || reset)) {
        try {
          this.tvWidget?.activeChart().removeEntity(this.chartLines[key], { disableUndo: true });
        } catch (err) {
          console.log(err);
        }
        delete this.chartLines[key];
      }
    });

    Object.keys(lines).forEach((key) => {
      if (!_isEqual(prevLines[key], lines[key]) || reset) {
        this.updateLine(key, lines[key]);
      }
    });
  };

  public updateLine = (key: string, { value, quantity, label, options }: TvLine) => {
    if (key === "limitPrice") {
      if (!this.chartOrder) {
        const line = this.tvWidget
          ?.activeChart()
          .createOrderLine()
          .setEditable(true)
          .setText(label)
          .setBodyBorderColor(options?.linecolor || "")
          .setBodyTextColor(options?.textcolor || "")
          .setQuantity(quantity || "")
          .setQuantityTextColor(options?.textcolor || "")
          .setQuantityBackgroundColor(options?.linecolor || "")
          .setQuantityBorderColor(options?.linecolor || "")
          .setPrice(value)
          .setLineColor(options?.linecolor || "")
          .onMove(() => {
            const price = this.chartOrder._line._points[0].price;
            this.props.onLineMove && this.props.onLineMove("limitPrice", price);
          });
        this.chartOrder = line;
      } else {
        this.chartOrder
          .setPrice(value)
          .setText(label)
          .setBodyBorderColor(options?.linecolor || "")
          .setBodyTextColor(options?.textcolor || "")
          .setQuantity(quantity || "")
          .setQuantityTextColor(options?.textcolor || "")
          .setQuantityBackgroundColor(options?.linecolor || "")
          .setQuantityBorderColor(options?.linecolor || "")
          .setLineColor(options?.linecolor || "");
      }
    } else {
      if (!this.chartLines[key]) {
        const id = this.tvWidget?.activeChart().createShape(
          { time: 0, price: value },
          {
            shape: "horizontal_line",
            disableSelection: true,
            text: label,
            overrides: {
              showLabel: "true",
              horzLabelsAlign: "left",
              ...options,
            },
          }
        );
        this.chartLines[key] = id;
      } else {
        this.tvWidget
          ?.activeChart()
          .getShapeById(this.chartLines[key])
          ?.setPoints([{ time: 0, price: Number(value) }]);
      }
    }
  };

  public handleShapeEvents = (id: EntityId, type: DrawingEventType) => {
    const linesById = {};

    for (const key in this.chartLines) {
      linesById[this.chartLines[key]] = key;
    }

    if (type === "move" && linesById[id]) {
      setTimeout(() => {
        const value = this.tvWidget?.activeChart().getShapeById(id).getPoints()[0]?.price || 0;
        this.props.onLineMove && this.props.onLineMove(linesById[id], value);
      });
    }
  };

  public shouldComponentUpdate() {
    return false;
  }

  public render(): JSX.Element {
    return <div ref={this.ref} style={{ height: "100%" }} />;
  }

  resetData = () => {
    this.tvWidget?.activeChart().resetData();
  };
}
