import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { ORDER_TYPE_NAME, PRICE_PROTECTION_OPTIONS, STRATEGY_NAME } from "@src/pages/defi/constants";
import { formatTokenAmount } from "@src/utils/format";
import { ClipSizeType, OrderExecutionStrategy, OrderSide, TriggerCondition } from "@src/store/apis/anbotoApi/types";
import { isMultiSliceOrderExecutionStrategy } from "@src/store/apis/anbotoApi/utils";
import { useDefiOrderFormContext, useTokenInfo } from "@src/pages/defi/hooks";
import { calculateNumberOfSlices } from "@src/pages/defi/utils";
import { TRIGGER_CONDITION_LABEL } from "@src/components";
import { EVMBasedAddress } from "@src/pages/defi/types";
import { DcaStrategyProperties } from "@src/pages/defi/order-form-card-no-gas/components";

export const PropertiesCard = () => {
  const orderForm = useDefiOrderFormContext();
  const {
    chainId,
    clipSizeType,
    clipSizeValue,
    extendDuration,
    tradingDuration,
    tradingDurationUnit,
    fromTokenAddress,
    fromTokenAmount,
    limitPrice,
    strategy,
    toTokenAddress,
    toTokenAmount,
    side,
    slippage,
    childSlippage,
    triggerPrice,
    triggerCondition,
    type,
    priceProtection,
  } = orderForm.getValues();
  const fromToken = useTokenInfo({ chainId, address: fromTokenAddress as EVMBasedAddress });
  const toToken = useTokenInfo({ chainId, address: toTokenAddress as EVMBasedAddress });

  if (!fromToken || !toToken) {
    return null;
  }

  const numberOfSlices = calculateNumberOfSlices({
    clipSizeType,
    clipSizeValue,
    quantity: side === OrderSide.SELL ? fromTokenAmount : toTokenAmount,
    strategy,
  });

  const isDcaStrategy = strategy === OrderExecutionStrategy.DCA;

  return (
    <Box p={2} mt={2} borderRadius={1} border="1px solid #232C2F">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            Pair
          </Typography>
          <Typography>
            {fromToken.symbol}/{toToken.symbol}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            Strategy
          </Typography>
          <Typography>{STRATEGY_NAME[strategy]}</Typography>
        </Grid>

        {isDcaStrategy ? (
          <DcaStrategyProperties />
        ) : (
          <>
            <Grid item xs={6}>
              <Typography variant="caption" color="text.secondary">
                Buy ({side === OrderSide.BUY ? fromToken.symbol : toToken.symbol})
              </Typography>
              <Typography>~ {formatTokenAmount(side === OrderSide.BUY ? fromTokenAmount : toTokenAmount)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="text.secondary">
                Pay ({side === OrderSide.BUY ? toToken.symbol : fromToken.symbol})
              </Typography>
              <Typography>{formatTokenAmount(side === OrderSide.BUY ? toTokenAmount : fromTokenAmount)}</Typography>
            </Grid>
          </>
        )}

        {strategy === OrderExecutionStrategy.ORDER && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Order type
            </Typography>
            <Typography>{ORDER_TYPE_NAME[type]}</Typography>
          </Grid>
        )}
        {isMultiSliceOrderExecutionStrategy(strategy) && strategy === OrderExecutionStrategy.TWAP && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Duration ({tradingDurationUnit})
            </Typography>
            <Typography>{tradingDuration || "-"}</Typography>
          </Grid>
        )}
        {/* clipSizeValue, clipSizeType */}
        {isMultiSliceOrderExecutionStrategy(strategy) &&
          clipSizeType !== ClipSizeType.AUTOMATIC &&
          clipSizeType !== ClipSizeType.NB_OF_CHILD_ORDERS && (
            <Grid item xs={6}>
              <Typography variant="caption" color="text.secondary">
                Clip size
              </Typography>
              <Typography>
                {clipSizeValue}
                {clipSizeType === ClipSizeType.PERCENTAGE ? "%" : ""}
              </Typography>
            </Grid>
          )}
        {isMultiSliceOrderExecutionStrategy(strategy) && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS ? "Number of child orders" : "Number of slices"}
            </Typography>
            <Typography>{numberOfSlices}</Typography>
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            {isMultiSliceOrderExecutionStrategy(strategy) ? "Price protection" : "Slippage"}
          </Typography>
          <Typography>
            {isMultiSliceOrderExecutionStrategy(strategy)
              ? priceProtection === PRICE_PROTECTION_OPTIONS[0]
                ? PRICE_PROTECTION_OPTIONS[0]
                : slippage + "%"
              : slippage + "%"}
          </Typography>
        </Grid>
        {isMultiSliceOrderExecutionStrategy(strategy) && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Swap slippage
            </Typography>
            <Typography>{childSlippage}%</Typography>
          </Grid>
        )}
        {strategy === OrderExecutionStrategy.LIMIT && !!limitPrice && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Limit price
            </Typography>
            <Typography>{limitPrice}</Typography>
          </Grid>
        )}
        {[OrderExecutionStrategy.TWAP].includes(strategy) && extendDuration && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Extend duration
            </Typography>
            <Typography>{extendDuration ? "Yes" : "No"}</Typography>
          </Grid>
        )}
        {/*trigger condition, trigger price*/}
        {(strategy === OrderExecutionStrategy.TWAP || strategy === OrderExecutionStrategy.ICEBERG) &&
        triggerPrice &&
        triggerCondition ? (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Trigger price {triggerCondition === TriggerCondition.ABOVE ? "△" : "▽"}{" "}
              {TRIGGER_CONDITION_LABEL[triggerCondition]}
            </Typography>
            <Typography>{triggerPrice}</Typography>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};
