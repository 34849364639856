import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useGroupDataModal } from "./use-group-data-modal";
import { CefiExchangeMarketType, OrderSymbol } from "@src/store/apis/anbotoApi/types";

type SymbolDataFromParams = {
  symbol: OrderSymbol["symbol"];
  exchangeName: OrderSymbol["exchange"];
  marketType: OrderSymbol["market_type"];
  account: string;
};

export const useSymbolDataFromSearchParamsModal = ({ trigger }: { trigger: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<SymbolDataFromParams>({} as SymbolDataFromParams);
  const paramsSymbol = searchParams.get("symbol") as string;
  const account = searchParams.get("account") || "";
  const showGroupDataModal = useGroupDataModal();

  useEffect(() => {
    if (paramsSymbol && trigger) {
      const [symbol, exchangeName, marketType] = decodeURIComponent(paramsSymbol).split("_");

      setData({
        symbol,
        exchangeName,
        marketType: marketType as CefiExchangeMarketType,
        account,
      });

      searchParams.delete("symbol");
      searchParams.delete("exchange");
      searchParams.delete("marketType");
      searchParams.delete("account");
      setSearchParams(searchParams, { replace: true });
    }
  }, [paramsSymbol, account, trigger]);

  useEffect(() => {
    if (data) {
      const { symbol, exchangeName, marketType } = data;

      showGroupDataModal(symbol, exchangeName, marketType, account);
    }
  }, [data.symbol, data.exchangeName, data.marketType, data.account]);
};
