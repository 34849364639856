import React from "react";
import _omit from "lodash/omit";
import { Box, BoxProps } from "@mui/material";
import { ParentOrderErrorCode, ParentOrderStatuses } from "@src/store/apis/anbotoApi/types";

const OrderRow = (
  props: React.PropsWithChildren<
    {
      orderId?: string;
      filledQuantity?: number;
      averagePrice?: number;
      errorCode?: ParentOrderErrorCode;
      leavesQuantity?: number;
      status?: ParentOrderStatuses;
      loading?: boolean;
      expanded?: boolean;
    } & BoxProps
  >
) => {
  return (
    <Box
      {..._omit(
        props,
        "orderId",
        "filledQuantity",
        "averagePrice",
        "errorCode",
        "leavesQuantity",
        "status",
        "loading",
        "expanded"
      )}
    />
  );
};

export const MemoizedOrderRow = React.memo(
  OrderRow,
  (prev, next) =>
    prev.orderId === next.orderId &&
    prev.filledQuantity === next.filledQuantity &&
    prev.averagePrice === next.averagePrice &&
    prev.errorCode === next.errorCode &&
    prev.leavesQuantity === next.leavesQuantity &&
    prev.status === next.status &&
    prev.loading === next.loading &&
    prev.expanded === next.expanded
);

MemoizedOrderRow.displayName = "MemoizedOrderRow";
