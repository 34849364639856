import React, { useMemo } from "react";
import BigNumber from "bignumber.js";
import { ColumnDef } from "@tanstack/table-core";
import { flexRender } from "@tanstack/react-table";
import { columnHelper, getColumns } from "./get-columns";
import { ClosePositionButton } from "./close-position-button";
import { Position } from "./types";
import { useFuturePositions } from "./use-future-positions";
import { CefiExchangeId, CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { useAppDispatch } from "@src/store/hooks";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { usePermissions } from "@src/hooks/use-permissions";
import {
  AnbotoTable,
  MemoizedTableCell,
  MemoizedTableHeaderCell,
  MemoizedTableRow,
  TableRow,
  renderSortArrows,
} from "@src/components/anboto-table";

export const PositionsTable = ({
  onClosePosition,
  onPositionClick,
}: {
  onClosePosition?: (position: Position, amount: string) => void;
  onPositionClick?: (position: Position) => void;
}) => {
  // const search = useAppSelector((state) => state.ordersTable.search);
  const dispatch = useAppDispatch();
  const permissions = usePermissions();
  const data = useFuturePositions();

  const columns = useMemo<ColumnDef<Position, any>[]>(() => {
    return [
      ...getColumns({ onPositionClick }),
      ...(permissions?.trade && onClosePosition
        ? [
            columnHelper.display({
              id: "action",
              header: "",
              size: 70,
              cell: ({ row }) => <ClosePositionButton position={row.original} onClosePosition={closePosition} />,
            }),
          ]
        : []),
    ];
  }, [permissions?.trade]);

  const closePosition = async (position: Position, amount: number) => {
    const symbol = {
      symbol: position.symbol,
      market_type: CefiExchangeMarketType.FUTURE,
      exchange: CEFI_EXCHANGE_NAME[CefiExchangeId.WOOX],
    };

    const { data } = await dispatch(
      anbotoApi.endpoints.getExchangesSymbolData.initiate({ ...symbol, exchange: CefiExchangeId.WOOX })
    ).unwrap();

    const presicion = data?.amount_precision || 0;

    const quantity = new BigNumber(amount).dp(presicion).toString();

    onClosePosition && onClosePosition(position, quantity);
  };

  return (
    <AnbotoTable<Position>
      columns={columns}
      data={data}
      getRowId={(row) => row.id}
      error={false}
      noDataText="No positions yet."
      headerRowRenderer={(headerGroups) =>
        headerGroups.map((headerGroup) => (
          <TableRow
            key={headerGroup.id}
            sx={{
              zIndex: 2,
              height: 44,
              position: "sticky",
              top: 0,
              background: (theme) => theme.palette.background.paper,
            }}
          >
            {headerGroup.headers.map((header, index) => (
              <MemoizedTableHeaderCell
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                width={header.column.getSize()}
                sx={{
                  cursor: header.column.getCanSort() ? "pointer" : "",
                  alignItems: "flex-end",
                }}
                flexGrow={index ? undefined : "0 !important"}
                shouldUpdate={header.column.getIsSorted()}
              >
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                {renderSortArrows(header.column.getIsSorted())}
              </MemoizedTableHeaderCell>
            ))}
          </TableRow>
        ))
      }
      rowRenderer={(row) => (
        <MemoizedTableRow key={row.id} shouldUpdate={Object.values(row.original).join("")}>
          {row.getVisibleCells().map((cell, index, arr) => {
            let shouldUpdate = cell.getValue();

            if ([1, arr.length - 1].includes(index))
              shouldUpdate = cell.row.original.symbol + cell.row.original.exchange;

            return (
              <MemoizedTableCell
                cell={cell}
                key={cell.id}
                width={cell.column.getSize()}
                flexGrow={index ? undefined : "0 !important"}
                shouldUpdate={index && shouldUpdate}
              />
            );
          })}
        </MemoizedTableRow>
      )}
    />
  );
};
