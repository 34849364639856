import { useRef, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { getMarketDataSubscription, getAccountSubscription, getPortfolioSubscription } from "@src/subscriptions";
import { useAuth } from "@src/features/auth/hooks/use-auth";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useAppDispatch } from "@src/store/hooks";
import { setInitialDataLoading } from "@src/store/slices/app-state.slice";

export const useInitiateUserData = () => {
  const { authenticated } = useAuth();
  const snackbar = useSnackbar();
  const errors = useRef<Record<string, boolean>>({});
  const dispatch = useAppDispatch();

  const [getAccounts, { error: getAccountsError }] = anbotoApi.useLazyGetUserAccountsQuery();
  const [getUserDetails, { error: getUserDetailsError }] = anbotoApi.useLazyGetUserDetailsQuery();
  const [getExchangeConfigs, { error: getExchangeConfigsError }] = anbotoApi.useLazyGetExchangeConfigsQuery();
  const [getExchanges, { error: getExchangesError }] = anbotoApi.useLazyGetExchangesQuery();

  const [accounts, setAccounts] = useState<any>();
  const [ready, setReady] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const error = getAccountsError || getUserDetailsError || getExchangeConfigsError;

  const init = async () => {
    dispatch(setInitialDataLoading(true));

    try {
      const { team_uuid } = await getUserDetails(null).unwrap();

      getMarketDataSubscription();
      getAccountSubscription(team_uuid);
      getPortfolioSubscription(team_uuid);

      setSubscribed(true);

      await Promise.allSettled([
        getExchangeConfigs(team_uuid),
        getAccounts(null).then(({ data }) => {
          setAccounts(data?.results);
        }),
        getExchanges({ isDefi: false }),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setReady(true);
      dispatch(setInitialDataLoading(false));
    }
  };

  useEffect(() => {
    if (authenticated) init();
  }, [authenticated]);

  useEffect(() => {
    if (!errors.current.getAccountsError && getAccountsError) {
      errors.current.getAccountsError = true;
      snackbar.enqueueSnackbar("Fetch user accounts error", { variant: "error" });
    }
    if (!errors.current.getUserDetailsError && getUserDetailsError) {
      errors.current.getUserDetailsError = true;
      snackbar.enqueueSnackbar("Fetch user details error", { variant: "error" });
    }
    if (!errors.current.getExchangeConfigsError && getExchangeConfigsError) {
      errors.current.getExchangeConfigsError = true;
      snackbar.enqueueSnackbar("Fetch exchange configs error", { variant: "error" });
    }
    if (!errors.current.getExchangesError && getExchangesError) {
      errors.current.getExchangesError = true;
      snackbar.enqueueSnackbar("Fetch exchanges error", { variant: "error" });
    }
  }, [getAccountsError, getUserDetailsError, getExchangeConfigsError, getExchangesError]);

  return { ready, accounts, authenticated, subscribed, error };
};
