import { useEffect } from "react";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";

export const useOrderFormTriggerAmounts = (): void => {
  const orderForm = useDefiOrderFormContext();
  const account = orderForm.watch("account");
  const buyTokenToEthRate = orderForm.watch("buyTokenToEthRate");
  const chainNativeCoinPrice = orderForm.watch("chainNativeCoinPrice");
  const fromTokenAmount = orderForm.watch("fromTokenAmount");
  const fromTokenBalance = orderForm.watch("fromTokenBalance");
  const toTokenAmount = orderForm.watch("toTokenAmount");
  const toTokenBalance = orderForm.watch("toTokenBalance");
  const sellTokenToEthRate = orderForm.watch("sellTokenToEthRate");
  const clipSizeValue = orderForm.watch("clipSizeValue");
  const maxFeeAbsolute = orderForm.watch("maxFeeAbsolute");
  const strategy = orderForm.watch("strategy");

  const triggerTokenAmounts = async () => {
    await orderForm.trigger("fromTokenAmount");
    await orderForm.trigger("toTokenAmount");
    await orderForm.trigger("maxGasPrice");
  };

  const triggerAmountIfNotEmpty = async () => {
    const amountsNotEmpty = fromTokenAmount && toTokenAmount;

    if (!fromTokenAmount || !toTokenAmount || !amountsNotEmpty) {
      return;
    }

    await triggerTokenAmounts();
  };

  useEffect(() => {
    void triggerAmountIfNotEmpty();
  }, [
    account,
    buyTokenToEthRate,
    chainNativeCoinPrice,
    maxFeeAbsolute,
    clipSizeValue,
    fromTokenBalance,
    sellTokenToEthRate,
    toTokenBalance,
    strategy,
  ]);
};
