import React from "react";
import { Paper, Stack, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { AnbotoButtonGroup, AnbotoButtonGroupOption } from "@src/components/ui/anboto-button-group";
import { METRICS_PATH, MetricsPageType } from "@src/pages/metrics/constant";

const periodFilterOptions: AnbotoButtonGroupOption<MetricsPageType>[] = [
  { label: "Trading stats", value: MetricsPageType.TRADING_STATS },
  { label: "Post trade analysis", value: MetricsPageType.POST_TRADE_ANALYSIS },
];

export const MetricsHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pageType = location.pathname.includes(`${METRICS_PATH}/${MetricsPageType.POST_TRADE_ANALYSIS}`)
    ? MetricsPageType.POST_TRADE_ANALYSIS
    : MetricsPageType.TRADING_STATS;

  const changePageType = () => {
    if (pageType === MetricsPageType.POST_TRADE_ANALYSIS) {
      navigate(`${METRICS_PATH}/${MetricsPageType.TRADING_STATS}`);
    } else {
      navigate(`${METRICS_PATH}/${MetricsPageType.POST_TRADE_ANALYSIS}`);
    }
  };

  return (
    <Root>
      <Stack direction="row" pl={3}>
        <MetricsButtonGroup size="small" options={periodFilterOptions} onChange={changePageType} value={pageType} />
      </Stack>
    </Root>
  );
};

const Root = styled(Paper)({
  position: "relative",
  zIndex: 1,
  padding: "10px 0",
});

const MetricsButtonGroup = styled(AnbotoButtonGroup)({
  [".MuiButton-text"]: {
    width: 198,
  },
});
