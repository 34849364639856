import React from "react";
import {
  Stack,
  Typography,
  BoxProps,
  styled,
  Box,
  useTheme,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useSnackbar } from "notistack";
import { TradePermissionButtonGroup } from "./trade-permissions-button-group";
import { UserAccountMember, UserAccountsResult, UserPermissions } from "@src/store/apis/anbotoApi/types";
import { ColorizedAvatar } from "@src/components/avatars-list/colorized-avatar";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";

type MembersListProps = {
  account: Partial<UserAccountsResult>;
  isAdmin: boolean;
} & BoxProps;

type MemberStatus = "pending" | "joined" | "admin";

export const MemberStatus = ({ status }: { status: MemberStatus }) => {
  const theme = useTheme();
  const colors = {
    pending: theme.custom.colors.dim,
    joined: theme.custom.colors.secondary,
    admin: theme.custom.colors.primary,
  };
  return (
    <Stack
      justifyContent="center"
      sx={{
        height: 37,
        borderRadius: 1,
        borderColor: colors[status],
        borderWidth: 1,
        borderStyle: "solid",
        paddingLeft: 0,
        paddingRight: 0,
        textTransform: "uppercase",
        userSelect: "none",
        width: 80,
        textAlign: "center",
      }}
    >
      <Typography
        variant="body2"
        fontWeight="bold"
        color={(theme) => (status === "pending" ? theme.custom.colors.dim : undefined)}
      >
        {status}
      </Typography>
    </Stack>
  );
};

const getPermissions = (userId: string, permissions: UserPermissions[] = []) =>
  permissions.find(({ user_uuid }) => user_uuid === userId);

export const MembersList = ({ account, isAdmin, ...props }: MembersListProps) => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const [selectedUser, setSelectedUser] = React.useState<UserAccountMember | null>(null);
  const admin = account?.members?.find(({ user_uuid }) => user_uuid === account.admin_uuid);
  const members = [
    ...(admin ? [{ ...admin, status: "admin" }] : []),
    ...(account?.members?.filter((m) => m.user_uuid !== account.admin_uuid).map((m) => ({ ...m, status: "joined" })) ||
      []),
    ...(account?.invited_members?.map((m) => ({ ...m, status: "pending" })) || []),
  ];

  const [removeUser, removeUserMutation] = anbotoApi.useRemoveUserFromAccountMutation();
  const [setReadOnly, setReadOnlyMutation] = anbotoApi.useSetMemberReadOnlyMutation();

  const handleRemoveUser = async () => {
    if (!selectedUser) return false;

    try {
      await removeUser({ email: selectedUser.email, accountId: account.uuid || "" }).unwrap();
      snackbar.enqueueSnackbar(`${selectedUser.email} removed from ${account.name} account`, { variant: "success" });
      setSelectedUser(null);
    } catch (error) {
      console.log({ error });
      snackbar.enqueueSnackbar(JSON.stringify(error?.data), { variant: "error" });
    }
  };

  const onTradePermissionChange = async (accountId = "", email: string, tradePermission: boolean) => {
    if (!accountId) return false;

    try {
      await setReadOnly({ accountId, email, readOnly: !tradePermission }).unwrap();

      snackbar.enqueueSnackbar(`Permissions for ${email} updated`, { variant: "success" });
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar(parseAnbotoRequestError(error), { variant: "error" });
    }
  };

  return (
    <Root {...props}>
      {members.map(({ first_name, last_name, email, status, user_uuid }, index, arr) => {
        const permissions = getPermissions(user_uuid, account.permissions);
        const memberIsAdmin = account.admin_uuid === user_uuid;

        return (
          <Stack
            key={email}
            direction="row"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: index ? 2.5 : 0, mb: index < arr.length ? 2.5 : 0, pr: isAdmin && memberIsAdmin ? 6 : 0 }}
          >
            <Stack direction="row" gap={1} alignItems="center" sx={{ flex: 1 }}>
              <ColorizedAvatar user={{ firstName: first_name || "", lastName: last_name || "", email: email || "" }} />
              <Typography sx={{ maxWidth: 300, overflow: "hidden", textOverflow: "ellipsis" }}>{email}</Typography>
            </Stack>
            <Stack direction="row" gap={1} alignItems="center">
              {status !== "pending" && (
                <TradePermissionButtonGroup
                  disabled={setReadOnlyMutation.isLoading || !isAdmin}
                  value={!permissions?.read_only}
                  onChange={(value: boolean) => onTradePermissionChange(account.uuid, email, value)}
                />
              )}
              <MemberStatus status={status as MemberStatus} />
            </Stack>
            {isAdmin && status !== "admin" && (
              <IconButton
                onClick={() => setSelectedUser(arr[index])}
                disabled={removeUserMutation.isLoading && selectedUser?.email === email}
              >
                {removeUserMutation.isLoading && selectedUser?.email === email ? (
                  <CircularProgress size={24} />
                ) : (
                  <RemoveCircleOutlineIcon sx={{ color: theme.custom.colors.dim }} />
                )}
              </IconButton>
            )}
          </Stack>
        );
      })}
      {!!selectedUser && (
        <Dialog
          open
          onClose={() => setSelectedUser(null)}
          sx={{ "& .MuiPaper-root": { background: `#232C2F !important` } }}
        >
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to remove{" "}
              <b>
                {`${selectedUser?.first_name || ""} ${selectedUser?.last_name || ""} (${selectedUser.email})`.trim()}
              </b>{" "}
              from <b>{account.name}</b> acount?
            </Typography>
          </DialogContent>
          <DialogActions>
            <AnbotoButton
              variant="outlined"
              size="small"
              onClick={() => setSelectedUser(null)}
              disabled={removeUserMutation.isLoading}
            >
              No
            </AnbotoButton>
            <AnbotoButton
              variant="outlined"
              size="small"
              onClick={handleRemoveUser}
              autoFocus
              loading={removeUserMutation.isLoading}
            >
              Yes
            </AnbotoButton>
          </DialogActions>
        </Dialog>
      )}
    </Root>
  );
};

const Root = styled(Box)({
  overflow: "auto",
});
