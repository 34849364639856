import { styled } from "@mui/material";
import { styledOptions } from "@src/utils/styled-options";

export const withFlatStyle = (component: any) =>
  styled(
    component,
    styledOptions
  )(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      background: theme.palette.background.default,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.background.default,
    },
    "& .MuiSelect-icon": {
      color: "#828C92",
      right: 10,
      width: 18,
      height: 18,
      top: `calc(50% - 9px)`,
    },
    "& .MuiInputAdornment-root .MuiOutlinedInput-root": {
      background: "transparent",
      color: "#B0BCC2",
    },
  }));

export const withDarkFlatStyle = (component: any) =>
  styled(component)(() => ({
    "& .MuiOutlinedInput-root": {
      border: "none",
      color: "#B0BCC2",
      backgroundColor: "#031116",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      color: "#B0BCC2",
    },
    "& .MuiInputAdornment-root .MuiOutlinedInput-root": {
      color: "#B0BCC2",
      border: "none",
    },
  }));

export const withLightFlatStyle = (component: any) =>
  styled(component)(({ theme }) => ({
    "&.MuiButtonBase-root": {
      background: theme.custom.background.secondary,
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
      color: "#F1F7FA",
      backgroundColor: theme.custom.background.secondary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      color: "#F1F7FA",
    },
    "& .MuiInputAdornment-root .MuiOutlinedInput-root": {
      color: "#F1F7FA",
      border: "none",
    },
  }));
