import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      border: {
        default: string;
      };
      background: {
        default: string;
        secondary: string;
        darkGrey: string;
        inputAdornment: {
          main: string;
          light: string;
        };
        progress: {
          notReady: string;
          ready: string;
        };
      };
      text: {
        inputAdornment: string;
        dim: string;
        label: string;
      };
      colors: {
        primary: string;
        secondary: string;
        dim: string;
        gray: string;
        darkBlue: string;
        darkGreen: string;
        darkOrange: string;
      };
      statuses: {
        pendingNew: string;
        accepted: string;
        rejected: string;
        partiallyFilled: string;
        pendingPause: string;
        paused: string;
      };
    };
  }

  interface ThemeOptions {
    custom?: {
      border: {
        default: string;
      };
      background: {
        default: string;
        secondary: string;
        darkGrey: string;
        inputAdornment: {
          main: string;
          light: string;
        };
        progress: {
          notReady: string;
          ready: string;
        };
      };
      text: {
        inputAdornment: string;
        dim: string;
        label: string;
      };
      colors: {
        primary: string;
        secondary: string;
        dim: string;
        gray: string;
        darkBlue: string;
        darkGreen: string;
        darkOrange: string;
      };
      statuses: {
        pending_new: string;
        accepted: string;
        rejected: string;
        partially_filled: string;
        pending_pause: string;
        paused: string;
      };
    };
  }
}

export const muiTheme = createTheme({
  custom: {
    border: {
      default: "#4A5053",
    },
    background: {
      default: "#192022",
      secondary: "#232C2F",
      darkGrey: "#2F3639",
      inputAdornment: {
        main: "#3B4043",
        light: "#4A5053",
      },
      progress: {
        notReady: "#4A5053",
        ready: "# ",
      },
    },
    text: {
      dim: "#B0BCC2",
      inputAdornment: "#B0BCC2",
      label: "#B0BCC2",
    },
    colors: {
      primary: "#2C72E3",
      secondary: "#179B83",
      dim: "#8A9296",
      gray: "#3B4043",
      darkBlue: "#0A1C39",
      darkGreen: "#01382e",
      darkOrange: "#805e14",
    },
    statuses: {
      pending_new: "#56C3F2",
      accepted: "#56C3F2",
      rejected: "#C3462B",
      partially_filled: "#179B83",
      pending_pause: "#2C72E3",
      paused: "#2C72E3",
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#2C72E3",
      light: "#2C72E3",
      dark: "#2C72E3",
    },
    error: {
      main: "#C3462B",
      light: "#C3462B",
      dark: "#C3462B",
    },
    warning: {
      main: "#ECAC27",
      light: "#ECAC27",
      dark: "#ECAC27",
    },
    background: {
      default: "#031116",
      paper: "#131A1C",
    },
    text: {
      primary: "#B0BCC2",
      secondary: "#8A9296",
      disabled: "#636B6F",
    },
    success: {
      main: "#179B83",
      light: "#179B83",
      dark: "#179B83",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          // backgroundColor: "#031116",
          backgroundImage: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
          },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#232C2F",
          padding: "8px 16px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "16px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #031116 inset",
            WebkitTextFillColor: "#fff",
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          minWidth: 3,
          backgroundColor: "#636B6F",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            color: "#8A9296",
          },
        },
      },
    },
  },
});
