import React, { forwardRef } from "react";
import { MenuItem, Tooltip } from "@mui/material";
import { InfoRounded } from "@mui/icons-material";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { EXPIRATION_NAME, EXPIRATIONS } from "@src/pages/defi/constants";

type ExpirationFieldProps = ControllerRenderProps & AnbotoTextFieldProps;

export const ExpirationField = forwardRef((props: ExpirationFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    fullWidth
    size="small"
    select
    inputRef={ref}
    InputProps={{
      endAdornment: (
        <Tooltip title="When an order should expire and be automatically cancelled. This is useful when you only want to set a limit price for a week, etc.  GTC : No expiration">
          <InfoRounded
            sx={{
              color: (theme) => theme.palette.text.secondary,
              position: "absolute",
              right: 35,
              cursor: "pointer",
              width: 14,
              height: 14,
            }}
          />
        </Tooltip>
      ),
    }}
    label="Expiration"
    sx={{
      // because of endAdornment
      "& .MuiOutlinedInput-root.MuiInputBase-root": {
        paddingRight: 0,
      },
    }}
  >
    {EXPIRATIONS.map((x) => (
      <MenuItem key={x} value={x}>
        {EXPIRATION_NAME[x]}
      </MenuItem>
    ))}
  </AnbotoTextField>
));

ExpirationField.displayName = "ExpirationField";
