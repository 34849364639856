import { useEffect, useState } from "react";
import { CHAIN_NAME } from "@src/pages/defi/constants";
import { ACTION_NAME } from "@src/pages/settings/fee-manager/constants";
import { filterTransactionsByDate, getChainName } from "@src/pages/settings/fee-manager/utils/helper";
import { GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";
import { AccountingLine, Operation } from "@src/pages/settings/fee-manager/api/types";
import { Action } from "@src/store/slices/feeManagerSlice";

type useTransactionsTableFiltersProps = {
  searchString: string;
  period: GetOrdersPeriod;
  filterBy: string[];
  data: AccountingLine[];
};
export const useTransactionsTableFilters = ({
  searchString,
  period,
  filterBy,
  data,
}: useTransactionsTableFiltersProps) => {
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (data) {
      const networks = filterBy.filter((x) => Object.values(CHAIN_NAME).includes(x));
      const actions = filterBy.filter((x) => Object.values(ACTION_NAME).includes(x) || x === "Collection");

      let currentFilteredData = [...filterTransactionsByDate(data, period)];

      if (actions.length) currentFilteredData = currentFilteredData.filter((obj) => actions.includes(obj.operation));

      if (networks.length)
        currentFilteredData = currentFilteredData.filter((x) => {
          let isNetworkIdInData = false;

          Object.values(x.token_data).forEach((token) => {
            if (filterBy.includes(getChainName(token))) {
              isNetworkIdInData = true;
              return;
            }
          });

          return isNetworkIdInData;
        });

      if (searchString) {
        const searchIndexes =
          currentFilteredData.map(({ operation, token_data, transactions_data }, index) => ({
            id: index,
            index: (operation === Operation.Collection
              ? ACTION_NAME[Action.FEE_CHARGE]
              : operation +
                Object.values(transactions_data).join("") +
                Object.values(token_data)
                  .map((x) => getChainName(x))
                  .join("")
            ).toLowerCase(),
          })) || [];

        const res = searchIndexes.reduce((prev, { id, index }) => {
          if (searchString.split(" ").every((word) => index.includes(word))) {
            prev[id] = true;
          }
          return prev;
        }, {});

        currentFilteredData = currentFilteredData.filter((x, i) => Object.keys(res).includes(i.toString()));
      }

      setFilteredData(currentFilteredData);
    }
  }, [period, data.length, filterBy, searchString]);

  return filteredData;
};
