import _pick from "lodash/pick";
import { AnbotoSubscription, AnbotoSubscriptionOptions } from "../anboto-subscription";
import { MarketDataMessage, MarketDataSubscribeData } from "../types";

const idKeys = ["data_type", "exchange", "market_type", "params", "symbol"];

export class MarketDataSubsctiption extends AnbotoSubscription<MarketDataSubscribeData, MarketDataMessage> {
  constructor(opts: AnbotoSubscriptionOptions) {
    super(opts);
  }

  getId = (data: MarketDataSubscribeData) => {
    return JSON.stringify(_pick(data, idKeys));
  };

  getIdFromMsg = (data: MarketDataMessage): string => {
    return JSON.stringify(_pick(data, idKeys));
  };

  transformMsg = (message: MarketDataMessage) => message.data;
}
