import React from "react";
import { Stack, StackProps, Typography, styled } from "@mui/material";

export const Copyright = (props: StackProps) => (
  <Stack {...props}>
    <Copy variant="body2">Copyright Anboto Labs © All Rights Reserved</Copy>
  </Stack>
);

const Copy = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
