import { InputLabel, InputLabelProps, styled } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StyledInputLabelProps = InputLabelProps & { fieldstate: any };

export const FormInputLabel = styled(InputLabel)<StyledInputLabelProps>(({ fieldstate, theme }) => ({
  ["&.Mui-focused"]: {
    color: theme.custom.text.dim,
  },
  color: fieldstate.error ? theme.palette.error.main : theme.custom.text.dim,
}));
