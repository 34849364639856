import React, { FC } from "react";
import { useDisconnect } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Box, IconButton, InputAdornment, Stack, styled, Tooltip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { ContentCopy, Logout } from "@mui/icons-material";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { withFlatStyle } from "../with-flat-style";
import { AnbotoIconButton } from "../ui/anboto-icon-button";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { truncateAccount } from "@src/pages/defi/utils";
import { SelectArrow } from "@src/components/select-arrow";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { useTextCopy } from "@src/pages/portfolio/components/send-crypto/hooks";
import { SelectSpeedButton } from "@src/pages/defi/order-form-card-no-gas/fields/select-speed-button";
import { useWalletName } from "@src/hooks/useWalletName";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { setIsTokenInfoChartPool } from "@src/store/slices/decentralizedExchangePageSlice";

export interface RainbowKitConnectButtonProps {
  formAccount?: string;
  error?: boolean;
}

export const RainbowKitConnectButton: FC<RainbowKitConnectButtonProps> = ({ formAccount, error, ...props }) => {
  const dispatch = useAppDispatch();
  const isTokenInfoChartPool = useAppSelector((state) => state.decentralizedExchangePage.isTokenInfoChartPool);

  const { disconnect } = useDisconnect();
  const { onCopyClick } = useTextCopy();
  const { name } = useWalletName(formAccount || "");

  const onCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (formAccount) {
      onCopyClick(formAccount, "Address");
    }
  };

  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready && account && chain && (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <Stack
            mb={2}
            direction="row"
            gap={2}
            justifyContent="space-between"
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <WalletConnectButton
                    onClick={openConnectModal}
                    variant="outlined"
                    error={error}
                    className="connect-wallet-button"
                  >
                    Connect wallet
                  </WalletConnectButton>
                );
              }
              if (chain?.unsupported) {
                openChainModal();
                return (
                  <AnbotoButton
                    sx={{ height: 32, background: "#f4433614", fontSize: 14, fontWeight: 400 }}
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={openChainModal}
                    type="button"
                  >
                    Wrong network
                  </AnbotoButton>
                );
              }

              return (
                <div style={{ display: "flex", gap: 8 }}>
                  <StyledAnbotoTextField
                    {...props}
                    size="small"
                    sx={{ "& .MuiInputBase-root": { pl: 1, pr: 0 } }}
                    onClick={openAccountModal}
                    value={formAccount ? name || truncateAccount(formAccount) : "Connect Wallet"}
                    inputProps={{ style: { cursor: "pointer" } }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SelectArrow cursor="pointer" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={onCopy}>
                            <ContentCopy
                              sx={{ color: (theme) => theme.palette.text.secondary, width: 16, height: 16 }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {connected && (
                    <AnbotoButton
                      size="small"
                      sx={{
                        padding: 0,
                        width: 32,
                        height: 32,
                        minWidth: 0,
                      }}
                      onClick={() => disconnect()}
                      variant="flat"
                    >
                      <Logout sx={{ width: 16, height: 16 }} />
                    </AnbotoButton>
                  )}
                </div>
              );
            })()}
            {connected && (
              <Stack direction="row" gap={1} justifyContent="center">
                <Tooltip title="Show chart for form pair">
                  <Box>
                    <AnbotoIconButton
                      flat
                      onClick={() => dispatch(setIsTokenInfoChartPool(false))}
                      size="small"
                      sx={{
                        width: 30,
                        height: 30,
                        border: isTokenInfoChartPool ? "1px solid #031116" : "1px solid #2C72E3",
                      }}
                    >
                      <ShowChartIcon sx={{ width: 16 }} />
                    </AnbotoIconButton>
                  </Box>
                </Tooltip>
                <SelectSpeedButton />
                <AnbotoIconButton onClick={openChainModal} flat sx={{ width: 32, height: 32 }}>
                  {chain?.iconUrl ? (
                    <img alt={chain?.name ?? "Chain icon"} src={chain?.iconUrl} style={{ width: 16, height: 16 }} />
                  ) : (
                    <CircleIcon sx={{ color: "#031116" }} />
                  )}
                </AnbotoIconButton>
              </Stack>
            )}
          </Stack>
        );
      }}
    </ConnectButton.Custom>
  );
};

const WalletConnectButton = styled(AnbotoButton)<{ error?: boolean }>(({ error, theme }) => ({
  width: 240,
  height: 32,
  background: error
    ? `linear-gradient(0deg, rgba(3, 19, 25, 0.24), rgba(3, 19, 25, 0.24)), ${theme.palette.error.main}`
    : `linear-gradient(114.93deg, ${theme.palette.primary.main} 0%, #56C3F2 100%)`,
}));

const StyledAnbotoTextField = withFlatStyle(AnbotoTextField);
