import React from "react";
import {
  Step,
  stepClasses,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  stepLabelClasses,
  Stepper,
  styled,
} from "@mui/material";
import StepIcon from "@src/components/anboto-stepper/step-icon";

interface AnbotoStepperProps {
  steps: string[];
  activeStep: number;
}

const AnbotoVerticalStepper = ({ steps, activeStep }: AnbotoStepperProps) => {
  return (
    <Stepper activeStep={activeStep} orientation="vertical" connector={<Connector />}>
      {steps.map((label) => (
        <StepRoot key={label}>
          <Label StepIconComponent={StepIcon}>{label}</Label>
        </StepRoot>
      ))}
    </Stepper>
  );
};

const StepRoot = styled(Step)(() => ({
  [`&.${stepClasses.root}`]: {
    position: "relative",
    marginTop: -7,
  },
}));
const Label = styled(StepLabel)(() => ({
  [`&.${stepLabelClasses.root}`]: {
    padding: 0,
  },
}));

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    height: 29,
    marginLeft: 8,
    position: "relative",
    top: -3,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#179B83",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#179B83",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[800],
    borderTopWidth: 3,
    borderRadius: 1,
    height: "100%",
  },
}));

export default AnbotoVerticalStepper;
