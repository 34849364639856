import React, { FC, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxControl, CheckboxForm } from "./styled";
import {
  AllocationCefiFundsTable,
  AllocationCefiMenu,
  filterCefiAllocations,
  filterCefiDataByBalance,
} from "@src/pages/portfolio";
import { MarketTypeSelector } from "@src/pages/portfolio/components/market-type-selector";
import { CefiAllocation, CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";
import { RootState } from "@src/store/types";
import {
  DEFAULT_CEFI_ALLOCATION_ITEM_ACTIVE,
  DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE,
  setCefiExchangeMarketType,
} from "@src/store/slices/portfolioSlice";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";

interface AllocationCefiFundsDirectProps {
  data?: CefiAllocation[];
  isFetching: boolean;
  isError: boolean;
  refetch: () => void;
}

export const AllocationCefiFundsDirect: FC<AllocationCefiFundsDirectProps> = ({
  data,
  isError,
  isFetching,
  refetch,
}) => {
  const [hideSmallBalance, onHideSmallBalance] = useState<boolean>(true);

  const dispatch = useDispatch();
  const selectedAllocationItemName = useSelector((state: RootState) => state.portfolio.activeCefiAllocationItem);
  const marketType = useSelector((state: RootState) => state.portfolio.marketType);
  const cefiTotalBalance = useSelector((state: RootState) => state.portfolio.cefiTotalBalance);
  const dataFilteredByBalance = filterCefiDataByBalance(hideSmallBalance, cefiTotalBalance, data);

  const filteredData = filterCefiAllocations(
    {
      market_type: marketType,
      subaccount:
        selectedAllocationItemName === DEFAULT_CEFI_ALLOCATION_ITEM_ACTIVE ||
        selectedAllocationItemName === DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE
          ? null
          : selectedAllocationItemName,
    },
    dataFilteredByBalance
  );

  const setMarketType = (mType: CefiExchangeMarketType) => {
    dispatch(setCefiExchangeMarketType(mType));
  };

  const isPositions = !!filteredData.positions?.length;

  filteredData.positions?.forEach((position) => {
    position.token_symbol = getOrderSymbolLabel(
      {
        symbol: position.token_symbol,
        exchange: CEFI_EXCHANGE_NAME[position.exchange_id],
        market_type: CefiExchangeMarketType.FUTURE,
      },
      ["symbol"]
    );

    return position;
  });

  return (
    <Stack display="flex" flexDirection="row" gap="21px">
      <AllocationCefiMenu />
      <Stack flexGrow={1}>
        <Stack display="flex" flexDirection="row" justifyContent="space-between" marginBottom={1}>
          <MarketTypeSelector
            marketType={marketType}
            setMarketType={setMarketType}
            spotAmount={filteredData.balances.spot}
            futureAmount={filteredData.balances.future}
            onHideSmallBalance={onHideSmallBalance}
          />
          {marketType === CefiExchangeMarketType.SPOT && (
            <CheckboxForm
              control={
                <CheckboxControl
                  size="small"
                  checked={hideSmallBalance}
                  onChange={() => onHideSmallBalance(!hideSmallBalance)}
                />
              }
              label="Hide small balance (<0.1%)"
            />
          )}
        </Stack>
        {isPositions && (
          <>
            <Typography mb={1} mt={2} fontSize={18} lineHeight="30px" variant="h6">
              Positions
            </Typography>

            <AllocationCefiFundsTable
              isTradingButtonShow={true}
              isOverview={false}
              data={filteredData.positions}
              isError={isError}
              isFetching={isFetching}
              refetch={refetch}
            />

            <Typography mb={1} mt={2} fontSize={18} lineHeight="30px" variant="h6">
              Assets
            </Typography>
          </>
        )}

        <AllocationCefiFundsTable
          isTradingButtonShow={!(marketType === "future")}
          isOverview={false}
          data={filteredData.margin}
          isError={isError}
          isFetching={isFetching}
          refetch={refetch}
        />
      </Stack>
    </Stack>
  );
};
