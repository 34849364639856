import { queryFnFactory } from "../utils";
import { OrderSymbol } from "../anbotoApi/types";
import { GetObjectsResultsItem } from "./types";
import { algoliaApi, algoliaSearchClient } from "@src/store/apis/algoliaApi/algolia-api";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const cefiSymbolsIndex = algoliaSearchClient.initIndex(process.env.REACT_APP_ALGOLIA_CEFI_SYMBOLS_INDEX!);

export const cefiSymbolsApi = algoliaApi.injectEndpoints({
  endpoints: (builder) => ({
    // searchIds: ["BTC/USDT:USDT_Bybit_future", BTC/USDT_Bybit_spot", ...]
    getObjects: builder.query<GetObjectsResultsItem[], OrderSymbol[]>({
      providesTags: (result) =>
        result
          ? result.map((x) => ({
              id: x ? x.objectID : "",
              type: "GET_OBJECTS",
            }))
          : ["GET_OBJECTS"],
      queryFn: queryFnFactory<OrderSymbol[], GetObjectsResultsItem[]>(async (symbols) => {
        const searchIds = symbols.map(({ symbol, exchange, market_type }) => `${symbol}_${exchange}_${market_type}`);

        try {
          const res = await cefiSymbolsIndex.getObjects<GetObjectsResultsItem>(searchIds);
          return res.results || [];
        } catch (err) {
          return err;
        }
      }),
    }),
  }),
});
