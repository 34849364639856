import React from "react";
import { Box, Paper, Skeleton, Stack, styled, Typography } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { PortfolioType } from "@src/store/slices/portfolioSlice";
import { RootState } from "@src/store/types";
import { CurrencyValue, SendCryptoButton, usePortfolioType, useWallets } from "@src/pages/portfolio";
import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";
import { CEFI_EXCHANGE_COLORS, CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";
import { truncateAccount } from "@src/pages/defi/utils";
import { CopyToClipboard } from "@src/components/copy-to-clipboard";
import { ExchangeAvatar } from "@src/components/exchange-avatar/exchange-avatar";

type BalanceHeaderProps = {
  isFetching?: boolean;
  selectedItem: string;
};

export const BalanceHeader = ({ isFetching, selectedItem }: BalanceHeaderProps) => {
  const navigate = useNavigate();
  const portfolioType = usePortfolioType();
  const totalBalance =
    portfolioType === PortfolioType.CEFI
      ? useSelector((state: RootState) => state.portfolio.cefiTotalBalance)
      : useSelector((state: RootState) => state.portfolio.defiTotalBalance);

  const onMakeATrade = () => {
    if (portfolioType === PortfolioType.CEFI) {
      navigate(`/trades/cefi`);
    } else {
      navigate(`/trades/defi`);
    }
  };

  const selectedExchange = useSelector((state: RootState) => state.portfolio.selectedExchange);
  const exchangeId =
    CefiExchangeId[selectedExchange.toUpperCase()] || CefiExchangeId[selectedExchange.toUpperCase().slice(0, -3)];
  const currentExchangeColor = CEFI_EXCHANGE_COLORS[exchangeId];

  const exchangeIconAvailable =
    selectedItem !== "all" && portfolioType === PortfolioType.CEFI && selectedExchange && exchangeId;

  const getLabel = (name: string) =>
    portfolioType === PortfolioType.CEFI ? getExchangeNameLabel(CEFI_EXCHANGE_NAME[name]) : name;

  const wallets = useWallets();
  const walletAddress =
    (selectedItem !== "all" &&
      portfolioType === PortfolioType.DEFI &&
      wallets &&
      Object.keys(wallets).find((address) => wallets[address] === selectedItem)) ||
    "";

  return (
    <BalanceHeaderRoot>
      <Stack display="flex" direction="column" height="100%" justifyContent="space-between">
        <Stack display="flex" direction="row" gap={1}>
          {exchangeIconAvailable && (
            <Stack
              sx={{
                minWidth: 24,
                height: 24,
                borderRadius: 12,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {<ExchangeAvatar exchange={exchangeId} size={20} />}
            </Stack>
          )}
          <Box
            sx={{
              display: "flex",
              border: `1px solid ${exchangeIconAvailable && currentExchangeColor ? currentExchangeColor : "#2C72E3"}`,
              borderRadius: "4px",
              fontColor: "white",
              textAlign: "center",
              height: 24,
              padding: "3px 8px",
              width: "fit-content",
              marginBottom: 1,
            }}
          >
            <Typography fontWeight={400} fontSize={10} variant="body2" textTransform="capitalize">
              {selectedItem === "all" ? "Overview" : getLabel(selectedItem)}
            </Typography>
          </Box>
          <Typography fontSize={13}>{walletAddress && truncateAccount(walletAddress)}</Typography>
          {walletAddress && (
            <Box sx={{ pl: 0.5 }}>
              <CopyToClipboard textToCopy={walletAddress} key={walletAddress} />
            </Box>
          )}
        </Stack>
        <Stack direction="row" marginBottom={1} height="100%" alignItems="center">
          <Stack direction="row" flex={1} gap={3}>
            <Stack gap={1} minWidth={150}>
              <Typography variant="subtitle1" color="text.secondary" fontSize={12}>
                Total Balance
              </Typography>
              <Typography variant="h5" minWidth={100} textAlign="left" fontWeight={600} fontSize={20}>
                {isFetching ? (
                  <Skeleton animation="wave" />
                ) : (
                  <Stack alignItems="center" direction="row" gap={2}>
                    ≈ <CurrencyValue value={totalBalance} />
                  </Stack>
                )}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" gap={1} height="100%" alignItems="center">
            {portfolioType === PortfolioType.DEFI && <SendCryptoButton />}
            <BalanceHeaderButton variant="contained" onClick={() => onMakeATrade()}>
              Make a trade
              <TrendingUpIcon sx={{ marginLeft: 1, width: 18 }} />
            </BalanceHeaderButton>
          </Stack>
        </Stack>
      </Stack>
    </BalanceHeaderRoot>
  );
};

const BalanceHeaderButton = styled(AnbotoButton)({
  width: 140,
  height: 32,
  fontSize: 14,
  fontWeight: 600,
  padding: "6px 8px 6px 16px",
});

const BalanceHeaderRoot = styled(Paper)({
  background: "linear-gradient(180deg, #031116 8.43%, #192022 100%)",
  paddingLeft: "30px",
  paddingRight: "40px",
  paddingTop: "20px",
  paddingBottom: "20px",
  width: "100%",
});
