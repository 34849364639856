import React, { forwardRef } from "react";
import { MenuItem } from "@mui/material";
import { TraderType } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const TRADER_TYPE_NAME: Record<TraderType, string> = {
  [TraderType.INSTITUTIONAL]: "Institutional Investor",
  [TraderType.EXPERIENCED_INDIVIDUAL]: "Expert Trader/ Crypto Whale",
  [TraderType.STARTER]: "Rookie Crypto Trader",
};

const TRADER_TYPES = [TraderType.INSTITUTIONAL, TraderType.EXPERIENCED_INDIVIDUAL, TraderType.STARTER];

export const TraderTypeSelect = forwardRef((props: AnbotoTextFieldProps & { excludeTypes?: TraderType[] }, ref) => {
  return (
    <AnbotoTextField {...props} inputRef={ref} select label="Trader type" size="medium" fullWidth>
      {TRADER_TYPES.map((type) => (
        <MenuItem key={type} value={type}>
          {TRADER_TYPE_NAME[type]}
        </MenuItem>
      ))}
    </AnbotoTextField>
  );
});

TraderTypeSelect.displayName = "TraderTypeSelect";
