import React from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { truncateAccount } from "@src/pages/defi/utils";
import { useTextCopy } from "@src/pages/portfolio/components/send-crypto/hooks";
import { CHAIN_CONFIG } from "@src/pages/defi/constants";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";

type AddressBlockProps = {
  addressName: string;
  address: string;
};

const AddressBlock = ({ addressName, address }: AddressBlockProps) => {
  const { onCopyClick } = useTextCopy();

  const orderForm = useDefiOrderFormContext();
  const chainId = orderForm.getValues("chainId");

  const onCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCopyClick(address, "Address");
  };

  return (
    <Stack direction="row" gap={2} alignItems="center">
      <Stack width={50}>
        <Typography fontSize={12}>{addressName}</Typography>
      </Stack>
      <Tooltip title={address}>
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={1}
          sx={{ background: "#232C2F", borderRadius: 0.5, pl: 1 }}
          alignItems="center"
        >
          <Typography fontSize={12} margin={0}>
            {truncateAccount(address)}
          </Typography>
          <Stack direction="row">
            <IconButton onClick={onCopy}>
              <ContentCopy sx={{ color: (theme) => theme.palette.text.secondary, width: 16, height: 16 }} />
            </IconButton>
            <IconButton target="_blank" href={CHAIN_CONFIG[chainId]?.blockExplorerUrls + "/address/" + address}>
              <OpenInNewIcon sx={{ color: "#B0BCC2", fontSize: 16 }} />
            </IconButton>
          </Stack>
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default AddressBlock;
