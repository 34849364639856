import { anbotoApi } from "@src/store/apis/anbotoApi/index";
import { GetTopTokenHoldersResult, UserTokensResult } from "@src/store/apis/anbotoApi/types";
import { SUPPORTED_CHAINS } from "@src/store/apis/debankApi/constants";

export const ankr = anbotoApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserTokensList: builder.query<UserTokensResult, { account: string; chainId: number }>({
      query: ({ account, chainId }) => ({
        url: "/dex/bot/get_ankr_user_token_list/",
        params: {
          walletAddress: account,
          // [eth, bsc, fantom, avalanche, polygon, arbitrum, syscoin, optimism, eth_goerli, polygon_mumbai, avalanche_fuji]
          blockchain: [SUPPORTED_CHAINS.find((x) => x.community_id === chainId)!.ankr_chain_id],
        },
      }),
      providesTags: (result, error, arg) => [
        {
          type: "USER_TOKENS_LIST",
          account: arg.account,
          chainId: arg.chainId,
        },
      ],
      keepUnusedDataFor: 60,
    }),
    getTopTokenHolders: builder.query<GetTopTokenHoldersResult, { chainId: number; contractAddress: string }>({
      query: ({ contractAddress, chainId }) => ({
        url: "/dex/bot/get_ankr_token_holders/",
        params: {
          contractAddress,
          // [eth, bsc, fantom, avalanche, polygon, arbitrum, syscoin, optimism, eth_goerli, polygon_mumbai, avalanche_fuji]
          blockchain: [SUPPORTED_CHAINS.find((x) => x.community_id === chainId)!.ankr_chain_id],
        },
      }),
    }),
  }),
});
