import { styled } from "@mui/material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

export const SmallIconButton = styled(AnbotoButton)<{ iconWidth?: number; width?: number }>(
  ({ iconWidth, theme, width }) => ({
    minWidth: width ? width : 32,
    width: width ? width : 32,
    height: width ? width : 32,
    padding: 0,
    background: theme.custom.background.secondary,
    border: `1px solid ${theme.custom.border.default}`,

    "& svg": {
      width: iconWidth ? iconWidth : 16,
    },
  })
);
