import React from "react";
import { Card, CardContent, Stack } from "@mui/material";
import HistoryFilters from "@src/pages/settings/transaction-history/history-filters";
import ExportParentOrdersButton from "@src/components/export-order-button/export-parent-orders-button";
import OrdersHistoryTable from "@src/pages/settings/transaction-history/orders-history-table";

const OrdersHistory = () => (
  <Card sx={{ overflow: "visible" }}>
    <CardContent>
      <Stack direction="column" maxWidth="92vw">
        <Stack direction="row" justifyContent="space-between">
          <HistoryFilters />

          <Stack paddingBottom={1}>
            <ExportParentOrdersButton compact={false} />
          </Stack>
        </Stack>
        <OrdersHistoryTable />
      </Stack>
    </CardContent>
  </Card>
);

export default OrdersHistory;
