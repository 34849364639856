import React from "react";
import { Typography, Stack, CircularProgress } from "@mui/material";
import PostTradeTable from "./post-trade-table";
import { useGet12MonthPostTradeData } from "@src/hooks/useGet12MonthPostTradeData";

const PostTradeStatsTable = () => {
  const { isFetching, postTradeData } = useGet12MonthPostTradeData();

  return (
    <>
      {isFetching ? (
        <Stack direction="row" justifyContent="center" alignItems="center" gap={2} p={5}>
          <CircularProgress size={20} />
          <Typography variant="body2" textAlign="left">
            Loading slippage data...
          </Typography>
        </Stack>
      ) : (
        <PostTradeTable data={postTradeData} />
      )}
    </>
  );
};

export default PostTradeStatsTable;
