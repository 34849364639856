import React, { useEffect, useState } from "react";
import { Themes } from "react-tradingview-widget";
import { Paper, CircularProgress, styled, Box } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { ParentOrderFormFieldValues } from "../order-form/hooks/use-parent-order-form";
import { TradingViewChart, TvLines } from "./TradingViewChart";
import { prepareExchange } from "./anboto-datafeed/helpers";
import { prepareChartSymbol } from "./helpers";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { digitsAfterZeros } from "@src/utils/format";
import { OrderSymbol, CefiExchangeId } from "@src/store/apis/anbotoApi/types";
import { ResolutionString } from "@src/charting_library/charting_library";

type ParentOrderSymbolChartProps = {
  defaultSelected?: string;
  orderSymbol?: OrderSymbol | null;
  triggerPrice?: string;
  wouldPrice?: string;
  limitPrice?: string;
  quantity?: string;
  onLineMove: (key: keyof ParentOrderFormFieldValues, value: string) => void;
};

const DEFAULT_TRADINGVIEW_SYMBOL = "BTC/USDT~Binance~spot";
const DEFAULT_PRECISION = 100;

export const ParentOrderSymbolChart = ({
  orderSymbol,
  triggerPrice,
  wouldPrice,
  limitPrice,
  quantity,
  onLineMove,
}: ParentOrderSymbolChartProps) => {
  const [tvReady, setTvReady] = useState(false);
  const [tradingviewSymbol, setTradingviewSymbol] = useState<string>(
    orderSymbol
      ? prepareChartSymbol(orderSymbol.symbol, orderSymbol.exchange, orderSymbol.market_type)
      : DEFAULT_TRADINGVIEW_SYMBOL
  );
  const [precision, setPrecision] = useState(DEFAULT_PRECISION);
  const [getMarketData] = anbotoApi.useLazyGetMarketDataQuery();

  // https://gitlab.com/anboto/dev/Anboto/-/blob/main/app/main/templates/index.html#L1083
  const onOrderSymbolChange = () => {
    const { symbol, exchange, market_type } = orderSymbol || {};
    if (symbol && exchange && market_type) {
      setTradingviewSymbol(prepareChartSymbol(symbol, exchange, market_type));

      udpatePrecision();
    }
  };

  const udpatePrecision = async () => {
    const { symbol, exchange, market_type } = orderSymbol || {};

    if (!symbol || !exchange || !market_type) return false;

    try {
      const marketData = await getMarketData({
        timeframe: "15m",
        since: +new Date() - 15 * 60 * 1000,
        symbol,
        exchange_id: prepareExchange(exchange),
        market_type,
      }).unwrap();

      if (marketData) {
        const marketDataItems = marketData.data || [];
        const bar = marketDataItems[marketDataItems.length - 1];
        const price = (bar && bar[4]) || "";
        const [, r] = `${price}`.split(".");
        const precision = !r ? 100 : 10 ** r.length;

        setPrecision(precision);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    onOrderSymbolChange();
  }, [orderSymbol]);

  const handleLineMove = (key: string, value: number) => {
    onLineMove(key as keyof ParentOrderFormFieldValues, String(digitsAfterZeros(value, 4)));
  };

  const chartLines: TvLines = {
    ...(wouldPrice
      ? {
          wouldPrice: {
            value: +wouldPrice,
            quantity,
            label: `Would price`,
            options: {
              linecolor: "#af52bf",
              textcolor: "#fafafa",
              horzLabelsAlign: "right",
              vertLabelsAlign: "bottom",
            },
          },
        }
      : {}),
    ...(limitPrice
      ? {
          limitPrice: {
            value: +limitPrice,
            quantity,
            label: `Limit`,
            options: {
              linecolor: "#179B83",
              textcolor: "#333",
            },
          },
        }
      : {}),
    ...(triggerPrice
      ? {
          triggerPrice: {
            value: +triggerPrice,
            label: "Trigger price",
            options: {
              linecolor: "#4b88dd",
              textcolor: "#fafafa",
              horzLabelsAlign: "right",
              vertLabelsAlign: "bottom",
            },
          },
        }
      : {}),
  };

  const getConfiguration = () => {
    if (orderSymbol?.exchange?.toLowerCase() === CefiExchangeId.BITMEX) {
      return {
        supported_resolutions: ["1", "5", "60", "1D"] as ResolutionString[],
      };
    }
    return {};
  };

  const getInterval = () => {
    let interval = "15";
    if (orderSymbol?.exchange?.toLowerCase() === CefiExchangeId.BITMEX) {
      interval = "60";
    }
    return interval as ResolutionString;
  };

  return (
    <Paper sx={{ height: "100%", overflow: "hidden", position: "relative" }} className="symbol-chart-container">
      {!tvReady && (
        <Loading>
          <CircularProgress size={36} />
        </Loading>
      )}
      {tradingviewSymbol && (
        <ErrorBoundary fallback={<Box>Something wrong happened during chart drawing.</Box>}>
          <TradingViewChart
            theme={Themes.DARK}
            symbol={tradingviewSymbol}
            lines={chartLines}
            onLineMove={handleLineMove}
            onReady={() => setTvReady(true)}
            precision={precision}
            interval={getInterval()}
            configuration={getConfiguration()}
          />
        </ErrorBoundary>
      )}
    </Paper>
  );
};

const Loading = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.background.paper,
}));
