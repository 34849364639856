import React, { forwardRef } from "react";
import { MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { WouldStyle } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const WOULD_STYLE: WouldStyle[] = [WouldStyle.PASSIVE, WouldStyle.HYBRID, WouldStyle.AGGRESSIVE];

export const WOULD_STYLE_LABEL = {
  [WouldStyle.PASSIVE]: "Passive",
  [WouldStyle.HYBRID]: "Hybrid",
  [WouldStyle.AGGRESSIVE]: "Aggressive",
};

export const WouldStyleSelect = forwardRef((props: AnbotoTextFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    inputRef={ref}
    select
    label={
      <Stack direction="row" gap={1} alignItems="center">
        <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
          {props.label || "Style"}
        </Typography>
        <Tooltip
          title="Price at which you would like to aggressively attempt to fill your order, regardless of the algorithm logic.
Order aggressively tries to fill if the instrument reaches this price, irrespective of volume based tracking objectives. Should be lower than limit and arrival time ask prices for BUY orders, higher than limit and arrival time bid prices for SELL orders."
        >
          <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
        </Tooltip>
      </Stack>
    }
    size="small"
    fullWidth
  >
    {WOULD_STYLE.map((x) => (
      <MenuItem key={x} value={x}>
        {WOULD_STYLE_LABEL[x]}
      </MenuItem>
    ))}
  </AnbotoTextField>
));

WouldStyleSelect.displayName = "WouldStyleSelect";
