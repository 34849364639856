import React, { useEffect, useMemo } from "react";
import { Stack } from "@mui/material";
import { SIDES, STRATEGIES, TRADING_TYPES } from "@src/pages/metrics/constant";
import { useAppDispatch } from "@src/store/hooks";
import {
  setHistoryFiltersExchanges,
  setHistoryFiltersSides,
  setHistoryFiltersStrategy,
  setHistoryFiltersTradeType,
  setHistoryPeriod,
} from "@src/store/slices/metricsSlice";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { ALL_AGGREGATORS_PARAMETER } from "@src/store/apis/anbotoApi/constants";
import { GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";
import { periodFilterOptions } from "@src/components/orders-table-filters";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";

const HistoryFilters = () => {
  const dispatch = useAppDispatch();
  const getDefiExchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi: true });
  const getCefiExchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi: false });
  const supportedDefiExchanges =
    React.useMemo(
      () =>
        getDefiExchangesQuery.data?.results
          .filter((exchange) => exchange.is_defi)
          .map((exchange) => exchange.exchange_id),
      [getDefiExchangesQuery.data?.results]
    ) || [];
  const supportedCefiExchanges =
    React.useMemo(
      () =>
        getCefiExchangesQuery.data?.results
          .filter((exchange) => !exchange.is_defi)
          .map((exchange) => exchange.exchange_id),
      [getCefiExchangesQuery.data?.results]
    ) || [];

  const exchange_list_ids = useMemo(
    () => [...supportedDefiExchanges, ALL_AGGREGATORS_PARAMETER, ...supportedCefiExchanges],
    [getDefiExchangesQuery.data?.results, getCefiExchangesQuery.data?.results]
  );

  // const filters = useAppSelector((state) => state.metrics.historyFilters);

  useEffect(() => {
    dispatch(setHistoryFiltersTradeType(TRADING_TYPES));

    dispatch(setHistoryFiltersStrategy(STRATEGIES));

    dispatch(setHistoryFiltersSides(SIDES));

    dispatch(setHistoryFiltersExchanges(exchange_list_ids));
  }, [getCefiExchangesQuery.data?.results, getDefiExchangesQuery.data?.results]);

  // const handleChangeTradeType = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   dispatch(setHistoryFiltersTradeType(typeof value === "string" ? value.split(",") : value));
  // };
  //
  // const handleChangeStrategy = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   dispatch(setHistoryFiltersStrategy(typeof value === "string" ? value.split(",") : value));
  // };
  //
  // const handleChangeSide = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   dispatch(setHistoryFiltersSides(typeof value === "string" ? value.split(",") : value));
  // };
  //
  // const handleChangeExchange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   dispatch(setHistoryFiltersExchanges(typeof value === "string" ? value.split(",") : value));
  // };

  const handleChangePeriod = (period) => {
    dispatch(setHistoryPeriod(period));
  };

  return (
    <Stack direction="row" flexGrow={1} justifyContent="space-between" marginRight={1}>
      <Stack marginBottom={3} direction="row" gap={2}>
        {/*<FormControl size="small" sx={{ width: 130, direction: "string" }}>*/}
        {/*  <Typography fontSize={12}>Type:</Typography>*/}
        {/*  <AnbotoTextField*/}
        {/*    size="small"*/}
        {/*    select*/}
        {/*    SelectProps={{ multiple: true, renderValue: (selected) => (selected as any).join(", ") }}*/}
        {/*    value={filters.tradeTypes}*/}
        {/*    onChange={handleChangeTradeType}*/}
        {/*  >*/}
        {/*    {TRADING_TYPES.map((tradingType) => (*/}
        {/*      <MenuItem sx={{ margin: 0, padding: 0 }} key={tradingType} value={tradingType}>*/}
        {/*        <Checkbox size="small" checked={filters.tradeTypes.indexOf(tradingType) > -1} />*/}
        {/*        <ListItemText primaryTypographyProps={{ fontSize: 12 }} sx={{ fontSize: 12 }} primary={tradingType} />*/}
        {/*      </MenuItem>*/}
        {/*    ))}*/}
        {/*  </AnbotoTextField>*/}
        {/*</FormControl>*/}

        {/*<FormControl size="small" sx={{ width: 130, direction: "string" }}>*/}
        {/*  <Typography fontSize={12}>Strategies:</Typography>*/}
        {/*  <AnbotoTextField*/}
        {/*    size="small"*/}
        {/*    select*/}
        {/*    SelectProps={{ multiple: true, renderValue: (selected) => (selected as any).join(", ") }}*/}
        {/*    value={filters.strategies}*/}
        {/*    onChange={handleChangeStrategy}*/}
        {/*  >*/}
        {/*    {STRATEGIES.map((strategy) => (*/}
        {/*      <MenuItem sx={{ margin: 0, padding: 0 }} key={strategy} value={strategy}>*/}
        {/*        <Checkbox size="small" checked={filters.strategies.indexOf(strategy) > -1} />*/}
        {/*        <ListItemText primaryTypographyProps={{ fontSize: 12 }} sx={{ fontSize: 12 }} primary={strategy} />*/}
        {/*      </MenuItem>*/}
        {/*    ))}*/}
        {/*  </AnbotoTextField>*/}
        {/*</FormControl>*/}

        {/*<FormControl size="small" sx={{ width: 130, direction: "string" }}>*/}
        {/*  <Typography fontSize={12}>Side:</Typography>*/}
        {/*  <AnbotoTextField*/}
        {/*    size="small"*/}
        {/*    select*/}
        {/*    SelectProps={{ multiple: true, renderValue: (selected) => (selected as any).join(", ") }}*/}
        {/*    value={filters.sides}*/}
        {/*    onChange={handleChangeSide}*/}
        {/*  >*/}
        {/*    {SIDES.map((assetClass) => (*/}
        {/*      <MenuItem sx={{ margin: 0, padding: 0 }} key={assetClass} value={assetClass}>*/}
        {/*        <Checkbox size="small" checked={filters.sides.indexOf(assetClass) > -1} />*/}
        {/*        <ListItemText primaryTypographyProps={{ fontSize: 12 }} sx={{ fontSize: 12 }} primary={assetClass} />*/}
        {/*      </MenuItem>*/}
        {/*    ))}*/}
        {/*  </AnbotoTextField>*/}
        {/*</FormControl>*/}

        {/*<FormControl size="small" sx={{ width: 130, direction: "string" }}>*/}
        {/*  <Typography fontSize={12}>Exchanges:</Typography>*/}
        {/*  <AnbotoTextField*/}
        {/*    size="small"*/}
        {/*    select*/}
        {/*    SelectProps={{ multiple: true, renderValue: (selected) => (selected as any).join(", ") }}*/}
        {/*    value={filters.exchanges}*/}
        {/*    onChange={handleChangeExchange}*/}
        {/*  >*/}
        {/*    {exchange_list_ids.map((exchange) => (*/}
        {/*      <MenuItem sx={{ margin: 0, padding: 0 }} key={exchange} value={exchange}>*/}
        {/*        <Checkbox size="small" checked={filters.exchanges.indexOf(exchange) > -1} />*/}
        {/*        <ListItemText primaryTypographyProps={{ fontSize: 12 }} sx={{ fontSize: 12 }} primary={exchange} />*/}
        {/*      </MenuItem>*/}
        {/*    ))}*/}
        {/*  </AnbotoTextField>*/}
        {/*</FormControl>*/}
      </Stack>

      <Stack>
        <AnbotoButtonGroup<GetOrdersPeriod>
          size="small"
          options={periodFilterOptions}
          defaultValue={GetOrdersPeriod.All}
          onChange={handleChangePeriod}
        />
      </Stack>
    </Stack>
  );
};

export default HistoryFilters;
