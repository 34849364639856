import { format } from "date-fns";
import { BoxPlotData } from "@src/pages/analytics/types";

export const dateFormatter = (date: number) => {
  if (!isNaN(date * 1000)) {
    return format(new Date(date * 1000), "dd-MM-yy");
  }
};

export const percentFormatter = (data: number) => {
  return `${+(data * 100).toFixed(8)}%`;
};

export const medianOfSortedArray = (sortedArray: number[]): number => {
  if (sortedArray.length === 0) return 0;

  const middle = Math.floor(sortedArray.length / 2);

  if (sortedArray.length % 2 === 0) {
    return (sortedArray[middle - 1] + sortedArray[middle]) / 2;
  }

  return sortedArray[middle];
};

export const quartileOfSortedArray = (sortedArray: number[], q: number) => {
  if (sortedArray.length === 0) return 0;

  const pos = (sortedArray.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sortedArray[base + 1] !== undefined) {
    return sortedArray[base] + rest * (sortedArray[base + 1] - sortedArray[base]);
  } else {
    return sortedArray[base];
  }
};

export const removeArrayItem = (arr: number[], value: number) => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const get3MonthAgoTimeStamp = (fromDate?: number) => {
  const date = typeof fromDate === "number" ? new Date(fromDate) : new Date();
  date.setMonth(date.getMonth() - 3);
  date.setHours(0, 0, 0, 0);
  return +date / 1000;
};

export const reduceBoxPlotData = (data: BoxPlotData[], maxCountOfBars: number): BoxPlotData[] => {
  const chunkSize = Math.floor(data.length / maxCountOfBars);
  const reducedData: BoxPlotData[] = new Array(maxCountOfBars);

  for (let i = 0; i < 300; i++) {
    const chunk = data.slice(i * chunkSize, (i + 1) * chunkSize);
    const min = Math.min(...chunk.map((d) => d.min));
    const topWhisker = Math.min(...chunk.map((d) => d.topWhisker));
    const bottomWhisker = Math.max(...chunk.map((d) => d.bottomWhisker));
    const bottomBox = Math.max(...chunk.map((d) => d.bottomBox));
    const topBox = Math.min(...chunk.map((d) => d.topBox));
    const average = chunk.reduce((acc, d) => acc + (d.average || 0), 0) / chunk.length;

    reducedData[i] = {
      min,
      bottomWhisker,
      bottomBox,
      topBox,
      topWhisker,
      average,
      size: 500,
      time: chunk.reduce((acc, d) => acc + (d.time || 0), 0) / chunk.length,
    };
  }

  return reducedData;
};

export const getMonthCount = (startDate: number, endDate: number): number => {
  let start = new Date(startDate);
  let end = new Date(endDate);

  if (start > end) {
    [start, end] = [end, start];
  }

  const yearDiff = end.getFullYear() - start.getFullYear();
  const monthDiff = end.getMonth() - start.getMonth();

  return Math.abs(yearDiff * 12 + monthDiff);
};
