import React from "react";
import { Box, Stack, StackProps, Typography, styled } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useOrderbookContext } from "../hooks/use-orderbook-context";
import { AMOUNT_OPACITY } from "../constants";
import { formatWithDelimiter } from "@src/utils/format";
import { usePrevious } from "@src/hooks/use-previous";

interface SpreadProps extends StackProps {}

export const SPREAD_HEIGHT = 30;

export const Spread = ({ ...props }: SpreadProps) => {
  const { midPrice, spread, decimals } = useOrderbookContext();
  const prevPrice = usePrevious(midPrice);
  const direction = midPrice > prevPrice ? "up" : midPrice < prevPrice ? "down" : "";

  const spreadPct = (+spread / +midPrice) * 100;
  let spreadPctDecimals = -Math.log10(spreadPct) + 1;

  spreadPctDecimals = spreadPctDecimals === Infinity || spreadPctDecimals < 0 ? 0 : spreadPctDecimals;

  return (
    <Root {...props}>
      <Stack direction="row" alignItems="center" flex={1}>
        <Price direction={direction} variant="body2">
          {formatWithDelimiter(midPrice.toFixed(decimals))}
        </Price>
        <Stack minWidth={16}>
          {direction ? (
            direction === "up" ? (
              <ArrowUpwardIcon sx={{ marginLeft: 0, fontSize: 16, color: (theme) => theme.custom.colors.secondary }} />
            ) : (
              <ArrowDownwardIcon sx={{ marginLeft: 0, fontSize: 16, color: (theme) => theme.palette.error.main }} />
            )
          ) : null}
        </Stack>
      </Stack>
      <Typography
        variant="body2"
        noWrap
        sx={{ color: (theme) => theme.custom.colors.dim, fontSize: 12, textAlign: "right" }}
      >
        {!spread ? null : (
          <>
            Spread: {spread}
            <Box component="span" ml={2}>
              {spreadPct.toFixed(spreadPctDecimals)}%
            </Box>
          </>
        )}
      </Typography>
    </Root>
  );
};

Spread.displayName = "Spread";

const Root = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  height: SPREAD_HEIGHT,
  alignItems: "center",
  gap: 2,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  justifyContent: "space-between",
  boxShadow: `inset 0 1px 0 ${theme.palette.divider}, inset 0 -1px 0 ${theme.palette.divider}`,
}));

const Price = styled(Typography)<{ direction: string }>(({ theme, direction }) => ({
  color:
    direction === "up"
      ? theme.custom.colors.secondary
      : direction === "down"
      ? theme.palette.error.main
      : theme.custom.colors.dim,
  fontSize: 14,
  fontWeight: "bold",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  opacity: AMOUNT_OPACITY,
}));
