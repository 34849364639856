/* eslint-disable react/display-name */
import React from "react";
import { createColumnHelper, Row } from "@tanstack/table-core";
import { IconButton, Stack, Typography } from "@mui/material";
import { ReactComponent as DebankLogo } from "@src/assets/svg/debank-logo.svg";
import { roundAmount } from "@src/pages/portfolio/utils/helpers";
import { truncateAccount } from "@src/pages/defi/utils";
import { HolderWithUsdValue } from "@src/pages/defi/token-info/components/top-holders/top-holders";
import { buildDebankLink, formatLargeAmount } from "@src/pages/defi/token-info/helpers";

export const columnHelper = createColumnHelper<HolderWithUsdValue>();

export const getColumns = ({ symbol }) => {
  return [
    columnHelper.accessor("id", {
      id: "id",
      header: "#",
      size: 40,
      cell: React.memo(
        ({ row }: { row: Row<HolderWithUsdValue> }) => (
          <Stack direction="row" gap={0.5} alignItems="center">
            <Typography fontWeight={500} fontSize={12}>
              {row.original?.id}
            </Typography>
          </Stack>
        ),
        (prev, next) => prev.row.original.id === next.row.original.id
      ),
    }),

    columnHelper.accessor("holderAddress", {
      id: "holderAddress",
      header: "Wallet Address",
      size: 80,
      cell: React.memo(
        ({ row }: { row: Row<HolderWithUsdValue> }) => (
          <> {row.original?.holderAddress ? truncateAccount(row.original.holderAddress) : null}</>
        ),
        (prev, next) => prev.row.original.holderAddress === next.row.original.holderAddress
      ),
    }),
    columnHelper.accessor("balance", {
      id: "balance",
      header: `Balance (${symbol ? symbol : ""})`,
      size: 70,
      cell: React.memo(
        ({ row }: { row: Row<HolderWithUsdValue> }) => (
          <Typography noWrap sx={{ fontSize: 12 }}>
            {formatLargeAmount(String(roundAmount(row.original.balance, 3)))}
          </Typography>
        ),
        (prev, next) => prev.row.original.balance === next.row.original.balance
      ),
    }),
    columnHelper.accessor("usdValue", {
      id: "usdValue",
      header: "Est. Value (USD)",
      size: 70,
      cell: React.memo(
        ({ row }: { row: Row<HolderWithUsdValue> }) => (
          <Typography noWrap sx={{ fontSize: 12 }}>
            $ {formatLargeAmount(roundAmount(String(row.original.usdValue), 3))}
          </Typography>
        ),
        (prev, next) => prev.row.original.usdValue === next.row.original.usdValue
      ),
    }),
    columnHelper.accessor("holderAddress", {
      id: "holder-link",
      header: "",
      size: 25,
      cell: React.memo(
        ({ row }: { row: Row<HolderWithUsdValue> }) => (
          <IconButton target="_blank" href={buildDebankLink(row.original?.holderAddress)}>
            <DebankLogo width={15} height={15} />
          </IconButton>
        ),
        (prev, next) => prev.row.original.holderAddress === next.row.original.holderAddress
      ),
    }),
  ];
};
