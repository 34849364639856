import React from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { MAX_SYMBOLS } from "../watchlist/constants";
import { addSymbolToFavourites, removeSymbolFromFavourites } from "./symbol-select.slice";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { CefiExchangeMarketType, OrderSymbol, FavoriteSymbol } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { getExchangeIdByExchangeName } from "@src/store/apis/anbotoApi/utils";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { useActiveView } from "@src/features/widgets-layout/hooks/use-active-view";
import { WidgetsGridView } from "@src/features/widgets-layout/types";

interface ToggleFavouritesIconButtonProps {
  symbol: OrderSymbol;
}

export const formatAsFavorite = (symbol: OrderSymbol) => {
  return {
    ...symbol,
    asset_class: symbol.market_type,
    exchange: getExchangeIdByExchangeName(symbol.exchange),
  } as FavoriteSymbol;
};

export const ToggleFavouritesIconButton = ({ symbol }: ToggleFavouritesIconButtonProps) => {
  const dispatch = useAppDispatch();
  const cefiSymbols = useAppSelector((state) => state.user.token_pair_watchlist);
  const otcSymbols = useAppSelector((state) => state.symbolSelect.favourites.otc);
  const view = useActiveView();
  const isOtc = view === WidgetsGridView.OTC;
  const symbols = isOtc ? otcSymbols : cefiSymbols;

  const [watchlistAddSymbol] = anbotoApi.useAddWatchlistSymbolMutation();
  const [watchlistRemoveSymbol] = anbotoApi.useRemoveWatchlistSymbolMutation();
  const snackbar = useSnackbar();

  const addSymbol = async () => {
    if (symbols.length >= MAX_SYMBOLS)
      return snackbar.enqueueSnackbar(`${MAX_SYMBOLS} symbols limit`, { variant: "error" });

    try {
      if (isOtc) {
        dispatch(addSymbolToFavourites({ symbol: formatAsFavorite(symbol), type: "otc" }));
      } else {
        await watchlistAddSymbol({ symbol: formatAsFavorite(symbol) }).unwrap();
      }
    } catch (err) {
      console.log(err);
      snackbar.enqueueSnackbar(parseAnbotoRequestError(err), {
        variant: "error",
      });
    }
  };

  const removeSymbol = async () => {
    try {
      if (isOtc) {
        dispatch(removeSymbolFromFavourites({ symbol: formatAsFavorite(symbol), type: "otc" }));
      } else {
        await watchlistRemoveSymbol({ symbol: formatAsFavorite(symbol) }).unwrap();
      }
    } catch (err) {
      console.log(err);
      snackbar.enqueueSnackbar(parseAnbotoRequestError(err), {
        variant: "error",
      });
    }
  };

  const isActive = symbols.find(
    (saved: OrderSymbol & { asset_class: CefiExchangeMarketType }) =>
      `${CEFI_EXCHANGE_NAME[saved.exchange]}${saved.asset_class}${saved.symbol}`.toLowerCase() ===
      `${symbol.exchange}${symbol.market_type}${symbol.symbol}`.toLowerCase()
  );

  const handleIconClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (isActive) {
      removeSymbol();
    } else {
      addSymbol();
    }
  };

  return (
    <IconButton onClick={handleIconClick} size="small">
      {isActive ? (
        <StarIcon fontSize="small" sx={{ color: "#ECAC27" }} />
      ) : (
        <StarBorderIcon fontSize="small" sx={{ color: (theme) => theme.palette.text.secondary }} />
      )}
    </IconButton>
  );
};
