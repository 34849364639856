import React from "react";
import { Content } from "@src/pages/competitions/components/content";
import { ReactComponent as BybitLogo } from "@src/assets/svg/trading-competition/bybit-logo.svg";
import { CampaignRules } from "@src/pages/competitions/components/bybit/campaign-rules";
import { TermsAndConditions } from "@src/pages/competitions/components/bybit/terms-and-conditions";
import { CONFIG } from "@src/pages/competitions/components/bybit/config";

export const Layout = () => (
  <Content
    prizePoolSize={CONFIG.PRIZE_POOL_SIZE}
    exchangeLogo={<BybitLogo />}
    isLuckyBonus
    exchangeName={CONFIG.EXCHANGE_NAME}
    startDate={CONFIG.START_TIME}
    endDate={CONFIG.END_TIME}
    rewards={CONFIG.REWARDS}
    prizePool={CONFIG.PRIZE_POOL}
    about={null}
    campaignRules={<CampaignRules />}
    termsAndConditions={<TermsAndConditions />}
    rewardsHeader={CONFIG.REWARDS_HEADER}
    isRewardsColumn={CONFIG.IS_REWARDS_COLUMN}
  />
);
