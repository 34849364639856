import React from "react";
import { Box, Stack, Typography, styled } from "@mui/material";
import { SelectTokenDialog } from "./select-token-dialog";
import { useDialog } from "@src/hooks/useDialog";
import { ChainId } from "@src/pages/defi/types";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { SelectArrow } from "@src/components/select-arrow";
import { useGetTokenByAddressAndChainQuery } from "@src/store/apis/algoliaApi";
import { TokenAvatar } from "@src/components/token-avatar";
import { useTokenInfo } from "@src/pages/defi/hooks";

export type TokenSelectProps = {
  selectedTokenAddress: string;
  onTokenSelect(tokenSymbol: string, tokenFieldName?: "fromTokenAddress" | "toTokenAddress"): void;
  disabled: boolean;
  chainId: ChainId;
};

export const TokenAddressField = ({ selectedTokenAddress, onTokenSelect, disabled, chainId }: TokenSelectProps) => {
  const selectTokenDialog = useDialog();

  const tokenInfo = useTokenInfo({ chainId, address: selectedTokenAddress });

  const { data: selectedToken } = useGetTokenByAddressAndChainQuery(
    { address: selectedTokenAddress!, chainId: chainId! },
    { skip: !selectedTokenAddress || !chainId }
  );

  const address = selectedToken?.address || tokenInfo?.address;
  const symbol = selectedToken?.symbol || tokenInfo?.symbol;

  const _onTokenSelect = (tokenSymbol: string) => {
    selectTokenDialog.hide();
    onTokenSelect(tokenSymbol);
  };

  return (
    <StyledRootBox p={0.25}>
      <StyledAnbotoButton disabled={disabled} size="small" variant="outlined" onClick={selectTokenDialog.show}>
        <Stack direction="row" gap={1} alignItems="center" width="100%">
          <TokenAvatar
            address={address}
            chainId={chainId}
            symbol={symbol}
            sx={{
              height: 16,
              width: 16,
              borderRadius: "50%",
              display: "block",
              fontSize: 12,
              lineHeight: "16px",
            }}
          />
          <Typography sx={{ flexGrow: 1, fontSize: 14 }} textAlign="left">
            {symbol}
          </Typography>
          <SelectArrow />
        </Stack>
      </StyledAnbotoButton>
      {selectTokenDialog.isOpen && (
        <SelectTokenDialog
          isOpen={selectTokenDialog.isOpen}
          onClose={selectTokenDialog.hide}
          onTokenSelect={_onTokenSelect}
        />
      )}
    </StyledRootBox>
  );
};

const StyledRootBox = styled(Box)(({ theme }) => ({
  maxHeight: 32,
  backgroundColor: theme.palette.background.paper,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
}));

const StyledAnbotoButton = styled(AnbotoButton)(({ theme }) => ({
  border: "none !important",
  display: "flex",
  px: 1,
  minWidth: 120,
  alignItems: "center",
  backgroundColor: theme.custom.background.secondary,
  padding: "2px 8px",
  maxHeight: "32px",
}));
