import React from "react";
import { Stack } from "@mui/material";
import { CostOfRepricingTradeAnalysis } from "@src/store/apis/anbotoApi/types";
import {
  DashboardDataCard,
  FieldLabel,
  FieldValue,
} from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/styled-components";
import DashboardDataCardHeader from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/dashboard-data-card-header";
import { formatBps } from "@src/components/post-trade-analysis/helpers";
import AnbotoInfoIcon from "@src/components/anboto-info-icon/anboto-info-icon";

type CostOfRepricingProps = {
  costOfRepricing: CostOfRepricingTradeAnalysis;
};

const Savings = ({ costOfRepricing }: CostOfRepricingProps) => (
  <DashboardDataCard flex={1}>
    <DashboardDataCardHeader
      header="Savings"
      title="This section provides provides information on fee savings (and opportunitiy costs) achieved by the passive executions (i.e. not crossing the spread) of the order"
    />
    <Stack direction="column" gap={1}>
      <Stack direction="row" gap={2}>
        <Stack flex={1.1} direction="column">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <FieldLabel>Maker Taker Savings</FieldLabel>
            <AnbotoInfoIcon
              iconSize={16}
              title={
                "Savings in exchange fees from executing as a liquidity maker.  Calculated as (Taker Fee - Maker Fee ) x Percent of Order Executed Passively (i.e. as a Liquidity Maker)"
              }
            />
          </Stack>

          <FieldValue>{formatBps(costOfRepricing?.maker_taker_savings || 0)} </FieldValue>
        </Stack>
        <Stack flex={0.9} direction="column">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <FieldLabel>Spread Savings</FieldLabel>
            <AnbotoInfoIcon
              iconSize={16}
              title={
                "Bid-Ask Spread savings. Calculated as Bid-Ask-Spread (in basis points) times Percent of Order Executed Passively (i.e. as a Liquidity Maker)"
              }
            />
          </Stack>
          <FieldValue>{formatBps(costOfRepricing?.spread_savings || 0)}</FieldValue>
        </Stack>
        <Stack flex={1} direction="column">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <FieldLabel>Cost of repricing</FieldLabel>
            <AnbotoInfoIcon
              iconSize={16}
              title={
                "\"Cost of having to re-price the algo's child orders which did not get filled because they were  \n" +
                'priced passively (on best bid for buys, best ask for sells)"'
              }
            />
          </Stack>
          <FieldValue>{formatBps(costOfRepricing?.cost_of_repricing || 0)} </FieldValue>
        </Stack>
      </Stack>
      <Stack direction="row" gap={2} flexWrap="wrap">
        <Stack flex={1.1} direction="column">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <FieldLabel>NET savings</FieldLabel>
            <AnbotoInfoIcon
              iconSize={16}
              title={
                "Overall (Net) savings from the passive executions  = 'Maker Taker Savings' + 'Spread Savings' - 'Cost of Repricing'"
              }
            />
          </Stack>
          <FieldValue>{formatBps(costOfRepricing?.net_savings || 0)} </FieldValue>
        </Stack>
        <Stack flex={1} direction="column">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <FieldLabel>% order cancelled</FieldLabel>{" "}
            <AnbotoInfoIcon
              iconSize={16}
              title={
                "Percent of the algo order's child orders (slices) which had to be cancelled and re-priced because they were priced to passively to execute"
              }
            />
          </Stack>
          <FieldValue>{costOfRepricing?.pct_orders_cancelled || 0} %</FieldValue>
        </Stack>
        <Stack flex={0.9}></Stack>
      </Stack>
    </Stack>
  </DashboardDataCard>
);

export default Savings;
