import { useEffect, useState } from "react";
import { useAuth } from "./use-auth";
import { APP_NAME } from "@src/constants/common";

export const useOtpQr = () => {
  const { setUpTOTP, authenticated, getCurrentUser } = useAuth();
  const [uri, setUri] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);

    try {
      const { signInDetails } = await getCurrentUser();
      const totpSetupDetails = await setUpTOTP();
      const codeUri = totpSetupDetails.getSetupUri(APP_NAME, signInDetails?.loginId).toString();

      setUri(codeUri);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authenticated) loadData();
  }, [authenticated]);

  return { uri, loading, error };
};
