import React from "react";
import { updateGroupDataWithModal, removeWidget } from "../../store/widgets.slice";
import { WidgetBase, WidgetBaseProps } from "../widget-base";
import { WidgetHeaderButton } from "../../components/widget-header-button";
import { DragArea } from "../../components/drag-area";
import { ParentOrderTableCard } from "@src/pages/cefi/orders-table";
import { useAppDispatch } from "@src/store/hooks";
import { CefiExchangeId, CefiExchangeMarketType, ParentOrder } from "@src/store/apis/anbotoApi/types";
import { useSupportedExchanges } from "@src/pages/cefi/hooks/use-supported-exchanges";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";
import { getOrderFormDataFromParentOrder } from "@src/pages/cefi/helpers/get-order-form-data-from-parent-order";
import { getOrderFormDataFromPosition } from "@src/pages/cefi/helpers/get-order-form-data-from-position";
import { useCefiOrderFormDefaultValues } from "@src/pages/cefi/order-form/hooks/use-cefi-order-form-default-values";
import { setIsOrderCopy } from "@src/store/slices/singleExchangePageSlice";
import { Position } from "@src/pages/cefi/positions-table/types";
import { OTC_EXCHANGES } from "@src/features/otc/constants";
import { useLinkedExchanges } from "@src/pages/cefi/hooks/use-linked-exchanges";

const OrderTable = React.memo(
  ParentOrderTableCard,
  (prev, next) => prev.exchangeIds?.join() === next.exchangeIds?.join()
);
OrderTable.displayName = "OrderTable";

export const CefiOrdersTableFullWidget = React.forwardRef(
  (props: React.PropsWithChildren<WidgetBaseProps>, ref: React.Ref<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const supportedExchanges = useSupportedExchanges({ isDefi: false });
    const defaultValues = useCefiOrderFormDefaultValues();
    const linkedExchanges = useLinkedExchanges();

    const exchangeIds = (linkedExchanges.exchanges?.filter((id) => !OTC_EXCHANGES.includes(id as CefiExchangeId)) ||
      []) as CefiExchangeId[];

    const handleSymbolClick = async (x: ParentOrder) => {
      dispatch(setIsOrderCopy(true));

      if (!supportedExchanges.getExchange(x.exchange)) return false;

      const fieldValues = getOrderFormDataFromParentOrder(x, supportedExchanges.getExchanges(), defaultValues);

      const symbol = {
        symbol: x.symbol!,
        exchange: CEFI_EXCHANGE_NAME[x.exchange],
        market_type: x.asset_class!.toLowerCase() as CefiExchangeMarketType,
      };

      dispatch(
        updateGroupDataWithModal({
          data: { ...defaultValues, ...fieldValues, symbol },
          title: getOrderSymbolLabel(symbol),
        })
      );
    };

    const handleClosePosition = (position: Position, amount: string) => {
      const symbol = {
        symbol: position.symbol,
        market_type: CefiExchangeMarketType.FUTURE,
        exchange: CEFI_EXCHANGE_NAME[position.exchange],
      };
      const formData = getOrderFormDataFromPosition({ position, defaultValues, quantity: amount, reduceOnly: true });

      dispatch(
        updateGroupDataWithModal({
          data: formData,
          title: getOrderSymbolLabel(symbol, ["symbol"]),
        })
      );
    };

    const handlePositionClick = async (position: Position) => {
      const symbol = {
        symbol: position.symbol,
        market_type: CefiExchangeMarketType.FUTURE,
        exchange: position.exchange,
      };
      dispatch(
        updateGroupDataWithModal({
          data: getOrderFormDataFromPosition({ position, defaultValues }),
          title: getOrderSymbolLabel(symbol),
        })
      );
    };

    return (
      <WidgetBase
        {...props}
        ref={ref}
        component={
          <OrderTable
            onSymbolClick={handleSymbolClick}
            onPositionClick={handlePositionClick}
            onClosePosition={handleClosePosition}
            exchangeIds={exchangeIds}
            topBarPostfix={
              !props.lock ? (
                <>
                  <DragArea sx={{ position: "absolute", left: 0, top: 0, height: "100%", width: "100%" }} />
                  <WidgetHeaderButton icon="close" onClick={() => dispatch(removeWidget(props.id))} sx={{ ml: 1 }} />
                </>
              ) : null
            }
          />
        }
      />
    );
  }
);

CefiOrdersTableFullWidget.displayName = "CefiOrdersTableFullWidget";
