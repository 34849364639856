import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Stack, InputLabel, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { DevTool } from "@hookform/devtools";
import { AppDispatch, RootState } from "@src/store/types";
import { setUserPreferences } from "@src/store/actions/user.action";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AnbotoSwitch } from "@src/components/ui/anboto-switch";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { UserNotificationSettings } from "@src/store/apis/anbotoApi/types";
import { ErrorState } from "@src/components/ErrorState";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

const UserPreferencesForm = ({ userNotificationSettings }: { userNotificationSettings: UserNotificationSettings }) => {
  const dispatch = useDispatch<AppDispatch>();

  const snackbar = useSnackbar();

  const { user_uuid } = useSelector((state: RootState) => state.user);

  const [updateUserNotificationsSettings, updateUserNotificationsSettingsMutation] =
    anbotoApi.useUpdateUserNotificationsSettingsMutation();

  const form = useForm<UserNotificationSettings>({ defaultValues: userNotificationSettings });

  const onSubmit = async (formData: UserNotificationSettings) => {
    try {
      await updateUserNotificationsSettings({ userId: user_uuid, userNotificationSettings: formData }).unwrap();
      snackbar.enqueueSnackbar("User preferences has been updated", { variant: "success" });
      dispatch(setUserPreferences(formData));
    } catch (e) {
      console.log(e);
      snackbar.enqueueSnackbar("User preferences has not been updated", { variant: "error" });
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="user-preferences__form">
      <Box>
        <InputLabel htmlFor="play_sound">Play sound</InputLabel>
        <Controller
          name="play_sound"
          control={form.control}
          render={({ field: { onChange, value } }) => (
            <AnbotoSwitch id="play_sound" checked={value} onChange={(e, v) => onChange(v)} />
          )}
        />
      </Box>
      <Box mt="15px">
        <InputLabel htmlFor="send_email">Send email</InputLabel>
        <Controller
          name="send_email"
          control={form.control}
          render={({ field: { onChange, value } }) => (
            <AnbotoSwitch id="send_email" checked={value} onChange={(e, v) => onChange(v)} />
          )}
        />
      </Box>
      <Typography mt="15px" fontSize="14px">
        Plots Preferences
      </Typography>
      <Typography mt="15px" fontSize="14px">
        Volume participation thresholds
      </Typography>
      <Stack direction="row" gap={2}>
        <Box mt="15px" width="280px" display="flex" flexDirection="column">
          <InputLabel htmlFor="safe_below">Safe below</InputLabel>
          <Controller
            control={form.control}
            name="safe_below"
            rules={{ required: true }}
            render={({ field }) => <AnbotoTextField {...field} type="number" />}
          />
        </Box>
        <Box mt="15px" width="280px" display="flex" flexDirection="column">
          <InputLabel htmlFor="dangerous_above">Dangerous above</InputLabel>
          <Controller
            control={form.control}
            name="dangerous_above"
            rules={{ required: true }}
            render={({ field }) => <AnbotoTextField {...field} type="number" />}
          />
        </Box>
      </Stack>
      <Box mt="15px">
        <AnbotoButton variant="contained" type="submit" loading={updateUserNotificationsSettingsMutation.isLoading}>
          Save Changes
        </AnbotoButton>
      </Box>
      <DevTool control={form.control} />
    </form>
  );
};

const UserPreferences = () => {
  const { user_uuid } = useSelector((state: RootState) => state.user);
  const getUserNotificationSettingsQuery = anbotoApi.useGetUserNotificationSettingsQuery({ userId: user_uuid });

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        Notifications Preferences
      </Typography>
      {getUserNotificationSettingsQuery.isFetching ? (
        <CircularProgress />
      ) : getUserNotificationSettingsQuery.error ? (
        <ErrorState onTryAgainClick={getUserNotificationSettingsQuery.refetch} />
      ) : (
        <UserPreferencesForm userNotificationSettings={getUserNotificationSettingsQuery.data!} />
      )}
    </Box>
  );
};

export { UserPreferences };
