import React, { ReactElement } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Stack, styled, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PrizePool as TPrizePool, Reward } from "../types";
import { Rewards } from "./rewards";
import { PrizePool } from "./prize-pool";
import { ReactComponent as AnbotoLogo } from "@src/assets/svg/trading-competition/anboto-logo.svg";
import { ReactComponent as VsImage } from "@src/assets/svg/trading-competition/vs.svg";
import { ReactComponent as LeftQuote } from "@src/assets/svg/trading-competition/left-quote.svg";
import { ReactComponent as RightQuote } from "@src/assets/svg/trading-competition/right-quote.svg";
import DashboardDataCardHeader from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/dashboard-data-card-header";
import { formatUsd } from "@src/utils/format";
import { CompetitionParticipant } from "@src/store/apis/anbotoApi/types";
import { LeaderBoard } from "@src/pages/competitions/components/leaderboard";

type ContentProps = {
  exchangeLogo: ReactElement;
  exchangeName: string;
  prizePoolSize: number;
  isLuckyBonus?: boolean;
  bonusTitle?: string;
  startDate: string;
  endDate: string;
  rewards: Reward[];
  prizePool: TPrizePool;
  about: ReactElement | null;
  campaignRules: ReactElement;
  termsAndConditions: ReactElement;
  leaderBoardData?: CompetitionParticipant[];
  isLeaderBoardDataFetching?: boolean;
  leaderBoardRefetch?: () => void;
  rewardsHeader?: string;
  isRewardsColumn?: boolean;
  leaderboardPosition?: "left" | "right";
  registerUrl?: string;
};

export const Content = ({
  exchangeLogo,
  prizePoolSize,
  isLuckyBonus,
  exchangeName,
  startDate,
  endDate,
  rewards,
  prizePool,
  about,
  campaignRules,
  termsAndConditions,
  leaderBoardData,
  isLeaderBoardDataFetching,
  leaderBoardRefetch,
  rewardsHeader,
  isRewardsColumn,
  bonusTitle,
  leaderboardPosition,
  registerUrl,
}: ContentProps) => {
  return (
    <Stack padding={4} pt={2} alignItems="center" direction="column">
      <Stack width={800} direction="column" gap={2}>
        <Stack
          height={156}
          borderRadius={1}
          sx={{
            background:
              "radial-gradient(circle, rgba(29,59,37,1) 0%, rgba(3,17,22,0.975249474789916) 43%, rgba(5,30,38,1) 74%, rgba(13,34,70,1) 100%)",
          }}
          direction="column"
          gap={3}
          px={4}
          py={3}
        >
          <Stack direction="row" gap={3} alignItems="center">
            <AnbotoLogo />
            <VsImage />
            <>{exchangeLogo}</>
            <Typography fontSize={24} fontWeight={600}>
              Trading Competition
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <LeftQuote />
            <Stack direction="row" gap={2}>
              <Typography color="#ECAC27" fontSize={18} fontWeight={500}>
                Up to {formatUsd(prizePoolSize, 0)} Prize Pool
              </Typography>
              {isLuckyBonus && (
                <Typography fontSize={18} fontWeight={500}>
                  {bonusTitle || "Bonus Lucky Draw"}
                </Typography>
              )}
            </Stack>
            <RightQuote />
            <Stack justifyContent="flex-end" flex={1}>
              {registerUrl && (
                <RegisterLink href={registerUrl} target="_blank">
                  Register now
                </RegisterLink>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Card p={2}>
          <DashboardDataCardHeader fontWeight={500} fontSize={16} header="Timeline" />
          <Stack direction="row" gap={2} alignItems="center" justifyContent="space-between">
            <Stack direction="row" gap={2} alignItems="center">
              <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Field>Start date</Field>
                <Value>{startDate}</Value>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
                <Field>End date</Field>
                <Value>{endDate}</Value>
              </Stack>
            </Stack>
            <Stack py={1} px={3} borderRadius={1} border="1px solid #ECAC27" textAlign="center">
              <Typography color="#ECAC27" fontSize={10} fontWeight={400}>
                All funds will be distributed by {exchangeName}
              </Typography>
            </Stack>
          </Stack>
        </Card>

        <Stack direction="row" gap={2}>
          {!leaderBoardData ? null : leaderboardPosition === "right" ? (
            <Card sx={{ paddingLeft: 1, paddingRight: 1, flexGrow: 1 }}>
              <Rewards rewards={rewards} column={isRewardsColumn} title={rewardsHeader} />
            </Card>
          ) : (
            <Card sx={{ paddingLeft: 1, paddingRight: 1, flexGrow: 1 }}>
              <LeaderBoard
                leaderBoardData={leaderBoardData}
                isLeaderBoardDataFetching={!!isLeaderBoardDataFetching}
                leaderBoardRefetch={leaderBoardRefetch}
              />
            </Card>
          )}

          <Stack direction={leaderBoardData ? "column" : "row"} width={leaderBoardData ? 475 : 800} gap={2}>
            <Card width={leaderBoardData ? "100%" : "49%"}>
              {leaderBoardData && leaderboardPosition === "right" ? (
                <LeaderBoard
                  leaderBoardData={leaderBoardData}
                  isLeaderBoardDataFetching={!!isLeaderBoardDataFetching}
                  leaderBoardRefetch={leaderBoardRefetch}
                />
              ) : (
                <Rewards rewards={rewards} column={isRewardsColumn} title={rewardsHeader} />
              )}
            </Card>

            <Card width={leaderBoardData ? "100%" : "49%"}>
              <PrizePool data={prizePool} />
            </Card>
          </Stack>
        </Stack>

        <>{about}</>

        <Stack direction="column" gap={2}>
          <Accordion sx={{ background: "#192022" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography>Campaign Rules</Typography>
            </AccordionSummary>
            <AccordionDetails>{campaignRules}</AccordionDetails>
          </Accordion>
          <Accordion sx={{ background: "#192022" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <Typography>Terms and conditions</Typography>
            </AccordionSummary>
            <AccordionDetails>{termsAndConditions}</AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const Card = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: 16,
  borderRadius: 4,
  flex: 1,
}));

const Field = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: theme.palette.text.secondary,
}));

const Value = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 500,
  color: "#B0BCC2",
}));

const RegisterLink = styled("a")(({ theme }) => ({
  display: "block",
  borderRadius: 4,
  padding: "8px 16px",
  background: "#ECAC27",
  color: theme.palette.common.black,
  fontSize: 16,
  fontWeight: 500,
  textAlign: "center",
  textDecoration: "none",
}));
