import React from "react";
import { Tooltip, Box, Stack, Typography, styled, Skeleton } from "@mui/material";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { getSymbolPair } from "@src/pages/cefi/order-form/utils";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";
import { usePrevious } from "@src/hooks/use-previous";

const getErrorMessage = (error: { data: Record<string, string> }) => {
  return Object.values(error?.data || {})[0] || "Failed to load balance";
};

type SymbolAccountBalanceProps = {
  account: null | string;
  symbol: null | OrderSymbol;
  variant?: "horizontal" | "vertical";
};

export const SymbolAccountBalance = ({ symbol, account, variant }: SymbolAccountBalanceProps) => {
  const isHorizontal = variant === "horizontal";
  const getSymbolAccountBalanceQuery = anbotoApi.useGetSymbolAccountBalanceQuery(
    {
      symbol: symbol!,
      account: account!,
    },
    { skip: !symbol || !account }
  );
  const symbolId = usePrevious(getLivePriceId(symbol));

  const showLoader = getSymbolAccountBalanceQuery.isFetching || symbolId !== getLivePriceId(symbol);

  const symbolPair = getSymbolPair(symbol ? symbol.symbol : "");

  return (
    <Stack sx={{ flex: 1 }} mt={isHorizontal ? 1 : 0} flexDirection={isHorizontal ? "row" : "column"}>
      <Row>
        {!isHorizontal && <Title>{symbol ? symbolPair.from : "-"}</Title>}
        {showLoader ? (
          <Skeleton sx={{ height: 18, width: 100 }} animation="wave" />
        ) : getSymbolAccountBalanceQuery.error ? (
          <Tooltip title={getErrorMessage(getSymbolAccountBalanceQuery.error as any)}>
            <Typography variant="caption" color="error" noWrap sx={{ minWidth: 0 }}>
              {getErrorMessage(getSymbolAccountBalanceQuery.error as any)}
            </Typography>
          </Tooltip>
        ) : (
          <>
            {isHorizontal && <Label>Available </Label>}
            <Value isHorizontal={isHorizontal}>
              {symbol ? getSymbolAccountBalanceQuery.data?.position?.toLocaleString() : "-"}
            </Value>
          </>
        )}
      </Row>
      <Row sx={{ justifyContent: isHorizontal ? "flex-end" : undefined }}>
        {!isHorizontal && <Title>{symbol ? symbolPair.to : "-"}</Title>}
        {showLoader ? (
          <Skeleton sx={{ height: 18, width: 100 }} animation="wave" />
        ) : getSymbolAccountBalanceQuery.error ? (
          <Tooltip title={getErrorMessage(getSymbolAccountBalanceQuery.error as any)}>
            <Typography variant="caption" color="error" noWrap sx={{ minWidth: 0 }}>
              {getErrorMessage(getSymbolAccountBalanceQuery.error as any)}
            </Typography>
          </Tooltip>
        ) : (
          <>
            {isHorizontal && <Label>Available </Label>}
            <Value isHorizontal={isHorizontal}>
              {symbol ? getSymbolAccountBalanceQuery.data?.currency_position?.toLocaleString() : "-"}
            </Value>
          </>
        )}
      </Row>
    </Stack>
  );
};

const Row = styled(Box)({
  display: "flex",
  gap: 2,
  alignItems: "center",
  flex: 1,
  overflow: "hidden",
});

const Title = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body2" sx={{ flex: 1 }} color="#8A9296">
    {children}
  </Typography>
);

const Value = ({ children, isHorizontal }: { children: React.ReactNode; isHorizontal?: boolean }) => (
  <Typography color="text.primary" sx={{ fontSize: isHorizontal ? 12 : 16 }}>
    {children}
  </Typography>
);

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: 12,
}));
