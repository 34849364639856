import React, { forwardRef } from "react";
import { styled, Button, Typography, Stack } from "@mui/material";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type MaxProps = {
  onClick?: () => void;
  disabled?: boolean;
};

type QuantityInputProps = AnbotoTextFieldProps & {
  labelPosition?: "left" | "right";
  errorType?: "warning";
  max?: boolean;
  maxProps?: MaxProps;
  size?: "medium" | "small";
  amount?: number;
};

export const QuantityInput = forwardRef(
  ({ labelPosition, errorType, max, maxProps, size = "small", amount = 0, ...props }: QuantityInputProps, ref) => {
    return (
      <AnbotoTextField
        {...props}
        inputRef={ref}
        variant="outlined"
        size={size}
        inputProps={{ min: 0, step: "1" }}
        fullWidth
        sx={{
          ...(errorType === "warning"
            ? {
                "& .MuiFormHelperText-root.Mui-error": {
                  color: "#ffb300",
                },
                "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ffb300",
                },
              }
            : undefined),
          "& .MuiOutlinedInput-root": {
            paddingRight: 0,
          },
        }}
        InputProps={{
          ...(max
            ? {
                endAdornment: (
                  <Stack direction="row" justifyContent="flex-end" alignItems="center">
                    {amount > 0 && (
                      <Typography width="150px" align="right" noWrap color="text.disabled">
                        ~{amount} USD
                      </Typography>
                    )}
                    <Max variant="text" onClick={maxProps?.onClick} disabled={maxProps?.disabled}>
                      Max
                    </Max>
                  </Stack>
                ),
              }
            : {}),
          sx: {
            "& .MuiInputBase-input": {
              paddingRight: "0",
            },
          },
        }}
        InputLabelProps={{
          sx: {
            justifyContent: labelPosition === "right" ? "flex-end" : "flex-start",
            maxWidth: "100%",
          },
        }}
      />
    );
  }
);
QuantityInput.displayName = "QuanityInput";

const Max = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: 0,
  paddingRight: 0,
}));
