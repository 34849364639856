export const CEFI_WIDGETS_INTRO_STEPS = [
  {
    title: "Welcome to CeFi!",
    intro: "Welcome to Anboto CeFi! These steps will guide you through the basic features of the platform.",
  },
  {
    element: ".widgets-icon-button",
    title: "Widgets",
    intro: "Click here to tailor the trading view to your liking.",
  },
  {
    element: ".parent-order-form",
    title: "Order Form",
    intro: "Execute algos across exchanges and networks through this order panel.",
  },
  {
    element: ".price-impact-container",
    title: "Price Impact",
    intro: "Plan your TWAP orders and monitor the risk of market movement via our detailed price impact panel.",
  },
  {
    element: ".symbol-chart-container",
    title: "Symbol Chart",
    intro: "View multiple candlestick charts across various timeframes for thorough analysis.",
  },
];
