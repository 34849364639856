import { AnbotoButtonGroupValue } from "../ui/anboto-button-group";
import { GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";

export enum DefaultStatusFilter {
  All = "all",
}

export type FilterStatusValue<T> = T | DefaultStatusFilter;

export type OrdersTableFiltersProps<T> = {
  search?: {
    onSearch: (value: string) => void;
  };
  period?: {
    onChange: (value: AnbotoButtonGroupValue<GetOrdersPeriod>) => void;
  };
  status?: {
    value: FilterStatusValue<T>;
    onChange: (value: FilterStatusValue<T>) => void;
    parentOrder?: boolean;
  };
  onHideOtherPairsToggle?: () => void;
  otherPairsHidden?: boolean;
};
