import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import formValidator from "@src/utils/formValidator";

export type ProfileInfoValues = {
  isNeedToCheckIsCompanyExists?: boolean;
  isEmail?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  telegramID: string;
  contactNo?: string;
  companyName?: string;
};

export const profileInfoSchema = formValidator
  .object({
    firstName: formValidator.string().required().min(1).label("First name"),
    lastName: formValidator.string().required().min(1).label("Last name"),
    email: formValidator.string().required().email().label("Email"),
    telegramID: formValidator.string().optional(),
    contactNo: formValidator.string().optional(),
    companyName: formValidator.string().label("Company name"),
  })
  .required();

export const buildOnboardingProfileSchema = (trigger: any) => {
  return formValidator
    .object({
      isEmail: formValidator.boolean(),
      isNeedToCheckIsCompanyExists: formValidator.boolean(),
      firstName: formValidator.string().required().min(1).label("First name"),
      lastName: formValidator.string().required().min(1).label("Last name"),
      email: formValidator.string().email().label("Email").when("isEmail", {
        is: true,
        then: formValidator.string().required(),
      }),
      telegramID: formValidator.string().optional(),
      contactNo: formValidator.string().optional(),
      companyName: formValidator
        .string()
        .required()
        .min(1)
        .label("Company name")
        .when("isNeedToCheckIsCompanyExists", {
          is: true,
          then: formValidator
            .string()
            .test(
              "checkIsCompanyExists",
              "This company name already exists. Choose an other name, please",
              async (value) => {
                try {
                  if (!value) return true;
                  const result = await trigger(value);
                  if (result && result.data) {
                    return result.data.name_is_available;
                  } else {
                    return false;
                  }
                } catch (error) {
                  console.log("checkIsCompanyExists validation error", error.message);
                  return false;
                }
              }
            ),
        }),
    })
    .required();
};

export const useProfileInfoForm = (isEmail: boolean, isNeedToCheckIsCompanyExists: boolean, trigger: any) =>
  useForm<ProfileInfoValues>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(buildOnboardingProfileSchema(trigger)),
    defaultValues: {
      isEmail,
      isNeedToCheckIsCompanyExists,
      firstName: "",
      lastName: "",
      email: "",
      contactNo: "",
      telegramID: "",
      companyName: "",
    },
  });
