import React from "react";
import { Chip } from "@mui/material";
import _uniq from "lodash/uniq";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export type TagsInputRef = {
  getTags: () => string[];
  setTags: (tags: string[]) => void;
  clearTags: () => void;
  getInputValue: () => string;
};

export type TagsInputProps = {
  onChange?: (tags: string[]) => void;
  ref?: React.RefObject<TagsInputRef>;
  wide?: boolean;
} & AnbotoTextFieldProps;

const CHARS_PER_ROW = 30;

export const TagsInput = React.forwardRef(({ onChange, sx, wide, ...props }: TagsInputProps, ref) => {
  const [inputValue, setInputValue] = React.useState("");
  const [tags, setTags] = React.useState<string[]>([]);
  const inputRef = React.useRef<HTMLInputElement>();

  React.useImperativeHandle(ref, () => ({
    getTags: () => tags,
    clearTags: () => setTags([]),
    getInputValue: () => inputValue,
    setTags: (tags: string[]) => setTags(tags),
  }));

  const handleDelete = (tag: string) => () => {
    setTags(tags.filter((t) => t !== tag));
  };

  const handleInputChange = (event) => {
    const str = event.target.value;

    if (str.trim() && [",", " "].includes(str[str.length - 1])) {
      const newTags = _uniq([...tags, str.slice(0, str.length - 2)]);
      setTags(newTags);
      setInputValue("");
      inputRef?.current?.focus();
      onChange && onChange(newTags);
    } else {
      setInputValue(event.target.value);
    }
  };

  const handleBlur = () => {
    const value = inputValue.trim();
    if (value) {
      const newTags = _uniq([...tags, value]);
      setTags(newTags);
      setInputValue("");
      onChange && onChange(newTags);
    }
  };

  const multiline = tags.join("").length > CHARS_PER_ROW;

  const handleKeyDown = (e) => {
    let newTags = tags;

    if (e.keyCode === 8 && !inputValue) {
      e.preventDefault();
      newTags = tags.slice(0, tags.length - 1);
      setTags(newTags);
    }

    if (e.keyCode === 13) e.preventDefault();

    if (e.keyCode === 13 && inputValue.trim()) {
      newTags = _uniq([...tags, inputValue]);
      setInputValue("");
      setTags(newTags);
    }

    onChange && onChange(newTags);
  };

  return (
    <AnbotoTextField
      size="small"
      value={inputValue}
      fullWidth
      multiline
      rows={multiline ? undefined : 1}
      sx={{ "& .MuiOutlinedInput-root": { flexWrap: multiline ? "wrap" : undefined }, ...sx }}
      inputRef={inputRef}
      InputProps={{
        sx: { width: wide ? 720 : 500, pt: multiline ? undefined : 0.5, pb: multiline ? undefined : 0.5 },
        startAdornment: tags.map((tag) => (
          <Chip
            size="small"
            key={tag}
            label={tag}
            onDelete={handleDelete(tag)}
            sx={{ ml: 0, mr: 1, mb: multiline ? 1 : 0 }}
          />
        )),
        onBlur: handleBlur,
        onChange: handleInputChange,
        onKeyDown: handleKeyDown,
      }}
      {...props}
    />
  );
});

TagsInput.displayName = "TagsInput";
