import { useEffect, useRef, useState } from "react";
import {
  useCefiTokenTable,
  useUpdatePortfolioDataMutation,
  useLazyPortfolioTaskStatusQuery,
  UpdatePortfolioDataParams,
} from "@src/pages/portfolio";
import { PortfolioType, SELECT_ALL } from "@src/store/slices/portfolioSlice";
import { useDefiTokenTable } from "@src/pages/portfolio/hooks/use-defi-token-table";
import { useAppSelector } from "@src/store/hooks";

export interface UsePortfolioDataResult {
  taskIsPending: boolean;
  update: () => Promise<void>;
}

export const usePortfolioData = (portfolioType: PortfolioType): UsePortfolioDataResult => {
  const [updatePortfolioData] = useUpdatePortfolioDataMutation();
  const [getTaskStatus] = useLazyPortfolioTaskStatusQuery();

  const { refetch: refetchDefi, isFetching: isFetchingDefi } = useDefiTokenTable();
  const { refetch: refetchCefi, isFetching: isFetchingCefi } = useCefiTokenTable();
  const selectedExchange = useAppSelector((state) => state.portfolio.selectedExchange);

  const [taskId, setTaskId] = useState<string>();
  const [taskIsPending, setTaskIsPending] = useState<boolean>(false);

  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const loading = taskIsPending || isFetchingCefi || isFetchingDefi;

  useEffect(() => {
    if (taskId && portfolioType === PortfolioType.DEFI) {
      intervalRef.current = setInterval(() => {
        checkTaskStatusAndMaybeUpdateData(taskId);
      }, 1500);
    }

    return () => clearInterval(intervalRef.current);
  }, [taskId]);

  useEffect(() => {
    void update();
  }, [portfolioType]);

  const update = async () => {
    clearInterval(intervalRef.current);
    setTaskIsPending(true);

    const params: UpdatePortfolioDataParams = {
      exchange_type: portfolioType,
    };

    if (portfolioType === PortfolioType.CEFI && selectedExchange !== SELECT_ALL) {
      params.exchange_id = selectedExchange;
    }

    try {
      const { data: jobTaskId } = await updatePortfolioData(params).unwrap();

      // it the result is "timeout_60" the backend notifies us that task pending
      if (!jobTaskId.includes("timeout")) {
        setTaskId(jobTaskId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkTaskStatusAndMaybeUpdateData = async (taskId: string) => {
    try {
      // @TODO: ask BE team to return task_id, to avoid refreshing wrong data type (cefi/defi)
      const { ready, success } = await getTaskStatus({ task_id: taskId }).unwrap();

      if (ready && success) {
        clearInterval(intervalRef.current);
        setTaskIsPending(false);
        setTaskId("");

        if (portfolioType === PortfolioType.CEFI) {
          refetchCefi();
        } else {
          refetchDefi();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    taskIsPending: loading,
    update,
  };
};
