import React from "react";
import { Tooltip, Typography } from "@mui/material";

type UnderlinedTooltipProps = {
  text: string;
  tooltipText: string;
};

const UnderlinedTooltip = ({ text, tooltipText }: UnderlinedTooltipProps) => {
  return (
    <Tooltip title={tooltipText}>
      <Typography sx={{ textDecoration: "underline" }} color="#636B6F" fontSize={12} fontWeight={400} variant="caption">
        {text}
      </Typography>
    </Tooltip>
  );
};

export default UnderlinedTooltip;
