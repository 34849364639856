import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { MonthlySummaryTableItem } from "@src/store/apis/anbotoApi/types";
import { getColumns } from "@src/pages/metrics/components/monthly-summary-table/getColumns";
import { AnbotoTable } from "@src/components/anboto-table";

type MonthlySummaryTableProps = {
  data: MonthlySummaryTableItem[];
  isFetching: boolean;
  isError: boolean;
};

const MonthlySummaryTable = ({ data, isError }: MonthlySummaryTableProps) => {
  const columns = useMemo<Array<ColumnDef<MonthlySummaryTableItem, any>>>(() => getColumns({ data }), []);

  return (
    <AnbotoTable<MonthlySummaryTableItem>
      columns={columns}
      data={data}
      loading={false}
      error={isError}
      getRowId={(order: MonthlySummaryTableItem) => order.month}
      hasFooter
    />
  );
};

export default MonthlySummaryTable;
