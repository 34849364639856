export const COMMON_INTRO_STEPS = [
  {
    element: ".order-table",
    title: "Orders Table",
    intro: "Manage, monitor and modify multiple orders across various networks and aggregators.",
  },
  {
    element: ".main-menu-button",
    title: "Main navigation",
    intro: "Navigate across the Anboto platform through our integrated menu. ",
  },
  {
    element: ".fee-wallet-header-container",
    title: "Fee Wallet",
    intro:
      "Deposit credits that serve as dedicated funds to cover trading fees on Anboto. <a href='/settings/fees' target='_blank'>Click here</a> to recharge your Fee Wallet",
  },
  {
    element: ".announcements-icon-button",
    title: "Announcements",
    intro: "View the latest developments on our platform and roadmap.",
  },
  {
    element: ".settings-icon-button",
    title: "Settings",
    intro: "Click this icon to obtain detailed access to profile, account, and security settings",
  },
  {
    element: ".support-button",
    title: "Support",
    intro: "In case of any difficulties, reach out to our customer support team via telegram.",
  },
  {
    element: ".cefi-defi-menu",
    title: "CeFi / DeFi Toggle",
    intro: "Click here to switch between CeFi execution and DeFi execution.",
  },
  {
    title: "All Set for now!",
    intro: "Welcome to the Anboto family! Keep TWAPing!",
  },
];
