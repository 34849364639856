import React, { memo } from "react";
import { LinearProgress, linearProgressClasses, styled, LinearProgressProps } from "@mui/material";

type QuantityLinearProgressProps = {
  targetQuantity: number;
  executedQuantity: number;
  quantity: number;
  width?: number;
};

export const QuantityLinearProgress = ({
  targetQuantity,
  executedQuantity,
  quantity,
  width,
  sx,
}: QuantityLinearProgressProps & LinearProgressProps) => {
  const value = executedQuantity > targetQuantity ? 100 : (executedQuantity * 100) / targetQuantity;

  const diff = quantity - executedQuantity;
  const buffer = quantity && diff > 0 ? diff : 0;
  const bufferValue = (buffer * 100) / targetQuantity;
  const pct = Math.round((executedQuantity * 100) / targetQuantity);

  return (
    <>
      <StyledLinearProgress
        variant="buffer"
        value={value}
        sx={{ width: width || 100, ...sx }}
        color="success"
        valueBuffer={value + bufferValue}
      />
      {pct}%
    </>
  );
};

export const StyledLinearProgress = styled(LinearProgress)({
  height: 6,
  background: "#3B4043",
  "& .MuiLinearProgress-bar": {
    transition: "none",
  },
  [`& .${linearProgressClasses.dashed}`]: {
    display: "none",
  },
  [`& .${linearProgressClasses.bar1Buffer}`]: {
    background: "linear-gradient(270deg, #179B83 0%, rgba(7,122,113,1) 100%)",
  },
  [`& .${linearProgressClasses.bar2Buffer}`]: {
    backgroundColor: "#ECAC27",
  },
});

export const QuantityLinearProgressMemo = memo(QuantityLinearProgress);
