import React, { useCallback, useEffect } from "react";
import { Skeleton, Stack, styled, Typography } from "@mui/material";
import _debounce from "lodash/debounce";
import { useSnackbar } from "notistack";
import { getClipSize, getNumberOfClips } from "./utils";
import { useDefiOrderFormContext, useTokenInfo } from "@src/pages/defi/hooks";
import { getClipSizeParameters } from "@src/pages/defi/utils";
import { ClipSizeType, OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";
import { getTradingDurationSec } from "@src/pages/cefi/order-form/utils";
import { DEFAULT_ORDER_CLIPSIZE, MAX_SLIPPAGE_VALUE } from "@src/pages/defi/constants";
import { useLazyGetSwapQuoteWithPreTradeAnalysisDefiAwareQuery } from "@src/store/apis/anbotoApi/defi";

const PreTradeAnalysis = () => {
  const orderForm = useDefiOrderFormContext();
  const snackbar = useSnackbar();

  const strategy = orderForm.watch("strategy");
  const chainId = orderForm.watch("chainId");
  const clipSizeType = orderForm.watch("clipSizeType");
  const clipSizeVal = orderForm.watch("clipSizeValue");
  const fromTokenAmount = orderForm.watch("fromTokenAmount");
  const fromTokenAddress = orderForm.watch("fromTokenAddress");
  const toTokenAddress = orderForm.watch("toTokenAddress");
  const childSlippage = orderForm.watch("childSlippage");
  const parentSlippage = orderForm.watch("slippage");
  const duration = orderForm.watch("tradingDuration");
  const durationUnit = orderForm.watch("tradingDurationUnit");

  const fromToken = useTokenInfo({ chainId, address: fromTokenAddress });
  const toToken = useTokenInfo({ chainId, address: toTokenAddress });

  const isOrderStrategy = strategy === OrderExecutionStrategy.ORDER || strategy === OrderExecutionStrategy.LIMIT;

  const isObligatoryParams = isOrderStrategy
    ? fromToken?.address && toToken?.address && fromTokenAmount && chainId && strategy && parentSlippage
    : fromToken?.address && toToken?.address && fromTokenAmount && chainId && strategy && childSlippage;

  const [getSwapQuoteWithPreTradeAnalysisDefiAware, { data, isFetching, isError, error }] =
    useLazyGetSwapQuoteWithPreTradeAnalysisDefiAwareQuery();

  const refetchPreTrade = async (
    chainId,
    sellToken,
    sellTokenDecimals,
    buyToken,
    buyTokenDecimals,
    childSlippage,
    sellAmount,
    clipSizeVal,
    durationSeconds,
    strategy
  ) => {
    try {
      const isOrderStrategy = strategy === OrderExecutionStrategy.ORDER || strategy === OrderExecutionStrategy.LIMIT;
      const formClipSizeType = orderForm.getValues("clipSizeType");

      const preTradeAnalysis = await getSwapQuoteWithPreTradeAnalysisDefiAware({
        chainId,
        sellToken,
        sellTokenDecimals,
        buyToken,
        buyTokenDecimals,
        childSlippage,
        sellAmount,
        ...getClipSizeParameters(
          isOrderStrategy ? ClipSizeType.PERCENTAGE : formClipSizeType,
          isOrderStrategy ? DEFAULT_ORDER_CLIPSIZE : clipSizeVal
        ),
        durationSeconds,
        strategy: strategy === OrderExecutionStrategy.LIMIT ? OrderExecutionStrategy.ORDER : strategy,
      }).unwrap();

      const twapSkip = strategy === OrderExecutionStrategy.TWAP && !durationSeconds;

      if (orderForm.getValues("clipSizeType") === ClipSizeType.AUTOMATIC && !twapSkip && !isOrderStrategy) {
        const formClipSizeValue = orderForm.getValues("clipSizeValue");

        const clipSizeValue = preTradeAnalysis?.data?.clip_size_val?.toString() || sellAmount?.toString();
        if (clipSizeValue && formClipSizeValue !== clipSizeValue) {
          orderForm.setValue("clipSizeValue", clipSizeValue);

          await orderForm.trigger("clipSizeValue");
        }
      }
    } catch (error) {
      console.log("refetchPostCredits error", error);
    }
  };

  const debouncedRefetchPreTrade = useCallback(
    _debounce(
      (
        chainId,
        sellToken,
        sellTokenDecimals,
        buyToken,
        buyTokenDecimals,
        childSlippage,
        sellAmount,
        clipSizeVal,
        durationSeconds,
        strategy
      ) =>
        refetchPreTrade(
          chainId,
          sellToken,
          sellTokenDecimals,
          buyToken,
          buyTokenDecimals,
          childSlippage,
          sellAmount,
          clipSizeVal,
          durationSeconds,
          strategy
        ),
      500
    ),
    []
  );

  useEffect(() => {
    if (
      fromTokenAddress !== toTokenAddress &&
      isObligatoryParams &&
      (clipSizeVal ||
        strategy === OrderExecutionStrategy.ORDER ||
        strategy === OrderExecutionStrategy.LIMIT ||
        clipSizeType === ClipSizeType.AUTOMATIC)
    ) {
      void debouncedRefetchPreTrade(
        chainId,
        fromToken?.address || "",
        fromToken?.decimals || 0,
        toToken?.address || "",
        toToken?.decimals || 0,
        ((isOrderStrategy ? +parentSlippage : +childSlippage!) / 100).toString(),
        fromTokenAmount,
        clipSizeVal,
        duration ? getTradingDurationSec(Number(duration), durationUnit) : undefined,
        strategy
      );
    }
  }, [
    chainId,
    fromToken?.address,
    toToken?.address,
    parentSlippage,
    childSlippage,
    fromTokenAmount,
    clipSizeType,
    clipSizeVal,
    durationUnit,
    duration,
    strategy,
  ]);

  useEffect(() => {
    if (!isError) {
      return;
    }

    const slippage = Number(orderForm.getValues("slippage"));
    const childSlippage = Number(orderForm.getValues("childSlippage"));

    if (slippage > MAX_SLIPPAGE_VALUE || childSlippage > MAX_SLIPPAGE_VALUE) {
      snackbar.enqueueSnackbar(
        `${
          strategy === OrderExecutionStrategy.TWAP ? "Slippage" : "Swap slippage "
        }  cannot exceed 50%. Please enter a valid slippage value within the allowed range.`,
        {
          variant: "error",
        }
      );

      void orderForm.trigger(strategy === OrderExecutionStrategy.TWAP ? "childSlippage" : "slippage");

      return;
    }

    const errorData = (error as { data: { success: boolean } })?.data;

    if (!errorData?.success && clipSizeType === ClipSizeType.AUTOMATIC) {
      snackbar.enqueueSnackbar(
        "Automatic clip size is not available for this pair, please set a custom clip size value",
        {
          variant: "warning",
        }
      );

      orderForm.setValue("clipSizeType", ClipSizeType.ABSOLUTE);
      orderForm.setValue("clipSizeValue", "");
      orderForm.trigger("clipSizeValue");
    }

    if (error) {
      console.log({ error });
    }
  }, [isError]);

  const clipSize = getClipSize(fromTokenAmount, clipSizeType, clipSizeVal, data?.data?.clip_size_val);
  const numberOfClips = getNumberOfClips({
    strategy,
    amount: fromTokenAmount,
    clipSizeType,
    clipSizeValue: clipSizeVal,
    automaticValue: data?.data?.n_clips,
  });
  const slippage =
    clipSizeType === ClipSizeType.AUTOMATIC ? (+(data?.data?.expected_slippage || 0) * 100).toFixed(2) : childSlippage;
  const recomendedClipsNumber = clipSizeType !== ClipSizeType.AUTOMATIC ? data?.data?.n_clips : "";
  const isAutomatic = clipSizeType === ClipSizeType.AUTOMATIC || strategy === OrderExecutionStrategy.ORDER;

  return (
    <>
      {strategy !== OrderExecutionStrategy.DCA &&
        (slippage !== "0.00" ||
          (numberOfClips && !isOrderStrategy) ||
          (data?.data?.advise_clip_size && !isOrderStrategy) ||
          (clipSize && !isOrderStrategy)) && (
          <Stack
            sx={{ background: "#192022" }}
            borderRadius={1}
            pt={2}
            pr={(isAutomatic || isOrderStrategy) && isFetching ? 2 : 0}
            pb={2}
            pl={2}
            className="defi-pre-trade-analysis-container"
          >
            <Typography fontSize={14} fontWeight={400} mb={0.5}>
              Pre-trade analysis
            </Typography>

            {(isAutomatic || isOrderStrategy) && isFetching ? (
              <Stack direction="row" justifyContent="space-between">
                <Skeleton width={130} height={18} />
                <Skeleton width={130} height={18} />
                <Skeleton width={130} height={18} />
              </Stack>
            ) : (
              <Stack direction="row" flexWrap="wrap">
                {clipSize && !isOrderStrategy && (
                  <ValueFieldBox>
                    <Field>Current clip size</Field>
                    <Value>{clipSize + " " + fromToken?.symbol}</Value>
                  </ValueFieldBox>
                )}

                {slippage && (
                  <ValueFieldBox>
                    <Field>Expected slippage</Field>
                    <Value>{slippage + "%"}</Value>
                  </ValueFieldBox>
                )}

                {numberOfClips && !isOrderStrategy && (
                  <ValueFieldBox>
                    <Field>Number of clips</Field>
                    <Stack direction="row" gap={1}>
                      <Value>{numberOfClips}</Value>
                      {isFetching ? (
                        <Skeleton width={40} height={18} />
                      ) : (
                        <Value>{recomendedClipsNumber ? `(${recomendedClipsNumber} suggested)` : ""}</Value>
                      )}
                    </Stack>
                  </ValueFieldBox>
                )}

                {data?.data?.advise_clip_size && !isOrderStrategy && (
                  <ValueFieldBox>
                    <Field>Advice clip size</Field>
                    <Value>{data?.data?.advise_clip_size + " " + fromToken?.symbol}</Value>
                  </ValueFieldBox>
                )}
              </Stack>
            )}
          </Stack>
        )}
    </>
  );
};

export default PreTradeAnalysis;

const ValueFieldBox = styled(Stack)(() => ({
  flexDirection: "row",
  gap: 8,
  alignItems: "center",
  marginRight: 24,
  marginBottom: 8,
}));
const Value = styled(Typography)(({ theme }) => ({ fontSize: 12, fontWeight: 400, color: theme.palette.text.primary }));
const Field = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: theme.palette.text.secondary,
}));
