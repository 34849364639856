import _keyBy from "lodash/keyBy";
import { GetOrdersType, OrderSource } from "./types";
import { setAccounts } from "./admin.slice";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import {
  CefiExchangeId,
  CefiExchangeMarketType,
  ClipSizeType,
  OrderExecutionStrategy,
  OrderSide,
  OrdersCurrentTab,
  ParentOrderErrorCode,
  ParentOrderMsgCode,
  ParentOrderStatuses,
  PlacementMode,
  PovRisk,
  TradingStyle,
  Urgency,
  WouldStyle,
} from "@src/store/apis/anbotoApi/types";
import { getPlatformOrdersSubscription } from "@src/subscriptions/anboto-platform-orders-subscription";
import { RootState } from "@src/store/types";

export type PlatformAccount = { id: number; name: string; uuid: string; last_parent_order_created_at: string };

export type PlatformOrder = {
  client_order_id: string;
  order_id: string;
  order_type: CefiExchangeMarketType;
  network_id: string;
  account_uuid: string;
  subaccount: string | null;
  exchange: CefiExchangeId;
  trader_uuid: string;
  trader_email: string;
  source: string;
  symbol: string;
  token1: string;
  token2: string;
  side: OrderSide;
  quantity: number;
  strategy: OrderExecutionStrategy;
  start_time: number;
  end_time: number;
  expiration_time: number;
  clip_size_type: ClipSizeType;
  clip_size_val: null | number;
  asset_class: CefiExchangeMarketType;
  is_trigger: boolean;
  is_pta_available: boolean;
  limit_price: number;
  duration_seconds: number;
  params: {
    would_price: number;
    would_pct: number;
    would_style: WouldStyle;
    trigger_price: number;
    trigger_condition: "above" | "below";
    trading_style: TradingStyle;
    placement_infos_placement_mode: PlacementMode;
    placement_infos_cancel: number;
    placement_infos_placement: number;
    extend_duration: true;
    pov_ratio: string;
    pov_risk: PovRisk;
    is_market_order: false;
    urgency: Urgency;
    reduce_only: boolean;
  };
  defi_data: null;
  created_at: string;
  last_status: {
    status: ParentOrderStatuses;
    last_price: null | number;
    average_price: null | number;
    average_price_usd: null | number;
    last_quantity: null | number;
    filled_quantity: number;
    leaves_quantity: number;
    error_code: null | ParentOrderErrorCode;
    message: string;
    created_at: number;
  };
  fee_urgency: null | number;
  max_fee: null | number;
  max_fee_target_slice_size: null | number;
  fee_asset: null | number;
};

type PaginatedResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export const adminApi = anbotoApi.injectEndpoints({
  endpoints: (builder) => ({
    getPlatformAccounts: builder.query<PlatformAccount[], void>({
      query: () => ({
        method: "GET",
        url: `/orders/admin/accounts`,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAccounts(_keyBy(data, "uuid")));
        } catch (error) {
          console.log(error);
        }
      },
      transformResponse: (response: PlatformAccount[]) =>
        response.sort((a, b) =>
          +new Date(a.last_parent_order_created_at || 0) < +new Date(b.last_parent_order_created_at || 0) ? 1 : -1
        ),
    }),
    getPlatformOrders: builder.query<
      PaginatedResponse<PlatformOrder>,
      {
        accountIds?: string;
        cefiDefi: GetOrdersType;
        tab?: OrdersCurrentTab;
        limit: number;
        offset?: number;
        source?: OrderSource;
        onlyActive?: boolean;
      }
    >({
      query: ({ accountIds, cefiDefi, tab, limit, offset, source, onlyActive }) => ({
        method: "GET",
        url: `/orders/admin/orders`,
        params: {
          ...(accountIds ? { account_uuids: accountIds } : {}),
          ...(cefiDefi !== GetOrdersType.All ? { cefi_defi: cefiDefi } : {}),
          ...(onlyActive ? { only_active: true } : {}),
          source,
          tab,
          limit,
          offset,
        },
      }),
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, updateCachedData, dispatch, getState }) {
        const refetching = { inProgress: false, symbols: {} };

        await cacheDataLoaded;

        const state = getState() as RootState;

        const { cefiDefi, source, teams } = state.support.filters;
        const teamIds = teams.reduce((acc, { uuid }) => ({ [uuid]: true }), {});

        const sub = getPlatformOrdersSubscription();

        const onMessage = (msg) => {
          const { code, data } = msg.message || {};

          updateCachedData((draft) => {
            if (data?.order_id) {
              try {
                const cefiOrder = draft.results.find((order) => {
                  return order.order_id === data.order_id;
                });

                if (cefiOrder) {
                  const {
                    average_price,
                    filled_quantity,
                    last_price,
                    last_quantity,
                    leaves_quantity,
                    status,
                    error_code,
                  } = data;

                  cefiOrder.last_status = {
                    ...cefiOrder.last_status,
                    ...(average_price ? { average_price } : {}),
                    ...(filled_quantity ? { filled_quantity } : {}),
                    ...(leaves_quantity ? { leaves_quantity } : {}),
                    ...(last_price ? { last_price } : {}),
                    ...(last_quantity ? { last_quantity } : {}),
                    ...(status ? { status } : {}),
                    ...(error_code ? { error_code } : {}),
                  };
                } else {
                  let skip = false;
                  //@TODO: handle trigger price
                  if (teams.length && !teamIds[data.account_uuid]) skip = true;
                  if (cefiDefi === GetOrdersType.Cefi && data.isDefi) skip = true;
                  if (cefiDefi === GetOrdersType.Defi && !data.isDefi) skip = true;
                  if (source === OrderSource.Api && data.source !== OrderSource.Api) skip = true;

                  // @TODO: implement fetching single data by id and add it to the list (when api ready)
                  if (code === ParentOrderMsgCode.CREATE && !refetching.inProgress && !skip) {
                    refetching.inProgress = true;

                    dispatch(
                      adminApi.endpoints.getPlatformOrders.initiate(arg, { subscribe: false, forceRefetch: true })
                    ).then(() =>
                      setTimeout(() => {
                        refetching.inProgress = false;
                      }, 3000)
                    );
                  }
                }
              } catch (err) {
                console.log(err);
              }
            }
          });
        };

        sub?.listenAll(onMessage);

        await cacheEntryRemoved;

        sub?.stopListenAll(onMessage);
      },
    }),
  }),
  overrideExisting: false,
});
