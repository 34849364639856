import React, { forwardRef } from "react";
import { MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { PovRisk } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

const POV_RISK: PovRisk[] = [PovRisk.DEFAULT, PovRisk.LOW, PovRisk.HIGH];

export const POV_RISK_NAME: Record<PovRisk, string> = {
  [PovRisk.DEFAULT]: "Medium",
  [PovRisk.LOW]: "Low",
  [PovRisk.HIGH]: "High",
};

export const PovRiskSelect = forwardRef((props: AnbotoTextFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    inputRef={ref}
    select
    label={
      <Stack direction="row" gap={1} alignItems="center" sx={{ lineHeight: 1 }}>
        <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
          {props.label || "Risk"}
        </Typography>
        <Tooltip title="" sx={{ lineHeight: 1 }}>
          <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
        </Tooltip>
      </Stack>
    }
    size="small"
    fullWidth
  >
    {POV_RISK.map((type) => (
      <MenuItem key={type} value={type}>
        {POV_RISK_NAME[type]}
      </MenuItem>
    ))}
  </AnbotoTextField>
));

PovRiskSelect.displayName = "PovRiskSelect";
