const attrsToSkip = [
  "compact",
  "vertical",
  "active",
  "swipeableMode",
  "loading",
  "expandable",
  "striped",
  "overlayStyles",
  "isActive",
  "selectedButtonStyles",
  "buttonStyles",
  "view",
  "animated",
  "clickable",
  "activeLink",
  "opened",
  "dim",
  "groupColor",
  "ownerState",
  "endAdornment",
  "isSorted",
].reduce((res, attr) => ({ ...res, [attr]: true }), {});

export const styledOptions = {
  shouldForwardProp: (prop: string) => !attrsToSkip[prop],
};
