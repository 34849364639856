import * as Cognito from "./cognito";

const _Auth = Cognito.default;

export const Auth = {
  ..._Auth,
  signIn: (params: { username: string; password: string }) =>
    _Auth.signIn({ ...params, options: { authFlowType: "USER_PASSWORD_AUTH" } }),
  getAuthTokens: () =>
    _Auth
      .fetchAuthSession()
      .then((res) => ({ idToken: res.tokens?.idToken?.toString(), accessToken: res.tokens?.accessToken.toString() })),
};
