import React, { forwardRef, useEffect, useRef } from "react";
import { MenuItem, Box, Skeleton } from "@mui/material";
import _omit from "lodash/omit";
import { MAIN_ACCOUNT_VALUE } from "../../constants";
import { useParentOrderFormContext } from "../hooks/use-parent-order-form-context";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { compareCaseInsensitive } from "@src/pages/defi/utils";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { SetupApiKeyModal } from "@src/components/symbol-select/setup-api-key-modal";
import { useTestnetEnabled } from "@src/hooks/use-testnet-enabled";
import { OTC_EXCHANGES_MAP } from "@src/features/otc/constants";

type AccountSelectProps = AnbotoTextFieldProps;

export const AccountSelect = forwardRef(
  ({ noLabel, otc, ...props }: AccountSelectProps & { noLabel?: boolean; otc?: boolean }, ref) => {
    const orderForm = useParentOrderFormContext();
    const symbol = orderForm.watch("symbol");
    const containerRef = useRef(null);

    const getExchangeConfigsQuery = anbotoApi.useGetExchangeConfigsQuery(null);
    const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);
    const teamId = getUserDetailsQuery.data?.team_uuid;
    const isTestnet = useTestnetEnabled();

    const getFilteredExchangeConfigs = (skipExchangeCompare?: boolean) => {
      if (!symbol) return [];

      return (
        getExchangeConfigsQuery.data?.results.filter(
          (x) =>
            compareCaseInsensitive(x.team_uuid, teamId || "") &&
            (skipExchangeCompare ? true : compareCaseInsensitive(x.exchange_name, symbol!.exchange)) &&
            (otc ? OTC_EXCHANGES_MAP[x.exchange_id] : !OTC_EXCHANGES_MAP[x.exchange_id])
        ) || []
      );
    };

    useEffect(() => {
      if (isTestnet) {
        orderForm.setValue("account", MAIN_ACCOUNT_VALUE);
      } else if (symbol && teamId && getFilteredExchangeConfigs().length) {
        const configs = getFilteredExchangeConfigs();
        const mainFound = configs.find((config) => !config.subaccount_title);
        const selectedAccount = orderForm.getValues("account");

        if (!selectedAccount || !configs.find((config) => config.subaccount_title === selectedAccount)) {
          orderForm.setValue("account", (mainFound ? MAIN_ACCOUNT_VALUE : configs[0].subaccount_title) as string);
        }
      }
    }, [symbol, teamId, orderForm.getValues("account"), getFilteredExchangeConfigs().length, isTestnet]);

    useEffect(() => {
      const configs = getFilteredExchangeConfigs(true);

      if (symbol && configs.length && !configs.find((el) => el.exchange_name === symbol?.exchange)) {
        orderForm.setValue("symbol", { ...symbol, exchange: configs[0].exchange_name });
      }
    }, [getExchangeConfigsQuery.isLoading]);

    const noSupportedExchangeApiKeys = !getFilteredExchangeConfigs().length && !isTestnet;

    return (
      <Box ref={containerRef} position="relative">
        {getExchangeConfigsQuery.isLoading && (
          <Skeleton sx={{ height: "100%", width: "100%", position: "absolute", left: 0, top: 0, transform: "none" }} />
        )}
        <AnbotoTextField
          data-testid="order-form-account-select"
          label={noLabel ? undefined : "Select account (Main/Sub)"}
          inputRef={ref}
          {..._omit(props, "noLabel")}
          value={noSupportedExchangeApiKeys ? "Account" : props.value}
          variant="outlined"
          size="small"
          fullWidth
          select
          disabled={noSupportedExchangeApiKeys}
          sx={{ ...props.sx, opacity: getExchangeConfigsQuery.isLoading ? 0 : 1 }}
        >
          {isTestnet ? (
            <MenuItem key={MAIN_ACCOUNT_VALUE} value={MAIN_ACCOUNT_VALUE}>
              Main account
            </MenuItem>
          ) : noSupportedExchangeApiKeys ? (
            <MenuItem key="Account" value="Account">
              Account
            </MenuItem>
          ) : (
            getFilteredExchangeConfigs().map((x) => (
              <MenuItem key={x.uuid} value={x.subaccount_title || MAIN_ACCOUNT_VALUE}>
                {x.subaccount_title ? `${x.subaccount_title} Subaccount` : "Main account"}
              </MenuItem>
            ))
          )}
        </AnbotoTextField>
        {!isTestnet && noSupportedExchangeApiKeys && !getExchangeConfigsQuery.isLoading && (
          <SetupApiKeyModal
            open
            container={() => containerRef.current}
            sx={{ zIndex: 9, position: "absolute !important", top: "32px !important" }}
          />
        )}
      </Box>
    );
  }
);

AccountSelect.displayName = "AccountSelect";
