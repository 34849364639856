import React from "react";
import { Typography } from "@mui/material";
import { formatBps } from "@src/components/post-trade-analysis/helpers";

type RedGreenLabelProps = {
  value: number;
};

const GreenLabel = ({ value }: RedGreenLabelProps) => {
  return (
    <Typography
      fontSize={14}
      padding={0.3}
      variant="caption"
      sx={{
        background: value > 0 ? "rgb(0 202 165 / 10%)" : "none",
        border: `${value > 0 ? "1px solid #179B83" : "none"}`,
        borderRadius: 0.5,
      }}
    >
      {formatBps(value)}
    </Typography>
  );
};

export default GreenLabel;
