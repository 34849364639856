import { DateTime } from "luxon";

export const SUPPORTED_TIMEZONES = [
  "Africa/Cairo",
  "Africa/Johannesburg",
  "Africa/Lagos",
  "America/Argentina/Buenos_Aires",
  "America/Bogota",
  "America/Caracas",
  "America/Chicago",
  "America/El_Salvador",
  "America/Juneau",
  "America/Lima",
  "America/Los_Angeles",
  "America/Mexico_City",
  "America/New_York",
  "America/Phoenix",
  "America/Santiago",
  "America/Sao_Paulo",
  "America/Toronto",
  "America/Vancouver",
  "Asia/Almaty",
  "Asia/Ashkhabad",
  "Asia/Bahrain",
  "Asia/Bangkok",
  "Asia/Chongqing",
  "Asia/Dubai",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Jakarta",
  "Asia/Jerusalem",
  "Asia/Kathmandu",
  "Asia/Kolkata",
  "Asia/Kuwait",
  "Asia/Muscat",
  "Asia/Qatar",
  "Asia/Riyadh",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Taipei",
  "Asia/Tehran",
  "Asia/Tokyo",
  "Atlantic/Reykjavik",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Amsterdam",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Brussels",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Helsinki",
  "Europe/Istanbul",
  "Europe/Lisbon",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Vilnius",
  "Europe/Warsaw",
  "Europe/Zurich",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "Pacific/Fakaofo",
  "Pacific/Honolulu",
  "Pacific/Norfolk",
  "US/Mountain",
];

export function getTimezone() {
  const localZone = DateTime.local().zoneName || "";

  if (SUPPORTED_TIMEZONES.includes(localZone)) {
    return localZone;
  }

  const localOffset = DateTime.local().offset;
  const res = {};

  SUPPORTED_TIMEZONES.forEach((tz) => {
    const tzOffset = DateTime.local().setZone(tz).offset;
    res[tzOffset] = tz;
  });

  const tz = res[localOffset] || "Etc/UTC";

  console.log(`[tv] ${localZone} not supported, using ${tz}`);

  return tz;
}

export const prepareChartSymbol = (symbol, exchange, market_type) => `${symbol}~${exchange}~${market_type}`;
