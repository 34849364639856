import { useCallback } from "react";
import { updateGroupDataWithModal } from "../store/widgets.slice";
import { useAppDispatch } from "@src/store/hooks";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";
import { CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";

export const useGroupDataModal = () => {
  const dispatch = useAppDispatch();

  const showGroupDataModal = useCallback(
    (symbol: string, exchangeName: string, marketType: string, account?: string) => {
      dispatch(
        updateGroupDataWithModal({
          data: {
            symbol: {
              symbol,
              ...(account ? { account } : {}),
              exchange: exchangeName,
              market_type: marketType,
            },
          },
          title: getOrderSymbolLabel({
            symbol,
            exchange: exchangeName,
            market_type: marketType as CefiExchangeMarketType,
          }),
          group: "1",
        })
      );
    },
    []
  );

  return showGroupDataModal;
};
