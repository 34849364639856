import { UseTokenInfoResult } from "@src/pages/defi/hooks";

export const getLimitPriceToFromTokenRatePercent = (
  isFromTokenIsBasePoolToken: boolean,
  limitPrice: string,
  price: string
): string =>
  (
    (isFromTokenIsBasePoolToken ? 1 - +limitPrice / (+price || 1) : -(1 - +limitPrice / (1 / (+price || 1)))) * 100
  ).toFixed(2);

export const getLimitPriceLabel = (
  isCalculateLimitPriceRate: boolean,
  isFromTokenIsBasePoolToken: boolean,
  fromToken: UseTokenInfoResult,
  toToken: UseTokenInfoResult,
  isPositiveLimitPriceRate: boolean,
  limitPriceToFromTokenRatePercent: string
): string => {
  let limitPriceLabel = "";

  if (isCalculateLimitPriceRate) {
    if (isFromTokenIsBasePoolToken) {
      limitPriceLabel =
        "Sell " +
        fromToken?.symbol +
        (" at rate (" + (isPositiveLimitPriceRate ? "-" : "+") + Math.abs(+limitPriceToFromTokenRatePercent) + "%)");
    } else {
      limitPriceLabel =
        "Buy " +
        toToken?.symbol +
        (" at rate (" + (isPositiveLimitPriceRate ? "+" : "") + limitPriceToFromTokenRatePercent + "%)");
    }
  } else {
    limitPriceLabel = "Limit price";
  }

  return limitPriceLabel;
};
