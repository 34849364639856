import { anbotoApi } from "@src/store/apis/anbotoApi";
import { CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";

export type GetPortfolioCefiPnlParams = {
  market_type?: CefiExchangeMarketType;
  account_uuid?: string; // afd2e100-f690-4a13-a772-d05cae971732
  exchange_list_ids: string; // binance,bybit
};

export type PnlData = {
  bucket: string; // ISOdateString "2023-01-18T00:00:00Z"
  daily_total_balance: number; // 30188.596629256386
  pnl: number | null; // 1.24
  nb_of_exchanges_in_pnl: number;
  exchanges_in_pnl: string[];
};

export type GetPortfolioCefiPnlResult = {
  success: boolean;
  data: PnlData[];
};

const portfolioCefiPnlApi = anbotoApi.injectEndpoints({
  endpoints: (build) => ({
    portfolioCefiPnl: build.query<GetPortfolioCefiPnlResult, GetPortfolioCefiPnlParams>({
      query: (params) => ({
        url: `/portfolio/cefi_pnl/`,
        params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const portfolioCefiPnl = portfolioCefiPnlApi.endpoints.portfolioCefiPnl;

export const { usePortfolioCefiPnlQuery, useLazyPortfolioCefiPnlQuery } = portfolioCefiPnlApi;
