import React, { useState } from "react";
import { intervalToDuration } from "date-fns";
import { useInterval } from "@src/hooks/use-interval";

export const IntervalDuration = ({ endTs }: { endTs: number }) => {
  const [duration, setDuration] = useState("");

  useInterval(() => {
    const now = +new Date();
    const { months, weeks, days, hours, minutes, seconds } = intervalToDuration({
      start: now,
      end: endTs,
    });

    const _minutes = Math.ceil((minutes || 0) + (seconds || 0) / 60);

    if (endTs < now) {
      setDuration("-");
    } else if (!hours && _minutes) {
      setDuration(`< ${_minutes}min`);
    } else {
      setDuration(
        [
          months ? `${months}m` : "",
          weeks ? `${weeks}w` : "",
          days ? `${days}d` : "",
          hours ? `${hours}h` : "",
          _minutes ? `${_minutes}min` : "",
        ]
          .filter(Boolean)
          .join(": ")
      );
    }
  }, 1000);

  return <>{duration}</>;
};
