import React from "react";
import { Stack, Typography, styled } from "@mui/material";
import { useOrderbookContext } from "../hooks/use-orderbook-context";
import { formatPrice } from "../helpers";
import { formatTokenAmount } from "@src/utils/format";

const Info = React.memo(
  ({
    coin1,
    coin2,
    sumCoin1,
    sumCoin2,
    decimals,
    midPrice,
  }: {
    coin1?: string;
    coin2?: string;
    sumCoin1: number;
    sumCoin2: number;
    decimals: number;
    midPrice: number;
  }) => {
    return (
      <Root direction="row" justifyContent="space-between" sx={{ background: "rgba(97, 97, 97, 1)" }}>
        <Stack gap={1}>
          <Typography variant="body2">{coin1}</Typography>
          <Typography variant="body2">{coin2}</Typography>
          <Typography variant="body2">Avg.Price:</Typography>
          <Typography variant="body2">Price Impact:</Typography>
        </Stack>
        <Stack gap={1}>
          <Typography variant="body2">{formatTokenAmount(sumCoin1)}</Typography>
          <Typography variant="body2">{formatPrice(sumCoin2 || 0, (sumCoin2 || 0) > 10 ? 2 : decimals)}</Typography>
          <Typography variant="body2">{formatPrice(sumCoin2 / sumCoin1, decimals)}</Typography>
          <Typography variant="body2">
            {(Math.abs(sumCoin2 / sumCoin1 / midPrice - 1) * 10000).toFixed(2)} bps
          </Typography>
        </Stack>
      </Root>
    );
  }
);

Info.displayName = "Info";

export const AggregatedInfo = () => {
  const { aggregatedInfo, decimals, midPrice } = useOrderbookContext();
  return (
    aggregatedInfo && (
      <Info
        coin1={aggregatedInfo.coin1}
        coin2={aggregatedInfo.coin2}
        sumCoin1={aggregatedInfo.amountCoin1}
        sumCoin2={aggregatedInfo.amountCoin2}
        decimals={decimals}
        midPrice={midPrice}
      />
    )
  );
};

const Root = styled(Stack)({
  width: 200,
});
