import GridLayout from "react-grid-layout";

export enum WidgetsGridView {
  CEFI = "cefi",
  DEFI = "defi",
  OTC = "otc",
}

export enum WidgetName {
  CefiOrderForm = "cefi-order-form",
  CefiSymbolChart = "cefi-symbol-chart",
  CefiPriceImpact = "cefi-price-impact",
  CefiOrdersTableFull = "cefi-orders-table-full",
  CefiOrderbook = "cefi-orderbook",

  DefiOrderForm = "defi-order-form",
  DefiSymbolChart = "defi-symbol-chart",
  DefiOrdersTableFull = "defi-orders-table-full",

  OtcOrderForm = "otc-order-form",
  OtcOrdersTableFull = "otc-orders-table-full",
  OtcSymbolChart = "otc-symbol-chart",
}

export type WidgetLayoutBreakpoints = "xxl" | "xl" | "lg" | "md" | "sm" | "xs";

export type WidgetLayout = Record<WidgetLayoutBreakpoints, GridLayout.Layout>;

export type WidgetsLayoutItem = {
  id: string;
  name: WidgetName;
  group?: string;
  layout: WidgetLayout;
};

export type WidgetsLayout = Record<string, WidgetsLayoutItem>;

export type PredefinedWidgetsLayout = {
  id: string;
  name: string;
  view: WidgetsGridView;
  version: number;
  layout: WidgetsLayout;
  groups?: Record<string, Record<string, any>>;
};

export type WidgetProps = {
  group?: number;
  key: string;
  layout: GridLayout.Layout;
};

export type WidgetConfigLayout = Record<WidgetLayoutBreakpoints, Omit<GridLayout.Layout, "i">>;

export type WidgetOptions = {
  title?: string;
  maxNumber: number;
  multiple?: boolean;
  removable?: boolean;
  withGroups?: boolean;
  noEmptyGroup?: boolean;
  symbolSelect?: boolean;
  symbolSelectDisabled?: boolean;
  lock?: boolean;
};

export type WidgetConfig = Pick<WidgetsLayoutItem, "name"> & {
  layout: WidgetConfigLayout;
  options: WidgetOptions;
};

export enum WidgetPredefinedLayoutView {
  BASIC = "basic",
  ADVANCE = "advance",
}
