import { WidgetsGridView } from "../types";
import { cefiPageLayoutBasic } from "./cefi-page-layout-basic";
import { defiPageLayout } from "./defi-page-layout";
import { otcPageLayout } from "./otc-page-layout";

export const layouts = {
  [WidgetsGridView.CEFI]: cefiPageLayoutBasic.layout,
  [WidgetsGridView.DEFI]: defiPageLayout.layout,
  [WidgetsGridView.OTC]: otcPageLayout.layout,
};

export const predefinedLayouts = {
  [WidgetsGridView.CEFI]: cefiPageLayoutBasic,
  [WidgetsGridView.DEFI]: defiPageLayout,
  [WidgetsGridView.OTC]: otcPageLayout,
};
