import React, { useEffect } from "react";
import { InputLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import { AuthCard } from "../../layout/auth-card";
import { FormRow } from "../../layout/form-row";
import { FormError } from "../../layout/form-error";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const ConfirmEmail = ({
  onSubmit,
  loading,
  error,
}: {
  onSubmit: (code: string) => void;
  error?: string;
  loading?: boolean;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });

  const code = watch("code");

  const onPaste = (event) => {
    const code = event.clipboardData.getData("text/plain");

    setValue("code", code);

    onSubmit(code);
  };

  useEffect(() => {
    if (error) {
      setError("code", {
        type: "manual",
        message: error,
      });
    }
  }, [error]);

  return (
    <AuthCard title="Confirm Email">
      <form onSubmit={handleSubmit(({ code }) => onSubmit(code))}>
        <FormRow>
          <InputLabel>Check your email for confirmation code</InputLabel>
          <AnbotoTextField
            disabled={loading}
            size="large"
            error={!!error}
            sx={{ marginTop: 10 }}
            inputProps={{ sx: { borderRadius: "4px" } }}
            placeholder="Enter your confirmation code"
            type="text"
            {...register("code", { required: true })}
            name="code"
            onPaste={onPaste}
          />
          {errors.code && <FormError>{errors.code.message}</FormError>}
        </FormRow>
        <AnbotoButton
          size="large"
          variant="contained"
          fullWidth={true}
          loading={loading}
          onClick={handleSubmit(({ code }) => onSubmit(code))}
          sx={{ marginTop: 2 }}
          type="submit"
          disabled={!code}
        >
          Submit
        </AnbotoButton>
      </form>
    </AuthCard>
  );
};
