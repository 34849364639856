import { useEffect } from "react";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import {
  usePortfolioTokenTableQuery,
  GetTokenTableParams,
  useExchangeListIds,
  usePortfolioType,
} from "@src/pages/portfolio";
import { CefiAllocationWithIcon } from "@src/store/apis/anbotoApi/types";
import {
  DEFAULT_CEFI_ALLOCATION_ITEM_ACTIVE,
  PortfolioType,
  SELECT_ALL,
  setAccountCefiAllocation,
  setCefiTotalBalance,
  setDataUpdatedCefi,
  setTokenCefiAllocation,
} from "@src/store/slices/portfolioSlice";
import { RootState } from "@src/store/types";

export interface UseCefiTokenTableResult {
  dataFromCache: CefiAllocationWithIcon[];
  isError: boolean;
  isFetching: boolean;
  refetch: () => void;
}

export const getLatestBucketDate = (dates: string[]) => dates.sort((a, b) => +new Date(a) - +new Date(b)).shift();

export const getFirstAvailableBuckets = (data) => {
  const availableBuckets = get(data, "data.buckets", {});
  const availableDates = Object.keys(availableBuckets);
  const date = getLatestBucketDate(availableDates);

  return date ? availableBuckets[date] : [];
};

export const useCefiTokenTable = (): UseCefiTokenTableResult => {
  const portfolioType = usePortfolioType();
  const { exchange_list_ids, isFetching: isExhangeListFetching } = useExchangeListIds();
  const selectedExchange = useSelector((state: RootState) => state.portfolio.selectedExchange);
  const dispatch = useDispatch();

  const params: GetTokenTableParams = {
    currency: "usd",
    exchange_list_ids,
  };

  const { data, isError, isFetching, refetch } = usePortfolioTokenTableQuery(params, {
    skip: !exchange_list_ids || portfolioType === PortfolioType.DEFI,
  });

  const dataFromCache = getFirstAvailableBuckets(exchange_list_ids ? data : null);

  useEffect(() => {
    /*** set data updated timestamp ***/
    if (data && exchange_list_ids) {
      let cefiLatestUpdate;
      if (selectedExchange === SELECT_ALL) {
        cefiLatestUpdate = Object.values(data.data.latest_updates || {}).reduce((a, b) => {
          return new Date(a) > new Date(b) ? a : b;
        }, "");
      } else {
        cefiLatestUpdate = data.data.latest_updates[exchange_list_ids];
      }

      dispatch(setDataUpdatedCefi(cefiLatestUpdate));
    }
  }, [data, exchange_list_ids]);

  useEffect(() => {
    // calculating and dispatching balances
    const totalBalance = dataFromCache.reduce((n, { value, is_position }) => (is_position ? n : n + value), 0);
    dispatch(setCefiTotalBalance(totalBalance));

    // allocation data dispatching
    if (selectedExchange === SELECT_ALL) {
      const allocationData = Array.from(
        dataFromCache.reduce(
          (m, { exchange_id, value, is_position }) =>
            is_position ? m : m.set(exchange_id, (m.get(exchange_id) || 0) + value),
          new Map()
        ),
        ([exchange_id, value]) => ({
          subaccount: exchange_id,
          value,
          percent: totalBalance ? value / totalBalance : 0,
        })
      );
      dispatch(setAccountCefiAllocation(allocationData));
    } else {
      const allocationData = Array.from(
        dataFromCache.reduce(
          (m, { subaccount, value, is_position }) =>
            is_position ? m : m.set(subaccount, (m.get(subaccount) || 0) + value),
          new Map()
        ),
        ([subaccount, value]) => ({
          subaccount: !subaccount ? DEFAULT_CEFI_ALLOCATION_ITEM_ACTIVE : subaccount,
          value,
          percent: totalBalance ? value / totalBalance : 0,
        })
      );
      dispatch(setAccountCefiAllocation(allocationData));
    }
  }, [dataFromCache, selectedExchange]);

  useEffect(() => {
    // tokenAllocationDatadispatching for chart
    const tokenAllocationData = Array.from(
      dataFromCache.reduce(
        (m, { token_symbol, value, is_position }) =>
          is_position ? m : m.set(token_symbol, (m.get(token_symbol) || 0) + value),
        new Map()
      ),
      ([tokenName, value]) => ({
        tokenName,
        value,
      })
    ).sort((a, b) => b.value - a.value);

    dispatch(setTokenCefiAllocation(tokenAllocationData));
  }, [dataFromCache]);

  return {
    dataFromCache,
    isError,
    isFetching: isFetching || isExhangeListFetching,
    refetch,
  };
};
