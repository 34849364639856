export type AccountingLine = {
  id: number;
  account_id: string;
  token_data: Record<string, TokenData>;
  operation: Operation;
  cefi_volume: number | undefined;
  defi_volume: number | undefined;
  transactions_data: Record<string, string>;
  proceed_at: string;
  cefi_fee_rate: number | undefined;
  defi_fee_rate: number | undefined;
};

export type TokenData = {
  network_id: number;
  quantity_paid: number;
  amount_paid: number;
};

export type TokenDetails = {
  token_address: string;
  token_symbol: string;
  logo_uri: string;
  network_id: number;
};

export type AccountingResponse = {
  accounting_lines: AccountingLine[];
  token_details: TokenDetails[];
};

export enum Operation {
  Deposit = "Deposit",
  Withdrawal = "Withdrawal",
  Collection = "Collection",
}

export type FeeManagerContract = {
  id: number;
  network_id: number;
  network_name: string;
  contract_address: string;
  provider: string;
};

export type TokenFee = {
  id: number;
  token_name: string;
  token_symbol: string;
  token_address: string;
  network_id: number;
  logo_uri: string;
  coin_gecko_id: string | null; // type null: to be removed before full feature is complete
};

export type TokenPreference = {
  choice_position: number;
  details: TokenFee;
};

export type UpdateTokenFeeParams = {
  team_uuid: string;
  body: {
    token_address: string;
    choice_position: number;
  }[];
};

export type AddTokenFeeParams = {
  team_uuid: string;
  body: {
    token_address: string;
    choice_position: number;
  };
};

export type GetFeesRatesResponse = {
  cefi: {
    id: number;
    name: string;
    fee: number;
    low_volume_limit: number;
  }[];
  defi: {
    id: number;
    name: string;
    fee: number;
    low_volume_limit: number;
  }[];
};

export type FeeManagerAccountStatsResult = {
  cefi_30_days_volume: number;
  defi_30_days_volume: number;
  fee_rates: number;
  fee_discount: number;
  referral_code: string;
};

export type AddAccountingLineParams = {
  team_uuid: string;
  body: {
    operation: Operation;
    token_address: string;
    quantity: number;
    tx_hash: string;
  };
};
