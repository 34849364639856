import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ethers } from "ethers";
import { TransactionResponse } from "@ethersproject/providers";
import BigNumber from "bignumber.js";
import { getToken, readContract, writeContract } from "@wagmi/core";
import { ChainId, Token } from "@src/pages/defi/types";
import { CHAIN_ID_ANBOTO_CONTRACT_ADDRESS, USDT_ETH_ADDRESS } from "@src/pages/defi/constants";
import anbotoContractAbi from "@src/assets/ABIs/anboto_sc.json";
import { queryFnFactory } from "@src/store/apis/utils";
import ERC20ABI from "@src/assets/ABIs/ERC20.json";
import { AnbotoContractOrder } from "@src/store/apis/anbotoApi/types";
import { EVMBasedAddress } from "@src/pages/defi/types/common";
import { USDT_ERC20ABI } from "@src/assets/ABIs/usdt_eth";
import { wagmiConfig } from "@src/pages/defi/wagmi-config";

export interface IsValidSignatureProps {
  accountFrom: string;
  chainId: ChainId;
  order: AnbotoContractOrder;
  signature: string;
}

export interface TokenBalanceProps {
  account: string;
  chainId: ChainId;
  tokenAddress: string;
}

export type GetBalanceOfParams = {
  chainId: ChainId | number;
  tokenAddress: string;
  walletAddress: string;
};

export type GetAllowanceParams = {
  chainId: ChainId | number;
  tokenAddress: string;
  walletAddress: string;
  feeManagerContract: string;
};

export type ApproveProps = {
  chainId: ChainId | number;
  contractAddress: string;
  tokenAddress: string;
  tokenAmount: string;
};

export const blockchainApi = createApi({
  reducerPath: "blockchainApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: [
    "TOKEN_BALANCE",
    "VALID_SIGNATURE",
    "TOKEN_DECIMALS",
    "TOKEN_BALANCE",
    "RAW_BALANCE_OF",
    "LATEST_BLOCK_TIMESTAMP",
    "FETCH_TOKEN",
  ],
  endpoints: (builder) => ({
    tokenAccountBalance: builder.query<string, TokenBalanceProps>({
      providesTags: ["TOKEN_BALANCE"],
      queryFn: queryFnFactory<TokenBalanceProps, string>(async ({ account, chainId, tokenAddress }) => {
        const balanceOf = (await readContract(wagmiConfig, {
          address: tokenAddress as EVMBasedAddress,
          abi: ERC20ABI,
          functionName: "balanceOf",
          chainId: chainId,
          args: [account],
        }).catch((error) => {
          console.log("balanceOf error", error);

          return 0;
        })) as BigNumber;

        console.log({ balanceOf });

        const decimals = (await readContract(wagmiConfig, {
          address: tokenAddress as EVMBasedAddress,
          abi: ERC20ABI,
          chainId: chainId,
          functionName: "decimals",
        }).catch((error) => {
          console.log("decimals error", error);

          return 0;
        })) as number;

        return new BigNumber(balanceOf).div(10 ** decimals).toString();
      }),
    }),
    checkIsValidSignature: builder.query<any, IsValidSignatureProps>({
      providesTags: ["VALID_SIGNATURE"],
      queryFn: queryFnFactory<IsValidSignatureProps, any>(
        async ({ accountFrom, chainId, order, signature }, { getState }) => {
          const state = getState() as any;
          const walletProvider = state.blockchain.walletProvider;
          const provider = new ethers.providers.Web3Provider(walletProvider, "any");
          const signer = provider.getSigner();
          const anbotoContractAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS[chainId];
          const anbotoContract = new ethers.Contract(anbotoContractAddress, anbotoContractAbi, signer);

          return anbotoContract
            .isValidSignature(order, accountFrom, signature)
            .then((x) => JSON.parse(JSON.stringify(x)))
            .catch((error) => console.log("checkIsValidSignature error", error));
        }
      ),
    }),
    getTokenDecimals: builder.query<number, { chainId: ChainId; tokenAddress: string }>({
      providesTags: ["TOKEN_DECIMALS"],
      queryFn: queryFnFactory<{ chainId: ChainId; tokenAddress: string }, number>(async ({ chainId, tokenAddress }) => {
        return readContract(wagmiConfig, {
          chainId: chainId,
          address: tokenAddress as EVMBasedAddress,
          abi: ERC20ABI,
          functionName: "decimals",
        }).catch((error) => {
          console.log("decimals error", error);
          return 0;
        }) as Promise<number>;
      }),
    }),
    getAllowance: builder.query<number, GetAllowanceParams>({
      queryFn: queryFnFactory<GetAllowanceParams, number>(
        async ({ tokenAddress, chainId, walletAddress, feeManagerContract }) => {
          const decimals = (await readContract(wagmiConfig, {
            address: tokenAddress as EVMBasedAddress,
            abi: ERC20ABI,
            chainId: chainId,
            functionName: "decimals",
          }).catch((error) => {
            console.log("decimals error", error);

            return 0;
          })) as number;

          const allowance = (await readContract(wagmiConfig, {
            address: tokenAddress as EVMBasedAddress,
            abi: ERC20ABI,
            functionName: "allowance",
            chainId: chainId,
            args: [walletAddress, feeManagerContract],
          }).catch((error) => {
            console.log("allowance error", error);

            return 0;
          })) as BigNumber;

          return new BigNumber(allowance).div(10 ** decimals).toNumber();
        }
      ),
    }),
    approve: builder.mutation<EVMBasedAddress | null, ApproveProps>({
      queryFn: queryFnFactory<ApproveProps, EVMBasedAddress | null>(
        async ({ chainId, contractAddress, tokenAddress, tokenAmount }) => {
          const abi = (
            chainId === ChainId.ETHEREUM && tokenAddress === USDT_ETH_ADDRESS ? USDT_ERC20ABI : ERC20ABI
          ) as any;

          return writeContract(wagmiConfig, {
            address: tokenAddress as EVMBasedAddress,
            abi,
            functionName: "approve",
            chainId: chainId,
            args: [contractAddress, tokenAmount],
          }).catch((error) => {
            console.log("approve error", error);
            return null;
          });
        }
      ),
    }),
    getGasTankBalance: builder.query<string, { chainId: ChainId; account: string }>({
      providesTags: ["TOKEN_BALANCE"],
      queryFn: queryFnFactory<{ chainId: ChainId; account: string }, string>(({ account, chainId }, { getState }) => {
        const state = getState() as any;
        const walletProvider = state.blockchain.walletProvider;
        const provider = new ethers.providers.Web3Provider(walletProvider, "any");
        const signer = provider.getSigner();
        const anbotoContractAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS[chainId];
        const anbotoContract = new ethers.Contract(anbotoContractAddress, anbotoContractAbi, signer);
        return anbotoContract
          .gasTank(account)
          .then((x) => x.toString())
          .catch(() => "0");
      }),
    }),
    addGas: builder.mutation<TransactionResponse, { chainId: ChainId; gasTankInput: string }>({
      queryFn: queryFnFactory<{ chainId: ChainId; gasTankInput: string }, TransactionResponse>(
        ({ gasTankInput, chainId }, { getState }) => {
          const state = getState() as any;
          const walletProvider = state.blockchain.walletProvider;
          const provider = new ethers.providers.Web3Provider(walletProvider, "any");
          const signer = provider.getSigner();
          const gasTankInputBN = ethers.utils.parseEther(gasTankInput);
          const anbotoContractAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS[chainId];
          return signer
            .sendTransaction({
              value: gasTankInputBN,
              to: anbotoContractAddress,
            })
            .then((x) => JSON.parse(JSON.stringify(x)));
        }
      ),
    }),
    getLatestBlockTimestamp: builder.query<string, null>({
      providesTags: ["LATEST_BLOCK_TIMESTAMP"],
      queryFn: queryFnFactory<null, string>((args, { getState }) => {
        const state = getState() as any;
        const walletProvider = state.blockchain.walletProvider;
        const provider = new ethers.providers.Web3Provider(walletProvider, "any");
        return provider.getBlock("latest").then((x) => x.timestamp.toString());
      }),
    }),
    fetchToken: builder.query<Token | null, { address: string; chainId: ChainId }>({
      providesTags: ["FETCH_TOKEN"],
      queryFn: queryFnFactory<{ address: EVMBasedAddress; chainId: ChainId }, Token | null>(
        async ({ address, chainId }) => {
          try {
            const token = await getToken(wagmiConfig, {
              address,
              chainId,
            });

            return {
              name: token.name,
              chainId,
              symbol: token.symbol,
              decimals: token.decimals,
              address,
              logoURI: undefined,
              trusted: false,
            } as Token;
          } catch (e) {
            console.log("fetch token error", e);

            return null;
          }
        }
      ),
    }),
  }),
});

export const {
  useTokenAccountBalanceQuery,
  useLazyTokenAccountBalanceQuery,
  useFetchTokenQuery,
  useLazyFetchTokenQuery,
} = blockchainApi;
