import _get from "lodash/get";
import { MAIN_ACCOUNT_VALUE } from "../constant";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { enrichDataWithIcons, getFirstAvailableBuckets, getLatestBucketDate } from "@src/pages/portfolio";
import {
  CefiAllocation,
  CefiAllocationWithIcon,
  CefiExchangeMarketType,
  OrderSymbol,
} from "@src/store/apis/anbotoApi/types";
import { buildTradeLinks, getPair, proposeNotFoundObjects } from "@src/pages/portfolio/utils/helpers";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { cefiSymbolsApi, cefiSymbolsIndex } from "@src/store/apis/algoliaApi";
import { getPortfolioSubscription } from "@src/subscriptions/anboto-portfolio-subscription";
import { RootState } from "@src/store/types";
import { PortfolioMsgAsset, PortfolioMsgData } from "@src/subscriptions/types";

export type GetTokenTableParams = {
  account_uuid?: string;
  currency: string;
  exchange_list_ids?: string; // binance,bybit
};

export type GetTokenTableResult = {
  success: boolean;
  data: {
    buckets: {
      [date: string]: CefiAllocationWithIcon[];
    };
    latest_updates: {
      [exchangeId: string]: string;
    }; // gateio: "2023-01-23T12:38:55.574534Z"
  };
};

const portfolioCefiTokenTableApi = anbotoApi.injectEndpoints({
  endpoints: (build) => ({
    portfolioTokenTable: build.query<GetTokenTableResult, GetTokenTableParams>({
      query: (params) => ({
        url: `/portfolio/cefi_token_table/`,
        params,
      }),
      transformResponse: (response: GetTokenTableResult, meta) => {
        const url = meta?.request.url || "";
        const exchanges = url
          .split("&")
          .find((el) => el.includes("exchange_list_ids"))
          ?.replaceAll("exchange_list_ids=", "")
          .split("%2C");

        const availableBuckets = _get(response, "data.buckets", {});
        const data = getFirstAvailableBuckets(response).filter((el: CefiAllocationWithIcon) =>
          exchanges?.includes(el.exchange_id)
        );
        const date = getLatestBucketDate(Object.keys(availableBuckets));
        const dataWithIcons = enrichDataWithIcons<CefiAllocation>(data);

        if (date && availableBuckets[date]) {
          response.data.buckets[date] = [...dataWithIcons];
        }
        return { ...response };
      },
      async onQueryStarted(arg, api) {
        try {
          const casheData = await api.queryFulfilled;
          const bucketDate = getLatestBucketDate(Object.keys(casheData?.data?.data?.buckets || {}));
          const assets = getFirstAvailableBuckets(casheData?.data) || [];

          if (assets) {
            const searchIds: OrderSymbol[] = assets.map((x) => ({
              symbol: getPair(x.token_symbol, !!x.is_position),
              exchange: CEFI_EXCHANGE_NAME[x.exchange_id],
              market_type: x.market_type as CefiExchangeMarketType,
            }));
            const getObjects = api.dispatch(cefiSymbolsApi.endpoints.getObjects.initiate(searchIds));
            const algoliaObjects = await getObjects;
            const currentSearchResult = [...(algoliaObjects?.data || [])];
            const searchResult = await proposeNotFoundObjects(currentSearchResult, assets, cefiSymbolsIndex);
            const currentTradeLinks = buildTradeLinks(searchResult, assets);

            if (currentTradeLinks) {
              const assetsWithTradeLink = assets.map((asset) => ({
                ...asset,
                tradeLink: currentTradeLinks[asset.token_symbol],
              }));

              if (bucketDate) {
                api.updateCachedData((draft) => {
                  draft.data.buckets[bucketDate] = [...assetsWithTradeLink];
                });
              }
            }
            getObjects.unsubscribe();
          }
        } catch (e) {
          console.log(e);
        }
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, updateCachedData, getState }) {
        await cacheDataLoaded;

        const state = getState() as RootState;
        const accountId = state.user.team_uuid;
        const accountSub = getPortfolioSubscription(accountId);
        const exchanges = arg.exchange_list_ids?.split(",");

        updateCachedData((draft) => {
          const state = getState() as RootState;
          const table = getFirstAvailableBuckets(draft);

          const streamData = state.portfolio.stream.assets;

          table.forEach((row) => {
            const { exchange_id, market_type, subaccount, token_symbol } = row;
            const streamDataVal = _get(
              streamData,
              `${exchange_id}.${subaccount || MAIN_ACCOUNT_VALUE}.${market_type}.${token_symbol}`,
              {}
            );
            const { balance, price } = streamDataVal as PortfolioMsgAsset;

            if (balance !== undefined) row.balance = balance;
            if (price !== undefined) row.price = price;
          });
        });

        const onMessage = (msg: PortfolioMsgData) => {
          const { asset_class, data, exchange, subaccount } = msg || ({} as PortfolioMsgData);

          if (!exchanges?.includes(exchange)) return false;

          updateCachedData((draft) => {
            try {
              const buckets = draft.data.buckets;

              const latestDate = getLatestBucketDate(Object.keys(buckets));

              if (latestDate && data) {
                const tableData = buckets[latestDate] || [];

                const filteredTableData = tableData.filter(
                  (el) => el.exchange_id === exchange && el.subaccount === subaccount && el.market_type === asset_class
                );

                if (!Object.keys(data).length) {
                  filteredTableData.forEach((row) => (row.balance = 0));
                  return;
                }

                Object.keys(data).forEach((symbol) => {
                  const row = filteredTableData.find(
                    (el) => el.token_symbol === symbol && el.exchange_id === exchange && el.market_type === asset_class
                  );
                  const price = data[symbol].price || row?.price || 0;
                  const balance = data[symbol].balance || 0;
                  const value = balance * price;

                  if (row) {
                    row.balance = balance;
                    row.price = price;
                    row.value = value;
                  } else if (balance) {
                    tableData.push({
                      token_symbol: symbol,
                      exchange_id: exchange,
                      market_type: asset_class,
                      subaccount,
                      balance,
                      price,
                      value,
                      // @TODO get rid of portion in allacations
                      portion: 0,
                      is_position: asset_class === CefiExchangeMarketType.FUTURE,
                      coinName: symbol,
                      symbol,
                      url: "",
                      tradeLink: "",
                    });
                  }
                });
              }
            } catch (err) {
              console.log(err);
            }
          });
        };

        accountSub?.listenAll(onMessage);

        await cacheEntryRemoved;

        accountSub?.stopListenAll(onMessage);
      },
    }),
  }),
  overrideExisting: false,
});

export const portfolioCefiTokenTable = portfolioCefiTokenTableApi.endpoints.portfolioTokenTable;

export const { usePortfolioTokenTableQuery, useLazyPortfolioTokenTableQuery, endpoints } = portfolioCefiTokenTableApi;
