import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type PlacementFieldProps = ControllerRenderProps & AnbotoTextFieldProps;

export const PlacementField = forwardRef((props: PlacementFieldProps, ref) => {
  return (
    <AnbotoTextField
      {...props}
      inputRef={ref}
      variant="outlined"
      size="small"
      label="Place Level"
      fullWidth
      type="number"
    />
  );
});

PlacementField.displayName = "PlacementField";
