import { useEffect } from "react";
import { useAuth } from "@src/features/auth/hooks/use-auth";
import { useAppSelector } from "@src/store/hooks";

export const useWisepops = (ready: boolean) => {
  const { authenticated } = useAuth();
  const terms_of_use = useAppSelector((state) => state.user.terms_of_use);
  const isQAenv = window.location.href?.includes("trade-qa");

  useEffect(() => {
    if (process.env.REACT_APP_DEPLOY_ENV === "prd" && authenticated && terms_of_use && !isQAenv && ready) {
      (window as any).wisepops("pageview");
    }
  }, [authenticated, terms_of_use, ready]);
};
