import React from "react";
import { Link, Stack, Typography, useTheme } from "@mui/material";
import DashboardDataCardHeader from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/dashboard-data-card-header";
import DyltrigLogo from "@src/assets/images/trading-competitions/dyltrig.webp";
import Satsdart from "@src/assets/images/trading-competitions/satsdart.webp";
import { Card } from "@src/pages/competitions/components/content";
import { ReactComponent as WooxLogo } from "@src/assets/svg/trading-competition/woox-logo.svg";

export const About = () => {
  const theme = useTheme();
  return (
    <Card>
      <DashboardDataCardHeader fontWeight={500} fontSize={16} header="About" />
      <Stack direction="column" gap={2}>
        <Stack direction="row" gap={2}>
          <Stack direction="column" gap={2} flex={1}>
            <Stack height={34} justifyContent="center" direction="column">
              <WooxLogo
                style={{
                  height: 16,
                  left: -10,
                  position: "relative",
                }}
              />
            </Stack>
            <Typography color={theme.palette.text.secondary} fontSize={14} fontWeight={400}>
              WOO X is one of the leading exchanges with access to 200+ spot and futures markets. Along with access to
              deep liquidity, WOO X is one of the most competitive exchanges with respect to fees and order
              execution.Click{" "}
              <Link color={theme.palette.text.secondary} href="https://x.woo.org/en/trade/BTC_USDT">
                here
              </Link>{" "}
              to create an account on WOO X.
            </Typography>
          </Stack>
          <Stack direction="column" gap={2} flex={1}>
            <Stack direction="row" gap={1} alignItems="center">
              <img
                src={DyltrigLogo}
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 32,
                  left: -5,
                  position: "relative",
                }}
                alt="Dyltrig"
              />{" "}
              <Typography fontSize={20} fontWeight={500}>
                Dyltrig
              </Typography>
            </Stack>

            <Typography
              sx={{
                top: -25,
              }}
              fontSize={14}
              fontWeight={400}
              color={theme.palette.text.secondary}
            >
              Dyltrig is a degenerate retail trader trying to break chains on the road to financial freedom. A member of
              “WOO-Force” since its inception - a group of like-minded WOO enthusiasts helping to grow the WOO network.{" "}
              <Link sx={{ color: "white" }} href="https://twitter.com/dyltrig" target="_blank" fontSize={14}>
                @dyltrig
              </Link>
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" gap={2}>
          <Stack direction="column" gap={2} flex={1}>
            <Stack direction="row" gap={1} alignItems="center">
              <img
                src={Satsdart}
                style={{
                  height: 32,
                  width: 32,
                  borderRadius: 32,
                  left: -5,
                  position: "relative",
                }}
                alt="Satsdart"
              />{" "}
              <Typography fontSize={20} fontWeight={500}>
                Satsdart
              </Typography>
            </Stack>
            <Typography
              sx={{
                top: -25,
              }}
              fontSize={14}
              fontWeight={400}
              color={theme.palette.text.secondary}
            >
              Full time shitposter, part time trader and WOOX partner for a while now.{" "}
              <Link sx={{ color: "white" }} href="https://twitter.com/satsdart" target="_blank" fontSize={14}>
                @satsdart
              </Link>
            </Typography>
          </Stack>
          <Stack flex={1}></Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
