import React, { FC } from "react";
import { Box, Tooltip } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { SelectSpeedDialog } from "./select-speed-dialog";
import { useDialog } from "@src/hooks/useDialog";
import { AnbotoIconButton } from "@src/components";

export interface SelectSpeedButtonProps {}

export const SelectSpeedButton: FC<SelectSpeedButtonProps> = () => {
  const selectSpeedDialog = useDialog();

  return (
    <Box>
      <Tooltip title="Transaction speed settings">
        <Box>
          <AnbotoIconButton flat onClick={selectSpeedDialog.show} size="small" sx={{ width: 32, height: 32 }}>
            <Settings sx={{ width: 16 }} />
          </AnbotoIconButton>
        </Box>
      </Tooltip>
      <SelectSpeedDialog isOpen={selectSpeedDialog.isOpen} onClose={selectSpeedDialog.hide} />
    </Box>
  );
};
