import { ChainId, Token } from "@src/pages/defi/types";

export const CHAIN_ID_DEFAULT_TOKENS: Record<ChainId, Token[]> = {
  [ChainId.ETHEREUM]: [
    {
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      chainId: 1,
      name: "WrappedEther",
      symbol: "WETH",
      decimals: 18,
      logoURI: "https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",
    },
    {
      address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      chainId: 1,
      name: "TetherUSD",
      symbol: "USDT",
      decimals: 6,
      logoURI: "https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png",
    },
    {
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      chainId: 1,
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      logoURI: "https://tokens-data.1inch.io/images/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    },
    {
      address: "0x6b175474e89094c44da98b954eedeac495271d0f",
      chainId: 1,
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      logoURI: "https://tokens-data.1inch.io/images/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    },
  ],
  [ChainId.BSC]: [
    {
      name: "WBNB Token",
      symbol: "WBNB",
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      chainId: 56,
      decimals: 18,
      logoURI: "https://tokens.pancakeswap.finance/images/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png",
    },
    {
      name: "Binance Pegged USDT",
      symbol: "USDT",
      address: "0x55d398326f99059fF775485246999027B3197955",
      chainId: 56,
      decimals: 18,
      logoURI: "https://tokens.pancakeswap.finance/images/0x55d398326f99059fF775485246999027B3197955.png",
    },
    {
      address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      chainId: 56,
      name: "USD Coin",
      symbol: "USDC",
      decimals: 18,
      logoURI: "https://tokens-data.1inch.io/images/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    },

    {
      address: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
      chainId: 56,
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      logoURI: "https://tokens-data.1inch.io/images/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    },
  ],
  [ChainId.POLYGON]: [
    {
      name: "Wrapped Matic",
      address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      symbol: "WMATIC",
      decimals: 18,
      chainId: 137,
      logoURI:
        "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png",
    },
    {
      name: "Tether USD",
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      symbol: "USDT",
      decimals: 6,
      chainId: 137,
      logoURI:
        "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png",
    },
    {
      address: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
      chainId: 137,
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      logoURI: "https://tokens-data.1inch.io/images/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    },

    {
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      chainId: 137,
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      logoURI: "https://tokens-data.1inch.io/images/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    },
  ],
  [ChainId.AVALANCHE]: [
    {
      chainId: 43114,
      address: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      decimals: 18,
      name: "Wrapped AVAX",
      symbol: "WAVAX",
      logoURI:
        "https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7/logo_24.png",
    },
    {
      chainId: 43114,
      address: "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
      decimals: 6,
      name: "Tether USD",
      symbol: "USDT.e",
      logoURI:
        "https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xc7198437980c041c805A1EDcbA50c1Ce5db95118/logo_24.png",
    },
    {
      address: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      chainId: 43114,
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      logoURI: "https://tokens-data.1inch.io/images/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    },

    {
      address: "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
      chainId: 43114,
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      logoURI: "https://tokens-data.1inch.io/images/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    },
  ],
  [ChainId.ARBITRUM]: [
    {
      chainId: ChainId.ARBITRUM,
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/arbitrum/assets/0x82aF49447D8a07e3bd95BD0d56f35241523fBab1/logo.png",
    },
    {
      chainId: ChainId.ARBITRUM,
      address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      name: "Tether USD",
      symbol: "USDT",
      decimals: 6,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/arbitrum/assets/0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9/logo.png",
    },
    {
      address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      chainId: ChainId.ARBITRUM,
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      logoURI: "https://tokens-data.1inch.io/images/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    },

    {
      address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      chainId: ChainId.ARBITRUM,
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      logoURI: "https://tokens-data.1inch.io/images/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    },
  ],
  [ChainId.OPTIMISM]: [
    {
      chainId: 10,
      address: "0x4200000000000000000000000000000000000006",
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      logoURI: "https://ethereum-optimism.github.io/data/WETH/logo.png",
      // extensions: {
      //   "optimismBridgeAddress": "0x4200000000000000000000000000000000000010"
      // }
    },
    {
      chainId: 10,
      address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
      name: "Tether USD",
      symbol: "USDT",
      decimals: 6,
      logoURI: "https://ethereum-optimism.github.io/data/USDT/logo.png",
      // "extensions": {
      //   "optimismBridgeAddress": "0x4200000000000000000000000000000000000010"
      // }
    },
    {
      address: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
      chainId: 10,
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      logoURI: "https://tokens-data.1inch.io/images/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
    },

    {
      address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
      chainId: 10,
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      logoURI: "https://tokens-data.1inch.io/images/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    },
  ],
  [ChainId.BASE]: [
    {
      chainId: 8453,
      address: "0x4200000000000000000000000000000000000006",
      name: "Wrapped Ether",
      symbol: "WETH",
      decimals: 18,
      logoURI: "https://ethereum-optimism.github.io/data/WETH/logo.png",
    },
    {
      chainId: 8453,
      address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      logoURI: "https://basescan.org/token/images/centre-usdc_28.png",
    },
    {
      address: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
      chainId: 8453,
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      logoURI: "https://tokens-data.1inch.io/images/0x6b175474e89094c44da98b954eedeac495271d0f.png",
    },
  ],
};
