import React, { useState } from "react";
import { InputLabel, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthCard } from "../layout/auth-card";
import { FormRow } from "../layout/form-row";
import { FormError } from "../layout/form-error";
import { BackButton } from "../layout/back";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { useAuth } from "@src/features/auth/hooks/use-auth";

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const { confirmResetPassword } = useAuth();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const username = searchParams.get("email") || "";
  const code = searchParams.get("code") || "";

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      password: "",
      password_repeat: "",
      code,
    },
  });

  const onSubmit = async ({ password, code }: any) => {
    setLoading(true);

    try {
      await confirmResetPassword({
        username,
        newPassword: password,
        confirmationCode: code,
      });

      setSuccess(true);
    } catch (error) {
      if (error.name === "CodeMismatchException") {
        setError("code", { message: error.message });
      } else {
        setError("password", { message: error.message });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthCard sx={{ minHeight: 0 }} logo>
      {success ? (
        <Typography variant="body2">Password has been reset with the new password.</Typography>
      ) : (
        <>
          {(!code || errors.code) && (
            <>
              <FormRow>
                <InputLabel>Confirmation code</InputLabel>
                <AnbotoTextField
                  data-sentry-mask="true"
                  size="large"
                  error={Boolean(errors.code)}
                  placeholder="Enter confirmation code"
                  {...register("code", {
                    minLength: {
                      value: 6,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                  name="code"
                />
              </FormRow>
              {errors.code && <FormError variant="body2">{errors.code.message}</FormError>}
            </>
          )}

          <FormRow sx={{ marginTop: "20px !important" }}>
            <InputLabel>New Password</InputLabel>
            <AnbotoTextField
              data-sentry-mask="true"
              size="large"
              error={Boolean(errors.password)}
              type="password"
              placeholder="Enter new password"
              {...register("password", {
                minLength: {
                  value: 6,
                  message: "Password must have at least 8 characters",
                },
              })}
              name="password"
            />
          </FormRow>
          {errors.password && <FormError variant="body2">{errors.password.message}</FormError>}
          <FormRow>
            <InputLabel>Confirm password</InputLabel>
            <AnbotoTextField
              size="large"
              data-sentry-mask="true"
              error={Boolean(errors.password_repeat)}
              type="password"
              placeholder="Confirm password"
              {...register("password_repeat", {
                validate: (value) => (value === getValues("password") ? true : "The passwords do not match"),
              })}
              name="password_repeat"
            />
            {errors.password_repeat && <FormError>{errors.password_repeat.message}</FormError>}
          </FormRow>

          <AnbotoButton
            sx={{ mt: 6 }}
            variant="contained"
            fullWidth={true}
            disabled={!isDirty}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            loadingIndicator="Loading..."
            type="submit"
            size="large"
          >
            Save Password
          </AnbotoButton>
        </>
      )}
      <BackButton title="Back to login" to="/login" />
    </AuthCard>
  );
};
