import { useEffect, useRef } from "react";
import { SnackbarKey, useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { compareCaseInsensitive } from "@src/pages/defi/utils";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useTestnetEnabled } from "@src/hooks/use-testnet-enabled";
import { OTC_EXCHANGES_MAP } from "@src/features/otc/constants";

export const SetupExchangeKeysInfoModal = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const snackbarId = useRef<SnackbarKey | null>(null);

  const getExchangeConfigsQuery = anbotoApi.useGetExchangeConfigsQuery(null);
  const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);
  const teamId = getUserDetailsQuery.data?.team_uuid;
  const isTestnet = useTestnetEnabled();
  const ready =
    !getExchangeConfigsQuery.isLoading &&
    !getUserDetailsQuery.isLoading &&
    getExchangeConfigsQuery.data &&
    getUserDetailsQuery.data;

  const getFilteredExchangeConfigs = () => {
    return (
      getExchangeConfigsQuery.data?.results.filter(
        (x) => compareCaseInsensitive(x.team_uuid, teamId || "") && OTC_EXCHANGES_MAP[x.exchange_id]
      ) || []
    );
  };

  useEffect(() => {
    if (ready && !snackbarId.current && !isTestnet && !getFilteredExchangeConfigs().length) {
      const key = snackbar.enqueueSnackbar("Setup your exchange keys to start trading", {
        variant: "info",
        actionName: `Setup API keys`,
        buttonAction: () => navigate("/settings/linked/otc"),
      });

      snackbarId.current = key;
    }

    return () => {
      if (snackbarId.current) {
        snackbar.closeSnackbar(snackbarId.current);
      }
    };
  }, [getExchangeConfigsQuery.data, getUserDetailsQuery.data]);

  return null;
};
