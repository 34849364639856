import { CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";

export enum SymbolSelectTab {
  ALL = "all",
  SPOT = "spot",
  FUTURE = "future",
  RECENT = "recent",
  FAVORITES = "favorites",
  CFD = "cfd",
}

export type HitSymbol = {
  symbol: string;
  exchange: string;
  market_type: CefiExchangeMarketType;
  coin1: string;
  coin2: string;
  coin_margin: null;
  is_derivative: boolean;
  symbol_label: string;
};

export type SymbolSelectVariant = "input" | "input-dark" | "inline";
