import React from "react";
import { Stack, Paper, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { PlatformOrder, adminApi } from "./api";
import { getColumns } from "./get-columns";
import { ExpandedRow } from "./expanded-row";
import { GetOrdersType, OrderSource, TFilters, TOrderSourceOptions } from "./types";
import { AccountsSelect } from "./accounts-select";
import { setFilter } from "./admin.slice";
import { OrdersCurrentTab } from "@src/store/apis/anbotoApi/types";
import { AnbotoTable } from "@src/components/anboto-table";
import { AnbotoButtonGroup, AnbotoButtonGroupOption } from "@src/components/ui/anboto-button-group";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { useWindowSize } from "@src/hooks/use-window-size";
import { HEADER_HEIGHT } from "@src/components/orderbook/components/vertical-header";
import { getPlatformOrdersSubscription } from "@src/subscriptions/anboto-platform-orders-subscription";
import { ORDERS_LIMIT } from "@src/store/constants";

const cefiDefiOptions: AnbotoButtonGroupOption<GetOrdersType>[] = [
  { label: "All", value: GetOrdersType.All },
  { label: "CeFi", value: GetOrdersType.Cefi },
  { label: "DeFi", value: GetOrdersType.Defi },
];

const sourceOptions: AnbotoButtonGroupOption<TOrderSourceOptions>[] = [
  { label: "All", value: "all" },
  { label: "Web UI", value: OrderSource.WebUI },
  { label: "Api", value: OrderSource.Api },
];

const orderTypeOptions: AnbotoButtonGroupOption<OrdersCurrentTab>[] = [
  { label: "Regular", value: OrdersCurrentTab.Regular },
  { label: "Trigger", value: OrdersCurrentTab.Trigger },
];

const loadingData = Array.from(Array(10)).fill({});

getPlatformOrdersSubscription();

export const AdminPage = () => {
  const dispatch = useAppDispatch();
  const { cefiDefi, orderType, source, teams, onlyActive } = useAppSelector((state) => state.support.filters);
  const [pagination, setPagination] = React.useState({ currentPage: 0, rowsPerPage: 20 });
  const windowSize = useWindowSize();

  const { data, isFetching, isError, refetch } = adminApi.useGetPlatformOrdersQuery({
    accountIds: teams?.length ? teams.map(({ uuid }) => uuid).join(",") : undefined,
    cefiDefi,
    tab: orderType,
    limit: onlyActive ? ORDERS_LIMIT : pagination.rowsPerPage,
    source: source === "all" ? undefined : source,
    onlyActive,
  });

  const columns = React.useMemo(() => getColumns({ currentTab: orderType }), [data]);

  const tableData = isFetching ? loadingData : data?.results || [];

  const activeAccounts = React.useMemo(
    () => data?.results.reduce((res, order) => ({ ...res, [order.account_uuid]: true }), {}),
    [data]
  );

  const updateFilter = (filter: Partial<TFilters>) => {
    dispatch(setFilter(filter));
  };

  const handleTabChange = (currentTab: OrdersCurrentTab) => {
    updateFilter({ orderType: currentTab });
  };

  return (
    <Stack
      sx={{
        width: (windowSize.width || 0) - 60,
        height: (windowSize.height || 0) - HEADER_HEIGHT - 55,
        flex: "1 0 20px",
        position: "relative",
      }}
    >
      <Paper>
        <Stack p={2} gap={2}>
          <Stack direction="row" gap={2} flexWrap="wrap">
            <AnbotoButtonGroup<GetOrdersType>
              size="small"
              options={cefiDefiOptions}
              defaultValue={cefiDefi}
              onChange={(value) => updateFilter({ cefiDefi: value })}
            />
            <AnbotoButtonGroup<OrdersCurrentTab>
              size="small"
              options={orderTypeOptions}
              defaultValue={orderType}
              onChange={handleTabChange}
            />
            <AnbotoButtonGroup<TOrderSourceOptions>
              size="small"
              options={sourceOptions}
              defaultValue={source}
              onChange={(value) => updateFilter({ source: value })}
            />
            <FormControlLabel
              label={
                <Typography variant="body2" color={(theme) => theme.palette.text.disabled}>
                  Active only
                </Typography>
              }
              sx={{ margin: 0 }}
              checked={onlyActive}
              control={
                <Checkbox
                  size="small"
                  sx={{ padding: 0, paddingRight: 1 }}
                  onChange={(e) => {
                    updateFilter({ onlyActive: e.target.checked });
                  }}
                />
              }
            />
          </Stack>
          <AccountsSelect
            activeAccounts={activeAccounts}
            defaultSelected={teams}
            onSave={(teams) => updateFilter({ teams })}
          />
        </Stack>
      </Paper>
      <Stack flex={1} overflow="hidden">
        <AnbotoTable<PlatformOrder>
          data={tableData || []}
          columns={columns}
          loading={isFetching}
          error={isError}
          refetch={refetch}
          getRowId={(row) => row.order_id}
          expandedRowRender={(data: PlatformOrder) => <ExpandedRow data={data} key={data.order_id} />}
          containerSx={{ "& .MuiBox-root": { flexShrink: 0 } }}
          paginationWithDataFetching={
            !onlyActive
              ? {
                  count: data?.count || 0,
                  pagination,
                  setPagination,
                }
              : undefined
          }
        />
      </Stack>
    </Stack>
  );
};
