import React, { FC, useEffect } from "react";
import { Stack } from "@mui/material";
import { useAccount } from "wagmi";
import { useSendCryptoFormContext, useWalletName } from "./hooks";
import { PasteOrSelectAccount } from "./paste-or-select-account";
import { Divider } from "./styled";
import { SelectTokenWithAmount } from "./select-token-with-amount";
import { AccountRainbowSelect } from "./account-rainbow-select";
import { ChainId, Token } from "@src/pages/defi/types";
import { CHAIN_ID_DEFAULT_TOKEN_PAIRS } from "@src/pages/defi/constants";

export interface SendCryptoFormProps {
  selectedToken?: Token;
  step: number;
}

export const SendCryptoForm: FC<SendCryptoFormProps> = ({ selectedToken }) => {
  const account = useAccount();
  const chain = account.chain;
  const sendCryptoForm = useSendCryptoFormContext();
  const { name } = useWalletName(account?.address || "");
  const chainId = sendCryptoForm.watch("chainId");
  const formAccount = sendCryptoForm.watch("accountFrom");

  const onChainIdChange = (newChainId: ChainId) => {
    sendCryptoForm.setValue("chainId", newChainId);
    sendCryptoForm.setValue("tokenAddress", CHAIN_ID_DEFAULT_TOKEN_PAIRS[newChainId].from.address);
  };

  useEffect(() => {
    if (account?.address && account?.address !== formAccount) {
      sendCryptoForm.setValue("accountFrom", account.address);
      sendCryptoForm.setValue("accountFromName", name);
    }
  }, [account, formAccount]);

  useEffect(() => {
    if (chain?.id && chainId !== chain.id) {
      onChainIdChange(chain.id);
    }
  }, [chain?.id, chainId]);

  return (
    <Stack direction="column" gap={2}>
      <AccountRainbowSelect formAccount={account?.address} error={!!sendCryptoForm.formState.errors?.accountFrom} />
      {account && (
        <>
          {!!selectedToken && <SelectTokenWithAmount account={account?.address} selectedToken={selectedToken} />}
          <Divider />
          <PasteOrSelectAccount label="SEND TO" />
        </>
      )}
    </Stack>
  );
};
