import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useLazyEstimateTransferQuery } from "@src/pages/portfolio";
import { coingeckoApi } from "@src/store/apis/coingeckoApi";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { GAS_PRICE_OPTION_GAS_ORACLE_PROP } from "@src/pages/defi/constants";
import { ChainId, GasPriceOptionType } from "@src/pages/defi/types";

export interface UseTransferEstimateResult {
  networkFee?: string;
  transferEstimate: (accountTo: string, tokenAddress: string, tokenAmount: string, decimals: number) => void;
}

export const useTransferEstimate = (chainId: ChainId): UseTransferEstimateResult => {
  const [networkFee, setNetworkFee] = useState<string>();
  const gasOracleProp = GAS_PRICE_OPTION_GAS_ORACLE_PROP[GasPriceOptionType.HIGH];
  const [estimateGas, estimateGasResult] = useLazyEstimateTransferQuery();
  const getChainPriceQuery = coingeckoApi.useGetChainPriceQuery({ chainId });
  const getGasOracleQuery = anbotoApi.useGetGasOracleQuery({ chainId });
  const selectedGasPriceOptionGwei = getGasOracleQuery.data ? getGasOracleQuery.data.data[gasOracleProp] : "0";
  const selectedGasPriceOptionEth = new BigNumber(selectedGasPriceOptionGwei).div(10 ** 9).toFixed();
  const usdPrice = getChainPriceQuery?.data?.usd;

  const transferEstimate = async (accountTo: string, tokenAddress: string, tokenAmount: string, decimals: number) => {
    await estimateGas({ accountTo, tokenAddress, tokenAmount, decimals });
  };

  useEffect(() => {
    if (estimateGasResult?.data && usdPrice) {
      const bigNumberEstimate = new BigNumber(estimateGasResult.data);
      const fee = bigNumberEstimate
        .multipliedBy(selectedGasPriceOptionEth)
        .multipliedBy(new BigNumber(usdPrice))
        .decimalPlaces(2);

      setNetworkFee(`${fee.toString()} $`);
    }
  }, [estimateGasResult?.data, selectedGasPriceOptionEth, usdPrice]);

  return {
    networkFee,
    transferEstimate,
  };
};
