import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createMigrate from "redux-persist/es/createMigrate";
import { PersistConfig } from "redux-persist/es/types";
import { reducer } from "@src/store/reducer";
import { tokensSlice } from "@src/store/slices/tokensSlice";
import { symbolSelectSlice } from "@src/components/symbol-select/symbol-select.slice";
import { uiSettingsSlice } from "@src/store/slices/uiSettingsSlice";
import { SymbolSelectTab } from "@src/components/symbol-select/types";
import { adminSlice } from "@src/features/admin/admin.slice";

// https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
const migrations = {
  15: (state) => {
    return {
      ...state,
      tokens: {
        ...state.tokens,
        tokensMap: {},
      },
    };
  },
  16: (state) => {
    return {
      ...state,
      symbolSelect: {
        ...state.symbolSelect,
        tokenLogos: {},
        tab: SymbolSelectTab.ALL,
      },
    };
  },
  17: (state) => {
    return {
      ...state,
      uiSettings: {
        ...state.uiSettings,
        isLayoutLocked: true,
      },
    };
  },
  // enable widgets by default
  18: (state) => {
    return {
      ...state,
      uiSettings: {
        ...state.uiSettings,
        widgetsEnabled: true,
      },
    };
  },
  19: (state) => {
    return {
      ...state,
      user: {
        ...state.user,
        mev_protection: true,
      },
    };
  },
  20: (state) => {
    return {
      ...state,
      user: {
        ...state.user,
        mev_protection: false,
      },
    };
  },
  22: (state) => {
    return {
      ...state,
      symbolSelect: {
        tab: { otc: SymbolSelectTab.ALL, cefi: SymbolSelectTab.ALL },
        recent: {
          otc: [],
          cefi: [],
        },
        selectedExchanges: {
          otc: {},
          cefi: {},
        },
        marketType: {
          otc: {},
          cefi: {},
        },
        tokenLogos: {},
        favourites: {
          otc: [],
          cefi: [],
        },
      },
    };
  },
};

const persistConfig: PersistConfig<any> = {
  key: "anboto-persist",
  storage,
  version: 22,
  whitelist: ["user", tokensSlice.name, uiSettingsSlice.name, symbolSelectSlice.name, adminSlice.name],
  migrate: createMigrate(migrations, { debug: true }),
  debug: true,
};

export const persistedReducer = persistReducer(persistConfig, reducer);
