import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ExpirationTime } from "@src/pages/cefi/order-form/fields/ExpirationTimeSelect";
import {
  OrderSymbol,
  OrderExecutionStrategy,
  OrderSide,
  OrderTradingDurationUnit,
  TriggerCondition,
} from "@src/store/apis/anbotoApi/types";
import formValidator from "@src/utils/formValidator";
import { useCefiOrderFormDefaultValues } from "@src/pages/cefi/order-form/hooks/use-cefi-order-form-default-values";
import { DEFAULT_OTC_SYMBOL } from "@src/features/widgets-layout/constants";

export type OtcOrderFormFieldValues = {
  symbol: null | OrderSymbol;
  account: string;
  quantity: string;
  toQuantity: string;
  side: OrderSide;
  strategy: OrderExecutionStrategy;
  tradingDuration?: string;
  tradingDurationUnit: OrderTradingDurationUnit;
  limitPrice: string;
  startTime: string;
  endTime: string;
  triggerCondition: TriggerCondition;
  triggerPrice: string;
  slippage: string;
  expirationTime: ExpirationTime;
};

export const OTC_ORDER_FORM_DEFAULT_VALUES: Partial<OtcOrderFormFieldValues> & {
  strategy: OrderExecutionStrategy;
} = {
  symbol: DEFAULT_OTC_SYMBOL,
  strategy: OrderExecutionStrategy.ORDER,
  side: OrderSide.BUY,
  account: "spot",
  quantity: "",
  tradingDurationUnit: OrderTradingDurationUnit.HOURS,
  tradingDuration: "",
  limitPrice: "",
  triggerCondition: TriggerCondition.ABOVE,
  triggerPrice: "",
  expirationTime: "6months",
  slippage: "0",
};

const schema = formValidator.object({
  account: formValidator.string().required(),
  strategy: formValidator.string().required(),
  side: formValidator.string().required(),
  tradingDuration: formValidator
    .number()
    .emptyable()
    .moreThan(0)
    .noLabel()
    .when("strategy", {
      is: (strategy: OrderExecutionStrategy) => strategy === OrderExecutionStrategy.TWAP,
      then: (x) => x.required(),
    }),
  slippage: formValidator
    .number()
    .emptyable()
    .min(0)
    .noLabel()
    .when("strategy", {
      is: (strategy: OrderExecutionStrategy) => strategy === OrderExecutionStrategy.FOK,
      then: (x) => x.required(),
    }),
  expirationTime: formValidator.number().emptyable().min(0).noLabel(),
  quantity: formValidator.number().emptyable().min(0).noLabel().required(),
  limitPrice: formValidator
    .number()
    .emptyable()
    .min(0)
    .noLabel()
    .when("strategy", {
      is: (strategy: OrderExecutionStrategy) => strategy === OrderExecutionStrategy.LIMIT,
      then: (x) => x.required(),
    }),
});

export const useOtcOrderForm = (values: Partial<OtcOrderFormFieldValues> = {}) => {
  const defaultValues = useCefiOrderFormDefaultValues(values);

  return useForm<OtcOrderFormFieldValues>({
    defaultValues: {
      ...defaultValues,
      ...values,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
};
