import React, { useMemo } from "react";
import { Skeleton, Stack } from "@mui/material";
import { ReactComponent as GreenLevelIcon } from "@src/components/price-impact/assets/volume-chart-green-status.svg";
import { ReactComponent as OrangeLevelIcon } from "@src/components/price-impact/assets/volume-chart-orange-status.svg";
import { ReactComponent as RedLevelIcon } from "@src/components/price-impact/assets/volume-chart-red-status.svg";
import { ReactComponent as GreenLevelIconActive } from "@src/components/price-impact/assets/volume-chart-green-status-active.svg";
import { ReactComponent as OrangeLevelIconActive } from "@src/components/price-impact/assets/volume-chart-orange-status-active.svg";
import { ReactComponent as RedLevelIconActive } from "@src/components/price-impact/assets/volume-chart-red-status-active.svg";
import { STATUS_LABEL } from "@src/components/price-impact/constant";

const ICON_HEIGHT = 50;
const ACTIVE_ICON_WIDTH = 100;

type RiskIconProps = {
  riskLevel: string;
  isDefault: boolean;
  isFetching: boolean;
};

export const RiskLevelIndicator = ({ riskLevel, isDefault, isFetching }: RiskIconProps) => {
  const iconsInOrder = useMemo(
    () => [
      riskLevel === STATUS_LABEL.orange ? (
        <OrangeLevelIconActive key={STATUS_LABEL.orange} height={ICON_HEIGHT} width={ACTIVE_ICON_WIDTH} />
      ) : (
        <OrangeLevelIcon key={STATUS_LABEL.orange} height={ICON_HEIGHT} />
      ),
      riskLevel === STATUS_LABEL.green ? (
        <GreenLevelIconActive key={STATUS_LABEL.green} height={ICON_HEIGHT} width={ACTIVE_ICON_WIDTH} />
      ) : (
        <GreenLevelIcon key={STATUS_LABEL.green} height={ICON_HEIGHT} />
      ),
      riskLevel === STATUS_LABEL.red ? (
        <RedLevelIconActive key={STATUS_LABEL.red} height={ICON_HEIGHT} width={ACTIVE_ICON_WIDTH} />
      ) : (
        <RedLevelIcon key={STATUS_LABEL.red} height={ICON_HEIGHT} />
      ),
    ],
    [riskLevel, isDefault]
  );

  const mainIndex = isDefault ? 1 : iconsInOrder.findIndex((icon) => icon.key === riskLevel);
  const secondaryIndexes = [0, 1, 2].filter((value) => value !== mainIndex);

  return (
    <Stack width="100%" pb={0} overflow="hidden">
      <Stack minWidth={150} height={70} pt={1.5} alignItems="center" justifyContent="center">
        {isFetching ? (
          <Stack direction="row" gap={0.1} alignItems="center" flex={1} overflow="hidden">
            <Skeleton width={50} height={ICON_HEIGHT} />
            <Skeleton width={ACTIVE_ICON_WIDTH} height={75} />
            <Skeleton width={50} height={ICON_HEIGHT} />
          </Stack>
        ) : (
          <Stack direction="row" gap={1}>
            {iconsInOrder[secondaryIndexes[0]]}
            {iconsInOrder[mainIndex === -1 ? secondaryIndexes[2] : mainIndex]}
            {iconsInOrder[secondaryIndexes[1]]}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
