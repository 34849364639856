import { useEffect } from "react";
import BigNumber from "bignumber.js";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import { MAX_GAS_PRICE_MULTIPLY_RATIO } from "@src/pages/defi/constants";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export const useMaxGasPrice = (): void => {
  const orderForm = useDefiOrderFormContext();
  const chainId = orderForm.watch("chainId");
  const gasPriceOption = orderForm.watch("gasPriceOption");
  const formMaxGasPrice = orderForm.watch("maxGasPrice");
  const { data: gasOracleData } = anbotoApi.useGetGasOracleQuery({ chainId });

  const gasPrice = gasOracleData && gasPriceOption ? gasOracleData.data[gasPriceOption] : "0";
  const maxGasPriceInGwei = new BigNumber(gasPrice).multipliedBy(MAX_GAS_PRICE_MULTIPLY_RATIO);
  const maxGasPrice = new BigNumber(maxGasPriceInGwei).toFixed();
  const isMaxGasDirty = orderForm.formState.dirtyFields.maxGasPrice;
  const isMaxGasTouched = orderForm.formState.touchedFields.maxGasPrice;

  // This hook is only for the case we trying to clear max gas price manually for the first time.
  // According the react-hook-form documentation, maxGasPrice becomes touched only after we leave this field (onBlur)
  // so for the time until we left this field we should determinate it as touched
  // if we clear the field without leaving it,
  // isMaxGasDirty will be changed from "true" to undefined and isMaxGasTouched remains undefined
  // so to prevent this we are manually changing isMaxGasTouched if isMaxGasDirty
  useEffect(() => {
    if (isMaxGasDirty) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      orderForm.formState.touchedFields.maxGasPrice = true;
    }
  }, [isMaxGasDirty]);

  // we need to change maxGasPrice field value if network value was changed
  // and we skip setting it if user touched the field manually
  useEffect(() => {
    if (formMaxGasPrice === maxGasPrice || isMaxGasDirty || isMaxGasTouched) {
      return;
    }

    orderForm.setValue("maxGasPrice", maxGasPrice ?? "");
  }, [formMaxGasPrice, maxGasPrice, isMaxGasDirty, isMaxGasTouched]);

  // reset maxGasPrice field state after network change
  // form reset/submit/copy from history - will also reset this field state automatically
  useEffect(() => {
    orderForm.resetField("maxGasPrice");
  }, [chainId]);
};
