// https://github.com/tradingview/charting-library-tutorial/blob/master/documentation/getting-started.md
import { prepareTimeframe, prepareExchange, isLatestBarDate, getResolutions } from "./helpers";
import SubscriptionManager from "./subscription";
import {
  DatafeedConfiguration,
  HistoryCallback,
  ErrorCallback,
  IDatafeedChartApi,
  IExternalDatafeed,
  LibrarySymbolInfo,
  OnReadyCallback,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
  SubscribeBarsCallback,
} from "@src/charting_library";
import { CefiExchangeMarketType, GetMarketDataParams, MarketDataItem } from "@src/store/apis/anbotoApi/types";
import { store } from "@src/store/store";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";
import { getLimit } from "@src/pages/cefi/utils";

const lastBarsCache = new Map();
const getLastBarsCacheKey = (ticker: string, resolution: string) => `${ticker}_${resolution}`;

export class AnbotoDataFeed implements IExternalDatafeed, IDatafeedChartApi {
  constructor(configuration: DatafeedConfiguration & { resetData: () => void }) {
    this.configuration = configuration;
    this.ws = new SubscriptionManager({ resetData: configuration.resetData });
  }

  configuration: DatafeedConfiguration = {};
  ws: SubscriptionManager;

  onReady = (callback: OnReadyCallback) => {
    setTimeout(() => callback(this.configuration));
  };

  searchSymbols = async () =>
    // userInput,
    // exchange,
    // symbolType,
    // onResultReadyCallback,
    {
      // console.log('[searchSymbols]: Method call', userInput, exchange, symbolType);
    };

  resolveSymbol = async (
    symbolName: string,
    onSymbolResolvedCallback: ResolveCallback
    // onResolveErrorCallback,
  ) => {
    const [symbol, exchange] = symbolName.split("~");
    const symbolInfo: LibrarySymbolInfo = {
      full_name: `${exchange}:${symbol}`,
      name: symbol,
      description: `${getExchangeNameLabel(exchange)}:${symbol}`,
      exchange: "",
      ticker: symbolName,
      listed_exchange: exchange,
      supported_resolutions: getResolutions(prepareExchange(exchange), this.configuration?.supported_resolutions || []),
      format: "price",
      minmov: 1,
      pricescale: 100,
      session: "24x7",
      timezone: "Etc/UTC",
      type: "crypto",
      has_intraday: true,
      has_weekly_and_monthly: true,
      volume_precision: 2,
      data_status: "streaming",
    };
    setTimeout(() => onSymbolResolvedCallback(symbolInfo));
  };

  getMarketData = async (params: GetMarketDataParams) => {
    return store.dispatch(anbotoApi.endpoints.getMarketData.initiate(params));
  };

  loadBars = async (params: GetMarketDataParams) => {
    const { data } = await this.getMarketData(params);
    const items: MarketDataItem[] = data?.data ? [...data.data] : [];
    const lastBar = items[items.length - 1];
    const lastDate = lastBar ? lastBar[0] : +new Date();

    return {
      lastDate,
      items,
    };
  };

  getBars = async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onResult: HistoryCallback,
    onError: ErrorCallback
  ) => {
    const { ticker } = symbolInfo;
    const [symbol, exchange, marketType] = ticker?.split("~") || "";
    const { countBack, from, to, firstDataRequest } = periodParams;

    if (from < 0) return onResult([], { noData: true });

    if (!symbol || !exchange || !marketType) return onResult([], { noData: true });

    const getBarsRequestParams = (from: number, limit?: number): GetMarketDataParams => ({
      timeframe: prepareTimeframe(resolution),
      limit,
      since: from,
      symbol: symbol,
      exchange_id: prepareExchange(exchange),
      market_type: marketType as CefiExchangeMarketType,
    });

    try {
      const loadedBars = await this.loadBars(
        getBarsRequestParams(from * 1000, getLimit(symbolInfo?.listed_exchange, countBack))
      );
      let lastDate = loadedBars.lastDate;
      const marketDataItems = loadedBars.items;
      const requestsLimit = 10;

      // apis don't return full data we need, have to request more
      if (marketDataItems.length && firstDataRequest) {
        let requestsCount = 0;

        while (!isLatestBarDate(new Date(lastDate), resolution) && requestsCount < requestsLimit) {
          const extraBars = await this.loadBars(getBarsRequestParams(lastDate));

          if (!extraBars.items.length) break;

          lastDate = extraBars.lastDate;

          marketDataItems.push(...extraBars.items);
          requestsCount++;
        }
      } else if (marketDataItems.length && marketDataItems.length < countBack) {
        let requestsCount = 0;
        let restCountBack = countBack - marketDataItems.length;

        while (restCountBack > 0 && requestsCount < requestsLimit) {
          const extraBars = await this.loadBars(
            getBarsRequestParams(lastDate, getLimit(symbolInfo?.listed_exchange, restCountBack))
          );

          if (!extraBars.items.length) break;

          lastDate = extraBars.lastDate;

          marketDataItems.push(...extraBars.items);

          requestsCount++;
          restCountBack = restCountBack - extraBars.items.length;
        }
      }

      const bars = marketDataItems
        .filter(([time]) => time <= to * 1000)
        .map(([time, open, high, low, close, volume]) => ({ time, open, high, low, close, volume }));

      if (bars?.length) {
        if (firstDataRequest) {
          lastBarsCache.set(getLastBarsCacheKey(symbolInfo.ticker || "", resolution), bars[bars.length - 1]);
        }

        onResult(bars, { noData: false });
      } else onResult([], { noData: true });
    } catch (err) {
      console.log(err.message);
      onError(err.message);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  subscribeBars = (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerId: string,
    onResetCacheNeededCallback: () => void
  ) => {
    const lastBar = lastBarsCache.get(getLastBarsCacheKey(symbolInfo?.ticker || "", resolution));

    if (symbolInfo?.ticker) {
      const [symbol, exchange, market_type] = symbolInfo.ticker.split("~") || [];
      const useTrades = ["coinbase"].includes(exchange.toLowerCase());

      this.ws.subscribe(
        symbol,
        prepareExchange(exchange),
        market_type as CefiExchangeMarketType,
        resolution,
        onTick,
        listenerId,
        useTrades,
        lastBar,
        onResetCacheNeededCallback
      );
    }
  };

  unsubscribeBars = (listenerId: string) => {
    this.ws.unsubscribe(listenerId);
  };
}
