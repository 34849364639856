import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { useParentOrderFormContext } from "../../hooks/use-parent-order-form-context";
import { WouldStyleSelect } from "../../fields/WouldStyleSelect";
import { WouldPriceField } from "../../fields/WouldPriceField";
import { WouldPctFieldField } from "../../fields/WouldPctField";
import { PlacementModeSelect } from "../../fields/PlacementModeSelect";
import { PlacementCancelField } from "../../fields/PlacementCancelField";
import { PlacementField } from "../../fields/PlacementField";
import { LimitPriceField } from "../../fields/limit-price-field";
import { ClipSizeTypeSelect } from "../../fields/ClipSizeTypeSelect";
import { ClipSizeValueField } from "../../fields/ClipSizeValueField";
import { useHideFields } from "../../hooks/use-hide-fields";
import { ExpirationTimeSelect } from "../../fields/ExpirationTimeSelect";
import { useExchangeSymbolErrors } from "../../hooks/use-exchange-symbol-errors";
import { CEFI_ORDER_FORM_DEFAULT_VALUES, ParentOrderFormFieldValues } from "../../hooks/use-parent-order-form";
import { PovRiskSelect } from "../../fields/PovRiskSelect";
import { ControllsPanel } from "./controlls-panel";
import { AdvancedOptionsDialogProps } from "./types";
import { TabLabel, TabValue } from "./constants";
import { AdvancedOptionsRoot } from "./advanced-options-root";
import { TriggerConditionField, TriggerPriceField } from "@src/components";
import { useTabs } from "@src/hooks/useTabs";
import {
  ClipSizeType,
  OrderExecutionStrategy,
  OrderSide,
  PlacementMode,
  TradingStyle,
  TriggerCondition,
} from "@src/store/apis/anbotoApi/types";
import { usePlacementModeValue } from "@src/pages/settings/order-preferences/hooks/use-placement-mode-value";
import { setIsOrderCopy } from "@src/store/slices/singleExchangePageSlice";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { withFlatStyle } from "@src/components/with-flat-style";

const getDefaultTab = (strategy: OrderExecutionStrategy) => {
  if (strategy === OrderExecutionStrategy.POV) return TabValue.Pov;
  if (strategy === OrderExecutionStrategy.LIMIT) return TabValue.Expiration;

  return TabValue.WouldStyle;
};

const inputStyles = {
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

export const ParentOrderAdvancedOptionsDialog = ({ draggable, hiddenFields, ...props }: AdvancedOptionsDialogProps) => {
  const orderForm = useParentOrderFormContext();
  const symbol = orderForm.watch("symbol");
  const strategy = orderForm.watch("strategy");
  const tradingStyle = orderForm.watch("tradingStyle");
  const tabs = useTabs(getDefaultTab(strategy));
  const exchangeSymbolErrors = useExchangeSymbolErrors(symbol);
  const clipSizeType = orderForm.watch("clipSizeType");
  const placementMode = orderForm.watch("placementMode");
  const wouldPrice = orderForm.watch("wouldPrice");
  const wouldPct = orderForm.watch("wouldPct");
  const isCustomPlacement = placementMode === PlacementMode.CUSTOM;
  const hideFields = useHideFields(orderForm);
  const isLimitStrategy = strategy === OrderExecutionStrategy.LIMIT;
  const hideMakerPlacement =
    (hideFields.placementMode && hideFields.placement && hideFields.placementCancel) || isLimitStrategy;
  const hideWouldPrice = isLimitStrategy;
  const hideLimitPrice = isLimitStrategy;
  const hideTriggerPrice = isLimitStrategy;
  const changePlacementMode = usePlacementModeValue();
  const hideClipsize = strategy === OrderExecutionStrategy.ORDER || strategy === OrderExecutionStrategy.LIMIT;

  const isOrderCopy = useAppSelector((state) => state.singleExchangePage.isOrderCopy);
  const dispatch = useAppDispatch();

  const onClose = () => props.onClose!({}, "backdropClick");

  const isVisible = (field: keyof ParentOrderFormFieldValues) => {
    return !(hiddenFields || []).includes(field);
  };

  useEffect(() => {
    if (isOrderCopy) {
      dispatch(setIsOrderCopy(false));
    } else {
      orderForm.setValue("clipSizeValue", "");
    }
  }, [clipSizeType]);

  useEffect(() => {
    if (strategy === OrderExecutionStrategy.ORDER) {
      orderForm.setValue("wouldPrice", "");
      orderForm.setValue("wouldPct", "");
      orderForm.clearErrors(["wouldPrice", "wouldPct"]);

      orderForm.setValue("clipSizeType", CEFI_ORDER_FORM_DEFAULT_VALUES.clipSizeType!);
      orderForm.setValue("clipSizeValue", "");
    }
  }, [strategy]);

  useEffect(() => {
    const side = orderForm.getValues("side");
    const trigger = side === OrderSide.SELL ? TriggerCondition.ABOVE : TriggerCondition.BELOW;
    orderForm.setValue("triggerCondition", trigger);
  }, []);

  useEffect(() => {
    if (wouldPrice && !wouldPct) {
      orderForm.setValue("wouldPct", "", { shouldValidate: true });
    }
    if (wouldPct && !wouldPrice) {
      orderForm.setValue("wouldPrice", "", { shouldValidate: true });
    }
    if (wouldPrice && (Number(wouldPct) > 100 || Number(wouldPct) <= 0)) {
      orderForm.setError("wouldPct", {
        type: "wouldPct",
        message: "wouldPct must be greater than 0 and less than 100%",
      });
    } else if ((wouldPrice && wouldPct) || (!wouldPrice && !wouldPct)) {
      orderForm.clearErrors(["wouldPrice", "wouldPct"]);
    }
  }, [wouldPrice, wouldPct]);

  useEffect(() => {
    if (
      (strategy === OrderExecutionStrategy.ICEBERG && tradingStyle === TradingStyle.HYBRID) ||
      strategy === OrderExecutionStrategy.LIMIT
    ) {
      const d = getDefaultTab(strategy);

      tabs.setValue(d);
    }
  }, [tradingStyle, strategy]);

  return (
    <AdvancedOptionsRoot
      strategy={strategy}
      hideWouldPrice={hideWouldPrice}
      hideLimitPrice={hideLimitPrice}
      hideTriggerPrice={hideTriggerPrice}
      hideMakerPlacement={hideMakerPlacement}
      hideClipsize={hideClipsize}
      exchangeSymbolErrors={exchangeSymbolErrors}
      currentTab={tabs.value}
      onTabChange={(x) => tabs.setValue(x)}
      onClose={onClose}
      draggable={draggable}
      {...props}
    >
      {isVisible("wouldStyle") && (
        <ControllsPanel isTab={!draggable} value={TabValue.WouldStyle} sx={{ px: 0.5 }} title={TabLabel.WouldPrice}>
          {strategy !== OrderExecutionStrategy.ORDER && (
            <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    control={orderForm.control}
                    name="wouldStyle"
                    render={({ field, formState }) => (
                      <WouldStyleSelect
                        {...field}
                        error={!!formState.errors.wouldStyle}
                        helperText={formState.errors.wouldStyle?.message}
                        labelStyle="dynamic"
                        sx={inputStyles}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={orderForm.control}
                    name="wouldPrice"
                    render={({ field, formState }) => {
                      const wouldPriceError = exchangeSymbolErrors.errors.wouldPrice;
                      return (
                        <WouldPriceField
                          {...field}
                          sx={inputStyles}
                          error={!!formState.errors.wouldPrice || !!wouldPriceError}
                          helperText={formState.errors.wouldPrice?.message || wouldPriceError}
                          labelStyle="dynamic"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={orderForm.control}
                    name="wouldPct"
                    render={({ field, formState }) => (
                      <WouldPctFieldField
                        {...field}
                        sx={inputStyles}
                        error={!!formState.errors.wouldPct}
                        helperText={formState.errors.wouldPct?.message}
                        labelStyle="dynamic"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </ControllsPanel>
      )}
      {strategy !== OrderExecutionStrategy.LIMIT && isVisible("limitPrice") && (
        <ControllsPanel title={TabLabel.LimitPrice} isTab={!draggable} value={TabValue.LimitPrice} sx={{ px: 0.5 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                control={orderForm.control}
                name="limitPrice"
                render={({ field, formState }) => {
                  const limitPriceError = exchangeSymbolErrors.errors.limitPrice;
                  return (
                    <LimitPriceField
                      {...field}
                      sx={inputStyles}
                      error={!!formState.errors.limitPrice || !!limitPriceError}
                      helperText={formState.errors.limitPrice?.message || limitPriceError}
                      labelStyle="dynamic"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </ControllsPanel>
      )}
      {isVisible("triggerPrice") && (
        <ControllsPanel title={TabLabel.TriggerPrice} isTab={!draggable} value={TabValue.TriggerPrice} sx={{ px: 0.5 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                control={orderForm.control}
                name="triggerCondition"
                render={({ field }) => (
                  <TriggerConditionField
                    {...field}
                    sx={inputStyles}
                    select
                    labelStyle="dynamic"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Trigger condition"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={orderForm.control}
                name="triggerPrice"
                render={({ field }) => {
                  const triggerPriceError = exchangeSymbolErrors.errors.triggerPrice;
                  return (
                    <TriggerPriceField
                      {...field}
                      sx={inputStyles}
                      error={!!triggerPriceError}
                      helperText={triggerPriceError}
                      labelStyle="dynamic"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </ControllsPanel>
      )}

      {isVisible("placementMode") && (
        <ControllsPanel
          title={TabLabel.MakerPlacement}
          isTab={!draggable}
          value={TabValue.MakerPlacement}
          sx={{ px: 0.5 }}
        >
          <Grid container spacing={2}>
            {!hideMakerPlacement && (
              <>
                <Grid item xs={6}>
                  <Controller
                    control={orderForm.control}
                    name="placementMode"
                    render={({ field }) => (
                      <PlacementModeSelect
                        {...field}
                        sx={inputStyles}
                        labelStyle="dynamic"
                        onChange={(e) => changePlacementMode(e.target.value as PlacementMode)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={orderForm.control}
                    name="placement"
                    render={({ field, formState }) => (
                      <PlacementField
                        disabled={!isCustomPlacement}
                        {...field}
                        sx={inputStyles}
                        error={!!formState.errors.placement}
                        helperText={formState.errors.placement?.message}
                        labelStyle="dynamic"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={orderForm.control}
                    name="placementCancel"
                    render={({ field, formState }) => (
                      <PlacementCancelField
                        disabled={!isCustomPlacement}
                        {...field}
                        sx={inputStyles}
                        error={!!formState.errors.placementCancel}
                        helperText={formState.errors.placementCancel?.message}
                        labelStyle="dynamic"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </ControllsPanel>
      )}

      {!hideClipsize && isVisible("clipSizeType") && (
        <ControllsPanel title={TabLabel.ClipSize} isTab={!draggable} value={TabValue.ClipSize} sx={{ px: 0.5 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="clipSizeType"
                control={orderForm.control}
                render={({ field, formState }) => (
                  <StyledClipSizeTypeSelect
                    {...field}
                    error={!!formState.errors.clipSizeType}
                    helperText={formState.errors.clipSizeType?.message}
                    labelStyle="dynamic"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            {clipSizeType !== ClipSizeType.AUTOMATIC && (
              <Grid item xs={6}>
                <Controller
                  name="clipSizeValue"
                  control={orderForm.control}
                  render={({ field, formState }) => {
                    const clipSizeError = exchangeSymbolErrors.errors.clipSize;

                    return (
                      <StyledClipSizeValueField
                        {...field}
                        error={!!formState.errors.clipSizeValue || !!clipSizeError}
                        helperText={formState.errors.clipSizeValue?.message || clipSizeError}
                        labelStyle="dynamic"
                        label={
                          clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS ? "Number of childs" : "Clip size value"
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    );
                  }}
                />
              </Grid>
            )}
          </Grid>
        </ControllsPanel>
      )}
      {isVisible("expirationTime") && (
        <ControllsPanel title={TabLabel.Expiration} isTab={!draggable} value={TabValue.Expiration} sx={{ px: 0.5 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="expirationTime"
                control={orderForm.control}
                render={({ field, formState }) => (
                  <ExpirationTimeSelect
                    {...field}
                    sx={inputStyles}
                    error={!!formState.errors.clipSizeType}
                    helperText={formState.errors.clipSizeType?.message}
                    labelStyle="dynamic"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </ControllsPanel>
      )}
      {strategy === OrderExecutionStrategy.POV && isVisible("povRisk") && (
        <ControllsPanel title={TabLabel.Pov} isTab={!draggable} value={TabValue.Pov} sx={{ px: 0.5 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="povRisk"
                control={orderForm.control}
                render={({ field, formState }) => (
                  <StyledPovRiskSelect
                    {...field}
                    error={!!formState.errors.povRisk}
                    helperText={formState.errors.povRisk?.message}
                    labelStyle="dynamic"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </ControllsPanel>
      )}
    </AdvancedOptionsRoot>
  );
};

const StyledClipSizeValueField = withFlatStyle(ClipSizeValueField);
const StyledPovRiskSelect = withFlatStyle(PovRiskSelect);
const StyledClipSizeTypeSelect = withFlatStyle(ClipSizeTypeSelect);
