import React from "react";
import { Stack, Typography } from "@mui/material";
import { CloudOff } from "@mui/icons-material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

type ErrorStateProps = {
  onTryAgainClick?: () => void;
  message?: string;
};

export const ErrorState = ({ onTryAgainClick, message }: ErrorStateProps) => {
  return (
    <Stack alignItems="center">
      <CloudOff sx={{ color: "#4A5053" }} />
      <Typography variant="body2" sx={{ color: "#8A9296", mt: 1.5, fontSize: 12 }}>
        {message || "Oops, something went wrong, please try again"}
      </Typography>
      {onTryAgainClick && (
        <AnbotoButton
          size="small"
          variant="outlined"
          onClick={onTryAgainClick}
          sx={{ width: 160, mt: 1, background: "#232C2F" }}
        >
          Refresh
        </AnbotoButton>
      )}
    </Stack>
  );
};
