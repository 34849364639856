import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTheme } from "@mui/material";
import { toggleWatchlist } from "@src/store/slices/uiSettingsSlice";
import { RootState } from "@src/store/types";
import { MenuItemSwitch } from "@src/components/menu-item-switch";

export const ToggleWatchlistTopBar = () => {
  const dispatch = useDispatch();
  const { watchlist } = useSelector((state: RootState) => state.uiSettings);
  const theme = useTheme();

  return (
    <MenuItemSwitch
      sx={{ backgroundColor: theme.custom.background.darkGrey, padding: theme.spacing(1.5, 2.25) }}
      checked={watchlist}
      label="Tickers bar"
      onClick={() => dispatch(toggleWatchlist())}
    />
  );
};
