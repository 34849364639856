import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ChainId } from "@src/pages/defi/types";
import { ComplexProtocol, UserToken } from "@src/store/apis/debankApi/types";
import { SUPPORTED_CHAINS } from "@src/store/apis/debankApi/constants";

export const debankApi = createApi({
  reducerPath: "debankApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://openapi.debank.com/v1" }),
  endpoints: (builder) => ({
    // https://docs.open.debank.com/en/reference/api-pro-reference/user#get-user-token-list
    getUserTokens: builder.query<UserToken[], { account: string; chainId: number }>({
      query: ({ account, chainId }) => ({
        url: `/user/token_list`,
        params: {
          is_all: false,
          has_balance: true,
          chain_id: SUPPORTED_CHAINS.find((x) => x.community_id === chainId)!.id,
          id: account,
        },
      }),
    }),
    getUserComplexProtocols: builder.query<ComplexProtocol[], { account: string; chainId: ChainId }>({
      query: ({ account, chainId }) => ({
        url: `/user/complex_protocol_list`,
        params: {
          chain_id: SUPPORTED_CHAINS.find((x) => x.community_id === chainId)!.id,
          id: account,
        },
      }),
    }),
    getUserChainBalance: builder.query<{ usd_value: number }, { account: string; chainId: number }>({
      query: ({ chainId, account }) => ({
        url: "/user/chain_balance",
        params: {
          id: account,
          chain_id: SUPPORTED_CHAINS.find((x) => x.community_id === chainId)!.id,
        },
      }),
    }),
    getUserTotalBalance: builder.query<{ total_usd_value: number }, { account: string }>({
      query: ({ account }) => ({
        url: "/user/total_balance",
        params: {
          id: account,
        },
      }),
    }),
  }),
});
