import React from "react";
import { TableActionButton } from "@src/components";
import { useDialog } from "@src/hooks/useDialog";
import PostTradeAnalysisDialog from "@src/components/post-trade-analysis/post-trade-analysis-dialog";
import { ParentOrder } from "@src/store/apis/anbotoApi/types";

type PostTradeAnalysisProps = {
  order: ParentOrder;
};

const PostTradeAnalysisButton = ({ order }: PostTradeAnalysisProps) => {
  const dialog = useDialog();

  return (
    <>
      <TableActionButton type="analysis" tooltip="Post trade analysis" onClick={() => dialog.show()} />
      <PostTradeAnalysisDialog open={dialog.isOpen} hide={dialog.hide} order={order} />
    </>
  );
};

export default PostTradeAnalysisButton;
