import { UseFormReturn } from "react-hook-form";
import { ParentOrderFormFieldValues } from "./use-parent-order-form";
import { OrderExecutionStrategy, TradingStyle, WouldStyle } from "@src/store/apis/anbotoApi/types";

export const useHideFields = (orderForm: UseFormReturn<ParentOrderFormFieldValues, any>) => {
  const tradingStyle = orderForm.watch("tradingStyle");
  const wouldPrice = orderForm.watch("wouldPrice");
  const wouldStyle = orderForm.watch("wouldStyle");
  const strategy = orderForm.watch("strategy");

  const hideMakerPlacement =
    (tradingStyle === TradingStyle.AGGRESSIVE && wouldStyle === WouldStyle.AGGRESSIVE && wouldPrice) ||
    (strategy === OrderExecutionStrategy.ICEBERG && tradingStyle === TradingStyle.HYBRID);

  return {
    placementMode: !!hideMakerPlacement,
    placement: !!hideMakerPlacement,
    placementCancel: !!hideMakerPlacement,
    tradingDuration:
      strategy !== OrderExecutionStrategy.TWAP &&
      strategy !== OrderExecutionStrategy.VWAP &&
      strategy !== OrderExecutionStrategy.IS,
    povRatio: strategy !== OrderExecutionStrategy.POV,
    tradingStyle: [OrderExecutionStrategy.ORDER, OrderExecutionStrategy.LIMIT].includes(strategy),
    urgency: strategy !== OrderExecutionStrategy.IS,
  };
};
