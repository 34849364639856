import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PortfolioSidebar from "./portfolio-sidebar";
import LinkExchangeButton from "./components/link-exchange-button";
import { RootState } from "@src/store/types";
import { useAddPortfolioExchangeMutation, useCefiTokenTable, useUserExchangesList } from "@src/pages/portfolio";
import { PortfolioType, SELECT_ALL, setSelectedExchange } from "@src/store/slices/portfolioSlice";
import { useAppDispatch } from "@src/store/hooks";
import { PortfolioItemType } from "@src/store/apis/anbotoApi/types";

export const PortfolioSidebarCEFI = () => {
  const { data, isError, isLoading, isFetching: isFetchingExchanges, refetch } = useUserExchangesList();
  const [addPortfolio] = useAddPortfolioExchangeMutation();
  const dispatch = useAppDispatch();
  const selectedExchange = useSelector((state: RootState) => state.portfolio.selectedExchange);
  const overviewTotalBalance = useSelector((state: RootState) => state.portfolio.overviewCefiTotalBalance);

  const addedList = data?.selected || [];
  const portfolioList: PortfolioItemType[] = [...(data?.available || [])].map((item) => ({
    item,
    isAdded: addedList.includes(item),
  }));

  const { isFetching } = useCefiTokenTable();

  useEffect(() => {
    setSelectedExchangeToStore(SELECT_ALL);
  }, [addedList]);

  const setSelectedExchangeToStore = (selectedExchange: string) => {
    dispatch(setSelectedExchange(selectedExchange));
  };

  const updateAddedExchangesList = (exchange: string) => {
    const selected = addedList?.includes(exchange)
      ? [...addedList.filter((item) => item !== exchange)]
      : [...addedList, exchange];

    addPortfolio({ portfolio_exchanges: [...selected] });
  };

  const tableOrExchangesLoading = isFetching || isFetchingExchanges;
  const recalculating = selectedExchange === SELECT_ALL && tableOrExchangesLoading;

  return (
    <PortfolioSidebar
      portfolioList={portfolioList}
      linkButton={<LinkExchangeButton />}
      portfolioType={PortfolioType.CEFI}
      selectedItem={selectedExchange}
      onSelect={setSelectedExchangeToStore}
      isError={isError}
      isLoading={isLoading}
      refetch={refetch}
      updateAddedItemList={updateAddedExchangesList}
      overviewTotalBalance={overviewTotalBalance}
      isFetching={recalculating}
    />
  );
};
