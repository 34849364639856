import React from "react";
import { Stack, StackProps, styled } from "@mui/material";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import { useNavigate } from "react-router-dom";
import { AnbotoButton } from "../ui/AnbotoButton/AnbotoButton";
import { useTestnetEnabled } from "@src/hooks/use-testnet-enabled";

export const BottomBar = ({ children, ...props }: React.PropsWithChildren<StackProps>) => {
  const navigate = useNavigate();
  const isTestnet = useTestnetEnabled();

  return (
    <Root direction="row" alignItems="center" justifyContent="space-between" {...props}>
      <Stack flexShrink={1} width={0} flexGrow={1}>
        {children}
      </Stack>
      {!isTestnet && (
        <Stack direction="row" flexWrap="nowrap" flexShrink={0} px={1} alignItems="center">
          <Sep />
          <IconButton variant="text" onClick={() => navigate(`/trading-competitions`)}>
            Trading competitions <StackedBarChartIcon sx={{ color: "#179B83", height: 16 }} />
          </IconButton>
        </Stack>
      )}
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  position: "fixed",
  left: 0,
  bottom: 0,
  background: theme.custom.background.secondary,
  width: "100%",
  height: 24,
  zIndex: 9,
}));

const IconButton = styled(AnbotoButton)(({ theme }) => ({
  height: 24,
  fontSize: 12,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  textDecoration: "none !important",
  color: theme.palette.text.primary,
  "&:hover": {
    background: "none",
  },
}));

const Sep = styled("div")({
  width: 1,
  height: 10,
  background: "#192022",
});
