import { Box, styled } from "@mui/material";
import { styledOptions } from "@src/utils/styled-options";
import { CELL_FONT_SIZE } from "@src/components/orders-table/orders-table";

export const TableFooterCell = styled(
  Box,
  styledOptions
)<{ expandable?: boolean }>(({ theme, expandable }) => ({
  color: theme.custom.text.dim,
  fontWeight: 400,
  borderTop: `1px solid ${theme.custom.border.default}`,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(0.25),
  paddingRight: theme.spacing(0.25),
  minHeight: 32,
  fontFamily: theme.typography.fontFamily,
  fontSize: CELL_FONT_SIZE - 1,
  "&:first-of-type": {
    paddingLeft: expandable ? undefined : theme.spacing(1),
  },
}));
