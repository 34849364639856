import React from "react";
import { Stack, styled } from "@mui/material";
import format from "date-fns/format";
import { ParentOrder } from "@src/store/apis/anbotoApi/types";
import {
  FieldLabel,
  FieldValue,
} from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/styled-components";
import { END_TIME_FORMAT } from "@src/pages/cefi/constants";

export const ExpandedRow = ({ data }: { data: ParentOrder }) => {
  const { start_time, end_time, trader_email } = data;

  return (
    <Root>
      {trader_email && (
        <FieldLabel>
          Trader: <FieldValue variant="caption">{trader_email}</FieldValue>
        </FieldLabel>
      )}
      {start_time && (
        <FieldLabel>
          Start time: <FieldValue variant="caption">{format(new Date(start_time), END_TIME_FORMAT)}</FieldValue>
        </FieldLabel>
      )}
      {end_time && (
        <FieldLabel>
          End time: <FieldValue variant="caption">{format(new Date(end_time), END_TIME_FORMAT)}</FieldValue>
        </FieldLabel>
      )}
    </Root>
  );
};

const Root = styled(Stack)(() => ({
  background: "#232C2F",
  borderRadius: "4px",
  flexDirection: "row",
  flexWrap: "wrap",
  padding: 8,
  gap: 16,
}));
