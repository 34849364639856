import React from "react";
import { Box, Dialog, DialogTitle, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PostTradeAnalysis from "@src/components/post-trade-analysis/post-trade-analysis/post-trade-analysis";
import { ParentOrder } from "@src/store/apis/anbotoApi/types";

type PostTradeAnalysisDialogProps = {
  open: boolean;
  hide: () => void;
  order: ParentOrder;
};

const PostTradeAnalysisDialog = ({ open, hide, order }: PostTradeAnalysisDialogProps) => {
  return (
    <React.Fragment>
      <Dialog scroll="body" fullWidth={false} maxWidth="lg" open={open} onClose={hide}>
        <Stack width="1000px">
          <DialogTitle>
            Post trade analysis{" "}
            <IconButton
              aria-label="close"
              onClick={hide}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Stack>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <PostTradeAnalysis order={order} hide={hide} />
            </Box>
          </Stack>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default PostTradeAnalysisDialog;
