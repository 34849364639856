import React from "react";
import { Paper, Stack, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { PortfolioSidebarDEFI } from "@src/pages/portfolio";
import { BalanceHeader } from "@src/pages/portfolio/components/balance-header";
import { useDefiTokenTable } from "@src/pages/portfolio/hooks/use-defi-token-table";
import { RootState } from "@src/store/types";

export const PortfolioDefiExchanges = () => {
  const { isFetching } = useDefiTokenTable();
  const selectedWallet = useSelector((state: RootState) => state.portfolio.selectedWallet);

  return (
    <Stack direction="row" flexGrow={1}>
      <SidebarBox>
        <PortfolioSidebarDEFI />
      </SidebarBox>
      <Paper sx={{ flexGrow: 1, position: "relative", gap: 0 }}>
        <BalanceHeader selectedItem={selectedWallet} isFetching={isFetching} />
        <Stack display="flex" flexDirection="column" sx={{ padding: "30px" }}>
          <Outlet />
        </Stack>
      </Paper>
    </Stack>
  );
};

const SidebarBox = styled(Stack)(({ theme }) => ({
  marginRight: 4,
  [theme.breakpoints.down("xl")]: {
    minWidth: 300,
    width: 300,
  },
  [theme.breakpoints.up("xl")]: {
    minWidth: 353,
    width: 353,
  },
}));
