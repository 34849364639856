import { TransactionResponse } from "@ethersproject/providers";
import { ethers } from "ethers";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { queryFnFactory } from "@src/store/apis/utils";
import WRAPPED_ABI from "@src/assets/ABIs/wrapped_native_abi.json";
import { ChainId } from "@src/pages/defi/types";
import { MIN_GAS_UNITS_PER_SLICE } from "@src/pages/defi/constants";
import { CHAIN_NATIVE_TOKENS } from "@src/constants/chain-native-tokens";
import { formatTokenAmount } from "@src/utils/format";
import { amountWithDecimals } from "@src/store/apis/blockchainApi/utils";

export interface AllowanceProps {
  account: string;
  chainId: ChainId;
}

export interface BalanceProps {
  account: string;
}

export interface BalanceOfProps {
  account: string;
  chainId: ChainId;
}

export interface DepositProps {
  chainId: ChainId;
  tokenAmount: string;
}

export interface ApproveProps {
  tokenAddress: string;
  tokenAmount: string;
}

export interface WithdrawProps {
  tokenAddress: string;
  tokenAmount: string;
}

// provider.getGasPrice()
const nativeCoins = blockchainApi.injectEndpoints({
  endpoints: (builder) => ({
    approveWithdraw: builder.mutation<TransactionResponse, ApproveProps>({
      queryFn: queryFnFactory<ApproveProps, TransactionResponse>(
        async ({ tokenAddress, tokenAmount }, { getState }) => {
          const state = getState() as any;
          const walletProvider = state.blockchain.walletProvider;
          const provider = new ethers.providers.Web3Provider(walletProvider, "any");
          const signer = provider.getSigner();
          const contract = new ethers.Contract(tokenAddress, WRAPPED_ABI, signer);
          const tokenAmountUint = await amountWithDecimals(contract, tokenAmount);

          return contract
            .approve(tokenAddress, tokenAmountUint, { gasLimit: MIN_GAS_UNITS_PER_SLICE })
            .then((x) => JSON.parse(JSON.stringify(x)));
        }
      ),
    }),
    checkAccountBalance: builder.query<any, BalanceProps>({
      queryFn: queryFnFactory<BalanceProps, any>(async ({ account }, { getState }) => {
        const state = getState() as any;
        const walletProvider = state.blockchain.walletProvider;
        const provider = new ethers.providers.Web3Provider(walletProvider, "any");

        const balance = await provider.getBalance(account).then((x) => JSON.parse(JSON.stringify(x)));

        return ethers.utils.formatEther(balance);
      }),
    }),
    checkAllowance: builder.query<any, AllowanceProps>({
      queryFn: queryFnFactory<AllowanceProps, any>(async ({ account, chainId }, { getState }) => {
        const state = getState() as any;
        const walletProvider = state.blockchain.walletProvider;
        const provider = new ethers.providers.Web3Provider(walletProvider, "any");
        const signer = provider.getSigner();
        const targetChain = CHAIN_NATIVE_TOKENS.find((chain) => chain.chainId === chainId);

        if (!targetChain) {
          return { hex: "" };
        }

        const contract = new ethers.Contract(targetChain!.wrappedTokenAddress, WRAPPED_ABI, signer);

        return contract
          .allowance(account, targetChain!.wrappedTokenAddress)
          .then((x) => JSON.parse(JSON.stringify(x)))
          .catch((e) => {
            console.log("checkAllowance error", e);

            return { hex: "" };
          });
      }),
    }),
    checkDepositBalance: builder.query<any, BalanceOfProps>({
      queryFn: queryFnFactory<BalanceOfProps, any>(async ({ account, chainId }, { getState }) => {
        const state = getState() as any;
        const walletProvider = state.blockchain.walletProvider;
        const provider = new ethers.providers.Web3Provider(walletProvider, "any");
        const signer = provider.getSigner();
        const targetChain = CHAIN_NATIVE_TOKENS.find((chain) => chain.chainId === chainId);

        if (!targetChain) {
          return { hex: "" };
        }

        const contract = new ethers.Contract(targetChain!.wrappedTokenAddress, WRAPPED_ABI, signer);

        return contract
          .balanceOf(account)
          .then((x) => JSON.parse(JSON.stringify(x)))
          .catch((e) => {
            console.log("checkDepositBalance error", e);

            return { hex: "" };
          });
      }),
    }),
    deposit: builder.mutation<TransactionResponse, DepositProps>({
      queryFn: queryFnFactory<DepositProps, TransactionResponse>(async ({ chainId, tokenAmount }, { getState }) => {
        const state = getState() as any;
        const walletProvider = state.blockchain.walletProvider;
        const provider = new ethers.providers.Web3Provider(walletProvider, "any");
        const signer = provider.getSigner();
        const targetChain = CHAIN_NATIVE_TOKENS.find((chain) => chain.chainId === chainId);

        const tokenAmountUint = ethers.utils.parseUnits(tokenAmount, targetChain!.decimals);

        return signer.sendTransaction({
          to: targetChain!.wrappedTokenAddress,
          value: tokenAmountUint,
          gasLimit: MIN_GAS_UNITS_PER_SLICE,
        });
      }),
    }),
    estimateDeposit: builder.query<any, DepositProps>({
      queryFn: queryFnFactory<DepositProps, any>(async ({ chainId, tokenAmount }, { getState }) => {
        const state = getState() as any;
        const walletProvider = state.blockchain.walletProvider;
        const provider = new ethers.providers.Web3Provider(walletProvider, "any");
        const targetChain = CHAIN_NATIVE_TOKENS.find((chain) => chain.chainId === chainId);

        if (!targetChain) {
          return { hex: "" };
        }

        const tokenAmountUint = ethers.utils.parseUnits(tokenAmount, targetChain.decimals);

        return provider
          .estimateGas({
            to: targetChain.wrappedTokenAddress,
            value: tokenAmountUint,
          })
          .then((x) => JSON.parse(JSON.stringify(x)))
          .catch((e) => {
            console.log("estimateDeposit error", e);

            return { hex: "" };
          });
      }),
    }),
    withdrawDeposit: builder.mutation<TransactionResponse, WithdrawProps>({
      queryFn: queryFnFactory<WithdrawProps, TransactionResponse>(
        async ({ tokenAddress, tokenAmount }, { getState }) => {
          const state = getState() as any;
          const walletProvider = state.blockchain.walletProvider;
          const provider = new ethers.providers.Web3Provider(walletProvider, "any");
          const signer = provider.getSigner();
          const contract = new ethers.Contract(tokenAddress, WRAPPED_ABI, signer);
          const tokenAmountUint = await amountWithDecimals(contract, formatTokenAmount(tokenAmount));

          return contract
            .withdraw(tokenAmountUint, { gasLimit: MIN_GAS_UNITS_PER_SLICE })
            .then((x) => JSON.parse(JSON.stringify(x)));
        }
      ),
    }),
  }),
});

export const {
  useCheckAllowanceQuery,
  useCheckAccountBalanceQuery,
  useEstimateDepositQuery,
  useCheckDepositBalanceQuery,
  useDepositMutation,
  useApproveWithdrawMutation,
  useWithdrawDepositMutation,
} = nativeCoins;
