import React from "react";
import { Box, Checkbox, FormControlLabel, Grid, Stack, Tooltip, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useChainId } from "wagmi";
import { GasPriceOption } from "./gas-price-option";
import { GAS_PRICE_OPTIONS } from "@src/pages/defi/constants";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { setMevProtection } from "@src/store/actions/user.action";
import { RootState } from "@src/store/types";
import { ChainId } from "@src/pages/defi/types";

const InfoIcon = () => (
  <Tooltip title="Stay up to date on gas prices. Find and compare the latest TX fees in DeFi.">
    <Typography color="text.secondary" variant="caption" display="flex">
      <HelpIcon fontSize="inherit" />
    </Typography>
  </Tooltip>
);

export const TransactionSpeedCard = () => {
  const chainId = useChainId();
  const dispatch = useAppDispatch();
  const checked = useAppSelector((state: RootState) => state.user.mev_protection);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMevProtection(event.target.checked));
  };

  return (
    <>
      <Box p={2} sx={{ backgroundColor: (theme) => theme.custom.background.secondary }} borderRadius={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="body2" fontWeight="600">
            Gas price (Gwei)
          </Typography>
          <InfoIcon />
        </Stack>
        <Box>
          <Box>
            <Grid container spacing={2} mt={0}>
              {GAS_PRICE_OPTIONS.map((x) => (
                <Grid item xs={4} key={x}>
                  <GasPriceOption gasPriceOption={x} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      {chainId === ChainId.ETHEREUM && (
        <Box
          marginTop={1}
          p={2}
          sx={{ backgroundColor: (theme) => theme.custom.background.secondary }}
          borderRadius={1}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Typography variant="body2" fontWeight="600">
              MEV Protection
            </Typography>
          </Stack>
          <Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox checked={!!checked} onChange={handleChange} inputProps={{ "aria-label": "controlled" }} />
                }
                label={<Typography fontSize={14}>Add MEV protection on Ethereum</Typography>}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
