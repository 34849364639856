import { AccountingLine, TokenData } from "../api/types";
import { ChainId } from "@src/pages/defi/types";
import { GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";
import { CHAIN_NAME } from "@src/pages/defi/constants";

export const CHAIN_RECCORD: Record<number, ChainId> = {
  1: ChainId.ETHEREUM,
  56: ChainId.BSC,
  137: ChainId.POLYGON,
  43114: ChainId.AVALANCHE,
  42161: ChainId.ARBITRUM,
  10: ChainId.OPTIMISM,
  8453: ChainId.BASE,
};

export const parseMetaMaskRequestError = (error: Record<string, string>) => {
  if (error.reason) {
    return error.reason;
  } else if (error.message) {
    return error.message;
  } else {
    return "Some error occurred";
  }
};

export const getDateFromPeriod = (period: GetOrdersPeriod) => {
  const date = new Date();
  switch (period) {
    case GetOrdersPeriod.Day:
      date.setDate(date.getDate() - 1);
      return date;
    case GetOrdersPeriod.Week:
      date.setDate(date.getDate() - 7);
      return date;
    case GetOrdersPeriod.Month:
      date.setMonth(date.getMonth() - 1);
      return date;
    case GetOrdersPeriod.ThreeMonths:
      date.setMonth(date.getMonth() - 3);
      return date;
    default:
      return date;
  }
};

export const filterTransactionsByDate = (data: AccountingLine[], period: GetOrdersPeriod) => {
  if (period === GetOrdersPeriod.All) return data;
  return data.filter((a) => new Date(a.proceed_at).getTime() > getDateFromPeriod(period).getTime());
};

export const getChainName = (tokenData: TokenData): string => {
  const chainId = tokenData?.network_id?.toString() || "";
  if (!chainId) return "";

  return CHAIN_NAME[chainId];
};
