import { AnbotoSubscription } from "../anboto-subscription";
import { PortfolioMessage } from "../types";

export class AccountSubsctiption extends AnbotoSubscription<string, PortfolioMessage> {
  getId = () => "";

  getIdFromMsg = () => "";

  transformMsg = (message: PortfolioMessage) => message;
}
