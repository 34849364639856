import React from "react";
import { Box, Stack, Divider, InputLabel, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { AuthCard } from "../layout/auth-card";
import { FormError } from "../layout/form-error";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { QrCode } from "@src/components/qr-code";

type SetupOtpProps = {
  onSubmit: (code: string) => void;
  onSkip?: () => void;
  uri: string;
  loading: boolean;
  error: string;
};

export const SetupOtp = ({ onSubmit, onSkip, uri, loading, error }: SetupOtpProps) => {
  const codeInputRef = React.useRef<HTMLInputElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });

  const onPaste = (event) => {
    const code = event.clipboardData.getData("text/plain");

    setValue("code", code);

    onSubmit(code);
  };

  React.useEffect(() => {
    codeInputRef.current?.focus();
  }, []);

  React.useEffect(() => {
    if (error) {
      codeInputRef.current?.focus();

      setError("code", {
        type: "manual",
        message: error,
      });
    }
  }, [error]);

  return (
    <AuthCard logo title="Enable two-factor authentication" sx={{ width: 948, minHeight: 0, padding: 3 }}>
      <Stack direction="row" sx={{ background: "#232C2F", p: 4, mt: -3, mb: -3, borderRadius: 1 }} minHeight={484}>
        <Box flex={1}>
          <Typography variant="h6" fontSize={18} fontWeight="bold">
            Scan QR code with your app
          </Typography>
          <Typography variant="body2" fontSize={16} color="text.secondary">
            Use your 2FA app to the scan the image below.{" "}
          </Typography>
          <Stack my={4} textAlign="center" justifyContent="center" alignItems="center">
            <QrCode uri={uri} size={260} />
          </Stack>
        </Box>
        <Divider sx={{ mx: 4 }} orientation="vertical" flexItem />
        <Box flex={1}>
          <form onSubmit={handleSubmit(({ code }) => onSubmit(code))} style={{ height: "100%" }}>
            <Stack height="100%">
              <Typography variant="body2" fontSize={16} color="text.secondary" mb={4}>
                After scanning the QR code image, the app will display a 6-digit that you can enter below
              </Typography>
              <InputLabel>Verification code</InputLabel>
              <AnbotoTextField
                data-sentry-mask="true"
                sx={{ mt: 1 }}
                inputProps={{ sx: { height: 42 } }}
                inputRef={codeInputRef}
                error={Boolean(errors.code)}
                placeholder="6-digit code / text code"
                fullWidth
                type="text"
                disabled={loading}
                {...register("code", {
                  required: true,
                  minLength: {
                    value: 6,
                    message: "Code must have at least 6 characters",
                  },
                })}
                name="code"
                onPaste={onPaste}
              />
              {errors.code && <FormError>{errors.code.message}</FormError>}
              <Box flexGrow={1} />
              <Stack direction="row" gap={2} width="100%" justifyContent={onSkip ? "space-between" : "flex-end"}>
                {onSkip && (
                  <AnbotoButton
                    disabled={loading}
                    size="large"
                    variant="outlined"
                    onClick={onSkip}
                    data-test-id="skip-2fa-setup-button"
                  >
                    Skip for now
                  </AnbotoButton>
                )}
                <AnbotoButton size="large" variant="contained" disabled={!isDirty} loading={!!loading} type="submit">
                  Continue
                </AnbotoButton>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </AuthCard>
  );
};
