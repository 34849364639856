import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { AccountStatuses, TradingActivity } from "@src/store/apis/anbotoApi/types";
import { NoTableData } from "@src/components/no-table-data";

export const DailyDashboard = () => {
  const [fetchDailyDashboard, { isLoading }] = anbotoApi.useLazyGetDailyDashboardQuery();

  const currentDate = new Date();

  const currentDayjsDate = dayjs(currentDate);

  const [value, setValue] = React.useState<Dayjs | null>(currentDayjsDate);
  const [tradingActivity, setTradingActivity] = React.useState<TradingActivity[]>([]);
  const [accountStatuses, setAccountStatuses] = React.useState<AccountStatuses[]>([]);
  const [, setAnchorEl] = React.useState<(EventTarget & HTMLDivElement) | undefined>();

  const setDailyDashboard = async (date) => {
    const dailyDashboardResult = await fetchDailyDashboard({ date: date.format("YYYY-MM-DD") });

    if (dailyDashboardResult.data) {
      setTradingActivity(dailyDashboardResult.data.trading_activity || []);
      setAccountStatuses(dailyDashboardResult.data.account_statuses || []);
    }
  };

  useEffect(() => {
    setDailyDashboard(currentDayjsDate);
  }, []);

  const onChangeDay = (newValue) => {
    setValue(newValue);
    setDailyDashboard(newValue);
  };

  return (
    <Stack gap={0.5} flexGrow={1}>
      <Paper>
        <Box>
          <Box sx={{ m: 4 }}>
            <DatePicker
              label="Controlled picker"
              value={value}
              onChange={onChangeDay}
              renderInput={(params) => (
                <AnbotoTextField size="small" {...params} onClick={(e) => setAnchorEl(e.currentTarget)} />
              )}
            />
          </Box>
          {isLoading ? (
            <Stack height={300} alignItems="center" justifyContent="center">
              <CircularProgress />
            </Stack>
          ) : (
            <>
              <Box
                p={2}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr",
                }}
              >
                <Stack overflow="auto" width="100%" flexShrink={0} direction="row">
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        marginBottom: 10,
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow sx={{ whiteSpace: "nowrap" }}>
                          <TableCell>Account name</TableCell>
                          <TableCell align="right">Account number</TableCell>
                          <TableCell align="right">Amount collected</TableCell>
                          <TableCell align="right">Amount due</TableCell>
                          <TableCell align="right">Balance before collection</TableCell>
                          <TableCell align="right">Cefi fee rate</TableCell>
                          <TableCell align="right">Defi fee rate</TableCell>
                          <TableCell align="right">Due Cefi Volume</TableCell>
                          <TableCell align="right">Due Defi Volume</TableCell>
                          <TableCell align="right">Charged Cefi Volume</TableCell>
                          <TableCell align="right">Charged Defi Volume</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {accountStatuses.length > 0 ? (
                          accountStatuses.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 }, border: 0, elevation: 0 }}
                            >
                              <TableCell component="th" scope="row">
                                {row.account_name}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.account_number}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Math.round(row.amount_collected * 100) / 100 || 0}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Math.round(row.amount_due * 100) / 100 || 0}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Math.round(row.balance_before_collection * 100) / 100 || 0}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.cefi_fee_rate || 0}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.defi_fee_rate || 0}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Math.round(row.due_cefi_volume * 100) / 100 || 0}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Math.round(row.due_defi_volume * 100) / 100 || 0}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Math.round(row.charged_cefi_volume * 100) / 100 || 0}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {Math.round(row.charged_defi_volume * 100) / 100 || 0}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={11} align="center">
                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                                gap={2}
                                justifyContent="center"
                                height={238}
                              >
                                <NoTableData />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
                <Stack overflow="auto">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Account name</TableCell>
                          <TableCell align="right">Account number</TableCell>
                          <TableCell align="right">Daily cefi volume</TableCell>
                          <TableCell align="right">Daily defi volume</TableCell>
                          <TableCell align="right">Cefi Exchange used</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tradingActivity.length > 0 ? (
                          tradingActivity.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 }, border: 0, elevation: 0 }}
                            >
                              <TableCell component="th" scope="row">
                                {row.account_name}
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                {row.account_number}
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                {Math.round(row.daily_cefi_volume * 100) / 100 || 0}
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                {Math.round(row.daily_defi_volume * 100) / 100 || 0}
                              </TableCell>
                              <TableCell component="th" scope="row" align="right">
                                {row.exchange_ids || 0}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={5} align="center">
                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                                gap={2}
                                justifyContent="center"
                                height={238}
                              >
                                <NoTableData />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};
