import { CompetitionConfig } from "@src/pages/competitions";

export const CONFIG: CompetitionConfig = {
  COMPETITION_ID: "bitget-may-2024",
  REWARDS: [
    { header: "1st", headerColor: "#ECAC27", minVolume: "", prizePoolPercent: 30 },
    { header: "2nd", headerColor: "#8A9296", minVolume: "", prizePoolPercent: 20 },
    { header: "3rd", headerColor: "#2C72E3", minVolume: "", prizePoolPercent: 15 },
    { header: "4th", headerColor: "transparent", minVolume: "", prizePoolPercent: 10 },
    { header: "5th", headerColor: "transparent", minVolume: "", prizePoolPercent: 5 },
    { header: "6th-10th", headerColor: "transparent", minVolume: "", prizePoolPercent: 20 },
  ],
  PRIZE_POOL: [
    { volume: "10M", prizePool: "$1K" },
    {
      volume: "25M",
      prizePool: "$5K",
    },
    {
      volume: "50M",
      prizePool: "$10K",
    },
    {
      volume: "100M",
      prizePool: "$20K",
    },
    {
      volume: "500M",
      prizePool: "$50K",
    },
    {
      volume: "1B",
      prizePool: "$100K",
    },
  ],
  START_TIME: "May 14th 2024 09:00 UTC",
  END_TIME: "June 14th 2024 09:00 UTC",
  PRIZE_POOL_SIZE: 100000,
  EXCHANGE_NAME: "Bitget",
  EXCHANGE_PROMO_LINK: "https://www.bitget.com/events/competition/fc48835c92775aeeb650931386d3d30f",
  REF_LINK: "https://www.bitget.com/referral/register?from=referral&clacCode=0RFL677X",
};
