import { PlatformAccount } from "./api";
import { OrdersCurrentTab } from "@src/store/apis/anbotoApi/types";

export enum GetOrdersType {
  Cefi = "cefi",
  Defi = "defi",
  All = "all",
}

export enum OrderSource {
  WebUI = "web-ui",
  Api = "api",
}

export type TFilters = {
  cefiDefi: GetOrdersType;
  orderType: OrdersCurrentTab;
  source: TOrderSourceOptions;
  teams: PlatformAccount[];
  onlyActive: boolean;
  accountsOnlyActive: boolean;
};

export type TOrderSourceOptions = OrderSource | "all";
