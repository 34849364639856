import React from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";
import referralProgramBg from "@src/assets/svg/referral-program-bg.svg";
import { Bybit, Gate, Woox, Bitget } from "@src/pages/competitions/index";

enum CompetitionId {
  GATE_NOVEMBER = "gate",
  WOOX_OCTOBER = "woox",
  BYBIT_JANUARY = "bybit",
  BITGET_MAY = "bitget",
}

const competitions = {
  [CompetitionId.BITGET_MAY]: {
    label: "Bitget May",
    component: <Bitget />,
  },
  [CompetitionId.BYBIT_JANUARY]: {
    label: "Bybit January",
    component: <Bybit />,
  },
  [CompetitionId.GATE_NOVEMBER]: {
    label: "Gate November 2023",
    component: <Gate />,
  },
  [CompetitionId.WOOX_OCTOBER]: {
    label: "Woox October 2023",
    component: <Woox />,
  },
};

const options: { value: CompetitionId; label: string }[] = Object.keys(competitions).map((key) => ({
  value: key as CompetitionId,
  label: competitions[key as CompetitionId].label,
}));

export const TradingCompetitions = () => {
  const navigate = useNavigate();
  const params = useParams();
  const currentId = (params.competitionId as CompetitionId) || CompetitionId.BITGET_MAY;

  return (
    <Stack
      flexGrow={1}
      sx={{
        background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url('${referralProgramBg}') right bottom no-repeat, linear-gradient(360deg, #031116 11.48%, #232C2F 102.73%)`,
        backgroundAttachment: "fixed",
      }}
      direction="column"
      alignItems="center"
    >
      <Stack width={800} mt={2}>
        <AnbotoButtonGroup<CompetitionId>
          size="small"
          options={options}
          onChange={(id) => navigate(`/trading-competitions/${id}`)}
          value={currentId}
        />
      </Stack>
      {competitions[currentId]?.component || (
        <Typography variant="body2" textAlign="center">
          No competition found
        </Typography>
      )}
    </Stack>
  );
};
