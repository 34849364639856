import { MarketDataSubsctiption } from "./anboto-market-data-subscription";

const marketDataSubscription: { current: null | MarketDataSubsctiption } = {
  current: null,
};

export const getMarketDataSubscription = () => {
  if (!marketDataSubscription.current) {
    marketDataSubscription.current = new MarketDataSubsctiption({
      url: "/stream/market_data/",
      auth: true,
    });
  }

  return marketDataSubscription.current;
};
