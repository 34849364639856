import React, { useRef, useMemo } from "react";
import { Box, Stack, styled } from "@mui/material";
import { createPortal } from "react-dom";
import { SetupApiKeyModal } from "./setup-api-key-modal";
import { SymbolSelector } from "./symbol-selector";
import { SymbolSelectVariant } from "./types";
import { addRecentSymbol } from "./symbol-select.slice";
import { SymbolSelectModal } from "./symbol-select-modal";
import { CefiExchangeId, OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { useMenu } from "@src/hooks/useMenu";
import { useAppDispatch } from "@src/store/hooks";
import { useLinkedExchanges } from "@src/pages/cefi/hooks/use-linked-exchanges";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useTestnetEnabled } from "@src/hooks/use-testnet-enabled";
import { OTC_EXCHANGES_MAP } from "@src/features/otc/constants";

export interface SymbolSelectProps {
  onChange(x: null | OrderSymbol): void;
  value: null | OrderSymbol;
  isError?: boolean;
  variant?: SymbolSelectVariant;
  disabled?: boolean;
  otc?: boolean;
}

export const SymbolSelect = ({ variant, value, onChange, disabled, otc }: SymbolSelectProps) => {
  const menu = useMenu();
  const dispatch = useAppDispatch();
  const modalTarget = useRef<HTMLDivElement>(null);
  const linkedExchanges = useLinkedExchanges();
  const isTestnet = useTestnetEnabled();
  const getExchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi: false });

  const exchanges = useMemo(
    () =>
      getExchangesQuery.data?.results?.reduce((res, { exchange_id }) => {
        let available = true;

        if (otc && ![CefiExchangeId.B2C2].includes(exchange_id)) available = false;
        if (!otc && [CefiExchangeId.B2C2].includes(exchange_id)) available = false;

        return { ...res, [exchange_id]: available };
      }, {}) || {},
    [getExchangesQuery.data]
  );
  const cefiLinked = !!linkedExchanges.exchanges.filter((id) => exchanges[id] && !OTC_EXCHANGES_MAP[id]).length;
  const otcLinked = !!linkedExchanges.exchanges.find((id) => OTC_EXCHANGES_MAP[id]);

  const hideModal = () => {
    menu.hide();
  };

  const handleClickAway = () => {
    hideModal();
  };

  const handleSymbolSelect = (symbol: OrderSymbol) => {
    hideModal();

    dispatch(addRecentSymbol({ symbol, type: otc ? "otc" : "cefi" }));

    onChange && onChange(symbol);
  };

  const handleSymbolSelectClick = (e) => {
    if (disabled) return false;

    if (linkedExchanges.isLoading) {
      return;
    } else {
      menu.show(e.currentTarget);
    }
  };

  const isSelectDisabled = linkedExchanges.isLoading || disabled;

  return (
    <Box position="relative">
      <>
        {!isSelectDisabled && <div data-testid="symbol-select-ready" style={{ height: 0, width: 0 }} />}
        <Stack onClick={(e) => handleSymbolSelectClick(e)} position="relative" data-testid="symbol-select">
          <PopperContainerBox ref={modalTarget} />
          <SymbolSelector variant={variant} value={value} disabled={isSelectDisabled} />
        </Stack>
        {isTestnet || (otc ? otcLinked : cefiLinked) ? (
          createPortal(
            <SymbolSelectModal
              container={() => modalTarget.current}
              open={menu.isOpen}
              anchorEl={menu.anchor}
              onSymbolSelect={handleSymbolSelect}
              onClickAway={handleClickAway}
              keepMounted
              exchanges={exchanges as Record<CefiExchangeId, boolean>}
              otc={otc}
            />,
            document.body
          )
        ) : linkedExchanges.isLoading ? null : (
          <SetupApiKeyModal
            onClickAway={handleClickAway}
            container={() => document.getElementById("popper-container")}
            open={menu.isOpen}
            anchorEl={menu.anchor}
            placement="bottom-start"
            sx={{ zIndex: 1 }}
          />
        )}
      </>
    </Box>
  );
};

const PopperContainerBox = styled(Box)({
  zIndex: 10,
  position: "absolute",
  width: "100%",
});
