import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useParentOrderFormContext } from "../../hooks/use-parent-order-form-context";
import { usePostTradeCreditsCefi } from "../../hooks/use-post-trade-credits-cefi";
import { CardDialog } from "@src/components/CardDialog";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { MissingCredits } from "@src/components/missing-credits";

type ReviewDialogProps = DialogProps & {
  onSubmit(): void;
  onClose(): void;
  onSaveForLater?: () => void;
  isOrderConfirmLoading: boolean;
  isSaveForLaterLoading?: boolean;
};

export const ReviewDialog = ({
  children,
  onSubmit,
  onClose,
  onSaveForLater,
  isOrderConfirmLoading,
  isSaveForLaterLoading,
  ...props
}: React.PropsWithChildren<ReviewDialogProps>) => {
  const orderForm = useParentOrderFormContext();

  const orderSymbol = orderForm.watch("symbol");
  const quantity = orderForm.watch("quantity");
  const { postTradeCredits, postTradeCreditsLoading } = usePostTradeCreditsCefi({
    quantity,
    orderSymbol,
  });

  return (
    <CardDialog {...props}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold">Order summary</Typography>
          <IconButton onClick={onClose} size="small">
            <Close sx={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flex: 1 }}>
        <Stack gap={2} flex={1} pt={2}>
          {!postTradeCreditsLoading && postTradeCredits < 0 && <MissingCredits creditsPrediction={postTradeCredits} />}
          {children}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" width="100%" gap={0.5}>
          <AnbotoButton variant="outlined" color="secondary" size="small" onClick={onClose}>
            Edit
          </AnbotoButton>

          {onSaveForLater && (
            <AnbotoButton
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => onSaveForLater()}
              loading={!!isSaveForLaterLoading}
            >
              <Typography fontSize={14} noWrap>
                Save for later
              </Typography>
            </AnbotoButton>
          )}

          <AnbotoButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => onSubmit()}
            loading={isOrderConfirmLoading || postTradeCreditsLoading}
            disabled={postTradeCredits < 0}
          >
            Confirm
          </AnbotoButton>
        </Stack>
      </DialogActions>
    </CardDialog>
  );
};
