export const DEFI_INTRO_STEPS = [
  {
    title: "Welcome to DeFi!",
    intro: "Welcome to Anboto DeFi! These steps will guide you through the basic features of the platform.",
  },
  {
    element: ".connect-wallet-button",
    title: "Connect Wallet",
    intro: "Enter the DeFi world by simply connecting your preferred wallet.",
  },
  {
    element: ".strategy-field-button-group",
    title: "Strategy",
    intro: "Click on the information icon to understand the use case of these algorithms.",
  },
  {
    element: ".clipsize-type-select",
    title: "Clip Size Type",
    intro:
      "Clip size is the size of each child order. Our smart TWAP calculates the optimal clip size for each order. ",
  },
  {
    element: ".pool-info",
    title: "Pool info",
    intro: "Learn about the various pools available for trading. Each pool has its own characteristics and liquidity.",
  },
];
