import dayjs from "dayjs";
import { useAppSelector } from "@src/store/hooks";
import { getOrderFormUserDefaultValues } from "@src/pages/defi/utils";
import { DEFI_ORDER_FORM_DEFAULT_VALUES } from "@src/pages/defi/hooks/use-defi-order-form";

export const useDefiOrderFormDefaultValues = (values = {}) => {
  const orderPreferences = useAppSelector((state) => state.user.order_preference);
  const userDefaultValues = getOrderFormUserDefaultValues(orderPreferences);
  const startTime = dayjs().toString();
  const defaultValues = { ...DEFI_ORDER_FORM_DEFAULT_VALUES, ...userDefaultValues, ...{ startTime }, ...values };

  return defaultValues;
};
