import React, { FC } from "react";
import { Skeleton } from "@mui/material";
import { useCurrencyMultiplicator } from "@src/pages/portfolio/hooks/use-currency-multiplicator";
import { formatCurrency } from "@src/utils/format";
import { useAppSelector } from "@src/store/hooks";
import { formatAmount, roundAmount } from "@src/pages/portfolio/utils/helpers";

export interface CurrencyValueProps {
  value: number;
}

export const CurrencyValue: FC<CurrencyValueProps> = ({ value }) => {
  const currency = useAppSelector((state) => state.uiSettings.currency)?.toUpperCase();
  const { isFetching, multiplicator } = useCurrencyMultiplicator(currency);
  const currentValueInCurrency = Math.abs(value * multiplicator);
  const valueSign = value < 0 ? "-" : "";
  const currencySymbol = formatCurrency(currency, 0).replaceAll("0", "").replaceAll(".", "");
  const curValue = valueSign + currencySymbol + formatAmount(roundAmount(String(currentValueInCurrency), 3));

  if (isFetching) {
    return <Skeleton animation="wave" sx={{ display: "inline-block", minWidth: 20, height: 20 }} />;
  }

  return <>{curValue}</>;
};
