import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  AccordionProps,
  AccordionSummaryProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const FeeWalletHelp = () => (
  <>
    <Typography mt={2} fontWeight="bold">
      What is the Fee Wallet?
    </Typography>
    <Typography mb={2}>
      The Fee Wallet is a user-friendly solution that ensures hassle-free fee payments while maintaining the security of
      your funds.
    </Typography>

    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography fontWeight="bold">1. Effortless Fee Deposits:</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Typography>
          When you’re ready to trade, simply deposit your desired amount of credits into your Fee Wallet. These credits
          serve as your dedicated funds to cover trading fees on the Anboto Platform.
        </Typography>
      </StyledAccordionDetails>
    </StyledAccordion>
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography fontWeight="bold">2. Seamless Fee Settlement:</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Typography>
          As you execute trades, the Fee Wallet intelligently deducts the corresponding fees directly from your
          deposited credits. This ensures smooth and hassle-free fee payments without the need for manual calculations.
        </Typography>
      </StyledAccordionDetails>
    </StyledAccordion>
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography fontWeight="bold">3. Real-time Transparency:</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Typography>
          The Fee Wallet provides a transparent overview of your credit balance and fee deductions, so you’re always in
          the loop!
        </Typography>
      </StyledAccordionDetails>
    </StyledAccordion>
    <StyledAccordion>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography fontWeight="bold">4. Flexibility and Control:</Typography>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <Typography>
          You retain full control over your funds at all times. Unlike traditional setups, our non-custodial approach
          ensures that you’re in charge of your credits, promoting security and trust.
        </Typography>
      </StyledAccordionDetails>
    </StyledAccordion>
  </>
);

const StyledAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  })
);

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => <AccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
