import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import { Stack, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { ApiKeyRow } from "./api-key-row";
import { AnbotoModal } from "@src/components/anboto-modal";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { anbotoApiKeys } from "@src/store/apis/anbotoApi/api-keys";
import { useAppSelector } from "@src/store/hooks";
import { ApiKeyCreateResponse } from "@src/store/apis/anbotoApi/api-keys/types";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";

export type KeyType = "publicKey" | "generatedKey";

export type ApiKeyFields = {
  type: KeyType;
  label: string;
  key: string;
};

export const ApiKeyModal = ({ onCreate, onClose }: { onCreate?: () => void; onClose: () => void }) => {
  const [createApiKey, { isLoading }] = anbotoApiKeys.useCreateApiKeyMutation();
  const snackbar = useSnackbar();
  const [data, setData] = useState<ApiKeyCreateResponse>();
  const teamId = useAppSelector((state) => state.user.team_uuid);

  const { register, handleSubmit } = useForm<ApiKeyFields>({
    defaultValues: {
      type: "generatedKey",
      label: "",
      key: "",
    },
  });

  const onSubmit = async ({ label }) => {
    try {
      const apiKeyData = await createApiKey({ teamId, label }).unwrap();

      setData(apiKeyData);

      onCreate && onCreate();
    } catch (error) {
      snackbar.enqueueSnackbar(parseAnbotoRequestError(error), { variant: "error" });

      onClose();
    }
  };

  return (
    <AnbotoModal
      title="Create API key"
      open
      onClose={onClose}
      onSuccessButtonClick={!data ? handleSubmit(onSubmit) : () => onClose && onClose()}
      successButtonLabel={!data ? "Submit" : "Ok"}
      loading={isLoading}
    >
      <Stack alignItems="center" justifyContent="center" minWidth={300} minHeight={100}>
        {data ? (
          <ApiKeyRow apiKey={data.api_key} secretKey={data.secret_key} />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <AnbotoTextField
              {...register("label", {
                required: true,
              })}
              variant="outlined"
              labelStyle="dynamic"
              label="Enter API Label"
              fullWidth
              sx={{ width: 300 }}
              disabled={isLoading}
            />
            {/* <Stack padding={0.5} mt={0.5}>
            <RadioGroup defaultChecked defaultValue={getValues("type")}>
              <FormControlLabel
                {...register("type", {
                  required: true,
                })}
                label="Generate symmetric key"
                control={<Radio size="small" />}
                value="generatedKey"
              />
              <FormControlLabel
                {...register("type", {
                  required: true,
                })}
                label="Enter public key"
                control={<Radio size="small" />}
                value="publicKey"
              />
            </RadioGroup>
          </Stack>
          <Stack mt={1} height={40}>
            {type === "publicKey" && (
              <AnbotoTextField
                {...register("key", {
                  required: true,
                })}
                variant="outlined"
                labelStyle="dynamic"
                label="Enter Public key"
                fullWidth
              />
            )}
          </Stack> */}
          </form>
        )}
      </Stack>
    </AnbotoModal>
  );
};
