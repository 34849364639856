import React from "react";
import { CampaignRules } from "./campaign-rules";
import { TermsAndConditions } from "./terms-and-conditions";
import { CONFIG } from "./config";
import { ReactComponent as BitgetLogo } from "@src/assets/svg/bitget-logo.svg";
import { Content } from "@src/pages/competitions/components/content";
import { useLeaderboard } from "@src/pages/competitions/hooks/use-leaderboard";

export const Layout = () => {
  const { sortedLeaderBoard, isFetching, refetch } = useLeaderboard({ competitionId: CONFIG.COMPETITION_ID });

  return (
    <Content
      prizePoolSize={CONFIG.PRIZE_POOL_SIZE}
      exchangeLogo={<BitgetLogo width={100} height="auto" />}
      isLuckyBonus
      bonusTitle="iPhone 15 | Messi Merchandise"
      exchangeName={CONFIG.EXCHANGE_NAME}
      startDate={CONFIG.START_TIME}
      endDate={CONFIG.END_TIME}
      rewards={CONFIG.REWARDS}
      isRewardsColumn={true}
      prizePool={CONFIG.PRIZE_POOL}
      about={null}
      campaignRules={<CampaignRules />}
      termsAndConditions={<TermsAndConditions />}
      leaderBoardData={sortedLeaderBoard}
      isLeaderBoardDataFetching={isFetching}
      leaderBoardRefetch={refetch}
      leaderboardPosition="right"
      registerUrl={CONFIG.EXCHANGE_PROMO_LINK}
    />
  );
};
