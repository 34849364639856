import CoinsList from "@src/store/apis/coingeckoApi/coins.json";
import { TokenDataWithIcon } from "@src/store/apis/anbotoApi/types";

export interface IconsData {
  token_symbol: string;
  chain_name?: string;
  is_position?: boolean;
}

const baseUrl = "https://cryptocompare.com";

export function enrichDataWithIcons<T>(
  data: Array<T & IconsData> | undefined,
  chainIcons?: { [name: string]: string }
): Array<T & TokenDataWithIcon> {
  if (!data) {
    return [];
  }
  return data.map((item) => {
    const tokenSymbol = item.is_position ? item.token_symbol.split("/")[0] : item.token_symbol;
    const targetCoin = CoinsList[tokenSymbol];

    if (targetCoin) {
      return {
        ...item,
        coinName: targetCoin.coinName,
        symbol: targetCoin.symbol,
        ...(!chainIcons && { url: `${baseUrl}${targetCoin.imageUrl}` }),
        ...(item.chain_name &&
          chainIcons &&
          chainIcons[item.chain_name] && {
            chainIconUrl: chainIcons[item.chain_name],
          }),
      };
    }

    return {
      ...item,
      symbol: item.token_symbol,
      coinName: item.token_symbol,
      ...(item.chain_name &&
        chainIcons &&
        chainIcons[item.chain_name] && { chainIconUrl: chainIcons[item.chain_name] }),
    };
  });
}
