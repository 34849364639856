import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const LimitPriceField = forwardRef((props: ControllerRenderProps & AnbotoTextFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    label={props?.label ? props?.label : "Limit price"}
    type="number"
    size="small"
    fullWidth
    inputRef={ref}
  />
));
LimitPriceField.displayName = "LimitPriceField";
