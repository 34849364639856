import React from "react";
import { Stack, Typography, Button, IconButton, styled } from "@mui/material";
import { Control } from "react-hook-form";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { SettingsRow } from "../settings-row";
import { getStyledIcon } from "../settings-thumb";
import { WalletNameField } from "@src/pages/settings/wallet/wallet-name-field";
import { WalletAddressField } from "@src/pages/settings/wallet/wallet-address-field";
import { WalletCreateBulkFormFieldValues } from "@src/pages/settings/wallet/use-wallet-create-bulk-form";

type WalletCreateBulkFormItemCardProps = {
  control: Control<WalletCreateBulkFormFieldValues>;
  index: number;
  isSubmitting: boolean;
  onCreateWalletClick(): void;
  onWalletDeleteClick(): void;
};

export const WalletCreateBulkFormItemCard = ({
  control,
  index,
  onCreateWalletClick,
  onWalletDeleteClick,
  isSubmitting,
}: WalletCreateBulkFormItemCardProps) => {
  return (
    <SettingsRow>
      {index === 0 ? (
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="body2">We only support EVM-compatible wallets</Typography>
          <AddWalletButton
            variant="text"
            color="success"
            sx={{ textTransform: "none" }}
            onClick={onCreateWalletClick}
            disabled={isSubmitting}
          >
            <AddCircleOutline sx={{ mr: 1 }} />
            Add another wallet
          </AddWalletButton>
        </Stack>
      ) : null}
      <Stack flexDirection={index === 0 ? "row" : "column"} gap={2} position="relative">
        <Stack flexDirection="row" justifyContent="space-between">
          <WalletNameField control={control} name={`wallets.${index}.name`} />
        </Stack>
        <WalletAddressField control={control} name={`wallets.${index}.address`} />
        {index === 0 ? null : (
          <IconButton
            disabled={isSubmitting}
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={onWalletDeleteClick}
          >
            {getStyledIcon(RemoveCircleOutline)}
          </IconButton>
        )}
      </Stack>
    </SettingsRow>
  );
};

const AddWalletButton = styled(Button)(({ theme }) => ({
  color: theme.custom.colors.secondary,
  fontSize: 14,
  fontWeight: "normal",
  textTransform: "none",
}));
