import React from "react";
import { Stack, StackProps, Radio } from "@mui/material";
import { Group } from "./group";

interface GroupListProps extends StackProps {
  groupIds: string[];
  selected?: string;
  onGroupClick: (id: string) => void;
  radio?: boolean;
  getSelectedLabel?: (id: string) => string;
}

export const GroupList = ({ groupIds, selected, onGroupClick, radio, getSelectedLabel, ...props }: GroupListProps) => (
  <Stack direction="row" gap={0.5} {...props} maxWidth={220} flexWrap="wrap" justifyContent="space-between">
    {groupIds.map((groupId) => (
      <Stack
        key={groupId}
        direction="row"
        alignItems="center"
        sx={{ cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          onGroupClick(groupId);
        }}
      >
        {radio && <Radio checked={groupId === selected} size="small" />}
        <Group
          groupId={groupId}
          active={groupId === selected || (!groupId && !groupIds.find((id) => id === selected))}
          label={getSelectedLabel ? getSelectedLabel(groupId) : groupId}
        />
      </Stack>
    ))}
  </Stack>
);
