import React, { FC, useMemo, memo } from "react";
import { Card, CardContent, Skeleton, Stack, Tabs, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CellProps, Column } from "react-table";
import { useDefiTableSearch } from "../hooks/use-defi-table-search";
import { ExpandedRow } from "./expanded-row";
import { GetOrdersPeriod, OrdersCurrentTab, OrderStatuses, ParentDefiOrder } from "@src/store/apis/anbotoApi/types";
import { OrdersTableFilters } from "@src/components/orders-table-filters";
import { DefaultStatusFilter, FilterStatusValue } from "@src/components/orders-table-filters/types";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { setCurrentTab } from "@src/store/slices/decentralizedExchangePageSlice";
import { loaderData } from "@src/pages/defi/constants";
import { OrdersTable } from "@src/components/orders-table";
import { AnbotoTab } from "@src/components/ui/AnbotoTabs/AnbotoTab";
import { getColumns } from "@src/pages/defi/orders-table-container/get-columns";
import { useWallets } from "@src/pages/portfolio";
import { useGetParentDefiOrdersQuery } from "@src/store/apis/anbotoApi/defi";
import { ORDERS_LIMIT } from "@src/store/constants";
import { ExportOrdersButton } from "@src/components/export-orders-button";
import { ALL_AGGREGATORS_PARAMETER } from "@src/store/apis/anbotoApi/constants";
import { usePermissions } from "@src/hooks/use-permissions";

export interface OrdersTableContainerProps {
  onSymbolClick(x: ParentDefiOrder): void;
}

const DefiOrdersTable: FC<OrdersTableContainerProps> = ({ onSymbolClick }) => {
  const { currentTab } = useAppSelector((state) => state.decentralizedExchangePage);
  const [period, setPeriod] = React.useState(GetOrdersPeriod.All);
  const [statusFilter, setStatusFilter] = React.useState<FilterStatusValue<OrderStatuses>>(DefaultStatusFilter.All);
  const wallets = useWallets();
  const getExchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi: true });
  const accountId = useAppSelector((state) => state.user.team_uuid);
  const permissions = usePermissions();
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const dispatch = useAppDispatch();

  const supportedExchanges =
    React.useMemo(
      () =>
        getExchangesQuery.data?.results.filter((exchange) => exchange.is_defi).map((exchange) => exchange.exchange_id),
      [getExchangesQuery.data]
    ) || [];
  const exchange_list_ids = [...supportedExchanges, ALL_AGGREGATORS_PARAMETER].join(",");

  const { data, isFetching, isError, refetch } = useGetParentDefiOrdersQuery(
    {
      accountId,
      currentTab,
      period,
      status: statusFilter,
      exchange_list_ids,
      limit: ORDERS_LIMIT,
    },
    { skip: getExchangesQuery.isFetching }
  );

  const { filteredOrders, setSearchString, searchString } = useDefiTableSearch(data, wallets);

  const isHistory = currentTab === OrdersCurrentTab.History;

  const handleTabChange = (e: React.SyntheticEvent, value: OrdersCurrentTab) => dispatch(setCurrentTab({ value }));

  const renderCell =
    (render) =>
    // eslint-disable-next-line react/display-name
    ({ row }: CellProps<ParentDefiOrder>) =>
      isFetching ? <Skeleton sx={{ flex: 1 }} variant="rectangular" animation="wave" /> : isError ? null : render(row);

  const columns = useMemo<Array<Column<ParentDefiOrder>>>(
    () => getColumns({ isHistory, onSymbolClick, renderCell, wallets, readOnly: !permissions.trade }),
    [isFetching, onSymbolClick]
  );

  const results = data?.filter((el) => (searchString && !!el.order_id ? filteredOrders[el.order_id] : true)) || [];

  const tableData = (isFetching ? (results?.length ? results : loaderData) : results) as Array<ParentDefiOrder>;

  return (
    <Card>
      <CardContent>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Tabs value={currentTab} sx={{ mb: 2 }} onChange={handleTabChange}>
            <AnbotoTab label="Regular orders" value={OrdersCurrentTab.Regular} />
            <AnbotoTab label="Trigger orders" value={OrdersCurrentTab.Trigger} />
            <AnbotoTab label="History" value={OrdersCurrentTab.History} />
          </Tabs>
          <Stack display="flex" direction="row" gap={2} alignItems="center" className="order-table">
            <Stack>
              <ExportOrdersButton
                size={isLgUp ? "small" : "medium"}
                ordersType="defi"
                compact={!isLgUp}
                label="Export all records"
                variant="flat"
              />
            </Stack>
            <OrdersTableFilters<OrderStatuses>
              status={{ value: statusFilter, onChange: (status) => setStatusFilter(status as OrderStatuses) }}
              period={{ onChange: setPeriod }}
              search={{
                onSearch: (value: string) => setSearchString(value?.toLowerCase()),
              }}
            />
          </Stack>
        </Stack>
        <OrdersTable<ParentDefiOrder>
          columns={columns}
          data={tableData}
          loading={isFetching}
          error={!!isError}
          refetch={refetch}
          limit={ORDERS_LIMIT}
          expandedRowRender={(data: ParentDefiOrder) => <ExpandedRow data={data} key={data.order_id} />}
          pagination
          striped
        />
      </CardContent>
    </Card>
  );
};

export const OrdersTableContainer = memo(DefiOrdersTable);
