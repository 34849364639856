import React from "react";
import {
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LeaderBoardTableRow } from "./leader-board-row";
import DashboardDataCardHeader from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/dashboard-data-card-header";
import { formatLargeNumber, getRankingNumberColor } from "@src/pages/competitions/helpers";
import { CompetitionParticipant } from "@src/store/apis/anbotoApi/types";
import { UserRow } from "@src/pages/competitions/components/user-row";
import { RankingCircle } from "@src/pages/competitions/components/rankning-circle";

const skeletonRows = Array.from({ length: 10 }).map((_, index) => (
  <LeaderBoardTableRow key={index}>
    <TableCell colSpan={3}>
      <Skeleton variant="text" width={270} />
    </TableCell>
  </LeaderBoardTableRow>
));

type LeaderBoardProps = {
  leaderBoardData: CompetitionParticipant[];
  isLeaderBoardDataFetching: boolean;
  leaderBoardRefetch?: () => void;
};

export const LeaderBoard = ({ leaderBoardData, leaderBoardRefetch, isLeaderBoardDataFetching }: LeaderBoardProps) => {
  return (
    <Stack direction="column" justifyContent="space-between" flexGrow={1} flex={1}>
      <Stack>
        <Stack px={1}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <DashboardDataCardHeader header="Leaderboard" fontWeight={500} fontSize={16} />

            {leaderBoardRefetch && (
              <IconButton
                onClick={() => leaderBoardRefetch()}
                sx={{
                  top: -5,
                  right: -10,
                }}
              >
                <RefreshIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>

        <TableContainer>
          <Table
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow
                sx={{
                  "&>th": {
                    border: 0,
                    fontSize: 12,
                    color: "#8A9296",
                    fontWeight: 400,
                    padding: 1,
                    paddingBottom: 0,
                  },
                  "&>th:last-child": { paddingRight: 1 },
                  "&>th:first-child": { paddingLeft: 1 },
                }}
              >
                <TableCell>Ranking</TableCell>
                <TableCell align="left">Client ID</TableCell>
                <TableCell align="right">Trading volume (USDT)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLeaderBoardDataFetching
                ? skeletonRows
                : leaderBoardData.map((leaderBoardItem, index) => {
                    const currentRank = index + 1;
                    return leaderBoardItem?.is_trader ? (
                      <UserRow
                        ranking={currentRank <= 10 ? currentRank : "You"}
                        volume={leaderBoardItem.volume}
                        clientId={leaderBoardItem.account_number}
                      />
                    ) : (
                      <LeaderBoardTableRow key={leaderBoardItem.account_number}>
                        <TableCell sx={{ color: `${getRankingNumberColor(currentRank)}${"!important"}` }}>
                          <Stack direction="row" gap={0.5} alignItems="center">
                            <Typography
                              sx={{ fontSize: 14, fontWeight: 500 }}
                              color={getRankingNumberColor(currentRank)}
                            >
                              {String(currentRank).length === 1 ? "0" + currentRank : currentRank}
                            </Typography>
                            <RankingCircle ranking={currentRank} />
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{leaderBoardItem?.account_number}</TableCell>
                        <TableCell align="right">{formatLargeNumber(leaderBoardItem.volume)}</TableCell>
                      </LeaderBoardTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Stack pl={1}>
        <Typography fontSize={12} color="#8A9296" fontWeight={400}>
          Only the 10 first one are displayed
        </Typography>
      </Stack>
    </Stack>
  );
};
