import React, { FC } from "react";
import { Box, styled, Tooltip } from "@mui/material";
import SVG from "react-inlinesvg";
import { SelectNetworkDialog } from "./select-network-dialog";
import { useDialog } from "@src/hooks/useDialog";
import { ChainId } from "@src/pages/defi/types";
import { CHAIN_LOGO } from "@src/pages/defi/constants";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

export interface SelectNetworkIconButtonProps {
  onChange: (chainId: ChainId) => void;
  chainId: ChainId;
}

export const SelectNetworkIconButton: FC<SelectNetworkIconButtonProps> = ({ chainId, onChange }) => {
  const selectNetworkDialog = useDialog();

  return (
    <Box>
      <Tooltip title="Select network">
        <SelectChainIconButton variant="outlined" onClick={selectNetworkDialog.show}>
          <SVG src={CHAIN_LOGO[chainId]} height={20} />
        </SelectChainIconButton>
      </Tooltip>
      <SelectNetworkDialog
        chainId={chainId}
        isOpen={selectNetworkDialog.isOpen}
        onChange={onChange}
        onClose={selectNetworkDialog.hide}
      />
    </Box>
  );
};

const SelectChainIconButton = styled(AnbotoButton)(({ theme }) => ({
  minWidth: 32,
  width: 32,
  height: 32,
  background: theme.custom.background.inputAdornment.light,
  border: `1px solid ${theme.custom.border.default}`,
  borderRadius: 4,
  padding: 0,
  float: "right",
}));
