import { styled } from "@mui/material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

export enum QuantityStatus {
  LOCKED = "locked",
  UNLOCKED = "unlocked",
}

export const UnlockingLabelButton = styled(AnbotoButton)<{ quantityStatus: QuantityStatus }>(
  ({ theme, quantityStatus }) => ({
    marginTop: 5,
    width: "100%",
    height: 20,
    fontSize: 12,
    color: `${theme.palette.text.primary} !important`,
    textAlign: "left",
    disabled: true,
    disableRipple: true,
    border: "1px solid",
    backgroundColor:
      quantityStatus === QuantityStatus.LOCKED ? theme.custom.colors.darkOrange : theme.custom.colors.darkGreen,
    borderColor: quantityStatus === QuantityStatus.LOCKED ? theme.palette.warning.main : theme.custom.colors.secondary,
    "&:hover": {
      backgroundColor:
        quantityStatus === QuantityStatus.LOCKED ? theme.custom.colors.darkOrange : theme.custom.colors.darkGreen,
    },
  })
);
