import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { ArrowDownward, ArrowUpward, InfoRounded } from "@mui/icons-material";
import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import format from "date-fns/format";
import { formatUsd } from "@src/utils/format";
import { referralProgramApi } from "@src/pages/referral-program";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { ReferralProgramTabs, setCurrentTab } from "@src/store/slices/referralProgramSlice";
import { RootState } from "@src/store/types";

const Commissions = () => {
  const dispatch = useAppDispatch();
  const handleInfoIconClick = () => {
    dispatch(setCurrentTab(ReferralProgramTabs.TIER_STRUCTURE));
  };

  const team_uuid = useAppSelector((state: RootState) => state.user.team_uuid)!;
  const { data, isFetching, isError, refetch } = referralProgramApi.useGetCodesListQuery({ team_uuid });

  const commissionsData = data?.referee_details || [];

  useEffect(() => {
    refetch();
  }, []);

  const [sortBy, setSortBy] = useState({ field: "", order: "asc" });

  const handleSort = (field: string) => {
    setSortBy((prevSortBy) => ({
      field,
      order: prevSortBy.field === field && prevSortBy.order === "asc" ? "desc" : "asc",
    }));
  };

  const sortedCommissionsData = [...commissionsData].sort((a, b) => {
    const multiplier = sortBy.order === "asc" ? 1 : -1;
    return a[sortBy.field] < b[sortBy.field] ? -1 * multiplier : 1 * multiplier;
  });

  return (
    <>
      {isError ? (
        <Stack justifyContent="center" alignItems="center" minHeight={400}>
          <Typography>Something went wrong. Please reload the page.</Typography>
        </Stack>
      ) : isFetching ? (
        <Stack justifyContent="center" alignItems="center" minHeight={400}>
          <CircularProgress />
        </Stack>
      ) : (
        <TableContainer>
          <Table
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow
                sx={{
                  "&>th": { border: 0, fontSize: 12, color: "#8A9296", fontWeight: 400, padding: 1, paddingBottom: 0 },
                }}
              >
                <TableCell>Referral code</TableCell>
                <TableCell align="center">Onboarding date</TableCell>
                <TableCell align="center">User email </TableCell>
                <TableCell align="center">
                  <Stack sx={{ alignItems: "center" }} direction="row" gap="0.5">
                    <Typography fontSize={12}> Referral rate</Typography>
                    <IconButton
                      sx={{
                        "&:hover": {
                          background: "none",
                          opacity: 0.7,
                        },
                      }}
                      onClick={handleInfoIconClick}
                    >
                      <InfoRounded
                        sx={{ width: 18, color: (theme) => theme.palette.text.secondary, cursor: "pointer" }}
                      />
                    </IconButton>
                  </Stack>
                </TableCell>
                <TableCell align="center" onClick={() => handleSort("fees_reward")} sx={{ cursor: "pointer" }}>
                  <Stack direction="row" gap={0.5} alignItems="center">
                    {sortBy.field === "fees_reward" && sortBy.order === "asc" && <ArrowUpward sx={{ fontSize: 12 }} />}
                    {sortBy.field === "fees_reward" && sortBy.order === "desc" && (
                      <ArrowDownward sx={{ fontSize: 12 }} />
                    )}
                    <Typography fontSize={12}>Commissions</Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedCommissionsData.map((commission) => (
                <TableRow
                  key={commission.email}
                  sx={{
                    background: "#192022",
                    paddingLeft: 2,
                    paddingRight: 2,
                    "&>td": {
                      border: 0,
                      fontWeight: 500,
                      fontSize: 14,
                      marginBottom: 1,
                      color: "#B0BCC2",
                      padding: 1,
                    },
                    "&>td:last-child": { paddingRight: 2, borderTopRightRadius: 4, borderBottomRightRadius: 4 },
                    "&>td:first-child": { paddingLeft: 2, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 },
                  }}
                >
                  <TableCell scope="row">{commission.code}</TableCell>
                  <TableCell align="center" scope="row">
                    {format(new Date(commission.since), "dd-MM-yyyy")}
                  </TableCell>
                  <TableCell align="center">{commission.email}</TableCell>
                  <TableCell align="center" scope="row">
                    {commission.referral_rate * 100}%
                  </TableCell>
                  <TableCell align="center">{formatUsd(commission.fees_reward)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default Commissions;
