import React from "react";
import { Stack, Typography, styled } from "@mui/material";
import { WIDGET_HEADER_HEIGHT } from "../constants";
import { GroupMenu } from "./group-menu";
import { DragArea } from "./drag-area";
import { WidgetHeaderButton } from "./widget-header-button";

export type WidgetHeaderProps = {
  onPlusClick?: () => void;
  onCloseClick?: () => void;
  group?: string;
  onGroupChange?: (id: string) => void;
  title?: React.ReactNode;
  spacingX?: number;
  lock?: boolean;
  noEmptyGroup?: boolean;
  hideGroup?: boolean;
};

export const WidgetHeader = ({
  onPlusClick,
  onCloseClick,
  onGroupChange,
  group,
  title,
  children,
  lock,
  spacingX = 2,
  noEmptyGroup,
  hideGroup,
}: React.PropsWithChildren<WidgetHeaderProps>) => {
  return (
    <Root px={spacingX}>
      {!lock && <DragArea sx={{ position: "absolute", zIndex: 0, left: 0, top: 0, width: "100%", height: "100%" }} />}

      <Stack
        overflow="hidden"
        direction="row"
        position="relative"
        zIndex={2}
        flexShrink={2}
        // 70px - buttons width
        maxWidth="calc(100% - 70px)"
      >
        {typeof title === "string" ? <Title variant="body2">{title}</Title> : title}
        {children}
      </Stack>

      <Stack
        direction="row"
        gap={0.5}
        alignItems="center"
        position="absolute"
        zIndex={2}
        right={(theme) => theme.spacing(spacingX)}
      >
        {!hideGroup && onGroupChange && (
          <GroupMenu selected={group} onChange={onGroupChange} noEmptyGroup={noEmptyGroup} />
        )}
        {!lock && onPlusClick && <WidgetHeaderButton icon="add" onClick={onPlusClick} />}
        {!lock && onCloseClick && <WidgetHeaderButton icon="close" onClick={onCloseClick} />}
      </Stack>
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  height: WIDGET_HEADER_HEIGHT,
  background: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  alignItems: "center",
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  maskPosition: "relative",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: 12,
  paddingLeft: theme.spacing(0.5),
}));
