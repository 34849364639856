import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";

const SHORTCUTS = [5, 10, 20];

type PovRatioFieldProps = ControllerRenderProps &
  AnbotoTextFieldProps & {
    hideButtonGroup?: boolean;
  };

export const PovRatioField = forwardRef((props: PovRatioFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    type="number"
    inputRef={ref}
    size="small"
    label={
      <Stack direction="row" gap={0.2} alignItems="center" sx={{ lineHeight: 1 }}>
        <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
          {props.label || "Ratio (%)"}
        </Typography>
        <Tooltip title="Amount 1 means order will participate to 1% of the volume">
          <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
        </Tooltip>
      </Stack>
    }
    inputProps={{
      max: 100,
      min: 0,
    }}
    InputProps={{
      sx: { paddingRight: "3px" },
      endAdornment: (
        <>
          {!props.hideButtonGroup && (
            <AnbotoButtonGroup<number>
              value={+props.value}
              view="compact"
              sx={{ display: "flex", "& .MuiButton-root": { minWidth: 0, padding: "3px 8px" } }}
              options={SHORTCUTS.map((value: number) => ({ label: `${value}`, value }))}
              onChange={props.onChange}
            />
          )}
        </>
      ),
    }}
    fullWidth
    FormHelperTextProps={{ sx: { position: "absolute", top: "100%", mt: 0, ml: 0 } }}
  />
));

PovRatioField.displayName = "PovRatioField";
