import React from "react";
import { Content } from "@src/pages/competitions/components/content";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { ReactComponent as GateLogo } from "@src/assets/svg/trading-competition/gateio-competition-logo.svg";
import { CampaignRules } from "@src/pages/competitions/components/gate/campaign-rules";
import { TermsAndConditions } from "@src/pages/competitions/components/gate/terms-and-conditions";
import { CONFIG } from "@src/pages/competitions/components/gate/config";

export const Layout = () => {
  const leaderBoardData = anbotoApi.useGetLeaderBoardQuery(null);
  const leaderBoard = leaderBoardData?.data?.["Gate trading competition"] || [];
  let sortedLeaderBoard = [...leaderBoard].sort((a, b) => b.volume - a.volume).slice(0, 10);

  if (!sortedLeaderBoard.some((x) => x.is_trader === true)) {
    const currentUserParticipant = leaderBoard.find((x) => x.is_trader === true);
    if (currentUserParticipant) sortedLeaderBoard = [...sortedLeaderBoard, currentUserParticipant];
  }

  return (
    <Content
      prizePoolSize={CONFIG.PRIZE_POOL_SIZE}
      exchangeLogo={
        <GateLogo
          style={{
            height: 24,
            width: 100,
          }}
        />
      }
      exchangeName={CONFIG.EXCHANGE_NAME}
      startDate={CONFIG.START_TIME}
      endDate={CONFIG.END_TIME}
      rewards={CONFIG.REWARDS}
      prizePool={CONFIG.PRIZE_POOL}
      about={null}
      campaignRules={<CampaignRules prizePool={CONFIG.PRIZE_POOL} />}
      termsAndConditions={<TermsAndConditions />}
    />
  );
};
