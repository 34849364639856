import React from "react";
import { Stack, ThemeProvider, Typography } from "@mui/material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { muiTheme } from "@src/components/theme/mui-theme";

export const FallbackPage = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <Stack gap={2} justifyContent="center" textAlign="center" alignItems="center" height="100vh">
        <Typography color="text.primary">Oops, something went wrong</Typography>
        <Typography color="text.primary">
          We were notified about this situation and will fix it as soon as possible
        </Typography>
        <AnbotoButton variant="outlined" onClick={() => window.location.reload()}>
          Refresh
        </AnbotoButton>
      </Stack>
    </ThemeProvider>
  );
};
