import React from "react";
import { Dialog, DialogContent, Typography, DialogActions } from "@mui/material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

type DialogBeforeDeleteProps = {
  open: boolean;
  setOpen: (x: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteFunction: (x?: any) => void;
};

export const DialogBeforeDelete = ({ open, setOpen, deleteFunction }: DialogBeforeDeleteProps) => {
  const handleConfirm = async () => {
    setOpen(false);
    await deleteFunction();
  };

  return (
    <Dialog open={open} sx={{ "& .MuiPaper-root": { background: `#232C2F !important` }, position: "fixed", zIndex: 3 }}>
      <DialogContent>
        <Typography variant="body1">Are you sure you want to delete? </Typography>
      </DialogContent>
      <DialogActions>
        <AnbotoButton variant="outlined" size="small" onClick={() => setOpen(false)}>
          No
        </AnbotoButton>
        <AnbotoButton variant="outlined" size="small" onClick={handleConfirm} autoFocus>
          Yes
        </AnbotoButton>
      </DialogActions>
    </Dialog>
  );
};
