/* eslint-disable react/display-name */
import React from "react";
import format from "date-fns/format";
import { Typography, Stack } from "@mui/material";
import { Row, createColumnHelper } from "@tanstack/table-core";
import { PlatformOrder } from "./api";
import { AccountName } from "./account-name";
import { END_TIME_FORMAT } from "@src/pages/cefi/constants";
import { LivePrice } from "@src/pages/cefi/orders-table/components/live-price";
import { Above } from "@src/pages/cefi/orders-table/components/above";
import { Below } from "@src/pages/cefi/orders-table/components/below";
import { formatStrategyLabel, getParentOrderStatus } from "@src/pages/cefi/orders-table/utils";
import { MemoizedExpandRow } from "@src/pages/cefi/orders-table/components/expand-row";
import { OrdersTableAccount } from "@src/components/orders-table-account";
import { OrderTableSide, ParentOrderStatus } from "@src/components";
import { QuantityLinearProgress } from "@src/components/quantity-linear-progress";
import { CELL_FONT_SIZE, sort, renderSortArrows } from "@src/components/orders-table";
import { OrderExecutionStrategy, OrdersCurrentTab, ParentOrderStatuses } from "@src/store/apis/anbotoApi/types";
import { IntervalDuration } from "@src/components/interval-duration";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";
import { formatPrice } from "@src/components/watchlist/helpers";
import { store } from "@src/store/store";
import { formatAmount, roundAmount } from "@src/pages/portfolio/utils/helpers";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";

const columnHelper = createColumnHelper<PlatformOrder>();

export const getColumns = ({ currentTab }: { currentTab: OrdersCurrentTab }) => {
  const isHistory = currentTab === OrdersCurrentTab.History;
  const isRegular = currentTab === OrdersCurrentTab.Regular;
  const isTrigger = currentTab === OrdersCurrentTab.Trigger;
  const isSaved = currentTab === OrdersCurrentTab.SavedOrders;

  return [
    columnHelper.display({
      header: "",
      size: 35,
      id: "sort",
      cell: ({ row }) => <MemoizedExpandRow expanded={row.getIsExpanded()} onClick={() => row.toggleExpanded()} />,
    }),
    columnHelper.accessor("symbol", {
      id: "symbol",
      header: "Symbol",
      enableSorting: true,
      size: 220,
      cell: React.memo(
        ({
          row: {
            original: { symbol, exchange, asset_class, defi_data },
          },
        }: {
          row: Row<PlatformOrder>;
        }) => {
          if (defi_data) {
            return <>{symbol}</>;
          }

          return (
            <>{`${getOrderSymbolLabel(
              {
                symbol: symbol,
                exchange: CEFI_EXCHANGE_NAME[exchange],
                market_type: asset_class,
              },
              ["symbol"]
            )} - ${getExchangeNameLabel(CEFI_EXCHANGE_NAME[exchange])} - ${asset_class}`}</>
          );
        },
        (prev, next) => prev.row.original.order_id === next.row.original.order_id
      ),
    }),
    columnHelper.accessor("account_uuid", {
      id: "account_uuid",
      header: "Team",
      size: 90,
      enableSorting: false,
      cell: React.memo(
        ({ row }: { row: Row<PlatformOrder> }) => <AccountName id={row.original.account_uuid} />,
        (prev, next) => prev.row.original.account_uuid === next.row.original.account_uuid
      ),
    }),
    columnHelper.accessor("subaccount", {
      id: "account",
      header: "Account",
      size: 90,
      enableSorting: true,
      cell: React.memo(
        ({ row }: { row: Row<PlatformOrder> }) => (
          <OrdersTableAccount label={row.original.subaccount || "Main"} fontSize={CELL_FONT_SIZE} />
        ),
        (prev, next) => prev.row.original.subaccount === next.row.original.subaccount
      ),
    }),
    columnHelper.accessor("source", {
      id: "source",
      header: "Source",
      size: 70,
      enableSorting: true,
    }),
    columnHelper.accessor("strategy", {
      id: "strategy",
      header: "Strategy",
      size: 70,
      enableSorting: true,
      cell: ({ row }) => formatStrategyLabel(row.original.strategy).toUpperCase(),
    }),
    columnHelper.accessor("side", {
      id: "side",
      header: "Side",
      size: 60,
      enableSorting: true,
      cell: React.memo(
        ({ row }: { row: Row<PlatformOrder> }) => <OrderTableSide side={row.original.side} />,
        (prev, next) => prev.row.original.side === next.row.original.side
      ),
    }),
    ...(!isSaved
      ? [
          columnHelper.display({
            id: "quantity",
            header: "Quantity",
            size: 140,
            cell: ({ row }) => `${row.original.last_status?.filled_quantity || 0} / ${row.original.quantity || 0}`,
          }),
          columnHelper.accessor("last_status", {
            id: "progress",
            header: "Progress",
            size: 150,
            cell: React.memo(
              ({ row }: { row: Row<PlatformOrder> }) =>
                [OrderExecutionStrategy.LIMIT, OrderExecutionStrategy.ORDER].includes(row.original.strategy) ? (
                  <>-</>
                ) : (
                  <QuantityLinearProgress
                    width={90}
                    executedQuantity={row.original.last_status?.filled_quantity || 0}
                    targetQuantity={row.original.quantity}
                    sx={{ marginRight: 1 }}
                    quantity={
                      (row.original.last_status?.filled_quantity || 0) +
                      (row.original.last_status?.leaves_quantity || 0)
                    }
                  />
                ),
              (prev, next) =>
                prev.row.original.last_status?.filled_quantity === next.row.original.last_status?.filled_quantity &&
                prev.row.original.quantity === next.row.original.quantity &&
                prev.row.original.last_status?.leaves_quantity === next.row.original.last_status?.leaves_quantity
            ),
            sortingFn: (rowA, rowB) => {
              const a = Math.round(
                ((rowA.original?.last_status?.filled_quantity || 0) * 100) / rowA.original?.quantity
              );
              const b = Math.round(
                ((rowB.original?.last_status?.filled_quantity || 0) * 100) / rowB.original?.quantity
              );
              return sort(a, b);
            },
          }),
        ]
      : [
          columnHelper.accessor("quantity", {
            id: "quantity",
            header: "Quantity",
            size: 100,
          }),
        ]),
    ...(!isSaved
      ? [
          columnHelper.display({
            id: "avg",
            header: "Avg",
            size: 110,
            cell: ({ row }) =>
              row.original?.last_status?.average_price
                ? formatAmount(roundAmount(String(row.original.last_status?.average_price), 3))
                : "-",
          }),
        ]
      : []),
    ...(isTrigger || isSaved
      ? [
          columnHelper.display({
            id: "triggerPrice",
            size: 140,
            header: ({ table }) => {
              const { id, desc } = table.getState().sorting[0] || {};

              const isAbove = id === "triggerPriceAbove";
              const isBelow = id === "triggerPriceBelow";

              return (
                <Stack direction="row" alignItems="center" flexWrap="nowrap">
                  Trigger (<Above>Above</Above>
                  {isAbove && renderSortArrows(desc)}
                  {" / "}
                  <Below>Below</Below>
                  {isBelow && renderSortArrows(desc)})
                </Stack>
              );
            },
            cell: ({ row }) => (
              <Typography
                variant="caption"
                sx={{
                  color:
                    row.original.params?.trigger_condition === "below"
                      ? "#C3462B"
                      : row.original.params?.trigger_condition === "above"
                      ? "#179B83"
                      : undefined,
                }}
              >
                {row.original.params?.trigger_price ? formatPrice(row.original.params.trigger_price) : "-"}
              </Typography>
            ),
          }),
        ]
      : []),
    ...(!isSaved
      ? [
          columnHelper.accessor("start_time", {
            id: "start-time",
            header: "Start time",
            size: 100,
            cell: ({ row }) =>
              row.original.start_time && format(new Date(row.original.start_time * 1000), END_TIME_FORMAT),
            sortingFn: (rowA, rowB) => {
              const a = new Date(rowA.original?.start_time).valueOf();
              const b = new Date(rowB.original?.start_time).valueOf();
              return sort(a, b);
            },
          }),
        ]
      : []),
    ...(isRegular
      ? [
          columnHelper.accessor("end_time", {
            id: "est-end-time",
            header: "Est. end time",
            size: 100,
            cell: ({ row }) => {
              const order = row.original;
              const endTime = order?.end_time || 0;
              const durationSeconds = order?.duration_seconds || 0;

              if (
                !endTime ||
                !durationSeconds ||
                ![ParentOrderStatuses.ACCEPTED, ParentOrderStatuses.PARTIALLY_FILLED].includes(
                  order?.last_status?.status
                )
              ) {
                return "-";
              }

              const endTs = order.start_time + durationSeconds * 1000;

              return <IntervalDuration endTs={endTs} />;
            },
            sortingFn: (rowA, rowB) => {
              const a = new Date(rowA.original?.end_time).valueOf();
              const b = new Date(rowB.original?.end_time).valueOf();
              return sort(a, b);
            },
          }),
        ]
      : []),
    ...(isHistory
      ? [
          columnHelper.accessor("end_time", {
            id: "end-time",
            header: "End time",
            size: 100,
            cell: ({ row }) => (row.original.end_time ? format(new Date(row.original.end_time), END_TIME_FORMAT) : ""),
            sortingFn: (rowA, rowB) => {
              const a = new Date(rowA.original?.end_time).valueOf();
              const b = new Date(rowB.original?.end_time).valueOf();
              return sort(a, b);
            },
          }),
        ]
      : []),
    ...(!isHistory
      ? [
          columnHelper.display({
            id: "live-price",
            header: "Live price",
            size: 100,
            cell: React.memo(
              ({ row }: { row: Row<PlatformOrder> }) => (
                <LivePrice
                  fontSize={CELL_FONT_SIZE}
                  key={`${row.original?.symbol}${row.original?.exchange}${row.original?.asset_class}`}
                  symbol={row.original?.symbol || ""}
                  exchange={row.original?.exchange || ""}
                  marketType={row.original?.asset_class || ""}
                />
              ),
              (prev, next) => prev.row.original.order_id === next.row.original.order_id
            ),
            sortingFn: (rowA, rowB) => {
              const prices = store.getState()?.subscriptions?.livePrice;
              const a =
                prices[
                  getLivePriceId({
                    symbol: rowA.original?.symbol,
                    exchange: rowA.original?.exchange,
                    market_type: rowA.original?.asset_class,
                  })
                ]?.price || 0;
              const b =
                prices[
                  getLivePriceId({
                    symbol: rowB.original?.symbol,
                    exchange: rowB.original?.exchange,
                    market_type: rowB.original?.asset_class,
                  })
                ]?.price || 0;

              return sort(a, b);
            },
          }),
        ]
      : []),
    ...(!isSaved
      ? [
          columnHelper.accessor("last_status", {
            id: "status",
            header: "Status",
            size: 120,
            cell: ({ row }) => <ParentOrderStatus<PlatformOrder> order={row.original} fontSize={CELL_FONT_SIZE} />,
            sortingFn: (rowA, rowB) => {
              const a = getParentOrderStatus(
                rowA.original.last_status?.status,
                rowA.original.quantity,
                rowA.original.last_status?.filled_quantity,
                rowA.original.last_status?.error_code
              );

              const b = getParentOrderStatus(
                rowB.original.last_status?.status,
                rowB.original.quantity,
                rowB.original.last_status?.filled_quantity,
                rowB.original.last_status?.error_code
              );

              return sort(a, b);
            },
          }),
        ]
      : []),
    ...(isSaved
      ? [
          columnHelper.accessor("created_at", {
            id: "created-at",
            header: "Created at",
            size: 100,
            cell: ({ row }) => row.original?.created_at && format(new Date(row.original?.created_at), END_TIME_FORMAT),
            sortingFn: (rowA, rowB) => {
              const a = rowA.original?.created_at ? new Date(rowA.original?.created_at).valueOf() : 0;
              const b = rowB.original?.created_at ? new Date(rowB.original?.created_at).valueOf() : 0;
              return sort(a, b);
            },
          }),
        ]
      : []),
  ];
};
