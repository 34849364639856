import React from "react";
import { styled, Dialog, DialogContent, Typography, IconButton, Stack, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

type CheckAllowanceDialogProps = {
  onClose: () => void;
  handleUnlock: (permanently?: boolean) => void;
  token: string;
  amountToApprove: string;
};

export const CheckAllowanceDialog = ({ onClose, handleUnlock, token, amountToApprove }: CheckAllowanceDialogProps) => {
  const theme = useTheme();

  return (
    <Dialog open onClose={onClose}>
      <DialogContent sx={{ width: 440, pt: 2 }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
        <Stack alignItems="center" sx={{ pb: 5 }}>
          <Icon alignItems="center" justifyContent="center">
            <LockOpenIcon sx={{ color: theme.custom.colors.secondary, width: 35, height: 35 }} />
          </Icon>
          <Typography variant="h6" sx={{ mt: 3 }}>
            Unlock {token} transfer
          </Typography>
          <Typography sx={{ textAlign: "center", mt: 2 }}>
            Our smart contract needs your permission in order to move <b>{amountToApprove}</b> on your behalf.
          </Typography>
          <AnbotoButton sx={{ width: 215, mt: 5 }} variant="contained" onClick={() => handleUnlock(true)}>
            Unlock permanently
          </AnbotoButton>
          <AnbotoButton sx={{ width: 215, mt: 3 }} variant="contained" onClick={() => handleUnlock()}>
            Unlock this time only
          </AnbotoButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const Icon = styled(Stack)(({ theme }) => ({
  border: `2px solid ${theme.custom.colors.secondary}`,
  width: 80,
  height: 80,
  borderRadius: 50,
}));
