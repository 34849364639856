import React from "react";
import { styled } from "@mui/material";
import { OrdersType } from "../types";
import { AMOUNT_OPACITY, amountStyles } from "../constants";

const StyledPrice = styled("div")<{ type?: OrdersType; isHorizontal?: boolean }>(({ theme, type }) => ({
  ...amountStyles,
  flex: 1,
  color: type === "ask" ? theme.palette.error.main : theme.custom.colors.secondary,
  opacity: AMOUNT_OPACITY,
}));

export const Price = React.memo(StyledPrice);
