import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type ClipSizeValueFieldProps = ControllerRenderProps & AnbotoTextFieldProps;

export const ClipSizeValueField = forwardRef((props: ClipSizeValueFieldProps, ref) => (
  <AnbotoTextField {...props} inputRef={ref} size="small" fullWidth type="number" />
));

ClipSizeValueField.displayName = "ClipSizeValueField";
