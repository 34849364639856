import GridLayout from "react-grid-layout";
import _cloneDeep from "lodash/cloneDeep";
import { WidgetLayoutBreakpoints, WidgetName } from "../types";
import { CONFIGS } from "../widgets/configs";

export const getLayout = (
  widgetName: WidgetName,
  layoutOverride?: Record<WidgetLayoutBreakpoints, Partial<GridLayout.Layout>>
) => {
  const layout = _cloneDeep(CONFIGS[widgetName].layout);

  if (layoutOverride) {
    Object.keys(layoutOverride).forEach((bp) => {
      const override = layoutOverride[bp];

      layout[bp] = { ...layout[bp], ...override };
    });
  }

  return layout;
};
