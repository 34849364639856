import { useEffect, useMemo, useState } from "react";
import format from "date-fns/format";
import { CHAIN_NAME } from "@src/pages/defi/constants";
import { formatTokenAmount } from "@src/utils/format";
import { END_TIME_FORMAT } from "@src/pages/cefi/constants";
import { ParentDefiOrder } from "@src/store/apis/anbotoApi/types";

export interface UseDefiTableSearchResult {
  filteredOrders: Record<string, boolean>;
  setSearchString: (searchString: string) => void;
  searchString: string;
}

export const useDefiTableSearch = (results: ParentDefiOrder[] = [], wallets): UseDefiTableSearchResult => {
  const [searchString, setSearchString] = useState<string>("");
  const [filteredOrders, setFilteredOrders] = useState<Record<string, boolean>>({});

  const searchIndexes = useMemo(
    () =>
      results?.map(
        ({
          defi_data,
          order_id,
          symbol,
          network_id,
          aggregator,
          strategy,
          last_status,
          quantity,
          created_at,
          end_time,
        }) => ({
          id: order_id,
          index: `${symbol}
            ${CHAIN_NAME[network_id]}
            ${defi_data?.maker_address ? wallets[defi_data?.maker_address] || defi_data?.maker_address : ""} +
            ${aggregator}
            ${strategy}
            ${last_status?.filled_quantity ? last_status.filled_quantity : ""}
            ${
              last_status?.average_price
                ? formatTokenAmount(last_status?.average_price, { avg: true, asset: symbol })
                : ""
            }
            ${last_status?.status ? last_status.status : ""}
            ${quantity}
            ${created_at ? format(new Date(created_at), END_TIME_FORMAT) : ""}
            ${end_time ? format(new Date(end_time), END_TIME_FORMAT) : ""}
          `.toLowerCase(),
        })
      ) || [],
    [results, wallets]
  );

  useEffect(() => {
    const res = searchIndexes.reduce((prev, { id, index }) => {
      if (id && searchString.split(" ").every((word) => index.includes(word))) {
        prev[id] = true;
      }

      return prev;
    }, {});

    setFilteredOrders(res);
  }, [searchString, searchIndexes]);

  return {
    filteredOrders,
    setSearchString,
    searchString,
  };
};
