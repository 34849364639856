import React, { useMemo } from "react";
import { Grid, Link, Stack, styled, Typography, useTheme } from "@mui/material";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { SettingsThumb } from "../settings-thumb";
import { ExchangeIps } from "../exchange-ips";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { CefiExchangeId, Exchange, ExchangeApiError } from "@src/store/apis/anbotoApi/types";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";
import { ExchangeAvatar } from "@src/components/exchange-avatar/exchange-avatar";
import { exchanges } from "@src/features/exchanges";
import { usePermissions } from "@src/hooks/use-permissions";

type ExchangesSettingsProps = {
  onEdit: (exchange: Exchange | null) => void;
  onDelete: (exchange: Exchange | null) => void;
  blackList?: CefiExchangeId[];
  whiteList?: CefiExchangeId[];
};

const EXCHANGES_SINGUP_LINKS: Record<string, Record<"phrase" | "link", string>> = {
  binance: { phrase: "", link: "" },
  woo: { phrase: "Sign up - get 25% OFF", link: "https://referral.woo.org/SUZ6knjE6FXAH1EQA" },
  bitmex: { phrase: "Sign up - get 10% OFF", link: "https://www.bitmex.com/app/register/81UILn" },
  bybit: { phrase: "Sign up", link: "https://www.bybit.com/invite?ref=MM1GJA" },
  bitget: {
    phrase: "Sign up - get 20% OFF",
    link: "https://www.bitget.com/en/referral/register?from=referral&clacCode=0RFL677X",
  },
  bitfinex: { phrase: "", link: "https://www.bitfinex.com/sign-up?refcode=11DZwvSXC" },
  ascendex: { phrase: "", link: "" },
  okx: { phrase: "Sign up", link: "https://www.okx.com/join/58421455" },
  kucoin: { phrase: "Sign up - get 20% OFF", link: "https://www.kucoin.com/r/rf/QBS9CAN4" },
  bitrue: { phrase: "", link: "" },
  kraken: { phrase: "", link: "" },
  coinbasepro: { phrase: "", link: "" },
  huobi: { phrase: "", link: "" },
  gateio: { phrase: "Sign up - get 30% OFF", link: "https://www.gate.io/signup/XgURVl0K?ref_type=103" },
};

// "Referral code: MM1GJA (Receive a mystery gift from Bybit)
// Referral link: https://www.bybit.com/invite?ref=MM1GJA"
// "Referral code: 81UILN (10% fee discount for 6 months)
// Referral link: https://www.bitmex.com/app/register/81UILn "
// "Referral Code: 58421455 (Receive a mystery gift from OKX)
// Referral link: https://www.okx.com/join/58421455"
// "Referral code: 0RFL677X
// Referral link: https://www.bitget.com/en/referral/register?from=referral&clacCode=0RFL677X"
// "Referral Code: NMGAMYGD (Receives 25% off on trading fees)
// Referral Link: https://referral.woo.org/SUZ6knjE6FXAH1EQA"
// different codes for spot and futures
// "Referral code: 11DZwvSXC
// Referral link: https://www.bitfinex.com/sign-up?refcode=11DZwvSXC"
// "Referral Code: QBS9CAN4 (Receives 20% cashback)
// Referral Link: https://www.kucoin.com/r/rf/QBS9CAN4"

export const ExchangesSettings = ({ onEdit, onDelete, blackList, whiteList }: ExchangesSettingsProps) => {
  const theme = useTheme();
  const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);

  const getExchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi: false });
  const getExchangeConfigsQuery = anbotoApi.useGetExchangeConfigsQuery(getUserDetailsQuery.data?.team_uuid || null, {
    skip: !getUserDetailsQuery.data,
  });
  const permissions = usePermissions();

  const exchangesExtraData = useMemo<Record<string, { main: number; sub: number; error: ExchangeApiError }>>(
    () =>
      getExchangeConfigsQuery.data?.results?.reduce((exchange, { exchange_id, subaccount_title }) => {
        if (!exchange[exchange_id]) {
          exchange[exchange_id] = {};
        }
        if (subaccount_title) {
          exchange[exchange_id].sub = exchange[exchange_id]?.sub ? exchange[exchange_id]?.sub + 1 : 1;
        } else {
          exchange[exchange_id].main = exchange[exchange_id]?.main ? exchange[exchange_id]?.main + 1 : 1;
        }

        return exchange;
      }, {}) || {},
    [getExchangeConfigsQuery.data]
  );

  const fetchedExchanges =
    getExchangesQuery.data?.results.filter((exchange) => {
      if (whiteList?.length) {
        return whiteList.includes(exchange.exchange_id);
      }

      if (blackList?.length) {
        return !blackList.includes(exchange.exchange_id);
      }
    }) || [];

  return (
    <>
      <Grid container spacing={2} p={2}>
        <Grid item xs={12}>
          <Typography mt={1} color={(theme) => theme.palette.text.secondary}>
            To link your exchange add your API keys.
            <StyledLink href="https://anboto-labs.gitbook.io/trading-guide/hKS10xjDjJRgHwJYzcVr/setup/connect-cex-api">
              Learn how to.
            </StyledLink>
          </Typography>
        </Grid>

        {fetchedExchanges.map((exchange) => {
          const mainAccountsTotal = exchangesExtraData[exchange.exchange_id]?.main || 0;
          const subAccountsTotal = exchangesExtraData[exchange.exchange_id]?.sub || 0;
          const signUpPhrase = EXCHANGES_SINGUP_LINKS[exchange.exchange_id]?.phrase || null;
          const signUpLink = EXCHANGES_SINGUP_LINKS[exchange.exchange_id]?.link || null;

          return (
            <Grid item xs={4} key={exchange.exchange_id}>
              <SettingsThumb
                key={exchange.exchange_id}
                sx={{ cursor: !!mainAccountsTotal || !!subAccountsTotal ? "pointer" : "default" }}
                variant={!!mainAccountsTotal || !!subAccountsTotal ? "solid" : "contained"}
                name={getExchangeNameLabel(exchange.name)}
                onEdit={permissions.trade ? () => onEdit(exchange) : undefined}
                onClick={
                  permissions.trade ? () => (!!mainAccountsTotal || !!subAccountsTotal) && onEdit(exchange) : undefined
                }
                onRemove={
                  permissions.trade && (mainAccountsTotal || subAccountsTotal) > 0
                    ? () => onDelete(exchange)
                    : undefined
                }
                namePreffix={<ExchangeAvatar exchange={exchange.exchange_id} size={24} />}
                items={[
                  {
                    label: "Main",
                    value: mainAccountsTotal,
                    visible: !!mainAccountsTotal || !!subAccountsTotal,
                  },
                  {
                    label: "Sub",
                    value: subAccountsTotal,
                    visible: !!subAccountsTotal,
                  },
                ]}
                postfix={
                  <Stack>
                    {!!mainAccountsTotal || !!subAccountsTotal
                      ? null
                      : permissions.trade && (
                          <AnbotoButton
                            variant="outlined"
                            sx={{ mt: 1, border: `1px solid ${theme.custom.border.default}` }}
                            onClick={() => onEdit(exchange)}
                            size="small"
                          >
                            Link account
                          </AnbotoButton>
                        )}
                    {signUpPhrase && signUpLink && (
                      <AnbotoButton
                        variant="outlined"
                        sx={{ mt: 1, border: `1px solid ${theme.custom.border.default}` }}
                        size="small"
                        onClick={() => {
                          window.open(signUpLink, "_blank");
                        }}
                      >
                        {signUpPhrase} <LoyaltyIcon sx={{ width: 16, ml: 1 }} />
                      </AnbotoButton>
                    )}
                  </Stack>
                }
                tags={exchanges[exchange.exchange_id]?.marketType.map((marketType) => marketType)}
              />
            </Grid>
          );
        })}
      </Grid>
      <ExchangeIps mt={7} ml={-3} mr={-3} exchange={CefiExchangeId.BINANCE} />
    </>
  );
};

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.custom.text.dim,
  textDecorationColor: theme.custom.text.dim,
  marginLeft: 3,
}));
