import { CefiAllocation, DefiAllocation, DefiAllocationWithIcon, FutureAssets } from "@src/store/apis/anbotoApi/types";
import { getKey } from "@src/pages/portfolio/utils/helpers";
import { Balances } from "@src/pages/portfolio";

const SMALL_BALANCE_VALUE = 0.001; // 0,1% of total balance

export const filterCefiDataByBalance = (hideSmallBalance: boolean, totalBalance: number, items?: CefiAllocation[]) => {
  if (!hideSmallBalance) {
    return items?.filter((item) => item.value);
  }

  return items?.filter((item) => Math.abs(item.value / totalBalance) > SMALL_BALANCE_VALUE);
};

export const filterDefiDataByBalance = (hideSmallBalance: boolean, totalBalance: number, items?: DefiAllocation[]) => {
  if (!hideSmallBalance) {
    return items;
  }

  return items?.filter((item) => Math.abs(item.value / totalBalance) > SMALL_BALANCE_VALUE);
};

export const filterCefiAllocations = (
  filter: { [key: string]: string | boolean | null },
  items?: CefiAllocation[]
): FutureAssets => {
  const filteredData = items?.filter((item) => Object.keys(filter).every((key) => item[key] === filter[key])) || [];

  const balances: Balances = filteredData?.reduce(
    (result: Balances, { market_type, value, is_position }) => {
      market_type === "future"
        ? is_position
          ? (result.position += Math.abs(value))
          : (result.future += Math.abs(value))
        : (result.spot += Math.abs(value));
      return result;
    },
    { future: 0, position: 0, spot: 0 }
  ) || { future: 0, position: 0, spot: 0 };

  if (filter["market_type"] === "future") {
    return (
      filteredData?.reduce(
        (result: FutureAssets, item: CefiAllocation) => {
          if (item.is_position) {
            result.positions!.push({ ...item, portion: Math.abs((item.value * 100) / balances.position) });
          } else {
            result.margin.push({ ...item, portion: Math.abs((item.value * 100) / balances.future) });
          }
          return result;
        },
        { positions: [], margin: [], balances }
      ) || { positions: [], margin: [], balances }
    );
  } else {
    return {
      balances,
      margin: filteredData.map((x) => {
        return { ...x, portion: Math.abs((x.value * 100) / balances.spot) };
      }),
    };
  }
};

export const filterDefiAllocations = (filter: { [key: string]: string | null }, items?: DefiAllocation[]) =>
  items?.filter((item) => Object.keys(filter).every((key) => item[key] === filter[key]));

export const mergeCefiAllocations = (items?: CefiAllocation[]): CefiAllocation[] => {
  const res = {};
  items?.forEach(
    (a) =>
      (res[a.token_symbol] = {
        ...a,
        balance: res[a.token_symbol] ? res[a.token_symbol].balance + a.balance : a.balance,
        portion: res[a.token_symbol] ? res[a.token_symbol].portion + a.portion : a.portion,
        value: res[a.token_symbol] ? res[a.token_symbol].value + a.value : a.value,
        expandedInfo: a.expandedInfo ? [...a.expandedInfo] : undefined,
      })
  );
  return Object.values(res) as CefiAllocation[];
};

export const mergeDefiAllocations = (
  items?: DefiAllocation[] | DefiAllocationWithIcon[]
): DefiAllocation[] | DefiAllocationWithIcon[] => {
  const res = {};
  items?.forEach((a) => {
    const key = getKey(a);
    res[key] = {
      ...a,
      balance: res[key] ? res[key].balance + a.balance : a.balance,
      portion: res[key] ? res[key].portion + a.portion : a.portion,
      value: res[key] ? res[key].value + a.value : a.value,
      expandedInfo: a.expandedInfo ? [...a.expandedInfo] : undefined,
    };
  });
  return Object.values(res);
};
