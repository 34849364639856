import React, { forwardRef } from "react";
import { MenuItem } from "@mui/material";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { OrderType } from "@src/store/apis/anbotoApi/types";
import { ORDER_TYPE_NAME } from "@src/pages/defi/constants";

export const TYPES: OrderType[] = [OrderType.MARKET, OrderType.LIMIT];
type TypeField = ControllerRenderProps & AnbotoTextFieldProps;

export const TypeField = forwardRef((props: TypeField, ref) => {
  return (
    <AnbotoTextField {...props} fullWidth inputRef={ref} size="small" select label="Order by">
      {TYPES.map((x) => (
        <MenuItem key={x} value={x}>
          {ORDER_TYPE_NAME[x]}
        </MenuItem>
      ))}
    </AnbotoTextField>
  );
});
TypeField.displayName = "TypeField";
