import React from "react";
import { styled } from "@mui/material";
import { TOOLBAR_HEIGHT } from "../constants";

export const Toolbar = ({ children }: React.PropsWithChildren<{}>) => <StyledToolbar>{children}</StyledToolbar>;

const StyledToolbar = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  height: TOOLBAR_HEIGHT,
}));
