import React from "react";
import { Button, ButtonProps, Typography, styled, Stack } from "@mui/material";
import { formatPrice } from "./utils";

export const SellBuy = ({
  side,
  price,
  pricePrecision,
  slippage,
  onClick,
  ...props
}: {
  side: "sell" | "buy";
  price: number;
  slippage: number;
  onClick: () => void;
  pricePrecision: number;
} & ButtonProps) => {
  const slippageAbs = price * (slippage / 10000);

  return (
    <StyledButton side={side} fullWidth {...props} onClick={() => onClick()}>
      <Stack alignItems="center" justifyContent="center">
        <Typography
          variant="body2"
          fontSize={12}
          lineHeight={1}
          sx={{
            color: side === "buy" ? "#179B83" : "#C3462B",
            left: side === "buy" ? 40 : undefined,
            right: side === "sell" ? 40 : undefined,
            top: 3,
          }}
        >
          {side}
        </Typography>
        {!!price && (
          <Typography variant="body2" fontWeight="bold" fontSize={19}>
            {formatPrice(price, pricePrecision)}
          </Typography>
        )}
        {!!slippage && !!price && (
          <>
            <SlippageLabel side={side} top={2}>
              {formatPrice(price + slippageAbs, pricePrecision)}
            </SlippageLabel>
            <SlippageLabel side={side} bottom={2}>
              {formatPrice(price - slippageAbs, pricePrecision)}
            </SlippageLabel>
          </>
        )}
      </Stack>
    </StyledButton>
  );
};

const StyledButton = styled(Button)<{ side: "sell" | "buy" }>(({ side }) => ({
  color: side === "buy" ? "#179B83" : "#C3462B",
  borderColor: side === "buy" ? "#179B83" : "#C3462B",
  background: side === "buy" ? "#011E1C" : "#100601",
  borderStyle: "solid",
  borderWidth: 1,
  borderRadius: 4,
  justifyContent: "center",
  textAlign: side === "buy" ? "right" : "left",
  borderBottomRightRadius: side === "sell" ? 0 : 4,
  borderBottomLeftRadius: side === "buy" ? 0 : 4,
  height: 80,
}));

const SlippageLabel = styled(Typography)<{ side: "sell" | "buy" }>(({ side }) => ({
  color: side === "sell" ? "#C3462B" : "#179B83",
  fontSize: 10,
  position: "absolute",
  left: side === "sell" ? 4 : undefined,
  right: side === "buy" ? 4 : undefined,
  opacity: 0.8,
}));
