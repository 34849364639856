import React from "react";
import { Stack, Tooltip } from "@mui/material";
import ascendexUrl from "./icons/ascendex.png";
import binanceUrl from "./icons/binance.png";
import bybitUrl from "./icons/bybit.png";
import bitmexUrl from "./icons/bitmex.png";
import coinbaseUrl from "./icons/coinbase.png";
import gateioUrl from "./icons/gateio.png";
import huobiUrl from "./icons/huobi.png";
import krakenUrl from "./icons/kraken.png";
import kucoinUrl from "./icons/kucoin.png";
import okxUrl from "./icons/okx.png";
import wooxUrl from "./icons/woox.png";
import bitGetUrl from "./icons/bitget.png";
import mexcUrl from "./icons/mexc.png";
import { OrderSymbol, CefiExchangeId } from "@src/store/apis/anbotoApi/types";
import { prepareExchange } from "@src/pages/cefi/order-symbol-chart/anboto-datafeed/helpers";

const getLogoStyles = (exchange: string, size: number) => {
  let styles: Record<string, string | number> = {
    height: size,
    maxWidth: size,
  };
  if (exchange === CefiExchangeId.OKX) {
    styles = {
      ...styles,
      height: size - 4,
    };
  }
  return styles;
};

export const LOGO_URLS = {
  [CefiExchangeId.ASCENDEX]: ascendexUrl,
  [CefiExchangeId.BINANCE]: binanceUrl,
  [CefiExchangeId.BYBIT]: bybitUrl,
  [CefiExchangeId.BITMEX]: bitmexUrl,
  [CefiExchangeId.COINBASE]: coinbaseUrl,
  [CefiExchangeId.GATEIO]: gateioUrl,
  [CefiExchangeId.HUOBI]: huobiUrl,
  [CefiExchangeId.KRAKEN]: krakenUrl,
  [CefiExchangeId.KUCOIN]: kucoinUrl,
  [CefiExchangeId.OKX]: okxUrl,
  [CefiExchangeId.WOOX]: wooxUrl,
  [CefiExchangeId.BITGET]: bitGetUrl,
  [CefiExchangeId.MEXC]: mexcUrl,
};

export const LogoImage = ({ exchange, style }: { exchange: string; style: React.CSSProperties }) => {
  const url = LOGO_URLS[prepareExchange(exchange)];

  return url ? <img src={url} title={exchange} style={style} /> : null;
};

export const ExchangeIcon = ({ symbol, size }: { symbol: OrderSymbol; size: number }) => {
  return (
    <Tooltip title={symbol.exchange}>
      <Stack alignItems="center" justifyContent="center" sx={{ width: size, height: size }}>
        {symbol.exchange && (
          <LogoImage exchange={symbol.exchange} style={{ ...getLogoStyles(symbol.exchange, size) }} />
        )}
      </Stack>
    </Tooltip>
  );
};
