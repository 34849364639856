import React from "react";
import { styled, Tooltip, tooltipClasses, TooltipProps, Box } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Instance } from "@popperjs/core";
import { AggregatedInfo } from "./aggregated-info";

export const AggregatedInfoTooltip = ({ children }: React.PropsWithChildren<{}>) => {
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef<Instance>(null);
  const areaRef = React.useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current !== null) {
      popperRef.current.update();
    }
  };

  return (
    <StyledTooltip
      title={<AggregatedInfo />}
      placement="right"
      TransitionProps={{ timeout: 0 }}
      arrow
      PopperProps={{
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              areaRef.current!.getBoundingClientRect().x,
              positionRef.current.y,
              areaRef.current?.clientWidth,
              0
            );
          },
        },
      }}
    >
      <Box ref={areaRef} onMouseMove={handleMouseMove}>
        {children}
      </Box>
    </StyledTooltip>
  );
};

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "8px 16px",
    fontSize: 12,
    background: "rgba(97, 97, 97, 1)",
  },
});
