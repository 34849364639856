import { Stack, styled, TableCell, tableCellClasses, Typography } from "@mui/material";

export const FieldLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 14,
}));

export const FieldValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 14,
  fontWeight: 600,
}));

export const DashboardDataCard = styled(Stack)(({ theme }) => ({
  flex: 1,
  color: theme.palette.text.secondary,
  background: theme.palette.background.default,
  padding: 16,
  flexGrow: 1,
  "& h6": {
    fontSize: 16,
  },
}));

export const DashBoardTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    borderBottom: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: 0,
  },
  paddingLeft: 0,
  paddingRight: 0,
}));

export const DashBoardTableHeaderCell = styled(TableCell)(() => ({
  fontSize: 14,
  color: "#8A9296",
  fontWeight: 400,
  paddingLeft: 0,
  paddingRight: 0,
}));
