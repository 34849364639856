import React from "react";
import { FormControl, MenuItem, Stack } from "@mui/material";
import { TableSearch } from "../table-search";
import { withLightFlatStyle } from "../with-flat-style";
import { OtherPairsHidden } from "./other-pairs-hidden";
import { DefaultStatusFilter, FilterStatusValue, OrdersTableFiltersProps } from "./types";
import { AnbotoButtonGroup, AnbotoButtonGroupOption } from "@src/components/ui/anboto-button-group";
import { GetOrdersPeriod, OrderStatuses, ParentOrderStatuses } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { CEFI_ORDER_STATUS_LABEL, PARENT_ORDER_STATUS_LABEL } from "@src/pages/cefi/constants";

export const periodFilterOptions: AnbotoButtonGroupOption<GetOrdersPeriod>[] = [
  { label: "1d", value: GetOrdersPeriod.Day },
  { label: "1wk", value: GetOrdersPeriod.Week },
  { label: "1mth", value: GetOrdersPeriod.Month },
  { label: "3mths", value: GetOrdersPeriod.ThreeMonths },
  { label: "All", value: GetOrdersPeriod.All },
];

export const statusFilterOptions: OrderStatuses[] = [
  OrderStatuses.WAITING,
  OrderStatuses.RUNNING,
  OrderStatuses.PAUSED,
  OrderStatuses.FINISHED,
  OrderStatuses.CANCELED,
  OrderStatuses.INSUFFICIENT_FUNDS,
  OrderStatuses.ERROR,
];

export const parentStatusFilterOptions: ParentOrderStatuses[] = [
  ParentOrderStatuses.PENDING_NEW,
  ParentOrderStatuses.ACCEPTED,
  ParentOrderStatuses.REJECTED,
  ParentOrderStatuses.PARTIALLY_FILLED,
  ParentOrderStatuses.FILLED,
  ParentOrderStatuses.PENDING_CANCEL,
  ParentOrderStatuses.CANCELLED,
  ParentOrderStatuses.PENDING_PAUSE,
  ParentOrderStatuses.PAUSED,
];

export function OrdersTableFilters<T>({ search, period, status, otherPairsHidden }: OrdersTableFiltersProps<T>) {
  const handleSearch = (value: string) => search?.onSearch(value?.toLowerCase().trim());
  const handleStatusChange = (e) =>
    status?.onChange(
      e.target.value !== DefaultStatusFilter.All ? (e.target.value as FilterStatusValue<T>) : DefaultStatusFilter.All
    );

  const statusOptions = status?.parentOrder ? parentStatusFilterOptions : statusFilterOptions;
  const statusLabels = status?.parentOrder ? PARENT_ORDER_STATUS_LABEL : CEFI_ORDER_STATUS_LABEL;

  return (
    <Stack gap={1} direction="row" alignItems="center">
      {status && (
        <>
          <FormControl size="small" sx={{ width: 130 }}>
            <StyledAnbotoTextField size="small" select value={status.value} onChange={handleStatusChange}>
              <MenuItem value={DefaultStatusFilter.All}>All</MenuItem>
              {statusOptions?.map((status: OrderStatuses | ParentOrderStatuses) => (
                <MenuItem key={status} value={status}>
                  {statusLabels[status]}
                </MenuItem>
              ))}
            </StyledAnbotoTextField>
          </FormControl>
        </>
      )}
      {otherPairsHidden && <OtherPairsHidden />}
      {search && <TableSearch onSearch={handleSearch} placeholder="Symbol / strategy / status..." />}
      {period && (
        <AnbotoButtonGroup<GetOrdersPeriod>
          size="small"
          options={periodFilterOptions}
          defaultValue={GetOrdersPeriod.All}
          onChange={period.onChange}
        />
      )}
    </Stack>
  );
}

const StyledAnbotoTextField = withLightFlatStyle(AnbotoTextField);
