import React from "react";
import { Stack, styled } from "@mui/material";
import {
  FieldLabel,
  FieldValue,
} from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/styled-components";
import { formatBps } from "@src/components/post-trade-analysis/helpers";
import AnbotoInfoIcon from "@src/components/anboto-info-icon/anboto-info-icon";
import { WithLoader } from "@src/pages/metrics/components/with-loader";
import { roundAmount } from "@src/pages/portfolio/utils/helpers";

type ReversionProps = {
  volumeParticipation: number;
  reversion: number;
  spreadCapture: number;
  loading?: boolean;
};

const StatsCard = ({ volumeParticipation, reversion, spreadCapture, loading }: ReversionProps) => {
  return (
    <Stack direction="column" gap={1} flex={1}>
      <InfoPanel>
        <FieldLabel>Spread capture:</FieldLabel>
        <FieldValue variant="caption">
          <WithLoader loading={loading}>{roundAmount(spreadCapture.toString())}%</WithLoader>
        </FieldValue>
        <AnbotoInfoIcon
          iconSize={18}
          title="Percent of the order's notional amount that was executed passively (on best bid for buys, best ask for sells), i.e. without crossing the bid-ask spread"
        />
      </InfoPanel>
      <InfoPanel>
        <FieldLabel>Reversion 5 min:</FieldLabel>
        <FieldValue variant="caption">
          <WithLoader loading={loading}>{formatBps(reversion)}</WithLoader>
        </FieldValue>
        <AnbotoInfoIcon
          iconSize={18}
          title="Price change (in bps) in the 5 minutes after the last trade.  Reversion is a measure of price impact of the order, negative reversion meaning more severe price impact, and zero or positive reversion indicating no impact"
        />
      </InfoPanel>
      <InfoPanel>
        <FieldLabel>VOLUME participation:</FieldLabel>
        <FieldValue variant="caption">
          <WithLoader loading={loading}>{roundAmount(volumeParticipation.toString())} %</WithLoader>
        </FieldValue>
        <AnbotoInfoIcon
          iconSize={18}
          title="Ratio (in percent) of the parent order's executed quantity to the total volume traded in the market during the lifetime of the order. "
        />
      </InfoPanel>
    </Stack>
  );
};

export default StatsCard;

export const InfoPanel = styled(Stack)(({ theme }) => ({
  flex: 1,
  alignItems: "center",
  flexDirection: "row",
  gap: 4,
  paddingTop: 8,
  paddingBottom: 8,
  paddingLeft: 16,
  paddingRight: 16,
  background: theme.palette.background.default,
  borderRadius: 0.5,
}));
