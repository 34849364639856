import React, { useMemo } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import format from "date-fns/format";
import { CellProps, Column } from "react-table";
import { ParentOrder } from "@src/store/apis/anbotoApi/types";
import { OrdersTable } from "@src/components/orders-table";
import { ExpandedRow } from "@src/components/post-trade-analysis/post-trade-analysis/components/order-details/expanded-row";
import { getColumns } from "@src/components/post-trade-analysis/post-trade-analysis/components/order-details/get-columns";

type OrderDetailsProps = {
  order: ParentOrder;
  hide: () => void;
};

const OrderDetails = ({ order }: OrderDetailsProps) => {
  const theme = useTheme();

  const renderCell =
    (render) =>
    // eslint-disable-next-line react/display-name
    ({ row }: CellProps<ParentOrder>) =>
      render(row);

  const columns = useMemo<Array<Column<ParentOrder>>>(
    () =>
      getColumns({
        renderCell,
      }),
    [order]
  );

  const tableData = [order] as Array<ParentOrder>;

  return (
    <Stack p={2} sx={{ background: theme.palette.background.default, borderRadius: 1 }}>
      <Stack mb={1} direction="row" justifyContent="space-between">
        <Typography fontSize={16} fontWeight={600}>
          Order details
        </Typography>
        <Typography fontSize={16} color={theme.palette.text.secondary}>
          {format(order.start_time, "yyyy-MM-dd")}
        </Typography>
      </Stack>

      <Stack sx={{ "& .MuiBox-root[role=rowgroup]": { minHeight: 25 } }}>
        <OrdersTable<ParentOrder>
          columns={columns}
          data={tableData}
          expandedRowRender={(data: ParentOrder) => <ExpandedRow data={data} key={data.order_id} />}
          loading={false}
        />
      </Stack>
    </Stack>
  );
};

export default OrderDetails;
