import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";

export const ADVANCED_OPTIONS_DIALOG_WIDTH = 320;
export const ADVANCED_OPTIONS_DIALOG_HEIGHT = 308;
export const B2C2_ACCOUNT_TYPE = {
  SPOT: "SPOT",
  CFD: "CFD",
};

export const OTC_EXCHANGES = [CefiExchangeId.B2C2];

export type OTCExchangeMap = Record<typeof OTC_EXCHANGES[number], boolean>;

export const OTC_EXCHANGES_MAP = OTC_EXCHANGES.reduce<OTCExchangeMap>(
  (res, id) => ({ ...res, [id]: true }),
  {} as OTCExchangeMap
);
