import { MAX_GROUPS } from "../../constants";
import { WidgetName, WidgetConfig } from "../../types";

export const CefiOrderFormWidgetConfig = {
  name: WidgetName.CefiOrderForm,
  layout: {
    xxl: { x: 0, y: 0, w: 3, h: 22, minW: 2, minH: 19 },
    xl: { x: 0, y: 0, w: 4, h: 22, minW: 3, minH: 19 },
    lg: { x: 0, y: 0, w: 7, h: 22, minW: 5, minH: 19 },
    md: { x: 0, y: 0, w: 8, h: 22, minW: 7, minH: 19 },
    sm: { x: 0, y: 0, w: 8, h: 22, minW: 7, minH: 19 },
    xs: { x: 0, y: 0, w: 24, h: 22, minW: 24, minH: 19 },
  },
  options: {
    withGroups: true,
    maxNumber: MAX_GROUPS,
    multiple: true,
    removable: true,
    symbolSelect: true,
  },
} as WidgetConfig;
