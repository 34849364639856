import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { InfoRounded } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { ReferralItem } from "@src/pages/referral-program";
import { Code } from "@src/pages/referral-program/apis/types";
import { useAppDispatch } from "@src/store/hooks";
import { ReferralProgramTabs, setCurrentTab } from "@src/store/slices/referralProgramSlice";

type ReferralListProps = {
  codeList: Code[];
  onShareClick: (code: Code) => void;
};
export const ReferralList = ({ codeList, onShareClick }: ReferralListProps) => {
  const dispatch = useAppDispatch();
  const handleInfoIconClick = () => {
    dispatch(setCurrentTab(ReferralProgramTabs.TIER_STRUCTURE));
  };

  return (
    <TableContainer>
      <Table
        sx={{
          borderCollapse: "separate",
          borderSpacing: "0px 8px",
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow
            sx={{
              "&>th": { border: 0, fontSize: 12, color: "#8A9296", fontWeight: 400, padding: 1, paddingBottom: 0 },
            }}
          >
            <TableCell>Referral code</TableCell>
            <TableCell align="left">
              <Stack sx={{ alignItems: "center" }} direction="row" gap="0.5">
                <Typography fontSize={12}> Referral rate</Typography>
                <IconButton
                  sx={{
                    "&:hover": {
                      background: "none",
                      opacity: 0.7,
                    },
                  }}
                  onClick={handleInfoIconClick}
                >
                  <InfoRounded sx={{ width: 18, color: (theme) => theme.palette.text.secondary, cursor: "pointer" }} />
                </IconButton>
              </Stack>
            </TableCell>
            <TableCell align="left">You | Referred</TableCell>
            <TableCell align="right">Total referrals</TableCell>
            <TableCell align="right">Share</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {codeList.map((code) => (
            <ReferralItem onShareClick={onShareClick} code={code} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
