import { MAIN_ACCOUNT_VALUE } from "@src/pages/cefi/constants";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";

export const leverageApi = anbotoApi.injectEndpoints({
  endpoints: (builder) => ({
    updateLeverage: builder.mutation<
      any,
      {
        exchangeId: string;
        symbol: string;
        marketType: CefiExchangeMarketType;
        accountId: string;
        leverage: number;
      }
    >({
      invalidatesTags: ["SYMBOL_BALANCE"],
      query: ({ exchangeId, symbol, marketType, accountId, leverage }) => ({
        method: "POST",
        url: `/wallets/set_leverage_for_symbol/`,
        body: {
          exchange_id: exchangeId,
          symbol: symbol,
          market_type: marketType,
          account_uuid: accountId,
          leverage: leverage,
        },
      }),
    }),
    getAccountMarginInfos: builder.query<
      { data: { margin_ratio: number; maintenance_margin: number; account_equity: number } },
      { exchangeId: string; marketType: CefiExchangeMarketType; subaccount?: string; accountId: string }
    >({
      keepUnusedDataFor: 60,
      query: ({ exchangeId, marketType, subaccount, accountId }) => ({
        method: "GET",
        url: `/wallets/get_account_margin_infos`,
        params: {
          account_uuid: accountId,
          exchange_id: exchangeId,
          market_type: marketType,
          ...(subaccount !== MAIN_ACCOUNT_VALUE ? { subaccount } : {}),
        },
      }),
    }),
  }),
  overrideExisting: false,
});
