import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { prepareExchange } from "@src/pages/cefi/order-symbol-chart/anboto-datafeed/helpers";
import { MarketDataTickerData } from "@src/subscriptions/types";

export type PriceState = {
  price: number;
  direction: "up" | "down" | "";
  percentage?: number;
};

export interface Subscriptions {
  livePrice: Record<string, PriceState>;
  ticker: Record<string, PriceState & { data: MarketDataTickerData }>;
  ohlcv: Record<string, number[]>;
  otcOrderbook: Record<
    string,
    {
      asks: number[][];
      bids: number[][];
      minAmount: number;
      maxAmount: number;
    }
  >;
}

const initialState: Subscriptions = {
  livePrice: {},
  ticker: {},
  ohlcv: {},
  otcOrderbook: {},
};

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setLivePrice: (state, action) => {
      const { price, id } = action.payload || {};
      const oldData = state.livePrice[id];

      state.livePrice[id] = {
        price: price || oldData?.price,
        direction: price > oldData?.price ? "up" : price < oldData?.price ? "down" : oldData?.direction || "",
      };
    },
    clearLivePrice: (state) => {
      state.livePrice = {};
    },
    setTicker: (
      state,
      action: PayloadAction<{ id: string; price: number; percentage?: number; data: MarketDataTickerData }>
    ) => {
      const { id, price, percentage, data } = action.payload;
      const oldData = state.ticker[id];

      if (!state.ticker[id]) {
        state.ticker[id] = {
          price,
          percentage,
          direction: price > oldData?.price ? "up" : price < oldData?.price ? "down" : oldData?.direction || "",
          data,
        };
      } else {
        if (price !== null) state.ticker[id].price = price;
        if (percentage !== null) state.ticker[id].percentage = percentage;
        state.ticker[id].data = data;
      }
    },
    setOHLCV: (state, action) => {
      const { id, data } = action.payload;

      state.ohlcv[id] = data;
    },
    setOtcOrderbook: (state, action: PayloadAction<{ symbol: OrderSymbol; bids: number[][]; asks: number[][] }>) => {
      const { symbol, asks, bids } = action.payload;

      state.otcOrderbook[getLivePriceId(symbol)] = {
        asks,
        bids,
        minAmount: asks.length ? asks[0][1] : 0,
        maxAmount: asks.length ? asks[asks.length - 1][1] : 0,
      };
    },
  },
});

export const getLivePriceId = (orderSymbol?: OrderSymbol | null) => {
  if (!orderSymbol) return "";
  const { symbol, exchange, market_type } = orderSymbol;

  return `${symbol}~${prepareExchange(exchange)}~${market_type}`.toLowerCase();
};

export const getWatchlistSymbolId = (fsym: string, tsym: string, exchange: string) => `${fsym}~${tsym}~${exchange}`;

export const { setLivePrice, clearLivePrice, setTicker, setOHLCV, setOtcOrderbook } = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
