import React from "react";
import { IconButton, Stack, styled, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { RenderWallet } from "./render-wallet";
import { CardDialog } from "@src/components/CardDialog";
import { AccountDialogContent, ModalBody, ModalHeader, PasteOrSelectAddress, useWallets } from "@src/pages/portfolio";
import { useSendCryptoFormContext, useTextCopy } from "@src/pages/portfolio/components/send-crypto/hooks";

export interface PasteOrSelectAccountDialogProps {
  isOpen: boolean;
  onClose(): void;
  placeholder?: string;
}

export const PasteOrSelectAccountDialog = ({ isOpen, onClose, placeholder }: PasteOrSelectAccountDialogProps) => {
  const sendCryptoForm = useSendCryptoFormContext();
  const wallets = useWallets();
  const { onCopyClick } = useTextCopy();

  const onWalletClick = (walletAddress) => {
    sendCryptoForm.setValue("accountTo", walletAddress);
    onClose();
  };

  const onCopyIconClick = (e, walletAddress: string) => {
    e.stopPropagation();

    onCopyClick(walletAddress, "Address");
  };

  return (
    <CardDialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <AccountDialogContent>
        <ModalHeader>
          Select wallet
          <IconButton onClick={onClose}>
            <KeyboardArrowDown />
          </IconButton>
        </ModalHeader>
        <ModalBody sx={{ height: "100%", overflow: "hidden" }}>
          <Stack direction="column" gap={2} height="100%">
            <PasteOrSelectAddress placeholder={placeholder} onClose={onClose} />
            <Typography
              sx={{ color: (theme) => theme.custom.text.inputAdornment }}
              fontSize={18}
              lineHeight="30px"
              fontWeight={500}
            >
              Linked wallet
            </Typography>
            <WalletsList sx={{ overflowY: Object.keys(wallets).length > 3 ? "scroll" : "hidden" }}>
              {Object.keys(wallets)?.map((walletAddress) => (
                <RenderWallet
                  onCopyIconClick={onCopyIconClick}
                  onWalletClick={onWalletClick}
                  walletAddress={walletAddress}
                  wallets={wallets}
                />
              ))}
            </WalletsList>
          </Stack>
        </ModalBody>
      </AccountDialogContent>
    </CardDialog>
  );
};

const WalletsList = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  padding: `0 ${theme.spacing(1)}`,
}));
