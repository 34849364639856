import React, { CSSProperties } from "react";
import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { useTextCopy } from "@src/pages/portfolio/components/send-crypto/hooks";
import { TokenTrusted } from "@src/components/token-trusted";
import { formatTokenAmount } from "@src/utils/format";
import { TokenAvatar } from "@src/components/token-avatar";
import { TokenWithBalance } from "@src/pages/defi/types";

type TokenItemProps = {
  token: TokenWithBalance;
  onTokenClick(token: TokenWithBalance): void;
  isLoading: boolean;
  style: CSSProperties;
};

export const TokenItem = ({ token, onTokenClick, isLoading, style }: TokenItemProps) => {
  const { onCopyClick } = useTextCopy();

  const handleCopyClick = (e) => {
    e.stopPropagation();
    onCopyClick(token.address, "Address");
  };

  return (
    <ListItem
      button
      onClick={() => onTokenClick(token)}
      sx={{ px: 3 }}
      style={style}
      title={token.address}
      secondaryAction={
        isLoading ? (
          <ListItemSecondaryAction>
            <CircularProgress size={18} color="inherit" />
          </ListItemSecondaryAction>
        ) : (
          <Stack direction="row" gap={1}>
            <TokenTrusted trusted={token.trusted} />
            {token.balance && <Typography>{formatTokenAmount(token.balance)}</Typography>}
          </Stack>
        )
      }
    >
      <ListItemAvatar>
        <TokenAvatar address={token.address} chainId={token.chainId} symbol={token.symbol} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <span>{token.symbol}</span>
            <IconButton onClick={handleCopyClick} sx={{ height: 20, width: 20, marginLeft: 1 }}>
              <ContentCopy sx={{ color: (theme) => theme.palette.text.secondary, width: 14 }} />
            </IconButton>
          </>
        }
        secondary={token.name}
      />
    </ListItem>
  );
};
