/* eslint-disable react/display-name */
import React from "react";
import { createColumnHelper, Row } from "@tanstack/table-core";
import { MonthlySummaryTableItem } from "@src/store/apis/anbotoApi/types";
import { formatUsd } from "@src/utils/format";

export const columnHelper = createColumnHelper<MonthlySummaryTableItem>();

export const getColumns = ({ data }) => [
  columnHelper.accessor("month", {
    id: "month",
    header: "Dates",
    size: 100,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{row.original.month || ""}</>,
      (prev, next) => prev.row.original.month === next.row.original.month
    ),
    sortingFn: (a, b) => a.original.date - b.original.date,
  }),
  columnHelper.accessor("tradedValue", {
    id: "tradedValue",
    header: "Traded (Gross)",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row.original?.tradedValue || 0)}</>,
      (prev, next) => prev.row.original.tradedValue === next.row.original.tradedValue
    ),
    footer: formatUsd(data.reduce((total, { tradedValue }) => (total += tradedValue), 0)),
  }),
  columnHelper.accessor("tradedValueCumulative", {
    id: "tradedValueCumulative",
    header: "Traded (Gross) - Cumulative",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row.original?.tradedValueCumulative || 0)}</>,
      (prev, next) => prev.row.original.tradedValueCumulative === next.row.original.tradedValueCumulative
    ),
    footer: formatUsd(data[0]?.tradedValueCumulative || 0),
  }),
  columnHelper.accessor("buyValue", {
    id: "buyValue",
    header: "Traded - Buy",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row?.original.buyValue || 0)}</>,
      (prev, next) => prev.row.original.buyValue === next.row.original.buyValue
    ),
    footer: formatUsd(data.reduce((total, { buyValue }) => (total += buyValue), 0)),
  }),
  columnHelper.accessor("sellValue", {
    id: "sellValue",
    header: "Traded - Sell",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row?.original.sellValue || 0)}</>,
      (prev, next) => prev.row.original.sellValue === next.row.original.sellValue
    ),
    footer: formatUsd(data.reduce((total, { sellValue }) => (total += sellValue), 0)),
  }),
  columnHelper.accessor("tradedNet", {
    id: "tradedNet",
    header: "Traded - Net",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row.original?.tradedNet || 0)}</>,
      (prev, next) => prev.row.original.tradedNet === next.row.original.tradedNet
    ),
    footer: formatUsd(data.reduce((total, { tradedNet }) => (total += tradedNet), 0)),
  }),
  columnHelper.accessor("tradedMaker", {
    id: "tradedMaker",
    header: "Traded (Gross) - Maker",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row.original?.tradedMaker || 0)}</>,
      (prev, next) => prev.row.original.tradedMaker === next.row.original.tradedMaker
    ),
    footer: formatUsd(data.reduce((total, { tradedMaker }) => (total += tradedMaker), 0)),
  }),
  columnHelper.accessor("tradedTaker", {
    id: "tradedTaker",
    header: "Traded (Gross) - Taker",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{formatUsd(row.original?.tradedTaker || 0)}</>,
      (prev, next) => prev.row.original.tradedTaker === next.row.original.tradedTaker
    ),
    footer: formatUsd(data.reduce((total, { tradedTaker }) => (total += tradedTaker), 0)),
  }),
  columnHelper.accessor("CefiTotal", {
    id: "CefiTotal",
    header: "CEFI % Total (Gross)",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{(row.original?.CefiTotal || 0).toFixed(0) + " %"}</>,
      (prev, next) => prev.row.original.CefiTotal === next.row.original.CefiTotal
    ),
    footer:
      data && data.length
        ? (data.reduce((total, { CefiTotal }) => (total += CefiTotal), 0) / data.length).toFixed(0) + " %"
        : "0%",
  }),
  columnHelper.accessor("DefiTotal", {
    id: "DefiTotal",
    header: "DEFI % Total (Gross)",
    size: 75,
    cell: React.memo(
      ({ row }: { row: Row<MonthlySummaryTableItem> }) => <>{(row.original?.DefiTotal || 0).toFixed(0) + " %"}</>,
      (prev, next) => prev.row.original.DefiTotal === next.row.original.DefiTotal
    ),
    footer:
      data && data.length
        ? (data.reduce((total, { DefiTotal }) => (total += DefiTotal), 0) / data.length).toFixed(0) + " %"
        : "0%",
  }),
];
