import { useEffect, useState } from "react";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export const useWalletName = (address = "") => {
  const getWalletsQuery = anbotoApi.useGetWalletsQuery(null);
  const [name, setName] = useState("");

  useEffect(() => {
    const wallet = getWalletsQuery.data?.results?.find(
      (wallet) => wallet.address?.toLowerCase() === address.toLowerCase()
    );

    if (wallet) {
      setName(wallet.name);
    } else {
      setName("");
    }
  }, [address, getWalletsQuery.isFetching]);

  return { name, address };
};
