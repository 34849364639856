import React from "react";
import { Tab, Tabs, styled, Box } from "@mui/material";
import { OrderSide } from "@src/store/apis/anbotoApi/types";

type SideSelectProps = {
  value: OrderSide;
  onChange(value: OrderSide): void;
};

export const SideSelect = ({ value, onChange }: SideSelectProps) => {
  return (
    <Box sx={{ backgroundColor: (theme) => theme.palette.background.default, borderRadius: 1, px: "2px" }}>
      <StyledTabs value={value} onChange={(e, activeTab) => onChange(activeTab)} variant="fullWidth">
        <StyledTab label="Buy" value={OrderSide.BUY} disableRipple />
        <StyledTab label="Sell" value={OrderSide.SELL} disableRipple />
      </StyledTabs>
    </Box>
  );
};

const StyledTabs = styled(Tabs)(({ theme, value }) => ({
  height: 32,
  minHeight: 32,
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
    color: value === OrderSide.BUY ? theme.custom.colors.secondary : theme.palette.error.main,
  },
  "& .MuiTabs-indicator": {
    boxSizing: "border-box",
    display: "block",
    height: 28,
    border: "1px solid",
    borderColor: value === OrderSide.BUY ? theme.custom.colors.secondary : theme.palette.error.main,
    background: value === OrderSide.BUY ? theme.palette.background.default : theme.palette.background.paper,
    borderRadius: 4,
    marginBottom: 2,
    zIndex: 0,
  },
  "& .MuiTabs-flexContainer": {
    position: "relative",
    zIndex: 1,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  padding: 0,
  minHeight: 28,
  marginTop: 2,
  "&.Mui-selected": {
    color: theme.palette.text.primary,
  },
  "&.MuiButtonBase-root.MuiTab-root": {
    textTransform: "none",
    fontSize: 16,
  },
}));
