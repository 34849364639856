import BigNumber from "bignumber.js";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks/use-defi-order-form-context";
import { getTradingDurationSec } from "@src/pages/cefi/order-form/utils";

export const useDcaStrategyQuantity = () => {
  const orderForm = useDefiOrderFormContext();
  const tradingDuration = orderForm.watch("tradingDuration");
  const tradingDurationUnit = orderForm.watch("tradingDurationUnit");
  const frequency = orderForm.watch("frequency");
  const frequencyUnit = orderForm.watch("frequencyUnit");
  const clipSizeValue = orderForm.watch("clipSizeValue");

  if (!frequencyUnit || !frequency || !tradingDurationUnit || !tradingDuration || !clipSizeValue) return "0";

  const durationInSeconds = getTradingDurationSec(Number(tradingDuration), tradingDurationUnit);
  const frequencyInSeconds = getTradingDurationSec(Number(frequency), frequencyUnit);

  return new BigNumber(clipSizeValue)
    .multipliedBy(new BigNumber(durationInSeconds))
    .dividedBy(new BigNumber(frequencyInSeconds))
    .toFixed();
};
