import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { DropResult } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { feeManagerApi } from "../api";
import { FeeWalletIntro } from "../fee-wallet-intro";
import DraggableList, { Item } from "./draggable-list";
import { reorder } from "./helpers";
import { RootState } from "@src/store/types";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";

export type UseDepositType = {
  isOpen: boolean;
  show: () => void;
  hide: () => void;
  chainFee: number;
  tokenFee: string;
  setChainFee: React.Dispatch<React.SetStateAction<number>>;
  setTokenFee: React.Dispatch<React.SetStateAction<string>>;
};

export type UseWithdrawType = UseDepositType;

type FeeManagerHeaderProps = {
  useDeposit: UseDepositType;
  useWithdraw: UseWithdrawType;
} & {};

export const OverviewTokenFees = ({ useDeposit, useWithdraw }: FeeManagerHeaderProps) => {
  const snackbar = useSnackbar();

  const { team_uuid } = useSelector((state: RootState) => state.user);
  // const { items } = useSelector((state: RootState) => state.feeManager);

  const [updateTokenFees, updateTokenfeesMutation] = feeManagerApi.useUpdateTokenFeesMutation();
  const getTokenListQuery = feeManagerApi.useGetFeeTokensPreferenceQuery({ team_uuid: team_uuid });

  const { data, isFetching } = feeManagerApi.useGetFeeTokensAndBalancesQuery();

  const [feeItems, setFeeItems] = useState<Item[]>([]);

  useEffect(() => {
    setFeeItems(data?.items || []);
  }, [data?.items]);

  const onDragEnd = async ({ destination, source }: DropResult) => {
    if (!destination || !feeItems) return;
    try {
      const newItems = reorder(feeItems, source.index, destination.index);
      const itemsForApi = newItems.map((token, index) => {
        return { token_address: token.token_address!, choice_position: index + 1 };
      });
      await updateTokenFees({ team_uuid: team_uuid, body: itemsForApi }).unwrap();
      setFeeItems(newItems);
      snackbar.enqueueSnackbar("Preference successfully updated", { variant: "success" });
    } catch (error) {
      snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data), { variant: "error" });
    }
  };

  return (
    <>
      {getTokenListQuery.data?.length === 0 && !updateTokenfeesMutation.isLoading && !isFetching ? (
        <FeeWalletIntro useDeposit={useDeposit} />
      ) : (
        <>
          {feeItems && !updateTokenfeesMutation.isLoading && !isFetching ? (
            <DraggableList items={feeItems} onDragEnd={onDragEnd} useDeposit={useDeposit} useWithdraw={useWithdraw} />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" minHeight="400px">
              <CircularProgress />
            </Box>
          )}
        </>
      )}
    </>
  );
};
