import * as React from "react";
import { styled, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { WidgetName, WidgetsGridView } from "../types";
import { GROUPS, MAX_GROUPS } from "../constants";
import { updateGroupData, addWidget, closeUpdateGroupDataModal } from "../store/widgets.slice";
import { useWidgetsGridLayout } from "../hooks/use-widgets-grid-layout";
import { GroupList } from "./group-list";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

const MODAL_WIDTH = 330;
const MODAL_HEIGHT = 330;

export const UpdateGroupDataModal = () => {
  const dispatch = useAppDispatch();
  const [currentGroupsWithForm, setCurrentGroupsWithForm] = React.useState<string[]>([]);
  const [groupsWithoutForm, setGroupsWithoutForm] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState("");
  const view = useAppSelector((state) => state.widgets.view);
  const { layout } = useWidgetsGridLayout(view);
  const groupDataModal = useAppSelector((state) => state.widgets.groupDataModal);
  const otc = view === WidgetsGridView.OTC;

  const forms = Object.values(layout).filter(
    ({ name }) => name === (otc ? WidgetName.OtcOrderForm : WidgetName.CefiOrderForm)
  );

  React.useEffect(() => {
    const groupIds = GROUPS.map(({ id }) => id).filter(Boolean);

    const currentGroups = forms
      .reduce<string[]>(
        (res, { group }) => (group && !res.includes(group) && groupIds.includes(group) ? [...res, group] : res),
        []
      )
      .sort();
    const availableNewGroups = GROUPS.reduce<string[]>(
      (prev, { id }) => (currentGroups.includes(id) ? prev : [...prev, id]),
      []
    )
      .sort()
      .filter(Boolean);

    setCurrentGroupsWithForm(currentGroups);
    setGroupsWithoutForm(availableNewGroups);

    if (!selected) setSelected(currentGroups[0]);
  }, [groupDataModal.data ? JSON.stringify(groupDataModal.data) : "", JSON.stringify(forms)]);

  const handleClose = () => {
    dispatch(closeUpdateGroupDataModal());
  };

  const handleSubmit = () => {
    if (!selected || !groupDataModal.data) return false;

    dispatch(
      updateGroupData({
        reset: true,
        group: selected,
        data: groupDataModal.data,
      })
    );

    if (groupsWithoutForm.includes(selected)) {
      dispatch(
        addWidget({
          name: otc ? WidgetName.OtcOrderForm : WidgetName.CefiOrderForm,
          group: selected,
        })
      );
    }

    handleClose();
  };

  if (!groupDataModal.visible) return null;

  return (
    <Draggable>
      <Modal
        elevation={8}
        sx={{ left: (window.innerWidth - MODAL_WIDTH) / 2, top: (window.innerHeight - MODAL_HEIGHT) / 2 }}
      >
        {groupDataModal.title && (
          <Typography fontWeight="bold" variant="h6" mb={2} noWrap>
            {groupDataModal.title}
          </Typography>
        )}
        <Typography>Select trading form</Typography>
        <GroupList radio groupIds={currentGroupsWithForm} selected={selected} onGroupClick={setSelected} marginY={1} />
        {!!groupsWithoutForm.length && !!currentGroupsWithForm.length && forms.length < MAX_GROUPS && (
          <Typography>Or create a new one</Typography>
        )}
        {forms.length < MAX_GROUPS && (
          <GroupList radio groupIds={groupsWithoutForm} selected={selected} onGroupClick={setSelected} marginY={1} />
        )}
        <Actions>
          <AnbotoButton size="small" variant="outlined" onClick={handleClose}>
            Cancel
          </AnbotoButton>
          <AnbotoButton size="small" variant="contained" onClick={handleSubmit}>
            Ok
          </AnbotoButton>
        </Actions>
      </Modal>
    </Draggable>
  );
};

const Modal = styled(Paper)(({ theme }) => ({
  width: MODAL_WIDTH,
  height: MODAL_HEIGHT,
  border: `1px solid ${theme.custom.colors.primary}`,
  position: "fixed",
  zIndex: 99,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  cursor: "grab",
}));

const Actions = styled(Stack)(({ theme }) => ({
  position: "absolute",
  left: 0,
  bottom: 0,
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
}));
