import React from "react";
import { Stack, Typography } from "@mui/material";
import { GROUPS } from "../constants";

export const GroupIcon = ({ group }: { group: string }) => {
  const groupIds = GROUPS.map(({ id }) => id).filter(Boolean);
  const color = GROUPS.find((_group) => _group.id === group)?.color || GROUPS[0]?.color;

  return (
    <Stack
      sx={{
        width: 22,
        height: 20,
        border: `1px solid ${color}`,
        background: (theme) => theme.custom.background.secondary,
        borderRadius: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography fontSize={10} color={color} fontWeight="bold">
        {groupIds.includes(group) ? group : "-"}
      </Typography>
    </Stack>
  );
};
