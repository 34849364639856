import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { TooltipProps } from "recharts";
import { format } from "date-fns";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

const AssetsChartTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const dateFormatter = (date) => (date ? format(new Date(date * 1000), "Pp") : "");

  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <Box sx={{ background: "#3B4043", padding: 1, borderRadius: 1 }}>
        <Typography>{dateFormatter(payload?.[0]?.payload?.["time"])}</Typography>

        {payload.map((item) => (
          <Stack direction="row" gap={0.5} alignItems="center">
            <Box width={10} height={10} sx={{ borderRadius: "50%", background: `${item.color}` }}></Box>
            <Typography>
              <Typography variant="caption" color={theme.palette.text.secondary}>
                {item.name}:
              </Typography>{" "}
              {parseFloat((Number(item?.value ? item.value : 0) * 100).toFixed(4))}%
            </Typography>
          </Stack>
        ))}
      </Box>
    );
  }

  return null;
};

export default AssetsChartTooltip;
