import React, { ChangeEvent, forwardRef } from "react";
import InfoIcon from "@mui/icons-material/Info";
import _omit from "lodash/omit";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { StrategyDialog } from "./strategy-dialog";
import { OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";
import { useDialog } from "@src/hooks/useDialog";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const CEFI_ORDER_STRATEGIES: OrderExecutionStrategy[] = [
  OrderExecutionStrategy.ORDER,
  OrderExecutionStrategy.LIMIT,
  OrderExecutionStrategy.TWAP,
  OrderExecutionStrategy.VWAP,
  OrderExecutionStrategy.ICEBERG,
  OrderExecutionStrategy.POV,
  OrderExecutionStrategy.IS,
];

export const ORDER_EXECUTION_STRATEGY_NAME: Record<OrderExecutionStrategy, string> = {
  [OrderExecutionStrategy.TWAP]: "TWAP",
  [OrderExecutionStrategy.VWAP]: "VWAP",
  [OrderExecutionStrategy.ICEBERG]: "Iceberg",
  [OrderExecutionStrategy.ORDER]: "Market",
  [OrderExecutionStrategy.LIMIT]: "Limit",
  [OrderExecutionStrategy.POV]: "POV",
  [OrderExecutionStrategy.DCA]: "DCA",
  [OrderExecutionStrategy.IS]: "IS",
  [OrderExecutionStrategy.FOK]: "Fill or kill",
};

export const ORDER_EXECUTION_STRATEGY_DESCRIPTION: Record<OrderExecutionStrategy, string> = {
  [OrderExecutionStrategy.TWAP]:
    "Time-weighted average price strategy breaks up a large order and releases dynamically determined smaller chunks of the order to the market using evenly divided time slots between a start and end time. The aim is to execute the order close to the average price between the start and end times thereby minimizing market impact.",
  [OrderExecutionStrategy.VWAP]:
    "Volume-weighted average price strategy breaks up a large order and releases dynamically determined smaller chunks of the order to the market using stock-specific historical volume profiles. The aim is to execute the order close to the volume-weighted average price (VWAP).",
  [OrderExecutionStrategy.ICEBERG]:
    'Iceberg orders are large single orders that have been divided into smaller limit orders for the purpose of hiding the actual order quantity. The term "iceberg" comes from the fact that the visible lots are just the "tip of the iceberg" given the greater number of limit orders ready to be placed.',
  [OrderExecutionStrategy.ORDER]:
    "A market order is an order to buy or sell a security immediately. This type of order guarantees that the order will be executed, but does not guarantee the execution price. A market order generally will execute at or near the current bid (for a sell order) or ask (for a buy order) price.",
  [OrderExecutionStrategy.LIMIT]:
    "An order with a limit price condition. It triggers the swap and only continues when the spot market price of Output Tokens per Input Tokens is above the limit price threshold.",
  [OrderExecutionStrategy.POV]:
    "Percent of volume (POV) strategy designed to control execution pace by targeting a percentage of market volume. Emphasis on staying as close to the stated POV rate as possible.",
  [OrderExecutionStrategy.DCA]:
    "Dollar-cost averaging (DCA) is an investment strategy where an individual regularly invests a fixed amount of money into a particular asset at scheduled intervals, regardless of its price, aiming to reduce the impact of market volatility on the overall purchase price.",
  [OrderExecutionStrategy.IS]:
    "Implementation shortfall is a trading strategy that aims to minimize the difference between the decision price and the final execution price. It involves dividing the order into smaller parts and executing them over time, taking into account market conditions and the cost of trading.",
  [OrderExecutionStrategy.FOK]:
    'A "Fill or Kill" (FOK) order is a type of financial order that mandates the immediate execution of the entire order in its entirety or not at all. If the order cannot be completely filled at the time of submission, it is automatically canceled',
};

type StrategySelectProps = ControllerRenderProps &
  AnbotoTextFieldProps & {
    whiteList?: OrderExecutionStrategy[];
  };

export const StrategySelect = forwardRef(({ whiteList, ...props }: StrategySelectProps, ref) => {
  const dialog = useDialog();

  const onDialogListItemClick = (x: OrderExecutionStrategy) => {
    const changeEvent = { target: { value: x } } as ChangeEvent<HTMLInputElement>;
    props.onChange(changeEvent);
    dialog.hide();
  };

  const strategies = whiteList
    ? CEFI_ORDER_STRATEGIES.filter((strategy) => whiteList?.includes(strategy))
    : CEFI_ORDER_STRATEGIES;

  const handleIconClick = (e) => {
    e.stopPropagation();
    dialog.show();
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        {props.labelStyle === "dynamic" && <HelpIcon onClick={handleIconClick} />}
        <AnbotoTextField
          {..._omit(props, "whiteList")}
          inputRef={ref}
          select
          size="small"
          fullWidth
          label={
            props.labelStyle === "dynamic" ? (
              "Strategy"
            ) : (
              <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="caption" lineHeight="1">
                  Strategy
                </Typography>
                {props.disabled ? null : (
                  <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} onClick={dialog.show} />
                )}
              </Stack>
            )
          }
        >
          {strategies.map((x) => (
            <MenuItem key={x} value={x}>
              {ORDER_EXECUTION_STRATEGY_NAME[x]}
            </MenuItem>
          ))}
        </AnbotoTextField>
      </Box>
      {!props.disabled && (
        <StrategyDialog
          open={dialog.isOpen}
          hide={dialog.hide}
          value={props.value}
          onSelect={onDialogListItemClick}
          strategies={CEFI_ORDER_STRATEGIES}
        />
      )}
    </>
  );
});
StrategySelect.displayName = "StrategySelect";

const HelpIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: "pointer",
  fontSize: 14,
  position: "absolute",
  right: 34,
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 2,
}));
