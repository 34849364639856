import React from "react";
import { TablePagination, Stack, StackProps } from "@mui/material";

type AnbotoPaginationProps = {
  total: number;
  currentPage: number;
  pageSize: number;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  onChangePage: (activePage: number) => void;
  prefix?: React.ReactNode;
  sx?: StackProps["sx"];
};

export const AnbotoPagination = ({
  total,
  currentPage,
  pageSize,
  onChangeRowsPerPage,
  onChangePage,
  prefix,
  sx,
}: AnbotoPaginationProps) => {
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onChangePage(newPage);
  };

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={{ borderTop: (theme) => `1px solid ${theme.custom.background.default}`, ...sx }}
    >
      {prefix}
      <TablePagination
        component="div"
        count={total}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 20, 50]}
        sx={{ "& > .MuiToolbar-root": { pl: 1 } }}
      />
    </Stack>
  );
};
