import React from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Stack, Typography, styled } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CopyToClipboard } from "@src/components";

export const ApiKeyRow = ({
  apiKey,
  secretKey,
  label = "API Key",
  expanded,
  onToggleExpand,
  onDeleteClick,
  hideSecretKey,
}: {
  apiKey: string;
  secretKey: string;
  label?: string;
  expanded?: boolean;
  hideSecretKey?: boolean;
  onToggleExpand?: () => void;
  onDeleteClick?: () => void;
}) => {
  return (
    <Row>
      {onToggleExpand && (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack
            flex={1}
            direction="row"
            gap={1}
            alignItems="center"
            onClick={onToggleExpand}
            sx={{ cursor: "pointer" }}
          >
            <IconButton size="small" sx={{ color: "#8A9296" }}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Typography flex={1} variant="body2" fontSize={16} sx={{ userSelect: "none" }}>
              {label}
            </Typography>
          </Stack>
          {onDeleteClick && (
            <IconButton onClick={onDeleteClick} sx={{ width: 34, height: 34, color: "#8A9296" }}>
              <RemoveCircleOutlineIcon sx={{ fontSize: 20 }} />
            </IconButton>
          )}
        </Stack>
      )}
      {(expanded || !onToggleExpand) && (
        <Expanded sx={{ background: onToggleExpand ? "#232C2F" : undefined, marginTop: onToggleExpand ? 2 : 0 }}>
          {apiKey && (
            <Stack direction="row" gap={1}>
              <Typography variant="body2" fontSize={16} fontWeight={600} sx={{ color: "#F1F7FA" }}>
                ApiKey
              </Typography>
              <CopyToClipboard textToCopy={apiKey} size={16} />
            </Stack>
          )}

          <Typography variant="body2" fontSize={16} fontWeight={500} sx={{ color: "#8A9296" }} mt={1}>
            {apiKey}
          </Typography>

          {secretKey && (
            <Stack direction="row" gap={1} mt={2}>
              <Typography variant="body2" fontSize={16} fontWeight={600} sx={{ color: "#F1F7FA" }}>
                Secret Key
              </Typography>
              {!hideSecretKey && <CopyToClipboard textToCopy={secretKey} size={16} />}
            </Stack>
          )}
          <Typography variant="body2" fontSize={16} fontWeight={500} sx={{ color: "#8A9296" }} mt={1}>
            {hideSecretKey ? "******" : secretKey}
          </Typography>
        </Expanded>
      )}
    </Row>
  );
};

const Row = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  background: "#192022",
  width: 640,
  "&:not(:first-child)": {
    marginTop: theme.spacing(1),
  },
}));

const Expanded = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
}));
