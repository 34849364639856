export const getTickerSubMsg = ({ exchange, fsym, tsym }: Record<string, string>) =>
  [2, exchange, fsym, tsym].map((el) => el || "*").join("~");

export const getCryptocompareExchangeName = (exchange: string) => {
  const name = exchange.toLowerCase();

  if (name === "gate.io") return "gateio";
  if (name === "huobi") return "huobipro";
  if (name === "ascendex") return "bitmax";
  if (name === "coinbasepro") return "coinbase";
  if (name === "okx") return "okex";

  return name;
};
