import React from "react";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";

export const TradePermissionButtonGroup = ({
  value,
  onChange,
  disabled,
}: {
  value: boolean;
  onChange: (trade: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <AnbotoButtonGroup<number>
      defaultValue={value ? 1 : 0}
      view="outlined"
      disabled={disabled}
      options={[1, 0].map((x) => ({ label: x ? "Trade" : "View only", value: x }))}
      onChange={(value: number) => onChange(!!value)}
    />
  );
};
