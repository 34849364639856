import { useEffect, useState } from "react";
import _uniq from "lodash/uniq";
import _groupBy from "lodash/groupBy";
import _get from "lodash/get";
import { filterUnsupported, getFromTo, getSymbolsDeps } from "./helpers";
import { cryptocompareApi } from "@src/store/apis/cryptocompareApi";
import { GetMultipleSymbolsFullDataItem } from "@src/store/apis/cryptocompareApi/types";
import { OrderSymbol, CefiExchangeId } from "@src/store/apis/anbotoApi/types";
import { getExchangeKey } from "@src/store/helpers";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";

export type SymbolsFullData = Record<string, GetMultipleSymbolsFullDataItem>;

const getKey = (symbol: OrderSymbol) => getLivePriceId(symbol);

const triggers = {
  firstLoad: true,
};

export const useSymbolsFullData = (symbols: OrderSymbol[] = []) => {
  const [ccFetchSymbolData] = cryptocompareApi.useLazyGetMultipleSymbolsFullDataQuery();
  const [loading, setLoading] = useState(false);
  // exchange.from.to.GetMultipleSymbolsFullDataItem
  const [data, setData] = useState<SymbolsFullData>({});
  const [firstLoad, setFirstLoad] = useState(triggers.firstLoad);

  const symbolsDeps = getSymbolsDeps(symbols);

  useEffect(() => {
    let intervalId;

    if (symbols.length) {
      loadData();
      intervalId = setInterval(() => loadData(), 5 * 60 * 1000);
    }

    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [symbolsDeps]);

  const loadData = async () => {
    setLoading(true);

    const symbolsbyExchangeId = _groupBy(symbols, ({ exchange }) => exchange.toLowerCase());

    const promises: Promise<Record<string, GetMultipleSymbolsFullDataItem> | undefined>[] = [];

    const exchanges = Object.keys(symbolsbyExchangeId);

    exchanges.forEach((exchange) => {
      const fromTo = symbolsbyExchangeId[exchange].reduce(
        (res, { symbol }) => {
          const { from, to } = getFromTo(symbol);
          res.fromSymbols.push(from);
          res.toSymbols.push(to);
          return res;
        },
        { fromSymbols: [], toSymbols: [] } as Record<string, string[]>
      );
      const { fromSymbols, toSymbols } = fromTo;
      promises.push(fetchSymbolsDataByExchange(exchange, _uniq(fromSymbols), _uniq(toSymbols)));
    });

    const allResults = await Promise.allSettled(promises);

    const results = {};
    (allResults as PromiseFulfilledResult<Record<string, GetMultipleSymbolsFullDataItem>>[]).forEach(
      ({ status, value }, index) => {
        if (status === "fulfilled") {
          const key = getExchangeKey(exchanges[index]);
          results[key] = value;
        }
      }
    );

    const data = {};

    symbols.forEach((s) => {
      const { exchange, symbol } = s;
      const { from, to } = getFromTo(symbol);
      const raw = _get(results, `${getExchangeKey(exchange)}.${from}.${to}`, {}) as GetMultipleSymbolsFullDataItem;
      data[getKey(s)] = raw;
    });

    setData(data);

    if (firstLoad) {
      triggers.firstLoad = false;
      setFirstLoad(false);
    }

    setLoading(false);
  };

  const fetchSymbolsDataByExchange = async (exchange: string, fromSymbols: string[], toSymbols: string[]) => {
    try {
      const { RAW } = await ccFetchSymbolData({ exchange, fromSymbols, toSymbols }).unwrap();
      return RAW;
    } catch (err) {
      console.log(err);
    }
  };

  return [data, loading, firstLoad, getKey] as [SymbolsFullData, boolean, boolean, typeof getKey];
};

export const useToplistSymbols = (tsym = "USDT") => {
  const topList = cryptocompareApi.useGetTopListQuery({ tsym });
  const [symbols, setSymbols] = useState<OrderSymbol[]>([]);

  useEffect(() => {
    const preparedSymbols =
      topList.data?.reduce<OrderSymbol[]>((res, { RAW }) => {
        const { FROMSYMBOL, TOSYMBOL } = RAW["USDT"] || {};

        if (["USDT", "BUSD", "USDC"].includes(FROMSYMBOL) || !FROMSYMBOL) return res;

        return [
          ...res,
          {
            symbol: `${FROMSYMBOL}/${TOSYMBOL}`,
            exchange: CefiExchangeId.BINANCE,
            market_type: "spot",
          } as OrderSymbol,
        ];
      }, []) || [];

    if (!topList.isFetching && preparedSymbols.length) setSymbols(preparedSymbols.filter(filterUnsupported));
  }, [topList.isFetching, topList.data]);

  return [symbols, topList.isFetching] as [OrderSymbol[], boolean];
};
