import React from "react";
import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { MetricsHeader } from "@src/pages/metrics/components/metrics-header/metrics-header";

const MetricsPage = () => (
  <Stack>
    <MetricsHeader />
    <Outlet />
  </Stack>
);

export default MetricsPage;
