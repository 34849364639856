import { styled, Tab } from "@mui/material";

export const StyledTabListTab = styled(Tab)<{ error?: boolean }>(({ theme, error }) => ({
  minHeight: 38,
  minWidth: 0,
  paddingTop: 0,
  paddingRight: theme.spacing(1),
  paddingBottom: 0,
  paddingLeft: theme.spacing(1),
  textTransform: "none",
  fontWeight: "normal",
  color: error ? theme.palette.error.light : theme.custom.text.dim,
  "&.Mui-selected": {
    color: error ? theme.palette.error.light : theme.palette.text.primary,
    fontWeight: 600,
  },
}));
