import React from "react";
import { Box, Button, ButtonProps, Stack, StackProps, styled, useTheme } from "@mui/material";
import AnbotoInfoIcon from "@src/components/anboto-info-icon/anboto-info-icon";
import { styledOptions } from "@src/utils/styled-options";

export type AnbotoButtonGroupValue<T> = T;

export type AnbotoButtonGroupOption<T> = {
  label: string;
  title?: string;
  value: T;
  isActive?: boolean;
  endAdornment?: JSX.Element;
  disabled?: boolean;
};

export type AnbotoButtonGroupView = "default" | "compact" | "outlined";

export type AnbotoButtonGroupProps<T> = Omit<StackProps, "onChange"> & {
  options: AnbotoButtonGroupOption<T>[];
  onChange?: (value: AnbotoButtonGroupValue<T>) => void;
  defaultValue?: AnbotoButtonGroupValue<T>;
  value?: AnbotoButtonGroupValue<T>;
  view?: AnbotoButtonGroupView;
  size?: ButtonProps["size"];
  disabled?: boolean;
};

export function ButtonGroup<T>({
  options,
  onChange,
  value,
  defaultValue,
  view,
  size,
  selectedButtonStyles,
  buttonStyles,
  overlayStyles,
  disabled,
  ...props
}: AnbotoButtonGroupProps<T> & {
  selectedButtonStyles?: React.CSSProperties;
  buttonStyles?: React.CSSProperties;
  overlayStyles?: React.CSSProperties;
}) {
  const theme = useTheme();
  const [selected, setSelected] = React.useState<AnbotoButtonGroupValue<T> | undefined>(defaultValue);

  const handleSelect = (newValue: AnbotoButtonGroupValue<T>) => {
    if (value === undefined) setSelected(newValue);
    onChange && onChange(newValue);
  };

  return (
    <StyledStack overlayStyles={overlayStyles} view={view} flexDirection="row" {...props}>
      {options.map((option) => {
        const isSelected = value !== undefined ? option.value === value : option.value === selected;
        const isActive = !!option.isActive;

        return (
          <StyledButton
            key={option.value as unknown as string}
            onClick={() => handleSelect(option.value)}
            selected={isSelected}
            isActive={isActive}
            variant={value === selected ? "contained" : "text"}
            view={view}
            size={size}
            selectedButtonStyles={selectedButtonStyles}
            buttonStyles={buttonStyles}
            disabled={disabled}
          >
            <ButtonLabel view={view}>{option.label}</ButtonLabel>
            {option?.endAdornment ? option.endAdornment : null}
            {option?.title && (
              <AnbotoInfoIcon
                color={isActive ? "#ECAC27" : isSelected ? theme.palette.primary.main : "#636B6F"}
                iconSize={12}
                title={option.title}
              />
            )}
          </StyledButton>
        );
      })}
    </StyledStack>
  );
}

const StyledStack = styled(
  Stack,
  styledOptions
)<{ view?: AnbotoButtonGroupView; overlayStyles?: React.CSSProperties }>(({ view, theme, overlayStyles }) => {
  const compact = view === "compact";

  return {
    marginTop: 0,
    borderRadius: theme.shape.borderRadius,
    background: theme.custom.background.secondary,
    padding: compact ? 0 : 1,
    ...overlayStyles,
  };
});

const StyledButton = styled(
  Button,
  styledOptions
)<{
  selected?: boolean;
  isActive?: boolean;
  view?: AnbotoButtonGroupView;
  selectedButtonStyles?: React.CSSProperties;
  buttonStyles?: React.CSSProperties;
}>(({ selected, isActive, theme, view, selectedButtonStyles, buttonStyles }) => {
  const compact = view === "compact";
  const outlined = view === "outlined";
  return {
    flex: compact || outlined ? 1 : undefined,
    color: isActive
      ? "#ECAC27"
      : (compact || outlined) && selected
      ? theme.palette.primary.main
      : selected
      ? "#F1F7FA"
      : "#636B6F",
    textTransform: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: (compact || outlined) && selected ? theme.custom.colors.primary : theme.custom.background.secondary,
    backgroundColor:
      (compact || outlined) && selected
        ? theme.custom.background.secondary
        : selected
        ? `${theme.custom.colors.primary} !important`
        : "transparent",
    boxShadow: compact ? "none" : `inset 0 0 0 1px ${theme.custom.background.secondary} !important`,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    height: compact ? 24 : 32,
    ...buttonStyles,
    ...(selected && selectedButtonStyles),
  };
});

const ButtonLabel = styled(Box)<{ view?: AnbotoButtonGroupView }>({
  flex: 1,
  textAlign: "center",
  whiteSpace: "nowrap",
});

// eslint-disable-next-line react/display-name
export const AnbotoButtonGroup = React.memo(
  ButtonGroup,
  (prev, next) => prev.value === next.value && prev.disabled === next.disabled
) as typeof ButtonGroup;
