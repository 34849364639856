import { createListenerMiddleware } from "@reduxjs/toolkit";
import { anbotoApi } from "./index";
import { startWidgetsListener } from "@src/features/widgets-layout/store/start-widgets-listener";
import { WidgetsGridView } from "@src/features/widgets-layout/types";
import { WidgetsViewState } from "@src/features/widgets-layout/store/widgets.slice";

export const listenerMiddleware = createListenerMiddleware();

const saveWidgetsLayoutToUserData = (view: WidgetsGridView, data: WidgetsViewState, dispatch) => {
  const field = `layout_${view}`;

  dispatch(
    anbotoApi.endpoints.patchUser.initiate({
      [field]: data,
    })
  );
};

startWidgetsListener(listenerMiddleware, saveWidgetsLayoutToUserData);
