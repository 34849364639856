import wsSubscribe, { WsSubscription } from "./ws-subscribe";
import { Handler, Listener } from "./types";
import { getWsUrl } from "@src/utils/url";
import { WS_HOST } from "@src/store/apis/anbotoApi/constants";

export interface AnbotoSubscriptionOptions {
  url: string;
  auth?: boolean;
}

const SUBSCRIBE_ALL_ID = "_SUBSCRIBE_ALL_";

export abstract class AnbotoSubscription<SubscribeMsgData, MsgData> {
  constructor(opts: AnbotoSubscriptionOptions) {
    this.connect(opts);
  }

  connection: WsSubscription | null = null;

  async connect(opts: AnbotoSubscriptionOptions) {
    const { url, auth } = opts;

    this.connection = await wsSubscribe({
      url: getWsUrl(`${WS_HOST}${url}`),
      onmessage: (e: MessageEvent) => this.onMessage(e),
      auth,
    });
  }

  isClosed() {
    return this.connection?.getState() === WebSocket.CLOSED;
  }

  listeners: Map<string, Listener> = new Map();

  isReady() {
    return this.connection?.getState() === WebSocket.OPEN;
  }

  abstract getId(data?: SubscribeMsgData): string;

  abstract getIdFromMsg(data?: MsgData): string;

  private addListener(id: string, handler: Handler) {
    const sub = this.listeners.get(id);

    if (typeof handler !== "function") throw new Error("unsubscribe handler should be a function, got ", handler);

    if (sub) {
      sub.handlers.push(handler);
      return;
    }

    const listenerItem: Listener = {
      handlers: [handler],
      lastUpdate: 0,
    };

    this.listeners.set(id, listenerItem);
  }

  private removeListener(id: string, handler: Handler) {
    const sub = this.listeners.get(id);

    if (!this.connection || !sub) return;

    if (typeof handler !== "function") throw new Error("unsubscribe handler should be a function, got ", handler);

    sub.handlers = sub.handlers.filter((fn: Handler) => fn !== handler);

    return sub;
  }

  subscribe(data: SubscribeMsgData, handler: Handler) {
    const subId = this.getId(data);

    if (subId === SUBSCRIBE_ALL_ID) {
      throw new Error(SUBSCRIBE_ALL_ID + " ID can not be used for subscription");
    }

    this.addListener(subId, handler);

    if (data && Object.keys(data)) {
      const msg = JSON.stringify({
        event: "websocket_subscribe",
        data,
      });

      this.connection?.send(msg);
    }

    return () => this.unsubscribe(data, handler);
  }

  unsubscribe(data: SubscribeMsgData, handler: Handler) {
    const subId = this.getId(data);

    const sub = this.removeListener(subId, handler);

    if (data && sub?.handlers.length === 0) {
      const msg = JSON.stringify({
        event: "websocket_unsubscribe",
        data,
      });

      this.connection?.send(msg);
      this.listeners.delete(subId);
    }
  }

  listenAll(handler: Handler) {
    this.addListener(SUBSCRIBE_ALL_ID, handler);
  }

  stopListenAll(handler: Handler) {
    this.removeListener(SUBSCRIBE_ALL_ID, handler);
  }

  transformMsg = (message: MsgData) => message;

  onMessage(e: MessageEvent) {
    if (e) {
      const { data } = e;

      const msgData = JSON.parse(data);
      const id = this.getIdFromMsg(msgData);

      const listener = this.listeners.get(id);
      const allMessagesListener = this.listeners.get(SUBSCRIBE_ALL_ID);

      allMessagesListener?.handlers?.forEach((fn: Handler) => {
        fn(this.transformMsg(msgData), msgData);
      });

      if (listener) {
        listener.handlers?.forEach((fn: Handler) => {
          fn(this.transformMsg(msgData), msgData);
        });
      }
    }
  }
}
