import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { Close, FindInPage, Refresh } from "@mui/icons-material";
import BigNumber from "bignumber.js";
import { TokenList } from "./token-list";
import { CHAIN_ID_DEFAULT_TOKENS } from "@src/constants/chainIdDetaultTokens";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { useDebouncedState } from "@src/pages/defi/hooks";
import { TOKEN_NAME_INPUT_DEBOUNCE_MS } from "@src/pages/defi/constants";
import { ChainId, Token, TokenWithBalance } from "@src/pages/defi/types";
import { saveToken } from "@src/store/slices/tokensSlice";
import { useAppDispatch } from "@src/store/hooks";
import { useExternalTokens } from "@src/hooks/use-external-tokens";
import { TokenChip } from "@src/components/select-view/token-chip";
import { useElementSize } from "@src/hooks/use-element-size";
import { useLiveExecutedQuantity } from "@src/pages/defi/order-form-card-no-gas/hooks/use-live-executed-quantity";
import { SmallIconButton } from "@src/pages/defi/order-form-card-no-gas/components";

type SelectTokenViewProps = {
  account?: string;
  chainId?: ChainId;
  onTokenSelect(tokenAddress: string): void;
  onClose(): void;
};

export const SelectView = ({ account, chainId, onTokenSelect, onClose }: SelectTokenViewProps) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearch = useDebouncedState(searchValue, TOKEN_NAME_INPUT_DEBOUNCE_MS);
  const searchString = debouncedSearch.toLowerCase();
  const dispatch = useAppDispatch();
  const { tokens, isLoading } = useExternalTokens({ account, chainId, searchString, skipNative: true });
  const { executedQuantity, execIsFetching, getUserTokensList, tokenBalanceslistIsFetching } =
    useLiveExecutedQuantity();
  const [containerRef, size] = useElementSize();

  const isFetching = execIsFetching || tokenBalanceslistIsFetching;

  const onTokenClick = async (token: Token) => {
    setSearchValue("");

    if (!token.address) {
      return;
    }

    dispatch(saveToken(token));

    onTokenSelect(token.address);
  };

  const sortedTokens = useMemo(
    () =>
      tokens.sort((a: TokenWithBalance, b: TokenWithBalance) => {
        if (a.balanceUsd && b.balanceUsd)
          return new BigNumber(b.balanceUsd).toNumber() - new BigNumber(a.balanceUsd).toNumber();

        if (!a.balanceUsd && !b.balanceUsd) return 0;

        if (a.balanceUsd && !b.balanceUsd) return -1;

        if (!a.balanceUsd && b.balanceUsd) return 1;

        return 0;
      }),
    [tokens]
  );

  const refreshTokenListBalances = () => {
    if (!isFetching && account && chainId) {
      getUserTokensList({ account, chainId });
    }
  };

  useEffect(() => {
    refreshTokenListBalances();
  }, [executedQuantity]);

  return (
    <>
      <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }} component="div">
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Select a token
        </Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack height="100%" gap={2}>
          <Box>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                {!!chainId &&
                  CHAIN_ID_DEFAULT_TOKENS[chainId].map((x) => (
                    <TokenChip
                      key={`${chainId}_${x.address}`}
                      address={x.address}
                      chainId={chainId}
                      onClick={onTokenClick}
                    />
                  ))}
              </Box>
              <SmallIconButton onClick={refreshTokenListBalances} disabled={isFetching}>
                {!isFetching ? <Refresh /> : <CircularProgress size={18} color="inherit" />}
              </SmallIconButton>
            </Stack>
            {/* TODO - uncomment this when defi v3 tested well
            {chainNativeToken && (
              <Chip
                key={`${chainId}-${chainNativeToken.symbol}`}
                sx={{ mr: 2 }}
                label={chainNativeToken.symbol}
                avatar={<Avatar src={chainNativeToken.logoURI} />}
                onClick={() => onTokenClick(chainNativeToken.tokenId)}
              />
            )} */}
          </Box>
          <AnbotoTextField
            fullWidth
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            size="small"
            placeholder="Search name or paste address"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FindInPage />
                </InputAdornment>
              ),
              endAdornment: searchValue.length ? (
                <InputAdornment position="end">
                  {isLoading ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : (
                    <IconButton onClick={() => setSearchValue("")}>
                      <Close />
                    </IconButton>
                  )}
                </InputAdornment>
              ) : null,
            }}
          />
          <Box sx={{ flex: 1, overflow: "hidden" }} ref={containerRef}>
            {size.height && sortedTokens?.length > 0 && (
              <TokenList
                height={size.height}
                key="own-list"
                activeTokens={sortedTokens}
                isLoading={isLoading || isFetching}
                onTokenClick={onTokenClick}
              />
            )}
          </Box>
        </Stack>
      </DialogContent>
    </>
  );
};
