import React from "react";
import { styled } from "@mui/material";

export const ResizeHandler = React.forwardRef<HTMLElement, { handleAxis?: string }>(({ handleAxis, ...props }, ref) => {
  return <Root ref={ref} className={`react-resizable-handle handle-${handleAxis}`} {...props} />;
});

ResizeHandler.displayName = "ResizeHandler";

const Root = styled("span")({
  width: 20,
  height: 20,
  position: "absolute",
  display: "block",
  zIndex: 2,
  bottom: 0,
  right: 0,
  cursor: "nwse-resize",
  "&:before": {
    content: "''",
    display: "block",
    borderStyle: "solid",
    borderWidth: `0 0 8px 8px`,
    borderColor: `transparent transparent #36475c transparent`,
    position: "absolute",
    right: 3,
    bottom: 3,
  },
});
