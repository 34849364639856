import { useActiveView } from "@src/features/widgets-layout/hooks/use-active-view";
import { useLinkedExchanges } from "@src/pages/cefi/hooks/use-linked-exchanges";
import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";
import { useAppSelector } from "@src/store/hooks";

export const useExchangesFilter = () => {
  const view = useActiveView();
  const selectedExchanges = useAppSelector((state) => state.symbolSelect.selectedExchanges[view]) || {};
  const linkedExchanges = useLinkedExchanges();

  return Object.keys(selectedExchanges).reduce<string[]>(
    (res, key) => (selectedExchanges[key] && linkedExchanges.exchanges.includes(key) ? [...res, key] : res),
    []
  ) as CefiExchangeId[];
};
