import * as React from "react";
import Chip from "@mui/material/Chip";
import { Checkbox, Dialog, FormControlLabel, CircularProgress, Stack, Typography, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PlatformAccount, adminApi } from "./api";
import { setFilter } from "./admin.slice";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

export function AccountsSelect({
  defaultSelected,
  onSave,
  activeAccounts,
}: {
  defaultSelected: PlatformAccount[];
  onSave: (accounts: PlatformAccount[]) => void;
  activeAccounts?: Record<string, boolean>;
}) {
  const dispatch = useAppDispatch();
  const onlyActive = useAppSelector((state) => state.support.filters.onlyActive);
  const accountsOnlyActive = useAppSelector((state) => state.support.filters.accountsOnlyActive);
  const [selected, setSelected] = React.useState<PlatformAccount[]>(defaultSelected || []);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [filteredAccounts, setFilteredAccounts] = React.useState<PlatformAccount[]>([]);

  const { data: accounts, isFetching: accountsFetching } = adminApi.useGetPlatformAccountsQuery();

  const handleDelete = (account: PlatformAccount) => {
    onSave(defaultSelected.filter((el) => el.uuid !== account.uuid));
  };

  const handleClose = () => {
    setOpen(false);
    setSelected(defaultSelected);
  };

  const handleSave = () => {
    onSave([...selected]);
    handleClose();
  };

  const handleSelectChange = (account: PlatformAccount, checked: boolean) => {
    if (checked) {
      setSelected((prev) => [...prev, account]);
    } else {
      setSelected((prev) => prev.filter((el) => el.uuid !== account.uuid));
    }
  };

  React.useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected.map((el) => el.uuid).join(",")]);

  React.useEffect(() => {
    const accs = (accounts || []).filter((el) => {
      if (onlyActive && accountsOnlyActive && activeAccounts && !activeAccounts[el.uuid]) return false;

      return el.name.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredAccounts(accs);
  }, [search, accountsOnlyActive, onlyActive, activeAccounts]);

  return (
    <Stack>
      <Select
        alignItems="center"
        direction="row"
        p={0.5}
        onClick={() => !accountsFetching && setOpen(true)}
        minHeight={42}
      >
        {!defaultSelected.length && (
          <Typography color={(theme) => theme.palette.text.disabled} variant="caption" ml={1}>
            Select teams
          </Typography>
        )}
        {defaultSelected.map((el) => (
          <Chip id={el.uuid} label={el.name} size="small" sx={{ m: 0.5 }} onDelete={() => handleDelete(el)} />
        ))}
        {accountsFetching ? <Loader size={20} /> : <Expand />}
      </Select>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Stack width={500} px={2} pt={2}>
          <AnbotoTextField autoFocus fullWidth value={search} onChange={(e) => setSearch(e.target.value)} />
          <Stack height={400} overflow="scroll" mt={1}>
            {(search || onlyActive ? filteredAccounts : accounts)?.map((el) => (
              <Stack>
                <FormControlLabel
                  control={<Checkbox checked={!!selected.find((i) => i.uuid === el.uuid)} />}
                  label={el.name}
                  onChange={(_, checked) => handleSelectChange(el, checked)}
                />
              </Stack>
            ))}
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between" gap={2} p={2}>
          {onlyActive && (
            <FormControlLabel
              label={
                <Typography variant="body2" color={(theme) => theme.palette.text.disabled}>
                  Active only
                </Typography>
              }
              sx={{ margin: 0 }}
              checked={accountsOnlyActive}
              control={
                <Checkbox
                  size="small"
                  sx={{ padding: 0, paddingRight: 1 }}
                  onChange={(e) => {
                    dispatch(setFilter({ accountsOnlyActive: e.target.checked }));
                  }}
                />
              }
            />
          )}
          <Stack direction="row" gap={2}>
            <AnbotoButton size="small" variant="outlined" onClick={handleClose}>
              Cancel
            </AnbotoButton>
            <AnbotoButton size="small" variant="contained" onClick={handleSave}>
              Save
            </AnbotoButton>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  );
}

const Select = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  flexWrap: "wrap",
  cursor: "pointer",
  paddingRight: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  position: "relative",
  "&:hover": {
    borderColor: theme.palette.primary.main,
  },
}));

const Expand = styled(ExpandMoreIcon)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: "50%",
  transform: "translateY(-50%)",
  color: theme.palette.text.secondary,
}));

const Loader = styled(CircularProgress)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: 11,
}));
