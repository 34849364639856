import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { Stack, styled, Typography } from "@mui/material";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { BaseQueryFn, FetchArgs, FetchBaseQueryMeta, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  GetSwapQuoteWithPreTradeAnalysisDefiAwareParams,
  GetSwapQuoteWithPreTradeAnalysisDefiAwareResult,
  OrderExecutionStrategy,
  OrderSide,
} from "@src/store/apis/anbotoApi/types";
import { TokenAmountField } from "@src/pages/defi/order-form-card-no-gas/fields";
import { TokenAddressField } from "@src/pages/defi/order-form-card-no-gas/fields/token-address-field";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AMOUNT_INPUT_DEBOUNCE_MS, DEFI_FORM_RESET_FROM_HISTORY } from "@src/pages/defi/constants";
import { onlyNumbers } from "@src/utils/only-numbers";
import { useDebouncedState, useDefiOrderFormContext } from "@src/pages/defi/hooks";
import { Token } from "@src/pages/defi/types";
import { getOppositeAmount } from "@src/pages/defi/utils";
import { cutAmount } from "@src/utils/format";
import { getTradingDurationSec } from "@src/pages/cefi/order-form/utils";

export interface TokenAmountControlProps {
  addressFieldName: "fromTokenAddress" | "toTokenAddress";
  amountFieldName: "fromTokenAmount" | "toTokenAmount";
  disabled: boolean;
  isFetchingQuoteData: boolean;
  fromToken?: Token;
  toToken?: Token;
  getQuoteData: (
    arg: GetSwapQuoteWithPreTradeAnalysisDefiAwareParams,
    preferCacheValue?: boolean | undefined
  ) => QueryActionCreatorResult<
    QueryDefinition<
      GetSwapQuoteWithPreTradeAnalysisDefiAwareParams,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
      never,
      GetSwapQuoteWithPreTradeAnalysisDefiAwareResult,
      "anbotoApi"
    >
  >;
}

export const TokenAmountControl: FC<TokenAmountControlProps> = (props) => {
  const { addressFieldName, amountFieldName, isFetchingQuoteData, getQuoteData, fromToken, toToken } = props;
  const [isLoaderHidden, setIsLoaderHidden] = useState<boolean>(false);
  const shouldUpdateQuote = useRef<boolean>(false);
  const orderForm = useDefiOrderFormContext();
  const chainId = orderForm.watch("chainId");
  const side = orderForm.watch("side");
  const slippage = orderForm.watch("slippage");
  const childSlippage = orderForm.watch("childSlippage");
  const errorMessage = orderForm.formState.errors[amountFieldName]?.message;
  const hasError = !!errorMessage;
  const tokenBalanceFieldName = amountFieldName === "fromTokenAmount" ? "fromTokenBalance" : "toTokenBalance";
  const tokenBalance = orderForm.watch(tokenBalanceFieldName);
  const fromTokenAddress = orderForm.watch("fromTokenAddress");
  const toTokenAddress = orderForm.watch("toTokenAddress");
  const strategy = orderForm.watch("strategy");
  const clipSizeType = orderForm.watch("clipSizeType");
  const clipSizeVal = orderForm.watch("clipSizeValue");
  const tokenAmount = orderForm.watch(amountFieldName);
  const tradingDuration = orderForm.watch("tradingDuration");
  const tradingDurationUnit = orderForm.watch("tradingDurationUnit");
  const limitPrice = orderForm.watch("limitPrice");
  const durationSeconds = tradingDuration
    ? getTradingDurationSec(Number(tradingDuration), tradingDurationUnit)
    : undefined;
  const debouncedTokenAmount = useDebouncedState(tokenAmount || "", AMOUNT_INPUT_DEBOUNCE_MS);
  const debouncedChildSlippage = useDebouncedState(childSlippage || "", AMOUNT_INPUT_DEBOUNCE_MS);
  const debouncedLimitPrice = useDebouncedState(limitPrice || "", AMOUNT_INPUT_DEBOUNCE_MS);
  const isLoading = isLoaderHidden ? false : isFetchingQuoteData;
  const {
    formState: {
      touchedFields: { toTokenAmount: toTokenAmountTouched, fromTokenAmount: fromTokenAmountTouched },
    },
  } = orderForm;
  const amountsTouched = fromTokenAmountTouched || toTokenAmountTouched;

  const onTokenAddressChange = async (newTokenAddress: string) => {
    orderForm.setValue(addressFieldName, newTokenAddress);

    if (strategy === OrderExecutionStrategy.LIMIT) {
      orderForm.setValue("limitPrice", "");
      orderForm.setValue("toTokenAmount", "");
    }

    await orderForm.trigger(amountFieldName);
  };

  const updateSideAmount = async (value: string) => {
    shouldUpdateQuote.current = false;
    setIsLoaderHidden(true);
    const fromTokenAmount = orderForm.getValues("fromTokenAmount");
    const limitPrice = orderForm.getValues("limitPrice");
    const baseTokenAddress = orderForm.getValues("baseTokenAddress");

    if (
      !fromToken ||
      !toToken ||
      (strategy === OrderExecutionStrategy.LIMIT && !limitPrice) ||
      (strategy === OrderExecutionStrategy.LIMIT && !fromTokenAmount)
    ) {
      return;
    }

    const sideAmount = await getOppositeAmount({
      chainId,
      getQuoteData,
      side,
      clipSizeType,
      clipSizeVal,
      slippage:
        strategy === OrderExecutionStrategy.ORDER || strategy === OrderExecutionStrategy.LIMIT
          ? slippage
          : childSlippage || "0",
      fromToken: amountFieldName === "fromTokenAmount" ? fromToken : toToken,
      toToken: amountFieldName === "fromTokenAmount" ? toToken : fromToken,
      value,
      durationSeconds,
      strategy,
      limitPrice,
      baseTokenAddress,
    });

    const sideAmountFieldName = amountFieldName === "fromTokenAmount" ? "toTokenAmount" : "fromTokenAmount";

    orderForm.setValue(sideAmountFieldName, sideAmount.toString());

    if (amountsTouched) {
      await orderForm.trigger(amountFieldName);
    }

    setIsLoaderHidden(false);
  };

  const onTokenAmountChange = async (newTokenAmount: string) => {
    const value = onlyNumbers(newTokenAmount);

    orderForm.setValue(amountFieldName, value);

    shouldUpdateQuote.current = true;
  };

  const onMaxClick = async () => {
    const roundedTokenBalance = cutAmount(tokenBalance);

    orderForm.setValue(amountFieldName, roundedTokenBalance);

    shouldUpdateQuote.current = true;

    setTimeout(() => {
      orderForm.trigger(amountFieldName);
    });
  };

  const resetQuoteToHistoryOrder = useCallback(() => {
    if (addressFieldName !== "fromTokenAddress") {
      return;
    }

    void updateSideAmount(tokenAmount);
  }, [tokenAmount]);

  useEffect(() => {
    if (!shouldUpdateQuote.current) {
      return;
    }

    void updateSideAmount(debouncedTokenAmount);
  }, [debouncedTokenAmount]);

  useEffect(() => {
    if (amountFieldName !== "fromTokenAmount") {
      return;
    }

    void updateSideAmount(orderForm.getValues("fromTokenAmount"));
  }, [debouncedChildSlippage]);

  useEffect(() => {
    if (amountFieldName !== "fromTokenAmount") {
      return;
    }

    void updateSideAmount(orderForm.getValues("fromTokenAmount"));
  }, [debouncedLimitPrice]);

  useEffect(() => {
    window.addEventListener(DEFI_FORM_RESET_FROM_HISTORY, resetQuoteToHistoryOrder);

    return () => {
      window.removeEventListener(DEFI_FORM_RESET_FROM_HISTORY, resetQuoteToHistoryOrder);
    };
  }, [resetQuoteToHistoryOrder]);

  useEffect(() => {
    if (amountFieldName !== "fromTokenAmount") {
      return;
    }

    void updateSideAmount(orderForm.getValues("fromTokenAmount"));
  }, [fromTokenAddress, toTokenAddress]);

  const canShowMaxButton =
    !!tokenBalance &&
    ((addressFieldName === "fromTokenAddress" && side === OrderSide.SELL) ||
      (addressFieldName === "toTokenAddress" && side === OrderSide.BUY));

  return (
    <Stack>
      <Stack direction="row" width="100%" overflow="hidden">
        {addressFieldName === "fromTokenAddress" ? (
          <TokenAmountLabel>{side === OrderSide.BUY ? "Buy" : "Sell"}</TokenAmountLabel>
        ) : (
          <TokenAmountLabel>{side === OrderSide.BUY ? "Pay" : "Receive"}</TokenAmountLabel>
        )}
        <Controller
          name={amountFieldName}
          control={orderForm.control}
          render={({ field }) => {
            return (
              <TokenAmountField
                {...field}
                disabled={props?.disabled}
                isLoading={isLoading}
                onChange={(e) => onTokenAmountChange(e.target.value)}
                error={hasError}
                sx={{ flexGrow: 1 }}
              />
            );
          }}
        />
        {canShowMaxButton ? (
          <MaxButton disabled={props?.disabled} onClick={onMaxClick} size="small">
            Max
          </MaxButton>
        ) : null}
        <Controller
          name={addressFieldName}
          control={orderForm.control}
          render={({ field }) => (
            <TokenAddressField
              chainId={chainId}
              disabled={isLoading}
              selectedTokenAddress={field.value}
              onTokenSelect={onTokenAddressChange}
            />
          )}
        />
      </Stack>
      <Typography color="error.main" fontSize={13} mt={0.5} ml={8}>
        {errorMessage}
      </Typography>
    </Stack>
  );
};

const TokenAmountLabel = ({ children }: PropsWithChildren<any>) => (
  <Typography
    variant="caption"
    color="text.secondary"
    display="flex"
    alignItems="center"
    justifyContent="center"
    minWidth={64}
    maxHeight={32}
    fontWeight="bold"
    sx={{
      backgroundColor: (theme) => theme.custom.background.secondary,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    }}
  >
    {children}
  </Typography>
);

const MaxButton = styled(AnbotoButton)(({ theme, disabled }) => ({
  maxHeight: "32px",
  backgroundColor: disabled ? "#4a5053" : theme.palette.background.paper,
  borderRadius: 0,
  color: theme.palette.text.disabled,
}));
