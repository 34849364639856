import { WidgetsGridView, WidgetsLayout } from "./types";
import { LAYOUT_VERSION } from "./constants";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { setUser } from "@src/store/actions/user.action";
import { RootState } from "@src/store/types";

export type GetDefiTokenTableParams = {
  currency: string;
  wallet_labels?: string;
};

export const anbotoWidgetsApi = anbotoApi.injectEndpoints({
  endpoints: (builder) => ({
    createLayout: builder.mutation<{ id: string }, { name: string; view: WidgetsGridView; layout: WidgetsLayout }>({
      query: ({ name, view, layout }) => ({
        method: "POST",
        url: "/v3/layouts/create_layout/",
        body: {
          name,
          view,
          layout,
          version: LAYOUT_VERSION,
        },
      }),
      async onQueryStarted(data, { dispatch, getState, queryFulfilled }) {
        const state = getState() as RootState;
        const layouts = state.user.user_layouts;

        try {
          const resp = await queryFulfilled;

          if (resp?.data?.id) dispatch(setUser({ user_layouts: [...(layouts || []), { ...data, id: resp.data.id }] }));
        } catch {
          dispatch(setUser({ user_layouts: layouts }));
        }
      },
    }),
    deleteLayout: builder.mutation<any, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/v3/layouts/${id}/`,
      }),
      async onQueryStarted(id: string, { dispatch, getState, queryFulfilled }) {
        const state = getState() as RootState;
        const layouts = state.user.user_layouts;

        dispatch(setUser({ user_layouts: (layouts || []).filter((layout) => layout.id !== id) }));

        try {
          await queryFulfilled;
        } catch {
          dispatch(setUser({ user_layouts: layouts }));
        }
      },
    }),
    updateLayout: builder.mutation<any, { name: string; id: string; view: WidgetsGridView; layout: WidgetsLayout }>({
      query: ({ id, name }) => ({
        method: "PATCH",
        url: `/v3/layouts/${id}/`,
        body: {
          name,
        },
      }),
      async onQueryStarted(
        data: { name: string; id: string; view: WidgetsGridView; layout: WidgetsLayout },
        { dispatch, getState, queryFulfilled }
      ) {
        const state = getState() as RootState;
        const userLayouts = state.user.user_layouts;
        const index = userLayouts.findIndex((layout) => layout.id === data.id);
        const layouts = [...userLayouts.filter((layout) => layout.id !== data.id)];

        layouts.splice(index, 0, data);

        dispatch(setUser({ user_layouts: layouts }));

        try {
          await queryFulfilled;
        } catch {
          dispatch(setUser({ user_layouts: userLayouts }));
        }
      },
    }),
  }),
  overrideExisting: false,
});
