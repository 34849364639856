import React from "react";
import { InstantSearch, Configure, ConfigureProps } from "react-instantsearch";
import { SearchOptions } from "@algolia/client-search";
import { AlgoliaResultsList, AlgoliaResultsListProps } from "./algolia-results-list";
import { useSymbolSelectFilters } from "./hooks/use-symbol-select-filters";
import { SymbolSelectTab } from "./types";
import { useFavoriteSymbolFilters } from "./hooks/use-favorite-symbol-filters";
import { algoliaSearchClient } from "@src/store/apis/algoliaApi";
import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";

const SymbolSelectConfigure = (props: ConfigureProps & SearchOptions) => <Configure {...props} />;

export const AlgoliaResults = ({
  currentTab,
  exchanges,
  otc,
  ...props
}: AlgoliaResultsListProps & {
  currentTab: SymbolSelectTab;
  exchanges?: Record<CefiExchangeId, boolean>;
  otc?: boolean;
}) => {
  const configureFilters = useSymbolSelectFilters(exchanges, otc);
  const favoritesFilters = useFavoriteSymbolFilters(props.search);

  let blacklist: string[] = [];
  let filters = "";

  if (currentTab === SymbolSelectTab.FAVORITES) {
    filters = favoritesFilters.filters;
    blacklist = favoritesFilters.blackList;
  }

  return (
    <InstantSearch searchClient={algoliaSearchClient} indexName="crypto_symbols">
      <SymbolSelectConfigure filters={[configureFilters, filters].filter(Boolean).join(" AND ")} />
      {([SymbolSelectTab.ALL, SymbolSelectTab.SPOT, SymbolSelectTab.FUTURE, SymbolSelectTab.CFD].includes(currentTab) ||
        props.search) && <AlgoliaResultsList {...props} blacklist={blacklist} />}
    </InstantSearch>
  );
};
