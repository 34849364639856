import React, { useState } from "react";
import { Stack, styled, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { MembersList } from "./members-list";
import { TagsInput, TagsInputRef } from "./tags-input";
import { TradePermissionButtonGroup } from "./trade-permissions-button-group";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { UserAccountsResult } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { RootState } from "@src/store/types";
import { useAppDispatch } from "@src/store/hooks";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";

export type AccountFormRef = {
  submit: () => void;
  loading: boolean;
};

type AccountFormProps = {
  account?: UserAccountsResult;
  onAfterSubmit?: () => void;
  showMembersList?: boolean;
  ref?: AccountFormRef;
  defaultName?: string;
};

export const AccountForm = React.forwardRef(
  ({ account, onAfterSubmit, showMembersList, defaultName = "" }: AccountFormProps, ref) => {
    const snackbar = useSnackbar();
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({ defaultValues: { name: account?.name || defaultName } });
    const { user_uuid } = useSelector((state: RootState) => state.user);
    const dispatch = useAppDispatch();
    const [tradePermission, setTradePermission] = useState(true);

    const [updateUserAccount, updateUserAccountMutation] = anbotoApi.useUpdateUserAccountMutation();
    const [createUserAccount, createUserAccountMutation] = anbotoApi.useCreateUserAccountMutation();
    const [sendInvite, sendInviteMutation] = anbotoApi.useSendUserAccountInviteMutation();
    const tagsInputRef = React.useRef<TagsInputRef>();

    const saveLoading = updateUserAccountMutation.isLoading || createUserAccountMutation.isLoading;
    const isAdmin = user_uuid === account?.admin_uuid;
    const newAccount = !account?.uuid;

    const onSubmit = async ({ name }) => {
      try {
        if (newAccount) {
          const { uuid } = await createUserAccount({ name }).unwrap();
          await handleSendInvite(uuid);
          snackbar.enqueueSnackbar("Account created!", { variant: "success" });
        } else {
          await updateUserAccount({ name, id: account?.uuid }).unwrap();
        }
        onAfterSubmit && onAfterSubmit();
      } catch (error) {
        snackbar.enqueueSnackbar(error?.data ? JSON.stringify(error?.data) : "Error", { variant: "error" });
      }
    };

    const handleSendInvite = async (accountId?: string) => {
      const emails = tagsInputRef.current?.getTags();

      if (!emails?.length) return false;
      if (accountId || account?.uuid) {
        const id = accountId || account?.uuid || "";
        try {
          const { results } = await sendInvite({ id, emails: emails.join(","), readOnly: !tradePermission }).unwrap();

          dispatch(anbotoApi.util.invalidateTags(["USER_ACCOUNTS"]));

          Object.keys(results).forEach((email) => {
            if (results[email].toLowerCase() === "success") {
              snackbar.enqueueSnackbar(`Invite sent to ${email}`, { variant: "success" });
            } else {
              snackbar.enqueueSnackbar(`${email} ${results[email]}`, { variant: "error" });
            }
          });

          tagsInputRef?.current?.setTags(emails.filter((email) => results[email].toLowerCase() !== "success"));
        } catch (error) {
          snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data?.detail), { variant: "error" });
        }
      }
    };

    React.useImperativeHandle(ref, () => ({
      submit: handleSubmit(onSubmit),
      loading: saveLoading,
    }));

    return (
      <>
        <Stack sx={{ flex: 1, overflow: "hidden", pt: 3, width: 600 }} gap={2}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AnbotoTextField
              fullWidth
              disabled={(!newAccount && !isAdmin) || saveLoading}
              error={Boolean(errors.name)}
              labelStyle="dynamic"
              label="Account name*"
              {...register("name", {
                required: "Must be filled out",
              })}
              name="name"
            />
          </form>
          {showMembersList && (
            <Members direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="body1" color={(theme) => theme.custom.text.dim}>
                Members
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {account?.members?.length || 0} members joined
              </Typography>
            </Members>
          )}
          {account && showMembersList && (
            <MembersList isAdmin={isAdmin} account={account} sx={{ flex: 1, overflow: "auto" }} />
          )}
        </Stack>
        {(isAdmin || newAccount) && (
          <Stack sx={{ pt: 1, mt: showMembersList ? undefined : 3 }}>
            {!newAccount && (
              <Stack direction="row" alignItems="center" gap={1}>
                <Stack>
                  <Typography variant="body2" fontWeight="bold">
                    Invite people to this account (Optional)
                  </Typography>
                  <TagsInput
                    wide={!showMembersList}
                    ref={tagsInputRef}
                    sx={{ mt: 1, mb: 1 }}
                    placeholder="Emails, comma separated"
                  />
                </Stack>
                <Stack height="100%">
                  <Typography variant="body2" fontWeight="bold" mb={1.1}>
                    Permissions
                  </Typography>
                  <TradePermissionButtonGroup onChange={setTradePermission} value={tradePermission} />
                </Stack>

                <AnbotoButton
                  sx={{ minWidth: 160, mt: 2 }}
                  variant="contained"
                  color="primary"
                  onClick={() => setTimeout(handleSendInvite)}
                  loading={sendInviteMutation.isLoading}
                >
                  Send invite
                </AnbotoButton>
              </Stack>
            )}
            {/* <Typography variant="body2" color={(theme) => theme.custom.colors.dim}>
              Anyone in the team have access to this account
            </Typography> */}
          </Stack>
        )}
      </>
    );
  }
);

AccountForm.displayName = "AccountForm";

const Members = styled(Stack)(({ theme }) => ({
  backgroundColor: "#232C2F",
  height: 40,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  flexShrink: 0,
}));
