import React from "react";
import { SymbolListItem } from "./symbol-list-item";
import { CefiExchangeId, OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { getExchangeIdByExchangeName } from "@src/store/apis/anbotoApi/utils";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";

interface RecentSymbolsListProps {
  search: string;
  onSelect: (symbol: OrderSymbol) => void;
  onRemoveClick: (symbol: OrderSymbol) => void;
  items: OrderSymbol[];
  exchanges: Record<CefiExchangeId, boolean>;
}

export const RecentSymbolsList = ({ search, onSelect, onRemoveClick, items, exchanges }: RecentSymbolsListProps) => {
  const filteredSymbols = (items || []).filter(({ symbol, exchange, market_type }) => {
    const words = search.toLowerCase().split(" ").filter(Boolean);

    if (!exchanges[getExchangeIdByExchangeName(exchange)]) return false;

    for (let i = 0; i < words.length; i++) {
      if (!`${symbol}${exchange}${market_type}`.toLowerCase().includes(words[i])) return false;
    }

    return true;
  });

  return (
    <>
      {filteredSymbols.map((symbol) => (
        <SymbolListItem
          key={getLivePriceId(symbol)}
          symbol={symbol}
          onSelect={() => onSelect(symbol)}
          onCloseClick={() => onRemoveClick(symbol)}
        />
      ))}
    </>
  );
};
