import React from "react";
import { ClickAwayListener, Paper, Popper, PopperProps, styled } from "@mui/material";
import { SetupApiKeyMenuItem } from "./setup-api-key-menu-item";

interface SetupApiKeyModalProps extends PopperProps {
  onClickAway?: () => void;
}

export const SetupApiKeyModal = ({ onClickAway, ...props }: SetupApiKeyModalProps) => {
  const handleClickAway = () => {
    onClickAway && onClickAway();
  };

  return (
    <Popper {...props}>
      <SetupApiKeysPaper>
        <ClickAwayListener onClickAway={handleClickAway}>
          <SetupApiKeyMenuItem />
        </ClickAwayListener>
      </SetupApiKeysPaper>
    </Popper>
  );
};

const SetupApiKeysPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  overflow: "hidden",
  marginTop: 2,
}));
