import { WidgetConfig, WidgetName } from "../../types";
import { MAX_GROUPS } from "../../constants";

export const initialLayout = {
  xxl: { x: 3, y: 0, w: 8, h: 22, minW: 3, minH: 15 },
  xl: { x: 4, y: 0, w: 7, h: 22, minW: 5, minH: 10 },
  lg: { x: 6, y: 0, w: 8, h: 22, minW: 5, minH: 10 },
  md: { x: 8, y: 0, w: 8, h: 22, minW: 4, minH: 3 },
  sm: { x: 14, y: 0, w: 10, h: 22, minW: 7, minH: 5 },
  xs: { x: 0, y: 19, w: 24, h: 22, minW: 7, minH: 5 },
};

export const CefiSymbolChartWidgetConfig = {
  name: WidgetName.CefiSymbolChart,
  layout: {
    xxl: { x: 3, y: 0, w: 15, h: 22, minW: 3, minH: 15 },
    xl: { x: 4, y: 0, w: 14, h: 22, minW: 4, minH: 12 },
    lg: { x: 12, y: 0, w: 12, h: 22, minW: 5, minH: 10 },
    md: { x: 8, y: 0, w: 16, h: 22, minW: 6, minH: 10 },
    sm: { x: 10, y: 0, w: 14, h: 22, minW: 12, minH: 10 },
    xs: { x: 0, y: 19, w: 24, h: 22, minW: 12, minH: 10 },
  },
  options: {
    multiple: true,
    removable: true,
    withGroups: true,
    maxNumber: MAX_GROUPS,
    symbolSelect: true,
  },
} as WidgetConfig;
