import React from "react";
import { ButtonProps, Stack, StackProps, Typography, styled } from "@mui/material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

export const Card = ({
  children,
  title,
  titlePostfix,
  onSuccessButtonClick,
  onDismissButtonClick,
  successButtonProps,
  successButtonLabel = "Save",
  dismissButtonLabel = "Cancel",
}: React.PropsWithChildren<
  {
    title: string;
    titlePostfix?: React.ReactElement;
    onSuccessButtonClick?: () => void;
    onDismissButtonClick?: () => void;
    successButtonProps?: ButtonProps;
    successButtonLabel?: string;
    dismissButtonLabel?: string;
  } & StackProps
>) => {
  return (
    <Stack minHeight="100%" paddingTop={7.5} paddingBottom={4} alignItems="center" overflow="auto" flexGrow={1}>
      <Root>
        <Header>
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography variant="body2" fontSize={20} fontWeight="bold">
              {title}
            </Typography>
            {titlePostfix}
          </Stack>
          <Stack direction="row" alignItems="center" gap={2}>
            {onDismissButtonClick && (
              <AnbotoButton size="small" onClick={onDismissButtonClick} variant="outlined" sx={{ minWidth: 160 }}>
                {dismissButtonLabel}
              </AnbotoButton>
            )}
            {onSuccessButtonClick && (
              <AnbotoButton
                size="small"
                onClick={onSuccessButtonClick}
                variant="contained"
                sx={{ minWidth: 160 }}
                {...successButtonProps}
              >
                {successButtonLabel}
              </AnbotoButton>
            )}
          </Stack>
        </Header>
        <Stack padding={6} minHeight={600} flex={1}>
          {children}
        </Stack>
      </Root>
    </Stack>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  width: "80%",
  background: theme.palette.background.paper,
  border: `1px solid #232C2F`,
  borderRadius: theme.shape.borderRadius,
  flex: 1,
}));

const Header = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  borderBottom: `2px solid #232C2F`,
  height: 80,
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
}));
