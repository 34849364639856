import React, { useEffect, useState } from "react";
import { Box, DialogProps, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import { useAccount } from "wagmi";
import { waitForTransactionReceipt } from "@wagmi/core";
import { SettingsDialog } from "../../settings-dialog";
import { useDepositAndWithdrawForm } from "../utils/useDepositAndWithdrawForm";
import { feeManagerApi } from "../api";
import { UseDepositType } from "../overview-token-fees";
import { AnbotoConnectWalletButton } from "../components/anboto-wallet-connect-button";
import { FeeWalletWarning } from "../fee-wallet-warning";
import { DepositInputs } from "./deposit-inputs";
import { WrongChainAlert } from "@src/pages/defi/WrongChainAlert";
import { RootState } from "@src/store/types";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { getMultipliedTokenAmountInUnits } from "@src/utils/getTokenUnits";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { feeManagerContractApi } from "@src/store/apis/blockchainApi/fee-manager";
import { ChainId } from "@src/pages/defi/types";
import DepositSteps from "@src/pages/settings/fee-manager/deposit-flow-dialog/deposit-steps";
import { wagmiConfig } from "@src/pages/defi/wagmi-config";

type DepositFlowDialogProps = DialogProps & {
  onClose(onAfterSubmit?: boolean): void;
  useDeposit: UseDepositType;
} & {};

export enum TeamSCProps {
  Uuid = 0,
  Owner = 1,
  IsValid = 2,
}

export const DepositFlowDialog = ({ onClose, useDeposit, ...props }: DepositFlowDialogProps) => {
  const depositForm = useDepositAndWithdrawForm();
  const { address, chain } = useAccount();

  const [activeStep, setActiveStep] = useState(0);

  const snackbar = useSnackbar();

  const { team_uuid } = useSelector((state: RootState) => state.user);
  const chainId = depositForm.watch("chainId");
  const tokenAddress = depositForm.watch("tokenAddress");
  const depositDisabled = depositForm.watch("isDisabled");
  const tokenDecimals = depositForm.watch("tokenDecimals");

  const [symbolsReccord, setSymbolsReccord] = useState<Record<string, Record<"symbol" | "coinGeckoId", string>>>({
    "": { symbol: "", coinGeckoId: "" },
  });
  const [confirmationLoading, setConfirmationLoading] = useState<boolean>(false);

  const possibleTokens = feeManagerApi.useGetPossibleTokenFeesQuery();
  const userTokenFees = feeManagerApi.useGetFeeTokensPreferenceQuery({ team_uuid });
  const getFeeManagerContracts = feeManagerApi.useGetFeeManagerContractsQuery();
  const [forceUpdateAccounting] = feeManagerApi.useLazyGetForceUpateAccountingQuery();
  const [getFeeTokensAndBalances] = feeManagerApi.useLazyGetFeeTokensAndBalancesQuery();
  const [getUserTokenFees] = feeManagerApi.useLazyGetFeeTokensPreferenceQuery();
  const [addTokenFee] = feeManagerApi.useAddTokenFeesMutation();
  const [getAllowance] = blockchainApi.useLazyGetAllowanceQuery();
  const [approve] = blockchainApi.useApproveMutation();
  const [getTeamStatus] = feeManagerContractApi.useLazyTeamsQuery();
  const [createTeam] = feeManagerContractApi.useCreateTeamMutation();
  const [depositFee] = feeManagerContractApi.useDepositFeeMutation();

  useEffect(() => {
    if (possibleTokens.data) {
      const newSymbolsReccord = {};
      possibleTokens.data.forEach((token) => {
        newSymbolsReccord[token.token_address] = {
          symbol: token.token_symbol,
          coinGeckoId: token.coin_gecko_id,
        };
      });
      setSymbolsReccord(newSymbolsReccord);

      if (useDeposit.chainFee && useDeposit.tokenFee) {
        depositForm.setValue("chainId", useDeposit.chainFee);
        depositForm.setValue("tokenAddress", useDeposit.tokenFee);
        depositForm.setValue("tokenSymbol", newSymbolsReccord[useDeposit.tokenFee]["symbol"]);
      } else {
        depositForm.setValue("chainId", ChainId.ARBITRUM);
      }
    }
  }, [possibleTokens.data, useDeposit.chainFee, useDeposit.tokenFee]);

  const submitAddTokenFee = async (tokenAddress: string, choicePosition: number) => {
    try {
      await addTokenFee({
        body: {
          token_address: tokenAddress,
          choice_position: choicePosition,
        },
        team_uuid: team_uuid,
      }).unwrap();
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data), { variant: "error" });
    }
  };

  const [isFirstTimeDeposit, setIsFirstTimeDeposit] = useState(false);
  const checkIfFirstTimeDeposit = async () => {
    const contract = getFeeManagerContracts.data?.find((contract) => contract.network_id === chainId);

    if (contract) {
      const teamSC = await getTeamStatus({ contract, team_uuid }).unwrap();
      setIsFirstTimeDeposit(!teamSC[TeamSCProps.IsValid]);
    } else {
      setIsFirstTimeDeposit(true);
    }
  };

  useEffect(() => {
    checkIfFirstTimeDeposit();
  }, [chainId]);
  // }, [chainId, walletProvider]);

  const onConfirmDeposit = async () => {
    const inputedAmount = depositForm.getValues("amount");
    setConfirmationLoading(true);
    try {
      if (getFeeManagerContracts && address) {
        const contract = getFeeManagerContracts.data?.find((contract) => contract.network_id === chainId);
        if (contract) {
          const amount = getMultipliedTokenAmountInUnits(inputedAmount, tokenDecimals);
          // update data off chain to keep track of tokens used
          if (
            userTokenFees.data &&
            !userTokenFees.data?.find((token) => token.details.token_address === tokenAddress)
          ) {
            try {
              // we create the team/account per chain on per first deposit
              const teamSC = await getTeamStatus({ contract, team_uuid }).unwrap();

              if (!teamSC[TeamSCProps.IsValid]) {
                try {
                  const hash = await createTeam({ contract, team_uuid, walletAdmin: address }).unwrap();
                  snackbar.enqueueSnackbar("On chain sync, please wait...", { variant: "info", persist: true });

                  if (hash) {
                    await waitForTransactionReceipt(wagmiConfig, { hash });
                  }
                  snackbar.closeSnackbar();
                  setActiveStep(1);
                } catch (error) {
                  console.log(error);
                  snackbar.closeSnackbar();
                  setConfirmationLoading(false);
                  return snackbar.enqueueSnackbar("Something happenned when signing the transaction", {
                    variant: "error",
                  });
                }
              }
              // submitAddTokenFee: this method updates the user token preference list
              // by adding a new token in the list
              await submitAddTokenFee(tokenAddress, userTokenFees.data.length + 1);
            } catch (error) {
              console.log(error);
              setConfirmationLoading(false);
              return snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data), { variant: "error" });
            }
          }

          const allowance = await getAllowance({
            chainId: contract.network_id,
            tokenAddress: tokenAddress,
            walletAddress: address,
            feeManagerContract: contract.contract_address,
          }).unwrap();

          setActiveStep(2);

          // giving more allowance in case the curent one is too low
          const allowanceExceeded = new BigNumber(allowance).lt(inputedAmount);
          if (allowanceExceeded) {
            const hash = await approve({
              chainId,
              tokenAddress,
              tokenAmount: amount,
              contractAddress: contract.contract_address,
            }).unwrap();
            snackbar.enqueueSnackbar("Allowance in progress, please wait...", { variant: "info", persist: true });

            if (hash) {
              await waitForTransactionReceipt(wagmiConfig, {
                hash,
              });
            }
            snackbar.closeSnackbar();
          }

          const hash = await depositFee({ contract, team_uuid, amount, tokenAddress }).unwrap();
          snackbar.enqueueSnackbar("Deposit in progress, please wait...", { variant: "info", persist: true });

          if (hash) {
            await waitForTransactionReceipt(wagmiConfig, { hash });
          }

          await forceUpdateAccounting().unwrap();

          const tokenIsInPreference = userTokenFees.data?.find((token) => token.details.token_address === tokenAddress);
          // userTokenFees is a list of tokens (drag & drop list) currently used by the user
          // tokenAddress is the address of the token of the current deposit
          // if tokenAddress is not in userTokenFees it means the preference list needs to be refreshed
          if (!tokenIsInPreference) {
            await getUserTokenFees({ team_uuid });
          }

          snackbar.closeSnackbar();
          snackbar.enqueueSnackbar("Deposit successful", { variant: "success" });

          setConfirmationLoading(false);
          useDeposit.hide();
          await getFeeTokensAndBalances();
          return hash;
        }
      }
    } catch (error) {
      console.log(error);
      snackbar.closeSnackbar();
      snackbar.enqueueSnackbar("Something happenned when signing the transaction", { variant: "error" });
      setConfirmationLoading(false);
    }
  };

  return (
    <SettingsDialog
      title={address ? "Recharge Fee Wallet" : "Connect Wallet"}
      onClose={onClose}
      onOverlayClick={onClose}
      {...props}
      actions={
        address && (
          <Stack direction="row" justifyContent="end" width="100%">
            <AnbotoButton
              sx={{ minWidth: 160 }}
              variant="contained"
              color="primary"
              size="small"
              disabled={depositDisabled}
              onClick={onConfirmDeposit}
              loading={confirmationLoading}
            >
              Confirm
            </AnbotoButton>
          </Stack>
        )
      }
    >
      <Stack alignItems="center" position="relative" height="100%">
        <FeeWalletWarning sx={{ marginTop: 2 }} />
        {address ? (
          <>
            <Box width="480px" mt={2}>
              {chain?.id !== chainId && <WrongChainAlert formChainId={chainId} />}
              <DepositInputs depositForm={depositForm} symbolsReccord={symbolsReccord} />
            </Box>
            {isFirstTimeDeposit && <DepositSteps step={activeStep} />}
          </>
        ) : (
          <AnbotoConnectWalletButton sx={{ position: "absolute", top: "50%" }} />
        )}
      </Stack>
    </SettingsDialog>
  );
};
