import React, { useEffect, useState } from "react";
import { DialogProps, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { waitForTransactionReceipt } from "@wagmi/core";
import { feeManagerApi } from "../api";
import { SettingsDialog } from "../../settings-dialog";
import { useDepositAndWithdrawForm } from "../utils/useDepositAndWithdrawForm";
import { UseWithdrawType } from "../overview-token-fees";
import { AnbotoConnectWalletButton } from "../components/anboto-wallet-connect-button";
import { FeeManagerContract } from "../api/types";
import { WithdrawFormType, WithdrawInputs } from "./withdraw-inputs";
import { WrongChainAlert } from "@src/pages/defi/WrongChainAlert";
import { RootState } from "@src/store/types";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { WithdrawError, feeManagerContractApi } from "@src/store/apis/blockchainApi/fee-manager";
import { wagmiConfig } from "@src/pages/defi/wagmi-config";

type WithdrawalFlowDialogProps = DialogProps & {
  onClose(onAfterSubmit?: boolean): void;
  useWithdraw: UseWithdrawType;
} & {};

export const WithdrawalFlowDialog = ({ onClose, useWithdraw, ...props }: WithdrawalFlowDialogProps) => {
  const { address } = useAccount();
  const withdrawalRequestForm = useDepositAndWithdrawForm();
  const withdrawalClaimForm = useDepositAndWithdrawForm();

  const snackbar = useSnackbar();

  const { team_uuid } = useSelector((state: RootState) => state.user);
  const { chain } = useAccount();
  const chainId = withdrawalRequestForm.watch("chainId");

  const [symbolsReccord, setSymbolsReccord] = useState<Record<string, string>>({ "": "" });
  const [confirmationLoading, setConfirmationLoading] = useState<boolean>(false);

  const [getTokenDecimals] = blockchainApi.useLazyGetTokenDecimalsQuery();
  const [forceUpdateAccounting] = feeManagerApi.useLazyGetForceUpateAccountingQuery();
  const possibleTokens = feeManagerApi.useGetPossibleTokenFeesQuery();
  const getFeeManagerContracts = feeManagerApi.useGetFeeManagerContractsQuery();
  const [getFeeTokensAndBalances] = feeManagerApi.useLazyGetFeeTokensAndBalancesQuery();

  const [requestFeeWithdrawal] = feeManagerContractApi.useRequestFeeWithdrawalMutation();
  const [claimFeeWithdrawal] = feeManagerContractApi.useClaimFeeWithdrawalMutation();

  useEffect(() => {
    if (possibleTokens.data) {
      const newSymbolsReccord = {};
      possibleTokens.data.forEach((token) => {
        return (newSymbolsReccord[token.token_address] = token.token_symbol);
      });
      setSymbolsReccord(newSymbolsReccord);
      if (useWithdraw.chainFee && useWithdraw.tokenFee) {
        withdrawalRequestForm.setValue("chainId", useWithdraw.chainFee);
        withdrawalRequestForm.setValue("tokenAddress", useWithdraw.tokenFee);
        withdrawalRequestForm.setValue("tokenSymbol", newSymbolsReccord[useWithdraw.tokenFee]);

        withdrawalClaimForm.setValue("chainId", useWithdraw.chainFee);
        withdrawalClaimForm.setValue("tokenAddress", useWithdraw.tokenFee);
        withdrawalClaimForm.setValue("tokenSymbol", newSymbolsReccord[useWithdraw.tokenFee]);
      }
    }
  }, [possibleTokens.data, useWithdraw.chainFee, useWithdraw.tokenFee]);

  useEffect(() => {
    if (useWithdraw.chainFee && useWithdraw.tokenFee) {
      withdrawalRequestForm.setValue("chainId", useWithdraw.chainFee);
      withdrawalRequestForm.setValue("tokenAddress", useWithdraw.tokenFee);

      withdrawalClaimForm.setValue("chainId", useWithdraw.chainFee);
      withdrawalClaimForm.setValue("tokenAddress", useWithdraw.tokenFee);
    }
  }, [useWithdraw.chainFee, useWithdraw.tokenFee]);

  const onConfirmWithdraw = async (form: WithdrawFormType) => {
    setConfirmationLoading(true);
    try {
      if (getFeeManagerContracts) {
        const chainId = form.getValues("chainId");
        const tokenAddress = form.getValues("tokenAddress");
        const inputedAmount = form.getValues("amount");
        const contract: FeeManagerContract = getFeeManagerContracts.data?.find(
          (contract) => contract.network_id === chainId
        );

        if (contract) {
          const decimals = await getTokenDecimals({ chainId: chainId, tokenAddress: tokenAddress }).unwrap();
          const amount = (parseFloat(inputedAmount) * 10 ** decimals).toString();

          let hash;
          if (form === withdrawalRequestForm) {
            hash = await requestFeeWithdrawal({ contract, team_uuid, amount, tokenAddress }).unwrap();
          } else {
            hash = await claimFeeWithdrawal({ contract, team_uuid, amount, tokenAddress }).unwrap();
          }

          if (hash === WithdrawError.UnauthorizedWallet) {
            snackbar.enqueueSnackbar("Unauthorized Wallet, please use your admin wallet to sign the transaction", {
              variant: "error",
            });
            return;
          }
          snackbar.enqueueSnackbar("Pending transaction, please wait...", { variant: "info", persist: true });

          await waitForTransactionReceipt(wagmiConfig, { hash });
          snackbar.closeSnackbar();

          if (form === withdrawalClaimForm) {
            await forceUpdateAccounting().unwrap();
          }

          snackbar.enqueueSnackbar(form === withdrawalRequestForm ? "Request successful" : "Withdraw successful", {
            variant: "success",
          });
          setConfirmationLoading(false);
          await getFeeTokensAndBalances();
          useWithdraw.hide();
        }
      }
    } catch (error) {
      snackbar.closeSnackbar();
      snackbar.enqueueSnackbar("Some error happened when signing...", {
        variant: "error",
      });
      setConfirmationLoading(false);
    }
  };

  return (
    <SettingsDialog
      title={address ? "Withdraw Fund" : "Connect Wallet"}
      onClose={onClose}
      onOverlayClick={onClose}
      {...props}
    >
      <Stack sx={{ height: "100%" }} justifyContent="center" alignItems="center">
        {address ? (
          <>
            {chain?.id !== chainId && <WrongChainAlert formChainId={chainId} />}
            <WithdrawInputs
              withdrawalRequestForm={withdrawalRequestForm}
              withdrawalClaimForm={withdrawalClaimForm}
              symbolsReccord={symbolsReccord}
              onConfirmWithdraw={onConfirmWithdraw}
              confirmationLoading={confirmationLoading}
            />
          </>
        ) : (
          <AnbotoConnectWalletButton />
        )}
      </Stack>
    </SettingsDialog>
  );
};
