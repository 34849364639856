import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";

export type GroupBy = "days" | "weeks" | "months";

export interface Metrics {
  tradesMetricsFilters: {
    tradeTypes: string[];
    strategies: string[];
    assetClasses: string[];
    exchanges: string[];
    groupBy: GroupBy;
    sides: string[];
  };
  historyFilters: {
    tradeTypes: string[];
    strategies: string[];
    sides: string[];
    exchanges: string[];
    period: GetOrdersPeriod;
  };
}

const initialState: Metrics = {
  tradesMetricsFilters: {
    tradeTypes: [],
    strategies: [],
    assetClasses: [],
    exchanges: [],
    groupBy: "months",
    sides: [],
  },
  historyFilters: {
    tradeTypes: [],
    strategies: [],
    sides: [],
    exchanges: [],
    period: GetOrdersPeriod.All,
  },
};

export const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    setTradesGroupBy: (state, action: PayloadAction<GroupBy>) => {
      state.tradesMetricsFilters.groupBy = action.payload;
    },
    setTradesFiltersTradeType: (state, action: PayloadAction<string[]>) => {
      state.tradesMetricsFilters.tradeTypes = action.payload;
    },
    setTradesFiltersStrategy: (state, action: PayloadAction<string[]>) => {
      state.tradesMetricsFilters.strategies = action.payload;
    },
    setTradesFiltersAssetsClass: (state, action: PayloadAction<string[]>) => {
      state.tradesMetricsFilters.assetClasses = action.payload;
    },
    setTradesFiltersExchanges: (state, action: PayloadAction<string[]>) => {
      state.tradesMetricsFilters.exchanges = action.payload;
    },
    setTradesFiltersSides: (state, action: PayloadAction<string[]>) => {
      state.tradesMetricsFilters.sides = action.payload;
    },

    setHistoryFiltersTradeType: (state, action: PayloadAction<string[]>) => {
      state.historyFilters.tradeTypes = action.payload;
    },
    setHistoryFiltersStrategy: (state, action: PayloadAction<string[]>) => {
      state.historyFilters.strategies = action.payload;
    },
    setHistoryFiltersSides: (state, action: PayloadAction<string[]>) => {
      state.historyFilters.sides = action.payload;
    },
    setHistoryFiltersExchanges: (state, action: PayloadAction<string[]>) => {
      state.historyFilters.exchanges = action.payload;
    },
    setHistoryPeriod: (state, action: PayloadAction<GetOrdersPeriod>) => {
      state.historyFilters.period = action.payload;
    },
  },
});

export const {
  setTradesFiltersTradeType,
  setTradesFiltersExchanges,
  setTradesFiltersStrategy,
  setTradesFiltersAssetsClass,
  setTradesFiltersSides,
  setHistoryFiltersStrategy,
  setHistoryFiltersTradeType,
  setHistoryFiltersSides,
  setHistoryFiltersExchanges,
  setHistoryPeriod,
  setTradesGroupBy,
} = metricsSlice.actions;

export default metricsSlice.reducer;
