import { useGetPriceQuery } from "@src/store/apis/cryptocompareApi";

export interface UseCurrencyMultiplicatorResult {
  isFetching: boolean;
  multiplicator: number;
}

export const useCurrencyMultiplicator = (currency: string): UseCurrencyMultiplicatorResult => {
  const { data, isFetching } = useGetPriceQuery({ fsym: "USD", tsyms: currency });

  return {
    isFetching,
    multiplicator: data && data[currency] ? data[currency] : 1,
  };
};
