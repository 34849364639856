import React from "react";
import { Stack, Skeleton } from "@mui/material";

export const WithLoader = ({ children, loading }: React.PropsWithChildren & { loading?: boolean }) => {
  return (
    <Stack sx={{ height: 24, lineHeight: "23.24px !important", fontSize: "14px !important" }}>
      {loading ? <Skeleton sx={{ height: 23, minWidth: 40 }} /> : children}
    </Stack>
  );
};
