import { useAppSelector } from "@src/store/hooks";
import { MenuItemId } from "@src/components/layout/main-layout/main-menu-dropdown";

export const TESTNET_WHITELIST: MenuItemId[] = [
  MenuItemId.Cex,
  MenuItemId.Api,
  MenuItemId.Documentation,
  MenuItemId.Support,
  MenuItemId.OrderPreferences,
  MenuItemId.TransactionHistory,
  MenuItemId.Account,
  MenuItemId.Metrics,
  MenuItemId.FundingRates,
];

export const useTestnetEnabled = () => {
  return useAppSelector((state) => state.user.is_testnet);
};
