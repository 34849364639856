import { QuantityStatus } from "@src/pages/settings/fee-manager/components/unlocking-label-button";
import { FeeBalance } from "@src/pages/settings/fee-manager/hooks/use-fee-manager-balances";

export const getBalanceAndStatus = (
  feeBalance: FeeBalance | undefined
): { balance: string; status: QuantityStatus } => {
  if (!feeBalance) return { balance: "0", status: QuantityStatus.LOCKED };

  const { claimableBalance, unclaimableBalance } = feeBalance;

  const balance = parseFloat(claimableBalance || "0") > 0 ? claimableBalance : unclaimableBalance || "0";
  const status = parseFloat(claimableBalance || "0") > 0 ? QuantityStatus.UNLOCKED : QuantityStatus.LOCKED;

  return { balance, status };
};
