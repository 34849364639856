import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const ExpandRow = ({ expanded, onClick }: { expanded: boolean; onClick: () => void }) => {
  const styles = { color: "#636B6F", cursor: "pointer", fontSize: 18 };

  return expanded ? (
    <ArrowDropUpIcon sx={styles} onClick={onClick} />
  ) : (
    <ArrowDropDownIcon sx={styles} onClick={onClick} />
  );
};

export const MemoizedExpandRow = React.memo(ExpandRow, (prev, next) => prev.expanded === next.expanded);

MemoizedExpandRow.displayName = "MemoizedExpandRow";
