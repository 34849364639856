import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { ReactComponent as TransferCompletedIcon } from "@src/assets/svg/transfer-completed.svg";

export const SendCompleted: FC = () => {
  return (
    <Stack direction="column" gap={1} alignItems="center">
      <TransferCompletedIcon />
      <Typography variant="subtitle1" fontSize={24}>
        Send completed
      </Typography>
      <Typography>Your transaction is on the way!</Typography>
    </Stack>
  );
};
