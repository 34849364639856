import { useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useSnackbar } from "notistack";
import { useAppSelector } from "@src/store/hooks";
import { feeManagerApi } from "@src/pages/settings/fee-manager/api";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { feeManagerContractApi } from "@src/store/apis/blockchainApi/fee-manager";

export type FeeToken = {
  chainId: number;
  tokenAddress: string;
};

export type FeeBalance = {
  requestableBalance: string;
  unclaimableBalance: string;
  claimableBalance: string;
};

export type ReadBalance = {
  balance: number;
  claimableWithdrawals: number;
  collectableBalance: number;
  requestableBalance: number;
  unclaimableWithdrawals: number;
};

type useFeeManagerBalancesProps = {
  feeTokens: FeeToken[];
};

type useFeeManagerBalancesResult = {
  feeBalances: { [key: string]: FeeBalance }; // key = chainId + tokenBalance
};

export const useFeeManagerBalances = ({ feeTokens }: useFeeManagerBalancesProps): useFeeManagerBalancesResult => {
  const [feeBalances, setFeeBalances] = useState<{ [key: string]: FeeBalance }>({});

  const snackbar = useSnackbar();

  const { team_uuid } = useAppSelector((state) => state.user);

  const getFeeManagerContracts = feeManagerApi.useGetFeeManagerContractsQuery();
  const [getTokenDecimals] = blockchainApi.useLazyGetTokenDecimalsQuery();
  const [getFeeManagerBalance] = feeManagerContractApi.useLazyGetBalanceQuery();

  const fetchFeeBalances = async () => {
    try {
      for (const feeToken of feeTokens) {
        if (feeToken.tokenAddress.length === 0) return;

        const contract = getFeeManagerContracts.data?.find((contract) => contract.network_id === feeToken.chainId);

        if (contract) {
          const decimals = await getTokenDecimals({
            chainId: feeToken.chainId,
            tokenAddress: feeToken.tokenAddress,
          }).unwrap();

          const balance = await getFeeManagerBalance({
            contract,
            team_uuid,
            tokenAddress: feeToken.tokenAddress,
          }).unwrap();

          const requestableBalance = new BigNumber(balance.requestableBalance).div(10 ** decimals).toFixed();

          const unclaimableBalance = new BigNumber(balance.unclaimableWithdrawals).div(10 ** decimals).toFixed();

          const claimableBalance = new BigNumber(balance.claimableWithdrawals).div(10 ** decimals).toFixed();
          const currentFeeBalance = { requestableBalance, unclaimableBalance, claimableBalance };

          setFeeBalances((prevState) => ({
            ...prevState,
            [feeToken.chainId + feeToken.tokenAddress]: currentFeeBalance,
          }));
        }
      }
    } catch (e) {
      snackbar.enqueueSnackbar(e, { variant: "error" });
    }
  };

  useEffect(() => {
    if (feeTokens) fetchFeeBalances();
  }, [feeTokens]);

  return { feeBalances };
};
