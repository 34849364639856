import { Box, styled } from "@mui/material";
import { CELL_FONT_SIZE } from "./constants";
import { styledOptions } from "@src/utils/styled-options";

export const TableCell = styled(
  Box,
  styledOptions
)<{ loading?: boolean; expandable?: boolean; shouldUpdate?: any }>(({ theme, loading, expandable }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: CELL_FONT_SIZE,
  display: "flex",
  alignItems: "center",
  flexWrap: "nowrap",
  paddingTop: theme.spacing(0.25),
  paddingRight: loading ? 0 : theme.spacing(0.5),
  paddingbottom: theme.spacing(0.25),
  paddingLeft: loading ? 0 : theme.spacing(0.5),
  minHeight: 32,
  flexShrink: 1,
  flexGrow: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  "&:first-of-type": {
    paddingLeft: expandable ? undefined : theme.spacing(1),
  },
  background: theme.palette.background.paper,
}));
