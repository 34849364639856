import React, { useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  RectangleProps,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import { Stack, useTheme } from "@mui/material";
import { COLORS } from "@src/pages/portfolio/constant";
import HistoricalDataTooltip from "@src/pages/analytics/funding-rates/historical-data-funding-rates/historical-data-tooltip";
import {
  get3MonthAgoTimeStamp,
  getMonthCount,
  percentFormatter,
  reduceBoxPlotData,
} from "@src/pages/analytics/helpers";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import AngleAxisTimeTick from "@src/pages/analytics/funding-rates/funding-rates-assets-history/angle-axis-time-tick";
import { MAX_HISTORICAL_DATA_BARS } from "@src/pages/analytics/constant";
import { BoxPlotData } from "@src/pages/analytics/types";

type HistoricalDataChartProps = {
  boxPlotData: BoxPlotData[];
};

const DotBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (!x || !y || !width || !height) {
    return null;
  }

  return <line x1={x + width / 2} y1={y + height} x2={x + width / 2} y2={y} stroke={COLORS[0]} strokeWidth={1} />;
};

const HorizonBar = (props: RectangleProps) => {
  const { x, y, width, height } = props;

  if (!x || !y || !width || !height) {
    return null;
  }

  return <line x1={x + width / 4} y1={y} x2={x + (width / 4) * 3} y2={y} />;
};

const HistoricalDataChart = ({ boxPlotData }: HistoricalDataChartProps) => {
  const theme = useTheme();

  const lastDate = boxPlotData.reduce((time, item) => (item.time > time ? item.time : time), 0);
  const threeMonthAgoTimestamp = get3MonthAgoTimeStamp(lastDate * 1000);
  const lastThreeMonthData = boxPlotData.filter((x) => x.time >= threeMonthAgoTimestamp);

  const initialState = {
    data: lastThreeMonthData,
    refAreaLeft: "",
    refAreaRight: "",
    animation: false,
  };

  const [chartState, setChartState] = useState(initialState);

  const zoom = () => {
    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setChartState({
        ...chartState,
        refAreaLeft: "",
        refAreaRight: "",
      });
      return;
    }

    if (getMonthCount(+refAreaLeft * 1000, +refAreaRight * 1000) > 4) {
      setChartState({
        ...chartState,
        refAreaLeft: "",
        refAreaRight: "",
        data: reduceBoxPlotData(
          [...boxPlotData].filter((x) =>
            refAreaLeft <= refAreaRight
              ? Number(refAreaLeft) <= x.time && x.time <= Number(refAreaRight)
              : Number(refAreaRight) <= x.time && x.time <= Number(refAreaLeft)
          ),
          MAX_HISTORICAL_DATA_BARS
        ),
      });

      return;
    }

    setChartState({
      ...chartState,
      refAreaLeft: "",
      refAreaRight: "",
      data:
        getMonthCount(+refAreaLeft * 1000, +refAreaRight * 1000) > 4
          ? reduceBoxPlotData(
              [...boxPlotData].filter((x) =>
                refAreaLeft <= refAreaRight
                  ? Number(refAreaLeft) <= x.time && x.time <= Number(refAreaRight)
                  : Number(refAreaRight) <= x.time && x.time <= Number(refAreaLeft)
              ),
              MAX_HISTORICAL_DATA_BARS
            )
          : [...boxPlotData].filter((x) =>
              refAreaLeft <= refAreaRight
                ? Number(refAreaLeft) <= x.time && x.time <= Number(refAreaRight)
                : Number(refAreaRight) <= x.time && x.time <= Number(refAreaLeft)
            ),
    });
  };

  const zoomOut = () => {
    setChartState({
      ...chartState,
      data: reduceBoxPlotData(boxPlotData.slice(), MAX_HISTORICAL_DATA_BARS),
      refAreaLeft: "",
      refAreaRight: "",
    });
  };

  const { data, refAreaLeft, refAreaRight } = chartState;

  return (
    <Stack direction="column">
      <Stack marginBottom={0.5} direction="row" justifyContent="flex-end">
        <AnbotoButton
          data-testid="historical-data-zoom-button"
          sx={{ width: 150, alignSelf: "right" }}
          size="small"
          variant="outlined"
          onClick={zoomOut}
        >
          Zoom Out
        </AnbotoButton>
      </Stack>

      <ResponsiveContainer width={"100%"} minHeight={"500px"}>
        <ComposedChart
          onMouseDown={(e) => setChartState({ ...chartState, refAreaLeft: e?.activeLabel || "" })}
          onMouseMove={(e) =>
            setChartState({
              ...chartState,
              refAreaRight: e?.activeLabel || "",
            })
          }
          onMouseUp={zoom}
          data={data}
        >
          <CartesianGrid stroke={theme.custom.background.secondary} vertical={false} strokeDasharray="5 5" />
          <Tooltip content={<HistoricalDataTooltip />} />

          <Bar isAnimationActive={false} stackId={"a"} dataKey={"min"} fill={"none"} />
          <Bar isAnimationActive={false} stackId={"a"} dataKey={"bar"} shape={<HorizonBar />} />
          <Bar isAnimationActive={false} stackId={"a"} dataKey={"bottomWhisker"} shape={<DotBar />} />
          <Bar isAnimationActive={false} stackId={"a"} dataKey={"bottomBox"} fill={COLORS[0]} />
          <Bar isAnimationActive={false} stackId={"a"} dataKey={"bar"} shape={<HorizonBar />} />
          <Bar isAnimationActive={false} stackId={"a"} dataKey={"topBox"} fill={COLORS[0]} />
          <Bar isAnimationActive={false} stackId={"a"} dataKey={"topWhisker"} shape={<DotBar />} />
          <Bar isAnimationActive={false} stackId={"a"} dataKey={"bar"} shape={<HorizonBar />} />
          <ZAxis type="number" dataKey="size" range={[0, 250]} />

          <XAxis height={60} tick={(props) => <AngleAxisTimeTick {...props} />} dataKey="time" />
          <YAxis tickFormatter={(data) => percentFormatter(data)} tick={{ fontSize: 12, fill: "white" }} />
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.1} />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    </Stack>
  );
};

export default HistoricalDataChart;
