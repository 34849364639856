import React, { forwardRef, useCallback } from "react";
import { Box, Stack, Typography, styled, Button, IconButton, Link } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import { SvgIconComponent } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import { useSnackbar, SnackbarContent, CustomContentProps, VariantType } from "notistack";

export interface AnbotoCustomContentProps extends CustomContentProps {
  title?: string;
  buttonAction?(): void;
  actionName?: string;
}

const setColorVariant = (variant: VariantType) => {
  switch (variant) {
    case "success":
      return "#179B83";
    case "error":
      return "#f54242";
    case "warning":
      return "#ECAC27";
    default:
      return "#2C72E3";
  }
};

const regExURL =
  "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})";

const getLink = (message: string) => {
  if (message.match(regExURL)) return message.match(regExURL)![0] || null;
};

const regExMail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;

const getMail = (message: string) => {
  if (message.match(regExMail)) return message.match(regExMail)![0] || null;
};

const message = (message: string) => {
  const link = getLink(message);
  const mail = getMail(message);

  if (!link && mail) {
    const rawMessageParts = message.split(mail);

    return (
      <>
        {rawMessageParts[0]}
        <a href={"mailto:" + mail} style={{ cursor: "pointer", color: "white", textDecoration: "underline" }}>
          {mail}
        </a>
        {rawMessageParts[1]}
      </>
    );
  }

  if (link && !mail) {
    const rawMessageParts = message.split(link);

    return (
      <>
        {rawMessageParts[0]}
        <Link sx={{ cursor: "pointer", color: "white", textDecoration: "underline" }}>
          <a style={{ color: "white" }} href={link}>
            {link}
          </a>
        </Link>
        {rawMessageParts[1]}
      </>
    );
  }

  if (link && mail) {
    const linkComesFirst = message.split(link)[0].length > message.split(mail)[0].length;

    const firstSplit = message.split(linkComesFirst ? link : mail);
    const secondSplit = firstSplit[1].split(linkComesFirst ? mail : link);

    return (
      <>
        {firstSplit[0]}
        <Link sx={{ cursor: "pointer", color: "white", textDecoration: "underline" }}>
          <a style={{ color: "white" }} href={link}>
            {link}
          </a>
        </Link>
        {secondSplit[0]}
        <a href={"mailto:" + mail} style={{ cursor: "pointer", color: "white", textDecoration: "underline" }}>
          {mail}
        </a>
        {secondSplit[1]}
      </>
    );
  }

  return <> {message} </>;
};

export const NotificationBar = forwardRef((props: AnbotoCustomContentProps, ref: any) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Stack direction="row" sx={{ marginTop: 5, minHeight: 64 }}>
        <StyledBox
          sx={{
            display: "flex",
            alignItems: "center",
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            backgroundColor: setColorVariant(props.variant),
          }}
          variant={props.variant}
        >
          {getStyledIcon(
            props.variant === "success" ? CheckCircleIcon : props.variant === "warning" ? WarningIcon : ErrorIcon
          )}
        </StyledBox>
        <StyledBox
          sx={{
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            backgroundColor: "#031116",
            minWidth: 674,
          }}
          variant={props.variant}
        >
          <Stack flexDirection="row" gap={2} alignItems="center" justifyContent="space-between">
            <Box pl={2}>
              {props.title && (
                <Typography display="block" sx={{ paddingTop: 1, color: "white", fontWeight: "bold" }}>
                  {props.title}
                </Typography>
              )}
              {props.message && (
                <Typography display="block" sx={{ paddingY: 1, color: "white" }}>
                  {message(props.message.toString())}
                </Typography>
              )}
            </Box>
            <Stack flexDirection="row" gap={2} alignItems="center" justifyContent="flex-end">
              {props.actionName && props.buttonAction ? (
                <StyledNotificationButton colorvariant={props.variant} onClick={props.buttonAction}>
                  {props.actionName}
                </StyledNotificationButton>
              ) : null}
              <IconButton onClick={handleDismiss}>
                <Close color="disabled" />
              </IconButton>
            </Stack>
          </Stack>
        </StyledBox>
      </Stack>
    </SnackbarContent>
  );
});

NotificationBar.displayName = "NotificationBar";

const StyledBox = styled(Box)<{ variant: VariantType }>(({ variant }) => ({
  padding: 10,
  border: "1px solid",
  borderColor: setColorVariant(variant),
}));

const getStyledIcon = (icon: SvgIconComponent) => {
  const Icon = styled(icon)(() => ({
    color: "#000000",
  }));

  return <Icon />;
};

const StyledNotificationButton = styled(Button)<{ colorvariant: VariantType }>(({ colorvariant }) => ({
  textTransform: "none",
  border: "2px solid",
  color: "white",
  borderColor: setColorVariant(colorvariant),
}));
