import { add, getTime, isSameDay, isSameHour, isSameMinute, isSameMonth, isSameWeek } from "date-fns";
import { ResolutionString } from "@src/charting_library";

export function getErrorMessage(error: string | Error | undefined): string {
  if (error === undefined) {
    return "";
  } else if (typeof error === "string") {
    return error;
  }

  return error.message;
}

export function resolutionIs(resolution, type: "minutes" | "hour" | "day" | "week" | "month") {
  const char = resolution.split("").pop()?.toLowerCase();
  switch (type) {
    case "minutes":
      return /^\d+$/.test(resolution);
    case "hour":
      return +resolution === 60;
    case "day":
      return char === "d";
    case "week":
      return char === "w";
    case "month":
      return char === "m";
  }
}

export function isLatestBarDate(lastDate: Date, resolution: ResolutionString) {
  return (
    (resolutionIs(resolution, "day") && isSameDay(lastDate, new Date())) ||
    (resolutionIs(resolution, "week") && isSameWeek(lastDate, new Date())) ||
    (resolutionIs(resolution, "month") && isSameMonth(lastDate, new Date())) ||
    (+resolution === 1 && !isSameMinute(lastDate, new Date())) ||
    (resolutionIs(resolution, "minutes") && isSameHour(new Date(lastDate), new Date()))
  );
}

// '1m' | '5m' | '15m' | '1h' | '1d' | '1w' | '1M'
export function prepareTimeframe(resolution: ResolutionString): string {
  let timeframe = "1d";

  if (/^\d+$/.test(resolution)) {
    if (+resolution % 60 === 0) {
      timeframe = `${+resolution / 60}h`;
    } else {
      // minutes 1, 5, 15...
      timeframe = `${+resolution}m`;
    }
  } else {
    // 1d, 1w, 1M
    let char = resolution.replace(/\d/g, "");
    char = char === "M" ? char : char.toLowerCase();
    const val = resolution.replace(/[^0-9]/g, "");

    timeframe = `${val}${char}`;
  }

  return timeframe;
}

export function getNextBarTs(barTs: number, resolution: ResolutionString) {
  if (/^\d+$/.test(resolution)) {
    return getTime(add(new Date(barTs), { minutes: +resolution }));
  }

  const char = resolution.replace(/\d/g, "");
  const val = +resolution.replace(/[^0-9]/g, "");

  let nextBarTs: number;

  switch (char.toLowerCase()) {
    case "d":
      nextBarTs = getTime(add(new Date(barTs), { days: val }));
      break;

    case "w":
      nextBarTs = getTime(add(new Date(barTs), { weeks: val }));
      break;

    case "m":
      nextBarTs = getTime(add(new Date(barTs), { months: val }));
      break;

    default:
      throw Error(resolution + " resolution is not implemented");
  }

  return getTime(nextBarTs);
}

export const getTimeframe = (tvTimeframe: string) => {
  const aggregate = tvTimeframe.replace(/\D/g, "");
  const tvTimeframeSymbols = tvTimeframe.replace(/[^a-zA-Z]+/g, "");
  const timeframe = tvTimeframeSymbols === "m" ? "minute" : tvTimeframeSymbols === "h" ? "hour" : "day";
  return { aggregate, timeframe };
};
