import { CompetitionConfig } from "@src/pages/competitions";

export const CONFIG: CompetitionConfig = {
  REWARDS: [
    { header: "1st", headerColor: "#ECAC27", minVolume: "1M", prizePoolPercent: 25 },
    { header: "2nd", headerColor: "#8A9296", minVolume: "1M", prizePoolPercent: 20 },
    { header: "1rd", headerColor: "#2C72E3", minVolume: "1M", prizePoolPercent: 15 },
    { header: "4th-10th", headerColor: "transparent", minVolume: "500K", prizePoolPercent: 40 },
  ],
  PRIZE_POOL: [
    { volume: "25M", prizePool: "$5K" },
    {
      volume: "50M",
      prizePool: "$10K",
    },
    {
      volume: "100M",
      prizePool: "$20K",
    },
    {
      volume: "200M",
      prizePool: "$35K",
    },
    {
      volume: "500M",
      prizePool: "$50K",
    },
  ],
  START_TIME: "Dec 5th 2023 00:00 UTC",
  END_TIME: "Jan 5th 2024 23:59 UTC",
  PRIZE_POOL_SIZE: 50000,
  EXCHANGE_NAME: "Gate",
};
