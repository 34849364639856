import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import formValidator from "@src/utils/formValidator";
import { Wallet } from "@src/store/apis/anbotoApi/types";
import { walletAddressIsValid } from "@src/pages/defi/utils";

export type WalletUpdateFormFieldValues = Wallet;

export const walletSchema = formValidator.object({
  name: formValidator.string().required().min(2).max(20).label("Wallet label"),
  address: formValidator
    .string()
    .required()
    .test("ensure wallet address is valid", "Not EVM compatible", (address) => walletAddressIsValid(address!))
    .label("Wallet address"),
});

export const useWalletUpdateForm = (defaultValues: Wallet) => {
  return useForm<WalletUpdateFormFieldValues>({
    defaultValues,
    resolver: yupResolver(walletSchema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });
};
