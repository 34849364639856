import { styled } from "@mui/material";
import { TabPanel } from "@mui/lab";

export const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: 0,
  "&:not([hidden])": {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
}));
