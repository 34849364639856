import React from "react";
import { Stack, Tabs } from "@mui/material";
import { PositionsTable } from "../../positions-table";
import { Position } from "../../positions-table/types";
import { PositionsCount } from "../../positions-table/positions-count";
import { ParentOrdersTable } from "./parent-orders-table";
import { setParams, setSearch } from "@src/pages/cefi/orders-table/orders-table.slice";
import { CefiExchangeId, OrdersCurrentTab, ParentOrder, ParentOrderStatuses } from "@src/store/apis/anbotoApi/types";
import { AnbotoTab } from "@src/components/ui/AnbotoTabs/AnbotoTab";
import { OrdersTableFilters } from "@src/components/orders-table-filters";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { ORDERS_LIMIT } from "@src/store/constants";
import { PaginationTooltip } from "@src/components/orders-table/pagination-tooltip";

interface ParentOrderTableCardProps {
  onSymbolClick?: (order: ParentOrder) => void;
  topBarPostfix?: React.ReactNode;
  onClosePosition?: (position: Position, amount: string) => void;
  onPositionClick?: (position: Position) => void;
  hideTabs?: OrdersCurrentTab[];
  exchangeIds?: CefiExchangeId[];
  getSymbolLabel?: (order: ParentOrder) => string;
}

export const ParentOrderTableCard = React.forwardRef(
  (
    {
      onSymbolClick,
      topBarPostfix,
      onClosePosition,
      onPositionClick,
      hideTabs,
      exchangeIds,
      getSymbolLabel,
    }: ParentOrderTableCardProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const dispatch = useAppDispatch();
    const { currentTab } = useAppSelector((state) => state.ordersTable.params);

    const isPositions = currentTab === OrdersCurrentTab.Positions;

    const handleTabChange = (e: React.SyntheticEvent, currentTab: OrdersCurrentTab) =>
      dispatch(setParams({ currentTab }));

    const handleSymbolClick = React.useCallback((order: ParentOrder) => onSymbolClick && onSymbolClick(order), []);
    const positionsVisible = !hideTabs?.includes(OrdersCurrentTab.Positions);
    const savedVisible = !hideTabs?.includes(OrdersCurrentTab.SavedOrders);

    return (
      <Stack ref={ref} sx={{ flex: 1, height: "100%" }}>
        <Stack className="order-table" direction="row" alignItems="center" px={1} position="relative">
          <Tabs value={currentTab} onChange={handleTabChange} sx={{ position: "relative", zIndex: 2 }}>
            <AnbotoTab label="Regular orders" value={OrdersCurrentTab.Regular} />
            <AnbotoTab label="Trigger orders" value={OrdersCurrentTab.Trigger} />
            {positionsVisible && (
              <AnbotoTab
                label={
                  <>
                    Positions (<PositionsCount />)
                  </>
                }
                value={OrdersCurrentTab.Positions}
              />
            )}
            <AnbotoTab label="History" value={OrdersCurrentTab.History} />
            {savedVisible && <AnbotoTab label="Saved Orders" value={OrdersCurrentTab.SavedOrders} />}
          </Tabs>
          <Stack flex={1} />
          <Stack gap={2} direction="row" alignItems="center" sx={{ alignItems: "stretch" }}>
            <Stack sx={{ position: "relative", zIndex: 2 }}>
              <OrdersTableFilters<ParentOrderStatuses>
                period={isPositions ? undefined : { onChange: (period) => dispatch(setParams({ period })) }}
                search={{
                  onSearch: (value: string) => dispatch(setSearch(value)),
                }}
                otherPairsHidden
              />
            </Stack>
          </Stack>
          {topBarPostfix}
        </Stack>
        <Stack flex={1} overflow="hidden">
          {isPositions ? (
            <PositionsTable onClosePosition={onClosePosition} onPositionClick={onPositionClick} />
          ) : (
            <ParentOrdersTable
              onSymbolClick={handleSymbolClick}
              exchangeIds={exchangeIds}
              getSymbolLabel={getSymbolLabel}
            />
          )}
        </Stack>

        {!isPositions && <PaginationTooltip limit={ORDERS_LIMIT} p={0.5} justifyContent="center" />}
      </Stack>
    );
  }
);

ParentOrderTableCard.displayName = "ParentOrdersTable";
