import React from "react";
import { Switch, SwitchProps, styled } from "@mui/material";

export type AnbotoSwitchProps = SwitchProps & {};

export const AnbotoSwitch = (props: AnbotoSwitchProps) => {
  return <StyledSwitch {...props} disableRipple />;
};

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  margin: 12,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 16,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(16px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: theme.custom.colors.secondary,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#2F675E",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    borderRadius: 8,
  },
  "& .MuiSwitch-track": {
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
