/* eslint-disable react/display-name */

import React from "react";
import { Typography } from "@mui/material";
import { createColumnHelper, Row } from "@tanstack/table-core";
import { CoinNameIcon } from "../../coin-name-icon";
import { CefiAllocationWithIcon } from "@src/store/apis/anbotoApi/types";
import { CurrencyValue } from "@src/pages/portfolio";
import TradeButton from "@src/pages/portfolio/components/allocation-table/allocation-funds-table/trade-button";
import { formatAmount, roundAmount } from "@src/pages/portfolio/utils/helpers";
import { MemoizedExpandRow } from "@src/pages/cefi/orders-table/components/expand-row";

const columnHelper = createColumnHelper<CefiAllocationWithIcon>();

export const getCefiColumns = (isOverview: boolean, isTradingButtonShow?: boolean) => [
  ...(isOverview
    ? [
        columnHelper.display({
          header: "",
          size: 35,
          id: "sort",
          cell: ({ row }) => <MemoizedExpandRow expanded={row.getIsExpanded()} onClick={() => row.toggleExpanded()} />,
        }),
      ]
    : []),
  columnHelper.accessor("token_symbol", {
    id: "token_symbol",
    header: "Token",
    size: 200,
    cell: ({ row }: { row: Row<CefiAllocationWithIcon> }) => (
      <CoinNameIcon coinName={row.original.coinName} symbol={row.original.token_symbol} url={row.original.url} />
    ),
  }),
  columnHelper.accessor("balance", {
    id: "balance",
    header: "Balance",
    size: 30,
    cell: ({ row }: { row: Row<CefiAllocationWithIcon> }) => (
      <Typography color={row.original.balance < 0 ? "error.main" : "success.main"} fontSize={14} fontWeight="bold">
        {formatAmount(roundAmount(String(row.original.balance), 3))}
      </Typography>
    ),
  }),
  columnHelper.accessor("price", {
    id: "price",
    header: "Price",
    size: 30,
    cell: ({ row }: { row: Row<CefiAllocationWithIcon> }) => <CurrencyValue value={row.original.price} />,
  }),
  columnHelper.accessor("value", {
    id: "value",
    header: "Value",
    size: 30,
    cell: ({ row }: { row: Row<CefiAllocationWithIcon> }) => <CurrencyValue value={row.original.value} />,
  }),
  columnHelper.accessor("portion", {
    id: "portion",
    header: "Portion (%)",
    size: 30,
    cell: ({ row }: { row: Row<CefiAllocationWithIcon> }) => <span>{Number(row.original.portion).toFixed(2)}%</span>,
    sortingFn: (a, b) => a.original.portion - b.original.portion,
  }),
  ...(isTradingButtonShow
    ? [
        columnHelper.display({
          header: "Action",
          size: 10,
          id: "action",
          cell: ({ row }) => <TradeButton isDisabled={!row.original.tradeLink} url={row.original.tradeLink} />,
        }),
      ]
    : []),
];
