import React from "react";
import { DialogProps, Stack } from "@mui/material";
import { DevTool } from "@hookform/devtools";
import { SettingsDialog } from "../settings-dialog";
import { SettingsRow } from "../settings-row";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { Wallet } from "@src/store/apis/anbotoApi/types";
import { WalletNameField } from "@src/pages/settings/wallet/wallet-name-field";
import { WalletAddressField } from "@src/pages/settings/wallet/wallet-address-field";
import { useWalletUpdateForm, WalletUpdateFormFieldValues } from "@src/pages/settings/wallet/use-wallet-update-form";

type WalletUpdateDialogProps = DialogProps & {
  onUpdate(x: WalletUpdateFormFieldValues): void;
  onClose(): void;
  wallet: Wallet;
};

export const WalletUpdateDialog = ({ onUpdate, onClose, wallet, ...props }: WalletUpdateDialogProps) => {
  const form = useWalletUpdateForm(wallet);

  return (
    <SettingsDialog
      title="Update wallet"
      onClose={() => onClose()}
      {...props}
      actions={
        <Stack direction="row" justifyContent="space-between" width="100%">
          <AnbotoButton
            variant="outlined"
            color="secondary"
            size="small"
            disabled={form.formState.isSubmitting}
            sx={{ minWidth: 160 }}
            onClick={onClose}
          >
            Cancel
          </AnbotoButton>
          <AnbotoButton
            sx={{ minWidth: 160 }}
            size="small"
            variant="contained"
            onClick={form.handleSubmit(onUpdate)}
            loading={form.formState.isSubmitting}
          >
            Save
          </AnbotoButton>
        </Stack>
      }
    >
      <Stack sx={{ height: "100%" }}>
        <SettingsRow>
          <Stack gap={2}>
            <WalletNameField control={form.control} name="name" />
            <WalletAddressField control={form.control} name="address" />
          </Stack>
        </SettingsRow>
      </Stack>
      <DevTool control={form.control} />
    </SettingsDialog>
  );
};
