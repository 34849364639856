import BigNumber from "bignumber.js";
import { SYMBOL_PRECISION } from "../constants/symbol-precision";

export const formatUsd = (value: number, digits?: number) =>
  Intl.NumberFormat("en-US", {
    maximumFractionDigits: digits === undefined ? 2 : digits,
    style: "currency",
    currency: "USD",
  }).format(value || 0);

export const formatBps = (value: number): string => {
  return `${Math.round(value * 10000 * 100) / 100} bps `;
};

export const formatCurrency = (currency: string, value: number) => {
  try {
    const formatted = Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      style: "currency",
      currency,
    }).format(value || 0);

    return formatted;
  } catch (err) {
    return formatUsd(value);
  }
};

export const USD_FORMAT_ASSETS = ["USD", "USDC", "USDT", "BUSD", "PERP"];

export const getAssetPrecision = (symbol = "") => {
  const [from, to] = SYMBOL_PRECISION[symbol] || [6, 6];

  return {
    from,
    to,
  };
};

export const digitsAfterZeros = (value: string | number, digits = 2) => {
  const [w, f] = `${value}`.split(".");
  let frac = "";

  if (!f) return w;

  let i = 0;

  while (f[i] === "0" && i < f.length) {
    frac += "0";
    i++;
  }

  let rest = f.slice(i);

  if (rest) {
    // remove zeros at the end and convert back to string
    rest = Number(new BigNumber(`${0}.${rest}`).toFixed(digits, 2)).toString();
    frac = rest.replace("0.", frac);
  } else {
    frac = "";
  }

  return w + (frac ? `.${frac}` : "");
};

type FormatTokenAmountOptions = {
  asset?: string;
  avg?: boolean;
  plain?: boolean;
  precision?: number;
};

export const formatWithDelimiter = (value: string | number, delimiter = ",") => {
  const [w, f] = `${value}`.split(".");
  const whole = w.replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
  return `${whole}${f ? "." + f : ""}`;
};

export const prepareZeros = (value: string, maxZeros = 2) => {
  const [f, w] = value.split(".");
  let str = `${w}`;

  if (str) {
    while (str.length > maxZeros && !+str[str.length - 1]) {
      str = str.slice(0, str.length - 1);
    }
  }

  if (!str || str.length < maxZeros) {
    str = str || "";
    while (str.length < maxZeros) {
      str += "0";
    }
  }

  return [f, str].join(".");
};

// round up
export const formatTokenAmount = (tokenAmount: string | number, options: FormatTokenAmountOptions = {}) => {
  const { asset, avg, plain, precision } = options;
  const useUsdFormatting = USD_FORMAT_ASSETS.includes((asset || "").toUpperCase());

  if (avg) {
    const amount = prepareZeros(digitsAfterZeros(tokenAmount, precision !== undefined ? precision : 4));
    return plain ? amount : formatWithDelimiter(amount);
  }

  const amount = new BigNumber(tokenAmount || 0).toFixed(
    precision !== undefined ? precision : useUsdFormatting ? 2 : 6,
    useUsdFormatting ? 8 : 0
  );

  if (useUsdFormatting || precision !== undefined) return plain ? amount : formatWithDelimiter(amount);

  const fakeWhole = "1234567890";
  const [w, f] = amount.split(".");

  return `${Number(`${fakeWhole}.${f}`)}`.replace(fakeWhole, plain ? w : formatWithDelimiter(w));
};

const MAX_TOKEN_DECIMALS = 8;

// cut token amount to MAX_TOKEN_DECIMALS
export const cutAmount = (amount: string, decimals?: number, cutZeros?: boolean): string => {
  const _decimals = decimals ?? MAX_TOKEN_DECIMALS;

  const amountWithZeros = new BigNumber(amount).multipliedBy(10 ** _decimals).toFixed(0, 1);

  const res = new BigNumber(amountWithZeros).dividedBy(10 ** _decimals);

  if (cutZeros) return res.toFixed();

  return res.toFixed(_decimals);
};
