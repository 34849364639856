import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useAccount, useSwitchChain } from "wagmi";
import { CHAIN_CONFIG } from "@src/pages/defi/constants";
import { ChainId } from "@src/pages/defi/types";

type WrongChainAlertProps = {
  formChainId: ChainId;
};

export const WrongChainAlert = ({ formChainId }: WrongChainAlertProps) => {
  const snackbar = useSnackbar();
  const { chain } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const connectedWalletChainName = chain ? CHAIN_CONFIG[chain.id]?.chainName : "unsupported";
  const chainName = CHAIN_CONFIG[formChainId].chainName;

  useEffect(() => {
    if (chainName !== connectedWalletChainName) {
      wrongChainNotification();
    }
  }, [chainName === connectedWalletChainName]);

  const onWalletSwitchClick = async () => {
    if (switchChainAsync) {
      snackbar.closeSnackbar();
      try {
        await switchChainAsync({ chainId: formChainId });
      } catch (e) {
        snackbar.enqueueSnackbar(e?.message || "Some error happened", {
          variant: "error",
        });
      }
    }
  };

  const wrongChainNotification = () => {
    snackbar.enqueueSnackbar(`You are currently connected to ${connectedWalletChainName} network.`, {
      variant: "warning",
      title: "Wrong network",
      actionName: `Switch to ${chainName}`,
      buttonAction: onWalletSwitchClick,
    });
  };

  return <></>;
};
