import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { useOtcOrderFormContext } from "../otc-order-form/hooks/use-otc-order-form-context";
import { ExpirationTimeLabel } from "@src/pages/cefi/order-form/fields/ExpirationTimeSelect";
import { TRIGGER_CONDITION_LABEL } from "@src/components";
import { OrderExecutionStrategy, OrderSide } from "@src/store/apis/anbotoApi/types";
import { formatTokenAmount } from "@src/utils/format";
import { getSymbolPair } from "@src/pages/cefi/order-form/utils";
import { ORDER_EXECUTION_STRATEGY_NAME } from "@src/pages/cefi/order-form/fields/StrategySelect";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";

export const OtcOrderPropertiesCard = () => {
  const orderForm = useOtcOrderFormContext();
  const symbol = orderForm.watch("symbol");
  const quantity = orderForm.watch("quantity");
  const side = orderForm.watch("side");
  const strategy = orderForm.watch("strategy");
  const tradingDurationUnit = orderForm.watch("tradingDurationUnit");
  const tradingDuration = orderForm.watch("tradingDuration");
  const limitPrice = orderForm.watch("limitPrice");
  const triggerCondition = orderForm.watch("triggerCondition");
  const triggerPrice = orderForm.watch("triggerPrice");
  const expirationTime = orderForm.watch("expirationTime");
  const slippage = orderForm.watch("slippage");

  if (!symbol) return null;

  return (
    <Box px={2} py={1} borderRadius={1} border="1px solid #232C2F" flex={1}>
      <Grid container spacing={0.5} flexWrap="wrap">
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            Pair
          </Typography>
          <Typography>{getOrderSymbolLabel(symbol, ["symbol"])}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            Quantity (
            {side === OrderSide.BUY ? (
              <Typography variant="caption" sx={{ color: "#179B83" }}>
                Buy
              </Typography>
            ) : (
              <Typography variant="caption" sx={{ color: "#C3462B" }}>
                Sell
              </Typography>
            )}
            )
          </Typography>
          <Amount>
            {formatTokenAmount(quantity)} {getSymbolPair(symbol!.symbol).from}
          </Amount>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            Strategy
          </Typography>
          <Amount>
            {ORDER_EXECUTION_STRATEGY_NAME[strategy]} / {side.toUpperCase()}
          </Amount>
        </Grid>
        {(strategy === OrderExecutionStrategy.TWAP || strategy === OrderExecutionStrategy.VWAP) && tradingDuration && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Duration
            </Typography>
            <Amount>
              {tradingDuration} {tradingDurationUnit}
            </Amount>
          </Grid>
        )}

        {limitPrice && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Limit price
            </Typography>
            <Amount>{limitPrice}</Amount>
          </Grid>
        )}
        {triggerPrice && triggerCondition && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Trigger condition
            </Typography>
            <Amount>{TRIGGER_CONDITION_LABEL[triggerCondition]}</Amount>
          </Grid>
        )}
        {triggerPrice && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Trigger price
            </Typography>
            <Amount>{triggerPrice}</Amount>
          </Grid>
        )}

        {strategy === OrderExecutionStrategy.TWAP && expirationTime && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Expiration time
            </Typography>
            <Amount>{ExpirationTimeLabel[expirationTime]}</Amount>
          </Grid>
        )}

        {strategy === OrderExecutionStrategy.FOK && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Slippage
            </Typography>
            <Amount>{slippage} bps</Amount>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const Amount = styled(Typography)({ fontSize: 14 });
