import { useEffect, useMemo, useState } from "react";
import { createValidator } from "../exchange-symbol-validator";
import { useParentOrderFormContext } from "./use-parent-order-form-context";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { OTC_EXCHANGES_MAP } from "@src/features/otc/constants";
import { getExchangeIdByExchangeName } from "@src/store/apis/anbotoApi/utils";

export const useExchangeSymbolErrors = (symbol: OrderSymbol | null) => {
  const getExchangesSymbolData = anbotoApi.useGetExchangesSymbolDataQuery(symbol!, {
    skip: !symbol || OTC_EXCHANGES_MAP[getExchangeIdByExchangeName(symbol.exchange)],
  });
  const orderForm = useParentOrderFormContext();
  const validationData = getExchangesSymbolData.data?.data;

  const validator = useMemo(() => createValidator(validationData), [getExchangesSymbolData.data?.data]);

  const quantity = orderForm.watch("quantity");
  const clipSizeValue = orderForm.watch("clipSizeValue");
  const clipSizeType = orderForm.watch("clipSizeType");
  const wouldPrice = orderForm.watch("wouldPrice");
  const limitPrice = orderForm.watch("limitPrice");
  const triggerPrice = orderForm.watch("triggerPrice");

  const [hasErrors, setHasErrors] = useState(false);
  const defaultErrorsState = {
    quantity: "",
    clipSize: "",
    wouldPrice: "",
    limitPrice: "",
    triggerPrice: "",
  };
  const [errors, setErrors] =
    useState<Record<"quantity" | "clipSize" | "wouldPrice" | "limitPrice" | "triggerPrice", string>>(
      defaultErrorsState
    );

  useEffect(() => {
    if (!getExchangesSymbolData.isFetching) {
      const validationErrors = {
        quantity: validator.amount(quantity),
        clipSize: validator.clipSize(quantity, clipSizeValue, clipSizeType),
        wouldPrice: validator.price(wouldPrice),
        limitPrice: validator.price(limitPrice),
        triggerPrice: validator.price(triggerPrice),
      };

      setHasErrors(symbol && quantity ? !!Object.values(validationErrors).join("") : false);
      setErrors(symbol && quantity ? validationErrors : defaultErrorsState);
    }
  }, [
    symbol?.symbol,
    symbol?.exchange,
    symbol?.market_type,
    quantity,
    clipSizeValue,
    wouldPrice,
    limitPrice,
    triggerPrice,
    getExchangesSymbolData.isFetching,
  ]);

  return { hasErrors, errors, validationData };
};
