import React, { useEffect } from "react";
import { Box, InputAdornment, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { ErrorState } from "@src/components/ErrorState";
import { FetchState } from "@src/components/FetchState";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import { GAS_PRICE_OPTION_GAS_ORACLE_PROP, TRANSACTION_SPEED_OPTION_NAME } from "@src/pages/defi/constants";
import { formatTokenAmount } from "@src/utils/format";

export const MaxGasPrice = () => {
  const orderForm = useDefiOrderFormContext();
  const chainId = orderForm.getValues("chainId");
  const [getGasOracleData, { data: gasOracleData, isError, isFetching }] = anbotoApi.useLazyGetGasOracleQuery();
  const formGasPriceOption = orderForm.watch("gasPriceOption");
  const gasOracleProp = GAS_PRICE_OPTION_GAS_ORACLE_PROP[formGasPriceOption];
  const name = TRANSACTION_SPEED_OPTION_NAME[formGasPriceOption];
  const gasPriceOptionGwei = gasOracleData?.data?.[gasOracleProp] || "";

  const fetchGasPrice = async () => {
    try {
      await getGasOracleData({ chainId }).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    void fetchGasPrice();
  }, [chainId]);

  return (
    <Box p={2} borderRadius={1} sx={{ border: (theme) => `1px solid ${theme.custom.background.secondary}` }}>
      <Stack direction="column" gap={1}>
        <Typography color="text.secondary" textAlign="center">
          Gas speed
        </Typography>
        {!!gasPriceOptionGwei && (
          <Typography variant="body2" textAlign="center">
            {name} (price {formatTokenAmount(gasPriceOptionGwei)} Gwei)
          </Typography>
        )}
      </Stack>
      <Box borderRadius={1} p={2} mt={2} sx={{ backgroundColor: (theme) => theme.custom.colors.gray }}>
        {isFetching ? (
          <FetchState />
        ) : isError ? (
          <ErrorState onTryAgainClick={fetchGasPrice} />
        ) : (
          <Stack direction="row" gap={2} alignItems="flex-start">
            <Box width="50%">
              <Stack direction="row" mt={0.5} gap={1} alignItems="center">
                <Typography>Max gas price (Gwei)</Typography>
              </Stack>
            </Box>
            <Box width="50%">
              <Controller
                name="maxGasPrice"
                render={({ field, fieldState }) => (
                  <AnbotoTextField
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    size="small"
                    fullWidth
                    InputProps={{
                      sx: { backgroundColor: (theme) => theme.palette.background.paper },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography color="text.disabled" variant="caption">
                            Gwei
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
};
