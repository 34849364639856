import React, { FC, memo } from "react";
import { useSnackbar } from "notistack";
import { TableActionButton } from "@src/components";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { ParentDefiOrder } from "@src/store/apis/anbotoApi/types";

interface StopActionButtonProps {
  order: ParentDefiOrder;
}

export const CancelOrderButton: FC<StopActionButtonProps> = ({ order }) => {
  const snackbar = useSnackbar();

  const [cancelOrder, cancelOrderMutation] = anbotoApi.useCancelParentOrderMutation();

  const onClick = async () => {
    if (!order.order_id) {
      return;
    }

    try {
      await cancelOrder({ orderId: order.order_id, accountId: order.account_uuid }).unwrap();
      snackbar.enqueueSnackbar("Order has been canceled", { variant: "success" });
    } catch (e) {
      console.log(e);
      snackbar.enqueueSnackbar(e?.message || "Some error occurred", { variant: "error" });
    }
  };

  return <TableActionButton type="cancel" loading={cancelOrderMutation.isLoading} onClick={onClick} />;
};

export const CancelOrderButtonMemo = memo(CancelOrderButton);
