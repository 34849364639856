import React, { FC } from "react";
import { Box, Stack, styled } from "@mui/material";
import { SubLabel } from "@src/pages/portfolio";

export interface VerificationProps {
  accountFrom: string;
  accountTo: string;
  sendAmount: string;
  receiveAmount: string;
  networkFee: string;
}

export const Verification: FC<VerificationProps> = (props) => {
  const { accountFrom, accountTo, sendAmount, networkFee, receiveAmount } = props;
  return (
    <StyledBox>
      <StyledStack>
        <SubLabel>Send from</SubLabel>
        <Amount>{accountFrom}</Amount>
      </StyledStack>
      <StyledStack>
        <SubLabel>Receive wallet address</SubLabel>
        <Amount>{accountTo}</Amount>
      </StyledStack>
      <StyledStack>
        <SubLabel>Send amount</SubLabel>
        <Amount>{sendAmount}</Amount>
      </StyledStack>
      <StyledStack>
        <SubLabel>Network fees</SubLabel>
        <Amount>{networkFee}</Amount>
      </StyledStack>
      <StyledStack>
        <SubLabel>Receive amount</SubLabel>
        <Amount>{receiveAmount}</Amount>
      </StyledStack>
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 12,
  borderRadius: 4,
  width: "100%",
}));

const StyledStack = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
});

const Amount = styled("span")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "21px",
}));
