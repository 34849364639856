import React, { useCallback, useEffect, useState } from "react";
import { Box, InputAdornment, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { Controller } from "react-hook-form";
import { QueryActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query";
import _debounce from "lodash/debounce";
import BigNumber from "bignumber.js";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import { TokenAddressField } from "@src/pages/defi/order-form-card-no-gas/fields/token-address-field";
import { AnbotoSlider } from "@src/components/anboto-slider/anboto-slider";
import { DCA_FREQUENCY_STEPS, DEFAULT_DEFI_SLIPPAGE } from "@src/pages/defi/constants";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { constructTokenAddress, getOppositeAmount } from "@src/pages/defi/utils";
import { TokenBalance } from "@src/pages/defi/order-form-card-no-gas/token-balance";
import { useDefiOrderFormContext, useTokenInfo } from "@src/pages/defi/hooks";
import {
  ClipSizeType,
  GetBaseCoinPriceResult,
  GetSwapQuoteWithPreTradeAnalysisDefiAwareData,
  GetSwapQuoteWithPreTradeAnalysisDefiAwareParams,
  GetSwapQuoteWithPreTradeAnalysisDefiAwareResult,
  OrderExecutionStrategy,
  OrderSide,
} from "@src/store/apis/anbotoApi/types";
import { useDcaStrategyQuantity } from "@src/pages/defi/hooks/use-dca-strategy-quantity";
import { EVMBasedAddress } from "@src/pages/defi/types";
import { getTradingDurationSec } from "@src/pages/cefi/order-form/utils";
import { ClipSizeValueField } from "@src/pages/cefi/order-form/fields/ClipSizeValueField";
import { TradingDurationUnitField } from "@src/pages/cefi/order-form/fields/TradingDurationUnitField";
import { withDarkFlatStyle, withFlatStyle } from "@src/components/with-flat-style";
import { TradingDurationField } from "@src/pages/cefi/order-form/fields/TradingDurationField";
import { store } from "@src/store/store";
import { TokenPrice } from "@src/pages/defi/order-form-card-no-gas/token-price";
import { AdvancedOptionsDialog } from "@src/pages/defi/order-form-card-no-gas/dialog";
import { AdvancedSettingsError } from "@src/components";
import { DEFI_ADVANCED_SETTINGS_DIALOG_FIELDS } from "@src/pages/defi/order-form-card-no-gas/components/main-strategies-form/main-strategies-form";

type DcaStrategyFormProps = {
  isQuoteDataFetching: boolean;
  isNativeCoinPriceFetching: boolean;
  nativeCoinPrice: GetBaseCoinPriceResult | undefined;
  quoteData: GetSwapQuoteWithPreTradeAnalysisDefiAwareData | undefined;
  advancedSettingsDialog: { hide: () => void; isOpen: boolean; show: () => void };
  getQuoteData: (
    arg: GetSwapQuoteWithPreTradeAnalysisDefiAwareParams,
    preferCacheValue?: boolean | undefined
  ) => QueryActionCreatorResult<
    QueryDefinition<
      GetSwapQuoteWithPreTradeAnalysisDefiAwareParams,
      BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
      never,
      GetSwapQuoteWithPreTradeAnalysisDefiAwareResult,
      "anbotoApi"
    >
  >;
};

const DcaStrategyForm = ({
  getQuoteData,
  isQuoteDataFetching,
  nativeCoinPrice,
  quoteData,
  isNativeCoinPriceFetching,
  advancedSettingsDialog,
}: DcaStrategyFormProps) => {
  const orderForm = useDefiOrderFormContext();
  const dcaInvestmentAmount = useDcaStrategyQuantity();
  const theme = useTheme();

  const chainId = orderForm.watch("chainId");
  const frequencyUnit = orderForm.watch("frequencyUnit");
  const fromTokenAddress = orderForm.watch("fromTokenAddress");
  const toTokenAddress = orderForm.watch("toTokenAddress");
  const clipSizeVal = orderForm.watch("clipSizeValue");
  const duration = orderForm.watch("tradingDuration");
  const durationUnit = orderForm.watch("tradingDurationUnit");
  const frequency = orderForm.watch("frequency");
  const childSlippage = orderForm.getValues("childSlippage");

  const fromToken = useTokenInfo({ chainId, address: fromTokenAddress as EVMBasedAddress });
  const toToken = useTokenInfo({ chainId, address: toTokenAddress as EVMBasedAddress });

  const [dcaFrequencySliderValue, setDcaFrequencySliderValue] = useState(DCA_FREQUENCY_STEPS[0].value);
  const advancedSettingsErrors = Object.keys(orderForm.formState.errors).filter((key) =>
    DEFI_ADVANCED_SETTINGS_DIALOG_FIELDS.includes(key)
  );
  const hasAdvancedSettingsErrors = advancedSettingsErrors.length > 0;

  const onTokenAddressChange = async (
    newTokenAddress: string,
    tokenFieldName: "toTokenAddress" | "fromTokenAddress"
  ) => {
    orderForm.setValue(tokenFieldName, newTokenAddress);
  };

  const onFrequencySliderChange = (e) => {
    if (typeof e === "object" && e?.target?.value) {
      const value = e.target.value;
      setDcaFrequencySliderValue(value);
      const currentFrequencyStep = DCA_FREQUENCY_STEPS.find((x) => x.value === value) || DCA_FREQUENCY_STEPS[0];
      orderForm.setValue("frequency", currentFrequencyStep.frequency);
      orderForm.setValue("frequencyUnit", currentFrequencyStep.frequencyUnit);
    }
  };

  const changeDcaFrequencySliderValue = (currentFrequency: number) => {
    if (frequencyUnit) {
      const currentFrequencyInSeconds = getTradingDurationSec(currentFrequency, frequencyUnit);

      if (currentFrequencyInSeconds < DCA_FREQUENCY_STEPS[0].seconds) {
        setDcaFrequencySliderValue(DCA_FREQUENCY_STEPS[0].value);
        return;
      }

      let newSliderFrequency;

      for (let index = 0; index < DCA_FREQUENCY_STEPS.length; index++) {
        const step = DCA_FREQUENCY_STEPS[index];
        const nextStep = DCA_FREQUENCY_STEPS[index + 1];

        if (
          step.seconds <= currentFrequencyInSeconds &&
          nextStep?.seconds &&
          nextStep.seconds > currentFrequencyInSeconds
        ) {
          newSliderFrequency = step;
          break;
        } else if (!nextStep) {
          newSliderFrequency = DCA_FREQUENCY_STEPS[7];
        }
      }

      if (newSliderFrequency) {
        setDcaFrequencySliderValue(newSliderFrequency.value);
      }
    }
  };

  const getDcaQuoteData = async () => {
    const state = store.getState();
    const tokensMap = state.tokens.tokensMap;

    const chainId = orderForm.getValues("chainId");
    const clipSizeVal = orderForm.getValues("clipSizeValue");
    const duration = orderForm.getValues("tradingDuration");
    const durationUnit = orderForm.getValues("tradingDurationUnit");
    const frequency = orderForm.getValues("frequency");
    const frequencyUnit = orderForm.getValues("frequencyUnit");
    const fromTokenAddress = orderForm.getValues("fromTokenAddress");
    const toTokenAddress = orderForm.getValues("toTokenAddress");

    if (!clipSizeVal || !frequencyUnit) return;

    const durationInSeconds = getTradingDurationSec(Number(duration), durationUnit);
    const frequencyInSeconds = getTradingDurationSec(Number(frequency), frequencyUnit);

    const dcaInvestmentAmount = new BigNumber(clipSizeVal)
      .multipliedBy(new BigNumber(durationInSeconds))
      .dividedBy(new BigNumber(frequencyInSeconds))
      .toFixed();

    const fromToken = tokensMap[constructTokenAddress(chainId, fromTokenAddress as EVMBasedAddress)]?.token;
    const toToken = tokensMap[constructTokenAddress(chainId, toTokenAddress as EVMBasedAddress)]?.token;

    const sideAmount = await getOppositeAmount({
      chainId,
      getQuoteData,
      side: OrderSide.SELL,
      slippage: DEFAULT_DEFI_SLIPPAGE,
      fromToken,
      toToken,
      value: dcaInvestmentAmount,
      clipSizeType: ClipSizeType.ABSOLUTE,
      clipSizeVal,
      durationSeconds: durationInSeconds,
      strategy: OrderExecutionStrategy.DCA,
      limitPrice: "",
      baseTokenAddress: "",
    });

    orderForm.setValue("toTokenAmount", sideAmount.toString());

    await orderForm.trigger("toTokenAmount");
  };

  const getDebouncedDcaQuoteData = useCallback(
    _debounce(() => getDcaQuoteData(), 500),
    []
  );

  useEffect(() => {
    changeDcaFrequencySliderValue(Number(orderForm.getValues("frequency")));
  }, [frequencyUnit]);

  useEffect(() => {
    if (fromTokenAddress && toTokenAddress && clipSizeVal && duration && frequency) void getDebouncedDcaQuoteData();
  }, [fromTokenAddress, toTokenAddress, clipSizeVal, duration, frequency, durationUnit, frequencyUnit]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.custom.background.default,
          borderRadius: theme.spacing(0.5),
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          margin: `${theme.spacing(2)} 0`,
        }}
      >
        <Stack direction="column" gap={1}>
          <Typography fontSize={14} fontWeight={600} color="#B0BCC2">
            You want to invest
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <TokenPrice
              isFetching={isQuoteDataFetching || isNativeCoinPriceFetching}
              tokenSymbol={fromToken?.symbol}
              nativeCoinPrice={nativeCoinPrice?.usd}
              tokenEthRate={quoteData?.sellTokenToEthRate}
            />
            <TokenPrice
              isFetching={isQuoteDataFetching || isNativeCoinPriceFetching}
              tokenSymbol={toToken?.symbol}
              nativeCoinPrice={nativeCoinPrice?.usd}
              tokenEthRate={quoteData?.buyTokenToEthRate}
            />
          </Stack>
          <Stack direction="row" alignItems="flex-start">
            <Stack>
              <Controller
                name="clipSizeValue"
                control={orderForm.control}
                render={({ field, formState }) => (
                  <ClipSizeValueField
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      orderForm.clearErrors("clipSizeValue");
                    }}
                    error={!!formState.errors.clipSizeValue}
                    helperText={formState.errors.clipSizeValue?.message}
                    labelStyle="dynamic"
                    label=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack direction="row" gap={0.5} alignItems="center">
              <Controller
                name="fromTokenAddress"
                control={orderForm.control}
                render={({ field }) => (
                  <TokenAddressField
                    disabled={false}
                    chainId={chainId}
                    selectedTokenAddress={field.value}
                    onTokenSelect={(fromTokenAddress) => onTokenAddressChange(fromTokenAddress, "fromTokenAddress")}
                  />
                )}
              />
              <Typography fontSize={12} color="text.secondary">
                in
              </Typography>
              <Controller
                name="toTokenAddress"
                control={orderForm.control}
                render={({ field }) => (
                  <TokenAddressField
                    disabled={false}
                    chainId={chainId}
                    selectedTokenAddress={field.value}
                    onTokenSelect={(toTokenAddress) => onTokenAddressChange(toTokenAddress, "toTokenAddress")}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <Box
        sx={{
          backgroundColor: theme.custom.background.default,
          borderRadius: theme.spacing(0.5),
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          margin: `${theme.spacing(2)} 0`,
        }}
      >
        <Stack direction="row" gap={2}>
          <Stack mt={1}>
            <Controller
              name="frequency"
              control={orderForm.control}
              render={({ field, formState }) => (
                <StyledDarkTradingDurationField
                  {...field}
                  label="Frequency"
                  onChange={(e) => {
                    field.onChange(e.target.value.replace(/[^\d]/g, ""));
                    changeDcaFrequencySliderValue(e?.target?.value || 1);
                    orderForm.clearErrors("frequency");
                  }}
                  error={!!formState.errors.frequency}
                  helperText={formState.errors.frequency?.message}
                  labelStyle="dynamic"
                  InputLabelProps={{
                    shrink: true,
                    sx: { zIndex: 2 },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Controller
                          name="frequencyUnit"
                          control={orderForm.control}
                          render={({ field }) => (
                            <TradingDurationUnitField
                              {...field}
                              sx={{
                                "& .MuiSelect-select": {
                                  backgroundColor: theme.custom.background.secondary,
                                  color: theme.palette.text.primary,
                                  py: 0.5,
                                  pl: 1,
                                  mr: 0.25,
                                  pr: "28px!important",
                                },
                              }}
                            />
                          )}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Stack>

          <Stack pl={2} minWidth={275}>
            <AnbotoSlider
              value={dcaFrequencySliderValue}
              onChange={(e: any) => {
                e.target && onFrequencySliderChange(e);
              }}
              step={14}
              min={1}
              max={99}
              marks={DCA_FREQUENCY_STEPS}
            />
          </Stack>
        </Stack>
      </Box>

      <Stack mt={1} direction="row" gap={1} alignItems="center" justifyContent="space-between">
        <Stack width={140}>
          <Controller
            name="tradingDuration"
            control={orderForm.control}
            render={({ field, formState }) => (
              <StyledTradingDurationField
                {...field}
                label="Duration of"
                onChange={(e) => {
                  field.onChange(e.target.value.replace(/[^\d]/g, ""));
                  orderForm.clearErrors("tradingDuration");
                }}
                error={!!formState.errors.tradingDuration}
                helperText={formState.errors.tradingDuration?.message}
                labelStyle="dynamic"
                InputLabelProps={{
                  shrink: true,
                  sx: { zIndex: 2 },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Controller
                        name="tradingDurationUnit"
                        control={orderForm.control}
                        render={({ field }) => (
                          <TradingDurationUnitField
                            sx={{
                              "& .MuiSelect-select": {
                                py: 0.5,
                                pl: 1,
                                mr: 0.25,
                                pr: "28px!important",
                              },
                            }}
                            {...field}
                          />
                        )}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Stack>
          <AnbotoButton
            sx={{
              padding: 0.5,
              borderRadius: 0.4,
              height: 32,
              width: 110,
              fontSize: 12,
              background: "#232c2f",
              color: "#899297",
              textAlign: "right",
            }}
            onClick={advancedSettingsDialog.show}
            fullWidth
          >
            <TuneOutlinedIcon sx={{ height: 14, position: "absolute", left: 3 }} />
            <Typography paddingLeft={1} variant="caption">
              Slippage: {childSlippage}%
            </Typography>
            {hasAdvancedSettingsErrors && (
              <Tooltip title={advancedSettingsErrors.join("\r\n")}>
                <AdvancedSettingsError />
              </Tooltip>
            )}
          </AnbotoButton>
        </Stack>
      </Stack>

      <Stack mt={2}>
        <Stack
          sx={{ border: "1px solid #232C2F" }}
          borderRadius={1}
          padding={1.5}
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontSize={12} fontWeight={400}>
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {" "}
              Total investments amount:
            </Typography>{" "}
            {parseFloat(Number(dcaInvestmentAmount).toFixed(5))} {fromToken?.symbol ? fromToken.symbol : ""}
          </Typography>
          <Stack direction="row" alignItems="center">
            <TokenBalance chainId={chainId} tokenAddress={fromTokenAddress} />
          </Stack>
        </Stack>
      </Stack>
      <Box p={2} pt={0} display="flex" flexDirection="column" flex={1} overflow="hidden">
        <AdvancedOptionsDialog open={advancedSettingsDialog.isOpen} onClose={advancedSettingsDialog.hide} />
      </Box>
    </>
  );
};

export default DcaStrategyForm;

const StyledDarkTradingDurationField = withDarkFlatStyle(TradingDurationField);
const StyledTradingDurationField = withFlatStyle(TradingDurationField);
