import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { PlatformAccount } from "./api";
import { GetOrdersType, TFilters } from "./types";
import { OrdersCurrentTab } from "@src/store/apis/anbotoApi/types";

export interface AdminState {
  accounts: Record<string, PlatformAccount>;
  filters: TFilters;
}

const initialState: AdminState = {
  accounts: {},
  filters: {
    cefiDefi: GetOrdersType.All,
    orderType: OrdersCurrentTab.Regular,
    source: "all",
    teams: [],
    onlyActive: true,
    accountsOnlyActive: false,
  },
};

export const adminSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    setAccounts: (state, action: PayloadAction<Record<string, PlatformAccount>>) => {
      state.accounts = action.payload;
    },
    setFilter: (state, action: PayloadAction<Partial<TFilters>>) => {
      state.filters = { ...current(state).filters, ...action.payload };
    },
  },
});

export const { setAccounts, setFilter } = adminSlice.actions;

export default adminSlice.reducer;
