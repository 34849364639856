import BigNumber from "bignumber.js";
import { ClipSizeType, OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";
import { calculateNumberOfSlices } from "@src/pages/defi/utils";

export const getClipSize = (
  amount?: string,
  clipSizeType?: ClipSizeType,
  clipSizeValue?: string,
  automaticValue?: string
): string => {
  if (!amount || !+amount || !clipSizeType) return "";

  const isValidClipSizeValue = clipSizeValue && +clipSizeValue;

  if (clipSizeType === ClipSizeType.ABSOLUTE)
    return isValidClipSizeValue ? new BigNumber(clipSizeValue).toFixed(5) : "";
  if (clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS)
    return isValidClipSizeValue ? new BigNumber(amount).dividedBy(clipSizeValue).toFixed(5) : "";
  if (clipSizeType === ClipSizeType.PERCENTAGE)
    return isValidClipSizeValue ? new BigNumber(amount).multipliedBy(clipSizeValue).dividedBy(100).toFixed(5) : "";

  return automaticValue || "";
};

export const getNumberOfClips = ({
  amount,
  clipSizeType,
  clipSizeValue,
  automaticValue,
  strategy,
}: {
  amount?: string;
  clipSizeType?: ClipSizeType;
  clipSizeValue?: string;
  automaticValue?: string;
  strategy: OrderExecutionStrategy;
}): string => {
  if (!amount || !clipSizeType) return "";

  const isValidClipSizeValue = clipSizeValue && +clipSizeValue;

  if (clipSizeType !== ClipSizeType.AUTOMATIC) {
    if (!isValidClipSizeValue) return "";

    return calculateNumberOfSlices({
      strategy,
      quantity: amount,
      clipSizeValue,
      clipSizeType,
    }).toString();
  }

  return automaticValue || "";
};
