import React from "react";
import { Control, Controller } from "react-hook-form";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const WalletAddressField = ({ control, name }: { control: Control<any>; name: string }) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState, formState }) => (
      <AnbotoTextField
        {...field}
        sx={{ flexGrow: 1 }}
        labelStyle="dynamic"
        label="Wallet address"
        placeholder="Paste your wallet address"
        error={!!fieldState.error}
        helperText={fieldState.error?.message}
        disabled={formState.isSubmitting}
      />
    )}
  />
);
