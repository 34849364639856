import React, { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import { saveLogo } from "./symbol-select.slice";
import { defiTokens } from "@src/store/apis/algoliaApi";
import { normalizeIpfsLink } from "@src/pages/defi/utils";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

const predefined = {
  btc: "https://tokens.1inch.io/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c.png",
  xrp: "https://tokens.pancakeswap.finance/images/0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE.png",
};

export const SymbolSearchIcon = ({ coin = "", size = 16 }: { coin?: string; size?: number }) => {
  const dispatch = useAppDispatch();
  const searchString = coin?.replace("1000", "");

  const predefinedIcon = predefined[coin.toLowerCase()];
  const tokenLogo = useAppSelector((state) => state.symbolSelect.tokenLogos[coin]);

  const { data } = defiTokens.useSearchTokenQuery(
    { searchString, limit: 10, attributes: ["logoURI"] },
    {
      skip: !!tokenLogo || !coin || !!predefinedIcon,
    }
  );

  const fetchedLogo = data ? data?.find((el) => el.logoURI)?.logoURI || "" : "";

  const uri = predefinedIcon || tokenLogo || fetchedLogo;

  useEffect(() => {
    if (!predefinedIcon && !tokenLogo && fetchedLogo) dispatch(saveLogo({ coin, logo: fetchedLogo }));
  }, [coin, fetchedLogo]);

  return (
    <Avatar sx={{ width: size, height: size }} src={normalizeIpfsLink(uri)} alt={coin}>
      <Box width={size} height={size} sx={{ background: (theme) => theme.custom.background.secondary }} />
    </Avatar>
  );
};
