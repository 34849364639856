import React, { useEffect, useState } from "react";
import { Tooltip, Stack, Typography, Dialog, DialogActions, DialogContent } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { SettingsCard } from "../settings-card";
import { WalletUpdateFormFieldValues } from "../wallet/use-wallet-update-form";
import { WalletCreateBulkDialog } from "../wallet/wallet-create-bulk-dialog";
import { WalletUpdateDialog } from "../wallet/wallet-update-dialog";
import { ExchangeKeysDialog } from "./edit-exchange-dialog";
import { DialogBeforeDelete } from "./delete-dialog";
import { ExchangesSettings } from "./exchanges-settings";
import { WalletsSettings } from "./wallets-settings";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { CefiExchangeId, Exchange, ExchangeConfig } from "@src/store/apis/anbotoApi/types";
import { AnbotoTab } from "@src/components/ui/AnbotoTabs/AnbotoTab";
import { RootState } from "@src/store/types";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { useAppSelector } from "@src/store/hooks";

const enum TABS {
  EXCHANGE = "exchanges",
  OTC = "otc",
  WALLET = "wallets",
}

export const LinkedSettings = () => {
  const { type = "exchanges" } = useParams();

  useEffect(() => {
    navigate(`/settings/linked/${type}`);
  }, []);

  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { user_uuid } = useSelector((state: RootState) => state.user);
  const isOtcEnabled = useAppSelector((state) => state.user.is_otc_visible);

  // exchanges
  const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);
  const [deleteExchangeConfig, deleteExchangeConfigMutation] = anbotoApi.useDeleteExchangeConfigMutation();
  const [deleteExchangeId, setDeleteExchangeId] = useState<number | null>(null);

  const getExchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi: false });
  const getExchangeConfigsQuery = anbotoApi.useGetExchangeConfigsQuery(getUserDetailsQuery.data?.team_uuid || null, {
    skip: !getUserDetailsQuery.data,
  });

  const [selectedExchange, setSelectedExchange] = useState<Exchange | null>(null);
  const [deleteExchange, setDeleteExchange] = useState<Exchange | null>(null);

  const getConfigListByExchangeId = (id: number) =>
    getExchangeConfigsQuery.data?.results?.filter((config: ExchangeConfig) => config.exchange === id) || [];

  const selectedExchangeConfigItems = selectedExchange ? getConfigListByExchangeId(selectedExchange.id) : [];

  const handleAccountsRemove = async () => {
    if (!deleteExchange) return;
    try {
      setDeleteExchangeId(deleteExchange.id);
      await Promise.all(
        getConfigListByExchangeId(deleteExchange.id).map(({ uuid }) => deleteExchangeConfig(uuid).unwrap())
      );
      snackbar.enqueueSnackbar("Exchange configs deleted successfully", { variant: "success" });
    } catch (e) {
      snackbar.enqueueSnackbar("Failed to delete exchange configs", { variant: "error" });
    } finally {
      setDeleteExchangeId(null);
      setDeleteExchange(null);
    }
  };

  // wallets

  const getWalletsQuery = anbotoApi.useGetWalletsQuery(null);
  const [updateWallets] = anbotoApi.useUpdateWalletsMutation();
  const [selectedWalletIndex, setSelectedWalletIndex] = useState<number | null>(null);
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const [dialogDeleteWallet, setDialogDeleteWallet] = useState(false);
  const [walletToDelete, setWalletToDelete] = useState<number | null>(null);

  const onDeleteWallet = async (index: any) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const wallets = getWalletsQuery.data!.results.filter((x, i) => i !== index);
      await updateWallets({ wallets, userId: user_uuid }).unwrap();
      snackbar.enqueueSnackbar("Wallet has been deleted", { variant: "success" });
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  const onUpdate = async (walletUpdateFormFieldValues: WalletUpdateFormFieldValues) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const wallets = getWalletsQuery.data!.results.map((x, i) =>
        i === selectedWalletIndex ? walletUpdateFormFieldValues : x
      );
      await updateWallets({ wallets, userId: user_uuid }).unwrap();
      snackbar.enqueueSnackbar("Wallet has been updated", { variant: "success" });
      setShowCreateDialog(false);
    } catch (e) {
      snackbar.enqueueSnackbar(parseAnbotoRequestError(e?.data), { variant: "error" });
    }
  };

  const closeDialog = () => {
    setSelectedWalletIndex(null);
    setShowCreateDialog(false);
  };

  return (
    <SettingsCard
      loading={!getExchangesQuery.data || !getExchangeConfigsQuery.data || !getUserDetailsQuery.data}
      headerProps={{
        title: `Linked CEXs / ${isOtcEnabled ? "OTC / " : ""}DEFI wallets`,
        description: "Anboto is non custodial and permissionless. We can not access your API keys",
        postfix: (
          <Tooltip title="Please make sure you have inserted the API key before making a trade">
            <Stack>
              <AnbotoButton
                variant="contained"
                onClick={() => navigate("/trades/cefi")}
                disabled={!getExchangeConfigsQuery.data?.results?.length}
              >
                Make a trade
              </AnbotoButton>
            </Stack>
          </Tooltip>
        ),
      }}
      dialog={
        type === TABS.EXCHANGE || type === TABS.OTC ? (
          selectedExchange && (
            <ExchangeKeysDialog
              exchange={selectedExchange}
              configItems={selectedExchangeConfigItems}
              open
              onClose={() => setSelectedExchange(null)}
              configItemsUpdated={!getExchangeConfigsQuery.isFetching}
            />
          )
        ) : showCreateDialog ? (
          <WalletCreateBulkDialog open onSubmitSuccess={closeDialog} onClose={closeDialog} />
        ) : selectedWalletIndex !== null ? (
          <WalletUpdateDialog
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            wallet={getWalletsQuery.data!.results[selectedWalletIndex]}
            open
            onUpdate={onUpdate}
            onClose={closeDialog}
          />
        ) : null
      }
      footer={
        type === TABS.WALLET && !!getWalletsQuery.data?.results.length ? (
          <Stack direction="row">
            <AnbotoButton variant="contained" onClick={() => setShowCreateDialog(true)}>
              Add another wallet
            </AnbotoButton>
          </Stack>
        ) : null
      }
    >
      <TabContext value={type}>
        <TabList
          sx={{ mt: -3 }}
          onChange={(e, tab) => {
            navigate(`/settings/linked/${tab}`);
          }}
        >
          <AnbotoTab value={TABS.EXCHANGE} label={"Link CEX"} />
          {isOtcEnabled && <AnbotoTab value={TABS.OTC} label={"Link OTC"} />}
          <AnbotoTab value={TABS.WALLET} label={"Link Wallet"} />
        </TabList>
        {type === TABS.EXCHANGE ? (
          <ExchangesSettings
            onEdit={setSelectedExchange}
            onDelete={setDeleteExchange}
            blackList={[CefiExchangeId.B2C2]}
          />
        ) : type === TABS.OTC && isOtcEnabled ? (
          <ExchangesSettings
            onEdit={setSelectedExchange}
            onDelete={setDeleteExchange}
            whiteList={[CefiExchangeId.B2C2]}
          />
        ) : (
          <WalletsSettings
            onEdit={setSelectedWalletIndex}
            onDelete={(index) => {
              setDialogDeleteWallet(true);
              setWalletToDelete(index);
            }}
            setShowCreateDialog={setShowCreateDialog}
          />
        )}
      </TabContext>
      {!!deleteExchange && (
        <Dialog
          open
          onClose={() => setDeleteExchange(null)}
          sx={{ "& .MuiPaper-root": { background: `#232C2F !important` } }}
        >
          <DialogContent>
            <Typography variant="body1">Are you sure you want to delete {deleteExchange?.name} account?</Typography>
          </DialogContent>
          <DialogActions>
            <AnbotoButton variant="outlined" size="small" onClick={() => setDeleteExchange(null)}>
              No
            </AnbotoButton>
            <AnbotoButton
              variant="outlined"
              size="small"
              onClick={handleAccountsRemove}
              autoFocus
              loading={deleteExchange.id === deleteExchangeId && deleteExchangeConfigMutation.isLoading}
            >
              Yes
            </AnbotoButton>
          </DialogActions>
        </Dialog>
      )}
      <DialogBeforeDelete
        open={dialogDeleteWallet}
        setOpen={setDialogDeleteWallet}
        deleteFunction={() => onDeleteWallet(walletToDelete)}
      />
    </SettingsCard>
  );
};
