import React from "react";
import { MenuItem, Stack, Typography } from "@mui/material";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const ReduceOnlySelect = React.forwardRef((props: AnbotoTextFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    value={+(props.value || "0")}
    inputRef={ref}
    select
    label={
      <Stack direction="row" gap={1} alignItems="center">
        <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
          Reduce only
        </Typography>
      </Stack>
    }
    size="small"
    fullWidth
  >
    <MenuItem value={1}>Yes</MenuItem>
    <MenuItem value={0}>No</MenuItem>
  </AnbotoTextField>
));

ReduceOnlySelect.displayName = "ReduceOnlySelect";
