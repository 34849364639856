import React from "react";
import { CefiGroup, updateGroupData } from "../../store/widgets.slice";
// import { Chart } from "../components/chart";
import { WidgetBase, WidgetBaseProps } from "../widget-base";
import { useGroupData } from "../../hooks/use-group-data";
import { useAppDispatch } from "@src/store/hooks";
import { ParentOrderSymbolChart } from "@src/pages/cefi/order-symbol-chart/parent-order-symbol-chart";
import { ParentOrderFormFieldValues } from "@src/pages/cefi/order-form/hooks/use-parent-order-form";

export const CefiSymbolChartWidget = React.forwardRef((props: WidgetBaseProps, ref: React.Ref<HTMLDivElement>) => {
  const dispatch = useAppDispatch();
  const { group, id } = props;
  const groupId = group || id;
  const groupData = useGroupData(group);

  const { triggerPrice, wouldPrice, limitPrice, quantity, symbol: groupSymbol } = (groupData || {}) as CefiGroup;

  const handleLineMove = (key: keyof ParentOrderFormFieldValues, value: string) => {
    dispatch(
      updateGroupData({
        group: groupId,
        data: {
          [key]: value,
        },
      })
    );
  };

  return (
    <WidgetBase
      {...props}
      ref={ref}
      component={
        groupSymbol?.symbol && groupSymbol?.exchange && groupSymbol.market_type ? (
          <ParentOrderSymbolChart
            key={props.id}
            orderSymbol={groupSymbol}
            onLineMove={handleLineMove}
            triggerPrice={triggerPrice}
            wouldPrice={wouldPrice}
            limitPrice={limitPrice}
            quantity={quantity}
          />
        ) : null
      }
    />
  );
  // return (
  //   <WidgetBase
  //     {...props}
  //     ref={ref}
  //     multiple
  //     withGroups
  //     removable
  //     component={<Chart symbol={groupSymbol ? groupSymbol.symbol + groupSymbol.exchange : ""} />}
  //   />
  // );
});

CefiSymbolChartWidget.displayName = "CefiSymbolChartWidget";
