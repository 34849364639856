import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import GridLayout from "react-grid-layout";
import _cloneDeep from "lodash/cloneDeep";
import _isEmpty from "lodash/isEmpty";
import { CONFIGS } from "../widgets/configs";
import { DEFAULT_SYMBOL } from "../constants";
import { initialLayout as cefiSymbolChartWidgetInitialLayout } from "../widgets/cefi-symbol-chart-widget/cefi-symbol-chart-widget.config";
import { calculateWidgetLayout } from "../utils/calculate-widget-layout";
import { compactPageLayout } from "../utils/compact-page-layout";
import { predefinedLayouts } from "../layouts";
import {
  WidgetsGridView,
  WidgetsLayout,
  WidgetName,
  WidgetsLayoutItem,
  WidgetLayoutBreakpoints,
  PredefinedWidgetsLayout,
} from "../types";
import { getWidgetId } from "../utils/get-id";
import { checkAndFixCefiGroups } from "../utils/check-and-fix-cefi-groups";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { ParentOrderFormFieldValues } from "@src/pages/cefi/order-form/hooks/use-parent-order-form";
import { orderAdvancedSettingsModals } from "@src/pages/cefi/order-form/utils";

export type CefiGroup = {
  symbol?: OrderSymbol;
  priceGrouping?: string;
} & Partial<ParentOrderFormFieldValues>;

export type DefiGroup = {};

export type WidgetsViewState = {
  layout: WidgetsLayout;
  groups: Record<string, CefiGroup | DefiGroup>;
};

export type WidgetsState = {
  view: WidgetsGridView;
  breakpoint: WidgetLayoutBreakpoints;
  advancedSettingsModal: Record<
    string,
    {
      group: string;
      id: string;
      x: number;
      y: number;
    } | null
  >;
  groupDataModal: {
    visible: boolean;
    data: Partial<CefiGroup> | Partial<DefiGroup> | null;
    title: string;
  };
  views: Record<WidgetsGridView, WidgetsViewState>;
};

export const initialState: WidgetsState = {
  view: WidgetsGridView.CEFI,
  breakpoint: "md",
  advancedSettingsModal: {},
  groupDataModal: {
    visible: false,
    data: null,
    title: "",
  },
  views: {
    cefi: {
      layout: {},
      groups: {},
    },
    defi: {
      layout: {},
      groups: {},
    },
    otc: {
      layout: {},
      groups: {},
    },
  },
};

export const widgetsSlice = createSlice({
  name: "widgets",
  initialState,
  reducers: {
    initializeWidgets: (state, action: PayloadAction<Record<WidgetsGridView, WidgetsViewState>>) => {
      Object.keys(action.payload).forEach((view) => {
        const layoutState = action.payload[view] || {};
        const { layout = {}, groups = {} } = layoutState;

        state.views[view] = {
          layout: _isEmpty(layout) ? predefinedLayouts[view]?.layout || {} : layout,
          groups: _isEmpty(groups)
            ? predefinedLayouts[view]?.groups || {}
            : view === WidgetsGridView.CEFI
            ? checkAndFixCefiGroups(_cloneDeep(groups))
            : groups,
        };
      });
    },
    changeView: (state, action: PayloadAction<WidgetsGridView>) => {
      state.view = action.payload;
    },
    changeBreakpoint: (state, action: PayloadAction<WidgetLayoutBreakpoints>) => {
      state.breakpoint = action.payload;
    },
    addWidget: (state, { payload: { name, group } }: PayloadAction<{ name: WidgetName; group?: string }>) => {
      const view = state.view;
      if (!view) return state;

      const viewState = state.views[view];
      if (!viewState) return state;

      const widget = CONFIGS[name];
      if (!widget) {
        console.log(name + " widget not found");
        return state;
      }

      const widgetsNumber = Object.values(viewState.layout).filter((widget) => widget.name === name).length;

      if (widgetsNumber >= widget.options.maxNumber) {
        console.log(name + " max number limit");
        return state;
      }

      const id = getWidgetId(name);
      let pageLayout = _cloneDeep(current(state.views[state.view].layout));

      const newWidgetLayout = name === WidgetName.CefiSymbolChart ? cefiSymbolChartWidgetInitialLayout : widget.layout;
      const widgetState = {
        id,
        name,
        group: group || "1",
        layout: calculateWidgetLayout(pageLayout, newWidgetLayout, id),
      } as WidgetsLayoutItem;

      pageLayout[id] = widgetState;

      pageLayout = compactPageLayout(pageLayout);

      viewState.layout = pageLayout;
    },
    removeWidget: (state, action: PayloadAction<string>) => {
      const view = state.view;

      if (view) {
        delete state.views[view]?.layout[action.payload];
        state.views[view].layout = compactPageLayout(current(state.views[state.view].layout));
      }
    },
    updateLayout: (state, action: PayloadAction<GridLayout.Layout[]>) => {
      const view = state.view;
      const bp = state.breakpoint;

      if (!bp || !view) return state;

      const widgetsLayout = state.views[view].layout;

      action.payload.forEach((layoutItem) => {
        widgetsLayout[layoutItem.i].layout[bp] = layoutItem;
      });
    },
    setPredefinedLayout: (state, action: PayloadAction<PredefinedWidgetsLayout>) => {
      const view = state.view;

      state.views[view].layout = action.payload.layout;
    },
    changeGroup: (state, { payload: { id, group } }: PayloadAction<{ id: string; group: string }>) => {
      const view = state.view;
      const _group = group || id;

      if (!view) return state;

      const widgetState = state.views[view]?.layout[id];
      const groups = state.views[view].groups;

      if (widgetState) widgetState.group = _group;

      if (!groups[_group]) {
        groups[group] = {
          symbol: DEFAULT_SYMBOL,
        };
      }
    },
    updateGroupData: (
      state,
      {
        payload: { group, data, reset },
      }: PayloadAction<{
        group?: string;
        data: Partial<CefiGroup> | Partial<DefiGroup> | null;
        reset?: boolean;
      }>
    ) => {
      const view = state.view;

      if (!view || !data) return state;

      const viewState = state.views[view];

      if (viewState) {
        const groups = viewState.groups;
        const formGroup = Object.values(viewState.layout || {}).find(
          ({ name }) => name === (view === WidgetsGridView.OTC ? WidgetName.OtcOrderForm : WidgetName.CefiOrderForm)
        )?.group;
        const groupToUpdate = group || formGroup;

        if (groups && groupToUpdate) {
          groups[groupToUpdate] = {
            ...(reset ? {} : current(groups)[groupToUpdate]),
            ...data,
          };
        }
      }
    },
    updateWidgetLayout: (
      state,
      { payload: { id, layout } }: PayloadAction<{ id: string; layout: Partial<GridLayout.Layout> }>
    ) => {
      const view = state.view;
      const bp = state.breakpoint;

      if (!view || !bp) return state;

      const widgetLayout = state.views[view]?.layout[id]?.layout;

      if (widgetLayout) {
        widgetLayout[bp] = { ...current(widgetLayout[bp]), ...layout };
      }
    },
    updateGroupDataWithModal: (
      state,
      {
        payload: { data, title, group },
      }: PayloadAction<{
        data: Partial<CefiGroup> | Partial<DefiGroup> | null;
        title: string;
        group?: string;
      }>
    ) => {
      const view = state.view;
      const isOtc = view === WidgetsGridView.OTC;

      const groups = Object.values(state.views[state.view].layout).reduce<string[]>((prev, { group, name }) => {
        if (name !== (isOtc ? WidgetName.OtcOrderForm : WidgetName.CefiOrderForm)) return prev;

        if (!group || !prev.includes(group)) {
          prev.push(group || "");
        }

        return prev;
      }, []);

      if (group || groups.length === 1) {
        if (data) {
          widgetsSlice.caseReducers.updateGroupData(state, {
            type: "updateGroupData",
            payload: { group: group || groups[0], data },
          });
        }
      } else {
        state.groupDataModal = {
          visible: true,
          data,
          title,
        };
      }
    },
    closeUpdateGroupDataModal: (state) => {
      state.groupDataModal = {
        visible: false,
        data: null,
        title: "",
      };
    },
    openAdvancedSettingsModal: (
      state,
      { payload: { group, id, x, y } }: PayloadAction<{ group: string; id: string; x: number; y: number }>
    ) => {
      state.advancedSettingsModal[group] = { id, x, y, group };

      orderAdvancedSettingsModals(id);
    },
    closeAdvancedSettingsModal: (state, { payload }: PayloadAction<string>) => {
      state.advancedSettingsModal[payload] = null;
    },
  },
});

export const {
  initializeWidgets,
  addWidget,
  removeWidget,
  updateLayout,
  setPredefinedLayout,
  changeGroup,
  updateGroupData,
  updateWidgetLayout,
  changeView,
  changeBreakpoint,
  updateGroupDataWithModal,
  closeUpdateGroupDataModal,
  openAdvancedSettingsModal,
  closeAdvancedSettingsModal,
} = widgetsSlice.actions;

export default widgetsSlice.reducer;
