import React from "react";
import { Box, Stack, styled } from "@mui/material";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import _uniqWith from "lodash/uniqWith";
import { useFavoriteSymbolFilters } from "./hooks/use-favorite-symbol-filters";
import { SymbolListItem } from "./symbol-list-item";
import { orderSymbolFavourites } from "./symbol-select.slice";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { FavoriteSymbol, OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { useActiveView } from "@src/features/widgets-layout/hooks/use-active-view";

interface FavoriteSymbolsListProps {
  search: string;
  onSelect: (symbol: OrderSymbol) => void;
  otc?: boolean;
}

export const formatAsOrderSymbol = ({
  symbol,
  exchange,
  market_type,
  asset_class,
  coin1 = "",
  coin2 = "",
  coin_margin,
  is_derivative,
}: FavoriteSymbol) => ({
  symbol,
  exchange: CEFI_EXCHANGE_NAME[exchange],
  market_type: asset_class || market_type,
  coin1,
  coin2,
  coin_margin,
  is_derivative,
});

export const FavoriteSymbolsList = ({ search, onSelect, otc }: FavoriteSymbolsListProps) => {
  const dispatch = useAppDispatch();
  const [watchlistUpdateSymbolOrder] = anbotoApi.useUpdateWatchlistSymbolOrderMutation();
  const symbols = useAppSelector((state) => state.user.token_pair_watchlist);
  const view = useActiveView();
  const isOtc = view === "otc";

  const { filteredSymbols } = useFavoriteSymbolFilters(search);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    if (otc) {
      return dispatch(
        orderSymbolFavourites({ from: source.index, to: destination.index, type: isOtc ? "otc" : "cefi" })
      );
    }

    watchlistUpdateSymbolOrder({ symbol: symbols[source.index], from: source.index, to: destination.index });
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {_uniqWith(filteredSymbols, (a, b) => getLivePriceId(a) === getLivePriceId(b)).map(
                (symbol: FavoriteSymbol, index: number) => (
                  <Draggable key={getLivePriceId(symbol)} draggableId={getLivePriceId(symbol)} index={index}>
                    {(provided) => (
                      <SymbolListItemDraggable ref={provided.innerRef} {...provided.draggableProps}>
                        <SymbolListItem
                          key={getLivePriceId(symbol)}
                          symbol={formatAsOrderSymbol(symbol)}
                          onSelect={onSelect}
                          tickerInfo
                          before={
                            !search && (
                              <Drag {...provided.dragHandleProps} gap={1} className="symbol-list-item-drag">
                                {/* <DensityMediumIcon
                                  sx={{
                                    fontSize: 16,
                                    color: (theme) => theme.palette.text.secondary,
                                  }}
                                /> */}
                              </Drag>
                            )
                          }
                        />
                      </SymbolListItemDraggable>
                    )}
                  </Draggable>
                )
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

const SymbolListItemDraggable = styled(Box)({
  left: "auto !important",
  top: "auto !important",
  width: "100%",
  "&:hover .symbol-list-item-drag": {
    visibility: "visible",
  },
});

const Drag = styled(Stack)({
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
  position: "absolute",
  overflow: "hidden",
  left: 0,
  width: 10,
  height: "100%",
  // visibility: "hidden",
  opacity: 0.3,
});
