import { ChainId } from "@src/pages/defi/types";

export const CHAIN_ID_BASE_URL: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: "https://api.0x.org",
  [ChainId.BSC]: "https://bsc.api.0x.org",
  [ChainId.POLYGON]: "https://polygon.api.0x.org",
  [ChainId.AVALANCHE]: "https://avalanche.api.0x.org",
  [ChainId.ARBITRUM]: "https://arbitrum.api.0x.org",
  [ChainId.OPTIMISM]: "https://optimism.api.0x.org",
  [ChainId.BASE]: "https://base.api.0x.org",
};
