import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { APP_TITLES, DEFAULT_TITLE } from "@src/components/title-wrapper/constant";

type TitleWrapperProps = {
  children: ReactNode;
};
const TitleWrapper = ({ children }: TitleWrapperProps) => {
  const location = useLocation();

  useEffect(() => {
    document.title = APP_TITLES[location.pathname] || DEFAULT_TITLE;
  }, [location]);

  return <>{children}</>;
};

export default TitleWrapper;
