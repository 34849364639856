import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "@src/store/types";
import { AllocationMenuDefiItems } from "@src/pages/portfolio/components/allocation-menu/allocation-menu-defi-items";
import { useChainIcons } from "@src/pages/portfolio/hooks/use-chain-icons";
import { DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE } from "@src/store/slices/portfolioSlice";

export const AllocationDefiMenu = () => {
  let allocationDefiData = useSelector((state: RootState) => state.portfolio.accountDefiAllocation);
  const chainIcons = useChainIcons();

  allocationDefiData = [
    ...allocationDefiData.map((item) => {
      const iconAddress = chainIcons[item.subaccount];

      if (iconAddress) {
        return {
          ...item,
          iconAddress,
        };
      } else {
        return {
          ...item,
        };
      }
    }),
  ];

  allocationDefiData = [
    {
      subaccount: DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE,
      value: allocationDefiData.reduce((n, { value }) => n + value, 0),
      percent: 1,
    },
    ...allocationDefiData,
  ];

  return <AllocationMenuDefiItems allocationItems={allocationDefiData} />;
};
