import React from "react";
import { Badge, Tooltip, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ParentDefiOrder, ParentOrderErrorCode, ParentOrderStatuses } from "@src/store/apis/anbotoApi/types";
import { getColorByOrderStatus } from "@src/pages/defi/utils";
import { getParentOrderStatus } from "@src/pages/cefi/orders-table/utils";

export type ParentOrderStatusProps<T extends { last_status?: ParentDefiOrder["last_status"]; quantity: number }> = {
  order: T;
  info?: string;
  fontSize?: number;
};

const Label = ({ color, fontSize = 11, text }: { color: string; fontSize?: number; text: string }) => (
  <Typography
    noWrap
    variant="body2"
    color={color}
    sx={{ fontSize, ["&:first-letter"]: { textTransform: "capitalize" } }}
  >
    {text}
  </Typography>
);

export function ParentOrderStatus<T extends { last_status?: ParentDefiOrder["last_status"]; quantity: number }>({
  order,
  info,
  fontSize,
}: ParentOrderStatusProps<T>) {
  const lastStatus = order.last_status;
  const quantity = order.quantity;

  if (!lastStatus?.status) {
    return null;
  }

  const { status, filled_quantity = 0, error_code } = lastStatus || {};
  const isPartlyFilled = filled_quantity > 0 && error_code;
  const insuffFunds = error_code === ParentOrderErrorCode.INSUFFICIENT_FUNDS;

  const labelText = getParentOrderStatus(status, quantity, filled_quantity, error_code as ParentOrderErrorCode);

  const croppedStatus = typeof status === "string" ? (status as string).slice(0, 10) + "..." : "-";
  const color = (!insuffFunds && !!status && !isPartlyFilled && getColorByOrderStatus(status)) || "text.secondary";

  return (
    <>
      <Label fontSize={fontSize} text={labelText || croppedStatus} color={color} />
      {status === ParentOrderStatuses.REJECTED && info && (
        <Tooltip title={info} arrow placement="top-end">
          <ErrorOutlineOutlinedIcon sx={{ color: "#4A5053", fontSize: "14px" }} />
        </Tooltip>
      )}
      {lastStatus.error_code && lastStatus.message && (
        <Tooltip title={lastStatus.message} sx={{ color: "#4A5053", fontSize: "14px", marginLeft: 0.5 }}>
          <InfoOutlinedIcon />
        </Tooltip>
      )}
      {status === ParentOrderStatuses.PARTIALLY_FILLED && <Badge color="success" variant="dot" />}
    </>
  );
}
