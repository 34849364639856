import React, { useEffect } from "react";
import { useSearchBox, useHits } from "react-instantsearch";
import { HitSymbol } from "./types";
import { SymbolListItem } from "./symbol-list-item";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";

export interface AlgoliaResultsListProps {
  search?: string;
  onSelect: (value: OrderSymbol) => void;
  blacklist?: string[];
}

export const AlgoliaResultsList = ({ search = "", onSelect, blacklist }: AlgoliaResultsListProps) => {
  const searchBox = useSearchBox();

  const hits = useHits<HitSymbol>();

  useEffect(() => {
    searchBox.refine(search);
  }, [search]);

  return (
    <>
      {hits.hits
        .filter((x) => !blacklist?.includes(getLivePriceId(x)))
        .map((x) => {
          const orderSymbol = {
            symbol: x.symbol,
            exchange: x.exchange,
            market_type: x.market_type,
            coin1: x.coin1,
            coin2: x.coin2,
            coin_margin: x.coin_margin,
            is_derivative: x.is_derivative,
          };

          return <SymbolListItem key={getLivePriceId(x)} symbol={orderSymbol} onSelect={() => onSelect(orderSymbol)} />;
        })}
    </>
  );
};
