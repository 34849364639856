import { cutAmount, formatWithDelimiter } from "@src/utils/format";

export function formatPrice(price: number, precision?: number, cut?: boolean) {
  const res = cutAmount(price.toString(), precision, cut);

  return formatWithDelimiter(res);
}

export function interpolatePrice(
  side: "asks" | "bids",
  amount: number,
  orderBook: { asks: number[][]; bids: number[][] }
) {
  let prices;

  if (side === "asks") {
    prices = orderBook.asks;
  } else {
    prices = orderBook.bids;
  }

  const [minPrice, minQty] = prices[0];
  const [maxPrice, maxQty] = prices[1];

  const interpolatedPrice = minPrice + ((amount - minQty) * (maxPrice - minPrice)) / (maxQty - minQty);

  return interpolatedPrice;
}
