import { WidgetConfig, WidgetName } from "../../types";
import { MAX_GROUPS } from "../../constants";

export const CefiOrderbookWidgetConfig = {
  name: WidgetName.CefiOrderbook,
  layout: {
    xxl: { x: 3, y: 0, w: 3, h: 22, minW: 1, minH: 7 },
    xl: { x: 4, y: 0, w: 3, h: 22, minW: 2, minH: 7 },
    lg: { x: 6, y: 0, w: 4, h: 22, minW: 3, minH: 7 },
    md: { x: 8, y: 0, w: 5, h: 22, minW: 5, minH: 7 },
    sm: { x: 4, y: 0, w: 5, h: 22, minW: 5, minH: 7 },
    xs: { x: 0, y: 0, w: 24, h: 22, minW: 5, minH: 7 },
  },
  options: {
    multiple: true,
    removable: true,
    withGroups: true,
    maxNumber: MAX_GROUPS,
    symbolSelect: true,
  },
} as WidgetConfig;
