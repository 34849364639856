import React from "react";
import { Skeleton, Stack, Typography, styled } from "@mui/material";
import { formatPrice } from "./utils";
import { digitsAfterZeros, formatWithDelimiter } from "@src/utils/format";

export const Spread = ({ sell, buy }: { sell: number; buy: number }) => {
  const midPrice = (sell + buy) / 2;
  const spread = buy - sell;
  const spreadPct = (spread / midPrice) * 100;

  return (
    <Root>
      {sell ? (
        <Typography
          variant="body2"
          fontSize={10}
          color={(theme) => theme.palette.text.secondary}
          position="relative"
          zIndex={3}
          whiteSpace="nowrap"
        >
          {formatWithDelimiter(digitsAfterZeros(spread))} - {formatPrice(spreadPct, 2, true)}%
        </Typography>
      ) : (
        <Skeleton height={10} width={55} sx={{ zIndex: 3, transform: "none" }} />
      )}
      <Separator />
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  position: "absolute",
  left: "50%",
  top: 0,
  transform: "translate(-50%, 0)",
  zIndex: 10,
  padding: theme.spacing(0, 1),
  minHeight: 17,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  "&:before": {
    content: '""',
    position: "absolute",
    display: "block",
    top: 0,
    left: 0,
    width: "50%",
    height: "100%",
    borderLeft: "1px solid #C3462B",
    borderBottom: "1px solid #C3462B",
    borderBottomLeftRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    zIndex: 1,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    display: "block",
    top: 0,
    right: 0,
    width: "50%",
    height: "100%",
    borderRight: "1px solid #179B83",
    borderBottom: "1px solid #179B83",
    borderBottomRightRadius: theme.shape.borderRadius,
    background: theme.palette.background.paper,
    zIndex: 1,
  },
}));

const Separator = styled(Stack)(({ theme }) => ({
  width: theme.spacing(1),
  background: theme.palette.background.paper,
  position: "absolute",
  left: "50%",
  top: 0,
  transform: "translate(-50%, 0)",
  height: "100%",
  zIndex: 2,
}));
