import { anbotoApi } from "@src/store/apis/anbotoApi";
import { GetTokenTableParams, GetTokenTableResult } from "@src/pages/portfolio";

export type GetDefiTokenTableParams = {
  currency: string;
  wallet_labels?: string;
};

const portfolioDefiTokenTableApi = anbotoApi.injectEndpoints({
  endpoints: (build) => ({
    portfolioDefiTokenTable: build.query<GetTokenTableResult, GetTokenTableParams>({
      query: (params) => ({
        url: `/portfolio/defi_token_table/`,
        params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const portfolioDefiTokenTable = portfolioDefiTokenTableApi.endpoints.portfolioDefiTokenTable;
export const {
  useLazyPortfolioDefiTokenTableQuery,
  usePortfolioDefiTokenTableQuery,
  endpoints: endpointsDefi,
} = portfolioDefiTokenTableApi;
