import React from "react";
import { useAppSelector } from "@src/store/hooks";

export const PositionsCount = () => {
  const symbols = useAppSelector((state) => state.portfolio.stream.positions);

  const positions = Object.values(symbols).filter((symbol) => symbol.isPosition && !!symbol.balance);

  return <>{positions.length}</>;
};
