import React, { useMemo } from "react";
import { Stack, Card, CardHeader, Typography, Box, IconButton, Grid, useTheme } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { Logout } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useAccount, useDisconnect } from "wagmi";
import { ChainFeesField } from "../chain-fee-multiselect";
import { TokenFeesField } from "../token-fee-multiselect";
import { DepositAndWithdrawForm } from "../utils/useDepositAndWithdrawForm";
import { QuantityInput } from "@src/pages/cefi/order-form/fields/QuantityInput";
import { truncateAccount } from "@src/pages/defi/utils";
import { ChainId } from "@src/pages/defi/types";
import { onlyNumbers } from "@src/utils/only-numbers";
import { useWalletName } from "@src/hooks/useWalletName";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { CopyToClipboard } from "@src/components";
import {
  QuantityStatus,
  UnlockingLabelButton,
} from "@src/pages/settings/fee-manager/components/unlocking-label-button";
import { useFeeManagerBalances } from "@src/pages/settings/fee-manager/hooks/use-fee-manager-balances";
import { DEFAULT_FEE_MANAGER_BALANCES } from "@src/pages/settings/fee-manager/constants";

export type WithdrawFormType = UseFormReturn<DepositAndWithdrawForm>;

type WithdrawInputsType = {
  withdrawalRequestForm: WithdrawFormType;
  withdrawalClaimForm: WithdrawFormType;
  symbolsReccord: Record<string, string>;
  onConfirmWithdraw(form: WithdrawFormType): Promise<void>;
  confirmationLoading: boolean;
};

export const WithdrawInputs = ({
  withdrawalRequestForm,
  withdrawalClaimForm,
  symbolsReccord,
  onConfirmWithdraw,
  confirmationLoading,
}: WithdrawInputsType) => {
  const theme = useTheme();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { name } = useWalletName(address || "");
  const tokenSymbol = withdrawalRequestForm.watch("tokenSymbol");
  const requestedAmount = withdrawalRequestForm.watch("amount");
  const claimedAmount = withdrawalClaimForm.watch("amount");

  const feeTokens = useMemo(
    () => [
      {
        chainId: withdrawalRequestForm.getValues("chainId"),
        tokenAddress: withdrawalRequestForm.getValues("tokenAddress"),
      },
    ],
    [withdrawalRequestForm.getValues("chainId"), withdrawalRequestForm.getValues("tokenAddress")]
  );

  const feeManagerBalances = useFeeManagerBalances({
    feeTokens,
  });

  const onChainIdChange = (newChainId: ChainId) => {
    withdrawalRequestForm.setValue("chainId", newChainId);
    withdrawalClaimForm.setValue("chainId", newChainId);
  };

  const onTokenAddressChange = (newTokenAddress: string) => {
    withdrawalRequestForm.setValue("tokenAddress", newTokenAddress);
    withdrawalRequestForm.setValue("tokenSymbol", symbolsReccord[newTokenAddress]);
    withdrawalRequestForm.setValue("amount", "");

    withdrawalClaimForm.setValue("tokenAddress", newTokenAddress);
    withdrawalClaimForm.setValue("tokenSymbol", symbolsReccord[newTokenAddress]);
    withdrawalClaimForm.setValue("amount", "");
  };

  const onQuantityChange = (value: string, form: WithdrawFormType) => {
    const quantity = onlyNumbers(value);
    if (quantity) {
      form.setValue("amount", quantity);
    } else {
      form.setValue("amount", "");
    }
  };

  const onMaxClickRequest = () => withdrawalRequestForm.setValue("amount", requestableBalance || "");
  const onMaxClickClaim = () => withdrawalClaimForm.setValue("amount", claimableBalance || "");

  const currentFeeBalances =
    feeManagerBalances.feeBalances[feeTokens[0].chainId + feeTokens[0].tokenAddress] || DEFAULT_FEE_MANAGER_BALANCES;
  const { claimableBalance, unclaimableBalance, requestableBalance } = currentFeeBalances;
  const claimableBalanceFloat = parseFloat(claimableBalance || "0");
  const unclaimableBalanceFloat = parseFloat(unclaimableBalance || "0");

  const requestExceededBalance = parseFloat(requestedAmount) > parseFloat(requestableBalance);
  const requestIsDisabled = !requestedAmount.length || !parseFloat(requestableBalance) || requestExceededBalance;
  const requestIsLoading = confirmationLoading && !!requestedAmount.length;

  const claimExceededBalance = parseFloat(requestedAmount) > parseFloat(requestableBalance);
  const claimIsDisabled = !claimedAmount.length || !claimableBalanceFloat || claimExceededBalance;
  const claimIsLoading = confirmationLoading && !!claimedAmount.length;

  return (
    <Stack justifyContent="center" maxWidth="600px">
      <Card sx={{ bgcolor: "background.default" }}>
        <CardHeader
          titleTypographyProps={{ noWrap: true }}
          sx={{ maxHeight: "55px" }}
          title={
            <Stack direction="row" justifyContent="space-between">
              <Typography color="text.secondary">Withdraw with</Typography>
              <Stack direction="row" gap={1}>
                <Typography>{name || truncateAccount(address!)}</Typography>
                <CopyToClipboard textToCopy={address!} />
              </Stack>
            </Stack>
          }
          action={
            <Box sx={{ mt: -0.8, ml: 1 }}>
              <IconButton onClick={() => disconnect()}>
                <Logout />
              </IconButton>
            </Box>
          }
        />
      </Card>
      <Grid container direction="column" alignItems="center" width="100%">
        <Grid item width="100%" py={1}>
          <Stack direction="row" gap={2}>
            <Controller
              name="chainId"
              control={withdrawalRequestForm.control}
              render={({ field }) => <ChainFeesField {...field} onChange={onChainIdChange} />}
            />
            <Controller
              name="tokenAddress"
              control={withdrawalRequestForm.control}
              rules={{ required: true }}
              render={({ field }) => (
                <TokenFeesField
                  {...field}
                  selectedChainField={withdrawalRequestForm.watch("chainId")}
                  onChange={onTokenAddressChange}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item width="100%">
          {unclaimableBalanceFloat > 0 && (
            <UnlockingLabelButton disabled quantityStatus={QuantityStatus.LOCKED}>
              {unclaimableBalance} {tokenSymbol} will be unlocked for withdraw in {countdownToMidnight()}
            </UnlockingLabelButton>
          )}
          {claimableBalanceFloat > 0 && (
            <UnlockingLabelButton disabled quantityStatus={QuantityStatus.UNLOCKED}>
              {claimableBalance} {tokenSymbol} are available for withdraw to your admin wallet
            </UnlockingLabelButton>
          )}
        </Grid>
        <Grid item py={1}>
          <Stack direction="row" gap={2}>
            <Box width="20px" fontSize={20} fontWeight="bold" color="text.disabled">
              1
            </Box>
            <Controller
              name="amount"
              control={withdrawalRequestForm.control}
              render={({ field, fieldState }) => {
                const amountExceededBalance = parseFloat(field.value) > parseFloat(requestableBalance);
                const errorMessage =
                  fieldState.error?.message ||
                  (amountExceededBalance && `Only ${requestableBalance} ${tokenSymbol} are available`);
                const hasError = !!errorMessage || amountExceededBalance;

                return (
                  <QuantityInput
                    {...field}
                    size="medium"
                    max={true}
                    maxProps={{ onClick: onMaxClickRequest }}
                    error={hasError}
                    helperText={errorMessage}
                    disabled={!parseFloat(requestableBalance)}
                    onChange={(e) => onQuantityChange(e.target.value, withdrawalRequestForm)}
                    label={
                      <Stack direction="row" justifyContent="space-between" width="100%">
                        <Typography fontSize={12}>
                          Quantity to request {requestableBalance && `(${requestableBalance} available)`}
                        </Typography>
                      </Stack>
                    }
                  />
                );
              }}
            />
            <Box width="20px" fontSize={20} fontWeight="bold" color="text.disabled">
              2
            </Box>
            <Controller
              name="amount"
              control={withdrawalClaimForm.control}
              render={({ field, fieldState }) => {
                const amountExceededBalance = parseFloat(field.value) > parseFloat(claimableBalance);
                const errorMessage =
                  fieldState.error?.message ||
                  (amountExceededBalance && `Only ${claimableBalance} ${tokenSymbol} are available`);
                const hasError = !!errorMessage || amountExceededBalance;

                return (
                  <QuantityInput
                    {...field}
                    size="medium"
                    max={!!parseFloat(claimableBalance)}
                    maxProps={{ onClick: onMaxClickClaim }}
                    error={hasError}
                    helperText={errorMessage}
                    disabled={!parseFloat(claimableBalance)}
                    onChange={(e) => onQuantityChange(e.target.value, withdrawalClaimForm)}
                    label={
                      <Stack direction="row" justifyContent="space-between" width="100%">
                        <Typography fontSize={12}>
                          Quantity to withdraw {claimableBalance && `(${claimableBalance} available)`}
                        </Typography>
                      </Stack>
                    }
                  />
                );
              }}
            />
          </Stack>
        </Grid>
        <Grid item width="100%" py={1}>
          <Stack direction="row" width="100%" gap={2}>
            <AnbotoButton
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              disabled={requestIsDisabled}
              loading={requestIsLoading}
              onClick={() => onConfirmWithdraw(withdrawalRequestForm)}
            >
              Request
            </AnbotoButton>
            <ArrowForwardIosIcon sx={{ fontSize: 35, color: theme.custom.colors.gray }} />
            <AnbotoButton
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              disabled={claimIsDisabled}
              loading={claimIsLoading}
              onClick={() => onConfirmWithdraw(withdrawalClaimForm)}
            >
              Withdraw
            </AnbotoButton>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export const countdownToMidnight = () => {
  const now: Date = new Date();
  const midnight: Date = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0));
  const countdown = midnight.getTime() - now.getTime();

  const hours = Math.floor(countdown / (1000 * 60 * 60));
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));

  let strCountdown = "";

  if (hours < 1 && minutes < 1) {
    strCountdown = "less than one minute";
  } else if (hours < 1) {
    strCountdown = `${minutes} minutes`;
  } else {
    strCountdown = `${hours} hours and ${minutes} minutes`;
  }
  return strCountdown;
};
