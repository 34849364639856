import React, { useEffect } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { NoTableData } from "@src/components/no-table-data";
import { FeeCollects, TradingMonthlyActivity } from "@src/store/apis/anbotoApi/types";

export const MonthlyDashboard = () => {
  const [fetchMonthlyDashboard, { isLoading }] = anbotoApi.useLazyGetMonthlyDashboardQuery();

  const currentDate = new Date();
  const currentDayjsDate = dayjs(currentDate);

  const [value, setValue] = React.useState<Dayjs | null>(currentDayjsDate);
  const [monthlyTradingActivity, setMonthlyTradingActivity] = React.useState<TradingMonthlyActivity>([]);
  const [monthlyActivityByAccount, setMonthlyActivityByAccount] = React.useState<any>([]);
  const [feeCollects, setFeeCollects] = React.useState<FeeCollects[]>([]);
  const [, setAnchorEl] = React.useState<(EventTarget & HTMLDivElement) | undefined>();

  const setMonthlyDashboard = async (date) => {
    const monthlyDashboardResult = await fetchMonthlyDashboard({ month: date.format("YYYY-MM") });

    if (monthlyDashboardResult.data) {
      setMonthlyTradingActivity(monthlyDashboardResult.data.trading_activity || []);
      setMonthlyActivityByAccount(monthlyDashboardResult.data.trading_activity_by_account || []);
      setFeeCollects(monthlyDashboardResult.data.fee_collects || []);
    }
  };

  useEffect(() => {
    setMonthlyDashboard(currentDayjsDate);
  }, []);

  const onChangeMonth = (newValue) => {
    setValue(newValue);
    setMonthlyDashboard(newValue);
  };

  return (
    <Stack gap={0.5}>
      <Paper>
        <Box sx={{ m: 4 }}>
          <DatePicker
            views={["year", "month"]}
            label="Controlled picker"
            value={value}
            onChange={onChangeMonth}
            renderInput={(params) => (
              <AnbotoTextField size="small" {...params} onClick={(e) => setAnchorEl(e.currentTarget)} />
            )}
          />
        </Box>
        {isLoading ? (
          <Stack height={300} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack maxWidth="100%" overflow="auto" direction="row" flexWrap="wrap">
            <Box sx={{ px: 1 }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    width: 640,
                    marginBottom: 10,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell align="right">Total volume</TableCell>
                      <TableCell align="right">Daily cefi volume</TableCell>
                      <TableCell align="right">Daily defi volume</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {monthlyTradingActivity.length > 0 ? (
                      monthlyTradingActivity.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 }, border: 0, elevation: 0 }}
                        >
                          <TableCell component="th" scope="row">
                            {row.date || 0}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {row.total_daily_volume || 0}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {row.daily_cefi_volume || 0}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {row.daily_defi_volume || 0}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            gap={2}
                            justifyContent="center"
                            height={238}
                          >
                            <NoTableData />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ px: 1 }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    width: 340,
                    marginBottom: 10,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Collect date</TableCell>
                      <TableCell align="right">Amount collected</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feeCollects.length > 0 ? (
                      feeCollects.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 }, border: 0, elevation: 0 }}
                        >
                          <TableCell component="th" scope="row">
                            {row.proceed_at}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {row.amount || 0}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={11} align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            gap={2}
                            justifyContent="center"
                            height={238}
                          >
                            <NoTableData />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ px: 1 }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    width: 640,
                    marginBottom: 10,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right">Volume CEFI</TableCell>
                      <TableCell align="right">Volume DEFI</TableCell>
                      <TableCell align="right">Volume Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {monthlyActivityByAccount.length > 0 ? (
                      <>
                        {monthlyActivityByAccount.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 }, border: 0, elevation: 0 }}
                          >
                            <TableCell component="th" scope="row">
                              {row.account_number} / {row.admin_email}
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {Math.round(row.daily_cefi_volume) || 0}
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {Math.round(row.daily_defi_volume) || 0}
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                              {Math.round(row.total_daily_volume) || 0}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow
                          key="total"
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            border: 0,
                            elevation: 0,
                            fontWeight: 800,
                          }}
                        >
                          <TableCell component="th" scope="row">
                            TOTAL
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {Math.round(monthlyActivityByAccount.reduce((acc, cur) => acc + cur.daily_cefi_volume, 0))}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {Math.round(
                              monthlyActivityByAccount.reduce((acc, cur) => acc + cur.daily_defi_volume, 0)
                            ) || 0}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {Math.round(
                              monthlyActivityByAccount.reduce((acc, cur) => acc + cur.total_daily_volume, 0)
                            ) || 0}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={11} align="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                            gap={2}
                            justifyContent="center"
                            height={238}
                          >
                            <NoTableData />
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        )}
      </Paper>
    </Stack>
  );
};
