import { useState, useEffect, useCallback } from "react";
import GridLayout from "react-grid-layout";
import { WidgetsGridView, WidgetsLayout } from "../types";
import { updateLayout } from "../store/widgets.slice";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

export const useWidgetsGridLayout = (view: WidgetsGridView) => {
  const layout = useAppSelector((state) => state.widgets.views[view]?.layout) as WidgetsLayout;
  const dispatch = useAppDispatch();
  const [loading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!view) setError("widgets layout view is not specified");
    if (!layout) setError(`${view} layout is not found`);
  }, [layout, view]);

  const dispatchUpdateLayout = useCallback((layout: GridLayout.Layout[]) => {
    dispatch(updateLayout(layout));
  }, []);

  return { layout: layout || {}, loading, updateLayout: dispatchUpdateLayout, error };
};
