import { useEffect, useState } from "react";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export const useLinkedExchanges = () => {
  const [exchanges, setExchanges] = useState<string[]>([]);

  const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);

  const getExchangeConfigsQuery = anbotoApi.useGetExchangeConfigsQuery(getUserDetailsQuery.data?.team_uuid || null, {
    skip: !getUserDetailsQuery.data,
  });

  useEffect(() => {
    if (getExchangeConfigsQuery.data?.results) {
      const exchanges = Object.keys(
        getExchangeConfigsQuery.data.results.reduce((res, { exchange_id }) => ({ ...res, [exchange_id]: true }), {})
      );

      setExchanges(exchanges);
    }
  }, [getExchangeConfigsQuery.data]);

  return {
    exchanges,
    isLoading: getUserDetailsQuery.isLoading || getExchangeConfigsQuery.isLoading,
  };
};
