import React from "react";
import { Stack, StackProps, Typography, styled } from "@mui/material";
import { CopyToClipboard } from "@src/components";
import { useAppSelector } from "@src/store/hooks";
import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";

export const ExchangeIps = ({ exchange, ...props }: { exchange: CefiExchangeId } & StackProps) => {
  const ipAddresses = useAppSelector((state) => state.user.ip_addresses);
  const ips = ipAddresses?.length ? ipAddresses : (process.env.REACT_APP_CEFI_EXCHANGE_PUBLIC_IPS || "").split(",");

  let separator = ",";

  if (exchange.toLowerCase() === CefiExchangeId.BINANCE) {
    separator = " ";
  }

  const ipsStr = ips.join(separator);

  return (
    <Dim alignItems="center" gap={0.5} {...props}>
      <Text variant="body1">It is strongly recommended to only allow this IP address list below:</Text>
      {ips.length ? (
        <Stack gap={3} direction="row">
          {ips?.map((ip: string) => (
            <CopyToClipboard label={ip} textToCopy={ip} key={ip} />
          ))}
          <CopyToClipboard label="Copy all" textToCopy={ipsStr} key={ipsStr} />
        </Stack>
      ) : (
        "-"
      )}
    </Dim>
  );
};

const Dim = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.default,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
