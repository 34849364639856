import React, { useRef } from "react";
import { Skeleton, Stack, Tooltip, Typography, styled } from "@mui/material";
import { openLeverageModal } from "./leverage.slice";
import { MODAL_HEIGHT, MODAL_WIDTH } from "./leverage-modal";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

export const Leverage = ({ symbol, account }: { symbol: OrderSymbol | null; account: string }) => {
  const modalTarget = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const accountId = useAppSelector((state) => state.user.team_uuid);

  const { data, isFetching } = anbotoApi.useGetSymbolAccountBalanceQuery(
    { symbol: symbol!, account: account! },
    { skip: !symbol || !account }
  );

  return (
    symbol && (
      <Stack direction="row" gap={1} justifyContent="flex-end" pt={0.5}>
        {isFetching ? (
          <Skeleton height={20} width={100} />
        ) : (
          <>
            <Tooltip title="Margin mode">
              <Typography variant="body2" fontSize={12} textTransform="capitalize">
                {data?.margin_mode}
              </Typography>
            </Tooltip>
            <Tooltip title="Leverage">
              <LeverageAmount
                variant="body2"
                fontSize={12}
                ref={modalTarget}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  window.el = modalTarget?.current;

                  modalTarget.current &&
                    dispatch(
                      openLeverageModal({
                        accountId,
                        symbol,
                        x:
                          Math.abs(document.body.getBoundingClientRect().left) +
                            modalTarget.current.getBoundingClientRect().left -
                            MODAL_WIDTH || 0,
                        y:
                          Math.abs(document.body.getBoundingClientRect().top) +
                            modalTarget.current.getBoundingClientRect().top -
                            MODAL_HEIGHT || 0,
                        leverage: +(data?.leverage || 1),
                      })
                    );
                }}
              >
                {data?.leverage ? `${data.leverage}x` : "--"}
              </LeverageAmount>
            </Tooltip>
          </>
        )}
      </Stack>
    )
  );
};

const LeverageAmount = styled(Typography)(() => ({
  textDecoration: "underline",
  cursor: "pointer",
}));
