import React from "react";
import { Divider, List, ListSubheader, styled, Box, MenuItem, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToggleSidebarButton } from "../sidebar";
import { MenuItemId } from "../layout/main-layout/main-menu-dropdown";
import { styledOptions } from "@src/utils/styled-options";
import SidebarMenuItem from "@src/components/sidebar-menu/sidebar-menu-item";
import { AnalyticsLinkLabel, TradingTypeLabel } from "@src/components/sidebar-menu/types";

export type MenuPart = {
  header: string;
  offset?: number;
  className?: string;
  items: MenuItem[];
};

export type MenuItem = {
  id: MenuItemId;
  label: string;
  url: string;
  external?: boolean;
  icon?: React.ReactElement;
  iconActive?: React.ReactElement;
  component?: React.ElementType<any>;
  className?: string;
};

export type ExtraProps = {
  active?: boolean;
  vertical?: boolean;
  compact?: boolean;
  swipeableMode?: boolean;
};

export type SidebarMenuProps = {
  menu: MenuPart[];
  toggleSidebar?: () => void;
  isVertical?: boolean;
  compact?: boolean;
  swipeableMode?: boolean;
};

export const SidebarMenu = ({
  menu,
  toggleSidebar,
  isVertical = true,
  compact,
  swipeableMode = false,
}: SidebarMenuProps) => {
  return (
    <Menu vertical={isVertical} sx={{ alignItems: "stretch" }}>
      {menu.map(({ header, items, offset }, index) => (
        <React.Fragment key={header}>
          {!!index && !compact && (
            <Divider
              sx={{
                my: 0,
                ml: isVertical ? 0 : 1,
                height: isVertical ? "auto" : 24,
                border: swipeableMode ? "0.5px solid #192022" : null,
              }}
              orientation={isVertical ? "horizontal" : "vertical"}
            />
          )}
          <StyledList
            vertical={isVertical}
            className="cefi-defi-menu"
            swipeableMode={swipeableMode}
            subheader={
              !compact ? (
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <StyledListSubheader sx={{ height: swipeableMode ? "40px" : null }} disableSticky>
                    {header}
                  </StyledListSubheader>
                  {((isVertical && header === TradingTypeLabel.CEFI) ||
                    (isVertical && header === AnalyticsLinkLabel.ANALYTICS)) && (
                    <ToggleSidebarButton sx={{ my: 1 }} onClick={toggleSidebar}>
                      <ArrowBackIcon sx={{ width: 20, height: 20 }} />
                    </ToggleSidebarButton>
                  )}
                </Stack>
              ) : null
            }
          >
            {items.map(({ className, label, url, icon, iconActive, external, component }, index) => (
              <SidebarMenuItem
                key={url}
                label={label}
                url={url}
                external={external}
                icon={icon}
                iconActive={iconActive}
                isVertical={isVertical}
                compact={!!compact}
                toggleSidebar={toggleSidebar}
                sx={{ marginTop: index ? offset : undefined }}
                component={component}
                className={className}
              />
            ))}
          </StyledList>
        </React.Fragment>
      ))}
    </Menu>
  );
};

const StyledList = styled(
  List,
  styledOptions
)<ExtraProps>(({ vertical, theme, swipeableMode }) => ({
  display: vertical ? "block" : "flex",
  paddingTop: theme.spacing(1.5),
  paddingRight: theme.spacing(0.75),
  paddingBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(0.75),
  alignItems: "stretch",
  backgroundColor: swipeableMode ? theme.custom.background.darkGrey : theme.palette.background.paper,
}));

export const StyledListSubheader = styled(ListSubheader)({
  backgroundColor: "transparent",
  display: "flex",
  alignItems: "center",
  fontSize: 18,
});

const Menu = styled(
  Box,
  styledOptions
)<ExtraProps>(({ vertical }) => ({
  display: vertical ? undefined : "flex",
  flex: 1,
  justifyContent: "flex-end",
}));
