import React, { useEffect, useState } from "react";
import { Hub } from "aws-amplify/utils";
import { fetchAuthSession, fetchMFAPreference, FetchMFAPreferenceOutput } from "aws-amplify/auth";
import "./configure";
import { useAppDispatch } from "@src/store/hooks";
import { setUser } from "@src/store/actions/user.action";

type AuthenticatorState = {
  ready: boolean;
  authenticated: boolean;
  mfa: FetchMFAPreferenceOutput;
  refetchSession: (options?: { updateAuthenticated?: boolean; forceRefresh?: boolean }) => Promise<void>;
};

interface AuthenticatorProviderProps {}

export const AuthenticatorContext = React.createContext<AuthenticatorState>({} as AuthenticatorState);

export const AuthenticatorProvider = ({ children }: React.PropsWithChildren<AuthenticatorProviderProps>) => {
  const [ready, setReady] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [mfa, setMfa] = useState<FetchMFAPreferenceOutput>({});
  const dispatch = useAppDispatch();

  const refetchSession = async (options?: { forceRefresh?: boolean }) => {
    const { forceRefresh = false } = options || {};

    try {
      const { tokens } = await fetchAuthSession({ forceRefresh });

      if (tokens) {
        setAuthenticated(true);

        const mfaPreference = await fetchMFAPreference();

        setMfa(mfaPreference);

        dispatch(setUser({ idToken: tokens?.idToken?.toString() }));
      }
    } catch (error) {
      setAuthenticated(false);
    } finally {
      if (!ready) setReady(true);
    }
  };

  useEffect(() => {
    refetchSession({ forceRefresh: true });

    Hub.listen("auth", async ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          console.log("user have been signedIn successfully.");
          refetchSession();
          break;
        case "signedOut":
          console.log("user have been signedOut successfully.");
          setAuthenticated(false);
          break;
        case "tokenRefresh":
          console.log("auth tokens have been refreshed.");
          refetchSession();
          break;
        case "tokenRefresh_failure":
          console.log("failure while refreshing auth tokens.");
          break;
        case "signInWithRedirect":
          console.log("signInWithRedirect API has successfully been resolved.");
          break;
        case "signInWithRedirect_failure":
          console.log("failure while trying to resolve signInWithRedirect API.");
          break;
        case "customOAuthState":
          console.info("custom state returned from CognitoHosted UI");
          break;
      }
    });
  }, []);

  return (
    <AuthenticatorContext.Provider value={{ authenticated, ready, mfa, refetchSession }}>
      {children}
    </AuthenticatorContext.Provider>
  );
};
