import { useEffect, useState, useCallback } from "react";
import format from "date-fns/format";
import _debounce from "lodash/debounce";
import { getParentOrderStatus } from "../utils";
import { END_TIME_FORMAT } from "@src/pages/cefi/constants";
import { ParentOrder } from "@src/store/apis/anbotoApi/types";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";

type SearchIndex = { id: string; index: string };

export const useParentOrdersTableSearch = (results: ParentOrder[] = [], searchString = "") => {
  const [searchIndexes, setSearchIndexes] = useState<SearchIndex[]>([]);
  const [searchFilter, setSearchFilter] = useState<Record<string, boolean>>({});

  const getIndexes = (results: ParentOrder[]): SearchIndex[] =>
    results?.map(
      (result) =>
        ({
          id: result.order_id,
          index: (
            result.order_id +
            result.exchange +
            result.asset_class +
            getOrderSymbolLabel({
              symbol: result.symbol,
              market_type: result.asset_class,
              exchange: result.exchange,
            }) +
            result.symbol +
            result.side +
            result.quantity +
            result.strategy +
            (result.start_time ? format(new Date(+result.start_time), END_TIME_FORMAT) : "") +
            (result.end_time ? format(new Date(+result.end_time), END_TIME_FORMAT) : "") +
            result.asset_class +
            result.trader_email +
            (result.last_status
              ? getParentOrderStatus(
                  result.last_status.status,
                  result.quantity,
                  result.last_status.filled_quantity,
                  result.last_status.error_code
                )
              : "")
          ).toLowerCase(),
        } || [])
    );

  const debouncedSetSearchIndexes = useCallback(
    _debounce((results: ParentOrder[]) => {
      setSearchIndexes(getIndexes(results));
    }, 500),
    [results]
  );

  useEffect(() => {
    debouncedSetSearchIndexes(results);
  }, [results]);

  useEffect(() => {
    const res = searchIndexes.reduce((prev, { id, index }) => {
      if (searchString.split(" ").every((word) => index.includes(word))) {
        prev[id] = true;
      }
      return prev;
    }, {});
    setSearchFilter(res);
  }, [searchString, searchIndexes]);

  return searchString ? results.filter((order) => searchFilter[order.order_id]) : results;
};
