export const onlyNumbers = (value = "", noDots?: boolean) => {
  if (noDots) return value.replace(/[^0-9]/g, "");

  let val = value.replace(/[^0-9.]/g, "");

  if (val[0] === ".") {
    val = `0.${val.split(".")[0]}`;
  }
  if (val.split("").filter((char) => char === ".").length > 1) {
    val = val.slice(0, val.length - 1);
  }
  return val;
};
