import React, { useEffect } from "react";
import { styled, Stack, Box, BoxProps } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  useTable,
  useExpanded,
  usePagination,
  Column as TableColumn,
  useFlexLayout,
  useSortBy,
  TableState,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { TableBody } from "./table-body";
import { TableRow } from "./table-row";
import { TableHeaderCell } from "./table-header-cell";
import { TableCell } from "./table-cell";
import { PaginationTooltip } from "./pagination-tooltip";
import { AnbotoPagination } from "@src/components/ui/anboto-pagination";
import { TableDataError } from "@src/components/table-data-error";
import { NoTableData } from "@src/components/no-table-data";
import { TableFooterCell } from "@src/components/orders-table/table-footer-cell";

export type Column<T extends object = {}> = TableColumn<T> & { sticky?: string };

export type TOrdersTable<T extends {}> = {
  data: Array<T>;
  columns: Array<Column<T>>;
  loading: boolean;
  error?: boolean;
  refetch?: () => void;
  expandedRowRender?: (row: T) => React.ReactNode;
  limit?: number;
  pagination?: boolean;
  striped?: boolean;
  noDataComponent?: React.ReactElement;
  tableProps?: BoxProps;
  tableBodyProps?: BoxProps;
  initialState?: Partial<TableState<T>>;
  paginationWithDataFetching?: {
    count: number;
    pagination: { currentPage: number; rowsPerPage: number };
    setPagination: (pagination: { currentPage: number; rowsPerPage: number }) => void;
  };
  hasFooter?: boolean;
  containerProps?: BoxProps;
  noDataText?: string;
};

export const CELL_FONT_SIZE = 12;

export const sort = (a, b) => (a > b ? 1 : a < b ? -1 : 0);

export const renderSortArrows = (desc?: boolean) =>
  desc ? <ArrowDownwardIcon sx={{ width: 16, height: 16 }} /> : <ArrowUpwardIcon sx={{ width: 16, height: 16 }} />;

export function OrdersTable<T extends {}>({
  data,
  columns,
  loading,
  error,
  refetch,
  expandedRowRender,
  limit,
  noDataComponent,
  tableProps,
  tableBodyProps,
  initialState,
  pagination,
  paginationWithDataFetching,
  hasFooter,
  containerProps,
  noDataText,
}: TOrdersTable<T> & BoxProps) {
  const table = useTable<T>(
    {
      data,
      columns,
      autoResetExpanded: false,
      initialState,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useSticky
  );

  const tooltip = React.useMemo(
    () => (limit && !paginationWithDataFetching ? <PaginationTooltip limit={limit} /> : null),
    [limit]
  );

  useEffect(() => {
    if (paginationWithDataFetching) {
      table?.setPageSize(paginationWithDataFetching?.pagination.rowsPerPage || 10);
    }

    if (!paginationWithDataFetching && !pagination && limit) {
      table?.setPageSize(limit);
    }
  }, [paginationWithDataFetching, pagination, limit]);

  const changeRowsPerPageHandler = (value: number) => {
    table?.setPageSize(value);
    paginationWithDataFetching?.setPagination({ currentPage: 0, rowsPerPage: value });
  };

  const changePageHandler = (value: number) => {
    paginationWithDataFetching?.setPagination({
      currentPage: value,
      rowsPerPage: paginationWithDataFetching?.pagination.rowsPerPage,
    });
  };

  const handlePageSizeChange = (value: number) => {
    table?.setPageSize(value);
  };

  return (
    <>
      <Box sx={{ overflow: "auto", height: "100%" }} {...containerProps}>
        <Table {...table.getTableProps()} {...tableProps}>
          {table.headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeaderCell
                    {...header.getHeaderProps(header.getSortByToggleProps())}
                    expandable={!!expandedRowRender}
                  >
                    <Stack direction="row" alignItems="center">
                      {header.render("Header")}
                      {header.isSorted && !["triggerPriceBelow", "triggerPriceAbove"].includes(header.id)
                        ? renderSortArrows(header.isSortedDesc)
                        : null}
                    </Stack>
                  </TableHeaderCell>
                );
              })}
            </TableRow>
          ))}
          <TableBody {...table.getTableBodyProps()} {...tableBodyProps}>
            {error ? (
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap={2}
                justifyContent="center"
                height={238}
              >
                <TableDataError onRefresh={() => refetch && refetch()} />
              </Box>
            ) : table.rows.length === 0 && !loading ? (
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                gap={2}
                justifyContent="center"
                height={238}
              >
                {noDataComponent || <NoTableData message={noDataText} />}
              </Box>
            ) : (
              table.page?.map((row, index) => {
                table.prepareRow(row);
                return (
                  <React.Fragment key={row.id || index}>
                    <TableRow {...row.getRowProps()} loading={loading}>
                      {row.cells?.map((cell, index: number) => (
                        <TableCell
                          loading={loading}
                          {...cell.getCellProps()}
                          sx={{ px: !index ? 0 : undefined }}
                          expandable={!!expandedRowRender}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                    {!loading && row.isExpanded && expandedRowRender && expandedRowRender(row?.original as T)}
                  </React.Fragment>
                );
              })
            )}
          </TableBody>

          {hasFooter &&
            table.footerGroups.map((footerGroup) => (
              <TableRow {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((footer) => {
                  return (
                    <TableFooterCell
                      {...footer.getFooterProps(footer.getSortByToggleProps())}
                      expandable={!!expandedRowRender}
                    >
                      <Stack direction="row" alignItems="center">
                        {footer.render("Footer")}
                      </Stack>
                    </TableFooterCell>
                  );
                })}
              </TableRow>
            ))}
        </Table>
      </Box>
      {pagination && (
        <AnbotoPagination
          total={table?.data?.length}
          currentPage={table?.state?.pageIndex}
          pageSize={table?.state?.pageSize}
          onChangeRowsPerPage={handlePageSizeChange}
          onChangePage={(value) => table?.gotoPage(value)}
          prefix={tooltip}
        />
      )}
      {paginationWithDataFetching && (
        <AnbotoPagination
          total={paginationWithDataFetching.count || 0}
          currentPage={paginationWithDataFetching.pagination.currentPage}
          pageSize={paginationWithDataFetching.pagination.rowsPerPage}
          onChangeRowsPerPage={changeRowsPerPageHandler}
          onChangePage={changePageHandler}
          prefix={tooltip}
        />
      )}
    </>
  );
}

export const Table = styled(Box)({});
