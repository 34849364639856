import React from "react";
import { Stack, Typography } from "@mui/material";
import { Skeleton } from "@mui/lab";
import BigNumber from "bignumber.js";
import { GasPriceOptionType } from "@src/pages/defi/types";
import { GAS_PRICE_OPTION_GAS_ORACLE_PROP, TRANSACTION_SPEED_OPTION_NAME } from "@src/pages/defi/constants";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { formatTokenAmount } from "@src/utils/format";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";

export type GasPriceOptionProps = {
  gasPriceOption: GasPriceOptionType;
};

export const getGasPriceOptionInUsd = (gwei: string, ethUsdPrice: string) =>
  new BigNumber(ethUsdPrice)
    .dividedBy(10 ** 9)
    .multipliedBy(gwei)
    .toNumber();

export const GasPriceOption = ({ gasPriceOption }: GasPriceOptionProps) => {
  const orderForm = useDefiOrderFormContext();

  const chainId = orderForm.watch("chainId");
  const formGasPriceOption = orderForm.watch("gasPriceOption");

  const name = TRANSACTION_SPEED_OPTION_NAME[gasPriceOption];
  const gasOracleProp = GAS_PRICE_OPTION_GAS_ORACLE_PROP[gasPriceOption];

  const { data: gasOracleData } = anbotoApi.useGetGasOracleQuery({ chainId });

  const isActive = formGasPriceOption === gasPriceOption;
  const gasPriceOptionGwei = gasOracleData?.data?.[gasOracleProp] || 0;

  const onClick = () => orderForm.setValue("gasPriceOption", gasPriceOption);

  return (
    <Stack
      p={1}
      borderRadius={1}
      onClick={onClick}
      sx={{
        backgroundColor: (theme) => (isActive ? theme.palette.background.paper : theme.custom.colors.gray),
        border: (theme) =>
          isActive ? `1px solid ${theme.palette.primary.light}` : `1px solid ${theme.custom.colors.gray}`,
        cursor: "pointer",
      }}
    >
      <Typography fontSize={14} color={isActive ? "primary" : "text.primary"}>
        {name}
      </Typography>
      <Typography variant="caption">
        {gasOracleData?.data ? <>{formatTokenAmount(gasPriceOptionGwei)} Gwei</> : <Skeleton />}
      </Typography>
    </Stack>
  );
};
