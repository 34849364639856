/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  Stack,
  IconButton,
  Typography,
  styled,
  MenuItem,
  Menu,
  useTheme,
  Box,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import MenuIcon from "@mui/icons-material/Menu";
import { MoreVert } from "@mui/icons-material";
import { countdownToMidnight } from "../withdraw-flow-dialog/withdraw-inputs";
import { Item } from "./draggable-list";
import { UseDepositType, UseWithdrawType } from ".";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { useMenu } from "@src/hooks/useMenu";
import { CHAIN_LOGO } from "@src/pages/defi/constants";
import { formatUsd } from "@src/utils/format";
import { FeeBalance } from "@src/pages/settings/fee-manager/hooks/use-fee-manager-balances";
import {
  QuantityStatus,
  UnlockingLabelButton,
} from "@src/pages/settings/fee-manager/components/unlocking-label-button";
import { getBalanceAndStatus } from "@src/pages/settings/fee-manager/utils";

export type DraggableListItemProps = {
  item: Item;
  index: number;
  useDeposit: UseDepositType;
  useWithdraw: UseWithdrawType;
  isDraggable: boolean;
  balancesInfo: FeeBalance | undefined;
  disabled?: boolean;
};

export const DraggableListItem = ({
  item,
  index,
  useDeposit,
  useWithdraw,
  isDraggable,
  balancesInfo,
  disabled,
}: DraggableListItemProps) => {
  const theme = useTheme();
  const menu = useMenu();

  const onDepositClick = (item: Item) => {
    useDeposit.setChainFee(item.network_id!);
    useDeposit.setTokenFee(item.token_address!);
    useDeposit.show();
  };

  const onWithdrawClick = (item: Item) => {
    useWithdraw.setChainFee(item.network_id!);
    useWithdraw.setTokenFee(item.token_address!);
    useWithdraw.show();
    menu.hide();
  };

  const { balance, status } = getBalanceAndStatus(balancesInfo);

  return (
    <Draggable isDragDisabled={!isDraggable} draggableId={item.id} index={index}>
      {(provided) => (
        <Stack position="relative" alignItems="center">
          <StyledListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            index={index}
          >
            {isDraggable ? <MenuIcon sx={{ color: theme.custom.colors.dim, mr: 2 }} /> : <Box width={40} />}
            <Stack position="relative">
              <ListItemAvatar>
                <Avatar>
                  <img
                    alt={item.token}
                    src={item.icon}
                    height={40}
                    style={{ backgroundColor: theme.custom.background.secondary }}
                  />
                </Avatar>
              </ListItemAvatar>
              {item?.network_id && CHAIN_LOGO[item.network_id] && (
                <img
                  style={{ position: "absolute", top: -5, right: 10 }}
                  alt={item.token}
                  src={CHAIN_LOGO[item.network_id]}
                  height={20}
                />
              )}
            </Stack>
            <ListItemText primary={item.token} />

            <Stack direction="row" gap={2} mr={4}>
              <Typography>{item.quantity}</Typography>
              <Typography color="text.secondary">≈{formatUsd(item.value)}</Typography>
            </Stack>
            {!disabled && (
              <AnbotoButton variant="outlined" size="small" onClick={() => onDepositClick(item)}>
                Deposit
              </AnbotoButton>
            )}
            {!disabled && (
              <>
                <IconButton sx={{ ml: 2 }} onClick={(e) => menu.show(e.currentTarget)}>
                  <MoreVert />
                </IconButton>
                <StyledMenu
                  anchorEl={menu.anchor}
                  anchorOrigin={{
                    horizontal: -53,
                    vertical: 30,
                  }}
                  open={menu.isOpen}
                  onClose={menu.hide}
                >
                  <StyledMenuItem onClick={() => onWithdrawClick(item)}>Withdrawal</StyledMenuItem>
                </StyledMenu>
              </>
            )}
          </StyledListItem>
          <Stack position="absolute" top={57} width="fit-content">
            {parseFloat(balance || "0") > 0 && (
              <UnlockingLabelButton disabled quantityStatus={status}>
                {balance} {item.token}{" "}
                {status === QuantityStatus.LOCKED
                  ? `will be unlocked for withdraw in ${countdownToMidnight()}`
                  : "are available for withdraw to your admin wallet"}
              </UnlockingLabelButton>
            )}
          </Stack>
          {index === 0 && (
            <>
              <Divider sx={{ mt: 2.5, width: "100%" }} />
              <Typography width="70%" maxWidth="720px" textAlign="left" color="text.disabled" fontSize={12}>
                Additional tokens will be used to pay your fees if the default token balance reach 0
              </Typography>
            </>
          )}
        </Stack>
      )}
    </Draggable>
  );
};

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiMenu-paper": {
    elevation: 0,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    minWidth: "110px",
    borderRadius: "4px",
    mt: 1.5,
    padding: 0,
    "& .MuiMenu-list": {
      padding: 0,
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: theme.spacing(0.5),
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  justifyContent: "center",
}));

const StyledListItem = styled(ListItem)<{ index: number }>(({ theme, index }) => ({
  width: "70%",
  maxWidth: "720px",
  height: "84px",
  margin: theme.spacing(0.5),
  borderRadius: "5px",
  backgroundColor: index === 0 ? theme.custom.colors.darkBlue : theme.custom.background.secondary,
}));
