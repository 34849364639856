import React from "react";
import { Avatar, AvatarProps, Tooltip } from "@mui/material";
import { stringToColor, stringAvatar } from "./helpers";

export type AvatarUser = {
  firstName: string;
  lastName: string;
  email: string;
};

type ColorizedAvatarProps = {
  dashed?: boolean;
  user: AvatarUser;
} & AvatarProps;

export const ColorizedAvatar = ({ user, dashed, ...props }: ColorizedAvatarProps) => {
  const color = React.useMemo(() => stringToColor(user.email.replace(/[^a-zA-Z0-9]/g, "")), [user.email]);

  let name = `${user.firstName || ""} ${user.lastName || ""}`.trim();
  name = name ? `(${name})` : "";

  return (
    <Tooltip title={`${user.email} ${name}`}>
      <Avatar {...stringAvatar(user.email, user.firstName, color, dashed)} {...props} />
    </Tooltip>
  );
};
