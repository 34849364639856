import { Amplify } from "aws-amplify";
// import { CookieStorage } from "aws-amplify/utils";
// import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

if (!process.env.REACT_APP_AWS_USER_POOL_ID) throw new Error("REACT_APP_AWS_USER_POOL_ID is not specified");
if (!process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID)
  throw new Error("REACT_APP_AWS_USER_POOL_CLIENT_ID is not specified");

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID!,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID!,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: "XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab",
      // // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      // signUpVerificationMethod: "code", // 'code' | 'link'
      // loginWith: {
      //   // OPTIONAL - Hosted UI configuration
      //   oauth: {
      //     domain: "your_cognito_domain",
      //     scopes: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      //     redirectSignIn: ["http://localhost:3000/"],
      //     redirectSignOut: ["http://localhost:3000/"],
      //     responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      //   },
      // },
    },
  },
});

// cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());
