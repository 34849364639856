import React, { forwardRef, useEffect, useState } from "react";
import { MenuItem } from "@mui/material";
import SVG from "react-inlinesvg";
import { ControllerRenderProps } from "react-hook-form";
import { feeManagerApi } from "./api";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { CHAIN_LOGO, CHAIN_NAME } from "@src/pages/defi/constants";
import { useRecentChainIdState } from "@src/pages/defi/hooks";
import { ChainId } from "@src/pages/defi/types";

const CHAIN_IDS: ChainId[] = [
  ChainId.ETHEREUM,
  ChainId.BSC,
  ChainId.POLYGON,
  ChainId.AVALANCHE,
  ChainId.ARBITRUM,
  ChainId.OPTIMISM,
  ChainId.BASE,
];

export type ChainFeesFieldProps = ControllerRenderProps;

export const ChainFeesField = forwardRef(({ value, onChange }: ChainFeesFieldProps, ref) => {
  const recentChainIdState = useRecentChainIdState();
  const getFeeManagerContractsQuery = feeManagerApi.useGetFeeManagerContractsQuery();
  const [chainList, setChainList] = useState<number[]>();

  useEffect(() => {
    if (getFeeManagerContractsQuery.data?.length) {
      const newChainList = getFeeManagerContractsQuery.data.map((contract) => contract.network_id);
      setChainList(newChainList);
    }
  }, [getFeeManagerContractsQuery.data]);

  const _onChange = (newChainId: number) => {
    recentChainIdState.setState(newChainId);
    onChange(newChainId);
  };

  const getFilteredChainIds = (chainIds: ChainId[], chainList: number[]) =>
    chainIds.filter((chainId) => chainList.includes(chainId));

  if (chainList) {
    return (
      <>
        <AnbotoTextField
          label="Select chain"
          fullWidth
          size="small"
          onChange={(e) => _onChange(parseInt(e.target.value))}
          value={value}
          inputProps={{ style: { cursor: "pointer" } }}
          select
          inputRef={ref}
          sx={{
            "& .MuiSelect-select": {
              height: "30px",
              width: "100%",
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          {getFilteredChainIds(CHAIN_IDS, chainList).map((x) => (
            <MenuItem key={x} value={x}>
              <SVG src={CHAIN_LOGO[x]} height={20} />
              &nbsp;
              {CHAIN_NAME[x]}
            </MenuItem>
          ))}
        </AnbotoTextField>
      </>
    );
  } else {
    return <></>;
  }
});

ChainFeesField.displayName = "ChainFeesField";
