export const PORTFOLIO_PATH = "/portfolio";
export const MAX_NUMBER_TOKEN_ALLOCATION_CHARTS = 5;
export const MAX_NUMBER_TOKEN_PROTOCOL_CHARTS = 5;
export const MAX_NUMBER_TOKEN_EXCHANGE_CHARTS = 5;
export const MAX_NUMBER_WALLETS_CHARTS = 5;
export const ICONS_BASE_URL = "https://cryptocompare.com";
export const COLORS: string[] = ["#2C72E3", "#179B83", "#C3462B", "#ECAC27", "#56C3F2", "#11998E"];
export const PORTFOLIO_CURRENCIES = ["USD", "EUR", "GBP", "JPY"];

export const DEFAULT_COIN = "BTC";
export const DEFAULT_STABLE_COIN = "USDT";
export const PAIR_SYMBOL_DELIMITER = "/";
export const MAIN_ACCOUNT_VALUE = "MAIN_ACCOUNT_VALUE";

export enum ChartNames {
  TokenAllocation = "Token allocation",
  ExchangesAllocation = "Exchanges allocation",
  ProtocolsAllocation = "Protocols allocation",
  WalletAllocation = "Wallet allocation",
}
