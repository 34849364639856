import React, { useState, useRef, useEffect } from "react";
import { TabContext } from "@mui/lab";
import { Box, ClickAwayListener, Paper, Popper, Stack, styled, PopperProps } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { Star } from "@mui/icons-material";
import { SymbolSearchInput } from "./symbol-search-input";
import { AlgoliaResults } from "./algolia-results";
import { SymbolSelectTab } from "./types";
import { removeRecentSymbol, setTab } from "./symbol-select.slice";
import { SymbolSelectExchanges } from "./symbol-select-exchanges";
import { RecentSymbolsList } from "./recent-symbols-list";
import { FavoriteSymbolsList } from "./favorite-symbols-list";
import { CefiExchangeId, OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { StyledTabList, StyledTabListTab } from "@src/components";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

export const SymbolSelectModal = ({
  open,
  onClickAway,
  onSymbolSelect,
  exchanges,
  otc,
  ...props
}: {
  open?: boolean;
  onClickAway: () => void;
  onSymbolSelect: (symbol: OrderSymbol) => void;
  exchanges: Record<CefiExchangeId, boolean>;
  exchangeFilter?: boolean;
  tabs?: SymbolSelectTab[];
  otc?: boolean;
} & PopperProps) => {
  const type = otc ? "otc" : "cefi";
  const currentTab = useAppSelector((state) => state.symbolSelect.tab[type]);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const customScrollBarRef = useRef<HTMLInputElement>(null);
  const recentSymbols = useAppSelector((state) => state.symbolSelect.recent[type]);

  const handleClickAway = () => {
    onClickAway();
    setSearchText("");
  };

  const handleSymbolSelect = (symbol: OrderSymbol) => {
    setSearchText("");
    onSymbolSelect(symbol);
  };

  const focusInput = () => {
    inputRef.current && inputRef.current.focus();
  };

  const onRecentItemRemoveClick = (orderSymbol: OrderSymbol) => {
    dispatch(removeRecentSymbol({ symbol: orderSymbol, type }));
  };

  useEffect(() => {
    customScrollBarRef.current?.scrollTo({ top: 0 });
  }, [searchText]);

  useEffect(() => {
    focusInput();
  }, [currentTab, open]);

  return (
    <Popper
      open={open}
      placement="bottom-start"
      sx={{ width: 450, zIndex: 99, minHeight: 400, userSelect: "none" }}
      disablePortal
      modifiers={[
        {
          name: "preventOverflow",
          enabled: true,
        },
      ]}
      {...props}
    >
      <ClickAwayListener onClickAway={handleClickAway} touchEvent="onTouchStart" mouseEvent="onMouseDown">
        <Paper sx={{ backgroundColor: "#192022" }} elevation={4}>
          <TabContext value={currentTab}>
            <StyledTabList
              onChange={(e, x: SymbolSelectTab) => dispatch(setTab({ tab: x, type }))}
              sx={{ background: "transparent", minHeight: 32, pl: 1 }}
              TabIndicatorProps={{
                style: { transition: "none" },
              }}
            >
              <Tab
                label={
                  <Stack direction="row" alignItems="center">
                    Favorites
                    <Star
                      sx={{
                        marginLeft: (theme) => theme.spacing(0.5),
                        fontSize: 14,
                        color: currentTab === SymbolSelectTab.FAVORITES ? "#ECAC27" : "",
                      }}
                    />
                  </Stack>
                }
                value={SymbolSelectTab.FAVORITES}
                disableRipple
              />
              <Tab
                label={
                  <Stack direction="row" alignItems="center">
                    Recent
                    <HistoryIcon
                      sx={{
                        marginLeft: (theme) => theme.spacing(0.5),
                        fontSize: 14,
                      }}
                    />
                  </Stack>
                }
                value={SymbolSelectTab.RECENT}
                disableRipple
              />
              <Tab label="All" value={SymbolSelectTab.ALL} sx={{ minWidth: 50 }} disableRipple />
              <Tab label="Spot" value={SymbolSelectTab.SPOT} disableRipple />
              {!otc && <Tab label="Future" value={SymbolSelectTab.FUTURE} disableRipple />}
              {otc && <Tab label="CFD" value={SymbolSelectTab.CFD} disableRipple />}
            </StyledTabList>
          </TabContext>
          {!otc && <SymbolSelectExchanges mt={1.5} onChange={() => focusInput()} exchanges={exchanges} />}
          <Box mx={1.5} mt={1.5}>
            <SymbolSearchInput
              ref={inputRef}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              autoFocus
            />
          </Box>
          <Box overflow="auto" maxHeight={400} minHeight={400} ref={customScrollBarRef} mt={1}>
            {currentTab === SymbolSelectTab.RECENT && (
              <RecentSymbolsList
                items={recentSymbols}
                exchanges={exchanges}
                onRemoveClick={onRecentItemRemoveClick}
                onSelect={handleSymbolSelect}
                search={searchText}
              />
            )}
            {currentTab === SymbolSelectTab.FAVORITES && (
              <FavoriteSymbolsList otc={otc} onSelect={handleSymbolSelect} search={searchText} />
            )}
            <AlgoliaResults
              exchanges={exchanges}
              search={searchText}
              onSelect={handleSymbolSelect}
              currentTab={currentTab}
              otc={otc}
            />
          </Box>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

const Tab = styled(StyledTabListTab)({
  minHeight: 35,
  minWidth: 80,
});
