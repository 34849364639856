import React from "react";
import { Typography, styled, Stack, StackProps } from "@mui/material";
import { GROUPS } from "../constants";
import { styledOptions } from "@src/utils/styled-options";

export const Group = ({
  label,
  groupId,
  active,
  ...props
}: { label?: string; groupId: string; active?: boolean } & StackProps) => {
  const color = GROUPS.find((group) => group.id === groupId)?.color || "";

  return (
    <StyledGroup groupColor={color} active={active} {...props}>
      <Typography fontSize={11} fontWeight="bold" color={color} lineHeight={2}>
        {label || groupId || "-"}
      </Typography>
    </StyledGroup>
  );
};

const StyledGroup = styled(
  Stack,
  styledOptions
)<{ groupColor?: string; active?: boolean }>(({ groupColor, active, theme }) => ({
  border: `1px solid ${groupColor}`,
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  alignItems: "center",
  justifyContent: "center",
  userSelect: "none",
  cursor: "pointer",
  opacity: active ? 1 : 0.4,
  "&: hover": {
    opacity: 1,
  },
  minWidth: 64,
}));
