import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { useAppSelector } from "@src/store/hooks";
import { CELL_FONT_SIZE } from "@src/components/anboto-table";

export const AccountName = ({ id }: { id: string }) => {
  const account = useAppSelector((state) => state.support.accounts[id]);
  return (
    <Tooltip title={account?.name}>
      <Typography noWrap variant="body2" fontSize={CELL_FONT_SIZE}>
        {account?.name}
      </Typography>
    </Tooltip>
  );
};
