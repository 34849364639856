import React from "react";
import { Typography, styled } from "@mui/material";

export const OrdersTableAccount = ({ label, fontSize }: { label: string; fontSize?: number }) => {
  return (
    <Root variant="body2" sx={{ fontSize }}>
      {label}
    </Root>
  );
};
const Root = styled(Typography)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.custom.border.default,
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  textAlign: "center",
  display: "inline-block",
  verticalAlign: "middle",
  overflow: "hidden",
  minWidth: 50,
  maxWidth: 80,
  textTransform: "uppercase",
  whiteSpace: "nowrap",
  fontWeight: "bold",
  textOverflow: "ellipsis",
}));
