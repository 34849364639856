import React from "react";
import { CardDialog } from "@src/components/CardDialog";
import { SelectView } from "@src/components/select-view";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";

export type SelectTokenDialogProps = {
  isOpen: boolean;
  onClose(): void;
  onTokenSelect(tokenAddress: string): void;
};

export const SelectTokenDialog = ({ isOpen, onClose, onTokenSelect }: SelectTokenDialogProps) => {
  const defiOrderForm = useDefiOrderFormContext();
  const account = defiOrderForm.watch("account");
  const chainId = defiOrderForm.watch("chainId");

  return (
    <CardDialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth sx={{ minHeight: 500, zIndex: 20 }}>
      <SelectView account={account} chainId={chainId} onTokenSelect={onTokenSelect} onClose={onClose} />
    </CardDialog>
  );
};
