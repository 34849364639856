import React, { useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSearchParams } from "react-router-dom";
import { InviteSource } from "../index";
import { AuthCard } from "../../layout/auth-card";
import { FormError } from "../../layout/form-error";
import { TraderTypeSelect } from "./trader-type-select";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { TraderType } from "@src/store/apis/anbotoApi/types";
import { useAppSelector } from "@src/store/hooks";
import { AccountChoiceTypes } from "@src/pages/auth/onboarding.slice";

export type VerificationTraderTypeProps = {
  traderType?: TraderType;
  onNext: (traderType: TraderType) => void;
  onBack: () => void;
  onChange: (traderType: TraderType) => void;
};

export const VerificationTraderType = ({ traderType, onNext, onBack, onChange }: VerificationTraderTypeProps) => {
  const [searchParams] = useSearchParams();
  const isInviteSource = searchParams.get("is_account") === InviteSource.TEAM;
  const isRegistrationWithUniqueCode = !!searchParams.get("code");
  const accountTypeChoice = useAppSelector((state) => state.onboarding.accountTypeChoice);
  const isChoiceEnableForReferee = accountTypeChoice === AccountChoiceTypes.ENABLED;
  const isAccountChoiceEnabled = (!isInviteSource && isRegistrationWithUniqueCode) || isChoiceEnableForReferee;
  const [error, setError] = useState("");

  const onNextClick = () => {
    if (!traderType) return setError("Please refer your trading experience");

    onNext(traderType);
  };

  return (
    <AuthCard sx={{ minHeight: 0 }}>
      <Grid container direction="column" alignItems="center" spacing={2.5} paddingTop={5}>
        <Typography>What type of trader are you?</Typography>
        <Grid item width="100%" display="flex" justifyContent="space-between">
          <TraderTypeSelect
            value={traderType}
            onChange={(event) => onChange(event.target.value as TraderType)}
            helperText="Please select between starter, experienced individual or institutional"
            labelStyle="dynamic"
            InputLabelProps={{ shrink: true }}
          />
          {error && <FormError>{error}</FormError>}
        </Grid>

        <Grid item mt={5} width="100%" display="flex" flexDirection="row">
          {isAccountChoiceEnabled && (
            <AnbotoButton
              tabIndex={1}
              sx={{ marginRight: "5px", width: "15%", "&:focus": { outline: 1 } }}
              size="large"
              variant="outlined"
              loadingIndicator={<CircularProgress color="inherit" size={16} />}
              type="submit"
              onClick={onBack}
            >
              <ArrowBackIcon />
            </AnbotoButton>
          )}
          <AnbotoButton
            tabIndex={1}
            sx={{ marginLeft: "5px", width: "100%", "&:focus": { outline: 1 } }}
            size="large"
            variant="contained"
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
            type="submit"
            disabled={!traderType}
            onClick={onNextClick}
          >
            Next
          </AnbotoButton>
        </Grid>
      </Grid>
    </AuthCard>
  );
};
