import React from "react";
import { styled, Stack, Typography, Box, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ExchangeAvatar } from "../exchange-avatar/exchange-avatar";
import { SymbolSelectVariant } from "./types";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";
import { getExchangeIdByExchangeName } from "@src/store/apis/anbotoApi/utils";

const isDark = (variant?: SymbolSelectVariant) => variant === "input-dark";

interface SymbolSelectorProps {
  variant?: SymbolSelectVariant;
  value?: OrderSymbol | null;
  disabled?: boolean;
}

export const SymbolSelector = ({ variant, value, disabled }: React.PropsWithChildren<SymbolSelectorProps>) => {
  if (variant === "inline")
    return value ? (
      <Stack direction="row" sx={{ cursor: disabled ? "default" : "pointer" }} gap={0.75} alignItems="center">
        <Tooltip title={value.exchange}>
          <div>
            <ExchangeAvatar size={16} exchange={getExchangeIdByExchangeName(value.exchange)} />
          </div>
        </Tooltip>
        <Typography fontSize={13} fontWeight={500} lineHeight={1.5} noWrap textTransform="capitalize">
          {getOrderSymbolLabel(value, ["symbol", "market_type"])}
        </Typography>
        {!disabled && <ArrowDropDownIcon sx={{ fontSize: 14, color: (theme) => theme.palette.text.secondary }} />}
      </Stack>
    ) : null;

  return (
    <SelectedSymbolContainerStack disabled={disabled} direction="row">
      <SelectedSymbolPrefixTypography disabled={disabled} selectVariant={variant}>
        Symbol
      </SelectedSymbolPrefixTypography>
      <Stack
        flexDirection="row"
        flexGrow={1}
        flex={1}
        pl={1}
        alignItems="center"
        sx={{ color: value ? "text.primary" : "text.secondary" }}
      >
        <Typography fontWeight="bold" fontSize={14}>
          {value ? getOrderSymbolLabel(value) : "Symbol - Spot / Future - Exchange"}
        </Typography>
      </Stack>
      <Box p={0.5}>
        <ExpandMoreIcon sx={{ color: (theme) => theme.custom.text.dim }} />
      </Box>
    </SelectedSymbolContainerStack>
  );
};

const SelectedSymbolContainerStack = styled(Stack)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  height: 32,
  fontSize: 14,
  lineHeight: "16px",
  overflow: "hidden",
  cursor: "pointer",
  borderWidth: 1,
  borderRadius: 4,
  borderStyle: "solid",
  borderColor: theme.custom.border.default,
  backgroundColor: disabled ? "#4a5053" : "#031116",
  ...(disabled ? { color: "#90999d" } : {}),
}));

const SelectedSymbolPrefixTypography = styled(Typography, {
  shouldForwardProp: (prop: string) => !["selectVariant"].includes(prop),
})<{ selectVariant?: SymbolSelectVariant; disabled?: boolean }>(({ selectVariant, disabled }) => ({
  backgroundColor: disabled ? "#4a5053" : isDark(selectVariant) ? "#192022" : "#4A5053",
  fontSize: 14,
  width: 66,
  display: "flex",
  lineHeight: "16px",
  color: disabled ? "#90999d" : isDark(selectVariant) ? "#B0BCC2" : undefined,
  fontWeight: 400,
  alignItems: "center",
  justifyContent: "center",
}));
