import React from "react";
import { MenuItem, Paper, Stack, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AnbotoButtonGroup, AnbotoButtonGroupOption } from "@src/components/ui/anboto-button-group";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { PortfolioType, ViewType } from "@src/store/slices/portfolioSlice";
import { PORTFOLIO_CURRENCIES, PORTFOLIO_PATH } from "@src/pages/portfolio/constant";
import { PortfolioDataUpdate } from "@src/pages/portfolio/components/portfolio-data-update";
import { usePortfolioType } from "@src/pages/portfolio";
import { RootState } from "@src/store/types";
import { useAppDispatch } from "@src/store/hooks";
import { setCurrency } from "@src/store/slices/uiSettingsSlice";

const periodFilterOptions: AnbotoButtonGroupOption<PortfolioType>[] = [
  { label: "Exchange (CEFI)", value: PortfolioType.CEFI },
  { label: "Decentralized (DEFI)", value: PortfolioType.DEFI },
];

export const PortfolioHeader = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const portfolioType = usePortfolioType();
  const currency =
    useSelector((state: RootState) => state.uiSettings.currency)?.toLowerCase() ||
    PORTFOLIO_CURRENCIES[0].toLowerCase();

  const changePortfolioType = () => {
    if (portfolioType === PortfolioType.CEFI) {
      navigate(`${PORTFOLIO_PATH}/${PortfolioType.DEFI}/${ViewType.FUNDS}`);
    } else {
      navigate(`${PORTFOLIO_PATH}/${PortfolioType.CEFI}/${ViewType.FUNDS}`);
    }
  };

  const changePortfolioCurrency = (currency: string) => {
    dispatch(setCurrency(currency));
  };

  return (
    <Root>
      <Stack px={2} direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap={1}>
          <PortfolioTypeButtonGroup
            size="small"
            options={periodFilterOptions}
            onChange={changePortfolioType}
            value={portfolioType}
          />
          <AnbotoTextField
            size="small"
            labelStyle="dynamic"
            select
            value={currency}
            onChange={(e) => changePortfolioCurrency(e.target.value)}
            label="Currency"
            sx={{ width: 80 }}
          >
            {PORTFOLIO_CURRENCIES?.map((currency) => (
              <MenuItem key={currency} value={currency.toLowerCase()}>
                {currency}
              </MenuItem>
            ))}
          </AnbotoTextField>
        </Stack>

        <PortfolioDataUpdate />
      </Stack>
    </Root>
  );
};

const Root = styled(Paper)({
  position: "relative",
  zIndex: 1,
  padding: "10px 0",
});

const PortfolioTypeButtonGroup = styled(AnbotoButtonGroup)({
  [".MuiButton-text"]: {
    width: 198,
  },
});
