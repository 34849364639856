import { Box, styled } from "@mui/material";
import { CELL_FONT_SIZE } from "./constants";
import { styledOptions } from "@src/utils/styled-options";

export const TableHeaderCell = styled(
  Box,
  styledOptions
)<{ expandable?: boolean; shouldUpdate?: any }>(({ theme, expandable }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400,
  borderBottom: `1px solid ${theme.custom.background.default}`,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(0.25),
  paddingRight: theme.spacing(0.25),
  minHeight: 32,
  fontFamily: theme.typography.fontFamily,
  fontSize: CELL_FONT_SIZE - 1,
  background: theme.palette.background.paper,
  flexShrink: 1,
  flexGrow: 1,
  flexWrap: "nowrap",
  display: "flex",
  alignItems: "center",
  "&:first-of-type": {
    paddingLeft: expandable ? undefined : theme.spacing(1),
  },
}));
