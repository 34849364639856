import { Stack, styled } from "@mui/material";

export const FormRow = styled(Stack)(({ theme }) => ({
  "&:not(:first-child)": {
    marginTop: theme.spacing(1.25),
  },
  "& .MuiTextField-root": {
    marginTop: 10,
  },
}));
