import React from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { FeesPostTradeAnalysis } from "@src/store/apis/anbotoApi/types";
import { formatUsd } from "@src/utils/format";
import {
  DashboardDataCard,
  DashBoardTableCell,
  DashBoardTableHeaderCell,
} from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/styled-components";
import DashboardDataCardHeader from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/dashboard-data-card-header";
import { formatBps } from "@src/components/post-trade-analysis/helpers";

type TakerMakerFeesProps = {
  takerMakerData: FeesPostTradeAnalysis;
};

const TakerMakerFees = ({ takerMakerData }: TakerMakerFeesProps) => {
  const rows = [
    {
      description: "Your exchange Fee Tier",
      taker: formatBps(takerMakerData?.taker_fees_infos?.fees_in_bps_notional || 0),
      maker: formatBps(takerMakerData?.maker_fees_infos?.fees_in_bps_notional || 0),
      total: formatBps(takerMakerData?.fees_in_bps_notional || 0),
    },
    {
      description: "% of taker/maker trades",
      taker:
        (
          ((takerMakerData?.taker_fees_infos?.notional_executed || 0) / (takerMakerData?.notional_executed || 1)) *
          100
        ).toFixed(2) + "%",
      maker:
        (
          ((takerMakerData?.maker_fees_infos?.notional_executed || 0) / (takerMakerData?.notional_executed || 1)) *
          100
        ).toFixed(2) + "%",
      total: "100%",
    },
    {
      description: "Exchange Fee Cost",
      taker: formatUsd(takerMakerData?.taker_fees_infos?.fees_paid_usd || 0),
      maker: formatUsd(takerMakerData?.maker_fees_infos?.fees_paid_usd || 0),
      total: formatUsd(takerMakerData?.fees_paid_usd || 0),
    },
  ];

  return (
    <DashboardDataCard flex={1}>
      <DashboardDataCardHeader
        header="Fees"
        title="Trading fees paid the the exchange, Total + broken down into liquidity maker and liquidity taker"
      />
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <DashBoardTableHeaderCell align="left"></DashBoardTableHeaderCell>
              <DashBoardTableHeaderCell align="left">Taker</DashBoardTableHeaderCell>
              <DashBoardTableHeaderCell align="center">Maker</DashBoardTableHeaderCell>
              <DashBoardTableHeaderCell align="right">Total</DashBoardTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.total} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <DashBoardTableCell align="left">{row.description}</DashBoardTableCell>
                <DashBoardTableCell align="left">{row.taker}</DashBoardTableCell>
                <DashBoardTableCell align="center">{row.maker}</DashBoardTableCell>
                <DashBoardTableCell align="right">{row.total}</DashBoardTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardDataCard>
  );
};

export default TakerMakerFees;
