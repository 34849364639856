import React, { ChangeEvent, forwardRef } from "react";
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import { createPortal } from "react-dom";
import { ORDER_FORM_PORTAL_ID } from "../constants";
import { TradingStyle } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { useDialog } from "@src/hooks/useDialog";
import { CardDialog } from "@src/components/CardDialog";

export const TRADING_STYLE: TradingStyle[] = [TradingStyle.AGGRESSIVE, TradingStyle.HYBRID, TradingStyle.PASSIVE];

export const TRADING_STYLE_LABEL = {
  [TradingStyle.AGGRESSIVE]: "Aggressive",
  [TradingStyle.HYBRID]: "Hybrid",
  [TradingStyle.PASSIVE]: "Passive",
};

export const TRADING_STYLE_DESCRIPTION = {
  [TradingStyle.AGGRESSIVE]:
    "“Aggressive” means taking or removing liquidity out of the market. Put it simply, it is a taker order. In our case our algo crosses the spread in a low touch. A market taker places orders that remove liquidity from the market, regardless if you buy or sell. An aggressive trading style is equivalent to executing market orders which performs the transaction at the current market price.",
  [TradingStyle.HYBRID]:
    "A hybrid trading style allows the user to act both as a market taker and market marker. If there are short-term asymmetries in the market, a hybrid trading style will shift from a passive trading style to an aggressive trading style (or vice versa) in order to minimize slippage. ",
  [TradingStyle.PASSIVE]:
    "“Passive” means adding liquidity to the market. Put simply, it is a maker order. A market maker places orders that provide liquidity to the market, regardless if you buy or sell. A passive trading style is equivalent to executing limit orders which performs the transaction at a specific price, which is determined by our platform. ",
};

export const TradingStyleSelect = forwardRef(
  ({ excludeTradingStyles, ...props }: AnbotoTextFieldProps & { excludeTradingStyles?: TradingStyle[] }, ref) => {
    const dialog = useDialog();

    const handleIconClick = (e) => {
      e.stopPropagation();
      dialog.show();
    };

    const onDialogListItemClick = (x: TradingStyle) => {
      const changeEvent = { target: { value: x } } as ChangeEvent<HTMLInputElement>;
      if (props.onChange) props.onChange(changeEvent);
      dialog.hide();
    };

    const currentTradingStyleList = TRADING_STYLE.filter((style) => !excludeTradingStyles?.includes(style));

    return (
      <>
        <Box sx={{ position: "relative" }}>
          {props.labelStyle === "dynamic" && <HelpIcon onClick={handleIconClick} />}
          <AnbotoTextField {...props} inputRef={ref} select size="small" fullWidth label="Trading style">
            {currentTradingStyleList.map((x) => (
              <MenuItem key={x} value={x}>
                {TRADING_STYLE_LABEL[x]}
              </MenuItem>
            ))}
          </AnbotoTextField>
        </Box>
        {!props.disabled &&
          dialog.isOpen &&
          createPortal(
            <CardDialog open={dialog.isOpen} sx={{ zIndex: 3 }}>
              <StyledDialogTitle>
                <Stack justifyContent="space-between" flexDirection="row" alignItems="center">
                  <Typography variant="h6">Select strategy</Typography>
                  <IconButton size="small" onClick={dialog.hide}>
                    <Close />
                  </IconButton>
                </Stack>
              </StyledDialogTitle>
              <StyledDialogContent>
                <List disablePadding>
                  {currentTradingStyleList.map((x) => (
                    <StyledListItem key={x} isSelected={x === props.value} onClick={() => onDialogListItemClick(x)}>
                      <ListItemText primary={TRADING_STYLE_LABEL[x]} secondary={TRADING_STYLE_DESCRIPTION[x]} />
                    </StyledListItem>
                  ))}
                </List>
              </StyledDialogContent>
            </CardDialog>,
            document.getElementById(ORDER_FORM_PORTAL_ID) as HTMLElement
          )}
      </>
    );
  }
);

TradingStyleSelect.displayName = "TradingStyleSelect";

type StyledListItemProps = ListItemProps & { isSelected: boolean };
const StyledListItem = styled(ListItem, { shouldForwardProp: (x) => x !== "isSelected" })<StyledListItemProps>(
  ({ theme, isSelected }) => ({
    backgroundColor: "#192022",
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    border: isSelected ? `1px solid #2C72E3` : "initial",
    borderRadius: 4,
  })
);

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: "#232C2F",
});

const StyledDialogContent = styled(DialogContent)({
  padding: 16,
  paddingTop: "16px !important",
});

const HelpIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: "pointer",
  fontSize: 14,
  position: "absolute",
  right: 34,
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 2,
}));
