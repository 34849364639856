import BigNumber from "bignumber.js";
import { CEFI_PARENT_ORDERS_CACHE_KEY } from "./constants";
import { OrdersCurrentTab } from "./types";
import { RootState } from "@src/store/types";

export const reloadParentOrders = ({ getState, dispatch }, anbotoApi) => {
  const state = getState();
  const accountId = (state as RootState).user.team_uuid;
  const tableParams = (state as RootState).ordersTable.params;

  if (tableParams.currentTab === OrdersCurrentTab.Positions) return false;

  dispatch(
    anbotoApi.endpoints.getParentCefiOrders.initiate(
      { accountId, ...tableParams },
      { fixedCacheKey: CEFI_PARENT_ORDERS_CACHE_KEY }
    )
  );
};

export const prepareNumericValue = (value: string | number | undefined) => (value ? +value : undefined);

export const prepareNumericId = (value: number | string) => new BigNumber(value).toString();

export const getExchangeNameLabel = (name = "") => {
  if (name.toLowerCase() === "woox") return "WOO X";

  return name;
};
