import { CefiGroup, WidgetsViewState } from "../store/widgets.slice";
import { OTC_EXCHANGES_MAP } from "@src/features/otc/constants";
import { CEFI_ORDER_FORM_DEFAULT_VALUES } from "@src/pages/cefi/order-form/hooks/use-parent-order-form";
import { getExchangeIdByExchangeName } from "@src/store/apis/anbotoApi/utils";

export const checkAndFixCefiGroups = (groups?: WidgetsViewState["groups"]) => {
  if (!groups) return groups;

  Object.keys(groups || {}).forEach((groupId) => {
    const group = groups[groupId] as CefiGroup;
    const { exchange, symbol, market_type } = group.symbol || {};

    if (
      !(exchange && symbol && market_type) ||
      (group.symbol && OTC_EXCHANGES_MAP[getExchangeIdByExchangeName(group?.symbol?.exchange || "")])
    ) {
      if (!groups[groupId]) groups[groupId] = {};

      groups[groupId] = CEFI_ORDER_FORM_DEFAULT_VALUES;
    }
  });

  return groups;
};
