import React from "react";
import { Column, Row } from "react-table";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Link, Tooltip, Typography } from "@mui/material";
import format from "date-fns/format";
import { CancelOrderButtonMemo } from "./cancel-order-button";
import { OrderExecutionStrategy, ParentDefiOrder, ParentOrderStatuses } from "@src/store/apis/anbotoApi/types";
import { CHAIN_NAME } from "@src/pages/defi/constants";
import { QuantityLinearProgressMemo } from "@src/components/quantity-linear-progress";
import { CELL_FONT_SIZE, sort } from "@src/components/orders-table";
import { END_TIME_FORMAT } from "@src/pages/cefi/constants";
import { truncateAccount } from "@src/pages/defi/utils";
import { isCancelable } from "@src/utils/is-cancelable";
import { IntervalDuration } from "@src/components/interval-duration";
import { ParentOrderStatus } from "@src/components";
import { formatAmount, roundAmount } from "@src/pages/portfolio/utils/helpers";

export const getColumns = ({ isHistory, onSymbolClick, renderCell, wallets, readOnly }): Column<ParentDefiOrder>[] => [
  {
    id: "expand",
    width: 30,
    // eslint-disable-next-line react/prop-types
    Cell: renderCell((row) => {
      return (
        // eslint-disable-next-line react/prop-types
        <span {...row.getToggleRowExpandedProps()}>
          {/* eslint-disable-next-line react/prop-types */}
          {row.isExpanded ? (
            <ArrowDropUpIcon sx={{ color: (theme) => theme.palette.text.disabled }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: (theme) => theme.palette.text.disabled }} />
          )}
        </span>
      );
    }),
  },
  {
    Header: "Symbol",
    width: 200,
    accessor: "symbol",
    Cell: renderCell(({ original }: { original: ParentDefiOrder }) => {
      const network = original.network_id ? CHAIN_NAME[original.network_id] : "";
      const label = network ? `${original.symbol} - ${network}` : original.symbol;

      return (
        <Link
          sx={{ cursor: "pointer", color: "white", textDecoration: "underline" }}
          onClick={() => onSymbolClick(original)}
        >
          {label}
        </Link>
      );
    }),
  },
  {
    Header: "Wallet",
    accessor: "params", // ??
    width: 100,
    Cell: renderCell(({ original }: { original: ParentDefiOrder }) =>
      original.defi_data?.maker_address
        ? wallets[original.defi_data.maker_address] || truncateAccount(original.defi_data.maker_address)
        : null
    ),
  },
  {
    width: 70,
    Header: "Strategy",
    accessor: "strategy",
    Cell: renderCell(({ original }: { original: ParentDefiOrder }) =>
      original.limit_price ? OrderExecutionStrategy.LIMIT.toUpperCase() : original.strategy?.toUpperCase()
    ),
  },
  {
    width: 130,
    Header: "Quantity",
    id: "executedQuantity",
    Cell: renderCell(({ original }: { original: ParentDefiOrder }) => {
      const value = `${original.last_status?.filled_quantity || 0} / ${original.quantity || 0}`;

      return (
        <Tooltip title={value}>
          <Typography noWrap sx={{ fontSize: 12 }}>
            {value}
          </Typography>
        </Tooltip>
      );
    }),
    accessor: "quantity",
  },
  {
    width: 150,
    Header: "",
    id: "executedQuantityProgress",
    Cell: renderCell(({ original }: { original: ParentDefiOrder }) => (
      <QuantityLinearProgressMemo
        width={90}
        executedQuantity={original.last_status?.filled_quantity || 0}
        targetQuantity={original.quantity}
        quantity={(original.last_status?.filled_quantity || 0) + (original.last_status?.leaves_quantity || 0)}
      />
    )),
    accessor: "last_status",
    sortType: (rowA: Row<ParentDefiOrder>, rowB: Row<ParentDefiOrder>) => {
      const a = Math.round(((rowA.original?.last_status?.filled_quantity || 0) * 100) / rowA.original?.quantity);
      const b = Math.round(((rowB.original?.last_status?.filled_quantity || 0) * 100) / rowB.original?.quantity);
      return sort(a, b);
    },
    // accessor: "infos" as keyof ParentDefiOrder,
  },
  {
    width: 100,
    Header: "Avg",
    Cell: renderCell(({ original }: { original: ParentDefiOrder }) => (
      <Tooltip
        title={
          original?.last_status?.average_price
            ? formatAmount(roundAmount(String(original?.last_status?.average_price), 3))
            : ""
        }
      >
        <Typography noWrap sx={{ fontSize: 12 }}>
          {original?.last_status?.average_price
            ? formatAmount(roundAmount(String(original?.last_status?.average_price), 3))
            : "-"}
        </Typography>
      </Tooltip>
    )),
  },
  {
    width: 100,
    Header: "Start time",
    // accessor: "start_time" as keyof ParentDefiOrder,
    Cell: renderCell(
      ({ original }: { original: ParentDefiOrder }) =>
        original?.start_time && format(new Date(original?.start_time), END_TIME_FORMAT)
    ),
    sortType: (rowA: Row<ParentDefiOrder>, rowB: Row<ParentDefiOrder>) => {
      const a = new Date(rowA.original?.start_time ?? "").valueOf();
      const b = new Date(rowB.original?.start_time ?? "").valueOf();
      return sort(a, b);
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...(!isHistory
    ? [
        {
          width: 100,
          Header: "Est. end time",
          id: "estendtime",
          accessor: "infos",
          Cell: renderCell(({ original }: { original: ParentDefiOrder }) => {
            const startTime = original?.start_time || 0;
            const endTime = original?.end_time || 0;
            const durationSeconds = original?.duration_seconds || 0;

            if (
              !startTime ||
              !endTime ||
              !durationSeconds ||
              !original?.last_status?.status ||
              ![
                ParentOrderStatuses.PENDING_NEW,
                ParentOrderStatuses.ACCEPTED,
                ParentOrderStatuses.PARTIALLY_FILLED,
              ].includes(original.last_status.status) ||
              original.strategy === OrderExecutionStrategy.ORDER
            )
              return "-";

            const endTs = startTime + durationSeconds * 1000;

            return <IntervalDuration endTs={endTs} />;
          }),
          sortType: (rowA: Row<ParentDefiOrder>, rowB: Row<ParentDefiOrder>) => {
            const a = rowA.original?.expiration_time || 0;
            const b = rowB.original?.expiration_time || 0;
            return sort(a, b);
          },
        },
      ]
    : []),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ...(isHistory
    ? [
        {
          width: 100,
          Header: "End time",
          accessor: "end_time" as keyof ParentDefiOrder,
          Cell: renderCell(
            ({ original }: { original: ParentDefiOrder }) =>
              original?.end_time && format(new Date(original?.end_time), END_TIME_FORMAT)
          ),
          sortType: (rowA: Row<ParentDefiOrder>, rowB: Row<ParentDefiOrder>) => {
            const a = new Date(rowA.original?.end_time ?? "").valueOf();
            const b = new Date(rowB.original?.end_time ?? "").valueOf();

            return sort(a, b);
          },
        },
      ]
    : []),
  {
    width: 100,
    Header: "Status",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    accessor: "status",
    Cell: renderCell(({ original }: { original: ParentDefiOrder }) => (
      <ParentOrderStatus<ParentDefiOrder> order={original} fontSize={CELL_FONT_SIZE} />
    )),
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  {
    Header: () => <Box textAlign="right">Actions</Box>,
    id: "actions",
    width: 60,
    Cell: renderCell(({ original }: { original: ParentDefiOrder }) => {
      if (readOnly) return null;
      /* TODO - uncomment this when defi v3 tested well
      const targetToken = CHAIN_NATIVE_TOKENS?.find(
        (token) => token.chainId === original.network_id && token.wrappedTokenAddress === original?.token2
      );

      const canWithdraw = !!targetToken && original?.last_status?.status === ParentOrderStatuses.FILLED; */

      return (
        <Box display="flex" justifyContent="right">
          {!isHistory && !!original.last_status?.status && isCancelable(original.last_status?.status) && (
            <CancelOrderButtonMemo order={original} />
          )}
          {/* {canWithdraw && <WithdrawButton autoOpen={!isHistory} targetToken={targetToken} order={original} />} */}
        </Box>
      );
    }),
  },
];
