import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useCefiTokenTable } from "@src/pages/portfolio";
import { CefiAllocationWithIcon } from "@src/store/apis/anbotoApi/types";
import { RootState } from "@src/store/types";
import { AllocationCefiFundsDirect, AllocationCefiFundsOverview } from "@src/pages/portfolio/components";
import { SELECT_ALL } from "@src/store/slices/portfolioSlice";
import { PieChartsCEFI } from "@src/pages/portfolio/components/charts/pie-charts-cefi";

const loaderData = Array.from(Array(10)).map(() => ({})) as CefiAllocationWithIcon[];

export const AllocationsCefiFunds = () => {
  const selectedExchange = useSelector((state: RootState) => state.portfolio.selectedExchange);
  const { dataFromCache, isFetching, isError, refetch } = useCefiTokenTable();

  const hideSubAccounts = selectedExchange === SELECT_ALL || isFetching;

  return (
    <Box>
      <PieChartsCEFI isFetching={isFetching} />
      {hideSubAccounts ? (
        <AllocationCefiFundsOverview
          data={isFetching ? loaderData : dataFromCache}
          isError={isError}
          isFetching={isFetching}
          refetch={refetch}
        />
      ) : (
        <AllocationCefiFundsDirect
          data={isFetching ? loaderData : dataFromCache}
          isError={isError}
          isFetching={isFetching}
          refetch={refetch}
        />
      )}
    </Box>
  );
};
