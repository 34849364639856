import { ChainId } from "@src/pages/defi/types";

export const GECKOTERMINAL_CHAIN_IDS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: "eth",
  [ChainId.BSC]: "bsc",
  [ChainId.POLYGON]: "polygon_pos",
  [ChainId.AVALANCHE]: "avax",
  [ChainId.ARBITRUM]: "arbitrum",
  [ChainId.OPTIMISM]: "optimism",
  [ChainId.BASE]: "base",
};
