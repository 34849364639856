import React, { forwardRef } from "react";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { STRATEGY_NAME, DEFI_STRATEGIES, STRATEGY_DESCRIPTION } from "@src/pages/defi/constants";
import { useDialog } from "@src/hooks/useDialog";
import { CardDialog } from "@src/components/CardDialog";
import { DefiOrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";

type StrategyFieldProps = ControllerRenderProps & AnbotoTextFieldProps & { noInfo?: boolean };

export const StrategyField = forwardRef<any, StrategyFieldProps>((props: StrategyFieldProps, ref) => {
  const dialog = useDialog();

  const onDialogListItemClick = (x: DefiOrderExecutionStrategy) => {
    props.onChange(x);
    dialog.hide();
  };

  const handleIconClick = (e) => {
    e.stopPropagation();
    dialog.show();
  };

  return (
    <>
      <AnbotoButtonGroup<string>
        className="strategy-field-button-group"
        value={props.value}
        view="compact"
        buttonStyles={{
          height: 32,
          fontSize: 14,
          minWidth: 0,
          padding: "3px 3px",
          fontWeight: 400,
        }}
        selectedButtonStyles={{ fontWeight: 600, fontSize: 14 }}
        sx={{
          display: "flex",
        }}
        options={DEFI_STRATEGIES.map((value) => ({
          label: STRATEGY_NAME[value].toUpperCase(),
          value,
          endAdornment: props?.noInfo ? undefined : (
            <InfoOutlinedIcon
              onClick={handleIconClick}
              sx={{
                color: "#3c3f42",
                cursor: "pointer",
                width: 12,
                height: 12,
                position: "absolute",
                right: 15,
              }}
            />
          ),
        }))}
        onChange={(value) => props.onChange(value)}
        ref={ref}
      />

      {!props.disabled && (
        <CardDialog open={dialog.isOpen} sx={{ zIndex: 3 }}>
          <StyledDialogTitle>
            <Stack justifyContent="space-between" flexDirection="row" alignItems="center">
              <Typography variant="h6">Select strategy</Typography>
              <IconButton size="small" onClick={dialog.hide}>
                <Close />
              </IconButton>
            </Stack>
          </StyledDialogTitle>
          <StyledDialogContent>
            <List disablePadding>
              {DEFI_STRATEGIES.map((x) => (
                <StyledListItem key={x} isSelected={x === props.value} onClick={() => onDialogListItemClick(x)}>
                  <ListItemText primary={STRATEGY_NAME[x]} secondary={STRATEGY_DESCRIPTION[x]} />
                </StyledListItem>
              ))}
            </List>
          </StyledDialogContent>
        </CardDialog>
      )}
    </>
  );
});
StrategyField.displayName = "StrategyField";

type StyledListItemProps = ListItemProps & { isSelected: boolean };
const StyledListItem = styled(ListItem, { shouldForwardProp: (x) => x !== "isSelected" })<StyledListItemProps>(
  ({ theme, isSelected }) => ({
    backgroundColor: "#192022",
    marginBottom: theme.spacing(1),
    cursor: "pointer",
    border: isSelected ? `1px solid #2C72E3` : "initial",
    borderRadius: 4,
  })
);

const StyledDialogContent = styled(DialogContent)({
  padding: 16,
  paddingTop: "16px !important",
});

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: "#232C2F",
});
