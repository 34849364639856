import React from "react";
import { Stack, Typography, styled } from "@mui/material";

export const ThumbTag = ({ value }: { value: string }) => {
  return (
    <Root>
      <Typography variant="caption">{value}</Typography>
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.08)",
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0, 1),
}));
