import React from "react";
import {
  Typography,
  styled,
  Stack,
  CircularProgress,
  StackProps,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { MoreVert, SvgIconComponent } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ThumbTag } from "./thumb-tag";

export type SettingsThumbItem = {
  label: string;
  value?: number | string;
  component?: React.ReactNode;
  visible: boolean;
};

export type SettingsThumbVariant = "default" | "contained" | "solid";

export type SettingsThumbProps = {
  name: string;
  onEdit?: () => void;
  onRemove?: () => void;
  items: SettingsThumbItem[];
  onRemoveLoading?: boolean;
  postfix?: React.ReactNode;
  showViewButton?: boolean;
  namePreffix?: React.ReactNode;
  preName?: React.ReactNode;
  variant?: SettingsThumbVariant;
  sx?: StackProps["sx"];
  onClick?: () => void;
  tags?: string[];
} & StackProps;

export const getStyledIcon = (icon: SvgIconComponent, onClick?: () => void) => {
  const Icon = styled(icon)(({ theme }) => ({
    width: 18,
    height: 18,
    color: theme.palette.text.secondary,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  }));

  return <Icon onClick={onClick} />;
};

export const SettingsThumb = ({
  name,
  namePreffix,
  preName,
  onEdit,
  items,
  onRemove,
  onRemoveLoading,
  postfix,
  showViewButton,
  tags,
  variant = "default",
  onClick,
  sx,
  ...props
}: SettingsThumbProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const optionsOpen = Boolean(anchorEl);

  const theme = useTheme();

  const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleOptionsClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Root
      {...props}
      justifyContent="space-between"
      sx={{
        ...sx,
        minHeight: 132,
        backgroundColor: variant === "solid" ? theme.palette.background.paper : undefined,
        border: variant === "solid" ? `1px solid ${theme.custom.colors.secondary}` : undefined,
        flexGrow: 1,
      }}
      onClick={() => onClick && onClick()}
    >
      <Stack>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Box>
            {preName}
            <Stack direction="row" alignItems="center" gap={0.5}>
              {namePreffix}
              <Typography variant="h6" fontSize={16}>
                {name}
              </Typography>
            </Stack>
            {tags?.length && (
              <Stack direction="row" gap={0.5} mt={2}>
                {tags.map((tag) => (
                  <ThumbTag key={tag} value={tag} />
                ))}
              </Stack>
            )}
          </Box>

          <Stack direction="row">
            {onRemove !== undefined && (variant === "contained" || variant === "solid") ? (
              <IconButton sx={{ color: "#4A5053" }} onClick={handleOptionsClick}>
                <MoreVert />
              </IconButton>
            ) : (
              <Stack direction="row" gap={1.5}>
                {onEdit && variant === "default" && getStyledIcon(showViewButton ? ArrowForwardIcon : EditIcon, onEdit)}
                {onRemoveLoading ? (
                  <CircularProgress size={18} />
                ) : (
                  onRemove && getStyledIcon(RemoveCircleOutlineIcon, onRemove)
                )}
              </Stack>
            )}
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: -53,
                vertical: 30,
              }}
              open={optionsOpen}
              onClose={handleOptionsClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  backgroundColor: theme.palette.background.paper,
                  borderColor: theme.palette.primary.main,
                  borderWidth: 1,
                  minWidth: "110px",
                  borderStyle: "solid",
                  borderRadius: "4px",
                  mt: 1.5,
                  padding: 0,
                  "& .MuiMenu-list": {
                    padding: 0,
                  },
                },
              }}
            >
              <StyledMenuItem
                onClick={(e) => {
                  onEdit && onEdit();
                  handleOptionsClose(e);
                }}
              >
                Edit
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove && onRemove();
                  handleOptionsClose(e);
                }}
                sx={{ color: theme.palette.error.main }}
              >
                Delete
              </StyledMenuItem>
            </Menu>
          </Stack>
        </Stack>
        {items.length && (
          <Stack direction={variant !== "default" ? "column" : "row"} gap={1}>
            {items.map(({ label, value, component, visible }, index) =>
              visible ? (
                <Stack
                  direction={variant !== "default" ? "row" : "column"}
                  justifyContent="space-between"
                  sx={{ flex: 1, mr: 1.5 }}
                  key={index}
                >
                  <Label variant="caption" fontSize={variant !== "default" ? 15 : 12}>
                    {label}
                  </Label>
                  {component || (
                    <Typography fontWeight={500} noWrap>
                      {value}
                    </Typography>
                  )}
                </Stack>
              ) : null
            )}
          </Stack>
        )}
      </Stack>
      {postfix}
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  background: theme.custom.background.secondary,
  minHeight: 120,
  padding: theme.spacing(2),
  borderRadius: 4,
  height: "100%",
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: theme.spacing(0.5),
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius,
  justifyContent: "center",
}));
