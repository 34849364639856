import React from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { COLORS } from "@src/pages/portfolio/constant";
import { percentFormatter } from "@src/pages/analytics/helpers";
import { FundingRate } from "@src/store/apis/anbotoApi/types";
import ComparativeChartTooltip from "@src/pages/analytics/funding-rates/comparative-funding-rates/comparative-chart-tooltip";
import AngleAxisTick from "@src/pages/analytics/funding-rates/comparative-funding-rates/angle-axis-tick";

type ComparativeFundingRatesProps = {
  data: FundingRate[];
  isFetching: boolean;
  isError: boolean;
};

const ComparativeFundingRatesChart = ({ data, isFetching, isError }: ComparativeFundingRatesProps) => {
  const theme = useTheme();
  const sortedData = [...data].sort((a, b) => b.rate - a.rate);

  return (
    <>
      {isError ? (
        <Stack height={300} direction="column" justifyContent="center" textAlign="center">
          <Typography>Something went wrong. Please, try again later</Typography>
        </Stack>
      ) : isFetching ? (
        <Stack alignItems="center" height={300} direction="column" justifyContent="center" textAlign="center">
          <CircularProgress />
        </Stack>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={sortedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke={theme.custom.background.secondary} vertical={false} strokeDasharray="5 5" />
            <XAxis height={60} tick={(props) => <AngleAxisTick {...props} />} interval={0} dataKey="symbol" />
            <YAxis tick={{ fontSize: 12, fill: "white" }} tickFormatter={percentFormatter} />
            <Tooltip content={<ComparativeChartTooltip />} cursor={false} />
            <Bar dataKey="rate" fill={COLORS[0]} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default ComparativeFundingRatesChart;
