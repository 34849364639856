import React from "react";
import { useNavigate } from "react-router-dom";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

const LinkWalletButton = () => {
  const navigate = useNavigate();

  return (
    <>
      <AnbotoButton onClick={() => navigate("/settings/linked/wallets")} color="secondary" variant="outlined">
        Link wallet
      </AnbotoButton>
    </>
  );
};

export default LinkWalletButton;
