import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Stack, StackProps, Tooltip, Typography, styled } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { updateSelectedExchanges } from "../symbol-select/symbol-select.slice";
import { ExchangeAvatar } from "../exchange-avatar/exchange-avatar";
import { prepareExchange } from "@src/pages/cefi/order-symbol-chart/anboto-datafeed/helpers";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { useLinkedExchanges } from "@src/pages/cefi/hooks/use-linked-exchanges";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";
import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useTestnetEnabled } from "@src/hooks/use-testnet-enabled";
import { useActiveView } from "@src/features/widgets-layout/hooks/use-active-view";
import { WidgetsGridView } from "@src/features/widgets-layout/types";

export const SymbolSelectExchanges = ({
  exchanges,
  ...props
}: StackProps & {
  onChange: (data: Record<Partial<CefiExchangeId>, boolean>) => void;
  exchanges?: Record<CefiExchangeId, boolean>;
}) => {
  const view = useActiveView();
  const type = view === WidgetsGridView.CEFI ? "cefi" : "otc";
  const getExchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi: false });
  const linkedExchanges = useLinkedExchanges();
  const selectedExchanges = useAppSelector((state) => state.symbolSelect.selectedExchanges[type]) || {};
  const dispatch = useAppDispatch();
  const [, update] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const isTestnet = useTestnetEnabled();

  const _exchanges = linkedExchanges.exchanges
    .filter((id) => (exchanges ? exchanges[id] : true))
    .map((exchange) => ({ exchange, linked: true }));
  const restExchanges =
    getExchangesQuery.data?.results.reduce<{ exchange: CefiExchangeId; linked: boolean }[]>(
      (prev, { exchange_id }) =>
        linkedExchanges.exchanges.includes(exchange_id) ? prev : [{ exchange: exchange_id, linked: false }, ...prev],
      []
    ) || [];

  _exchanges.push(...restExchanges);

  const orderedExchanges = React.useMemo(
    () => [..._exchanges].sort((a, b) => +(selectedExchanges[b.exchange] || 0) - +(selectedExchanges[a.exchange] || 0)),
    [linkedExchanges.exchanges.join()]
  );

  const forceUpdate = useCallback(() => update(+new Date()), []);

  const scrollTo = (direction: "left" | "right") => {
    const left = direction === "left" ? 0 : 1000;

    setTimeout(() => {
      forceUpdate();
    }, 50);

    scrollRef.current?.scrollTo({ left });
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      forceUpdate();

      scrollRef.current?.addEventListener("scrollend", forceUpdate);
    });

    return () => {
      scrollRef.current?.removeEventListener("scrollend", forceUpdate);
    };
  }, []);

  const scrollLeft = scrollRef.current?.scrollLeft;
  const showArrows = scrollRef.current?.clientWidth && scrollRef.current.clientWidth < scrollRef.current.scrollWidth;

  const showLeft = showArrows && scrollLeft;
  const showRight = showArrows && !scrollLeft;

  return (
    <Stack position="relative" mx={1.5} {...props}>
      <ButtonGradient dir="left" sx={{ left: 0, visibility: showLeft ? "visible" : "hidden" }}>
        <ScrollButton onClick={() => scrollTo("left")}>
          <KeyboardArrowLeftIcon sx={{ fontSize: 14, color: (theme) => theme.palette.text.secondary }} />
        </ScrollButton>
      </ButtonGradient>
      <ButtonGradient dir="right" sx={{ right: 0, visibility: showRight ? "visible" : "hidden" }}>
        <ScrollButton onClick={() => scrollTo("right")}>
          <KeyboardArrowRightIcon sx={{ fontSize: 14, color: (theme) => theme.palette.text.secondary }} />
        </ScrollButton>
      </ButtonGradient>
      <Stack
        ref={scrollRef}
        direction="row"
        gap={1}
        alignItems="center"
        sx={{
          overflow: "hidden",
          scrollbarWidth: "none",
          scrollBehavior: "smooth",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            width: 0,
            height: 0,
          },
        }}
      >
        {orderedExchanges.map(({ exchange, linked }) => {
          const isSelected = selectedExchanges && selectedExchanges[exchange];
          const name = getExchangeNameLabel(CEFI_EXCHANGE_NAME[exchange]);
          const isLinked = linked || isTestnet;

          return (
            <Tooltip
              key={exchange}
              arrow
              placement="bottom"
              title={
                isLinked ? (
                  name
                ) : (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: 11, cursor: "pointer" }}
                    onClick={() => navigate("/settings/linked/exchanges")}
                  >
                    <span style={{ textDecoration: "underline" }}>Link</span> the {name} exchange first
                  </Typography>
                )
              }
            >
              <Stack
                direction="row"
                alignItems="center"
                gap={0.5}
                p={0.5}
                sx={{
                  cursor: isLinked ? "pointer" : "default",
                  background: "#232C2F",
                  borderRadius: (theme) => theme.spacing(1),
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: isSelected ? (theme) => theme.palette.primary.main : "transparent",
                  flexWrap: "nowrap",
                  opacity: isLinked ? 0.9 : 0.5,
                }}
                key={exchange}
                onClick={() => {
                  if (linked) {
                    const data = {
                      ...selectedExchanges,
                      [exchange]: selectedExchanges ? !selectedExchanges[exchange] : true,
                    } as unknown as Record<Partial<CefiExchangeId>, boolean>;
                    dispatch(updateSelectedExchanges({ exchanges: data, type }));
                    props.onChange && props.onChange(data);
                  }
                }}
              >
                <ExchangeAvatar
                  exchange={prepareExchange(exchange)}
                  size={18}
                  imgProps={{
                    sx: {
                      filter: isLinked ? "" : "grayscale()",
                    },
                  }}
                />
                {/* <Typography fontSize={10} noWrap>
                {name}
              </Typography> */}
              </Stack>
            </Tooltip>
          );
        })}
      </Stack>
    </Stack>
  );
};

const ScrollButton = styled(Stack)(({ theme }) => ({
  width: 20,
  height: 20,
  border: `1px solid ${theme.palette.text.secondary}`,
  background: "#192022",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  cursor: "pointer",
}));

const ButtonGradient = styled(Stack)<{ dir: "left" | "right" }>(({ theme, dir }) => ({
  height: "100%",
  zIndex: 2,
  width: 20,
  position: "absolute",
  top: 0,
  background:
    dir === "left"
      ? `linear-gradient(90deg, ${theme.palette.background.default} 0%, ${theme.palette.background.default} 43%, rgba(255,255,255,0) 100%)`
      : `linear-gradient(270deg, ${theme.palette.background.default} 0%, ${theme.palette.background.default} 43%, rgba(255,255,255,0) 100%)`,
}));
