import React, { forwardRef } from "react";
import SVG from "react-inlinesvg";
import { MenuItem } from "@mui/material";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { CHAIN_LOGO, CHAIN_NAME, CHAIN_IDS } from "@src/pages/defi/constants";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { useRecentChainIdState } from "@src/pages/defi/hooks";

export type ChainIdFieldProps = ControllerRenderProps;

export const ChainIdField = forwardRef(({ value, onChange }: ChainIdFieldProps, ref) => {
  const recentChainIdState = useRecentChainIdState();

  const _onChange = (newChainId: number) => {
    recentChainIdState.setState(newChainId);
    onChange(newChainId);
  };

  return (
    <>
      <AnbotoTextField
        label="Select chain"
        fullWidth
        size="small"
        onChange={(e) => _onChange(parseInt(e.target.value))}
        value={value}
        inputProps={{ style: { cursor: "pointer" } }}
        select
        inputRef={ref}
        sx={{
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        {CHAIN_IDS.map((x) => (
          <MenuItem key={x} value={x}>
            <SVG src={CHAIN_LOGO[x]} height={20} />
            &nbsp;
            {CHAIN_NAME[x]}
          </MenuItem>
        ))}
      </AnbotoTextField>
    </>
  );
});
ChainIdField.displayName = "ChainIdField";
