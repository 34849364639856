import React from "react";
import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";
import { PortfolioHeader } from "../components/portfolio-header";

export const PortfolioPage = () => {
  return (
    <Stack gap={0.5} flexGrow={1}>
      <PortfolioHeader />
      <Outlet />
    </Stack>
  );
};
