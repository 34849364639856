import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import AnbotoHorizontalStepper from "@src/components/anboto-stepper/anboto-horizontal-stepper";

const steps = [
  "Create an account to deposit your credits",
  "Give allowance the token to be spent",
  "Sign the deposit itself",
];

type DepositStepsProps = {
  step: number;
};

const DepositSteps = ({ step }: DepositStepsProps) => {
  return (
    <Box marginTop={3} border="1px solid #56C3F2" borderRadius={1} sx={{ width: 640 }}>
      <Stack direction="column">
        <Stack textAlign="center" sx={{ background: "#232C2F" }} padding={0.75}>
          <Typography fontWeight={500} fontSize={12}>
            First time deposit steps
          </Typography>
        </Stack>
        <Stack padding={1}>
          <AnbotoHorizontalStepper steps={steps} activeStep={step} />
        </Stack>
      </Stack>
    </Box>
  );
};

export default DepositSteps;
