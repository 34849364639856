import React from "react";
import {
  List,
  ListItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { CONFIG } from "@src/pages/competitions/components/gate/config";

type CampaignRulesWooxProps = {
  prizePool: { volume: string; prizePool: string }[];
};
export const CampaignRules = ({ prizePool }: CampaignRulesWooxProps) => {
  const theme = useTheme();

  return (
    <>
      <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary, fontSize: 14 }}>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          The duration of the competition has been specified as:
          <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>Start of event: {CONFIG.START_TIME};</ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>End of event: {CONFIG.END_TIME};</ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          The users/participants of the competition must be registered and successfully KYC-ed on Gate.io by the end of
          the competition duration.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Only trading volume generated during the trading competition will be counted.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          During the competition, only the trading volume generated by Anboto users who use Anboto to trade on Gate.io
          is eligible.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>Both Gate.io Spot and Futures are available</ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Prize pool: the competition uses a dynamic prize pool mechanism where the higher total trading volume during
          the trading competition unlocks a larger prize pool. The size of the prize pool and distribution of rewards
          are:
          <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>Prize pools:</ListItem>
            <TableContainer sx={{ width: 300 }}>
              <Table
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0px 8px",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&>th": {
                        border: 0,
                        fontSize: 12,
                        color: "#8A9296",
                        fontWeight: 400,
                        padding: 1,
                        paddingBottom: 0,
                      },
                      "&>th:last-child": { paddingRight: 0 },
                      "&>th:first-child": { paddingLeft: 0 },
                    }}
                  >
                    <TableCell>Prize Pool (paid in WOO)</TableCell>
                    <TableCell align="right">Min Volume Requirements</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {prizePool.map((prize) => (
                    <TableRow
                      key={prize.volume}
                      sx={{
                        background: "#192022",
                        paddingLeft: 0,
                        paddingRight: 0,
                        "&>td": {
                          border: 0,
                          fontWeight: 500,
                          fontSize: 14,
                          marginBottom: 1,
                          color: "#B0BCC2",
                          padding: 1,
                        },
                        "&>td:last-child": { paddingRight: 0 },
                        "&>td:first-child": { paddingLeft: 0 },
                      }}
                    >
                      <TableCell align="left">{prize.prizePool}</TableCell>
                      <TableCell align="right">{prize.volume}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          The top 10 traders (based on trading volume) will be selected as winners.
          <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>Prize Distribution by Ranking:</ListItem>
            <TableContainer sx={{ width: 400 }}>
              <Table
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0px 8px",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&>th": {
                        border: 0,
                        fontSize: 12,
                        color: "#8A9296",
                        fontWeight: 400,
                        padding: 1,
                        paddingBottom: 0,
                      },
                      "&>th:last-child": { paddingRight: 0 },
                      "&>th:first-child": { paddingLeft: 0 },
                    }}
                  >
                    <TableCell>Trading Volume Ranking</TableCell>
                    <TableCell align="right">Prize</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <StyledTableRow key="1st">
                    <TableCell align="left">1st</TableCell>
                    <TableCell align="right">25%</TableCell>
                  </StyledTableRow>
                  <StyledTableRow key="2nd">
                    <TableCell align="left">2nd</TableCell>
                    <TableCell align="right">20%</TableCell>
                  </StyledTableRow>
                  <StyledTableRow key="3rd">
                    <TableCell align="left">3rd</TableCell>
                    <TableCell align="right">15%</TableCell>
                  </StyledTableRow>
                  <StyledTableRow key="4th - 10th">
                    <TableCell align="left">4th - 10th</TableCell>
                    <TableCell align="right">Split 40% of the prize pool</TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </List>
        </ListItem>

        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Additional rules:
          <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
              To be eligible for the top-3 rewards, your Competition Trading Volume must be higher than $1,000,000 USDT
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
              To be eligible for the top-10 rewards, your Competition Trading Volume must be higher than $500,000 USDT
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          In case of a tie, all winning traders will share the prize pool.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Effective trading volume includes both successfully executed long and short positions.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Gate.io reserves the right to disqualify trades that are wash trades, involve illegal bulk account
          registrations, self-dealing, or display signs of market manipulation or suspicious behavior, etc. The
          participant of the competition acknowledges and agrees that the decision regarding disqualification is at the
          sole discretion of Gate.io. Gate.io is not obliged to provide any explicit reason(s) for doing so.
        </ListItem>
      </List>
    </>
  );
};

const StyledTableRow = styled(TableRow)(() => ({
  background: "#192022",
  paddingLeft: 0,
  paddingRight: 0,
  "&>td": {
    border: 0,
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 8,
    color: "#B0BCC2",
    padding: 8,
  },
  "&>td:last-child": { paddingRight: 0 },
  "&>td:first-child": { paddingLeft: 0 },
}));
