import { extractJSON, PortfolioCefiError } from "@src/pages/portfolio";
import { CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";

export const parseApiErrors = (apiErrors: PortfolioCefiError[]): string[] =>
  apiErrors
    ?.map((errorItem) => {
      const extractedText = extractJSON(errorItem.error_text);
      const parsedText = extractedText ? JSON.parse(extractedText) : null;
      const errorText = parsedText?.msg || parsedText?.message || errorItem.error_text || "";

      return {
        errorType: errorItem.error_type,
        errorText,
        marketType: errorItem.market_type,
      };
    })
    .filter((item) => item.marketType === CefiExchangeMarketType.SPOT)
    .map((item) => `Market type: ${item.marketType}; Error type: ${item.errorType}; Error text: ${item.errorText}`);
