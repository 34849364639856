import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import bannerBg from "@src/assets/images/referral-banner.jpg";

export const Banner = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{ backgroundImage: `url(${bannerBg})`, backgroundSize: "co" }}
      px={3}
      borderRadius={2}
      border={`1px solid ${theme.custom.colors.primary}`}
      justifyContent="space-between"
      direction="row"
      alignItems="center"
      height={100}
      width={800}
      mb={1}
    >
      <Typography fontSize={40} fontWeight={500} color={theme.custom.colors.primary}>
        SHARE{" "}
        <Typography fontSize={40} fontWeight={500} color={theme.custom.colors.secondary} variant="caption">
          fees!
        </Typography>
      </Typography>
      <Typography fontWeight={500} fontSize={24}>
        Enjoy the benefit together
      </Typography>
    </Stack>
  );
};
