import { useEffect } from "react";
import { useAccount } from "wagmi";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import {
  useDefiOrderFormFee,
  useOrderFormFromTokenBalance,
  useOrderFormToTokenBalance,
} from "@src/pages/defi/order-form-card-no-gas/hooks";

export interface UseDefiOrderFormFillHiddenFieldsProps {
  nativeCoinPrice?: number;
  buyTokenToEthRate?: string;
  sellTokenToEthRate?: string;
}

export const useDefiOrderFormFillHiddenFields = ({
  nativeCoinPrice,
  buyTokenToEthRate,
  sellTokenToEthRate,
}: UseDefiOrderFormFillHiddenFieldsProps): void => {
  const orderForm = useDefiOrderFormContext();
  const systemAccount = useAccount();
  const account = orderForm.watch("account");
  const chainNativeCoinPrice = orderForm.watch("chainNativeCoinPrice");
  const formSellTokenToEthRate = orderForm.watch("sellTokenToEthRate");
  const formBuyTokenToEthRate = orderForm.watch("buyTokenToEthRate");

  // load and set up token balances
  useOrderFormFromTokenBalance();
  useOrderFormToTokenBalance();
  useDefiOrderFormFee();

  useEffect(() => {
    if (account !== systemAccount?.address) {
      orderForm.setValue("account", systemAccount?.address);
    }
  }, [systemAccount?.address, account]);

  useEffect(() => {
    const nativeCoinPriceStr = nativeCoinPrice?.toString() || "";

    if (chainNativeCoinPrice === nativeCoinPriceStr) {
      return;
    }

    orderForm.setValue("chainNativeCoinPrice", nativeCoinPriceStr);
  }, [chainNativeCoinPrice, nativeCoinPrice]);

  useEffect(() => {
    const sellTokenToEthRateStr = sellTokenToEthRate?.toString() ?? "";

    if (formSellTokenToEthRate === sellTokenToEthRateStr) {
      return;
    }

    orderForm.setValue("sellTokenToEthRate", sellTokenToEthRate?.toString() ?? "");
  }, [sellTokenToEthRate, formSellTokenToEthRate]);

  useEffect(() => {
    const buyTokenToEthRateStr = buyTokenToEthRate?.toString() ?? "";

    if (formBuyTokenToEthRate === buyTokenToEthRateStr) {
      return;
    }

    orderForm.setValue("buyTokenToEthRate", buyTokenToEthRate?.toString() ?? "");
  }, [buyTokenToEthRate, formBuyTokenToEthRate]);
};
