import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";
import { CefiExchangeId, CefiExchangeMarketType, OrderSymbol } from "@src/store/apis/anbotoApi/types";

export const getOrderSymbolLabel = (
  orderSymbol: OrderSymbol = {} as OrderSymbol,
  toShow: Array<keyof OrderSymbol> = []
) => {
  const { symbol, exchange, market_type } = orderSymbol;
  const symbolMeta = {
    symbol,
    exchange: getExchangeNameLabel(exchange),
    market_type,
  };

  if ([CEFI_EXCHANGE_NAME[CefiExchangeId.WOOX], "woox", "woo", "woo x"].includes(exchange?.toLowerCase())) {
    if ((market_type && market_type === CefiExchangeMarketType.FUTURE) || symbol.includes(":")) {
      const coin1 = symbol.split("/").shift();

      symbolMeta.symbol = `${coin1}-PERP`;
    }
  }

  let res = ["symbol", "market_type", "exchange"] as Array<keyof OrderSymbol>;

  if (toShow.length) {
    res = res.filter((key) => toShow.includes(key));
  }

  return res
    .map((key) => symbolMeta[key])
    .filter(Boolean)
    .join(" - ");
};
