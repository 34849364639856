import React from "react";
import { Stack } from "@mui/material";
import LiquiditySourcesList from "@src/pages/defi/order-form-card-no-gas/fields/select-speed-button/liquidity-sources-list";
import { RootState } from "@src/store/types";
import { useAppSelector } from "@src/store/hooks";

const LiquiditySources = () => {
  const currentLiquiditySources = useAppSelector((state: RootState) => state.user.liquiditySources);

  return (
    <>
      {currentLiquiditySources && Object.keys(currentLiquiditySources).length ? (
        <Stack minHeight={300} justifyContent="start">
          <LiquiditySourcesList />
        </Stack>
      ) : null}
    </>
  );
};

export default LiquiditySources;
