import React from "react";
import { CircularProgress, styled, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { DateTime } from "luxon";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { AnbotoButton, AnbotoButtonProps } from "../ui/AnbotoButton/AnbotoButton";
import { withLightFlatStyle } from "../with-flat-style";
import { AnbotoIconButton } from "@src/components";
import { fileDownload } from "@src/utils/file";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";

export type ExportOrdersButtonProps = {
  label: string;
  compact?: boolean;
  iconWidth?: number;
  ordersType: "cefi" | "defi";
} & AnbotoButtonProps;

const DEFAULT_COMPACT_ICON_WIDTH = 18;

export const ExportOrdersButton = ({ label, ordersType, compact, iconWidth, ...props }: ExportOrdersButtonProps) => {
  const snackbar = useSnackbar();
  const isDefi = ordersType === "defi";

  const [exportOrders, exportOrdersMutation] = anbotoApi.useExportParentOrdersMutation();

  const handleExport = async () => {
    try {
      const data = await exportOrders(isDefi).unwrap();
      fileDownload(data, `${ordersType}_orders_${DateTime.now().toFormat("MMM_d_yyyy_HH:mm")}.csv`);
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data?.error), { variant: "error" });
    }
  };

  if (compact)
    return (
      <Tooltip title={label}>
        <AnbotoIconButton disabled={exportOrdersMutation.isLoading} onClick={handleExport} flat>
          {exportOrdersMutation.isLoading ? (
            <CircularProgress size={iconWidth || DEFAULT_COMPACT_ICON_WIDTH} color="inherit" />
          ) : (
            <SaveAltIcon
              htmlColor="text.secondary"
              sx={{
                color: "#B0BCC2",
                width: iconWidth || DEFAULT_COMPACT_ICON_WIDTH,
                height: iconWidth || DEFAULT_COMPACT_ICON_WIDTH,
              }}
            />
          )}
        </AnbotoIconButton>
      </Tooltip>
    );

  return (
    <Button
      size="small"
      variant="outlined"
      color="secondary"
      endIcon={<SaveAltIcon htmlColor="text.secondary" />}
      loadingPosition="end"
      loading={exportOrdersMutation.isLoading}
      onClick={handleExport}
      sx={{ paddingY: (theme) => theme.spacing(0.5) }}
      {...props}
    >
      <ExportButtonLabel>{label}</ExportButtonLabel>
    </Button>
  );
};

const ExportButtonLabel = styled("span")({
  marginRight: 10,
});

const Button = withLightFlatStyle(AnbotoButton);
