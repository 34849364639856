import React, { ForwardedRef, forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { TriggerCondition } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

const TRIGGER_CONDITIONS: TriggerCondition[] = [TriggerCondition.ABOVE, TriggerCondition.BELOW];

export const TRIGGER_CONDITION_LABEL: Record<TriggerCondition, string> = {
  [TriggerCondition.ABOVE]: "Above",
  [TriggerCondition.BELOW]: "Below",
};

type TriggerConditionFieldProps = ControllerRenderProps & {
  select?: boolean;
  disabled?: boolean;
  labelStyle?: AnbotoTextFieldProps["labelStyle"];
  label?: string;
} & AnbotoTextFieldProps;

export const TriggerConditionField = forwardRef((props: TriggerConditionFieldProps, ref: ForwardedRef<null>) => (
  <AnbotoTextField
    label={
      <Stack direction="row" gap={1} alignItems="center">
        <Typography fontSize={11} variant="caption">
          {props.label || "Trigger condition"}
        </Typography>
        <Tooltip title="'Above': When the trigger price is above the current market price. 'Below': When the trigger price is below the current market price.">
          <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
        </Tooltip>
      </Stack>
    }
    {...props}
    fullWidth
    inputRef={ref}
    labelStyle="dynamic"
    size="small"
    select
    disabled={props.disabled}
  >
    {TRIGGER_CONDITIONS.map((x) => (
      <MenuItem key={x} value={x}>
        {TRIGGER_CONDITION_LABEL[x]}
      </MenuItem>
    ))}
  </AnbotoTextField>
));

TriggerConditionField.displayName = "TriggerConditionField";
