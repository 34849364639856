import React from "react";
import { Stack, StackProps } from "@mui/material";
import { DRAG_HANDLE_CSS_CLASS_NAME } from "../constants";

export const DragArea = ({ sx, ...props }: StackProps) => {
  return (
    <Stack
      className={DRAG_HANDLE_CSS_CLASS_NAME}
      sx={{ cursor: "grab", alignSelf: "stretch", minHeight: "100%", ...sx }}
      {...props}
    />
  );
};
