import { useEffect } from "react";
import { useEstimatedGasCostPerSlice } from "@src/pages/defi/order-form-card-no-gas/hooks/use-estimated-gas-cost-per-slice";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";

export const useDefiOrderFormFee = () => {
  const orderForm = useDefiOrderFormContext();
  const formMaxFeeAbsolute = orderForm.watch("maxFeeAbsolute");
  const formMaxFeeAbsoluteUsd = orderForm.watch("maxFeeAbsoluteUsd");
  const { feeAbsolute, feeAbsoluteUsd } = useEstimatedGasCostPerSlice();
  const isSubmitting = orderForm.formState.isSubmitting;

  useEffect(() => {
    if (formMaxFeeAbsolute === feeAbsolute || isSubmitting) {
      return;
    }
    // max_fee_absolute = average_gas_cost * max_gas_price * output_token_price_in_network_token
    orderForm.setValue("maxFeeAbsolute", feeAbsolute);
  }, [formMaxFeeAbsolute, feeAbsolute, isSubmitting]);

  useEffect(() => {
    if (formMaxFeeAbsoluteUsd === feeAbsoluteUsd || isSubmitting) {
      return;
    }
    // max_fee_absolute = average_gas_cost * max_gas_price * output_token_price_in_network_token
    orderForm.setValue("maxFeeAbsoluteUsd", feeAbsoluteUsd);
  }, [formMaxFeeAbsoluteUsd, feeAbsoluteUsd, isSubmitting]);
};
