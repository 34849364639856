import { MAIN_ACCOUNT_VALUE } from "../constants";
import { ParentOrderFormFieldValues } from "../order-form/hooks/use-parent-order-form";
import { Position } from "../positions-table/types";
import { CefiExchangeMarketType, OrderExecutionStrategy, OrderSide } from "@src/store/apis/anbotoApi/types";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";

export const getOrderFormDataFromPosition = ({
  position,
  defaultValues,
  quantity,
  reduceOnly,
}: {
  position: Position;
  defaultValues: Partial<ParentOrderFormFieldValues>;
  quantity?: string;
  reduceOnly?: boolean;
}) => {
  const symbol = {
    symbol: position.symbol,
    market_type: CefiExchangeMarketType.FUTURE,
    exchange: CEFI_EXCHANGE_NAME[position.exchange],
  };

  const formData = {
    ...defaultValues,
    account: position.subaccount || MAIN_ACCOUNT_VALUE,
    symbol,
    quantity,
    reduceOnly,
  };

  if (!quantity) return formData;

  // close position
  return {
    ...formData,
    strategy: OrderExecutionStrategy.ICEBERG,
    side: position.balance > 0 ? OrderSide.SELL : OrderSide.BUY,
  };
};
