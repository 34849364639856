import { useEffect } from "react";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks/use-defi-order-form-context";
import { DefiOrderExpiration, OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";
import { useDefiOrderFormDefaultValues } from "@src/pages/defi/hooks/use-defi-order-form-default-values";

export const useDefiOrderFormDefaultExpiration = (): void => {
  const orderForm = useDefiOrderFormContext();
  const strategy = orderForm.watch("strategy");
  const expirationFieldWasTouched = orderForm.formState.touchedFields.expiration;
  const defaultValues = useDefiOrderFormDefaultValues();

  useEffect(() => {
    if (expirationFieldWasTouched || defaultValues?.expiration !== undefined) {
      return;
    }

    if ([OrderExecutionStrategy.TWAP, OrderExecutionStrategy.VWAP].includes(strategy)) {
      orderForm.setValue("expiration", DefiOrderExpiration.WEEK);
    } else {
      orderForm.setValue("expiration", DefiOrderExpiration.DAY);
    }
  }, [expirationFieldWasTouched, strategy]);
};
