import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrdersCurrentTab } from "../apis/anbotoApi/types";
import { SearchPool } from "@src/store/apis/geckoterminal-api/types";

export interface DecentralizedExchangePageSlice {
  currentTab: OrdersCurrentTab;
  tokenInfoPool: SearchPool;
  isTokenInfoChartPool: boolean;
}

const initialState: DecentralizedExchangePageSlice = {
  currentTab: OrdersCurrentTab.Regular,
  tokenInfoPool: undefined,
  isTokenInfoChartPool: false,
};

export const decentralizedExchangePageSlice = createSlice({
  name: "decentralizedExchangePage",
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload.value;
    },
    setTokenInfoPool: (state, action: PayloadAction<SearchPool>) => {
      state.tokenInfoPool = action.payload;
    },
    setIsTokenInfoChartPool: (state, action: PayloadAction<boolean>) => {
      state.isTokenInfoChartPool = action.payload;
    },
  },
});

export const { setCurrentTab, setTokenInfoPool, setIsTokenInfoChartPool } = decentralizedExchangePageSlice.actions;

export default decentralizedExchangePageSlice.reducer;
