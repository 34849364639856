import React from "react";
import { Avatar, Box, Link, Stack, Typography } from "@mui/material";
import { AccountingLine, TokenDetails } from "@src/pages/settings/fee-manager/api/types";
import { formatUsd } from "@src/utils/format";
import { SUPPORTED_CHAINS } from "@src/store/apis/debankApi/constants";
import { CHAIN_CONFIG } from "@src/pages/defi/constants";
import { truncateAccount } from "@src/pages/defi/utils";
import { CopyToClipboard } from "@src/components";

export const getTokenValue = (x: AccountingLine) =>
  Object.values(x.token_data).map((token, idx) => (
    <Box key={idx} sx={{ gap: 1 }}>
      <Typography>{formatUsd(Number(token.amount_paid || 0))}</Typography>
    </Box>
  ));

export const getTokenQuantity = (x: AccountingLine) =>
  Object.values(x.token_data).map((token, idx) => (
    <Box key={idx} sx={{ gap: 1 }}>
      <Typography>{Math.round(token.quantity_paid * 1000) / 1000}</Typography>
    </Box>
  ));

export const getToken = (x: AccountingLine, tokenDetails: TokenDetails[]) =>
  Object.entries(x.token_data).map((token, idx) => {
    const tokenProps = tokenDetails.find((tokenDetail) => token?.[0] === tokenDetail.token_address);

    return (
      <Box key={idx} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {tokenProps && (
          <>
            <Avatar src={tokenProps.logo_uri} sx={{ height: 24, width: 24 }} />
            <Typography>{tokenProps.token_symbol}</Typography>
          </>
        )}
      </Box>
    );
  });

export const getChain = (x: AccountingLine) =>
  Object.values(x.token_data).map((token, idx) => (
    <Typography key={idx}>{SUPPORTED_CHAINS.find((chain) => chain.community_id === token.network_id)?.name}</Typography>
  ));

export const getBlockExplorerLinks = (txs) =>
  Object.keys(txs).map((chainId) => (
    <Stack display="flex" direction="row" gap={1}>
      <Typography key={chainId}>
        <Link sx={{ cursor: "pointer", color: "white", textDecoration: "underline", fontSize: 14 }}>
          <a
            style={{ color: "white" }}
            target="_blank"
            href={CHAIN_CONFIG[chainId].blockExplorerUrls + "/tx/" + txs[chainId]}
            rel="noreferrer"
          >
            {truncateAccount(txs[chainId])}
          </a>
        </Link>
      </Typography>
      <CopyToClipboard textToCopy={txs[chainId]} />
    </Stack>
  ));
