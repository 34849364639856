import { Box, Stack, styled } from "@mui/material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

export const SubLabel = styled("span")({
  fontSize: 14,
  lineHeight: "21px",
  fontWeight: 400,
  color: "#8A9296",
});

export const AccountAddress = styled("span")(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  fontSize: 12,
  lineHeight: "21px",
}));

export const Divider = styled(Box)(({ theme }) => ({
  backgroundColor: theme.custom.colors.gray,
  height: 1,
  width: "100%",
  margin: `${theme.spacing(1)} 0`,
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 640,
  border: "0px solid",
  borderRadius: 4,
  backgroundColor: theme.custom.background.secondary,
  display: "grid",
  gridTemplateRows: "48px 1fr 56px",
}));

export const ModalHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.custom.background.default,
  color: theme.palette.text.primary,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  lineHeight: "30px",
  fontWeight: 500,
  fontSize: 18,
  height: 48,
  padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
}));

export const ModalBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}));

export const ModalFooter = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
  textAlign: "right",
  bottom: 0,
  borderTop: `1px solid ${theme.custom.colors.gray}`,
  width: "100%",
  height: 56,
}));

export const ModalActionButton = styled(AnbotoButton)(({ theme }) => ({
  height: 32,
  fontSize: 14,
  fontWeight: 600,
  lineHeight: "21px",
  border: `1px solid ${theme.custom.border.default}`,
  borderRadius: 4,
}));

export const SubmitButton = styled(ModalActionButton)({
  width: 160,
});

export const AccountDialogContent = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.custom.background.default,
  height: "100%",
}));
