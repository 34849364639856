import { useState } from "react";
import { useDialog } from "./useDialog";
import { ChainId } from "@src/pages/defi/types";

export const useDepositWithdrawDialog = () => {
  const [chainFee, setChainFee] = useState<ChainId | number>(0);
  const [tokenFee, setTokenFee] = useState<string>("");
  const dialog = useDialog();
  return { ...dialog, chainFee, tokenFee, setChainFee, setTokenFee };
};
