import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MAX_SYMBOLS } from "../watchlist/constants";
import { SymbolSelectTab } from "./types";
import { CefiExchangeId, CefiExchangeMarketType, FavoriteSymbol, OrderSymbol } from "@src/store/apis/anbotoApi/types";

const MAX_RECENT_SEARCHES = 10;

export interface State {
  tab: { otc: SymbolSelectTab; cefi: SymbolSelectTab };
  recent: { otc: OrderSymbol[]; cefi: OrderSymbol[] };
  selectedExchanges: { otc: Record<CefiExchangeId, boolean>; cefi: Record<CefiExchangeId, boolean> };
  marketType: { otc: Record<CefiExchangeMarketType, boolean>; cefi: Record<CefiExchangeMarketType, boolean> };
  tokenLogos: Record<string, string>;
  favourites: { otc: FavoriteSymbol[]; cefi: FavoriteSymbol[] };
}

const initialState: State = {
  tab: { otc: SymbolSelectTab.ALL, cefi: SymbolSelectTab.ALL },
  recent: {
    otc: [],
    cefi: [],
  },
  selectedExchanges: {
    otc: {} as Record<CefiExchangeId, boolean>,
    cefi: {} as Record<CefiExchangeId, boolean>,
  },
  marketType: {
    otc: {} as Record<CefiExchangeMarketType, boolean>,
    cefi: {} as Record<CefiExchangeMarketType, boolean>,
  },
  tokenLogos: {},
  favourites: {
    otc: [],
    cefi: [],
  },
};

const getSymbolId = ({ symbol, exchange, market_type }: OrderSymbol) => [symbol, exchange, market_type].join("");

export const symbolSelectSlice = createSlice({
  name: "symbolSelect",
  initialState,
  reducers: {
    addRecentSymbol: (state, action: PayloadAction<{ symbol: OrderSymbol; type: "cefi" | "otc" }>) => {
      if (action.payload) {
        const { symbol, type } = action.payload;
        const oldState = (state.recent[type] || []).filter(
          (stateSymbol) => getSymbolId(stateSymbol) !== getSymbolId(symbol)
        );
        state.recent[type] = [symbol, ...oldState].slice(0, MAX_RECENT_SEARCHES);
      }
    },
    removeRecentSymbol: (state, action: PayloadAction<{ symbol: OrderSymbol; type: "cefi" | "otc" }>) => {
      const { type, symbol } = action.payload;

      state.recent[type] = (state.recent[type] || []).filter((stateSymbol: OrderSymbol) => {
        return getSymbolId(stateSymbol).toLowerCase() !== getSymbolId(symbol).toLowerCase();
      });
    },
    clearRecent: (state, action: PayloadAction<"cefi" | "otc">) => {
      state.recent[action.payload] = [];
    },
    updateSelectedExchanges: (
      state,
      action: PayloadAction<{ exchanges: Record<Partial<CefiExchangeId>, boolean>; type: "cefi" | "otc" }>
    ) => {
      state.selectedExchanges[action.payload.type] = action.payload.exchanges;
    },
    updateMarketType: (
      state,
      action: PayloadAction<{ marketType: Record<CefiExchangeMarketType, boolean>; type: "cefi" | "otc" }>
    ) => {
      const { marketType, type } = action.payload;
      state.marketType[type] = marketType;
    },
    saveLogo: (state, action: PayloadAction<{ coin: string; logo: string }>) => {
      const { coin, logo } = action.payload;
      state.tokenLogos[coin] = logo;
    },
    setTab: (state, action: PayloadAction<{ tab: SymbolSelectTab; type: "cefi" | "otc" }>) => {
      const { type, tab } = action.payload;
      state.tab[type] = tab;
    },
    addSymbolToFavourites: (state, action: PayloadAction<{ symbol: FavoriteSymbol; type: "cefi" | "otc" }>) => {
      const { type, symbol } = action.payload;
      state.favourites[type] = [symbol, ...state.favourites[type]].slice(0, MAX_SYMBOLS);
    },
    removeSymbolFromFavourites: (state, action: PayloadAction<{ symbol: FavoriteSymbol; type: "cefi" | "otc" }>) => {
      const { type, symbol } = action.payload;
      state.favourites[type] = state.favourites[type].filter((s) => getSymbolId(s) !== getSymbolId(symbol));
    },
    orderSymbolFavourites: (state, action: PayloadAction<{ from: number; to: number; type: "cefi" | "otc" }>) => {
      const { from, to, type } = action.payload;
      const [symbol] = state.favourites[type].splice(from, 1);
      state.favourites[type].splice(to, 0, symbol);
    },
  },
});

export const {
  addRecentSymbol,
  removeRecentSymbol,
  clearRecent,
  updateSelectedExchanges,
  updateMarketType,
  saveLogo,
  setTab,
  addSymbolToFavourites,
  removeSymbolFromFavourites,
  orderSymbolFavourites,
} = symbolSelectSlice.actions;

export default symbolSelectSlice.reducer;
