import React, { FC } from "react";
import { Avatar } from "@mui/material";
import { ChainId } from "@src/pages/defi/types";
import { normalizeIpfsLink } from "@src/pages/defi/utils";
import { useTokenInfo } from "@src/pages/defi/hooks";

export interface TokenAvatarProps {
  address?: string;
  chainId?: ChainId;
  symbol?: string;
  sx?: {
    borderRadius?: string | number;
    display?: string | number;
    fontSize?: string | number;
    height: string | number;
    lineHeight?: string | number;
    width: string | number;
    textAlign?: string;
  };
}

const defaultAvatarStyles = { width: 24, height: 24 };

export const TokenAvatar: FC<TokenAvatarProps> = ({ address, sx, chainId, symbol }) => {
  const token = useTokenInfo({ chainId, address });
  // we don't want to check any image twice

  const tokenLogoURI = token?.logoURI || "";
  const tokenSymbol = token?.symbol || symbol || "";

  if (tokenLogoURI) {
    return (
      <Avatar
        sx={sx || defaultAvatarStyles}
        src={normalizeIpfsLink(tokenLogoURI)}
        imgProps={{ loading: "lazy" }}
        alt={tokenSymbol}
      />
    );
  }

  return <Avatar sx={sx || defaultAvatarStyles}>{tokenSymbol.slice(0, 1)}</Avatar>;
};
