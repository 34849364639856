import { useAppSelector } from "@src/store/hooks";

export const useCefiOrDefiModalShow = () => {
  const is_defi_intro_shown = useAppSelector((state) => state.user.is_defi_intro_shown);
  const is_cefi_intro_shown = useAppSelector((state) => state.user.is_cefi_intro_shown);
  const is_widgets_intro_shown = useAppSelector((state) => state.user.is_widgets_intro_shown);
  const is_cefi_or_defi_modal_shown = useAppSelector((state) => state.user.is_cefi_or_defi_modal_shown);

  const showCefiOrDefiModal =
    !is_cefi_or_defi_modal_shown && !is_defi_intro_shown && !is_cefi_intro_shown && !is_widgets_intro_shown;

  return showCefiOrDefiModal;
};
