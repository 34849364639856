import React from "react";
import { Divider, InputAdornment, Stack, styled, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { ReduceOnlySelect } from "../../order-form/fields/reduce-only-select";
import { TradingStyleSelect } from "@src/pages/cefi/order-form/fields/TradingStyleSelect";
import { WouldStyleSelect } from "@src/pages/cefi/order-form/fields/WouldStyleSelect";
import { WouldPriceField } from "@src/pages/cefi/order-form/fields/WouldPriceField";
import { WouldPctFieldField } from "@src/pages/cefi/order-form/fields/WouldPctField";
import { PlacementField } from "@src/pages/cefi/order-form/fields/PlacementField";
import { PlacementCancelField } from "@src/pages/cefi/order-form/fields/PlacementCancelField";
import { CLIP_SIZE_TYPE_NAME } from "@src/pages/cefi/order-form/fields/ClipSizeTypeSelect";
import {
  ExpirationTime,
  ExpirationTimeSelect,
  getExpirationTimeFromMs,
} from "@src/pages/cefi/order-form/fields/ExpirationTimeSelect";
import { LimitPriceField } from "@src/pages/cefi/order-form/fields/limit-price-field";
import { TradingDurationField } from "@src/pages/cefi/order-form/fields/TradingDurationField";
import { TradingDurationUnitField } from "@src/pages/cefi/order-form/fields/TradingDurationUnitField";
import { PovRiskSelect } from "@src/pages/cefi/order-form/fields/PovRiskSelect";
import { PovRatioField } from "@src/pages/cefi/order-form/fields/pov-ratio-field";
import { useParentOrderForm } from "@src/pages/cefi/order-form/hooks/use-parent-order-form";
import { TriggerConditionField, TriggerPriceField } from "@src/components";
import {
  AllParentOrder,
  ClipSizeType,
  OrderTradingDurationUnit,
  ParentOrder,
  PlacementMode,
  TradingStyle,
  TriggerCondition,
} from "@src/store/apis/anbotoApi/types";
import { getTradingDurationFromMs } from "@src/pages/cefi/order-form/utils";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { UrgencySelect } from "@src/pages/cefi/order-form/fields/urgency-select";

type ExpandedRowProps = {
  data: ParentOrder | AllParentOrder;
  isExtraHeader?: boolean;
};

export const ExpandedRow = ({ data, isExtraHeader }: ExpandedRowProps) => {
  const {
    strategy,
    side,
    order_id,
    quantity,
    clip_size_type,
    clip_size_val,
    params,
    start_time,
    expiration_time,
    limit_price,
    trader_email,
  } = data;
  const { would, placement_infos, trigger, trading_style, pov_risk, pov_ratio, reduce_only, urgency } = params || {};
  const orderForm = useParentOrderForm({
    ...(quantity ? { quantity: quantity?.toString() } : {}),
    side,
    strategy,
    ...(clip_size_val ? { clipSizeValue: clip_size_val.toString() } : {}),
    clipSizeType: clip_size_type,
    ...(params?.duration_seconds
      ? { tradingDuration: getTradingDurationFromMs(params.duration_seconds * 1000).duration.toString() }
      : {}),
    ...(params?.duration_seconds
      ? {
          tradingDurationUnit: getTradingDurationFromMs(params.duration_seconds * 1000)
            .durationUnits as OrderTradingDurationUnit,
        }
      : {}),
    ...(limit_price ? { limitPrice: limit_price.toString() } : {}),
    startTime: start_time?.toString(),
    // endTime: end_time,
    ...(would?.would_price ? { wouldPrice: would.would_price.toString() } : {}),
    ...(would?.would_style ? { wouldStyle: would.would_style } : {}),
    ...(would?.would_style ? { wouldPct: would.would_pct.toString() } : {}),
    ...(placement_infos?.placement_mode ? { placementMode: placement_infos.placement_mode as PlacementMode } : {}),
    ...(placement_infos?.placement ? { placement: placement_infos.placement.toString() } : {}),
    ...(placement_infos?.cancel ? { placementCancel: placement_infos.cancel.toString() } : {}),

    ...(trigger?.trigger_condition ? { triggerCondition: trigger.trigger_condition as TriggerCondition } : {}),
    ...(trigger?.trigger_price ? { triggerPrice: trigger?.trigger_price.toString() } : {}),

    ...(trading_style ? { tradingStyle: trading_style as TradingStyle } : {}),
    ...(expiration_time
      ? { expirationTime: getExpirationTimeFromMs(Number(start_time), expiration_time) as ExpirationTime }
      : {}),
    ...(pov_risk ? { povRisk: pov_risk } : {}),
    ...(pov_ratio ? { povRatio: pov_ratio } : {}),
    ...(reduce_only ? { reduceOnly: reduce_only } : {}),
    ...(urgency ? { urgency } : {}),
  });

  return (
    <Root>
      {!isExtraHeader && (
        <Stack gap={2} direction="row" alignItems="center" sx={{ pb: 2 }}>
          <Stack direction="row" gap={2}>
            <Typography variant="body2">Order no. {order_id}</Typography>
            <Typography variant="body2">Trader: {trader_email}</Typography>
          </Stack>
          <Divider />
        </Stack>
      )}
      <Stack direction="row" gap={1} justifyContent="flex-start">
        {trading_style && (
          <Controller
            name="tradingStyle"
            control={orderForm.control}
            render={({ field }) => <TradingStyleSelect {...field} disabled />}
          />
        )}
        {params?.duration_seconds && (
          <Controller
            name="tradingDuration"
            control={orderForm.control}
            render={({ field }) => (
              <TradingDurationField
                {...field}
                sx={{ minWidth: 130 }}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Controller
                        name="tradingDurationUnit"
                        control={orderForm.control}
                        render={({ field }) => <TradingDurationUnitField {...field} disabled />}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}

        {params?.urgency && (
          <Controller
            control={orderForm.control}
            name="urgency"
            render={({ field }) => <UrgencySelect {...field} disabled label="Urgency" />}
          />
        )}

        {params?.would?.would_style && (
          <Controller
            control={orderForm.control}
            name="wouldStyle"
            render={({ field }) => <WouldStyleSelect {...field} disabled label="Would Style" />}
          />
        )}

        {params?.would?.would_price && (
          <Controller
            control={orderForm.control}
            name="wouldPrice"
            render={({ field }) => <WouldPriceField {...field} disabled label="I Would Price" />}
          />
        )}

        {params?.would?.would_pct && (
          <Controller
            control={orderForm.control}
            name="wouldPct"
            render={({ field }) => <WouldPctFieldField {...field} disabled label="Would Pct" sx={{ maxWidth: 60 }} />}
          />
        )}

        {limit_price && (
          <Controller
            name="limitPrice"
            control={orderForm.control}
            render={({ field }) => <LimitPriceField {...field} disabled label="Limit price" />}
          />
        )}
        {trigger?.trigger_condition && (
          <Controller
            control={orderForm.control}
            name="triggerCondition"
            render={({ field }) => <TriggerConditionField {...field} select disabled />}
          />
        )}
        {trigger?.trigger_price && (
          <Controller
            control={orderForm.control}
            name="triggerPrice"
            render={({ field }) => <TriggerPriceField {...field} disabled label="Trigger price" />}
          />
        )}
        {placement_infos?.placement && (
          <Controller
            control={orderForm.control}
            name="placement"
            render={({ field }) => <PlacementField disabled {...field} sx={{ maxWidth: 70 }} />}
          />
        )}
        {placement_infos?.cancel && (
          <Controller
            control={orderForm.control}
            name="placementCancel"
            render={({ field }) => <PlacementCancelField disabled {...field} sx={{ maxWidth: 70 }} />}
          />
        )}
        <Controller
          control={orderForm.control}
          name="reduceOnly"
          render={({ field }) => <ReduceOnlySelect disabled {...field} />}
        />
        <Controller
          name="clipSizeValue"
          control={orderForm.control}
          render={({ field }) => {
            return (
              <AnbotoTextField
                {...field}
                value={
                  clip_size_type === ClipSizeType.AUTOMATIC
                    ? CLIP_SIZE_TYPE_NAME[clip_size_type]
                    : clip_size_type === ClipSizeType.PERCENTAGE
                    ? `${field.value}%`
                    : field.value
                }
                size="small"
                fullWidth
                label="Clip size"
                disabled
              />
            );
          }}
        />
        {expiration_time && (
          <Controller
            name="expirationTime"
            control={orderForm.control}
            render={({ field }) => <ExpirationTimeSelect {...field} disabled />}
          />
        )}
        {pov_ratio && (
          <Controller
            name="povRatio"
            control={orderForm.control}
            render={({ field }) => <PovRatioField {...field} disabled hideButtonGroup />}
          />
        )}
        {pov_risk && (
          <Controller
            name="povRisk"
            control={orderForm.control}
            render={({ field }) => <PovRiskSelect {...field} disabled />}
          />
        )}
      </Stack>
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  background: theme.custom.background.default,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(1),
}));
