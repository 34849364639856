import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useAppSelector } from "@src/store/hooks";

export const usePermissions = () => {
  const getAccountsQuery = anbotoApi.useGetUserAccountsQuery(null);
  const teamId = useAppSelector((state) => state.user.team_uuid);
  const userId = useAppSelector((state) => state.user.user_uuid);
  const currentAccount = (getAccountsQuery?.data?.results || []).find((account) => account.uuid === teamId);
  const permissions = currentAccount?.permissions?.find((p) => p.user_uuid === userId);
  const trade = !permissions?.read_only;

  return {
    view: true,
    trade,
  };
};
