import { useEffect } from "react";
import { useLazyGetTokenByAddressAndChainQuery } from "@src/store/apis/algoliaApi";
import { ChainId, EVMBasedAddress, Token } from "@src/pages/defi/types";
import { constructTokenAddress, isTokenSaveNeeded } from "@src/pages/defi/utils";
import { saveToken } from "@src/store/slices/tokensSlice";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

export interface UseTokenInfoProps {
  chainId?: ChainId;
  address?: EVMBasedAddress | string;
}

export type UseTokenInfoResult = Token | undefined;

export const useTokenInfo = ({ chainId, address }: UseTokenInfoProps): UseTokenInfoResult => {
  const [getToken, { isLoading }] = useLazyGetTokenByAddressAndChainQuery();
  const tokensMap = useAppSelector((state) => state.tokens.tokensMap);
  const dispatch = useAppDispatch();
  const tokenChainAddress = chainId && address ? constructTokenAddress(chainId, address as EVMBasedAddress) : null;

  const findInExternal = async (): Promise<void> => {
    if (isLoading || !address || !chainId) {
      return;
    }

    const { data: token } = await getToken({ address, chainId });

    if (!token) {
      return;
    }

    dispatch(saveToken(token));
  };

  useEffect(() => {
    if (!chainId || !address || !tokenChainAddress) {
      return;
    }

    if (!isTokenSaveNeeded(tokensMap[tokenChainAddress])) {
      return;
    }

    void findInExternal();
  }, [chainId, address, tokensMap]);

  if (!address || !chainId) {
    return undefined;
  }

  return tokensMap[constructTokenAddress(chainId, address as EVMBasedAddress)]?.token;
};
