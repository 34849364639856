import React from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { WidgetPredefinedLayoutView } from "../../types";
import { ReactComponent as LayoutBasicIcon } from "./icons/layout-basic.svg";
import { ReactComponent as LayoutBasicActiveIcon } from "./icons/layout-basic-active.svg";
import { ReactComponent as LayoutAdvanceIcon } from "./icons/layout-advance.svg";
import { ReactComponent as LayoutAdvanceActiveIcon } from "./icons/layout-advance-active.svg";

type IconProps = React.SVGProps<SVGSVGElement> & { type: WidgetPredefinedLayoutView };

const DefaultIcon = ({ type, ...props }: IconProps) =>
  type === WidgetPredefinedLayoutView.BASIC ? <LayoutBasicIcon {...props} /> : <LayoutAdvanceIcon {...props} />;

const ActiveIcon = ({ type, ...props }: IconProps) =>
  type === WidgetPredefinedLayoutView.BASIC ? (
    <LayoutBasicActiveIcon {...props} />
  ) : (
    <LayoutAdvanceActiveIcon {...props} />
  );

export const WIDTH = 80;

export const LayoutButton = ({
  type,
  title,
  description,
  onClick,
  onDelete,
  onEdit,
  active,
  width,
}: {
  type: WidgetPredefinedLayoutView;
  title: string;
  description?: string;
  onClick: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  active?: boolean;
  width?: number;
}) => {
  const [hover, setHover] = React.useState(false);
  const buttonWidth = width || WIDTH;

  return (
    <Stack
      sx={{ cursor: "pointer", position: "relative", width: buttonWidth }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      position="relative"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      {hover && onDelete && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          sx={{ position: "absolute", right: 4, top: 4, background: "none !important", padding: 0, zIndex: 2 }}
        >
          <ClearIcon sx={{ fontSize: 14, opacity: 0.5, "&:hover": { opacity: 1 } }} />
        </IconButton>
      )}
      {hover && onEdit && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          sx={{ position: "absolute", left: 4, top: 4, background: "none !important", padding: 0, zIndex: 2 }}
        >
          <EditOutlinedIcon sx={{ fontSize: 14, opacity: 0.5, "&:hover": { opacity: 1 } }} />
        </IconButton>
      )}

      {active && (
        <CheckCircleRoundedIcon sx={{ color: "#2C72E3", position: "absolute", right: 2, top: 30, fontSize: 14 }} />
      )}
      {active || hover ? (
        <ActiveIcon type={type} width={buttonWidth} />
      ) : (
        <DefaultIcon type={type} width={buttonWidth} />
      )}
      {title && (
        <Box sx={{ width: buttonWidth }} overflow="hidden" mt={0.5}>
          <Typography fontSize={14} variant="body2" fontWeight="bold" textAlign="center">
            {title}
          </Typography>
        </Box>
      )}
      {description && (
        <Stack alignItems="center">
          <Typography variant="caption" color={(theme) => theme.palette.text.secondary} textAlign="center">
            {description}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
