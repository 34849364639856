import React from "react";
import { Alert, AlertProps, Typography } from "@mui/material";

export const FeeWalletWarning = (props: AlertProps) => (
  <Alert severity="warning" {...props}>
    <Typography paragraph>
      REMINDER! You are adding credits to pay for fees on the Anboto Platform. You are NOT uploading your funds onto
      Anboto!
    </Typography>
    <Typography paragraph>
      Anboto is purely an execution platform that doesn’t have any custody over your funds. Your funds remain safe on
      your exchange.
    </Typography>
  </Alert>
);
