import React, { useEffect, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { useTestnetEnabled } from "@src/hooks/use-testnet-enabled";

export const HideInTestnet = ({ children }: PropsWithChildren<{}>) => {
  const isTestnet = useTestnetEnabled();
  const navigate = useNavigate();

  useEffect(() => {
    if (isTestnet) navigate("/");
  }, [isTestnet]);

  return isTestnet ? null : <>{children}</>;
};
