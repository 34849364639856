import React from "react";
import { styled } from "@mui/material";
import { useOrderbookContext } from "../hooks/use-orderbook-context";
import { OrdersType } from "../types";
import { ROW_HEIGHT } from "../constants";

export const Indicator = React.memo(({ amount, type }: { amount: number; type: OrdersType }) => {
  const { totalAmount } = useOrderbookContext();
  let shift = (amount * 100) / totalAmount;

  shift = shift > 100 ? 100 : shift;

  return <StyledIndicator type={type} style={{ transform: `translateX(-${shift}%)` }} />;
});

Indicator.displayName = "Indicator";

const StyledIndicator = styled("div")<{ type: OrdersType; isHorizontal?: boolean }>(({ type }) => ({
  background: type === "ask" ? "#cf3844" : "#009687",
  height: ROW_HEIGHT - 1,
  opacity: 0.2,
  position: "absolute",
  left: "100%",
  top: 1,
  zIndex: 1,
  width: "100%",
}));
