import React from "react";
import DisableTwoFa from "@src/pages/settings/two-fa/disable-two-fa";
import EnableTwoFa, { EnableTwoFaProps } from "@src/pages/settings/two-fa/enable-two-fa";
import { useAuth } from "@src/features/auth/hooks/use-auth";

export type FieldValues = {
  two_fa_code: string;
};

export type TwoFaProps = {
  disableTurnOff?: boolean;
  headerSx?: EnableTwoFaProps["headerSx"];
  containerSX?: EnableTwoFaProps["containerSX"];
};

const TwoFa = ({ disableTurnOff, headerSx, containerSX }: TwoFaProps) => {
  const { mfa } = useAuth();

  return mfa.enabled ? (
    <DisableTwoFa disableTurnOff={disableTurnOff} headerSx={headerSx} containerSX={containerSX} />
  ) : (
    <EnableTwoFa headerSx={headerSx} containerSX={containerSX} />
  );
};

export { TwoFa };
