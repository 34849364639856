import React from "react";
import { Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { WithLoader } from "../with-loader";
import StatsCard, {
  InfoPanel,
} from "@src/components/post-trade-analysis/post-trade-analysis/components/stats-card/stats-card";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useAppSelector } from "@src/store/hooks";
import AnbotoFetchingError from "@src/components/anboto-fetching-error/anboto-fetching-error";
import DashboardDataCardHeader from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/dashboard-data-card-header";
import {
  DashboardDataCard,
  DashBoardTableCell,
  DashBoardTableHeaderCell,
  FieldLabel,
  FieldValue,
} from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/styled-components";
import { formatUsd } from "@src/utils/format";
import AnbotoInfoIcon from "@src/components/anboto-info-icon/anboto-info-icon";
import { formatBps } from "@src/components/post-trade-analysis/helpers";
import GreenLabel from "@src/components/post-trade-analysis/post-trade-analysis/components/green-label/green-label";

type PostTradeStatsProps = {
  start: number;
  end: number;
};

const EMPTY_TRADES_LIST_ERROR_MESSAGE = "Could not find post trade analysis for account";

const PostTradeStats = ({ start, end }: PostTradeStatsProps) => {
  const user_uuid = useAppSelector((state) => state.user.team_uuid);
  const filters = useAppSelector((state) => state.metrics.tradesMetricsFilters);

  const { data, isFetching, isError, error } = anbotoApi.useGetPostTradeAnalysisMetricsDataQuery(
    {
      start,
      end,
      account: user_uuid,
      ...(filters.assetClasses.length ? { strategies: filters.strategies } : {}),
      ...(filters.assetClasses.length ? { spot_or_future: filters.assetClasses } : {}),
      ...(filters.exchanges.length ? { exchanges: filters.exchanges } : {}),
      ...(filters.sides.length ? { sides: filters.sides } : {}),
    },
    {
      skip:
        !filters.strategies.length ||
        !filters.assetClasses.length ||
        !filters.exchanges.length ||
        !filters.exchanges.length ||
        !filters.sides.length ||
        start > end,
    }
  );
  const isEmptyTradesList =
    error && (error as { data: { error: string } })?.data?.error?.startsWith(EMPTY_TRADES_LIST_ERROR_MESSAGE);

  const postTradeData = data?.data?.result;

  const rows = [
    {
      description: "Exchange Fee Cost",
      taker: formatUsd(postTradeData?.fees?.taker_usd || 0),
      maker: formatUsd(postTradeData?.fees?.maker_usd || 0),
      total: formatUsd(postTradeData?.fees?.total_usd || 0),
    },
    {
      description: "% of taker/maker trades",
      taker: (((postTradeData?.fees?.taker_usd || 0) / (postTradeData?.fees?.total_usd || 1)) * 100).toFixed(2) + "%",
      maker: (((postTradeData?.fees?.maker_usd || 0) / (postTradeData?.fees?.total_usd || 1)) * 100).toFixed(2) + "%",
      total: "100%",
    },
  ];

  const slippageRows = [
    {
      type: "Arrival",
      slippage: postTradeData?.slippages?.arrival_average || 0,
      title: 'Mid Price (simple average of best bid and best ask) at the order start ("arrival") time',
    },
    {
      type: "TWAP",
      slippage: postTradeData?.slippages?.twap_average || 0,
      title:
        "Time Weighted Average Price (TWAP)  is the simple average of  trade prices observed n the market during the period between order's Start Time and End Time",
    },
    {
      type: "VWAP",
      slippage: postTradeData?.slippages?.vwap_average || 0,
      title:
        "Volume Weighted Average Price (VWAP)  is the volume-weighted average of trade prices observed n the market during the period between order's Start Time and End Time",
    },
    {
      type: "Impacted price average",
      slippage: postTradeData?.slippages?.impacted_price_average || 0,
      title: "Impacted price average",
    },
  ];

  return (
    <>
      {isError ? (
        <AnbotoFetchingError
          hideIcon={isEmptyTradesList}
          errorText={isEmptyTradesList ? (error as { data: { error: string } })?.data?.error : undefined}
        />
      ) : (
        <Stack gap={1}>
          <Stack direction="row" gap={1} sx={{ minHeight: 40 }}>
            <InfoPanel>
              <FieldLabel>Total notional:</FieldLabel>
              <FieldValue variant="caption">
                <WithLoader loading={isFetching}>{formatUsd(postTradeData?.total_notional || 0)}</WithLoader>
              </FieldValue>
            </InfoPanel>
            <InfoPanel>
              <FieldLabel>Parent order count:</FieldLabel>
              <FieldValue variant="caption">
                <WithLoader loading={isFetching}>{postTradeData?.number_of_parents || "-"}</WithLoader>
              </FieldValue>
            </InfoPanel>
            <InfoPanel>
              <FieldLabel>Average order size:</FieldLabel>
              <FieldValue variant="caption">
                <WithLoader loading={isFetching}>
                  {formatUsd((postTradeData?.total_notional || 0) / (postTradeData?.number_of_parents || 1))}
                </WithLoader>
              </FieldValue>
            </InfoPanel>
          </Stack>

          <Stack direction="row" gap={1}>
            {(postTradeData?.fees || isFetching) && (
              <DashboardDataCard flex={1}>
                <DashboardDataCardHeader
                  header="Fees"
                  title="Trading fees paid the the exchange, Total + broken down into liquidity maker and liquidity taker."
                />
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <DashBoardTableHeaderCell align="left"></DashBoardTableHeaderCell>
                        <DashBoardTableHeaderCell align="left">Taker</DashBoardTableHeaderCell>
                        <DashBoardTableHeaderCell align="center">Maker</DashBoardTableHeaderCell>
                        <DashBoardTableHeaderCell align="right">Total</DashBoardTableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.total} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <DashBoardTableCell align="left">{row.description}</DashBoardTableCell>
                          <DashBoardTableCell align="left">
                            <WithLoader loading={isFetching}>{row.taker}</WithLoader>
                          </DashBoardTableCell>
                          <DashBoardTableCell align="center">
                            <WithLoader loading={isFetching}>{row.maker}</WithLoader>
                          </DashBoardTableCell>
                          <DashBoardTableCell align="right">
                            <WithLoader loading={isFetching}>{row.total}</WithLoader>
                          </DashBoardTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DashboardDataCard>
            )}
            {(postTradeData?.cost_of_repricing || isFetching) && (
              <DashboardDataCard flex={1}>
                <DashboardDataCardHeader
                  header="Savings"
                  title="This section provides provides information on fee savings (and opportunitiy costs) achieved by the passive executions (i.e. not crossing the spread) of the order"
                />
                <Stack direction="column" gap={1}>
                  <Stack direction="row" gap={2}>
                    <Stack flex={1.1} direction="column">
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        <FieldLabel>Maker Taker Savings</FieldLabel>
                        <AnbotoInfoIcon
                          iconSize={16}
                          title={
                            "Savings in exchange fees from executing as a liquidity maker.  Calculated as (Taker Fee - Maker Fee ) x Percent of Order Executed Passively (i.e. as a Liquidity Maker)"
                          }
                        />
                      </Stack>

                      <FieldValue>
                        <WithLoader loading={isFetching}>
                          {formatBps(postTradeData?.cost_of_repricing?.maker_taker_savings_average || 0)}{" "}
                        </WithLoader>
                      </FieldValue>
                    </Stack>
                    <Stack flex={0.9} direction="column">
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        <FieldLabel>Spread Savings</FieldLabel>
                        <AnbotoInfoIcon
                          iconSize={16}
                          title={
                            "Bid-Ask Spread savings. Calculated as Bid-Ask-Spread (in basis points) times Percent of Order Executed Passively (i.e. as a Liquidity Maker)"
                          }
                        />
                      </Stack>
                      <FieldValue>
                        <WithLoader loading={isFetching}>
                          {formatBps(postTradeData?.cost_of_repricing?.spread_savings_average || 0)}
                        </WithLoader>
                      </FieldValue>
                    </Stack>
                    <Stack direction="row" gap={2} flexWrap="wrap">
                      <Stack flex={1} direction="column">
                        <Stack direction="row" alignItems="center" gap={0.5}>
                          <FieldLabel>NET savings</FieldLabel>
                          <AnbotoInfoIcon
                            iconSize={16}
                            title={
                              "Overall (Net) savings from the passive executions  = 'Maker Taker Savings' + 'Spread Savings' - 'Cost of Repricing'"
                            }
                          />
                        </Stack>
                        <FieldValue>
                          <WithLoader loading={isFetching}>
                            {formatBps(postTradeData?.cost_of_repricing?.net_savings_average || 0)}
                          </WithLoader>
                        </FieldValue>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </DashboardDataCard>
            )}
          </Stack>
          <Stack gap={1} direction="row">
            <Stack flex={1}>
              {(postTradeData?.slippages || isFetching) && (
                <DashboardDataCard flex={1}>
                  <DashboardDataCardHeader
                    header="Slippage and price"
                    title="This section provides key benchmark prices for the algo order, and slippage against them (+ve slippage = outperformed, -ve slippage = underperformed)"
                  />

                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <DashBoardTableHeaderCell>Benchmark</DashBoardTableHeaderCell>
                          <DashBoardTableHeaderCell align="right">Slippages</DashBoardTableHeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {slippageRows.map((row) => (
                          <TableRow key={row.type} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <DashBoardTableCell>
                              <Stack direction="row" gap={0.5} alignItems="center">
                                <Typography fontSize={14}>{row.type}</Typography>
                                <AnbotoInfoIcon iconSize={16} title={row.title} />
                              </Stack>
                            </DashBoardTableCell>
                            <DashBoardTableCell align="right">
                              <WithLoader loading={isFetching}>
                                <GreenLabel value={row?.slippage || 0} />
                              </WithLoader>
                            </DashBoardTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DashboardDataCard>
              )}
            </Stack>
            <Stack flex={1}>
              <StatsCard
                loading={isFetching}
                spreadCapture={postTradeData?.percent_passive?.pct_passive_notional_average || 0}
                reversion={postTradeData?.reversion_5min_bps_average || 0}
                volumeParticipation={postTradeData?.volume_participation_average || 0}
              />
            </Stack>
          </Stack>

          <DashboardDataCard flex={1}>
            <DashboardDataCardHeader header="Bid ask spread" title="Bid ask spread" />
            <Stack direction="column" gap={1}>
              <Stack direction="row" gap={2}>
                <Stack flex={1.1} direction="column">
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <FieldLabel>Arrival vs spread</FieldLabel>
                    <AnbotoInfoIcon iconSize={16} title="Arrival vs spread" />
                  </Stack>

                  <FieldValue>
                    <WithLoader loading={isFetching}>
                      {formatBps(postTradeData?.bid_ask_spread?.arrival_vs_spread || 0)}
                    </WithLoader>
                  </FieldValue>
                </Stack>
                <Stack flex={1.1} direction="column">
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <FieldLabel>TWAP vs spread</FieldLabel>
                    <AnbotoInfoIcon iconSize={16} title="TWAP vs spread" />
                  </Stack>

                  <FieldValue>
                    <WithLoader loading={isFetching}>
                      {formatBps(postTradeData?.bid_ask_spread?.twap_vs_spread || 0)}
                    </WithLoader>
                  </FieldValue>
                </Stack>
                <Stack flex={1.1} direction="column">
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <FieldLabel>Average</FieldLabel>
                    <AnbotoInfoIcon iconSize={16} title="Average" />
                  </Stack>

                  <FieldValue>
                    <WithLoader loading={isFetching}>
                      {formatBps(postTradeData?.bid_ask_spread?.average || 0)}
                    </WithLoader>
                  </FieldValue>
                </Stack>
              </Stack>
            </Stack>
          </DashboardDataCard>
        </Stack>
      )}
    </>
  );
};

export default PostTradeStats;
