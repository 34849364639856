import { combineReducers } from "@reduxjs/toolkit";
import { cryptocompareApi } from "./apis/cryptocompareApi";
import userReducer from "@src/store/reducers/user.reducer";
import uiSettingsReducer from "@src/store/slices/uiSettingsSlice";
import watchlistReducer from "@src/store/slices/watchlistSlice";
import symbolSelectReducer from "@src/components/symbol-select/symbol-select.slice";
import portfolioReducer from "@src/store/slices/portfolioSlice";
import metricsReducer from "@src/store/slices/metricsSlice";
import feeManagerReducer from "@src/store/slices/feeManagerSlice";
import onboardingReducer from "@src/pages/auth/onboarding.slice";
import subscriptionsReducer from "@src/store/slices/subscriptionsSlice";
import singleExchangePageReducer from "@src/store/slices/singleExchangePageSlice";
import decentralizedExchangePageReducer from "@src/store/slices/decentralizedExchangePageSlice";
import widgetsReducer from "@src/features/widgets-layout/store/widgets.slice";
import referralProgramReducer from "@src/store/slices/referralProgramSlice";
import { debankApi } from "@src/store/apis/debankApi";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { algoliaApi } from "@src/store/apis/algoliaApi";
import { zeroexApi } from "@src/store/apis/zeroexApi";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { blockchainSlice } from "@src/store/slices/blockchainSlice";
import { coingeckoApi } from "@src/store/apis/coingeckoApi";
import { tokensSlice } from "@src/store/slices/tokensSlice";
import { geckoTerminalApi } from "@src/store/apis/geckoterminal-api";
import ordersTableReducer from "@src/pages/cefi/orders-table/orders-table.slice";
import appStateReducer from "@src/store/slices/app-state.slice";
import adminReducer from "@src/features/admin/admin.slice";
import leverageReducer from "@src/features/leverage/leverage.slice";

export const reducer = combineReducers({
  appState: appStateReducer,
  user: userReducer,
  widgets: widgetsReducer,
  uiSettings: uiSettingsReducer,
  subscriptions: subscriptionsReducer,
  singleExchangePage: singleExchangePageReducer,
  decentralizedExchangePage: decentralizedExchangePageReducer,
  watchlist: watchlistReducer,
  symbolSelect: symbolSelectReducer,
  portfolio: portfolioReducer,
  metrics: metricsReducer,
  feeManager: feeManagerReducer,
  referralProgram: referralProgramReducer,
  onboarding: onboardingReducer,
  ordersTable: ordersTableReducer,
  leverage: leverageReducer,
  [algoliaApi.reducerPath]: algoliaApi.reducer,
  [debankApi.reducerPath]: debankApi.reducer,
  [anbotoApi.reducerPath]: anbotoApi.reducer,
  [zeroexApi.reducerPath]: zeroexApi.reducer,
  [blockchainApi.reducerPath]: blockchainApi.reducer,
  [coingeckoApi.reducerPath]: coingeckoApi.reducer,
  [geckoTerminalApi.reducerPath]: geckoTerminalApi.reducer,
  [blockchainSlice.name]: blockchainSlice.reducer,
  [tokensSlice.name]: tokensSlice.reducer,
  [cryptocompareApi.reducerPath]: cryptocompareApi.reducer,
  support: adminReducer,
});
