import { PlacementCancelValue, PlacementMode, PlacementValue } from "@src/store/apis/anbotoApi/types";
import { useParentOrderFormContext } from "@src/pages/cefi/order-form/hooks/use-parent-order-form-context";
import { useCefiOrderFormDefaultValues } from "@src/pages/cefi/order-form/hooks/use-cefi-order-form-default-values";

export const usePlacementModeValue = () => {
  const orderPreferences = useCefiOrderFormDefaultValues();
  const orderForm = useParentOrderFormContext();

  const changePlacementMode = (placementMode?: PlacementMode) => {
    if (!placementMode) return;
    orderForm.setValue("placementMode", placementMode);
    if (placementMode === PlacementMode.DEFAULT) {
      orderForm.setValue("placement", PlacementValue.DEFAULT);
      orderForm.setValue("placementCancel", PlacementCancelValue.DEFAULT);
    } else if (placementMode === PlacementMode.TIGHT) {
      orderForm.setValue("placement", PlacementValue.TIGHT);
      orderForm.setValue("placementCancel", PlacementCancelValue.TIGHT);
    } else {
      orderForm.setValue("placement", orderPreferences.placement || "");
      orderForm.setValue("placementCancel", orderPreferences.placementCancel || "");
    }
  };

  return changePlacementMode;
};
