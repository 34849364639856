import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { SignInOutput, SignUpOutput } from "aws-amplify/auth";
import { SetupOtp } from "../login/setup-otp";
import { VerifyOtp } from "../login/verify-otp";
import { ConfirmEmail } from "./components/confirm-email";
import { Layout } from "./components/layout";
import AccountType from "@src/pages/auth/registration/components/account-type";
import AccountActivated from "@src/pages/auth/registration/components/account-activated";
import {
  AccountChoiceTypes,
  AccountTypes,
  OnboardingSteps,
  setIsAccountTypeChoiceAvailable,
  setInitialAccountTypeToStore,
  setCurrentOnboardingStep,
  setSelectedAccountType,
} from "@src/pages/auth/onboarding.slice";
import { VerificationPassword } from "@src/pages/auth/registration/components/verification-password";
import { VerificationInfos } from "@src/pages/auth/registration/components/verification-infos";
import { VerificationTraderType } from "@src/pages/auth/registration/components/verification-trader-type";
import { VerificationCode } from "@src/pages/auth/registration";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { CreateAccountParams, TraderType } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { useAuth } from "@src/features/auth/hooks/use-auth";
import { useOtpQr } from "@src/features/auth/hooks/use-otp-qr";

export enum SignUpStep {
  CODE = "Activation code",
  ACCOUNT_TYPE = "Account activate",
  PASSWORD = "Setup Password",
  PROFILE = "Profile informations",
  TRADER_INFO = "Trader info",
  CONFIRM_EMAIL = "Confirm email",
  ACCOUNT_ACTIVATED = "Account activated",
  TWO_FACTOR_SETTINGS = "Two factor settings",
  TWO_FACTOR_AUTH_CODE = "Two factor auth code",
}

export enum InviteSource {
  TEAM = "1",
  ADMIN = "0",
}

type StepKey =
  | "signUp"
  | "signIn"
  | "confirmEmail"
  | "createAnbotoAccount"
  | "createTeamAccount"
  | "updateCompanyInfo"
  | "otp";

export const Registration = () => {
  const snackbar = useSnackbar();
  const dispatch = useAppDispatch();
  const { signIn, signUp, authenticated, confirmSignIn, confirmSignUp } = useAuth();

  const [createAccount] = anbotoApi.useCreateAccountMutation();
  const [acceptInvite] = anbotoApi.useAddParticipantMutation();

  const [searchParams] = useSearchParams();
  const inviteEmail = searchParams.get("email") || "";
  const referralCode = searchParams.get("referral_code") || "";
  // 0 - invite from admin panel, 1 - invite from team account
  const inviteSource = searchParams.get("is_account") || "0";
  // if invite is from team account we need invite code additionally to verify the user
  const inviteCode = searchParams.get("code") || "";

  const accountType = useAppSelector((state) => state.onboarding.accountType);
  const selectedAccountType = useAppSelector((state) => state.onboarding.selectedAccountType);

  const [step, setStep] = useState<SignUpStep>(SignUpStep.CODE);
  const [accountName, setAccountName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [traderType, setTraderType] = useState<TraderType>();
  const [currentEmail, setCurrentEmail] = useState<string>(inviteEmail);

  const [errors, setErrorsState] = useState<Record<StepKey, string>>({} as Record<StepKey, string>);
  const [loading, setLoadingState] = useState<Record<StepKey, boolean>>({} as Record<StepKey, boolean>);
  const { uri: otpUri } = useOtpQr();

  const navigate = useNavigate();

  const inviteIsFromTeamAccount = inviteSource === InviteSource.TEAM;

  const isIndividualAccount =
    accountType === AccountTypes.INDIVIDUAL && selectedAccountType === AccountTypes.INDIVIDUAL;

  const setError = (key: StepKey, value: string) => setErrorsState((prev) => ({ ...prev, [key]: value }));
  const setLoading = (key: StepKey, value: boolean) => setLoadingState((prev) => ({ ...prev, [key]: value }));

  const handleSignUpNextStep = (output: SignUpOutput) => {
    console.log({ output });

    switch (output.nextStep.signUpStep) {
      case "CONFIRM_SIGN_UP":
        return setStep(SignUpStep.CONFIRM_EMAIL);
      case "DONE":
        return signInUser();
    }
  };

  const handleSignInNextStep = (output: SignInOutput) => {
    console.log({ output });

    switch (output.nextStep.signInStep) {
      case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
        return setStep(SignUpStep.TWO_FACTOR_SETTINGS);

      case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
        return setStep(SignUpStep.TWO_FACTOR_AUTH_CODE);
      case "DONE":
        return setStep(SignUpStep.ACCOUNT_ACTIVATED);
    }
  };

  const onVerifyRefCode = async (accountChoiceEnabled: boolean) => {
    if (accountChoiceEnabled) {
      dispatch(setIsAccountTypeChoiceAvailable(AccountChoiceTypes.ENABLED));

      setStep(SignUpStep.ACCOUNT_TYPE);
    } else {
      dispatch(setIsAccountTypeChoiceAvailable(AccountChoiceTypes.DISABLED));
      dispatch(setSelectedAccountType(AccountTypes.INDIVIDUAL));

      setStep(SignUpStep.TRADER_INFO);
    }
  };

  const onVerifyInvite = async (verifyResult: { accountName?: string; isRegistered?: boolean }) => {
    if (verifyResult.isRegistered) {
      if (!authenticated) {
        // show the user that code is ok
        snackbar.enqueueSnackbar("Valid activation code. Please log in to continue the invite acceptance process.", {
          variant: "success",
        });

        return navigate(`/login?team_invite_code=${inviteCode}&email=${inviteEmail}`);
      }

      try {
        // adding new participant to the team
        await acceptInvite({
          user_email: inviteEmail,
          invite_code: inviteCode,
        }).unwrap();

        // go to settings to show user that he has new team
        return navigate("/settings/account");
      } catch (err) {
        return snackbar.enqueueSnackbar(parseAnbotoRequestError(err), {
          variant: "error",
        });
      }
    }

    if (verifyResult.accountName) setAccountName(verifyResult.accountName);

    if (inviteIsFromTeamAccount) {
      // if invitation is from the team - he just needs to fill login / password
      setStep(SignUpStep.TRADER_INFO);
    } else {
      // if invitation is from admin panel - send user to fill all the data
      setStep(SignUpStep.ACCOUNT_TYPE);
    }
  };

  const signInUser = async () => {
    setLoading("signIn", true);

    try {
      const output = await signIn({ username: currentEmail, password });

      setLoading("signIn", false);

      handleSignInNextStep(output);
    } catch (error) {
      setLoading("signIn", false);
      setError("signIn", error.message);

      snackbar.enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const register = async (email: string, password: string) => {
    setLoading("signUp", true);
    setCurrentEmail(email);
    setPassword(password);

    try {
      const output = await signUp({
        username: email,
        password: password,
      });

      setLoading("signUp", false);

      handleSignUpNextStep(output);
    } catch (error) {
      setLoading("signUp", false);

      snackbar.enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const onActivatedClick = () => navigate(inviteIsFromTeamAccount ? "/settings/account" : "/two-factor-auth");

  const createAnbotoAccount = async (email: string, password: string, accountInfo?: Partial<CreateAccountParams>) => {
    setLoading("createAnbotoAccount", true);

    const body: CreateAccountParams = {
      email,
      invitational_email: inviteEmail || email || "",
      password1: password,
      password2: password,
      is_account: +inviteSource,
      is_individual_account: isIndividualAccount,
      trader_type: traderType,
      ...accountInfo,
    };

    if (referralCode) body.referral_code = referralCode;
    if (inviteCode) body.code = inviteCode;

    try {
      await createAccount(body).unwrap();

      setLoading("createAnbotoAccount", false);

      await register(email, password);
    } catch (error) {
      console.log(error);
      setLoading("createAnbotoAccount", false);
      setError("createAnbotoAccount", parseAnbotoRequestError(error?.data));

      snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data), { variant: "error" });
    }
  };

  const onPasswordSubmit = (email: string, password: string) => {
    setCurrentEmail(email);
    setPassword(password);

    if (isIndividualAccount) {
      return createAnbotoAccount(email, password);
    }

    dispatch(setCurrentOnboardingStep(OnboardingSteps.PROFILE_INFORMATION));

    setStep(SignUpStep.PROFILE);
  };

  const onCompanyInfoSubmit = (accountInfo: Partial<CreateAccountParams>) => {
    setLoading("updateCompanyInfo", true);

    createAnbotoAccount(accountInfo.email || currentEmail || "", password, accountInfo);
  };

  const onConfirmEmailSubmit = async (code: string) => {
    setLoading("confirmEmail", true);

    try {
      const output = await confirmSignUp({
        username: currentEmail,
        confirmationCode: code,
      });

      console.log("confirmSignUp", output);
      setLoading("confirmEmail", false);

      handleSignUpNextStep(output);
    } catch (error) {
      console.log(error.message);
      setLoading("confirmEmail", false);
      setError("confirmEmail", error.message);
    }
  };

  const onOtpCodeSubmit = async (code: string) => {
    try {
      setLoading("otp", true);

      const output = await confirmSignIn({
        challengeResponse: code,
      });

      setLoading("otp", false);

      handleSignInNextStep(output);
    } catch (error) {
      setLoading("otp", false);

      setError("otp", error.message);
    }
  };

  useEffect(() => {
    dispatch(setCurrentOnboardingStep(OnboardingSteps.ACCOUNT_ACTIVATE));
    dispatch(
      setInitialAccountTypeToStore(inviteSource === InviteSource.TEAM ? AccountTypes.TEAM : AccountTypes.INDIVIDUAL)
    );
  }, []);

  return (
    <Layout>
      {step === SignUpStep.CODE && (
        <VerificationCode
          referralCode={referralCode}
          inviteCode={inviteCode}
          inviteEmail={inviteEmail}
          onVerifyRefCode={onVerifyRefCode}
          onVerifyInvite={onVerifyInvite}
          inviteIsFromTeamAccount={inviteIsFromTeamAccount}
        />
      )}
      {step === SignUpStep.ACCOUNT_TYPE && <AccountType onSubmit={() => setStep(SignUpStep.TRADER_INFO)} />}
      {step === SignUpStep.TRADER_INFO && (
        <VerificationTraderType
          onBack={() => setStep(SignUpStep.ACCOUNT_TYPE)}
          onChange={setTraderType}
          onNext={() => traderType && setStep(SignUpStep.PASSWORD)}
          traderType={traderType}
        />
      )}
      {step === SignUpStep.PASSWORD && (
        <VerificationPassword
          loading={loading.createAnbotoAccount || loading.signUp}
          emailURL={inviteEmail}
          setStep={setStep}
          defaultValues={{
            password,
            currentEmail,
          }}
          onSubmit={onPasswordSubmit}
        />
      )}
      {step === SignUpStep.PROFILE && (
        <VerificationInfos
          onBack={() => setStep(SignUpStep.PASSWORD)}
          onNext={(info) => onCompanyInfoSubmit(info)}
          accountName={accountName}
          currentEmail={currentEmail}
          loading={loading.createAnbotoAccount || loading.signUp}
        />
      )}
      {step === SignUpStep.CONFIRM_EMAIL && (
        <ConfirmEmail
          onSubmit={(code) => onConfirmEmailSubmit(code)}
          loading={loading.confirmEmail || loading.signIn}
          error={errors.confirmEmail}
        />
      )}
      {step === SignUpStep.TWO_FACTOR_SETTINGS && (
        <SetupOtp onSubmit={onOtpCodeSubmit} uri={otpUri} loading={loading.otp || loading.signIn} error={errors.otp} />
      )}
      {step === SignUpStep.TWO_FACTOR_AUTH_CODE && (
        <VerifyOtp onSubmit={onOtpCodeSubmit} loading={loading.otp || loading.signIn} error={errors.otp} />
      )}
      {step === SignUpStep.ACCOUNT_ACTIVATED && (
        <AccountActivated accountName={accountName} onNextClick={() => onActivatedClick()} />
      )}
    </Layout>
  );
};
