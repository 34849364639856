import React from "react";
import { CircularProgress, styled, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { AnbotoIconButton } from "@src/components";
import { AnbotoButton, AnbotoButtonProps } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { fileDownload } from "@src/utils/file";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { useAppSelector } from "@src/store/hooks";
import { RootState } from "@src/store/types";

const DEFAULT_COMPACT_ICON_WIDTH = 18;

type ExportParentOrdersButtonProps = {
  compact?: boolean;
} & AnbotoButtonProps;
const ExportParentOrdersButton = ({ compact }: ExportParentOrdersButtonProps) => {
  const snackbar = useSnackbar();
  const account_uuid = useAppSelector((state: RootState) => state.user.team_uuid)!;

  const [exportAllParentOrders, exportAllParentOrdersMutation] = anbotoApi.useExportAllParentOrdersMutation();

  const handleExport = async () => {
    try {
      const data = await exportAllParentOrders({ account_uuid }).unwrap();
      fileDownload(data, `All_orders_${DateTime.now().toFormat("MMM_d_yyyy_HH:mm")}.csv`);
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data?.error), { variant: "error" });
    }
  };

  if (compact)
    return (
      <Tooltip title={"Export all parent orders"}>
        <AnbotoIconButton disabled={exportAllParentOrdersMutation.isLoading} onClick={handleExport} size="small">
          {exportAllParentOrdersMutation.isLoading ? (
            <CircularProgress size={DEFAULT_COMPACT_ICON_WIDTH} color="inherit" />
          ) : (
            <SaveAltIcon
              htmlColor="text.secondary"
              sx={{ width: DEFAULT_COMPACT_ICON_WIDTH, height: DEFAULT_COMPACT_ICON_WIDTH }}
            />
          )}
        </AnbotoIconButton>
      </Tooltip>
    );

  return (
    <AnbotoButton
      size="small"
      variant="outlined"
      color="secondary"
      endIcon={<SaveAltIcon htmlColor="text.secondary" />}
      loadingPosition="end"
      loading={exportAllParentOrdersMutation.isLoading}
      onClick={handleExport}
      sx={{ paddingY: (theme) => theme.spacing(0.5) }}
    >
      <ExportButtonLabel>Export all parent orders</ExportButtonLabel>
    </AnbotoButton>
  );
};

const ExportButtonLabel = styled("span")({
  marginRight: 10,
});

export default ExportParentOrdersButton;
