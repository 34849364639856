import { useEffect } from "react";
import { geckoTerminalApi } from "@src/store/apis/geckoterminal-api";
import { EVMBasedAddress } from "@src/pages/defi/types";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks/use-defi-order-form-context";
import { useTokenInfo } from "@src/pages/defi/hooks/use-token-info";
import { isStableCoin } from "@src/pages/portfolio/utils/helpers";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { GECKOTERMINAL_CHAIN_IDS } from "@src/store/apis/geckoterminal-api/constants";
import { setTokenInfoPool } from "@src/store/slices/decentralizedExchangePageSlice";

export const usePoolInfo = () => {
  const dispatch = useAppDispatch();
  const orderForm = useDefiOrderFormContext();

  const chainId = orderForm.watch("chainId");
  const fromTokenAddress = orderForm.watch("fromTokenAddress");
  const toTokenAddress = orderForm.watch("toTokenAddress");

  const formFromToken = useTokenInfo({ chainId, address: fromTokenAddress as EVMBasedAddress });
  const formToToken = useTokenInfo({ chainId, address: toTokenAddress as EVMBasedAddress });

  const { data, isFetching, isLoading } = geckoTerminalApi.useGetBiggestTokenPairPoolQuery(
    {
      chainId,
      fromTokenAddress,
      toTokenAddress,
    },
    { skip: !chainId }
  );

  const formPool = data?.data;
  const formPoolObjectString = JSON.stringify(formPool);

  useEffect(() => {
    dispatch(setTokenInfoPool(formPool));
  }, [formPoolObjectString]);

  const tokenInfoPool = useAppSelector((state) => state.decentralizedExchangePage.tokenInfoPool);

  const geckoTerminalChainId = GECKOTERMINAL_CHAIN_IDS[chainId];
  const pairInfoFromTokenAddress =
    tokenInfoPool?.relationships?.base_token?.data?.id?.replace(geckoTerminalChainId + "_", "") || "";
  const pairInfoToTokenAddress =
    tokenInfoPool?.relationships?.quote_token?.data?.id?.replace(geckoTerminalChainId + "_", "") || "";

  const pairInfoFromToken = useTokenInfo({ chainId, address: pairInfoFromTokenAddress });
  const pairInfoToToken = useTokenInfo({ chainId, address: pairInfoToTokenAddress });

  const noPool = !formPool;
  let baseTokenAddress = "";

  if (noPool || !formPool?.baseTokenAddress) {
    if (!formFromToken?.symbol || !formToToken?.symbol) {
      baseTokenAddress = fromTokenAddress;
    } else {
      const isFromTokenIsStableCoin = isStableCoin(formFromToken.symbol);
      const isToTokenSymbolIsStableCoin = isStableCoin(formToToken.symbol);

      // when one of coins is stable we need to make another one base token
      if (isFromTokenIsStableCoin) {
        baseTokenAddress = toTokenAddress;
      } else if (isToTokenSymbolIsStableCoin) {
        baseTokenAddress = fromTokenAddress;
      } else {
        baseTokenAddress = fromTokenAddress;
      }
    }
  } else if (formPool?.isNeedToRevert) {
    baseTokenAddress = formPool?.quoteTokenAddress;
  } else {
    baseTokenAddress = formPool?.baseTokenAddress;
  }

  useEffect(() => {
    orderForm.setValue("baseTokenAddress", baseTokenAddress);
  }, [baseTokenAddress]);

  return {
    formPool,
    tokenInfoPool,
    baseTokenAddress,
    isFetching,
    isLoading,
    formFromToken,
    formToToken,
    pairInfoFromToken,
    pairInfoToToken,
    chainId,
  };
};
