import React from "react";
import { Box, BoxProps, Stack, styled, Typography } from "@mui/material";
import { ExchangeIcon } from "../exchange-icon";
import { formatPrice } from "./helpers";
import { GetMultipleSymbolsFullDataItem } from "@src/store/apis/cryptocompareApi/types";
import { FavoriteSymbol } from "@src/store/apis/anbotoApi/types";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";
import { useAppSelector } from "@src/store/hooks";

export type WatchlistSymbolItemProps = {
  symbol: FavoriteSymbol;
  initialData?: GetMultipleSymbolsFullDataItem;
  onClick?: () => void;
  showPrice?: boolean;
  showChange24h?: boolean;
  showChange1h?: boolean;
  showChange1d?: boolean;
  showExchangeIcon?: boolean;
  showCoin?: boolean;
};

export const WatchlistSymbolItem = ({
  symbol,
  // initialData,
  onClick,
  showPrice,
  showChange24h,
  // showChange1h,
  // showChange1d,
  showExchangeIcon,
  showCoin,
  sx,
  ...props
}: WatchlistSymbolItemProps & BoxProps) => {
  const id = getLivePriceId(symbol);
  const tickerInfo = useAppSelector((state) => state.subscriptions.ticker[id]);
  const price = +tickerInfo?.price || "-";
  const change24h = tickerInfo?.percentage || "-";

  return (
    <Box sx={{ overflow: "hidden", flexShrink: 0, ...sx }} {...props}>
      <Stack gap={0.5} direction="row" alignItems="center" onClick={() => onClick && onClick()}>
        {showExchangeIcon && <ExchangeIcon symbol={symbol} size={14} />}
        {showCoin && <SymbolText noWrap>{getOrderSymbolLabel(symbol, ["symbol"])}</SymbolText>}
        {showPrice && price !== "-" ? <PriceText>{formatPrice(price)}</PriceText> : ""}
        {showChange24h && change24h !== "-" ? <ChangePct change={+change24h} /> : ""}
      </Stack>
    </Box>
  );
};

const ChangePct = ({ change }: { change: number }) => {
  const up = change >= 0;

  return (
    <Stack direction="row" alignItems="center" sx={{ minWidth: 50 }}>
      {<ChangePctText up={!!up}>{up ? "+" : "-"}</ChangePctText>}
      {<ChangePctText up={!!up}>{Math.abs(+change).toFixed(2)}%</ChangePctText>}
    </Stack>
  );
};

const SymbolText = styled(Typography)({
  textTransform: "uppercase",
  fontSize: 12,
  fontWeight: 500,
});

const PriceText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 12,
  fontWeight: 500,
}));

const ChangePctText = styled(Typography, {
  shouldForwardProp: (props) => props !== "up",
})<{ up?: boolean }>(({ theme, up }) => ({
  color: up ? theme.custom.colors.secondary : theme.palette.error.main,
  fontSize: 12,
  fontWeight: 500,
}));
