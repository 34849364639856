/* eslint-disable react/display-name */
import React, { useCallback, useEffect } from "react";
import { Row } from "@tanstack/table-core";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { usePostTradeCreditsCefi } from "../../order-form/hooks/use-post-trade-credits-cefi";
import { useParentOrdersTableSearch } from "../hooks/use-parent-orders-table-search";
import { getColumns, GetColumnsProps } from "../get-columns";
import { columnHelper } from "../column-helper";
import { setCurrentTab, setOrderLoading } from "../orders-table.slice";
import { isCancelable } from "../utils";
import { ActionButton } from "./action-button";
import { ExpandedRow } from "./expanded-row";
import { MemoizedOrdersTable } from "./table";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { CefiExchangeId, OrdersCurrentTab, ParentOrder } from "@src/store/apis/anbotoApi/types";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { ORDERS_LIMIT } from "@src/store/constants";
import PostTradeAnalysisButton from "@src/components/post-trade-analysis/post-trade-analysis-button";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";
import { usePermissions } from "@src/hooks/use-permissions";

const emptyArray: Array<ParentOrder> = [];

const loadingData = Array.from(Array(10)).fill({});

interface ParentOrdersTableProps {
  onSymbolClick?: (order: ParentOrder) => void;
  exchangeIds?: CefiExchangeId[];
  getSymbolLabel?: (order: ParentOrder) => string;
  excludeColumns?: GetColumnsProps["excludeColumns"];
}

export const ParentOrdersTable = React.memo(
  ({ onSymbolClick, exchangeIds, getSymbolLabel, excludeColumns }: ParentOrdersTableProps) => {
    const dispatch = useAppDispatch();
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const accountId = useAppSelector((state) => state.user.team_uuid);
    const { currentTab, period, status } = useAppSelector((state) => state.ordersTable.params);
    const searchString = useAppSelector((state) => state.ordersTable.search);
    const isHistory = currentTab === OrdersCurrentTab.History;
    const isSaved = currentTab === OrdersCurrentTab.SavedOrders;
    const isPositions = currentTab === OrdersCurrentTab.Positions;

    const getExchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi: false });
    const [stopCefiOrder] = anbotoApi.useCancelParentOrderMutation();
    const [deleteSavedOrder] = anbotoApi.useDeleteSavedOrderMutation();
    const [startSavedOrder] = anbotoApi.useStartSavedOrderMutation();
    const [loadOrders, commonOrders] = anbotoApi.useLazyGetParentCefiOrdersQuery();
    const [loadSavedOrders, savedOrders] = anbotoApi.useLazyGetParentCefiSavedOrdersQuery();
    const { fetchPostCredits } = usePostTradeCreditsCefi();
    const isPostTradeVisible = useAppSelector((state) => state.user.is_post_trade_visible);
    const permissions = usePermissions();

    const orders = currentTab === OrdersCurrentTab.SavedOrders ? savedOrders : commonOrders;

    const showOnlySelectedPairs = useAppSelector((state) => state.ordersTable.otherPairsHidden.value);
    const selectedPairs = useAppSelector((state) => state.ordersTable.otherPairsHidden.symbols);

    const refetch = () => {
      if (isPositions) return;

      if (currentTab === OrdersCurrentTab.SavedOrders) {
        return loadSavedOrders({ accountId, currentTab, period, status, limit: ORDERS_LIMIT });
      }

      return loadOrders({ accountId, currentTab, period, status, limit: ORDERS_LIMIT, exchangeIds });
    };

    const resultsData = orders.isSuccess ? orders?.data || emptyArray : emptyArray;

    const activeGroupsSymbolResults = resultsData.filter((order) =>
      selectedPairs.includes(
        getLivePriceId({
          symbol: order.symbol,
          exchange: order.exchange,
          market_type: order.asset_class,
        })
      )
    );

    const searchResults = useParentOrdersTableSearch(
      showOnlySelectedPairs ? activeGroupsSymbolResults : resultsData,
      searchString
    );

    const supportedExchanges = (React.useMemo(
      () =>
        getExchangesQuery.data?.results.reduce((res, { exchange_id, name }) => ({ ...res, [exchange_id]: name }), {}),
      [getExchangesQuery.data]
    ) || {}) as Record<CefiExchangeId, boolean>;

    const handleOrderCancel = async (orderId: string, accountId: string) => {
      try {
        dispatch(setOrderLoading({ id: orderId, value: true, actionType: "cancel" }));
        await stopCefiOrder({ accountId, orderId }).unwrap();
        snackbar.enqueueSnackbar("Order has been canceled successfully", { variant: "success" });
      } catch (err) {
        console.log(err);
        snackbar.enqueueSnackbar(parseAnbotoRequestError(err?.data), { variant: "error" });
      } finally {
        dispatch(setOrderLoading({ id: orderId, value: false, actionType: "cancel" }));
      }
    };

    const handleDeleteSavedOrder = async ({ account_uuid, order_id }: ParentOrder) => {
      try {
        dispatch(setOrderLoading({ id: order_id, value: true, actionType: "delete" }));
        await deleteSavedOrder({ account_uuid, order_id }).unwrap();
        snackbar.enqueueSnackbar("Order deleted", { variant: "success" });
      } catch (err) {
        console.log(err);
        snackbar.enqueueSnackbar(parseAnbotoRequestError(err?.data), { variant: "error" });
      } finally {
        dispatch(setOrderLoading({ id: order_id, value: false, actionType: "delete" }));
      }
    };

    const handleSymbolClick = useCallback((order: ParentOrder) => onSymbolClick && onSymbolClick(order), []);

    const handleStartSavedOrder = async ({
      account_uuid,
      order_id,
      symbol,
      exchange,
      asset_class,
      quantity,
      params,
    }: ParentOrder) => {
      try {
        dispatch(setOrderLoading({ id: order_id, value: true, actionType: "resume" }));
        // we first check if the client can pay before running the order

        const postTradeCredits = await fetchPostCredits({
          symbol,
          exchange,
          market_type: asset_class,
          quantity: quantity.toString(),
        });

        // if credits are missing we don't go any further, we invite the client to top up his fee wallet first
        if (postTradeCredits < 0) {
          dispatch(setOrderLoading({ id: order_id, value: false, actionType: "resume" }));

          return snackbar.enqueueSnackbar("Missing credits, please top up your fee wallet first", {
            variant: "warning",
            actionName: "Top Up",
            buttonAction: () => navigate("/settings/fees"),
          });
        }

        await startSavedOrder({ account_uuid, order_id }).unwrap();
        dispatch(setOrderLoading({ id: order_id, value: false, actionType: "resume" }));

        const value = params?.trigger?.trigger_price ? OrdersCurrentTab.Trigger : OrdersCurrentTab.Regular;

        dispatch(setCurrentTab({ value }));

        snackbar.enqueueSnackbar("Order started", { variant: "success" });
      } catch (err) {
        dispatch(setOrderLoading({ id: order_id, value: false, actionType: "resume" }));
        console.log(err);
        snackbar.enqueueSnackbar(parseAnbotoRequestError(err?.data), { variant: "error" });
      }
    };

    const columns = React.useMemo(
      () => [
        ...getColumns({
          supportedExchanges,
          onSymbolClick: handleSymbolClick,
          currentTab,
          onCancelOrder: handleOrderCancel,
          getSymbolLabel,
          excludeColumns,
        }),
        ...(!isHistory && !isSaved && permissions.trade
          ? [
              columnHelper.display({
                id: "actions",
                size: 30,
                cell: React.memo(
                  ({ row }: { row: Row<ParentOrder> }) =>
                    row.original.last_status && isCancelable(row.original.last_status.status) ? (
                      <ActionButton
                        type="cancel"
                        orderId={row.original.order_id}
                        onClick={() => handleOrderCancel(row.original.order_id, row.original.account_uuid)}
                      />
                    ) : null,
                  (prev, next) => prev.row.original.order_id === next.row.original.order_id
                ),
              }),
            ]
          : []),
        ...(isSaved && permissions.trade
          ? [
              columnHelper.display({
                id: "actions-saved-orders",
                size: 200,
                cell: React.memo(
                  ({ row }: { row: Row<ParentOrder> }) => (
                    <Stack gap={1} direction="row">
                      <ActionButton
                        type="resume"
                        orderId={row.original.order_id}
                        onClick={() => handleStartSavedOrder(row.original)}
                      />
                      <ActionButton
                        orderId={row.original.order_id}
                        type="delete"
                        onClick={() => handleDeleteSavedOrder(row.original)}
                        sx={{ marginLeft: 4 }}
                      />
                    </Stack>
                  ),
                  (prev, next) => prev.row.original.order_id === next.row.original.order_id
                ),
              }),
            ]
          : []),
        ...(isHistory && isPostTradeVisible
          ? [
              columnHelper.display({
                id: "analytics",
                size: 60,
                header: "Analysis",
                cell: React.memo(
                  ({ row }: { row: Row<ParentOrder> }) =>
                    row.original?.is_pta_available ? <PostTradeAnalysisButton order={row.original} /> : <></>,
                  (prev, next) => prev.row.original.order_id === next.row.original.order_id
                ),
              }),
            ]
          : []),
      ],
      [currentTab, Object.keys(supportedExchanges).join()]
    );

    const tableData = orders.isFetching && !searchResults.length ? loadingData : searchResults;

    useEffect(() => {
      if (exchangeIds?.length) refetch();
    }, [accountId, currentTab, period, status, exchangeIds?.join()]);

    return (
      <>
        <MemoizedOrdersTable
          columns={columns}
          data={tableData}
          loading={orders.isFetching}
          error={orders.isError}
          expandedRowRender={(data: ParentOrder) => <ExpandedRow data={data} key={data.order_id} />}
          noDataText="You don't have any records"
          refetch={refetch}
          getRowId={(order: ParentOrder) => order.order_id}
        />
      </>
    );
  },
  (prev, next) => prev.exchangeIds?.join() === next.exchangeIds?.join()
);

ParentOrdersTable.displayName = "ParentOrdersTable";
