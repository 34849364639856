import React, { useState } from "react";
import { Stack, styled, Typography } from "@mui/material";
import { CurrencyValue } from "@src/pages/portfolio";
import { Value } from "@src/components/price-impact/components/value";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";
import { formatNumber } from "@src/pages/portfolio/utils/helpers";
import AddressBlock from "@src/pages/defi/token-info/components/address-block";
import { transformToNumber } from "@src/pages/defi/token-info/helpers";
import { SearchPool } from "@src/store/apis/geckoterminal-api/types";
import AnbotoLoader from "@src/components/anboto-loader/anboto-loader";
import { Token } from "@src/pages/defi/types";
import { Field } from "@src/components/price-impact/components/price-impact";

type TokenInfoContentProps = {
  pool: SearchPool;
  isFetching: boolean;
  fromToken: Token | undefined;
  toToken: Token | undefined;
};

const TIMEFRAMES_LIST = [
  { label: "1 hr", value: "h1" },
  { label: "24 hr", value: "h24" },
];

const PoolInfoContent = ({ pool, isFetching, fromToken, toToken }: TokenInfoContentProps) => {
  const [currentTimeframe, setCurrentTimeframe] = useState(TIMEFRAMES_LIST[0].value);

  // transformToNumber - sometimes gecko api returns {} instead of number
  const buys = transformToNumber(pool?.attributes?.transactions?.[currentTimeframe]?.buys);
  const buyers = transformToNumber(pool?.attributes?.transactions?.[currentTimeframe]?.buyers);
  const sells = transformToNumber(pool?.attributes?.transactions?.[currentTimeframe]?.sells);
  const sellers = transformToNumber(pool?.attributes?.transactions?.[currentTimeframe]?.sellers);

  const poolAddress = pool?.attributes?.address;

  return (
    <>
      {isFetching ? (
        <AnbotoLoader />
      ) : pool ? (
        <Stack gap={1} maxHeight={430}>
          <Stack direction="row" justifyContent="flex-start" pt={1}>
            <Stack flexDirection="column" width="50%">
              <Field>24h Vol</Field>
              <StyledValue>
                <CurrencyValue value={Number(pool?.attributes?.volume_usd?.h24)} />
              </StyledValue>
            </Stack>
            <Stack flexDirection="column" width="50%">
              <Field>Liquidity</Field>
              <StyledValue>
                <CurrencyValue value={Number(pool?.attributes?.reserve_in_usd)} />
              </StyledValue>
            </Stack>
          </Stack>

          <Stack sx={{ borderBottom: "1px solid #232C2F" }}></Stack>

          <Stack gap={1} minWidth={300}>
            {poolAddress && <AddressBlock addressName="Pool" address={poolAddress} />}
            {fromToken?.symbol && <AddressBlock addressName={fromToken?.symbol} address={fromToken?.address} />}
            {toToken?.symbol && <AddressBlock addressName={toToken?.symbol} address={toToken?.address} />}
          </Stack>

          <Stack sx={{ borderBottom: "1px solid #232C2F" }}></Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack flexDirection="column" width="50%">
              <Field>Fully Diluted Valuation</Field>
              <StyledValue>
                <CurrencyValue value={Number(pool?.attributes?.fdv_usd)} />
              </StyledValue>
            </Stack>
            <Stack flexDirection="column" width="50%">
              <Field>Market Cap</Field>
              <StyledValue>
                <CurrencyValue value={Number(pool?.attributes?.market_cap_usd)} />
              </StyledValue>
            </Stack>
          </Stack>

          <Stack gap={2}>
            <AnbotoButtonGroup
              buttonStyles={{ width: "100%" }}
              options={TIMEFRAMES_LIST}
              onChange={setCurrentTimeframe}
              value={currentTimeframe}
            />
            <Stack
              sx={{ borderRadius: 0.5, border: "1px solid  #3B4043" }}
              padding={1.5}
              direction="row"
              justifyContent="space-between"
            >
              <Stack flexDirection="column" width="50%">
                <Field>TXNS</Field>
                <StyledValue>{buys + sells}</StyledValue>
              </Stack>
              <Stack flexDirection="column" width="50%">
                <Field>Buys</Field>
                <StyledValue>{buys}</StyledValue>
              </Stack>
              <Stack flexDirection="column" width="50%">
                <Field>Sells</Field>
                <StyledValue>{sells}</StyledValue>
              </Stack>
              <Stack flexDirection="column" width="50%">
                <Field>Volume</Field>
                <StyledValue>
                  <CurrencyValue value={pool?.attributes?.volume_usd?.[currentTimeframe]} />
                </StyledValue>
              </Stack>
            </Stack>

            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" gap={1}>
                  <Typography color="#2C6053" fontSize={12} fontWeight={400} variant="caption">
                    BUY
                  </Typography>{" "}
                  <Typography variant="caption">{buys}</Typography>{" "}
                </Stack>
                <Stack direction="row" gap={1}>
                  <Typography variant="caption">{sells}</Typography>{" "}
                  <Typography color="#6D3921" fontSize={12} fontWeight={400} variant="caption">
                    SELL
                  </Typography>
                </Stack>
              </Stack>
              <Stack display="flex" height={6} flexDirection="row">
                <Stack
                  height={6}
                  width={`${formatNumber((buys / (buys + sells || 1)) * 100, 2)}%`}
                  sx={{
                    cursor: "pointer",
                    background: "#00CAA5",
                  }}
                />
                <Stack
                  height={6}
                  width={`${formatNumber((sells / (buys + sells || 1)) * 100, 2)}%`}
                  sx={{
                    cursor: "pointer",
                    background: "#C3462B",
                  }}
                />
              </Stack>
            </Stack>

            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" gap={1}>
                  <Typography color="#2C6053" fontSize={12} fontWeight={400} variant="caption">
                    BUYERS
                  </Typography>{" "}
                  <Typography variant="caption">{buyers}</Typography>{" "}
                </Stack>
                <Stack direction="row" gap={1}>
                  <Typography variant="caption">{sellers}</Typography>{" "}
                  <Typography color="#6D3921" fontSize={12} fontWeight={400} variant="caption">
                    SELLERS
                  </Typography>
                </Stack>
              </Stack>

              <Stack display="flex" height={6} flexDirection="row">
                <Stack
                  height={6}
                  width={`${formatNumber((buyers / (buyers + sellers || 1)) * 100, 2)}%`}
                  sx={{
                    cursor: "pointer",
                    background: "#00CAA5",
                  }}
                />
                <Stack
                  height={6}
                  width={`${formatNumber((sellers / (buyers + sellers || 1)) * 100, 2)}%`}
                  sx={{
                    cursor: "pointer",
                    background: "#C3462B",
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Typography>Unable to fetch pool information</Typography>
      )}
    </>
  );
};

export default PoolInfoContent;

export const StyledValue = styled(Value)(() => ({
  color: "#aab6bb",
}));
