import { CefiExchangeId, OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";
import { MULTI_SLICE_ORDER_EXECUTION_STRATEGIES } from "@src/store/apis/anbotoApi/constants";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";

export const isMultiSliceOrderExecutionStrategy = (x: OrderExecutionStrategy) =>
  MULTI_SLICE_ORDER_EXECUTION_STRATEGIES.includes(x);

export const INVALID_2FA_TOKEN_ERROR_CODE = "user_2fa_failed";
export const INVALID_TOKEN_ERROR_CODE = "token_not_valid";
export const USER_INACTIVE_ERROR_CODE = "user_inactive";

export const isDeactivated = (x: { detail: string; code?: string }) => x.code === USER_INACTIVE_ERROR_CODE;

export const is2faError = (x: {
  code?: string;
  detail: string;
  messages: Array<{ token_type: "2fa"; message: string }>;
}) =>
  x.code === INVALID_2FA_TOKEN_ERROR_CODE ||
  (x.code === INVALID_TOKEN_ERROR_CODE && x.messages.some((y) => y.token_type === "2fa"));

export const getExchangeIdByExchangeName = (name = "") =>
  Object.keys(CEFI_EXCHANGE_NAME).find(
    (exchangeId: string) => CEFI_EXCHANGE_NAME[exchangeId]?.toLowerCase() === name?.toLowerCase()
  ) as CefiExchangeId;
