import React, { FC, useState } from "react";
import { FormProvider } from "react-hook-form";
import { IconButton, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { Close } from "@mui/icons-material";
import { ModalHeader, ModalBody, ModalFooter, ModalActionButton, SubmitButton } from "./styled";
import { InputProgress } from "@src/pages/portfolio/components/send-crypto/input-progress";
import { SendCryptoForm } from "@src/pages/portfolio/components/send-crypto/send-crypto-form";
import { SendCompleted } from "@src/pages/portfolio/components/send-crypto/send-completed";
import { Verification } from "@src/pages/portfolio/components/send-crypto/verification";
import { truncateAccount } from "@src/pages/defi/utils";
import { useSendCryptoForm, useTransferEstimate } from "@src/pages/portfolio/components/send-crypto/hooks";
import { useDecimalsQuery, useTransferCryptoMutation } from "@src/pages/portfolio";
import { useTokenInfo } from "@src/pages/defi/hooks";
import { EVMBasedAddress } from "@src/pages/defi/types";

export interface ModalContentProps {
  onClose: () => void;
}

export const ModalWithRainbow: FC<ModalContentProps> = ({ onClose }) => {
  const sendCryptoForm = useSendCryptoForm();
  const snackbar = useSnackbar();
  const [step, setStep] = useState<1 | 2>(1);
  const [isTransferCompleted, toggleTransferCompleted] = useState<boolean>(false);
  const accountFromName = sendCryptoForm.watch("accountFromName");
  const accountTo = sendCryptoForm.watch("accountTo");
  const tokenAmount = sendCryptoForm.watch("tokenAmount");
  const chainId = sendCryptoForm.watch("chainId");
  const tokenAddress = sendCryptoForm.watch("tokenAddress");
  const accountFrom = sendCryptoForm.watch("accountFrom");
  const selectedToken = useTokenInfo({ chainId, address: tokenAddress as EVMBasedAddress });
  const sendAmount = `${tokenAmount} ${selectedToken?.symbol}`;
  const { transferEstimate, networkFee } = useTransferEstimate(chainId);
  const sendFrom = accountFrom ? truncateAccount(accountFrom) : "";
  const [sendCrypto, { isLoading }] = useTransferCryptoMutation();
  const { data: decimals } = useDecimalsQuery({ tokenAddress }, { skip: !tokenAddress || !accountFrom });

  const { handleSubmit } = sendCryptoForm;

  const closeForm = () => {
    onClose();
    resetForm();
  };

  const onSubmit = async () => {
    if (!decimals) {
      snackbar.enqueueSnackbar("decimals error, please try again.", {
        variant: "error",
      });

      return;
    }

    setStep(2);
    transferEstimate(accountTo, tokenAddress, tokenAmount, decimals);
  };

  const resetForm = () => {
    setStep(1);
    sendCryptoForm.reset();
    toggleTransferCompleted(false);
  };

  const confirmTransaction = async () => {
    if (!decimals) {
      snackbar.enqueueSnackbar("decimals error, please try again.", {
        variant: "error",
      });

      return;
    }

    try {
      await sendCrypto({
        accountTo,
        tokenAddress,
        tokenAmount: tokenAmount,
        decimals,
      }).unwrap();

      snackbar.enqueueSnackbar(`Send token transaction is being minted`, { variant: "success" });
      toggleTransferCompleted(true);
    } catch (e) {
      snackbar.enqueueSnackbar(e.message || "Confirm transaction error, please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <ModalHeader>
        Send crypto
        <IconButton onClick={closeForm}>
          <Close />
        </IconButton>
      </ModalHeader>
      <ModalBody>
        <FormProvider {...sendCryptoForm}>
          {!isTransferCompleted && <InputProgress step={step} sx={{ marginBottom: (theme) => theme.spacing(4) }} />}
          {step === 1 && <SendCryptoForm selectedToken={selectedToken} step={step} />}
          {step === 2 && (
            <Stack direction="column" gap={6}>
              {isTransferCompleted && <SendCompleted />}
              <Verification
                accountFrom={accountFromName || sendFrom}
                accountTo={accountTo ? truncateAccount(accountTo) : ""}
                sendAmount={sendAmount}
                networkFee={networkFee || ""}
                receiveAmount={tokenAmount}
              />
            </Stack>
          )}
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        {step === 1 && (
          <SubmitButton color="primary" onClick={handleSubmit(onSubmit)} variant="contained">
            Review
          </SubmitButton>
        )}
        {step === 2 && (
          <>
            {isTransferCompleted ? (
              <ModalActionButton color="primary" onClick={onClose} variant="contained">
                Close
              </ModalActionButton>
            ) : (
              <Stack direction="row" justifyContent="space-between">
                <ModalActionButton color="secondary" onClick={() => setStep(1)}>
                  Edit order
                </ModalActionButton>
                <SubmitButton
                  color="primary"
                  loading={isLoading}
                  loadingPosition="end"
                  onClick={confirmTransaction}
                  variant="contained"
                >
                  Confirm order
                </SubmitButton>
              </Stack>
            )}
          </>
        )}
      </ModalFooter>
    </>
  );
};
