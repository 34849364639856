import React from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import format from "date-fns/format";
import { PostTradeTableData } from "@src/hooks/useGet12MonthPostTradeData";
import {
  DashboardDataCard,
  DashBoardTableCell,
  DashBoardTableHeaderCell,
} from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/styled-components";
import DashboardDataCardHeader from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/dashboard-data-card-header";
import { formatBps } from "@src/components/post-trade-analysis/helpers";

type PostTradeStatsTableProps = {
  data: PostTradeTableData[];
};

const PostTradeTable = ({ data }: PostTradeStatsTableProps) => {
  return (
    <DashboardDataCard flex={0.93}>
      <DashboardDataCardHeader header="Slippage stats" title="Slippage stats" />

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <DashBoardTableHeaderCell>Month</DashBoardTableHeaderCell>
              <DashBoardTableHeaderCell align="right">to arrival</DashBoardTableHeaderCell>
              <DashBoardTableHeaderCell align="right">to twap</DashBoardTableHeaderCell>
              <DashBoardTableHeaderCell align="right">to vwap</DashBoardTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.time} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <DashBoardTableCell>
                  <Typography fontSize={14}>{format(new Date(row.time * 1000), "MMMM yyyy")}</Typography>
                </DashBoardTableCell>
                <DashBoardTableCell align="right">
                  <Typography fontSize={14}>{formatBps(row.arrival)}</Typography>
                </DashBoardTableCell>
                <DashBoardTableCell align="right">
                  <Typography fontSize={14}>{formatBps(row.twap)}</Typography>
                </DashBoardTableCell>
                <DashBoardTableCell align="right">
                  <Typography fontSize={14}>{formatBps(row.vwap)}</Typography>
                </DashBoardTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardDataCard>
  );
};

export default PostTradeTable;
