import React from "react";
import ValueChange from "@src/components/value-change/value-change";
import { usePriceImpactContext } from "@src/components/price-impact/hooks/use-price-impact-context";

const PriceValueChange = () => {
  const { percentage } = usePriceImpactContext();

  return <ValueChange fontSize={12} value={percentage} />;
};

export default PriceValueChange;
