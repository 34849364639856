import React from "react";
import { Stack, TableCell, Typography } from "@mui/material";
import { formatLargeNumber, getRankingNumberColor, maskString } from "@src/pages/competitions/helpers";
import { LeaderBoardTableRow } from "@src/pages/competitions/components/leader-board-row";
import { RankingCircle } from "@src/pages/competitions/components/rankning-circle";

type WinnerRowProps = {
  clientId: string;
  ranking: number | string;
  volume: number;
};

export const UserRow = ({ clientId, ranking, volume }: WinnerRowProps) => {
  const isTop10Ranking = typeof ranking === "number";
  return (
    <LeaderBoardTableRow
      key={clientId}
      sx={{
        "&>td": {
          borderTop: "1px solid #179B83",
          borderBottom: "1px solid #179B83",
        },
        "&>td:last-child": {
          borderRight: "1px solid #179B83",
          borderTopRightRadius: 4,
          borderBottomRightRadius: 4,
        },
        "&>td:first-child": {
          borderLeft: "1px solid #179B83",
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
        },
      }}
    >
      <TableCell sx={{ ...(isTop10Ranking ? { color: `${getRankingNumberColor(ranking)}${"!important"}` } : {}) }}>
        <Stack direction="row" gap={0.5} alignItems="center">
          <Typography
            sx={{ fontSize: 14, fontWeight: 500 }}
            color={isTop10Ranking ? getRankingNumberColor(ranking) : undefined}
          >
            {String(ranking).length === 1 ? "0" + ranking : ranking}
          </Typography>
          {isTop10Ranking && <RankingCircle ranking={ranking} />}
        </Stack>
      </TableCell>
      <TableCell align="left">{maskString(String(clientId))}</TableCell>
      <TableCell align="right">{formatLargeNumber(volume)}</TableCell>
    </LeaderBoardTableRow>
  );
};
