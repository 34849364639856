import { MarketType } from "../../types";

export const bitget = {
  name: "Bitget",
  marketType: [MarketType.SPOT, MarketType.FUTURE],
  referalLink: "https://www.bitget.com/en/referral/register?from=referral&clacCode=0RFL677X",
  //
  // ...
  // ...
};
