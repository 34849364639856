import React from "react";
import { Stack } from "@mui/material";
import { PredefinedWidgetsLayout, WidgetPredefinedLayoutView } from "../../types";
import { otcPageLayout } from "../../layouts/otc-page-layout";
import { LayoutButton } from "./layout-button";

export const LayoutsOtc = ({ onLayoutClick }: { onLayoutClick: (layout: PredefinedWidgetsLayout) => void }) => {
  return (
    <>
      <Stack m={2}>
        <Stack flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          <LayoutButton
            title="Basic"
            description="Single symbol"
            type={WidgetPredefinedLayoutView.BASIC}
            onClick={() => onLayoutClick(otcPageLayout)}
            width={125}
          />
        </Stack>
      </Stack>
    </>
  );
};
