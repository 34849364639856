import React from "react";
import { Stack, StackProps, styled } from "@mui/material";
import { OrderbookVariant } from "../types";
import { TOOLBAR_HEIGHT } from "../constants";
import { SPREAD_HEIGHT, Spread } from "./spread";
import { HEADER_HEIGHT, VerticalHeader } from "./vertical-header";
import { OrdersList } from "./orders-list";
import { OrderbookProvider } from "./orderbook-provider";
import { PriceGroupingMenu } from "./price-grouping-menu";
import { Toolbar } from "./toolbar";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { useElementSize } from "@src/hooks/use-element-size";

export interface OrderbookProps extends StackProps {
  symbol: OrderSymbol;
  variant?: OrderbookVariant;
  grouping?: string;
  onGroupingChange: (grouping: string) => void;
  onRowClick?: (price: string) => void;
}

export const Orderbook = ({
  symbol,
  variant = "vertical",
  grouping = "",
  onGroupingChange,
  onRowClick,
  ...props
}: OrderbookProps) => {
  const [ordersAreaRef, { height }] = useElementSize();
  const isVertical = variant === "vertical";

  return (
    <Root {...props}>
      <Wrapper direction={isVertical ? "column" : "row"} gap={0}>
        <Stack flex={1} overflow="hidden" ref={ordersAreaRef}>
          {symbol && (
            <OrderbookProvider
              symbol={symbol}
              freeSpace={height - SPREAD_HEIGHT - TOOLBAR_HEIGHT - HEADER_HEIGHT}
              grouping={grouping}
              onRowClick={onRowClick}
            >
              <Toolbar>
                <PriceGroupingMenu onChange={onGroupingChange} selected={grouping} />
              </Toolbar>
              {isVertical && <VerticalHeader />}
              <div style={{ position: "relative", flex: 1, overflow: "hidden" }}>
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    bottom: `calc(50% + ${SPREAD_HEIGHT / 2}px)`,
                    left: 0,
                  }}
                >
                  <OrdersList type="ask" />
                </div>
                {isVertical && (
                  <Spread
                    sx={{
                      position: "absolute",
                      left: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                      width: "100%",
                    }}
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    top: `calc(50% + ${SPREAD_HEIGHT / 2}px)`,
                    left: 0,
                  }}
                >
                  <OrdersList type="bid" />
                </div>
              </div>
            </OrderbookProvider>
          )}
        </Stack>
      </Wrapper>
    </Root>
  );
};

const Root = styled(Stack)({
  position: "relative",
  width: "100%",
  height: "100%",
});

const Wrapper = styled(Stack)({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: "100%",
});

const TopArea = React.forwardRef(
  ({ children, ...props }: React.PropsWithChildren<{}>, ref: React.Ref<HTMLDivElement>) => (
    <div {...props} ref={ref}>
      {children}
    </div>
  )
);

TopArea.displayName = "TopArea";
