import { useState, useEffect } from "react";
import { PortfolioCefiError, usePortfolioCefiErrorsQuery, usePortfolioExchangesListQuery } from "@src/pages/portfolio";

export type ErrorMap = {
  [exchangeId: string]: PortfolioCefiError[];
};

export interface UsePortfolioCefiAllErrorsResult {
  errors: {
    [exchangeId: string]: PortfolioCefiError[];
  };
}

export const usePortfolioCefiAllErrors = (): UsePortfolioCefiAllErrorsResult => {
  // const exchangesListResult = useUserExchangesList();
  const { data: exchanges } = usePortfolioExchangesListQuery(null);
  const allExchanges = exchanges?.available || [];
  const exchange_list_ids = allExchanges?.join(",") || "";
  const [errors, setErrors] = useState<ErrorMap>({});
  const { data } = usePortfolioCefiErrorsQuery(
    {
      exchange_list_ids,
    },
    { skip: !exchange_list_ids }
  );

  const allErrors = data?.data || [];

  useEffect(() => {
    const errorsMap: ErrorMap = {};

    allErrors.forEach((error) => {
      if (!errorsMap[error.exchange_id]) {
        errorsMap[error.exchange_id] = [];
      }

      errorsMap[error.exchange_id].push(error);
    });

    setErrors(errorsMap);
  }, [data]);

  return {
    errors,
  };
};
