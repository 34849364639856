import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ExpirationTime } from "../fields/ExpirationTimeSelect";
import {
  OrderSymbol,
  OrderExecutionStrategy,
  OrderSide,
  OrderTradingDurationUnit,
  ClipSizeType,
  WouldStyle,
  PlacementMode,
  TriggerCondition,
  TradingStyle,
  PlacementValue,
  PlacementCancelValue,
  PovRisk,
  Urgency,
} from "@src/store/apis/anbotoApi/types";
import formValidator from "@src/utils/formValidator";
import { DEFAULT_SYMBOL } from "@src/features/widgets-layout/constants";
import { useCefiOrderFormDefaultValues } from "@src/pages/cefi/order-form/hooks/use-cefi-order-form-default-values";
import { DEFAULT_CEFI_EXTEND_DURATION } from "@src/pages/cefi/constants";

export type ParentOrderFormFieldValues = {
  symbol: null | OrderSymbol;
  account: string;
  quantity: string;
  toQuantity: string;
  side: OrderSide;
  strategy: OrderExecutionStrategy;
  clipSizeValue: string;
  clipSizeType: ClipSizeType;
  tradingDuration?: string;
  tradingDurationUnit: OrderTradingDurationUnit;
  extendDuration: boolean;
  limitPrice: string;
  startTime: string;
  endTime: string;
  wouldPrice: string;
  wouldStyle: WouldStyle;
  wouldPct: string;
  placementMode?: PlacementMode;
  placement?: string;
  placementCancel?: string;
  triggerCondition: TriggerCondition;
  triggerPrice: string;
  tradingStyle: TradingStyle;
  expirationTime: ExpirationTime;
  povRatio: string;
  povRisk: PovRisk;
  reduceOnly?: boolean;
  urgency: Urgency;
  slippage: string;
};

export const CEFI_ORDER_FORM_DEFAULT_VALUES: Partial<ParentOrderFormFieldValues> & {
  strategy: OrderExecutionStrategy;
} = {
  symbol: DEFAULT_SYMBOL,
  strategy: OrderExecutionStrategy.ORDER,
  side: OrderSide.BUY,
  account: "",
  quantity: "",
  toQuantity: "",
  tradingDurationUnit: OrderTradingDurationUnit.HOURS,
  tradingDuration: "",
  extendDuration: DEFAULT_CEFI_EXTEND_DURATION,
  limitPrice: "",
  startTime: "",
  endTime: "",
  clipSizeType: ClipSizeType.AUTOMATIC,
  clipSizeValue: "",
  tradingStyle: TradingStyle.HYBRID,
  wouldPrice: "",
  wouldStyle: WouldStyle.HYBRID,
  wouldPct: "",
  placementMode: PlacementMode.DEFAULT,
  placement: PlacementValue.DEFAULT,
  placementCancel: PlacementCancelValue.DEFAULT,
  triggerCondition: TriggerCondition.ABOVE,
  triggerPrice: "",
  expirationTime: "6months",
  povRatio: "5",
  povRisk: PovRisk.DEFAULT,
  reduceOnly: false,
  urgency: Urgency.MEDIUM,
  slippage: "0",
};

export const getTradingStyle = (strategy: OrderExecutionStrategy, defaultTradingStyle?: TradingStyle) => {
  if (strategy === OrderExecutionStrategy.LIMIT) return TradingStyle.PASSIVE;
  if (strategy === OrderExecutionStrategy.ORDER) return TradingStyle.AGGRESSIVE;

  return defaultTradingStyle || TradingStyle.HYBRID;
};

const schema = formValidator
  .object({
    account: formValidator.string().required(),
    strategy: formValidator.string().required(),
    side: formValidator.string().required(),
    tradingDuration: formValidator.number().emptyable().moreThan(0).noLabel(),
    expirationTime: formValidator.number().emptyable().min(0).noLabel(),
    // numberOfSlices: formValidator
    //   .number()
    //   .emptyable()
    //   .min(2)
    //   .integer()
    //   .noLabel()
    //   .when("strategy", {
    //     is: (x) => isMultiSliceOrderExecutionStrategy(x),
    //     then: (x) => x.required(),
    //   }),
    quantity: formValidator
      .number()
      .emptyable()
      .min(0)
      .noLabel()
      .when("side", {
        is: (side) => side === OrderSide.BUY,
        then: (x) => x.required(),
      }),
    toQuantity: formValidator
      .number()
      .emptyable()
      .min(0)
      .noLabel()
      .when("side", {
        is: (side) => side === OrderSide.SELL,
        then: (x) => x.required(),
      }),
    limitPrice: formValidator
      .number()
      .emptyable()
      .min(0)
      .noLabel()
      .when("strategy", {
        is: (strategy: OrderExecutionStrategy) => strategy === OrderExecutionStrategy.LIMIT,
        then: (x) => x.required(),
      }),
    clipSizeValue: formValidator
      .number()
      .emptyable()
      .min(0)
      .noLabel()
      .when("clipSizeType", {
        is: (type) =>
          type === ClipSizeType.ABSOLUTE ||
          type === ClipSizeType.PERCENTAGE ||
          type === ClipSizeType.NB_OF_CHILD_ORDERS,
        then: (x) => x.required(),
      })
      .when("clipSizeType", {
        is: (type) => type === ClipSizeType.PERCENTAGE,
        then: (x) => x.max(100).min(0),
      })
      .when("clipSizeType", {
        is: (type) => type === ClipSizeType.NB_OF_CHILD_ORDERS,
        then: (x) => x.min(1),
      })
      .when("clipSizeType", {
        is: (type) => type === ClipSizeType.ABSOLUTE,
        then: (x) => x.max(formValidator.ref("quantity")),
      }),
    wouldPrice: formValidator.number().emptyable().min(0.000001),
    wouldPct: formValidator.number().emptyable().min(0.000001).max(100),
    wouldStyle: formValidator.string(),
    placementMode: formValidator.string(),
    placement: formValidator.number().emptyable().min(1).max(10),
    placementCancel: formValidator.number().emptyable().min(2).max(15),
    triggerCondition: formValidator.string(),
    triggerPrice: formValidator.number().emptyable().min(0.000001),
    tradingStyle: formValidator.string().required(),
    povRatio: formValidator
      .number()
      .emptyable()
      .min(0.000001)
      .max(100)
      .when("strategy", {
        is: (strategy: OrderExecutionStrategy) => strategy === OrderExecutionStrategy.POV,
        then: (x) => x.required(),
      }),
  })
  .test("wouldPrice-wouldPct", "If either wouldPrice or wouldPct not empty both must be provided", function (values) {
    const { wouldPrice, wouldPct } = values;

    if ((!wouldPrice && wouldPct) || (!wouldPct && wouldPrice)) {
      return this.createError({
        path: !wouldPrice ? "wouldPrice" : "wouldPct",
        message: `${!wouldPrice ? "Price" : "Pct"} must be provided because ${
          !wouldPrice ? "Pct" : "Price"
        } is not empty`,
      });
    }

    return true;
  })
  .required();

export const useParentOrderForm = (values: Partial<ParentOrderFormFieldValues> = {}) => {
  const defaultValues = useCefiOrderFormDefaultValues(values);

  return useForm<ParentOrderFormFieldValues>({
    defaultValues: {
      ...defaultValues,
      ...values,
    },
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
};
