import format from "date-fns/format";

export const getDcaOrderDate = (date: string | undefined): { isNow: boolean; date: string } => {
  const currentDate = new Date().getTime();
  const now = { isNow: true, date: format(currentDate, "EEE, dd MMM yyyy HH:mm:ss") };

  if (!date) return now;

  const timestamp = new Date(date).getTime();

  if (timestamp > currentDate) {
    return { isNow: false, date };
  } else {
    return now;
  }
};
