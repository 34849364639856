import React from "react";
import { Grid, Stack, styled, Tooltip, Typography } from "@mui/material";
import { COLORS } from "../../constant";
import { iCefiAllocationItem } from "@src/store/apis/anbotoApi/types";
import { capitalizeFirstLetter, formatNumber } from "@src/pages/portfolio/utils/helpers";
import { CurrencyValue } from "@src/pages/portfolio";

type TableCefiAllocationExpandedRowProps = {
  allocations: iCefiAllocationItem[] | undefined;
};

const TableCefiAllocationExpandedRow = ({ allocations }: TableCefiAllocationExpandedRowProps): JSX.Element => {
  const balanceSum = allocations?.reduce((n, { balance }) => n + balance, 0);

  return (
    <Root>
      <Stack>
        <Typography marginBottom={1} fontSize={12} fontWeight={400} color="#8A9296">
          Allocation
        </Typography>
        <Stack display="flex" height={6} flexDirection="row">
          {allocations?.map((item, index) => (
            <Tooltip
              title={
                <>
                  Value: <CurrencyValue value={item.value} />
                </>
              }
            >
              <Stack
                height={6}
                width={`${formatNumber((balanceSum ? item.balance / balanceSum : 0) * 100, 2)}%`}
                sx={{
                  cursor: "pointer",
                  background: index < COLORS.length ? COLORS[index] : COLORS[index % COLORS.length],
                }}
              />
            </Tooltip>
          ))}
        </Stack>

        <Grid width="100%" marginTop={2} container gap={1}>
          {allocations?.map((item, index) => (
            <Grid
              sx={{
                borderLeft: `2px solid ${index < COLORS.length ? COLORS[index] : COLORS[index % COLORS.length]}`,
                paddingLeft: 1,
              }}
              xs={2}
              item
              key={item.exchange_id}
            >
              <Typography fontSize={12} fontWeight={400} noWrap>
                {capitalizeFirstLetter(item.exchange_id)}
              </Typography>
              <Typography fontSize={12} fontWeight={400} color="#F1F7FA">
                <CurrencyValue value={item.value} />
              </Typography>
              <Typography fontSize={12} fontWeight={400} color="#F1F7FA">
                {formatNumber((balanceSum ? item.balance / balanceSum : 0) * 100, 2)}%
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Root>
  );
};

const Root = styled(Stack)({
  background: "#232C2F",
  padding: 20,
  color: "#8A9296",
});

export default TableCefiAllocationExpandedRow;
