import BigNumber from "bignumber.js";
import _uniqWith from "lodash/uniqWith";
import { BINANCE_UNSUPPORTED_PAIRS } from "./constants";
import { formatWithDelimiter } from "@src/utils/format";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";

export const getFromTo = (symbol: string) => {
  const [from, to] = symbol.split("/");
  return { from, to };
};

export const formatPrice = (price: number | string) => {
  const [l, r] = `${price}`.split(".");

  const precision = l?.length > 1 ? 2 : +l < 1 ? r?.length || 4 : 4;

  return formatWithDelimiter(new BigNumber(price).toFixed(precision));
};

export const getSymbolsDeps = (symbols: OrderSymbol[], checkOrderChange = false) => {
  if (!symbols?.length) return "";

  const res = _uniqWith([...symbols], (a, b) => getLivePriceId(a) === getLivePriceId(b));

  if (!checkOrderChange) res.sort((a, b) => (getLivePriceId(a) > getLivePriceId(b) ? 1 : -1));

  return res.reduce((prev, symbol) => (prev += getLivePriceId(symbol)), "");
};

export function changeArrayOrder<T>(arr: T[], from: number, to: number) {
  const reordered = [...arr];

  reordered.splice(to, 0, ...reordered.splice(from, 1));

  return reordered;
}

export const filterUnsupported = (symbol: OrderSymbol) => !BINANCE_UNSUPPORTED_PAIRS.includes(symbol.symbol);
