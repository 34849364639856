import { useSelector } from "react-redux";
import { useUserExchangesList } from "@src/pages/portfolio";
import { RootState } from "@src/store/types";
import { SELECT_ALL } from "@src/store/slices/portfolioSlice";

export interface UseExchangeListIdsResult {
  exchangesIdsList: string[];
  exchange_list_ids: string;
  isFetching: boolean;
}

export const useExchangeListIds = (): UseExchangeListIdsResult => {
  const exchangesListResult = useUserExchangesList();
  const selectedExchanges = exchangesListResult?.data?.selected;
  const selectedExchange = useSelector((state: RootState) => state.portfolio.selectedExchange);
  // we use only selected, so we don't need to store copy of available twice
  const exchangesIdsList = selectedExchange !== SELECT_ALL ? [selectedExchange] : selectedExchanges || [];
  const exchange_list_ids = exchangesIdsList?.join(",") || "";

  return {
    exchangesIdsList,
    exchange_list_ids,
    isFetching: exchangesListResult.isFetching,
  };
};
