import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  GetBiggestTokenPairPoolParams,
  GetBiggestTokenPairPoolResult,
  GetLastTradesParams,
  GetLastTradesResult,
  GetOHLCVSDataParams,
  GetOHLCVSDataResult,
  GetPoolDataParams,
  GetPoolDataResult,
  GetTokenDetailsResults,
  GetTokenPairChartDataParams,
  GetTokenPairChartDataResult,
  GetTokenParams,
  GetTop20TokenPoolsResult,
  SearchPoolParams,
  SearchPoolResult,
} from "@src/store/apis/geckoterminal-api/types";
import { queryFnFactory } from "@src/store/apis/utils";
import { GECKOTERMINAL_CHAIN_IDS } from "@src/store/apis/geckoterminal-api/constants";
import { getTimeframe } from "@src/pages/defi/defi-chart/defi-data-feed/helpers";
import { MINIMAL_POOL_USD_RESERVE } from "@src/pages/defi/defi-chart";
import { isStableCoin } from "@src/pages/portfolio/utils/helpers";

export const baseQuery = fetchBaseQuery({ baseUrl: "https://api.geckoterminal.com/api/v2" });

export const geckoTerminalApi = createApi({
  reducerPath: "geckoTerminalApi",
  baseQuery,
  endpoints: (builder) => ({
    getTokenDetails: builder.query<GetTokenDetailsResults, GetTokenParams>({
      query: ({ network, token_address }) => ({
        method: "GET",
        url: `/networks/${network}/tokens/${token_address}`,
      }),
    }),
    getTop20TokenPools: builder.query<GetTop20TokenPoolsResult, GetTokenParams>({
      query: ({ network, token_address }) => ({
        method: "GET",
        url: `/networks/${network}/tokens/${token_address}/pools`,
      }),
    }),
    searchPool: builder.query<SearchPoolResult, SearchPoolParams>({
      query: ({ query }) => ({
        method: "GET",
        url: `/search/pools?query=${query}`,
      }),
    }),
    getPoolData: builder.query<GetPoolDataResult, GetPoolDataParams>({
      query: ({ network, pool_address }) => ({
        method: "GET",
        url: `/networks/${network}/pools/${pool_address}?include=base_token`,
      }),
    }),
    getOHLCVSData: builder.query<GetOHLCVSDataResult, GetOHLCVSDataParams>({
      query: ({ network, pool_address, timeframe, aggregate, before_timestamp, limit, isNeedToRevert }) => ({
        method: "GET",
        url: `/networks/${network}/pools/${pool_address}/ohlcv/${timeframe}?aggregate=${aggregate}&before_timestamp=${before_timestamp}&limit=${limit}${
          isNeedToRevert ? "&token=quote" : ""
        }`,
      }),
    }),
    getLastTrades: builder.query<GetLastTradesResult, GetLastTradesParams>({
      query: ({ network, poolAddress }) => ({
        method: "GET",
        url: `/networks/${network}/pools/${poolAddress}/trades`,
      }),
    }),
    getBiggestTokenPairPool: builder.query<GetBiggestTokenPairPoolResult, GetBiggestTokenPairPoolParams>({
      queryFn: queryFnFactory<GetBiggestTokenPairPoolParams, GetBiggestTokenPairPoolResult>(
        async ({ chainId, fromTokenAddress, toTokenAddress }, api) => {
          console.log("api", { chainId, fromTokenAddress, toTokenAddress });

          const fromTokenPoolsList = await api.dispatch(
            geckoTerminalApi.endpoints.getTop20TokenPools.initiate({
              network: GECKOTERMINAL_CHAIN_IDS[chainId],
              token_address: fromTokenAddress,
            })
          );

          const toTokenPoolsList = await api.dispatch(
            geckoTerminalApi.endpoints.getTop20TokenPools.initiate({
              network: GECKOTERMINAL_CHAIN_IDS[chainId],
              token_address: toTokenAddress,
            })
          );

          const allPoolsList = [...(fromTokenPoolsList.data?.data || []), ...(toTokenPoolsList.data?.data || [])];

          const tokensPairPoolsList = allPoolsList.filter((pool) => {
            const [fromToken, toToken, extraToken] = pool?.attributes?.name?.split("/") || "";

            if (extraToken) return false;
            if (!fromToken || !toToken) return false;

            const currentPoolFromTokenId = pool.relationships.base_token.data.id;
            const currentPoolToTokenId = pool.relationships.quote_token.data.id;

            const isCurrentPoolWorksForOurTokenPair =
              (currentPoolFromTokenId.toLowerCase().includes(toTokenAddress.toLowerCase()) ||
                currentPoolFromTokenId.toLowerCase().includes(fromTokenAddress.toLowerCase())) &&
              (currentPoolToTokenId.toLowerCase().includes(toTokenAddress.toLowerCase()) ||
                currentPoolToTokenId.toLowerCase().includes(fromTokenAddress.toLowerCase()));

            return isCurrentPoolWorksForOurTokenPair;
          });

          const pool = tokensPairPoolsList.length
            ? tokensPairPoolsList.reduce((prev, cur) => {
                const prevReserve = parseFloat(prev.attributes.reserve_in_usd);
                const currentReserve = parseFloat(cur.attributes.reserve_in_usd);
                return prevReserve > currentReserve ? prev : cur;
              })
            : undefined;

          if (pool) {
            const poolData = await api.dispatch(
              geckoTerminalApi.endpoints.getPoolData.initiate({
                network: GECKOTERMINAL_CHAIN_IDS[chainId],
                pool_address: pool.attributes.address,
              })
            );

            const baseToken = poolData?.data?.included[0]?.attributes?.symbol || "";
            const baseTokenAddress = poolData?.data?.included[0]?.attributes?.address || "";

            const quoteTokenId = poolData?.data?.data?.relationships?.quote_token?.data?.id;
            const splitQuoteTokenId = quoteTokenId?.split("_");
            const quoteTokenAddress = splitQuoteTokenId?.length > 1 ? splitQuoteTokenId[1] : "";

            const currentPool = {
              ...pool,
              isNeedToRevert: isStableCoin(baseToken),
              baseTokenAddress,
              quoteTokenAddress,
            };

            return { data: currentPool };
          } else {
            return { data: undefined };
          }
        }
      ),
    }),
    getTokenPairChartData: builder.query<GetTokenPairChartDataResult, GetTokenPairChartDataParams>({
      queryFn: queryFnFactory<GetTokenPairChartDataParams, GetTokenPairChartDataResult>(
        async (
          {
            fromTokenAddress,
            toTokenAddress,
            toTokenSymbol,
            fromTokenSymbol,
            chainId,
            tvTimeframe,
            before_timestamp,
            limit,
          },
          api
        ) => {
          const pool = await api.dispatch(
            geckoTerminalApi.endpoints.getBiggestTokenPairPool.initiate({
              chainId,
              fromTokenAddress,
              toTokenAddress,
              fromTokenSymbol,
              toTokenSymbol,
            })
          );

          if (parseFloat(pool?.data?.data?.attributes?.reserve_in_usd || "0") < MINIMAL_POOL_USD_RESERVE)
            return { data: [] } as GetTokenPairChartDataResult;

          const pool_address = pool?.data?.data ? String(pool.data.data.attributes.address) : undefined;
          const isNeedToRevert = pool?.data?.data.isNeedToRevert;

          if (pool_address) {
            const network = GECKOTERMINAL_CHAIN_IDS[chainId];
            const { aggregate, timeframe } = getTimeframe(tvTimeframe);

            const barChartOHLVSDRequestData = await api.dispatch(
              geckoTerminalApi.endpoints.getOHLCVSData.initiate({
                network,
                pool_address,
                timeframe,
                aggregate,
                before_timestamp,
                limit,
                isNeedToRevert,
              })
            );

            const tokenPairChartData = barChartOHLVSDRequestData?.data?.data?.attributes?.ohlcv_list;

            if (tokenPairChartData) {
              return { data: [...tokenPairChartData] };
            } else {
              return { data: [] } as GetTokenPairChartDataResult;
            }
          } else {
            return { data: [] } as GetTokenPairChartDataResult;
          }
        }
      ),
    }),
  }),
});
