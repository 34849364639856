import { ethers } from "ethers";

export const isIpfsUrl = (url: string) => url.startsWith("ipfs://");
export const isEnsUrl = (url: string) => url.endsWith(".eth");
export const getHttpUrlFromIpfsUrl = (ipfsUrl: string) => ipfsUrl.replace("ipfs://", "https://ipfs.io/ipfs/");
export const getHttpUrlFromEnsUrl = async (ensUrl: string) => {
  const provider = ethers.getDefaultProvider(1);
  const resolver = await provider.getResolver(ensUrl);
  if (!resolver) throw new Error("Invalid ens");
  const contentHash = await resolver.getContentHash();
  if (isIpfsUrl(contentHash)) return getHttpUrlFromIpfsUrl(contentHash);
  return contentHash;
};

export const getWsUrl = (host: string) => {
  const protocol = window.location.protocol.slice(0, -1) === "http" ? "ws" : "wss";
  return `${protocol}://${host}`;
};

export const getHttpUrl = (host: string) => {
  const protocol = window.location.protocol.slice(0, -1) === "http" ? "http" : "https";
  return `${protocol}://${host}`;
};
