import { AnbotoSubscription } from "../anboto-subscription";
import { CefiAccountMessage } from "../types";

export class AccountSubsctiption extends AnbotoSubscription<string, CefiAccountMessage> {
  getId = () => "";

  getIdFromMsg = () => "";

  transformMsg = ({ message }) => message;
}
