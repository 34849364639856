import React from "react";
import _omit from "lodash/omit";
import { TableRow, TableRowProps } from "@src/components/orders-table";

export const HeaderRow = (props: { sortId?: string; sortDesc?: boolean } & TableRowProps) => {
  return <TableRow {..._omit(props, ["sortId", "sortDesc"])} />;
};

export const MemoizedHeaderRow = React.memo(
  HeaderRow,
  (prev, next) => prev.sortDesc === next.sortDesc && prev.sortId === next.sortId && prev.loading === next.loading
);

MemoizedHeaderRow.displayName = "MemoizedHeaderRow";
