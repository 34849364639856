import React from "react";
import { Link, Alert, DialogProps, Stack } from "@mui/material";
import * as uuid from "uuid";
import _isEmpty from "lodash/isEmpty";
import { useSnackbar } from "notistack";
import { SettingsDialog } from "../settings-dialog";
import { ExchangeConfigListItem } from "../exchange-settings/ExchangeConfigListItem";
import { ExchangeConfigRow, ExchangeConfigRowRef } from "../exchange-settings/exchange-config-row";
import { ExchangeConfigFormData } from "../exchange-settings/ExchangeConfigFormData";
import { ExchangeIps } from "../exchange-ips";
import { DialogBeforeDelete } from "./delete-dialog";
import { description } from "./description";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { CefiExchangeId, Exchange, ExchangeConfig } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { DescriptionArea } from "@src/components/description-area";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";
import { setExchangeApiKeyError } from "@src/store/slices/uiSettingsSlice";
import { useAppDispatch } from "@src/store/hooks";
import { B2C2_ACCOUNT_TYPE } from "@src/features/otc/constants";

type ExchangeKeysDialogProps = DialogProps & {
  onClose(): void;
  exchange: Exchange;
  configItems?: ExchangeConfig[];
  configItemsUpdated?: boolean;
};

export const ExchangeKeysDialog = ({
  onClose,
  exchange,
  configItems = [],
  configItemsUpdated,
  ...props
}: ExchangeKeysDialogProps) => {
  const snackbar = useSnackbar();

  const [deleteExchangeConfig, deleteExchangeConfigMutation] = anbotoApi.useDeleteExchangeConfigMutation();
  const [createExchangeConfig, createExchangeConfigMutation] = anbotoApi.useCreateExchangeConfigMutation();
  const [updateExchangeConfig, updateExchangeConfigMutation] = anbotoApi.useUpdateExchangeConfigMutation();

  const [exchangeConfigList, setExchangeConfigList] = React.useState<ExchangeConfigListItem[]>([]);
  const [submitDisabled, setSubmitDisabled] = React.useState(true);
  const [deleteSubId, setDeleteSubId] = React.useState("");

  const [dialogRemoveAll, setDialogRemoveAll] = React.useState(false);
  const [dialogRemoveSub, setDialogRemoveSub] = React.useState(false);
  const [subaccountDeleteConfig, setSubaccountDeleteConfig] = React.useState<ExchangeConfigListItem | null>(null);

  const configFormRefs = React.useRef<Record<string, React.RefObject<ExchangeConfigRowRef>>>({});

  const deleteLoading = deleteExchangeConfigMutation.isLoading;
  const saveLoading =
    createExchangeConfigMutation.isLoading || updateExchangeConfigMutation.isLoading || !configItemsUpdated;

  const getItemDataFromConfig = (config: ExchangeConfig) => ({
    apiKey: config.api_keys_stars.api_key,
    secretKey: config.api_keys_stars.secret_key,
    subaccountTitle: config.subaccount_title || "",
    exchange: config.exchange,
    id: config.uuid,
    listId: config.uuid,
    password: config.api_keys_stars.password || "",
    error: config.error,
  });

  const addFormRef = (id: string) => (configFormRefs.current[id] = React.createRef<ExchangeConfigRowRef>());

  const getAccountObject = (subaccount?: boolean, subaccountName?: string) => {
    return {
      apiKey: "",
      secretKey: "",
      subaccountTitle: subaccount ? subaccountName || "New sub account" : "",
      exchange: exchange.id,
      listId: uuid.v4() as string,
    };
  };

  const onAccountAddClick = (subaccount?: boolean, subaccountName?: string) => {
    const subaccountExchangeConfigListItem = getAccountObject(subaccount, subaccountName);
    const configList = subaccount ? exchangeConfigList : [];

    setExchangeConfigList([...configList!, subaccountExchangeConfigListItem]);
    addFormRef(subaccountExchangeConfigListItem.listId);
  };

  const handleSubAccountDelete = async (config: ExchangeConfigListItem) => {
    if (!configItems.find((item) => item.uuid === config.id)) {
      setExchangeConfigList(exchangeConfigList!.filter((x) => x.listId !== config.listId));
      delete configFormRefs.current[config.listId];
    } else if (config.id) {
      try {
        setDeleteSubId(config.id);
        await deleteExchangeConfig(config.id).unwrap();
      } catch (error) {
        console.log(error);
      } finally {
        setDeleteSubId("");
      }
    }
  };

  const handleDeleteAll = async () => {
    try {
      await Promise.all(configItems.map(({ uuid }) => deleteExchangeConfig(uuid).unwrap()));
      configFormRefs.current = {};
      snackbar.enqueueSnackbar("Exchange config has been deleted successfully", { variant: "success" });
    } catch (e) {
      snackbar.enqueueSnackbar("Failed to delete exchange config", { variant: "error" });
    }
  };

  const updateConfig = async (config: ExchangeConfig, data: ExchangeConfigFormData) =>
    updateExchangeConfig({
      exchange: config.exchange,
      id: config.uuid,
      ...data,
    }).unwrap();

  const createConfig = async (data: ExchangeConfigFormData) =>
    createExchangeConfig({ exchange: exchange.id, ...(data as ExchangeConfigFormData) }).unwrap();

  const [validateApikey] = anbotoApi.useLazyValidateApiKeyQuery();
  const dispatch = useAppDispatch();

  const handleSave = async () => {
    const configsToUpdate: Record<string, Partial<ExchangeConfigFormData>> = {};
    let hasErrors = false;

    try {
      await Promise.all(
        Object.keys(configFormRefs.current).map(async (key) => {
          const form = configFormRefs.current[key]?.current?.getForm();

          await form?.handleSubmit(
            (formData: ExchangeConfigFormData) => {
              const preparedData = {};

              Object.keys(formData).forEach((key) => {
                if (formData[key]) preparedData[key] = formData[key];
                if (
                  exchange.exchange_id === CefiExchangeId.B2C2 &&
                  key === "secretKey" &&
                  !formData[key]["secretKey"]
                ) {
                  preparedData["secretKey"] = "secretKey";
                }
              });

              if (!_isEmpty(form.formState.touchedFields) && !_isEmpty(preparedData)) {
                configsToUpdate[key] = preparedData;
              }
            },
            (formErrors) => {
              !_isEmpty(formErrors) && (hasErrors = true);
            }
          )();
        })
      );
    } catch (error) {
      console.log(error);
      return;
    }

    if (!hasErrors && !_isEmpty(configsToUpdate)) {
      const updates = [];

      for (const [id, data = {}] of Object.entries(configsToUpdate)) {
        const configListItem = configItems.find((el) => el.uuid === id);

        if (configListItem) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          updates.push(updateConfig(configListItem, data));
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          updates.push(createConfig(data));
        }
        // configFormRefs.current[id]?.current?.resetForm();
      }

      if (updates.length) {
        try {
          const results = await Promise.all<ExchangeConfig>(updates);

          let isValidationApiKeyErrors = false;
          for (let i = 0; i < results.length; i++) {
            const validationResult = await validateApikey({ api_uuid: results[i]?.uuid || "" });

            if (validationResult?.data?.error && results[i]?.uuid) {
              isValidationApiKeyErrors = true;
              dispatch(
                setExchangeApiKeyError({
                  [results[i]?.uuid]: {
                    type: Object.keys(validationResult.data.error)[0],
                    text: Object.values(validationResult.data.error)[0],
                  },
                })
              );
              snackbar.enqueueSnackbar(
                Object.keys(validationResult.data.error)[0]
                  .split(/(?=[A-Z])/)
                  .join(" ") +
                  " " +
                  Object.values(validationResult.data.error)[0],
                { variant: "error" }
              );
            }
          }
          const itemWithError = results?.find((x) => !!x.error);
          if (itemWithError) {
            snackbar.enqueueSnackbar(itemWithError?.error?.type + " " + itemWithError?.error?.text, {
              variant: "error",
            });
          } else if (!isValidationApiKeyErrors) {
            snackbar.enqueueSnackbar("Exchange config has been updated successfully", { variant: "success" });
          }
          setSubmitDisabled(true);
        } catch (error) {
          console.log(error);
          snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data), { variant: "error" });
        }
      }
    }
  };

  React.useEffect(() => {
    const items: ExchangeConfigListItem[] = [];
    if (!configItemsUpdated) return;
    setExchangeConfigList([]);
    configFormRefs.current = {};
    const isB2C2 = exchange.exchange_id === CefiExchangeId.B2C2;

    if (isB2C2 && !configItems.length) {
      const configList = [
        getAccountObject(true, B2C2_ACCOUNT_TYPE.SPOT),
        getAccountObject(true, B2C2_ACCOUNT_TYPE.CFD),
      ];

      setExchangeConfigList(configList);
      configList.forEach(({ listId }) => addFormRef(listId));

      return;
    }

    if (
      !configItems.length ||
      (!isB2C2 && exchange.is_subaccounts_support && !configItems.find((el) => !el.subaccount_title))
    ) {
      onAccountAddClick();
    }

    if (configItems.length) {
      configItems.forEach((config) => {
        const item = getItemDataFromConfig(config);
        items.push(item);
        addFormRef(item.listId);
      });

      if (isB2C2 && configItems.length < 2) {
        if (!configItems.find((el) => el.subaccount_title === B2C2_ACCOUNT_TYPE.SPOT)) {
          items.unshift(getAccountObject(true, B2C2_ACCOUNT_TYPE.SPOT));
        } else {
          items.push(getAccountObject(true, B2C2_ACCOUNT_TYPE.CFD));
        }

        items.forEach(({ listId }) => {
          if (!configFormRefs.current[listId]) {
            addFormRef(listId);
          }
        });
      }
      setExchangeConfigList((state) => [...state, ...items].sort((item) => (!item.subaccountTitle ? -1 : 1)));
    }
  }, [configItemsUpdated]);

  return (
    <SettingsDialog
      title={getExchangeNameLabel(exchange?.name) || ""}
      onClose={onClose}
      onOverlayClick={onClose}
      {...props}
      contentSx={{
        height: "100%",
      }}
      dialogContentSx={{
        maxHeight: "100%",
      }}
    >
      <Stack flex={1} height="100%" position="relative">
        <Stack overflow="auto" pb={2} ml={-1} mr={-2} pl={1} pr={1}>
          {exchangeConfigList.map((config, index) => (
            <ExchangeConfigRow
              disableName={exchange.exchange_id === CefiExchangeId.B2C2}
              hideSecret={exchange.exchange_id === CefiExchangeId.B2C2}
              hideTitleDescription={exchange.exchange_id === CefiExchangeId.B2C2}
              optional={(exchange.is_subaccounts_support && !index) || exchange.exchange_id === CefiExchangeId.B2C2}
              key={config.listId}
              ref={configFormRefs.current[config.listId]}
              exchange={exchange}
              config={config}
              onSubaccountAddClick={() => onAccountAddClick(true)}
              onSubaccountDeleteClick={() => {
                setDialogRemoveSub(true);
                setSubaccountDeleteConfig(config);
              }}
              onChange={() => setSubmitDisabled(false)}
              deleteLoading={deleteSubId === config.id}
              hideDelete={!config.apiKey && exchange.exchange_id === CefiExchangeId.B2C2}
            />
          ))}
          {exchange.exchange_id === CefiExchangeId.WOOX && (
            <Alert severity="warning" sx={{ marginTop: 1 }}>
              Reminder! To trade futures on Anboto, enable &quot;Trade Spot & Futures&quot; under{" "}
              <Link href="https://x.woo.org/en/account/spot-margin" target="_blank" rel="noreferrer">
                Margin & Futures
              </Link>{" "}
              on your WOO X account.
            </Alert>
          )}
          {(description[exchange.exchange_id]?.content || description[exchange.exchange_id]?.video) && (
            <DescriptionArea
              videoUrl={description[exchange.exchange_id]?.video}
              withExpand
              expanded={exchangeConfigList[0]?.apiKey === ""}
            >
              {description[exchange.exchange_id]?.content}
            </DescriptionArea>
          )}
        </Stack>
        <ExchangeIps exchange={exchange.exchange_id} />
        <Stack direction="row" justifyContent="space-between" width="100%" mt={2}>
          {configItems.length > 0 && (
            <AnbotoButton
              variant="outlined"
              color="secondary"
              size="small"
              disabled={!configItems.length}
              onClick={() => setDialogRemoveAll(true)}
              loading={deleteLoading}
            >
              Remove all account
            </AnbotoButton>
          )}
          <AnbotoButton
            disabled={submitDisabled}
            sx={{ minWidth: 160, mr: 0, ml: "auto" }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSave}
            loading={saveLoading}
          >
            Save
          </AnbotoButton>
        </Stack>
      </Stack>

      <DialogBeforeDelete open={dialogRemoveAll} setOpen={setDialogRemoveAll} deleteFunction={handleDeleteAll} />
      <DialogBeforeDelete
        open={dialogRemoveSub}
        setOpen={setDialogRemoveSub}
        deleteFunction={() => handleSubAccountDelete(subaccountDeleteConfig!)}
      />
    </SettingsDialog>
  );
};
