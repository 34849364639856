import React, { FC } from "react";
import { Box, styled } from "@mui/material";
import { SubLabel, ThemeSx } from "@src/pages/portfolio";

export interface InputProgressProps {
  step: number;
  sx?: Record<string, string | number | ThemeSx>;
}

export const InputProgress: FC<InputProgressProps> = ({ step, sx }) => {
  return (
    <Box sx={{ fontSize: 14, lineHeight: "21px", fontWeight: 400, ...sx }}>
      <Box display="flex" justifyContent="space-between" gap={0.5} width="100%" paddingBottom={0.5}>
        <Progress
          sx={{
            backgroundColor: (theme) =>
              step === 1 || step === 2
                ? theme.custom.background.progress.ready
                : theme.custom.background.progress.notReady,
          }}
        />
        <Progress
          sx={{
            backgroundColor: (theme) =>
              step === 2 ? theme.custom.background.progress.ready : theme.custom.background.progress.notReady,
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" gap={0.5} width="100%">
        <SubLabel>Input information</SubLabel>
        <strong>1/2</strong>
      </Box>
    </Box>
  );
};

const Progress = styled(Box)({
  height: "4px",
  width: "100%",
});
