import { useEffect, useState } from "react";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export const useWalletName = (address: string | null | undefined) => {
  const { data } = anbotoApi.useGetWalletsQuery(null);
  const [name, setName] = useState("");

  useEffect(() => {
    if (!address) {
      return;
    }

    const wallet = data?.results?.find((wallet) => wallet.address?.toLowerCase() === address.toLowerCase());

    if (wallet) {
      setName(wallet.name);
    } else {
      setName("");
    }
  }, [address, data?.results]);

  return { name, address };
};
