import { AccountSubsctiption } from "./anboto-portfolio-subscription";

const subscriptions: Record<string, AccountSubsctiption> = {};

(window as any).accountSubs = subscriptions;

export const getPortfolioSubscription = (accountId: string) => {
  if (!accountId) throw new Error("Portfolio subscription: accountId is not specified");

  if (!subscriptions[accountId]) {
    subscriptions[accountId] = new AccountSubsctiption({
      url: `/stream/portfolio/${accountId}/`,
      auth: true,
    });
  }

  return subscriptions[accountId];
};
