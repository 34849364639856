import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

const ComparativeChartTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  const theme = useTheme();

  if (active && payload && payload.length) {
    const value = payload?.[0]?.payload?.rate;

    return (
      <Box sx={{ background: theme.custom.colors.gray, padding: "7px 20px 10px 20px", borderRadius: 1 }}>
        <Typography padding={0} margin={0} variant="caption">
          <Typography padding={0} margin={0} variant="caption" color={theme.palette.text.secondary}>
            {payload[0].payload.symbol}:
          </Typography>{" "}
          {value ? parseFloat((Number(value) * 100).toFixed(4)) : "0"} %
        </Typography>
      </Box>
    );
  }

  return null;
};

export default ComparativeChartTooltip;
