import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Box } from "@mui/material";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { useSendCryptoFormContext } from "@src/pages/portfolio/components/send-crypto/hooks";

export interface PasteOrSelectAddressProps {
  onClose(): void;
  placeholder?: string;
}

export function PasteOrSelectAddress(props: PasteOrSelectAddressProps) {
  const sendCryptoForm = useSendCryptoFormContext();
  const { onClose, placeholder } = props;
  const { trigger } = sendCryptoForm;
  const account = sendCryptoForm.watch("accountTo") as string;

  const validate = async () => {
    if (sendCryptoForm?.formState?.dirtyFields?.accountTo) {
      const validationResult = await trigger("accountTo");

      if (validationResult) {
        onClose();
      }
    }
  };

  useEffect(() => {
    void validate();
  }, [account]);

  return (
    <Box>
      <Controller
        name="accountTo"
        control={sendCryptoForm.control}
        render={({ field, fieldState, formState }) => (
          <AnbotoTextField
            {...field}
            fullWidth
            placeholder={placeholder}
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            disabled={formState.isSubmitting}
            sx={{
              "& .MuiOutlinedInput-input.MuiInputBase-input": {
                fontSize: 14,
                lineHeight: "21px",
                fontWeight: 400,
              },
            }}
          />
        )}
      />
    </Box>
  );
}
