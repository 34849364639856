import React from "react";
import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { MAX_LAYOUTS } from "../../constants";
import { useAppSelector } from "@src/store/hooks";

export const SaveButton = ({ onClick, ...props }: { onClick: () => void } & IconButtonProps) => {
  const layouts = useAppSelector((state) => state.user.user_layouts) || [];

  return layouts.length < MAX_LAYOUTS ? (
    <Tooltip title="Save">
      <IconButton onClick={() => onClick()} sx={{ color: (theme) => theme.palette.text.secondary }} {...props}>
        <SaveOutlinedIcon sx={{ fontSize: 18 }} />
      </IconButton>
    </Tooltip>
  ) : null;
};
