export const formatLargeNumber = (number: number): string => {
  if (number >= 1000000000) {
    return (number / 1000000000).toFixed(1) + "B";
  } else if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K";
  } else {
    return number.toFixed();
  }
};

const MASK_CHARS = "***";
export const maskString = (inputString: string): string => {
  if (inputString.length < 6) {
    return inputString;
  }

  return inputString.slice(0, 3) + MASK_CHARS + inputString.slice(-3);
};

export const getRankingNumberColor = (ranking: number): string =>
  ranking === 1 ? "#ECAC27" : ranking === 2 ? "#8A9296" : ranking === 3 ? "#2C72E3" : "2C72E3";
