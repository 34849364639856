import { anbotoApi } from "@src/store/apis/anbotoApi";

export type GetPortfolioCefiErrorsParams = {
  exchange_list_ids: string; // binance,bybit
};

export type PortfolioCefiError = {
  market_type: string; // "future";
  exchange_id: string; // "binance";
  subaccount: string; // "s";
  error_text: string; // 'binance {"code":-2015,"msg":"Invalid API-key, IP, or permissions for action, request ip: 18.176.134.207"}';
  error_type: string; // "AuthenticationError";
  latest_update: string; // "2023-01-25T14:14:31.521941Z";
};

export type GetPortfolioCefiErrorsResult = {
  success: boolean;
  data: PortfolioCefiError[];
};

const portfolioCefiErrorsApi = anbotoApi.injectEndpoints({
  endpoints: (build) => ({
    portfolioCefiErrors: build.query<GetPortfolioCefiErrorsResult, GetPortfolioCefiErrorsParams>({
      query: (params) => ({
        url: `/portfolio/cefi_errors/`,
        params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const portfolioCefiErrors = portfolioCefiErrorsApi.endpoints.portfolioCefiErrors;

export const { usePortfolioCefiErrorsQuery, useLazyPortfolioCefiErrorsQuery } = portfolioCefiErrorsApi;
