import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";

export const APP_NAME = "Anboto";

export const LAYOUT_GAP = 0.5;
export const DEFAULT_UI_VERSION = 3;
export const LAYOUT_MIN_WIDTH = 1200;
export const OTP_EXPIRE_DAYS = 7;

export const CEFI_EXCHANGE_NAME: Record<CefiExchangeId, string> = {
  [CefiExchangeId.BINANCE]: "Binance",
  [CefiExchangeId.BYBIT]: "Bybit",
  [CefiExchangeId.COINBASE]: "Coinbase",
  [CefiExchangeId.GATEIO]: "Gate.io",
  [CefiExchangeId.KRAKEN]: "Kraken",
  [CefiExchangeId.KUCOIN]: "Kucoin",
  [CefiExchangeId.OKX]: "OKX",
  [CefiExchangeId.ASCENDEX]: "Ascendex",
  [CefiExchangeId.HUOBI]: "Huobi",
  [CefiExchangeId.BITMEX]: "Bitmex",
  [CefiExchangeId.WOOX]: "WOOX",
  [CefiExchangeId.BITGET]: "Bitget",
  [CefiExchangeId.MEXC]: "MEXC",
  [CefiExchangeId.B2C2]: "B2C2",
};

export const CEFI_EXCHANGE_COLORS: Record<CefiExchangeId, string> = {
  [CefiExchangeId.BINANCE]: "#f0ba0c",
  [CefiExchangeId.KRAKEN]: "#5841d8",
  [CefiExchangeId.HUOBI]: "#3f8cd6",
  [CefiExchangeId.ASCENDEX]: "#7637ba",
  [CefiExchangeId.OKX]: "white",
  [CefiExchangeId.COINBASE]: "#3773f5",
  [CefiExchangeId.BYBIT]: "#f7a600",
  [CefiExchangeId.GATEIO]: "#2354e6",
  [CefiExchangeId.KUCOIN]: "#2dbd96",
  [CefiExchangeId.BITMEX]: "#2dbd96",
  [CefiExchangeId.WOOX]: "#07080a",
  [CefiExchangeId.BITGET]: "cyan",
  [CefiExchangeId.MEXC]: "#4281ff",
  [CefiExchangeId.B2C2]: "#7637ba",
};

export const URL_SYMBOL_DELIMITER = "_";

export const TRANSPARENT_COLOR = "transparent";

export const DOCS_LINK = "https://anboto-labs.gitbook.io/anboto-trading-guide/dW6iirLsEKYyUXsHRqeu/";

export const VIEW_ONLY_MODE_DESCRIPTION =
  "You are currently restricted from trading. Please contact your account manager to have permissions.";
