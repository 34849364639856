import React, { useMemo } from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import TradesChart from "@src/pages/metrics/components/trades-chart/trades-chart";
import SideChart from "@src/pages/metrics/components/side-chart/side-chart";
import { PieChartCard } from "@src/pages/portfolio/components/charts/pie-chart-card";
import TakerVsMakerChart from "@src/pages/metrics/components/taker-vs-maker-chart/taker-vs-maker-chart";
import TradesFilters from "@src/pages/metrics/components/trade-filters/trades-filters";
import TradesSummary from "@src/pages/metrics/components/trades-summary/trades-summary";
import {
  Metrics,
  MetricsTradesGrouped,
  MonthlySummaryTableItem,
  SideChartItem,
  TradesChartData,
} from "@src/store/apis/anbotoApi/types";
import MonthlySummaryTable from "@src/pages/metrics/components/monthly-summary-table/monthly-summary-table";

type TradesMetricsProps = {
  metrics: Metrics;
  metricsWithRange: MetricsTradesGrouped;
};
const EXHANGE_ALLOCATION_COUNT = 5;

const TradesMetrics = ({ metrics, metricsWithRange }: TradesMetricsProps) => {
  const theme = useTheme();
  const isXlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const tradingTypePieChartData = useMemo(() => {
    const defiValue = metrics.is_defi?.True?.executed_usd || 0;
    const cefiValue = metrics.is_defi?.False?.executed_usd || 0;

    return [
      {
        name: "DEFI",
        value: defiValue,
      },
      { name: "CEFI", value: cefiValue },
    ];
  }, [metrics?.overall]);

  const exchangesPieChartData = useMemo(() => {
    const sortedMetricsInfo = Object.keys(metrics?.exchange_id || {})
      .map((exchange) => ({
        name: exchange,
        value: metrics?.exchange_id[exchange]?.executed_usd || 0,
      }))
      .sort((a, b) => b.value - a.value);

    let othersSum = 0;
    for (let i = EXHANGE_ALLOCATION_COUNT; i < sortedMetricsInfo.length; i++) {
      othersSum += sortedMetricsInfo[i].value;
    }

    return [
      ...sortedMetricsInfo.slice(0, EXHANGE_ALLOCATION_COUNT),
      ...(othersSum > 0
        ? [
            {
              name: "Others",
              value: othersSum,
            },
          ]
        : []),
    ];
  }, [metrics]);

  const takerVsMaker = useMemo(
    () => ({
      taker_value: metrics?.taker_or_maker?.taker?.executed_usd || 0,
      maker_value: metrics?.taker_or_maker?.maker?.executed_usd || 0,
      taker:
        ((metrics?.taker_or_maker?.taker?.executed_usd || 0) /
          ((metrics?.taker_or_maker?.taker?.executed_usd || 0) + (metrics?.taker_or_maker?.maker?.executed_usd || 0))) *
        100,
      maker:
        ((metrics?.taker_or_maker?.maker?.executed_usd || 0) /
          ((metrics?.taker_or_maker?.taker?.executed_usd || 0) + (metrics?.taker_or_maker?.maker?.executed_usd || 0))) *
        100,
    }),
    [metrics]
  );
  const strategies = useMemo(() => Object.keys(metrics?.strategy || []), [metrics]);
  const exchanges = useMemo(() => Object.keys(metrics?.exchange_id || []), [metrics]);
  const assetClasses = useMemo(() => Object.keys(metrics?.asset_class || []), [metrics]);

  const dates = useMemo(
    () => Object.keys(metricsWithRange).sort((a, b) => new Date(a).getTime() - new Date(b).getTime()),
    [metricsWithRange]
  );

  const sideChartData: SideChartItem[] = useMemo(() => {
    const data: SideChartItem[] = [];

    dates.forEach((date) => {
      const tradeResult = metricsWithRange[date];

      data.push({
        buy: tradeResult?.side?.buy?.executed_usd || 0,
        sell: -tradeResult?.side?.sell?.executed_usd || 0,
        gross: tradeResult?.side?.gross?.executed_usd || 0,
        net: tradeResult?.side?.net?.executed_usd || 0,
        date: new Date(date).getTime(),
      });
    });

    return [...data];
  }, [metricsWithRange]);

  const tradesChartData: TradesChartData[] = useMemo(
    () =>
      dates.reduce<TradesChartData[]>((acc, currentDate, i) => {
        const tradeResult = metricsWithRange[currentDate];
        const prevTradeDateValue = i > 0 ? acc[i - 1].cumulativeSum : 0;
        const cumulativeSum = (tradeResult?.overall?.executed_usd || 0) + prevTradeDateValue;
        const trades = tradeResult?.overall?.executed_usd || 0;

        acc.push({
          trades,
          cumulativeSum,
          date: new Date(currentDate).getTime(),
        });

        return acc;
      }, []),
    [metricsWithRange]
  );

  const thisMonthTradingVolume = useMemo(
    () => metricsWithRange[dates[dates.length - 1]]?.overall?.executed_usd || 0,
    []
  );

  const totalTradingVolume = useMemo(
    () => Object.values(metricsWithRange).reduce((acc, cur) => acc + (cur.overall?.executed_usd || 0), 0),
    []
  );

  const monthlySummaryTableData = useMemo(
    () =>
      dates
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
        .reduce<MonthlySummaryTableItem[]>((acc, currentDate, index) => {
          const tradeResult = metricsWithRange[currentDate];
          const tradedValue = tradeResult?.overall?.executed_usd || 0;
          const accumulatedVolume = (acc[index - 1]?.accumulatedVolume || 0) + tradedValue;
          const tradedValueCumulative = index
            ? totalTradingVolume - (acc[index - 1]?.accumulatedVolume || 0)
            : totalTradingVolume;

          acc.push({
            tradedValue,
            tradedValueCumulative,
            date: +currentDate.replaceAll("-", ""),
            month: new Date(currentDate).toLocaleString("default", { month: "long", year: "numeric" }),
            buyValue: tradeResult?.side?.buy?.executed_usd || 0,
            sellValue: tradeResult?.side?.sell?.executed_usd || 0,
            tradedNet: tradeResult?.side?.net?.executed_usd || 0,
            tradedMaker: tradeResult?.taker_or_maker?.maker?.executed_usd || 0,
            tradedTaker: tradeResult?.taker_or_maker?.taker?.executed_usd || 0,
            DefiTotal: ((tradedValue - (tradeResult?.is_defi?.False?.executed_usd || 0)) / (tradedValue || 1)) * 100,
            CefiTotal: ((tradeResult?.is_defi?.False?.executed_usd || 0) / (tradedValue || 1)) * 100,
            accumulatedVolume,
          });

          return acc;
        }, []),
    []
  );

  return (
    <>
      <Stack maxWidth="90%">
        <TradesSummary thisMonthVolume={thisMonthTradingVolume} totalVolume={totalTradingVolume} />
        <Stack maxWidth="99%" direction={isXlUp ? "row" : "column"} gap={isXlUp ? 0 : 2}>
          <Stack direction="column" gap={2} width={isXlUp ? "70%" : "100%"}>
            <TradesFilters assetClasses={assetClasses} strategies={strategies} exchanges={exchanges} />
            <Typography variant="h6" fontWeight="bold">
              Volume Traded (Gross)
            </Typography>
            <TradesChart tradesChartData={tradesChartData} />
            <Typography variant="h6" fontWeight="bold">
              Volume Traded (Net)
            </Typography>
            <SideChart chartData={sideChartData} />
          </Stack>
          <Stack
            width={isXlUp ? "30%" : "100$"}
            direction={isXlUp ? "column" : "row"}
            gap={2}
            justifyContent="space-between"
          >
            <PieChartCard noData isFetching={false} chartName={"Trading type"} chartData={tradingTypePieChartData} />
            <PieChartCard noData isFetching={false} chartName={"Exchanges"} chartData={exchangesPieChartData} />

            {isXlUp && <TakerVsMakerChart takerVsMakerRate={takerVsMaker} />}
          </Stack>
          {!isXlUp && (
            <Stack marginTop={2}>
              <TakerVsMakerChart takerVsMakerRate={takerVsMaker} />
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack marginTop={2}>
        <Typography variant="h6" fontWeight="bold">
          Your trading Metrics (table format)
        </Typography>
        <MonthlySummaryTable data={monthlySummaryTableData} isError={false} isFetching={false} />
      </Stack>
    </>
  );
};

export default TradesMetrics;
