import React from "react";
import { ChainId } from "@src/pages/defi/types";
import { CardDialog } from "@src/components/CardDialog";
import { SelectView } from "@src/components/select-view";

export type SelectTokenDialogProps = {
  account?: string;
  isOpen: boolean;
  onClose(): void;
  onTokenSelect(tokenAddress: string): void;
  chainId: ChainId;
};

export const SelectTokenDialog = ({ account, isOpen, onClose, onTokenSelect, chainId }: SelectTokenDialogProps) => (
  <CardDialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth sx={{ minHeight: 500 }}>
    <SelectView account={account} onTokenSelect={onTokenSelect} onClose={onClose} chainId={chainId} />
  </CardDialog>
);
