import React from "react";
import { Stack, styled, Switch, Tooltip, Typography } from "@mui/material";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { PortfolioType } from "@src/store/slices/portfolioSlice";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { PortfolioCefiError } from "@src/pages/portfolio";
import { parseApiErrors } from "@src/pages/portfolio/utils";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";

type PortfolioItemProps = {
  apiErrors: PortfolioCefiError[];
  itemId: string;
  isAddedToPortfolio: boolean;
  isActive: boolean;
  setIsActive: (active: string) => void;
  updateAddedItemList?: (exchanges: string) => void;
  portfolioType: PortfolioType;
};

const PortfolioItem = ({
  apiErrors,
  itemId,
  isAddedToPortfolio,
  isActive,
  setIsActive,
  updateAddedItemList,
  portfolioType,
}: PortfolioItemProps) => {
  const errors = parseApiErrors(apiErrors);
  const movePortfolioExchange = (e, name: string): void => {
    e.stopPropagation();

    if (updateAddedItemList) {
      updateAddedItemList(name);
    }
  };

  const [checked, setChecked] = React.useState(isAddedToPortfolio);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    movePortfolioExchange(event, itemId);
    setChecked(event.target.checked);
  };

  return (
    <PortfolioItemBox
      color="secondary"
      variant="outlined"
      onClick={() => setIsActive(itemId)}
      className={isActive ? "active" : ""}
    >
      <Stack direction="row" gap={1} maxWidth="80%" minHeight={40} alignItems="center">
        <Typography noWrap overflow="hidden" textOverflow="ellipsis" fontSize={16} fontWeight={600}>
          {portfolioType === PortfolioType.CEFI ? getExchangeNameLabel(CEFI_EXCHANGE_NAME[itemId]) : itemId}
        </Typography>

        {errors?.length > 0 && (
          <Tooltip
            title={
              <>
                {errors.map((item) => (
                  <p key={item}>{item}</p>
                ))}
              </>
            }
            sx={{ fontSize: 14 }}
            placement="right-start"
          >
            <WarningAmber sx={{ color: (theme) => theme.palette.warning.main, width: 18 }} />
          </Tooltip>
        )}
      </Stack>
      <StyledSwitchWrap onClick={(e) => e.stopPropagation()}>
        <StyledSwitch checked={checked} onChange={handleChange} />
      </StyledSwitchWrap>
    </PortfolioItemBox>
  );
};

const PortfolioItemBox = styled(AnbotoButton)(() => ({
  padding: "0 5px 0 16px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  position: "relative",
  ["&.active"]: {
    background: "#0A1C39",
    borderColor: "#2C72E3",
  },
}));

const StyledSwitchWrap = styled(Stack)({
  position: "absolute",
  right: 0,
  top: 0,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  height: "100%",
});

const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 14,
  margin: 10,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 14,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(14px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    "&.Mui-checked": {
      transform: "translateX(14px)",
      color: theme.custom.colors.secondary,
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#2F675E",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 14,
    height: 14,
    borderRadius: 7,
  },
  "& .MuiSwitch-track": {
    borderRadius: 7,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default PortfolioItem;
