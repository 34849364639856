import React from "react";
import {
  Box,
  Stack,
  TableContainer,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { feeManagerApi } from "@src/pages/settings/fee-manager/api";

export const FeeRates = () => {
  const getFeesRatesQuery = feeManagerApi.useGetFeesRatesQuery();

  const sortPerVolume = (rows) => {
    return [...rows].sort((x, y) => x.low_volume_limit - y.low_volume_limit);
  };

  return (
    <Paper>
      <Box>
        {getFeesRatesQuery.isLoading && !getFeesRatesQuery.isError ? (
          <Stack height={300} alignItems="center" justifyContent="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Box sx={{ pt: 4 }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    width: 640,
                    marginBottom: 10,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Level</TableCell>
                      <TableCell align="right">30-day trading volume (USD)</TableCell>
                      <TableCell align="right">Fee rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortPerVolume(getFeesRatesQuery?.data?.cefi || []).map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 }, border: 0, elevation: 0 }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          {row.low_volume_limit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </TableCell>
                        <TableCell align="right">{Math.round(row.fee * 10000 * 100) / 100} bps</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        )}
      </Box>
    </Paper>
  );
};
