export enum ActionsTypes {
  SET_USER = "SET_USER",
  LOG_OUT_USER = "LOG_OUT_USER",
  PUT_USER_PREFERENCES = "PUT_USER_PREFERENCES",
  SET_USER_PREFERENCES = "SET_USER_PREFERENCES",
  ENABLE_USER_OTP = "ENABLE_USER_OTP",
  DISABLE_USER_OTP = "DISABLE_USER_OTP",
  CHANGE_USER_TEAM = "CHANGE_USER_TEAM",
  USER_METRICS_MONTHS = "USER_METRICS_MONTHS",
  SET_USER_METRICS_MONTHS = "SET_USER_METRICS_MONTHS",
  PUT_TOKEN_PAIR_WATCHLIST = "PUT_TOKEN_PAIR_WATCHLIST",
  SET_USER_IS_REFERRER = "SET_USER_IS_REFERRER",
  SET_USER_REFERRER_ALLOWED = "SET_USER_REFERRER_ALLOWED",
  SET_IS_REFERRAL_REQUEST_SENT = "SET_IS_REFERRAL_REQUEST_SENT",
  ENABLE_MEV_PROTECTION = "ENABLE_MEV_PROTECTION",
  SET_LIQUIDITY_SOURCES = "SET_LIQUIDITY_SOURCES",
  SET_ORDER_PREFERENCES = "SET_ORDER_PREFERENCES",
  SET_IS_DEFI_INTRO_SHOWN = "SET_IS_DEFI_INTRO_SHOWN",
  SET_IS_CEFI_INTRO_SHOWN = "SET_IS_CEFI_INTRO_SHOWN",
  SET_IS_WIDGETS_INTRO_SHOWN = "SET_IS_WIDGETS_INTRO_SHOWN",
  SET_IS_CEFI_OR_DEFI_MODAL_SHOWN = "SET_IS_CEFI_OR_DEFI_MODAL_SHOWN",
  SET_IS_INTRO_SHOWING = "SET_IS_INTRO_SHOWING",
}
