import * as React from "react";
import { Typography } from "@mui/material";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { useAnbotoTickerSubscription } from "@src/subscriptions/hooks/use-anboto-ticker-subscripton";

const formatPrice = (price: string | number) => {
  const [l, r] = `${price}`.split(".");
  const left = l.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const right = !r ? "00" : r.length < 2 ? r + "0" : r;
  return `${left}.${right}`;
};

export const LivePrice = ({
  symbol,
  exchange,
  marketType,
  fontSize,
}: {
  symbol: string;
  exchange: string;
  marketType: string;
  fontSize?: number;
}) => {
  const symbolObj = { symbol, exchange, market_type: marketType } as OrderSymbol;
  const livePrice = useAnbotoTickerSubscription(symbolObj);

  if (!livePrice) return <>-</>;

  const { price, direction } = livePrice;

  return (
    <Typography
      variant="body2"
      sx={{
        fontSize,
        color: direction === "up" ? "#009687" : direction === "down" ? "#cf3844" : "#8A9296",
      }}
    >
      {price ? formatPrice(price) : "-"}
    </Typography>
  );
};
