import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, Grid, InputAdornment, Stack, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  ChangePasswordFormFieldValues,
  useChangePasswordForm,
} from "@src/pages/settings/change-password/useChangePasswordForm";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { StyledLinearProgress, getStyledIcon } from "@src/pages/auth/registration/components/verification-password";
import { SettingsCard } from "@src/pages/settings/settings-card/settings-card";
import { PasswordStrength, strengthColor } from "@src/utils/password-strength";
import { useAuth } from "@src/features/auth/hooks/use-auth";
import { FormError } from "@src/pages/auth/layout/form-error";

const ChangePassword = () => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const changePasswordForm = useChangePasswordForm();
  const newPassword = changePasswordForm.watch("newPassword");
  const newPasswordRepeat = changePasswordForm.watch("newPasswordRepeat");

  const [oldPasswordVisible, setOldPasswordVisible] = useState<boolean>(true);
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { updatePassword } = useAuth();

  const [strength, setStrength] = useState<PasswordStrength>(PasswordStrength.EMPTY);

  const containLowerCase = /[a-z]/.test(newPassword) ? 1 : 0;
  const containUpperCase = /[A-Z]/.test(newPassword) ? 1 : 0;
  const containNumber = /[0-9]/.test(newPassword) ? 1 : 0;
  const containSpecialCharacter = /\W/.test(newPassword) ? 1 : 0;
  const isLongForMedium = newPassword.length > 5 ? 1 : 0;
  const isLongForHigh = newPassword.length > 9 ? 1 : 0;

  const strenthConditions =
    containLowerCase + containUpperCase + containNumber + containSpecialCharacter + isLongForMedium + isLongForHigh;

  useEffect(() => {
    if (strenthConditions === 0) {
      setStrength(PasswordStrength.EMPTY);
    } else if (strenthConditions > 0 && strenthConditions <= 2) {
      setStrength(PasswordStrength.WEAK);
    } else if (strenthConditions > 2 && strenthConditions <= 5) {
      setStrength(PasswordStrength.MEDIUM);
    } else {
      setStrength(PasswordStrength.HIGH); //matching the 6 conditions
    }
  }, [newPassword]);

  const onSubmit = async ({ oldPassword, newPassword }: ChangePasswordFormFieldValues) => {
    setError("");
    setLoading(true);
    try {
      await updatePassword({ oldPassword, newPassword });

      snackbar.enqueueSnackbar("Password has been changed", {
        variant: "success",
      });

      changePasswordForm.reset();
    } catch (e) {
      e.message && setError(e.message);

      snackbar.enqueueSnackbar(e?.message || "Some error occurred", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingsCard
      headerProps={{
        title: "Reset Password",
        description: "",
      }}
      footer={
        <Box display="flex" width="100%" justifyContent="space-between" py={2}>
          <Box>
            <Typography variant="caption" color="text.secondary" fontSize={16}>
              To make sure your account is secure, youll be logged out from <br /> other devices once you set the new
              new password
            </Typography>
          </Box>
          <Box p={1}>
            <AnbotoButton
              variant="contained"
              sx={{
                width: 240,
              }}
              onClick={changePasswordForm.handleSubmit(onSubmit)}
              loading={loading}
              disabled={newPassword !== newPasswordRepeat || newPassword === ""}
            >
              Reset password
            </AnbotoButton>
          </Box>
        </Box>
      }
    >
      <Stack alignItems="center" sx={{ mt: 6, mb: 8 }}>
        <Grid container direction="column" maxWidth="400px" alignItems="center" flexGrow={1}>
          <Grid item width="100%" maxWidth="500px">
            <Controller
              name="oldPassword"
              control={changePasswordForm.control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <AnbotoTextField
                  {...field}
                  data-sentry-mask="true"
                  labelStyle="dynamic"
                  fullWidth
                  type={oldPasswordVisible ? "password" : "text"}
                  label="Old Password"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <StyledInputAdornment position="end" onClick={() => setOldPasswordVisible(!oldPasswordVisible)}>
                        {oldPasswordVisible ? (
                          <VisibilityIcon sx={{ fontSize: 22, color: "#8A9296" }} />
                        ) : (
                          <VisibilityOffIcon sx={{ fontSize: 22, color: "#8A9296" }} />
                        )}
                      </StyledInputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item width="100%" sx={{ mt: 2 }}>
            <Controller
              name="newPassword"
              control={changePasswordForm.control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <AnbotoTextField
                  {...field}
                  data-sentry-mask="true"
                  type={newPasswordVisible ? "password" : "text"}
                  fullWidth
                  label="New password"
                  error={!!fieldState.error}
                  labelStyle="dynamic"
                  helperText={fieldState.error?.message}
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <StyledInputAdornment position="end" onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
                        {newPasswordVisible ? (
                          <VisibilityIcon sx={{ fontSize: 22, color: "#8A9296" }} />
                        ) : (
                          <VisibilityOffIcon sx={{ fontSize: 22, color: "#8A9296" }} />
                        )}
                      </StyledInputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item width="100%" sx={{ mt: 2 }}>
            <Controller
              name="newPasswordRepeat"
              control={changePasswordForm.control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <AnbotoTextField
                  {...field}
                  data-sentry-mask="true"
                  fullWidth
                  type={newPasswordVisible ? "password" : "text"}
                  label="Verify password"
                  labelStyle="dynamic"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={loading}
                  InputProps={{
                    endAdornment: (
                      <StyledInputAdornment position="end" onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
                        {newPasswordVisible ? (
                          <VisibilityIcon sx={{ fontSize: 22, color: "#8A9296" }} />
                        ) : (
                          <VisibilityOffIcon sx={{ fontSize: 22, color: "#8A9296" }} />
                        )}
                      </StyledInputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between">
              <Typography sx={{ fontSize: 14, paddingTop: 2 }} color="text.secondary">
                Password strength
              </Typography>
              {/* Typography = weak / medium / high */}
              <Typography sx={{ fontSize: 14, paddingTop: 2 }} color={strengthColor(strength, theme)}>
                {strength !== PasswordStrength.EMPTY
                  ? Object.keys(PasswordStrength)[Object.values(PasswordStrength).indexOf(strength)].toLowerCase()
                  : null}
              </Typography>
            </Grid>
            <Grid item width="100%">
              <StyledLinearProgress variant="determinate" valueBuffer={200} value={strength} strength={strength} />
            </Grid>
            <Typography sx={{ fontSize: 14, paddingTop: 2 }} color="text.secondary">
              Must contain:
            </Typography>
            <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" paddingY={0.3}>
              <Typography sx={{ fontSize: 14 }} color="white">
                At least 10 characters
              </Typography>
              {getStyledIcon(CheckCircleIcon, newPassword.length >= 10, 18)}
            </Grid>
            <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" paddingY={0.3}>
              <Typography sx={{ fontSize: 14 }} color="white">
                Lower case
              </Typography>
              {getStyledIcon(CheckCircleIcon, /[a-z]/.test(newPassword), 18)}
            </Grid>
            <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" paddingY={0.3}>
              <Typography sx={{ fontSize: 14 }} color="white">
                Upper case
              </Typography>
              {getStyledIcon(CheckCircleIcon, /[A-Z]/.test(newPassword), 18)}
            </Grid>
            <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" paddingY={0.3}>
              <Typography sx={{ fontSize: 14 }} color="white">
                Number
              </Typography>
              {getStyledIcon(CheckCircleIcon, /[0-9]/.test(newPassword), 18)}
            </Grid>
            <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" paddingY={0.3}>
              <Typography sx={{ fontSize: 14 }} color="white">
                Special characters
              </Typography>
              {getStyledIcon(CheckCircleIcon, /\W/.test(newPassword), 18)}
            </Grid>
            {error && <FormError sx={{ ml: 0 }}>{error}</FormError>}
          </Grid>
        </Grid>
      </Stack>
    </SettingsCard>
  );
};

export { ChangePassword };

const StyledInputAdornment = styled(InputAdornment)({
  "&:hover:not(.Mui-disabled)": {
    cursor: "default",
  },
});

StyledLinearProgress.displayName = "StyledLinearProgress";
