import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { InputLabel } from "@mui/material";
import { AuthCard } from "../layout/auth-card";
import { FormRow } from "../layout/form-row";
import { FormError } from "../layout/form-error";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

type VerifyOtpProps = {
  onSubmit: (code: string) => void;
  loading?: boolean;
  error?: string;
};

export const VerifyOtp = ({ onSubmit, error, loading }: VerifyOtpProps) => {
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setError,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });

  const onPaste = (event) => {
    const code = event.clipboardData.getData("text/plain");

    setValue("code", code);

    onSubmit(code);
  };

  useEffect(() => {
    if (error) {
      setError("code", {
        type: "manual",
        message: error,
      });
    }
  }, [error]);

  return (
    <AuthCard title="Welcome back" logo>
      <form onSubmit={handleSubmit(({ code }) => onSubmit(code))}>
        <FormRow>
          <InputLabel>Authenticator code</InputLabel>
          <AnbotoTextField
            disabled={loading}
            size="large"
            error={Boolean(errors.code)}
            type="text"
            autoFocus
            autoComplete="off"
            placeholder="Enter your authenticator code"
            {...register("code", {
              required: true,
              minLength: {
                value: 6,
                message: "Code must have at least 6 characters",
              },
            })}
            name="code"
            onPaste={onPaste}
            data-sentry-mask="true"
          />
          {errors.code && <FormError>{errors.code.message}</FormError>}
        </FormRow>

        <AnbotoButton
          size="large"
          sx={{ marginTop: "200px" }}
          variant="contained"
          fullWidth={true}
          disabled={!isDirty}
          loading={!!loading}
          onClick={handleSubmit(({ code }) => onSubmit(code))}
          type="submit"
        >
          Submit
        </AnbotoButton>
      </form>
    </AuthCard>
  );
};
