import React from "react";
import { Stack, styled } from "@mui/material";
import ReferralTabs from "@src/pages/referral-program/components/referral-info/tabs/referral-tabs";
import Overview from "@src/pages/referral-program/components/referral-info/overview/overview";
import { useAppSelector } from "@src/store/hooks";
import { ReferralProgramTabs } from "@src/store/slices/referralProgramSlice";
import TierStructure from "@src/pages/referral-program/components/referral-info/tier-structure/tier-structure";
import Commissions from "@src/pages/referral-program/components/referral-info/commissions/commissions";
import { Banner, referralProgramApi, ReferralSummary } from "@src/pages/referral-program";
import { RootState } from "@src/store/types";

export const ReferralInfo = () => {
  const currentTab = useAppSelector((state) => state.referralProgram.currentTab);

  const team_uuid = useAppSelector((state: RootState) => state.user.team_uuid)!;
  const { data } = referralProgramApi.useGetCodesListQuery({ team_uuid });

  const isCodeListEmpty = (data?.codes_details || []).length === 0;

  return (
    <Stack width={800} direction="column">
      <Banner />

      <ReferralSummary />

      {!isCodeListEmpty && (
        <ReferralBox sx={{ padding: 0 }}>
          <ReferralTabs />
        </ReferralBox>
      )}

      {currentTab === ReferralProgramTabs.OVERVIEW && !isCodeListEmpty && <Overview />}
      {currentTab === ReferralProgramTabs.COMMISSIONS && !isCodeListEmpty && <Commissions />}
      {currentTab === ReferralProgramTabs.TIER_STRUCTURE && !isCodeListEmpty && <TierStructure />}
    </Stack>
  );
};

export const ReferralBox = styled(Stack)(({ theme }) => ({
  marginBottom: 16,
  padding: 24,
  width: 800,
  borderRadius: 4,
  background: theme.custom.background.default,
}));
