import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography, Tabs, Tooltip, styled, Paper, Skeleton, Divider } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSnackbar } from "notistack";
import SVG from "react-inlinesvg";
import { feeManagerApi } from "./api";
import { TeamSCProps } from "./deposit-flow-dialog";
import { FeeManagerContract } from "./api/types";
import { FeeManagerTabs, setCurrentTab } from "@src/store/slices/feeManagerSlice";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AnbotoTab } from "@src/components/ui/AnbotoTabs/AnbotoTab";
import { RootState } from "@src/store/types";
import { formatBps, formatUsd } from "@src/utils/format";
import { CHAIN_LOGO } from "@src/pages/defi/constants";
import { truncateAccount } from "@src/pages/defi/utils";
import { CopyToClipboard } from "@src/components";
import { feeManagerContractApi } from "@src/store/apis/blockchainApi/fee-manager";
import { usePermissions } from "@src/hooks/use-permissions";

type FeeManagerHeaderProps = {
  handleDeposit: () => void;
} & {};

type AdminWallet = { chainId: number; address: string };

export const FeeManagerHeader = ({ handleDeposit }: FeeManagerHeaderProps) => {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const permissions = usePermissions();

  const { team_uuid } = useSelector((state: RootState) => state.user);
  const { currentTab } = useSelector((state: RootState) => state.feeManager);
  const [adminWallets, setAdminWallets] = useState<AdminWallet[]>([]);

  const handleTabChange = (e: React.SyntheticEvent, value: FeeManagerTabs) => dispatch(setCurrentTab({ value }));

  const accountStats = feeManagerApi.useGetFeeManagerAccountStatsQuery({ team_uuid: team_uuid });
  const feeManagerContracts = feeManagerApi.useGetFeeManagerContractsQuery();
  const { data, isFetching: isBalanceFetching } = feeManagerApi.useGetFeeTokensAndBalancesQuery();
  const [getTeamStatus] = feeManagerContractApi.useLazyTeamsQuery();

  useEffect(() => {
    if (feeManagerContracts.data) {
      loadAdminWallets();
    }
  }, [feeManagerContracts.data]);

  const loadAdminWallets = async () => {
    try {
      const contracts = feeManagerContracts.data!;

      const promises = contracts.map(async (contract: FeeManagerContract, idx) => {
        const teamSC = await getTeamStatus({ contract, team_uuid }).unwrap();

        const chainId = feeManagerContracts.data?.[idx]?.network_id;
        if (teamSC[TeamSCProps.IsValid] && teamSC[TeamSCProps.Owner] && chainId) {
          return { chainId: chainId, address: teamSC[TeamSCProps.Owner] as string };
        }
      });

      const walletsResult = await Promise.allSettled(promises);

      const newAdminWallets = walletsResult.reduce((result: AdminWallet[], walletItem) => {
        if (walletItem.status === "fulfilled" && walletItem.value) {
          result.push(walletItem.value);
        }
        return result;
      }, []);

      setAdminWallets(newAdminWallets);
    } catch (error) {
      snackbar.enqueueSnackbar("Failed loading admin wallet", { variant: "error" });
    }
  };

  const getFilteredWallets = (wallets) =>
    wallets.filter((walletA, index) => index === wallets.findIndex((walletB) => walletA.address === walletB.address));

  return (
    <Paper sx={{ background: "linear-gradient(180deg, rgba(3,17,22,0.01) 0%, rgba(25,32,34,1) 100%)" }}>
      <Stack width="100%" height="50%" justifyContent="space-between" direction="row">
        <Stack direction="row" pl={4} pt={3} gap={4}>
          <Stack gap={0.8}>
            <Stack direction="row">
              <Typography variant="subtitle1" color="text.secondary" fontSize={12}>
                Fee Wallet balance
              </Typography>
              <Tooltip title="Available credits on smart contracts to pay your fees" sx={{ fontSize: 12 }}>
                <StyledInfo fontSize="inherit" sx={{ mt: 0.55, ml: 0.5 }} />
              </Tooltip>
            </Stack>
            <Typography fontSize={20} fontWeight={600}>
              {isBalanceFetching ? <Skeleton width={60} /> : `≈ ${formatUsd(data?.balance || 0)}`}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography variant="subtitle1" color="text.secondary" fontSize={12}>
                Your fee rate
              </Typography>
              <Typography variant="subtitle1" fontFamily="Roboto" fontSize={12} fontWeight={500}>
                {accountStats.isFetching ? <Skeleton width={60} /> : `${formatBps(accountStats.data?.fee_rates || 0)}`}
              </Typography>
            </Stack>
            {accountStats.isFetching ? (
              <Skeleton width={160} />
            ) : accountStats.data?.amount_due ? (
              <Stack direction="row" gap={1}>
                <Typography fontSize={12} color="text.secondary" fontWeight={500}>
                  Debt
                </Typography>
                <Typography fontSize={12} fontFamily="Roboto" fontWeight={500}>
                  {formatUsd(accountStats.data?.amount_due || 0)}
                </Typography>
              </Stack>
            ) : null}
          </Stack>
          <Divider sx={{ borderWidth: "1px" }} orientation="vertical" />
          <Stack gap={0.8}>
            <Typography variant="subtitle1" color="text.secondary" fontSize={12}>
              Last 30days trading VOL
            </Typography>
            {accountStats.isFetching ? (
              <Skeleton width={160} />
            ) : (
              <Typography fontSize={20} fontFamily="Roboto" fontWeight={600}>
                Total:
                {` ${formatUsd(
                  (accountStats.data?.cefi_30_days_volume || 0) + (accountStats.data?.defi_30_days_volume || 0)
                )}`}
              </Typography>
            )}
            <Stack direction="row" gap={3}>
              {accountStats.isFetching ? (
                <Skeleton width={160} />
              ) : (
                <Stack direction="row" gap={0.5}>
                  <Typography fontSize={12} color="text.secondary" fontWeight={500}>
                    CEFI
                  </Typography>
                  <Typography fontSize={12} fontFamily="Roboto" fontWeight={500}>
                    {formatUsd(accountStats.data?.cefi_30_days_volume || 0)}
                  </Typography>
                </Stack>
              )}

              {accountStats.isFetching ? (
                <Skeleton width={160} />
              ) : (
                <Stack direction="row" gap={0.5}>
                  <Typography fontSize={12} color="text.secondary" fontWeight={500}>
                    DEFI
                  </Typography>
                  <Typography fontSize={12} fontFamily="Roboto" fontWeight={500}>
                    {formatUsd(accountStats.data?.defi_30_days_volume || 0)}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
          {!!accountStats.data?.fee_discount && (
            <>
              <Divider sx={{ borderWidth: "1px" }} orientation="vertical" />
              <Stack gap={0.8}>
                <Stack direction="row">
                  <Typography variant="subtitle1" color="text.secondary" fontSize={12}>
                    Your fee discount
                  </Typography>
                </Stack>
                <Typography fontSize={20} fontWeight={600}>{`${
                  Math.round((accountStats.data?.fee_discount || 0) * 10000) / 100
                } %`}</Typography>
                <Typography variant="subtitle1" color="text.secondary" fontSize={12}>
                  Referral code: {accountStats.data?.referral_code || ""}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
        <Stack gap={4} alignItems="flex-end" pt={4}>
          {permissions.trade && (
            <StyledButton onClick={handleDeposit} variant="dim" sx={{ mr: "30px" }}>
              Recharge Fee Wallet
            </StyledButton>
          )}
          <Stack direction="row" gap={1} mr={4}>
            {adminWallets.length >= 1 && feeManagerContracts.data ? (
              <>
                <Typography variant="subtitle1" color="text.secondary" fontSize={13}>
                  Admin wallet:
                </Typography>
                {getFilteredWallets(adminWallets).length === 1 ? (
                  <>
                    <Typography fontSize={14}>{truncateAccount(adminWallets[0].address)}</Typography>
                    <CopyToClipboard textToCopy={adminWallets[0].address} />
                  </>
                ) : (
                  <Stack gap={1}>
                    {adminWallets.map((adminWallet) => (
                      <Stack key={adminWallet.chainId} direction="row" gap={1}>
                        <SVG src={CHAIN_LOGO[adminWallet.chainId]} height={20} />
                        <Typography fontSize={14}>{truncateAccount(adminWallet.address)}</Typography>
                        <CopyToClipboard textToCopy={adminWallet.address} />
                      </Stack>
                    ))}
                  </Stack>
                )}
              </>
            ) : (
              !feeManagerContracts.data && !adminWallets[0] && <Skeleton width={160} />
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ ml: 2, mt: 1 }}>
        <Tabs value={currentTab || FeeManagerTabs.OVERVIEW} onChange={handleTabChange}>
          <AnbotoTab value={FeeManagerTabs.OVERVIEW} label="Fee Wallet" />
          <AnbotoTab value={FeeManagerTabs.TRANSACTIONS} label="Transactions" />
          <AnbotoTab value={FeeManagerTabs.FEE_RATES} label="Fee rates" />
          <AnbotoTab value={FeeManagerTabs.HELP} label="Help" />
        </Tabs>
      </Stack>
    </Paper>
  );
};

const StyledInfo = styled(InfoOutlinedIcon)(({ theme }) => ({
  cursor: "pointer",
  height: "13px",
  width: "13px",
  color: theme.custom.colors.dim,
}));

const StyledButton = styled(AnbotoButton)(({ theme }) => ({
  height: "32px",
  backgroundColor: theme.custom.background.secondary,
  borderColor: theme.custom.border.default,
}));
