import React, { useState } from "react";
import { Stack } from "@mui/material";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { CefiExchangeId, GetOrdersPeriod, OrderExecutionStrategy, OrderSide } from "@src/store/apis/anbotoApi/types";
import { AnbotoButtonGroup, AnbotoButtonGroupOption } from "@src/components/ui/anboto-button-group";
import TradesFilters from "@src/pages/metrics/components/trade-filters/trades-filters";
import { StyledAnbotoTextField } from "@src/pages/defi/order-form-card-no-gas/dialog";

export const periodFilterOptions: AnbotoButtonGroupOption<GetOrdersPeriod>[] = [
  { label: "1d", value: GetOrdersPeriod.Day },
  { label: "1wk", value: GetOrdersPeriod.Week },
  { label: "1mth", value: GetOrdersPeriod.Month },
  { label: "3mth", value: GetOrdersPeriod.ThreeMonths },
  { label: "1year", value: GetOrdersPeriod.All },
];

type PostTradeAnalysisFiltersProps = {
  onPeriodChange: (period: GetOrdersPeriod) => void;
  startDate: number;
  endDate: number;
  onStartDateChange: (startDate) => void;
  onEndDateChange: (endDate) => void;
};

const FILTER_STRATEGIES = [
  OrderExecutionStrategy.TWAP,
  OrderExecutionStrategy.VWAP,
  OrderExecutionStrategy.ORDER,
  OrderExecutionStrategy.POV,
  OrderExecutionStrategy.IS,
  OrderExecutionStrategy.LIMIT,
];

const FILTER_EXCHANGES_LIST = Object.values(CefiExchangeId);
const FILTER_ASSET_CLASSES = ["spot", "future"];
const FILTER_SIDES = [OrderSide.BUY, OrderSide.SELL];

const PostTradeAnalysisFilters = ({
  onPeriodChange,
  onEndDateChange,
  onStartDateChange,
  endDate,
  startDate,
}: PostTradeAnalysisFiltersProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const onDateTimeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onStartChange = (time) => {
    if (time) {
      onStartDateChange(time.unix());
    }
  };
  const onEndChange = (time) => {
    if (time) {
      onEndDateChange(time.unix());
    }
  };

  return (
    <>
      <Stack direction="row" gap={2} justifyContent="space-between" alignItems="flex-end">
        <TradesFilters
          assetClasses={FILTER_ASSET_CLASSES}
          strategies={FILTER_STRATEGIES}
          exchanges={FILTER_EXCHANGES_LIST}
          sides={FILTER_SIDES}
          hideDateFilters
          hideDefiFilters
        />

        <StyledDatePicker
          disableFuture
          label="Start date"
          inputFormat="MM/DD/YYYY"
          value={dayjs.unix(startDate)}
          onChange={(time) => onStartChange(time)}
          PopperProps={{
            placement: "bottom-end",
            anchorEl: anchorEl,
          }}
          renderInput={(params) => (
            <>
              <StyledAnbotoTextField
                onClick={onDateTimeClick}
                size="small"
                {...params}
                error={startDate > endDate}
                helperText={startDate > endDate ? "Start Date should be earlier than End Date" : ""}
                sx={{ "& .MuiFormHelperText-root": { position: "absolute", whiteSpace: "nowrap", bottom: -22 } }}
              />
            </>
          )}
        />

        <StyledDatePicker
          disableFuture
          label="End date"
          inputFormat="MM/DD/YYYY"
          value={dayjs.unix(endDate)}
          onChange={(time) => onEndChange(time)}
          PopperProps={{
            placement: "bottom-end",
            anchorEl: anchorEl,
          }}
          renderInput={(params) => (
            <>
              <StyledAnbotoTextField error={startDate > endDate} onClick={onDateTimeClick} size="small" {...params} />
            </>
          )}
        />

        <Stack>
          <AnbotoButtonGroup<GetOrdersPeriod>
            size="small"
            options={periodFilterOptions}
            defaultValue={GetOrdersPeriod.ThreeMonths}
            onChange={onPeriodChange}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default PostTradeAnalysisFilters;

const StyledDatePicker = DesktopDatePicker;
