import React, { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { DefiAllocation, DefiAllocationWithIcon } from "@src/store/apis/anbotoApi/types";
import { enrichDataWithIcons, mergeDefiAllocations } from "@src/pages/portfolio/utils";
import { getDefiColumns } from "@src/pages/portfolio/components/allocation-table/allocation-funds-table/get-defi-columns";
import TableDefiAllocationExpandedRow from "@src/pages/portfolio/components/allocation-funds-innder/table-defi-allocation-expanded-row";
import { useChainIcons } from "@src/pages/portfolio/hooks/use-chain-icons";
import { getKey } from "@src/pages/portfolio/utils/helpers";
import { AnbotoTable } from "@src/components/anboto-table";

interface AllocationDefiFundsTableProps {
  data?: DefiAllocation[];
  expandedRowRender?: (allocations: DefiAllocationWithIcon) => React.ReactNode;
  isFetching: boolean;
  isError: boolean;
  refetch: () => void;
  isOverview: boolean;
  isProtocolAsset: boolean;
}

const loaderData = Array.from(Array(10)).map(() => ({})) as DefiAllocationWithIcon[];

export const AllocationDefiFundsTable: FC<AllocationDefiFundsTableProps> = ({
  data,
  isFetching,
  isError,
  refetch,
  isOverview,
  isProtocolAsset,
}) => {
  const columns: Array<ColumnDef<DefiAllocationWithIcon, any>> = useMemo(
    () => getDefiColumns(isOverview, isProtocolAsset),
    [data, isOverview]
  );

  const chainIcons = useChainIcons();
  let dataWithIcons = isFetching ? loaderData : enrichDataWithIcons(data, chainIcons);

  if (isOverview && data) {
    const expandedInfoItems = dataWithIcons.reduce((acc, cur) => {
      const key = getKey(cur);
      if (acc[key]) {
        const index = acc[key].findIndex((x) => x.address === cur.wallet_address);
        if (index === -1) {
          acc[key] = [
            ...acc[key],
            {
              address: cur.wallet_address,
              balance: cur.balance,
              value: cur.value,
            },
          ];
        } else {
          acc[key][index].balance += cur.balance;
          acc[key][index].value += cur.value;
        }
      } else {
        acc[key] = [
          {
            address: cur.wallet_address,
            balance: cur.balance,
            value: cur.value,
          },
        ];
      }
      return acc;
    }, {});

    dataWithIcons = dataWithIcons.map((item) => {
      return {
        ...item,
        expandedInfo: expandedInfoItems[item.token_symbol + item.chain_name],
      };
    });
    dataWithIcons = mergeDefiAllocations(dataWithIcons);
  }

  return (
    <Box>
      <AnbotoTable<DefiAllocationWithIcon>
        columns={columns}
        data={dataWithIcons}
        loading={isFetching}
        error={!!isError}
        refetch={refetch}
        expandedRowRender={(data) =>
          data.expandedInfo ? <TableDefiAllocationExpandedRow allocations={data.expandedInfo} /> : <div></div>
        }
        getRowId={(defiAllocation: DefiAllocationWithIcon) =>
          defiAllocation.portion + defiAllocation.value + defiAllocation.token_symbol
        }
      />
    </Box>
  );
};
