import React from "react";
import { Stack, Tooltip, Typography, useTheme } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useSnackbar } from "notistack";
import { SettingsCard } from "@src/pages/settings/settings-card";
import teamAccounts from "@src/assets/images/team_accounts.webp";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { setRequestedTeamAccount } from "@src/store/slices/uiSettingsSlice";

const AccountSettingsIndividualAccount = () => {
  const snackbar = useSnackbar();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { user_uuid, team_name, account_number } = useAppSelector((state) => state.user);
  const { requestedTeamAccount } = useAppSelector((state) => state.uiSettings);

  const [createUserAccount] = anbotoApi.useLazySendCreateUserAccountRequestQuery();

  const handleRequestTeamAccount = async () => {
    try {
      const { is_sent } = await createUserAccount({ user_uuid: String(user_uuid) }).unwrap();
      if (is_sent) {
        dispatch(setRequestedTeamAccount());
        snackbar.enqueueSnackbar("Success. Our team will contact you soon", {
          variant: "success",
        });
      }
    } catch (e) {
      snackbar.enqueueSnackbar(parseAnbotoRequestError(e?.data?.detail), { variant: "error" });
    }
  };

  return (
    <SettingsCard
      headerProps={{
        title: `Individual account: ${team_name} ${team_name === account_number ? "" : account_number}`,
        postfix: (
          <AnbotoButton
            disabled={requestedTeamAccount}
            onClick={handleRequestTeamAccount}
            variant="contained"
            size="small"
          >
            {requestedTeamAccount ? "Team account requested" : "Request Team Account"}
          </AnbotoButton>
        ),
      }}
    >
      <Stack alignItems="center" textAlign="center">
        <Typography fontSize={24} fontWeight={500}>
          Create team account
        </Typography>
        <Typography marginTop={1} width={560} fontSize={16} fontWeight={400} color="#8A9296">
          You can invite team member to join your team account (3 accounts maximum), This way you can use different sets
          of API Keys
        </Typography>
        <Stack alignItems="center" flexDirection="row" gap={0.5}>
          <Tooltip title="You can delegate work by inviting users to your team" placement="top">
            <Typography
              sx={{ color: theme.custom.colors.dim, textDecoration: "underline", cursor: "pointer" }}
              fontSize={12}
            >
              Why need to create team
            </Typography>
          </Tooltip>
          <Tooltip title="You can delegate work by inviting users to your team" placement="top">
            <HelpIcon sx={{ color: theme.custom.colors.dim, cursor: "pointer", fontSize: 14 }} />
          </Tooltip>
        </Stack>
        <img style={{ width: 443, marginTop: 60 }} src={teamAccounts} alt="Team Accounts" />
      </Stack>
    </SettingsCard>
  );
};

export default AccountSettingsIndividualAccount;
