import { useEffect } from "react";
import { useTokenAccountBalanceQuery } from "@src/store/apis/blockchainApi";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";

export const useOrderFormToTokenBalance = (): void => {
  const orderForm = useDefiOrderFormContext();
  const account = orderForm.watch("account");
  const chainId = orderForm.watch("chainId");
  const formToTokenBalance = orderForm.watch("toTokenBalance");
  const toTokenAddress = orderForm.watch("toTokenAddress");

  const { data: toTokenBalance } = useTokenAccountBalanceQuery(
    { account: account!, chainId: chainId!, tokenAddress: toTokenAddress! },
    { skip: !account || !toTokenAddress || !chainId }
  );

  useEffect(() => {
    if (formToTokenBalance === toTokenBalance) {
      return;
    }

    orderForm.setValue("toTokenBalance", toTokenBalance?.toString() ?? "");
  }, [toTokenBalance, formToTokenBalance]);
};
