import React from "react";
import { Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { TradingStyleSelect } from "@src/pages/cefi/order-form/fields/TradingStyleSelect";
import { WouldStyleSelect } from "@src/pages/cefi/order-form/fields/WouldStyleSelect";
import { ClipSizeTypeSelect } from "@src/pages/defi/order-form-card-no-gas/fields";
import { ClipSizeType, PlacementMode } from "@src/store/apis/anbotoApi/types";
import { PlacementModeSelect } from "@src/pages/cefi/order-form/fields/PlacementModeSelect";
import { PlacementField } from "@src/pages/cefi/order-form/fields/PlacementField";
import { PlacementCancelField } from "@src/pages/cefi/order-form/fields/PlacementCancelField";
import { useParentOrderFormContext } from "@src/pages/cefi/order-form/hooks/use-parent-order-form-context";
import { ExpirationTimeSelect } from "@src/pages/cefi/order-form/fields/ExpirationTimeSelect";
import { usePlacementModeValue } from "@src/pages/settings/order-preferences/hooks/use-placement-mode-value";
import { StrategySelect } from "@src/pages/cefi/order-form/fields/StrategySelect";
import { TradingDurationField } from "@src/pages/cefi/order-form/fields/TradingDurationField";
import { TradingDurationUnitField } from "@src/pages/cefi/order-form/fields/TradingDurationUnitField";
import { AVAILABLE_STRATEGIES } from "@src/pages/cefi/order-form";

const CefiPreferencesForm = () => {
  const cefiPreferencesForm = useParentOrderFormContext();

  const placementMode = cefiPreferencesForm.watch("placementMode");
  const isCustomPlacement = placementMode === PlacementMode.CUSTOM;
  const changePlacementMode = usePlacementModeValue();

  return (
    <Stack width={750} alignSelf="center">
      <Typography mb={3} variant="h6" fontWeight="bold">
        CEFI
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Controller
            name="strategy"
            control={cefiPreferencesForm.control}
            render={({ field }) => <StrategySelect {...field} whiteList={AVAILABLE_STRATEGIES} labelStyle="dynamic" />}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="tradingDuration"
            control={cefiPreferencesForm.control}
            render={({ field, formState }) => (
              <TradingDurationField
                {...field}
                onChange={(e) => field.onChange(e.target.value.replace(/[^\d]/g, ""))}
                error={!!formState.errors.tradingDuration}
                helperText={formState.errors.tradingDuration?.message}
                labelStyle="dynamic"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Controller
                        name="tradingDurationUnit"
                        control={cefiPreferencesForm.control}
                        render={({ field }) => <TradingDurationUnitField {...field} />}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="tradingStyle"
            control={cefiPreferencesForm.control}
            render={({ field }) => <TradingStyleSelect {...field} labelStyle="dynamic" />}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            control={cefiPreferencesForm.control}
            name="wouldStyle"
            render={({ field, formState }) => (
              <WouldStyleSelect
                {...field}
                label="Would price style"
                error={!!formState.errors.wouldStyle}
                helperText={formState.errors.wouldStyle?.message}
                labelStyle="dynamic"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="clipSizeType"
            control={cefiPreferencesForm.control}
            render={({ field, formState }) => (
              <ClipSizeTypeSelect
                {...field}
                excludeTypes={[ClipSizeType.NB_OF_CHILD_ORDERS]}
                error={!!formState.errors.clipSizeType}
                helperText={formState.errors.clipSizeType?.message}
                labelStyle="dynamic"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="expirationTime"
            control={cefiPreferencesForm.control}
            render={({ field, formState }) => (
              <ExpirationTimeSelect
                {...field}
                error={!!formState.errors.clipSizeType}
                helperText={formState.errors.clipSizeType?.message}
                labelStyle="dynamic"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            control={cefiPreferencesForm.control}
            name="placementMode"
            render={({ field }) => (
              <PlacementModeSelect
                {...field}
                labelStyle="dynamic"
                onChange={(e) => changePlacementMode(e.target.value as PlacementMode)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            control={cefiPreferencesForm.control}
            name="placement"
            render={({ field, formState }) => (
              <PlacementField
                disabled={!isCustomPlacement}
                {...field}
                error={!!formState.errors.placement}
                helperText={formState.errors.placement?.message}
                labelStyle="dynamic"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            control={cefiPreferencesForm.control}
            name="placementCancel"
            render={({ field, formState }) => (
              <PlacementCancelField
                disabled={!isCustomPlacement}
                {...field}
                error={!!formState.errors.placementCancel}
                helperText={formState.errors.placementCancel?.message}
                labelStyle="dynamic"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CefiPreferencesForm;
