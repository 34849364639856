import React, { FC } from "react";
import { DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CardDialog } from "@src/components/CardDialog";
import { TransactionSpeedCard } from "@src/pages/defi/order-form-card-no-gas/components";
import LiquiditySources from "@src/pages/defi/order-form-card-no-gas/fields/select-speed-button/liquidity-sources";

export interface SelectNetworkDialogProps {
  isOpen: boolean;

  onClose(): void;
}

export const SelectSpeedDialog: FC<SelectNetworkDialogProps> = ({ isOpen, onClose }) => {
  return (
    <CardDialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontSize={16} lineHeight="21px" fontWeight={600}>
            Settings
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ px: 2, paddingTop: "16px !important" }}>
        <TransactionSpeedCard />
        <LiquiditySources />
      </DialogContent>
    </CardDialog>
  );
};
