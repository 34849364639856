import React, { FC } from "react";
import { formatDistance, parseISO } from "date-fns";
import { CircularProgress, IconButton, Stack, styled, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";
import { usePortfolioType, usePortfolioData } from "@src/pages/portfolio";
import { RootState } from "@src/store/types";
import { PortfolioType } from "@src/store/slices/portfolioSlice";

export const PortfolioDataUpdate: FC = () => {
  const portfolioType = usePortfolioType();
  const dataUpdatedCefi = useSelector((state: RootState) => state.portfolio.dataUpdatedCefi);
  const dataUpdatedDefi = useSelector((state: RootState) => state.portfolio.dataUpdatedDefi);
  const { taskIsPending, update } = usePortfolioData(portfolioType);

  const currentTabDate = portfolioType === PortfolioType.CEFI ? dataUpdatedCefi : dataUpdatedDefi;
  const formatted = currentTabDate ? formatDistance(parseISO(currentTabDate), new Date()) : "";

  return portfolioType === PortfolioType.DEFI ? (
    <Stack direction="row" alignItems="center" gap={1}>
      {formatted && (
        <Typography sx={{ fontSize: "14px" }} color="text.secondary">
          Balances updated
          <Typography sx={{ fontSize: "14px" }} variant={"caption"} color={"text.primary"}>
            &nbsp;{formatted}&nbsp;
          </Typography>
          ago
        </Typography>
      )}

      <RefreshIconButton size="medium" onClick={update}>
        {taskIsPending ? (
          <CircularProgress sx={{ color: (theme) => theme.palette.grey[200] }} size={17} />
        ) : (
          <RefreshIcon sx={{ width: 17, height: 17 }} />
        )}
      </RefreshIconButton>
    </Stack>
  ) : null;
};

const RefreshIconButton = styled(IconButton)({
  width: 35,
  height: 35,
  borderRadius: 4,
  border: "1px solid #4A5053",
});
