import React from "react";
import { useSnackbar } from "notistack";
import { CircularProgress, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { fileDownload } from "@src/utils/file";
import { AnbotoIconButton } from "@src/components";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";
import { MetricsPageOrderExportParams } from "@src/store/apis/anbotoApi/types";

const DEFAULT_COMPACT_ICON_WIDTH = 12;

const ExportCefiTradesButton = ({ account_uuid, order_id }: MetricsPageOrderExportParams) => {
  const [exportTrades, exportTradesMutation] = anbotoApi.useExportTradesMutation();
  const snackbar = useSnackbar();
  const handleExport = async () => {
    try {
      const data = await exportTrades({ account_uuid, order_id }).unwrap();
      fileDownload(data, `cefi_order_${order_id}_trades_${DateTime.now().toFormat("MMM_d_yyyy_HH:mm")}.csv`);
    } catch (error) {
      console.log(error);
      snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data?.error), { variant: "error" });
    }
  };

  return (
    <Tooltip title="Export all trades">
      <AnbotoIconButton
        disabled={exportTradesMutation.isLoading}
        onClick={handleExport}
        size="small"
        sx={{ height: 24 }}
      >
        {exportTradesMutation.isLoading ? (
          <CircularProgress size={DEFAULT_COMPACT_ICON_WIDTH} color="inherit" />
        ) : (
          <SaveAltIcon
            htmlColor="text.secondary"
            sx={{ width: DEFAULT_COMPACT_ICON_WIDTH, height: DEFAULT_COMPACT_ICON_WIDTH }}
          />
        )}
      </AnbotoIconButton>
    </Tooltip>
  );
};

export default ExportCefiTradesButton;
