import { Web3Provider } from "@ethersproject/providers";
import {
  AnbotoContractOrderV2,
  ClipSizeType,
  DefiOrderExecutionStrategy,
  DefiOrderExpiration,
  OrderExecutionStrategy,
  OrderSide,
  OrderTradingDurationUnit,
  OrderTriggerMode,
  OrderType,
  ParentDefiOrder,
  TriggerCondition,
} from "@src/store/apis/anbotoApi/types";
import { ChainId, GasPriceOptionType } from "@src/pages/defi/types";
import { DefiOrderFormFieldValues } from "@src/pages/defi/hooks";
import {
  CHAIN_ID_DEFAULT_TOKEN_PAIRS,
  DEFAULT_CHAIN_ID,
  DEFAULT_DEFI_CHILD_SLIPPAGE,
  DEFAULT_DEFI_MARKET_PRICE_PROTECTION_SLIPPAGE,
} from "@src/pages/defi/constants";
import { getTradingDurationFromMs, getTradingDurationSec } from "@src/pages/cefi/order-form/utils";
import { DEFAULT_DEFI_FEE_PERCENT, getClipSizeParameters, resolveCheckSumAddress } from "@src/pages/defi/utils";
import { getDcaOrderDate } from "@src/pages/defi/order-form-card-no-gas/components/dca-strategy-form/utils";

export interface PrepareDefiOrderProps {
  account: string;
  accountUid: string;
  anbotoContractOrder: AnbotoContractOrderV2;
  anbotoContractOrderSignature: string;
  chainId: ChainId;
  orderForm: DefiOrderFormFieldValues;
  provider: Web3Provider | null;
  is_private?: boolean;
  selected_aggregators: string[];
}

export const fromDefiDTOtoOrder = (account: string | null, order: ParentDefiOrder): DefiOrderFormFieldValues => {
  const fromTokenAddress = order.token1 ?? CHAIN_ID_DEFAULT_TOKEN_PAIRS[DEFAULT_CHAIN_ID].from.address;
  return {
    account: account ?? undefined,
    chainId: order.network_id,
    fromTokenAddress,
    toTokenAddress: order.token2 ?? CHAIN_ID_DEFAULT_TOKEN_PAIRS[DEFAULT_CHAIN_ID].to.address,
    fromTokenAmount: order.quantity.toString(),
    toTokenAmount: "",
    strategy: order.limit_price
      ? OrderExecutionStrategy.LIMIT
      : (order.strategy.toLowerCase() as DefiOrderExecutionStrategy),
    side: order.side.toLowerCase() as OrderSide,
    expiration: DefiOrderExpiration.WEEK,
    // TODO - fix triggerMode
    triggerMode: OrderTriggerMode.ONCE,
    // we expect values like 2 instead of 0.02
    slippage:
      order.strategy === OrderExecutionStrategy.ORDER || order.strategy === OrderExecutionStrategy.LIMIT
        ? (Number(order.defi_data?.slippage_percentage) * 100).toString()
        : DEFAULT_DEFI_MARKET_PRICE_PROTECTION_SLIPPAGE,
    childSlippage: order.defi_data?.slippage_percentage
      ? (Number(order.defi_data?.slippage_percentage) * 100).toString()
      : DEFAULT_DEFI_CHILD_SLIPPAGE,
    clipSizeType: order.clip_size_type ? order.clip_size_type : ClipSizeType.AUTOMATIC,
    clipSizeValue: order.clip_size_val ?? "",
    tradingDuration: order.duration_seconds
      ? getTradingDurationFromMs(order.duration_seconds * 1000).duration.toString()
      : "",
    tradingDurationUnit: order.duration_seconds
      ? (getTradingDurationFromMs(order.duration_seconds * 1000).durationUnits as OrderTradingDurationUnit)
      : OrderTradingDurationUnit.HOURS,
    frequencyUnit: OrderTradingDurationUnit.HOURS,
    extendDuration: order.params.extend_duration || false,
    limitPrice: "",
    triggerCondition: order.params.trigger_condition ? order.params.trigger_condition : TriggerCondition.ABOVE,
    triggerPrice: order.params.trigger_price ?? "",
    type: order.limit_price ? OrderType.LIMIT : OrderType.MARKET,
    gasPriceOption: GasPriceOptionType.HIGH,
    maxGasPrice: "",
    maxFeeAbsolute: "",
    maxFeeAbsoluteUsd: "",
    fromTokenBalance: "",
    toTokenBalance: "",
    chainNativeCoinPrice: "",
    buyTokenToEthRate: "",
    sellTokenToEthRate: "",
    isFeeTakenInInput: false,
    priceProtection: "Market",
  };
};

export const fromDefiFormToDefiPreOrder = ({ chainId, accountUid, orderForm }) => {
  const checksumAddressFrom = resolveCheckSumAddress(orderForm.fromTokenAddress);
  const checksumAddressTo = resolveCheckSumAddress(orderForm.toTokenAddress);
  const strategy =
    orderForm.strategy === OrderExecutionStrategy.LIMIT ? OrderExecutionStrategy.ORDER : orderForm.strategy;

  const isDcaStrategy = orderForm.strategy === OrderExecutionStrategy.DCA;
  const durationSeconds = orderForm.tradingDuration
    ? getTradingDurationSec(Number(orderForm.tradingDuration), orderForm.tradingDurationUnit)
    : undefined;
  const frequencySeconds = getTradingDurationSec(Number(orderForm.frequency), orderForm.frequencyUnit);
  const expirationTime = isDcaStrategy
    ? new Date(getDcaOrderDate(orderForm.startTime).date).getTime() + (durationSeconds || 0) * 1000
    : orderForm.expiration;

  const quantity = isDcaStrategy
    ? (+orderForm.clipSizeValue * (durationSeconds || 0)) / (frequencySeconds || 1)
    : +orderForm.fromTokenAmount;

  return {
    aggregator: "defi_sor",
    expiration_time: expirationTime,
    side: orderForm.side,
    symbol: `${checksumAddressFrom}/${checksumAddressTo}`,
    network_id: chainId,
    account_uuid: accountUid,
    // duration_seconds is relevant for Twap and Vwap, even on defi
    // So instead of telling the frequency between 2 slice, the user specify the duration of the whole trade
    duration_seconds: durationSeconds,
    quantity,
    strategy,
    limit_price:
      strategy === OrderExecutionStrategy.LIMIT || (strategy === OrderExecutionStrategy.ORDER && !!orderForm.limitPrice)
        ? orderForm.baseTokenAddress.toLowerCase() === orderForm.fromTokenAddress.toLowerCase()
          ? orderForm.limitPrice
          : 1 / orderForm.limitPrice
        : undefined,
    ...getClipSizeParameters(orderForm.clipSizeType, orderForm.clipSizeValue),
    fee_urgency: orderForm.gasPriceOption,
    params: {
      ...(orderForm.triggerPrice && orderForm.triggerCondition
        ? { trigger_condition: orderForm.triggerCondition as TriggerCondition }
        : {}),
      ...(orderForm.triggerPrice ? { trigger_price: orderForm.triggerPrice } : {}),
      extend_duration: false,
    },
  };
};

export const fromDefiFormToDefiOrder = ({
  account,
  accountUid,
  anbotoContractOrder,
  anbotoContractOrderSignature,
  chainId,
  orderForm,
  provider,
  selected_aggregators,
  is_private,
}: PrepareDefiOrderProps): ParentDefiOrder => {
  const preOrder = fromDefiFormToDefiPreOrder({ accountUid, chainId, orderForm });

  const slippagePercentage =
    preOrder.strategy === OrderExecutionStrategy.ORDER || preOrder.strategy === OrderExecutionStrategy.LIMIT
      ? orderForm.slippage
      : orderForm.childSlippage;
  const isDcaStrategy = orderForm.strategy === OrderExecutionStrategy.DCA;

  const frequencyInSeconds = getTradingDurationSec(Number(orderForm.frequency), orderForm.frequencyUnit);

  const startTime = new Date(getDcaOrderDate(orderForm.startTime).date).getTime();
  const endTime =
    +startTime + getTradingDurationSec(Number(orderForm.tradingDuration), orderForm.tradingDurationUnit) * 1000;

  return {
    ...preOrder,
    ...(isDcaStrategy ? { start_time: startTime, end_time: endTime } : {}),
    params: { ...preOrder.params, ...(isDcaStrategy ? { dca_frequency: frequencyInSeconds } : {}) },
    defi_data: {
      signature: anbotoContractOrderSignature,
      maker_address: account,
      total_amount: anbotoContractOrder.totalAmount,
      out_min: anbotoContractOrder.outMin,
      deadline: anbotoContractOrder.deadline,
      salt: anbotoContractOrder.salt,
      // we expect values like 0.02 instead of 2
      slippage_percentage: (Number(slippagePercentage) / 100).toString(),
      max_gas_price: anbotoContractOrder.maxGasPrice,
      max_fee_absolute: anbotoContractOrder.maxFeeAbsolute,
      is_fee_taken_in_input: orderForm.isFeeTakenInInput,
      wallet_protocol: {
        appVersion: window?.navigator?.appVersion || window?.navigator?.userAgent,
        provider: provider?.connection?.url || "",
      },
      selected_aggregators,
      fee_percent: DEFAULT_DEFI_FEE_PERCENT,
      ...(chainId === ChainId.ETHEREUM && { is_private }),
    },
  };
};
