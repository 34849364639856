import { ethers } from "ethers";
import BigNumber from "bignumber.js";
import { camelCase } from "lodash";
import { DEFAULT_ORDER_CLIPSIZE, MAX_GAS_PRICE_MULTIPLY_RATIO } from "@src/pages/defi/constants";
import { ChainId, EVMBasedAddress } from "@src/pages/defi/types";
import {
  AnbotoContractOrder,
  AnbotoContractOrderV2,
  ClipSizeType,
  DefiOrderExpiration,
  OrderExecutionStrategy,
  OrderPreferences,
  OrderSide,
  ParentOrderStatuses,
} from "@src/store/apis/anbotoApi/types";
import { gweiToWei } from "@src/utils/gweiToWei";
import { muiTheme } from "@src/components/theme/mui-theme";
import { isMultiSliceOrderExecutionStrategy } from "@src/store/apis/anbotoApi/utils";
import { cutAmount } from "@src/utils/format";
import { TokenConfig } from "@src/store/slices/tokensSlice";
import { getDevidedTokenAmountInUnits } from "@src/utils/getTokenUnits";

export const DEFAULT_DEFI_FEE_PERCENT = 10;

export const normalizeIpfsLink = (ipfsUrl: string) => ipfsUrl.replace("ipfs://", "https://cloudflare-ipfs.com/ipfs/");

export const truncateAccount = (account: string) => account.slice(0, 5) + "..." + account.slice(-5);

export const formatAmount = (amount: number) => amount;

export const resolveCheckSumAddress = (address: string): string => {
  try {
    return ethers.utils.getAddress(address);
  } catch (e) {
    console.log("resolveCheckSumAddress", e);

    return address;
  }
};

export const createAnbotoContractOrder = ({
  salt,
  deadline,
  slippage,
  inputTokenAddress,
  inputTokenAmount,
  inputTokenDecimals,
  outputTokenAddress,
  outputTokenAmount,
  outputTokenDecimals,
  maxGasPrice,
  isFeeTakenInInput,
}: {
  salt: number;
  deadline: number;
  slippage: string;
  maxGasPrice: string; // in gwei
  inputTokenAddress;
  inputTokenAmount;
  inputTokenDecimals: number;
  outputTokenAddress: string;
  outputTokenAmount: string;
  outputTokenDecimals: number;
  isFeeTakenInInput: boolean;
}): AnbotoContractOrder => {
  const slippagePercentage = parseFloat(slippage) / 100;
  const slippageDiffPercentage = 1 - slippagePercentage; // f.e. 0.99
  const totalAmount = new BigNumber(inputTokenAmount).multipliedBy(10 ** inputTokenDecimals);
  const outMin = new BigNumber(outputTokenAmount)
    .multipliedBy(10 ** outputTokenDecimals)
    .multipliedBy(slippageDiffPercentage);
  return {
    // from
    inputToken: resolveCheckSumAddress(inputTokenAddress),
    totalAmount: totalAmount.toFixed(0),
    // to
    outputToken: resolveCheckSumAddress(outputTokenAddress),
    outMin: outMin.toFixed(0),
    // params
    maxGasPrice: gweiToWei(maxGasPrice),
    feeAmount: 10, // 10 is 0.1% of output amount! https://www.investopedia.com/terms/b/basispoint.asp#mntl-sc-block_1-0-26
    deadline,
    salt,
    isFeeTakenInInput,
  };
};

export const createAnbotoContractOrderV3 = ({
  salt,
  deadline,
  slippage,
  inputTokenAddress,
  inputTokenAmount,
  inputTokenDecimals,
  outputTokenAddress,
  outputTokenAmount,
  outputTokenDecimals,
  maxGasPrice,
  maxFeeAbsolute,
  isFeeTakenInInput,
  isMarketPriceProtection,
}: {
  salt: number;
  deadline: number;
  slippage: string;
  inputTokenAddress;
  inputTokenAmount;
  inputTokenDecimals: number;
  outputTokenAddress: string;
  outputTokenAmount: string;
  outputTokenDecimals: number;
  maxGasPrice: string;
  maxFeeAbsolute: string;
  isFeeTakenInInput: boolean;
  isMarketPriceProtection: boolean;
}): AnbotoContractOrderV2 => {
  const slippagePercentage = parseFloat(slippage) / 100;
  const slippageDiffPercentage = 1 - slippagePercentage; // f.e. 0.99
  const totalAmountRaw = new BigNumber(inputTokenAmount).multipliedBy(10 ** inputTokenDecimals);

  const tokenFeeDecimals = isFeeTakenInInput ? inputTokenDecimals : outputTokenDecimals;

  const maxFeeAbsoluteBigInt = new BigNumber(maxFeeAbsolute).multipliedBy(
    MAX_GAS_PRICE_MULTIPLY_RATIO * 10 ** tokenFeeDecimals
  );

  let outMin;
  if (isMarketPriceProtection) {
    outMin = new BigNumber(0);
  } else if (isFeeTakenInInput) {
    // totalAmountRaw = total amount including fees
    const totalAmount = totalAmountRaw.minus(new BigNumber(maxFeeAbsoluteBigInt));
    const ratio = totalAmount.dividedBy(new BigNumber(totalAmountRaw)).toNumber();

    // as fee are taken on the input token a lower quantity is available to make the swap
    // the ratio is balancing this to compute the outMin quantity
    outMin = new BigNumber(outputTokenAmount)
      .multipliedBy(ratio)
      .multipliedBy(10 ** outputTokenDecimals)
      .multipliedBy(slippageDiffPercentage);
  } else {
    outMin = new BigNumber(outputTokenAmount)
      .multipliedBy(10 ** outputTokenDecimals)
      .multipliedBy(slippageDiffPercentage);

    outMin = outMin.minus(new BigNumber(maxFeeAbsoluteBigInt));
  }

  return {
    inputToken: resolveCheckSumAddress(inputTokenAddress),
    totalAmount: totalAmountRaw.toFixed(0),
    outputToken: resolveCheckSumAddress(outputTokenAddress),
    outMin: outMin.toFixed(0),
    // maxGasPrice in gwe
    maxGasPrice: new BigNumber(maxGasPrice).multipliedBy(10 ** 9).toFixed(0),
    maxFeeAbsolute: new BigNumber(maxFeeAbsolute).multipliedBy(10 ** tokenFeeDecimals).toFixed(0),
    feePercent: DEFAULT_DEFI_FEE_PERCENT,
    deadline,
    salt,
    isFeeTakenInInput,
  };
};

export const getSalt = () => Math.floor(Math.random() * 10000);

export const compareCaseInsensitive = (v1 = "", v2 = "") => v1.toLowerCase() === v2.toLowerCase();

export const constructTokenAddress = (chainId: ChainId, tokenAddress: EVMBasedAddress) =>
  `${chainId}_${tokenAddress?.toLowerCase()}`;

const walletAddressRegEx = /^0x[a-fA-F0-9]{40}$/;

export const walletAddressIsValid = (address: string) => !!address.match(walletAddressRegEx);

export const parseNativeValidationErrors = (error: Record<string, { message: string }>): string =>
  Object.keys(error)?.reduce((r, x) => `${r} ${x}: ${error[x].message}`, "") || "";

export const parsePreValidationErrors = (error: Record<string, string[]>): string =>
  Object.keys(error)?.reduce((r, x) => `${r} ${x}: ${error[x]}`, "") || "";

export const getAmountWithSlippage = (amount: string, slippage: string, decimals: number): string => {
  const amountBN = new BigNumber(amount);
  const slippageAmountBN = new BigNumber(slippage).dividedBy(100);

  return amountBN.plus(amountBN.multipliedBy(slippageAmountBN)).toFixed(decimals);
};

export const getColorByOrderStatus = (orderStatus: ParentOrderStatuses) => {
  const status = camelCase(orderStatus);

  return muiTheme.custom.statuses[status];
};

// const calculateClipSize = (x: string): number => {
//   // 3.1 => 4 clips, 3.01 => 3 clips
//   const clipSize = new BigNumber(new BigNumber(x).multipliedBy(10).toFixed(0, 1)).dividedBy(10).toFixed(0, 0);

//   return +clipSize < 1 ? 1 : parseInt(clipSize, 10);
// };

export const calculateNumberOfSlices = ({
  strategy,
  quantity,
  clipSizeValue,
  clipSizeType,
}: {
  strategy: OrderExecutionStrategy;
  quantity: string;
  clipSizeType: ClipSizeType;
  clipSizeValue?: string;
}): number => {
  const isMultiStrategy = isMultiSliceOrderExecutionStrategy(strategy) || strategy === OrderExecutionStrategy.DCA;

  if (!isMultiStrategy) {
    return 1;
  }

  if (!clipSizeValue || clipSizeValue === "0") {
    return 1;
  }

  if (strategy === OrderExecutionStrategy.DCA) {
    return parseInt(new BigNumber(quantity).dividedBy(new BigNumber(clipSizeValue)).toString(), 10);
  }

  if (clipSizeType === ClipSizeType.PERCENTAGE) {
    return parseInt(new BigNumber(100).dividedBy(new BigNumber(clipSizeValue)).toString(), 10);
  }

  if (clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS) {
    return parseInt(clipSizeValue);
  }

  if (clipSizeType === ClipSizeType.ABSOLUTE || clipSizeType === ClipSizeType.AUTOMATIC) {
    return parseInt(new BigNumber(quantity).dividedBy(new BigNumber(clipSizeValue)).toString(), 10);
  }

  // for now we don't support ClipSizeType.AUTOMATIC
  return 1;
};

export const getOppositeAmount = async ({
  chainId,
  getQuoteData,
  side,
  slippage,
  fromToken,
  toToken,
  value,
  clipSizeType,
  clipSizeVal,
  durationSeconds,
  strategy,
  limitPrice,
  baseTokenAddress,
}) => {
  if (!value || value === "0") {
    return "";
  }

  if (fromToken.address === toToken.address) {
    return new BigNumber(value).toFixed();
  }

  const isOrderStrategy = strategy === OrderExecutionStrategy.ORDER || strategy === OrderExecutionStrategy.LIMIT;
  try {
    const result = await getQuoteData({
      chainId,
      sellToken: side === OrderSide.BUY ? toToken.address : fromToken.address,
      sellTokenDecimals: side === OrderSide.BUY ? toToken.decimals : fromToken.decimals,
      buyToken: side === OrderSide.BUY ? fromToken.address : toToken.address,
      buyTokenDecimals: side === OrderSide.BUY ? fromToken.decimals : toToken.decimals,
      sellAmount: value,
      childSlippage: (+slippage! / 100).toString(),
      ...getClipSizeParameters(
        isOrderStrategy ? ClipSizeType.PERCENTAGE : clipSizeType,
        isOrderStrategy ? DEFAULT_ORDER_CLIPSIZE : clipSizeVal
      ),
      durationSeconds,
      strategy: strategy === OrderExecutionStrategy.LIMIT ? OrderExecutionStrategy.ORDER : strategy,
    }).unwrap();

    if (strategy === OrderExecutionStrategy.LIMIT) {
      if (!limitPrice) {
        return "";
      }

      const buyAmount =
        baseTokenAddress.toLowerCase() === fromToken.address.toLowerCase()
          ? new BigNumber(value).multipliedBy(limitPrice)
          : new BigNumber(value).dividedBy(limitPrice);

      return cutAmount(buyAmount.toString());
    } else {
      if (!result?.data?.buyAmount) {
        return "";
      }

      const sideAmount = getDevidedTokenAmountInUnits(result.data.buyAmount, toToken.decimals);
      // if the price logic will not work, we will use rate logic
      // const sideAmount = new BigNumber(value).multipliedBy(result.data.price).toFixed();
      // ? new BigNumber(sellAmount).dividedBy(result.sellTokenToEthRate).multipliedBy(result.buyTokenToEthRate)
      // : new BigNumber(buyAmount!).dividedBy(result.buyTokenToEthRate).multipliedBy(result.sellTokenToEthRate);

      return cutAmount(sideAmount);
    }
  } catch (e) {
    console.log("fetch quote error", e);
  }

  return "";
};

export const isTokenSaveNeeded = (tokenConfig: TokenConfig): boolean => {
  if (tokenConfig) {
    const currentTimestamp = new Date().getTime();
    const msDay = 24 * 60 * 60 * 1000;

    if (tokenConfig.timestamp && tokenConfig.timestamp + msDay > currentTimestamp) {
      return false;
    }
  }

  return true;
};

export const getClipSizeParameters = (clipSizeType: ClipSizeType, clipSizeVal?: string) => ({
  clip_size_type: clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS ? ClipSizeType.PERCENTAGE : clipSizeType,
  ...(clipSizeVal
    ? {
        clip_size_val:
          clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS
            ? (100 / (Number(clipSizeVal) || 1)).toString()
            : clipSizeVal,
      }
    : {}),
});

export const getOrderFormUserDefaultValues = (orderPreferences: OrderPreferences) => ({
  ...(orderPreferences?.defi_parent_slipage_percent !== undefined
    ? { slippage: orderPreferences.defi_parent_slipage_percent }
    : {}),
  ...(orderPreferences?.defi_child_slipage_percent !== undefined
    ? { childSlippage: orderPreferences.defi_child_slipage_percent }
    : {}),
  ...(orderPreferences?.defi_clip_size_type ? { clipSizeType: orderPreferences.defi_clip_size_type } : {}),
  ...(orderPreferences?.defi_expiration !== undefined
    ? { expiration: fromOrderPreferenceExpirationToFormExpiration(orderPreferences.defi_expiration) }
    : {}),
  ...(orderPreferences?.defi_strategy !== undefined ? { strategy: orderPreferences.defi_strategy } : {}),
  ...(orderPreferences?.defi_duration_type !== undefined
    ? { tradingDurationUnit: orderPreferences.defi_duration_type }
    : {}),
  ...(orderPreferences?.defi_duration_value !== undefined
    ? { tradingDuration: orderPreferences.defi_duration_value || "" }
    : {}),
});

const fromOrderPreferenceExpirationToFormExpiration = (expiration) => {
  switch (expiration) {
    case 0:
      return DefiOrderExpiration.GTC;
    case 24 * 3600:
      return DefiOrderExpiration.DAY;
    case 7 * 24 * 3600:
      return DefiOrderExpiration.WEEK;
    case 30 * 24 * 3600:
      return DefiOrderExpiration.MONTH;
    case 6 * 30 * 24 * 3600:
      return DefiOrderExpiration.HALF_YEAR;
    default:
      return DefiOrderExpiration.GTC;
  }
};
