import React from "react";
import { Typography, Stack } from "@mui/material";
import { ReactComponent as NoDataIcon } from "@src/assets/svg/data.svg";

type NoTableDataType = { message?: string } & {};

export const NoTableData = ({ message = "No data" }: NoTableDataType) => (
  <Stack alignItems="center">
    <NoDataIcon />
    <Typography variant="body2" sx={{ color: "#8A9296", mt: 1.5, fontSize: 12 }}>
      {message}
    </Typography>
  </Stack>
);
