import { useSnackbar } from "notistack";

export interface UseTextCopyResult {
  onCopyClick: (text: string, fieldName?: string) => void;
}

export const useTextCopy = (): UseTextCopyResult => {
  const snackbar = useSnackbar();

  const onCopyClick = (text: string, fieldName?: string) => {
    navigator.clipboard.writeText(text);

    snackbar.enqueueSnackbar(`${fieldName} copied to clipboard`, { variant: "info" });
  };

  return {
    onCopyClick,
  };
};
