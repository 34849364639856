import React, { useEffect } from "react";
import { InputLabel } from "@mui/material";
import { useForm } from "react-hook-form";
import { AuthCard } from "../layout/auth-card";
import { FormRow } from "../layout/form-row";
import { FormError } from "../layout/form-error";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

export const SetupPassword = ({
  onSubmit,
  loading,
  error,
}: {
  onSubmit: (password: string) => void;
  loading?: boolean;
  error?: string;
}) => {
  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    getValues,
    setError,
  } = useForm({
    defaultValues: {
      password: "",
      password_repeat: "",
    },
  });

  useEffect(() => {
    if (error) {
      setError("password", { message: error });
    }
  }, [error]);

  return (
    <AuthCard sx={{ minHeight: 0 }} logo title="Set up your new password">
      <>
        <FormRow>
          <InputLabel>Password</InputLabel>
          <AnbotoTextField
            data-sentry-mask="true"
            size="large"
            error={Boolean(errors.password)}
            type="password"
            placeholder="Enter new password"
            {...register("password", {
              minLength: {
                value: 8,
                message: "Password must have at least 8 characters",
              },
            })}
            name="password"
          />
        </FormRow>
        {errors.password && <FormError variant="body2">{errors.password.message}</FormError>}
        <FormRow>
          <InputLabel>Confirm password</InputLabel>
          <AnbotoTextField
            data-sentry-mask="true"
            error={Boolean(errors.password_repeat)}
            type="password"
            placeholder="Confirm password"
            size="large"
            {...register("password_repeat", {
              validate: (value) => (value === getValues("password") ? true : "The passwords do not match"),
            })}
            name="password_repeat"
          />
          {errors.password_repeat && <FormError>{errors.password_repeat.message}</FormError>}
        </FormRow>

        <AnbotoButton
          sx={{ mt: 6 }}
          variant="contained"
          fullWidth={true}
          disabled={!isDirty}
          loading={loading}
          onClick={handleSubmit(({ password }) => onSubmit(password))}
          loadingIndicator="Loading..."
          type="submit"
          size="large"
        >
          Submit
        </AnbotoButton>
      </>
    </AuthCard>
  );
};
