import React from "react";
import { useGroupData } from "../../hooks/use-group-data";
import { WidgetBase, WidgetBaseProps } from "../widget-base";
import { CefiGroup } from "../../store/widgets.slice";
import { PriceImpactWrapper } from "@src/components/price-impact/components/price-impact-wrapper";
import { ClipSizeType } from "@src/store/apis/anbotoApi/types";

export const CefiPriceImpactWidget = React.forwardRef((props: WidgetBaseProps, ref: React.Ref<HTMLDivElement>) => {
  const {
    symbol = null,
    quantity = "",
    clipSizeValue,
    clipSizeType = ClipSizeType.AUTOMATIC,
    tradingDuration,
    tradingDurationUnit,
    strategy,
    side,
  } = useGroupData(props.group || props.id) as CefiGroup;

  return (
    <WidgetBase
      {...props}
      ref={ref}
      symbolSelect
      symbolSelectDisabled
      stableGutter
      component={
        <PriceImpactWrapper
          strategy={strategy}
          symbol={symbol}
          quantity={quantity}
          clipSizeVal={clipSizeValue}
          clipSizeType={clipSizeType}
          tradingDuration={tradingDuration}
          tradingDurationUnit={tradingDurationUnit}
          side={side}
        />
      }
    />
  );
});

CefiPriceImpactWidget.displayName = "CefiPriceImpactWidget";
