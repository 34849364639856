import { Auth } from "@src/features/auth";

type WsSubscribeProps = {
  url: string;
  onclose?: (e) => void;
  onerror?: (e) => void;
  onmessage?: (e) => void;
  onopen?: () => void;
  reconnectTimeoutMs?: number;
  auth?: boolean;
};

export type WsSubscription = {
  ws?: WebSocket;
  close: () => void;
  send: (data: any) => void;
  getState: () => number | undefined;
  toSend: (string | ArrayBufferLike | Blob | ArrayBufferView)[];
};

export default async function wsSubscribe(opts: WsSubscribeProps) {
  const subscription: WsSubscription = {
    ws: undefined,
    getState() {
      return this.ws?.readyState;
    },
    send(data) {
      if (this.ws?.readyState === WebSocket.OPEN) {
        return this.ws?.send(data);
      }
      // console.log("[Subscribe] connection is not opened, pushing to queue", data);
      this.toSend.push(data);
    },
    close() {
      this.ws?.close();
    },
    toSend: [],
  };

  function handleClose(e: CloseEvent) {
    console.log("☮️ closed", e);
    if (e.code !== 1000) {
      return setTimeout(() => connect(), opts.reconnectTimeoutMs || 5000);
    }
    opts.onclose && opts.onclose(e);
  }

  function handleOpen() {
    // console.log("open", subscription);
    try {
      subscription.toSend.forEach((data, index) => {
        // console.log("[Subscribe] sending from queue", data);
        if (data) {
          subscription.ws?.send(data);
          delete subscription.toSend[index];
        }
      });
      // subscription.toSend = subscription.toSend.filter(Boolean);
    } catch (error) {
      console.error("[Subscribe]", error);
    }
    opts.onopen && opts.onopen();
  }

  async function connect() {
    const { url, onerror, onmessage, auth } = opts;

    const { idToken } = await Auth.getAuthTokens();

    const subprotocol = auth && idToken ? [idToken] : undefined;

    const ws = new WebSocket(url, subprotocol);

    if (onerror) ws.onerror = onerror;
    if (onmessage) ws.onmessage = onmessage;

    ws.onopen = handleOpen;
    ws.onclose = handleClose;

    subscription.ws?.close();
    subscription.ws = ws;

    return subscription;
  }

  return connect();
}
