import React from "react";
import { Stack, Tabs } from "@mui/material";
import { setParams, setSearch } from "@src/pages/cefi/orders-table/orders-table.slice";
import { CefiExchangeId, OrdersCurrentTab, ParentOrder, ParentOrderStatuses } from "@src/store/apis/anbotoApi/types";
import { AnbotoTab } from "@src/components/ui/AnbotoTabs/AnbotoTab";
import { OrdersTableFilters } from "@src/components/orders-table-filters";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { ORDERS_LIMIT } from "@src/store/constants";
import { PaginationTooltip } from "@src/components/orders-table/pagination-tooltip";
import { ParentOrdersTable } from "@src/pages/cefi/orders-table/components/parent-orders-table";

interface OtcOrdersTableCardProps {
  onSymbolClick?: (order: ParentOrder) => void;
  exchangeIds?: CefiExchangeId[];
  topBarPostfix?: React.ReactNode;
}

export const OtcOrdersTableCard = React.forwardRef(
  ({ onSymbolClick, exchangeIds, topBarPostfix }: OtcOrdersTableCardProps, ref: React.Ref<HTMLDivElement>) => {
    const dispatch = useAppDispatch();
    const { currentTab } = useAppSelector((state) => state.ordersTable.params);

    const isPositions = currentTab === OrdersCurrentTab.Positions;

    const handleTabChange = (e: React.SyntheticEvent, currentTab: OrdersCurrentTab) =>
      dispatch(setParams({ currentTab }));

    const handleSymbolClick = React.useCallback((order: ParentOrder) => onSymbolClick && onSymbolClick(order), []);

    return (
      <Stack ref={ref} sx={{ flex: 1, height: "100%" }}>
        <Stack className="order-table" direction="row" alignItems="center" px={1} position="relative">
          <Tabs value={currentTab} onChange={handleTabChange} sx={{ position: "relative", zIndex: 2 }}>
            <AnbotoTab label="Regular orders" value={OrdersCurrentTab.Regular} />
            <AnbotoTab label="Trigger orders" value={OrdersCurrentTab.Trigger} />
            <AnbotoTab label="History" value={OrdersCurrentTab.History} />
          </Tabs>
          <Stack flex={1} />
          <Stack gap={2} direction="row" alignItems="center" sx={{ alignItems: "stretch" }}>
            <Stack sx={{ position: "relative", zIndex: 2 }}>
              <OrdersTableFilters<ParentOrderStatuses>
                period={isPositions ? undefined : { onChange: (period) => dispatch(setParams({ period })) }}
                search={{
                  onSearch: (value: string) => dispatch(setSearch(value)),
                }}
                otherPairsHidden
              />
            </Stack>
          </Stack>
          {topBarPostfix}
        </Stack>
        <Stack flex={1} overflow="hidden">
          <ParentOrdersTable
            onSymbolClick={handleSymbolClick}
            exchangeIds={exchangeIds}
            excludeColumns={["live-price"]}
          />
        </Stack>

        {!isPositions && <PaginationTooltip limit={ORDERS_LIMIT} p={0.5} justifyContent="center" />}
      </Stack>
    );
  }
);

OtcOrdersTableCard.displayName = "OtcOrdersTableCard";
