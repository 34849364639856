import React, { useState } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useAccount } from "wagmi";
import { signMessage } from "@wagmi/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GroupsIcon from "@mui/icons-material/Groups";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { Banner, ReferralBox, referralProgramApi } from "@src/pages/referral-program";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { setIsReferrer, setIsReferrerRequestSent } from "@src/store/actions/user.action";
import { AnbotoConnectWalletButton } from "@src/pages/settings/fee-manager/components/anboto-wallet-connect-button";
import { wagmiConfig } from "@src/pages/defi/wagmi-config";

const SIGN_MESSAGE = "By signing, you grant Anboto to give rewards access to this wallet:";

export const WelcomeReferralProgram = () => {
  const { address } = useAccount();
  const theme = useTheme();
  const snackbar = useSnackbar();
  const dispatch = useAppDispatch();

  const { team_uuid, referrer_allowed, referrer_request_sent } = useAppSelector((state) => state.user);
  const [signIsLoading, setSignIsLoading] = useState(false);

  const [becomeReferrer] = referralProgramApi.useBecomeReferrerMutation();
  const [trigger] = referralProgramApi.useLazyGetRequestProgramQuery();

  const onSign = async (message) => {
    try {
      if (address) {
        setSignIsLoading(true);
        const fullMessage = message + address;
        await signMessage(wagmiConfig, { message: fullMessage });
        const isReferrer = await becomeReferrer({ team_uuid, body: { wallet: address } }).unwrap();
        dispatch(setIsReferrer(isReferrer.message === "referrer created"));
        setSignIsLoading(false);
      }
    } catch (err) {
      setSignIsLoading(false);
      snackbar.enqueueSnackbar(err?.message || "Some error happened", { variant: "error" });
    }
  };

  const onRequestReferralProgram = async () => {
    try {
      await trigger({ team_uuid }).unwrap();
      snackbar.enqueueSnackbar("You successfully requested referral program", { variant: "success" });
      dispatch(setIsReferrerRequestSent(true));
    } catch (err) {
      snackbar.enqueueSnackbar(err?.message || "Some error happened", { variant: "error" });
    }
  };

  if (!referrer_allowed) {
    return (
      <>
        <Banner />
        <ReferralBox p={3} gap={4}>
          <Stack direction="column" alignItems="center">
            <GroupsIcon sx={{ color: theme.palette.text.secondary, fontSize: 56 }} />
          </Stack>
          <Stack p={3} sx={{ background: "#031116", color: theme.palette.text.secondary }}>
            <Typography variant="h6" gutterBottom>
              Unlock Your Earnings Potential with Anboto&apos;s Affiliate Program
            </Typography>
            <Typography fontSize={14} variant="body1">
              Are you passionate about cryptocurrency trading and want to maximize your earnings? Join Anboto&apos;s
              Affiliate Program – the gateway to lucrative rewards for crypto enthusiasts like you.
            </Typography>

            <Typography variant="h6" gutterBottom fontSize={16} pt={2}>
              Anboto Affiliate Benefits
            </Typography>

            <Stack direction="row" justifyContent="space-between" pb={3}>
              <Stack flexDirection="column" gap={2}>
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <CheckCircleIcon sx={{ color: "#179B83", width: 20 }} />
                  <Typography fontSize={14} fontWeight={400}>
                    Up to 30% commission
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <CheckCircleIcon sx={{ color: "#179B83", width: 20 }} />
                  <Typography fontSize={14} fontWeight={400}>
                    Sub-Affiliates
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <CheckCircleIcon sx={{ color: "#179B83", width: 20 }} />
                  <Typography fontSize={14} fontWeight={400}>
                    member of the Anboto Family (exclusive telegram group, early access to new features, etc)
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Typography variant="h6" gutterBottom fontSize={16}>
              Becoming an Affiliate is easy
            </Typography>

            <Stack direction="row" justifyContent="space-between" pb={3}>
              <Stack flexDirection="column" gap={2}>
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <CheckCircleIcon sx={{ color: "#179B83", width: 20 }} />
                  <Typography fontSize={14} fontWeight={400}>
                    Apply - a member of our team will contact you
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <CheckCircleIcon sx={{ color: "#179B83", width: 20 }} />
                  <Typography fontSize={14} fontWeight={400}>
                    Share your exclusive invitation codes to users and traders
                  </Typography>
                </Stack>
                <Stack flexDirection="row" gap={1} alignItems="center">
                  <CheckCircleIcon sx={{ color: "#179B83", width: 20 }} />
                  <Typography fontSize={14} fontWeight={400}>
                    Earn up to 30% in commissions. Extra % if your user becomes an Affiliate
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Typography fontSize={14} variant="body1" mb={1}>
              The more traders you invite, the higher your earnings potential becomes. Don&apos;t miss out on this
              opportunity to earn while sharing your passion for crypto trading. Join the Anboto Affiliate Program
              today, take advantage of our generous referral rates and start building your financial future.
            </Typography>

            <Stack mt={2} justifyContent="center" direction="row">
              <AnbotoButton
                onClick={() => onRequestReferralProgram()}
                sx={{
                  width: 250,
                  height: 32,
                  fontSize: 14,
                  fontWeight: 500,
                }}
                variant={"contained"}
                endIcon={<VpnKeyIcon />}
                disabled={referrer_request_sent}
              >
                {referrer_request_sent ? "Pending..." : "Apply to Affiliate Program"}
              </AnbotoButton>
            </Stack>
          </Stack>
        </ReferralBox>
      </>
    );
  }

  return (
    <>
      <Banner />

      <ReferralBox p={3} gap={4} direction="row">
        <Stack
          sx={{ backgroundColor: `${theme.custom.background.default}` }}
          borderRadius={2}
          direction="row"
          gap={8}
          padding={3}
          alignItems="center"
        >
          {address ? (
            <>
              <Typography fontSize={20} fontWeight={500}>
                Sign a transaction to join the referral program
              </Typography>
              <AnbotoButton
                onClick={() => onSign(SIGN_MESSAGE)}
                sx={{
                  fontSize: 14,
                  width: 240,
                  height: 32,
                }}
                variant="contained"
              >
                {signIsLoading ? "Loading..." : "Sign transaction"}
              </AnbotoButton>
            </>
          ) : (
            <>
              <Typography fontSize={20} fontWeight={500}>
                {address} Connect your wallet to join the referral program
              </Typography>
              <AnbotoConnectWalletButton />
            </>
          )}
        </Stack>
      </ReferralBox>
    </>
  );
};
