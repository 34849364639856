import React, { forwardRef } from "react";
import { MenuItem, useTheme } from "@mui/material";
import { OrderTradingDurationUnit } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const ORDER_TRADING_DURATION_UNITS: OrderTradingDurationUnit[] = [
  OrderTradingDurationUnit.DAYS,
  OrderTradingDurationUnit.HOURS,
  OrderTradingDurationUnit.MINUTES,
];

export const ORDER_TRADING_DURATION_UNIT_NAME: Record<OrderTradingDurationUnit, string> = {
  [OrderTradingDurationUnit.DAYS]: "Days",
  [OrderTradingDurationUnit.HOURS]: "Hours",
  [OrderTradingDurationUnit.MINUTES]: "Mins",
};

export const TradingDurationUnitField = forwardRef((props: AnbotoTextFieldProps, ref) => {
  const theme = useTheme();
  return (
    <AnbotoTextField
      {...props}
      inputRef={ref}
      select
      hiddenLabel
      size="small"
      fullWidth
      InputProps={{
        sx: {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          background: theme.custom.background.inputAdornment.main,
        },
      }}
    >
      {ORDER_TRADING_DURATION_UNITS.map((x) => (
        <MenuItem key={x} value={x}>
          {ORDER_TRADING_DURATION_UNIT_NAME[x]}
        </MenuItem>
      ))}
    </AnbotoTextField>
  );
});
TradingDurationUnitField.displayName = "TradingDurationUnitField";
