import React from "react";
import { DialogProps, Stack } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { DevTool } from "@hookform/devtools";
import { SettingsDialog } from "../settings-dialog";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { Wallet } from "@src/store/apis/anbotoApi/types";
import { RootState } from "@src/store/types";
import {
  useWalletCreateBulkForm,
  WalletCreateBulkFormFieldValues,
} from "@src/pages/settings/wallet/use-wallet-create-bulk-form";
import { WalletCreateBulkFormItemCard } from "@src/pages/settings/wallet/wallet-create-bulk-form-item-card";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";

type WalletCreateDialogProps = DialogProps & {
  onSubmitSuccess(): void;
  onClose(): void;
};

export const WalletCreateBulkDialog = ({ onSubmitSuccess, onClose, ...props }: WalletCreateDialogProps) => {
  const form = useWalletCreateBulkForm();

  const fieldArray = useFieldArray({
    control: form.control,
    name: "wallets",
  });

  const snackbar = useSnackbar();

  const [updateWallets] = anbotoApi.useUpdateWalletsMutation();
  const getWalletsQuery = anbotoApi.useGetWalletsQuery(null);

  const { user_uuid } = useSelector((state: RootState) => state.user);

  const onCreateWalletFormClick = () => {
    fieldArray.append({
      name: "",
      address: "",
    });
  };

  const onWalletFormDeleteClick = (index: number) => {
    fieldArray.remove(index);
  };

  const onResetClick = () => {
    form.reset();
  };

  const onSubmit = async (x: WalletCreateBulkFormFieldValues) => {
    try {
      const wallets: Wallet[] = [...getWalletsQuery.data!.results, ...x.wallets];
      await updateWallets({ wallets, userId: user_uuid }).unwrap();
      snackbar.enqueueSnackbar("Wallets have been added", { variant: "success" });
      onSubmitSuccess();
      form.reset();
    } catch (e) {
      snackbar.enqueueSnackbar(parseAnbotoRequestError(e?.data), { variant: "error" });
    }
  };

  return (
    <SettingsDialog
      title="Add wallet address"
      onClose={onClose}
      {...props}
      actions={
        <Stack direction="row" justifyContent="space-between" width="100%">
          <AnbotoButton sx={{ minWidth: 160 }} variant="outlined" color="secondary" size="small" onClick={onResetClick}>
            Remove all
          </AnbotoButton>
          <AnbotoButton
            sx={{ minWidth: 160 }}
            size="small"
            variant="contained"
            onClick={form.handleSubmit(onSubmit)}
            loading={form.formState.isSubmitting}
          >
            Save
          </AnbotoButton>
        </Stack>
      }
    >
      {fieldArray.fields.map((x, i) => (
        <WalletCreateBulkFormItemCard
          control={form.control}
          index={i}
          key={x.id}
          onCreateWalletClick={onCreateWalletFormClick}
          onWalletDeleteClick={() => onWalletFormDeleteClick(i)}
          isSubmitting={form.formState.isSubmitting}
        />
      ))}
      <DevTool control={form.control} />
    </SettingsDialog>
  );
};
