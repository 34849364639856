import React from "react";
import { Stack, Box, styled } from "@mui/material";
import _omit from "lodash/omit";
import { WIDGET_HEADER_HEIGHT } from "../constants";
import { addWidget, removeWidget, changeGroup } from "../store/widgets.slice";
import { WidgetConfigLayout, WidgetName, WidgetOptions } from "../types";
import { WidgetHeader, WidgetHeaderProps } from "../components/widget-header";
import { WidgetSymbolSelect } from "../components/widget-symbol-select";
import { useAppDispatch } from "@src/store/hooks";

export type WidgetBaseProps = {
  component?: React.ReactNode;
  id: string;
  name: WidgetName;
  title?: WidgetHeaderProps["title"];
  group: string;
  dataGrid?: WidgetConfigLayout;
  noHeader?: boolean;
  headerProps?: WidgetHeaderProps;
  hideGroup?: boolean;
  stableGutter?: boolean;
  otc?: boolean;
} & WidgetOptions;

export const WidgetBase = React.forwardRef(
  (
    {
      children,
      component,
      name,
      id,
      multiple,
      group = "",
      title,
      withGroups,
      removable,
      symbolSelect,
      symbolSelectDisabled,
      dataGrid,
      noHeader,
      headerProps,
      lock,
      noEmptyGroup,
      hideGroup,
      otc,
      ...props
    }: React.PropsWithChildren<WidgetBaseProps>,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const dispatch = useAppDispatch();

    const _addWidget = () => {
      dispatch(addWidget({ name, group }));
    };

    const _removeWidget = () => {
      dispatch(removeWidget(id));
    };

    const _changeGroup = (group: string) => {
      dispatch(changeGroup({ id, group }));
    };

    return (
      <Root {..._omit(props, ["maxNumber"])} ref={ref} data-grid={dataGrid}>
        {noHeader ? null : (
          <WidgetHeader
            title={title}
            onPlusClick={multiple ? _addWidget : undefined}
            onCloseClick={removable ? _removeWidget : undefined}
            onGroupChange={withGroups ? _changeGroup : undefined}
            noEmptyGroup={noEmptyGroup}
            group={group}
            lock={lock}
            hideGroup={hideGroup}
            {...headerProps}
          >
            {symbolSelect && <WidgetSymbolSelect group={group} key={id} disabled={symbolSelectDisabled} otc={otc} />}
          </WidgetHeader>
        )}
        <Content
          sx={{
            marginTop: noHeader ? 0 : `${WIDGET_HEADER_HEIGHT}px`,
            scrollbarGutter: props.stableGutter ? "stable" : undefined,
          }}
        >
          {component}

          {children}
        </Content>
      </Root>
    );
  }
);

WidgetBase.displayName = "WidgetBase";

const Root = styled(Stack)(({ theme }) => ({
  position: "relative",
  background: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
}));

const Content = styled(Box)({
  flex: 1,
  overflow: "auto",
  position: "relative",
});
