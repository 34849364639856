import React from "react";
import { Typography } from "@mui/material";
import { CELL_FONT_SIZE } from "../orders-table";

type OrderTableSideProps = {
  side?: string;
};

export const OrderTableSide = ({ side }: OrderTableSideProps) => {
  return side ? (
    <Typography
      variant="body2"
      sx={{
        color: side?.toUpperCase() === "BUY" ? "success.main" : "error.main",
        fontSize: CELL_FONT_SIZE,
      }}
    >
      {side?.toUpperCase()}
    </Typography>
  ) : null;
};
