import React, { useState } from "react";
import { Stack, useTheme, Menu, Typography, IconButton, styled, Divider, Tooltip } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { useMatch } from "react-router-dom";
import { createPortal } from "react-dom";
import { useSnackbar } from "notistack";
import { WidgetName, PredefinedWidgetsLayout } from "../../types";
import { addWidget, setPredefinedLayout } from "../../store/widgets.slice";
import { anbotoWidgetsApi } from "../../anboto-widgets-api";
import { LAYOUT_VERSION } from "../../constants";
import { Layouts } from "./layouts";
import { SaveButton } from "./save-button";
import { LayoutDialog } from "./layout-dialog";
import { WidgetsList } from "./widgets-list";
import { LayoutsOtc } from "./layouts-otc";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { setLocked } from "@src/store/slices/uiSettingsSlice";
import { useDialog } from "@src/hooks/useDialog";

export const WidgetsMenu = ({ children }: React.PropsWithChildren<{}>) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();

  const [selected, setSelected] = useState<PredefinedWidgetsLayout | null>(null);

  const view = useAppSelector((state) => state.widgets.view);
  const currentLayout = useAppSelector((state) => state.widgets.views[view].layout);

  const isLayoutLocked = useAppSelector((state) => state.uiSettings.isLayoutLocked);
  const cefiMatchUrl = useMatch("/trades/cefi");
  const otcMatchUrl = useMatch("/trades/otc");

  const isOtc = otcMatchUrl?.pathname === "/trades/otc";

  const showMenuButton = cefiMatchUrl?.pathname === "/trades/cefi" || isOtc;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const settingsOpen = Boolean(anchorEl);

  const dialog = useDialog();
  const [createLayout, createLayoutMutation] = anbotoWidgetsApi.useCreateLayoutMutation();
  const [updateLayout, updateLayoutMutation] = anbotoWidgetsApi.useUpdateLayoutMutation();
  const [deleteLayout] = anbotoWidgetsApi.useDeleteLayoutMutation();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLockWidgets = () => {
    dispatch(setLocked(!isLayoutLocked));
  };

  const handleWidgetClick = (name: WidgetName) => {
    dispatch(addWidget({ name }));
    dispatch(setLocked(false));
  };

  const onClose = () => {
    dialog.hide();
    setSelected(null);
  };

  const applyLayout = (layout: PredefinedWidgetsLayout) => {
    dispatch(setPredefinedLayout(layout));
  };

  const createUserLayout = async (name: string) => {
    try {
      const layout: Omit<PredefinedWidgetsLayout, "id" | "groups"> = {
        name,
        version: LAYOUT_VERSION,
        view,
        layout: currentLayout,
      };

      await createLayout(layout).unwrap();

      dialog.hide();
    } catch (error) {
      snackbar.enqueueSnackbar("Create layout error", { variant: "error" });
      console.log(error);
    }

    onClose();
  };

  const updateUserLayout = async (name: string) => {
    if (selected) {
      try {
        await updateLayout({
          ...selected,
          name,
        }).unwrap();

        dialog.hide();
      } catch (error) {
        snackbar.enqueueSnackbar("Update layout error", { variant: "error" });
        console.log(error);
      }

      onClose();
    }
  };

  const onSubmit = async (name: string) => {
    if (selected) updateUserLayout(name);
    else createUserLayout(name);
  };

  const onDeleteClick = async (id: string) => {
    try {
      await deleteLayout(id).unwrap();
    } catch (error) {
      snackbar.enqueueSnackbar("Delete layout error", { variant: "error" });
      console.log(error);
    }
  };

  const showEditModal = (layout: PredefinedWidgetsLayout) => {
    setSelected(layout);
    dialog.show();
  };

  const loading = createLayoutMutation.isLoading || updateLayoutMutation.isLoading;

  return !view || !showMenuButton ? null : (
    <>
      <Stack onClick={handleMenuClick}>{children}</Stack>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={settingsOpen}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            backgroundColor: "#031116",
            borderColor: theme.palette.primary.main,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: "4px",
            mb: 1.5,
            pl: 0,
            pr: 0,
            pt: 1,
            pb: 2,
            "& .MuiMenu-list": {
              padding: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Stack width={306}>
          {!isOtc && (
            <>
              <Stack
                direction="row"
                position="relative"
                justifyContent="space-between"
                alignItems="center"
                pr={1}
                sx={{ minHeight: 34 }}
              >
                <Title>Layout</Title>
                <SaveButton onClick={() => dialog.show()} />
              </Stack>
              <Layouts onLayoutClick={applyLayout} onEditClick={showEditModal} onDeleteClick={onDeleteClick} />
              <Divider />
            </>
          )}
          {isOtc && (
            <>
              <LayoutsOtc onLayoutClick={applyLayout} />
              <Divider />
            </>
          )}
          <Stack mb={1} pt={1.5} alignItems="center" direction="row" justifyContent="space-between">
            <Title>Widgets</Title>
            <Tooltip title={isLayoutLocked ? "Unlock" : "Lock"}>
              <IconButton onClick={handleLockWidgets} sx={{ color: theme.palette.text.secondary, mr: 1 }}>
                {isLayoutLocked ? (
                  <LockOutlinedIcon sx={{ fontSize: 18 }} />
                ) : (
                  <LockOpenOutlinedIcon sx={{ fontSize: 18 }} />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
          <WidgetsList onWidgetClick={handleWidgetClick} />
        </Stack>
      </Menu>
      {createPortal(
        <LayoutDialog
          selected={selected}
          visible={dialog.isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
          loading={loading}
        />,
        document.body
      )}
    </>
  );
};

export const Title = styled(Typography)(({ theme }) => ({
  userSelect: "none",
  paddingLeft: theme.spacing(2),
  fontSize: 14,
  fontWeight: 600,
  color: theme.palette.text.disabled,
}));
