import React from "react";
import { Stack, Typography } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

type ValueChangeProps = {
  value: number;
  fontSize: number;
};

const ValueChange = ({ value, fontSize }: ValueChangeProps) => {
  const color = value >= 0 ? "#179B83" : "#f44336";

  return (
    <Stack direction="row" alignItems="center">
      {value >= 0 ? (
        <ArrowDropUpIcon sx={{ color, fontSize: 16 }} />
      ) : (
        <ArrowDropDownIcon sx={{ color, fontSize: 16 }} />
      )}{" "}
      <Typography variant="caption" color={color} fontSize={fontSize}>
        {value.toFixed(2)}%
      </Typography>
    </Stack>
  );
};

export default ValueChange;
