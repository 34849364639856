import { anbotoApi } from "@src/store/apis/anbotoApi";

export type GetPortfolioDefiPnlParams = {
  wallet_labels: string; // binance,bybit
};

export type PnlDefiData = {
  bucket: string; // ISOdateString "2023-01-18T00:00:00Z"
  daily_total_balance: number; // 30188.596629256386
  pnl: number | null; // 1.24
  nb_of_wallets_in_pnl: number;
  wallets_in_pnl: string[];
};

export type GetPortfolioDefiPnlResult = {
  success: boolean;
  data: PnlDefiData[];
};

const portfolioDefiPnlApi = anbotoApi.injectEndpoints({
  endpoints: (build) => ({
    portfolioDefiPnl: build.query<GetPortfolioDefiPnlResult, GetPortfolioDefiPnlParams>({
      query: (params) => ({
        url: `/portfolio/defi_pnl/`,
        params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const portfolioDefiPnl = portfolioDefiPnlApi.endpoints.portfolioDefiPnl;

export const { usePortfolioDefiPnlQuery, useLazyPortfolioDefiPnlQuery } = portfolioDefiPnlApi;
