import { useCallback, useEffect, useRef, useState } from "react";
import _debounce from "lodash/debounce";
import { useAppSelector } from "@src/store/hooks";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { feeManagerApi } from "@src/pages/settings/fee-manager/api";
import { prepareExchange } from "@src/pages/cefi/order-symbol-chart/anboto-datafeed/helpers";
import { CefiExchangeMarketType, OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";

type PostTradeCreditsCefiProps = {
  quantity: string;
  orderSymbol: OrderSymbol | null;
};

type OrderParamsProps = {
  symbol: string;
  exchange: string;
  market_type: string;
  quantity: string;
};

export const usePostTradeCreditsCefi = (params?: PostTradeCreditsCefiProps) => {
  const team_uuid = useAppSelector((state) => state.user.team_uuid);
  const [postTradeCredits, setPostTradeCredits] = useState(0);
  const [getMarketData] = anbotoApi.useLazyGetMarketDataQuery();
  const accountStats = feeManagerApi.useGetFeeManagerAccountStatsQuery({ team_uuid: team_uuid });
  const tokensAndBalances = feeManagerApi.useGetFeeTokensAndBalancesQuery();
  const getExchangesQuery = anbotoApi.useGetExchangesQuery({ isDefi: false });
  const [postTradeCreditsLoading, setPostTradeCreditsLoading] = useState(false);
  const feeManagerEnabled: boolean = useAppSelector((state) => state.user.fee_manager_enabled);
  const { orderSymbol, quantity } = params || {};
  const calls = useRef(0);
  const dataLoading = tokensAndBalances.isFetching && accountStats.isFetching && getExchangesQuery.isFetching;
  const hasRequiredData =
    tokensAndBalances.data && accountStats.data && getExchangesQuery.data && orderSymbol && quantity;

  const triggerRefetch = useCallback(
    _debounce(async (orderSymbol: OrderSymbol, quantity: string) => {
      if (orderSymbol && quantity) {
        calls.current += 1;

        const postCredits = await fetchPostCredits({
          symbol: orderSymbol.symbol,
          exchange: orderSymbol.exchange,
          market_type: orderSymbol.market_type,
          quantity,
        });

        calls.current -= 1;

        if (calls.current < 1) {
          setPostTradeCredits(postCredits);
          setPostTradeCreditsLoading(false);
        }
      }
    }, 1000),
    [accountStats, tokensAndBalances, getExchangesQuery]
  );

  const fetchPostCredits = async ({ symbol, exchange, market_type, quantity }: OrderParamsProps): Promise<number> => {
    try {
      const feeRate = accountStats?.data?.fee_rates;
      const creditsBalance = tokensAndBalances?.data?.balance || 0;

      // @TODO: get price with another way as it's possible no trades happend last hour
      const marketData = await getMarketData({
        timeframe: "15m",
        since: +new Date() - 60 * 60 * 1000,
        symbol,
        exchange_id: prepareExchange(exchange),
        market_type: market_type as CefiExchangeMarketType,
      }).unwrap();

      const isFeeLess =
        !feeManagerEnabled ||
        getExchangesQuery.data?.results.find((config) => config?.exchange_id === prepareExchange(exchange))
          ?.is_fee_less;
      const bar = [...(marketData?.data || [])].pop() || [];
      const price = bar[4] || 0;

      // we set 0 as default value, so if some how an error happens in our end required credits won't be negative or undefined
      // and so the client can launch his trade
      const postTradeCreditsBalance = creditsBalance - parseFloat(quantity) * price * feeRate || 0;

      return isFeeLess ? creditsBalance : postTradeCreditsBalance;
    } catch (error) {
      return 0;
    }
  };

  useEffect(() => {
    if (hasRequiredData && !dataLoading) {
      setPostTradeCreditsLoading(true);
      triggerRefetch(orderSymbol, quantity);
    }
  }, [dataLoading, hasRequiredData, quantity, getLivePriceId(orderSymbol || null)]);

  return { postTradeCredits, fetchPostCredits, postTradeCreditsLoading: postTradeCreditsLoading || dataLoading };
};
