import React from "react";
import { Stack, Typography } from "@mui/material";
import { FeeWalletHelp } from "./fee-wallet-help";
import { UseDepositType } from "./overview-token-fees";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

export const FeeWalletIntro = ({ useDeposit }: { useDeposit: UseDepositType }) => {
  return (
    <Stack maxWidth={900} ml="auto" mr="auto">
      <Typography variant="h6" textAlign="center">
        Fee Wallet - Simplifying Fee Payments
      </Typography>

      <FeeWalletHelp />

      <Stack alignItems="center" justifyContent="center" mt={3} mb={3}>
        <AnbotoButton variant="contained" onClick={() => useDeposit.show()}>
          Add Credits to your Fee Wallet
        </AnbotoButton>
        <Typography variant="subtitle1" sx={{ color: (theme) => theme.custom.colors.dim }}>
          (These credits serve as your dedicated fund to cover trading fees)
        </Typography>
      </Stack>
    </Stack>
  );
};
