import React from "react";
import { ThemeProvider, Tooltip } from "@mui/material";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { createTheme, Theme } from "@mui/material/styles";
import { muiTheme } from "@src/components/theme/mui-theme";

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    xl: true;
  }
  interface ButtonPropsVariantOverrides {
    dim: true;
    flat: true;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export type AnbotoButtonProps = LoadingButtonProps & { tooltip?: string };

const theme: Theme = createTheme({
  palette: { mode: "dark" },
  components: {
    MuiButton: {
      variants: [
        {
          props: { size: "xl" },
          style: {
            padding: "13px 39px",
            fontSize: "24px",
            lineHeight: "38px",
          },
        },
        {
          props: { variant: "dim" },
          style: {
            backgroundColor: muiTheme.palette.background.default,
            color: muiTheme.palette.text.secondary,
          },
        },
        {
          props: { variant: "flat" },
          style: {
            backgroundColor: muiTheme.palette.background.default,
          },
        },
      ],
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "white",
        },
        sizeLarge: {
          fontSize: "20px",
          lineHeight: "28px",
          padding: "10px 30px",
        },
        sizeMedium: {
          fontSize: "16px",
          lineHeight: "24px",
          padding: "8px 24px",
        },
        sizeSmall: {
          fontSize: "14px",
          lineHeight: "21px",
          padding: "6px 18px",
        },
        containedPrimary: {
          background: "linear-gradient(114.93deg, #2C72E3 0%, #56C3F2 100%)",
          color: "white",
          "&.Mui-disabled": {
            color: "#4A5053",
            background: "#232C2F",
            border: "1px solid #232C2F",
          },
        },
        outlinedPrimary: {
          borderColor: "#232C2F",
          background: "#232C2F",
          color: "white",
          "&:hover": {
            borderColor: "#2C72E3",
          },
        },
        outlinedSecondary: {
          borderColor: "#232C2F",
          background: "#232C2F",
          color: "white",
          "&:hover": {
            borderColor: "#2C72E3",
          },
        },
      },
    },
  },
});

export const AnbotoButton = (props: AnbotoButtonProps) => {
  const { children, tooltip, ...restProps } = props;

  return (
    <ThemeProvider theme={theme}>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <LoadingButton {...restProps}>{children}</LoadingButton>
        </Tooltip>
      ) : (
        <LoadingButton {...restProps}>{children}</LoadingButton>
      )}
    </ThemeProvider>
  );
};
