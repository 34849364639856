import React, { FC, useState } from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxForm, CheckboxControl } from "./styled";
import { AllocationCefiFundsTable, filterCefiAllocations, filterCefiDataByBalance } from "@src/pages/portfolio";
import { CefiAllocation, CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";
import { MarketTypeSelector } from "@src/pages/portfolio/components/market-type-selector";
import { RootState } from "@src/store/types";
import { setCefiExchangeMarketType } from "@src/store/slices/portfolioSlice";

interface AllocationCefiFundsOverviewProps {
  data?: CefiAllocation[];
  isError: boolean;
  isFetching: boolean;
  refetch: () => void;
}

export const AllocationCefiFundsOverview: FC<AllocationCefiFundsOverviewProps> = ({
  data,
  isError,
  isFetching,
  refetch,
}) => {
  const [hideSmallBalance, onHideSmallBalance] = useState<boolean>(true);

  const dispatch = useDispatch();

  const marketType = useSelector((state: RootState) => state.portfolio.marketType);

  const cefiTotalBalance = useSelector((state: RootState) => state.portfolio.cefiTotalBalance);
  const dataFilteredByBalance = filterCefiDataByBalance(hideSmallBalance, cefiTotalBalance, data);
  const filteredData = filterCefiAllocations(
    {
      market_type: marketType,
    },
    dataFilteredByBalance
  );

  const setMarketType = (mType: CefiExchangeMarketType) => {
    dispatch(setCefiExchangeMarketType(mType));
  };

  const isPositions = !!filteredData.positions?.length;

  return (
    <Box>
      <Typography mb={1} fontSize={18} lineHeight="30px" variant="h6">
        Allocation
      </Typography>
      <Stack width="100%" display="flex" flexDirection="row" justifyContent="space-between" marginBottom={1}>
        {isFetching ? (
          <Skeleton height={34} width={300} animation="wave" />
        ) : (
          <MarketTypeSelector
            marketType={marketType}
            setMarketType={setMarketType}
            spotAmount={filteredData.balances.spot}
            futureAmount={filteredData.balances.future}
            onHideSmallBalance={onHideSmallBalance}
          />
        )}
        {marketType === CefiExchangeMarketType.SPOT && (
          <CheckboxForm
            control={
              <CheckboxControl
                size="small"
                checked={hideSmallBalance}
                onChange={() => onHideSmallBalance(!hideSmallBalance)}
              />
            }
            label="Hide small balance (<0.1%)"
          />
        )}
      </Stack>

      {isPositions && (
        <>
          <Typography mb={1} mt={2} fontSize={18} lineHeight="30px" variant="h6">
            Positions
          </Typography>

          <AllocationCefiFundsTable
            isTradingButtonShow={false}
            isOverview={true}
            data={filteredData.positions}
            isError={isError}
            isFetching={isFetching}
            refetch={refetch}
          />

          <Typography mb={1} mt={2} fontSize={18} lineHeight="30px" variant="h6">
            Assets
          </Typography>
        </>
      )}

      <AllocationCefiFundsTable
        isTradingButtonShow={false}
        isOverview={true}
        data={filteredData.margin}
        isError={isError}
        isFetching={isFetching}
        refetch={refetch}
      />
    </Box>
  );
};
