import React, { useEffect } from "react";
import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import { ProfileInfoValues, useProfileInfoForm } from "../index";
import { getStyledIcon } from "../../layout/get-styled-icon";
import { AuthCard } from "../../layout/auth-card";
import { FormInputLabel } from "../../layout/form-input-label";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { muiTheme } from "@src/components/theme/mui-theme";
import { CreateAccountParams } from "@src/store/apis/anbotoApi/types";
import { RootState } from "@src/store/types";
import { AccountTypes } from "@src/pages/auth/onboarding.slice";
import { REG_EX_MAIL, REG_EX_TELEGRAM } from "@src/pages/auth/registration/constants";
import { telegramIdFormater } from "@src/pages/settings/helpers";

export type VerificationInfosProps = {
  onBack: () => void;
  onNext: (value: Partial<CreateAccountParams>) => void;
  accountName: string;
  currentEmail: string;
  loading: boolean;
};

export const VerificationInfos = ({ currentEmail, accountName, onNext, onBack, loading }: VerificationInfosProps) => {
  const accountType = useSelector((state: RootState) => state.onboarding.accountType);
  const selectedAccountType = useSelector((state: RootState) => state.onboarding.selectedAccountType);
  const [isTeamAccounExists, { isLoading: checkCompanyNameLoading }] = anbotoApi.useLazyIsTeamAccountExistsQuery();
  const isIndividualInviteAccountTeam =
    accountType === AccountTypes.INDIVIDUAL && selectedAccountType === AccountTypes.TEAM;

  const profileInfoForm = useProfileInfoForm(
    isIndividualInviteAccountTeam,
    isIndividualInviteAccountTeam,
    isTeamAccounExists
  );

  useEffect(() => {
    profileInfoForm.setValue("email", currentEmail || "");
    profileInfoForm.setValue("companyName", accountName || "");
  }, []);

  const firstName = profileInfoForm.watch("firstName");
  const lastName = profileInfoForm.watch("lastName");
  const email = profileInfoForm.watch("email");
  const telegramID = profileInfoForm.watch("telegramID");
  const companyName = profileInfoForm.watch("companyName");

  useEffect(() => {
    profileInfoForm.clearErrors("companyName");
  }, [companyName]);
  useEffect(() => {
    profileInfoForm.clearErrors("firstName");
  }, [firstName]);
  useEffect(() => {
    profileInfoForm.clearErrors("lastName");
  }, [lastName]);

  const onSubmitProfileInfoForm = async ({ firstName, lastName, contactNo, companyName }: ProfileInfoValues) => {
    const trimmedNumber = contactNo?.replace(/\s/g, "") || "";

    onNext({
      first_name: firstName || "",
      last_name: lastName || "",
      company_name: companyName,
      ...(trimmedNumber.length >= 5 ? { phone_number: trimmedNumber } : {}),
      ...(telegramID ? { telegram_id: telegramIdFormater(telegramID) } : {}),
    });
  };

  return (
    <AuthCard>
      <Grid container direction="column" alignItems="center" spacing={2.5} paddingTop={5}>
        <Grid item width="100%" display="flex" justifyContent="space-between">
          <Controller
            name="firstName"
            control={profileInfoForm.control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%", marginRight: 0.5 }} size="small" variant="outlined">
                <FormInputLabel fieldstate={fieldState}>First Name*</FormInputLabel>
                <OutlinedInput {...field} error={!!fieldState.error} type="text" label="First Name" />
                {fieldState.error ? (
                  <Typography sx={{ fontSize: 13 }} color={muiTheme.palette.error.main}>
                    {fieldState.error?.message || "First Name is required"}
                  </Typography>
                ) : null}
              </FormControl>
            )}
          />
          <Controller
            name="lastName"
            control={profileInfoForm.control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%", marginLeft: 0.5 }} size="small" variant="outlined">
                <FormInputLabel fieldstate={fieldState}>Last Name*</FormInputLabel>
                <OutlinedInput {...field} error={!!fieldState.error} type="text" label="Last Name" />
                {fieldState.error ? (
                  <Typography sx={{ fontSize: 13 }} color={muiTheme.palette.error.main}>
                    {fieldState.error?.message || "Last Name is required"}
                  </Typography>
                ) : null}
              </FormControl>
            )}
          />
        </Grid>

        {!(accountType === AccountTypes.INDIVIDUAL && selectedAccountType === AccountTypes.TEAM) && (
          <Grid item width="100%">
            <Controller
              name="email"
              control={profileInfoForm.control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormControl sx={{ width: "100%" }} size="small" variant="outlined">
                  <FormInputLabel fieldstate={fieldState}>Email*</FormInputLabel>
                  <OutlinedInput
                    {...field}
                    error={!!fieldState.error}
                    type="text"
                    label="Email"
                    disabled={accountType === AccountTypes.TEAM}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">{getStyledIcon(CheckCircleIcon, REG_EX_MAIL.test(email))}</IconButton>
                      </InputAdornment>
                    }
                  />
                  {fieldState.error ? (
                    <Typography sx={{ fontSize: 13 }} color={muiTheme.palette.error.main}>
                      {fieldState.error?.message || "Email is required"}
                    </Typography>
                  ) : null}
                </FormControl>
              )}
            />
          </Grid>
        )}
        <Grid item width="100%">
          <Controller
            name="telegramID"
            control={profileInfoForm.control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%" }} size="small" variant="outlined">
                <FormInputLabel fieldstate={fieldState}>@telegramID</FormInputLabel>
                <OutlinedInput
                  {...field}
                  type="text"
                  label="@telegramID"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        {getStyledIcon(CheckCircleIcon, REG_EX_TELEGRAM.test(telegramID))}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {fieldState.error ? (
                  <Typography sx={{ fontSize: 13 }} color={muiTheme.palette.error.main}>
                    {fieldState.error?.message}
                  </Typography>
                ) : null}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item width="100%">
          <Controller
            name="companyName"
            control={profileInfoForm.control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%" }} size="small" variant="outlined">
                <FormInputLabel fieldstate={fieldState}>Company Name*</FormInputLabel>
                <OutlinedInput
                  {...field}
                  error={!!fieldState.error}
                  disabled={accountType === AccountTypes.TEAM}
                  type="text"
                  label="Company Name"
                />
                {fieldState.error ? (
                  <Typography sx={{ fontSize: 13 }} color={muiTheme.palette.error.main}>
                    {fieldState.error?.message || "Company name is required"}
                  </Typography>
                ) : null}
              </FormControl>
            )}
          />
        </Grid>
        <Grid container>
          <Typography sx={{ fontSize: 12, color: "text.secondary", mt: 1, ml: 3, pb: 7.5, textAlign: "left" }}>
            * Required field
          </Typography>
        </Grid>
        <Grid item marginY="40px" width="100%" display="flex" flexDirection="row">
          <AnbotoButton
            tabIndex={1}
            sx={{ marginRight: "5px", width: "15%", "&:focus": { outline: 1 } }}
            size="large"
            variant="outlined"
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
            type="submit"
            onClick={onBack}
            disabled={!!loading || checkCompanyNameLoading}
          >
            <ArrowBackIcon />
          </AnbotoButton>
          <AnbotoButton
            tabIndex={1}
            sx={{ marginLeft: "5px", width: "80%", "&:focus": { outline: 1 } }}
            size="large"
            variant="contained"
            loadingIndicator={<CircularProgress color="inherit" size={16} />}
            type="submit"
            loading={!!loading || checkCompanyNameLoading}
            onClick={profileInfoForm.handleSubmit(onSubmitProfileInfoForm)}
          >
            Next
          </AnbotoButton>
        </Grid>
      </Grid>
    </AuthCard>
  );
};
