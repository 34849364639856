import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Box, CircularProgress, FormControl, OutlinedInput, Stack } from "@mui/material";
import formValidator from "@src/utils/formValidator";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { FieldValues } from "@src/pages/settings/two-fa/index";

type AuthFormProps = {
  onSubmit: ({ two_fa_code }: FieldValues) => Promise<void>;
  loading?: boolean;
};

type OtpCode = {
  one: string;
  two: string;
  three: string;
  four: string;
  five: string;
  six: string;
};

const OTP_FORM_INDEX = {
  0: "one",
  1: "two",
  2: "three",
  3: "four",
  4: "five",
  5: "six",
};

const OtpForm = ({ onSubmit, loading }: AuthFormProps) => {
  const validationCodeSchema = formValidator
    .object({
      one: formValidator.string().matches(/[0-9]/).max(1).label("one"),
      two: formValidator.string().matches(/[0-9]/).max(1).label("two"),
      three: formValidator.string().matches(/[0-9]/).max(1).label("three"),
      four: formValidator.string().matches(/[0-9]/).max(1).label("four"),
      five: formValidator.string().matches(/[0-9]/).max(1).label("five"),
      six: formValidator.string().matches(/[0-9]/).max(1).label("six"),
    })
    .required();

  const defaultCodeFormValues = { one: "", two: "", three: "", four: "", five: "", six: "" };

  const codeValuesForm = useForm<OtpCode>({
    resolver: yupResolver(validationCodeSchema),
    defaultValues: defaultCodeFormValues,
  });
  codeValuesForm.watch();

  const validationCode = Object.values(codeValuesForm.getValues()).join("");

  const inputRefs = useRef<any[]>([]);
  const addRef = (el: any, i: number) => {
    inputRefs.current[i] = el;
  };
  const handleKeyDown = (e, inputIndex) => {
    if (!e) return;
    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();
      codeValuesForm.setValue(OTP_FORM_INDEX[inputIndex], "");
      if (inputIndex !== 0) inputRefs.current[inputIndex - 1].focus();
      return;
    }
    if (!isNaN(Number(e.key)) && inputIndex < 6) {
      e.preventDefault();
      codeValuesForm.setValue(OTP_FORM_INDEX[inputIndex], e.key);
      if (inputIndex < 5) {
        inputRefs.current[inputIndex + 1].focus();
      }
    }
  };

  const onVerify = async () => {
    await onSubmit({ two_fa_code: validationCode });
  };

  const onPaste = (e) => {
    const code = e.clipboardData.getData("Text");
    const isNumericCode = !isNaN(parseFloat(code)) && isFinite(code);
    if (isNumericCode) {
      (Object.keys(defaultCodeFormValues) as (keyof OtpCode)[]).forEach((x, i) => {
        codeValuesForm.setValue(x, e.clipboardData.getData("Text").charAt(i) || "");
      });
      inputRefs.current[5].focus();
    }
  };

  const allowOnlyNumber = (e) => {
    return e.key?.replace(/[^0-9]/g, "") || "";
  };

  return (
    <Stack display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" width="100%" alignItems="center" justifyContent="center">
        <Stack
          gap={1}
          paddingY={3}
          flexDirection="row"
          justifyContent="space-between"
          width="max-content"
          alignSelf="center"
        >
          {(Object.keys(defaultCodeFormValues) as (keyof OtpCode)[]).map((x, i) => (
            <Controller
              key={x}
              name={x}
              control={codeValuesForm.control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormControl>
                  <OutlinedInput
                    disabled={loading}
                    data-sentry-mask="true"
                    size="small"
                    {...field}
                    inputProps={{ maxLength: 1, style: { padding: 8, textAlign: "center" } }}
                    sx={{ width: 32 }}
                    error={!!fieldState.error}
                    onPaste={onPaste}
                    type="text"
                    inputRef={(el) => addRef(el, i)}
                    onKeyDown={(e) => handleKeyDown(e, i)}
                    onChange={(e) => allowOnlyNumber(e)}
                    className={field.value ? "Mui-focused" : ""}
                  />
                </FormControl>
              )}
            />
          ))}
        </Stack>
      </Box>

      <AnbotoButton
        tabIndex={1}
        sx={{ width: 160 }}
        size="small"
        variant="contained"
        fullWidth={true}
        disabled={validationCode.length < 6}
        loadingIndicator={<CircularProgress color="inherit" size={16} />}
        type="submit"
        onClick={codeValuesForm.handleSubmit(onVerify)}
        loading={loading}
      >
        Verify
      </AnbotoButton>
    </Stack>
  );
};
export default OtpForm;
