import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type PlacementCancelFieldProps = ControllerRenderProps & AnbotoTextFieldProps;

export const PlacementCancelField = forwardRef((props: PlacementCancelFieldProps, ref) => {
  return (
    <AnbotoTextField
      {...props}
      inputRef={ref}
      variant="outlined"
      size="small"
      label="Cancel Level"
      fullWidth
      type="number"
    />
  );
});

PlacementCancelField.displayName = "PlacementCancelField";
