import BigNumber from "bignumber.js";
import { ClipSizeType, GetExchangesSymbolData } from "@src/store/apis/anbotoApi/types";

export type ValidatorFn = (value: string) => string;

export type ExchangeSymbolValidator = {
  maxAmount: ValidatorFn;
  minAmount: ValidatorFn;
  contractSize: ValidatorFn;
  amountPrecision: ValidatorFn;
  pricePrecision: ValidatorFn;
  minPrice: ValidatorFn;
  maxPrice: ValidatorFn;
  minCost: ValidatorFn;
  minNotional: ValidatorFn;
  price: ValidatorFn;
  amount: ValidatorFn;
  clipSize: (amount: string, clipSizeValue: string, clipSizeType: ClipSizeType) => string;
};

export type ExchangeSymbolValidationErrorCode =
  | "minPrice"
  | "maxPrice"
  | "minAmount"
  | "maxAmount"
  | "minCost"
  | "amountPrecision"
  | "pricePrecision"
  | "contractSize"
  | "minNotional"
  | "clipSize"
  | "maxClipSize";

const getErrorText = (code: ExchangeSymbolValidationErrorCode, value: string, value2?: string) => {
  const errors = {
    maxAmount: `Quantity must be less then or equal to ${value}`,
    minAmount: `Quantity must be greater then or equal to ${value}`,
    contractSize: `Quantity must be a multiple of ${value}`,
    maxPrice: `The price must be less then or equal ot ${value}`,
    minPrice: `The price must be greater then or equal ot ${value}`,
    amountPrecision: `The max precision must be ${value}`,
    pricePrecision: `The max precision must be ${value}`,
    clipSize: `Quantity per slice ${value} is less than allowed (${value2})`,
    maxClipSize: `Quantity per slice must be less then or equal to ${value}`,
  } as Record<ExchangeSymbolValidationErrorCode, string>;

  return errors[code];
};

export const createValidator = (validationData?: GetExchangesSymbolData): ExchangeSymbolValidator => {
  return {
    maxAmount: (value: string) => {
      if (!validationData?.max_amount) return "";

      const maxAmount = new BigNumber(validationData.max_amount);

      return new BigNumber(value).gt(maxAmount) ? getErrorText("maxAmount", maxAmount.toString()) : "";
    },
    minAmount: (value: string) => {
      if (!validationData?.min_amount) return "";

      const minAmount = new BigNumber(validationData.min_amount);

      return new BigNumber(value).lt(minAmount) ? getErrorText("minAmount", minAmount.toString()) : "";
    },
    contractSize: (value: string) =>
      validationData?.contract_size && !new BigNumber(value).div(validationData.contract_size).isInteger()
        ? getErrorText("contractSize", new BigNumber(validationData.contract_size).toString())
        : "",
    amountPrecision: (value: string) => {
      if (validationData?.amount_precision === null || validationData?.amount_precision === undefined) return "";

      const valueRounded = new BigNumber(value).dp();

      return valueRounded && valueRounded > validationData?.amount_precision
        ? getErrorText("amountPrecision", validationData?.amount_precision.toString())
        : "";
    },
    pricePrecision: (value: string) => {
      if (validationData?.price_precision === null || validationData?.price_precision === undefined) return "";

      const valueRounded = new BigNumber(value).dp();

      return valueRounded && valueRounded > validationData.price_precision
        ? getErrorText("pricePrecision", validationData.price_precision.toString())
        : "";
    },
    minPrice: (value: string) => {
      return validationData?.min_price && new BigNumber(value).lt(new BigNumber(validationData.min_price))
        ? getErrorText("minPrice", new BigNumber(validationData.min_price).toString())
        : "";
    },
    maxPrice: (value: string) =>
      validationData?.max_price && new BigNumber(value).gt(new BigNumber(validationData.max_price))
        ? getErrorText("maxPrice", new BigNumber(validationData.max_price).toString())
        : "",
    minCost: () => "",
    minNotional: () => "",
    price: function (value: string) {
      return this.minPrice(value) || this.maxPrice(value) || this.pricePrecision(value);
    },
    amount: function (value: string) {
      return this.minAmount(value) || this.contractSize(value) || this.amountPrecision(value);
    },
    clipSize: function (amount: string, clipSizeValue: string, clipSizeType: ClipSizeType) {
      if (!validationData?.min_amount || !clipSizeValue || clipSizeType === ClipSizeType.AUTOMATIC) return "";

      const amountPerClip =
        clipSizeType === ClipSizeType.ABSOLUTE
          ? clipSizeValue
          : clipSizeType === ClipSizeType.PERCENTAGE
          ? new BigNumber(amount).multipliedBy(new BigNumber(clipSizeValue).dividedBy(100)).toString()
          : new BigNumber(amount).dividedBy(new BigNumber(clipSizeValue)).toString();

      const clipSizeValueBN = new BigNumber(clipSizeValue);
      if (clipSizeType === ClipSizeType.PERCENTAGE) {
        if (clipSizeValueBN.gt(100)) {
          return "Quantity must be less then or equal to 100";
        } else if (clipSizeValueBN.lte(0)) {
          return "Quantity must be greater then 0";
        }
      }

      if (clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS) {
        if (Number.isInteger(Number(clipSizeValue))) {
          if (clipSizeValueBN.lt(1)) {
            return "Quantity must be equal to or greater than 1";
          }
        } else {
          return "Quantity must be an integer";
        }
      }

      const minAmountPerClip = new BigNumber(validationData.min_amount);

      const clipSizeMaxAmountError =
        this.maxAmount(amountPerClip) || new BigNumber(amountPerClip).gt(amount)
          ? getErrorText("maxClipSize", `${amount}`)
          : "";

      const clipSizeMinAmountError = this.minAmount(amountPerClip)
        ? getErrorText("clipSize", `${amountPerClip}`, minAmountPerClip.toString())
        : "";

      const precisionError = clipSizeType === ClipSizeType.ABSOLUTE ? this.amountPrecision(amountPerClip) : "";

      return clipSizeMaxAmountError || clipSizeMinAmountError || precisionError || this.contractSize(amountPerClip);
    },
  };
};
