export const REFERRAL_PERCENT_AMOUNT: number[] = [0, 0.15, 0.25, 0.35, 0.5];
export const REFERRAL_LINK_BASE = "https://trade.anboto.xyz/auth/register_via_invite_link?referral_code=";
export const RANDOM_CODE_LENGTH = 7;
export const BLANK_MESSAGE = "message";

export const REFERRAL_LEVELS = [
  {
    name: "Tier 1",
    discount: 0.2,
    minVolume: 0,
  },
  {
    name: "Tier 2",
    discount: 0.25,
    minVolume: 50000000,
  },
  {
    name: "Tier 3",
    discount: 0.3,
    minVolume: 200000000,
  },
];
