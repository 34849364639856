import React from "react";
import { OrderbookOrders } from "@src/components/orderbook/types";
import { OrderSide, OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { useAnbotoTickerSubscription } from "@src/subscriptions/hooks/use-anboto-ticker-subscripton";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { getSweepPriceDelta } from "@src/components/price-impact/utils";

type PriceImpactContextState = {
  asks: OrderbookOrders;
  bids: OrderbookOrders;
  price: number;
  percentage: number;
  high: number;
  low: number;
  quoteVolume: number;
  baseVolume: number;
  oneMinVolume: number;
  sweepPrice: number;
  estimatedSlippage: number;
  percentOf24hVolume: number;
};

interface PriceImpactProviderProps {
  symbol: OrderSymbol;
  quantity: string;
  side: OrderSide | undefined;
}

export const PriceImpactContext = React.createContext<PriceImpactContextState>({} as PriceImpactContextState);

const PriceImpactProvider = ({
  children,
  symbol,
  quantity,
  side,
}: React.PropsWithChildren<PriceImpactProviderProps>) => {
  const { price, percentage, high, low, quoteVolume, baseVolume } = useAnbotoTickerSubscription(symbol);

  const exchangeId = Object.keys(CEFI_EXCHANGE_NAME).find((key) => CEFI_EXCHANGE_NAME[key] === symbol?.exchange);
  const isObligatoryAgnosticParams = symbol?.symbol && exchangeId && symbol?.market_type;
  const preTradeAnalysisAgnosticData = anbotoApi.useGetPreTradeAnalysisAgnosticDataQuery(
    {
      symbol: symbol?.symbol as string,
      exchange: exchangeId || "",
      asset_class: symbol?.market_type as string,
    },
    {
      skip: !isObligatoryAgnosticParams,
      pollingInterval: 60 * 1000,
    }
  );

  const minVolume = preTradeAnalysisAgnosticData?.data?.data?.volume_analysis?.volume || 0;
  const dayVolume = preTradeAnalysisAgnosticData?.data?.data?.volume_analysis?.timeframe || 1;
  const contractSize = preTradeAnalysisAgnosticData?.data?.data?.contract_size || 1;
  const asks = preTradeAnalysisAgnosticData?.data?.data?.order_book?.asks || [];
  const bids = preTradeAnalysisAgnosticData?.data?.data?.order_book?.bids || [];

  const oneMinVolume = Number(minVolume) / Number(dayVolume);
  const delta = getSweepPriceDelta(side === OrderSide.BUY ? asks : bids, quantity, contractSize, side);
  const sweepPrice = isNaN(delta) || !quantity ? 0 : price + delta;
  const estimatedSlippage = sweepPrice ? (Math.abs(sweepPrice - price) / price) * 100 : 0;
  const percentOf24hVolume = (+quantity / baseVolume) * 100;

  return (
    <PriceImpactContext.Provider
      value={{
        asks,
        bids,
        price,
        percentage,
        high,
        low,
        quoteVolume,
        baseVolume,
        oneMinVolume,
        sweepPrice,
        estimatedSlippage,
        percentOf24hVolume,
      }}
    >
      {children}
    </PriceImpactContext.Provider>
  );
};

export default PriceImpactProvider;
