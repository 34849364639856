import { useEffect, useState } from "react";

export const useDebouncedState = (state: string, delay: number) => {
  const [debouncedState, setDebouncedState] = useState(state);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedState(state), delay);
    return () => clearTimeout(handler);
  }, [state, delay]);
  return debouncedState;
};
