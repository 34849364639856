import React, { useState } from "react";
import { Box, IconButton, Modal, Stack, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { useAppDispatch } from "@src/store/hooks";
import { setIsCefiOrDefiModalShown } from "@src/store/actions/user.action";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export const CefiOrDefiModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [patchUser] = anbotoApi.usePatchUserMutation();
  const [open, setOpen] = useState<boolean>(true);

  const onGoToAccountPage = async (tradingType: "cefi" | "defi") => {
    dispatch(setIsCefiOrDefiModalShown(true));

    if (tradingType === "cefi") {
      navigate("/trades/cefi");
    } else {
      navigate("/trades/defi");
    }

    await patchUser({ is_cefi_or_defi_modal_shown: true });
    setOpen(false);
  };

  return (
    <Modal open={open} disableAutoFocus={true} disableEnforceFocus={true} disableEscapeKeyDown={true}>
      <StyledBox>
        <IconButton
          aria-label="close"
          onClick={() => onGoToAccountPage("cefi")}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography color="#F1F7FA" variant="h6" sx={{ textAlign: "center", fontWeight: "bold", marginBottom: 2 }}>
          Welcome to ANBOTO app
        </Typography>

        <Typography color="#F1F7FA" mb={2} fontSize={14}>
          Trade algos across various exchanges by simply linking your API Keys or trade across your preferred networks
          and aggregators.
        </Typography>

        <Stack mt={3} direction="row" justifyContent="space-between">
          <Stack width="48%">
            <AnbotoButton
              sx={{ border: "1px solid #4A5053", height: 40 }}
              variant="outlined"
              onClick={() => onGoToAccountPage("defi")}
              size="small"
            >
              Take me to DeFi
            </AnbotoButton>
          </Stack>

          <Stack width="48%">
            <AnbotoButton
              sx={{ height: 40 }}
              variant="contained"
              onClick={() => onGoToAccountPage("cefi")}
              size="small"
            >
              Take me to CeFi
            </AnbotoButton>
          </Stack>
        </Stack>
      </StyledBox>
    </Modal>
  );
};

const StyledBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 505,
  border: "0px solid",
  borderColor: "#232C2F",
  backgroundColor: "#232C2F",
  borderRadius: 8,
  padding: 44,
});
