import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { AllocationMenuItem } from "./allocation-menu-item";
import { RootState } from "@src/store/types";
import {
  AllocationAccount,
  DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE,
  setActiveDefiAllocationItem,
} from "@src/store/slices/portfolioSlice";

type AllocationMenuProps = {
  allocationItems: AllocationAccount[];
};

export const AllocationMenuDefiItems = ({ allocationItems }: AllocationMenuProps) => {
  const dispatch = useDispatch();
  const selectedAllocationItemName = useSelector((state: RootState) => state.portfolio.activeDefiAllocationItem);

  const selectAllocationItem = (name: string) => {
    dispatch(setActiveDefiAllocationItem(name));
  };

  useEffect(() => {
    const targetAllocationItem = allocationItems?.find((item) => item.subaccount === selectedAllocationItemName);

    if (!targetAllocationItem && allocationItems?.length) {
      selectAllocationItem(allocationItems[0].subaccount);
    }
  }, [allocationItems, selectedAllocationItemName]);

  return (
    <Stack width={222} display="flex" flexDirection="column">
      <Stack display="flex" flexDirection="column" gap={1}>
        {[...allocationItems]
          .sort((x) => (x.subaccount === DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE ? -1 : 1))
          .map((item) => (
            <AllocationMenuItem
              setActive={selectAllocationItem}
              isActive={selectedAllocationItemName === item.subaccount}
              key={item.subaccount}
              item={item}
            />
          ))}
      </Stack>
    </Stack>
  );
};
