import React from "react";
import { Alert, Box, Stack, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { formatTokenAmount } from "@src/utils/format";
import { getAmountWithSlippage } from "@src/pages/defi/utils";
import { OrderExecutionStrategy, OrderSide } from "@src/store/apis/anbotoApi/types";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import { useEstimatedGasCostPerSlice } from "@src/pages/defi/order-form-card-no-gas/hooks";
import { Token } from "@src/pages/defi/types";
import { useDcaStrategyQuantity } from "@src/pages/defi/hooks/use-dca-strategy-quantity";

export interface ReceiveCardProps {
  fromToken?: Token;
  toToken?: Token;
}

export const ReceiveCard = ({ fromToken, toToken }: ReceiveCardProps) => {
  const orderForm = useDefiOrderFormContext();
  const { side, slippage, isFeeTakenInInput } = orderForm.getValues();
  const { feeAbsolute } = useEstimatedGasCostPerSlice();
  const dcaStrategyQuantity = useDcaStrategyQuantity();

  if (!fromToken || !toToken) {
    return null;
  }

  const tokenSymbol = isFeeTakenInInput ? fromToken.symbol : toToken.symbol;
  const toTokenAmount =
    side === OrderSide.BUY
      ? getAmountWithSlippage(orderForm.getValues("toTokenAmount"), slippage, toToken.decimals)
      : orderForm.getValues("toTokenAmount");
  const fromTokenAmount = orderForm.getValues("fromTokenAmount");
  const strategy = orderForm.getValues("strategy");

  const isDcaStrategy = strategy === OrderExecutionStrategy.DCA;

  const payText =
    side === OrderSide.BUY
      ? `You will pay (including slippage, ${toToken.symbol})`
      : `You will pay (${fromToken.symbol})`;
  const tokenAmount = side === OrderSide.BUY ? fromTokenAmount : toTokenAmount;
  const tokenAmountMinusFees = new BigNumber(tokenAmount).minus(feeAbsolute).toFixed();

  return (
    <Box p={2} borderRadius={1} sx={{ border: (theme) => `1px solid ${theme.custom.background.secondary}` }}>
      <Stack direction="column" gap={1}>
        <Typography variant="body2" fontWeight="600" textAlign="center">
          GASLESS Transaction
        </Typography>
      </Stack>
      <Box borderRadius={1} p={2} mt={2} sx={{ backgroundColor: (theme) => theme.custom.colors.gray }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="text.secondary">{payText}</Typography>
          <Typography>
            {formatTokenAmount(
              isDcaStrategy ? dcaStrategyQuantity : side === OrderSide.BUY ? toTokenAmount : fromTokenAmount
            )}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <Stack direction="column">
            <Typography color="text.secondary">
              You will receive ({side === OrderSide.BUY ? fromToken.symbol : toToken.symbol})
            </Typography>
            <Typography color="text.secondary" fontSize={14}>
              (Incl. Settlement fees)
            </Typography>
          </Stack>
          {tokenAmountMinusFees !== "NaN" ? <Typography>~ {formatTokenAmount(tokenAmountMinusFees)}</Typography> : "-"}
        </Stack>
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <Typography flexGrow={1} color="text.secondary">
            Settlement Fees ({tokenSymbol})
          </Typography>
          {feeAbsolute !== "NaN" ? <Typography>~ {formatTokenAmount(feeAbsolute)}</Typography> : "-"}
        </Stack>
        <Alert variant="outlined" color="warning" sx={{ mt: 2 }}>
          We can only guarantee pay amount.
          <br />
          Received amount is an estimation based on current price
        </Alert>
      </Box>
    </Box>
  );
};

// We should warn them that the amount in dollar is just an approximation, and that the final amount traded in dollars can differ from this greatly, depending on the price variation of AVAX/USDT.
