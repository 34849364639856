import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { DEFI_EXCHANGE_LOGOS_PATH, DEFI_EXCHANGES_IDS } from "@src/pages/defi/constants";
import { setLiquiditySources } from "@src/store/actions/user.action";
import { RootState } from "@src/store/types";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

const LiquiditySourcesList = () => {
  const currentLiquiditySources = useAppSelector((state: RootState) => state.user.liquiditySources);
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setLiquiditySources({
        ...(currentLiquiditySources || {}),
        [event.target.name]: { ...currentLiquiditySources[event.target.name], value: event.target.checked },
      })
    );
  };

  const error = currentLiquiditySources ? Object.values(currentLiquiditySources).every((value) => !value) : undefined;

  return (
    <Box mt={1} p={2} sx={{ backgroundColor: (theme) => theme.custom.background.secondary }} borderRadius={1}>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="body2" fontWeight="600">
          Liquidity sources
        </Typography>
      </Stack>
      <Box>
        <Box>
          <FormControl required error={error} component="fieldset" sx={{ m: 3 }} variant="standard">
            {error && <FormHelperText>You must select at least one item from the checkbox list</FormHelperText>}
            <FormGroup sx={{ flexDirection: "row" }}>
              {Object.keys(currentLiquiditySources || {}).map((liqSource) => (
                <FormControlLabel
                  sx={{ width: "45%", justifyContent: "space-between" }}
                  key={liqSource}
                  control={
                    <Checkbox
                      checked={currentLiquiditySources[liqSource].value}
                      onChange={handleChange}
                      name={liqSource}
                    />
                  }
                  labelPlacement="start"
                  label={
                    <Stack alignItems="center" direction="row" gap={1}>
                      <img src={DEFI_EXCHANGE_LOGOS_PATH[DEFI_EXCHANGES_IDS[liqSource.toUpperCase()]]} height={20} />
                      {currentLiquiditySources[liqSource].name}
                    </Stack>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default LiquiditySourcesList;
