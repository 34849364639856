import React, { FC } from "react";
import { useDisconnect } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { IconButton, InputAdornment, Stack, styled } from "@mui/material";
import { ContentCopy, Logout } from "@mui/icons-material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { truncateAccount } from "@src/pages/defi/utils";
import { SelectArrow } from "@src/components/select-arrow";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { useTextCopy } from "@src/pages/portfolio/components/send-crypto/hooks";
import { useWalletName } from "@src/hooks/useWalletName";

export interface RainbowKitConnectButtonProps {
  formAccount?: string;
  error?: boolean;
}

export const AccountRainbowSelect: FC<RainbowKitConnectButtonProps> = ({ formAccount, error, ...props }) => {
  const { disconnect } = useDisconnect();
  const { onCopyClick } = useTextCopy();
  const { name } = useWalletName(formAccount || "");

  const onCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (formAccount) {
      onCopyClick(formAccount, "Address");
    }
  };

  return (
    <ConnectButton.Custom>
      {({ account, chain, openAccountModal, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready && account && chain && (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <Stack
            mb={2}
            direction="row"
            gap={1}
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <WalletConnectButton onClick={openConnectModal} variant="outlined" error={error}>
                    Connect wallet
                  </WalletConnectButton>
                );
              }
              if (chain?.unsupported) {
                return (
                  <button onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }

              return (
                <>
                  <AnbotoTextField
                    {...props}
                    label="Connected wallet"
                    labelStyle="dynamic"
                    sx={{ width: 320 }}
                    onClick={openAccountModal}
                    value={formAccount ? name || truncateAccount(formAccount) : "Connect Wallet"}
                    inputProps={{ style: { cursor: "pointer" } }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SelectArrow />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={onCopy}>
                            <ContentCopy sx={{ color: (theme) => theme.palette.text.secondary, width: 20 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {connected && (
                    <SmallIconButton onClick={() => disconnect()}>
                      <Logout />
                    </SmallIconButton>
                  )}
                </>
              );
            })()}
            {connected && (
              <SelectChainIconButton variant="outlined" onClick={openChainModal}>
                {chain?.iconUrl && (
                  <img alt={chain?.name ?? "Chain icon"} src={chain?.iconUrl} style={{ width: 20, height: 20 }} />
                )}
              </SelectChainIconButton>
            )}
          </Stack>
        );
      }}
    </ConnectButton.Custom>
  );
};

const WalletConnectButton = styled(AnbotoButton)<{ error?: boolean }>(({ error, theme }) => ({
  width: "100%",
  height: 40,
  background: error
    ? `linear-gradient(0deg, rgba(3, 19, 25, 0.24), rgba(3, 19, 25, 0.24)), ${theme.palette.error.main}`
    : `linear-gradient(114.93deg, ${theme.palette.primary.main} 0%, #56C3F2 100%)`,
}));

const SelectChainIconButton = styled(AnbotoButton)(({ theme }) => ({
  minWidth: 40,
  width: 40,
  height: 40,
  background: theme.custom.background.inputAdornment.light,
  border: `1px solid ${theme.custom.border.default}`,
  borderRadius: 4,
  padding: 0,
  float: "right",
}));

const SmallIconButton = styled(AnbotoButton)<{ iconWidth?: number; width?: number }>(({ iconWidth, theme, width }) => ({
  minWidth: width ? width : 40,
  width: width ? width : 40,
  height: width ? width : 40,
  padding: 0,
  background: theme.custom.background.secondary,
  border: `1px solid ${theme.custom.border.default}`,

  "& svg": {
    width: iconWidth ? iconWidth : 16,
  },
}));
