import React from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ChangePassword } from "./pages/settings/change-password";
import { UserPreferences } from "./pages/settings/user-preferences";
import { TwoFa } from "./pages/settings/two-fa";
import { AccountSettings } from "./pages/settings/account-settings";
import { ProfileSettings } from "./pages/settings/profile-settings";
import { FeeManager } from "./pages/settings/fee-manager";
import { LinkedSettings } from "./pages/settings/linked-settings/linked-settings";
import { useAppSelector } from "./store/hooks";
import { ReferralSignUpForm } from "./pages/auth/registration/components/referral-sign-up-form";
import { FeesDashboardPage } from "./pages/fees-dashboard";
import { AuthLayout } from "./pages/auth/layout/auth-layout";
import { ApiSettings } from "./pages/settings/api-settings";
import { HideInTestnet } from "./components/hide-in-testnet/hide-in-testnet";
import { AdminPage } from "./features/admin/admin-page";
import { OtcPage } from "./pages/otc";
import { ReferralProgram } from "@src/pages/referral-program";
import { Registration } from "@src/pages/auth/registration/registration";
import { Linked } from "@src/pages/settings/linked-settings";
import { IsAuthenticated } from "@src/components/is-authenticated";
import { Login } from "@src/pages/auth/login/login";
import { ForgotPassword } from "@src/pages/auth/reset-password/forgot-password";
import { ResetPassword } from "@src/pages/auth/reset-password/reset-password";
import { MainLayout } from "@src/components/layout/main-layout/main-layout";
import { CefiOrderPage } from "@src/pages/cefi";
import SettingsPage from "@src/pages/settings";
import { DefiOrderPage } from "@src/pages/defi";
import {
  AllocationsCefiFunds,
  AllocationsDefiFunds,
  PortfolioCefiExchanges,
  PortfolioDefiExchanges,
  PortfolioPage,
} from "@src/pages/portfolio";
import FundingRates from "@src/pages/analytics/funding-rates/funding-rates";
import TwoFactorAuth from "@src/pages/auth/registration/two-factor-auth";
import { AnalyticsPage } from "@src/pages/analytics/analytics-page";
import TitleWrapper from "@src/components/title-wrapper/title-wrapper";
import MetricsPage from "@src/pages/metrics/metrics-page";
import OrdersHistory from "@src/pages/settings/transaction-history/orders-history";
import OrderPreferencesForm from "@src/pages/settings/order-preferences/order-preferences-form";
import { TradingCompetitions } from "@src/pages/competitions/trading-competitions";
import TradingStats from "@src/pages/metrics/pages/metrics/trading-stats";
import PostTradeAnalysisMetrics from "@src/pages/metrics/pages/post-trade-analysis/post-trade-analysis-metrics";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const Router = () => {
  const fee_manager_enabled = useAppSelector((state) => state.user.fee_manager_enabled);
  const is_fees_dashboard_visible = useAppSelector((state) => state.user.is_fees_dashboard_visible);
  const isStaff = useAppSelector((state) => state.user.is_staff);

  return (
    <BrowserRouter>
      <TitleWrapper>
        <SentryRoutes>
          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            <Route path="/auth/register_via_invite_link" element={<Registration />} />
            <Route path="/referral-code-sign-up" element={<ReferralSignUpForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/two-factor-auth" element={<TwoFactorAuth />} />
          </Route>
          <Route element={<IsAuthenticated value={true} />}>
            <Route element={<MainLayout />}>
              <Route path="/trades" element={<Outlet />}>
                <Route path="cefi" element={<CefiOrderPage />} />
                <Route path="otc" element={<OtcPage />} />
                <Route
                  path="defi"
                  element={
                    <HideInTestnet>
                      <DefiOrderPage />
                    </HideInTestnet>
                  }
                />
              </Route>
              <Route path="/analytics" element={<AnalyticsPage />}>
                <Route path="funding-rates" element={<FundingRates />} />
                <Route index element={<Navigate to="/analytics/funding-rates" replace />} />
              </Route>
              <Route
                path="/portfolio"
                element={
                  <HideInTestnet>
                    <PortfolioPage />
                  </HideInTestnet>
                }
              >
                <Route path="cefi" element={<PortfolioCefiExchanges key="/portfolio/cefi" />}>
                  <Route path="funds" element={<AllocationsCefiFunds key="/portfolio/cefi/funds" />} />
                  <Route index element={<Navigate to="/portfolio/cefi/funds" replace />} />
                </Route>
                <Route path="defi" element={<PortfolioDefiExchanges key="/portfolio/defi" />}>
                  <Route path="funds" element={<AllocationsDefiFunds key="/portfolio/defi/funds" />} />
                  <Route index element={<Navigate to="/portfolio/defi/funds" replace />} />
                </Route>
                <Route index element={<Navigate to="/portfolio/cefi" replace />} />
              </Route>
              <Route
                path="/affiliate-program"
                element={
                  <HideInTestnet>
                    <ReferralProgram />
                  </HideInTestnet>
                }
              />
              <Route
                path="/trading-competitions/*"
                element={
                  <HideInTestnet>
                    <TradingCompetitions />
                  </HideInTestnet>
                }
              >
                <Route path=":competitionId" />
              </Route>
              <Route path="/metrics/*" element={<MetricsPage />}>
                <Route path="post-trade-analysis" element={<PostTradeAnalysisMetrics />} />
                <Route path="trading-stats" element={<TradingStats />} />
                <Route index element={<Navigate to="/trading-stats" replace />} />
              </Route>
              <Route path="/settings/*" element={<SettingsPage />}>
                <Route path="api" element={<ApiSettings />} />
                <Route path="profile" element={<ProfileSettings />} />
                <Route path="account" element={<AccountSettings />} />
                <Route path="password" element={<ChangePassword />} />
                <Route path="user-preferences" element={<UserPreferences />} />
                <Route path="two-fa" element={<TwoFa />} />
                <Route
                  path="linked/*"
                  element={
                    <HideInTestnet>
                      <Linked />
                    </HideInTestnet>
                  }
                >
                  <Route path=":type" element={<LinkedSettings />} />
                </Route>

                <Route path="transactions-history" element={<OrdersHistory />} />
                <Route path="order-preferences" element={<OrderPreferencesForm />} />
                {fee_manager_enabled && (
                  <Route
                    path="fees"
                    element={
                      <HideInTestnet>
                        <FeeManager />
                      </HideInTestnet>
                    }
                  />
                )}
                <Route path="*" element={<Navigate to="account" replace />} />
              </Route>
              {is_fees_dashboard_visible && <Route path="fees-dashboard" element={<FeesDashboardPage />} />}
              {isStaff && <Route path="admin-dashboard" element={<AdminPage />} />}
              <Route path="*" element={<Navigate to="/trades/cefi" replace />} />
            </Route>
          </Route>
        </SentryRoutes>
      </TitleWrapper>
    </BrowserRouter>
  );
};
