import React from "react";
import WidgetsGridLayout from "@src/features/widgets-layout";
import { WidgetsGridView } from "@src/features/widgets-layout/types";
import { useAppSelector } from "@src/store/hooks";
import { SetupExchangeKeysInfoModal } from "@src/components/setup-exchange-keys-info-modal/setup-exchange-keys-info-modal";

export const OtcPage = () => {
  const isOtcEnabled = useAppSelector((state) => state.user.is_otc_visible);
  return isOtcEnabled ? (
    <>
      <SetupExchangeKeysInfoModal />
      <WidgetsGridLayout view={WidgetsGridView.OTC} />
    </>
  ) : null;
};
