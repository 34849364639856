import React from "react";
import { Typography, Stack, Tooltip, useTheme } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AdvancedSettingsError } from "@src/components";

export const AdvancedSettingsButton = ({
  onClick,
  onClickPopup,
  draggable,
  errors,
}: {
  onClick?: () => void;
  onClickPopup: () => void;
  draggable: boolean;
  opened: boolean;
  errors: string;
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      sx={{
        borderRadius: 0.5,
        boxSizing: "border-box",
        border: `1px dashed ${draggable ? theme.palette.divider : "transparent"}`,
      }}
    >
      <AnbotoButton
        variant="dim"
        size="small"
        sx={{
          height: 30,
          paddingLeft: 1.75,
          position: "relative",
          flex: 1,
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          background: draggable ? "none" : undefined,
          borderRadius: !onClick && !!onClickPopup ? 0.5 : undefined,
        }}
        onClick={onClick || onClickPopup}
        disabled={draggable}
      >
        <Typography sx={{ flexGrow: 1 }} fontWeight="bold" variant="body2" textAlign="left" noWrap>
          Advanced Settings
        </Typography>
        {!!errors && (
          <Tooltip title={errors}>
            <AdvancedSettingsError />
          </Tooltip>
        )}
        {!onClick && !!onClickPopup && (
          <MoreVertIcon
            sx={{
              color: (theme) => theme.palette.text.disabled,
              fontSize: 15,
              transform: "translateX(10px)",
            }}
          />
        )}
      </AnbotoButton>

      {!draggable && onClick && (
        <AnbotoButton
          variant="dim"
          size="small"
          sx={{
            height: 32,
            paddingRight: 1,
            paddingLeft: 1,
            position: "relative",
            minWidth: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          onClick={onClickPopup}
        >
          <OpenInNewIcon
            sx={{
              color: (theme) => theme.palette.text.disabled,
              fontSize: 15,
            }}
          />
        </AnbotoButton>
      )}
    </Stack>
  );
};
