import React, { useState } from "react";
import { DialogContent, DialogProps, Grid, IconButton, Stack, styled } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import { TabContext } from "@mui/lab";
import dayjs from "dayjs";
import { DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import { OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";
import { CardDialog } from "@src/components/CardDialog";
import {
  StyledTabList,
  StyledTabListTab,
  StyledTabPanel,
  TriggerConditionField,
  TriggerPriceField,
} from "@src/components";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import { ExpirationField, SlippageField } from "@src/pages/defi/order-form-card-no-gas/fields";
import { useTabs } from "@src/hooks/useTabs";
import { isMultiSliceOrderExecutionStrategy } from "@src/store/apis/anbotoApi/utils";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";
import {
  DEFAULT_DEFI_MARKET_SLIPPAGE,
  PRICE_PROTECTION_INFO,
  PRICE_PROTECTION_OPTIONS,
} from "@src/pages/defi/constants";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { withFlatStyle } from "@src/components/with-flat-style";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type AdvancedOptionsDialogProps = DialogProps;

const TAB_IDS = {
  slippage: "1",
  priceProtection: "2",
  expiration: "3",
  clipSizeValue: "4",
  triggerPrice: "5",
  startDate: "6",
};

export const AdvancedOptionsDialog = (props: AdvancedOptionsDialogProps) => {
  const orderForm = useDefiOrderFormContext();
  const strategy = orderForm.getValues("strategy");
  const currentPriceProtectionOption = orderForm.watch("priceProtection");
  const startTime = orderForm.watch("startTime");

  const tabs = useTabs(TAB_IDS.slippage);

  const isDcaStrategy = strategy === OrderExecutionStrategy.DCA;
  const isMultiSliceStrategy = isMultiSliceOrderExecutionStrategy(strategy);

  const handleClose = () => {
    props.onClose!({}, "backdropClick");

    tabs.setValue(TAB_IDS.slippage);
  };

  const handleTabChange = (e, x) => {
    tabs.setValue(x);
  };

  const isTwapOrIcebergStrategy =
    strategy === OrderExecutionStrategy.TWAP || strategy === OrderExecutionStrategy.ICEBERG;

  const handlePriceProtectionChange = (value) => {
    if (value === PRICE_PROTECTION_OPTIONS[0]) {
      orderForm.setValue("slippage", DEFAULT_DEFI_MARKET_SLIPPAGE);
    } else {
      orderForm.setValue("slippage", "");
    }

    orderForm.setValue("priceProtection", value);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const onStartTimeChange = (time) => {
    if (time) {
      orderForm.setValue("startTime", time.toString());
    }
  };

  const onDateTimeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <CardDialog
      {...props}
      isAdvancedSettingsCard={true}
      sx={{ height: 200, bottom: 0, top: "auto" }}
      withOverlay
      onOverlayClick={handleClose}
    >
      <DialogContent
        sx={{
          py: 0.5,
          px: 1.5,
          display: "flex",
          flexDirection: "column",
          background: "#121a1c",
          borderTopLeftRadius: (theme) => theme.shape.borderRadius,
          borderTopRightRadius: (theme) => theme.shape.borderRadius,
        }}
      >
        <IconButton onClick={handleClose} size="small" sx={{ position: "absolute", right: 5, top: 9 }}>
          <Close sx={{ fontSize: 18, color: (theme) => theme.palette.text.disabled }} />
        </IconButton>
        <TabContext value={tabs.value}>
          <StyledTabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto" sx={{ mb: 0.5 }}>
            <StyledTabListTab label="Slippage" value={TAB_IDS.slippage} error={!!orderForm.formState.errors.slippage} />

            {(isDcaStrategy || isTwapOrIcebergStrategy) && (
              <StyledTabListTab
                label="Price protection"
                value={TAB_IDS.priceProtection}
                error={!!orderForm.formState.errors.priceProtection}
              />
            )}
            <StyledTabListTab
              label="Expiration"
              value={TAB_IDS.expiration}
              error={!!orderForm.formState.errors.expiration}
            />
            {/* MUI: The Tabs component doesn't accept a Fragment as a child. */}
            {isTwapOrIcebergStrategy && (
              <StyledTabListTab
                label="Trigger Price"
                value={TAB_IDS.triggerPrice}
                error={!!orderForm.formState.errors.triggerPrice}
              />
            )}

            {isDcaStrategy && (
              <StyledTabListTab
                label="Start date"
                value={TAB_IDS.startDate}
                error={!!orderForm.formState.errors.startTime}
              />
            )}
          </StyledTabList>
          <StyledTabPanel value={TAB_IDS.expiration} sx={{ px: 0.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="expiration"
                  control={orderForm.control}
                  render={({ field, formState }) => (
                    <StyledExpirationField
                      error={!!formState.errors.expiration}
                      helperText={formState.errors.expiration?.message}
                      {...field}
                      labelStyle="dynamic"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </StyledTabPanel>

          <StyledTabPanel value={TAB_IDS.slippage} sx={{ px: 0.5 }}>
            <Grid container>
              <Grid item xs={6}>
                {!isMultiSliceStrategy && !isDcaStrategy && (
                  <Grid item xs={12}>
                    <Controller
                      name="slippage"
                      control={orderForm.control}
                      render={({ field, formState }) => (
                        <StyledSlippageField
                          {...field}
                          label="Slippage"
                          error={!!formState.errors.slippage}
                          helperText={formState.errors.slippage?.message}
                          labelStyle="dynamic"
                        />
                      )}
                    />
                  </Grid>
                )}

                {(isMultiSliceStrategy || isDcaStrategy) && (
                  <Grid item xs={12}>
                    <Controller
                      name="childSlippage"
                      control={orderForm.control}
                      render={({ field, formState }) => (
                        <StyledSlippageField
                          {...field}
                          label="Swap slippage"
                          error={!!formState.errors.childSlippage}
                          helperText={formState.errors.childSlippage?.message}
                          labelStyle="dynamic"
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </StyledTabPanel>

          {isTwapOrIcebergStrategy && (
            <>
              <StyledTabPanel value={TAB_IDS.triggerPrice} sx={{ px: 0.5 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      control={orderForm.control}
                      name="triggerCondition"
                      render={({ field }) => (
                        <StyledTriggerConditionField
                          {...field}
                          select
                          labelStyle="dynamic"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      control={orderForm.control}
                      name="triggerPrice"
                      render={({ field, formState }) => {
                        return (
                          <StyledTriggerPriceField
                            {...field}
                            error={!!formState.errors.triggerPrice}
                            helperText={formState.errors.triggerPrice?.message}
                            labelStyle="dynamic"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </StyledTabPanel>
            </>
          )}

          <StyledTabPanel value={TAB_IDS.priceProtection} sx={{ px: 0.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <AnbotoButtonGroup<string>
                  value={currentPriceProtectionOption}
                  view="compact"
                  buttonStyles={{
                    height: 32,
                    fontSize: 11,
                    minWidth: 0,
                    padding: "3px 3px",
                    fontWeight: 300,
                  }}
                  selectedButtonStyles={{ fontWeight: 600, fontSize: 11 }}
                  sx={{
                    display: "flex",
                  }}
                  options={PRICE_PROTECTION_OPTIONS.map((value) => ({
                    label: value,
                    value,
                    ...(value === PRICE_PROTECTION_OPTIONS[1] ? { title: PRICE_PROTECTION_INFO } : {}),
                  }))}
                  onChange={(value) => handlePriceProtectionChange(value)}
                />
              </Grid>
              <Grid item xs={5} minHeight={56}>
                {currentPriceProtectionOption === PRICE_PROTECTION_OPTIONS[1] && (
                  <Controller
                    name="slippage"
                    control={orderForm.control}
                    render={({ field, formState }) => (
                      <StyledSlippageField
                        {...field}
                        label="Price protection"
                        error={!!formState.errors.slippage}
                        helperText={formState.errors.slippage?.message}
                        labelStyle="dynamic"
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </StyledTabPanel>

          <StyledTabPanel value={TAB_IDS.startDate} sx={{ p: 2.5, py: 4 }}>
            <Grid container spacing={2}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" gap={1}>
                  <StyledDatePicker
                    label="Start day"
                    sx={{
                      "& .MuiFormLabel-root": {
                        position: "absolute",
                        top: -6,
                        left: 10,
                        fontSize: 11,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    disablePast
                    inputFormat="MM/DD/YYYY"
                    value={startTime}
                    onChange={(time) => onStartTimeChange(time)}
                    PopperProps={{
                      placement: "bottom-end",
                      anchorEl: anchorEl,
                    }}
                    renderInput={(params) => (
                      <>
                        <StyledAnbotoTextField onClick={onDateTimeClick} size="small" {...params} />
                      </>
                    )}
                  />
                  <StyledTimePicker
                    value={startTime}
                    label="Start time"
                    onChange={(time) => onStartTimeChange(time)}
                    sx={{
                      "& .MuiFormLabel-root": { position: "absolute", top: -6, left: 10, fontSize: 11 },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    PopperProps={{
                      placement: "bottom-end",
                      anchorEl: anchorEl,
                    }}
                    renderInput={(params) => (
                      <>
                        <StyledAnbotoTextField label="Start time" onClick={onDateTimeClick} size="small" {...params} />
                      </>
                    )}
                  />

                  <AnbotoButton
                    sx={{ height: 32, fontSize: 14, fontWeight: 400, color: "#2C72E3", px: 0.5, minWidth: 24 }}
                    onClick={() => onStartTimeChange(dayjs())}
                  >
                    Now
                  </AnbotoButton>
                </Stack>
              </Stack>
            </Grid>
          </StyledTabPanel>
        </TabContext>
      </DialogContent>
    </CardDialog>
  );
};

const StyledDatePicker = withFlatStyle(DesktopDatePicker);
const StyledTimePicker = withFlatStyle(TimePicker);
const StyledExpirationField = withFlatStyle(ExpirationField);
const StyledTriggerConditionField = withFlatStyle(TriggerConditionField);
const StyledTriggerPriceField = withFlatStyle(TriggerPriceField);
const StyledSlippageField = withFlatStyle(SlippageField);
export const StyledAnbotoTextField = styled(AnbotoTextField)(() => ({
  "& .MuiIconButton-root": { color: "#8A9296", padding: 0, paddingRight: 6 },
  "& .MuiIconButton-root svg": { width: 18 },
  "& .MuiInputBase-input": { padding: 0, height: 32, paddingLeft: 8 },
}));
