import BigNumber from "bignumber.js";
import { Duration } from "luxon";
import { CefiExchangeId, OrderPreferences } from "@src/store/apis/anbotoApi/types";
import { ExpirationTime, expirationTimeOptions } from "@src/pages/cefi/order-form/fields/ExpirationTimeSelect";
import { BITGET_LIMIT } from "@src/pages/cefi/constants";
import { roundAmount } from "@src/pages/portfolio/utils/helpers";

export const modifyWithMaxAmountPrecision = (amount: string, maxPrecision: number | null | undefined) => {
  if (maxPrecision !== null && maxPrecision !== undefined) {
    const result = new BigNumber(amount).dp(maxPrecision, BigNumber.ROUND_DOWN).toString();
    return result === "0" ? roundAmount(amount, 2).toString() : result;
  }

  return amount;
};

export const hexToRgbaWithOpacity = (hex: string, opacity: number): string => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const formatDuration = (durationInSeconds: number): string => {
  if (!durationInSeconds || durationInSeconds < 0) return "-";

  const duration = Duration.fromMillis(durationInSeconds * 1000)
    .shiftTo("months", "days", "hours", "minutes", "seconds")
    .toObject();

  const formattedDuration: string[] = [];

  const { months, days, hours, minutes } = duration;

  if (months && months > 0) {
    formattedDuration.push(`${months} month${months > 1 ? "s" : ""}`);
  }
  if (days && days > 0) {
    formattedDuration.push(`${days} day${days > 1 ? "s" : ""}`);
  }
  if (hours && hours > 0) {
    formattedDuration.push(`${hours} hour${hours > 1 ? "s" : ""}`);
  }
  if (minutes && minutes > 0) {
    formattedDuration.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
  }

  return formattedDuration.slice(0, 2).join(" ");
};

export const getOrderFormUserDefaultValues = (orderPreferences: OrderPreferences) => ({
  ...(orderPreferences?.cefi_trading_style !== undefined ? { tradingStyle: orderPreferences.cefi_trading_style } : {}),
  ...(orderPreferences?.cefi_would_price !== undefined ? { wouldStyle: orderPreferences.cefi_would_price } : {}),
  ...(orderPreferences?.cefi_maker_placement_type !== undefined
    ? { placementMode: orderPreferences.cefi_maker_placement_type }
    : {}),
  ...(orderPreferences?.cefi_custom_placement_level !== undefined
    ? { placement: orderPreferences.cefi_custom_placement_level }
    : {}),
  ...(orderPreferences?.cefi_custom_cancel_level !== undefined
    ? { placementCancel: orderPreferences.cefi_custom_cancel_level }
    : {}),
  ...(orderPreferences?.cefi_clip_size_type !== undefined
    ? { clipSizeType: orderPreferences.cefi_clip_size_type }
    : {}),
  ...(orderPreferences?.cefi_expiration !== undefined
    ? { expirationTime: fromOrderPreferenceExpirationToFormExpiration(orderPreferences.cefi_expiration) }
    : {}),
  ...(orderPreferences?.cefi_strategy !== undefined ? { strategy: orderPreferences.cefi_strategy } : {}),
  ...(orderPreferences?.cefi_duration_type !== undefined
    ? { tradingDurationUnit: orderPreferences.cefi_duration_type }
    : {}),
  ...(orderPreferences?.cefi_duration_value !== undefined
    ? { tradingDuration: orderPreferences.cefi_duration_value || "" }
    : {}),
});

const fromOrderPreferenceExpirationToFormExpiration = (expiration): ExpirationTime => {
  switch (expiration) {
    case 7 * 24 * 3600:
      return expirationTimeOptions[0];
    case 30 * 24 * 3600:
      return expirationTimeOptions[1];
    case 6 * 30 * 24 * 3600:
      return expirationTimeOptions[2];
    default:
      return expirationTimeOptions[0];
  }
};

export const getLimit = (exchange, limit) => {
  return exchange?.toLowerCase() === CefiExchangeId.BITGET && limit > BITGET_LIMIT ? BITGET_LIMIT : limit;
};
