import React, { useState } from "react";
import { BoxProps, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import _omit from "lodash/omit";
import { LoadingTgModal } from "./loading-tg-modal";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useAppSelector } from "@src/store/hooks";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";

export const SupportButton = React.forwardRef<HTMLDivElement>(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }: React.PropsWithChildren<BoxProps>, ref) => {
    const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);
    const snackbar = useSnackbar();
    const team_uuid = useAppSelector((state) => state.user.team_uuid);
    const telegram_id = useAppSelector((state) => state.user.telegram_id);
    const [createTelegramGroup] = anbotoApi.useCreateTelegramGroupMutation();
    const [getUserDetails] = anbotoApi.useLazyGetUserDetailsQuery();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const waitForTelegramGroupCreated = () => {
      return new Promise<any>((resolve) => {
        const intervalId = setInterval(async () => {
          const { team_telegram_support_link } = await getUserDetails(null).unwrap();
          if (team_telegram_support_link) {
            resolve(team_telegram_support_link);
            clearInterval(intervalId);
          }
        }, 5000);
      });
    };

    const onCreateTelegramGroup = async () => {
      if (!telegram_id) {
        return snackbar.enqueueSnackbar("Please setup your telegram Id first", {
          variant: "error",
          buttonAction: () => {
            navigate("settings/profile");
            snackbar.closeSnackbar();
          },
          actionName: "Set a Telegram ID",
        });
      }
      try {
        setLoading(true);
        await createTelegramGroup({ account_uuid: team_uuid }).unwrap();
        await waitForTelegramGroupCreated();
        setLoading(false);
        const { team_telegram_support_link } = await getUserDetails(null).unwrap();
        if (typeof team_telegram_support_link === "string") {
          snackbar.enqueueSnackbar("Your Telegram Support group is ready", {
            variant: "success",
            buttonAction: () => {
              window.open(team_telegram_support_link);
              snackbar.closeSnackbar();
            },
            actionName: "Open",
          });
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        snackbar.enqueueSnackbar(parseAnbotoRequestError(error?.data?.error), {
          variant: "error",
        });
      }
    };

    return (
      <div ref={ref}>
        {loading && <LoadingTgModal />}
        {getUserDetailsQuery.data?.team_telegram_support_link && telegram_id ? (
          <a
            href={getUserDetailsQuery.data?.team_telegram_support_link}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Box {..._omit(props, "ownerState")}>{children}</Box>
          </a>
        ) : (
          <Box {..._omit(props, "ownerState")} onClick={() => onCreateTelegramGroup()}>
            {children}
          </Box>
        )}
      </div>
    );
  }
);

SupportButton.displayName = "SupportButton";
