import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { Stack, useTheme } from "@mui/material";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";
import AnbotoInfoIcon from "@src/components/anboto-info-icon/anboto-info-icon";

const SHORTCUTS = ["1", "5", "10"];

type SlippageFieldProps = ControllerRenderProps &
  AnbotoTextFieldProps & {
    hideButtonGroup?: boolean;
    infoIconTitle?: string;
  };

export const SlippageField = forwardRef(({ infoIconTitle, hideButtonGroup, ...props }: SlippageFieldProps, ref) => {
  const theme = useTheme();
  return (
    <>
      <AnbotoTextField
        {...props}
        type="number"
        inputRef={ref}
        size="small"
        label={props.label || "Slippage (%)"}
        inputProps={{
          max: 100,
          min: 0,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          sx: { paddingRight: "3px" },
          endAdornment: (
            <>
              {infoIconTitle && (
                <Stack paddingRight={1}>
                  <AnbotoInfoIcon isFilled iconSize={14} title={infoIconTitle} />
                </Stack>
              )}
              {!hideButtonGroup && (
                <AnbotoButtonGroup<string>
                  value={props?.value?.toString()}
                  view="compact"
                  sx={{ display: "flex", "& .MuiButton-root": { minWidth: 0, padding: "3px 8px" } }}
                  options={SHORTCUTS.map((value: string) => ({ label: `${value}%`, value }))}
                  onChange={props.onChange}
                  selectedButtonStyles={{ borderRadius: 2, border: `1px solid ${theme.custom.colors.primary}` }}
                  buttonStyles={{ background: "transparent", border: `1px solid #232C2E`, borderRadius: 2 }}
                  overlayStyles={{ background: "#232C2E", borderRadius: 2 }}
                />
              )}
            </>
          ),
        }}
        required
        fullWidth
        FormHelperTextProps={{ sx: { position: "absolute", top: "100%", mt: 0, ml: 0 } }}
      />
    </>
  );
});

SlippageField.displayName = "SlippageField";
