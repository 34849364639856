import { ChainId } from "@src/pages/defi/types";

export type ChainNativeTokens = {
  chainId: ChainId;
  coinName: string;
  decimals: number;
  logoURI: string;
  name: string;
  symbol: string;
  tokenId: string;
  wrappedTokenAddress: string;
  wrappedTokenName: string;
  wrappedTokenSymbol: string;
};

export const CHAIN_NATIVE_TOKENS: ChainNativeTokens[] = [
  {
    chainId: ChainId.ETHEREUM,
    coinName: "ethereum",
    decimals: 18,
    logoURI: "https://static.debank.com/image/chain/logo_url/eth/42ba589cd077e7bdd97db6480b0ff61d.png",
    name: "Ethereum",
    symbol: "ETH",
    tokenId: "eth",
    wrappedTokenAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    wrappedTokenName: "WrappedEther",
    wrappedTokenSymbol: "WETH",
  },
  {
    chainId: ChainId.BSC,
    coinName: "binancecoin",
    decimals: 18,
    logoURI: "https://static.debank.com/image/chain/logo_url/bsc/7c87af7b52853145f6aa790d893763f1.png",
    name: "Binance coin",
    symbol: "BNB",
    tokenId: "bnb",
    wrappedTokenAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    wrappedTokenName: "WBNB Token",
    wrappedTokenSymbol: "WBNB",
  },
  {
    chainId: ChainId.POLYGON,
    coinName: "matic-network",
    decimals: 18,
    logoURI: "https://static.debank.com/image/chain/logo_url/matic/d3d807aff1a13e9ba51a14ff153d6807.png",
    name: "Matic token",
    symbol: "MATIC",
    tokenId: "matic",
    wrappedTokenAddress: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    wrappedTokenName: "Wrapped Matic",
    wrappedTokenSymbol: "WMATIC",
  },
  {
    chainId: ChainId.AVALANCHE,
    coinName: "avalanche-2",
    decimals: 18,
    logoURI: "https://static.debank.com/image/chain/logo_url/avax/4d1649e8a0c7dec9de3491b81807d402.png",
    name: "Avalanche",
    symbol: "AVAX",
    tokenId: "avax",
    wrappedTokenAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    wrappedTokenName: "Wrapped AVAX",
    wrappedTokenSymbol: "WAVAX",
  },
  /* Arbitrum and OPTIMISM use ETH as native
  {
    chainId: ChainId.ARBITRUM,
    coinName: "",
    decimals: 18,
    logoURI: "https://static.debank.com/image/chain/logo_url/arb/f6d1b236259654d531a1459b2bccaf64.png",
    name: "Arbitrum",
    symbol: "ARB",
    tokenId: "arb",
    wrappedTokenAddress: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    wrappedTokenName: "Wrapped Ether",
    wrappedTokenSymbol: "WETH",
  },
  {
    chainId: ChainId.OPTIMISM,
    coinName: "optimism",
    decimals: 18,
    logoURI: "https://static.debank.com/image/chain/logo_url/op/01ae734fe781c9c2ae6a4cc7e9244056.png",
    name: "Optimism",
    symbol: "OP",
    tokenId: "op",
    wrappedTokenAddress: "0x4200000000000000000000000000000000000006",
    wrappedTokenName: "Wrapped Ether",
    wrappedTokenSymbol: "WETH",
  },*/
];
