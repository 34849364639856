import React from "react";
import { Badge, Stack, Tooltip, Typography } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { CEFI_ORDER_STATUS_LABEL } from "@src/pages/cefi/constants";
import { OrderStatuses } from "@src/store/apis/anbotoApi/types";

export type OrderStatusProps = {
  defaultStatusColor?: string;
  status: OrderStatuses;
  info?: string;
  fontSize?: number;
  lineHeight?: string;
};

const STATUS_COLOR = {
  [OrderStatuses.WAITING]: "#56C3F2",
  [OrderStatuses.RUNNING]: "#179B83",
  [OrderStatuses.ERROR]: "#C3462B",
  [OrderStatuses.PAUSED]: "#2C72E3",
};

export const OrderStatus = ({ status, info, fontSize, defaultStatusColor, lineHeight }: OrderStatusProps) => {
  const labelText = CEFI_ORDER_STATUS_LABEL[status];

  const croppedStatus = typeof status === "string" ? (status as string).slice(0, 10) + "..." : "-";

  const label = (
    <Typography
      noWrap
      variant="body2"
      color={STATUS_COLOR[status] || defaultStatusColor || "text.secondary"}
      sx={{ fontSize, lineHeight }}
    >
      {labelText || croppedStatus}
    </Typography>
  );

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {labelText ? label : <Tooltip title={status}>{label}</Tooltip>}
      {status === OrderStatuses.ERROR && info && (
        <Tooltip title={<Typography variant="caption">{info}</Typography>} arrow placement="top-end">
          <ErrorOutlineOutlinedIcon sx={{ color: "#4A5053", fontSize: "20px" }} />
        </Tooltip>
      )}
      {status === OrderStatuses.RUNNING && <Badge color="success" variant="dot" />}
    </Stack>
  );
};
