import React from "react";
import { Skeleton, Stack, Typography } from "@mui/material";
import BigNumber from "bignumber.js";
import { formatUsd } from "@src/utils/format";

type TokenPriceProps = {
  isFetching: boolean;
  tokenSymbol?: string;
  tokenEthRate?: string;
  nativeCoinPrice?: number;
};

export const TokenPrice = ({ isFetching, tokenSymbol, nativeCoinPrice, tokenEthRate }: TokenPriceProps) => {
  const usdPrice =
    !!tokenEthRate && !!nativeCoinPrice
      ? new BigNumber(nativeCoinPrice).div(new BigNumber(tokenEthRate)).toNumber()
      : 0;

  return (
    <Stack direction="row" gap={1}>
      <Typography color="text.secondary" variant="caption">
        Current ({tokenSymbol || ""})
      </Typography>
      <Typography variant="caption">
        {isFetching ? <Skeleton width={100} /> : usdPrice ? <span>~ {formatUsd(usdPrice)}</span> : <span>-</span>}
      </Typography>
    </Stack>
  );
};
