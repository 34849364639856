import React from "react";
import { Stack } from "@mui/material";
import { FormLink } from "./form-link";

export const BackButton = ({ to, title, tabIndex }: { to: string; title: string; tabIndex?: number }) => {
  return (
    <Stack direction="row" gap={0.5} justifyContent="center" mt={2}>
      <FormLink to={to} tabIndex={tabIndex} dim>
        {title}
      </FormLink>
    </Stack>
  );
};
