import React from "react";
import { Grid, Typography } from "@mui/material";
import { useDcaStrategyQuantity } from "@src/pages/defi/hooks/use-dca-strategy-quantity";
import { useDefiOrderFormContext, useTokenInfo } from "@src/pages/defi/hooks";
import { EVMBasedAddress } from "@src/pages/defi/types";
import { getDcaOrderDate } from "@src/pages/defi/order-form-card-no-gas/components/dca-strategy-form/utils";

export const DcaStrategyProperties = () => {
  const orderForm = useDefiOrderFormContext();
  const dcaQuantity = useDcaStrategyQuantity();

  const {
    chainId,
    clipSizeValue,
    tradingDuration,
    tradingDurationUnit,
    fromTokenAddress,
    frequency,
    frequencyUnit,
    startTime,
  } = orderForm.getValues();

  const fromToken = useTokenInfo({ chainId, address: fromTokenAddress as EVMBasedAddress });

  if (!fromToken) {
    return null;
  }

  const checkedStartDate = getDcaOrderDate(startTime);

  return (
    <>
      <Grid item xs={6}>
        <Typography variant="caption" color="text.secondary">
          Invest ({fromToken.symbol})
        </Typography>
        <Typography>{dcaQuantity}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption" color="text.secondary">
          Pay ({fromToken.symbol})
        </Typography>
        <Typography>{clipSizeValue}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption" color="text.secondary">
          Every
        </Typography>
        <Typography>
          {frequency} {frequencyUnit}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption" color="text.secondary">
          Duration
        </Typography>
        <Typography>
          {tradingDuration} {tradingDurationUnit}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption" color="text.secondary">
          Start date
        </Typography>
        <Typography>{checkedStartDate.isNow ? "Now" : startTime}</Typography>
      </Grid>
    </>
  );
};
