import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { TriggerCondition } from "@src/store/apis/anbotoApi/types";

export const TriggerPriceMinField = forwardRef(
  (props: ControllerRenderProps & AnbotoTextFieldProps & { triggerCondition?: TriggerCondition | null }, ref) => (
    <AnbotoTextField
      {...props}
      inputRef={ref}
      type="number"
      label={`Trigger price ${
        props?.triggerCondition === TriggerCondition.ABOVE
          ? "△ Above"
          : props?.triggerCondition === TriggerCondition.BELOW
          ? "▽ Below"
          : ""
      }`}
      size="small"
      fullWidth
    />
  )
);
TriggerPriceMinField.displayName = "TriggerPriceMinField";
