import React from "react";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { AnbotoTextField, AnbotoTextFieldProps } from "../ui/AnbotoTextField/AnbotoTextField";

export const SymbolSearchInput = React.forwardRef((props: AnbotoTextFieldProps, ref) => {
  return (
    <AnbotoTextField
      ref={ref}
      hiddenLabel
      autoFocus
      fullWidth
      inputProps={{ autoComplete: "off", form: { autoComplete: "off" } }}
      autoComplete="off"
      size="small"
      placeholder="Search any token, symbol or exchange"
      sx={{
        "& .MuiInputBase-root": { paddingLeft: 1.5 },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: (theme) => theme.palette.primary.main,
          borderWidth: 2,
        },
      }}
      InputProps={{
        sx: {
          background: "none",
        },
        startAdornment: (
          <InputAdornment position="start">
            <Search sx={{ color: "#B0BCC2", fontSize: 20 }} />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
});

SymbolSearchInput.displayName = "SymbolSearchInput";
