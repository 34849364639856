import { useExchangesFilter } from "./use-exchanges-filter";
import { useActiveView } from "@src/features/widgets-layout/hooks/use-active-view";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { FavoriteSymbol } from "@src/store/apis/anbotoApi/types";
import { useAppSelector } from "@src/store/hooks";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";
import { WidgetsGridView } from "@src/features/widgets-layout/types";

export const useFavoriteSymbolFilters = (search = "") => {
  const exchanges = useExchangesFilter();
  const view = useActiveView();
  const isOtc = view === WidgetsGridView.OTC;
  const cefiSymbols = useAppSelector((state) => state.user.token_pair_watchlist);
  const otcSymbols = useAppSelector((state) => state.symbolSelect.favourites.otc);
  const symbols = isOtc ? otcSymbols : cefiSymbols;

  const filteredSymbols = symbols.filter(({ symbol, exchange, market_type, coin1, coin2 }) => {
    const words = search.toLowerCase().split(" ").filter(Boolean);

    if (exchanges.length && !exchanges.includes(exchange)) return false;

    for (let i = 0; i < words.length; i++) {
      if (!`${coin1}${coin2}${symbol}${exchange}${market_type}`.toLowerCase().includes(words[i])) return false;
    }

    return true;
  }) as FavoriteSymbol[];

  const filters = filteredSymbols
    .map(
      ({ symbol, exchange, asset_class }) => `NOT objectID:"${symbol}_${CEFI_EXCHANGE_NAME[exchange]}_${asset_class}"`
    )
    .join(" AND ");

  const blackList = filteredSymbols.map((s) => getLivePriceId({ ...s, exchange: CEFI_EXCHANGE_NAME[s.exchange] }));

  return { filters, filteredSymbols, blackList };
};
