import { ActionsTypes } from "@src/store/actions/actions-types";
import {
  ClipSizeType,
  OrderExecutionStrategy,
  OrderTradingDurationUnit,
  PlacementMode,
  TradingStyle,
  UserDetails,
  WouldStyle,
} from "@src/store/apis/anbotoApi/types";
import { DEFAULT_DEFI_CHILD_SLIPPAGE, DEFAULT_DEFI_SLIPPAGE } from "@src/pages/defi/constants";
import { DEFAULT_ORDER_PREFERENCE_EXPIRATION } from "@src/pages/settings/order-preferences/constant";

export type UserState = UserDetails & {
  access_token?: string;
  refresh_token?: string;
  current_otp: "ENABLED" | "";
  mev_protection: boolean;
  idToken?: string;
  isIntroShowing: boolean;
};

const initialState: UserState = {
  //is intro showing right now
  isIntroShowing: false,
  idToken: "",
  account_number: "",
  access_token: "",
  refresh_token: "",
  user_uuid: "",
  email: "",
  first_name: "",
  last_name: "",
  wallets: null,
  team_uuid: "",
  team_name: "",
  otp_enabled: false,
  is_referrer: false,
  referrer_allowed: false,
  referrer_request_sent: false,
  current_otp: "",
  telegram_id: "",
  team_telegram_support_link: null,
  referral_link_limit: 0,
  play_sound: false,
  send_email: false,
  company_name: "",
  terms_of_use: true,
  max_number_teams: 3,
  token_pair_watchlist: [],
  is_individual_account: false,
  fee_manager_enabled: false,
  preferences: {
    volume_participation_plot: {
      safe_below: 0,
      dangerous_above: 0,
    },
  },
  order_preference: {
    cefi_trading_style: TradingStyle.HYBRID,
    cefi_would_price: WouldStyle.HYBRID,
    cefi_maker_placement_type: PlacementMode.DEFAULT,
    cefi_custom_placement_level: "",
    cefi_custom_cancel_level: "",
    cefi_clip_size_type: ClipSizeType.AUTOMATIC,
    cefi_expiration: DEFAULT_ORDER_PREFERENCE_EXPIRATION,
    cefi_extend_duration: true,
    cefi_strategy: OrderExecutionStrategy.TWAP,
    cefi_duration_type: OrderTradingDurationUnit.HOURS,
    cefi_duration_value: "",
    defi_parent_slipage_percent: DEFAULT_DEFI_SLIPPAGE,
    defi_child_slipage_percent: DEFAULT_DEFI_CHILD_SLIPPAGE,
    defi_clip_size_type: ClipSizeType.AUTOMATIC,
    defi_expiration: DEFAULT_ORDER_PREFERENCE_EXPIRATION,
    defi_extend_duration: true,
    defi_strategy: OrderExecutionStrategy.ORDER,
    defi_duration_type: OrderTradingDurationUnit.HOURS,
    defi_duration_value: "",
  },
  liquiditySources: {},
  ip_addresses: [],
  mev_protection: false,
  is_staff: false,
  is_analytics_visible: false,
  is_post_trade_visible: false,
  is_widgets_visible: false,
  is_otc_visible: false,
  user_layouts: [],
  is_fees_dashboard_visible: false,
  is_cefi_intro_shown: false,
  is_defi_intro_shown: false,
  is_widgets_intro_shown: false,
  is_cefi_or_defi_modal_shown: false,
  is_testnet: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionsTypes.SET_USER:
      return { ...state, ...action.payload };
    case ActionsTypes.PUT_TOKEN_PAIR_WATCHLIST:
      return { ...state, token_pair_watchlist: action.payload };
    case ActionsTypes.SET_USER_IS_REFERRER:
      return { ...state, ...action.payload };
    case ActionsTypes.SET_USER_REFERRER_ALLOWED:
      return { ...state, ...action.payload };
    case ActionsTypes.SET_IS_CEFI_INTRO_SHOWN:
      return { ...state, ...action.payload };
    case ActionsTypes.SET_IS_INTRO_SHOWING:
      return { ...state, ...action.payload };
    case ActionsTypes.SET_IS_DEFI_INTRO_SHOWN:
      return { ...state, ...action.payload };
    case ActionsTypes.SET_IS_WIDGETS_INTRO_SHOWN:
      return { ...state, ...action.payload };
    case ActionsTypes.SET_IS_CEFI_OR_DEFI_MODAL_SHOWN:
      return { ...state, ...action.payload };
    case ActionsTypes.SET_IS_REFERRAL_REQUEST_SENT:
      return { ...state, ...action.payload };
    case ActionsTypes.SET_LIQUIDITY_SOURCES:
      return { ...state, ...action.payload };
    case ActionsTypes.SET_ORDER_PREFERENCES:
      return { ...state, ...action.payload };
    case ActionsTypes.PUT_USER_PREFERENCES:
    case ActionsTypes.SET_USER_PREFERENCES:
      return {
        ...state,
        play_sound: action.payload.play_sound,
        send_email: action.payload.send_email,
        preferences: {
          ...state.preferences,
          volume_participation_plot: {
            ...state.preferences.volume_participation_plot,
            safe_below: action.payload.safe_below,
            dangerous_above: action.payload.dangerous_above,
          },
        },
      };
    case ActionsTypes.ENABLE_USER_OTP:
      return { ...state, otp_enabled: action.payload, current_otp: "ENABLED" };
    case ActionsTypes.DISABLE_USER_OTP:
      return { ...state, otp_enabled: action.payload, current_otp: "" };
    case ActionsTypes.CHANGE_USER_TEAM:
      return { ...state, ...action.payload };
    case ActionsTypes.ENABLE_MEV_PROTECTION:
      return { ...state, ...action.payload };
    case ActionsTypes.LOG_OUT_USER:
      return initialState;
    default:
      return state;
  }
};
export default reducer;
