import React from "react";
import { Stack, Typography } from "@mui/material";
import { formatUsd } from "@src/utils/format";

type TradesSummaryProps = {
  thisMonthVolume: number;
  totalVolume: number;
};
const TradesSummary = ({ thisMonthVolume, totalVolume }: TradesSummaryProps) => (
  <Stack marginBottom={3}>
    <Typography>
      <Typography fontSize={14} variant="caption" fontWeight={500}>
        This month volume traded:
      </Typography>{" "}
      {formatUsd(thisMonthVolume)}
    </Typography>
    <Stack>
      <Typography>
        <Typography fontSize={14} variant="caption" fontWeight={500}>
          {" "}
          Total volume traded:{" "}
        </Typography>{" "}
        {formatUsd(totalVolume)}
      </Typography>
    </Stack>
  </Stack>
);

export default TradesSummary;
