import React, { forwardRef } from "react";
import { TextField, TextFieldProps, ThemeProvider } from "@mui/material";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import { KeyboardArrowDown } from "@mui/icons-material";
import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";
import _get from "lodash/get";
import { muiTheme } from "@src/components/theme/mui-theme";

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    large: true;
  }
}

export const themeOverrides = {
  palette: {
    mode: "dark",
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          [`&.Mui-disabled`]: {
            backgroundColor: "#232C2F !important",
            color: "#4A5053",
            WebkitTextFillColor: "#4A5053",
          },
        },
        inputAdornedStart: {
          paddingLeft: "0 !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#031116",
          borderRadius: 4,
          "&.Mui-disabled fieldset": {
            opacity: 0,
          },
          "&:hover .MuiInputAdornment-root": {
            zIndex: 0,
          },
          "&.Mui-focused .MuiInputAdornment-root": {
            zIndex: 0,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: muiTheme.palette.primary.main,
            borderWidth: 2,
          },
          "&.Mui-disabled": {
            color: "#90999d",
            WebkitTextFillColor: "#90999d",
          },
          "& .Mui-disabled": {
            color: "#90999d",
            WebkitTextFillColor: "#90999d",
          },
          "& .MuiSelect-icon": {
            color: "#828C92",
          },
        },
        input: {
          "&.Mui-disabled": {
            color: "#90999d",
            WebkitTextFillColor: "#90999d",
          },
          padding: "8px 14px",
          lineHeight: "24px",
          height: "initial",
          fontSize: 16,
          fontWeight: 400,
        },
        inputSizeSmall: {
          paddingTop: 6,
          paddingBottom: 6,
          lineHeight: "20px",
          fontSize: 14,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0px",
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { size: "large" },
          style: {
            "& input": {
              paddingTop: 12,
              paddingBottom: 12,
              fontSize: "16px",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
          },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          marginTop: 8,
        },
        list: {
          backgroundColor: "#031116",
          borderColor: "#3B4043",
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: muiTheme.shape.borderRadius,
          padding: 0,
        },
        paper: {
          borderRadius: 0,
          paddingtop: 10,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 400,
          borderRadius: 4,
          padding: 8,
          marginTop: 4,
          margin: 4,
          color: "#B0BCC2",
          "&.Mui-selected": {
            color: "#B0BCC2",
            fontWeight: 500,
            background: "transparent!important",
          },
          "&:hover": {
            color: "#2C72E3",
            background: "transparent!important",
          },
          "&:hover .MuiTypography-root": {
            color: "#2C72E3",
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "&": {
            position: "relative",
            zIndex: 1,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "0 !important",
          },
        },
      },
    },
  },
} as ThemeOptions;

const staticLabelOverrides = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        display: "flex",
        position: "initial",
        lineHeight: "1",
        marginBottom: "6px",
        transform: "none",
        fontSize: 12,
        fontWeight: 400,
        color: "#8A9296",
        "&.Mui-error": {
          color: "#8A9296",
        },
        ["&.Mui-focused"]: {
          color: "#8A9296",
        },
      },
    },
  },
  notchedOutline: {
    "& legend": {
      width: 0,
    },
  },
};

const dynamicLabelOverrides = {
  MuiInputLabel: {
    styleOverrides: {
      root: {
        "&.MuiInputLabel-sizeSmall.MuiInputLabel-shrink": {
          fontSize: 11,
          transform: "translate(14px, -7px) scale(1)",
        },
        "&:not(.MuiInputLabel-shrink)": {
          transform: "translate(14px, 9px) scale(1)",
        },
        "&.MuiInputLabel-sizeSmall:not(.MuiInputLabel-shrink)": {
          transform: "translate(14px, 6px) scale(1)",
          fontSize: 14,
        },
      },
    },
  },
};

const staticLabelThemeOverrides = _cloneDeep(themeOverrides);
_set(staticLabelThemeOverrides, "components.MuiInputLabel", staticLabelOverrides.MuiInputLabel);
_set(
  staticLabelThemeOverrides,
  "components.MuiOutlinedInput.styleOverrides.notchedOutline",
  staticLabelOverrides.notchedOutline
);

const dynamicLabelThemeOverrides = _cloneDeep(themeOverrides);
const muiInputBase = _get(dynamicLabelThemeOverrides, "components.MuiInputBase.styleOverrides.root", {}) as {};
const muiOutlinedInput = _get(dynamicLabelThemeOverrides, "components.MuiOutlinedInput.styleOverrides.root", {}) as {};

_set(dynamicLabelThemeOverrides, "components.MuiInputLabel", dynamicLabelOverrides.MuiInputLabel);
_set(dynamicLabelThemeOverrides, "components.MuiInputBase.styleOverrides.root", {
  ...muiInputBase,
  [`&.Mui-disabled`]: {
    backgroundColor: "#232C2F !important",
  },
});
_set(dynamicLabelThemeOverrides, "components.MuiInputBase.styleOverrides.root", {
  ...muiInputBase,
  [`&.Mui-disabled`]: {
    backgroundColor: "#232C2F !important",
  },
});
_set(dynamicLabelThemeOverrides, "components.MuiOutlinedInput.styleOverrides.root", {
  ...muiOutlinedInput,
  ["&.Mui-disabled fieldset"]: {
    opacity: 1,
    borderWidth: "1 !important",
  },
  ["&.Mui-disabled .MuiOutlinedInput-notchedOutline"]: {
    borderColor: "#232C2F !important",
    borderWidth: 1,
  },
});

const staticLabelTheme = createTheme(staticLabelThemeOverrides, muiTheme);
const dynamicLabelTheme = createTheme(dynamicLabelThemeOverrides, muiTheme);

export type AnbotoTextFieldProps = TextFieldProps & {
  labelStyle?: "static" | "dynamic";
  shrink?: boolean;
};

export const AnbotoTextField = forwardRef(({ labelStyle = "static", ...props }: AnbotoTextFieldProps, ref) => {
  const theme = labelStyle === "static" ? staticLabelTheme : dynamicLabelTheme;
  const shrink = labelStyle === "static" ? true : undefined;
  return (
    <ThemeProvider theme={theme}>
      <TextField
        {...props}
        inputRef={ref}
        InputLabelProps={{ shrink, ...props.InputLabelProps }}
        SelectProps={{
          IconComponent: KeyboardArrowDown,
          ...props.SelectProps,
        }}
        FormHelperTextProps={{
          ...props.FormHelperTextProps,
          sx: { color: props.color === "success" ? "success.main" : undefined },
        }}
      />
    </ThemeProvider>
  );
});

AnbotoTextField.displayName = "AnbotoTextField";
