import { OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";

export const MULTI_SLICE_ORDER_EXECUTION_STRATEGIES = [
  OrderExecutionStrategy.TWAP,
  OrderExecutionStrategy.VWAP,
  OrderExecutionStrategy.ICEBERG,
  OrderExecutionStrategy.IS,
];

export const HOST = window.location.host.endsWith("rev01.anboto.xyz")
  ? "trade-dev01.anboto.xyz" // only for review apps envs
  : ""; // for other envs

export const WS_HOST = HOST || window.location.host;

export const CEFI_PARENT_ORDERS_CACHE_KEY = "PARENT_ORDERS";

export const UNEXPECTED_API_ERROR = "PARSING_ERROR";

export const ALL_AGGREGATORS_PARAMETER = "defi_sor";
