import React from "react";
import { Box, IconButton, Link, Stack, styled, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import howToCEFIPic from "@src/pages/portfolio/components/sidebar/assets/images/portfolio-howto-cefi.png";
import howToDEFIPic from "@src/pages/portfolio/components/sidebar/assets/images/portfolio-howto-defi.png";
import { PortfolioType } from "@src/store/slices/portfolioSlice";

type PortfolioHowToProps = {
  portfolioType: PortfolioType;
  setShowHowto: (isShowHowTo: boolean) => void;
};

const PortfolioHowTo = ({ setShowHowto, portfolioType }: PortfolioHowToProps) => {
  const navigate = useNavigate();

  const go2Settings = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigate(portfolioType === PortfolioType.CEFI ? "/settings/linked/exchanges" : "/settings/linked/wallets");
  };

  return (
    <PortfolioHowToStyled>
      <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={16} fontWeight={600}>
          Track {portfolioType === PortfolioType.CEFI ? "account" : "wallet"} balance
        </Typography>
        <IconButton onClick={() => setShowHowto(false)}>
          <CloseIcon sx={{ fontSize: 20, color: "#8A9296" }} />
        </IconButton>
      </Stack>
      {portfolioType === PortfolioType.CEFI ? (
        <Typography marginBottom={1.5} fontSize={14} fontWeight={400} color="#B0BCC2">
          After{" "}
          <Link onClick={go2Settings} href="#">
            linking
          </Link>{" "}
          the exchange account, you can add it here to track your balance overview
        </Typography>
      ) : (
        <Typography marginBottom={1.5} fontSize={14} fontWeight={400} color="#B0BCC2">
          After{" "}
          <Link onClick={go2Settings} href="#">
            linking
          </Link>{" "}
          the wallet, you can add it here to track your balance overview
        </Typography>
      )}
      {portfolioType === PortfolioType.CEFI ? (
        <img src={howToCEFIPic} alt="How to track account balance" />
      ) : (
        <img src={howToDEFIPic} alt="How to track wallet balance" />
      )}
    </PortfolioHowToStyled>
  );
};

const PortfolioHowToStyled = styled(Box)(() => ({
  flexDirection: "column",
  display: "flex",
  border: "2px solid #2C72E3",
  background: "#0A1C39",
  borderRadius: 8,
  padding: 16,
}));

export default PortfolioHowTo;
