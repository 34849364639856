import React, { forwardRef } from "react";
import { CircularProgress, MenuItem } from "@mui/material";
import { ControllerRenderProps } from "react-hook-form";
import { useAccount } from "wagmi";
import { feeManagerApi } from "./api";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export type TokenFeesFieldProps = ControllerRenderProps & { selectedChainField: number };

export const TokenFeesField = forwardRef(({ value, onChange, selectedChainField }: TokenFeesFieldProps, ref) => {
  const _onChange = (newTokenAddress: string) => {
    onChange(newTokenAddress);
  };

  const { chain } = useAccount();
  const possibleTokens = feeManagerApi.useGetPossibleTokenFeesQuery();

  const tokenList = possibleTokens.data?.filter((token) => token.network_id === chain?.id);

  return (
    <>
      <AnbotoTextField
        label="Select token"
        fullWidth
        size="small"
        onChange={(e) => _onChange(e.target.value)}
        value={value}
        inputProps={{ style: { cursor: "pointer" } }}
        select
        inputRef={ref}
        disabled={selectedChainField !== chain?.id}
        sx={{
          "& .MuiSelect-select": {
            height: "30px",
            width: "100%",
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        {tokenList ? (
          tokenList.map((x) => (
            <MenuItem key={x.id} value={x.token_address}>
              <img src={x.logo_uri} height={25} style={{ marginRight: 8 }} />
              {x.token_name}
            </MenuItem>
          ))
        ) : (
          <CircularProgress size={24} sx={{ mt: 1, ml: 1 }} />
        )}
      </AnbotoTextField>
    </>
  );
});

TokenFeesField.displayName = "TokenFeesField";
