import { Box, styled, TableRowProps as MTableRowProps } from "@mui/material";
import { styledOptions } from "@src/utils/styled-options";

export interface TableRowProps extends MTableRowProps {
  loading?: boolean;
  striped?: boolean;
  isDragging?: boolean;
}

export const TableRow = styled(
  Box,
  styledOptions
)<TableRowProps>(({ loading, striped, theme }) => ({
  "&:nth-of-type(even)": { backgroundColor: loading || !striped ? undefined : "#232C2F" },
  display: "flex",
  flexDirection: "row",
  "& [data-sticky-td]": {
    background: theme.palette.background.paper,
  },
}));
