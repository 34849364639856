import React from "react";
import { Box, InputAdornment, Stack } from "@mui/material";
import { PasteOrSelectAccountDialog } from "./paste-or-select-account-dialog";
import { useDialog } from "@src/hooks/useDialog";
import { useSendCryptoFormContext, useWalletOrAccountName } from "@src/pages/portfolio/components/send-crypto/hooks";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { SelectArrow } from "@src/components/select-arrow";

export interface PasteOrSelectAccountProps {
  label?: string;
}

const PASTE_OR_SELECT = "Paste wallet address / select wallet";

export function PasteOrSelectAccount({ label }: PasteOrSelectAccountProps) {
  const sendCryptoForm = useSendCryptoFormContext();
  const selectAccountDialog = useDialog();
  const account = sendCryptoForm.watch("accountTo") as string;
  const accountName = useWalletOrAccountName(account);

  return (
    <Box sx={{ fontSize: 14, lineHeight: "21px", fontWeight: 400 }}>
      <Stack direction="column" gap={1}>
        {!!label && <label>{label}</label>}
        <AnbotoTextField
          fullWidth
          error={!!sendCryptoForm.formState.errors?.accountTo?.message}
          label={PASTE_OR_SELECT}
          labelStyle="dynamic"
          onClick={selectAccountDialog.show}
          value={accountName || "Paste wallet address / select wallet"}
          inputProps={{ style: { cursor: "pointer" } }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <SelectArrow />
              </InputAdornment>
            ),
          }}
          sx={{ marginTop: (theme) => theme.spacing(0.75) }}
        />
      </Stack>
      <PasteOrSelectAccountDialog
        isOpen={selectAccountDialog.isOpen}
        placeholder={PASTE_OR_SELECT}
        onClose={selectAccountDialog.hide}
      />
    </Box>
  );
}
