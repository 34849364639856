import React from "react";
import { AxisTickProps } from "@src/pages/analytics/types";

const AngleAxisTick = ({ x, y, payload }: AxisTickProps) => (
  <g transform={`translate(${x},${y})`}>
    <text fontSize={12} x={0} y={0} dy={16} textAnchor="end" fill="white" transform="rotate(-45)">
      {payload.value}
    </text>
  </g>
);

export default AngleAxisTick;
