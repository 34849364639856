import { Theme } from "@mui/material/styles";

export enum PasswordStrength {
  EMPTY = 0,
  WEAK = 25,
  MEDIUM = 50,
  HIGH = 100,
}

export const strengthColor = (strength: PasswordStrength, theme: Theme) => {
  switch (strength) {
    case PasswordStrength.WEAK:
      return theme.palette.error.main;
    case PasswordStrength.MEDIUM:
      return theme.palette.warning.main;
    case PasswordStrength.HIGH:
      return theme.palette.success.main;
    default:
      return theme.palette.text.disabled;
  }
};
