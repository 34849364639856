import React from "react";
import { TableActionButton, TableActionButtonProps } from "@src/components";
import { useAppSelector } from "@src/store/hooks";

export const ActionButton = ({
  orderId,
  type,
  onClick,
}: {
  orderId: string;
  onClick: (orderId: string) => void;
} & TableActionButtonProps) => {
  const loading = useAppSelector((state) => state.ordersTable.loading.order[orderId]);
  const actionType = useAppSelector((state) => state.ordersTable.loading.actionType);

  return (
    <TableActionButton
      disabled={loading && actionType !== type}
      type={type}
      loading={loading && actionType === type}
      onClick={() => !loading && onClick(orderId)}
    />
  );
};
