import React from "react";
import { Checkbox, FormControl, ListItemText, ListSubheader, MenuItem, Stack, Typography } from "@mui/material";
import { ExportTransactionsButton } from "./export-transactions-button";
import { OrdersTableFilters } from "@src/components/orders-table-filters";
import { GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { Action } from "@src/store/slices/feeManagerSlice";
import { ACTION_NAME } from "@src/pages/settings/fee-manager/constants";
import { CHAIN_NAME } from "@src/pages/defi/constants";
import { TableSearch } from "@src/components/table-search";

type TransactionTableFiltersProps = {
  setPeriod: (period: GetOrdersPeriod) => void;
  filterBy: string[];
  setFilterBy: (filter: string[]) => void;
  availableChainIds: string[];
  setSearchString: (searchString: string) => void;
};

const TransactionTableFilters = ({
  setPeriod,
  filterBy,
  setFilterBy,
  availableChainIds,
  setSearchString,
}: TransactionTableFiltersProps) => {
  const handleChange = (event) => {
    const value = event.target.value;
    setFilterBy(typeof value === "string" ? value.split(",") : value);
  };

  const handleSearch = (search) => {
    setSearchString(search.toLowerCase().trim());
  };

  return (
    <>
      <Typography variant="h6">Records</Typography>
      <Stack gap={1} direction="row" alignItems="center">
        <ExportTransactionsButton />
        <Typography sx={{ fontSize: 12 }} color="text.secondary">
          Filter By
        </Typography>

        <FormControl size="small" sx={{ width: 130 }}>
          <AnbotoTextField
            size="small"
            select
            SelectProps={{ multiple: true, renderValue: (selected) => (selected as any).join(", ") }}
            value={filterBy}
            onChange={handleChange}
          >
            <ListSubheader sx={{ lineHeight: 2.2, paddingLeft: 1 }}>Actions</ListSubheader>
            {Object.keys(Action)
              .filter((x) => !isNaN(parseInt(x)))
              .map((action) => (
                <MenuItem
                  sx={{ margin: 0, padding: 0 }}
                  key={ACTION_NAME[action]}
                  value={ACTION_NAME[action] === "Fee charge" ? "Collection" : ACTION_NAME[action]}
                >
                  <Checkbox
                    size="small"
                    checked={
                      filterBy.indexOf(ACTION_NAME[action] === "Fee charge" ? "Collection" : ACTION_NAME[action]) > -1
                    }
                  />
                  <ListItemText
                    primaryTypographyProps={{ fontSize: 12 }}
                    sx={{ fontSize: 12 }}
                    primary={ACTION_NAME[action]}
                  />
                </MenuItem>
              ))}
            {availableChainIds && <ListSubheader sx={{ lineHeight: 2.2, paddingLeft: 1 }}>Networks</ListSubheader>}
            {availableChainIds.map((chainId) => (
              <MenuItem sx={{ margin: 0, padding: 0 }} key={CHAIN_NAME[chainId]} value={CHAIN_NAME[chainId]}>
                <Checkbox size="small" checked={filterBy.indexOf(CHAIN_NAME[chainId]) > -1} />
                <ListItemText primaryTypographyProps={{ fontSize: 12 }} primary={CHAIN_NAME[chainId]} />
              </MenuItem>
            ))}
          </AnbotoTextField>
        </FormControl>

        <OrdersTableFilters period={{ onChange: setPeriod }} />

        <TableSearch onSearch={handleSearch} placeholder="Actions / Network" />
      </Stack>
    </>
  );
};

export default TransactionTableFilters;
