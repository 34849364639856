import React from "react";
import { Stack } from "@mui/material";
import { useOtcOrderbookSubscription } from "../../use-otc-orderbook-subscription";
import { SellBuy } from "./sell-buy-button";
import { Spread } from "./spread";
import { useOtcOrderFormContext } from "./hooks/use-otc-order-form-context";
import { interpolatePrice } from "./utils";
import { OrderExecutionStrategy, OrderSide, OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { useAppSelector } from "@src/store/hooks";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";

export const SellBuyControlls = ({
  symbol,
  onSubmit,
  strategy,
  slippage,
  amount,
}: {
  symbol: OrderSymbol;
  onSubmit: () => void;
  strategy: OrderExecutionStrategy;
  slippage: string;
  amount: string;
}) => {
  const _amount = +amount;
  const form = useOtcOrderFormContext();

  useOtcOrderbookSubscription([symbol]);

  const orderbook = useAppSelector((state) => state.subscriptions.otcOrderbook[getLivePriceId(symbol)]);

  const askMinPrice = orderbook?.asks[0][0] || 0;
  const bidMinPrice = orderbook?.bids[0][0] || 0;

  const p1 = askMinPrice.toString().split(".")[1]?.length || 2;
  const p2 = bidMinPrice.toString().split(".")[1]?.length || 2;
  const pricePrecision = p1 > p2 ? p1 : p2;

  const askMinQty = orderbook?.asks[0][1];
  const askMaxQty = orderbook?.asks[1][1];
  const bidMinQty = orderbook?.bids[0][1];
  const bidMaxQty = orderbook?.bids[1][1];

  // @TODO: reverse constants once BE data be corrected
  const iSellPrice =
    _amount && _amount >= bidMinQty && _amount <= bidMaxQty ? interpolatePrice("bids", _amount, orderbook) : 0;

  const iBuyPrice =
    _amount && _amount >= askMinQty && _amount <= askMaxQty ? interpolatePrice("asks", _amount, orderbook) : 0;

  // const sellPrice = iSellPrice || bidMinPrice || 0;
  // const buyPrice = iBuyPrice || askMinPrice || 0;
  const sellPrice = iBuyPrice || askMinPrice || 0;
  const buyPrice = iSellPrice || bidMinPrice || 0;

  const submitOrder = (side: OrderSide) => {
    form.setValue("side", side);
    onSubmit();
  };

  return (
    <>
      <Stack direction="row" position="relative" gap={1}>
        <Spread sell={sellPrice} buy={buyPrice} />
        <SellBuy
          slippage={strategy === OrderExecutionStrategy.FOK ? +slippage : 0}
          side="sell"
          price={sellPrice}
          pricePrecision={pricePrecision}
          onClick={() => submitOrder(OrderSide.SELL)}
        />
        <SellBuy
          slippage={strategy === OrderExecutionStrategy.FOK ? +slippage : 0}
          side="buy"
          price={buyPrice}
          pricePrecision={pricePrecision}
          onClick={() => submitOrder(OrderSide.BUY)}
        />
      </Stack>
    </>
  );
};
