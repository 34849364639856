import { OrderPreferencesExpirations, OrderStatuses, ParentOrderStatuses } from "@src/store/apis/anbotoApi/types";

export const END_TIME_FORMAT = "dd-MM-yy | HH:mm";

export const MAIN_ACCOUNT_VALUE = "MAIN_ACCOUNT_VALUE";

export const CEFI_ORDER_STATUS_LABEL: Record<OrderStatuses, string> = {
  [OrderStatuses.WAITING]: "Waiting",
  [OrderStatuses.RUNNING]: "Running",
  [OrderStatuses.FINISHED]: "Finished",
  [OrderStatuses.PAUSING]: "Pausing",
  [OrderStatuses.PAUSED]: "Paused",
  [OrderStatuses.SERVER_PAUSING]: "Server Pausing",
  [OrderStatuses.SERVER_PAUSED]: "Server Paused",
  [OrderStatuses.CANCELING]: "Canceling",
  [OrderStatuses.CANCELED]: "Canceled",
  [OrderStatuses.INSUFFICIENT_FUNDS]: "Insufficient Funds",
  [OrderStatuses.ERROR]: "Error",
  [OrderStatuses.WAIT_FOR_ORDERBOOK]: "Wait For Orderbook",
  [OrderStatuses.WAITING_ALLOWANCE]: "Waiting Allowance",
};

export const PARENT_ORDER_STATUS_LABEL: Record<ParentOrderStatuses, string> = {
  [ParentOrderStatuses.PENDING_NEW]: "Pending",
  [ParentOrderStatuses.ACCEPTED]: "Running",
  [ParentOrderStatuses.REJECTED]: "Rejected",
  [ParentOrderStatuses.PARTIALLY_FILLED]: "Partly Filled",
  [ParentOrderStatuses.FILLED]: "Finished",
  [ParentOrderStatuses.PENDING_CANCEL]: "Canceling",
  [ParentOrderStatuses.CANCELLED]: "Cancelled",
  [ParentOrderStatuses.PENDING_PAUSE]: "Pausing",
  [ParentOrderStatuses.PAUSED]: "Paused",
  [ParentOrderStatuses.INSUFFICIENT_FUNDS]: "Insufficient Funds",
  [ParentOrderStatuses.PENDING_UNPAUSE]: "Pending",
};

export const MIN_FREQUENCY_SECONDS = 6;

export const CEFI_EXPIRATIONS: OrderPreferencesExpirations[] = [
  OrderPreferencesExpirations.WEEK,
  OrderPreferencesExpirations.MONTH,
  OrderPreferencesExpirations.SIX_MONTH,
];

export const DEFAULT_CEFI_EXTEND_DURATION = true;

export const BITGET_LIMIT = 1000;
