import React, { useState } from "react";
import { Box, Grid, Paper, Stack, Tabs } from "@mui/material";
import { DailyDashboard } from "./daily-dashboard";
import { MonthlyDashboard } from "./monthly-dashboard";
import { FeeManagerBalances } from "./fee-manager-balances";
import { AnbotoExecBalances } from "./anboto-exec-balances";
import { AnbotoTab } from "@src/components/ui/AnbotoTabs/AnbotoTab";

export enum DashboardTabs {
  DAILY = "daily",
  MONTHLY = "monthly",
  FEE_MANAGER = "fee_manager",
  EXEC_CONTRACT = "exec_contract",
}

export const FeesDashboardPage = () => {
  const [dashboardTab, setDashboardTab] = useState<DashboardTabs>(DashboardTabs.DAILY);

  const handleTabChange = (e: React.SyntheticEvent, value: DashboardTabs) => setDashboardTab(value);

  return (
    <Paper>
      <Stack sx={{ ml: 2, mt: 1 }}>
        <Tabs value={dashboardTab || DashboardTabs.DAILY} onChange={handleTabChange}>
          <AnbotoTab value={DashboardTabs.DAILY} label="Daily view" />
          <AnbotoTab value={DashboardTabs.MONTHLY} label="Monthly view" />
          <AnbotoTab value={DashboardTabs.FEE_MANAGER} label="Fee manager balances" />
          <AnbotoTab value={DashboardTabs.EXEC_CONTRACT} label="Anboto exec balances" />
        </Tabs>
      </Stack>
      <Box>
        <Grid container direction="column" paddingTop={"21px"}>
          {dashboardTab === DashboardTabs.DAILY && <DailyDashboard />}
          {dashboardTab === DashboardTabs.MONTHLY && <MonthlyDashboard />}
          {dashboardTab === DashboardTabs.FEE_MANAGER && <FeeManagerBalances />}
          {dashboardTab === DashboardTabs.EXEC_CONTRACT && <AnbotoExecBalances />}
        </Grid>
      </Box>
    </Paper>
  );
};
