import React, { useEffect } from "react";
import { Menu, Stack, Typography, useTheme, MenuItem, styled } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useOrderbookContext } from "../hooks/use-orderbook-context";

type PriceGroupingMenuProps = {
  selected: string;
  onChange: (group: string) => void;
};

const GroupingMenu = React.memo(
  ({ onChange, selected, groups }: PriceGroupingMenuProps & { groups: string[] }) => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const settingsOpen = Boolean(anchorEl);
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (selected: string) => {
      onChange(selected);
      handleMenuClose();
    };
    return (
      <>
        <Stack
          aria-controls={settingsOpen ? "group-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={settingsOpen ? "true" : undefined}
          onClick={handleMenuOpen}
          alignItems="center"
          justifyContent="flex-end"
          direction="row"
          sx={{ cursor: "pointer" }}
        >
          <Typography sx={{ fontSize: 12 }}>{selected}</Typography>
          <ArrowDropDownIcon sx={{ fontSize: 12 }} />
        </Stack>
        <Menu
          id={`price-group-menu`}
          anchorEl={anchorEl}
          open={settingsOpen}
          onClose={handleMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              backgroundColor: "#031116",
              borderColor: theme.palette.primary.main,
              borderWidth: 1,
              borderStyle: "solid",
              borderRadius: "8px",
              mt: 1.5,
              padding: 0,
              "& .MuiMenu-list": {
                padding: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {groups.map((groups: string) => (
            <StyledMenuItem key={groups} onClick={() => handleChange(groups)}>
              {groups}
            </StyledMenuItem>
          ))}
        </Menu>
      </>
    );
  },
  (prev, next) => prev.selected === next.selected && prev.groups.join() === next.groups.join()
);

GroupingMenu.displayName = "GroupingMenu";

export const PriceGroupingMenu = ({ onChange, selected = "" }: PriceGroupingMenuProps) => {
  const { groups = [] } = useOrderbookContext();
  const [localGroups, setLocalGroups] = React.useState<string[]>([]);

  useEffect(() => {
    setLocalGroups(groups);
    onChange(groups[0]);
  }, [groups[0]]);

  return <GroupingMenu selected={selected} groups={localGroups} onChange={onChange} />;
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontSize: 12,
  height: 20,
  padding: "12px 16px",
  borderRadius: 4,
  textAlign: "left",
  color: "#8A9296",
  "&:hover": {
    fontWeight: 500,
    color: "white",
  },
}));
