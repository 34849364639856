import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { RiskLevelIndicator } from "@src/components/price-impact/components/risk-level-indicator";
import { annualized, formatDuration } from "@src/components/price-impact/utils";
import {
  CefiExchangeMarketType,
  ClipSizeType,
  OrderExecutionStrategy,
  OrderTradingDurationUnit,
} from "@src/store/apis/anbotoApi/types";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { STATUS_LABEL } from "@src/components/price-impact/constant";
import { PriceImpactWrapperProps } from "@src/components/price-impact/components/price-impact-wrapper";
import UnderlinedTooltip from "@src/components/underlined-tooltip/underlined-tooltip";
import PriceImpactContextValue from "@src/components/price-impact/components/price-impact-context-value";
import PriceValueChange from "@src/components/price-impact/components/price-value-change";
import { Value } from "@src/components/price-impact/components/value";
import { formatAmount, roundAmount } from "@src/pages/portfolio/utils/helpers";
import { useDebounce } from "@src/hooks/use-debounce";

interface PriceImpactProps extends PriceImpactWrapperProps {}

const POLLING_INTERVAL = 60 * 1000;

const PriceImpact = ({
  symbol,
  quantity,
  clipSizeVal,
  clipSizeType,
  tradingDuration,
  tradingDurationUnit,
  strategy,
}: PriceImpactProps) => {
  const theme = useTheme();
  const exchangeId = Object.keys(CEFI_EXCHANGE_NAME).find((key) => CEFI_EXCHANGE_NAME[key] === symbol?.exchange);
  const isDuration = strategy ? [OrderExecutionStrategy.TWAP, OrderExecutionStrategy.VWAP].includes(strategy) : false;
  const [pollingInterval, setPollingInterval] = useState(POLLING_INTERVAL);
  const isObligatoryParams = isDuration
    ? symbol && quantity && exchangeId && tradingDuration && strategy
    : symbol && quantity && exchangeId && strategy;
  const isObligatoryAgnosticParams = symbol?.symbol && exchangeId && symbol?.market_type;
  const isClipSize = strategy !== OrderExecutionStrategy.ORDER;

  const tradingDurationInSeconds = String(
    tradingDurationUnit === OrderTradingDurationUnit.HOURS
      ? Number(tradingDuration) * 3600
      : tradingDurationUnit === OrderTradingDurationUnit.DAYS
      ? Number(tradingDuration) * 3600 * 24
      : Number(tradingDuration) * 60
  );

  const _quantity = useDebounce(quantity, 1000);

  const { data, isLoading, isFetching, isError } = anbotoApi.useGetPreTradeAnalysisQuery(
    {
      symbol: symbol?.symbol as string,
      exchange: exchangeId || "",
      asset_class: symbol?.market_type as string,
      strategy: strategy!,
      quantity: _quantity,
      ...(isClipSize
        ? {
            clip_size_type: clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS ? ClipSizeType.PERCENTAGE : clipSizeType,
            ...(clipSizeType !== ClipSizeType.AUTOMATIC
              ? {
                  clip_size_val:
                    clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS
                      ? (100 / (Number(clipSizeVal) || 1)).toString()
                      : clipSizeVal,
                }
              : {}),
          }
        : {}),
      ...(isDuration ? { duration_seconds: tradingDurationInSeconds } : {}),
    },
    {
      skip: !isObligatoryParams,
      pollingInterval,
    }
  );

  const preTradeAnalysisAgnosticData = anbotoApi.useGetPreTradeAnalysisAgnosticDataQuery(
    {
      symbol: symbol?.symbol as string,
      exchange: exchangeId || "",
      asset_class: symbol?.market_type as string,
    },
    {
      skip: !isObligatoryAgnosticParams,
      pollingInterval: 60 * 1000,
    }
  );

  const { volume_participation, order_book_analysis } = data?.data || {};

  const currentDataParticipation = volume_participation || 0;

  const isLowOrderBookParticipation = (order_book_analysis?.participation_order_book || 0) < 5;
  const currentRisk = isLowOrderBookParticipation
    ? currentDataParticipation > 20
      ? STATUS_LABEL.red
      : currentDataParticipation > 5
      ? STATUS_LABEL.orange
      : currentDataParticipation > 0
      ? STATUS_LABEL.green
      : STATUS_LABEL.default
    : currentDataParticipation > 10
    ? STATUS_LABEL.red
    : currentDataParticipation > 3
    ? STATUS_LABEL.orange
    : currentDataParticipation > 0
    ? STATUS_LABEL.green
    : STATUS_LABEL.default;

  const isShowAdviseDurationSafe = order_book_analysis?.advice_duration_safe && currentRisk !== STATUS_LABEL.green;

  const [isFundingRateYearTimeframe, setIsFundingRateYearTimeframe] = useState(false);

  useEffect(() => {
    setPollingInterval(isError ? 0 : POLLING_INTERVAL);
  }, [isError]);

  return (
    <Stack alignItems="center" direction="column" gap={1}>
      <Stack width="100%" sx={{ borderRadius: 0.5, background: "#192022" }}>
        <RiskLevelIndicator
          isFetching={isFetching}
          riskLevel={quantity ? currentRisk : STATUS_LABEL.default}
          isDefault={!isObligatoryParams}
        />
        {strategy &&
          [OrderExecutionStrategy.POV, OrderExecutionStrategy.TWAP, OrderExecutionStrategy.VWAP].includes(strategy) && (
            <Accordion
              disableGutters
              elevation={0}
              square
              sx={{
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
                "&::before": {
                  display: "none",
                },
                "&.Mui-expanded": {
                  margin: 0,
                },
                "&:not(:last-child)": {
                  borderBottom: 0,
                },
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                },
                "& .MuiButtonBase-root": {
                  minHeight: 38,
                },
                "& .MuiAccordionDetails-root": {
                  paddingBottom: 1,
                  paddingTop: 0,
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "#8A9296" }} />}>
                <Typography fontSize={12} fontWeight={500} color="#8A9296">
                  See Suggestion
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack width="100%" gap={0.5} direction="column">
                  <DataRow>
                    <Field>Your trade</Field>
                    <Value>
                      {isLoading ? (
                        <Skeleton width={50} />
                      ) : !isObligatoryParams ? (
                        "-"
                      ) : (
                        `${tradingDuration} ${tradingDurationUnit?.slice(0, -1)}${
                          Number(tradingDuration) === 1 ? "" : "s"
                        } Duration`
                      )}
                    </Value>
                  </DataRow>

                  <DataRow>
                    <Field>Trade Qty % OrderBook</Field>
                    <Value>
                      {isLoading ? (
                        <Skeleton width={50} />
                      ) : !isObligatoryParams ? (
                        "-"
                      ) : (
                        `${order_book_analysis?.participation_order_book.toFixed(2)}%`
                      )}
                    </Value>
                  </DataRow>

                  <DataRow>
                    <Field>Trade Qty % of Volume</Field>
                    <Value>
                      {isLoading ? (
                        <Skeleton width={50} />
                      ) : !isObligatoryParams ? (
                        "-"
                      ) : (
                        `${volume_participation?.toFixed(2)}%`
                      )}
                    </Value>
                  </DataRow>
                </Stack>

                {!!isShowAdviseDurationSafe && !!isObligatoryParams && (
                  <Card gap={1} my={1} sx={{ paddingLeft: 1.5, borderRadius: 2, background: "#031116" }}>
                    {isLoading ? (
                      <Skeleton width={50} />
                    ) : (
                      <Stack direction="row" alignItems="flex-start" pl={2}>
                        <LightbulbOutlinedIcon
                          sx={{
                            color: theme.palette.text.secondary,
                            fontSize: 16,
                            position: "absolute",
                            left: 8,
                            top: 13,
                          }}
                        />
                        <Typography fontWeight={400} fontSize={12} color={(theme) => theme.palette.text.secondary}>
                          We suggest <b>duration</b>
                        </Typography>
                      </Stack>
                    )}

                    {isLoading ? (
                      <Skeleton width={50} />
                    ) : (
                      <Stack pl={2}>
                        <Typography variant="body2" fontSize={13}>
                          {formatDuration(order_book_analysis?.advice_duration_safe)} for{" "}
                          <Typography fontSize={13} variant="body2" color="#179B83" component="span">
                            Low Impact
                          </Typography>
                        </Typography>
                      </Stack>
                    )}
                  </Card>
                )}
              </AccordionDetails>
            </Accordion>
          )}
      </Stack>

      <Stack width="100%" gap={0.2} direction="column">
        <Stack direction="row" justifyContent="space-between">
          <UnderlinedTooltip text="Last Price" tooltipText="Midpoint price from the exchange" />
          <Stack direction="row" gap={0.25} alignItems="center">
            <PriceImpactContextValue
              type="price"
              fieldName="price"
              sx={{ fontSize: 14, fontWeight: 600, color: "#B0BCC2" }}
            />
            <PriceValueChange />
          </Stack>
        </Stack>
        <Stack sx={{ borderBottom: "1px solid #061116", my: 1 }}></Stack>

        <DataRow>
          <Field>
            <UnderlinedTooltip
              text="Est. Sweep price"
              tooltipText="Projected price factoring in trade size, based on an order book snapshot with a 50% adjustment for potential spoofing"
            />
          </Field>
          <PriceImpactContextValue type="price" fieldName="sweepPrice" />
        </DataRow>
        <DataRow>
          <Field>
            <UnderlinedTooltip
              text="Est. Slippage"
              tooltipText="Predicted percentage difference between the sweep price and the best bid (or ask) price"
            />
          </Field>
          <PriceImpactContextValue type="percent" fieldName="estimatedSlippage" />
        </DataRow>

        <DataRow>
          <Field>% of 24H Vol</Field>
          <PriceImpactContextValue type="percent" fieldName="percentOf24hVolume" />
        </DataRow>

        <Stack sx={{ borderBottom: "1px solid #061116", my: 1 }}></Stack>

        <Typography pb={0.5} color="#8A9296" fontSize={12} fontWeight={500}>
          Volatility index
        </Typography>

        <DataRow>
          <Field>
            <UnderlinedTooltip
              text=" Short"
              tooltipText="5 hours historical volatility. Annualized. Frequence 1 min."
            />
            /{" "}
            <UnderlinedTooltip text=" Mid" tooltipText="3 days historical volatility. Annualized. Frequence 1 hour." />{" "}
            term
          </Field>
          <Value>
            {preTradeAnalysisAgnosticData.isLoading ? (
              <Skeleton width={50} />
            ) : !isObligatoryAgnosticParams ? (
              "-"
            ) : (
              `${annualized(
                (preTradeAnalysisAgnosticData?.data?.data?.volatility_analysis?.short_term?.volatility || 0) * 100
              ).toFixed(2)}% / ${annualized(
                (preTradeAnalysisAgnosticData?.data?.data?.volatility_analysis?.long_term?.volatility || 0) * 100
              ).toFixed(2)}%`
            )}
          </Value>
        </DataRow>

        <Stack sx={{ borderBottom: "1px solid #061116", my: 1 }}></Stack>

        <Typography pb={0.5} color="#8A9296" fontSize={12} fontWeight={500}>
          Token information
        </Typography>

        {symbol?.market_type === CefiExchangeMarketType.FUTURE && (
          <DataRow>
            <Field
              sx={{ display: "flex", flexDirection: "row", gap: 0.5, cursor: "pointer" }}
              onClick={() => setIsFundingRateYearTimeframe((val) => !val)}
            >
              Funding
              <Field
                sx={{
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                {isFundingRateYearTimeframe ? "1y" : "8h"}
              </Field>
            </Field>
            <Value color="#d77843!important">
              {preTradeAnalysisAgnosticData.isLoading ? (
                <Skeleton width={50} />
              ) : !isObligatoryAgnosticParams ? (
                "-"
              ) : (
                `${(
                  (preTradeAnalysisAgnosticData?.data?.data?.futures_infos?.funding_rate || 0) *
                  100 *
                  (isFundingRateYearTimeframe ? 1095 : 1)
                ).toFixed(4)}%`
              )}
            </Value>
          </DataRow>
        )}

        <DataRow>
          <Field>24h High</Field>
          <PriceImpactContextValue type="price" fieldName="high" />
        </DataRow>

        <DataRow>
          <Field>24h Low</Field>
          <PriceImpactContextValue type="price" fieldName="low" />
        </DataRow>

        <DataRow>
          <Field>1min Vol ({symbol?.coin1 || symbol?.symbol?.split("/")[0]})</Field>
          <Stack direction="row" color="#636b6f" fontSize={12}>
            <PriceImpactContextValue type="price" fieldName="oneMinVolume" />
          </Stack>
        </DataRow>

        <DataRow>
          <Field>24h Vol ({symbol?.coin1 || symbol?.symbol?.split("/")[0]})</Field>
          <Stack direction="row" color="#636b6f" fontSize={12}>
            <PriceImpactContextValue type="price" fieldName="baseVolume" />
          </Stack>
        </DataRow>

        <DataRow>
          <Field>24h Vol ({symbol?.coin2 || symbol?.symbol?.split("/")[1]})</Field>
          <PriceImpactContextValue type="price" fieldName="quoteVolume" />
        </DataRow>

        <DataRow>
          <Field>
            <UnderlinedTooltip text="OI (USDT)" tooltipText="Open interest" />
          </Field>
          <Value>
            {preTradeAnalysisAgnosticData.isLoading ? (
              <Skeleton width={50} />
            ) : !isObligatoryAgnosticParams ? (
              "-"
            ) : (
              formatAmount(roundAmount(String(preTradeAnalysisAgnosticData?.data?.data?.futures_infos?.open_interest)))
            )}
          </Value>
        </DataRow>
      </Stack>
    </Stack>
  );
};

export default PriceImpact;

export const DataRow = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: 0.75,
  alignItems: "center",
}));

export const Field = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: 12,
  color: "#636B6F",
  userSelect: "none",
}));

const Card = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(2),
  position: "relative",
  background: theme.custom.background.default,
  flex: 1,
  borderRadius: 4,
  width: "100%",
}));
