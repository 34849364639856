import { Tab, TabProps, styled } from "@mui/material";

export type AnbotoTabProps = TabProps<any, { component?: any }>;

export const AnbotoTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  textDecoration: "none",
  color: theme.palette.text.primary,
  "&:hover": {
    color: "#F1F7FA",
  },
  "&.Mui-selected": {
    color: "#F1F7FA",
  },
}));
