import React, { useEffect, useState } from "react";
import { Stack, Typography, styled } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

type DescriptionAreaProps = {
  videoUrl?: string;
  withExpand?: boolean;
  expanded?: boolean;
};

export const DescriptionArea = ({
  children,
  videoUrl,
  withExpand,
  expanded,
}: React.PropsWithChildren<DescriptionAreaProps>) => {
  const [visible, setVisible] = useState(false);
  const showContent = !withExpand || visible;

  useEffect(() => {
    if (expanded) {
      setVisible(expanded);
    }
  }, [expanded]);

  return (
    <Root>
      {withExpand && (
        <Stack direction="row" justifyContent="flex-start">
          <Help direction="row" alignItems="center" onClick={() => setVisible((state) => !state)}>
            <HelpLink>Help</HelpLink>
            <HelpIcon sx={{ fontSize: 12, marginLeft: 0.5, color: (theme) => theme.custom.colors.dim }} />
          </Help>
        </Stack>
      )}
      {showContent && (
        <Stack direction="row">
          {videoUrl && (
            <Stack>
              <iframe width={600} height={320} src={videoUrl} frameBorder={0}></iframe>
            </Stack>
          )}
          <Stack ml={4} sx={{ color: "rgb(138, 146, 150)" }}>
            {children}
          </Stack>
        </Stack>
      )}
    </Root>
  );
};

const Root = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  ["& ol ul"]: {
    paddingLeft: theme.spacing(2),
  },
}));

const HelpLink = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  lineHeight: 1.2,
  color: theme.custom.colors.dim,
}));

const Help = styled(Stack)(({ theme }) => ({
  cursor: "pointer",
  borderBottom: `1px dashed ${theme.custom.colors.dim}`,
  marginBottom: theme.spacing(2),
  "&:hover": {
    border: "none",
    marginBottom: `calc(${theme.spacing(2)} + 1px)`,
  },
}));
