import React from "react";
import {
  Box,
  Slide,
  Paper,
  Stack,
  DialogProps,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Divider,
  DialogActions,
  BoxProps,
  PaperProps,
  DialogContentProps,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export type SettingsDialogProps = DialogProps & {
  title: string;
  open: boolean;
  onClose: () => void;
  onOverlayClick?: () => void;
  actions?: React.ReactNode;
  overlaySx?: BoxProps["sx"];
  contentSx?: PaperProps["sx"];
  dialogContentSx?: DialogContentProps["sx"];
};

export const SettingsDialog = ({
  open,
  title,
  onClose,
  children,
  actions,
  overlaySx,
  contentSx,
  onOverlayClick,
  dialogContentSx,
}: SettingsDialogProps) => {
  const overlayClickHandler = (e, onClick) => {
    if (e.target === e.currentTarget) {
      onClick();
    }
  };

  return (
    <Box
      onClick={(e) => onOverlayClick && overlayClickHandler(e, onOverlayClick)}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "rgba(3, 17, 22, 0.7)",
        height: "100%",
        zIndex: 2,
        ...overlaySx,
      }}
    >
      <Slide direction="up" in={open} mountOnEnter={false} unmountOnExit={false}>
        <Paper
          sx={{
            height: "80%",
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            ...contentSx,
          }}
        >
          <Stack height="100%">
            <DialogTitle>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">{title}</Typography>
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent sx={{ maxHeight: 600, ...dialogContentSx }}> {children}</DialogContent>
            {actions && (
              <>
                <Divider />
                <DialogActions>{actions}</DialogActions>
              </>
            )}
          </Stack>
        </Paper>
      </Slide>
    </Box>
  );
};
