import React, { useMemo, useState } from "react";
import { Themes } from "react-tradingview-widget";
import { Box, CircularProgress, Paper, styled } from "@mui/material";
import { ErrorBoundary } from "@sentry/react";
import { PRICE_PROTECTION_OPTIONS } from "@src/pages/defi/constants";
import { TradingViewChart } from "@src/pages/defi/defi-chart/trading-view-chart";
import { ChainId, Token } from "@src/pages/defi/types";
import { ResolutionString } from "@src/charting_library";
import { TvLines } from "@src/pages/cefi/order-symbol-chart/TradingViewChart";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import { digitsAfterZeros } from "@src/utils/format";
import { isMultiSliceOrderExecutionStrategy } from "@src/store/apis/anbotoApi/utils";
import { OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";

type DefiTradingViewChartProps = {
  fromToken: Token;
  toToken: Token;
  chainId: ChainId;
  isLineChart: boolean;
  isReverseChart: boolean;
};

const prepareChartSymbol = (fromTokenSymbol, toTokenSymbol, chain, fromTokenAddress, toTokenAddress, isLineChart) =>
  `${fromTokenSymbol}/${toTokenSymbol}~${chain}~${fromTokenAddress}~${toTokenAddress}~${isLineChart}`;

export const DefiTradingViewChart = ({
  fromToken,
  toToken,
  chainId,
  isLineChart,
  isReverseChart,
}: DefiTradingViewChartProps) => {
  const orderFormContext = useDefiOrderFormContext();
  const triggerPrice = orderFormContext.watch("triggerPrice");
  const slippage = orderFormContext.watch("slippage");
  const limitPrice = orderFormContext.watch("limitPrice");
  const quantity = orderFormContext.watch("fromTokenAmount");
  const strategy = orderFormContext.watch("strategy");
  const currentPriceProtectionOption = orderFormContext.watch("priceProtection");

  const [currentTokenPrice, setCurrentTokenPrice] = useState<number | undefined>(undefined);

  const isMarketPriceProtection = currentPriceProtectionOption === PRICE_PROTECTION_OPTIONS[0];
  const isDcaStrategy = strategy === OrderExecutionStrategy.DCA;
  const isShowSlippage =
    ((isMultiSliceOrderExecutionStrategy(strategy) && !isMarketPriceProtection) ||
      !isMultiSliceOrderExecutionStrategy(strategy)) &&
    currentTokenPrice &&
    slippage &&
    !isDcaStrategy;
  const slippageLabel = isMultiSliceOrderExecutionStrategy(strategy) ? "Limit Price" : "Slippage";

  const tradingviewSymbol = useMemo(
    () =>
      prepareChartSymbol(fromToken.symbol, toToken.symbol, chainId, fromToken.address, toToken.address, isLineChart),
    [chainId, fromToken.symbol, toToken.symbol, isLineChart]
  );

  const [tvReady, setTvReady] = useState(false);

  const getInterval = () => {
    let interval = "15";
    if (isLineChart) {
      interval = "1D";
    }
    return interval as ResolutionString;
  };

  const chartLines: TvLines = {
    ...(isShowSlippage
      ? {
          slippage: {
            value: isReverseChart
              ? currentTokenPrice + (+slippage * currentTokenPrice) / 100
              : currentTokenPrice - (+slippage * currentTokenPrice) / 100,
            label: slippageLabel,
            linestyle: 2,
            options: {
              linecolor: "#636B6F",
              textcolor: "#636B6F",
              horzLabelsAlign: "right",
              vertLabelsAlign: "bottom",
            },
          },
        }
      : {}),
    ...(limitPrice
      ? {
          limitPrice: {
            value: +limitPrice,
            quantity,
            label: `Limit`,
            options: {
              linecolor: "#179B83",
              textcolor: "#333",
            },
          },
        }
      : {}),
    ...(triggerPrice
      ? {
          triggerPrice: {
            value: +triggerPrice,
            label: "Trigger price",
            options: {
              linecolor: "#4b88dd",
              textcolor: "#fafafa",
              horzLabelsAlign: "right",
              vertLabelsAlign: "bottom",
            },
          },
        }
      : {}),
  };

  const handleLineMove = (key: string, value: number) => {
    let _value: string;

    if (key === "slippage" && currentTokenPrice) {
      const slippageValue = (Math.abs(value - currentTokenPrice) * 100) / currentTokenPrice;
      if (isReverseChart) {
        _value = digitsAfterZeros(value < currentTokenPrice ? 0 : slippageValue, 2);
      } else {
        _value = digitsAfterZeros(value > currentTokenPrice ? 0 : slippageValue, 2);
      }
    } else {
      _value = digitsAfterZeros(value, 4);
    }

    orderFormContext.setValue(key as any, String(_value));
  };

  return (
    <Paper sx={{ height: "100%", overflow: "hidden", position: "relative", flex: 1 }}>
      {!tvReady && (
        <Loading>
          <CircularProgress size={36} />
        </Loading>
      )}
      {tradingviewSymbol && (
        <ErrorBoundary fallback={<Box>Something wrong happened during chart drawing.</Box>}>
          <TradingViewChart
            isLineChart={isLineChart}
            theme={Themes.DARK}
            symbol={tradingviewSymbol}
            onReady={() => setTvReady(true)}
            interval={getInterval()}
            lines={chartLines}
            onLineMove={handleLineMove}
            setCurrentTokenPrice={setCurrentTokenPrice}
          />
        </ErrorBoundary>
      )}
    </Paper>
  );
};

export default DefiTradingViewChart;

const Loading = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 1,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: theme.palette.background.paper,
}));
