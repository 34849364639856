import React from "react";
import { List, ListItem, useTheme } from "@mui/material";

export const CampaignRules = () => {
  const theme = useTheme();

  return (
    <>
      <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary, fontSize: 14 }}>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Accumulate Spot and Derivatives (Perpetual and Inverse Contracts except 0 fee trading pairs) trading volume of
          at least $10,000 to qualify for the event.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          The TOP 12 traders will stand to win a share of the 20,000 USDT prize pool! The more you trade, the higher the
          prize pool you can unlock.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Users must register during the event to be eligible for rewards.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Users must complete Identity Verification Lv. 1 on Bybit to claim rewards.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Only trading volumes accumulated after registration for the event will be counted. Eligible trading volumes
          will be based on the total trading volume accumulated during the event period.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Users must reach a sum trading volume (including copy trading) of at least $10,000 to be eligible for rewards.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Registration is not available for sub accounts. Nevertheless, trading volumes from sub accounts will
          contribute to the overall trading volume calculation.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Please note that live rankings are subject to change until they are finalized at the end of the event.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          If two participants accumulate the same total trading volume, their final ranking will be determined by when
          their trading volumes are achieved.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          The ranking results are refreshed daily, with the most recent data available on the following day (T+1).
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Rewards will be sent out to eligible reward recipients within 14 days of ranking results.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Rewards will be distributed based on the users trading volumes and the final prize pool unlocked.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          All participating users must strictly adhere to the Bybit Terms of Service. Bybit reserves the right to
          disqualify any participants who engage in dishonest or abusive activities during the event, including
          bulk-account registrations to farm additional bonuses and any other activities in connection with unlawful,
          fraudulent or harmful purposes.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Bybit reserves the right to modify the terms of this event without notifying users in advance.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Bybit reserves the right to final interpretation of this event. If you have any questions, please contact our
          customer support.
        </ListItem>
      </List>
    </>
  );
};
