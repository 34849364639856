import React from "react";
import { Stack } from "@mui/material";
import referralProgramBg from "../../../assets/svg/referral-program-bg.svg";
import { RootState } from "@src/store/types";
import { useAppSelector } from "@src/store/hooks";
import { ReferralInfo, WelcomeReferralProgram } from "@src/pages/referral-program";
import { usePermissions } from "@src/hooks/use-permissions";

export const ReferralProgram = () => {
  const { is_referrer, referrer_allowed } = useAppSelector((state: RootState) => state.user);
  const permissions = usePermissions();

  if (!permissions.trade) return null;

  return (
    <Stack
      flexGrow={1}
      sx={{
        background: `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url('${referralProgramBg}') right bottom no-repeat, linear-gradient(360deg, #031116 11.48%, #232C2F 102.73%)`,
      }}
    >
      <Stack paddingTop={4} gap={3} flexGrow={1} direction="column" alignItems="center">
        {is_referrer && referrer_allowed ? <ReferralInfo /> : <WelcomeReferralProgram />}
      </Stack>
    </Stack>
  );
};
