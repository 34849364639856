import React, { forwardRef, useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  OutlinedInput,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller } from "react-hook-form";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SvgIconComponent } from "@mui/icons-material";
import { useCreatePasswordForm, SignUpStep } from "../index";
import { FormInputLabel } from "../../layout/form-input-label";
import { AuthCard } from "../../layout/auth-card";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { muiTheme } from "@src/components/theme/mui-theme";
import { AccountTypes } from "@src/pages/auth/onboarding.slice";
import { PasswordStrength, strengthColor } from "@src/utils/password-strength";
import { useAppSelector } from "@src/store/hooks";

type VerificationPasswordProps = {
  setStep: (step: SignUpStep) => void;
  emailURL: string;
  loading: boolean;
  defaultValues: {
    currentEmail: string;
    password: string;
  };
  onSubmit: (username: string, password: string) => void;
};

export const VerificationPassword = ({
  setStep,
  emailURL,
  defaultValues,
  onSubmit,
  loading,
}: VerificationPasswordProps) => {
  const theme = useTheme();
  const accountType = useAppSelector((state) => state.onboarding.accountType);
  const selectedAccountType = useAppSelector((state) => state.onboarding.selectedAccountType);

  useEffect(() => {
    // @TODO: why do we need this?????
    if (accountType === AccountTypes.INDIVIDUAL && !selectedAccountType) setStep(SignUpStep.ACCOUNT_TYPE);
  }, [accountType, selectedAccountType]);

  const createPasswordForm = useCreatePasswordForm(accountType === AccountTypes.INDIVIDUAL, {
    password: defaultValues.password || "",
    confirmPassword: defaultValues.password || "",
    email: defaultValues.currentEmail || emailURL || "",
  });

  useEffect(() => {
    if (accountType === AccountTypes.INDIVIDUAL) {
      createPasswordForm.setValue("email", emailURL || defaultValues.currentEmail || "");
    }
  }, []);

  const password = createPasswordForm.watch("password");
  const confirmPassword = createPasswordForm.watch("confirmPassword");
  const email = createPasswordForm.watch("email");
  const [strength, setStrength] = useState<PasswordStrength>(PasswordStrength.EMPTY);
  const [passwordsVisible, setPasswordsVisible] = useState(true);

  const errorMismatchPasswords = confirmPassword !== password && !!confirmPassword.length;

  // 6 strengths conditions
  const containLowerCase = /[a-z]/.test(password) ? 1 : 0;
  const containUpperCase = /[A-Z]/.test(password) ? 1 : 0;
  const containNumber = /[0-9]/.test(password) ? 1 : 0;
  const containSpecialCharacter = /\W/.test(password) ? 1 : 0;
  const isLongForMedium = password.length > 5 ? 1 : 0;
  const isLongForHigh = password.length > 9 ? 1 : 0;

  const strengthConditions =
    containLowerCase + containUpperCase + containNumber + containSpecialCharacter + isLongForMedium + isLongForHigh;

  useEffect(() => {
    if (strengthConditions === 0) {
      setStrength(PasswordStrength.EMPTY);
    } else if (strengthConditions > 0 && strengthConditions <= 2) {
      setStrength(PasswordStrength.WEAK);
    } else if (strengthConditions > 2 && strengthConditions <= 5) {
      setStrength(PasswordStrength.MEDIUM);
    } else {
      setStrength(PasswordStrength.HIGH); //matching the 6 conditions
    }
  }, [password]);

  const onCreatePassword = async () => {
    if (!email || !password) return false;

    onSubmit(email, password);
  };

  return (
    <AuthCard>
      <Grid container direction="column" alignItems="center" spacing={3} paddingTop={5}>
        {accountType === AccountTypes.INDIVIDUAL && (
          <Grid item width="100%">
            <Controller
              name="email"
              control={createPasswordForm.control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormControl sx={{ width: "100%" }} size="small" variant="outlined">
                  <FormInputLabel fieldstate={fieldState}>Email*</FormInputLabel>
                  <OutlinedInput {...field} error={!!fieldState.error} type="text" label="Email" />
                  {fieldState.error ? (
                    <Typography sx={{ fontSize: 13 }} color={muiTheme.palette.error.main}>
                      {fieldState.error?.message || "Email is required"}
                    </Typography>
                  ) : null}
                </FormControl>
              )}
            />
          </Grid>
        )}
        <Grid item width="100%">
          <Controller
            name="password"
            control={createPasswordForm.control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%" }} size="small" variant="outlined">
                <FormInputLabel fieldstate={fieldState}>Enter Password</FormInputLabel>
                <OutlinedInput
                  {...field}
                  type={passwordsVisible ? "password" : "text"}
                  error={!!fieldState.error || errorMismatchPasswords}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setPasswordsVisible(!passwordsVisible)}
                        edge="end"
                      >
                        {getStyledIcon(passwordsVisible ? VisibilityIcon : VisibilityOffIcon)}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Enter Password"
                />
                {fieldState.error ? (
                  <Typography sx={{ fontSize: 13 }} color={muiTheme.palette.error.main}>
                    {fieldState.error?.message || "The password should have at least 10 characters"}
                  </Typography>
                ) : null}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item width="100%">
          <Controller
            name="confirmPassword"
            control={createPasswordForm.control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <FormControl sx={{ width: "100%" }} size="small" variant="outlined">
                <FormInputLabel fieldstate={fieldState}>Confirm Password</FormInputLabel>
                <OutlinedInput
                  {...field}
                  type={passwordsVisible ? "password" : "text"}
                  error={!!fieldState.error || errorMismatchPasswords}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setPasswordsVisible(!passwordsVisible)}
                        edge="end"
                      >
                        {getStyledIcon(passwordsVisible ? VisibilityIcon : VisibilityOffIcon)}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm Password"
                />
                <Typography sx={{ fontSize: 13 }} color={muiTheme.palette.error.main}>
                  {fieldState.error
                    ? fieldState.error?.message || "The password should have at least 10 characters"
                    : !fieldState.error && errorMismatchPasswords
                    ? "Your passwords do not match."
                    : null}
                </Typography>
              </FormControl>
            )}
          />
          <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between">
            <Typography sx={{ fontSize: 14, paddingTop: 2 }} color="text.secondary">
              Password strength
            </Typography>
            {/* Typography = weak / medium / high */}
            <Typography sx={{ fontSize: 14, paddingTop: 2 }} color={strengthColor(strength, theme)}>
              {strength !== PasswordStrength.EMPTY
                ? Object.keys(PasswordStrength)[Object.values(PasswordStrength).indexOf(strength)].toLowerCase()
                : null}
            </Typography>
          </Grid>
          <Grid item width="100%">
            <StyledLinearProgress variant="determinate" valueBuffer={200} value={strength} strength={strength} />
          </Grid>
          <Typography sx={{ fontSize: 14, paddingTop: 2 }} color="text.secondary">
            Must contain:
          </Typography>
          <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" paddingY={0.5}>
            <Typography sx={{ fontSize: 14 }} color="white">
              At least 10 characters
            </Typography>
            {getStyledIcon(CheckCircleIcon, password.length >= 10)}
          </Grid>
          <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" paddingY={0.5}>
            <Typography sx={{ fontSize: 14 }} color="white">
              Lower case
            </Typography>
            {getStyledIcon(CheckCircleIcon, /[a-z]/.test(password))}
          </Grid>
          <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" paddingY={0.5}>
            <Typography sx={{ fontSize: 14 }} color="white">
              Upper case
            </Typography>
            {getStyledIcon(CheckCircleIcon, /[A-Z]/.test(password))}
          </Grid>
          <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" paddingY={0.5}>
            <Typography sx={{ fontSize: 14 }} color="white">
              Number
            </Typography>
            {getStyledIcon(CheckCircleIcon, /[0-9]/.test(password))}
          </Grid>
          <Grid item width="100%" display="flex" flexDirection="row" justifyContent="space-between" paddingY={0.5}>
            <Typography sx={{ fontSize: 14 }} color="white">
              Special characters
            </Typography>
            {getStyledIcon(CheckCircleIcon, /\W/.test(password))}
          </Grid>
        </Grid>
        <Grid item marginY="30px" width="100%" display="flex" flexDirection="row">
          {accountType === AccountTypes.INDIVIDUAL && (
            <AnbotoButton
              tabIndex={1}
              sx={{ marginRight: "5px", width: "15%", "&:focus": { outline: 1 } }}
              size="large"
              variant="outlined"
              type="submit"
              onClick={() => {
                createPasswordForm.reset();
                setStep(SignUpStep.TRADER_INFO);
              }}
              disabled={loading}
            >
              <ArrowBackIcon />
            </AnbotoButton>
          )}
          <AnbotoButton
            tabIndex={1}
            sx={{
              marginLeft: "5px",
              width: accountType === AccountTypes.INDIVIDUAL ? "80%" : "100%",
              "&:focus": { outline: 1 },
            }}
            size="large"
            variant="contained"
            loading={loading}
            type="submit"
            onClick={createPasswordForm.handleSubmit(onCreatePassword)}
          >
            Next
          </AnbotoButton>
        </Grid>
      </Grid>
    </AuthCard>
  );
};

type StyledLinearProgressType = LinearProgressProps & { strength: PasswordStrength };

export const StyledLinearProgress = forwardRef((props: StyledLinearProgressType, ref) => {
  const Bar = styled(LinearProgress)<StyledLinearProgressType>(({ strength, theme }) => ({
    height: 4,
    color: muiTheme.custom.colors.secondary,
    [`& .${linearProgressClasses.dashed}`]: {
      display: "none",
    },
    width: "100%",
    "& .MuiLinearProgress-bar1Determinate": {
      backgroundColor: strengthColor(strength, theme),
    },
  }));

  return <Bar ref={ref} value={props.strength} {...props} />;
});

StyledLinearProgress.displayName = "StyledLinearProgress";

export const getStyledIcon = (icon: SvgIconComponent, isValid?: boolean, size = 24) => {
  const Icon = styled(icon)(() => ({
    color: !isValid ? muiTheme.custom.text.dim : muiTheme.custom.colors.secondary,
  }));

  return <Icon sx={{ fontSize: size }} />;
};
