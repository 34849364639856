import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { PrizePool as TPrizePool } from "../types";
import DashboardDataCardHeader from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/dashboard-data-card-header";

export const PrizePool = ({ data }: { data: TPrizePool }) => {
  return (
    <>
      <DashboardDataCardHeader fontWeight={500} fontSize={16} header="Prize Pool" />

      <TableContainer>
        <Table
          sx={{
            borderCollapse: "separate",
            borderSpacing: "0px 8px",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow
              sx={{
                "&>th": {
                  border: 0,
                  fontSize: 12,
                  color: "#8A9296",
                  fontWeight: 400,
                  padding: 1,
                  paddingBottom: 0,
                },
                "&>th:last-child": { paddingRight: 0 },
                "&>th:first-child": { paddingLeft: 0 },
              }}
            >
              <TableCell>Min campaign volume requirement</TableCell>
              <TableCell align="right">Prize Pool</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.map((prize) => (
              <TableRow
                key={prize.volume}
                sx={{
                  background: "#192022",
                  "&>td": {
                    border: 0,
                    fontWeight: 500,
                    fontSize: 14,
                    marginBottom: 1,
                    color: "#B0BCC2",
                    padding: 1,
                  },
                }}
              >
                <TableCell align="left">{prize.volume}</TableCell>
                <TableCell align="right">{prize.prizePool}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
