import React from "react";
import { Box, DialogProps, Slide, Stack } from "@mui/material";

type DialogCard = DialogProps & {
  isAdvancedSettingsCard?: boolean;
  withOverlay?: boolean;
  onOverlayClick?: () => void;
};

type DialogBodyProps = DialogCard;

const DialogBody = ({ isAdvancedSettingsCard, open, children, sx }: React.PropsWithChildren<DialogBodyProps>) => (
  <Slide direction="up" in={open} mountOnEnter={false} unmountOnExit={false} onClick={(e) => e.stopPropagation()}>
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "#031116",
        height: isAdvancedSettingsCard ? "88%" : "100%",
        zIndex: 2,
        ...sx,
      }}
    >
      <Stack height="100%">{children}</Stack>
    </Box>
  </Slide>
);

export const CardDialog = ({ withOverlay, ...props }: DialogCard) =>
  !props.open ? null : withOverlay ? (
    <Box
      onClick={() => props.onOverlayClick && props.onOverlayClick()}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        backgroundColor: "rgba(3, 17, 22, 0.7)",
        height: "100%",
        zIndex: 3,
      }}
    >
      <DialogBody {...props} />
    </Box>
  ) : (
    <DialogBody {...props} />
  );
