import React, { forwardRef } from "react";
import { MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { ClipSizeType } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

const CLIP_SIZE_TYPE: ClipSizeType[] = [
  ClipSizeType.AUTOMATIC,
  ClipSizeType.ABSOLUTE,
  ClipSizeType.PERCENTAGE,
  ClipSizeType.NB_OF_CHILD_ORDERS,
];

export const CLIP_SIZE_TYPE_NAME: Record<ClipSizeType, string> = {
  [ClipSizeType.AUTOMATIC]: "Automatic",
  [ClipSizeType.ABSOLUTE]: "Absolute",
  [ClipSizeType.PERCENTAGE]: "Percentage",
  [ClipSizeType.NB_OF_CHILD_ORDERS]: "Nb of child orders",
};

export const ClipSizeTypeSelect = forwardRef((props: AnbotoTextFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    inputRef={ref}
    select
    label={
      <Stack direction="row" gap={1} alignItems="center">
        <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
          {props.label || "Clip Size"}
        </Typography>
        <Tooltip title="Clip size refers to the size of the child order. Automatic clip size creates digestible chunks based on historical data of trade volumes. You can manually control the clip size by either choosing “Percentage” and “Absolute”.">
          <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
        </Tooltip>
      </Stack>
    }
    size="small"
    fullWidth
  >
    {CLIP_SIZE_TYPE.map((type) => (
      <MenuItem key={type} value={type}>
        {CLIP_SIZE_TYPE_NAME[type]}
      </MenuItem>
    ))}
  </AnbotoTextField>
));

ClipSizeTypeSelect.displayName = "ClipSizeTypeSelect";
