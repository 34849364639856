import { WidgetName } from "../types";
import { CefiOrderFormWidget } from "./cefi-order-form-widget";
import { CefiSymbolChartWidget } from "./cefi-symbol-chart-widget";
import { CefiPriceImpactWidget } from "./cefi-price-impact-widget";
import { CefiOrdersTableFullWidget } from "./cefi-orders-table-full-widget";
import { CefiOrderbookWidget } from "./cefi-orderbook-widget";
import { WidgetBaseProps } from "./widget-base";
import { OtcOrderFormWidget } from "./otc-order-form-widget";
import { OtcOrdersTableFullWidget } from "./otc-orders-table-full-widget";
import { OtcSymbolChart } from "./otc-symbol-chart-widget";

export const COMPONENTS: Record<WidgetName, React.ExoticComponent<WidgetBaseProps>> = {
  [WidgetName.CefiOrderForm]: CefiOrderFormWidget,
  [WidgetName.CefiSymbolChart]: CefiSymbolChartWidget,
  [WidgetName.CefiPriceImpact]: CefiPriceImpactWidget,
  [WidgetName.CefiOrdersTableFull]: CefiOrdersTableFullWidget,
  [WidgetName.CefiOrderbook]: CefiOrderbookWidget,

  [WidgetName.DefiOrderForm]: CefiOrderFormWidget,
  [WidgetName.DefiSymbolChart]: CefiSymbolChartWidget,
  [WidgetName.DefiOrdersTableFull]: CefiPriceImpactWidget,

  [WidgetName.OtcOrderForm]: OtcOrderFormWidget,
  [WidgetName.OtcOrdersTableFull]: OtcOrdersTableFullWidget,
  [WidgetName.OtcSymbolChart]: OtcSymbolChart,
};
