import React, { forwardRef } from "react";
import { MenuItem, Stack, Typography } from "@mui/material";
import { ExtendDurationType } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

const EXTEND_DURATION_TYPE: ExtendDurationType[] = [ExtendDurationType.YES, ExtendDurationType.NO];

export const EXTEND_DURATION_NAME: Record<ExtendDurationType, string> = {
  [ExtendDurationType.YES]: "Yes",
  [ExtendDurationType.NO]: "No",
};

export const ExtendDurationSelect = forwardRef((props: AnbotoTextFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    inputRef={ref}
    select
    label={
      <Stack direction="row" gap={1} alignItems="center">
        <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
          Extend duratoon
        </Typography>
      </Stack>
    }
    size="small"
    fullWidth
  >
    {EXTEND_DURATION_TYPE.map((type) => (
      <MenuItem key={type} value={type}>
        {EXTEND_DURATION_NAME[type]}
      </MenuItem>
    ))}
  </AnbotoTextField>
));

ExtendDurationSelect.displayName = "ExtendDurationSelect";
