import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { geckoTerminalApi } from "@src/store/apis/geckoterminal-api";
import { GECKOTERMINAL_CHAIN_IDS } from "@src/store/apis/geckoterminal-api/constants";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import { DefiTransaction } from "@src/store/apis/geckoterminal-api/types";
import AnbotoLoader from "@src/components/anboto-loader/anboto-loader";
import { getColumns } from "@src/pages/defi/token-info/components/transactions-table/get-columns";
import { AnbotoTable } from "@src/components/anboto-table/anboto-table";

type TransactionsTableProps = {
  poolAddress: string;
  poolFromTokenSymbol: string | undefined;
};

const TransactionsTable = ({ poolAddress, poolFromTokenSymbol }: TransactionsTableProps) => {
  const orderForm = useDefiOrderFormContext();
  const chainId = orderForm.getValues("chainId");

  const network = GECKOTERMINAL_CHAIN_IDS[chainId];
  const { data, isFetching, isError, refetch } = geckoTerminalApi.useGetLastTradesQuery(
    { network, poolAddress },
    { skip: !poolAddress }
  );

  const tableData = useMemo(() => {
    const transactionsList = data?.data?.map((transacton) => transacton.attributes) || [];
    const uniqueObjectsArray = transactionsList.reduce((accumulator, current) => {
      const isUnique = !accumulator.some((obj) => obj.tx_hash === current.tx_hash);
      if (isUnique) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    return uniqueObjectsArray;
  }, [isFetching]);
  const columns = useMemo<Array<ColumnDef<DefiTransaction, any>>>(
    () => getColumns({ chainId, symbol: poolFromTokenSymbol }),
    [isFetching]
  );

  return (
    <Stack>
      {isFetching ? (
        <Stack direction="column" justifyContent="center" minHeight={300}>
          <AnbotoLoader />
        </Stack>
      ) : (
        <Stack maxHeight={430} position="relative" overflow="auto">
          <AnbotoTable<DefiTransaction>
            containerSx={{ minWidth: 435 }}
            columns={columns}
            data={tableData}
            loading={isFetching}
            error={!!isError}
            refetch={refetch}
            noDataText="You don't have any records"
            getRowId={(order: DefiTransaction) => order.tx_hash}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default TransactionsTable;
