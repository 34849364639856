import React from "react";
import { Column } from "react-table";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AllParentOrder, ParentOrder } from "@src/store/apis/anbotoApi/types";
import { CHAIN_NAME } from "@src/pages/defi/constants";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { OrdersTableAccount } from "@src/components/orders-table-account";
import { CELL_FONT_SIZE } from "@src/components/orders-table";
import { OrderTableSide } from "@src/components";
import { formatUsd } from "@src/utils/format";
import { getDurationString } from "@src/components/post-trade-analysis/helpers";

export const getColumns = ({ renderCell }): Column<ParentOrder>[] => [
  {
    id: "expand",
    width: 30,
    // eslint-disable-next-line react/prop-types
    Cell: renderCell((row) => {
      return (
        // eslint-disable-next-line react/prop-types
        <span {...row.getToggleRowExpandedProps()}>
          {/* eslint-disable-next-line react/prop-types */}
          {row.isExpanded ? (
            <ArrowDropUpIcon sx={{ color: (theme) => theme.palette.text.disabled }} />
          ) : (
            <ArrowDropDownIcon sx={{ color: (theme) => theme.palette.text.disabled }} />
          )}
        </span>
      );
    }),
  },
  {
    Header: "Order Id",
    width: 150,
    Cell: renderCell(({ original }: { original: AllParentOrder }) => original.order_id),
  },
  {
    Header: "Symbol",
    width: 200,
    Cell: renderCell(({ original }: { original: AllParentOrder }) => {
      let symbol = "";
      if (original.order_type === "defi") {
        const network = original.network_id ? CHAIN_NAME[original.network_id] : "";
        symbol = network ? `${original.symbol} - ${network} - ${original.exchange}` : original.symbol;
      } else {
        symbol = original.asset_class
          ? `${original.symbol} - ${getExchangeNameLabel(CEFI_EXCHANGE_NAME[original.exchange])} - ${
              original.asset_class
            }`
          : "-";
      }
      return symbol;
    }),
  },
  {
    Header: "Subaccount",
    width: 90,
    Cell: renderCell(({ original }: { original: AllParentOrder }) => (
      <OrdersTableAccount label={original?.subaccount || "Main"} fontSize={CELL_FONT_SIZE} />
    )),
  },
  {
    Header: "Side",
    width: 60,
    Cell: renderCell(({ original }: { original: AllParentOrder }) => <OrderTableSide side={original?.side} />),
  },
  {
    Header: "Strategy",
    width: 70,
    Cell: renderCell(({ original }: { original: AllParentOrder }) => original?.strategy?.toUpperCase()),
  },
  {
    Header: "Quantity",
    width: 60,
    Cell: renderCell(({ original }: { original: AllParentOrder }) => original.quantity),
  },
  {
    Header: "Filled",
    width: 60,
    Cell: renderCell(({ original }: { original: AllParentOrder }) => original.last_status?.filled_quantity || 0),
  },
  {
    Header: "Amount $",
    width: 110,
    Cell: renderCell(({ original }: { original: AllParentOrder }) =>
      formatUsd((original.last_status?.filled_quantity || 0) * (original.last_status?.last_price || 0))
    ),
  },
  {
    width: 80,
    Header: "Duration",
    Cell: renderCell(
      ({ original }: { original: AllParentOrder }) =>
        `${getDurationString(original?.start_time || 0, original?.end_time || 0)} `
    ),
  },
];
