import { WidgetConfig, WidgetName } from "../../types";

export const CefiOrdersTableFullWidgetConfig = {
  name: WidgetName.CefiOrdersTableFull,
  layout: {
    xxl: { x: 0, y: Infinity, w: 18, h: 20, minW: 8, minH: 16 },
    xl: { x: 0, y: Infinity, w: 18, h: 20, minW: 8, minH: 16 },
    lg: { x: 0, y: Infinity, w: 24, h: 20, minW: 20, minH: 16 },
    md: { x: 0, y: Infinity, w: 24, h: 20, minW: 20, minH: 16 },
    sm: { x: 0, y: Infinity, w: 24, h: 20, minW: 24, minH: 16 },
    xs: { x: 0, y: Infinity, w: 24, h: 20, minW: 24, minH: 16 },
  },
  options: {
    title: "",
    maxNumber: 1,
    removable: true,
    noHeader: true,
  },
} as WidgetConfig;
