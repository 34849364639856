import React from "react";
import { Link, List, ListItem, Typography, useTheme } from "@mui/material";

export const TermsAndConditions = () => {
  const theme = useTheme();
  return (
    <>
      <Typography fontSize={14} color={theme.palette.text.secondary}>
        All participants of the campaign accept that:
      </Typography>
      <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary, fontSize: 14 }}>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          This event is open only to Anboto Labs users. Please register using {`Anboto Labs's`}{" "}
          <Link href="https://trade.anboto.xyz/auth/register_via_invite_link?referral_code=bitget">referral link</Link>{" "}
          to participate in this campaign.
        </ListItem>

        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          To receive any reward from the competition, users must follow{" "}
          <Link href="https://twitter.com/anboto_labs" target="_blank">
            Anboto Labs on Twitter
          </Link>
          .
        </ListItem>

        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          {`To qualify for the grand prize "iPhone 15", a minimum trading volume of 1,000,000 USDT is required.`}
        </ListItem>

        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          {`The term "iPhone 15" refers to the iPhone 15 128GB model, valued at 799 USDT.`}
        </ListItem>

        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Due to potential regional shipping complications, Bitget reserves the right to substitute the prize with an
          equivalent value in USDT.
        </ListItem>

        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Prizes will be distributed within seven working days after the end of the event.
        </ListItem>

        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          For more information about the trading bonus, visit:{" "}
          <Link target="_blank" href="https://www.bitget.com/it/academy/what-is-bitget-trading-bonus">
            Bitget Trading Bonus
          </Link>
          .
        </ListItem>

        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Bitget reserves the right to change or revise the terms of this event, or cancel it at any time and for any
          reason without notice in its sole discretion.
        </ListItem>

        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Bitget reserves the right to disqualify users engaging in malicious activities during the event. Such
          activities include, but are not limited to, wash trading and using multiple accounts. It is important to note
          that using the same device or undergoing KYC verification under the same identity will be regarded as a single
          user.
        </ListItem>
      </List>
    </>
  );
};
