import React from "react";
import {
  Box,
  CircularProgress,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CHAIN_CONFIG, CHAIN_LOGO } from "../defi/constants";
import { feeManagerApi } from "../settings/fee-manager/api";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { NoTableData } from "@src/components/no-table-data";

export const FeeManagerBalances = () => {
  const feeManagerContracts = feeManagerApi.useGetFeeManagerContractsQuery();
  const feeManagerBalancesQuery = anbotoApi.useGetFeeManagerBalancesQuery();
  const feeManagerAccountsBalancesQuery = anbotoApi.useGetFeeManagerAccountsBalancesQuery();

  return (
    <Stack gap={0.5} flexGrow={1}>
      <Paper>
        <Box>
          {feeManagerBalancesQuery.isLoading && feeManagerContracts.isLoading ? (
            <Stack height={300} alignItems="center" justifyContent="center">
              <CircularProgress />
            </Stack>
          ) : feeManagerBalancesQuery.isError && feeManagerContracts.isError ? (
            <Box display="flex" alignItems="center" flexDirection="column" gap={2} justifyContent="center" height={238}>
              <NoTableData />
            </Box>
          ) : (
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Box>
                <Box sx={{ pt: 4 }}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        width: 300,
                        marginBottom: 10,
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>blockchain</TableCell>
                          <TableCell align="right">Balance USD</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {feeManagerBalancesQuery.data && feeManagerContracts.data ? (
                          feeManagerBalancesQuery.data.chains.map((row, index) => {
                            const contractAddress = feeManagerContracts.data.find(
                              (contract) => contract.network_id === row.network_id
                            ).contract_address;

                            return (
                              <TableRow
                                key={index}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 }, border: 0, elevation: 0 }}
                              >
                                <TableCell component="th" scope="row">
                                  <Link
                                    sx={{
                                      cursor: "pointer",
                                      color: "white",
                                      textDecoration: "underline",
                                      fontSize: 14,
                                    }}
                                  >
                                    <a
                                      style={{ color: "white" }}
                                      target="_blank"
                                      href={
                                        CHAIN_CONFIG[row.network_id].blockExplorerUrls + "/address/" + contractAddress
                                      }
                                      rel="noreferrer"
                                    >
                                      {row.network_name}
                                    </a>
                                  </Link>
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                  {Math.round(row.balance_usd)}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                                gap={2}
                                justifyContent="center"
                                height={238}
                              >
                                <NoTableData />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Stack>
          )}
        </Box>
        <Box>
          {feeManagerAccountsBalancesQuery.isLoading ? (
            <Stack height={300} alignItems="center" justifyContent="center">
              <CircularProgress />
            </Stack>
          ) : feeManagerAccountsBalancesQuery.isError ? (
            <Box display="flex" alignItems="center" flexDirection="column" gap={2} justifyContent="center" height={238}>
              <NoTableData />
            </Box>
          ) : (
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Box>
                <Box sx={{ pt: 4 }}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        width: 600,
                        marginBottom: 10,
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>UUID</TableCell>
                          <TableCell>Account</TableCell>
                          <TableCell align="right" sx={{ width: 130 }}>
                            Balances USD
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {feeManagerAccountsBalancesQuery.data ? (
                          feeManagerAccountsBalancesQuery.data
                            .filter((row) => row.fee_manager_balances)
                            .map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 }, border: 0, elevation: 0 }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.uuid}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                  <Stack gap={1}>
                                    {/* any has to be removed by typing the response of the api */}
                                    {Object.entries(row.fee_manager_balances).map(([chain, balance]: [string, any]) => {
                                      return (
                                        <Stack direction="row" gap={1}>
                                          {chain && CHAIN_LOGO[parseInt(chain)] && (
                                            <img alt={chain} src={CHAIN_LOGO[parseInt(chain)]} height={20} />
                                          )}
                                          <Typography noWrap>
                                            {Math.round(parseFloat(balance) * 100) / 100} $
                                          </Typography>
                                        </Stack>
                                      );
                                    })}
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                                gap={2}
                                justifyContent="center"
                                height={238}
                              >
                                <NoTableData />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Stack>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};
