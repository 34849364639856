import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { anbotoApi } from "@src/store/apis/anbotoApi/index";
import {
  Allowance,
  GetDefiParentOrdersParams,
  GetPreTradeAnalysisDefiAwareParams,
  GetSwapQuoteWithPreTradeAnalysisDefiAwareParams,
  GetSwapQuoteWithPreTradeAnalysisDefiAwareResult,
  OrdersCurrentTab,
  PaginationResult,
  ParentDefiOrder,
  ParentOrderMsgCode,
  ParentOrderStatuses,
  PreTradeAnalysisDefiAwareResult,
  ValidatedData,
} from "@src/store/apis/anbotoApi/types";
import { DefaultStatusFilter } from "@src/components/orders-table-filters/types";
import { ChainId } from "@src/pages/defi/types";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { getAccountSubscription } from "@src/subscriptions";

export const anbotoDefiV3Api = anbotoApi.injectEndpoints({
  endpoints: (builder) => ({
    getTokenAllowance: builder.query<
      { allowance_data: Allowance; validated_data: ValidatedData },
      { chainId: ChainId; fromTokenAddress: string; account: string }
    >({
      query: ({ chainId, fromTokenAddress, account }) => ({
        url: "/v3/orders/token_allowance/",
        params: {
          network_id: chainId,
          token_address: fromTokenAddress,
          maker_address: account,
        },
      }),
    }),
    preValidateOrder: builder.mutation<ParentDefiOrder, ParentDefiOrder>({
      query: (parentOrder) => {
        return {
          method: "POST",
          url: `/v3/orders/add_defi_validate/`,
          body: parentOrder,
        };
      },
    }),
    submitParentDefiOrder: builder.mutation<ParentDefiOrder, ParentDefiOrder>({
      invalidatesTags: ["PARENT_DEFI_ORDERS"],
      query: (parentOrder) => {
        return {
          method: "POST",
          url: `/v3/orders/add_defi/`,
          body: parentOrder,
        };
      },
    }),
    getParentDefiOrders: builder.query<ParentDefiOrder[], GetDefiParentOrdersParams>({
      providesTags: (result) =>
        result
          ? result.map(({ order_id }) => ({
              type: "PARENT_DEFI_ORDERS",
              id: order_id,
            }))
          : ["PARENT_DEFI_ORDERS"],
      query: ({ accountId, currentTab, exchange_list_ids, period, status, limit }) => ({
        url: `/v3/orders/view_all_defi/`,
        params: {
          account_uuid: accountId,
          current_tab: currentTab,
          date_time_limit: period,
          limit,
          // 0x,1inch,paraswap,openocean,odos
          exchange_list_ids,
          ...(status === DefaultStatusFilter.All ? {} : { status: status }),
        },
      }),
      transformResponse: ({ results }: PaginationResult<ParentDefiOrder>) => {
        return results?.map((order) => {
          if (order.start_time) order.start_time = order.start_time * 1000;
          if (order.end_time) order.end_time = order.end_time * 1000;
          if (order.expiration_time) order.expiration_time = order.expiration_time * 1000;

          return order;
        });
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, updateCachedData, dispatch }) {
        const refetching = { inProgress: false };

        await cacheDataLoaded;

        const accountSub = getAccountSubscription(arg.accountId);

        const onMessage = ({ code, data }) => {
          console.log("defi", { code, data });
          if (!data?.order_id) return false;

          updateCachedData((draft: MaybeDrafted<ParentDefiOrder[]>) => {
            try {
              const defiOrder = draft.find((order) => {
                return order.order_id === data.order_id;
              });

              if (defiOrder) {
                console.log("target order", data);
                const {
                  average_price,
                  filled_quantity,
                  last_price,
                  last_quantity,
                  leaves_quantity,
                  status,
                  error_code,
                } = data;

                defiOrder.last_status = {
                  ...(defiOrder.last_status || {}),
                  average_price,
                  ...(filled_quantity ? { filled_quantity } : {}),
                  ...(leaves_quantity ? { leaves_quantity } : {}),
                  last_price,
                  last_quantity,
                  status,
                  error_code,
                };
                // reset blockchainApi tokenAccountBalance query
                dispatch(blockchainApi.util.resetApiState());
              } else {
                const isCefiOrder = !data?.is_defi;
                const skipRefetchTab =
                  arg?.currentTab === OrdersCurrentTab.History &&
                  ![
                    ParentOrderStatuses.CANCELLED,
                    ParentOrderStatuses.PENDING_CANCEL,
                    ParentOrderStatuses.REJECTED,
                  ].includes(data.status?.toLowerCase() as ParentOrderStatuses);
                const skipRefetch = skipRefetchTab || isCefiOrder;

                // @TODO: implement fetching single data by id and add it to the list (when api ready)
                if (code === ParentOrderMsgCode.CREATE && !refetching.inProgress && !skipRefetch) {
                  refetching.inProgress = true;
                  dispatch(
                    anbotoDefiV3Api.endpoints.getParentDefiOrders.initiate(arg, {
                      subscribe: false,
                      forceRefetch: true,
                    })
                  ).then(() =>
                    setTimeout(() => {
                      refetching.inProgress = false;
                    }, 3000)
                  );
                }
              }
            } catch (err) {
              console.log("defi order web socket error", err);
            }
          });
        };

        accountSub?.listenAll(onMessage);

        await cacheEntryRemoved;

        accountSub?.stopListenAll(onMessage);
      },
    }),
    getPreTradeAnalysisDefiAware: builder.query<PreTradeAnalysisDefiAwareResult, GetPreTradeAnalysisDefiAwareParams>({
      query: ({
        symbol,
        quantity,
        chainId,
        clip_size_type,
        durationSeconds,
        clip_size_val,
        strategy,
        child_slippage,
      }) => ({
        url: "/v3/orders/pre_trade_analysis_defi_aware/",
        params: {
          symbol,
          quantity,
          network_id: chainId,
          clip_size_type,
          clip_size_val,
          strategy,
          child_slippage,
          duration_seconds: durationSeconds,
        },
      }),
    }),
    getSwapQuoteWithPreTradeAnalysisDefiAware: builder.query<
      GetSwapQuoteWithPreTradeAnalysisDefiAwareResult,
      GetSwapQuoteWithPreTradeAnalysisDefiAwareParams
    >({
      query: ({
        chainId,
        sellToken,
        sellTokenDecimals,
        buyToken,
        buyTokenDecimals,
        sellAmount,
        childSlippage,
        clip_size_type,
        clip_size_val,
        durationSeconds,
        strategy,
      }) => ({
        url: `/v3/orders/quote_with_pre_trade_analysis_defi_aware/`,
        params: {
          network_id: chainId,
          sell_token: sellToken,
          sell_token_decimals: sellTokenDecimals,
          buy_token: buyToken,
          buy_token_decimals: buyTokenDecimals,
          sell_amount: sellAmount,
          child_slippage: childSlippage,
          clip_size_type,
          clip_size_val,
          duration_seconds: durationSeconds,
          strategy,
        },
      }),
    }),
  }),
});

export const {
  useSubmitParentDefiOrderMutation,
  useGetParentDefiOrdersQuery,
  useLazyGetTokenAllowanceQuery,
  usePreValidateOrderMutation,
  useGetPreTradeAnalysisDefiAwareQuery,
  useGetSwapQuoteWithPreTradeAnalysisDefiAwareQuery,
  useLazyGetSwapQuoteWithPreTradeAnalysisDefiAwareQuery,
} = anbotoDefiV3Api;
