import { Duration } from "luxon";
import { LightChartsData } from "@src/store/apis/anbotoApi/types";

export const deleteDuplicateTimestampData = (arr: LightChartsData): LightChartsData => {
  return arr.reduce((acc: LightChartsData, cur) => {
    const existingItem = acc.find((item) => item.time === cur.time);

    if (!existingItem) {
      acc.push(cur);
    }

    return acc;
  }, []);
};

export const splitWithAverageDuplicateTimestampData = (arr: LightChartsData): LightChartsData => {
  return arr.reduce((acc: LightChartsData, cur) => {
    const existingItem = acc.find((item) => item.time === cur.time);

    if (existingItem) {
      existingItem.value = (existingItem.value + cur.value) / 2;
    } else {
      acc.push(cur);
    }

    return acc;
  }, []);
};

const MAX_N_CHART_POINTS = 600;
export const getTimeFrame = (startTime: number, endTime: number): string => {
  const timeDelta = endTime - startTime;
  if (timeDelta <= MAX_N_CHART_POINTS) {
    return "1s";
  } else if (timeDelta <= MAX_N_CHART_POINTS * 60) {
    return "1T";
  } else if (timeDelta <= MAX_N_CHART_POINTS * 60 * 15) {
    return "15T";
  } else {
    return "1h";
  }
};

export const formatBps = (number: number): string => {
  const currentFormattedString =
    number?.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }) || "0";
  return (currentFormattedString === "-0" ? "0" : currentFormattedString) + " bps";
};

export const getDurationString = (startTime: number, endTime: number): string => {
  if (!startTime || !endTime || startTime > endTime) {
    return "-";
  }

  const duration = Duration.fromMillis(endTime - startTime)
    .shiftTo("days", "hours", "minutes", "seconds")
    .toObject();

  const formattedDuration: string[] = [];
  const { days, hours, minutes } = duration;

  if (days && days > 0) {
    formattedDuration.push(`${days}d`);
  }
  if (hours && hours > 0) {
    formattedDuration.push(`${hours}h`);
  }
  if (minutes && minutes > 0) {
    formattedDuration.push(`${minutes}m`);
  }

  return formattedDuration.join(" ");
};
