import { BigNumber } from "bignumber.js";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { MIN_GAS_UNITS_PER_SLICE } from "@src/pages/defi/constants";
import { CHAIN_NATIVE_TOKENS } from "@src/constants/chain-native-tokens";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";

export interface UseGasAmountEstimationResult {
  averageGasCost: string;
  averageGasCostL2: string;
  isLoading: boolean;
}
// Formula to calculate optimism max_fee_absolute =
// l1_average_gas_cost * l1_max_gas_price (ethereum_gas_price) * l1_fee_scalar (equals to 1) + l2_average_gas_cost * l2_max_gas_price (optimism_gas_price)
export const useOrderFormGasAmountEstimation = (): UseGasAmountEstimationResult => {
  const orderForm = useDefiOrderFormContext();
  const chainId = orderForm.watch("chainId");
  const { data: gasAmountEstimation, isLoading } = anbotoApi.useGetGasAmountEstimationQuery({ chainId });
  const decimals = CHAIN_NATIVE_TOKENS.find((token) => token.chainId === chainId)?.decimals || 18;
  const averageGasCost = gasAmountEstimation?.data?.GAS?.average || gasAmountEstimation?.data?.GASL1?.average;
  const averageGasCostL2 = gasAmountEstimation?.data?.GASL2?.average;

  return {
    averageGasCost: averageGasCost
      ? new BigNumber(averageGasCost).multipliedBy(10 ** decimals).toString()
      : MIN_GAS_UNITS_PER_SLICE.toString(),
    averageGasCostL2: averageGasCostL2
      ? new BigNumber(averageGasCostL2).multipliedBy(10 ** decimals).toString()
      : MIN_GAS_UNITS_PER_SLICE.toString(),
    isLoading,
  };
};
