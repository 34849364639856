import React from "react";
import { IconButton, Modal, ModalProps, Stack, Typography, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AnbotoButton } from "../ui/AnbotoButton/AnbotoButton";

export const AnbotoModal = ({
  open,
  onClose,
  title,
  children,
  onSuccessButtonClick,
  onDismissButtonClick,
  successButtonLabel = "Submit",
  dismissButtonLabel = "Cancel",
  loading,
}: ModalProps & {
  title?: string;
  onSuccessButtonClick?: () => void;
  onDismissButtonClick?: () => void;
  successButtonLabel?: string;
  dismissButtonLabel?: string;
  loading?: boolean;
}) => {
  return (
    <Modal open={open} onClose={onClose} disableRestoreFocus={true} disableAutoFocus={true}>
      <Root boxShadow={24}>
        {title && (
          <Header>
            <Typography variant="body2" fontWeight="bold" fontSize={18}>
              {title}
            </Typography>

            <IconButton size="small" onClick={(e) => onClose && onClose(e, "escapeKeyDown")}>
              <CloseIcon sx={{ fontSize: 18, color: "#636B6F" }} />
            </IconButton>
          </Header>
        )}
        <Body>{children}</Body>
        {(onSuccessButtonClick || onDismissButtonClick) && (
          <Footer sx={{ justifyContent: onSuccessButtonClick && onDismissButtonClick ? "space-between" : "flex-end" }}>
            {onDismissButtonClick && (
              <AnbotoButton size="small" onClick={onDismissButtonClick} variant="outlined" sx={{ minWidth: 160 }}>
                {dismissButtonLabel}
              </AnbotoButton>
            )}
            {onSuccessButtonClick && (
              <AnbotoButton
                loading={loading}
                size="small"
                onClick={onSuccessButtonClick}
                variant="contained"
                sx={{ minWidth: 160 }}
              >
                {successButtonLabel}
              </AnbotoButton>
            )}
          </Footer>
        )}
      </Root>
    </Modal>
  );
};

const Root = styled(Stack)<{ width?: string }>(({ width }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width,
  background: "#232C2F",
}));

const Header = styled(Stack)(({ theme }) => ({
  background: "#192022",
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2),
  flexDirection: "row",
  justifyContent: "space-between",
  height: 48,
  alignItems: "center",
}));

const Body = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2.5),
}));

const Footer = styled(Stack)(({ theme }) => ({
  height: 60,
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  borderTop: "1px solid #3B4043",
  flexDirection: "row",
  alignItems: "center",
}));
