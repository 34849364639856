import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const queryFnFactory =
  <Args, Result>(
    promiseCreator: (args: Args, api: BaseQueryApi, extraOptions: any, baseQuery: any) => Promise<Result>
  ) =>
  (args: Args, api: BaseQueryApi, extraOptions: any, baseQuery: any) =>
    promiseCreator(args, api, extraOptions, baseQuery)
      .then((x) => ({ data: x }))
      .catch((x) => ({ error: x }));
