import * as React from "react";
import { MenuItem, styled, useTheme } from "@mui/material";
import { setWatchlistView, toggleWatchlist, toggleWatchlistAutoScroll } from "@src/store/slices/uiSettingsSlice";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { WatchlistView } from "@src/store/slices/watchlistSlice";
import { MenuItemSwitch } from "@src/components/menu-item-switch";

export const WatchlistMenu = () => {
  const dispatch = useAppDispatch();
  const view = useAppSelector((state) => state.uiSettings.watchlistView);
  const autoScroll = useAppSelector((state) => state.uiSettings.watchlistAutoScroll);
  const theme = useTheme();

  const handleMenuClick = (e) => {
    if (e.target.value === "hide") return dispatch(toggleWatchlist());

    dispatch(setWatchlistView(e.target.value));
  };

  const toggleAutoScroll = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(toggleWatchlistAutoScroll());
  };

  return (
    <Root
      select
      size="small"
      hiddenLabel
      value={view}
      onChange={handleMenuClick}
      InputProps={{
        sx: {
          backgroundColor: "transparent",
        },
      }}
    >
      <MenuItem key={WatchlistView.TOP_LIST} value={WatchlistView.TOP_LIST}>
        Top list
      </MenuItem>
      <MenuItem key={WatchlistView.FAVOURITES} value={WatchlistView.FAVOURITES}>
        Favorites
      </MenuItem>
      <MenuItemSwitch
        checked={autoScroll}
        label="Auto scroll"
        onClick={toggleAutoScroll}
        sx={{ paddingLeft: theme.spacing(1.75) }}
      />
      <StyledMenuItem key="hide" value="hide">
        Hide tickers bar
      </StyledMenuItem>
    </Root>
  );
};

const Root = styled(AnbotoTextField)(({ theme }) => ({
  minWidth: 100,
  "& fieldset": {
    borderColor: "transparent !important",
  },
  "& .MuiSelect-select": {
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
    paddingRight: "30px !important",
    paddingLeft: `${theme.spacing(1)} !important`,
    fontSize: 12,
    fontWeight: "bold",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  justifyContent: "center",
}));
