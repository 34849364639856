import React from "react";
import { CefiGroup, updateGroupData } from "../store/widgets.slice";
import { DEFAULT_OTC_SYMBOL, DEFAULT_SYMBOL } from "../constants";
import { SymbolSelect } from "@src/components/symbol-select";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { useCefiOrderFormDefaultValues } from "@src/pages/cefi/order-form/hooks/use-cefi-order-form-default-values";
import { MAIN_ACCOUNT_VALUE } from "@src/pages/portfolio/constant";

interface WidgetSymbolSelectProps {
  group: string;
  disabled?: boolean;
  otc?: boolean;
}

export const WidgetSymbolSelect = ({ group, disabled, otc }: WidgetSymbolSelectProps) => {
  const dispatch = useAppDispatch();
  const view = useAppSelector((state) => state.widgets.view) || "";
  const groupData = useAppSelector((state) => state.widgets.views[view]?.groups[group]) as CefiGroup;
  const defaultValues = useCefiOrderFormDefaultValues();
  const selectedSymbol = groupData?.symbol || (otc ? DEFAULT_OTC_SYMBOL : DEFAULT_SYMBOL);

  const handleSymbolChange = (symbol: OrderSymbol) => {
    dispatch(
      updateGroupData({
        group,
        data: {
          ...defaultValues,
          symbol,
          account: groupData.account || MAIN_ACCOUNT_VALUE,
          side: groupData.side,
          strategy: groupData.strategy,
          quantity: "",
          toQuantity: "",
        },
      })
    );
  };

  return (
    <SymbolSelect value={selectedSymbol} onChange={handleSymbolChange} variant="inline" disabled={disabled} otc={otc} />
  );
};
