import React, { useEffect } from "react";
import { Stack, styled, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { useDispatch, useSelector } from "react-redux";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import {
  AccountTypes,
  OnboardingSteps,
  setCurrentOnboardingStep,
  setSelectedAccountType,
} from "@src/pages/auth/onboarding.slice";
import { RootState } from "@src/store/types";

type AccountTypeProps = {
  onSubmit: (accountType: AccountTypes) => void;
};

const AccountType = ({ onSubmit }: AccountTypeProps) => {
  const selectedAccountType = useSelector((state: RootState) => state.onboarding.selectedAccountType);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentOnboardingStep(OnboardingSteps.ACCOUNT_ACTIVATE));
  }, []);

  return (
    <Stack flex={1} flexDirection="column" gap={10} alignItems="center" justifyContent="center">
      <Typography textAlign="center" fontSize={32} fontWeight={500}>
        Select account to onboard
      </Typography>
      <Stack flexDirection="row" gap={8} width={654}>
        <AccountTypeCard
          active={selectedAccountType === AccountTypes.INDIVIDUAL}
          onClick={() => dispatch(setSelectedAccountType(AccountTypes.INDIVIDUAL))}
        >
          <AccountTypeCardWrapper>
            <Typography fontSize={32} fontWeight={500} color="#8A9296">
              Individual account
            </Typography>

            <Stack flexDirection="column" gap={2}>
              <Stack flexDirection="row" justifyContent="space-between">
                <Typography fontSize={16} fontWeight={400}>
                  Create multi team account
                </Typography>
                <CancelIcon sx={{ color: "#C3462B", width: 20 }} />
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between">
                <Typography fontSize={16} fontWeight={400}>
                  Invite team member
                </Typography>
                <CancelIcon sx={{ color: "#C3462B", width: 20 }} />
              </Stack>
            </Stack>
            <AccountCircleIcon
              style={{
                position: "absolute",
                right: -34,
                top: 7,
                width: 210,
                height: 210,
                opacity: 0.05,
              }}
            />
          </AccountTypeCardWrapper>
        </AccountTypeCard>
        <AccountTypeCard
          active={selectedAccountType === AccountTypes.TEAM}
          onClick={() => dispatch(setSelectedAccountType(AccountTypes.TEAM))}
        >
          <AccountTypeCardWrapper>
            <Typography fontSize={32} fontWeight={500} color="#8A9296">
              Team account
            </Typography>

            <Stack flexDirection="column" gap={2}>
              <Stack flexDirection="row" justifyContent="space-between">
                <Typography fontSize={16} fontWeight={400}>
                  Create multi team account
                </Typography>
                <CheckCircleIcon sx={{ color: "#179B83", width: 20 }} />
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between">
                <Typography fontSize={16} fontWeight={400}>
                  Invite team member
                </Typography>
                <CheckCircleIcon sx={{ color: "#179B83", width: 20 }} />
              </Stack>
            </Stack>

            <SupervisedUserCircleIcon
              style={{
                position: "absolute",
                right: -34,
                top: 7,
                width: 210,
                height: 210,
                opacity: 0.05,
              }}
            />
          </AccountTypeCardWrapper>
        </AccountTypeCard>
      </Stack>
      <AnbotoButton
        tabIndex={1}
        disabled={!selectedAccountType}
        sx={{ "&:focus": { outline: 1 }, width: 320 }}
        size="large"
        variant="contained"
        fullWidth={true}
        type="submit"
        onClick={() => selectedAccountType && onSubmit(selectedAccountType)}
      >
        Next
      </AnbotoButton>
    </Stack>
  );
};

const AccountTypeCardWrapper = styled(Stack)({
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",
  textAlign: "left",
  padding: 32,
  width: 295,
  height: 310,
  borderRadius: 8,
  flexDirection: "column",
  justifyContent: "space-between",
  background: "linear-gradient(42.07deg, #031116 50.08%, #232C2F 100%)",
});

const AccountTypeCard = styled(Stack)<{ active?: boolean }>(({ theme, active }) => ({
  border: "2px solid transparent",
  borderRadius: 8,
  borderColor: active ? `${theme.custom.colors.primary}` : "transparent",
  "&:hover": {
    border: `2px solid ${theme.custom.colors.primary}`,
  },
}));

export default AccountType;
