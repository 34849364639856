import {
  BecomeReferrerParams,
  CodeAvailabilityResponse,
  CodeCreationParams,
  CodesListResponse,
  ReferTraderParams,
  ReferrerSummaryResponse,
} from "./types";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export const referralProgramApi = anbotoApi.injectEndpoints({
  endpoints: (build) => ({
    getRequestProgram: build.query<{ is_sent: boolean }, { team_uuid: string }>({
      query: ({ team_uuid }) => ({
        url: `/referral_program/request_program/${team_uuid}/`,
      }),
    }),
    getCodeAvailability: build.query<CodeAvailabilityResponse, { code: string }>({
      query: ({ code }) => ({
        url: `/referral_program/code/${code}/`,
      }),
    }),
    getCodesList: build.query<CodesListResponse, { team_uuid: string }>({
      query: ({ team_uuid }) => ({
        url: `/referral_program/code_list/${team_uuid}/`,
      }),
    }),
    getReferrerSummary: build.query<ReferrerSummaryResponse, { team_uuid: string }>({
      query: ({ team_uuid }) => ({
        url: `/referral_program/referrer_summary/${team_uuid}/`,
      }),
    }),
    createCode: build.mutation<{ message: string }, CodeCreationParams>({
      query: ({ body, team_uuid }) => ({
        method: "POST",
        url: `/referral_program/code/${team_uuid}/`,
        body,
      }),
    }),
    becomeReferrer: build.mutation<{ message: string }, BecomeReferrerParams>({
      query: ({ body, team_uuid }) => ({
        method: "POST",
        url: `/referral_program/become_referrer/${team_uuid}/`,
        body,
      }),
    }),
    referTrader: build.mutation<{ message: string }, ReferTraderParams>({
      query: ({ body, team_uuid }) => ({
        method: "POST",
        url: `/referral_program/refer_trader/${team_uuid}/`,
        body,
      }),
    }),
  }),
});
