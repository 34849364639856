// https://github.com/tradingview/charting-library-tutorial/blob/master/documentation/getting-started.md
import { prepareTimeframe } from "./helpers";
import {
  DatafeedConfiguration,
  HistoryCallback,
  ErrorCallback,
  IDatafeedChartApi,
  IExternalDatafeed,
  LibrarySymbolInfo,
  OnReadyCallback,
  PeriodParams,
  ResolutionString,
  ResolveCallback,
} from "@src/charting_library";
import { store } from "@src/store/store";
import { geckoTerminalApi } from "@src/store/apis/geckoterminal-api";
import { GeckoTerminalOHLCVItem } from "@src/store/apis/geckoterminal-api/types";
import { ChainId } from "@src/pages/defi/types";
import { GetDefiMarketDataParams } from "@src/pages/defi/defi-chart/types";
import { coingeckoApi } from "@src/store/apis/coingeckoApi";
import { getDefiResolutions } from "@src/pages/defi/defi-chart/trading-view-chart";
import { CHAIN_NAME } from "@src/pages/defi/constants";

export class DefiDataFeed implements IExternalDatafeed, IDatafeedChartApi {
  constructor(configuration: DatafeedConfiguration) {
    this.configuration = configuration;
  }

  configuration: DatafeedConfiguration = {};

  onReady = (callback: OnReadyCallback) => {
    setTimeout(() => callback(this.configuration));
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  searchSymbols = async () => {};

  resolveSymbol = async (symbolName: string, onSymbolResolvedCallback: ResolveCallback) => {
    const [symbol, chain] = symbolName.split("~");
    const isLineChart = symbolName.split("~")[4];

    const symbolInfo: LibrarySymbolInfo = {
      full_name: `${CHAIN_NAME[chain]}:${symbol}`,
      name: symbol,
      description: `${CHAIN_NAME[chain]}:${symbol}`,
      exchange: CHAIN_NAME[chain] + " | GeckoTerminal.com",
      ticker: symbolName,
      listed_exchange: CHAIN_NAME[chain],
      supported_resolutions: getDefiResolutions(
        isLineChart === "true",
        this.configuration?.supported_resolutions || []
      ),
      format: "price",
      minmov: 1,
      pricescale: 100,
      session: "24x7",
      timezone: "Etc/UTC",
      type: "crypto",
      has_intraday: true,
      has_weekly_and_monthly: true,
      volume_precision: 2,
      data_status: "streaming",
    };
    setTimeout(() => onSymbolResolvedCallback(symbolInfo));
  };

  getLineChartData = async (chain: string, fromTokenAddress: string, toTokenAddress: string) => {
    const marketDataForLineChart = await store.dispatch(
      coingeckoApi.endpoints.getTokenPairChartData.initiate({
        chainId: Number(chain) as ChainId,
        fromTokenAddress,
        toTokenAddress,
        period: "YEAR",
      })
    );

    return (
      marketDataForLineChart?.data?.map((item) => ({
        time: new Date(item.x).getTime(),
        open: item.y,
        high: item.y,
        low: item.y,
        close: item.y,
        volume: 0,
      })) || []
    );
  };

  getMarketData = async (params: GetDefiMarketDataParams) => {
    const { fromTokenAddress, toTokenAddress, toTokenSymbol, fromTokenSymbol } = params;

    const pairChartData = await store.dispatch(
      geckoTerminalApi.endpoints.getTokenPairChartData.initiate({
        chainId: Number(params.chain) as ChainId,
        tvTimeframe: params.timeframe,
        before_timestamp: String(params.to),
        limit: String(params.limit || 0),
        fromTokenAddress,
        toTokenAddress,
        fromTokenSymbol,
        toTokenSymbol,
      })
    );

    return pairChartData.data.data || [];
  };

  loadBars = async (params: GetDefiMarketDataParams) => {
    const marketData: GeckoTerminalOHLCVItem[] = [];
    // Splitting limit parameter to chunks of 1000 records. API can call no more than 1000 records in one request.
    let countOfChunks = Math.ceil((params.limit || 0) / 1000);

    while (countOfChunks > 0) {
      const lastBar = marketData[0];
      const lastDate = lastBar ? lastBar[0] : params.to;
      const limit = (params.limit || 0) > 1000 ? 1000 : params.limit ? params.limit : 0;

      const currentMarketData = await this.getMarketData({
        ...params,
        limit,
        to: lastDate,
      });

      marketData.push(
        ...currentMarketData
          .map((x) => {
            return [...[x[0] * 1000], ...x.slice(1)] as GeckoTerminalOHLCVItem;
          })
          .sort((a, b) => a[0] - b[0])
      );
      countOfChunks--;
    }

    const lastBar = marketData[0];
    const lastDate = lastBar ? lastBar[0] : +new Date();

    return {
      lastDate,
      items: marketData,
    };
  };

  getBars = async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onResult: HistoryCallback,
    onError: ErrorCallback
  ) => {
    const { ticker } = symbolInfo;
    const [symbol, chain, fromTokenAddress, toTokenAddress] = ticker?.split("~") || "";
    const [fromTokenSymbol, toTokenSymbol] = symbol.split("/");
    const { countBack, to, firstDataRequest } = periodParams;

    if (!symbol || !chain || to < 0) return onResult([], { noData: true });

    try {
      const loadedBars = await this.loadBars({
        timeframe: prepareTimeframe(resolution),
        limit: countBack,
        to,
        symbol,
        chain,
        fromTokenAddress,
        toTokenAddress,
        fromTokenSymbol,
        toTokenSymbol,
      });

      const marketDataItems = loadedBars.items;
      const bars = marketDataItems
        .filter(([time]) => time <= to * 1000)
        .map(([time, open, high, low, close, volume]) => ({ time, open, high, low, close, volume }));

      if (bars.length) {
        onResult(bars, { noData: false });
      } else {
        const lineChartData = await this.getLineChartData(chain, fromTokenAddress, toTokenAddress);

        onResult(
          firstDataRequest
            ? lineChartData?.length
              ? lineChartData
              : [
                  {
                    time: new Date().getTime(),
                    open: 0,
                    high: 0,
                    low: 0,
                    close: 0,
                    volume: 0,
                  },
                ]
            : [],
          { noData: true }
        );
      }
    } catch (err) {
      console.log(err.message);
      onError(err.message);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  subscribeBars = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  unsubscribeBars = () => {};
}
