import React, { forwardRef } from "react";
import { Box, MenuItem } from "@mui/material";
import { Urgency } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const URGENCY_VALUES: Urgency[] = [Urgency.HIGH, Urgency.MEDIUM, Urgency.LOW];

export const URGENCY_LABEL = {
  [Urgency.HIGH]: "High",
  [Urgency.MEDIUM]: "Medium",
  [Urgency.LOW]: "Low",
};

export const UrgencySelect = forwardRef(({ ...props }: AnbotoTextFieldProps, ref) => {
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <AnbotoTextField {...props} inputRef={ref} select size="small" fullWidth label="Urgency">
          {URGENCY_VALUES.map((x) => (
            <MenuItem key={x} value={x}>
              {URGENCY_LABEL[x]}
            </MenuItem>
          ))}
        </AnbotoTextField>
      </Box>
    </>
  );
});

UrgencySelect.displayName = "UrgencySelect";
