import React from "react";
import { IconButton, Stack, PaperProps, Typography, Paper, styled } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CloseIcon from "@mui/icons-material/Close";
import { feeManagerApi } from "../fee-manager/api";
import { StepsThumb } from "./steps-thumb";
import { anbotoApi } from "@src/store/apis/anbotoApi";

const initialSteps = [
  {
    icon: { icon: PersonIcon, css: { width: 18, height: 18 } },
    name: "profile",
    title: "Complete profile",
    description: "Enter personal information",
    link: "/settings/profile",
  },
  {
    icon: { icon: VpnKeyIcon, css: { width: 22 } },
    name: "setupExchange",
    title: "Setup exchange API keys",
    description: "Connect your exchange platform",
    link: "/settings/linked/exchanges",
  },
];

const feeManagerStep = {
  icon: { icon: MonetizationOnIcon, css: { width: 22 } },
  name: "firstDeposit",
  title: "Recharge Fee Wallet",
  description: "Add credits to your fee wallet",
  link: "/settings/fees",
};

const STORAGE_KEY = "hideSettingsSteps";

export const SettingsSteps = (props: PaperProps) => {
  const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);
  // @TODO check phone number, telegram, etc.. when profile edit is implemented
  const { team_uuid, email, first_name, last_name, fee_manager_enabled } = getUserDetailsQuery?.data || {};
  const getExchangeConfigsQuery = anbotoApi.useGetExchangeConfigsQuery(team_uuid || null, {
    skip: !team_uuid,
  });
  const getFeeTokensAndBalancesQuery = feeManagerApi.useGetFeeTokensAndBalancesQuery();
  const finished = {
    profile: first_name || last_name,
    setupExchange: !!getExchangeConfigsQuery?.data?.results.length,
    firstDeposit: !!getFeeTokensAndBalancesQuery?.data?.balance,
  };
  const [stepsVisible, setStepsVisible] = React.useState(false);

  const steps = [...initialSteps];

  if (fee_manager_enabled) {
    steps.splice(1, 0, feeManagerStep);
  }

  const hideSteps = () => {
    localStorage.setItem(STORAGE_KEY, "true");
    setStepsVisible(false);
  };

  React.useEffect(() => {
    const hidden = Boolean(localStorage.getItem(STORAGE_KEY));
    setStepsVisible(!hidden);
  }, []);

  return stepsVisible ? (
    <Paper {...props}>
      <Stack direction="row" gap={1} justifyContent="center" sx={{ p: 2 }}>
        <Stack sx={{ mr: 2, maxWidth: 220 }} justifyContent="center">
          <Typography variant="body2">
            Welcome !{" "}
            <Typography variant="caption" align="center" style={{ wordWrap: "break-word" }}>
              {email}
            </Typography>
          </Typography>
          <Typography variant="body2">Few things to go</Typography>
        </Stack>
        {steps.map(({ icon, name, title, description, link }, index) => (
          <StepsThumb
            key={name}
            icon={icon}
            title={`Step ${++index}. ${title}`}
            description={description}
            active={finished[name]}
            link={link}
          />
        ))}
        <Stack justifyContent="center">
          <IconButton onClick={hideSteps}>
            <Close />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  ) : null;
};

const Close = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: "pointer",
}));
