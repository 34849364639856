import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChainId } from "@src/pages/defi/types";
import { Item } from "@src/pages/settings/fee-manager/overview-token-fees/draggable-list";

export enum Action {
  WITHDRAWAL = 0,
  DEPOSIT = 1,
  FEE_CHARGE = 2,
}

export enum FeeManagerTabs {
  INTRO = "intro",
  OVERVIEW = "overview",
  TRANSACTIONS = "transactions",
  FEE_RATES = "fee_rates",
  HELP = "help",
}

export interface FeeManager {
  currentTab: FeeManagerTabs;
  currentCreditsBalance: number;
  filtersNetworks: ChainId[];
  filtersActions: Action[];
  items: Item[] | undefined;
}

const initialState: FeeManager = {
  currentTab: FeeManagerTabs.OVERVIEW,
  currentCreditsBalance: 0,
  filtersNetworks: [],
  filtersActions: [],
  items: [],
};

export const FeeManagerSlice = createSlice({
  name: "FeeManager",
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload.value;
    },
    setCurrentCreditsBalance: (state, action: PayloadAction<number>) => {
      state.currentCreditsBalance = action.payload;
    },
    setItems: (state, action: PayloadAction<Item[] | undefined>) => {
      state.items = action.payload ? [...action.payload] : undefined;
    },
    selectAllNetworks: (state) => {
      state.filtersNetworks = [];
    },
    selectAllActions: (state) => {
      state.filtersActions = [];
    },
    toggleNetwork: (state, action: PayloadAction<ChainId>) => {
      const chainId = action.payload;
      state.filtersNetworks = state.filtersNetworks.includes(chainId)
        ? state.filtersNetworks.filter((x) => x !== chainId)
        : [...state.filtersNetworks, chainId];
    },
    toggleAction: (state, action: PayloadAction<Action>) => {
      const payloadAction = action.payload;
      state.filtersActions = state.filtersActions.includes(payloadAction)
        ? state.filtersActions.filter((x) => x !== payloadAction)
        : [...state.filtersActions, payloadAction];
    },
  },
});

export const {
  setCurrentTab,
  setCurrentCreditsBalance,
  setItems,
  selectAllNetworks,
  selectAllActions,
  toggleNetwork,
  toggleAction,
} = FeeManagerSlice.actions;

export default FeeManagerSlice.reducer;
