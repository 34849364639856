import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { IconButtonProps } from "@mui/material";
import { WidgetIconButton } from "./widget-icon-button";

interface WidgetHeaderButtonProps extends IconButtonProps {
  onClick: () => void;
  icon: "add" | "close";
}

export const WidgetHeaderButton = ({ onClick, icon, sx, ...props }: WidgetHeaderButtonProps) => {
  return (
    <WidgetIconButton {...props} sx={{ width: 20, height: 20, ...sx }} onClick={onClick}>
      {icon === "close" && <CloseIcon sx={{ fontSize: 11 }} />}
      {icon === "add" && <AddIcon sx={{ fontSize: 11 }} />}
    </WidgetIconButton>
  );
};
