import React from "react";
import { styled } from "@mui/material";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

export const AnbotoIconButton = ({ flat, ...props }: IconButtonProps & { flat?: boolean }) => {
  if (flat) return <FlatIcon {...props} />;

  return <DefaultIcon {...props} />;
};

const DefaultIcon = styled(IconButton)(({ theme }) => ({
  "&:hover": { borderColor: "#2C72E3" },
  border: "1px solid #4A5053",
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
  background: "#232C2F",
}));

const FlatIcon = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.background.default,
}));
