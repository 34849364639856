import React from "react";
import { Stack } from "@mui/material";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import AnbotoLoader from "@src/components/anboto-loader/anboto-loader";
import AnbotoFetchingError from "@src/components/anboto-fetching-error/anboto-fetching-error";
import { ParentOrder } from "@src/store/apis/anbotoApi/types";
import OrderDetails from "@src/components/post-trade-analysis/post-trade-analysis/components/order-details/order-details";
import TakerMakerFees from "@src/components/post-trade-analysis/post-trade-analysis/components/taker-maker-fees/taker-maker-fees";
import SlippadgeAndPrice from "@src/components/post-trade-analysis/post-trade-analysis/components/slippadge-and-price/slippadge-and-price";
import Savings from "@src/components/post-trade-analysis/post-trade-analysis/components/savings/savings";
import StatsCard from "@src/components/post-trade-analysis/post-trade-analysis/components/stats-card/stats-card";

type PostTradeAnalysisProps = {
  order: ParentOrder;
  hide: () => void;
};

const PostTradeAnalysis = ({ order, hide }: PostTradeAnalysisProps) => {
  const postTradeAnalysis = anbotoApi.useGetPostTradeAnalysisDataQuery({ parent_id: order.order_id });

  return (
    <>
      {postTradeAnalysis.isLoading ? (
        <AnbotoLoader />
      ) : postTradeAnalysis.isError ? (
        <AnbotoFetchingError />
      ) : postTradeAnalysis.data ? (
        <Stack padding={2} gap={1}>
          <OrderDetails order={order} hide={hide} />
          <Stack direction="row" gap={1}>
            {postTradeAnalysis?.data?.data?.fees && (
              <TakerMakerFees takerMakerData={postTradeAnalysis.data.data.fees} />
            )}
            {postTradeAnalysis?.data?.data?.cost_of_repricing && (
              <Savings costOfRepricing={postTradeAnalysis.data.data.cost_of_repricing} />
            )}
          </Stack>
          <Stack gap={1} direction="row">
            {postTradeAnalysis?.data?.data?.slippages && postTradeAnalysis?.data?.data?.prices && (
              <SlippadgeAndPrice
                slippages={postTradeAnalysis.data.data.slippages}
                prices={postTradeAnalysis.data.data.prices}
              />
            )}
            <StatsCard
              spreadCapture={postTradeAnalysis.data.data?.percent_passive?.pct_passive_notional || 0}
              reversion={postTradeAnalysis.data.data?.reversion_5min_bps || 0}
              volumeParticipation={postTradeAnalysis.data.data?.volume_participation || 0}
            />
          </Stack>
        </Stack>
      ) : null}
    </>
  );
};

export default PostTradeAnalysis;
