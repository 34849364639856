import { CompetitionConfig } from "@src/pages/competitions";

export const CONFIG: CompetitionConfig = {
  COMPETITION_ID: "Woox trading competition 24 october",
  REWARDS: [
    { header: "1st", headerColor: "#ECAC27", minVolume: "1M", prizePoolPercent: 25 },
    { header: "2nd", headerColor: "#8A9296", minVolume: "1M", prizePoolPercent: 25 },
    { header: "1rd", headerColor: "#2C72E3", minVolume: "1M", prizePoolPercent: 25 },
    { header: "4th-10th", headerColor: "transparent", minVolume: "1M", prizePoolPercent: 25 },
    { header: "5 Lucky winners", headerColor: "transparent", minVolume: "1M", prizePoolPercent: 25 },
  ],
  PRIZE_POOL: [
    { volume: "25M", prizePool: "$2.5K" },
    {
      volume: "50M",
      prizePool: "$5K",
    },
    {
      volume: "100M",
      prizePool: "$10K",
    },
    {
      volume: "200M",
      prizePool: "$17.5K",
    },
    {
      volume: "500M",
      prizePool: "$25K",
    },
  ],
  START_TIME: "Oct 24th 2023 00:00 UTC",
  END_TIME: "Nov 13th 2023 23:59 UTC",
  PRIZE_POOL_SIZE: 25000,
  EXCHANGE_NAME: "WOO X",
};
