import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CloudOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";
import { NoTableData } from "@src/components/no-table-data";
import TransactionsTableRow from "@src/pages/settings/fee-manager/transactions/transactions-table-row";
import { RootState } from "@src/store/types";
import { feeManagerApi } from "@src/pages/settings/fee-manager/api";
import { AnbotoPagination } from "@src/components/ui/anboto-pagination";
import { PaginationTooltip } from "@src/components/orders-table/pagination-tooltip";
import TransactionTableFilters from "@src/pages/settings/fee-manager/transactions/transaction-table-filters";
import { useTransactionsTableFilters } from "@src/pages/settings/fee-manager/hooks/use-transactions-table-filters";
import { TokenData } from "@src/pages/settings/fee-manager/api/types";

const FEE_TABLE_LIMIT = 1000;
export const Transactions = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const { team_uuid } = useSelector((state: RootState) => state.user);
  const getAccounting = feeManagerApi.useGetAccountingQuery({ team_uuid: team_uuid });
  const availableChainIds =
    getAccounting?.data?.accounting_lines
      ?.map((x) => (Object.values(x?.token_data)?.[0] as TokenData)?.network_id?.toString() || "")
      .filter((value, index, self) => self.indexOf(value) === index) || [];

  const [period, setPeriod] = useState<GetOrdersPeriod>(GetOrdersPeriod.All);
  const [filterBy, setFilterBy] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>("");
  const data = getAccounting && getAccounting.data ? [...getAccounting.data.accounting_lines] : [];
  const tokenDetails = getAccounting && getAccounting.data ? [...getAccounting.data.token_details] : [];

  const filteredData = useTransactionsTableFilters({
    searchString,
    period,
    filterBy,
    data,
  });

  const isCollection = filteredData?.some((x) => x.operation === "Collection") || false;
  const sortedData = [...filteredData!]
    .sort((a, b) => new Date(b.proceed_at).getTime() - new Date(a.proceed_at).getTime())
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <Paper>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" marginX="30px" marginTop="24px">
          <TransactionTableFilters
            availableChainIds={availableChainIds}
            setPeriod={setPeriod}
            filterBy={filterBy}
            setFilterBy={setFilterBy}
            setSearchString={setSearchString}
          />
        </Stack>

        <Box px={4}>
          <Table sx={{ mt: 2 }}>
            <TableHead>
              <TableRow>
                {isCollection && <TableCell></TableCell>}
                <TableCell>Actions</TableCell>
                <TableCell>Token</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Network</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Tx ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getAccounting.isFetching ? (
                <TableRow>
                  <TableCell colSpan={7} sx={{ border: "none" }}>
                    <Stack height="200px" alignItems="center" justifyContent="center" gap={1}>
                      <Typography>Loading</Typography>
                      <CircularProgress />
                    </Stack>
                  </TableCell>
                </TableRow>
              ) : getAccounting.error ? (
                <TableRow>
                  <TableCell colSpan={7} sx={{ border: "none" }}>
                    <Stack height="200px" alignItems="center" justifyContent="center" gap={1}>
                      <CloudOff />
                      <Typography>Oops ~ something went wrong</Typography>
                      <Button onClick={getAccounting.refetch} variant="outlined">
                        Refresh
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ) : filteredData?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} sx={{ border: "none" }}>
                    <Stack width="100%" display="flex" alignItems="center" justifyContent="center" minHeight="400px">
                      <NoTableData message="You don't have any transaction records yet" />
                    </Stack>
                  </TableCell>
                </TableRow>
              ) : (
                sortedData.map((accountingLine) => (
                  <TransactionsTableRow
                    isCollection={isCollection}
                    accountingLine={accountingLine}
                    tokenDetails={tokenDetails}
                  />
                ))
              )}
            </TableBody>
          </Table>
          <AnbotoPagination
            total={filteredData?.length || 0}
            currentPage={page}
            pageSize={rowsPerPage}
            onChangeRowsPerPage={(value) => handleChangeRowsPerPage(value)}
            onChangePage={(value) => handleChangePage(value)}
            prefix={<PaginationTooltip limit={FEE_TABLE_LIMIT} />}
          />
        </Box>
      </Stack>
    </Paper>
  );
};
