import React from "react";
import { TypographyProps } from "@mui/material";
import { usePriceImpactContext } from "@src/components/price-impact/hooks/use-price-impact-context";
import { Value } from "@src/components/price-impact/components/value";
import { formatAmount, roundAmount } from "@src/pages/portfolio/utils/helpers";

type RowValueProps = {
  fieldName: string;
  sx?: TypographyProps["sx"];
  type: "value" | "percent" | "price";
};

const PriceImpactContextValue = ({ fieldName, sx, type }: RowValueProps) => {
  const context = usePriceImpactContext();
  const value = context[fieldName];

  return (
    <Value sx={sx}>
      {value
        ? type === "price"
          ? formatAmount(roundAmount(value))
          : type === "percent"
          ? roundAmount(value) + "%"
          : roundAmount(value)
        : "-"}
    </Value>
  );
};

export default PriceImpactContextValue;
