import React, { useState } from "react";
import WidgetsGridLayout from "@src/features/widgets-layout";
import { WidgetsGridView } from "@src/features/widgets-layout/types";
import { Intro } from "@src/components/intro";
import { LeverageModal } from "@src/features/leverage/leverage-modal";

export const CefiOrderPage = () => {
  const [widgetsMounted, setWidgetsMounted] = useState(false);

  const onWidgetsMount = () => {
    if (!widgetsMounted) setWidgetsMounted(true);
  };

  return (
    <>
      {widgetsMounted && <Intro page="cefi-widgets" />}
      <LeverageModal />
      <WidgetsGridLayout onWidgetsMount={onWidgetsMount} view={WidgetsGridView.CEFI} />
    </>
  );
};
