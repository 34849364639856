import BigNumber from "bignumber.js";
import { ankr } from "@src/store/apis/anbotoApi/ankr";

export interface UseAccountChainTokenBalancesProps {
  account: string | undefined;
  chainId?: number;
}

export interface UseAccountChainTokenBalancesResult {
  balances: Record<string, number> | null;
  isFetching: boolean;
}

export const useAccountChainTokenBalances = ({
  account,
  chainId,
}: UseAccountChainTokenBalancesProps): UseAccountChainTokenBalancesResult => {
  const { data, isFetching } = ankr.useGetUserTokensListQuery(
    {
      account: account!,
      chainId: chainId!,
    },
    { skip: !account || !chainId }
  );

  const balances = (data?.result?.assets || []).reduce(
    (r, x) => ({
      ...r,
      [x.tokenSymbol.toLowerCase()]: new BigNumber(x.balance).toFixed(),
    }),
    {}
  );

  return {
    balances,
    isFetching,
  };
};
