import { useCallback, useEffect } from "react";
import { getPortfolioSubscription } from "@src/subscriptions/anboto-portfolio-subscription";
import { useAppSelector, useAppDispatch } from "@src/store/hooks";
import { PortfolioMessage } from "@src/subscriptions/types";
import { setCefiStreamData } from "@src/store/slices/portfolioSlice";

export const AssetsSubscriber = () => {
  const accountId = useAppSelector((state) => state.user.team_uuid);
  const dispatch = useAppDispatch();

  const onMessage = useCallback((msg: PortfolioMessage) => {
    dispatch(setCefiStreamData(msg));
  }, []);

  useEffect(() => {
    if (accountId) {
      const subscription = getPortfolioSubscription(accountId);

      subscription.listenAll(onMessage);
    }
  }, [accountId]);
  return null;
};
