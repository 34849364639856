import { useEffect, useCallback, useState } from "react";
import _throttle from "lodash/throttle";
import { MarketDataSubscriptionType, MarketDataTickerData } from "../types";
import { getMarketDataSubscription } from "../index";
import { prepareExchange } from "@src/pages/cefi/order-symbol-chart/anboto-datafeed/helpers";
import { getLivePriceId } from "@src/store/slices/subscriptionsSlice";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";

const marketDataSubscription = getMarketDataSubscription();

export const useAnbotoTickerSubscription = (symbol: OrderSymbol) => {
  const [livePrice, setLivePrice] = useState({
    price: 0,
    direction: "",
    percentage: 0,
    change: 0,
    high: 0,
    low: 0,
    quoteVolume: 0,
    baseVolume: 0,
  });

  const onMessage = useCallback(
    _throttle((msg: MarketDataTickerData) => {
      const { info, close, percentage, change, high, low, quoteVolume, baseVolume } = msg;
      const msgPrice = info?.price || close;

      if (msgPrice && msgPrice !== +livePrice?.price) {
        setLivePrice({
          price: msgPrice,
          direction: msgPrice > livePrice.price ? "up" : msgPrice < livePrice.price ? "down" : livePrice.direction,
          percentage,
          change,
          high,
          low,
          quoteVolume,
          baseVolume,
        });
      }
    }, 1000),
    []
  );

  const getSubscribeMessage = ({ symbol, exchange, market_type }: OrderSymbol) => {
    return {
      symbol,
      market_type,
      data_type: MarketDataSubscriptionType.TICKER,
      exchange: prepareExchange(exchange),
      params: {},
    };
  };

  useEffect(() => {
    let unsubscribe;

    if (symbol) {
      unsubscribe = marketDataSubscription.subscribe(getSubscribeMessage(symbol), onMessage);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [getLivePriceId(symbol)]);

  return {
    symbol,
    ...livePrice,
  };
};
