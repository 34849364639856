import { PredefinedWidgetsLayout, WidgetName, WidgetsGridView, WidgetsLayout } from "../types";
import { createWidgetLayoutItem } from "../utils/create-widget-layout-item";
import { getLayout } from "./get-layout";

const layout: WidgetsLayout = {
  // set 1
  ...createWidgetLayoutItem(
    WidgetName.CefiOrderForm,
    getLayout(WidgetName.CefiOrderForm, {
      xxl: { x: 0, y: 0, w: 3 },
      xl: { x: 0, y: 0, w: 4 },
      lg: { x: 0, y: 0, w: 6 },
      md: { x: 0, y: 0, w: 7 },
      sm: { x: 0, y: 0, w: 10 },
      xs: { x: 0, y: 0, w: 24 },
    })
  ),
  ...createWidgetLayoutItem(
    WidgetName.CefiPriceImpact,
    getLayout(WidgetName.CefiPriceImpact, {
      xxl: { x: 3, y: 0, w: 2 },
      xl: { x: 4, y: 0, w: 3 },
      lg: { x: 6, y: 0, w: 4 },
      md: { x: 7, y: 0, w: 5 },
      sm: { x: 10, y: 0, w: 6 },
      xs: { x: 0, y: 0, w: 24 },
    })
  ),
  ...createWidgetLayoutItem(
    WidgetName.CefiOrderbook,
    getLayout(WidgetName.CefiOrderbook, {
      xxl: { x: 5, y: 0, w: 2 },
      xl: { x: 7, y: 0, w: 3 },
      lg: { x: 10, y: 0, w: 4 },
      md: { x: 12, y: 0, w: 5 },
      sm: { x: 10, y: 0, w: 6 },
      xs: { x: 0, y: 0, w: 24 },
    })
  ),
  ...createWidgetLayoutItem(
    WidgetName.CefiSymbolChart,
    getLayout(WidgetName.CefiSymbolChart, {
      xxl: { x: 7, y: 0, w: 5 },
      xl: { x: 10, y: 0, w: 14 },
      lg: { x: 14, y: 0, w: 10 },
      md: { x: 17, y: 0, w: 7 },
      sm: { x: 17, y: 0, w: 14 },
      xs: { x: 0, y: 0, w: 24 },
    })
  ),
  // orders table
  ...createWidgetLayoutItem(
    WidgetName.CefiOrdersTableFull,
    getLayout(WidgetName.CefiOrdersTableFull, {
      xxl: { x: 0, y: 19, w: 24 },
      xl: { x: 0, y: 38, w: 24 },
      lg: { x: 0, y: 38, w: 24 },
      md: { x: 0, y: 38, w: 24 },
      sm: { x: 0, y: 38, w: 24 },
      xs: { x: 0, y: 38, w: 24 },
    })
  ),
};

export const cefiPageLayoutAdvance: PredefinedWidgetsLayout = {
  id: "advance",
  name: "Advanced",
  view: WidgetsGridView.CEFI,
  version: 1,
  layout,
};
