import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { walletSchema } from "./use-wallet-update-form";
import formValidator from "@src/utils/formValidator";
import { Wallet } from "@src/store/apis/anbotoApi/types";

export type WalletCreateBulkFormFieldValues = {
  wallets: Wallet[];
};

const defaultValues: WalletCreateBulkFormFieldValues = {
  wallets: [{ name: "", address: "" }],
};

const schema = formValidator.object({
  wallets: formValidator.array().of(walletSchema).min(1),
});

export const useWalletCreateBulkForm = () => {
  return useForm<WalletCreateBulkFormFieldValues>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
};
