import { useCefiOrderPreferences } from "./use-cefi-order-preferences";
import {
  CEFI_ORDER_FORM_DEFAULT_VALUES,
  ParentOrderFormFieldValues,
  getTradingStyle,
} from "@src/pages/cefi/order-form/hooks/use-parent-order-form";

export const useCefiOrderFormDefaultValues = (values: Partial<ParentOrderFormFieldValues> = {}) => {
  const userFormPreferences = useCefiOrderPreferences();
  const initialValues = {
    ...CEFI_ORDER_FORM_DEFAULT_VALUES,
    ...userFormPreferences,
  };

  const defaultValues = {
    ...initialValues,
    tradingStyle: getTradingStyle(
      values.strategy || initialValues.strategy,
      values.tradingStyle || initialValues.tradingStyle
    ),
    ...values,
  };

  return defaultValues;
};
