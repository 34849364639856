import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {
  GetChainPriceParams,
  GetChainPriceResult,
  GetTokenInfoParams,
  GetTokenParams,
  TokenHistoricalMarketData,
} from "./types";
import { queryFnFactory } from "@src/store/apis/utils";
import {
  CHAIN_ID_ASSET_PLATFORM,
  CHAIN_ID_COIN_ID,
  PERIOD_DAYS,
  PERIOD_HASH_TIME_FN,
} from "@src/store/apis/coingeckoApi/constants";
import {
  GetTokenPairChartDataQueryParams,
  GetTokenPairChartDataQueryResult,
  GetTokenPriceParams,
  GetTokenPriceResult,
} from "@src/store/apis/coingeckoApi/types";
import { byHashedTime, handleError } from "@src/store/apis/coingeckoApi/utils";
import { CHAIN_NATIVE_TOKENS } from "@src/constants/chain-native-tokens";
import { Token } from "@src/pages/defi/types";

export const baseQuery = fetchBaseQuery({ baseUrl: "https://api.coingecko.com/api/v3" });

export const tokensBaseQuery = fetchBaseQuery({ baseUrl: "https://tokens.coingecko.com/" });

export const coingeckoApi = createApi({
  reducerPath: "coingeckoApi",
  baseQuery,
  endpoints: (builder) => ({
    getCoinInfo: builder.query<Token, GetTokenInfoParams>({
      query: ({ id }) => ({
        url: `/coins/${id}`,
      }),
    }),
    getSimplePrice: builder.query<GetChainPriceResult, GetTokenParams>({
      query: ({ tokenIdsList }) => ({
        url: `/simple/price`,
        params: { ids: tokenIdsList, vs_currencies: "usd" },
      }),
    }),
    getChainPrice: builder.query<GetChainPriceResult, GetChainPriceParams>({
      query: ({ chainId }) => ({
        url: `/simple/price`,
        params: { ids: CHAIN_ID_ASSET_PLATFORM[chainId], vs_currencies: "usd" },
      }),
      transformResponse: (response, meta, { chainId }) =>
        (response as GetChainPriceResult)[CHAIN_ID_ASSET_PLATFORM[chainId]] || { usd: 0 },
    }),
    getTokenPrice: builder.query<GetTokenPriceResult, GetTokenPriceParams>({
      queryFn: queryFnFactory<GetTokenPriceParams, GetTokenPriceResult>(
        async ({ chainId, tokenAddress }, api, extraOptions, baseQuery) => {
          const targetToken = CHAIN_NATIVE_TOKENS.find(
            (token) => token.chainId === chainId && token.tokenId === tokenAddress
          );

          const url = targetToken ? "/simple/price" : `/simple/token_price/${CHAIN_ID_COIN_ID[chainId]}`;
          const params = targetToken
            ? { ids: targetToken.coinName, vs_currencies: "usd" }
            : { contract_addresses: [tokenAddress], vs_currencies: "usd" };

          const result = await baseQuery({
            url,
            params,
          }).then(handleError);

          if (targetToken) {
            return {
              [tokenAddress]: result[targetToken.coinName],
            };
          }

          return result;
        }
      ),
    }),
    getTokenPairChartData: builder.query<GetTokenPairChartDataQueryResult, GetTokenPairChartDataQueryParams>({
      queryFn: queryFnFactory<GetTokenPairChartDataQueryParams, GetTokenPairChartDataQueryResult>(
        async ({ fromTokenAddress, toTokenAddress, chainId, period }, api, extraOptions, baseQuery) => {
          const coinId = CHAIN_ID_COIN_ID[chainId];
          const periodDays = PERIOD_DAYS[period];
          const periodHashTimeFn = PERIOD_HASH_TIME_FN[period];
          const targetPayToken = CHAIN_NATIVE_TOKENS.find(
            (token) => token.chainId === chainId && token.tokenId === fromTokenAddress
          );
          const targetReceiveToken = CHAIN_NATIVE_TOKENS.find(
            (token) => token.chainId === chainId && token.tokenId === toTokenAddress
          );

          const params = { vs_currency: "usd", days: periodDays };

          const payTokenUrl = targetPayToken
            ? `coins/${targetPayToken.coinName}/market_chart`
            : `/coins/${coinId}/contract/${fromTokenAddress.toLowerCase()}/market_chart`;
          const receiveTokenUrl = targetReceiveToken
            ? `coins/${targetReceiveToken.coinName}/market_chart`
            : `/coins/${coinId}/contract/${toTokenAddress.toLowerCase()}/market_chart`;

          const payTokenHistoricalMarketData: TokenHistoricalMarketData = await baseQuery({
            url: payTokenUrl,
            params,
          }).then(handleError);

          const receiveTokenHistoricalMarketData: TokenHistoricalMarketData = await baseQuery({
            url: receiveTokenUrl,
            params,
          }).then(handleError);

          const payTokenHistoricalMarketDataByHashedTime = payTokenHistoricalMarketData?.prices?.reduce(
            byHashedTime(periodHashTimeFn),
            {}
          );

          const receiveTokenHistoricalMarketDataByHashedTime = receiveTokenHistoricalMarketData?.prices?.reduce(
            byHashedTime(periodHashTimeFn),
            {}
          );

          if (!payTokenHistoricalMarketDataByHashedTime || !receiveTokenHistoricalMarketDataByHashedTime) {
            return [];
          }

          return Object.entries<number>(payTokenHistoricalMarketDataByHashedTime)
            .map<[string, number]>(([t, v], i: number) => [
              t,
              v /
                (i < Object.values<number>(receiveTokenHistoricalMarketDataByHashedTime).length - 1
                  ? Object.values<number>(receiveTokenHistoricalMarketDataByHashedTime)[i]
                  : Object.values<number>(receiveTokenHistoricalMarketDataByHashedTime).slice(-1)[0]),
            ])
            .map((x) => ({ x: x[0], y: x[1] }));
        }
      ),
    }),
  }),
});
