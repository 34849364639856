import React from "react";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ReactComponent as TeamIcon } from "@src/assets/svg/team-icon.svg";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AccountTypes } from "@src/pages/auth/onboarding.slice";
import { RootState } from "@src/store/types";

type AccountActivatedProps = {
  accountName: string;
  onNextClick: () => void;
  loading?: boolean;
  error?: string;
};

const AccountActivated = ({ accountName, onNextClick, loading, error }: AccountActivatedProps) => {
  const accountType = useSelector((state: RootState) => state.onboarding.accountType);

  return (
    <Stack margin="auto" direction="column" gap={4} alignItems="center">
      <TeamIcon />
      <Typography fontSize={24} fontWeight={500}>
        {loading
          ? "Creating account..."
          : error ||
            (accountType === AccountTypes.INDIVIDUAL && !accountName.length ? "Account activated" : "You have joined ")}
      </Typography>
      {!loading && accountType === AccountTypes.TEAM && accountName.length && (
        <Typography>Account - {accountName}</Typography>
      )}
      {!error && (
        <AnbotoButton
          onClick={onNextClick}
          tabIndex={1}
          sx={{ width: 240, "&:focus": { outline: 1 } }}
          size="medium"
          variant="contained"
          loading={!!loading}
        >
          Next
        </AnbotoButton>
      )}
    </Stack>
  );
};

export default AccountActivated;
