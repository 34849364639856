import React from "react";
import { Stack } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { useSnackbar } from "notistack";
import { DEFI_ORDER_FORM_DEFAULT_VALUES, useDefiOrderForm } from "@src/pages/defi/hooks";
import DefiPreferencesForm from "@src/pages/settings/order-preferences/defi-preferences-form";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import {
  CEFI_ORDER_FORM_DEFAULT_VALUES,
  useParentOrderForm,
} from "@src/pages/cefi/order-form/hooks/use-parent-order-form";
import CefiPreferencesForm from "@src/pages/settings/order-preferences/cefi-order-preferences-form";
import {
  PlacementCancelValue,
  PlacementMode,
  PlacementValue,
  SetOrderPreferencesParams,
} from "@src/store/apis/anbotoApi/types";
import { SettingsCard } from "@src/pages/settings/settings-card";
import {
  CefiExpirationTimeValues,
  DEFAULT_DEFI_EXTEND_DURATION,
  DefiExpirationTimeValues,
} from "@src/pages/settings/order-preferences/constant";
import { useCefiOrderPreferences } from "@src/pages/cefi/order-form/hooks/use-cefi-order-preferences";
import { useAppSelector } from "@src/store/hooks";
import { usePermissions } from "@src/hooks/use-permissions";

const preferenceFormWrongErrors = ["account", "quantity", "maxFeeAbsolute", "maxGasPrice", "tradingDuration"];

const OrderPreferencesForm = () => {
  const snackbar = useSnackbar();
  const team_uuid = useAppSelector((state) => state.user.team_uuid);

  const [setOrderPreferences, { isFetching }] = anbotoApi.useLazySetOrderPreferencesQuery();

  const defiPreferencesForm = useDefiOrderForm();

  const cefiOrderPreferences = useCefiOrderPreferences();
  const cefiPreferencesForm = useParentOrderForm(cefiOrderPreferences);
  const permissions = usePermissions();

  const resetOrderPreferences = () => {
    defiPreferencesForm.reset(DEFI_ORDER_FORM_DEFAULT_VALUES);
    cefiPreferencesForm.reset(CEFI_ORDER_FORM_DEFAULT_VALUES);
  };

  const saveOrderPreferences = async () => {
    try {
      await defiPreferencesForm.trigger();
      await cefiPreferencesForm.trigger();

      const orderPreferenceFormErrorsFields = [
        ...Object.keys(defiPreferencesForm.control._formState.errors),
        ...Object.keys(cefiPreferencesForm.control._formState.errors),
      ];

      const orderPreferenceErrors = orderPreferenceFormErrorsFields.filter(
        (error) => !preferenceFormWrongErrors.includes(error)
      );

      if (orderPreferenceErrors.length) return;

      const preferences: Partial<SetOrderPreferencesParams> = {
        cefi_strategy: cefiPreferencesForm.getValues("strategy"),
        cefi_duration_type: cefiPreferencesForm.getValues("tradingDurationUnit"),
        cefi_trading_style: cefiPreferencesForm.getValues("tradingStyle"),
        cefi_would_price: cefiPreferencesForm.getValues("wouldStyle"),
        cefi_maker_placement_type: cefiPreferencesForm.getValues("placementMode") || PlacementMode.DEFAULT,
        cefi_custom_placement_level: cefiPreferencesForm.getValues("placement") || PlacementValue.DEFAULT,
        cefi_custom_cancel_level: cefiPreferencesForm.getValues("placementCancel") || PlacementCancelValue.DEFAULT,
        cefi_clip_size_type: cefiPreferencesForm.getValues("clipSizeType"),
        cefi_expiration: CefiExpirationTimeValues[cefiPreferencesForm.getValues("expirationTime")],
        cefi_extend_duration: cefiPreferencesForm.getValues("extendDuration"),
        defi_strategy: defiPreferencesForm.getValues("strategy"),
        defi_duration_type: defiPreferencesForm.getValues("tradingDurationUnit"),
        defi_parent_slipage_percent: defiPreferencesForm.getValues("slippage"),
        defi_child_slipage_percent: defiPreferencesForm.getValues("childSlippage"),
        defi_clip_size_type: defiPreferencesForm.getValues("clipSizeType"),
        defi_expiration: DefiExpirationTimeValues[defiPreferencesForm.getValues("expiration")],
        defi_extend_duration: DEFAULT_DEFI_EXTEND_DURATION,
      };
      if (cefiPreferencesForm.getValues("tradingDuration"))
        preferences.cefi_duration_value = cefiPreferencesForm.getValues("tradingDuration");
      if (defiPreferencesForm.getValues("tradingDuration"))
        preferences.defi_duration_value = defiPreferencesForm.getValues("tradingDuration");

      await setOrderPreferences({
        ...preferences,
        team_uuid,
      } as SetOrderPreferencesParams).unwrap();

      snackbar.enqueueSnackbar("Order preferences saved successfully", { variant: "success" });
    } catch (e) {
      snackbar.enqueueSnackbar(e?.message || "Some error occurred", { variant: "error" });
    }
  };

  return (
    <Stack maxWidth="99%" flexGrow={1} flexDirection="column" gap={1} padding={3}>
      <SettingsCard
        headerProps={{
          title: "Order preferences",
        }}
        footer={
          permissions.trade && (
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
              <AnbotoButton variant="outlined" onClick={resetOrderPreferences}>
                Reset
              </AnbotoButton>
              <AnbotoButton loading={isFetching} variant="contained" onClick={saveOrderPreferences}>
                Save settings
              </AnbotoButton>
            </Stack>
          )
        }
      >
        <FormProvider {...cefiPreferencesForm}>
          <CefiPreferencesForm />
          <DevTool control={cefiPreferencesForm.control} />
        </FormProvider>

        <FormProvider {...defiPreferencesForm}>
          <DefiPreferencesForm />

          <DevTool control={defiPreferencesForm.control} />
        </FormProvider>
      </SettingsCard>
    </Stack>
  );
};

export default OrderPreferencesForm;
