import { createSlice } from "@reduxjs/toolkit";
import { ChainId, EVMBasedAddress, Token } from "@src/pages/defi/types";
import { constructTokenAddress, isTokenSaveNeeded } from "@src/pages/defi/utils";

export type TokenListUrlToIsActive = Record<string, boolean>;

export type TokenConfig = {
  timestamp: number;
  token: Token;
};

export type TokensConfig = {
  timestamp: number;
  tokens: Token[];
};

// TODO remove tokenListConfigs and tokenConfigs after release
type State = {
  tokenListConfigs: [];
  tokenConfigs: [];
  tokensMap: Record<`${ChainId}_${EVMBasedAddress}`, TokenConfig>;
};

const initialState: State = {
  tokenListConfigs: [],
  tokenConfigs: [],
  tokensMap: {},
};

export function saveToken(token: Token) {
  return async function saveTokenThunk(dispatch) {
    if (!token) {
      return;
    }

    dispatch(
      createToken({
        timestamp: new Date().getTime(),
        token,
      })
    );
  };
}

export function saveTokens(tokens: Token[]) {
  return async function saveTokensWithImageCheckThunk(dispatch, getState) {
    if (!tokens?.length) {
      return;
    }

    const state = getState() as State;

    tokens.forEach((token) => {
      const tokenChainAddress = constructTokenAddress(token.chainId, token.address as EVMBasedAddress);

      if (isTokenSaveNeeded(state.tokensMap?.[tokenChainAddress])) {
        dispatch(saveToken(token));
      }
    });
  };
}

export const tokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    createToken: (state, { payload: tokenConfig }: { payload: TokenConfig }) => {
      const tokenChainAddress = constructTokenAddress(
        tokenConfig.token.chainId,
        tokenConfig.token.address as EVMBasedAddress
      );

      if (isTokenSaveNeeded(state.tokensMap[tokenChainAddress])) {
        state.tokensMap[tokenChainAddress] = tokenConfig;
      }
    },
    createTokens: (state, { payload: tokensConfig }: { payload: TokensConfig }) => {
      const tokens = tokensConfig.tokens;

      if (!tokens?.length) {
        return state;
      }

      const newTokensMap = { ...state.tokensMap };

      tokens.forEach((token) => {
        const tokenChainAddress = constructTokenAddress(token.chainId, token.address as EVMBasedAddress);

        if (isTokenSaveNeeded(state.tokensMap[tokenChainAddress])) {
          newTokensMap[tokenChainAddress] = { timestamp: tokensConfig.timestamp, token };
        }
      });

      return {
        ...state,
        tokensMap: newTokensMap,
      };
    },
    updateTokenUrl: (
      state,
      {
        payload: { chainId, address, logoURI },
      }: {
        payload: { chainId: ChainId; address: EVMBasedAddress | string; logoURI: string };
      }
    ) => {
      const tokenChainAddress = constructTokenAddress(chainId, address as EVMBasedAddress);
      const targetTokenConfig = state.tokensMap[tokenChainAddress];

      if (!targetTokenConfig) {
        return state;
      }

      return {
        ...state,
        tokensMap: {
          ...state.tokensMap,
          [tokenChainAddress]: {
            timestamp: new Date().getTime(),
            token: {
              ...targetTokenConfig.token,
              logoURI,
            },
          },
        },
      };
    },
  },
});

export const { createToken, createTokens, updateTokenUrl } = tokensSlice.actions;
