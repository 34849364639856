import React from "react";
import { Content } from "@src/pages/competitions/components/content";
import { ReactComponent as WooxLogo } from "@src/assets/svg/trading-competition/woox-logo.svg";
import { CampaignRules } from "@src/pages/competitions/components/woox/campaign-rules";
import { TermsAndConditions } from "@src/pages/competitions/components/woox/terms-and-conditions";
import { About } from "@src/pages/competitions/components/woox/about";
import { CONFIG } from "@src/pages/competitions/components/woox/config";
import { useLeaderboard } from "@src/pages/competitions/hooks/use-leaderboard";

export const Layout = () => {
  const { sortedLeaderBoard, isFetching, refetch } = useLeaderboard({ competitionId: CONFIG.COMPETITION_ID });

  return (
    <Content
      prizePoolSize={CONFIG.PRIZE_POOL_SIZE}
      exchangeLogo={<WooxLogo />}
      isLuckyBonus
      exchangeName={CONFIG.EXCHANGE_NAME}
      startDate={CONFIG.START_TIME}
      endDate={CONFIG.END_TIME}
      rewards={CONFIG.REWARDS}
      prizePool={CONFIG.PRIZE_POOL}
      about={<About />}
      campaignRules={<CampaignRules prizePool={CONFIG.PRIZE_POOL} />}
      termsAndConditions={<TermsAndConditions />}
      leaderBoardData={sortedLeaderBoard}
      isLeaderBoardDataFetching={isFetching}
      leaderBoardRefetch={refetch}
    />
  );
};
