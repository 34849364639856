import React from "react";
import { StepIconProps, styled } from "@mui/material";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

const StepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircleOutlinedIcon className="StepIcon-completedIcon" />
      ) : (
        <CircleOutlinedIcon sx={{ width: 16 }} />
      )}
    </StepIconRoot>
  );
};

const StepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.grey[700],
  display: "flex",
  height: 16,
  width: 16,
  alignItems: "center",
  ...(ownerState.active && {
    color: theme.custom.colors.secondary,
  }),
  "& .StepIcon-completedIcon": {
    color: theme.custom.colors.secondary,
    zIndex: 1,
    width: 16,
  },
}));

export default StepIcon;
