import { createSlice } from "@reduxjs/toolkit";

export enum ReferralProgramTabs {
  OVERVIEW = "Overview",
  COMMISSIONS = "Commissions",
  TIER_STRUCTURE = "Tier structure",
}

export interface ReferralProgram {
  currentTab: ReferralProgramTabs;
}

const initialState: ReferralProgram = {
  currentTab: ReferralProgramTabs.OVERVIEW,
};

export const ReferralProgramSlice = createSlice({
  name: "referralProgram",
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
});

export const { setCurrentTab } = ReferralProgramSlice.actions;

export default ReferralProgramSlice.reducer;
