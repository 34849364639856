import { DEFAULT_OTC_SYMBOL } from "../constants";
import { PredefinedWidgetsLayout, WidgetName, WidgetsGridView, WidgetsLayout } from "../types";
import { createWidgetLayoutItem } from "../utils/create-widget-layout-item";
import { getLayout } from "./get-layout";

export const layout: WidgetsLayout = {
  ...createWidgetLayoutItem(
    WidgetName.OtcOrderForm,
    getLayout(WidgetName.OtcOrderForm, {
      xxl: { x: 0, y: 0, w: 2, h: 14 },
      xl: { x: 0, y: 0, w: 3, h: 14 },
      lg: { x: 0, y: 0, w: 4, h: 14 },
      md: { x: 0, y: 0, w: 5, h: 14 },
      sm: { x: 0, y: 0, w: 7, h: 14 },
      xs: { x: 0, y: 0, w: 7, h: 14 },
    }),
    "1"
  ),
  ...createWidgetLayoutItem(
    WidgetName.OtcOrderForm,
    getLayout(WidgetName.OtcOrderForm, {
      xxl: { x: 2, y: 0, w: 2, h: 14 },
      xl: { x: 3, y: 0, w: 3, h: 14 },
      lg: { x: 4, y: 0, w: 4, h: 14 },
      md: { x: 5, y: 0, w: 5, h: 14 },
      sm: { x: 7, y: 0, w: 7, h: 14 },
      xs: { x: 7, y: 0, w: 7, h: 14 },
    }),
    "2"
  ),
  ...createWidgetLayoutItem(
    WidgetName.OtcOrderForm,
    getLayout(WidgetName.OtcOrderForm, {
      xxl: { x: 4, y: 0, w: 2, h: 14 },
      xl: { x: 6, y: 0, w: 3, h: 14 },
      lg: { x: 8, y: 0, w: 4, h: 14 },
      md: { x: 10, y: 0, w: 5, h: 14 },
      sm: { x: 14, y: 0, w: 7, h: 14 },
      xs: { x: 14, y: 0, w: 7, h: 14 },
    }),
    "3"
  ),
  ...createWidgetLayoutItem(
    WidgetName.OtcSymbolChart,
    getLayout(WidgetName.OtcSymbolChart, {
      xxl: { x: 6, y: 0, w: 9, h: 14 },
      xl: { x: 9, y: 0, w: 5, h: 14 },
      lg: { x: 12, y: 0, w: 6, h: 14 },
      md: { x: 15, y: 0, w: 9, h: 14 },
      sm: { x: 0, y: 0, w: 7, h: 14 },
      xs: { x: 0, y: 0, w: 7, h: 14 },
    }),
    "1"
  ),
  ...createWidgetLayoutItem(
    WidgetName.OtcOrdersTableFull,
    getLayout(WidgetName.OtcOrdersTableFull, {
      xxl: { x: 0, y: 13, w: 24 },
      xl: { x: 0, y: 13, w: 24 },
      lg: { x: 0, y: 13, w: 24 },
      md: { x: 0, y: 13, w: 24 },
      sm: { x: 0, y: 13, w: 24 },
      xs: { x: 0, y: 13, w: 24 },
    })
  ),
};

export const otcPageLayout: PredefinedWidgetsLayout = {
  id: "otc",
  name: "Otc",
  view: WidgetsGridView.OTC,
  version: 1,
  layout,
  groups: {
    1: {
      symbol: DEFAULT_OTC_SYMBOL,
    },
    2: {
      symbol: {
        ...DEFAULT_OTC_SYMBOL,
        symbol: "ETH/USD",
        coin1: "ETH",
        coin2: "USDT",
      },
    },
    3: {
      symbol: {
        ...DEFAULT_OTC_SYMBOL,
        symbol: "XRP/USD",
        coin1: "XRP",
        coin2: "USD",
      },
    },
  },
};
