import { ChainId } from "@src/pages/defi/types";
import { ankr } from "@src/store/apis/anbotoApi/ankr";

export interface UseDefiFormGetUserTokensQueryProps {
  account?: string;
  chainId?: ChainId;
}

const emptyArray = [];

export const useGetUserTokensQuery = ({ account, chainId }: UseDefiFormGetUserTokensQueryProps) => {
  const { data, error, isLoading, fulfilledTimeStamp, refetch } = ankr.useGetUserTokensListQuery(
    {
      account: account!,
      chainId: chainId!,
    },
    { skip: !account || !chainId }
  );

  return {
    data: data?.result?.assets || emptyArray,
    error,
    fulfilledTimeStamp,
    isLoading,
    refetch,
  };
};
