import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { connectMetamaskWallet, connectWcWallet } from "@src/store/slices/blockchainSlice";

export const useRestoreWalletProvider = (): void => {
  const dispatch = useAppDispatch();
  const chainId = useAppSelector((state) => state.blockchain.chainId);
  const walletType = useAppSelector((state) => state.blockchain.walletType);

  const restoreWallet = async () => {
    if (walletType === "METAMASK") {
      await dispatch(connectMetamaskWallet()).unwrap();
    }

    if (walletType === "WC") {
      await dispatch(connectWcWallet(chainId)).unwrap();
    }
  };

  useEffect(() => {
    void restoreWallet();
  }, []);
};
