import React from "react";
import { useNavigate } from "react-router-dom";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

const LinkExchangeButton = () => {
  const navigate = useNavigate();
  return (
    <AnbotoButton onClick={() => navigate("/settings/linked/exchanges")} color="secondary" variant="outlined">
      Link Exchange
    </AnbotoButton>
  );
};

export default LinkExchangeButton;
