import React from "react";
import { Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { PricesPostTradeAnalysis, SlippagesPostTradeAnalysis } from "@src/store/apis/anbotoApi/types";
import { formatUsd } from "@src/utils/format";
import {
  DashBoardTableCell,
  DashboardDataCard,
  FieldLabel,
  FieldValue,
  DashBoardTableHeaderCell,
} from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/styled-components";
import DashboardDataCardHeader from "@src/components/post-trade-analysis/post-trade-analysis/components/styled-components/dashboard-data-card-header";
import GreenLabel from "@src/components/post-trade-analysis/post-trade-analysis/components/green-label/green-label";
import AnbotoInfoIcon from "@src/components/anboto-info-icon/anboto-info-icon";

type PriceAndSlippageProps = {
  prices: PricesPostTradeAnalysis;
  slippages: SlippagesPostTradeAnalysis;
};

const createData = (type: string, price: number, slippage: number, title: string) => {
  return { type, price, slippage, title };
};

const SlippadgeAndPrice = ({ prices, slippages }: PriceAndSlippageProps) => {
  const rows = [
    createData(
      "Arrival price",
      prices?.arrival || 0,
      slippages?.arrival || 0,
      'Mid Price (simple average of best bid and best ask) at the order start ("arrival") time'
    ),
    createData(
      "TWAP",
      prices?.twap || 0,
      slippages?.twap || 0,
      "Time Weighted Average Price (TWAP)  is the simple average of  trade prices observed n the market during the period between order's Start Time and End Time"
    ),
    createData(
      "VWAP",
      prices?.vwap || 0,
      slippages?.vwap || 0,
      "Volume Weighted Average Price (VWAP)  is the volume-weighted average of trade prices observed n the market during the period between order's Start Time and End Time"
    ),
    createData(
      "Impacted price",
      prices?.impacted_price || 0,
      slippages?.impacted_price || 0,
      "The hyothetical average price of sending the entire parent order quantity to the exchange as a market order, i.e. of “walking up/down the order book” for buys/sells"
    ),
  ];

  return (
    <DashboardDataCard flex={0.93}>
      <DashboardDataCardHeader
        header="Slippage and price"
        title="This section provides key benchmark prices for the algo order, and slippage against them (+ve slippage = outperformed, -ve slippage = underperformed)"
      />

      <Stack
        alignItems="center"
        direction="row"
        gap={0.5}
        mb={1}
        py={0.5}
        px={1}
        sx={{ background: "#232C2F", borderRadius: 0.5 }}
      >
        <FieldLabel>
          Avg. exec. price: <FieldValue variant="caption">{formatUsd(prices?.avg_exec_price || 0)}</FieldValue>
        </FieldLabel>
        <AnbotoInfoIcon
          iconSize={18}
          title="Average executed price price of the order, i.e. total Amount executed  divided by total Quantity executed"
        />
      </Stack>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <DashBoardTableHeaderCell>Benchmark</DashBoardTableHeaderCell>
              <DashBoardTableHeaderCell align="right">Prices</DashBoardTableHeaderCell>
              <DashBoardTableHeaderCell align="right">Slippages</DashBoardTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.type} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <DashBoardTableCell>
                  <Stack direction="row" gap={0.5} alignItems="center">
                    <Typography fontSize={14}>{row.type}</Typography>
                    <AnbotoInfoIcon iconSize={16} title={row.title} />
                  </Stack>
                </DashBoardTableCell>
                <DashBoardTableCell align="right">{formatUsd(row?.price || 0)}</DashBoardTableCell>
                <DashBoardTableCell align="right">
                  <GreenLabel value={row?.slippage || 0} />
                </DashBoardTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardDataCard>
  );
};

export default SlippadgeAndPrice;
