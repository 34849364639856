import React, { forwardRef } from "react";
import { MenuItem } from "@mui/material";
import { ClipSizeType } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const CLIP_SIZE_TYPE_NAME: Record<ClipSizeType, string> = {
  [ClipSizeType.AUTOMATIC]: "Automatic",
  [ClipSizeType.ABSOLUTE]: "Absolute",
  [ClipSizeType.PERCENTAGE]: "Percentage",
  [ClipSizeType.NB_OF_CHILD_ORDERS]: "Nb of child orders",
};

const CLIP_SIZE_TYPES = [
  ClipSizeType.AUTOMATIC,
  ClipSizeType.ABSOLUTE,
  ClipSizeType.PERCENTAGE,
  ClipSizeType.NB_OF_CHILD_ORDERS,
];

export const ClipSizeTypeSelect = forwardRef((props: AnbotoTextFieldProps & { excludeTypes?: ClipSizeType[] }, ref) => {
  return (
    <AnbotoTextField {...props} inputRef={ref} select label="Clip size type" size="small" fullWidth>
      {CLIP_SIZE_TYPES.filter((type) => !(props?.excludeTypes || []).includes(type)).map((type) => (
        <MenuItem key={type} value={type}>
          {CLIP_SIZE_TYPE_NAME[type]}
        </MenuItem>
      ))}
    </AnbotoTextField>
  );
});

ClipSizeTypeSelect.displayName = "ClipSizeTypeSelect";
