import { styled, TableRow } from "@mui/material";

export const LeaderBoardTableRow = styled(TableRow)(() => ({
  background: "#192022",
  paddingLeft: 16,
  paddingRight: 16,
  "&>td": {
    border: 0,
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 8,
    color: "#B0BCC2",
    padding: 8,
  },
  "&>td:last-child": { paddingRight: 8 },
  "&>td:first-child": { paddingLeft: 8 },
}));
