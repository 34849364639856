import { createSlice } from "@reduxjs/toolkit";
import { getLivePriceId } from "./subscriptionsSlice";

export enum WatchlistView {
  TOP_LIST = "toplist",
  FAVOURITES = "favourites",
}

export interface Watchlist {
  displayFavouritesDialog: boolean;
  loadingSymbols: Record<string, boolean>;
}

const initialState: Watchlist = {
  displayFavouritesDialog: false,
  loadingSymbols: {},
};

export const watchlistSlice = createSlice({
  name: "watchlist",
  initialState,
  reducers: {
    toggleDisplayFavouritesDialog: (state) => {
      state.displayFavouritesDialog = !state.displayFavouritesDialog;
    },
    toggleLoadingSymbol: (state, { payload }) => {
      const id = getLivePriceId(payload);
      state.loadingSymbols[id] = state.loadingSymbols[id] ? false : true;
    },
  },
});

export const { toggleDisplayFavouritesDialog, toggleLoadingSymbol } = watchlistSlice.actions;

export default watchlistSlice.reducer;
