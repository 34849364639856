import React, { useMemo } from "react";
import { Paper, Typography } from "@mui/material";
import { format } from "date-fns";
import ComparativeFundingRatesChart from "@src/pages/analytics/funding-rates/comparative-funding-rates/comparative-funding-rates-chart";
import BoxPlot from "@src/pages/analytics/funding-rates/historical-data-funding-rates/historical-data-funding-rates";
import FundingRatesAssetsChart from "@src/pages/analytics/funding-rates/funding-rates-assets-history/funding-rates-assets-chart";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { FundingRate } from "@src/store/apis/anbotoApi/types";

type FundingRatesChartData = { [date: string]: { [symbol: string]: number; time: number } };

const FundingRates = () => {
  const fundingRatesData = anbotoApi.useGetFundingRatesQuery(null);

  const comparativeFundingRatesChartData = fundingRatesData?.data?.last_snapshot || [];
  const fundingRatesAssetsHistoricalData: { [p: string]: number; time: number }[] = useMemo(
    () =>
      Object.values(
        (fundingRatesData?.data?.historical_data || []).reduce((acc: FundingRatesChartData | {}, cur: FundingRate) => {
          acc[cur.time] = { ...acc[cur.time], [cur.symbol]: cur.rate, time: cur.time };
          return acc;
        }, {})
      ).sort((a, b) => a.time - b.time),
    [comparativeFundingRatesChartData]
  );

  const currentFundingRatesDate = comparativeFundingRatesChartData.length
    ? format(new Date(Number(comparativeFundingRatesChartData[0].time) * 1000), "dd-MM-yy | HH:mm zzz")
    : "";

  return (
    <Paper sx={{ flex: 1, padding: 4 }}>
      <Typography marginBottom={3} variant="h5">
        Funding Rates (Binance)
      </Typography>

      <Typography data-testid="comparative-funding-rates-header" variant="h6">
        Comparative funding rates {`(${currentFundingRatesDate})`}
      </Typography>
      <ComparativeFundingRatesChart
        data={comparativeFundingRatesChartData}
        isFetching={fundingRatesData.isFetching}
        isError={fundingRatesData.isError}
      />

      <Typography marginTop={3} variant="h6">
        Historical data
      </Typography>
      <BoxPlot
        data={fundingRatesAssetsHistoricalData}
        isFetching={fundingRatesData.isFetching}
        isError={fundingRatesData.isError}
      />

      <Typography marginTop={3} variant="h6">
        Funding rate assets history
      </Typography>
      <FundingRatesAssetsChart
        data={fundingRatesAssetsHistoricalData}
        isFetching={fundingRatesData.isFetching}
        isError={fundingRatesData.isError}
      />
    </Paper>
  );
};

export default FundingRates;
