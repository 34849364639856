import React from "react";
import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AllocationAccount } from "@src/store/slices/portfolioSlice";
import { numberToPercent } from "@src/pages/portfolio/utils/helpers";
import { CurrencyValue } from "@src/pages/portfolio";

type AllocationMenuItemProps = {
  item: AllocationAccount;
  isActive: boolean;
  setActive: (name: string) => void;
};

export const AllocationMenuItem = ({ isActive, item, setActive }: AllocationMenuItemProps) => {
  return (
    <MenuItem
      onClick={() => setActive(item.subaccount)}
      className={isActive ? "active" : ""}
      color="secondary"
      variant="outlined"
    >
      <Stack width="100%" display="flex" flexDirection="row" justifyContent="flex-start" gap={1}>
        {item.iconAddress && <img width="20px" src={item.iconAddress} alt={item.subaccount} />}
        <Typography width="100%" fontWeight={400} fontSize={14} color="#8A9296">
          {item.subaccount}
        </Typography>
      </Stack>
      <Stack width="100%" display="flex" flexDirection="row" justifyContent="space-between">
        <Typography fontWeight={600} fontSize={16}>
          ≈ <CurrencyValue value={item.value} />
        </Typography>
        {item.percent && (
          <Typography fontWeight={400} fontSize={14}>
            {numberToPercent(item.percent)}
          </Typography>
        )}
      </Stack>
    </MenuItem>
  );
};

const MenuItem = styled(AnbotoButton)(() => ({
  padding: "7px 10px",
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  ["&.active"]: {
    background: "#0A1C39",
    borderColor: "#2C72E3",
  },
}));
