import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAccount } from "wagmi";
import formValidator from "@src/utils/formValidator";
import { ChainId } from "@src/pages/defi/types";

export type DepositAndWithdrawForm = {
  walletAddress: string;
  chainId: number | ChainId;
  tokenAddress: string;
  tokenSymbol: string;
  tokenDecimals: number;
  amount: string;
  isDisabled: boolean;
};

export const depositSchema = formValidator
  .object({
    // walletAddress will be required when doing the off chain updating for the accounting
    // walletAddress: formValidator.string().required().min(1).label("Wallet address"),
    chainId: formValidator.string().required().min(1).label("Network"),
    tokenAddress: formValidator.string().required().min(1).label("Token address"),
    amount: formValidator.number().required().min(0.001, "Min quantity is 0.001").label("Quantity"),
    isDisabled: formValidator.bool().required(),
  })
  .required();

export const useDepositAndWithdrawForm = () => {
  const { chain } = useAccount();
  return useForm<DepositAndWithdrawForm>({
    resolver: yupResolver(depositSchema),
    defaultValues: {
      walletAddress: "",
      chainId: chain?.id || 0,
      tokenAddress: "",
      tokenSymbol: "",
      tokenDecimals: 0,
      amount: "",
      isDisabled: true,
    },
  });
};
