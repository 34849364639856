import React from "react";
import { Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, Typography } from "@mui/material";

interface AnbotoStepperProps {
  steps: string[];
  activeStep: number;
}

const AnbotoHorizontalStepper = ({ steps, activeStep }: AnbotoStepperProps) => {
  return (
    <Stepper activeStep={activeStep} connector={<Connector />}>
      {steps.map((label) => {
        return (
          <StyledStep key={label}>
            <StepLabel>
              <Typography fontSize={10}>{label}</Typography>
            </StepLabel>
          </StyledStep>
        );
      })}
    </Stepper>
  );
};

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#179B83",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#179B83",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[800],
    borderTopWidth: 3,
    borderRadius: 1,
    height: "100%",
  },
}));

const StyledStep = styled(Step)(() => ({
  "& .MuiStepLabel-root .Mui-completed.MuiStepIcon-root ": {
    width: 20,
    color: "#179B83",
  },
  "& .MuiStepLabel-root .Mui-active.MuiStepIcon-root": {
    color: "#179B83",
    width: 20,
  },
  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text ": {
    fill: "black",
  },
  "& .MuiStepLabel-root .MuiSvgIcon-root": {
    color: "#232C2F",
    width: 20,
  },
}));

export default AnbotoHorizontalStepper;
