import { useEffect, useCallback } from "react";
import { MarketDataSubscriptionType, MarketDataTickerMessage } from "../types";
import { getMarketDataSubscription } from "../index";
import { getLivePriceId, setTicker } from "@src/store/slices/subscriptionsSlice";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { useAppDispatch } from "@src/store/hooks";

const marketDataSubscription = getMarketDataSubscription();
const subscribed: string[] = [];

export const useAnbotoTickersToStoreSubscriptions = (symbols: OrderSymbol[] = []) => {
  const dispatch = useAppDispatch();

  const onMessage = useCallback((_, msg: MarketDataTickerMessage) => {
    const { data_type, symbol, exchange, market_type, data } = msg;

    if (data_type === MarketDataSubscriptionType.TICKER) {
      dispatch(
        setTicker({
          id: getLivePriceId({
            exchange,
            symbol,
            market_type,
          }),
          price: data.close,
          percentage: data.percentage,
          data,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (symbols.length) {
      symbols
        .filter((s) => !subscribed.includes(getLivePriceId(s)))
        .forEach(({ symbol, market_type, exchange }) => {
          subscribed.push(getLivePriceId({ symbol, market_type, exchange }));

          marketDataSubscription.subscribe(
            {
              symbol,
              market_type,
              data_type: MarketDataSubscriptionType.TICKER,
              exchange,
              params: {},
            },
            onMessage
          );
        });
    }
  }, [symbols.map((s) => getLivePriceId(s)).join()]);
};
