import React from "react";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logos from "@src/pages/auth/assets/auth-logo.svg";
import AnbotoVerticalStepper from "@src/components/anboto-stepper/anboto-vertical-stepper";
import { ONBOARDING_STEPS_NAMES } from "@src/pages/auth/common";
import { RootState } from "@src/store/types";
import { AccountTypes } from "@src/pages/auth/onboarding.slice";

const Sidebar = () => {
  const currentStep = useSelector((state: RootState) => state.onboarding.currentStep);
  const accountType = useSelector((state: RootState) => state.onboarding.accountType);
  const onboardingStepNames = Object.values(ONBOARDING_STEPS_NAMES);
  const steps = [
    onboardingStepNames[0],
    ...(accountType === AccountTypes.INDIVIDUAL ? [onboardingStepNames[1]] : [onboardingStepNames[2]]),
  ];

  return (
    <Stack>
      <Typography fontWeight={500} fontSize={24} color="#8A9296">
        Welcome to
      </Typography>
      <Link to="/">
        <img
          style={{
            height: 32,
            marginTop: 12,
          }}
          alt="Anboto"
          src={Logos}
        />
      </Link>
      <Typography marginBottom={3.125} marginTop={11.25} fontSize={16} fontWeight={400} color="#8A9296">
        Few things to activate
        <br /> your account
      </Typography>

      <AnbotoVerticalStepper steps={steps} activeStep={currentStep} />
    </Stack>
  );
};

export default Sidebar;
