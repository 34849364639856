import { PredefinedWidgetsLayout, WidgetName, WidgetsGridView, WidgetsLayout } from "../types";
import { createWidgetLayoutItem } from "../utils/create-widget-layout-item";
import { getLayout } from "./get-layout";

const layout: WidgetsLayout = {
  ...createWidgetLayoutItem(WidgetName.CefiOrderForm, getLayout(WidgetName.CefiOrderForm)),
  ...createWidgetLayoutItem(WidgetName.CefiSymbolChart, getLayout(WidgetName.CefiSymbolChart)),
  ...createWidgetLayoutItem(WidgetName.CefiOrdersTableFull, getLayout(WidgetName.CefiOrdersTableFull)),
};

export const defiPageLayout: PredefinedWidgetsLayout = {
  id: "basic",
  name: "Basic",
  version: 1,
  view: WidgetsGridView.DEFI,
  layout,
  groups: {},
};
