import { AnbotoSubscription, AnbotoSubscriptionOptions } from "../anboto-subscription";
import { MarketDataMessage, MarketDataSubscribeData } from "../types";

export class PlatformOrdersSubsctiption extends AnbotoSubscription<MarketDataSubscribeData, MarketDataMessage> {
  constructor(opts: AnbotoSubscriptionOptions) {
    super(opts);
  }

  getId = () => "";

  getIdFromMsg = () => "";
}
