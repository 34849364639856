import React, { FC, useState } from "react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { styled } from "@mui/material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { SendCryptoModal } from "@src/pages/portfolio";

export const SendCryptoButton: FC = () => {
  const [isOpen, toggleModal] = useState<boolean>(false);

  const handleCloseModal = () => {
    toggleModal(false);
  };

  return (
    <>
      <BalanceHeaderButton variant="outlined" onClick={() => toggleModal(true)}>
        Send Crypto
        <CallMadeIcon sx={{ marginLeft: 1, width: 20 }} />
      </BalanceHeaderButton>
      <SendCryptoModal onClose={handleCloseModal} isOpen={isOpen} />
    </>
  );
};

const BalanceHeaderButton = styled(AnbotoButton)({
  width: 140,
  height: 32,
  fontSize: 14,
  fontWeight: 600,
  padding: "6px 8px 6px 16px",
});
