import React, { forwardRef, useEffect } from "react";
import { Grid, Stack, Card, Typography, Button, styled, CircularProgress, Alert, Tooltip } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { getStyledIcon } from "@src/pages/settings/settings-thumb";
import { ExchangeConfigFormData } from "@src/pages/settings/exchange-settings/ExchangeConfigFormData";
import { ExchangeConfigListItem } from "@src/pages/settings/exchange-settings/ExchangeConfigListItem";
import { CefiExchangeId, Exchange } from "@src/store/apis/anbotoApi/types";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { isMainAccount } from "@src/pages/settings/helpers";
import { useAppSelector } from "@src/store/hooks";

type ExchangeConfigRowProps = {
  exchange: Exchange;
  disabled?: boolean;
  config?: ExchangeConfigListItem;
  onSubaccountAddClick?(): void;
  onSubaccountDeleteClick?(): void;
  ref?: React.RefObject<ExchangeConfigRowRef>;
  onChange?: (data: Record<string, string>) => void;
  deleteLoading?: boolean;
  optional?: boolean;
  disableName?: boolean;
  hideSecret?: boolean;
  hideDelete?: boolean;
  hideTitleDescription?: boolean;
};

export type ExchangeConfigRowRef = {
  getForm: () => UseFormReturn<ExchangeConfigFormData>;
};

export const ExchangeConfigRow = forwardRef(
  (
    {
      exchange,
      config,
      onSubaccountAddClick,
      onSubaccountDeleteClick,
      onChange,
      deleteLoading,
      optional,
      disableName,
      hideSecret,
      hideDelete,
      hideTitleDescription,
    }: ExchangeConfigRowProps,
    ref
  ) => {
    const getDefaultValues = () => ({
      apiKey: "",
      secretKey: "",
      subaccountTitle: config?.subaccountTitle || "",
      password: "",
    });

    const form = useForm<ExchangeConfigFormData>({
      defaultValues: getDefaultValues(),
    });

    const apiKey = form.watch("apiKey");
    const secretKey = form.watch("secretKey");

    React.useImperativeHandle(ref, () => ({
      getForm: () => form,
    }));

    useEffect(() => {
      form.reset(getDefaultValues());
      const subscription = form.watch((value) => onChange && onChange(value));
      return () => subscription.unsubscribe();
    }, [config]);

    const xs = hideSecret ? 12 : exchange.is_passphrase ? 4 : 6;

    const apiKeysErrors = useAppSelector((state) => state.uiSettings.apiKeysErrors);
    const currentApiKeyError =
      config?.id && apiKeysErrors && apiKeysErrors[config.id]
        ? apiKeysErrors[config.id]?.type === "ok"
          ? null
          : apiKeysErrors[config.id]
        : config?.error;

    return (
      <Stack gap={2} sx={{ pt: 2 }}>
        <Card variant="outlined" sx={{ pt: config?.subaccountTitle ? 2 : 1, pr: 2, pl: 2, pb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: config?.subaccountTitle ? 0.1 : 1 }}
          >
            {config?.subaccountTitle ? (
              <Controller
                control={form.control}
                name="subaccountTitle"
                rules={{
                  required: true,
                  validate: { isNotMainAccountName: (value) => !isMainAccount(value) },
                }}
                render={({ field, fieldState }) => (
                  <AnbotoTextField
                    {...field}
                    sx={{ maxWidth: 240 }}
                    label="Sub account name"
                    disabled={!!config.id || disableName}
                    required
                    fullWidth
                    error={!!fieldState.error || isMainAccount(field.value)}
                    size="medium"
                    variant="outlined"
                    helperText={isMainAccount(field.value) && "Shouldn't be named as 'main' or 'Main'"}
                  />
                )}
              />
            ) : (
              <>
                <Typography variant="body1" fontWeight="bold" flex={1}>
                  Main account
                </Typography>
                {exchange.is_subaccounts_support && (
                  <AddSubAccount variant="text" startIcon={<AddCircleOutlineIcon />} onClick={onSubaccountAddClick}>
                    Add sub account
                  </AddSubAccount>
                )}
              </>
            )}
            {!hideDelete && (
              <Stack ml={2}>
                {deleteLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  getStyledIcon(RemoveCircleOutlineIcon, onSubaccountDeleteClick)
                )}
              </Stack>
            )}
          </Stack>
          {config?.subaccountTitle && !hideTitleDescription && (
            <Typography variant="body2" sx={{ color: "#56C3F2", mb: 1.25 }}>
              Sub account name must be the same as your exchange label
            </Typography>
          )}
          <Grid container spacing={2} mt={hideTitleDescription ? 1 : 0}>
            <Grid item xs={xs}>
              <Controller
                control={form.control}
                name="apiKey"
                rules={{ required: (!config?.id && !optional) || secretKey }}
                render={({ field, fieldState }) => (
                  <AnbotoTextField
                    {...field}
                    label="API key"
                    size="medium"
                    fullWidth
                    required
                    labelStyle="dynamic"
                    InputLabelProps={{
                      shrink: !!config?.apiKey || undefined,
                    }}
                    error={!!fieldState.error}
                    disabled={!!config?.apiKey}
                    placeholder={config?.apiKey || "Enter your api key"}
                    data-sentry-mask="true"
                  />
                )}
              />
            </Grid>
            {!hideSecret && (
              <Grid item xs={xs}>
                <Controller
                  control={form.control}
                  name="secretKey"
                  rules={{ required: (!config?.id && !optional) || apiKey }}
                  render={({ field, fieldState }) => (
                    <AnbotoTextField
                      {...field}
                      label="Secret code"
                      size="medium"
                      labelStyle="dynamic"
                      InputLabelProps={{
                        shrink: !!config?.secretKey || undefined,
                      }}
                      required
                      fullWidth
                      error={!!fieldState.error}
                      disabled={!!config?.secretKey}
                      placeholder={config?.secretKey || "Enter your secret key"}
                      data-sentry-mask="true"
                    />
                  )}
                />
              </Grid>
            )}
            {exchange.is_passphrase && (
              <Grid item xs={xs}>
                <Controller
                  control={form.control}
                  name="password"
                  rules={{ required: (!config?.id && !optional) || apiKey || secretKey }}
                  render={({ field, fieldState }) => (
                    <AnbotoTextField
                      {...field}
                      label={
                        exchange.exchange_id === CefiExchangeId.GATEIO
                          ? "Gate account UID *"
                          : exchange.exchange_id === CefiExchangeId.WOOX
                          ? "Application Id *"
                          : "Passphrase *"
                      }
                      fullWidth
                      error={!!fieldState.error}
                      labelStyle="dynamic"
                      InputLabelProps={{
                        shrink: !!config?.password || undefined,
                      }}
                      size="medium"
                      disabled={!!config?.password}
                      placeholder={config?.password || "Enter your passphrase"}
                      data-sentry-mask="true"
                    />
                  )}
                />
              </Grid>
            )}

            {currentApiKeyError && (
              <Alert
                icon={<ErrorOutlineRoundedIcon sx={{ margin: 0, padding: 0, fontSize: 18 }} />}
                sx={{ padding: "0 8px", width: "100%", marginTop: 2, marginLeft: 2 }}
                severity="error"
              >
                <Tooltip sx={{ padding: 0 }} title={currentApiKeyError?.text}>
                  <Typography fontSize={13}>{currentApiKeyError?.type.split(/(?=[A-Z])/).join(" ")} </Typography>
                </Tooltip>
              </Alert>
            )}
          </Grid>
        </Card>
      </Stack>
    );
  }
);

ExchangeConfigRow.displayName = "ExchangeConfigRow";

const AddSubAccount = styled(Button)(({ theme }) => ({
  color: theme.custom.colors.secondary,
  fontSize: 14,
  fontWeight: "normal",
  textTransform: "none",
}));
