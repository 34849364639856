import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  BLANK_MESSAGE,
  generateRandomString,
  RANDOM_CODE_LENGTH,
  REFERRAL_PERCENT_AMOUNT,
  referralProgramApi,
} from "@src/pages/referral-program";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AnbotoButtonGroup, AnbotoButtonGroupOption } from "@src/components/ui/anboto-button-group";
import { RootState } from "@src/store/types";
import formValidator from "@src/utils/formValidator";
import { FormInputLabel } from "@src/pages/auth/layout/form-input-label";
import { muiTheme } from "@src/components/theme/mui-theme";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";

type ReferralDialogProps = {
  open: boolean;
  handleClose: () => void;
  refetchLinksList: () => void;
};
type createCodeFormValues = {
  code: string;
};

enum AccountChoiceType {
  Enabled = "enabled",
  Disabled = "disabled",
}

const accountTypeOptions: AnbotoButtonGroupOption<AccountChoiceType>[] = [
  { label: "Both individual and team account", value: AccountChoiceType.Enabled },
  { label: "Only individual account", value: AccountChoiceType.Disabled },
];

export const GenerateLinkDialog = ({ open, refetchLinksList, handleClose }: ReferralDialogProps) => {
  const { team_uuid } = useSelector((state: RootState) => state.user);

  const theme = useTheme();
  const snackbar = useSnackbar();
  const [accountCodeChoice, setAccountCodeChoice] = useState(AccountChoiceType.Enabled);

  const [rebate, setRebate] = useState<number>(0);
  const [getCodeAvailability] = referralProgramApi.useLazyGetCodeAvailabilityQuery();

  const validateCode = async (value: string | undefined) => {
    try {
      if (!value) return;
      const verifyCode = await getCodeAvailability({ code }).unwrap();
      if (verifyCode.code_exists) {
        createCodeForm.setError("code", {
          type: "server",
          message: "Code is already exists",
        });
      } else {
        createCodeForm.clearErrors();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createCodeFormValidator = formValidator.object({
    code: formValidator
      .string()
      .required()
      .min(2)
      .label("Code")
      .matches(/^[a-zA-Z0-9]+$/, "Only letters (A-Z, a-z) and numbers (0-9) are allowed"),
  });

  const createCodeForm = useForm<createCodeFormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(createCodeFormValidator),
    defaultValues: {
      code: "",
    },
  });

  const code = createCodeForm.watch("code");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      validateCode(code);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [code]);

  useEffect(() => {
    setRebate(0);
    createCodeForm.setValue("code", "");
  }, [open]);

  const [createCode] = referralProgramApi.useCreateCodeMutation();

  const createRandomCode = () => {
    createCodeForm.setValue("code", generateRandomString(RANDOM_CODE_LENGTH));
    validateCode(code);
  };

  const onCodeCreate = async () => {
    try {
      let accountChoiceEnabled;
      if (accountCodeChoice === AccountChoiceType.Enabled) {
        accountChoiceEnabled = true;
      } else {
        accountChoiceEnabled = false;
      }

      await createCode({
        team_uuid,
        body: { code, rebate, message: BLANK_MESSAGE, account_type_choice_enabled: accountChoiceEnabled },
      }).unwrap();
      refetchLinksList();
      handleClose();
    } catch (e) {
      snackbar.enqueueSnackbar(parseAnbotoRequestError(e?.data), { variant: "error" });
    }
  };

  const changeCodeAccountType = () => {
    if (accountCodeChoice === AccountChoiceType.Enabled) {
      setAccountCodeChoice(AccountChoiceType.Disabled);
    } else {
      setAccountCodeChoice(AccountChoiceType.Enabled);
    }
  };

  return (
    <Dialog sx={{ padding: 0 }} open={open} onClose={handleClose}>
      <Stack sx={{ width: 560, background: theme.custom.background.secondary }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height={48}
          sx={{ background: theme.custom.background.default }}
        >
          <Typography fontSize={18} fontWeight={500} paddingLeft={3}>
            Generate Link
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <DialogContent>
          <Stack direction="column" position="relative" gap={2}>
            <Stack>
              <Controller
                name="code"
                control={createCodeForm.control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState }) => (
                  <FormControl sx={{ width: "100%" }} size="small" variant="outlined">
                    <FormInputLabel fieldstate={fieldState}>Code*</FormInputLabel>
                    <OutlinedInput
                      {...field}
                      error={!!fieldState.error}
                      type="text"
                      label="Code"
                      sx={{ background: theme.custom.background.default }}
                    />
                    {fieldState.error ? (
                      <Typography sx={{ fontSize: 13 }} color={muiTheme.palette.error.main}>
                        {fieldState.error?.message || "Code is required"}
                      </Typography>
                    ) : null}
                  </FormControl>
                )}
              />
              <AnbotoButton
                sx={{ height: 32, position: "absolute", right: 4, top: 4 }}
                variant="outlined"
                onClick={() => createRandomCode()}
              >
                Random
              </AnbotoButton>
            </Stack>

            <Stack
              borderRadius={1}
              padding={2}
              sx={{ background: theme.palette.background.paper, color: theme.palette.text.secondary }}
            >
              <Typography fontSize={14}>Set the amount of fee benefit shared</Typography>

              <AnbotoButtonGroup<number>
                view="outlined"
                value={rebate}
                sx={{ mt: 1, display: "flex" }}
                options={REFERRAL_PERCENT_AMOUNT.map((value: number) => ({ label: `${value * 100}%`, value }))}
                onChange={setRebate}
              />
              <Stack marginTop={1} direction="row" justifyContent="space-between">
                <Stack>
                  <Typography fontSize={18}>You receive: </Typography>
                  <Typography fontWeight={600} fontSize={18} color={theme.palette.text.primary} variant="caption">
                    {(1 - rebate) * 100}%
                  </Typography>
                </Stack>

                <Stack alignItems="flex-end">
                  <Typography fontSize={18}>Referee receives: </Typography>
                  <Typography fontWeight={600} fontSize={18} color={theme.palette.text.primary} variant="caption">
                    {rebate * 100}%
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Typography fontSize={14}>
                During the onboarding process, the types of accounts that your referral code will grant access to are:
              </Typography>
              <AnbotoButtonGroup
                sx={{ mt: 1, display: "flex", border: `1px solid ${theme.custom.border.default}` }}
                size="small"
                view="compact"
                options={accountTypeOptions}
                onChange={changeCodeAccountType}
                value={accountCodeChoice}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: `1px solid ${theme.custom.background.inputAdornment.main}`,
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          <AnbotoButton
            disabled={code.length === 0}
            variant="contained"
            size="small"
            onClick={createCodeForm.handleSubmit(onCodeCreate)}
          >
            Generate
          </AnbotoButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
