import { useSelector } from "react-redux";
import { SELECT_ALL } from "@src/store/slices/portfolioSlice";
import { RootState } from "@src/store/types";
import { PortfolioItemType, Wallet } from "@src/store/apis/anbotoApi/types";
import { useUserWalletsList } from "@src/pages/portfolio/hooks/use-user-wallets-list";

export interface UseSelectedWalletsResult {
  isError: boolean;
  isLoading: boolean;
  isFetchingWallets: boolean;
  selectedWalletsAddresses: string[];
  selectedWalletsNames: string[];
  selectedWallets: Wallet[];
  refetch: () => void;
  walletsData: Wallet[];
  portfolioList: PortfolioItemType[];
}

export interface WalletsSplitResult {
  selectedWallets: Wallet[];
  portfolioList: PortfolioItemType[];
}

const emptyWalletsSplitResult: WalletsSplitResult = { selectedWallets: [], portfolioList: [] };

export const useSelectedWallets = (): UseSelectedWalletsResult => {
  const { data, isError, isLoading, isFetching: isFetchingWallets, refetch } = useUserWalletsList();
  const selectedWallet = useSelector((state: RootState) => state.portfolio.selectedWallet);

  const { selectedWallets, portfolioList }: WalletsSplitResult =
    data?.results?.reduce(
      (result: WalletsSplitResult, item: Wallet) => {
        if (item.is_show_in_portfolio) {
          result.selectedWallets.push(item);
        }
        result.portfolioList.push({ item, isAdded: !!item.is_show_in_portfolio });

        return result;
      },
      { selectedWallets: [], portfolioList: [] }
    ) || emptyWalletsSplitResult;

  // you can select all, one
  const currentSelectedWallets =
    selectedWallet === SELECT_ALL ? selectedWallets : data?.results?.filter((wallet) => wallet.name === selectedWallet);

  return {
    isError,
    isLoading,
    isFetchingWallets,
    selectedWalletsNames:
      selectedWallet !== SELECT_ALL
        ? [selectedWallet]
        : selectedWallets?.map((x) => {
            return x.name;
          }) || [],
    selectedWalletsAddresses: currentSelectedWallets?.map((wallet) => wallet.address) || [],
    selectedWallets,
    portfolioList,
    refetch,
    walletsData: data?.results || [],
  };
};
