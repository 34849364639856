import { useEffect, useState } from "react";
import { SymbolSelectTab } from "../types";
import { useLinkedExchanges } from "@src/pages/cefi/hooks/use-linked-exchanges";
import { useAppSelector } from "@src/store/hooks";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { CefiExchangeId, CefiExchangeMarketType } from "@src/store/apis/anbotoApi/types";
import { OtcMarketType } from "@src/features/otc/types";

export const useSymbolSelectFilters = (exchanges?: Record<CefiExchangeId, boolean>, otc?: boolean) => {
  const [filters, setFilters] = useState("");
  const type = otc ? "otc" : "cefi";
  const selectedExchanges = useAppSelector((state) => state.symbolSelect.selectedExchanges[type]) || {};
  const linkedExchanges = useLinkedExchanges();
  const hasExchangesFilter = Object.keys(selectedExchanges).find(
    (key) => selectedExchanges[key] && linkedExchanges.exchanges.includes(key)
  );
  const currentTab = useAppSelector((state) => state.symbolSelect.tab[type]);
  const marketType =
    currentTab === SymbolSelectTab.FUTURE
      ? CefiExchangeMarketType.FUTURE
      : currentTab === SymbolSelectTab.SPOT
      ? CefiExchangeMarketType.SPOT
      : currentTab === SymbolSelectTab.CFD
      ? OtcMarketType.CFD
      : undefined;

  const _exchanges = hasExchangesFilter
    ? linkedExchanges.exchanges?.filter((exchange) => selectedExchanges[exchange])
    : linkedExchanges.exchanges || [];

  let exchangeFilter = _exchanges
    ?.filter((exchange) => (exchanges ? exchanges[exchange] : true))
    .map((exchange) => (CEFI_EXCHANGE_NAME[exchange] ? `exchange:${CEFI_EXCHANGE_NAME[exchange]}` : ""))
    .filter(Boolean)
    .join(" OR ");

  exchangeFilter = exchangeFilter ? `(${exchangeFilter})` : "";

  const marketTypeFilter = marketType ? `market_type: ${marketType}` : "";
  const filterString = [marketTypeFilter, exchangeFilter].filter(Boolean).join(" AND ");

  useEffect(() => {
    setFilters(filterString);
  }, [filterString]);

  return filters;
};
