import { DEFAULT_SYMBOL } from "../constants";
import { PredefinedWidgetsLayout, WidgetName, WidgetsGridView, WidgetsLayout } from "../types";
import { createWidgetLayoutItem } from "../utils/create-widget-layout-item";
import { getLayout } from "./get-layout";

export const layout: WidgetsLayout = {
  ...createWidgetLayoutItem(
    WidgetName.CefiOrderForm,
    getLayout(WidgetName.CefiOrderForm, {
      xxl: { x: 0, y: 0, w: 3 },
      xl: { x: 0, y: 0, w: 4 },
      lg: { x: 0, y: 0, w: 5 },
      md: { x: 0, y: 0, w: 8 },
      sm: { x: 0, y: 0, w: 8 },
      xs: { x: 0, y: 0, w: 24 },
    })
  ),
  ...createWidgetLayoutItem(
    WidgetName.CefiPriceImpact,
    getLayout(WidgetName.CefiPriceImpact, {
      xxl: { x: 3, y: 0, w: 2 },
      xl: { x: 4, y: 0, w: 3 },
      lg: { x: 5, y: 0, w: 4 },
      md: { x: 8, y: 0, w: 5 },
      sm: { x: 8, y: 0, w: 5 },
      xs: { x: 0, y: 0, w: 24 },
    })
  ),
  ...createWidgetLayoutItem(
    WidgetName.CefiSymbolChart,
    getLayout(WidgetName.CefiSymbolChart, {
      xxl: { x: 5, y: 0, w: 19 },
      xl: { x: 7, y: 0, w: 17 },
      lg: { x: 9, y: 0, w: 15 },
      md: { x: 13, y: 0, w: 11 },
      sm: { x: 13, y: 0, w: 11 },
      xs: { x: 0, y: 0, w: 24 },
    })
  ),
  ...createWidgetLayoutItem(
    WidgetName.CefiOrdersTableFull,
    getLayout(WidgetName.CefiOrdersTableFull, {
      xxl: { x: 0, y: 19, w: 24 },
      xl: { x: 0, y: 19, w: 24 },
      lg: { x: 0, y: 19, w: 24 },
      md: { x: 0, y: 19, w: 24 },
      sm: { x: 0, y: 19, w: 24 },
      xs: { x: 0, y: 19, w: 24 },
    })
  ),
};

export const cefiPageLayoutBasic: PredefinedWidgetsLayout = {
  id: "basic",
  name: "Basic",
  view: WidgetsGridView.CEFI,
  version: 1,
  layout,
  groups: {
    1: {
      symbol: DEFAULT_SYMBOL,
    },
  },
};
