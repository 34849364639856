import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type LimitPriceFieldProps = ControllerRenderProps & AnbotoTextFieldProps & { coin?: string };

export const LimitPriceField = forwardRef(({ coin, error, ...props }: LimitPriceFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    inputRef={ref}
    error={error}
    size="small"
    fullWidth
    type="number"
    placeholder={"Price " + (coin ? `(${coin})` : "")}
  />
));

LimitPriceField.displayName = "LimitPriceField";
