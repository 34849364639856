import React, { useEffect, useRef, useState } from "react";
import { Box, Button, MobileStepper, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { RootState } from "@src/store/types";
import {
  ChartNames,
  MAX_NUMBER_TOKEN_ALLOCATION_CHARTS,
  MAX_NUMBER_TOKEN_PROTOCOL_CHARTS,
  MAX_NUMBER_WALLETS_CHARTS,
} from "@src/pages/portfolio/constant";
import { getCountChartsInSlide, getWalletName } from "@src/pages/portfolio/utils/helpers";
import { PieChartCard } from "@src/pages/portfolio/components/charts/pie-chart-card";
import { SELECT_ALL } from "@src/store/slices/portfolioSlice";
import { useComponentWidth } from "@src/pages/portfolio/hooks/use-component-width";
import { useSelectedWallets } from "@src/pages/portfolio";

type PieChartsDefiProps = {
  isFetching: boolean;
};

export const PieChartsDefi = ({ isFetching }: PieChartsDefiProps) => {
  const { selectedWallets, portfolioList } = useSelectedWallets();
  const selectedWallet = useSelector((state: RootState) => state.portfolio.selectedWallet);

  const tokensAllocation = useSelector((state: RootState) => state.portfolio.tokenDefiAllocation);
  let tokensAllocationChart = tokensAllocation
    ?.map((item) => {
      return { name: item.tokenName, value: item.value };
    })
    .slice(0, MAX_NUMBER_TOKEN_ALLOCATION_CHARTS);
  if (tokensAllocation.length > MAX_NUMBER_TOKEN_ALLOCATION_CHARTS) {
    const othersTokenAllocationChartBalance = tokensAllocation
      .slice(MAX_NUMBER_TOKEN_ALLOCATION_CHARTS, tokensAllocation.length)
      .reduce((n, { value }) => n + value, 0);
    tokensAllocationChart = [...tokensAllocationChart, { name: "Others", value: othersTokenAllocationChartBalance }];
  }

  const protocolAllocation = useSelector((state: RootState) => state.portfolio.accountDefiAllocation);
  let protocolAllocationChart = protocolAllocation
    ?.map((item) => {
      return { name: item.subaccount, value: item.value };
    })
    .slice(0, MAX_NUMBER_TOKEN_PROTOCOL_CHARTS);
  if (protocolAllocation.length > MAX_NUMBER_TOKEN_PROTOCOL_CHARTS) {
    const othersProtocolAllocationChartBalance = protocolAllocation
      .slice(MAX_NUMBER_TOKEN_PROTOCOL_CHARTS, protocolAllocation.length)
      .reduce((n, { value }) => n + value, 0);
    protocolAllocationChart = [
      ...protocolAllocationChart,
      {
        name: "Others",
        value: othersProtocolAllocationChartBalance,
      },
    ];
  }

  const walletAllocation = useSelector((state: RootState) => state.portfolio.walletAllocation);
  let walletAllocationChart = walletAllocation
    ?.map((item) => {
      return { name: getWalletName(selectedWallets, item.walletAddress), value: item.value };
    })
    .slice(0, MAX_NUMBER_WALLETS_CHARTS);
  if (walletAllocation.length > MAX_NUMBER_WALLETS_CHARTS) {
    const othersWalletAllocationChartBalance = walletAllocation
      .slice(MAX_NUMBER_WALLETS_CHARTS, walletAllocation.length)
      .reduce((n, { value }) => n + value, 0);
    walletAllocationChart = [
      ...walletAllocationChart,
      {
        name: "Others",
        value: othersWalletAllocationChartBalance,
      },
    ];
  }

  const steps = [
    ...(selectedWallet === SELECT_ALL
      ? [
          <PieChartCard
            isFetching={isFetching}
            chartName={ChartNames.WalletAllocation}
            chartData={walletAllocationChart}
            linkButton={portfolioList?.length === 0}
          />,
        ]
      : []),
    <PieChartCard isFetching={isFetching} chartName={ChartNames.TokenAllocation} chartData={tokensAllocationChart} />,
    <PieChartCard
      isFetching={isFetching}
      chartName={ChartNames.ProtocolsAllocation}
      chartData={protocolAllocationChart}
    />,
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const componentRef = useRef();
  const componentWidth = useComponentWidth(componentRef);
  const [countOfChartsPerSlide, setCountOfChartsPerSlide] = useState(getCountChartsInSlide(componentWidth));
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"));
  useEffect(() => {
    setCountOfChartsPerSlide(getCountChartsInSlide(componentWidth));
  }, [componentWidth]);

  const maxSteps = steps.length > countOfChartsPerSlide ? Math.floor(steps.length / countOfChartsPerSlide) + 1 : 1;

  useEffect(() => {
    setActiveStep(0);
  }, [selectedWallet]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box ref={componentRef}>
      <Box>
        <Stack
          sx={{
            flexDirection: "row",
            gap: isBigScreen ? 10 : 2,
            justifyContent: "center",
            paddingBottom: 3,
          }}
        >
          {steps.slice(activeStep, countOfChartsPerSlide + activeStep).map((i) => i)}
        </Stack>
      </Box>
      <MobileStepper
        steps={maxSteps}
        activeStep={activeStep}
        position="static"
        nextButton={
          <Button
            className="navigation rightNav"
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <ArrowForward />
          </Button>
        }
        backButton={
          <Button className="navigation leftNav" size="small" onClick={handleBack} disabled={activeStep === 0}>
            <ArrowBack />
          </Button>
        }
        sx={{
          height: 0,
          padding: 0,
          background: "transparent",
          "& .MuiMobileStepper-dots": {
            display: "none",
          },
          "& .navigation": {
            top: -135,
            color: "#8A9296",
            ...(steps.length <= countOfChartsPerSlide && { display: "none" }),
          },
          "& .navigation.rightNav": {
            ...(!isBigScreen && { left: 30 }),
          },
          "& .navigation.leftNav": {
            ...(!isBigScreen && { left: -30 }),
          },
          "& .navigation:hover": {
            background: "transparent",
            color: "#d9d9d9",
          },
          "& .navigation.Mui-disabled": {
            opacity: 0.3,
          },
        }}
      />
    </Box>
  );
};
