import { WidgetConfig, WidgetName } from "../../types";
import { MAX_GROUPS } from "../../constants";

export const initialLayout = {
  xxl: { x: 6, y: 0, w: 9, h: 14 },
  xl: { x: 9, y: 0, w: 5, h: 14 },
  lg: { x: 12, y: 0, w: 6, h: 14 },
  md: { x: 15, y: 0, w: 9, h: 14 },
  sm: { x: 0, y: 0, w: 7, h: 14 },
  xs: { x: 0, y: 0, w: 7, h: 14 },
};

export const OtcSymbolChartWidgetConfig = {
  name: WidgetName.OtcSymbolChart,
  layout: initialLayout,
  options: {
    multiple: true,
    removable: true,
    withGroups: true,
    maxNumber: MAX_GROUPS,
    symbolSelect: true,
    otc: true,
  },
} as WidgetConfig;
