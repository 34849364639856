import React from "react";
import { Skeleton, Stack, styled, Tooltip, Typography, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { GenerateLinkDialog, referralProgramApi } from "@src/pages/referral-program";
import { RootState } from "@src/store/types";
import { formatUsd } from "@src/utils/format";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { feeManagerApi } from "@src/pages/settings/fee-manager/api";
import { useAppSelector } from "@src/store/hooks";
import { useDialog } from "@src/hooks/useDialog";

export const ReferralSummary = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dialog = useDialog();

  const { team_uuid } = useSelector((state: RootState) => state.user);

  const { data: balanceData } = feeManagerApi.useGetFeeTokensAndBalancesQuery();
  const { data, isError, isFetching } = referralProgramApi.useGetReferrerSummaryQuery({ team_uuid });

  const withdrawButtonVisible = balanceData?.balance > 0;

  const referral_link_limit = useAppSelector((state: RootState) => state.user.referral_link_limit)!;

  const getCodeList = referralProgramApi.useGetCodesListQuery({ team_uuid });
  const isEmptyReferralList = getCodeList?.data?.codes_details.length === 0;

  return (
    <Stack mb={3}>
      {isError ? (
        <Typography>Something went wrong. Please reload the page</Typography>
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            px={3}
            py={2}
            color={theme.palette.text.secondary}
            sx={{ background: "#232C2F", borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
          >
            <Stack direction="row" gap={0.5} alignItems="center">
              <Typography fontWeight={500} fontSize={16} color={theme.palette.text.secondary}>
                Remaining referral
              </Typography>
              {getCodeList?.isFetching ? (
                <Skeleton width={40} />
              ) : (
                <Typography fontWeight={500} fontSize={20} color="#B0BCC2">
                  {isEmptyReferralList
                    ? referral_link_limit
                    : referral_link_limit - (getCodeList?.data?.codes_details || []).length}
                  /{referral_link_limit}
                </Typography>
              )}
            </Stack>
            <AnbotoButton
              sx={{
                width: 200,
                height: 32,
                fontSize: 14,
                fontWeight: 500,
              }}
              size="small"
              variant="outlined"
              endIcon={<VpnKeyIcon />}
              onClick={dialog.show}
              disabled={referral_link_limit - (getCodeList?.data?.codes_details || []).length <= 0}
            >
              Create referral code
            </AnbotoButton>
          </Stack>

          <Stack
            sx={{
              background: "#192022",
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
            p={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row">
              <Stack pr={4} sx={{ borderRight: "1px solid #3B4043" }}>
                <Field>Referral made:</Field>
                <Value>{isFetching ? <Skeleton sx={{ minWidth: 50 }} /> : data?.total_referees}</Value>
              </Stack>
              <Stack pl={4}>
                <Stack direction="row" gap={0.5} alignItems="center">
                  <Field>Total rewards:</Field>
                  <Tooltip title="Since joined the referral program" placement="top">
                    <InfoIcon sx={{ color: theme.custom.colors.dim, cursor: "pointer", fontSize: 14 }} />
                  </Tooltip>
                </Stack>
                <Value>
                  {isFetching ? <Skeleton sx={{ minWidth: 50 }} /> : formatUsd(data?.total_fees_reward || 0)}
                </Value>
              </Stack>
            </Stack>
            {withdrawButtonVisible && (
              <AnbotoButton
                sx={{
                  width: 96,
                  height: 32,
                  padding: "6px 19px 5px 17px",
                }}
                onClick={() => navigate("/settings/fees")}
                size="small"
                variant="contained"
              >
                Withdraw
              </AnbotoButton>
            )}
          </Stack>
        </>
      )}
      <GenerateLinkDialog refetchLinksList={getCodeList.refetch} open={dialog.isOpen} handleClose={dialog.hide} />
    </Stack>
  );
};

const Field = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.text.secondary,
  alignItems: "center",
}));

const Value = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 500,
  color: "#B0BCC2",
}));
