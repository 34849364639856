import React, { FC } from "react";
import { DialogContent, DialogTitle, IconButton, Stack, styled, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import SVG from "react-inlinesvg";
import { CardDialog } from "@src/components/CardDialog";
import { CHAIN_IDS, CHAIN_LOGO, CHAIN_NAME } from "@src/pages/defi/constants";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { ChainId } from "@src/pages/defi/types";

export interface SelectNetworkDialogProps {
  chainId: ChainId;
  isOpen: boolean;
  onChange: (x: ChainId) => void;
  onClose(): void;
}

export const SelectNetworkDialog: FC<SelectNetworkDialogProps> = ({ chainId, isOpen, onChange, onClose }) => {
  const onChainSelect = (x: ChainId) => {
    onChange(x);
    onClose();
  };

  return (
    <CardDialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontSize={16} lineHeight="21px" fontWeight={600}>
            Select Network
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ px: 2, paddingTop: "16px !important" }}>
        <Stack direction="column" gap={1}>
          {CHAIN_IDS.map((x) => (
            <ChainItemButton key={x} onClick={() => onChainSelect(x)} isSelected={chainId === x}>
              <SVG src={CHAIN_LOGO[x]} height={20} />
              &nbsp;
              {CHAIN_NAME[x]}
            </ChainItemButton>
          ))}
        </Stack>
      </DialogContent>
    </CardDialog>
  );
};

const ChainItemButton = styled(AnbotoButton)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  width: "100%",
  height: 64,
  background: isSelected ? theme.custom.background.inputAdornment.main : theme.custom.background.default,
  borderRadius: 4,
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(2),
  alignItems: "center",
  justifyContent: "flex-start",
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "24px",

  "&:hover": {
    background: theme.custom.background.inputAdornment.light,
  },
}));
