import React from "react";
import { styled } from "@mui/material";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";
import { ParentOrder } from "@src/store/apis/anbotoApi/types";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";

export interface OrderSymbolProps {
  order: ParentOrder;
  onSymbolClick?: (order: ParentOrder) => void;
  supported: boolean;
  title?: string;
}

export const OrderSymbol = ({ order = {} as ParentOrder, onSymbolClick, supported, title }: OrderSymbolProps) => {
  const label =
    title ||
    (order.asset_class
      ? `${getOrderSymbolLabel(
          {
            symbol: order.symbol,
            exchange: CEFI_EXCHANGE_NAME[order.exchange],
            market_type: order.asset_class,
          },
          ["symbol"]
        )} - ${getExchangeNameLabel(CEFI_EXCHANGE_NAME[order.exchange])} - ${order.asset_class}`
      : "-");

  return supported ? <Link onClick={() => onSymbolClick && onSymbolClick(order)}>{label}</Link> : <>{label}</>;
};

const Link = styled("span")(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.text.primary,
  textDecoration: "underline",
}));
