export enum TabLabel {
  WouldPrice = "Would Price",
  LimitPrice = "Limit Price",
  TriggerPrice = "Trigger Price",
  MakerPlacement = "Maker Placement",
  ClipSize = "Clip Size",
  Expiration = "Expiration",
  Pov = "PoV",
}

export enum TabValue {
  Pov = "pov",
  WouldStyle = "would-style",
  LimitPrice = "limit-price",
  TriggerPrice = "trigger-price",
  MakerPlacement = "maker-placement",
  ClipSize = "clip-size",
  Expiration = "expiration",
}
