import { Action } from "@src/store/slices/feeManagerSlice";

export const ACTION_NAME = {
  [Action.DEPOSIT]: "Deposit",
  [Action.WITHDRAWAL]: "Withdrawal",
  [Action.FEE_CHARGE]: "Fee charge",
};

export const MIN_DEPOSIT_AMOUNT_IN_USD = 5;
// to allow to inputing stable coin as 1 coin ~ 1 USD
export const PERCENTAGE_TOLERANCE = 1;

export const DEFAULT_FEE_MANAGER_BALANCES = {
  claimableBalance: 0,
  requestableBalance: 0,
  unclaimableBalance: 0,
};

export const FEEBOTO_COINGECKO_ID = "feeboto";
export const FEEBOTO_PRICE_USD = 1;
