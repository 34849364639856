import * as React from "react";
import {
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  ColumnDef,
  SortDirection,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { Box, Skeleton, Stack } from "@mui/material";
import { useVirtual } from "react-virtual";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import styled from "@emotion/styled";
import { MemoizedOrderRow } from "./order-row";
import { MemoizedHeaderRow } from "./header-row";
import { TableHeaderCell, TableCell } from "@src/components/orders-table";
import { TableDataError } from "@src/components/table-data-error";
import { NoTableData } from "@src/components/no-table-data";
import { ParentOrder } from "@src/store/apis/anbotoApi/types";

export const renderSortArrows = (sort?: false | SortDirection) => {
  if (!sort) return null;

  return sort === "desc" ? (
    <ArrowDownwardIcon sx={{ width: 16, height: 16, color: (theme) => theme.palette.text.secondary }} />
  ) : (
    <ArrowUpwardIcon sx={{ width: 16, height: 16, color: (theme) => theme.palette.text.secondary }} />
  );
};

export function AnbotoTable({
  data,
  columns,
  loading,
  expandedRowRender,
  error,
  noDataComponent,
  noDataText,
  refetch,
  getRowId,
}: {
  error: boolean;
  data: ParentOrder[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<ParentOrder, any>[];
  loading: boolean;
  expandedRowRender?: (data: ParentOrder) => React.ReactNode;
  noDataComponent?: React.ReactElement;
  noDataText?: string;
  refetch?: () => void;
  getRowId: (data: ParentOrder) => string;
}) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    sortingFns: {},
  });

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    estimateSize: React.useCallback(() => 35, []),
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        flex: 1,
        fontSize: 12,
        overflow: "auto",
      }}
      ref={tableContainerRef}
    >
      {table.getHeaderGroups().map((headerGroup) => (
        <MemoizedHeaderRow
          loading={loading}
          sortDesc={table.getState().sorting[0]?.desc}
          sortId={table.getState().sorting[0]?.id}
          key={headerGroup.id}
          sx={{
            minWidth: 1200,
            zIndex: 2,
            height: 34,
            position: "sticky",
            top: 0,
            background: (theme) => theme.palette.background.paper,
          }}
        >
          {headerGroup.headers.map((header, index) => (
            <HeaderCell
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
              sx={{
                cursor: header.column.getCanSort() ? "pointer" : "",
                width: header.column.getSize(),
              }}
              flexGrow={index ? undefined : "0 !important"}
            >
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              {renderSortArrows(header.column.getIsSorted())}
            </HeaderCell>
          ))}
        </MemoizedHeaderRow>
      ))}
      {error ? (
        <InfoContainer>
          <TableDataError onRefresh={() => refetch && refetch()} />
        </InfoContainer>
      ) : data.length === 0 && !loading ? (
        <InfoContainer>{noDataComponent || <NoTableData message={noDataText} />}</InfoContainer>
      ) : (
        <>
          {paddingTop > 0 && <div style={{ height: `${paddingTop}px` }} />}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index] as Row<ParentOrder>;
            return (
              <MemoizedOrderRow
                key={getRowId(row.original) || virtualRow.index}
                loading={loading}
                orderId={getRowId(row.original)}
                filledQuantity={row.original.last_status?.filled_quantity}
                averagePrice={row.original.last_status?.average_price}
                errorCode={row.original.last_status?.error_code}
                leavesQuantity={row.original.last_status?.leaves_quantity}
                status={row.original.last_status?.status}
                expanded={row.getIsExpanded()}
                sx={{ minWidth: 1200 }}
              >
                <Stack direction="row">
                  {row.getVisibleCells().map((cell, index) => (
                    <Cell
                      key={cell.id}
                      loading={loading}
                      width={cell.column.getSize()}
                      flexGrow={index ? undefined : "0 !important"}
                    >
                      {loading ? (
                        <Skeleton
                          key={cell.id}
                          sx={{ flex: 1, width: cell.column.getSize(), height: 16 }}
                          variant="rectangular"
                          animation="wave"
                        />
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </Cell>
                  ))}
                </Stack>
                {!loading && row.getIsExpanded() && expandedRowRender && expandedRowRender(row.original)}
              </MemoizedOrderRow>
            );
          })}
          {paddingBottom > 0 && <div style={{ height: `${paddingBottom}px` }} />}
        </>
      )}
    </Box>
  );
}

// eslint-disable-next-line react/display-name
export const MemoizedOrdersTable = React.memo(
  AnbotoTable,
  (prev, next) => prev.data === next.data && prev.columns === next.columns && prev.loading === next.loading
) as typeof AnbotoTable;

const InfoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: 2,
  justifyContent: "center",
  height: 238,
});

const HeaderCell = styled(TableHeaderCell)(({ theme }) => ({
  background: theme.palette.background.paper,
  flexShrink: 1,
  flexGrow: 1,
  flexWrap: "nowrap",
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.secondary,
}));

const Cell = styled(TableCell)({
  flexShrink: 1,
  flexGrow: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  height: 34,
});
