import React, { useState } from "react";
import { Autocomplete, CircularProgress, Stack, TextField, Typography, useTheme } from "@mui/material";
import { CartesianGrid, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { COLORS } from "@src/pages/portfolio/constant";
import { get3MonthAgoTimeStamp, percentFormatter } from "@src/pages/analytics/helpers";
import AssetsChartTooltip from "@src/pages/analytics/funding-rates/funding-rates-assets-history/assets-chart-tooltip";
import AngleAxisTimeTick from "@src/pages/analytics/funding-rates/funding-rates-assets-history/angle-axis-time-tick";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

type FundingRatesAssetsHistoricalProps = {
  data: { [symbol: string]: number }[];
  isFetching: boolean;
  isError: boolean;
};

const FundingRatesAssetsChart = ({ data, isFetching, isError }: FundingRatesAssetsHistoricalProps) => {
  const theme = useTheme();

  if (!data.length) return null;

  const lastDate = data.reduce((time, item) => (item.time > time ? item.time : time), 0);
  const threeMonthAgoTimestamp = get3MonthAgoTimeStamp(lastDate * 1000);
  const lastThreeMonthData = data.filter((x) => x.time >= threeMonthAgoTimestamp);

  const initialState = {
    chartData: lastThreeMonthData,
    refAreaLeft: "",
    refAreaRight: "",
    animation: false,
  };
  const [chartState, setChartState] = useState(initialState);
  const tickers = Object.keys(data[0] || []).filter((x) => x !== "time");
  const [currentTickers, setCurrentTickers] = useState<string[]>([...tickers]);

  const zoom = () => {
    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setChartState({
        ...chartState,
        refAreaLeft: "",
        refAreaRight: "",
      });
      return;
    }

    setChartState({
      ...chartState,
      refAreaLeft: "",
      refAreaRight: "",
      chartData: chartData.filter((x) =>
        refAreaLeft <= refAreaRight
          ? Number(refAreaLeft) <= x.time && x.time <= Number(refAreaRight)
          : Number(refAreaRight) <= x.time && x.time <= Number(refAreaLeft)
      ),
    });
  };

  const zoomOut = () => {
    setChartState({
      ...chartState,
      chartData: data.slice(),
      refAreaLeft: "",
      refAreaRight: "",
    });
  };

  const handleChange = (event, currentValue) => {
    setCurrentTickers(typeof currentValue === "string" ? currentValue.split(",") : currentValue);
  };

  const { chartData, refAreaLeft, refAreaRight } = chartState;

  return (
    <>
      {isError ? (
        <Stack height={300} direction="column" justifyContent="center" textAlign="center">
          <Typography>Something went wrong. Please, try again later</Typography>
        </Stack>
      ) : isFetching ? (
        <Stack height={300} direction="column" justifyContent="center" alignItems="center" textAlign="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Stack padding={1} paddingBottom={10} sx={{ background: "#031116" }}>
          <Stack marginBottom={0.5} direction="row" justifyContent="space-between" gap={1}>
            <Autocomplete
              onChange={handleChange} // prints the selected value
              size="small"
              multiple
              options={tickers}
              getOptionLabel={(option) => option}
              defaultValue={[...currentTickers]}
              renderInput={(params) => (
                <TextField data-testId="ticker-picker" {...params} label="tickers" placeholder="Tickers" />
              )}
              sx={{ minWidth: "200px" }}
            />
            <AnbotoButton
              sx={{ height: 35, width: 150, alignSelf: "right" }}
              size="small"
              variant="outlined"
              onClick={zoomOut}
              data-testid="rates-history-zoom-out"
            >
              Zoom Out
            </AnbotoButton>
          </Stack>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={500}
              height={300}
              onMouseDown={(e) => setChartState({ ...chartState, refAreaLeft: e?.activeLabel || "" })}
              onMouseMove={(e) =>
                setChartState({
                  ...chartState,
                  refAreaRight: e.activeLabel || "",
                })
              }
              onMouseUp={zoom}
              data={chartData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid stroke={theme.custom.background.secondary} vertical={false} strokeDasharray="5 5" />
              <XAxis height={60} tick={(props) => <AngleAxisTimeTick {...props} />} dataKey="time" />
              <YAxis tick={{ fontSize: 12, fill: "white" }} tickFormatter={(data) => percentFormatter(data)} />
              <Tooltip content={<AssetsChartTooltip />} />
              {tickers.map((ticker, i) => (
                <Line
                  key={ticker}
                  isAnimationActive={false}
                  type={"stepAfter"}
                  hide={currentTickers.indexOf(ticker) <= -1}
                  dot={false}
                  dataKey={ticker}
                  stroke={COLORS[i % COLORS.length]}
                />
              ))}
              {refAreaLeft && refAreaRight ? (
                <ReferenceArea x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.1} />
              ) : null}
            </LineChart>
          </ResponsiveContainer>
        </Stack>
      )}
    </>
  );
};

export default FundingRatesAssetsChart;
