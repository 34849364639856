import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  OutlinedInput,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { referralProgramApi } from "@src/pages/referral-program";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { RootState } from "@src/store/types";
import { FormInputLabel } from "@src/pages/auth/layout/form-input-label";
import { REG_EX_MAIL } from "@src/pages/auth/registration/constants";
import { muiTheme } from "@src/components/theme/mui-theme";
import formValidator from "@src/utils/formValidator";

export type SendEmailInvitationFormValues = {
  email: string;
};
type ReferTraderDialogProps = {
  open: boolean;
  handleClose: () => void;
  code: string;
};
export const ReferTraderDialog = ({ open, handleClose, code }: ReferTraderDialogProps) => {
  const { team_uuid } = useSelector((state: RootState) => state.user);

  const theme = useTheme();

  const sendEmailInvitationFormValidator = formValidator.object({
    email: formValidator.string().required().email().label("Email"),
  });
  const sendEmailInvitationForm = useForm<SendEmailInvitationFormValues>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(sendEmailInvitationFormValidator),
    defaultValues: {
      email: "",
    },
  });
  const email = sendEmailInvitationForm.watch("email");
  const [referTrader] = referralProgramApi.useReferTraderMutation();
  const snackbar = useSnackbar();

  useEffect(() => {
    sendEmailInvitationForm.setValue("email", "");
  }, [open]);

  const onSubmit = async () => {
    try {
      await referTrader({ team_uuid, body: { code, email } }).unwrap();
      snackbar.enqueueSnackbar("Trader referred. Thank you", { variant: "success" });
      handleClose();
    } catch (e) {
      snackbar.enqueueSnackbar(e.message, { variant: "error" });
    }
  };

  return (
    <Dialog sx={{ padding: 0 }} open={open} onClose={handleClose}>
      <Stack sx={{ width: 560, background: theme.custom.background.secondary }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height={48}
          sx={{ background: theme.custom.background.default }}
        >
          <Typography fontSize={18} fontWeight={500} paddingLeft={3}>
            Generate Link
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>

        <DialogContent>
          <Stack direction="column" position="relative" gap={0.2}>
            <Typography fontSize={16} fontWeight={500}>
              Invite people
            </Typography>
            <Controller
              name="email"
              control={sendEmailInvitationForm.control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <FormControl sx={{ width: "100%" }} size="small" variant="outlined">
                  <FormInputLabel fieldstate={fieldState}>Email*</FormInputLabel>
                  <OutlinedInput
                    {...field}
                    error={!!fieldState.error}
                    type="text"
                    label="Email"
                    sx={{ background: theme.custom.background.default }}
                  />
                  {fieldState.error ? (
                    <Typography sx={{ fontSize: 13 }} color={muiTheme.palette.error.main}>
                      {fieldState.error?.message || "Email is required"}
                    </Typography>
                  ) : null}
                </FormControl>
              )}
            />
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            borderTop: `1px solid ${theme.custom.background.inputAdornment.main}`,
            paddingTop: 1,
            paddingBottom: 1,
          }}
        >
          <AnbotoButton
            disabled={!REG_EX_MAIL.test(email)}
            variant="contained"
            size="small"
            onClick={sendEmailInvitationForm.handleSubmit(onSubmit)}
          >
            Send invite
          </AnbotoButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
