import { ethers } from "ethers";
import { readContract, signTypedData } from "@wagmi/core";
import { WriteContractReturnType } from "viem";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { queryFnFactory } from "@src/store/apis/utils";
import { ChainId } from "@src/pages/defi/types";
import {
  ANBOTO_CONTRACT2_ORDER_STRUCT_TYPES,
  ANBOTO_CONTRACT_DOMAIN_V2,
  CHAIN_ID_ANBOTO_CONTRACT_ADDRESS_V2,
} from "@src/pages/defi/constants";
import { AnbotoContractOrderV2, SignOrderQueryParamsV2 } from "@src/store/apis/anbotoApi/types";
import ERC20ABI from "@src/assets/ABIs/ERC20.json";
import ANBOTO_CONTRACT_V2_ABI from "@src/assets/ABIs/anboto-contract-v2.json";
import { EVMBasedAddress } from "@src/pages/defi/types/common";
import { wagmiConfig } from "@src/pages/defi/wagmi-config";

export interface TransferCryptoProps {
  accountTo: string;
  chainId: ChainId;
  tokenAddress: string;
  tokenAmount: string;
}

export interface EstimateTransferProps {
  accountTo: string;
  chainId: ChainId;
  tokenAddress: string;
  tokenAmount: string;
}

export interface IsValidSignatureProps {
  accountFrom: string;
  chainId: ChainId;
  order: AnbotoContractOrderV2;
  signature: string;
}

export interface ApproveProps {
  chainId: ChainId;
  tokenAddress: string;
  tokenAmount: string;
}

export interface AllowanceProps {
  account: string;
  chainId: ChainId;
  tokenAddress: string;
}

const anbotoContractV2 = blockchainApi.injectEndpoints({
  endpoints: (builder) => ({
    allowance: builder.query<string, AllowanceProps>({
      queryFn: queryFnFactory<AllowanceProps, string>(async ({ account, chainId, tokenAddress }) => {
        const anbotoContractAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS_V2[chainId];

        const allowance = (await readContract(wagmiConfig, {
          address: tokenAddress as EVMBasedAddress,
          abi: ERC20ABI,
          functionName: "allowance",
          args: [account, anbotoContractAddress],
        }).catch((error) => {
          console.log("allowance error", error);

          return error;
        })) as Promise<string>;

        return allowance.toString();
      }),
    }),
    approveAnbotoSmartContract: builder.mutation<WriteContractReturnType | null, ApproveProps>({
      queryFn: queryFnFactory<ApproveProps, WriteContractReturnType | null>(
        async ({ chainId, tokenAddress, tokenAmount }, { dispatch }) => {
          const contractAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS_V2[chainId];

          return dispatch(
            blockchainApi.endpoints.approve.initiate({
              chainId,
              contractAddress,
              tokenAddress,
              tokenAmount,
            })
          ).unwrap();
        }
      ),
    }),
    checkIsAnboto: builder.query<boolean, { account: string; chainId: ChainId }>({
      queryFn: queryFnFactory<{ account: string; chainId: ChainId }, boolean>(({ account, chainId }) => {
        const anbotoContractAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS_V2[chainId];

        return readContract(wagmiConfig, {
          address: anbotoContractAddress as EVMBasedAddress,
          abi: ANBOTO_CONTRACT_V2_ABI,
          functionName: "isAnboto",
          args: [account],
        }).catch((error) => {
          console.log("checkIsAnboto error", error);

          return error;
        }) as Promise<boolean>;
      }),
    }),
    checkIsValidSignatureV2: builder.query<boolean, IsValidSignatureProps>({
      queryFn: queryFnFactory<IsValidSignatureProps, boolean>(({ accountFrom, chainId, order, signature }) => {
        const anbotoContractAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS_V2[chainId];

        return readContract(wagmiConfig, {
          address: anbotoContractAddress as EVMBasedAddress,
          abi: ANBOTO_CONTRACT_V2_ABI,
          functionName: "isValidSignature",
          args: [order, accountFrom, signature],
        }).catch((error) => {
          console.log("checkIsValidSignatureV2 error", error);

          return error;
        }) as Promise<boolean>;
      }),
    }),
    signOrder: builder.mutation<string, SignOrderQueryParamsV2>({
      queryFn: queryFnFactory<SignOrderQueryParamsV2, string>(async ({ anbotoContractOrder, chainId }) => {
        const tokenAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS_V2[chainId];
        const domain = { ...ANBOTO_CONTRACT_DOMAIN_V2, chainId, verifyingContract: tokenAddress as EVMBasedAddress };

        return signTypedData(wagmiConfig, {
          domain,
          types: ANBOTO_CONTRACT2_ORDER_STRUCT_TYPES,
          message: anbotoContractOrder,
          primaryType: "Order",
        })
          .then((signature) => {
            const validSignature = ethers.utils.splitSignature(signature);

            return ethers.utils.joinSignature(validSignature);
          })
          .catch((error) => {
            console.log("signOrder error", error);

            return "";
          });
      }),
    }),
  }),
});

export const {
  useLazyCheckIsValidSignatureV2Query,
  useSignOrderMutation,
  useApproveAnbotoSmartContractMutation,
  useLazyAllowanceQuery,
} = anbotoContractV2;
