import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { getCryptocompareExchangeName } from "./helpers";
import {
  GetMultipleSymbolsFullDataParams,
  ExchangeDataItem,
  GetMultipleSymbolsFullDataResponse,
  GetExchangesResponse,
  CCResponse,
  TopListResponse,
  OHLCVResponse,
  GetOHLCVResponse,
  GetPriceResult,
  GetPriceParams,
} from "./types";

const API_KEY = process.env.REACT_APP_CRYPTOCOMPARE_API_KEY;

export const baseQuery = fetchBaseQuery({
  baseUrl: "https://min-api.cryptocompare.com/data",
  prepareHeaders: (headers) => {
    headers.set("authorization", `Apikey ${API_KEY}`);

    return headers;
  },
});

const multipleSymbolsFullDataQuery = ({ fromSymbols, toSymbols, exchange }) => ({
  url: `/pricemultifull`,
  params: {
    fsyms: fromSymbols.join(","),
    tsyms: toSymbols.join(","),
    e: getCryptocompareExchangeName(exchange),
  },
});

export const cryptocompareApi = createApi({
  reducerPath: "cryptocompareApi",
  baseQuery,
  endpoints: (builder) => ({
    getPrice: builder.query<GetPriceResult, GetPriceParams>({
      query: (params) => ({
        url: `/price`,
        params,
      }),
    }),
    getMultipleSymbolsFullData: builder.query<GetMultipleSymbolsFullDataResponse, GetMultipleSymbolsFullDataParams>({
      query: multipleSymbolsFullDataQuery,
    }),
    getMultipleSymbolsFullDataCached: builder.query<
      GetMultipleSymbolsFullDataResponse,
      GetMultipleSymbolsFullDataParams
    >({
      query: multipleSymbolsFullDataQuery,
      keepUnusedDataFor: 60 * 10,
    }),
    getExchanges: builder.query<GetExchangesResponse, null>({
      query: () => ({
        url: `/exchanges/general`,
      }),
      transformResponse: (resp: CCResponse<Record<string, ExchangeDataItem>>) => {
        return Object.values(resp.Data);
      },
    }),
    getTopList: builder.query<TopListResponse["Data"], { limit?: number; tsym: string }>({
      query: ({ limit = 10, tsym }) => ({
        url: `/top/totaltoptiervolfull?`,
        params: {
          limit,
          tsym,
        },
      }),
      transformResponse: (resp: TopListResponse) => {
        if (Array.isArray(resp.Data)) {
          return resp.Data;
        }
        return [];
      },
    }),
    getHourlyPairChartData: builder.query<
      GetOHLCVResponse,
      { fsym: string; tsym: string; exchange: string; limit: number }
    >({
      query: ({ fsym, tsym, exchange, limit }) => ({
        url: `/histohour`,
        params: {
          fsym,
          tsym,
          limit,
          e: getCryptocompareExchangeName(exchange),
        },
      }),
      transformResponse: (resp: OHLCVResponse) => {
        const data = resp?.Data;
        if (Array.isArray(data)) {
          return resp?.Data?.map(({ time, close }) => ({
            ts: time,
            price: close,
          }));
        }
        return [];
      },
    }),
  }),
});

export const { useGetPriceQuery } = cryptocompareApi;
