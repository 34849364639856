import React, { forwardRef, ForwardedRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { MenuItem } from "@mui/material";
import { OrderTriggerMode } from "@src/store/apis/anbotoApi/types";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

const TRIGGER_MODES: OrderTriggerMode[] = [OrderTriggerMode.ONCE, OrderTriggerMode.ALWAYS];

export const TRIGGER_MODE_NAME: Record<OrderTriggerMode, string> = {
  [OrderTriggerMode.ONCE]: "Once",
  [OrderTriggerMode.ALWAYS]: "Always",
};

type TriggerModeFieldProps = ControllerRenderProps & {
  select?: boolean;
  disabled?: boolean;
};

export const TriggerModeField = forwardRef((props: TriggerModeFieldProps, ref: ForwardedRef<null>) =>
  props.select ? (
    <AnbotoTextField
      inputRef={ref}
      select
      size="small"
      fullWidth
      label="Trigger mode"
      disabled={props.disabled}
      {...props}
    >
      {TRIGGER_MODES.map((x) => (
        <MenuItem key={x} value={x}>
          {TRIGGER_MODE_NAME[x]}
        </MenuItem>
      ))}
    </AnbotoTextField>
  ) : (
    <AnbotoButtonGroup<OrderTriggerMode>
      defaultValue={props.value}
      view="outlined"
      sx={{ mt: 0, display: "flex" }}
      options={TRIGGER_MODES.map((x) => ({ label: TRIGGER_MODE_NAME[x], value: x }))}
      onChange={(value: string) => props.onChange(value)}
    />
  )
);
TriggerModeField.displayName = "TriggerModeField";
