import React from "react";
import { styled, Typography, Stack } from "@mui/material";
import { useOrderbookContext } from "../hooks/use-orderbook-context";

export const HEADER_HEIGHT = 20;

const VerticalHeaderContent = React.memo(
  ({ coin1, coin2 }: { coin1?: string; coin2?: string }) => {
    return (
      <Root mx={1}>
        <Label flex={1} variant="body2">
          Price({coin2})
        </Label>
        <Label width="35%" variant="body2" textAlign="right">
          Amount({coin1})
        </Label>
        <Label width="35%" variant="body2" textAlign="right">
          Total
        </Label>
      </Root>
    );
  },
  (prev, next) => prev.coin1 === next.coin1 && prev.coin2 === next.coin2
);

VerticalHeaderContent.displayName = "VerticalHeaderContent";

export const VerticalHeader = () => {
  const { aggregatedInfo } = useOrderbookContext();

  return <VerticalHeaderContent coin1={aggregatedInfo.coin1} coin2={aggregatedInfo.coin2} />;
};

const Root = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.paper,
  zIndex: 1,
  height: HEADER_HEIGHT,
  alignItems: "center",
  flexDirection: "row",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: 11,
  color: theme.palette.text.secondary,
}));
