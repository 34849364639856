import React, { useEffect } from "react";
import { Card, CardActions, CardContent, Divider, Stack, useTheme } from "@mui/material";
import { Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import { usePublicClient } from "wagmi";
import BigNumber from "bignumber.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import { StrategyField } from "./fields";
import { useDefiOrderFormFillHiddenFields, useMaxGasPrice } from "./hooks";
import { ReviewDialog } from "./dialog/review-dialog";
import { ChainId, EVMBasedAddress } from "@src/pages/defi/types";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { parseNativeValidationErrors, parsePreValidationErrors } from "@src/pages/defi/utils";
import { ClipSizeType, OrderExecutionStrategy, OrdersCurrentTab } from "@src/store/apis/anbotoApi/types";
import { useDialog } from "@src/hooks/useDialog";
import { setCurrentTab } from "@src/store/slices/decentralizedExchangePageSlice";
import { useOrderFormTriggerAmounts } from "@src/pages/defi/order-form-card-no-gas/hooks/use-order-form-trigger-amounts";
import {
  useLazyGetSwapQuoteWithPreTradeAnalysisDefiAwareQuery,
  usePreValidateOrderMutation,
} from "@src/store/apis/anbotoApi/defi";
import { fromDefiFormToDefiPreOrder } from "@src/pages/defi/order-dto-mappers";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { RainbowKitConnectButton } from "@src/components/rainbow-kit-connect-button";
import { UNEXPECTED_API_ERROR } from "@src/store/apis/anbotoApi/constants";
import {
  useBaseCoinPrice,
  useDefiOrderFormContext,
  useDefiOrderFormDefaultExpiration,
  useTokenInfo,
} from "@src/pages/defi/hooks";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { geckoTerminalApi } from "@src/store/apis/geckoterminal-api";
import { GECKOTERMINAL_CHAIN_IDS } from "@src/store/apis/geckoterminal-api/constants";
import {
  DEFAULT_DEFI_MULTISLICE_SLIPPAGE,
  DEFAULT_DEFI_SLIPPAGE,
  DEFI_FORM_RESET_FROM_HISTORY,
} from "@src/pages/defi/constants";
import { useDefiOrderFormDefaultValues } from "@src/pages/defi/hooks/use-defi-order-form-default-values";
import DcaStrategyForm from "@src/pages/defi/order-form-card-no-gas/components/dca-strategy-form/dca-strategy-form";
import MainStrategiesForm from "@src/pages/defi/order-form-card-no-gas/components/main-strategies-form/main-strategies-form";
import { isMultiSliceOrderExecutionStrategy } from "@src/store/apis/anbotoApi/utils";
import { ankr } from "@src/store/apis/anbotoApi/ankr";
import { usePermissions } from "@src/hooks/use-permissions";

export const OrderFormCardNoGas = () => {
  const dispatch = useAppDispatch();
  const defaultValues = useDefiOrderFormDefaultValues();
  const permissions = usePermissions();
  const orderForm = useDefiOrderFormContext();
  const reviewDialog = useDialog();
  const snackbar = useSnackbar();
  const advancedSettingsDialog = useDialog();

  const [getTokenDetails] = geckoTerminalApi.useLazyGetTokenDetailsQuery();

  const account = orderForm.watch("account");
  const chainId = orderForm.watch("chainId");
  const fromTokenAddress = orderForm.watch("fromTokenAddress");
  const fromTokenAmount = orderForm.watch("fromTokenAmount");
  const toTokenAmount = orderForm.watch("toTokenAmount");
  const toTokenAddress = orderForm.watch("toTokenAddress");
  const triggerPrice = orderForm.watch("triggerPrice");
  const limitPrice = orderForm.watch("limitPrice");
  const strategy = orderForm.watch("strategy");
  const clipSizeValue = orderForm.watch("clipSizeValue");

  const startTime = orderForm.watch("startTime");
  const frequency = orderForm.watch("frequency");

  const isDcaStrategy = strategy === OrderExecutionStrategy.DCA;

  const fromToken = useTokenInfo({ chainId, address: fromTokenAddress as EVMBasedAddress });
  const toToken = useTokenInfo({ chainId, address: toTokenAddress as EVMBasedAddress });

  const setTokenFee = async () => {
    const fromTokenDetails = await getTokenDetails({
      network: GECKOTERMINAL_CHAIN_IDS[chainId],
      token_address: fromTokenAddress,
    }).unwrap();

    const toTokenAddressDetails = await getTokenDetails({
      network: GECKOTERMINAL_CHAIN_IDS[chainId],
      token_address: toTokenAddress,
    }).unwrap();

    const usdReserveFromToken = parseFloat(fromTokenDetails?.data?.attributes?.total_reserve_in_usd || "0");
    const usdReserveToToken = parseFloat(toTokenAddressDetails?.data?.attributes?.total_reserve_in_usd || "0");
    const isFeeTakenInInput = usdReserveFromToken > usdReserveToToken;

    orderForm.setValue("isFeeTakenInInput", isFeeTakenInInput);
  };
  anbotoApi.useGetExchangesQuery({ isDefi: true });

  // for Arbitrum, Optimism and Base ETH should be used as a native coin, so we are substituting network ids for this networks
  const targetChainId =
    chainId === ChainId.ARBITRUM || chainId === ChainId.OPTIMISM || chainId === ChainId.BASE
      ? ChainId.ETHEREUM
      : chainId;
  const team_uuid = useAppSelector((state) => state.user.team_uuid);
  const { nativeCoinPrice, isNativeCoinPriceFetching } = useBaseCoinPrice(targetChainId);

  const [preValidateOrder, { isLoading }] = usePreValidateOrderMutation();
  const publicClient = usePublicClient();

  const [getQuoteData, { data, isFetching: isFetchingQuoteData }] =
    useLazyGetSwapQuoteWithPreTradeAnalysisDefiAwareQuery();
  const quoteData = data?.data;

  // fill hidden fields that take part in form validation
  useDefiOrderFormFillHiddenFields({
    nativeCoinPrice: nativeCoinPrice?.usd,
    buyTokenToEthRate: quoteData?.buyTokenToEthRate,
    sellTokenToEthRate: quoteData?.sellTokenToEthRate,
  });

  //set up default expiration based on strategy
  useDefiOrderFormDefaultExpiration();
  // trigger amount fields if external api results changed
  useOrderFormTriggerAmounts();
  // set up max gas price from gas oracle and gas option selected
  useMaxGasPrice();

  const onReviewClick = async () => {
    const expiration = orderForm.getValues("expiration");
    const block = (await publicClient?.getBlock()) as { timestamp: bigint };

    const deadline = Number(block.timestamp) + expiration;

    // @TODO: remove when iceberg automatic will be implemented
    const _orderForm = orderForm.getValues();
    const isAutomaticIceberg =
      _orderForm.clipSizeType === ClipSizeType.AUTOMATIC && _orderForm.strategy === OrderExecutionStrategy.ICEBERG;
    const clipSizeType = isAutomaticIceberg ? ClipSizeType.PERCENTAGE : _orderForm.clipSizeType;
    const clipSizeValue = isAutomaticIceberg ? "100" : _orderForm.clipSizeValue;

    const order = fromDefiFormToDefiPreOrder({
      accountUid: team_uuid,
      chainId,
      orderForm: {
        ...orderForm.getValues(),
        clipSizeType,
        clipSizeValue,
        expiration: deadline * 1000,
      },
    });

    try {
      await preValidateOrder(order).unwrap();

      reviewDialog.show();
    } catch (e) {
      if (e.status === UNEXPECTED_API_ERROR) {
        return snackbar.enqueueSnackbar(
          "Something went wrong. Please try again later, or contact our support team for assistance. Thank you for your patience and understanding",
          { variant: "error" }
        );
      }

      const errorMessage = e?.message || e?.data ? parsePreValidationErrors(e?.data) : "Some error occurred";

      snackbar.enqueueSnackbar(errorMessage, { variant: "error" });
    }

    advancedSettingsDialog.hide();
  };

  const validateAndReview = async () => {
    const isValid = await orderForm.trigger([
      "account",
      "strategy",
      "slippage",
      "tradingDuration",
      "triggerPrice",
      "fromTokenAmount",
      "toTokenAmount",
      "clipSizeValue",
      "expiration",
      "maxGasPrice",
      "startTime",
      "frequency",
      "limitPrice",
    ]);

    if (isValid) {
      await onReviewClick();
    } else {
      snackbar.enqueueSnackbar(
        parseNativeValidationErrors(orderForm.formState.errors as Record<string, { message: string }>) ||
          "Some error occurred",
        {
          variant: "error",
        }
      );
    }
  };

  const handleResetClick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    orderForm.formState.dirtyFields.maxGasPrice = false;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    orderForm.formState.touchedFields.maxGasPrice = false;

    orderForm.reset(defaultValues);
  };

  const handleStrategyChange = (newStrategy) => {
    if (!newStrategy) return;
    const isNewStrategyIsDCA = newStrategy === OrderExecutionStrategy.DCA;

    const isMultiSliceStrategy = isMultiSliceOrderExecutionStrategy(strategy);
    const slippage = isMultiSliceStrategy || isDcaStrategy ? DEFAULT_DEFI_MULTISLICE_SLIPPAGE : DEFAULT_DEFI_SLIPPAGE;

    orderForm.reset({
      ...defaultValues,
      strategy: newStrategy,
      slippage,
      ...(isMultiSliceStrategy ? { childSlippage: slippage } : {}),
      side: orderForm.getValues("side"),
      fromTokenAddress: orderForm.getValues("fromTokenAddress"),
      toTokenAddress: orderForm.getValues("toTokenAddress"),
      fromTokenAmount: isNewStrategyIsDCA ? "" : orderForm.getValues("fromTokenAmount"),
      toTokenAmount: isNewStrategyIsDCA ? "" : orderForm.getValues("toTokenAmount"),
      chainId: orderForm.getValues("chainId"),
      isFeeTakenInInput: orderForm.getValues("isFeeTakenInInput"),
      ...(isNewStrategyIsDCA ? { clipSizeType: ClipSizeType.ABSOLUTE, clipSizeValue: "" } : {}),
    });
  };

  const onSubmitSuccess = () => {
    const value = triggerPrice || limitPrice ? OrdersCurrentTab.Trigger : OrdersCurrentTab.Regular;

    dispatch(setCurrentTab({ value }));

    reviewDialog.hide();
    handleResetClick();
  };

  const hasAmountErrors =
    !!orderForm.formState.errors?.fromTokenAmount?.message || !!orderForm.formState.errors?.toTokenAmount?.message;

  useEffect(() => {
    if (fromTokenAddress.length > 0 && toTokenAddress.length > 0) {
      setTokenFee();
    }
  }, [fromTokenAddress, toTokenAddress, chainId]);

  const amountsNotSet = !new BigNumber(fromTokenAmount).toNumber() || !new BigNumber(toTokenAmount).toNumber();
  const isSameTokens = fromToken?.address === toToken?.address;

  const isReviewDisabled =
    !permissions.trade || isDcaStrategy
      ? !clipSizeValue || !frequency || !startTime || isFetchingQuoteData || !account || isSameTokens
      : isFetchingQuoteData || hasAmountErrors || !account || amountsNotSet || isSameTokens;

  // close review dialog if user clicks on history
  useEffect(() => {
    window.addEventListener(DEFI_FORM_RESET_FROM_HISTORY, reviewDialog.hide);

    return () => {
      window.removeEventListener(DEFI_FORM_RESET_FROM_HISTORY, reviewDialog.hide);
    };
  }, [reviewDialog]);

  ankr.useGetUserTokensListQuery(
    {
      account: account!,
      chainId: chainId!,
    },
    { skip: !account || !chainId }
  );

  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Card sx={{ position: "relative", width: isXl ? 490 : 380 }}>
      <Stack flex={1} sx={{ position: "relative", overflow: "hidden" }}>
        <CardContent sx={{ minHeight: 535 }}>
          <RainbowKitConnectButton formAccount={account} error={!!orderForm.formState.errors?.account} />
          <Stack>
            <Controller
              name="strategy"
              control={orderForm.control}
              render={({ field }) => <StrategyField {...field} onChange={handleStrategyChange} />}
            />
          </Stack>
          {/* https://anbotolabs.atlassian.net/browse/FE-860 */}
          {/* <Box mb={2} mt={2}>
          <Controller
            name="side"
            control={orderForm.control}
            render={({ field }) => <SideSelect value={field.value} onChange={field.onChange} />}
          />
        </Box> */}
          {isDcaStrategy ? (
            <DcaStrategyForm
              getQuoteData={getQuoteData}
              quoteData={quoteData}
              isQuoteDataFetching={isFetchingQuoteData}
              nativeCoinPrice={nativeCoinPrice}
              isNativeCoinPriceFetching={isNativeCoinPriceFetching}
              advancedSettingsDialog={advancedSettingsDialog}
            />
          ) : (
            <MainStrategiesForm
              quoteData={quoteData}
              isFetchingQuoteData={isFetchingQuoteData}
              getQuoteData={getQuoteData}
              nativeCoinPrice={nativeCoinPrice}
              isNativeCoinPriceFetching={isNativeCoinPriceFetching}
              advancedSettingsDialog={advancedSettingsDialog}
            />
          )}
        </CardContent>
      </Stack>
      <Divider />
      <CardActions sx={{ px: 2, pb: 2, pt: 1, justifyContent: "space-between" }}>
        <AnbotoButton
          variant="outlined"
          size="small"
          sx={{ color: (theme) => theme.palette.error.main }}
          onClick={handleResetClick}
        >
          Clear
        </AnbotoButton>
        <AnbotoButton
          variant="contained"
          color="primary"
          size="small"
          onClick={validateAndReview}
          loading={orderForm.formState.isSubmitting || isLoading || (isDcaStrategy && isFetchingQuoteData)}
          disabled={isReviewDisabled}
        >
          Review order
        </AnbotoButton>
      </CardActions>
      <ReviewDialog
        open={reviewDialog.isOpen}
        onClose={reviewDialog.hide}
        onSubmitSuccess={onSubmitSuccess}
        sellAmount={quoteData?.sellAmount}
      />
    </Card>
  );
};
