export const SUPPORTED_PORTFOLIO_CHAINS = [
  {
    id: "eth",
    community_id: 1,
    name: "Ethereum",
    native_token_id: "eth",
    logo_url: "https://static.debank.com/image/chain/logo_url/eth/42ba589cd077e7bdd97db6480b0ff61d.png",
    wrapped_token_id: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    is_support_pre_exec: true,
  },
  {
    id: "bsc",
    community_id: 56,
    name: "BSC",
    native_token_id: "bsc",
    logo_url: "https://static.debank.com/image/chain/logo_url/bsc/7c87af7b52853145f6aa790d893763f1.png",
    wrapped_token_id: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    is_support_pre_exec: true,
  },
  {
    id: "xdai",
    community_id: 100,
    name: "Gnosis",
    native_token_id: "xdai",
    logo_url: "https://static.debank.com/image/chain/logo_url/xdai/8b5320523b30bd57a388d1bcc775acd5.png",
    wrapped_token_id: "0xe91d153e0b41518a2ce8dd3d7944fa863463a97d",
    is_support_pre_exec: true,
  },
  {
    id: "matic",
    community_id: 137,
    name: "Polygon",
    native_token_id: "matic",
    logo_url: "https://static.debank.com/image/chain/logo_url/matic/d3d807aff1a13e9ba51a14ff153d6807.png",
    wrapped_token_id: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    is_support_pre_exec: true,
  },
  {
    id: "ftm",
    community_id: 250,
    name: "Fantom",
    native_token_id: "ftm",
    logo_url: "https://static.debank.com/image/chain/logo_url/ftm/700fca32e0ee6811686d72b99cc67713.png",
    wrapped_token_id: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
    is_support_pre_exec: true,
  },
  {
    id: "okt",
    community_id: 66,
    name: "OEC",
    native_token_id: "okt",
    logo_url: "https://static.debank.com/image/chain/logo_url/okt/1228cd92320b3d33769bd08eecfb5391.png",
    wrapped_token_id: "0x8f8526dbfd6e38e3d8307702ca8469bae6c56c15",
    is_support_pre_exec: false,
  },
  {
    id: "heco",
    community_id: 128,
    name: "HECO",
    native_token_id: "heco",
    logo_url: "https://static.debank.com/image/chain/logo_url/heco/db5152613c669e0cc8624d466d6c94ea.png",
    wrapped_token_id: "0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f",
    is_support_pre_exec: false,
  },
  {
    id: "avax",
    community_id: 43114,
    name: "Avalanche",
    native_token_id: "avax",
    logo_url: "https://static.debank.com/image/chain/logo_url/avax/4d1649e8a0c7dec9de3491b81807d402.png",
    wrapped_token_id: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
    is_support_pre_exec: true,
  },
  {
    id: "op",
    community_id: 10,
    name: "Optimism",
    native_token_id: "op",
    logo_url: "https://static.debank.com/image/chain/logo_url/op/01ae734fe781c9c2ae6a4cc7e9244056.png",
    wrapped_token_id: "0x4200000000000000000000000000000000000006",
    is_support_pre_exec: true,
  },
  {
    id: "base",
    community_id: 8453,
    name: "Base",
    native_token_id: "eth",
    logo_url: "",
    wrapped_token_id: "0x4200000000000000000000000000000000000006",
    is_support_pre_exec: true,
  },
  {
    id: "arb",
    community_id: 42161,
    name: "Arbitrum",
    native_token_id: "arb",
    logo_url: "https://static.debank.com/image/chain/logo_url/arb/f6d1b236259654d531a1459b2bccaf64.png",
    wrapped_token_id: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
    is_support_pre_exec: true,
  },
  {
    id: "celo",
    community_id: 42220,
    name: "Celo",
    native_token_id: "celo",
    logo_url: "https://static.debank.com/image/chain/logo_url/celo/41da5c1d3c0945ae822a1f85f02c76cf.png",
    wrapped_token_id: "0x471ece3750da237f93b8e339c536989b8978a438",
    is_support_pre_exec: false,
  },
  {
    id: "movr",
    community_id: 1285,
    name: "Moonriver",
    native_token_id: "movr",
    logo_url: "https://static.debank.com/image/chain/logo_url/movr/4b0de5a711b437f187c0d0f15cc0398b.png",
    wrapped_token_id: "0x98878b06940ae243284ca214f92bb71a2b032b8a",
    is_support_pre_exec: false,
  },
  {
    id: "cro",
    community_id: 25,
    name: "Cronos",
    native_token_id: "cro",
    logo_url: "https://static.debank.com/image/chain/logo_url/cro/44f784a1f4c0ea7d26d00acabfdf0028.png",
    wrapped_token_id: "0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23",
    is_support_pre_exec: true,
  },
  {
    id: "boba",
    community_id: 288,
    name: "Boba",
    native_token_id: "boba",
    logo_url: "https://static.debank.com/image/chain/logo_url/boba/e43d79cd8088ceb3ea3e4a240a75728f.png",
    wrapped_token_id: "0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000",
    is_support_pre_exec: true,
  },
  {
    id: "metis",
    community_id: 1088,
    name: "Metis",
    native_token_id: "metis",
    logo_url: "https://static.debank.com/image/chain/logo_url/metis/b289da32db4d860ebf6fb46a6e41dcfc.png",
    wrapped_token_id: "0x75cb093e4d61d2a2e65d8e0bbb01de8d89b53481",
    is_support_pre_exec: true,
  },
  {
    id: "btt",
    community_id: 199,
    name: "BitTorrent",
    native_token_id: "btt",
    logo_url: "https://static.debank.com/image/chain/logo_url/btt/2130a8d57ff2a0f3d50a4ec9432897c6.png",
    wrapped_token_id: "0x197a4ed2b1bb607e47a144b9731d7d34f86e9686",
    is_support_pre_exec: false,
  },
  {
    id: "aurora",
    community_id: 1313161554,
    name: "Aurora",
    native_token_id: "aurora",
    logo_url: "https://static.debank.com/image/chain/logo_url/aurora/c7590fd2defb8e7d7dc071166838c33a.png",
    wrapped_token_id: "",
    is_support_pre_exec: false,
  },
  {
    id: "mobm",
    community_id: 1284,
    name: "Moonbeam",
    native_token_id: "mobm",
    logo_url: "https://static.debank.com/image/chain/logo_url/mobm/a8442077d76b258297181c3e6eb8c9cc.png",
    wrapped_token_id: "0xacc15dc74880c9944775448304b263d191c6077f",
    is_support_pre_exec: false,
  },
  {
    id: "sbch",
    community_id: 10000,
    name: "SmartBch",
    native_token_id: "sbch",
    logo_url: "https://static.debank.com/image/chain/logo_url/sbch/d78ac780803e7f0a17b73558f423502e.png",
    wrapped_token_id: "0x3743ec0673453e5009310c727ba4eaf7b3a1cc04",
    is_support_pre_exec: false,
  },
  {
    id: "fuse",
    community_id: 122,
    name: "Fuse",
    native_token_id: "fuse",
    logo_url: "https://static.debank.com/image/chain/logo_url/fuse/ea4c9e12e7f646d42aa8fb07ab8dfec8.png",
    wrapped_token_id: "0x0be9e53fd7edac9f859882afdda116645287c629",
    is_support_pre_exec: false,
  },
  {
    id: "hmy",
    community_id: 1666600000,
    name: "Harmony",
    native_token_id: "hmy",
    logo_url: "https://static.debank.com/image/chain/logo_url/hmy/734c003023531e31c636ae25d5a73172.png",
    wrapped_token_id: "0xcf664087a5bb0237a0bad6742852ec6c8d69a27a",
    is_support_pre_exec: true,
  },
  {
    id: "klay",
    community_id: 8217,
    name: "Klaytn",
    native_token_id: "klay",
    logo_url: "https://static.debank.com/image/chain/logo_url/klay/1df018b8493cb97c50b7e390ef63cba4.png",
    wrapped_token_id: "0xe4f05a66ec68b54a58b17c22107b02e0232cc817",
    is_support_pre_exec: true,
  },
  {
    id: "astar",
    community_id: 592,
    name: "Astar",
    native_token_id: "astar",
    logo_url: "https://static.debank.com/image/chain/logo_url/astar/398c7e0014bdada3d818367a7273fabe.png",
    wrapped_token_id: "0xaeaaf0e2c81af264101b9129c00f4440ccf0f720",
    is_support_pre_exec: true,
  },
  {
    id: "sdn",
    community_id: 336,
    name: "Shiden",
    native_token_id: "sdn",
    logo_url: "https://static.debank.com/image/chain/logo_url/sdn/0baaa4ee7cb16feed71119b062ccd277.png",
    wrapped_token_id: "0x0f933dc137d21ca519ae4c7e93f87a4c8ef365ef",
    is_support_pre_exec: true,
  },
  {
    id: "palm",
    community_id: 11297108109,
    name: "Palm",
    native_token_id: "palm",
    logo_url: "https://static.debank.com/image/chain/logo_url/palm/ece828c59e643b8a8a13aa99145ae7d7.png",
    wrapped_token_id: "0xf98cabf0a963452c5536330408b2590567611a71",
    is_support_pre_exec: false,
  },
  {
    id: "iotx",
    community_id: 4689,
    name: "IoTeX",
    native_token_id: "iotx",
    logo_url: "https://static.debank.com/image/chain/logo_url/iotx/d3be2cd8677f86bd9ab7d5f3701afcc9.png",
    wrapped_token_id: "0xa00744882684c3e4747faefd68d283ea44099d03",
    is_support_pre_exec: false,
  },
  {
    id: "rsk",
    community_id: 30,
    name: "RSK",
    native_token_id: "rsk",
    logo_url: "https://static.debank.com/image/chain/logo_url/rsk/2958b02ef823097b70fac99f39889e2e.png",
    wrapped_token_id: "0x542fda317318ebf1d3deaf76e0b632741a7e677d",
    is_support_pre_exec: false,
  },
  {
    id: "wan",
    community_id: 888,
    name: "Wanchain",
    native_token_id: "wan",
    logo_url: "https://static.debank.com/image/chain/logo_url/wan/f3aa8b31414732ea5e026e05665146e6.png",
    wrapped_token_id: "0xdabd997ae5e4799be47d6e69d9431615cba28f48",
    is_support_pre_exec: false,
  },
  {
    id: "kcc",
    community_id: 321,
    name: "KCC",
    native_token_id: "kcc",
    logo_url: "https://static.debank.com/image/chain/logo_url/kcc/3a5a4ef7d5f1db1e53880d70219d75b6.png",
    wrapped_token_id: "0x4446fc4eb47f2f6586f9faab68b3498f86c07521",
    is_support_pre_exec: false,
  },
  {
    id: "sgb",
    community_id: 19,
    name: "Songbird",
    native_token_id: "sgb",
    logo_url: "https://static.debank.com/image/chain/logo_url/sgb/619f46d574d62a50bdfd9f0e2f47ddc1.png",
    wrapped_token_id: "0x02f0826ef6ad107cfc861152b32b52fd11bab9ed",
    is_support_pre_exec: false,
  },
  {
    id: "evmos",
    community_id: 9001,
    name: "EvmOS",
    native_token_id: "evmos",
    logo_url: "https://static.debank.com/image/chain/logo_url/evmos/26e038b4d5475d5a4b92f7fc08bdabc9.png",
    wrapped_token_id: "0xd4949664cd82660aae99bedc034a0dea8a0bd517",
    is_support_pre_exec: false,
  },
  {
    id: "dfk",
    community_id: 53935,
    name: "DFK",
    native_token_id: "dfk",
    logo_url: "https://static.debank.com/image/chain/logo_url/dfk/233867c089c5b71be150aa56003f3f7a.png",
    wrapped_token_id: "0xccb93dabd71c8dad03fc4ce5559dc3d89f67a260",
    is_support_pre_exec: false,
  },
  {
    id: "tlos",
    community_id: 40,
    name: "Telos",
    native_token_id: "tlos",
    logo_url: "https://static.debank.com/image/chain/logo_url/telos/f9f7493def4c08ed222540bebd8ce87a.png",
    wrapped_token_id: "0xd102ce6a4db07d247fcc28f366a623df0938ca9e",
    is_support_pre_exec: false,
  },
  {
    id: "swm",
    community_id: 73772,
    name: "Swimmer",
    native_token_id: "swm",
    logo_url: "https://static.debank.com/image/chain/logo_url/swm/361526e901cb506ef7074c3678ce769a.png",
    wrapped_token_id: "0x9c765eee6eff9cf1337a1846c0d93370785f6c92",
    is_support_pre_exec: false,
  },
  {
    id: "nova",
    community_id: 42170,
    name: "Arbitrum Nova",
    native_token_id: "nova",
    logo_url: "https://static.debank.com/image/chain/logo_url/nova/06eb2b7add8ba443d5b219c04089c326.png",
    wrapped_token_id: "0x722e8bdd2ce80a4422e880164f2079488e115365",
    is_support_pre_exec: true,
  },
  {
    id: "canto",
    community_id: 7700,
    name: "Canto",
    native_token_id: "canto",
    logo_url: "https://static.debank.com/image/chain/logo_url/canto/47574ef619e057d2c6bbce1caba57fb6.png",
    wrapped_token_id: "0x826551890dc65655a0aceca109ab11abdbd7a07b",
    is_support_pre_exec: false,
  },
  {
    id: "doge",
    community_id: 2000,
    name: "Dogechain",
    native_token_id: "doge",
    logo_url: "https://static.debank.com/image/chain/logo_url/doge/2538141079688a7a43bc22c7b60fb45f.png",
    wrapped_token_id: "0xb7ddc6414bf4f5515b52d8bdd69973ae205ff101",
    is_support_pre_exec: false,
  },
  {
    id: "kava",
    community_id: 2222,
    name: "Kava",
    native_token_id: "kava",
    logo_url: "https://static.debank.com/image/chain/logo_url/step/a09a84faa6fc54a5c86dd41eccd4f9d1.png",
    wrapped_token_id: "0xc86c7c0efbd6a49b35e8714c5f59d99de09a225b",
    is_support_pre_exec: false,
  },
  {
    id: "step",
    community_id: 1234,
    name: "Step",
    native_token_id: "step",
    logo_url: "https://static.debank.com/image/chain/logo_url/step/db79600b8feafe17845617ca9c606dbe.png",
    wrapped_token_id: "0xb58a9d5920af6ac1a9522b0b10f55df16686d1b6",
    is_support_pre_exec: false,
  },
  {
    id: "mada",
    community_id: 2001,
    name: "Milkomeda",
    native_token_id: "mada",
    logo_url: "https://static.debank.com/image/chain/logo_url/mada/cdc4b1112c2c5a2757cbda33f4476b7f.png",
    wrapped_token_id: "0xae83571000af4499798d1e3b0fa0070eb3a3e3f9",
    is_support_pre_exec: false,
  },
  {
    id: "cfx",
    community_id: 1030,
    name: "Conflux",
    native_token_id: "cfx",
    logo_url: "https://static.debank.com/image/chain/logo_url/cfx/eab0c7304c6820b48b2a8d0930459b82.png",
    wrapped_token_id: "0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b",
    is_support_pre_exec: true,
  },
  {
    id: "brise",
    community_id: 32520,
    name: "Bitgert",
    native_token_id: "brise",
    logo_url: "https://static.debank.com/image/chain/logo_url/brise/4f6c040cf49f4d8c4eabbad7cd2f4ae4.png",
    wrapped_token_id: "0x0eb9036cbe0f052386f36170c6b07ef0a0e3f710",
    is_support_pre_exec: true,
  },
];
