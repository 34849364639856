import React from "react";
import {
  List,
  ListItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { CONFIG } from "@src/pages/competitions/components/woox/config";

type CampaignRulesWooxProps = {
  prizePool: { volume: string; prizePool: string }[];
};
export const CampaignRules = ({ prizePool }: CampaignRulesWooxProps) => {
  const theme = useTheme();

  return (
    <>
      <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary, fontSize: 14 }}>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          The duration of the competition has been specified as:
          <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>Start of event: {CONFIG.START_TIME};</ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>End of event: {CONFIG.END_TIME};</ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
              Reward distribution: Nov 27th 2023 23:59 UTC.
            </ListItem>
          </List>
          - WOO Network reserves the right to change the above-mentioned timeline at its discretion, in particular to
          end the event earlier.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          The users/ participants of the competition must be registered and successfully KYC-ed on WOO X by the end of
          the competition duration.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Only trading volume generated during the trading competition will be counted.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Only the volume traded on WOO X will be counted.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          During the competition, only the trading volume generated by all Anboto users who use Anboto to trade on WOO X
          is eligible.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>Only WOO X Futures pairs are eligible.</ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Prize pool: the competition uses a dynamic prize pool mechanism where the higher total trading volume during
          the trading competition unlocks a larger prize pool. The size of the prize pool and distribution of rewards
          are:
          <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>Prize pools:</ListItem>
            <TableContainer sx={{ width: 300 }}>
              <Table
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0px 8px",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&>th": {
                        border: 0,
                        fontSize: 12,
                        color: "#8A9296",
                        fontWeight: 400,
                        padding: 1,
                        paddingBottom: 0,
                      },
                      "&>th:last-child": { paddingRight: 0 },
                      "&>th:first-child": { paddingLeft: 0 },
                    }}
                  >
                    <TableCell>Prize Pool (paid in WOO)</TableCell>
                    <TableCell align="right">Min Volume Requirements</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {prizePool.map((prize) => (
                    <TableRow
                      key={prize.volume}
                      sx={{
                        background: "#192022",
                        paddingLeft: 0,
                        paddingRight: 0,
                        "&>td": {
                          border: 0,
                          fontWeight: 500,
                          fontSize: 14,
                          marginBottom: 1,
                          color: "#B0BCC2",
                          padding: 1,
                        },
                        "&>td:last-child": { paddingRight: 0 },
                        "&>td:first-child": { paddingLeft: 0 },
                      }}
                    >
                      <TableCell align="left">{prize.prizePool}</TableCell>
                      <TableCell align="right">{prize.volume}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          The top 10 traders (based on trading volume) will be selected as winners, and 5 random winners will be picked
          up from the referees of Anboto’s top 1 affiliates (based on trading volume) as the winner of the Lottery.
          <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>Prize Distribution by Ranking:</ListItem>
            <TableContainer sx={{ width: 400 }}>
              <Table
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0px 8px",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "&>th": {
                        border: 0,
                        fontSize: 12,
                        color: "#8A9296",
                        fontWeight: 400,
                        padding: 1,
                        paddingBottom: 0,
                      },
                      "&>th:last-child": { paddingRight: 0 },
                      "&>th:first-child": { paddingLeft: 0 },
                    }}
                  >
                    <TableCell>Trading Volume Ranking</TableCell>
                    <TableCell align="right">Prize</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <StyledTableRow key="1st">
                    <TableCell align="left">1st</TableCell>
                    <TableCell align="right">30%</TableCell>
                  </StyledTableRow>
                  <StyledTableRow key="2nd">
                    <TableCell align="left">2nd</TableCell>
                    <TableCell align="right">15%</TableCell>
                  </StyledTableRow>
                  <StyledTableRow key="3rd">
                    <TableCell align="left">3rd</TableCell>
                    <TableCell align="right">10%</TableCell>
                  </StyledTableRow>
                  <StyledTableRow key="4th - 10th">
                    <TableCell align="left">4th - 10th</TableCell>
                    <TableCell align="right">Split 25% of the prize pool</TableCell>
                  </StyledTableRow>
                  <StyledTableRow key="Lottery (5 Random Winners)">
                    <TableCell align="left">Lottery (5 Random Winners)</TableCell>
                    <TableCell align="right">Split 20% of the prize pool</TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </List>
        </ListItem>

        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Additional rules:
          <List sx={{ listStyleType: "disc", paddingLeft: 2, color: theme.palette.text.secondary }}>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
              To be eligible for the top-3 rewards, your Competition Trading Volume must be higher than $2,000,000 USDT
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
              To be eligible for the top-10 rewards, your Competition Trading Volume must be higher than $200,000 USDT
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
              To be eligible for the 5 random winner rewards, your Competition Trading Volume must be higher than
              $20,000 USDT
            </ListItem>
            <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
              To be eligible for the Lottery, the 5 random winners have to be referred by Anboto’s affiliates who
              generated the most volume during the campaign.
            </ListItem>
          </List>
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          In case of a tie, all winning traders will share the prize pool.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          Effective trading volume includes both successfully executed long and short positions.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          WOO Network reserves the right to disqualify trades that are wash trades, involve illegal bulk account
          registrations, self-dealing, or display signs of market manipulation or suspicious behavior, etc. The
          participant of the competition acknowledges and agrees that the decision regarding disqualification is at the
          sole discretion of the WOO X and WOO is not obliged to provide any explicit reason(s) for doing so.
        </ListItem>
        <ListItem sx={{ display: "list-item", paddingLeft: 1 }}>
          All rewards will be credited within 14 days after competition has ended.
        </ListItem>
      </List>
    </>
  );
};

const StyledTableRow = styled(TableRow)(() => ({
  background: "#192022",
  paddingLeft: 0,
  paddingRight: 0,
  "&>td": {
    border: 0,
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 8,
    color: "#B0BCC2",
    padding: 8,
  },
  "&>td:last-child": { paddingRight: 0 },
  "&>td:first-child": { paddingLeft: 0 },
}));
