import { Auth } from "../index";
import { useAuthenticatorContext } from "./use-authenticator-context";
import { persistedStore } from "@src/store/persistedStore";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.auth = Auth;

export const useAuth = () => {
  const { authenticated, ready, mfa, refetchSession } = useAuthenticatorContext();

  return {
    ...Auth,
    signOut: async () => {
      await Auth.signOut();
      persistedStore.purge();
    },
    authenticated,
    ready,
    mfa,
    refetchSession,
  };
};
