import { GetWalletsResult } from "@src/store/apis/anbotoApi/types";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export interface UseUserWalletsListResult {
  data?: GetWalletsResult;
  dataFromCache?: GetWalletsResult;
  isError: boolean;
  isFetching: boolean;
  isLoading: boolean;
  refetch: () => void;
}

export const useUserWalletsList = (): UseUserWalletsListResult => {
  const { data, isError, isLoading, isFetching, refetch } = anbotoApi.useGetWalletsQuery(null);

  const portfolioWalletsListState = anbotoApi.endpoints.getWallets.useQueryState(null);

  return {
    data,
    dataFromCache: portfolioWalletsListState.currentData,
    isError,
    isLoading,
    isFetching,
    refetch,
  };
};
