import React from "react";
import { Box, Grid, Typography, styled } from "@mui/material";
import { useParentOrderFormContext } from "../../hooks/use-parent-order-form-context";
import { ExpirationTimeLabel } from "../../fields/ExpirationTimeSelect";
import { PLACEMENT_MODE_LABEL } from "../../fields/PlacementModeSelect";
import { CLIP_SIZE_TYPE_NAME } from "../../fields/ClipSizeTypeSelect";
import { TRADING_STYLE_LABEL } from "../../fields/TradingStyleSelect";
import { POV_RISK_NAME } from "../../fields/PovRiskSelect";
import { MAIN_ACCOUNT_VALUE } from "@src/pages/cefi/constants";
import { TRIGGER_CONDITION_LABEL } from "@src/components";
import { ClipSizeType, OrderExecutionStrategy, OrderSide, TradingStyle } from "@src/store/apis/anbotoApi/types";
import { formatTokenAmount } from "@src/utils/format";
import { getSymbolPair } from "@src/pages/cefi/order-form/utils";
import { ORDER_EXECUTION_STRATEGY_NAME } from "@src/pages/cefi/order-form/fields/StrategySelect";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";
import { URGENCY_LABEL } from "@src/pages/cefi/order-form/fields/urgency-select";

export const ParentOrderPropertiesCard = () => {
  const orderForm = useParentOrderFormContext();
  const symbol = orderForm.watch("symbol");
  const account = orderForm.watch("account");
  const quantity = orderForm.watch("quantity");
  const toQuantity = orderForm.watch("toQuantity");
  const side = orderForm.watch("side");
  const strategy = orderForm.watch("strategy");
  const tradingDurationUnit = orderForm.watch("tradingDurationUnit");
  const tradingDuration = orderForm.watch("tradingDuration");
  const limitPrice = orderForm.watch("limitPrice");
  const clipSizeType = orderForm.watch("clipSizeType");
  const clipSizeValue = orderForm.watch("clipSizeValue");
  const tradingStyle = orderForm.watch("tradingStyle");
  const wouldPrice = orderForm.watch("wouldPrice");
  const wouldStyle = orderForm.watch("wouldStyle");
  const wouldPct = orderForm.watch("wouldPct");
  const placementMode = orderForm.watch("placementMode");
  const placement = orderForm.watch("placement");
  const placementCancel = orderForm.watch("placementCancel");
  const triggerCondition = orderForm.watch("triggerCondition");
  const triggerPrice = orderForm.watch("triggerPrice");
  const urgency = orderForm.watch("urgency");
  const expirationTime = orderForm.watch("expirationTime");
  const povRisk = strategy === OrderExecutionStrategy.POV ? orderForm.watch("povRisk") : "";
  const povRatio = strategy === OrderExecutionStrategy.POV ? orderForm.watch("povRatio") : "";
  const reduceOnly = orderForm.watch("reduceOnly");

  const isAlgoOrder = ![OrderExecutionStrategy.LIMIT, OrderExecutionStrategy.ORDER].includes(strategy);
  const isMarketOrder = strategy === OrderExecutionStrategy.ORDER;
  const showPlacementParameters =
    !(strategy === OrderExecutionStrategy.ICEBERG && tradingStyle === TradingStyle.HYBRID) && isAlgoOrder;

  if (!symbol) return null;

  return (
    <Box p={2} borderRadius={1} border="1px solid #232C2F" flex={1}>
      <Grid container spacing={1} flexWrap="wrap">
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            Pair
          </Typography>
          <Typography>{getOrderSymbolLabel(symbol, ["symbol"])}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            Account
          </Typography>
          <Amount>
            {symbol!.exchange} / {account === MAIN_ACCOUNT_VALUE ? "Main" : account}
          </Amount>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            Quantity ({side === OrderSide.BUY ? "Buy" : "Pay"})
          </Typography>
          <Amount>
            {formatTokenAmount(quantity)} {getSymbolPair(symbol!.symbol).from}
          </Amount>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            Quantity ({side === OrderSide.BUY ? "Pay" : "Buy"})
          </Typography>
          <Amount>
            {formatTokenAmount(toQuantity)} {getSymbolPair(symbol!.symbol).to}
          </Amount>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" color="text.secondary">
            Strategy
          </Typography>
          <Amount>
            {ORDER_EXECUTION_STRATEGY_NAME[strategy]} / {side.toUpperCase()}
          </Amount>
        </Grid>
        {(strategy === OrderExecutionStrategy.TWAP || strategy === OrderExecutionStrategy.VWAP) && tradingDuration && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Duration
            </Typography>
            <Amount>
              {tradingDuration} {tradingDurationUnit}
            </Amount>
          </Grid>
        )}
        {isAlgoOrder && tradingStyle && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Trading style
            </Typography>
            <Amount>{TRADING_STYLE_LABEL[tradingStyle]}</Amount>
          </Grid>
        )}
        {strategy === OrderExecutionStrategy.IS && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Urgency
            </Typography>
            <Amount>{URGENCY_LABEL[urgency]}</Amount>
          </Grid>
        )}
        {povRatio && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Ratio
            </Typography>
            <Typography>{povRatio}%</Typography>
          </Grid>
        )}
        {povRisk && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Risk
            </Typography>
            <Typography>{POV_RISK_NAME[povRisk]}</Typography>
          </Grid>
        )}
        {wouldPrice && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Would style
            </Typography>
            <Amount>{wouldStyle}</Amount>
          </Grid>
        )}
        {wouldPrice && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Would price
            </Typography>
            <Amount>{wouldPrice}</Amount>
          </Grid>
        )}
        {wouldPrice && wouldPct && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Would pct
            </Typography>
            <Amount>{wouldPct}</Amount>
          </Grid>
        )}
        {limitPrice && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Limit price
            </Typography>
            <Amount>{limitPrice}</Amount>
          </Grid>
        )}
        {triggerPrice && triggerCondition && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Trigger condition
            </Typography>
            <Amount>{TRIGGER_CONDITION_LABEL[triggerCondition]}</Amount>
          </Grid>
        )}
        {triggerPrice && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Trigger price
            </Typography>
            <Amount>{triggerPrice}</Amount>
          </Grid>
        )}
        {showPlacementParameters && (
          <>
            {placementMode && (
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">
                  Maker placement style
                </Typography>
                <Amount>{PLACEMENT_MODE_LABEL[placementMode]}</Amount>
              </Grid>
            )}
            {placement && (
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">
                  Maker place level
                </Typography>
                <Amount>{placement}</Amount>
              </Grid>
            )}
            {placementCancel && (
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">
                  Maker cancel level
                </Typography>
                <Amount>{placementCancel}</Amount>
              </Grid>
            )}
            {reduceOnly && (
              <Grid item xs={6}>
                <Typography variant="caption" color="text.secondary">
                  Reduce Only
                </Typography>
                <Amount>Yes</Amount>
              </Grid>
            )}
          </>
        )}
        {clipSizeValue && clipSizeType !== ClipSizeType.NB_OF_CHILD_ORDERS && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Clip size type
            </Typography>
            <Amount>{CLIP_SIZE_TYPE_NAME[clipSizeType]}</Amount>
          </Grid>
        )}
        {clipSizeValue && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              {clipSizeType === ClipSizeType.NB_OF_CHILD_ORDERS ? "Number of child orders" : "Clip size value"}
            </Typography>
            <Amount>{clipSizeValue}</Amount>
          </Grid>
        )}
        {!isMarketOrder && expirationTime && (
          <Grid item xs={6}>
            <Typography variant="caption" color="text.secondary">
              Expiration time
            </Typography>
            <Amount>{ExpirationTimeLabel[expirationTime]}</Amount>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const Amount = styled(Typography)({ fontSize: 14 });
