import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type WouldPriceFieldProps = ControllerRenderProps & AnbotoTextFieldProps;

export const WouldPriceField = forwardRef((props: WouldPriceFieldProps, ref) => {
  return (
    <AnbotoTextField
      {...props}
      inputRef={ref}
      variant="outlined"
      size="small"
      label={
        <Stack direction="row" gap={1} alignItems="center">
          <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
            {props.label || "Price"}
          </Typography>
          <Tooltip title="The price at which you are willing to fill the entire order. It effectively provides a 'get-it-done' price for the order should the market move favorably to a certain level.">
            <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
          </Tooltip>
        </Stack>
      }
      fullWidth
      type="number"
    />
  );
});

WouldPriceField.displayName = "WouldPriceField";
