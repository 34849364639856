import React, { FC } from "react";
import TokenIcon from "@mui/icons-material/Token";
import { Box, styled, Typography, Stack } from "@mui/material";

interface CoinNameIconProps {
  coinName?: string;
  symbol: string;
  url?: string;
  chainImgUrl?: string;
}

export const CoinNameIcon: FC<CoinNameIconProps> = ({ coinName, symbol, url, chainImgUrl }) => (
  <Box
    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
    alignItems="center"
    display="flex"
    flexDirection="row"
    gap={1}
    height={32}
  >
    <Stack width={20} position="relative">
      <IconRound>
        {url ? <Img alt="name" src={url} height={20} width={20} /> : <TokenIcon sx={{ width: 20 }} />}
      </IconRound>
      {chainImgUrl && <ChainImg alt="name" src={chainImgUrl} height={10} width={10} />}
    </Stack>
    <Typography fontSize={14} fontWeight={600} noWrap>
      {symbol} <Small>{coinName}</Small>
    </Typography>
  </Box>
);

const IconRound = styled("div")({
  background: "transparent",
  borderRadius: 20,
  overflow: "hidden",
  height: 20,
  width: 20,
});

const Img = styled("img")({
  height: "100%",
  width: "100%",
});
const ChainImg = styled("img")({
  position: "absolute",
  width: 14,
  height: 14,
  left: 9,
  top: -6,
  borderRadius: 14,
});

const Small = styled("span")(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400,
  fontSize: 14,
}));
