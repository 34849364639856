import React, { useEffect } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { TRADING_TYPES } from "@src/pages/metrics/constant";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import {
  setTradesFiltersAssetsClass,
  setTradesFiltersExchanges,
  setTradesFiltersSides,
  setTradesFiltersStrategy,
  setTradesFiltersTradeType,
  setTradesGroupBy,
} from "@src/store/slices/metricsSlice";
import { AnbotoButtonGroup } from "@src/components/ui/anboto-button-group";
import { MetricsGroupBy, MetricsGroupByOptions } from "@src/store/apis/anbotoApi/types";
import { getExchangeNameFromServerExchangeName } from "@src/pages/metrics/helpers";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { DEFI_SERVER_EXCHANGE_NAMES } from "@src/pages/defi/constants";
import { ORDER_EXECUTION_STRATEGY_NAME } from "@src/pages/cefi/order-form/fields/StrategySelect";
import { firstCapital } from "@src/utils/first-capital";

type TradesFiltersProps = {
  exchanges: string[];
  strategies: string[];
  sides?: string[];
  assetClasses?: string[];
  hideDateFilters?: boolean;
  hideDefiFilters?: boolean;
};

const TradesFilters = ({
  strategies,
  exchanges,
  assetClasses,
  hideDateFilters,
  hideDefiFilters,
  sides,
}: TradesFiltersProps) => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state) => state.metrics.tradesMetricsFilters);

  useEffect(() => {
    dispatch(setTradesFiltersTradeType(TRADING_TYPES));

    dispatch(setTradesFiltersStrategy(strategies));

    dispatch(setTradesFiltersAssetsClass(assetClasses || []));

    dispatch(setTradesFiltersExchanges(exchanges));

    dispatch(setTradesFiltersSides(sides || []));
  }, [strategies, exchanges, assetClasses, sides]);

  const handleChangeTradeType = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setTradesFiltersTradeType(typeof value === "string" ? value.split(",") : value));
  };

  const handleChangeStrategy = (event) => {
    const {
      target: { value },
    } = event;

    dispatch(setTradesFiltersStrategy(typeof value === "string" ? value.split(",") : value));
  };

  const handleAllClick = (checked: boolean) => {
    dispatch(setTradesFiltersStrategy(checked ? strategies : []));
  };

  const handleChangeAssetClass = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setTradesFiltersAssetsClass(typeof value === "string" ? value.split(",") : value));
  };

  const handleChangeExchange = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setTradesFiltersExchanges(typeof value === "string" ? value.split(",") : value));
  };

  const handleSelectAllExchangesClick = (checked: boolean) => {
    dispatch(setTradesFiltersExchanges(checked ? exchanges : []));
  };

  const handleChangeSides = (event) => {
    const {
      target: { value },
    } = event;
    dispatch(setTradesFiltersSides(typeof value === "string" ? value.split(",") : value));
  };

  const handleChangePeriod = (period) => {
    dispatch(setTradesGroupBy(period));
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" gap={0.5}>
        {!hideDefiFilters && (
          <FormControl size="small" sx={{ width: 130, direction: "string" }}>
            <Typography fontSize={12}>Type:</Typography>
            <AnbotoTextField
              size="small"
              select
              SelectProps={{ multiple: true, renderValue: (selected) => (selected as any).join(", ") }}
              value={filters.tradeTypes}
              onChange={handleChangeTradeType}
            >
              {TRADING_TYPES.map((tradingType) => (
                <MenuItem sx={{ margin: 0, padding: 0 }} key={tradingType} value={tradingType}>
                  <Checkbox size="small" checked={filters.tradeTypes.indexOf(tradingType) > -1} />
                  <ListItemText primaryTypographyProps={{ fontSize: 12 }} sx={{ fontSize: 12 }} primary={tradingType} />
                </MenuItem>
              ))}
            </AnbotoTextField>
          </FormControl>
        )}

        <FormControl size="small" sx={{ width: 130, direction: "string" }}>
          <Typography fontSize={12}>Strategies:</Typography>
          <AnbotoTextField
            size="small"
            select
            SelectProps={{
              multiple: true,
              renderValue: (selected) => (selected as string[]).map((s) => ORDER_EXECUTION_STRATEGY_NAME[s]).join(", "),
            }}
            value={filters.strategies}
            onChange={handleChangeStrategy}
          >
            <Stack paddingLeft={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={filters.strategies.length === strategies.length}
                    onChange={() => handleAllClick(filters.strategies.length !== strategies.length)}
                  />
                }
                label={<Typography fontSize={12}>All</Typography>}
              />
            </Stack>
            {strategies.map((strategy) => (
              <MenuItem key={strategy} value={strategy} sx={{ padding: 0 }}>
                <Checkbox size="small" checked={filters.strategies.indexOf(strategy) > -1} />
                <ListItemText
                  primary={ORDER_EXECUTION_STRATEGY_NAME[strategy]}
                  primaryTypographyProps={{ fontSize: 12 }}
                />
              </MenuItem>
            ))}
          </AnbotoTextField>
        </FormControl>

        <FormControl size="small" sx={{ width: 130, direction: "string" }}>
          <Typography fontSize={12}>Asset classes:</Typography>
          <AnbotoTextField
            size="small"
            select
            SelectProps={{
              multiple: true,
              renderValue: (selected) => (selected as string[]).map(firstCapital).join(", "),
            }}
            value={filters.assetClasses}
            onChange={handleChangeAssetClass}
          >
            {assetClasses?.map((assetClass) => (
              <MenuItem sx={{ margin: 0, padding: 0 }} key={assetClass} value={assetClass}>
                <Checkbox size="small" checked={filters.assetClasses.indexOf(assetClass) > -1} />
                <ListItemText
                  primaryTypographyProps={{ fontSize: 12, textTransform: "capitalize" }}
                  sx={{ fontSize: 12 }}
                  primary={assetClass}
                />
              </MenuItem>
            ))}
          </AnbotoTextField>
        </FormControl>

        <FormControl size="small" sx={{ width: 130, direction: "string" }}>
          <Typography fontSize={12}>Exchanges:</Typography>
          <AnbotoTextField
            size="small"
            select
            SelectProps={{
              multiple: true,
              renderValue: (selected) => (selected as string[]).map((s) => CEFI_EXCHANGE_NAME[s] || s).join(", "),
            }}
            value={filters.exchanges}
            onChange={handleChangeExchange}
          >
            <Stack paddingLeft={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={filters.exchanges.length === exchanges.length}
                    onChange={() => handleSelectAllExchangesClick(filters.exchanges.length !== exchanges.length)}
                  />
                }
                label={<Typography fontSize={12}>All</Typography>}
              />
            </Stack>

            {!hideDefiFilters && <ListSubheader sx={{ fontSize: 14, lineHeight: 2.5 }}>CeFi</ListSubheader>}

            {(hideDefiFilters
              ? exchanges
              : exchanges.filter((name) =>
                  Object.keys(CEFI_EXCHANGE_NAME).find((key) => CEFI_EXCHANGE_NAME[key] === name)
                )
            ).map((exchange) => (
              <MenuItem sx={{ padding: 0 }} key={exchange} value={exchange}>
                <Checkbox size="small" checked={filters.exchanges.indexOf(exchange) > -1} />
                <ListItemText
                  primaryTypographyProps={{ fontSize: 12 }}
                  sx={{ fontSize: 12 }}
                  primary={CEFI_EXCHANGE_NAME[exchange] || exchange}
                />
              </MenuItem>
            ))}
            {!hideDefiFilters && (
              <>
                <ListSubheader sx={{ fontSize: 14, lineHeight: 2.5 }}>DeFi</ListSubheader>

                {exchanges
                  .filter((name) =>
                    Object.keys(DEFI_SERVER_EXCHANGE_NAMES).find((key) => DEFI_SERVER_EXCHANGE_NAMES[key] === name)
                  )
                  .map((exchange) => (
                    <MenuItem sx={{ margin: 0, padding: 0 }} key={exchange} value={exchange}>
                      <Checkbox size="small" checked={filters.exchanges.indexOf(exchange) > -1} />
                      <ListItemText
                        primaryTypographyProps={{ fontSize: 12 }}
                        sx={{ fontSize: 12 }}
                        primary={getExchangeNameFromServerExchangeName(exchange)}
                      />
                    </MenuItem>
                  ))}
              </>
            )}
          </AnbotoTextField>
        </FormControl>

        {sides && (
          <FormControl size="small" sx={{ width: 130, direction: "string" }}>
            <Typography fontSize={12}>Side:</Typography>
            <AnbotoTextField
              size="small"
              select
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (selected as any).map(firstCapital).join(", "),
              }}
              value={filters.sides}
              onChange={handleChangeSides}
            >
              {sides?.map((side) => (
                <MenuItem sx={{ margin: 0, padding: 0 }} key={side} value={side}>
                  <Checkbox size="small" checked={filters.sides.indexOf(side) > -1} />
                  <ListItemText
                    primaryTypographyProps={{ fontSize: 12, textTransform: "uppercase" }}
                    sx={{ fontSize: 12 }}
                    primary={side}
                  />
                </MenuItem>
              ))}
            </AnbotoTextField>
          </FormControl>
        )}
      </Stack>

      {!hideDateFilters && (
        <Stack marginRight={4}>
          <Typography fontSize={12}>Group by:</Typography>
          <AnbotoButtonGroup<MetricsGroupBy>
            size="small"
            options={MetricsGroupByOptions}
            defaultValue={MetricsGroupBy.Months}
            onChange={handleChangePeriod}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default TradesFilters;
