import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BigNumber from "bignumber.js";
import {
  CHAIN_ID_DEFAULT_TOKEN_PAIRS,
  DCA_FREQUENCY_STEPS,
  DEFAULT_CHAIN_ID,
  DEFAULT_DEFI_CHILD_SLIPPAGE,
  DEFAULT_DEFI_SLIPPAGE,
} from "../constants";
import { ChainId, GasPriceOptionType } from "@src/pages/defi/types";
import formValidator from "@src/utils/formValidator";
import {
  ClipSizeType,
  DefiOrderExecutionStrategy,
  DefiOrderExpiration,
  OrderExecutionStrategy,
  OrderSide,
  OrderTradingDurationUnit,
  OrderTriggerMode,
  OrderType,
  TriggerCondition,
} from "@src/store/apis/anbotoApi/types";
import { validateAmount } from "@src/pages/defi/validation-utils";
import { useAppSelector } from "@src/store/hooks";
import { getTradingDurationSec } from "@src/pages/cefi/order-form/utils";
import { useDefiOrderFormDefaultValues } from "@src/pages/defi/hooks/use-defi-order-form-default-values";
import { DEFAULT_DEFI_EXTEND_DURATION } from "@src/pages/settings/order-preferences/constant";

export type DefiOrderFormFieldValues = {
  account: string | undefined;
  chainId: ChainId;
  clipSizeValue?: string;
  clipSizeType: ClipSizeType;
  fromTokenAmount: string;
  fromTokenAddress: string;
  fromTokenBalance: string;
  toTokenAmount: string;
  toTokenBalance: string;
  toTokenAddress: string;
  side: OrderSide;
  strategy: DefiOrderExecutionStrategy;
  slippage: string;
  childSlippage?: string;
  type: OrderType;
  expiration: DefiOrderExpiration;
  tradingDuration?: string;
  tradingDurationUnit: OrderTradingDurationUnit;
  triggerCondition: TriggerCondition;
  triggerPrice: string;
  triggerMode: OrderTriggerMode;
  limitPrice: string;
  chainNativeCoinPrice: string;
  buyTokenToEthRate: string;
  sellTokenToEthRate: string;
  randomizeTime?: boolean;
  gasPriceOption: GasPriceOptionType;
  maxGasPrice: string;
  maxFeeAbsolute: string;
  maxFeeAbsoluteUsd: string;
  isFeeTakenInInput: boolean;
  extendDuration: boolean;
  priceProtection: "Market" | "Price protection";
  baseTokenAddress?: string;
  startTime?: string;
  frequency?: string;
  frequencyUnit: OrderTradingDurationUnit;
};

export const DEFI_ORDER_FORM_DEFAULT_VALUES: DefiOrderFormFieldValues = {
  account: undefined,
  chainId: DEFAULT_CHAIN_ID,
  clipSizeType: ClipSizeType.AUTOMATIC,
  clipSizeValue: "",
  fromTokenAddress: CHAIN_ID_DEFAULT_TOKEN_PAIRS[DEFAULT_CHAIN_ID].from.address,
  fromTokenAmount: "",
  toTokenAddress: CHAIN_ID_DEFAULT_TOKEN_PAIRS[DEFAULT_CHAIN_ID].to.address,
  toTokenAmount: "",
  strategy: OrderExecutionStrategy.ORDER,
  expiration: DefiOrderExpiration.WEEK,
  triggerMode: OrderTriggerMode.ONCE,
  randomizeTime: true,
  slippage: DEFAULT_DEFI_SLIPPAGE,
  childSlippage: DEFAULT_DEFI_CHILD_SLIPPAGE,
  tradingDurationUnit: OrderTradingDurationUnit.HOURS,
  tradingDuration: "",
  extendDuration: DEFAULT_DEFI_EXTEND_DURATION,
  limitPrice: "",
  triggerCondition: TriggerCondition.ABOVE,
  triggerPrice: "",
  gasPriceOption: GasPriceOptionType.HIGH,
  maxGasPrice: "",
  maxFeeAbsolute: "",
  maxFeeAbsoluteUsd: "",
  type: OrderType.MARKET,
  side: OrderSide.SELL,
  chainNativeCoinPrice: "",
  buyTokenToEthRate: "",
  sellTokenToEthRate: "",
  fromTokenBalance: "",
  toTokenBalance: "",
  isFeeTakenInInput: false,
  priceProtection: "Market",
  baseTokenAddress: CHAIN_ID_DEFAULT_TOKEN_PAIRS[DEFAULT_CHAIN_ID].from.address,
  startTime: "",
  frequency: DCA_FREQUENCY_STEPS[0].frequency,
  frequencyUnit: DCA_FREQUENCY_STEPS[0].frequencyUnit,
};

const schema = formValidator
  .object({
    account: formValidator.string().required("This field can not be empty"),
    strategy: formValidator.string().required(),
    slippage: formValidator.number().emptyable().required().min(0).max(50).noLabel(),
    childSlippage: formValidator.number().emptyable().required().min(0).max(50).noLabel(),
    tradingDuration: formValidator
      .number()
      .emptyable()
      .min(1)
      .noLabel()
      .when("strategy", {
        is: (strategy: OrderExecutionStrategy) =>
          strategy === OrderExecutionStrategy.TWAP || strategy === OrderExecutionStrategy.DCA,
        then: (x) => x.required(),
      })
      .test(function (value) {
        const tradingDurationUnit = this.parent.tradingDurationUnit;
        const expiration = this.parent.expiration;
        const duration = value && tradingDurationUnit ? getTradingDurationSec(Number(value), tradingDurationUnit) : 0;
        const frequency = this.parent.frequency;
        const frequencyUnit = this.parent.frequencyUnit;
        const frequencyInSeconds =
          frequencyUnit && frequency ? getTradingDurationSec(Number(frequency), frequencyUnit) : 0;
        const isDcaStrategy = this.parent.strategy === OrderExecutionStrategy.DCA;

        if (expiration < duration && !isDcaStrategy) {
          return this.createError({
            message: "Duration cannot be longer than expiration",
            path: "tradingDuration",
          });
        }

        if (isDcaStrategy) {
          if (frequencyInSeconds > duration) {
            return this.createError({
              message: "Duration cannot be less than frequency",
              path: "tradingDuration",
            });
          }
        }

        return true;
      }),
    triggerPriceMin: formValidator.number().emptyable().min(0).noLabel(),
    maxGasPrice: formValidator
      .number()
      .emptyable()
      .noLabel()
      .required("This field can not be empty")
      .min(0.00000001, "Must be greater than 0.00000001")
      .test(function () {
        // todo - this is only for side === 'SELL'
        const isDcaStrategy = this.parent.strategy === OrderExecutionStrategy.DCA;
        const nativeCoinPrice = this.parent.chainNativeCoinPrice;
        const tokenEthRate = this.parent.sellTokenToEthRate;
        const clipSizeType = this.parent.clipSizeType;
        const clipSizeValue = this.parent.clipSizeValue;
        const strategy = this.parent.strategy;
        const maxFeeAbsoluteUsd = this.parent.maxFeeAbsoluteUsd;
        const durationInSeconds = getTradingDurationSec(this.parent.duration, this.parent.tradingDurationUnit);
        const frequencyInSeconds = getTradingDurationSec(this.parent.frequency, this.parent.frequencyUnit);
        const fromTokenAmount = isDcaStrategy
          ? (clipSizeValue * durationInSeconds) / frequencyInSeconds
          : this.parent.fromTokenAmount;

        const message = validateAmount({
          maxFeeAbsoluteUsd,
          clipSizeType,
          clipSizeValue,
          nativeCoinPrice,
          tokenEthRate,
          strategy,
          value: fromTokenAmount,
        });

        if (message) {
          return this.createError({
            message: "Max gas price is too high",
            path: "maxGasPrice",
          });
        } else {
          return true;
        }
      }),
    maxFeeAbsolute: formValidator.number().emptyable().required("This field can not be empty").noLabel(),
    fromTokenAmount: formValidator
      .string()
      .test(function (value) {
        const side = this.parent.side;
        const accountTokenBalance = this.parent.fromTokenBalance;

        if (side === OrderSide.BUY) {
          return true;
        }

        if (accountTokenBalance && value && new BigNumber(value).gt(accountTokenBalance)) {
          return this.createError({
            message: "Insufficient balance",
            path: "fromTokenAmount",
          });
        }

        return true;
      })
      .test(function (value) {
        const side = this.parent.side;

        if (side === OrderSide.BUY) {
          return true;
        }

        const nativeCoinPrice = this.parent.chainNativeCoinPrice;
        const tokenEthRate = this.parent.sellTokenToEthRate;
        const clipSizeType = this.parent.clipSizeType;
        const clipSizeValue = this.parent.clipSizeValue;
        const strategy = this.parent.strategy;
        const maxFeeAbsoluteUsd = this.parent.maxFeeAbsoluteUsd;

        const message = validateAmount({
          maxFeeAbsoluteUsd,
          clipSizeType,
          clipSizeValue,
          nativeCoinPrice,
          tokenEthRate,
          strategy,
          value,
        });

        if (message) {
          return this.createError({
            message,
            path: "fromTokenAmount",
          });
        } else {
          return true;
        }
      }),
    toTokenAmount: formValidator
      .string()
      .test(function (value) {
        const side = this.parent.side;
        const accountTokenBalance = this.parent.toTokenBalance;

        if (side === OrderSide.SELL) {
          return true;
        }

        if (accountTokenBalance && value && new BigNumber(value).gt(accountTokenBalance)) {
          return this.createError({
            message: "Insufficient balance",
            path: "toTokenAmount",
          });
        }

        return true;
      })
      .test(function (value) {
        const side = this.parent.side;

        if (side === OrderSide.SELL) {
          return true;
        }

        const nativeCoinPrice = this.parent.chainNativeCoinPrice;
        const tokenEthRate = this.parent.sellTokenToEthRate;
        const clipSizeValue = this.parent.clipSizeValue;
        const clipSizeType = this.parent.clipSizeType;
        const strategy = this.parent.strategy;
        const maxFeeAbsoluteUsd = this.parent.maxFeeAbsoluteUsd;

        const message = validateAmount({
          maxFeeAbsoluteUsd,
          clipSizeType,
          clipSizeValue,
          nativeCoinPrice,
          tokenEthRate,
          strategy,
          value,
        });

        if (message) {
          return this.createError({
            message,
            path: "toTokenAmount",
          });
        } else {
          return true;
        }
      }),
    limitPrice: formValidator
      .number()
      .emptyable()
      .min(0)
      .noLabel()
      .when(["strategy"], {
        is: (strategy: OrderExecutionStrategy) => strategy === OrderExecutionStrategy.LIMIT,
        then: (x) => x.required(),
      }),
    clipSizeValue: formValidator
      .number()
      .emptyable()
      .min(0)
      .noLabel()
      .when("clipSizeType", {
        is: (type) =>
          type === ClipSizeType.ABSOLUTE ||
          type === ClipSizeType.PERCENTAGE ||
          type === ClipSizeType.NB_OF_CHILD_ORDERS,
        then: (x) => x.required(),
      })
      .when("clipSizeType", {
        is: (type) => type === ClipSizeType.PERCENTAGE,
        then: (x) => x.max(100).min(0),
      })
      .when("clipSizeType", {
        is: (type) => type === ClipSizeType.NB_OF_CHILD_ORDERS,
        then: (x) => x.min(1).integer(),
      })
      .when(["clipSizeType", "fromTokenAmount", "side"], {
        is: (clipSizeType, fromTokenAmount, side) =>
          clipSizeType === ClipSizeType.ABSOLUTE && fromTokenAmount && side === OrderSide.SELL,
        then: (x) => x.max(formValidator.ref("fromTokenAmount")),
      })
      .when(["clipSizeType", "toTokenAmount", "side"], {
        is: (clipSizeType, toTokenAmount, side) =>
          clipSizeType === ClipSizeType.ABSOLUTE && toTokenAmount && side === OrderSide.BUY,
        then: (x) => x.max(formValidator.ref("toTokenAmount")),
      })
      .test(function (value) {
        if (
          this.parent.strategy !== OrderExecutionStrategy.DCA ||
          !value ||
          !this.parent.frequency ||
          !this.parent.tradingDuration
        )
          return true;

        const durationInSeconds = getTradingDurationSec(
          Number(this.parent.tradingDuration),
          this.parent.tradingDurationUnit
        );
        const frequencyInSeconds = getTradingDurationSec(Number(this.parent.frequency), this.parent.frequencyUnit);
        const fromTokenAmount = new BigNumber(value)
          .multipliedBy(durationInSeconds)
          .dividedBy(frequencyInSeconds)
          .toString();

        const accountTokenBalance = this.parent.fromTokenBalance;
        const nativeCoinPrice = this.parent.chainNativeCoinPrice;
        const tokenEthRate = this.parent.sellTokenToEthRate;
        const clipSizeType = this.parent.clipSizeType;
        const clipSizeValue = this.parent.clipSizeValue;
        const strategy = this.parent.strategy;
        const maxFeeAbsoluteUsd = this.parent.maxFeeAbsoluteUsd;

        const message = new BigNumber(fromTokenAmount).gt(accountTokenBalance)
          ? "Insufficient balance"
          : validateAmount({
              maxFeeAbsoluteUsd,
              clipSizeType,
              clipSizeValue,
              nativeCoinPrice,
              tokenEthRate,
              strategy,
              value: String(fromTokenAmount),
            });

        if (message) {
          return this.createError({
            message,
            path: "clipSizeValue",
          });
        }

        return true;
      }),
    startTime: formValidator
      .string()
      .min(1)
      .when("strategy", {
        is: (strategy: OrderExecutionStrategy) => strategy === OrderExecutionStrategy.DCA,
        then: (x) => x.required(),
      }),
    frequency: formValidator
      .number()
      .min(1)
      .noLabel()
      .when("strategy", {
        is: (strategy: OrderExecutionStrategy) => strategy === OrderExecutionStrategy.DCA,
        then: (x) => x.required(),
      }),
  })
  .required();

export const useDefiOrderForm = (values = {}) => {
  const defaultChainId = useAppSelector((state) => state.blockchain.chainId);
  const defaultValues = useDefiOrderFormDefaultValues(values);

  if (defaultChainId) {
    defaultValues.chainId = defaultChainId;
    const chainIdDefaultTokenPairs = CHAIN_ID_DEFAULT_TOKEN_PAIRS[defaultChainId];

    if (chainIdDefaultTokenPairs) {
      defaultValues.fromTokenAddress = chainIdDefaultTokenPairs.from.address;
      defaultValues.toTokenAddress = chainIdDefaultTokenPairs.to.address;
    }
  }

  return useForm<DefiOrderFormFieldValues>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
};
