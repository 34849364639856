import BigNumber from "bignumber.js";
import { FEEBOTO_COINGECKO_ID } from "../constants";
import {
  FeeManagerContract,
  TokenFee,
  AddTokenFeeParams,
  TokenPreference,
  UpdateTokenFeeParams,
  GetFeesRatesResponse,
  FeeManagerAccountStatsResult,
  AddAccountingLineParams,
  AccountingResponse,
} from "./types";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { coingeckoApi } from "@src/store/apis/coingeckoApi";
import { CHAIN_ID_COIN_ID } from "@src/store/apis/coingeckoApi/constants";
import { Item } from "@src/pages/settings/fee-manager/overview-token-fees/draggable-list";
import { feeManagerContractApi } from "@src/store/apis/blockchainApi/fee-manager";

export const feeManagerApi = anbotoApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccounting: builder.query<AccountingResponse, { team_uuid: string }>({
      query: ({ team_uuid }) => ({
        url: `/fee_manager/accounting/${team_uuid}/`,
      }),
    }),
    exportAccountingCSV: builder.mutation<Blob, { team_uuid: string }>({
      query: ({ team_uuid }) => ({
        url: `/fee_manager/accounting_csv/${team_uuid}/`,
        responseHandler: (response) => response.blob(),
      }),
    }),
    getForceUpateAccounting: builder.query<{ updated: boolean }, void>({
      query: () => ({
        url: `/fee_manager/force_update_accounting/`,
      }),
    }),
    getFeeManagerContracts: builder.query<FeeManagerContract[], void>({
      query: () => ({
        url: `/fee_manager/contracts/`,
      }),
    }),
    getPossibleTokenFees: builder.query<TokenFee[], void>({
      query: () => ({
        url: `/fee_manager/tokens/`,
      }),
    }),
    getFeesRates: builder.query<GetFeesRatesResponse, void>({
      query: () => ({
        url: `/fee_manager/fee_rates/`,
      }),
    }),
    addTokenFees: builder.mutation<string, AddTokenFeeParams>({
      query: ({ body, team_uuid }) => ({
        url: `/fee_manager/tokens_preference/${team_uuid}/`,
        method: "POST",
        body,
      }),
    }),
    getFeeTokensPreference: builder.query<TokenPreference[], { team_uuid: string }>({
      query: ({ team_uuid }) => ({
        url: `/fee_manager/tokens_preference/${team_uuid}/`,
      }),
    }),
    updateTokenFees: builder.mutation<string, UpdateTokenFeeParams>({
      query: ({ body, team_uuid }) => ({
        url: `/fee_manager/tokens_preference/${team_uuid}/`,
        method: "PUT",
        body,
      }),
    }),
    addAccountingLine: builder.mutation<string, AddAccountingLineParams>({
      query: ({ body, team_uuid }) => ({
        url: `/fee_manager/accounting/${team_uuid}/`,
        method: "POST",
        body,
      }),
    }),
    getFeeManagerAccountStats: builder.query<FeeManagerAccountStatsResult, { team_uuid: string }>({
      query: ({ team_uuid }) => ({
        url: `/fee_manager/account_stats/${team_uuid}/`,
      }),
    }),
    getFeeTokensAndBalances: builder.query<{ items: Item[] | undefined; balance: number | undefined }, void>({
      async queryFn(arg, { dispatch, getState }) {
        try {
          // const [getFeeManagerBalance] = feeManagerContractApi.useLazyGetBalanceQuery();
          const state = getState();
          const team_uuid = (state as { user: { team_uuid: string } }).user.team_uuid;

          const getFeeManagerContractsQuery = await dispatch(feeManagerApi.endpoints.getFeeManagerContracts.initiate());

          if (getFeeManagerContractsQuery.data?.length) {
            const feeManagerContracts = getFeeManagerContractsQuery.data!;
            const userFeeTokens = await dispatch(
              feeManagerApi.endpoints.getFeeTokensPreference.initiate({ team_uuid })
            ).unwrap();

            const tokenIdsList = userFeeTokens
              .filter((token) => token.details.coin_gecko_id !== FEEBOTO_COINGECKO_ID)
              .map((token) => token.details.coin_gecko_id)
              .filter((tokenId, i, self) => self.indexOf(tokenId) === i)
              .join(",");

            let tokenPrices = await dispatch(coingeckoApi.endpoints.getSimplePrice.initiate({ tokenIdsList })).unwrap();

            tokenPrices = { ...tokenPrices };
            const feeBotoFound = userFeeTokens.find((token) => token.details.coin_gecko_id === FEEBOTO_COINGECKO_ID);

            if (feeBotoFound) {
              tokenPrices[FEEBOTO_COINGECKO_ID] = { usd: 1 };
            }

            const feeManagerContractsReshaped = {};
            feeManagerContracts.forEach((contract, index) => {
              feeManagerContractsReshaped[contract.network_id.toString()] = feeManagerContracts[index];
            });

            const sortedUserFeeTokens = userFeeTokens.slice().sort((a, b) => a.choice_position - b.choice_position);

            const feeTokens = sortedUserFeeTokens.map(async (token) => {
              const network_id = token.details.network_id;
              const tokenAddress = token.details.token_address;

              const contract = feeManagerContractsReshaped[network_id.toString()];

              const sub = dispatch(
                feeManagerContractApi.endpoints.getBalance.initiate(
                  {
                    contract,
                    team_uuid,
                    tokenAddress,
                  },
                  { forceRefetch: true }
                )
              );
              sub.unsubscribe();

              const balance = await sub.unwrap();

              const tokenDecimals = await dispatch(
                blockchainApi.endpoints.getTokenDecimals.initiate({
                  chainId: network_id,
                  tokenAddress,
                })
              ).unwrap();

              const quantity = tokenDecimals
                ? new BigNumber(balance.requestableBalance).div(10 ** tokenDecimals).toNumber()
                : 0;

              return {
                id: token.choice_position.toString(),
                token: token.details.token_symbol,
                token_address: tokenAddress,
                network_id,
                network: CHAIN_ID_COIN_ID[network_id],
                icon: token.details.logo_uri,
                quantity,
                value: quantity * tokenPrices[token.details.coin_gecko_id!]["usd"],
              };
            });

            const tokens = await Promise.all(feeTokens);
            const currentCreditsBalance = tokens.reduce((acc, item) => {
              return acc + item.value;
            }, 0);

            return { data: { items: tokens, balance: currentCreditsBalance } };
          }
        } catch (error) {
          console.log("getFeeTokensAndBalances error", error);
          return { data: { items: undefined, balance: undefined } };
        }

        return { data: { items: undefined, balance: undefined } };
      },
    }),
  }),
});
