import { CSSProperties } from "react";

export const ROW_HEIGHT = 20;

export const TOOLBAR_HEIGHT = 25;

export const amountStyles: CSSProperties = {
  fontSize: 12,
  position: "relative",
  whiteSpace: "nowrap",
};

export const AMOUNT_OPACITY = 1;
