import React from "react";
import { Link, Skeleton, Stack, styled, Typography, useTheme } from "@mui/material";
import { Controller } from "react-hook-form";
import { LimitPriceField } from "@src/pages/defi/order-form-card-no-gas/fields";
import { useDefiOrderFormContext } from "@src/pages/defi/hooks";
import { withDarkFlatStyle } from "@src/components/with-flat-style";
import { usePoolInfo } from "@src/pages/defi/hooks/use-pool-info";
import { GetSwapQuoteWithPreTradeAnalysisDefiAwareData } from "@src/store/apis/anbotoApi/types";
import { cutAmount } from "@src/utils/format";
import {
  getLimitPriceLabel,
  getLimitPriceToFromTokenRatePercent,
} from "@src/pages/defi/order-form-card-no-gas/components/limit-price-control/utils";

type LimitPriceProps = {
  quoteData: GetSwapQuoteWithPreTradeAnalysisDefiAwareData | undefined;
  quoteDataFetching: boolean;
};

const LimitPriceControl = ({ quoteData, quoteDataFetching }: LimitPriceProps) => {
  const theme = useTheme();
  const orderForm = useDefiOrderFormContext();

  const limitPrice = orderForm.watch("limitPrice");
  const fromTokenAmount = orderForm.watch("fromTokenAmount");

  const { baseTokenAddress, isFetching, formFromToken, formToToken } = usePoolInfo();
  const isFromTokenIsBasePoolToken = baseTokenAddress.toLowerCase() === formFromToken?.address.toLowerCase();

  const price = quoteData?.price || "";
  const limitPriceToFromTokenRatePercent = getLimitPriceToFromTokenRatePercent(
    isFromTokenIsBasePoolToken,
    limitPrice,
    price
  );
  const isPositiveLimitPriceRate = +limitPriceToFromTokenRatePercent > 0;

  const isCalculateLimitPriceRate = price && fromTokenAmount && limitPrice && !isFetching && !quoteDataFetching;
  const limitPriceLabel = getLimitPriceLabel(
    !!isCalculateLimitPriceRate,
    isFromTokenIsBasePoolToken,
    formFromToken,
    formToToken,
    isPositiveLimitPriceRate,
    limitPriceToFromTokenRatePercent
  );

  const onMarketPriceClick = () => {
    if (price) {
      orderForm.setValue("limitPrice", cutAmount(isFromTokenIsBasePoolToken ? price : String(1 / +price)));
      orderForm.trigger("limitPrice");
    }
  };

  return (
    <>
      <Stack direction="row" pt={1}>
        <Controller
          name="limitPrice"
          control={orderForm.control}
          render={({ field, formState }) => (
            <StyledLimitPriceField
              {...field}
              onChange={(e) => {
                field.onChange(e);
                orderForm.clearErrors("limitPrice");
              }}
              InputLabelProps={{
                style: {
                  color: isCalculateLimitPriceRate ? (isPositiveLimitPriceRate ? "#ECAC27" : "#179B83") : "#8A9296",
                },
              }}
              label={limitPriceLabel}
              error={!!formState.errors.limitPrice}
              helperText={formState.errors.limitPrice?.message}
              labelStyle="dynamic"
            />
          )}
        />
        <RootBox>
          {price && isCalculateLimitPriceRate && (
            <>
              {quoteDataFetching ? (
                <Skeleton width={50} />
              ) : (
                <Link
                  onClick={onMarketPriceClick}
                  lineHeight="32px"
                  color={theme.palette.text.secondary}
                  fontSize={12}
                  sx={{ cursor: "pointer" }}
                >
                  {cutAmount(isFromTokenIsBasePoolToken ? price : String(1 / +price))}
                </Link>
              )}
            </>
          )}
          <Typography lineHeight="32px" fontSize={14}>
            {isFromTokenIsBasePoolToken ? formToToken?.symbol : formFromToken?.symbol}
          </Typography>
        </RootBox>
      </Stack>

      {isPositiveLimitPriceRate && !!isCalculateLimitPriceRate && (
        <Stack sx={{ background: "#031116", borderRadius: 1, border: "1px solid #ECAC27" }} padding={1} marginTop={1}>
          <Typography fontSize={12} color={theme.palette.text.secondary}>
            Limit price is{" "}
            <Typography variant="caption" color="#ECAC27">
              {" "}
              {limitPriceToFromTokenRatePercent}% {isFromTokenIsBasePoolToken ? "lower" : "higher"} than market
            </Typography>{" "}
            , you are {isFromTokenIsBasePoolToken ? "selling" : "buying"} at a much{" "}
            {isFromTokenIsBasePoolToken ? "lower" : "higher"} rate. We recommend that you use Swap instead
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default LimitPriceControl;

const StyledLimitPriceField = withDarkFlatStyle(LimitPriceField);
const RootBox = styled(Stack)(({ theme }) => ({
  maxHeight: 32,
  backgroundColor: theme.palette.background.paper,
  textAlign: "center",
  width: 250,
  justifyContent: "flex-end",
  fontSize: 14,
  flexDirection: "row",
  gap: 8,
  paddingRight: 8,
}));
