import { useEffect, useCallback } from "react";
import { MarketDataSubscriptionType, MarketOtcOrderbookMessage } from "@src/subscriptions/types";
import { getMarketDataSubscription } from "@src/subscriptions";
import { getLivePriceId, setOtcOrderbook } from "@src/store/slices/subscriptionsSlice";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { useAppDispatch } from "@src/store/hooks";
import { getExchangeIdByExchangeName } from "@src/store/apis/anbotoApi/utils";

const marketDataSubscription = getMarketDataSubscription();
const subscribed: string[] = [];

export const useOtcOrderbookSubscription = (symbols: OrderSymbol[] = []) => {
  const dispatch = useAppDispatch();

  const onMessage = useCallback((_, msg: MarketOtcOrderbookMessage) => {
    const { data_type, symbol, market_type, exchange, data } = msg;

    if (data_type === MarketDataSubscriptionType.OTC_ORDERBOOK) {
      dispatch(
        setOtcOrderbook({
          symbol: { symbol, market_type, exchange },
          bids: data.bids,
          asks: data.asks,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (symbols.length) {
      symbols
        .filter((s) => !subscribed.includes(getLivePriceId(s)))
        .forEach(({ symbol, market_type, exchange }) => {
          if (!subscribed.includes(getLivePriceId({ symbol, market_type, exchange }))) {
            subscribed.push(getLivePriceId({ symbol, market_type, exchange }));

            marketDataSubscription.subscribe(
              {
                symbol,
                market_type,
                data_type: MarketDataSubscriptionType.OTC_ORDERBOOK,
                exchange: getExchangeIdByExchangeName(exchange),
                params: {},
              },
              onMessage
            );
          }
        });
    }
  }, [symbols.map((s) => getLivePriceId(s)).join()]);
};
