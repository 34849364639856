import React from "react";
import { Stack } from "@mui/material";
import ExportCefiTradesButton from "@src/components/export-order-button/export-cefi-trades-button";
import ExportChildOrdersButton from "@src/components/export-order-button/export-child-orders-button";

type ExportOrderButtonProps = {
  isCefi: boolean;
  account_uuid: string;
  order_id: string | undefined;
};
const ExportOrderButtons = ({ isCefi, account_uuid, order_id }: ExportOrderButtonProps) => {
  if (!order_id) return null;

  return (
    <Stack direction="row" gap={2}>
      <ExportChildOrdersButton account_uuid={account_uuid} order_id={order_id} />
      {isCefi && <ExportCefiTradesButton account_uuid={account_uuid} order_id={order_id} />}
    </Stack>
  );
};

export default ExportOrderButtons;
