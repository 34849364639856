import React, { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { CheckboxForm, CheckboxControl } from "./styled";
import { AllocationDefiMenu, filterDefiAllocations } from "@src/pages/portfolio";
import { DefiAllocation } from "@src/store/apis/anbotoApi/types";
import { RootState } from "@src/store/types";
import { AllocationDefiFundsTable } from "@src/pages/portfolio/components/allocation-table/allocation-funds-table/allocation-defi-funds-table";
import {
  DEFAULT_CEFI_ALLOCATION_ITEM_ACTIVE,
  DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE,
  SELECT_ALL,
} from "@src/store/slices/portfolioSlice";

interface AllocationDefiFundsDirectProps {
  data?: DefiAllocation[];
  hideSmallBalance: boolean;
  isFetching: boolean;
  isError: boolean;
  onHideSmallBalance: (hideSmallBalance: boolean) => void;
  refetch: () => void;
}

export const AllocationDefiFunds: FC<AllocationDefiFundsDirectProps> = ({
  data,
  hideSmallBalance,
  isError,
  isFetching,
  refetch,
  onHideSmallBalance,
}) => {
  const selectedAllocationItemName = useSelector((state: RootState) => state.portfolio.activeDefiAllocationItem);
  const selectedWallet = useSelector((state: RootState) => state.portfolio.selectedWallet);
  const isOverview =
    selectedAllocationItemName === DEFAULT_DEFI_ALLOCATION_ITEM_ACTIVE ||
    selectedAllocationItemName === undefined ||
    selectedAllocationItemName === DEFAULT_CEFI_ALLOCATION_ITEM_ACTIVE;
  const filteredData = filterDefiAllocations(isOverview ? {} : { chain_name: selectedAllocationItemName }, data);
  const filteredDataAssets = filteredData?.filter((x) => !x.protocol);
  const filteredDataProtocolAssets = filteredData?.filter((x) => x.protocol);
  const isTableEmpty = filteredData?.length === 0;
  const hideChains = isTableEmpty || isFetching;

  return (
    <Stack display="flex" flexDirection="row" gap="21px">
      {!hideChains && (
        <Stack>
          <Typography mb={2} fontSize={18} lineHeight="30px" variant="h6">
            Allocation
          </Typography>
          <AllocationDefiMenu />
        </Stack>
      )}
      <Stack flexGrow={1}>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent={hideChains ? "space-between" : "flex-end"}
          marginBottom={1}
        >
          {hideChains && (
            <Typography mb={2} fontSize={18} lineHeight="30px" variant="h6">
              Allocation
            </Typography>
          )}
          <CheckboxForm
            control={
              <CheckboxControl
                size="small"
                checked={hideSmallBalance}
                onChange={() => onHideSmallBalance(!hideSmallBalance)}
              />
            }
            label="Hide small balance (<0.1%)"
          />
        </Stack>
        <AllocationDefiFundsTable
          isOverview={selectedWallet === SELECT_ALL}
          data={filteredDataAssets}
          isError={isError}
          isFetching={isFetching}
          refetch={refetch}
          isProtocolAsset={false}
        />
        {filteredDataProtocolAssets && filteredDataProtocolAssets.length > 0 && (
          <>
            <Typography mt={4} mb={2} fontSize={18} lineHeight="30px" variant="h6">
              Protocol Assets
            </Typography>
            <AllocationDefiFundsTable
              isOverview={selectedWallet === SELECT_ALL}
              data={filteredDataProtocolAssets}
              isError={isError}
              isFetching={isFetching}
              refetch={refetch}
              isProtocolAsset={true}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
