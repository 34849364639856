export const APP_TITLES: { [url: string]: string } = {
  "/auth/register_via_invite_link": "Registration",
  "/two-factor-auth": "Two-Factor Authentication",
  "/login": "Login",
  "/forgot-password": "Forgot Password",
  "/reset-password": "Reset Password",
  "/trades/cefi": "CEFI Trades",
  "/trades/defi": "DEFI Trades",
  "/settings/profile": "Profile Settings",
  "/settings/account": "Account Settings",
  "/settings/password": "Password Settings",
  "/settings/user-preferences": "User Preferences",
  "/settings/two-fa": "Two-Factor Authentication Settings",
  "/settings/linked": "Linked Settings",
  "/wallet": "Wallet",
  "/analytics/funding-rates": "Funding Rates Analytics",
  "/portfolio/cefi/funds": "CEFI Portfolio Funds",
  "/portfolio/cefi/transactions": "CEFI Portfolio Transactions",
  "/portfolio/defi/funds": "DEFI Portfolio Funds",
  "/portfolio/defi/transactions": "DEFI Portfolio Transactions",
  "/affiliate-program": "Affiliate Program",
};

export const DEFAULT_TITLE = "Anboto - Smarter Order Execution";
