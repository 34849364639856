import React from "react";
import { Stack, Avatar, AvatarProps } from "@mui/material";
import ascendexUrl from "./icons/ascendex.svg";
import b2c2Url from "./icons/b2c2.webp";
import binanceUrl from "./icons/binance.svg";
import bybitUrl from "./icons/bybit.svg";
import bitmexUrl from "./icons/bitmex.svg";
import coinbaseUrl from "./icons/coinbase.svg";
import gateioUrl from "./icons/gateio.svg";
import huobiUrl from "./icons/huobi.svg";
import krakenUrl from "./icons/kraken.svg";
import kucoinUrl from "./icons/kucoin.svg";
import okxUrl from "./icons/okx.svg";
import wooxUrl from "./icons/woox.svg";
import bitgetUrl from "./icons/bitget.svg";
import mexcUrl from "./icons/mexc.svg";
import { CefiExchangeId } from "@src/store/apis/anbotoApi/types";
import { getExchangeNameLabel } from "@src/store/apis/anbotoApi/helpers";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";

// const getLogoStyles = (exchange: string, size: number) => {
//   let styles: Record<string, string | number> = {
//     height: size,
//     maxWidth: size,
//   };
//   if (exchange === CefiExchangeId.OKX) {
//     styles = {
//       ...styles,
//       height: size - 4,
//     };
//   }
//   return styles;
// };

export const LOGO_URLS = {
  [CefiExchangeId.ASCENDEX]: ascendexUrl,
  [CefiExchangeId.BINANCE]: binanceUrl,
  [CefiExchangeId.BYBIT]: bybitUrl,
  [CefiExchangeId.BITMEX]: bitmexUrl,
  [CefiExchangeId.COINBASE]: coinbaseUrl,
  [CefiExchangeId.GATEIO]: gateioUrl,
  [CefiExchangeId.HUOBI]: huobiUrl,
  [CefiExchangeId.KRAKEN]: krakenUrl,
  [CefiExchangeId.KUCOIN]: kucoinUrl,
  [CefiExchangeId.OKX]: okxUrl,
  [CefiExchangeId.WOOX]: wooxUrl,
  [CefiExchangeId.BITGET]: bitgetUrl,
  [CefiExchangeId.MEXC]: mexcUrl,
  [CefiExchangeId.B2C2]: b2c2Url,
};

export const ExchangeAvatar = ({
  exchange,
  size,
  imgProps,
  ...props
}: { exchange: string; size: number } & AvatarProps) => {
  const name = getExchangeNameLabel(CEFI_EXCHANGE_NAME[exchange]);
  let imageProps = {};

  if (exchange === CefiExchangeId.BITMEX) imageProps = { width: "80%", height: "80%" };

  return (
    <Stack alignItems="center" justifyContent="center">
      <Avatar
        variant="square"
        src={LOGO_URLS[exchange]}
        alt={name}
        sx={{ width: size, height: size }}
        imgProps={{ ...imageProps, ...imgProps }}
        {...props}
      />
    </Stack>
  );
};
