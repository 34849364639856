import React, { useEffect, useRef, memo } from "react";

function TradingViewWidget({ ticker }: { ticker: string }) {
  const container = useRef<HTMLDivElement>(null);
  console.log("tv ticker:", ticker);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      {
        "custom_css_url": "//trade.anboto.xyz/tv.css",
        "autosize": true,
        "symbol": "${ticker}",
        "interval": "1H",
        "timezone": "Etc/UTC",
        "theme": "dark",
        "style": "1",
        "locale": "en",
        "allow_symbol_change": true,
        "calendar": false,
        "save_image": false,
        "allow_symbol_change": false,
        "backgroundColor": "#131A1C",
        "backgroundColor": "#131A1C",
        "toolbar_bg": "#131A1C",
        "gridColor": "#192022",
        "hide_legend": true,
        "support_host": "https://www.tradingview.com",
        "overrides": {
          "scalesProperties.textColor": "#8A9296",
          "paneProperties.vertGridProperties.color": "#192022",
          "paneProperties.horzGridProperties.color": "#192022",
          "paneProperties.backgroundType": "solid",
          "paneProperties.background": "#131A1C",
          "mainSeriesProperties.candleStyle.upColor": "#179B83",
          "mainSeriesProperties.candleStyle.downColor": "#C3462B",
          "mainSeriesProperties.candleStyle.borderUpColor": "#179B83",
          "mainSeriesProperties.candleStyle.borderDownColor": "#C3462B",
          "mainSeriesProperties.candleStyle.wickUpColor": "#179B83",
          "mainSeriesProperties.candleStyle.wickDownColor": "#C3462B"
        }
      }`;

    container.current?.replaceChildren(script);
  }, [ticker]);

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
    </div>
  );
}

export default memo(TradingViewWidget);
