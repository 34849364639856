import React from "react";
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { CONFIG } from "./config";
import { formatTokenAmount } from "@src/utils/format";

const rows = [
  [300, 1500, 3000, 6000, 15_000, 30_000],
  [200, 1_000, 2_000, 4_000, 10_000, 20_000],
  [150, 750, 1_500, 3_000, 7_500, 15_000],
  [100, 500, 1_000, 2_000, 5_000, 10_000],
  [50, 250, 500, 1_000, 2_500, 5_000],
  [40, 200, 400, 800, 2_000, 4_000],
];

export const CampaignRules = () => {
  return (
    <>
      <Typography variant="body2">
        Seize the opportunity to win an iPhone 15 and share a 100,000 USDT bonus with Anboto!
      </Typography>
      <Typography variant="body2" my={2}>
        Simply{" "}
        <Link href={CONFIG.REF_LINK} target="_blank" rel="noreferrer">
          register
        </Link>{" "}
        a Bitget account with Anboto’s referral link and start trading on Bitget.
      </Typography>
      <Typography variant="h6" my={2}>
        Trading Round:
      </Typography>
      <Typography variant="body2" my={2}>
        At the end of the competition, participants will be ranked by their Trading Volume:
      </Typography>
      <TableContainer>
        <Table
          sx={{
            borderCollapse: "separate",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell padding="none">Ranking</TableCell>
              <TableCell align="right">
                Total Trading Volume
                <br />
                {">"} 10m
              </TableCell>
              <TableCell align="right">
                Total Trading Volume
                <br />
                {">"} 25m
              </TableCell>
              <TableCell align="right">
                Total Trading Volume
                <br />
                {">"} 50m
              </TableCell>
              <TableCell align="right">
                Total Trading Volume
                <br />
                {">"} 100m
              </TableCell>
              <TableCell align="right">
                Total Trading Volume
                <br />
                {">"} 500m
              </TableCell>
              <TableCell align="right">
                Total Trading Volume
                <br />
                {">"} 1b
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  background: "#192022",
                  paddingLeft: 0,
                  paddingRight: 0,
                  "&>td": {
                    border: 0,
                    fontWeight: 500,
                    fontSize: 14,
                    marginBottom: 1,
                    color: "#B0BCC2",
                    padding: 1,
                  },
                  "&>td:last-child": { paddingRight: 0 },
                  "&>td:first-child": { paddingLeft: 0 },
                }}
              >
                <TableCell align="left" key={index}>
                  {index + 1}
                </TableCell>
                {row.map((value) => (
                  <TableCell align="right" key={value}>
                    {formatTokenAmount(value)} USDT
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6" my={2}>
        Exclusive Messi Merchandise:
      </Typography>
      <Typography variant="body2" my={2}>
        3 lucky users will receive an exclusive Bitget x Messi Merchandise at the end of the campaign.
      </Typography>
      <Typography variant="h6" my={2}>
        Exclusive Bonus and Fee Discount for Anboto’s users:
      </Typography>
      <Typography variant="body2" my={2}>
        Users who{" "}
        <Link target="_blank" href={CONFIG.REF_LINK} rel="noreferrer">
          register
        </Link>{" "}
        and complete KYC during the campaign period will receive a 25 usdt trading bonus + special 10% futures fee
        reduction for 30 days
      </Typography>
      <Typography variant="h6" my={2}>
        iPhone 15 Giveaway:
      </Typography>
      <Typography variant="body2" my={2}>
        The user with the highest trading volume during the campaign period wins the iPhone 15. To be eligible for the
        iPhone 15 giveaway, a minimum trading volume of 1,000,000 USDT is required.
      </Typography>
    </>
  );
};
