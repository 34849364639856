import { useState } from "react";

export const RECENT_CHAIN_ID_LS = "RECENT_CHAIN_ID";

export const useRecentChainIdState = () => {
  const [state, setState] = useState<number>(() => parseInt(window.localStorage.getItem(RECENT_CHAIN_ID_LS) || ""));
  const _setState = (newRecentChainId: number) => {
    window.localStorage.setItem(RECENT_CHAIN_ID_LS, newRecentChainId.toString());
    setState(newRecentChainId);
  };
  return { state, setState: _setState };
};
