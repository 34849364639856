import React, { useEffect, useState } from "react";
import { Steps } from "intro.js-react";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { setIsDefiIntroShown, setIsIntroShowing, setIsWidgetsIntroShown } from "@src/store/actions/user.action";
import { IntroItem, IntroType } from "@src/components/intro/types";
import { COMMON_INTRO_STEPS, DEFI_INTRO_STEPS, CEFI_WIDGETS_INTRO_STEPS } from "@src/components/intro/steps";
import { useTestnetEnabled } from "@src/hooks/use-testnet-enabled";

type IntroProps = {
  page: IntroType;
};

export const Intro = ({ page }: IntroProps) => {
  const isTestnet = useTestnetEnabled();

  const dispatch = useAppDispatch();

  const [patchUser] = anbotoApi.usePatchUserMutation();

  const { is_defi_intro_shown, is_widgets_intro_shown, is_cefi_or_defi_modal_shown } = useAppSelector(
    (state) => state.user
  );
  const { isIntroShowing } = useAppSelector((state) => state.user);

  let steps: IntroItem[] = [];
  if (page === "defi") {
    steps = [...DEFI_INTRO_STEPS, ...(is_widgets_intro_shown ? [] : COMMON_INTRO_STEPS)];
  } else if (page === "cefi-widgets") {
    steps = [...CEFI_WIDGETS_INTRO_STEPS, ...(is_defi_intro_shown ? [] : COMMON_INTRO_STEPS)];
  }

  useEffect(() => {
    if ((!is_defi_intro_shown && page === "defi") || (!is_widgets_intro_shown && page === "cefi-widgets")) {
      dispatch(setIsIntroShowing(true));
      return;
    }

    dispatch(setIsIntroShowing(false));
  }, [page]);

  const onExit = async () => {
    dispatch(setIsIntroShowing(false));

    if (page === "defi") {
      dispatch(setIsDefiIntroShown(true));
      await patchUser({ is_defi_intro_shown: true });
    } else if (page === "cefi-widgets") {
      dispatch(setIsWidgetsIntroShown(true));
      await patchUser({ is_widgets_intro_shown: true });
    }
    document.body.style.overflow = "auto";
  };

  const [showIntro, setShowIntro] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowIntro(true);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {!isTestnet && showIntro && is_cefi_or_defi_modal_shown && isIntroShowing ? (
        <Steps
          enabled={true}
          steps={steps}
          initialStep={0}
          onExit={onExit}
          onStart={() => {
            document.body.style.overflow = "hidden";
          }}
          options={{
            disableInteraction: true,
            tooltipClass: "anboto-intro",
            showProgress: true,
            showBullets: false,
            nextLabel: "Next >",
            prevLabel: "< Back",
            hidePrev: true,
            showStepNumbers: true,
            stepNumbersOfLabel: "/",
            scrollToElement: false,
          }}
        />
      ) : null}
    </>
  );
};
