import React from "react";
import { TabPanelProps } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { StyledTabPanel } from "@src/components";

export const ControllsPanel = ({
  children,
  isTab,
  title,
  ...props
}: React.PropsWithChildren & TabPanelProps & { isTab: boolean; title?: string }) => {
  return isTab ? (
    <StyledTabPanel {...props}>{children}</StyledTabPanel>
  ) : (
    <Stack>
      {title && (
        <Typography variant="body2" fontWeight="bold" mt={2} mb={1.5}>
          {title}
        </Typography>
      )}
      {children}
    </Stack>
  );
};
