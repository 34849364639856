import { ExchangeId } from "./types";
import { binance } from "./exchanges/binance";
import { bybit } from "./exchanges/bybit";
import { coinbase } from "./exchanges/coinbase";
import { gateio } from "./exchanges/gateio";
import { kraken } from "./exchanges/kraken";
import { kucoin } from "./exchanges/kucoin";
import { okx } from "./exchanges/okx";
import { ascendex } from "./exchanges/ascendex";
import { huobi } from "./exchanges/huobi";
import { bitmex } from "./exchanges/bitmex";
import { woox } from "./exchanges/woox";
import { bitget } from "./exchanges/bitget";
import { mexc } from "./exchanges/mexc";

export const exchanges = {
  [ExchangeId.BINANCE]: binance,
  [ExchangeId.BYBIT]: bybit,
  [ExchangeId.COINBASE]: coinbase,
  [ExchangeId.GATEIO]: gateio,
  [ExchangeId.KRAKEN]: kraken,
  [ExchangeId.KUCOIN]: kucoin,
  [ExchangeId.OKX]: okx,
  [ExchangeId.ASCENDEX]: ascendex,
  [ExchangeId.HUOBI]: huobi,
  [ExchangeId.BITMEX]: bitmex,
  [ExchangeId.WOOX]: woox,
  [ExchangeId.BITGET]: bitget,
  [ExchangeId.MEXC]: mexc,
};
