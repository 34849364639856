import React, { useEffect, useState } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { ReferralList, referralProgramApi, ReferTraderDialog } from "@src/pages/referral-program";
import { useDialog } from "@src/hooks/useDialog";
import { Code } from "@src/pages/referral-program/apis/types";
import { useAppSelector } from "@src/store/hooks";
import { RootState } from "@src/store/types";

const Overview = () => {
  const referTraderDialog = useDialog();

  const [currentCode, setCurrentCode] = useState("");

  const onShareClickHandler = (code: Code) => {
    setCurrentCode(code.code);
    referTraderDialog.show();
  };

  const team_uuid = useAppSelector((state: RootState) => state.user.team_uuid)!;
  const { data, isError, isFetching, refetch } = referralProgramApi.useGetCodesListQuery({ team_uuid });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Stack>
      {isError ? (
        <Stack justifyContent="center" alignItems="center" minHeight={400}>
          <Typography>Something went wrong. Please reload the page.</Typography>
        </Stack>
      ) : isFetching ? (
        <Stack justifyContent="center" alignItems="center" minHeight={400}>
          <CircularProgress />
        </Stack>
      ) : (
        <ReferralList onShareClick={onShareClickHandler} codeList={data?.codes_details || []} />
      )}

      <ReferTraderDialog code={currentCode} open={referTraderDialog.isOpen} handleClose={referTraderDialog.hide} />
    </Stack>
  );
};

export default Overview;
