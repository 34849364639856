import React from "react";
import {
  Box,
  CircularProgress,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CHAIN_CONFIG, CHAIN_ID_ANBOTO_CONTRACT_ADDRESS_V2, CHAIN_NAME } from "../defi/constants";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { NoTableData } from "@src/components/no-table-data";

export const AnbotoExecBalances = () => {
  const execContractBalancesQuery = anbotoApi.useGetExecContractsBalancesQuery();

  return (
    <Stack gap={0.5} flexGrow={1}>
      <Paper>
        <Box>
          {execContractBalancesQuery.isLoading ? (
            <Stack height={300} alignItems="center" justifyContent="center">
              <CircularProgress />
            </Stack>
          ) : execContractBalancesQuery.isError ? (
            <Box display="flex" alignItems="center" flexDirection="column" gap={2} justifyContent="center" height={238}>
              <NoTableData />
            </Box>
          ) : (
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Box>
                <Box sx={{ pt: 4 }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Balances in USD of funds locked on Anboto Exec. Contract
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        width: 300,
                        marginBottom: 3,
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Blockchain</TableCell>
                          <TableCell align="right">Total balance USD</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {execContractBalancesQuery.data ? (
                          execContractBalancesQuery.data.chains.map((row, index) => {
                            const contractAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS_V2[row.network_id];

                            return (
                              <TableRow
                                key={index}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 }, border: 0, elevation: 0 }}
                              >
                                <TableCell component="th" scope="row">
                                  <Link
                                    sx={{
                                      cursor: "pointer",
                                      color: "white",
                                      textDecoration: "underline",
                                      fontSize: 14,
                                    }}
                                  >
                                    <a
                                      style={{ color: "white" }}
                                      target="_blank"
                                      href={
                                        CHAIN_CONFIG[row.network_id].blockExplorerUrls + "/address/" + contractAddress
                                      }
                                      rel="noreferrer"
                                    >
                                      {CHAIN_NAME[row.network_id]}
                                    </a>
                                  </Link>
                                </TableCell>
                                <TableCell component="th" scope="row" align="right">
                                  {Math.round(row.balances_detail.result.totalBalanceUsd)}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                                gap={2}
                                justifyContent="center"
                                height={238}
                              >
                                <NoTableData />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
              <Box>
                <Box sx={{ pt: 4 }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Balances in USD of all tokens locked on Anboto Exec. Contract (on all chains)
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        width: 640,
                        marginBottom: 3,
                      }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Blockchain</TableCell>
                          <TableCell align="right">Token name</TableCell>
                          <TableCell align="right">Token Symbol</TableCell>
                          <TableCell align="right">Balance</TableCell>
                          <TableCell align="right">Balance USD</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {execContractBalancesQuery.data ? (
                          execContractBalancesQuery.data.chains
                            .map((chain) => chain.balances_detail.result.assets)
                            .flat()
                            .map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 }, border: 0, elevation: 0 }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.blockchain}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.tokenName}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.tokenSymbol}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {Math.round(row.balance * 10 ** 5) / 10 ** 5 || 0}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {Math.round(row.balanceUsd) || 0}
                                </TableCell>
                              </TableRow>
                            ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              <Box
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                                gap={2}
                                justifyContent="center"
                                height={238}
                              >
                                <NoTableData />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Stack>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};
