import React, { forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

type TriggerPriceFieldProps = ControllerRenderProps & AnbotoTextFieldProps;

export const TriggerPriceField = forwardRef((props: TriggerPriceFieldProps, ref) => {
  return (
    <AnbotoTextField
      {...props}
      inputRef={ref}
      size="small"
      label={
        <Stack direction="row" gap={1} alignItems="center">
          <Typography fontSize={11} variant="caption">
            {props.label || "Trigger Price"}
          </Typography>
          <Tooltip title="The price at which the order begins to execute. Execution is inactive and starts only when the trigger price is reached.">
            <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
          </Tooltip>
        </Stack>
      }
      fullWidth
      type="number"
    />
  );
});

TriggerPriceField.displayName = "TriggerPriceField";
