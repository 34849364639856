import React, { memo, useMemo, useState } from "react";
import { Stack, Box, Typography, Tooltip, styled, IconButton } from "@mui/material";
import { DragDropContext, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { DraggableListItem } from "./draggable-list-Item";
import { UseDepositType, UseWithdrawType } from ".";
import { useFeeManagerBalances } from "@src/pages/settings/fee-manager/hooks/use-fee-manager-balances";
import { usePermissions } from "@src/hooks/use-permissions";

export type Item = {
  id: string;
  token: string;
  token_address?: string;
  network_id?: number;
  network: string;
  icon: string;
  quantity: GLfloat;
  value: GLfloat;
};

export type DraggableListProps = {
  items: Item[];
  onDragEnd: OnDragEndResponder;
  useDeposit: UseDepositType;
  useWithdraw: UseWithdrawType;
};

const DraggableList = ({ items, onDragEnd, useDeposit, useWithdraw }: DraggableListProps) => {
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const feeTokens = useMemo(
    () =>
      items.map((item) => {
        return { chainId: item.network_id || 0, tokenAddress: item.token_address || "" };
      }),
    [items]
  );
  const feeManagerBalances = useFeeManagerBalances({
    feeTokens,
  });
  const permissions = usePermissions();

  return (
    <>
      <Stack alignContent="center" alignItems="center">
        <Box width="90%">
          <DragDropContext onDragEnd={onDragEnd}>
            <Stack>
              <Stack
                direction="row"
                alignSelf="center"
                justifyContent="space-between"
                sx={{ width: "70%", maxWidth: "720px", mb: 2 }}
              >
                <Stack>
                  <Stack alignSelf="left" direction="row">
                    <Typography>Default token to pay your fees</Typography>
                    <Tooltip title="This list will set your preference choices to pay your fees" sx={{ fontSize: 12 }}>
                      <StyledInfo fontSize="inherit" sx={{ mt: 0.55, ml: 0.5 }} />
                    </Tooltip>
                  </Stack>
                  {permissions.trade && (
                    <Typography noWrap textAlign="left" color="text.disabled" fontSize={12}>
                      Unlock the drag & drop list to edit your preference
                    </Typography>
                  )}
                </Stack>
                {permissions.trade && (
                  <IconButton onClick={() => setIsDraggable(!isDraggable)}>
                    {isDraggable ? <LockOpenOutlinedIcon color="disabled" /> : <LockOutlinedIcon color="disabled" />}
                  </IconButton>
                )}
              </Stack>
              <Droppable droppableId="droppable-list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {items.map((item, index) => (
                      <DraggableListItem
                        balancesInfo={feeManagerBalances.feeBalances[item.network_id! + item.token_address!]}
                        item={item}
                        index={index}
                        key={item.id}
                        useDeposit={useDeposit}
                        useWithdraw={useWithdraw}
                        isDraggable={isDraggable}
                        disabled={!permissions.trade}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Stack>
          </DragDropContext>
        </Box>
      </Stack>
    </>
  );
};

export default memo(DraggableList);

const StyledInfo = styled(InfoOutlinedIcon)(() => ({
  cursor: "pointer",
  height: "13px",
  width: "13px",
  color: "#8A9296",
}));
