import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { Stack, DialogContent, IconButton, Typography, styled, Paper } from "@mui/material";
import { Close } from "@mui/icons-material";
import { TabContext } from "@mui/lab";
import Draggable from "react-draggable";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useExchangeSymbolErrors } from "../../hooks/use-exchange-symbol-errors";
import { orderAdvancedSettingsModals } from "../../utils";
import { AdvancedOptionsDialogProps } from "./types";
import { TabValue, TabLabel } from "./constants";
import { StyledTabList, StyledTabListTab } from "@src/components";
import { CardDialog } from "@src/components/CardDialog";
import { OrderExecutionStrategy } from "@src/store/apis/anbotoApi/types";
import { GroupIcon } from "@src/features/widgets-layout/components/group-icon";

export const ADVANCED_OPTIONS_DIALOG_WIDTH = 400;

export const AdvancedOptionsRoot = ({
  draggable,
  children,
  strategy,
  hideWouldPrice,
  hideLimitPrice,
  hideTriggerPrice,
  hideMakerPlacement,
  hideClipsize,
  exchangeSymbolErrors,
  currentTab,
  onTabChange,
  openInPopupClick,
  draggableOpened,
  width,
  height,
  ...props
}: React.PropsWithChildren &
  AdvancedOptionsDialogProps & {
    draggable?: { x: number; y: number; id: string; group: string } | null;
    strategy: OrderExecutionStrategy;
    hideWouldPrice?: boolean;
    hideLimitPrice?: boolean;
    hideTriggerPrice?: boolean;
    hideMakerPlacement?: boolean;
    hideClipsize?: boolean;
    exchangeSymbolErrors: ReturnType<typeof useExchangeSymbolErrors>;
    currentTab: string;
    onTabChange: (tab: TabValue) => void;
  }) => {
  const popupRef = React.useRef<HTMLDivElement>(null);
  const setActiveModal = () => {
    if (draggable) orderAdvancedSettingsModals(draggable.id);
  };

  useEffect(() => {
    if (draggable && props.open) {
      setActiveModal();
    }
  }, [draggable, props.open]);

  return draggable && draggableOpened ? (
    createPortal(
      <Draggable
        handle={`.advanced-options-header-${draggable.id}`}
        defaultPosition={{ x: draggable.x, y: draggable.y }}
        onMouseDown={setActiveModal}
      >
        <StyledModal
          elevation={8}
          className={`advanced-options-popup advanced-options-popup-${draggable.id}`}
          ref={popupRef}
          width={width}
          height={height}
        >
          <Stack
            direction="row"
            gap={1}
            alignItems="center"
            px={2}
            pt={1.5}
            sx={{ cursor: "grab" }}
            className={`advanced-options-header-${draggable.id}`}
          >
            <Typography variant="body2" noWrap flex={1}>
              Advanced settings
            </Typography>
            <GroupIcon group={draggable.group} />
            <IconButton size="small" sx={{ background: "none !important" }} onClick={props.onClose}>
              <Close sx={{ fontSize: 14, color: (theme) => theme.palette.text.primary }} />
            </IconButton>
          </Stack>
          <Stack px={2} pb={2}>
            {children}
          </Stack>
        </StyledModal>
      </Draggable>,
      document.body
    )
  ) : draggable && !draggableOpened ? null : (
    <CardDialog
      {...props}
      isAdvancedSettingsCard={true}
      sx={{ height: 200, bottom: 0, top: "auto" }}
      withOverlay
      onOverlayClick={props.onClose}
    >
      <DialogContent
        sx={{
          py: 0.5,
          px: 1.5,
          display: "flex",
          flexDirection: "column",
          background: "#121a1c",
          borderTopLeftRadius: (theme) => theme.shape.borderRadius,
          borderTopRightRadius: (theme) => theme.shape.borderRadius,
        }}
      >
        <Stack direction="row" sx={{ position: "absolute", right: 5, top: 9 }}>
          <IconButton onClick={openInPopupClick} size="small">
            <OpenInNewIcon sx={{ fontSize: 18, color: (theme) => theme.palette.text.disabled }} />
          </IconButton>
          <IconButton onClick={props.onClose} size="small">
            <Close sx={{ fontSize: 18, color: (theme) => theme.palette.text.disabled }} />
          </IconButton>
        </Stack>
        <TabContext value={currentTab}>
          <StyledTabList
            onChange={(e, x: TabValue) => onTabChange(x)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ mb: 0.5, mr: 6, background: (theme) => theme.custom.background.secondary }}
          >
            {strategy === OrderExecutionStrategy.POV && <StyledTabListTab label="PoV" value={TabValue.Pov} />}
            {!hideWouldPrice && (
              <StyledTabListTab
                label={TabLabel.WouldPrice}
                value={TabValue.WouldStyle}
                error={!!exchangeSymbolErrors.errors.wouldPrice}
              />
            )}
            {!hideLimitPrice && (
              <StyledTabListTab
                label={TabLabel.LimitPrice}
                value={TabValue.LimitPrice}
                error={!!exchangeSymbolErrors.errors.limitPrice}
              />
            )}
            {!hideTriggerPrice && (
              <StyledTabListTab
                label={TabLabel.TriggerPrice}
                value={TabValue.TriggerPrice}
                error={!!exchangeSymbolErrors.errors.triggerPrice}
              />
            )}
            {!hideMakerPlacement && <StyledTabListTab label="Maker Placement" value={TabValue.MakerPlacement} />}
            {!hideClipsize && (
              <StyledTabListTab
                label={TabLabel.ClipSize}
                value={TabValue.ClipSize}
                error={!!exchangeSymbolErrors.errors.clipSize}
              />
            )}
            <StyledTabListTab label="Expiration" value={TabValue.Expiration} />
          </StyledTabList>
          {children}
        </TabContext>
      </DialogContent>
    </CardDialog>
  );
};

const StyledModal = styled(Paper)<{ width?: number; height?: number }>(({ theme, width, height }) => ({
  width: width || ADVANCED_OPTIONS_DIALOG_WIDTH,
  height,
  border: `1px solid ${theme.custom.colors.primary}`,
  position: "absolute",
  borderRadius: theme.shape.borderRadius,
  top: 0,
  left: 0,
}));
