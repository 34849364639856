import React from "react";
import { Card, useTheme } from "@mui/material";

export const SettingsRow = ({ children }: React.PropsWithChildren<any>) => {
  const theme = useTheme();

  return (
    <Card
      variant="outlined"
      sx={{ padding: theme.spacing(2), maxWidth: 830, width: "100%", margin: `${theme.spacing(3)} auto` }}
    >
      {children}
    </Card>
  );
};
