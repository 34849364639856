import BigNumber from "bignumber.js";
import { formatAmount } from "@src/pages/portfolio/utils/helpers";
import { GECKOTERMINAL_CHAIN_IDS } from "@src/store/apis/geckoterminal-api/constants";
import { ChainId } from "@src/pages/defi/types";

export const formatLargeAmount = (amount: string) => {
  const bnAmount = new BigNumber(amount);
  if (bnAmount.lt(new BigNumber("1000000"))) return formatAmount(amount);

  if (bnAmount.gt("1000000000000000000000000")) {
    return bnAmount.dividedBy("1000000000000000000000000").toFixed(2).replace(/\.0$/, "") + "Sp";
  }
  if (bnAmount.gt("1000000000000000000000")) {
    return bnAmount.dividedBy("1000000000000000000000").toFixed(2).replace(/\.0$/, "") + "Sx";
  }
  if (bnAmount.gt("1000000000000000000")) {
    return bnAmount.dividedBy("1000000000000000000").toFixed(2).replace(/\.0$/, "") + "Qn";
  }
  if (bnAmount.gt("1000000000000000")) {
    return bnAmount.dividedBy("1000000000000000").toFixed(2).replace(/\.0$/, "") + "Q";
  }
  if (bnAmount.gt("1000000000000")) {
    return bnAmount.dividedBy("1000000000000").toFixed(2).replace(/\.0$/, "") + "T";
  }
  if (bnAmount.gt("1000000000")) {
    return bnAmount.dividedBy("1000000000").toFixed(2).replace(/\.0$/, "") + "B";
  }
  if (bnAmount.gt("1000000")) {
    return bnAmount.dividedBy("1000000").toFixed(2).replace(/\.0$/, "") + "M";
  }
};

// it's possible to get some object instead of number in ankr => we need to transform {} to zero
export const transformToNumber = (amount) => {
  if (typeof amount === "object" || amount === undefined) {
    return 0;
  } else {
    return amount;
  }
};

export const buildDebankLink = (address: string | undefined) =>
  address ? "https://debank.com/profile/" + address : "#";

export const getChainIdFromGeckoTerminalPoolId = (id: string | undefined): ChainId | undefined => {
  const geckoTerminalChainId = id?.replace(/_(?=0x[0-9a-fA-F]{40})[0-9a-zA-Z_]*?(?=_0x[0-9a-fA-F]{40}|$)/g, "");

  if (geckoTerminalChainId) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Object.entries(GECKOTERMINAL_CHAIN_IDS).find(([, v]) => v === geckoTerminalChainId)?.[0] as ChainId;
  } else {
    return undefined;
  }
};
