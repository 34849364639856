import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import formValidator from "@src/utils/formValidator";

export type ChangePasswordFormFieldValues = {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
};

const schema = formValidator
  .object({
    oldPassword: formValidator.string().required().min(8).label("Old password"),
    newPassword: formValidator.string().required().min(8).label("New password"),
    newPasswordRepeat: formValidator
      .string()
      .required()
      .oneOf([formValidator.ref("newPassword")], "Your passwords do not match.")
      .label("Verify password"),
  })
  .required();

export const useChangePasswordForm = () =>
  useForm<ChangePasswordFormFieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
    },
  });
