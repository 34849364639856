import React, { FC } from "react";
import { IconButton, Stack, styled, Typography } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { UseWalletsResult } from "@src/pages/portfolio";

export type RenderWalletProps = {
  walletAddress: string;
  wallets: UseWalletsResult;
  onCopyIconClick: (e, walletAddress: string) => void;
  onWalletClick: (address: string) => void;
};

export const RenderWallet: FC<RenderWalletProps> = (props) => {
  const { onCopyIconClick, onWalletClick, wallets, walletAddress } = props;

  return (
    <WalletItem onClick={() => onWalletClick(walletAddress)}>
      <Typography
        sx={{ color: (theme) => theme.palette.text.primary }}
        fontSize={16}
        lineHeight="24px"
        fontWeight={600}
      >
        {wallets[walletAddress]}
      </Typography>
      <Stack
        sx={{ color: (theme) => theme.palette.text.secondary }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography fontSize={12} lineHeight="18px" fontWeight={400}>
          {walletAddress}
        </Typography>
        <IconButton onClick={(e) => onCopyIconClick(e, walletAddress)}>
          <ContentCopy sx={{ color: (theme) => theme.palette.text.secondary, width: 20 }} />
        </IconButton>
      </Stack>
    </WalletItem>
  );
};

const WalletItem = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.custom.background.secondary,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
  gap: 6,
  cursor: "pointer",
}));
