import {
  portfolioExchangesList,
  GetPortfolioUserExchangeListResult,
  usePortfolioExchangesListQuery,
} from "@src/pages/portfolio";

export interface UseUserExchangesListResult {
  data?: GetPortfolioUserExchangeListResult;
  dataFromCache?: GetPortfolioUserExchangeListResult;
  isError: boolean;
  isFetching: boolean;
  isLoading: boolean;
  refetch: () => void;
}

export const useUserExchangesList = (): UseUserExchangesListResult => {
  const { data, isError, isLoading, isFetching, refetch } = usePortfolioExchangesListQuery(null);

  const portfolioExchangesListState = portfolioExchangesList.useQueryState(null);

  return {
    data,
    dataFromCache: portfolioExchangesListState.currentData,
    isError,
    isLoading,
    isFetching,
    refetch,
  };
};
