import { anbotoApi } from "@src/store/apis/anbotoApi";

export interface GetPortfolioUserExchangeListResult {
  available: string[];
  selected: string[];
  success: boolean;
}

export interface PortfolioUserExchanges {
  portfolio_exchanges: string[];
}

const portfolioExchangesListApi = anbotoApi.injectEndpoints({
  endpoints: (builder) => ({
    portfolioExchangesList: builder.query<GetPortfolioUserExchangeListResult, null>({
      query: () => ({
        url: `/portfolio/user_exchanges_list`,
      }),
      providesTags: ["PORTFOLIO_EXCHANGES_LIST"],
    }),
    addPortfolioExchange: builder.mutation<GetPortfolioUserExchangeListResult, PortfolioUserExchanges>({
      invalidatesTags: ["PORTFOLIO_EXCHANGES_LIST"],
      query: (body) => ({
        method: "POST",
        url: `/portfolio/user_exchanges_list/`,
        body,
      }),
      async onQueryStarted({ portfolio_exchanges }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(exchangesListOptimisticUpdate({ selected: portfolio_exchanges }));
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const exchangesListOptimisticUpdate = (data) =>
  portfolioExchangesListApi.util.updateQueryData("portfolioExchangesList", null, (draft) => {
    Object.assign(draft, data);
  });

export const portfolioExchangesList = portfolioExchangesListApi.endpoints.portfolioExchangesList;

export const { usePortfolioExchangesListQuery, useAddPortfolioExchangeMutation } = portfolioExchangesListApi;
