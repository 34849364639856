import React from "react";
import { Tab, Tabs, styled, Box } from "@mui/material";
import { FormVariant } from "../types";
import { OrderSide } from "@src/store/apis/anbotoApi/types";

type SideSelectProps = {
  value: OrderSide;
  onChange(value: OrderSide): void;
  variant?: FormVariant;
};

export const SideSelect = ({ value, onChange, variant }: SideSelectProps) => {
  return (
    <Box sx={{ backgroundColor: variant === "dark" ? "#192022" : "#031116", borderRadius: 1, px: "2px" }}>
      <StyledTabs value={value} onChange={(e, activeTab) => onChange(activeTab)} variant="fullWidth">
        <StyledTab label="Buy" value={OrderSide.BUY} disableRipple />
        <StyledTab label="Sell" value={OrderSide.SELL} disableRipple />
      </StyledTabs>
    </Box>
  );
};

const StyledTabs = styled(Tabs)(({ value }) => ({
  height: 32,
  minHeight: 32,
  "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
    color: value === OrderSide.BUY ? "#179B83" : "#C3462B",
  },
  "& .MuiTabs-indicator": {
    boxSizing: "border-box",
    display: "block",
    height: 28,
    border: "1px solid",
    borderColor: value === OrderSide.BUY ? "#179B83" : "#C3462B",
    background: value === OrderSide.BUY ? "#011E1C" : "#100601",
    borderRadius: 4,
    marginBottom: 2,
    zIndex: 0,
  },
  "& .MuiTabs-flexContainer": {
    position: "relative",
    zIndex: 1,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  padding: 0,
  minHeight: 28,
  marginTop: 2,
  color: "#4A5053",
  "&.Mui-selected": {
    color: theme.palette.text.primary,
  },
}));
