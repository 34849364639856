import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { Typography, TypographyProps, styled } from "@mui/material";
import { styledOptions } from "@src/utils/styled-options";

export const FormLink = ({
  children,
  containerProps,
  dim,
  ...props
}: LinkProps & { containerProps?: TypographyProps; dim?: boolean }) => {
  return (
    <Root variant="body2" {...containerProps} dim={dim}>
      <Link {...props}>{children}</Link>
    </Root>
  );
};

const Root = styled(
  Typography,
  styledOptions
)<{ dim?: boolean }>(({ theme, dim }) => ({
  color: `${dim ? theme.palette.text.disabled : theme.palette.primary.main}`,
  "& a": {
    color: `${dim ? theme.palette.text.disabled : theme.palette.primary.main}`,
  },
}));
