import React from "react";
import { IconButton, ListItemButton, ListItemText, Stack } from "@mui/material";
import { Close } from "@mui/icons-material";
import { WatchlistSymbolItem } from "../watchlist/watchlist-symbol-item";
import { SymbolSearchIcon } from "./symbol-search-icon";
import { ToggleFavouritesIconButton } from "./toggle-favourites-icon-button";
import { FavoriteSymbol, OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { getOrderSymbolLabel } from "@src/utils/get-order-symbol-label";

interface SymbolListItemProps {
  symbol: OrderSymbol;
  onSelect: (symbol: OrderSymbol) => void;
  onCloseClick?: (symbol: OrderSymbol) => void;
  primary?: React.ReactNode;
  before?: React.ReactNode;
  after?: React.ReactNode;
  tickerInfo?: boolean;
}

export const SymbolListItem = ({ symbol, onSelect, onCloseClick, before, after, tickerInfo }: SymbolListItemProps) => {
  return (
    <ListItemButton
      dense
      onClick={() => onSelect(symbol)}
      sx={{ flexGrow: "initial", padding: 0, position: "relative", cursor: "pointer" }}
    >
      <Stack
        gap={1}
        alignItems="center"
        direction="row"
        py={0.5}
        px={1.5}
        width="100%"
        minHeight={40}
        flexWrap="nowrap"
      >
        {before}
        <ToggleFavouritesIconButton symbol={symbol} />
        <SymbolSearchIcon coin={symbol.coin1 || ""} size={20} />
        <ListItemText
          title={getOrderSymbolLabel(symbol)}
          primary={getOrderSymbolLabel(symbol)}
          primaryTypographyProps={{ lineHeight: 1, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
        />
        {onCloseClick && (
          <Stack flex={1} alignItems="flex-end">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onCloseClick(symbol);
              }}
            >
              <Close fontSize="small" sx={{ color: "text.secondary" }} />
            </IconButton>
          </Stack>
        )}
        {tickerInfo && (
          <Stack direction="row">
            <WatchlistSymbolItem symbol={symbol as FavoriteSymbol} showPrice />
            <WatchlistSymbolItem
              symbol={symbol as FavoriteSymbol}
              showChange24h
              minWidth={70}
              display="flex"
              justifyContent="flex-end"
            />
          </Stack>
        )}
        {after}
      </Stack>
    </ListItemButton>
  );
};
