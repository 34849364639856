import React from "react";
import { Paper, Stack } from "@mui/material";
import AnbotoLoader from "@src/components/anboto-loader/anboto-loader";
import AnbotoFetchingError from "@src/components/anboto-fetching-error/anboto-fetching-error";
import TradesMetrics from "@src/pages/metrics/components/trades-metrics/trades-metrics";
import { useAppSelector } from "@src/store/hooks";
import { getLast36monthDates, trimMetricsTradesGroupedData } from "@src/pages/metrics/helpers";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { DEFI_SERVER_EXCHANGE_NAMES } from "@src/pages/defi/constants";

const TradingStats = () => {
  const filters = useAppSelector((state) => state.metrics.tradesMetricsFilters);

  const { start_time, end_time } = getLast36monthDates();

  const getMetricsTradeQuery = anbotoApi.useGetMetricsTradeQuery({
    start_time,
    end_time,
  });

  const is_defi = filters.tradeTypes.length === 2 ? undefined : filters.tradeTypes[0] === "DEFI";
  const exchange = filters.exchanges
    .map(
      (name) =>
        Object.keys(CEFI_EXCHANGE_NAME).find((key) => CEFI_EXCHANGE_NAME[key] === name) ||
        Object.keys(DEFI_SERVER_EXCHANGE_NAMES).find((key) => DEFI_SERVER_EXCHANGE_NAMES[key] === name)
    )
    .join();

  const getMetricsTradesGroupedQuery = anbotoApi.useGetMetricsTradesGroupedQuery({
    n_groups: 36,
    group_by: filters.groupBy,
    end_time,
    exchange,
    strategy: filters.strategies.join(),
    asset_class: filters.assetClasses.join(),
    ...(is_defi ? { is_defi } : {}),
  });

  const trimmedMetricsTradesGroupedData = trimMetricsTradesGroupedData({
    ...(getMetricsTradesGroupedQuery?.data?.data || {}),
  });
  return (
    <Paper sx={{ maxWidth: "99.9%", flexGrow: 1, padding: 3, display: "flex" }}>
      <Stack maxWidth="99%" flexGrow={1} flexDirection="column" gap={1}>
        {getMetricsTradeQuery?.isLoading || getMetricsTradesGroupedQuery?.isLoading ? (
          <AnbotoLoader />
        ) : getMetricsTradeQuery?.isError || getMetricsTradesGroupedQuery?.isError ? (
          <AnbotoFetchingError />
        ) : getMetricsTradeQuery?.data && getMetricsTradesGroupedQuery?.data ? (
          <TradesMetrics
            metrics={getMetricsTradeQuery?.data?.data}
            metricsWithRange={trimmedMetricsTradesGroupedData}
          />
        ) : null}
      </Stack>
    </Paper>
  );
};

export default TradingStats;
