import React from "react";
import { Stack } from "@mui/material";
import { getRankingNumberColor } from "@src/pages/competitions/helpers";

type RankingCircleProps = {
  ranking: number;
};

export const RankingCircle = ({ ranking }: RankingCircleProps) => {
  return (
    <>
      {ranking === 1 || ranking === 2 || ranking === 3 ? (
        <Stack sx={{ width: 9, height: 9, borderRadius: 5, background: getRankingNumberColor(ranking) }}></Stack>
      ) : null}
    </>
  );
};
