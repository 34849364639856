import * as yup from "yup";
import { NumberSchema } from "yup";

const formValidator = yup;

formValidator.setLocale({
  mixed: {
    // required: 'This field is required'
  },
  number: {
    // min: 'Deve ser maior que ${min}',
  },
});

export const numberTransform = (value) => (isNaN(value) ? undefined : value);

declare module "yup" {
  interface NumberSchema {
    emptyable(): NumberSchema;

    noLabel(): NumberSchema;
  }
}
formValidator.addMethod(formValidator.number, "emptyable", function () {
  return this.nullable().transform(numberTransform) as NumberSchema;
});
formValidator.addMethod(formValidator.number, "noLabel", function () {
  return this.label(" ");
});

export default formValidator;
