import React from "react";
import { Box, Paper, Stack, styled, Tooltip, Typography, useTheme } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { InfoOutlined } from "@mui/icons-material";
import { AnbotoIconButton, StyledTabList, StyledTabListTab } from "@src/components";
import { useTabs } from "@src/hooks/useTabs";
import { usePoolInfo } from "@src/pages/defi/hooks/use-pool-info";
import PoolInfoContent from "@src/pages/defi/token-info/components/pool-info-content";
import AnbotoLoader from "@src/components/anboto-loader/anboto-loader";
import TransactionsTable from "@src/pages/defi/token-info/components/transactions-table/transactions-table";
import TopHolders from "@src/pages/defi/token-info/components/top-holders/top-holders";
import PoolSearch from "@src/pages/defi/token-info/components/pool-search/pool-search";
import { setIsTokenInfoChartPool } from "@src/store/slices/decentralizedExchangePageSlice";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import TokenPriceInfo from "@src/pages/defi/token-info/components/token-price-info";

const TAB_IDS = {
  TokenInfo: "1",
  Activities: "2",
  TopHolders: "3",
};

const PoolInfo = () => {
  const dispatch = useAppDispatch();
  const tabs = useTabs(TAB_IDS.TokenInfo);
  const isTokenInfoChartPool = useAppSelector((state) => state.decentralizedExchangePage.isTokenInfoChartPool);

  const handleTabChange = (e, x) => {
    tabs.setValue(x);
  };

  const { tokenInfoPool, isLoading, pairInfoFromToken, pairInfoToToken } = usePoolInfo();
  const poolAddress = tokenInfoPool?.attributes?.address;
  const baseTokenPrice = tokenInfoPool?.attributes?.base_token_price_usd || 0;

  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Paper
      className="pool-info"
      sx={{ height: "100%", position: "relative", width: isXl ? 458 : 348, padding: 0.5, px: 2 }}
    >
      {isLoading ? (
        <Stack direction="column" justifyContent="center" minHeight={300}>
          <AnbotoLoader />
        </Stack>
      ) : (
        <Stack pt={1}>
          <Stack direction="row" gap={0.5} position="relative" width="100%">
            <PoolSearch />
            <Tooltip title="Show chart for token info pair">
              <Box>
                <AnbotoIconButton
                  flat
                  onClick={() => dispatch(setIsTokenInfoChartPool(true))}
                  size="small"
                  sx={{
                    width: 30,
                    height: 30,
                    border: isTokenInfoChartPool ? "1px solid #2C72E3" : "1px solid #031116",
                  }}
                >
                  <ShowChartIcon sx={{ width: 16 }} />
                </AnbotoIconButton>
              </Box>
            </Tooltip>
          </Stack>
          <TokenPriceInfo
            pool={tokenInfoPool}
            isFetching={isLoading}
            fromToken={pairInfoFromToken}
            toToken={pairInfoToToken}
          />

          <TabContext value={tabs.value}>
            <StyledTabList
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{ mb: 0.5, marginRight: 0 }}
            >
              <StyledTabListTab label="Token info" value={TAB_IDS.TokenInfo} />
              <StyledTabListTab label="Activities" value={TAB_IDS.Activities} />
              <StyledTabListTab
                icon={
                  <Tooltip title={`Top holders (${pairInfoFromToken?.symbol}) in linquidity pool`}>
                    <InfoOutlined
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                        width: 14,
                        height: 14,
                      }}
                    />
                  </Tooltip>
                }
                label="Top holders"
                value={TAB_IDS.TopHolders}
                iconPosition="end"
              />
            </StyledTabList>

            <StyledTabPanel value={TAB_IDS.TokenInfo} sx={{ px: 0.5 }}>
              <PoolInfoContent
                pool={tokenInfoPool}
                isFetching={isLoading}
                fromToken={pairInfoFromToken}
                toToken={pairInfoToToken}
              />
            </StyledTabPanel>
            <StyledTabPanel value={TAB_IDS.Activities} sx={{ px: 0 }}>
              {poolAddress ? (
                <TransactionsTable
                  poolAddress={poolAddress}
                  poolFromTokenSymbol={
                    tokenInfoPool?.isNeedToRevert ? pairInfoToToken?.symbol : pairInfoFromToken?.symbol
                  }
                />
              ) : (
                <Typography>Unable to fetch transactions information</Typography>
              )}
            </StyledTabPanel>
            <StyledTabPanel value={TAB_IDS.TopHolders} sx={{ px: 0.5 }}>
              <TopHolders
                baseTokenPrice={+baseTokenPrice}
                tokenInfoPool={tokenInfoPool}
                pairInfoToToken={pairInfoToToken}
                pairInfoFromToken={pairInfoFromToken}
              />
            </StyledTabPanel>
          </TabContext>
        </Stack>
      )}
    </Paper>
  );
};

export default PoolInfo;

export const StyledTabPanel = styled(TabPanel)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
  "&:not([hidden])": {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
}));
