import React, { useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { filterDefiDataByBalance, PieChartsDefi } from "@src/pages/portfolio";
import { useDefiTokenTable } from "@src/pages/portfolio/hooks/use-defi-token-table";
import { AllocationDefiFunds } from "@src/pages/portfolio/components/allocation-funds-innder/allocation-defi-funds";
import { RootState } from "@src/store/types";
import { useRestoreWalletProvider } from "@src/hooks";

export const AllocationsDefiFunds = () => {
  useRestoreWalletProvider();
  const [hideSmallBalance, onHideSmallBalance] = useState<boolean>(true);
  const { data, isFetching, isError, refetch } = useDefiTokenTable();
  const defiTotalBalance = useSelector((state: RootState) => state.portfolio.defiTotalBalance);

  const dataFilteredByBalance = filterDefiDataByBalance(hideSmallBalance, defiTotalBalance, data);

  return (
    <Box>
      <PieChartsDefi isFetching={isFetching} />
      <AllocationDefiFunds
        data={dataFilteredByBalance}
        hideSmallBalance={hideSmallBalance}
        isError={isError}
        isFetching={isFetching}
        onHideSmallBalance={onHideSmallBalance}
        refetch={refetch}
      />
    </Box>
  );
};
