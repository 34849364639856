export const STABLE_COINS_LIST = {
  USDT: true,
  USDC: true,
  BUSD: true,
  DAI: true,
  FRAX: true,
  TUSD: true,
  USDP: true,
  FDUSD: true,
  USDD: true,
  GUSD: true,
  FEI: true,
  USDJ: true,
  USTC: true,
  TRIBE: true,
  vUSDC: true,
  USDX: true,
  vBUSD: true,
  VAI: true,
  SUSD: true,
  OUSD: true,
  CUSD: true,
  XSGD: true,
  QC: true,
  SBD: true,
  EUROC: true,
  USDK: true,
  HUSD: true,
  RSV: true,
  KRT: true,
  CEUR: true,
  BIDR: true,
  IDRT: true,
  DGD: true,
  vDAI: true,
  BITCNY: true,
  EBASE: true,
  XCHF: true,
  DGX: true,
  BITUSD: true,
  BGBP: true,
  ESD: true,
  HGT: true,
  BAC: true,
  BITEUR: true,
  ITL: true,
  USNBT: true,
  BITGOLD: true,
  XEUR: true,
  CUSDT: true,
  MUSD: true,
  MTR: true,
  MIMATIC: true,
  fUSDT: true,
  FLUSD: true,
  SEUR: true,
  IRON: true,
  DUSD: true,
  EURT: true,
  ARTH: true,
  USDAP: true,
  DPT: true,
  BRCP: true,
  BEAN: true,
  USDQ: true,
  COFFIN: true,
  COUSD: true,
  USX: true,
  TRYB: true,
  KBC: true,
  XUSD: true,
  USDB: true,
  AGEUR: true,
  XIDR: true,
  BKRW: true,
  XSTUSD: true,
  USDEX: true,
  PAR: true,
  MIM: true,
  "1GOLD": true,
  CADC: true,
  JPYC: true,
  BVND: true,
  BSD: true,
  FLOAT: true,
  USDFL: true,
  DSD: true,
  USDL: true,
  ONC: true,
  MDO: true,
  ALUSD: true,
  xDAI: true,
  ZUSD: true,
  H2O: true,
  nUSD: true,
  TOR: true,
  WANUSDT: true,
  MONEY: true,
  FUSD: true,
  DOLA: true,
  USDS: true,
  mCEUR: true,
  USDH: true,
  YUSD: true,
  mCUSD: true,
  USDZ: true,
  MXNT: true,
  ONEICHI: true,
  USDI: true,
  AUSD: true,
  USDs: true,
  USDR: true,
  EUROS: true,
  DJED: true,
};
