import React from "react";
import DefiTradingViewChart from "@src/pages/defi/defi-chart/defi-trading-view-chart";
import { usePoolInfo } from "@src/pages/defi/hooks/use-pool-info";
import { useAppSelector } from "@src/store/hooks";

export const MINIMAL_POOL_USD_RESERVE = 10000;

export const DefiChart = () => {
  const {
    formPool,
    tokenInfoPool,
    formFromToken,
    formToToken,
    chainId,
    isLoading,
    pairInfoFromToken,
    pairInfoToToken,
  } = usePoolInfo();

  const isTokenInfoChartPool = useAppSelector((state) => state.decentralizedExchangePage.isTokenInfoChartPool);
  const currentChartPool = isTokenInfoChartPool ? tokenInfoPool : formPool;

  let isReverseChart = false;
  const tokensPairFromPool = currentChartPool?.attributes?.name?.split(" ");
  if (tokensPairFromPool && tokensPairFromPool?.length >= 2) {
    const poolFromToken: string = tokensPairFromPool[0];
    const poolToToken: string = tokensPairFromPool[2];
    if (poolToToken === formFromToken?.symbol && poolFromToken === formToToken?.symbol) {
      isReverseChart = true;
    }
  } else {
    isReverseChart = false;
  }

  const isLineChart =
    !isLoading &&
    (!currentChartPool || parseFloat(currentChartPool?.attributes?.reserve_in_usd || "0") < MINIMAL_POOL_USD_RESERVE);

  if (!formFromToken || !formToToken) {
    return null;
  }

  const fromToken = isTokenInfoChartPool ? pairInfoFromToken || formFromToken : formFromToken;
  const toToken = isTokenInfoChartPool ? pairInfoToToken || formToToken : formToToken;

  return (
    <DefiTradingViewChart
      isReverseChart={isReverseChart}
      chainId={chainId}
      toToken={toToken}
      fromToken={fromToken}
      isLineChart={isLineChart}
    />
  );
};
