import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import AnbotoInfoIcon from "@src/components/anboto-info-icon/anboto-info-icon";

type DashboardDataCardHeaderProps = {
  header: string;
  title?: string;
  fontSize?: number;
  fontWeight?: number;
};

const DashboardDataCardHeader = ({ header, title, fontSize, fontWeight }: DashboardDataCardHeaderProps) => {
  const theme = useTheme();
  return (
    <Stack mb={1} direction="row" gap={0.5} justifyContent="flex-start" alignItems="center">
      <Typography
        fontSize={fontSize}
        m={0}
        color={theme.palette.text.primary}
        variant="h6"
        fontWeight={fontWeight ? fontWeight : "bold"}
      >
        {header}
      </Typography>
      {title && <AnbotoInfoIcon iconSize={20} title={title} />}
    </Stack>
  );
};

export default DashboardDataCardHeader;
