import { isAnyOf } from "@reduxjs/toolkit";
import _cloneDeep from "lodash/cloneDeep";
import _pick from "lodash/pick";
import { WidgetsGridView } from "../types";
import {
  WidgetsViewState,
  addWidget,
  removeWidget,
  updateLayout,
  changeGroup,
  updateGroupData,
  CefiGroup,
  setPredefinedLayout,
} from "./widgets.slice";
import { RootState } from "@src/store/types";

export const startWidgetsListener = (
  listenerMiddleware,
  cb: (view: WidgetsGridView, layout: WidgetsViewState, dispatch) => void
) =>
  listenerMiddleware.startListening({
    matcher: isAnyOf(updateLayout, removeWidget, addWidget, changeGroup, updateGroupData, setPredefinedLayout),
    effect: (action, { getState, dispatch }) => {
      const state = getState() as RootState;
      const { view, views } = state.widgets;

      if (updateGroupData.match(action)) {
        if (view === WidgetsGridView.CEFI) {
          const { symbol, priceGrouping } = (action.payload.data as Partial<CefiGroup>) || {};

          if (![symbol, priceGrouping].filter(Boolean).length) return false;
        }
      }

      cb && cb(view, prepareViewData(view, views[view]), dispatch);
    },
  });

const prepareViewData = (view: WidgetsGridView, viewState: WidgetsViewState) => {
  const data = _cloneDeep(viewState);

  if (view === WidgetsGridView.CEFI) {
    Object.keys(data.groups).forEach((group) => {
      data.groups[group] = _pick(data.groups[group], ["symbol", "priceGrouping"]);
    });
  }

  return data;
};
