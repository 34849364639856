import { styled } from "@mui/material";
import { TabList } from "@mui/lab";

export const StyledTabList = styled(TabList)(({ theme }) => ({
  background: "transparent!important",
  borderRadius: theme.shape.borderRadius,
  minHeight: 38,
  marginRight: 25,
  position: "relative",
  "& .MuiTabScrollButton-root": {
    position: "absolute",
    zIndex: 1,
    background: "#121a1c",
    height: "100%",
    opacity: 1,
    width: 30,
    top: 0,
  },
  "& .MuiTabScrollButton-root:last-child": {
    right: 0,
  },
  "& .MuiTabScrollButton-root.Mui-disabled": {
    opacity: 1,
    display: "none",
  },
  "& .MuiTabScrollButton-root .MuiSvgIcon-root": {
    fill: theme.palette.text.disabled,
  },
  "& .MuiTabs-indicator": {
    background: "none !important",
  },
  "& .MuiTabs-indicator:before": {
    content: "''",
    background: theme.custom.colors.primary,
    height: 2,
    display: "block",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));
