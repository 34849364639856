import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { TokenAvatar } from "@src/components/token-avatar";
import { CHAIN_NAME } from "@src/pages/defi/constants";
import { GECKOTERMINAL_CHAIN_IDS } from "@src/store/apis/geckoterminal-api/constants";
import { CurrencyValue } from "@src/pages/portfolio";
import PriceChange from "@src/components/price-change/price-change";
import { roundAmount } from "@src/pages/portfolio/utils/helpers";
import { SearchPool } from "@src/store/apis/geckoterminal-api/types";
import { Token } from "@src/pages/defi/types";
import AnbotoLoader from "@src/components/anboto-loader/anboto-loader";
import { getChainIdFromGeckoTerminalPoolId } from "@src/pages/defi/token-info/helpers";

type TokenPriceInfoProps = {
  pool: SearchPool;
  isFetching: boolean;
  fromToken: Token | undefined;
  toToken: Token | undefined;
};

const TokenPriceInfo = ({ pool, fromToken, toToken, isFetching }: TokenPriceInfoProps) => {
  const currentPoolChainId = getChainIdFromGeckoTerminalPoolId(pool?.id);

  return (
    <Stack>
      {isFetching ? (
        <AnbotoLoader />
      ) : pool ? (
        <Stack py={1} direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Stack zIndex={2}>
                {currentPoolChainId && (
                  <TokenAvatar
                    address={fromToken?.address}
                    chainId={currentPoolChainId}
                    sx={{
                      height: 16,
                      width: 16,
                      borderRadius: "50%",
                      display: "block",
                      fontSize: 12,
                      lineHeight: "16px",
                      textAlign: "center",
                    }}
                  />
                )}
              </Stack>
              (
              <Stack zIndex={1} left={-8} position="relative">
                {currentPoolChainId && (
                  <TokenAvatar
                    address={toToken?.address}
                    chainId={currentPoolChainId}
                    sx={{
                      height: 16,
                      width: 16,
                      borderRadius: "50%",
                      display: "block",
                      fontSize: 12,
                      lineHeight: "16px",
                      textAlign: "center",
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Typography fontSize={14} fontWeight={600} color="white">
              {pool?.attributes?.name} ({currentPoolChainId ? CHAIN_NAME[currentPoolChainId] : ""})
            </Typography>
            {currentPoolChainId && (
              <IconButton
                target="_blank"
                href={
                  "https://www.geckoterminal.com/" +
                  GECKOTERMINAL_CHAIN_IDS[currentPoolChainId] +
                  "/pools/" +
                  pool?.attributes?.address
                }
              >
                <OpenInNewIcon sx={{ color: "#B0BCC2", fontSize: 16 }} />
              </IconButton>
            )}
          </Stack>
          <Stack textAlign="right" minWidth={120} width="fit-content">
            <Typography fontSize={12} fontWeight={400} color="#636B6F">
              Live price (USDT)
            </Typography>
            <Stack direction="row" alignItems="center" width="fit-content">
              <Typography fontSize={14} fontWeight={600} color="white">
                <CurrencyValue
                  value={Number(
                    pool?.isNeedToRevert
                      ? pool?.attributes?.quote_token_price_usd
                      : pool?.attributes?.base_token_price_usd
                  )}
                />
              </Typography>
              <PriceChange priceChange={roundAmount(pool?.attributes?.price_change_percentage?.h24)} />
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Typography>Unable to fetch pool information</Typography>
      )}
    </Stack>
  );
};

export default TokenPriceInfo;
