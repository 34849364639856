import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { PredefinedWidgetsLayout } from "../../types";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

export const LayoutDialog = ({
  visible,
  onClose,
  onSubmit,
  selected,
  loading,
}: {
  selected?: PredefinedWidgetsLayout | null;
  visible: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
  loading?: boolean;
}) => {
  const [name, setName] = useState(selected?.name || "");

  useEffect(() => {
    setName(selected?.name || "");
  }, [visible]);

  return (
    <Dialog open={visible}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold">Save</Typography>
          <IconButton onClick={onClose} size="small">
            <Close sx={{ fontSize: 16 }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack minWidth={250} mx={2} mt={2}>
        <AnbotoTextField value={name} onChange={(e) => setName(e.target.value)} placeholder="Layout name" />
      </Stack>

      <DialogActions>
        <AnbotoButton size="small" onClick={onClose}>
          Cancel
        </AnbotoButton>
        <AnbotoButton
          loading={loading}
          size="small"
          variant="contained"
          onClick={() => onSubmit(name)}
          disabled={!name}
        >
          Save
        </AnbotoButton>
      </DialogActions>
    </Dialog>
  );
};
