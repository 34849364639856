import { parseUnits } from "@ethersproject/units";
import { readContract, writeContract, WriteContractReturnType } from "@wagmi/core";
import { getContract } from "viem";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { queryFnFactory } from "@src/store/apis/utils";
import ERC20ABI from "@src/assets/ABIs/ERC20.json";
import { EVMBasedAddress } from "@src/pages/defi/types/common";
import { wagmiConfig } from "@src/pages/defi/wagmi-config";

export interface TransferCryptoProps {
  accountTo: string;
  tokenAddress: string;
  tokenAmount: string;
  decimals: number;
}

export interface EstimateTransferProps {
  accountTo: string;
  tokenAddress: string;
  tokenAmount: string;
  decimals: number;
}

export interface DecimalsProps {
  tokenAddress: string;
}

// provider.getGasPrice()
const portfolioBlockchainApi = blockchainApi.injectEndpoints({
  endpoints: (builder) => ({
    decimals: builder.query<number, DecimalsProps>({
      queryFn: queryFnFactory<DecimalsProps, number>(async ({ tokenAddress }) => {
        return readContract(wagmiConfig, {
          address: tokenAddress as EVMBasedAddress,
          abi: ERC20ABI,
          functionName: "decimals",
        }).catch((error) => {
          console.log("decimals error", error);

          return 0;
        }) as Promise<number>;
      }),
    }),
    estimateTransfer: builder.query<any, EstimateTransferProps>({
      queryFn: queryFnFactory<EstimateTransferProps, any>(
        async ({ accountTo, tokenAddress, tokenAmount, decimals }) => {
          const contract = getContract({
            address: tokenAddress as EVMBasedAddress,
            abi: ERC20ABI,
            client: {
              wallet: wagmiConfig.getClient(),
            },
          });

          const tokenAmountUint = parseUnits(tokenAmount, decimals);

          const gas = await contract.estimateGas
            .transfer([accountTo, tokenAmountUint], {
              account: accountTo as EVMBasedAddress,
            })
            .catch((error) => {
              console.log("estimate transfer error", error);

              return 0;
            });

          return gas.toString();
        }
      ),
    }),
    transferCrypto: builder.mutation<WriteContractReturnType | null, TransferCryptoProps>({
      queryFn: queryFnFactory<TransferCryptoProps, WriteContractReturnType | null>(
        async ({ accountTo, tokenAddress, tokenAmount, decimals }) => {
          const tokenAmountUint = parseUnits(tokenAmount, decimals);

          return writeContract(wagmiConfig, {
            address: tokenAddress as EVMBasedAddress,
            abi: ERC20ABI,
            functionName: "transfer",
            args: [accountTo, tokenAmountUint],
          }).catch((error) => {
            console.log("transfer error", error);
            return null;
          });
        }
      ),
    }),
  }),
});

export const { useLazyEstimateTransferQuery, useTransferCryptoMutation, useDecimalsQuery, useEstimateTransferQuery } =
  portfolioBlockchainApi;
