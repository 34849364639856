import { useMemo } from "react";
import { SUPPORTED_PORTFOLIO_CHAINS } from "@src/pages/portfolio/utils/supported-portfolio-chains";

export const useChainIcons = () => {
  const chainIcons = useMemo(
    () => SUPPORTED_PORTFOLIO_CHAINS.reduce((obj, item) => Object.assign(obj, { [item.name]: item.logo_url }), {}),
    [SUPPORTED_PORTFOLIO_CHAINS]
  );

  return chainIcons;
};
