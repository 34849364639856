import React, { useCallback, useEffect, useRef } from "react";
import { DevTool } from "@hookform/devtools";
import { FormProvider } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useAccount, useSwitchChain } from "wagmi";
import { Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import { ClipSizeType, OrderExecutionStrategy, OrderSide, ParentDefiOrder } from "@src/store/apis/anbotoApi/types";
import { saveToken } from "@src/store/slices/tokensSlice";
import { blockchainApi } from "@src/store/apis/blockchainApi";
import { constructTokenAddress, isTokenSaveNeeded } from "@src/pages/defi/utils";
import { OrdersTableContainer } from "@src/pages/defi/orders-table-container";
import { fromDefiDTOtoOrder } from "@src/pages/defi/order-dto-mappers";
import { useDefiOrderForm } from "@src/pages/defi/hooks/use-defi-order-form";
import { ChainId, EVMBasedAddress } from "@src/pages/defi/types";
import { CHAIN_IDS, CHAIN_ID_DEFAULT_TOKEN_PAIRS, DEFI_FORM_RESET_FROM_HISTORY } from "@src/pages/defi/constants";
import { OrderFormCardNoGas } from "@src/pages/defi/order-form-card-no-gas";
import { LAYOUT_GAP } from "@src/constants/common";
import { Intro } from "@src/components/intro";
import PoolInfo from "@src/pages/defi/token-info/pool-info";
import { DefiChart } from "@src/pages/defi/defi-chart";

export const DefiOrderPage = () => {
  const orderForm = useDefiOrderForm();
  const dispatch = useAppDispatch();
  const snackbar = useSnackbar();
  const { switchChainAsync } = useSwitchChain();
  const tokensMap = useAppSelector((state) => state.tokens.tokensMap);

  const providerAccountInfo = useAccount();
  const { address, chain } = providerAccountInfo;

  const formAccount = orderForm.watch("account");
  const chainId = orderForm.watch("chainId");
  // when we restoring order from history, we need to prevent automatic form reset
  const preventAutomaticReset = useRef<boolean>(false);

  const [getTokenDecimals] = blockchainApi.useLazyGetTokenDecimalsQuery();

  const createTokenFromHistory = async (networkId: ChainId, tokenAddress: string, tokenSymbol: string) => {
    const tokenDecimals = await getTokenDecimals({ chainId: networkId, tokenAddress: tokenAddress }).unwrap();

    const token = {
      symbol: tokenSymbol,
      address: tokenAddress,
      chainId: networkId,
      name: tokenAddress,
      decimals: tokenDecimals,
    };

    await dispatch(saveToken(token));
  };

  const handleOrderHistoryClick = useCallback(
    async (order: ParentDefiOrder) => {
      if (!address) {
        snackbar.enqueueSnackbar("Please connect your wallet!");

        return;
      }

      preventAutomaticReset.current = true;

      try {
        const { network_id: networkId } = order;

        if (networkId !== chain?.id) {
          await switchChainAsync({ chainId: networkId });
        }

        const orderSide = order.side.toLowerCase();
        const symbolArr = order.symbol.split("/");
        const fromTokenSymbol = orderSide === OrderSide.BUY ? symbolArr[1] : symbolArr[0];
        const toTokenSymbol = orderSide === OrderSide.BUY ? symbolArr[0] : symbolArr[1];
        const fromTokenAddress = order.token1;
        const toTokenAddress = order.token2;

        if (fromTokenAddress) {
          const fromTokenChainAddress = constructTokenAddress(chainId, fromTokenAddress as EVMBasedAddress);

          if (isTokenSaveNeeded(tokensMap[fromTokenChainAddress])) {
            await createTokenFromHistory(networkId, fromTokenAddress, fromTokenSymbol);
          }
        }

        if (toTokenAddress) {
          const toTokenChainAddress = constructTokenAddress(chainId, toTokenAddress as EVMBasedAddress);

          if (isTokenSaveNeeded(tokensMap[toTokenChainAddress])) {
            await createTokenFromHistory(networkId, toTokenAddress, toTokenSymbol);
          }
        }
        const newFormValues = fromDefiDTOtoOrder(address, order);

        orderForm.reset(newFormValues);
      } catch (e) {
        snackbar.enqueueSnackbar("Could not fetch metadata for tokens");
      } finally {
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent(DEFI_FORM_RESET_FROM_HISTORY));
          preventAutomaticReset.current = false;
        });
      }
    },
    [orderForm, chainId, tokensMap]
  );

  const handleChainIdChange = (newChainId: ChainId) => {
    if (Object.values(CHAIN_IDS).includes(newChainId)) {
      orderForm.setValue("chainId", newChainId);

      const tokenFrom = CHAIN_ID_DEFAULT_TOKEN_PAIRS[newChainId].from;
      const tokenTo = CHAIN_ID_DEFAULT_TOKEN_PAIRS[newChainId].to;

      orderForm.setValue("fromTokenAddress", tokenFrom.address);
      orderForm.setValue("toTokenAddress", tokenTo.address);

      const strategy = orderForm.getValues("strategy");
      if (strategy === OrderExecutionStrategy.TWAP) {
        orderForm.setValue("clipSizeType", ClipSizeType.AUTOMATIC);
      }

      if (strategy === OrderExecutionStrategy.LIMIT) {
        orderForm.setValue("toTokenAmount", "");
        orderForm.setValue("limitPrice", "");
      }
    }
  };

  const handleAccountChange = (account: string | undefined) => {
    orderForm.setValue("account", account);
  };

  // TODO - handle account change from wagmi and remove this hook
  useEffect(() => {
    if (address && address !== formAccount) {
      handleAccountChange(address);
    }
  }, [address, formAccount]);

  // TODO - handle chain id change from wagmi and remove this hook
  useEffect(() => {
    if (chain?.id && chainId !== chain.id && !preventAutomaticReset.current) {
      handleChainIdChange(chain?.id);
    }
  }, [chain?.id, chainId]);

  return (
    <>
      <Intro page="defi" />
      <Stack flex={1} gap={LAYOUT_GAP}>
        <FormProvider {...orderForm}>
          <Stack direction="row" gap={LAYOUT_GAP}>
            <OrderFormCardNoGas />
            <DefiChart />
            <PoolInfo />
          </Stack>
          <OrdersTableContainer onSymbolClick={handleOrderHistoryClick} />
          <DevTool control={orderForm.control} />
        </FormProvider>
      </Stack>
    </>
  );
};
