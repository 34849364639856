export enum ExchangeId {
  BINANCE = "binance",
  BYBIT = "bybit",
  COINBASE = "coinbasepro",
  GATEIO = "gateio",
  KRAKEN = "kraken",
  KUCOIN = "kucoin",
  OKX = "okx",
  ASCENDEX = "ascendex",
  HUOBI = "huobi",
  BITMEX = "bitmex",
  WOOX = "woo",
  BITGET = "bitget",
  MEXC = "mexc",
}

export enum MarketType {
  SPOT = "spot",
  FUTURE = "future",
}
