import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { PortfolioType } from "@src/store/slices/portfolioSlice";

export interface PortfolioTaskStatusResult {
  success: boolean;
  ready: boolean;
}

export interface PortfolioTaskStatusParams {
  task_id?: string;
}

export interface UpdatePortfolioDataResult {
  success: boolean;
  data: string;
}

export interface UpdatePortfolioDataParams {
  exchange_type: PortfolioType;
  exchange_id?: string;
}

export type UpdatePortfolioDataSuccessResponse = {
  data: UpdatePortfolioDataResult;
};

export type UpdatePortfolioDataErrorResponse = {
  error: FetchBaseQueryError | SerializedError;
};

const portfolioDataApi = anbotoApi.injectEndpoints({
  endpoints: (builder) => ({
    portfolioTaskStatus: builder.query<PortfolioTaskStatusResult, PortfolioTaskStatusParams>({
      query: (params) => ({
        url: `/portfolio/portfolio_task_status/`,
        params,
      }),
      providesTags: ["PORTFOLIO_TASK_STATUS"],
    }),
    updatePortfolioData: builder.mutation<UpdatePortfolioDataResult, UpdatePortfolioDataParams>({
      invalidatesTags: ["PORTFOLIO_TASK_STATUS"],
      query: (body) => ({
        method: "POST",
        url: `/portfolio/update_data/`,
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const portfolioData = portfolioDataApi.endpoints.portfolioTaskStatus;

export const { useUpdatePortfolioDataMutation, useLazyPortfolioTaskStatusQuery } = portfolioDataApi;
