import React from "react";
import { Stack, Box, Card, styled, CircularProgress } from "@mui/material";
import { SettingsHeader, SettingsHeaderProps } from "../settings-header";
import { ErrorState } from "@src/components/ErrorState";

type SettingsCardProps = {
  headerProps: SettingsHeaderProps;
  dialog?: React.ReactNode;
  footer?: React.ReactNode;
  contentHeader?: React.ReactNode;
  loading?: boolean;
  error?: boolean;
  refetch?: () => void;
};

export const SettingsCard = ({
  headerProps,
  contentHeader,
  children,
  dialog,
  footer,
  loading,
  error,
  refetch,
}: React.PropsWithChildren<SettingsCardProps>) => {
  return (
    <Root>
      <Stack sx={{ ml: "auto", mr: "auto", maxWidth: 950 }} gap={2}>
        <SettingsHeader {...headerProps} />
        <Content variant="outlined" error={error}>
          <>
            {contentHeader && <Stack> {contentHeader}</Stack>}
            {loading ? (
              <Stack alignItems="center" justifyContent="center" sx={{ flex: 1, mb: 3 }}>
                <CircularProgress />
              </Stack>
            ) : error ? (
              <ErrorState onTryAgainClick={refetch} />
            ) : (
              <>
                <Stack sx={{ flex: 1 }}>{children}</Stack>
                {footer && <Footer justifyContent="center">{footer}</Footer>}
              </>
            )}
          </>
        </Content>
      </Stack>

      {dialog}
    </Root>
  );
};

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  position: "relative",
  flex: 1,
  height: "100%",
}));

const Footer = styled(Stack)(({ theme }) => ({
  borderTop: `1px solid rgba(255, 255, 255, 0.12)`,
  marginLeft: `-${theme.spacing(3)}`,
  marginRight: `-${theme.spacing(3)}`,
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  minHeight: 70,
}));

export const Content = styled(Card)<{ error?: boolean }>(({ theme, error }) => ({
  background: theme.palette.background.default,
  justifyContent: error ? "center" : undefined,
  paddingTop: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingBottom: error ? theme.spacing(3) : undefined,
  minHeight: 544,
  display: "flex",
  flexDirection: "column",
  flex: 1,
}));
