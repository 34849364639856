import {
  OrderTradingDurationUnit,
  OrderExecutionStrategy,
  OrderRole,
  OrderSide,
} from "@src/store/apis/anbotoApi/types";

export const getQuantityPerSlice = (numberOfSlices: number, quantity: number) => quantity / numberOfSlices;

export const TRADING_DURATION_UNIT_SECONDS: Record<OrderTradingDurationUnit, number> = {
  [OrderTradingDurationUnit.DAYS]: 24 * 60 * 60,
  [OrderTradingDurationUnit.HOURS]: 60 * 60,
  [OrderTradingDurationUnit.MINUTES]: 60,
};

export const getFrequencyInSeconds = (
  numberOfSlices: number,
  tradingDuration: number,
  tradingDurationUnit: OrderTradingDurationUnit
) => {
  const tradingDurationUnitSeconds = TRADING_DURATION_UNIT_SECONDS[tradingDurationUnit];
  const tradingDurationSeconds = tradingDuration * tradingDurationUnitSeconds;

  return numberOfSlices > 1 ? tradingDurationSeconds / (numberOfSlices - 1) : tradingDurationSeconds;
};

export const getFrequencyInMinutes = (
  numberOfSlices: string,
  tradingDuration: string,
  tradingDurationUnit: OrderTradingDurationUnit
) => {
  const frequencyInSeconds =
    numberOfSlices && tradingDuration
      ? getFrequencyInSeconds(parseInt(numberOfSlices), parseFloat(tradingDuration), tradingDurationUnit)
      : "";

  return frequencyInSeconds ? Number((frequencyInSeconds / 60).toFixed(2)) : "";
};

export const getTradingDurationUnit = (frequencyInSeconds: number, numberOfSlices: number) => {
  const frequency = frequencyInSeconds * (numberOfSlices - 1);

  if (frequency % TRADING_DURATION_UNIT_SECONDS[OrderTradingDurationUnit.DAYS] === 0)
    return OrderTradingDurationUnit.DAYS;
  if (frequency % TRADING_DURATION_UNIT_SECONDS[OrderTradingDurationUnit.HOURS] === 0)
    return OrderTradingDurationUnit.HOURS;
  return OrderTradingDurationUnit.MINUTES;
};

export const getTradingDurationFromMs = (ms: number) => {
  let duration: number;
  let durationUnits: OrderTradingDurationUnit;

  const days = Math.round(ms / (1000 * TRADING_DURATION_UNIT_SECONDS[OrderTradingDurationUnit.DAYS]));
  const hours = Math.round(ms / (1000 * TRADING_DURATION_UNIT_SECONDS[OrderTradingDurationUnit.HOURS]));
  const minutes = Math.round(ms / (1000 * TRADING_DURATION_UNIT_SECONDS[OrderTradingDurationUnit.MINUTES]));

  if (days > 0) {
    durationUnits = OrderTradingDurationUnit.DAYS;
    duration = days;
  } else if (hours > 0) {
    durationUnits = OrderTradingDurationUnit.HOURS;
    duration = hours;
  } else {
    durationUnits = OrderTradingDurationUnit.MINUTES;
    duration = minutes;
  }

  return {
    duration,
    durationUnits,
  };
};

export const getTradingDuration = (frequency: number, numberOfSlices: number) => {
  const tradingDurationUnit = getTradingDurationUnit(frequency, numberOfSlices);
  const duration = frequency * (numberOfSlices - 1);

  return Math.round(duration / TRADING_DURATION_UNIT_SECONDS[tradingDurationUnit]);
};

export const getTradingDurationSec = (duration: number, units: OrderTradingDurationUnit) => {
  switch (units) {
    case OrderTradingDurationUnit.DAYS:
      return duration * 24 * 60 * 60;
    case OrderTradingDurationUnit.HOURS:
      return duration * 60 * 60;
    case OrderTradingDurationUnit.MINUTES:
      return duration * 60;
    default:
      return duration;
  }
};

// @TODO find better way to extract from/to (coin1, coin2 from algolia response?)
export const getSymbolPair = (symbol = "") => {
  if (!symbol) return { from: "", to: "" };

  let sep = "";
  const seps = {
    ["/"]: true,
    ["_"]: true,
    ["-"]: true,
  };

  const arr = symbol.split("");
  for (let i = 0; i < arr.length; i++) {
    if (seps[arr[i]]) {
      sep = arr[i];
      break;
    }
  }

  const [from, _to, ...rest] = symbol.split(sep);
  const to = _to ? [_to, ...rest].join(sep) : "";

  return { from, to };
};

export const getAssetsFromSymbol = (symbol: string) => {
  let from: string;
  let to: string;

  if (symbol.includes("/")) {
    const [f, ...t] = symbol.split("/");
    from = f;
    to = t ? t.join("/") : "";
  } else {
    const [f, ...t] = symbol.split("-");
    from = f;
    to = t ? t.join("-") : "";
  }
  return { from, to: to || from };
};

export const fieldHasValue = (value: any) => value !== undefined && value !== "";

export const isVisibleField = (
  fieldName: string,
  opts: {
    strategy: OrderExecutionStrategy;
    role: OrderRole;
    side: OrderSide;
    triggerPriceMin?: number | string;
    triggerPriceMax?: number | string;
  }
) => {
  const { strategy, role, side, triggerPriceMin, triggerPriceMax } = opts;

  const hasTriggerFields = fieldHasValue(triggerPriceMin) || fieldHasValue(triggerPriceMax);
  const bothTriggerAllowed = ![OrderExecutionStrategy.LIMIT, OrderExecutionStrategy.ORDER].includes(strategy);

  const showPlacementFields =
    strategy === OrderExecutionStrategy.LIMIT ||
    (role === OrderRole.MAKER &&
      [
        OrderExecutionStrategy.ORDER,
        OrderExecutionStrategy.TWAP,
        OrderExecutionStrategy.VWAP,
        OrderExecutionStrategy.ICEBERG,
      ].includes(strategy));

  const fields = {
    frequency: strategy === OrderExecutionStrategy.TWAP,
    numberOfRetries: showPlacementFields,
    // placement type
    refreshMode: showPlacementFields,
    // placement
    refreshPlacementLevel: showPlacementFields,
    // cancel
    refreshThreshold: showPlacementFields,
    triggerMode: hasTriggerFields && bothTriggerAllowed,
    triggerPriceMin:
      (hasTriggerFields && bothTriggerAllowed) || (fieldHasValue(triggerPriceMin) && side === OrderSide.BUY),
    triggerPriceMax:
      (hasTriggerFields && bothTriggerAllowed) || (fieldHasValue(triggerPriceMax) && side === OrderSide.SELL),
  };

  return !!fields[fieldName];
};

export const orderAdvancedSettingsModals = (id: string) => {
  Array.from(document.getElementsByClassName("advanced-options-popup")).forEach(
    (el) => ((el as HTMLElement).style.zIndex = "999")
  );

  setTimeout(() => {
    const container = document.getElementsByClassName(`advanced-options-popup-${id}`)[0] as HTMLElement;

    if (container) container.style.zIndex = "1000";
  });
};
