import * as React from "react";
import { useEffect } from "react";
import {
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  ColumnDef,
  SortDirection,
  getExpandedRowModel,
  InitialTableState,
  Column,
  HeaderGroup,
  Cell,
} from "@tanstack/react-table";
import { Box, BoxProps, Skeleton } from "@mui/material";
import { useVirtual } from "react-virtual";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import styled from "@emotion/styled";
import { TableDataError } from "@src/components/table-data-error";
import { NoTableData } from "@src/components/no-table-data";
import { TableRow } from "@src/components/anboto-table/table-row";
import { TableHeaderCell } from "@src/components/anboto-table/table-header-cell";
import { TableCell } from "@src/components/anboto-table/table-cell";
import { AnbotoPagination } from "@src/components/ui/anboto-pagination";

export const renderSortArrows = (sort?: false | SortDirection) => {
  if (!sort) return null;

  return sort === "desc" ? (
    <ArrowDownwardIcon sx={{ width: 16, height: 16, color: (theme) => theme.palette.text.secondary }} />
  ) : (
    <ArrowUpwardIcon sx={{ width: 16, height: 16, color: (theme) => theme.palette.text.secondary }} />
  );
};

function getCommonPinningStyles<T>(column: Column<T>): BoxProps["sx"] {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
    position: isPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: isPinned ? 1 : 0,
  };
}

export function AnbotoTable<T extends {}>({
  data,
  columns,
  loading,
  expandedRowRender,
  error,
  noDataComponent,
  noDataText,
  refetch,
  getRowId,
  containerSx,
  hasFooter,
  paginationWithDataFetching,
  rowSx,
  initialState,
  headerRowRenderer,
  rowRenderer,
}: {
  error: boolean;
  data: Array<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  getRowId: (data: T) => string;
  loading?: boolean;
  expandedRowRender?: (data: T) => React.ReactNode;
  noDataComponent?: React.ReactElement;
  noDataText?: string;
  refetch?: () => void;
  isMemoized?: string;
  containerSx?: BoxProps["sx"];
  hasFooter?: boolean;
  paginationWithDataFetching?: {
    count: number;
    pagination: { currentPage: number; rowsPerPage: number };
    setPagination: (pagination: { currentPage: number; rowsPerPage: number }) => void;
  };
  rowSx?: BoxProps["sx"];
  initialState?: InitialTableState;
  rowRenderer?: (row: Row<T>) => React.ReactNode;
  headerRowRenderer?: (row: HeaderGroup<T>[]) => React.ReactNode;
}) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    sortingFns: {},
    initialState,
  });

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    estimateSize: React.useCallback(() => 35, []),
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  useEffect(() => {
    if (paginationWithDataFetching) {
      table?.setPageSize(paginationWithDataFetching?.pagination.rowsPerPage || 10);
    }
  }, [paginationWithDataFetching]);

  const changeRowsPerPageHandler = (value: number) => {
    table?.setPageSize(value);
    paginationWithDataFetching?.setPagination({ currentPage: 0, rowsPerPage: value });
  };

  const changePageHandler = (value: number) => {
    paginationWithDataFetching?.setPagination({
      currentPage: value,
      rowsPerPage: paginationWithDataFetching?.pagination.rowsPerPage,
    });
  };

  return (
    <Box
      sx={{
        overflow: "auto",
        height: "100%",
        width: "100%",
        flex: 1,
        fontSize: 12,
        ...containerSx,
      }}
      ref={tableContainerRef}
    >
      {headerRowRenderer
        ? headerRowRenderer(table.getHeaderGroups())
        : table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={headerGroup.id}
              sx={{
                zIndex: 2,
                height: 34,
                position: "sticky",
                top: 0,
                background: (theme) => theme.palette.background.paper,
                ...rowSx,
              }}
            >
              {headerGroup.headers.map((header, index) => (
                <TableHeaderCell
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  sx={{
                    cursor: header.column.getCanSort() ? "pointer" : "",
                    ...getCommonPinningStyles(header.column),
                  }}
                  flexGrow={index ? undefined : "0 !important"}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  {renderSortArrows(header.column.getIsSorted())}
                </TableHeaderCell>
              ))}
            </TableRow>
          ))}
      {error ? (
        <InfoContainer>
          <TableDataError onRefresh={() => refetch && refetch()} />
        </InfoContainer>
      ) : data.length === 0 && !loading ? (
        <InfoContainer>{noDataComponent || <NoTableData message={noDataText} />}</InfoContainer>
      ) : (
        <>
          {paddingTop > 0 && <div style={{ height: `${paddingTop}px` }} />}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index] as Row<T>;
            if (rowRenderer) return rowRenderer(row);

            return (
              <Box key={getRowId(row.original)}>
                <TableRow loading={loading} expanded={row.getIsExpanded()} sx={rowSx}>
                  {row.getVisibleCells().map((cell, index) => (
                    <TableCell
                      key={cell.id}
                      loading={loading}
                      width={cell.column.getSize()}
                      flexGrow={index ? undefined : "0 !important"}
                      sx={{ ...getCommonPinningStyles(cell.column) }}
                    >
                      {loading ? (
                        <Skeleton
                          key={cell.id}
                          sx={{ flex: 1, width: cell.column.getSize(), height: 16 }}
                          variant="rectangular"
                          animation="wave"
                        />
                      ) : (
                        flexRender(cell.column.columnDef.cell, cell.getContext())
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                {!loading && row.getIsExpanded() && expandedRowRender && expandedRowRender(row.original)}
              </Box>
            );
          })}
          {paddingBottom > 0 && <div style={{ height: `${paddingBottom}px` }} />}

          {hasFooter &&
            table.getFooterGroups().map((footerGroup) => (
              <TableRow
                loading={loading}
                key={footerGroup.id}
                sx={{
                  zIndex: 2,
                  height: 34,
                  position: "sticky",
                  top: 0,
                  background: (theme) => theme.palette.background.paper,
                }}
              >
                {footerGroup.headers.map((header, index) => (
                  <TableHeaderCell
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    sx={{
                      cursor: header.column.getCanSort() ? "pointer" : "",
                      ...getCommonPinningStyles(header.column),
                    }}
                    flexGrow={index ? undefined : "0 !important"}
                  >
                    {" "}
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </TableHeaderCell>
                ))}
              </TableRow>
            ))}
          {paginationWithDataFetching && (
            <AnbotoPagination
              sx={{ position: "sticky", left: 0 }}
              total={paginationWithDataFetching.count || 0}
              currentPage={paginationWithDataFetching.pagination.currentPage}
              pageSize={paginationWithDataFetching.pagination.rowsPerPage}
              onChangeRowsPerPage={changeRowsPerPageHandler}
              onChangePage={changePageHandler}
            />
          )}
        </>
      )}
    </Box>
  );
}

const InfoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: 2,
  justifyContent: "center",
  height: 238,
});

export const MemoizedTableHeaderCell = React.memo(
  TableHeaderCell,
  (prevProps, nextProps) => nextProps.shouldUpdate === prevProps.shouldUpdate
);

function _TableCell<T>({ cell, ...props }: { cell: Cell<T, any>; shouldUpdate: any } & BoxProps) {
  return <TableCell {...props}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>;
}

export const MemoizedTableCell = React.memo(
  _TableCell,
  (prevProps, nextProps) => nextProps.shouldUpdate === prevProps.shouldUpdate
) as typeof _TableCell;

export const MemoizedTableRow = React.memo(
  TableRow,
  (prevProps, nextProps) => nextProps.shouldUpdate === prevProps.shouldUpdate
);
